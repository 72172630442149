/* eslint-disable @typescript-eslint/no-explicit-any */
import { TOAST_CONTENT } from "@enfusion-ui/core";
import {
  Button,
  ControlledTextInput,
  Modal,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  REST_API,
  styled,
  successToast,
} from "@enfusion-ui/web-core";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  checkPasswordStrength,
  PasswordRules,
} from "../../utils/passwordRules";

const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: var(--spacing-xl);
  gap: var(--spacing-xl);
  max-width: 300px;
  width: 90%;
`;

const InnerForm = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
`;

const ErrorContainer = styled.div`
  background-color: var(--danger);
  color: var(--text-normal);
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--text-input-border);
  padding: 0.5em;
  font-size: 0.75em;
`;

const ErrorMessage = styled.span`
  font-weight: bold;
  margin-left: 0.25em;
`;

const ContentContainer = styled.div`
  display: flex;
  font-size: 1.2em;
  padding: var(--spacing-xl);
  align-items: center;
  justify-content: center;
`;

const SubmitButton = styled(Button)`
  margin-top: var(--spacing-xl);
`;

type ChangePasswordFormValue = {
  currentPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
};

const ChangePasswordForm: React.FC<{
  onSubmit: (currentPassword: string, newPassword: string) => Promise<any>;
}> = ({ onSubmit }) => {
  const formMethods = useForm<ChangePasswordFormValue>({ mode: "all" });

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const rulesRef = React.useRef<PasswordRules | null>(null);

  const submitMiddleware = async (args: ChangePasswordFormValue) => {
    formMethods.clearErrors([
      "currentPassword",
      "newPassword",
      "newPasswordRepeat",
    ]);
    setLoading(true);
    setErrorMessage(null);

    try {
      await onSubmit(args.currentPassword, args.newPassword);
      successToast(TOAST_CONTENT.password.change.success);
    } catch (error) {
      AppLogging.error("Password change failed", error);
      setErrorMessage(
        "Failed to change your password! Make sure your current password is correct."
      );
    } finally {
      formMethods.reset();
      setLoading(false);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={formMethods.handleSubmit(submitMiddleware)}>
        <InnerForm>
          {errorMessage !== null && (
            <ErrorContainer>
              <FontAwesomeIcon icon={faExclamationCircle} size="lg" />{" "}
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </ErrorContainer>
          )}
          <ControlledTextInput
            label="Current Password"
            type="password"
            name="currentPassword"
            required
            data-testid="current-password-text-input"
            defaultValue=""
            errors={formMethods.errors}
            rules={{ required: "Required" }}
            autoFocus
          />

          <ControlledTextInput
            label="New Password"
            type="password"
            name="newPassword"
            required
            data-testid="new-password-text-input"
            rules={{
              required: "Required",
              validate: async (value) => {
                const { currentPassword } = formMethods.getValues();
                if (value === currentPassword)
                  return "Cannot match current password";
                if (rulesRef.current === null) {
                  rulesRef.current =
                    await REST_API.UTILITY.GET_PASSWORD_RULES.FETCH();
                }
                const result = checkPasswordStrength(rulesRef.current, value);
                if (result.verdict < 4) {
                  return result.hint;
                }
                return true;
              },
            }}
            errors={formMethods.errors}
            defaultValue=""
          />
          <ControlledTextInput
            label="Confirm New Password"
            type="password"
            name="newPasswordRepeat"
            required
            data-testid="new-password-repeat-text-input"
            rules={{
              required: "Required",
              validate: async (value) => {
                const { newPassword } = formMethods.getValues();
                if (value !== newPassword) return "Must match new password";
                return true;
              },
            }}
            errors={formMethods.errors}
            defaultValue=""
          />
        </InnerForm>

        <SubmitButton
          primary
          type="submit"
          busy={loading}
          data-testid="change-password-submit-button"
          data-e2e-id="change-password-submit-button"
        >
          Submit
        </SubmitButton>
      </Form>
    </FormProvider>
  );
};

export type ChangePasswordModalProps = {
  open: boolean;
  onCancel: VoidFunction;
};

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  open,
  onCancel,
}) => {
  const handleChangePassword = async (
    currentPassword: string,
    newPassword: string
  ) => {
    try {
      await REST_API.UTILITY.CHANGE_PASSWORD.FETCH(
        btoa(currentPassword),
        btoa(newPassword)
      );
      onCancel();
    } catch (e) {
      console.warn("Change password error", e);
      throw new Error("Failed to change password");
    }
  };

  return (
    <Modal
      isOpen={open}
      onClose={onCancel}
      title="Change Password"
      size="small"
      allowDismissal
      content={
        <ContentContainer>
          <ChangePasswordForm onSubmit={handleChangePassword} />
        </ContentContainer>
      }
    />
  );
};

export default ChangePasswordModal;
