import styled from "styled-components";

export const SummaryPanel = styled.div`
  width: 100%;
  padding: var(--spacing-xl);
  background-color: var(--background-color-2);
  border-radius: var(--radius-l);
  border: 1px solid var(--border);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const SummaryPanelLabel = styled.div`
  color: var(--text-color-1);
`;

export const SummaryEditButton = styled.div`
  font-size: 0.75rem;
  padding: var(--spacing-xl);
  text-align: center;
  background-color: var(--background-color-1);
  border-radius: var(--radius-l);
  cursor: pointer;
  :hover {
    opacity: 0.85;
  }
`;
