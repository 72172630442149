import { ExecutionOrderType } from "@enfusion-ui/types";
import { Button, ButtonProps, InputLabel } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const DEFAULT_TICK_SIZE = 10;

type ButtonColor = "success" | "danger";

type PxAdjType = "Ticks" | "Ask" | "Bid" | "MKT";

type PxAdjButtonDef =
  | {
      pxAdjType: "Ticks";
      value: number;
    }
  | {
      pxAdjType: "Ask" | "Bid" | "MKT";
      value?: undefined;
    };

const PriceAdjustmentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)<{ $buttonColor?: ButtonColor }>`
  color: ${({ $buttonColor }) =>
    $buttonColor ? "var(--background-color-2)" : "var(--text-normal)"};
  background-color: ${({ $buttonColor = "background-color-1" }) =>
    `var(--${$buttonColor})`};
  padding: var(--spacing-s);
  font-size: 0.8rem;
  line-height: 1rem;
  font-variant-numeric: tabular-nums lining-nums;

  :disabled,
  :disabled:hover {
    color: var(--background-color-2);
    background-color: var(--background-color-1);
    opacity: 1;
  }

  :hover {
    background-color: ${({ $buttonColor = "background-color-1" }) =>
      `var(--${$buttonColor})`};
    opacity: 0.8;
  }
`;

const DefaultButtonGrid = styled.div<{ numButtons: number }>`
  display: grid;
  grid-gap: var(--spacing-s);

  grid-template-columns: ${({ numButtons }) => `repeat(${numButtons}, 1fr)`};

  @media (max-width: 500px) {
    display: none;
  }
`;

const MobileButtonGrid = styled.div<{ numTickButtons: number }>`
  display: none;

  @media (max-width: 500px) {
    display: grid;
    grid-gap: var(--spacing-s);
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: ${({ numTickButtons }) =>
      `repeat(${numTickButtons + 2}, 1fr)`};

    button[title="MKT"] {
      grid-column: span 2;
    }
  }
`;

const PxAdjButton: React.FC<
  { pxAdjType: PxAdjType; value?: number } & ButtonProps
> = ({ pxAdjType, value, ...rest }) => {
  let displayName = "";
  let buttonColor: ButtonColor | undefined = undefined;

  if (pxAdjType === "Ticks" && value !== undefined) {
    displayName = `${value >= 0 ? "+" : "\u2212"}${Math.abs(value)}`;
    buttonColor = value >= 0 ? "success" : "danger";
  } else {
    displayName = pxAdjType;
    if (pxAdjType === "Ask") {
      buttonColor = "danger";
    } else if (pxAdjType === "Bid") {
      buttonColor = "success";
    }
  }

  return (
    <StyledButton
      {...rest}
      title={pxAdjType === "Ticks" ? `${displayName} Ticks` : displayName}
      $buttonColor={buttonColor}
      type="button"
    >
      {displayName}
    </StyledButton>
  );
};

const generateDefs = (base: number, increment: number, numButtons: number) => {
  const positive: PxAdjButtonDef[] = [];
  const negative: PxAdjButtonDef[] = [];

  positive.push({ pxAdjType: "Ticks", value: 1 });

  const positiveBase = base;
  const negativeBase = -(base + increment * (numButtons - 1));

  for (let i = 0; i < numButtons; i++) {
    const positiveValue = positiveBase + i * increment;
    positive.push({ pxAdjType: "Ticks", value: positiveValue });

    const negativeValue = negativeBase + i * increment;
    negative.push({ pxAdjType: "Ticks", value: negativeValue });
  }

  negative.push({ pxAdjType: "Ticks", value: -1 });

  return {
    negative,
    positive,
  };
};

const buttonDefs = generateDefs(5, 5, 3);

const defaultLayout: PxAdjButtonDef[] = [
  ...buttonDefs.negative,
  { pxAdjType: "Ask" },
  { pxAdjType: "Bid" },
  ...buttonDefs.positive,
  { pxAdjType: "MKT" },
];

const mobileLayout: PxAdjButtonDef[] = [
  { pxAdjType: "Ask" },
  { pxAdjType: "MKT" },
  { pxAdjType: "Bid" },
];

export type PriceAdjustmentButtonsProps = {
  askPrice: number | null;
  bidPrice: number | null;
  limitPrice: number | null;
  instrumentTickSize?: number;
  disabled?: boolean;
  applyPriceAdjustment: (price: number) => void;
  setOrderType: (newOrderType: ExecutionOrderType) => void;
};

const PriceAdjustmentButtons: React.FC<PriceAdjustmentButtonsProps> = ({
  askPrice,
  bidPrice,
  instrumentTickSize = DEFAULT_TICK_SIZE,
  disabled,
  limitPrice,
  applyPriceAdjustment,
  setOrderType,
}) => {
  const handlePriceAdjustmentClick = (def: PxAdjButtonDef) => {
    switch (def.pxAdjType) {
      case "Ask":
        if (askPrice !== null) applyPriceAdjustment(askPrice);
        break;
      case "Bid":
        if (bidPrice !== null) applyPriceAdjustment(bidPrice);
        break;
      case "Ticks": {
        const oldLimitPrice = limitPrice ?? 0;
        const newLimitPrice = oldLimitPrice + instrumentTickSize * def.value;
        applyPriceAdjustment(Math.round(newLimitPrice * 100) / 100);
        break;
      }
      case "MKT":
        setOrderType("Market");
        break;
    }
  };

  return (
    <PriceAdjustmentContainer>
      <InputLabel>Px Adj</InputLabel>
      <DefaultButtonGrid numButtons={defaultLayout.length}>
        {defaultLayout.map((def) => (
          <PxAdjButton
            key={
              def.pxAdjType === "Ticks"
                ? `${def.value}${def.pxAdjType}`
                : def.pxAdjType
            }
            {...def}
            onClick={() => handlePriceAdjustmentClick(def)}
            disabled={
              disabled ||
              (def.value && def.value < 0 && !askPrice) ||
              (def.value && def.value > 0 && !bidPrice) ||
              (def.pxAdjType === "Ask" && !askPrice) ||
              (def.pxAdjType === "Bid" && !bidPrice)
            }
          />
        ))}
      </DefaultButtonGrid>
      <MobileButtonGrid numTickButtons={0}>
        {mobileLayout.map((def) => (
          <PxAdjButton
            key={
              def.pxAdjType === "Ticks"
                ? `${def.value}${def.pxAdjType}`
                : def.pxAdjType
            }
            {...def}
            onClick={() => handlePriceAdjustmentClick(def)}
            disabled={
              disabled ||
              (def.pxAdjType === "Ask" && !askPrice) ||
              (def.pxAdjType === "Bid" && !bidPrice)
            }
          />
        ))}
      </MobileButtonGrid>
    </PriceAdjustmentContainer>
  );
};

export default PriceAdjustmentButtons;
