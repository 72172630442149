import { ChartOptions, TooltipOptions } from "highcharts";

import { formatterCreator } from "./utils";

const dtdLongTermCategories = ["DTD", "Long Term"];

export const getDTDLongTermChartConfig = (
  width?: number | null,
  title?: string,
  yFormatter: (y?: number | null) => string = (i) => `${i ?? 0}`,
  data?: Array<[number]>
) =>
  ({
    chart: {
      backgroundColor: "var(--background-color-2)",
      borderWidth: 0,
      skipClone: true,
      animation: false,
      style: { overflow: "visible" },
      type: "bar",
      spacingBottom: 6,
      spacingTop: 4,
      spacingLeft: 0,
      spacingRight: 0,
      width: width || 5,
      height: 80,
    },
    title: {
      text: title,
      align: "left",
      margin: 2,
      useHTML: true,
      style: {
        fontWeight: "bold",
        color: "var(--text-normal)",
        fontSize: "14px",
        lineHeight: "18px",
      },
    },
    credits: { enabled: false },
    tooltip: {
      outside: true,
      followPointer: true,
      backgroundColor: "var(--background-color-2)",
      style: { color: "var(--text-normal)" },
      formatter: formatterCreator(
        ({ point, colorIndex, y }) =>
          `<span style="color: ${point.color}; font-weight: bold">${
            dtdLongTermCategories[colorIndex]
          }</span>: ${yFormatter(y)}`
      ),
    },
    legend: { enabled: false },
    xAxis: {
      categories: ["-"],
      gridLineWidth: 0,
      lineWidth: 0,
      labels: {
        x: -5,
        formatter: formatterCreator(
          ({ pos }) =>
            `<span style="color: var(--text-color-1); font-size: 12px">${dtdLongTermCategories[pos]}</span>`
        ),
      },
      title: { text: null },
    },
    yAxis: {
      min: 0,
      title: { text: null },
      labels: { enabled: false },
      gridLineWidth: 0,
    },
    plotOptions: {
      bar: {
        borderWidth: 0,
        pointWidth: 16,
        borderRadius: "50%",
        dataLabels: {
          enabled: true,
          formatter: formatterCreator(
            ({ y }) =>
              `<span style="color: var(--text-normal); font-size: 12px">${yFormatter(
                y
              )}</span>`
          ),
        },
        groupPadding: 0.05,
        padding: 0,
      },
    },
    series: [
      {
        data,
        colorByPoint: true,
      },
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);

export const defaultChartOptions: ChartOptions = {
  borderWidth: 0,
  style: { overflow: "visible" },
  backgroundColor: "var(--background-color-2)",
};

export const defaultTooltipOptions: TooltipOptions = {
  hideDelay: 0,
  backgroundColor: "var(--background-color-2)",
  style: { color: "var(--text-normal)" },
};
