export const fillsMetaData = {
  quantity: {
    key: "quantity",
    name: "Quantity",
    columnTypeCategory: "Number",
    sideSensitive: false,
  },
  cumulativeQty: {
    key: "cumulativeQty",
    name: "CumulativeQty",
    columnTypeCategory: "Number",
    sideSensitive: false,
  },
  price: {
    key: "price",
    name: "Price",
    columnTypeCategory: "Price",
    sideSensitive: false,
  },
  tradeDate: {
    key: "tradeDate",
    name: "Trade Date",
    columnTypeCategory: "Date",
    sideSensitive: false,
  },
  execDate: {
    key: "execDateTime",
    name: "Exec Date",
    columnTypeCategory: "Date",
    sideSensitive: false,
  },
  execTime: {
    key: "execDateTime",
    name: "Time",
    columnTypeCategory: "Time",
    sideSensitive: false,
  },
  clientOrderId: {
    key: "clientOrderId",
    name: "ClOrderId",
    columnTypeCategory: "String",
    sideSensitive: false,
  },
  actualExecutionBroker: {
    key: "actualExecutionBroker",
    name: "ExecBroker",
    columnTypeCategory: "String",
    sideSensitive: false,
  },
  execID: {
    key: "execID",
    name: "ExecID",
    columnTypeCategory: "String",
    sideSensitive: false,
  },
  execTransType: {
    key: "execTransType",
    name: "ExecTransType",
    columnTypeCategory: "String",
    sideSensitive: false,
  },

  execRefID: {
    key: "execRefID",
    name: "ExecRefID",
    columnTypeCategory: "String",
    sideSensitive: false,
  },
  active: {
    key: "active",
    name: "Active",
    columnTypeCategory: "Boolean",
    sideSensitive: false,
  },
  farLegQty: {
    key: "farLegQty",
    name: "FarLegQty",
    columnTypeCategory: "Number",
    sideSensitive: false,
  },

  farLegPrice: {
    key: "farLegPrice",
    name: "FarLegPrice",
    columnTypeCategory: "Number",
    sideSensitive: false,
  },
  lastMkt: {
    key: "lastMkt",
    name: "Mkt",
    columnTypeCategory: "String",
    sideSensitive: false,
  },
  lastCapacity: {
    key: "lastCapacity",
    name: "Last Capacity",
    columnTypeCategory: "String",
    sideSensitive: false,
  },
  lastLiquidityInd: {
    key: "lastLiquidityInd",
    name: "Last Liq Ind",
    columnTypeCategory: "String",
    sideSensitive: false,
  },
  partyIDExecutingFirm: {
    key: "partyIDExecutingFirm",
    name: "Exec Firm",
    columnTypeCategory: "String",
    sideSensitive: false,
  },
  partyIDExecutingVenue: {
    key: "partyIDExecutingVenue",
    name: "Exec Venue",
    columnTypeCategory: "String",
    sideSensitive: false,
  },
};

export const allocationMetadata = {
  fundName: {
    key: "fundName",
    name: "Fund",
    columnTypeCategory: "String",
  },
  accountName: {
    key: "accountName",
    name: "Account",
    columnTypeCategory: "String",
  },
  custodian: {
    key: "custodian",
    name: "Custodian",
    columnTypeCategory: "String",
  },
  book: {
    key: "book",
    name: "Hierarchy",
    columnTypeCategory: "String",
  },
  allocationPct: {
    key: "allocationPct",
    name: "Order Allocation %",
    columnTypeCategory: "String",
  },
  allocationQuantity: {
    key: "allocationQuantity",
    name: "Order Allocation Quantity",
    columnTypeCategory: "Number",
  },
  orderAllocationNAV: {
    key: "orderAllocationNAV",
    name: "Order Allocation % of NAV",
    columnTypeCategory: "String",
  },
  positionQuantity: {
    key: "positionQuantity",
    name: "Position Quantity",
    columnTypeCategory: "Number",
  },
  positionPercOfNAV: {
    key: "positionPerOfNAV",
    name: "Position % of NAV",
    columnTypeCategory: "String",
  },
  unfilledPercOfNAV: {
    key: "unfilledPercOfNAV",
    name: "Unfilled % of NAV",
    columnTypeCategory: "String",
  },
  resultingPercOfNAV: {
    key: "resultingPercOfNAV",
    name: "Resulting % of NAV",
    columnTypeCategory: "String",
  },
};

export const getGridColumnTypes = (columnTypeCategory: string) => {
  switch (columnTypeCategory) {
    case "Number":
    case "Price":
      return "numericColumn";

    default:
      return undefined;
  }
};
