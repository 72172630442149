import { ReportSettingsDefinition } from "../../types";
import GLCashFlowStatementQuickFilter from "./GLCashFlowStatementQuickFilter";
import GLCashFlowStatementSettings from "./GLCashFlowStatementSettings";

export const WebGLCashFlowStatementQuery: ReportSettingsDefinition = {
  name: "GLCashFlowStatement",
  queryType: "WebGLCashFlowStatementQuery",
  quickFilterComponent: GLCashFlowStatementQuickFilter,
  settingsComponent: GLCashFlowStatementSettings,
};
