import ReportLoadingStatus from "@app-components/display/ReportLoadingStatus";
import { useReportRows } from "@app-context/reports/ReportsProvider";
import WidgetTitle from "@app-views/dashboards/components/WidgetTitle";
import { useReportDatasource } from "@app-views/dashboards/hooks/useReportDatasource";
import { WidgetContentContainerFill } from "@app-views/dashboards/widget/components/WidgetContentContainer";
import {
  getColumnDef,
  ListViewWidgetConfig,
  useDashboard,
  useWidget,
} from "@enfusion-ui/dashboards";
import {
  ChannelDataType,
  DashboardSubTableDatasource,
} from "@enfusion-ui/types";
import { formatReportValue } from "@enfusion-ui/utils";
import {
  Button,
  CenterContent,
  ContentMessage,
  ContentMessageWrapper,
  Spinner,
} from "@enfusion-ui/web-components";
import { styled, useReports } from "@enfusion-ui/web-core";
import {
  faFolder,
  faFolderOpen,
  faSkullCrossbones,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uniq } from "lodash";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
import { useMeasure } from "react-use";

const ListViewInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--background-color-1) !important;
  border-color: var(--border) !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5) !important;
  border-radius: 5px;
`;

const DataContainer = styled.ul`
  height: 100%;
  width: calc(100% - 1px);
  margin-top: 5px;
  min-width: 120px !important;
  list-style-type: none;
  padding-left: 0px;
  overflow-y: auto;

  > :first-child {
    margin-top: 5px;
  }
`;

const ListItem = styled.li`
  height: 30px;
  margin-bottom: 0.4em;
  padding-left: 10px;
  cursor: pointer;
`;

const ListItemContainer = styled.div<{ isSelected: boolean }>`
  height: 100%;
  width: 98%;
  border-radius: 5px;
  padding-left: 5px;
  line-height: 30px;
  ${({ isSelected }) =>
    isSelected ? "background-color: var(--primary) !important;" : ""}
  :hover {
    background-color: var(--background-color-1-hover);
    opacity: 0.9;
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

type MenuItem = {
  title: string;
  key: string;
  icon?: IconDefinition;
  isSelected: boolean;
};

const ListViewWidget: React.FC<unknown> = () => {
  const { config, setEditing, setChannelData, addChannelKeys } = useWidget();
  const { canEdit } = useDashboard();
  const { ref: resizeRef } = useResizeDetector();
  const [measureRef, { width, height }] = useMeasure<HTMLDivElement>();

  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [containerLoaded, setContainerLoaded] = React.useState<boolean>(false);
  const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);
  const [selectedItem, setSelectedItem] = React.useState<string | null>(null);

  const { openDetailTable } = useReports();
  const {
    datasourceId,
    column,
    key,
    showTitle = true,
    autoSelectFirst = false,
  } = config as ListViewWidgetConfig;

  const { metadata, errorLoading, gridOptions, datasource, tableId } =
    useReportDatasource();

  const { rowsStore } = useReportRows();

  const rowOptions = tableId ? rowsStore?.[tableId] ?? null : null;

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "List View",
        type: ChannelDataType.String,
      },
      {
        name: `${key} Selected Row`,
        description: "List View Selected Row",
        type: ChannelDataType.SubTableDatasource,
      },
    ]);
  }, [addChannelKeys, key]);

  const onSelectionChanged = React.useCallback(
    (selectedItem: string | null, menuItemsOverride?: MenuItem[]) => {
      const reportColumn = getColumnDef(column, gridOptions?.metadata.columns);
      const reportRow = reportColumn
        ? rowOptions?.rows.find(
            (i) =>
              (i[reportColumn.name].value as unknown as string) === selectedItem
          )
        : undefined;
      const items = menuItemsOverride || menuItems;
      const updateMenuItems = items.map((item) =>
        item.title === selectedItem
          ? { ...item, isSelected: true }
          : { ...item, isSelected: false }
      );
      const args = {
        destination: metadata?.destination || "",
        label: selectedItem || "",
        tableId: `${reportRow?.["__row_id"]}`,
        row: `${reportRow?.["__row_idx"]}`,
        reportId: datasource?.id || "",
        parentTableId: datasource?.id || "",
      };
      if (reportRow) openDetailTable(args);
      setMenuItems(updateMenuItems);
      setChannelData([selectedItem, args as DashboardSubTableDatasource]);
      setSelectedItem(selectedItem);
    },
    [
      datasource,
      menuItems,
      column,
      JSON.stringify(Object.keys(gridOptions?.metadata.columns ?? {})),
      rowOptions?.rows,
      metadata?.destination,
    ]
  );

  React.useEffect(() => {
    if (autoSelectFirst && selectedItem === null && menuItems.length > 0) {
      onSelectionChanged(menuItems[0].title);
    }
  }, [autoSelectFirst, menuItems?.length, selectedItem]);

  React.useEffect(() => {
    if (column) {
      const reportColumn = getColumnDef(column, gridOptions?.metadata.columns);

      const uniqueColumnValues = reportColumn
        ? uniq(
            rowOptions?.rows?.map((row) =>
              formatReportValue(row[reportColumn.name], reportColumn, {
                numberReductionFormat: config?.numberReductionFormat,
              })
            )
          )?.map((data) => ({
            title: data,
            key: data,
            isSelected: false,
          }))
        : [];

      if (autoSelectFirst) {
        onSelectionChanged(uniqueColumnValues?.[0]?.title, uniqueColumnValues);
      } else {
        setMenuItems(uniqueColumnValues);
      }
    } else {
      setMenuItems([]);
    }
  }, [
    datasourceId,
    column,
    JSON.stringify(Object.keys(gridOptions?.metadata.columns ?? {})),
    autoSelectFirst,
  ]);

  const handleRef = (ref: HTMLDivElement) => {
    containerRef.current = ref;
    resizeRef.current = ref;
    measureRef(ref);
    setContainerLoaded(true);
  };

  const isWidgetConfigurable = !datasourceId && canEdit;
  return (
    <WidgetContentContainerFill ref={handleRef}>
      {errorLoading ? (
        <CenterContent>
          <ContentMessage
            icon={faSkullCrossbones}
            message="We couldn't load data right now."
            details={errorLoading}
            parentHeight={height}
            parentWidth={width}
          />
        </CenterContent>
      ) : null}
      {!datasource ? (
        <CenterContent>
          <ContentMessageWrapper
            isConfigurable={canEdit}
            onMouseDown={(e) => {
              if (isWidgetConfigurable) {
                e.stopPropagation();
                e.preventDefault();
                setEditing(true);
              }
            }}
          >
            <ContentMessage
              message="Please configure ListView widget"
              size="3x"
              contentStyle={{ textAlign: "center" }}
              parentHeight={height}
              parentWidth={width}
            />
            {canEdit ? <Button theme="primary">Open Settings</Button> : null}
          </ContentMessageWrapper>
        </CenterContent>
      ) : null}
      {!errorLoading &&
      datasourceId &&
      !menuItems.length &&
      (!gridOptions || !containerLoaded || metadata?.loading) ? (
        <CenterContent>
          {metadata?.progressSteps ? (
            <ReportLoadingStatus
              steps={metadata.progressSteps}
              parentHeight={height}
              parentWidth={width}
            />
          ) : (
            <Spinner />
          )}
        </CenterContent>
      ) : null}
      <ListViewInnerContainer>
        <WidgetTitle title={showTitle ? key : undefined} align="flex-start" />
        {menuItems.length > 0 ? (
          <DataContainer>
            {menuItems?.map((item) => (
              <ListItem
                key={`${item.key}-${item.title}`}
                onClick={() => onSelectionChanged(item.title)}
              >
                <ListItemContainer isSelected={item.isSelected}>
                  {item.icon ? (
                    <FontAwesomeIcon
                      icon={item.icon}
                      style={{ marginRight: "var(--spacing-l)" }}
                      size="sm"
                    />
                  ) : item.isSelected ? (
                    <FontAwesomeIcon
                      icon={faFolderOpen}
                      style={{ marginRight: "var(--spacing-l)" }}
                      size="sm"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faFolder}
                      style={{ marginRight: "var(--spacing-l)" }}
                      size="sm"
                    />
                  )}
                  {item.title}
                </ListItemContainer>
              </ListItem>
            ))}
          </DataContainer>
        ) : null}
      </ListViewInnerContainer>
    </WidgetContentContainerFill>
  );
};

export default ListViewWidget;
