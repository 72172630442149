import { ConnectionStatus } from "@enfusion-ui/types";
import { styled, useTheme } from "@enfusion-ui/web-core";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

import { getColor } from "../utils/getIconColor";

export const StatusIconContainer = styled.div<{ minWidth?: number | string }>`
  display: flex;
  padding: var(--spacing-s);
  background-color: var(--background-color-1);
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  min-width: ${({ minWidth }) => minWidth || "1.3"}rem;
`;

export const ConnectionStatusIcon: React.FC<{
  icon?: IconDefinition;
  svg?: React.ReactNode;
  status?: ConnectionStatus;
  size?: SizeProp;
  minWidth?: number;
  dataE2EId?: string;
}> = ({ icon, svg, status, size, minWidth, dataE2EId }) => {
  const { theme } = useTheme();

  return (
    <StatusIconContainer
      minWidth={minWidth}
      data-e2e-id={dataE2EId || "connection-status-icon"}
    >
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          size={size}
          color={getColor(status, theme)}
        />
      )}
      {svg}
    </StatusIconContainer>
  );
};

export default ConnectionStatusIcon;
