import { ElementWrapper } from "@app-views/oems/OrderTicket/panels/InstrumentAndStrategyPanel";
import { fetchInstrument } from "@app-views/oems/utils/actions";
import { UNKNOWN_INSTRUMENT_ID } from "@enfusion-ui/core";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { InstrumentSearchResult } from "@enfusion-ui/types";
import { PanelRow, Select, SelectContainer } from "@enfusion-ui/web-components";
import {
  AppLogging,
  styled,
  useIsContentMobile,
  useTabs,
} from "@enfusion-ui/web-core";
import { debounce } from "lodash";
import * as React from "react";

import { AdvancedSearchButton } from "../components/AdvancedSearchButton";
import { AdvancedSearchContentPortal } from "../components/AdvancedSearchContentPortal";
import AdvancedInstrumentSearch from "./components/AdvancedInstrumentSearch";
import { CustomOption } from "./components/CustomOption";
import {
  InstrumentOption,
  InstrumentSearchBy,
  InstrumentSelectProps,
} from "./types";
import { fetchInstrumentOptions, getSelectorValue } from "./utils";

const StyledPanelRow = styled(PanelRow)`
  flex: 1;
  gap: var(--spacing);
`;

function getId(option: InstrumentOption | null) {
  return option ? option.value : null;
}

export const InstrumentSelect: React.FC<InstrumentSelectProps> = ({
  value = UNKNOWN_INSTRUMENT_ID,
  onChange,
  label = "Instrument",
  autoFocus,
  disabled,
  defaultSearchBy = InstrumentSearchBy.MARKET_IDENTIFIER,
  financialSubTypes,
  hideOptions = false,
  hideAdvancedSearchOnSelect = false,
  showAdvancedSearch = true,
  searchOnlyPrimary = false,
  hideAdvancedSearchButtonText,
  inline,
  required,
  dataE2EId,
}) => {
  const isMounted = useMounted();
  const [loading, setLoading] = React.useState(false);
  const [showAdvancedPortal, setShowAdvancedPortal] = React.useState(false);
  const selectRef = React.useRef(null);
  const isMobile = useIsContentMobile();

  const { selectedTabId } = useTabs();

  const [inputValue, setInputValue] = React.useState<string>("");
  const [instrumentValue, setInstrumentValue] =
    React.useState<InstrumentOption | null>(null);
  const [options, setOptions] = React.useState<
    InstrumentOption[] | undefined
  >();

  React.useEffect(() => {
    const fetchInstrumentInfo = async (id: number | null) => {
      /**
       * In case of MarketData Widget, initially UNKNOWN instrument is selected.
       * When as instrument is changed but not applied and instead cancelled, before edit widget is unmounted, the UNKNOWN_INSTRUMENT_INFO is retrieved.
       * This causes onChange to be called which typically does not happen when fetching normal instruments owing to delay in backend call.
       * Hence setTimeout is introduced to allow edit widget to close in case on UNKNOWN_INSTRUMENT_ID
       */
      setTimeout(async () => {
        const instrumentInfo = await fetchInstrument(id);
        if (isMounted()) {
          if (instrumentValue) {
            onChange(instrumentInfo);
          }
          const selectorValue = getSelectorValue(instrumentInfo);
          setInstrumentValue(selectorValue);
        }
      }, 200);
    };

    if (isMounted() && value !== getId(instrumentValue)) {
      fetchInstrumentInfo(value);
    }
  }, [value]);

  React.useEffect(() => {
    if (
      hideAdvancedSearchOnSelect &&
      instrumentValue &&
      instrumentValue.value !== UNKNOWN_INSTRUMENT_ID
    )
      toggleAdvancedMenu(true);
  }, [instrumentValue]);

  const searchInstruments = useRefCallback(
    debounce(async (searchText: string) => {
      if (isMounted()) {
        setLoading(true);
        try {
          const options = await fetchInstrumentOptions(
            searchText,
            financialSubTypes,
            defaultSearchBy === InstrumentSearchBy.DESCRIPTION,
            searchOnlyPrimary
          );
          if (isMounted())
            setOptions(options as InstrumentOption[] | undefined);
        } catch (err) {
          AppLogging.error(
            "Failed to fetch instrument search result data",
            err
          );
        } finally {
          if (isMounted()) setLoading(false);
        }
      }
    }, 200),
    [financialSubTypes, defaultSearchBy, isMounted, setLoading]
  );

  React.useEffect(() => {
    searchInstruments("");
  }, []);

  const handleChange = useRefCallback(
    async (option: InstrumentOption | null) => {
      if (isMounted()) {
        setInstrumentValue(option);

        if (option) {
          const instrument = option.instrument as InstrumentSearchResult;
          const valueText =
            defaultSearchBy === InstrumentSearchBy.MARKET_IDENTIFIER
              ? instrument?.ticker
              : instrument?.description;
          setInputValue(valueText ?? "");
        }
        const newValue = option ? await fetchInstrument(option.value) : null;
        onChange(newValue);
        if (isMounted() && showAdvancedPortal) setShowAdvancedPortal(false);
      }
    },
    [defaultSearchBy]
  );

  const toggleAdvancedMenu = debounce((hide) => {
    if (isMounted()) setShowAdvancedPortal((prev) => (hide ? false : !prev));
  }, 150);

  React.useEffect(() => {
    showAdvancedPortal && toggleAdvancedMenu(true);
  }, [selectedTabId]);

  return (
    <>
      <SelectContainer ref={selectRef}>
        <StyledPanelRow>
          <ElementWrapper>
            <Select
              dataE2EId={dataE2EId}
              inputId="instrument-select"
              label={label}
              value={instrumentValue}
              options={options}
              onChange={(value) => {
                handleChange(value as InstrumentOption);
              }}
              onInputChange={(value) => {
                searchInstruments(value);
              }}
              components={{ Option: CustomOption }}
              filterOption={(option) => !!option}
              isLoading={loading}
              autoFocus={autoFocus}
              clearable
              disabled={disabled}
              minWidth={150}
              inline={inline}
              required={required}
            />
          </ElementWrapper>
          {showAdvancedSearch ? (
            <>
              <AdvancedSearchButton
                disabled={disabled}
                active={showAdvancedPortal}
                hideLabelPlaceholder={inline}
                hideText={isMobile || hideAdvancedSearchButtonText}
                onClick={() => toggleAdvancedMenu(false)}
              />
              <AdvancedSearchContentPortal
                attachedRef={selectRef}
                hideOptions={hideOptions}
                open={showAdvancedPortal}
                closePopup={() => toggleAdvancedMenu(true)}
                dataE2EId="instrument-select-portal"
              >
                <AdvancedInstrumentSearch
                  hideOptions={hideOptions}
                  defaultInputValue={inputValue}
                  defaultSearchBy={defaultSearchBy}
                  financialSubTypes={financialSubTypes}
                  defaultPrimaryOnly={searchOnlyPrimary}
                  onInstrumentSelect={(id, description) => {
                    handleChange({
                      label: description,
                      value: id,
                      instrument: null,
                    });
                    toggleAdvancedMenu(true);
                  }}
                />
              </AdvancedSearchContentPortal>
            </>
          ) : null}
        </StyledPanelRow>
      </SelectContainer>
    </>
  );
};

export default InstrumentSelect;
