import { ThemeDefinition } from "@enfusion-ui/core";
import {
  CELL_SIZE,
  useWidget,
  Widget,
  WidgetConfig,
  WidgetDefinition,
  WidgetType,
} from "@enfusion-ui/dashboards";
import { DashboardOrientation, FlexLayout } from "@enfusion-ui/types";
import { DimensionsProvider } from "@enfusion-ui/web-core";
import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import * as React from "react";

import { WIDGET_COMPONENT_DEFINITIONS } from "../widgets/definitions";
import { usePDFAsyncEmpty } from "./context";
import { pxToPtConversion } from "./utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MissingComponentDef: React.FC<any> = () => {
  const { type } = useWidget();
  return <Text>Missing Render Comp {type}</Text>;
};

const pdfStyles = StyleSheet.create({
  page: {
    position: "relative",
    fontFamily: "Lato",
    fontWeight: 400,
  },
  widgetContainerOuter: {
    height: "100%",
    width: "100%",
    paddingVertical: pxToPtConversion(4),
    paddingHorizontal: pxToPtConversion(2),
  },
  widgetContainerInner: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
});

type WidgetBoxProps = {
  id: string;
  pageIndex: number;
  type: WidgetType;
  config: WidgetDefinition;
  colWidth: number;
  colCount: number;
  pageRowCount: number;
  h?: number;
  w?: number;
  x?: number;
  y?: number;
  minW?: number;
};

const WidgetBox: React.FC<WidgetBoxProps> = ({
  id,
  type,
  colWidth,
  colCount,
  x = 0,
  y = 0,
  w = 0,
  h = 0,
  minW = 1,
}) => {
  const [Content, asyncContent] = React.useMemo(
    () => [
      WIDGET_COMPONENT_DEFINITIONS[type]?.renderPdfComponent ??
        MissingComponentDef,
      WIDGET_COMPONENT_DEFINITIONS[type]?.pdfContentAsync ?? false,
    ],
    [type]
  );

  const noAsync = usePDFAsyncEmpty(id);

  React.useEffect(() => {
    if (!asyncContent) return noAsync();
  }, [asyncContent]);

  return (
    <DimensionsProvider
      width={Math.min(w * colWidth, Math.max(colCount - x, minW) * colWidth)}
      height={h * CELL_SIZE}
    >
      <View
        // debug
        style={{
          position: "absolute",
          top: y * CELL_SIZE,
          left: Math.min(x * colWidth, (colCount - 1) * colWidth),
          width: Math.min(
            w * colWidth,
            Math.max(colCount - x, minW) * colWidth
          ),
          height: h * CELL_SIZE,
        }}
      >
        <View style={[pdfStyles.widgetContainerOuter]}>
          <View style={[pdfStyles.widgetContainerInner]}>
            <Content />
          </View>
        </View>
      </View>
    </DimensionsProvider>
  );
};

const WidgetEntry: React.FC<{
  placement: FlexLayout;
  widgets: WidgetConfig[];
  colWidth: number;
  colCount: number;
  pageRowCount: number;
  index: number;
  pageIndex: number;
}> = React.memo(
  ({
    placement,
    widgets,
    colWidth,
    colCount,
    pageRowCount,
    index,
    pageIndex,
  }) => {
    const widget = widgets.find((e) => e.id === placement.i);
    if (!widget) return null;
    return (
      <Widget
        key={widget.id}
        {...widget}
        index={index}
        gridId="main"
        renderWidgetContent={(type, config) => (
          <WidgetBox
            id={widget.id}
            type={type}
            config={config}
            colWidth={colWidth}
            colCount={colCount}
            pageIndex={pageIndex}
            pageRowCount={pageRowCount}
            {...placement}
          />
        )}
      />
    );
  }
);

export const PDFPage: React.FC<{
  index: number;
  colWidth: number;
  colCount: number;
  pageRowCount: number;
  widgets: WidgetConfig[];
  layout: FlexLayout[];
  orientation: DashboardOrientation;
  theme: ThemeDefinition;
}> = React.memo(
  ({
    index,
    colWidth,
    colCount,
    pageRowCount,
    widgets,
    layout,
    orientation,
    theme,
  }) => {
    return (
      <Page
        orientation={orientation}
        style={[
          pdfStyles.page,
          {
            backgroundColor: theme.colors.backgroundColor2,
          },
        ]}
      >
        {layout.map((i, idx) => (
          <WidgetEntry
            key={idx}
            placement={i}
            widgets={widgets}
            colWidth={colWidth}
            colCount={colCount}
            pageRowCount={pageRowCount}
            index={idx}
            pageIndex={index}
          />
        ))}
      </Page>
    );
  }
);
