import { ControlledInputBase, NumericInput } from "@enfusion-ui/web-components";
import * as React from "react";

export const MaxResultsInput: React.FC<{
  label?: string;
  value: number | null;
  onChange: (val: number | null) => void;
}> = ({ label = "Search Limit", value, onChange, ...rest }) => {
  return (
    <NumericInput
      {...rest}
      value={value ?? null}
      onChange={onChange}
      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!new RegExp(/[0-9]/).test(e.key)) e.preventDefault();
      }}
      label={label}
      formatValue={null}
      hideControls
      clearable
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      defaultValue={null as any}
    />
  );
};

export const ControlledMaxResultsInput: React.FC<{
  name?: string;
  label?: string;
}> = ({ name = "maxResults", label }) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...rest }) => (
        <MaxResultsInput label={label} {...rest} />
      )}
    />
  );
};
