import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import { useRefCallback } from "@enfusion-ui/hooks";
import { DashboardRoot } from "@enfusion-ui/types";
import * as React from "react";

type CreateFolderModalParams = { root: DashboardRoot; path: string };

type PortfolioExplorerContextType = {
  createPortfolioModalRoot: DashboardRoot | null;
  createFolderModalParams: CreateFolderModalParams | null;

  openCreatePortfolioModal: (root: DashboardRoot) => void;
  closeCreatePortfolioModal: VoidFunction;

  openCreateFolderModal: (root: DashboardRoot, path: string) => void;
  closeCreateFolderModal: VoidFunction;

  deletePortfolio: (root: DashboardRoot, path: string) => void;
};

export const PortfolioExplorerContext = React.createContext<
  PortfolioExplorerContextType | undefined
>(undefined);

export function usePortfolioExplorer() {
  const context = React.useContext(PortfolioExplorerContext);
  if (typeof context === "undefined") {
    throw new Error(
      "usePortfolioExplorer must be used within a PortfolioExplorerProvider"
    );
  }
  return context;
}

export default function PortfolioExplorerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [createPortfolioModalRoot, setCreatePortfolioModalRoot] =
    React.useState<DashboardRoot | null>(null);
  const [createFolderModalParams, setCreateFolderModalParams] =
    React.useState<CreateFolderModalParams | null>(null);

  const explorerEvent = useExplorerEvents("Portfolios");

  const openCreatePortfolioModal = useRefCallback((root: DashboardRoot) => {
    setCreatePortfolioModalRoot(root);
  }, []);
  const closeCreatePortfolioModal = useRefCallback(() => {
    setCreatePortfolioModalRoot(null);
  }, []);

  const openCreateFolderModal = useRefCallback(
    (root: DashboardRoot, path: string) => {
      setCreateFolderModalParams({ root, path });
    },
    []
  );
  const closeCreateFolderModal = useRefCallback(() => {
    setCreateFolderModalParams(null);
  }, []);

  const deletePortfolio = useRefCallback(
    (root: DashboardRoot, path: string) => {
      explorerEvent.broadcast(root, ExplorerEventsAction.Delete, path);
    },
    [explorerEvent]
  );

  return (
    <PortfolioExplorerContext.Provider
      value={{
        createPortfolioModalRoot,
        createFolderModalParams,
        openCreatePortfolioModal,
        closeCreatePortfolioModal,
        openCreateFolderModal,
        closeCreateFolderModal,
        deletePortfolio,
      }}
    >
      {children}
    </PortfolioExplorerContext.Provider>
  );
}
