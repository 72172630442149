import { SelectOptionsType } from "@enfusion-ui/types";
import * as React from "react";
import { components, OptionProps } from "react-select";

import financialSubtypeDisplayNames from "../../../../utils/FinancialSubtypeDisplayNames";
import InstrumentCard from "./InstrumentCard";

type CustomOptionProps = OptionProps<SelectOptionsType<number>, boolean>;

export const CustomOption: React.FC<CustomOptionProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { instrument } = props.data as any; // InstrumentOption
  if (!instrument) return null;
  return (
    <components.Option {...props}>
      <InstrumentCard
        description={instrument.description}
        subType={
          financialSubtypeDisplayNames[instrument.subType || ""] ??
          (instrument.subType || "")
        }
        exchange={instrument.exchangeShortname ?? instrument.exchangeName}
        ticker={instrument.bbyellow ?? instrument.ticker}
      />
    </components.Option>
  );
};
