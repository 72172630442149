import { CHANGE_TRADE_INSTRUCTIONS } from "@enfusion-ui/core";
import { FormElementHeader, InfoPopover } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const Header = styled.header`
  display: flex;
  align-items: center;
  gap: var(--spacing);
  padding-bottom: var(--spacing-xl);
  border-bottom: 1px solid var(--input-border);

  & svg:hover {
    opacity: 0.5;
  }
`;

export const TradeTicketInstructionsTabHeader: React.FC = () => {
  return (
    <Header>
      <FormElementHeader showDivider={false}>
        Change Trade Instructions
      </FormElementHeader>
      <InfoPopover
        title={CHANGE_TRADE_INSTRUCTIONS.header}
        contentProps={{ as: "ul" }}
        testId="tt-instructions-tab-popover"
        iconTestId="tt-instructions-tab-info-icon"
      >
        {CHANGE_TRADE_INSTRUCTIONS.texts.map((text, idx) => (
          <li key={idx}>{text}</li>
        ))}
      </InfoPopover>
    </Header>
  );
};
