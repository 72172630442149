import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { ChangeView } from "./ChangeView";

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: var(--spacing-l);
  flex-direction: row;
`;

const Stack = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: var(--radius);
  border: 1px solid var(--background-accent);
`;

const StackHeader = styled.div`
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color-0);
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
`;

const StackEntry = styled.div`
  height: 54px;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-1);

  :last-child {
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
  }
`;

const StackEntryValue = styled.div<{
  color?: "text-normal" | "success" | "danger";
}>`
  flex: 4;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: var(--${({ color = "text-normal" }) => color});
`;

const StackEntrySubtext = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 10px;
  line-height: 16px;
  font-weight: 700;
  color: var(--text-color-1);
`;

export type CalloutGridSeriesDataEntry = {
  subText?: string;
  change?: number;
  value: number | string;
  color?: "success" | "danger";
};

export type CalloutGridSeriesEntry = {
  name: string;
  data: CalloutGridSeriesDataEntry[];
};

export type CalloutGridProps = {
  series: CalloutGridSeriesEntry[];
};

export const CalloutGrid: React.FC<CalloutGridProps> = ({ series }) => {
  return (
    <Container>
      {series.map((entry) => (
        <Stack key={entry.name}>
          <StackHeader>{entry.name}</StackHeader>
          {entry.data.map((i, idx) => (
            <StackEntry key={idx}>
              <StackEntryValue color={i.color}>{i.value}</StackEntryValue>
              <StackEntrySubtext>
                {typeof i.change === "number" ? (
                  <ChangeView
                    style={{ justifyContent: "center", alignItems: "center" }}
                    change={i.change}
                    iconSize="1x"
                  />
                ) : (
                  i.subText
                )}
              </StackEntrySubtext>
            </StackEntry>
          ))}
        </Stack>
      ))}
    </Container>
  );
};
