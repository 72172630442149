import { useRefCallback, useRefList } from "@enfusion-ui/hooks";
import * as React from "react";
import { useMeasure, usePreviousDistinct } from "react-use";
import { ThisTabContext } from "./context";
import { useTabs } from "./TabsProvider";
const isVisible = (elem) => {
    if (!elem)
        return false;
    return !!(elem.offsetWidth ||
        elem.offsetHeight ||
        elem.getClientRects().length);
};
export const ThisTabProvider = ({ id, config, children, }) => {
    const { focusTab, closeTab, renameTab, undockTab, dockTab, onTabWillClose } = useTabs();
    const elRef = React.useRef(null);
    const [measureRef, { width, height }] = useMeasure();
    const prevSize = usePreviousDistinct({ width, height }, (prev, next) => {
        if (!prev || typeof prev.width === "undefined")
            return false;
        if (next.width < 5 || next.height < 5)
            return true;
        return false;
    });
    const ref = useRefList(elRef, measureRef);
    const closeThisTab = useRefCallback(() => closeTab(id), [id, closeTab]);
    const focusThisTab = useRefCallback(() => focusTab(id), [id, focusTab]);
    const renameThisTab = useRefCallback((name, config) => renameTab(id, name, config), [id, renameTab]);
    const onThisTabWillClose = useRefCallback((callback) => onTabWillClose(id, callback), [id, onTabWillClose]);
    const undockThisTab = useRefCallback(() => undockTab(id, width, height), [id, undockTab, width, height]);
    const dockThisTab = useRefCallback(() => dockTab(id), [id, dockTab]);
    const selector = React.useMemo(() => {
        return `tab-${id}`;
    }, [id]);
    const visible = isVisible(elRef.current);
    const results = React.useMemo(() => ({
        id,
        selector,
        config,
        closeTab: closeThisTab,
        focusTab: focusThisTab,
        renameTab: renameThisTab,
        onTabWillClose: onThisTabWillClose,
        undockTab: undockThisTab,
        dockTab: dockThisTab,
        visible,
        width: width === 0 && !visible ? prevSize?.width ?? width : width,
        height: height === 0 && !visible ? prevSize?.height ?? height : height,
    }), [
        id,
        selector,
        config,
        closeThisTab,
        focusThisTab,
        renameThisTab,
        onThisTabWillClose,
        undockThisTab,
        dockThisTab,
        visible,
        width === 0 && !visible ? prevSize?.width ?? width : width,
        height === 0 && !visible ? prevSize?.height ?? height : height,
    ]);
    return (React.createElement(ThisTabContext.Provider, { value: results },
        React.createElement("div", { ref: ref, id: selector, style: { width: "100%", height: "100%" } }, children)));
};
export function useThisTab() {
    const context = React.useContext(ThisTabContext);
    if (context === undefined) {
        throw new Error("useThisTab must be used within an ThisTabProvider");
    }
    return context;
}
