import {
  EntrySourceSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType, GeneralLedgerEntrySource } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import { EntrySourceSelect } from "./EntrySourceSelect";

const EntrySourceSelectionWidget = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, key } = config as EntrySourceSelectionWidgetConfig;
  const [value, setValue] = React.useState<GeneralLedgerEntrySource[] | null>(
    defaultValue
  );

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Entry Source",
        type: ChannelDataType.String,
      },
    ]);
  }, [addChannelKeys, key]);

  React.useEffect(() => {
    setChannelData([value]);
  }, [setChannelData, value]);

  const handleChange = (option: GeneralLedgerEntrySource[] | null) => {
    setValue(option);
  };

  return (
    <CenterContent>
      <EntrySourceSelect label={key} value={value} onChange={handleChange} />
    </CenterContent>
  );
};

export default EntrySourceSelectionWidget;
