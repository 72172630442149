import { ReportSettingsDefinition } from "../../types";
import ComplianceBlotterQuickFilter from "./ComplianceBlotterQuickFilter";
import ComplianceBlotterSettings from "./ComplianceBlotterSettings";

export const WebComplianceBlotterQuery: ReportSettingsDefinition = {
  name: "ComplianceBlotter",
  queryType: "WebComplianceBlotterQuery",
  quickFilterComponent: ComplianceBlotterQuickFilter,
  settingsComponent: ComplianceBlotterSettings,
};
