/* eslint-disable @typescript-eslint/no-explicit-any */
// Installed tailwindcss using these steps:
// https://daveceddia.com/tailwind-create-react-app/#production
import "modern-normalize/modern-normalize.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-grid-layout/css/styles.css";
import "rc-menu/assets/index.css";

import { BSSO_TOKEN_KEY, GoogleAnalyticsCustomFields } from "@enfusion-ui/core";
import { AppEvent, AppEventCategories } from "@enfusion-ui/types";
import { AppLogging, FULLSTORY_ENABLED, IS_LOCAL } from "@enfusion-ui/web-core";
import { initApp } from "@enfusion-ui/web-layout";
import * as FullStory from "@fullstory/browser";
import {
  DurationCookieTypes,
  ProvenanceCookieTypes,
  PurposeCookieTypes,
} from "@use-cookie-consent/core";
import EmbedCharting from "app/EmbedCharting";
import LoginSuccessPage from "app/LoginSuccess";
import { BSSO } from "bsso";
import * as Cookies from "js-cookie";
import { omit } from "lodash";
import React from "react";

import App from "./app/App";
import Bloomberg from "./app/Bloomberg";
import TestView from "./app/Test";
import * as serviceWorker from "./serviceWorker";
// import AppLogging from "./utils/logging";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

window.appVersion = process.env.REACT_APP_VERSION;

if (FULLSTORY_ENABLED) {
  FullStory.init({
    orgId: process.env.REACT_APP_FULLSTORY_ORG_ID!,
    debug: IS_LOCAL,
  });
}

if (process.env.REACT_APP_GA_TOKEN && !IS_LOCAL) {
  window.ga("create", process.env.REACT_APP_GA_TOKEN, "none");
  window.ga("send", "pageview", {
    page: window.location.pathname + window.location.search,
  });
  window.ga(
    "set",
    GoogleAnalyticsCustomFields.AppId,
    process.env.REACT_APP_ENVIRONMENT
  );
  window.ga(
    "set",
    GoogleAnalyticsCustomFields.AppVersion,
    process.env.REACT_APP_VERSION
  );
}

if (IS_LOCAL) {
  // eslint-disable-next-line
  const whyDidYouRender: Function = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, { trackAllPureComponents: false });
}

try {
  initApp(
    App,
    serviceWorker,
    (render) => {
      if (window.location.pathname.endsWith("/login/success")) {
        render(<LoginSuccessPage />);
      } else if (window.location.pathname.endsWith("/test")) {
        render(<TestView />);
      } else if (
        window.location.pathname.slice(1).split("/")[0] === "embed-charting"
      ) {
        render(<EmbedCharting />);
      } else if (
        window.location.pathname.slice(1).split("/")[0] === "bloomberg" ||
        new URLSearchParams(window.location.hash).get("#token")
      ) {
        render(<Bloomberg />);
      } else {
        return false;
      }
      return true;
    },
    (user) => {
      if (user) {
        const cleaningUser = JSON.parse(
          JSON.stringify(omit(user, ["token", "authToken", "bsso"]))
        );
        const cleanUser = Object.keys(cleaningUser).reduce(
          (acc, key) => ({
            ...acc,
            [key]: JSON.stringify(cleaningUser[key]),
          }),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {} as any
        );
        const userAttributes = {
          ...cleanUser,
          displayName: `${cleanUser.username} (${cleaningUser.flags?.processingOrgName})`,
          email: cleanUser.username || "",
          po: cleaningUser.flags?.processingOrgName || "",
        };

        // window.zESettings = {
        //   webWidget: {
        //     contactOptions: {
        //       enabled: true,
        //       contactButton: { "*": "Get in touch" },
        //     },
        //     chat: {
        //       suppress: false,
        //       connectOnPageLoad: false,
        //     },
        //     contactForm: {
        //       fields: [
        //         {
        //           id: "name",
        //           prefill: {
        //             "*": userAttributes.username.replaceAll('"', ""),
        //           },
        //         },
        //         {
        //           id: "email",
        //           prefill: {
        //             "*": userAttributes.username.replaceAll('"', ""),
        //           },
        //         },
        //       ],
        //     },
        //   },
        // };

        if (FULLSTORY_ENABLED) {
          FullStory.setUserVars(userAttributes);

          AppLogging.event(
            {
              event: AppEvent.UserTracking,
              category: AppEventCategories.Auth,
            },
            {
              userName: userAttributes.username,
              po: userAttributes.po,
            }
          );
        }

        const consent: PurposeCookieTypes &
          DurationCookieTypes &
          ProvenanceCookieTypes = Cookies.getJSON(
          "USE_COOKIE_CONSENT_STATE"
        ) || {
          necessary: true,
        };

        if (!IS_LOCAL && consent.statistics) {
          window.ga(
            "set",
            GoogleAnalyticsCustomFields.UserId,
            userAttributes.username
          );
          if (user.flags)
            window.ga(
              "set",
              GoogleAnalyticsCustomFields.PO,
              user.flags.processingOrgName
            );
        }
      }
    },
    (user) => {
      const storedBSSO = localStorage.getItem(BSSO_TOKEN_KEY);
      const bsso = new BSSO(
        storedBSSO
          ? JSON.parse(storedBSSO)
          : {
              clientId: process.env.REACT_APP_BLOOMBERG_APP,
              redirectUri: window.origin + "/bloomberg",
            }
      );
      return {
        ...user,
        bsso,
      };
    }
  );
} catch (err) {
  AppLogging.localOnly.safeError("initApp failed", err);
}
