import * as React from "react";
export const VideoPreview = ({ filePath, }) => {
    return (React.createElement("div", { style: {
            width: "100%",
            flex: "1 1 0%",
            display: "flex",
            overflow: "hidden",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid var(--input-border)",
        } },
        React.createElement("video", { style: { width: "100%", height: "auto", maxHeight: "100%" }, controls: true },
            React.createElement("source", { src: filePath, type: "video/mp4" }),
            "I'm sorry; your browser doesn't support HTML5 video in WebM with VP8/VP9 or MP4 with H.264.")));
};
