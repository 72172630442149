import { RoundedDataGrid } from "@app-components/DataGrid";
import { mockDividendsGridData } from "@enfusion-ui/trades";
import { ColDef } from "ag-grid-community";
import * as React from "react";

const colDefs: ColDef[] = [
  {
    headerName: "Selected",
    field: "selected",
  },
  {
    headerName: "Id",
    field: "id",
  },
  {
    headerName: "Instrument Id",
    field: "instrumentId",
  },
  {
    headerName: "Instrument Name",
    field: "instrumentName",
  },
  {
    headerName: "Definition Name",
    field: "definitionName",
  },
];

export const Dividends: React.FC<unknown> = () => {
  return (
    <RoundedDataGrid
      height="15rem"
      columnDefs={colDefs}
      rowData={mockDividendsGridData}
    />
  );
};
