import { useWatchList } from "@app-context/watchList/context";
import { TOAST_CONTENT } from "@enfusion-ui/core";
import {
  AppEvent,
  AppEventCategories,
  WebWatchListInfo,
} from "@enfusion-ui/types";
import {
  Button,
  ControlledTextInput,
  Modal,
  SingleColumnGridWithGap,
  useNavBarState,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  errorToast,
  styled,
  successToast,
} from "@enfusion-ui/web-core";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { useWatchListExplorer } from "../../../context/watchList/WatchListExplorerProvider";

type CreateWatchlistFormType = {
  name: string;
  description?: string;
};

const ModalContentContainer = styled.div`
  margin-top: 1rem;
`;

const CreateWatchListModal: React.FC = () => {
  const formMethods = useForm<CreateWatchlistFormType>();

  const { createWatchListModal, closeCreateWatchListModal } =
    useWatchListExplorer();
  const { openWatchListTab, saveWatchList } = useWatchList();
  const { closeNavBarTabOnMobile } = useNavBarState();

  React.useEffect(() => {
    createWatchListModal && formMethods.clearErrors();
  }, [createWatchListModal]);

  const handleAddNewModal = async () => {
    const { name, description } = formMethods.getValues();
    if (name?.length >= 2) {
      const watchListInfo: WebWatchListInfo = {
        id: 0,
        name,
        description,
        items: [],
      };
      try {
        const savedWatchListInfo = await saveWatchList(watchListInfo);
        openWatchListTab(savedWatchListInfo);
        successToast(TOAST_CONTENT.WatchLists.create.success);
        closeNavBarTabOnMobile();
        AppLogging.event(
          {
            event: AppEvent.CreateFile,
            category: AppEventCategories.WatchLists,
          },
          { name }
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        errorToast(TOAST_CONTENT.WatchLists.create.failure);
        console.error(err.message);
      } finally {
        closeCreateWatchListModal();
      }
    } else {
      formMethods.setError("name", {
        type: "minLength",
        message: "Minimum 2 character required",
      });
    }
  };

  return (
    <Modal
      isOpen={createWatchListModal}
      onClose={closeCreateWatchListModal}
      title="Create WatchList"
      allowDismissal
      content={
        <FormProvider {...formMethods}>
          <ModalContentContainer>
            <SingleColumnGridWithGap
              as="form"
              onSubmit={formMethods.handleSubmit(handleAddNewModal)}
            >
              <ControlledTextInput
                name="name"
                label="Name"
                data-e2e-id="watchlist-name"
                required
                defaultValue=""
                rules={{
                  validate: (val: string) => {
                    if (!val || val.length < 2) {
                      return "Minimum 2 character required";
                    }
                    return true;
                  },
                }}
                errors={formMethods.errors}
                autoFocus
              />
              <ControlledTextInput
                name="description"
                label="Description"
                defaultValue=""
                autoFocus
              />
              <Button primary type="submit">
                Submit
              </Button>
            </SingleColumnGridWithGap>
          </ModalContentContainer>
        </FormProvider>
      }
    />
  );
};

export default CreateWatchListModal;
