import { createSelectOptions, formatPercentage } from "@enfusion-ui/utils";
import { AccrualFrequencies, AccrualMethods, AccrualPeriods, BusinessDayConventionValues, ConditionalValues, DayCountValues, DocumentAttachmentTypes, InstructionActions, KnockInOutValues, LotReliefTypes, ReportedAmountTypes, } from "./types";
/* General Tab - Swap Details Panel */
export const knockInOutRadioOptions = createSelectOptions(KnockInOutValues);
export const conditionalRadioOptions = createSelectOptions(ConditionalValues);
export const businessDayConventionOptions = createSelectOptions(BusinessDayConventionValues);
export const dayCountOptions = createSelectOptions(DayCountValues);
/* Lot Relief Tab */
export const lotReliefRadioOptions = createSelectOptions(LotReliefTypes);
/* Documents Tab */
export const documentAttachmentTypeOptions = createSelectOptions(DocumentAttachmentTypes);
/* Accruals Tab */
export const accrualPeriodOptions = createSelectOptions(AccrualPeriods);
export const accrualFrequencyOptions = createSelectOptions(AccrualFrequencies);
export const accrualMethodOptions = createSelectOptions(AccrualMethods);
/* Instructions Tab */
export const InstructionActionOptions = createSelectOptions(InstructionActions);
/* Reported Amounts Tab */
export const ReportedAmountTypeOptions = createSelectOptions(ReportedAmountTypes);
export function formatPercentInput(num) {
    if (!num || isNaN(Number(num)))
        return "";
    return formatPercentage(Number(num) / 100);
}
export function isAutoCalculated(val) {
    return val.endsWith("AutoCalculate");
}
export function isForceImpliedInPrice(val) {
    return val.startsWith("EndBroker") || val.startsWith("SoftDollar");
}
export function entryType(entry) {
    if (entry === null || typeof entry === "undefined")
        return "";
    return entry;
}
