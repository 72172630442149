import FileMoveModal from "@app-components/modal/FileMoveModal";
import FileRenameModal from "@app-components/modal/FileRenameModal";
import { createShareAction } from "@app-utils/shareLinks";
import {
  FileExplorerEntryContextArguments,
  OBJECT_WORDING,
  TOAST_CONTENT,
} from "@enfusion-ui/core";
import { useModalState, useRefCallback } from "@enfusion-ui/hooks";
import {
  AppEvent,
  AppEventCategories,
  DashboardRoot,
  FileTreeEntry,
  NodeData,
  StorageRoot,
} from "@enfusion-ui/types";
import {
  createTestId,
  getFileExt,
  getFileName,
  replaceDoubleSlashWithSingleSlash,
  trimSlash,
} from "@enfusion-ui/utils";
import {
  CopyToClipboard,
  PositionedPortal,
  useConfirmationModal,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  errorToast,
  styled,
  useContextMenu,
  useFolderActionContext,
} from "@enfusion-ui/web-core";
import {
  faBookOpen,
  faEdit,
  faFileImport,
  faFolderPlus,
  faPlus,
  faShare,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { last } from "lodash";
import Menu, { MenuItem } from "rc-menu";
import * as React from "react";

import { ExplorerSection } from "../types";

const TEXT = {
  noSelectedNode: "No selected node",
  notPermissioned: "User not permissioned to move the selected entry",
} as const;

const ContextMenuIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
`;

export const ContextMenuIcon: React.FC<Pick<FontAwesomeIconProps, "icon">> = ({
  icon,
}) => (
  <ContextMenuIconContainer>
    <FontAwesomeIcon icon={icon} size="sm" />
  </ContextMenuIconContainer>
);

export const ContextMenuItemContainer = styled.div`
  display: flex;
  gap: var(--spacing-l);
`;

function getPathFromContextNode(
  contextMenuNode: null | { node: NodeData | null }
) {
  if (!contextMenuNode?.node) return undefined;
  const node = contextMenuNode?.node as unknown as FileTreeEntry;
  const res = replaceDoubleSlashWithSingleSlash(
    node.path.replace(new RegExp(`/?${node.root.toLowerCase()}/`), "/")
  );
  return res;
}

function getFolderPathFromContextNode(
  contextMenuNode: null | { node: NodeData | null }
) {
  if (!contextMenuNode?.node) return "/";
  const node = contextMenuNode?.node as unknown as FileTreeEntry;
  let res = replaceDoubleSlashWithSingleSlash(node.path);
  res = res.split("/").slice(0, -1).join("/");
  if (!res.startsWith("/")) res = `/${res}`;
  return res;
}

export type ExplorerContextMenuNode = {
  node: NodeData | null;
  clientY: number | null;
  clientX: number | null;
};

export type UseExplorerContextMenuParams = {
  root: DashboardRoot;
  section: ExplorerSection;
  editEnabled?: boolean;
  shareEnabled?: boolean;
  selectedKeys?: string[];
  globalAdminEnabled?: boolean;
  openCreate?: (
    root: DashboardRoot,
    path: string,
    section: StorageRoot
  ) => void;
  openCreateFolder?: (
    root: DashboardRoot,
    path: string,
    section: StorageRoot
  ) => void;
  deleteFile?: (
    root: DashboardRoot,
    path: string,
    section: StorageRoot
  ) => void;
  forceOpenTab?: (node: NodeData) => void;
};

const defaultRenderMethod = () => null;

const dataTestId = (prefix: string, id: string) =>
  createTestId(`${prefix}-${id}-menu-item`);

export function useExplorerContextMenu(
  {
    root,
    section,
    editEnabled = false,
    shareEnabled = true,
    globalAdminEnabled = false,
    selectedKeys = [],
    openCreate,
    openCreateFolder,
    deleteFile: deleteFileExplorerAction,
    forceOpenTab,
  }: UseExplorerContextMenuParams,
  renderMenuItems: (
    contextMenuNode: ExplorerContextMenuNode | null
  ) => Array<React.ReactElement> | null = defaultRenderMethod
) {
  const { wording, toastContent } = React.useMemo(
    () => ({
      wording: OBJECT_WORDING[section],
      toastContent: TOAST_CONTENT[section],
    }),
    [section]
  );

  const { deleteFile, moveFile, copyFile, renameFile } =
    useFolderActionContext();

  const {
    contextMenuOpen,
    contextMenuNode,
    handleContextMenuPlacement,
    closeContextMenu,
    openContextMenu,
  } = useContextMenu<ExplorerContextMenuNode>();

  const haveContextNode = !!contextMenuNode && !!contextMenuNode.node;
  const nodeIsDir = haveContextNode && !contextMenuNode?.node?.file;
  const nodeIsNotDir = haveContextNode && contextMenuNode?.node?.file;
  const node = contextMenuNode?.node as unknown as FileTreeEntry;
  const showEditActions =
    editEnabled && (!root.startsWith("global") || globalAdminEnabled);

  const nodeName = React.useMemo(
    () => contextMenuNode?.node?.name.replace(".json", ""),
    [contextMenuNode]
  );

  const extraContentRef = React.useRef<Array<React.ReactElement> | null>(null);

  const handleEntryContext = useRefCallback(
    ({ node, clientX, clientY }: FileExplorerEntryContextArguments) => {
      const entry = { node, clientX, clientY };
      extraContentRef.current = renderMenuItems(entry);

      const hasContent =
        (showEditActions && (!!node || !!openCreate || !!openCreateFolder)) ||
        (shareEnabled && !!node) ||
        (!!extraContentRef.current && extraContentRef.current.length > 0);

      if (hasContent) {
        AppLogging.event(
          {
            event: AppEvent.OpenContextMenu,
            category: AppEventCategories[section],
          },
          {
            path: node?.path || "",
            file: node?.file || false,
          }
        );

        openContextMenu(entry);
      }
    },
    [
      renderMenuItems,
      showEditActions,
      shareEnabled,
      openCreate,
      openCreateFolder,
      section,
    ]
  );

  const handleDelete = useRefCallback(async () => {
    if (contextMenuNode?.node) {
      await deleteFile?.(contextMenuNode.node);
      deleteFileExplorerAction?.(
        root,
        contextMenuNode?.node.path,
        section.toLowerCase() as StorageRoot
      );
    } else {
      errorToast(toastContent.delete.failure, TEXT.noSelectedNode);
      AppLogging.error(
        `${toastContent.delete.failure} | ${TEXT.noSelectedNode}`
      );
    }
  }, [contextMenuNode, toastContent, deleteFileExplorerAction]);

  const handleOpenAnotherInstance = useRefCallback(() => {
    if (contextMenuNode?.node && forceOpenTab)
      forceOpenTab(contextMenuNode.node);
    else {
      errorToast(
        toastContent.openAdditionalInstance.failure,
        TEXT.noSelectedNode
      );
      AppLogging.error(
        `${toastContent.openAdditionalInstance.failure} | ${TEXT.noSelectedNode}`
      );
    }
  }, [contextMenuNode, forceOpenTab]);

  const DeleteConfirmationModal = useConfirmationModal({
    title: `Delete ${wording.upper}`,
    submitActionTheme: "danger",
    onSubmit: handleDelete,
    renderContent: () => `Are you sure you want to delete "${nodeName}"?`,
  });

  const OpenAnotherInstanceConfirmationModal = useConfirmationModal({
    title: "Open Another Instance",
    submitActionTheme: "primary",
    onSubmit: handleOpenAnotherInstance,
    renderContent: () =>
      `Do you want to open another instance of "${nodeName}"?`,
  });

  const handleSharedFileMove = useRefCallback(
    async (targetNode: FileTreeEntry | null) => {
      if (contextMenuNode?.node && targetNode) {
        const success = await copyFile?.(
          contextMenuNode.node,
          targetNode.root.toLowerCase() as DashboardRoot,
          targetNode.path
        );
        if (success) await deleteFile?.(contextMenuNode.node);
      }
    },
    [contextMenuNode]
  );

  const MoveSharedConfirmationModal = useConfirmationModal<FileTreeEntry>({
    title: "Move Shared File",
    submitActionTheme: "danger",
    submitButtonText: "Continue",
    onSubmit: handleSharedFileMove,
    renderContent: () =>
      "This action will move the file from being shared to only being visible by you.",
  });

  const handleDeleteFolder = useRefCallback(async () => {
    if (contextMenuNode?.node) {
      await deleteFile?.(contextMenuNode.node);
      deleteFileExplorerAction?.(
        root,
        contextMenuNode?.node.path,
        section.toLowerCase() as StorageRoot
      );
    } else {
      errorToast(TOAST_CONTENT.folder.delete.failure);
      AppLogging.error(
        `${TOAST_CONTENT.folder.delete.failure} | ${TEXT.noSelectedNode}`
      );
    }
  }, [contextMenuNode, deleteFile, deleteFileExplorerAction]);

  const DeleteFolderConfirmationModal = useConfirmationModal({
    title: "Delete Folder",
    submitActionTheme: "danger",
    onSubmit: handleDeleteFolder,
    renderContent: () => `Are you sure you want to delete "${nodeName}"?`,
  });

  const handleRenameFile = useRefCallback(
    async (path: string, newName: string) => {
      const existingPath = contextMenuNode
        ? getPathFromContextNode(contextMenuNode)
        : path;

      if (typeof existingPath === "string") {
        const ext = getFileExt(last(trimSlash(existingPath).split("/"))!);
        renameFile?.(existingPath, `${newName}${ext ? `.${ext}` : ""}`, !ext);
      } else errorToast(toastContent.rename.failure, TEXT.noSelectedNode);
      renameFileModalState.closeModal();
      closeContextMenu();
    },
    [contextMenuNode, closeContextMenu, toastContent]
  );

  const renameFileModalState = useModalState({
    onClose: closeContextMenu,
  });

  const handleMoveFile = useRefCallback(
    async (selectedNode: NodeData | null) => {
      if (!contextMenuNode?.node) {
        errorToast(toastContent.move.failure, TEXT.noSelectedNode);
        return;
      }
      try {
        const targetNode = selectedNode as unknown as FileTreeEntry;
        const destinationPath = selectedNode ? targetNode.path : "";
        const sourceRoot = (
          contextMenuNode.node as unknown as FileTreeEntry
        ).root.toLowerCase() as DashboardRoot;
        const targetRoot = targetNode.root.toLowerCase() as DashboardRoot;

        if (contextMenuNode?.node && selectedNode) {
          if (sourceRoot === "global" && !globalAdminEnabled) {
            errorToast(TEXT.notPermissioned);
            return;
          }
          const node = contextMenuNode?.node;
          if (sourceRoot === targetRoot) {
            await moveFile?.(node, destinationPath);
          } else {
            switch (sourceRoot) {
              case "user": {
                const success = await copyFile?.(
                  node,
                  targetRoot,
                  destinationPath
                );
                if (success) await deleteFile?.(node);
                break;
              }
              case "shared": {
                MoveSharedConfirmationModal.openModal(targetNode);
                break;
              }
              case "global": {
                await copyFile?.(node, targetRoot, destinationPath);
                break;
              }
            }
          }
        }

        fileMoveModalState.closeModal();
      } catch (err) {
        AppLogging.error(toastContent.move.failure, err);
        errorToast(toastContent.move.failure, (err as Error).message);
      }
    },
    [contextMenuNode, globalAdminEnabled, toastContent]
  );
  const fileMoveModalState = useModalState({
    onClose: closeContextMenu,
  });

  const content = React.useMemo(() => {
    return (
      <>
        {DeleteConfirmationModal.content}
        {MoveSharedConfirmationModal.content}
        {DeleteFolderConfirmationModal.content}
        {OpenAnotherInstanceConfirmationModal.content}

        <FileMoveModal
          open={fileMoveModalState.open}
          onClose={fileMoveModalState.closeModal}
          onSubmit={handleMoveFile}
          root="all"
          filePath={getFolderPathFromContextNode(contextMenuNode) ?? ""}
        />

        <FileRenameModal
          open={renameFileModalState.open}
          onClose={renameFileModalState.closeModal}
          onSubmit={handleRenameFile}
          fileName={getFileName(contextMenuNode?.node ?? undefined)}
          filePath={contextMenuNode?.node?.path ?? ""}
        />

        <PositionedPortal
          open={contextMenuOpen}
          setPlacement={handleContextMenuPlacement}
          onClickOutside={closeContextMenu}
        >
          <Menu
            onClick={closeContextMenu}
            data-e2e-id={`${wording.lower}-menu`}
            data-testid={createTestId(`${wording.lower}-menu`)}
            selectedKeys={selectedKeys}
          >
            {forceOpenTab && nodeIsNotDir ? (
              <MenuItem
                key="force-open-file"
                data-e2e-id={`force-open-${wording.lower}-menu-item`}
                data-testid={createTestId(`force-open-${wording.lower}-menu`)}
                onClick={OpenAnotherInstanceConfirmationModal.openModal}
              >
                <ContextMenuItemContainer>
                  <ContextMenuIcon icon={faBookOpen} />
                  Open Another Instance
                </ContextMenuItemContainer>
              </MenuItem>
            ) : null}
            {showEditActions && nodeIsNotDir ? (
              <>
                {deleteFile ? (
                  <MenuItem
                    key="delete-file"
                    data-e2e-id={`delete-${wording.lower}-menu-item`}
                    data-testid={createTestId(
                      `delete-${wording.lower}-menu-item`
                    )}
                    onClick={DeleteConfirmationModal.openModal}
                  >
                    <ContextMenuItemContainer>
                      <ContextMenuIcon icon={faTrash} />
                      Delete
                    </ContextMenuItemContainer>
                  </MenuItem>
                ) : null}
                {moveFile ? (
                  <MenuItem
                    key="move-file"
                    data-e2e-id={`move-${wording.lower}-menu-item`}
                    data-testid={createTestId(
                      `move-${wording.lower}-menu-item`
                    )}
                    onClick={fileMoveModalState.openModal}
                  >
                    <ContextMenuItemContainer>
                      <ContextMenuIcon icon={faFileImport} />
                      Move
                    </ContextMenuItemContainer>
                  </MenuItem>
                ) : null}
                {renameFile ? (
                  <MenuItem
                    key="rename-file"
                    data-e2e-id={`rename-${wording.lower}-menu-item`}
                    data-testid={createTestId(
                      `rename-${wording.lower}-menu-item`
                    )}
                    onClick={renameFileModalState.openModal}
                  >
                    <ContextMenuItemContainer>
                      <ContextMenuIcon icon={faEdit} />
                      Rename
                    </ContextMenuItemContainer>
                  </MenuItem>
                ) : null}
              </>
            ) : null}
            {showEditActions && nodeIsDir ? (
              <>
                {deleteFile ? (
                  <MenuItem
                    key="delete-folder"
                    data-e2e-id="delete-folder-menu-item"
                    data-testid={createTestId("delete-folder-menu-item")}
                    onClick={DeleteFolderConfirmationModal.openModal}
                  >
                    <ContextMenuItemContainer>
                      <ContextMenuIcon icon={faTrash} />
                      Delete
                    </ContextMenuItemContainer>
                  </MenuItem>
                ) : null}
                {renameFile ? (
                  <MenuItem
                    key="rename-folder"
                    data-e2e-id="rename-folder-menu-item"
                    data-testid={createTestId("rename-folder-menu-item")}
                    onClick={renameFileModalState.openModal}
                  >
                    <ContextMenuItemContainer>
                      <ContextMenuIcon icon={faEdit} />
                      Rename
                    </ContextMenuItemContainer>
                  </MenuItem>
                ) : null}
              </>
            ) : null}
            {shareEnabled && nodeIsNotDir ? (
              <>
                <MenuItem
                  key="share-file"
                  data-e2e-id={`share-${wording.lower}-menu-item`}
                  data-testid={createTestId(`share-${wording.lower}-menu-item`)}
                  onClick={() => {}}
                >
                  <CopyToClipboard
                    component={
                      <ContextMenuItemContainer>
                        <ContextMenuIcon icon={faShare} />
                        Share
                      </ContextMenuItemContainer>
                    }
                    {...createShareAction(section)(node.name, node.path)}
                  />
                </MenuItem>
              </>
            ) : null}
            {showEditActions && openCreate ? (
              <MenuItem
                onClick={() =>
                  openCreate(
                    root,
                    getFolderPathFromContextNode(contextMenuNode) ?? "",
                    section.toLowerCase() as StorageRoot
                  )
                }
                data-e2e-id={`add-${wording.lower}-menu-item`}
                data-testid={dataTestId("add-", wording.lower)}
                key={`new-${wording.lower}-menu-item`}
              >
                <ContextMenuItemContainer>
                  <ContextMenuIcon icon={faPlus} />
                  New {wording.upper}
                </ContextMenuItemContainer>
              </MenuItem>
            ) : null}
            {showEditActions && openCreateFolder ? (
              <>
                <MenuItem
                  key="create-folder"
                  data-e2e-id="add-folder-menu-item"
                  data-testid={createTestId("add-folder-menu-item")}
                  onClick={() => {
                    const path = contextMenuNode?.node
                      ? contextMenuNode.node.file
                        ? contextMenuNode.node.path
                            .split("/")
                            .slice(1, -1)
                            .join("/")
                        : contextMenuNode.node.path
                            .split("/")
                            .slice(1)
                            .join("/")
                      : "";
                    openCreateFolder(
                      root,
                      path,
                      section.toLowerCase() as StorageRoot
                    );
                  }}
                >
                  <ContextMenuItemContainer>
                    <ContextMenuIcon icon={faFolderPlus} />
                    New Folder
                  </ContextMenuItemContainer>
                </MenuItem>
              </>
            ) : null}
            {extraContentRef.current}
          </Menu>
        </PositionedPortal>
      </>
    );
  }, [
    contextMenuOpen,
    contextMenuNode,
    DeleteConfirmationModal,
    MoveSharedConfirmationModal,
    DeleteFolderConfirmationModal,
    OpenAnotherInstanceConfirmationModal,
  ]);

  return { content, handleEntryContext };
}
