import { blobToDataURL, getBlobFromXHR, getXHRContent, statusText, } from "@enfusion-ui/web-core";
import * as React from "react";
import { Spinner } from "../../display";
import { ContentWrapper } from "./styles";
export const PDFPreview = ({ filePath, setError, }) => {
    const [dataUrl, setDataUrl] = React.useState(null);
    React.useEffect(() => {
        const readFile = async () => {
            const req = await getXHRContent(filePath);
            if (req.status >= 200 && req.status <= 299) {
                const url = await blobToDataURL(getBlobFromXHR(req, "application/pdf").blob);
                setDataUrl(url);
            }
            else {
                setError(statusText(req.status));
            }
        };
        readFile();
    }, []);
    if (dataUrl === null) {
        return (React.createElement(ContentWrapper, null,
            React.createElement(Spinner, null)));
    }
    return (React.createElement("iframe", { src: dataUrl, title: "pdf", frameBorder: 0, style: {
            height: "100%",
            width: "100%",
            border: "1px solid var(--input-border)",
        } }));
};
