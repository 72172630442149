import { DateSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledShareClassMultiselect } from "../../../components/ReportQueryComponents/ShareClassMultiselect";

const InvestmentReportSettings = () => {
  return (
    <>
      <ControlledShareClassMultiselect />
      <DateSelection name="valueDate" label="Value Date" />
    </>
  );
};

export default InvestmentReportSettings;
