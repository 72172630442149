import { WidgetType } from "@enfusion-ui/dashboards";
import { DashboardViewGridProps } from "@enfusion-ui/types";
import { styled } from "@enfusion-ui/web-core";
import { motion } from "framer-motion";
import * as React from "react";

import { Grid } from "./Grid";

const StickyContainer = styled(motion.div)`
  z-index: 2;
  position: sticky;
  top: -1px;
  background-color: var(--background-color-1);
`;

export const StickyGrid: React.FC<DashboardViewGridProps<WidgetType>> = (
  props
) => {
  const { outerWidth, height, ...rest } = props;

  return (
    <StickyContainer
      initial={{ height: 0, opacity: 0 }}
      animate={{ height, opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      style={{
        width: outerWidth,
        height: height,
      }}
    >
      <Grid {...rest} height={height} gridId="sticky" />
    </StickyContainer>
  );
};
