import { formatNumber } from "@enfusion-ui/utils";
import { ColDef } from "ag-grid-community";

export const reconciliationSnapshotColumns = () => {
  const res = [
    {
      colId: "reconciliationType",
      headerName: "Reconciliation Types",
      valueGetter: ({ data }) =>
        data ? `${data.reconciliationType} Breaks` : "-",
      cellRenderer: "agGroupCellRenderer",
    },
    {
      colId: "reportName",
      headerName: "Report Name",
      valueGetter: ({ data }) => data.reportName,
    },
    {
      colId: "breakCount",
      headerName: "Break Counts",
      valueGetter: ({ data }) =>
        isNaN(data.breakCount) ? "-" : data.breakCount,
    },
    {
      colId: "matchedPercentage",
      headerName: "Matched Percentage",
      valueGetter: ({ data }) =>
        isNaN(data.matchPercentage)
          ? "-"
          : formatNumber(data.matchPercentage, {
              style: "percent",
              fractionDigits: 1,
            }),
    },
  ] as ColDef[];

  return res;
};

export const breakDetailsModalColumns = (counterParty: string) => {
  const res = [
    {
      colId: "description",
      headerName: "Description",
      valueGetter: ({ data }) => data?.description ?? "-",
      cellClass: "cell-align-right",
      suppressHeaderMenuButton: true,
    },
    {
      colId: "enfusion",
      headerName: "Enfusion",
      valueGetter: ({ data }) => {
        return data?.isDiff
          ? data?.values?.sourceValue ?? "-"
          : data?.values?.value ?? "-";
      },
      colSpan: ({ data }) => (data?.isDiff ? 1 : 2),
      cellClass: "cell-align-center",
      suppressHeaderMenuButton: true,
    },
    {
      colId: "counterParty",
      headerName: `${counterParty} (Grouped)`,
      valueGetter: ({ data }) => data?.values?.targetValue ?? "-",
      cellClass: "cell-align-center",
      suppressHeaderMenuButton: true,
    },
  ] as ColDef[];
  return res;
};
