import { useSelectDataValue } from "@app-views/dashboards/pdf/utils";
import {
  FinancialSubTypeWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType, FINANCIAL_SUBTYPE_OPTIONS } from "@enfusion-ui/types";
import { sortBy } from "lodash";
import * as React from "react";

import PdfSelectionWidget from "../PdfSelectionWidget";

const financialSubTypeOptions = sortBy(
  FINANCIAL_SUBTYPE_OPTIONS,
  (item) => item.label
);

const PdfFinancialSubTypeWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const { defaultValue, key } = config as FinancialSubTypeWidgetConfig;
  const [inputValue, setInputValue] = React.useState<string | null>(null);

  useSelectDataValue(
    key,
    "Financial Type",
    ChannelDataType.String,
    defaultValue
  );

  React.useEffect(() => {
    if (defaultValue) {
      const selectedValues = defaultValue
        .map((value) => {
          const selectedValue = financialSubTypeOptions.find(
            ({ value: val }) => val === value
          );
          return selectedValue?.label;
        })
        .filter((value) => value);

      const formattedValue = selectedValues.join(", ");
      setInputValue(formattedValue);
    }
  }, [financialSubTypeOptions, defaultValue]);

  return (
    <PdfSelectionWidget label={key} value={inputValue} placeholder="All" />
  );
};

export default PdfFinancialSubTypeWidget;
