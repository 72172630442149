import CurrencySelect from "@app-views/reports/reportSettings/generalLedgerAccounting/glDistributionLine/CurrencySelect";
import {
  callPutsInputOptions,
  cutOptions,
  exerciseTypeOptions,
  transactionsSelectOptions,
} from "@enfusion-ui/trades";
import {
  ControlledDatePicker,
  ControlledNumericInput,
  ControlledSelect,
  PanelRow,
} from "@enfusion-ui/web-components";
import React from "react";

import { StyledFormElementHeader } from "../../../components/styledComponents";

export const DualDigitalOptionTerms: React.FC<unknown> = () => {
  return (
    <>
      <PanelRow>
        <ControlledSelect
          name="callPutsInputOptions"
          label="Call / Puts 1"
          options={callPutsInputOptions}
          required
        />
        <ControlledSelect
          name="callPutsInputOptions"
          label="Call / Puts 2"
          options={callPutsInputOptions}
          required
        />
      </PanelRow>
      <PanelRow>
        <ControlledNumericInput name="strike" label="Strike 1" required />
        <ControlledNumericInput name="strike2" label="Strike 2" required />
      </PanelRow>
      <PanelRow>
        <ControlledSelect name="cut1" label="Cut 1" options={cutOptions} />
        <ControlledSelect name="cut2" label="Cut 1" options={cutOptions} />
      </PanelRow>
      <PanelRow>
        <ControlledDatePicker name="expiry" label="Expiry" required />
        <ControlledSelect
          name="exerciseType"
          label="Exercise Type"
          options={exerciseTypeOptions}
          required
        />
      </PanelRow>
      <PanelRow>
        <ControlledDatePicker
          name="optionSettleDate"
          label="Option Settle Date"
          required
        />
        <ControlledDatePicker name="triggerDate" label="Trigger Date" />
      </PanelRow>

      <StyledFormElementHeader>Trade Details</StyledFormElementHeader>
      <PanelRow>
        <CurrencySelect name="premiumCcy" label="Premium Ccy" required />
        <CurrencySelect name="payoutCcy" label="Payout Ccy" required />
      </PanelRow>
      <PanelRow>
        <ControlledNumericInput
          name="premium"
          label="Premium"
          fractionDigits={[0, 4, 2]}
        />
        <ControlledNumericInput
          name="payout"
          label="Payout"
          fractionDigits={[0, 4, 2]}
          required
        />
      </PanelRow>
      <PanelRow>
        <ControlledSelect
          name="transaction"
          label="Transaction"
          options={transactionsSelectOptions}
          required
        />
        <ControlledNumericInput
          name="fxRate"
          label="FX Rate (Trade to Book)"
          fractionDigits={[0, 4, 2]}
        />
      </PanelRow>
    </>
  );
};
