import ControlledAccountSelect from "@app-views/reports/components/ReportQueryComponents/AccountSelect";
import {
  ControlledDatePicker,
  ControlledSelect,
  IconButton,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faGear } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";

import {
  analyticsBenchmarkOptions,
  analyticsFrequencyOptions,
  analyticsPeriodOptions,
  analyticsRollUpOptions,
  analyticsTopNOptions,
} from "./utils";

const SectionContainer = styled.div`
  padding: var(--spacing-l);
`;

const ActionBarContainer = styled.div`
  display: flex;
  justify-content: space-between;

  padding-bottom: var(--spacing);
  overflow: auto;
`;

const ActionBarLabelText = styled.span`
  display: flex;
  align-items: center;
  font-size: small;
`;

const ItemGroup = styled.div`
  display: flex;
  gap: var(--spacing);
`;

export const AnalyticsViewActionBar: React.FC = () => {
  return (
    <SectionContainer>
      <ActionBarContainer>
        <ItemGroup>
          <ControlledSelect
            inline
            name="rollUp"
            minWidth={120}
            placeholder=""
            label="Roll Up"
            isClearable={false}
            options={analyticsRollUpOptions}
            controllerStyle={{ alignItems: "center" }}
          />
          <ControlledSelect
            inline
            minWidth={120}
            placeholder=""
            name="benchmark"
            label="Benchmark"
            isClearable={false}
            options={analyticsBenchmarkOptions}
          />
          <ControlledSelect
            inline
            name="topN"
            label="Top N"
            minWidth={80}
            placeholder=""
            isClearable={false}
            options={analyticsTopNOptions}
          />
        </ItemGroup>

        <ItemGroup style={{ marginLeft: "4rem" }}>
          <ControlledDatePicker
            inline
            hideLabel
            placeholder=""
            minWidth="96px"
            name="startDate"
            controllerStyle={{ alignItems: "center", maxWidth: "96px" }}
          />
          <ActionBarLabelText>TO</ActionBarLabelText>
          <ControlledDatePicker
            inline
            hideLabel
            placeholder=""
            name="endDate"
            minWidth="96px"
            controllerStyle={{ alignItems: "center", maxWidth: "96px" }}
          />

          <ControlledSelect
            placeholder=""
            minWidth={100}
            name="timeFrame"
            options={analyticsPeriodOptions}
          />

          <ControlledSelect
            inline
            label="Freq."
            placeholder=""
            minWidth={120}
            name="frequency"
            isClearable={false}
            options={analyticsFrequencyOptions}
          />

          <ControlledAccountSelect
            inline
            name="fund"
            label="Fund"
            containerStyle={{
              display: "flex",
              alignItems: "center",
            }}
          />
          <IconButton
            size="lg"
            icon={faGear}
            title="Settings"
            onClick={() => console.log("Settings clicked")} // TODO
          />
        </ItemGroup>
      </ActionBarContainer>
    </SectionContainer>
  );
};
