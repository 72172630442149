/* eslint-disable @typescript-eslint/no-explicit-any */
import { EMPTY_ARRAY, UNKNOWN_INSTRUMENT_INFO } from "@enfusion-ui/core";
import { useOnScreen, useRefCallback } from "@enfusion-ui/hooks";
import { FinancialSubtype, InstrumentSearchResult } from "@enfusion-ui/types";
import {
  Checkbox,
  ColumnGrid,
  FormContent,
  Select,
  TextInput,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  REST_API,
  styled,
  useIsMobileDevice,
} from "@enfusion-ui/web-core";
import { faCircleNotch, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColDef,
  GridReadyEvent,
  RowDataUpdatedEvent,
  RowDoubleClickedEvent,
  RowSelectedEvent,
  ValueGetterParams,
} from "ag-grid-community";
import { GridApi } from "ag-grid-enterprise";
import { debounce } from "lodash";
import * as React from "react";
import { ActionMeta, ValueType } from "react-select";

import financialSubtypeDisplayNames from "../../../../utils/FinancialSubtypeDisplayNames";
import { RoundedDataGrid } from "../../../DataGrid";
import { InstrumentSearchBy } from "../types";
import OptionSearchResultPanel from "./OptionSearchResultPanel";

const instrumentColDefs: ColDef[] = [
  { headerName: "Ticker", field: "ticker" },
  { headerName: "Description", field: "description" },
  { headerName: "Country", field: "countryCode" },
  { headerName: "Currency", field: "currencyCode" },
  {
    headerName: "Exchange",
    valueGetter: ({ data }: ValueGetterParams) => {
      const { exchangeShortname, exchangeName } = data;
      return exchangeShortname
        ? exchangeShortname
        : exchangeName
        ? exchangeName
        : "N/A";
    },
  },
  { headerName: "BB Ticker", field: "bbyellow" },
  { headerName: "SEDOL", field: "sedol" },
  {
    headerName: "CUSIP/CINS",
    valueGetter: ({ data }: ValueGetterParams) => {
      const { cusip, cins } = data;
      return cusip ? cusip : cins;
    },
  },
  { headerName: "ISIN", field: "isin" },
  { headerName: "RIC", field: "ric" },
  { headerName: "Id", field: "id" },
  {
    headerName: "Type",
    valueGetter: ({ data }: ValueGetterParams) => {
      const { subType } = data;
      const displayName = financialSubtypeDisplayNames[subType];
      return displayName ? displayName : subType;
    },
  },
];

type InstrumentState = {
  instrumentSearchResults: InstrumentSearchResult[];
  instrumentsLoading: boolean;
};

const defaultInstrumentState: InstrumentState = {
  instrumentSearchResults: [],
  instrumentsLoading: false,
};

const loadingInstrumentState: InstrumentState = {
  instrumentSearchResults: [],
  instrumentsLoading: true,
};

type InstrumentSearchByOption = { label: string; value: InstrumentSearchBy };

const searchByOptions: InstrumentSearchByOption[] = [
  {
    label: "Market Identifier",
    value: InstrumentSearchBy.MARKET_IDENTIFIER,
  },
  { label: "Description", value: InstrumentSearchBy.DESCRIPTION },
];

const charMinsPerSearchBy = {
  [InstrumentSearchBy.DESCRIPTION]: 3,
  [InstrumentSearchBy.MARKET_IDENTIFIER]: 0,
};

const AdvancedInstrumentSearchContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const InlineGrid = styled(ColumnGrid)`
  grid-template-columns: ${({ isMobile, numColumns }) =>
    isMobile ? "1fr 1fr" : `repeat(${numColumns}, 1fr)`};
  gap: 0.75rem;
`;

export type AdvancedInstrumentSearchProps = {
  onInstrumentSelect: (instrumentId: number, description: string) => void;
  defaultInputValue?: string;
  financialSubTypes?: FinancialSubtype[];
  defaultSearchBy?: InstrumentSearchBy;
  reverseSearchWidgetLayout?: boolean;
  twoColumnCheckbox?: boolean;
  hideOptions?: boolean;
  dataGridHeight?: number;
  doubleClickSelect?: boolean;
  defaultPrimaryOnly?: boolean;
  autoFocus?: boolean;
  getExcludedSecurities?: () => number[];
};

const AdvancedInstrumentSearch: React.FC<AdvancedInstrumentSearchProps> = ({
  onInstrumentSelect,
  defaultInputValue = "",
  financialSubTypes,
  defaultSearchBy = InstrumentSearchBy.MARKET_IDENTIFIER,
  reverseSearchWidgetLayout = false,
  twoColumnCheckbox = false,
  hideOptions = false,
  doubleClickSelect = true,
  dataGridHeight,
  defaultPrimaryOnly = false,
  autoFocus = false,
  getExcludedSecurities = () => EMPTY_ARRAY,
}) => {
  const ref = React.useRef(null);
  const onScreen = useOnScreen(ref);

  const [instrumentGridApi, setInstrumentGridApi] =
    React.useState<GridApi | null>(null);
  const [searchText, setSearchText] = React.useState(defaultInputValue);
  const [activeOnly, setActiveOnly] = React.useState(true);
  const [primaryOnly, setPrimaryOnly] = React.useState(defaultPrimaryOnly);
  const [searchBy, setInstrumentSearchBy] = React.useState(defaultSearchBy);
  const [optionSearchInstrumentId, setOptionSearchInstrumentId] =
    React.useState<number | null>(null);

  const [{ instrumentSearchResults, instrumentsLoading }, setInstrumentState] =
    React.useReducer<React.Reducer<InstrumentState, InstrumentState>>(
      (_state: InstrumentState, newState: InstrumentState) => {
        return newState;
      },
      defaultInstrumentState
    );

  const excludeSecurities = getExcludedSecurities();

  const isMobile = useIsMobileDevice();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.currentTarget.value);
  };

  React.useEffect(
    () => setPrimaryOnly(defaultPrimaryOnly),
    [defaultPrimaryOnly]
  );

  React.useEffect(() => {
    if (
      !!defaultInputValue &&
      defaultInputValue !== UNKNOWN_INSTRUMENT_INFO.description
    ) {
      setSearchText(defaultInputValue);
    } else {
      setSearchText("");
    }
  }, [defaultInputValue]);

  const loadInstrumentResults = useRefCallback(
    debounce(
      async (
        searchText: string,
        searchDescription: boolean,
        activeOnly: boolean,
        primaryOnly: boolean
      ) => {
        setOptionSearchInstrumentId(null);

        if (
          searchText.trim() &&
          searchText.length >= charMinsPerSearchBy[searchBy]
        ) {
          setInstrumentState(loadingInstrumentState);

          try {
            const data = await REST_API.INSTRUMENT.FAST_SEARCH.FETCH({
              searchText,
              activeOnly,
              primaryOnly,
              financialSubTypes,
              searchDescription,
            });

            const res = data ? data : [];

            setInstrumentState({
              instrumentSearchResults: res,
              instrumentsLoading: false,
            });
          } catch (err) {
            AppLogging.error("Failed to search instruments", err);
          }
        } else {
          setInstrumentState(defaultInstrumentState);
        }
      },
      200
    ),
    [
      financialSubTypes,
      searchBy,
      loadingInstrumentState,
      defaultInstrumentState,
    ]
  );

  React.useEffect(() => {
    loadInstrumentResults(
      searchText,
      searchBy === InstrumentSearchBy.DESCRIPTION,
      activeOnly,
      primaryOnly
    );
  }, [searchText, activeOnly, primaryOnly, searchBy]);

  React.useEffect(() => {
    if (instrumentGridApi) {
      if (instrumentsLoading) {
        instrumentGridApi.showLoadingOverlay();
      } else if (!instrumentSearchResults.length) {
        instrumentGridApi.showNoRowsOverlay();
      } else {
        instrumentGridApi.hideOverlay();
      }
    }
  }, [instrumentsLoading, instrumentSearchResults]);

  const handleSelectChange = (
    option: ValueType<InstrumentSearchByOption, false>,
    { action }: ActionMeta<InstrumentSearchByOption>
  ) => {
    const { value } = option as InstrumentSearchByOption;
    if (action === "select-option")
      setInstrumentSearchBy(value as InstrumentSearchBy);
  };

  const autoSizeOnDataChange = (event: RowDataUpdatedEvent) => {
    if (onScreen) event.api.autoSizeAllColumns();
  };

  const handleInstrumentGridReady = (event: GridReadyEvent) => {
    setInstrumentGridApi(event.api);
  };

  const handleInstrumentRowSelected = useRefCallback(
    (event: RowSelectedEvent) => {
      if (event.node.isSelected()) {
        setOptionSearchInstrumentId(event.data.id);
        if (!doubleClickSelect)
          onInstrumentSelect?.(event.data.id, event.data.description);
      }
    },
    [doubleClickSelect, onInstrumentSelect, setOptionSearchInstrumentId]
  );

  const handleRowDoubleClick = useRefCallback(
    (event: RowDoubleClickedEvent) => {
      if (doubleClickSelect)
        onInstrumentSelect?.(event.data.id, event.data.description);
    },
    [doubleClickSelect, onInstrumentSelect]
  );

  return (
    <AdvancedInstrumentSearchContainer ref={ref}>
      <FormContent isMobile={isMobile}>
        <InlineGrid
          isMobile={isMobile}
          numColumns={2}
          style={{
            marginBottom: "var(--spacing-l)",
          }}
        >
          {reverseSearchWidgetLayout ? (
            <>
              <TextInput
                name="searchText"
                value={searchText}
                onChange={handleInputChange}
                icon={
                  <FontAwesomeIcon
                    icon={instrumentsLoading ? faCircleNotch : faSearch}
                    spin={instrumentsLoading}
                  />
                }
                autoFocus={autoFocus}
              />
              <Select
                label="Search by:"
                value={searchByOptions.find(
                  (option) => option.value === searchBy
                )}
                options={searchByOptions}
                onChange={handleSelectChange}
                clearable={false}
                isSearchable={false}
                inputId="adv-instr-search-by-selection-id"
              />
            </>
          ) : (
            <>
              <Select
                label="Search by:"
                value={searchByOptions.find(
                  (option) => option.value === searchBy
                )}
                options={searchByOptions}
                onChange={handleSelectChange}
                clearable={false}
                isSearchable={false}
                inputId="adv-instr-search-by-selection-id"
              />
              <TextInput
                name="searchText"
                value={searchText}
                onChange={handleInputChange}
                icon={
                  <FontAwesomeIcon
                    icon={instrumentsLoading ? faCircleNotch : faSearch}
                    spin={instrumentsLoading}
                  />
                }
              />
            </>
          )}
        </InlineGrid>
        <InlineGrid
          isMobile={isMobile}
          numColumns={twoColumnCheckbox ? 2 : 4}
          style={{
            marginBottom: "var(--spacing-l)",
          }}
        >
          <Checkbox
            checked={primaryOnly}
            label="Primary Exchange"
            onChange={setPrimaryOnly}
            labelPlacement="right"
          />
          <Checkbox
            checked={activeOnly}
            label="Active Securities"
            onChange={setActiveOnly}
            labelPlacement="right"
          />
        </InlineGrid>

        <div
          style={{
            marginBottom: "var(--spacing-l)",
          }}
        >
          <RoundedDataGrid
            height={dataGridHeight ? dataGridHeight : 124}
            rowData={instrumentSearchResults}
            columnDefs={instrumentColDefs}
            defaultColDef={{
              resizable: true,
              suppressHeaderMenuButton: true,
            }}
            rowSelection="single"
            multiSortKey="ctrl"
            suppressCellFocus
            onRowSelected={handleInstrumentRowSelected}
            onRowDoubleClicked={handleRowDoubleClick}
            onRowDataUpdated={autoSizeOnDataChange}
            onGridReady={handleInstrumentGridReady}
            overlayNoRowsTemplate="No instruments to display"
            overlayLoadingTemplate="Searching..."
            suppressContextMenu
            suppressMovableColumns
            getRowStyle={(params) => {
              if (
                excludeSecurities &&
                excludeSecurities.includes(params.data.id)
              ) {
                return {
                  cursor: "not-allowed",
                  pointerEvents: "none",
                  color: "var(--text-muted)",
                };
              }
              return undefined;
            }}
          />
        </div>
        {!hideOptions && (
          <div
            style={{
              marginBottom: "var(--spacing-l)",
            }}
          >
            <OptionSearchResultPanel
              instrumentId={optionSearchInstrumentId}
              onInstrumentSelect={onInstrumentSelect}
              isOnScreen={onScreen}
            />
          </div>
        )}
      </FormContent>
    </AdvancedInstrumentSearchContainer>
  );
};

export default AdvancedInstrumentSearch;
