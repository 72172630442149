import CCPSelect from "@app-views/oems/components/CCPSelect";
import FCMDCMSelect from "@app-views/oems/components/FCMDCMSelect";
import { useOEMSOrderForm } from "@enfusion-ui/core";
import {
  ControlledNumericInput,
  ControlledSelect,
  PanelRow,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

import CDXSideDetailsPanel from "./CDXSideDetailsPanel";

const PanelWrapper = styled.div`
  background-color: var(--background-color-0);
  border-radius: var(--radius-l);
  padding: 16px;
`;

export type CDXClearingPanelProps = {
  disabled?: boolean;
};

const CDXClearingPanel: React.FC<CDXClearingPanelProps> = ({
  disabled = false,
}: CDXClearingPanelProps) => {
  const { completed } = useOEMSOrderForm();
  const { shouldDisableInstrumentFields, parentOrderId } = useWatch({
    name: ["shouldDisableInstrumentFields", "parentOrderId"],
  });

  return (
    <PanelWrapper>
      <CDXSideDetailsPanel disabled={disabled || !!parentOrderId} />
      <PanelRow>
        <ControlledNumericInput
          id="notional-id"
          name="notional"
          label="Notional"
          disabled={completed}
          mobileBasis="50%"
          min={0}
          dataTestId="notional-input"
          enableMultiplier
        />
        <ControlledSelect
          name="clearingType"
          label="Clearing Type"
          options={[{ label: "Cleared", value: "Cleared" }]}
          placeholder="Cleared"
          disabled
        />
      </PanelRow>
      <PanelRow>
        <CCPSelect disabled={disabled || shouldDisableInstrumentFields} />
        <FCMDCMSelect disabled={disabled || shouldDisableInstrumentFields} />
      </PanelRow>
    </PanelWrapper>
  );
};

export default CDXClearingPanel;
