import * as React from "react";

export type GDPRState = {
  acceptAllCookiesModalOpen: boolean;
  settingCookiesModalOpen: boolean;
  refreshBrowserModalOpen: boolean;
  strictlyNecessaryOn: boolean;
  performanceCookiesOn: boolean;
  functionalCookiesOn: boolean;
  targetingCookiesOn: boolean;
  setAcceptAllCookiesModalOpen: (checked: boolean) => void;
  toggleAcceptAllCookiesModalOpen: VoidFunction;
  toggleSettingCookiesModalOpen: VoidFunction;
  toggleRefreshBrowserModalOpen: VoidFunction;
  toggleCustomizeCookies: VoidFunction;
  toggleStrictlyNecessaryChange: VoidFunction;
  togglePerformanceCookiesChange: VoidFunction;
  toggleFunctionalCookiesChange: VoidFunction;
  toggleTargetingCookiesChange: VoidFunction;
  acceptAll: VoidFunction;
  confirmCookies: VoidFunction;
};

export const GDPRContext = React.createContext<GDPRState | undefined>(
  undefined
);

export const useGDPR = () => {
  const context = React.useContext(GDPRContext);
  if (context === undefined) {
    throw new Error("useGDPR must be used within a GDPRProvider");
  }
  return context;
};
