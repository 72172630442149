/* eslint-disable jsx-a11y/iframe-has-title */
import { CenterContent, SVGBackground } from "@enfusion-ui/web-components";
import { ThemeProvider } from "@enfusion-ui/web-core";
import * as React from "react";

const TestPage: React.FC<unknown> = () => {
  return (
    <ThemeProvider>
      <SVGBackground>
        <CenterContent style={{ width: "100%" }}>
          {/* <iframe
            src="https://www.gertig.one/user/jonathan.gertig/notebooks/Untitled.ipynb"
            style={{ width: "40%", height: "90%", borderWidth: 0 }}
          /> */}
          <iframe
            src="https://www.gertig.one/user/jonathan.gertig/voila/render/Untitled.ipynb?token=73a1ce5b60834061b2dbc0691402d26d"
            style={{ width: "90%", height: "90%", borderWidth: 0 }}
          />
        </CenterContent>
      </SVGBackground>
    </ThemeProvider>
  );
};

export default TestPage;
