/* eslint-disable @typescript-eslint/no-explicit-any */
import { FileTreeEntry, NodeData } from "@enfusion-ui/types";
import {
  Button,
  ConfirmationModal,
  ErrorAlert,
  FolderSelector,
  IconButton,
  Modal,
  MutedText,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faFolderPlus } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";

const FileNamesWrapper = styled.pre`
  padding: var(--spacing);
  margin: var(--spacing);
  font-size: 0.75rem;
  font-weight: 700;
`;

const ContentContainer = styled.div`
  margin-top: 1rem;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorContainer = styled.div`
  margin-bottom: var(--spacing-l);
`;

const Input = styled.input`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
`;

const FileInputContainer = styled.div`
  display: flex;
  flex: 0 1 40%;
`;

const SubmitButton = styled(Button)`
  margin-top: var(--spacing-xl);
`;

const FileUploadForm: React.FC<{
  filePath?: string | undefined;
  onSubmit: (fileList: FileList, filePath: string) => Promise<any>;
}> = ({ filePath = "", onSubmit }) => {
  const [fileListValue, setFileList] = React.useState<FileList | null>();
  const [filePathValue, setFilePath] = React.useState(filePath);
  const [loading, setLoading] = React.useState(false);
  const [hasFileListRequiredError, setHasFileListRequiredError] =
    React.useState(false);
  const [assignFolderOpen, setAssignFolderOpen] = React.useState(
    filePath ? filePath !== "/" : false
  );

  const toggleAssignFolderOpen = () => setAssignFolderOpen((state) => !state);

  const submitMiddleware = async () => {
    if (!fileListValue) {
      setHasFileListRequiredError(true);
      return;
    }
    setLoading(true);

    try {
      let path = "";

      if (
        typeof filePathValue !== "undefined" &&
        filePathValue !== "/" &&
        filePathValue !== ""
      ) {
        path = filePathValue;
      }

      if (path.charAt(0) === "/") {
        path = path.substring(1);
      }

      if (path.charAt(path.length - 1) !== "/") {
        path += "/";
      }

      await onSubmit(fileListValue, path);
    } finally {
      setLoading(false);
    }
  };

  const isRootFolder = filePathValue === "/" || filePathValue === "";

  const handleFileListChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileList(e.target.files);
    setHasFileListRequiredError(false);
  };

  const handleFilePathChange = (node: NodeData | null) => {
    const destinationPath = node ? (node as unknown as FileTreeEntry).path : "";
    setFilePath(destinationPath);
  };

  return (
    <FormContainer>
      <ErrorContainer>
        {hasFileListRequiredError ? (
          <ErrorAlert error="File selection is required" />
        ) : null}
      </ErrorContainer>
      <FileInputContainer>
        <Input
          name="fileList"
          type="file"
          multiple
          onChange={handleFileListChange}
          data-testid="file-input"
        />
        <IconButton
          type="button"
          icon={faFolderPlus}
          title="Add Directory"
          onClick={toggleAssignFolderOpen}
          style={{
            color: isRootFolder ? undefined : "var(--primary)",
          }}
        />
      </FileInputContainer>
      {assignFolderOpen ? (
        <FolderSelector
          name="filePath"
          label="Directory"
          onSelect={handleFilePathChange}
          defaultValue={filePath}
          clearable
        />
      ) : isRootFolder ? (
        <MutedText style={{ fontSize: 11 }}>
          The file will be uploaded to the root folder.
        </MutedText>
      ) : null}
      <SubmitButton
        primary
        type="submit"
        busy={loading}
        onClick={submitMiddleware}
      >
        Submit
      </SubmitButton>
    </FormContainer>
  );
};

export type UploadFileModalProps = {
  open: boolean;
  filePath?: string;
  onCancel: VoidFunction;
  checkDuplicateFiles: (
    fileList: FileList,
    filePath: string
  ) => Promise<Array<string>>;
  onSubmit: (fileList: FileList, filePath: string) => Promise<void>;
};

const UploadFileModal: React.FC<UploadFileModalProps> = ({
  open,
  filePath,
  onCancel,
  checkDuplicateFiles,
  onSubmit,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadedFiles, setUploadedFiles] =
    React.useState<[FileList, string, Array<string>]>();

  const closeOverwriteModal = () => setUploadedFiles(undefined);

  const handleUploadFiles = async (fileList: FileList, filePath: string) => {
    try {
      const duplicates = await checkDuplicateFiles(fileList, filePath);
      if (duplicates.length > 0) {
        setUploadedFiles([fileList, filePath, duplicates]);
      } else {
        await onSubmit(fileList, filePath);
        onCancel();
      }
    } catch (e) {
      console.warn("upload file error", e);
    }
  };

  const handleOverwriteConfirm = async () => {
    try {
      if (uploadedFiles) {
        await onSubmit(uploadedFiles[0], uploadedFiles[1]);
      }
      closeOverwriteModal();
      onCancel();
    } catch (e) {
      console.warn("upload file error", e);
    }
  };

  const overwriteModalOpen = typeof uploadedFiles !== "undefined";

  return (
    <>
      <Modal
        isOpen={open && !overwriteModalOpen}
        onClose={onCancel}
        title="Upload File"
        allowDismissal
        content={
          <ContentContainer>
            <FileUploadForm filePath={filePath} onSubmit={handleUploadFiles} />
          </ContentContainer>
        }
      />
      <ConfirmationModal
        open={open && overwriteModalOpen}
        onSubmit={handleOverwriteConfirm}
        onCancel={closeOverwriteModal}
        title="Overwrite File(s)"
        submitActionTheme="warning"
      >
        The following file name(s) already exist.
        <FileNamesWrapper>
          {uploadedFiles ? uploadedFiles[2].join("\n") : ""}
        </FileNamesWrapper>
        Do you want to overwrite?
      </ConfirmationModal>
    </>
  );
};

export default UploadFileModal;
