import { SwitchWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { Checkbox, WidthHeight100 } from "@enfusion-ui/web-components";
import * as React from "react";

const SwitchWidget: React.FC<unknown> = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, key } = config as SwitchWidgetConfig;
  const [checked, setChecked] = React.useState(defaultValue);

  React.useEffect(() => {
    setChecked(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Checkbox value",
        type: ChannelDataType.Boolean,
      },
    ]);
  }, [key]);

  React.useEffect(() => {
    setChannelData([checked]);
  }, [checked]);

  return (
    <WidthHeight100>
      <Checkbox
        checked={checked}
        onChange={setChecked}
        label={key}
        topLabelPlaceholder
      />
    </WidthHeight100>
  );
};

export default SwitchWidget;
