import { AccrualsTab } from "./tabs/AccrualsTab";
import { DocumentsTab } from "./tabs/DocumentsTab";
import { ExternalIdentifiersTab } from "./tabs/ExternalIdentifiersTab";
import { GeneralTab } from "./tabs/GeneralTab";
import { HistoryTab } from "./tabs/HistoryTab";
import { InstructionsTab } from "./tabs/InstructionsTab";
import { LotReliefTab } from "./tabs/LotReliefTab";
import { ReportedAmountsTab } from "./tabs/ReportedAmountsTab";
import { TradeKeywordsTab } from "./tabs/TradeKeywordsTab";
import { ValuationTab } from "./tabs/ValuationTab";

export const TradeTicketTabs = [
  {
    label: "General",
    key: "general",
    contentComponent: GeneralTab,
  },
  {
    label: "Valuation",
    key: "valuation",
    contentComponent: ValuationTab,
  },
  {
    label: "Trade Keywords",
    key: "tradeKeywords",
    contentComponent: TradeKeywordsTab,
  },
  {
    label: "History",
    key: "history",
    contentComponent: HistoryTab,
  },
  {
    label: "Documents",
    key: "documents",
    contentComponent: DocumentsTab,
  },
  {
    label: "Lot Relief",
    key: "lotRelief",
    contentComponent: LotReliefTab,
  },
  {
    label: "Accruals",
    key: "accruals",
    contentComponent: AccrualsTab,
  },
  {
    label: "External Identifiers",
    key: "externalIdentifiers",
    contentComponent: ExternalIdentifiersTab,
  },
  {
    label: "Instructions",
    key: "instructions",
    contentComponent: InstructionsTab,
  },
  {
    label: "Reported Amounts",
    key: "reportedAmounts",
    contentComponent: ReportedAmountsTab,
  },
];
