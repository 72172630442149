import {
  ExecutionOrderSide,
  ExecutionOrderType,
  NodeData,
  WebWatchListInfo,
} from "@enfusion-ui/types";
import * as React from "react";

export type OrderConfig = {
  instrumentId?: number | null;
  limitPrice?: number | null;
  orderSide?: ExecutionOrderSide | null;
  orderType?: ExecutionOrderType;
};

export type WatchListContextState = {
  loading: boolean;
  error: string | null;
  watchListNodes: Array<NodeData> | null;
  getWatchListById: (watchListId: number) => Promise<WebWatchListInfo>;
  saveWatchList: (payload: WebWatchListInfo) => Promise<WebWatchListInfo>;
  deleteWatchListItems: (
    watchlistId: number,
    itemsIds: Array<number>
  ) => Promise<WebWatchListInfo>;
  deleteWatchList: (watchListId: number) => Promise<boolean>;
  openOrder: (config: OrderConfig) => void;
  openWatchListTab: (config?: WebWatchListInfo) => void;
};

export const WatchListContext = React.createContext<
  WatchListContextState | undefined
>(undefined);

export const useWatchList = () => {
  const context = React.useContext(WatchListContext);
  if (context === undefined) {
    throw new Error("useWatchList must be used within a WatchListProvider");
  }
  return context;
};
