import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  AppEvent,
  AppEventCategories,
  DashboardRoot,
} from "@enfusion-ui/types";
import { Modal, useNavBarState } from "@enfusion-ui/web-components";
import {
  AppLogging,
  errorToast,
  REST_API,
  useTabs,
} from "@enfusion-ui/web-core";
import * as React from "react";

import DocumentSaveForm from "../../../components/modal/DocumentSaveForm";
import { useDashboardsExplorer } from "../../../context/dashboards/DashboardsExplorerProvider";
import { getNewDashboardTabContent } from "../core/utils";
import DashboardTemplateSelect, {
  DashboardTemplateKey,
  dashboardTemplates,
  DEFAULT_TEMPLATE,
} from "./DashboardTemplateSelect";

export default function CreateDashboardModal() {
  const [templateKey, setTemplateKey] =
    React.useState<DashboardTemplateKey>(DEFAULT_TEMPLATE);

  const { openTab } = useTabs();
  const { closeNavBarTabOnMobile } = useNavBarState();
  const explorerChannel = useExplorerEvents("Dashboards");
  const { createDashboardModalRoot, closeCreateDashboardModal } =
    useDashboardsExplorer();

  const handleSubmit = useRefCallback(
    async (name: string, filePath: string, rootPath: DashboardRoot) => {
      const path = `/${filePath}${name}.json`;

      const result = await REST_API.DASHBOARD.STORE_DASHBOARD({
        name,
        path,
        rootPath,
        version: 0,
        config: dashboardTemplates[templateKey],
        settings: {
          theme: "dashDark",
          compactType: "vertical",
          isLayoutLocked: true,
          orientation: "portrait",
        },
        forceWrite: false,
      });
      if (result.success && result.filePath) {
        closeCreateDashboardModal();
        explorerChannel.broadcast(rootPath, ExplorerEventsAction.Refetch);
        closeNavBarTabOnMobile();
        AppLogging.event(
          {
            event: AppEvent.CreateFile,
            category: AppEventCategories.Dashboards,
          },
          { name, root: rootPath, path: result.filePath }
        );
        openTab(
          getNewDashboardTabContent({
            name,
            root: rootPath,
            path: result.filePath,
            openInEditMode: true,
          })
        );
      } else {
        errorToast("Failed to create dashboard.", "Dashboard already exits.");
        throw new Error("Dashboard with this name already exits");
      }
    },
    [explorerChannel]
  );

  return (
    <Modal
      size="small"
      isOpen={!!createDashboardModalRoot}
      onClose={closeCreateDashboardModal}
      title="Create A New Dashboard"
      dataE2EId="create-dashboard-modal"
      content={
        <DocumentSaveForm
          root={createDashboardModalRoot || undefined}
          onSubmit={handleSubmit}
          onCancel={closeCreateDashboardModal}
          submitButtonText="Create"
        >
          <DashboardTemplateSelect
            value={templateKey}
            onChange={(value) => setTemplateKey(value)}
          />
        </DocumentSaveForm>
      }
    />
  );
}
