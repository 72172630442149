import {
  ReconciliationConfig,
  ReconciliationFilterState,
} from "@enfusion-ui/types";
import { cloneDeep } from "lodash";

import { SUMMARY_REPORT_IDS } from "./utils/constants";

export type ReconciliationProviderReducerState = {
  name: string;
  version: number;
  configVersion: number;
  instanceId: string | null;
  config: ReconciliationConfig;
  loading: boolean;
  error: boolean;
  filterState: ReconciliationFilterState;
};

export type ReconciliationHydratePayload = {
  configData: ReconciliationConfig;
};

export type ReconciliationProviderReducerAction =
  | {
      type: "hydrate";
      payload: ReconciliationHydratePayload;
    }
  | {
      type: "update-config";
      payload: { configData: ReconciliationConfig };
    }
  | {
      type: "change-filter-state";
      payload: { filterState: ReconciliationFilterState };
    }
  | {
      type: "loading";
      payload: { loading: boolean };
    };

export const reconciliationProviderDefaultState: ReconciliationProviderReducerState =
  {
    name: "",
    version: 0,
    configVersion: 0,
    instanceId: null,
    config: {
      reconReports: [],
      summaryReports: [
        {
          summaryReportId: SUMMARY_REPORT_IDS.NAV,
          datasource: null,
          column: null,
        },
        {
          summaryReportId: SUMMARY_REPORT_IDS.PNL,
          datasource: null,
          column: null,
        },
        {
          summaryReportId: SUMMARY_REPORT_IDS.CASH,
          datasource: null,
          column: null,
        },
      ],
    },

    error: false,
    loading: true,
    filterState: {
      serviceAccount: {
        label: "Service Account Name",
        value: [],
        type: "multi",
      },
      currency: { label: "Currency", value: "", type: "single" },
      instrument: { label: "Instrument", value: [], type: "multi" },
    },
  };

export function reconciliationProviderReducer(
  state: ReconciliationProviderReducerState,
  action: ReconciliationProviderReducerAction
): ReconciliationProviderReducerState {
  switch (action.type) {
    case "update-config": {
      const { configData } = action.payload;
      return {
        ...state,
        config: configData,
      };
    }

    case "change-filter-state": {
      const { filterState } = action.payload;
      return { ...state, filterState: filterState };
    }
    case "loading": {
      const { loading } = action.payload;
      return { ...state, loading: loading };
    }
    case "hydrate": {
      const { configData } = action.payload;
      return {
        ...state,
        config: cloneDeep(configData),
        filterState: cloneDeep(reconciliationProviderDefaultState.filterState),
        loading: false,
      };
    }
    default:
      return state;
  }
}
