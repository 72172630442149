import ControlledAccountSelect from "@app-components/inputs/account/ControlledAccountSelect";
import ControlledBookSelect from "@app-views/reports/components/ReportQueryComponents/BookSelect";
import { DatePeriodSelection } from "@app-views/reports/components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledMarketEnvironmentSelect } from "@app-views/reports/components/ReportQueryComponents/MarketEnvironmentSelect";
import { ControlledPortfolioGroupsSelect } from "@app-views/reports/components/ReportQueryComponents/PortfolioGroupsSelect";
import { WebReportBookSelection } from "@enfusion-ui/types";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

const InputContainer = styled.div`
  min-width: 250px;
`;

const HierarchyContainer = styled.div``;

const PositionEventQuickFilter = () => {
  const { bookSelection } = useWatch({
    name: ["bookSelection"],
  });

  const defaultBookSelectionRef = React.useRef(
    bookSelection as WebReportBookSelection
  );

  return (
    <>
      <InputContainer>
        <ControlledAccountSelect label="Account(s)" />
      </InputContainer>
      <InputContainer>
        <ControlledPortfolioGroupsSelect />
      </InputContainer>
      <HierarchyContainer>
        <ControlledBookSelect
          defaultBookSelection={defaultBookSelectionRef.current}
        />
      </HierarchyContainer>
      <DatePeriodSelection name="datePeriodSelection" label="Value Date" />
      <InputContainer>
        <ControlledMarketEnvironmentSelect />
      </InputContainer>
    </>
  );
};

export default PositionEventQuickFilter;
