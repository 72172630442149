import {
  FinancialSubTypeWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { FinancialSubtype } from "@enfusion-ui/types";
import { FormSectionAccordion, TextInput } from "@enfusion-ui/web-components";
import * as React from "react";

import FinancialSubTypeSelect from "../../../../../reports/components/ReportQueryComponents/FinancialSubTypeSelect";

const FinancialSubTypeEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange =
    (key: string) => (financialSubType: FinancialSubtype[] | null) => {
      changeConfigKeyToBeApplied(key, financialSubType ?? []);
    };

  const { defaultValue = null, key = "FinancialTypes" } =
    editedConfig as FinancialSubTypeWidgetConfig;

  return (
    <FormSectionAccordion title="Financial Type Options">
      <TextInput
        label="Key"
        value={key}
        name="key"
        onChange={handleTextChange("key")}
      />
      <FinancialSubTypeSelect
        value={defaultValue}
        onChange={handleBasicChange("defaultValue")}
        selectProps={{ label: "Default Financial Types" }}
      />
    </FormSectionAccordion>
  );
};

export default FinancialSubTypeEditWidget;
