import { FileExplorerEntryContextArguments } from "@enfusion-ui/core";
import {
  AppEvent,
  AppEventCategories,
  FileTreeEntry,
  NodeData,
} from "@enfusion-ui/types";
import { getFileIcon, getFileName } from "@enfusion-ui/utils";
import {
  AccordionContext,
  CenterContent,
  ConfirmationModal,
  CopyToClipboard,
  CreateFolderModal,
  DateFilterForm,
  ErrorAlert,
  FileExplorer,
  FilterDateType,
  MenuOptions,
  PositionedPortal,
  Spinner,
  TextInput,
  useNavBarState,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  FolderActionContext,
  gaModalView,
  getBlobFromXHR,
  getStorageFileUrl,
  getXHRContent,
  styled,
  useAuth,
  useContextMenu,
  useGeneralFiles,
  useTabs,
} from "@enfusion-ui/web-core";
import {
  faBroom,
  faFileDownload,
  faFileImport,
  faFolderPlus,
  faShare,
  faTrash,
  faUpload,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, parseISO } from "date-fns";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { sortBy } from "lodash";
import Menu, { Divider, MenuItem, SubMenu } from "rc-menu";
import { MenuInfo } from "rc-menu/lib/interface";
import * as React from "react";

import { FilterButton } from "../../components/control/FilterButton";
import FileMoveModal from "../../components/modal/FileMoveModal";
import UploadFileModal from "../../components/modal/UploadFileModal";
import { TutorialType } from "../../components/Tour/utils";
import { downloadStorageFile } from "../../utils/downloadStorageFile";
import { generalFileShareLinkProps } from "../../utils/shareLinks";

export type DateFilter = {
  dateType: FilterDateType;
  dateRange: [Date | null, Date | null];
};

const GeneralFileExplorer = styled(FileExplorer)`
  flex: 1;
  overflow-x: hidden;
`;

const SearchPanel = styled.div`
  padding: 0.5rem;
  background-color: var(--background-accent);
  margin-bottom: var(--spacing);
`;

const SearchForm = styled.form`
  display: flex;
`;

const FilterIconContainer = styled.div`
  height: 38px;
  margin-left: 4px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DateFilterContent = styled.div`
  display: ${({ open }: { open: boolean }) => (open ? "block" : "none")};
`;

const getFileDate = (node: NodeData) => {
  const data = node as unknown as FileTreeEntry;
  if (!data.modifiedDateTime) return "N/A";
  const date = new Date(data.modifiedDateTime);
  const today = new Date();
  if (
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return format(date, "p");
  }
  if (
    date.getFullYear() === today.getFullYear() ||
    date.getMonth() - today.getMonth() >= 6
  ) {
    return format(date, "LLL do");
  }
  return date.toLocaleDateString();
};

type FilesSortDir = "name" | "ma" | "ca";

const filterNodesReduce =
  (searchTerm: string, dateFilter: DateFilter | null) =>
  (res: Array<NodeData>, node: NodeData) => {
    if (node.nodes && node.nodes.length) {
      const subNodes = node.nodes.reduce(
        filterNodesReduce(searchTerm, dateFilter),
        []
      );
      if (subNodes.length) {
        res.push({ ...node, nodes: subNodes });
      }
    } else if (node.name.toLocaleLowerCase().includes(searchTerm)) {
      if (dateFilter) {
        const storageFileTree = node as unknown as FileTreeEntry;

        const nodeDateTime =
          dateFilter.dateType === "ModifiedAt"
            ? storageFileTree.modifiedDateTime
            : storageFileTree.creationDateTime;

        if (nodeDateTime) {
          const [start, end] = dateFilter.dateRange;
          const formattedNodeDate = format(parseISO(nodeDateTime), "yyyyMMdd");
          if (
            (!start || formattedNodeDate >= format(start, "yyyyMMdd")) &&
            (!end || formattedNodeDate <= format(end, "yyyyMMdd"))
          ) {
            res.push(node);
          }
        }
      } else {
        res.push(node);
      }
    }

    return res;
  };

const FilesExplorer: React.FC<{
  open: boolean;
  tutorial?: TutorialType | null;
}> = React.memo(function FilesExplorer({ tutorial }) {
  const { user, isEnabled } = useAuth();
  const { openTab } = useTabs();
  const {
    files: nodes,
    error,
    loading,
    deleteFolder,
    deleteFile,
    moveFile,
    createFolder,
    retrieveNodes,
    uploadFiles,
    checkForDuplicates,
  } = useGeneralFiles();
  const { closeNavBarTabOnMobile } = useNavBarState();
  const { setMenu, setSearch, setForceSearchIcon } =
    React.useContext(AccordionContext);
  const [sortDir, setSortDir] = React.useState<FilesSortDir>("ma");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [dateFilterOpen, setDateFilterOpen] = React.useState(false);
  const [dateFilter, setDateFilter] = React.useState<DateFilter | null>(null);
  const [uploadFileOpen, setUploadFileOpen] = React.useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);
  const [filePath, setFilePath] = React.useState("");
  const [deleteFolderConfirmationOpen, setDeleteFolderConfirmationOpen] =
    React.useState(false);
  const [createFolderModalOpen, setCreateFolderModalOpen] =
    React.useState(false);
  const [moveFileModalOpen, setMoveFileModalOpen] = React.useState(false);
  const [folderPath, setFolderPath] = React.useState("");
  const [selectedNodes, setSelectedNodes] = React.useState<Array<NodeData>>([]);

  const haveSelectedNodes = selectedNodes.filter((i) => i.file).length > 1;
  const adminEnabled = isEnabled("GeneralFiles") && user?.adminUser;

  const {
    contextMenuOpen,
    contextMenuNode,
    handleContextMenuPlacement,
    closeContextMenu,
    openContextMenu,
  } = useContextMenu<{
    node: NodeData | null;
    clientY: number | null;
    clientX: number | null;
  }>();

  //#region Handle accordion action interactions
  const sortHandler =
    (sortDir: FilesSortDir) =>
    ({ domEvent }: MenuInfo) => {
      domEvent.preventDefault();
      domEvent.stopPropagation();
      AppLogging.event(
        {
          event: AppEvent.SortFiles,
          category: AppEventCategories.GeneralFiles,
        },
        { sortDir }
      );
      setSortDir(sortDir);
    };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    AppLogging.event({
      event: AppEvent.SearchFiles,
      category: AppEventCategories.GeneralFiles,
    });
    setSearchTerm(e.target.value);
  };

  const handleDateFilterIconClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setDateFilterOpen(!dateFilterOpen);
  };

  React.useEffect(() => {
    if (searchTerm.trim().length > 0 || dateFilter) {
      setForceSearchIcon(true);
    } else {
      setForceSearchIcon(false);
    }
  }, [searchTerm, dateFilter]);

  const handleSetDateFilter = (
    dateType: FilterDateType,
    dateRange: [Date | null, Date | null]
  ) => {
    if (!dateRange[0] && !dateRange[1]) {
      setDateFilter(null);
    } else {
      setDateFilter({ dateType, dateRange });
    }
  };

  const handleClearSearchTerm = () => {
    setSearchTerm("");
  };

  React.useEffect(() => {
    const adminActions: MenuOptions = adminEnabled
      ? [
          {
            key: "uploadFile",
            title: "Upload File",
            icon: faUpload,
            onClick: openUploadFile,
          },
          {
            key: "createFolder",
            title: "Create Folder",
            icon: faFolderPlus,
            onClick: openCreateFolder,
          },
          "divider",
        ]
      : [];

    const actions: MenuOptions = [
      {
        key: "sort",
        title: "Sort",
        children: [
          {
            key: "name",
            title: "Sort by Name",
            onClick: sortHandler("name"),
          },
          {
            key: "ma",
            title: "Sort by Modified At",
            onClick: sortHandler("ma"),
          },
          {
            key: "ca",
            title: "Sort by Created At",
            onClick: sortHandler("ca"),
          },
        ],
      },
    ];

    setMenu({
      menu: [...adminActions, ...actions],
      selectedKeys: [sortDir],
    });
  }, [sortDir, adminEnabled]);

  React.useEffect(() => {
    setSearch(
      <SearchPanel>
        <SearchForm>
          <TextInput
            label="Search"
            name="name"
            value={searchTerm}
            onChange={handleSearchChange}
            clearable
            onClearValue={handleClearSearchTerm}
          />
          <FilterIconContainer>
            <FilterButton
              title="Date Filter"
              onClick={handleDateFilterIconClick}
              isFiltered={dateFilter !== null}
            />
          </FilterIconContainer>
        </SearchForm>
        <DateFilterContent open={dateFilterOpen}>
          <DateFilterForm onChange={handleSetDateFilter} />
        </DateFilterContent>
      </SearchPanel>
    );
  }, [dateFilterOpen, dateFilter, searchTerm]);
  //#endregion

  //#region FileExplorer handlers
  const filteredNodes = React.useMemo(() => {
    if (nodes === null) return [];
    if (searchTerm.trim().length === 0 && !dateFilter) {
      return nodes;
    }
    return nodes.reduce(
      filterNodesReduce(searchTerm.trim().toLocaleLowerCase(), dateFilter),
      []
    ) as Array<NodeData>;
  }, [searchTerm, dateFilter, nodes]);

  const handleClick = React.useCallback(
    (node: NodeData) => {
      const data = node as unknown as FileTreeEntry;
      if (node.file) {
        const fileName: string = data.name;

        openTab({
          component: "file",
          name: fileName,
          unique: `general-${data.path}`,
          icon: getFileIcon(node),
          config: { fileName, filePath: data.path, data, root: "general" },
        });
        closeNavBarTabOnMobile();
      }
    },
    [closeNavBarTabOnMobile]
  );

  const handleSortNodes = React.useCallback(
    (nodes: Array<NodeData>): Array<NodeData> => {
      const res = sortBy(nodes, (o: FileTreeEntry) => {
        if (sortDir === "name") {
          return o.name;
        }
        if (sortDir === "ca") {
          return o.creationDateTime
            ? new Date(o.creationDateTime).valueOf()
            : Infinity;
        }
        return o.modifiedDateTime
          ? new Date(o.modifiedDateTime).valueOf()
          : Infinity;
      }) as Array<NodeData>;
      if (sortDir !== "name") {
        return res.reverse();
      }
      return res;
    },
    [sortDir]
  );
  //#endregion

  //#region Handle context menu
  const closeUploadFile = () => {
    setUploadFileOpen(false);
    closeContextMenu();
  };

  const openUploadFile = () => {
    const node = contextMenuNode?.node as unknown as FileTreeEntry;
    const path = node
      ? node.path.substring(0, node.path.lastIndexOf("/") + 1)
      : "";

    gaModalView("/general/upload-file");

    setFilePath(path);
    setUploadFileOpen(true);
    closeContextMenu();
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
    closeContextMenu();
  };

  const openDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
    closeContextMenu();
  };

  const openDeleteFolderConfirmation = () => {
    setDeleteFolderConfirmationOpen(true);
    closeContextMenu();
  };

  const closeDeleteFolderConfirmation = () => {
    setDeleteFolderConfirmationOpen(false);
    closeContextMenu();
  };

  const openFileMoveModal = () => {
    const node = contextMenuNode?.node as unknown as FileTreeEntry;
    const path = node
      ? node.path.substring(0, node.path.lastIndexOf("/") + 1)
        ? node.path.substring(0, node.path.lastIndexOf("/") + 1)
        : "/"
      : "/";

    gaModalView("/general/move-file");

    setFilePath(path);
    setMoveFileModalOpen(true);
    closeContextMenu();
  };

  const closeFileMoveModal = () => {
    setMoveFileModalOpen(false);
    closeContextMenu();
  };

  const openCreateFolder = () => {
    const node = contextMenuNode?.node as unknown as FileTreeEntry;
    const path = node
      ? node.path.substring(0, node.path.lastIndexOf("/") + 1)
      : "";

    setCreateFolderModalOpen(true);
    setFolderPath(path);
    closeContextMenu();
  };

  const closeCreateFolder = () => {
    setCreateFolderModalOpen(false);
    closeContextMenu();
  };

  const handleCreateFolder = (folderName: string, folderPath: string) => {
    createFolder(folderName, folderPath);
    closeCreateFolder();
  };

  const handleDeleteFile = () => {
    AppLogging.event(
      {
        event: AppEvent.DeleteFile,
        category: AppEventCategories.GeneralFiles,
      },
      {
        path: (contextMenuNode?.node as unknown as FileTreeEntry).path,
        file: true,
      }
    );
    closeDeleteConfirmation();
    deleteFile((contextMenuNode?.node as unknown as FileTreeEntry).path);
  };

  const handleDeleteFolder = () => {
    AppLogging.event(
      {
        event: AppEvent.DeleteFile,
        category: AppEventCategories.GeneralFiles,
      },
      {
        path: (contextMenuNode?.node as unknown as FileTreeEntry).path,
        file: false,
      }
    );
    const node = contextMenuNode?.node as unknown as FileTreeEntry;
    closeDeleteFolderConfirmation();
    deleteFolder(node.path);
  };

  const handleEntryContext = React.useCallback(
    ({ node, clientX, clientY }: FileExplorerEntryContextArguments) => {
      AppLogging.event(
        {
          event: AppEvent.OpenContextMenu,
          category: AppEventCategories.GeneralFiles,
        },
        {
          path: node?.path || "",
          file: node?.file || false,
        }
      );
      openContextMenu({ node, clientX, clientY });
    },
    []
  );

  const handleSelectionChange = React.useCallback((nodes: Array<NodeData>) => {
    setSelectedNodes(nodes);
  }, []);

  const handleZipFileDownload = React.useCallback(async () => {
    AppLogging.event(
      {
        event: AppEvent.DownloadZipFile,
        category: AppEventCategories.GeneralFiles,
      },
      {
        paths: selectedNodes.map((i) => i?.path || ""),
      }
    );

    const zip = new JSZip();
    const fileData = await Promise.all(
      selectedNodes
        .filter((i) => i.file)
        .map(async (node) => {
          const fileNode = node as unknown as FileTreeEntry;
          const xhr = await getXHRContent(
            getStorageFileUrl("general", fileNode.path)
          );
          const { blob } = getBlobFromXHR(xhr);
          return { filePath: fileNode.path, blob };
        })
    );
    for (const entry of fileData) {
      zip.file(entry.filePath, entry.blob, { createFolders: true });
    }
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "files.zip");
  }, [selectedNodes]);
  const handleClearSelectedFiles = React.useCallback(() => {
    setSelectedNodes([]);
  }, []);

  const handleFileDownload = React.useCallback(() => {
    const node = contextMenuNode?.node as unknown as FileTreeEntry;
    AppLogging.event(
      {
        event: AppEvent.DownloadFile,
        category: AppEventCategories.GeneralFiles,
      },
      {
        path: node.path,
      }
    );
    downloadStorageFile("general", node.path);
    openContextMenu();
  }, [contextMenuNode]);

  const handleShareFile = () => {
    AppLogging.event({
      event: AppEvent.CopyShareLink,
      category: AppEventCategories.GeneralFiles,
    });
    closeContextMenu();
  };
  //#endregion

  if (error) {
    return (
      <CenterContent fillHeight={false}>
        <ErrorAlert error={error} />
      </CenterContent>
    );
  }

  if (loading || nodes === null) {
    return (
      <CenterContent fillHeight={false}>
        <Spinner />
      </CenterContent>
    );
  }

  const handleFileMove = (selectedNode: NodeData | null) => {
    const node = contextMenuNode?.node as unknown as FileTreeEntry;
    const destinationPath = selectedNode
      ? (selectedNode as unknown as FileTreeEntry).path
      : "";
    moveFile(node.path, destinationPath);
    setMoveFileModalOpen(false);
  };

  const haveContextNode = !!contextMenuNode && !!contextMenuNode.node;
  const nodeIsDir = haveContextNode && !contextMenuNode?.node?.file;
  const nodeIsNotDir = haveContextNode && contextMenuNode?.node?.file;
  const node = contextMenuNode?.node as unknown as FileTreeEntry;

  return (
    <FolderActionContext.Provider value={{ createFolder, retrieveNodes }}>
      <GeneralFileExplorer
        key={sortDir}
        nodes={filteredNodes}
        onEntryContext={handleEntryContext}
        onEntryClick={handleClick}
        getFileIcon={getFileIcon}
        getFileName={getFileName}
        getFileDate={getFileDate}
        sortNodes={handleSortNodes}
        tutorial={tutorial}
        onSelectionChange={handleSelectionChange}
        retrieveNodes={retrieveNodes}
      />
      <CreateFolderModal
        open={createFolderModalOpen}
        folderPath={folderPath}
        onCancel={closeCreateFolder}
        onSubmit={handleCreateFolder}
      />
      <FileMoveModal
        open={moveFileModalOpen}
        filePath={filePath}
        onClose={closeFileMoveModal}
        onSubmit={handleFileMove}
      />
      <UploadFileModal
        open={uploadFileOpen}
        filePath={filePath}
        onCancel={closeUploadFile}
        checkDuplicateFiles={checkForDuplicates}
        onSubmit={uploadFiles}
      />
      <ConfirmationModal
        title="Delete File"
        onSubmit={handleDeleteFile}
        submitActionTheme="danger"
        open={deleteConfirmationOpen}
        onCancel={closeDeleteConfirmation}
      >
        Are you sure you want to delete {contextMenuNode?.node?.name}?
      </ConfirmationModal>
      <ConfirmationModal
        title="Delete Folder"
        onSubmit={handleDeleteFolder}
        submitActionTheme="danger"
        open={deleteFolderConfirmationOpen}
        onCancel={closeDeleteFolderConfirmation}
      >
        Are you sure you want to delete the folder {contextMenuNode?.node?.id}?
      </ConfirmationModal>
      <PositionedPortal
        open={contextMenuOpen}
        setPlacement={handleContextMenuPlacement}
        onClickOutside={closeContextMenu}
      >
        <Menu selectedKeys={[sortDir]} onClick={closeContextMenu}>
          {adminEnabled && nodeIsNotDir ? (
            <MenuItem key="delete-file" onClick={openDeleteConfirmation}>
              <FontAwesomeIcon
                icon={faTrash}
                style={{ marginRight: "var(--spacing-l)" }}
                size="sm"
              />
              Delete File
            </MenuItem>
          ) : null}
          {adminEnabled && nodeIsNotDir ? (
            <MenuItem key="move-file" onClick={openFileMoveModal}>
              <FontAwesomeIcon
                icon={faFileImport}
                style={{ marginRight: "var(--spacing-l)" }}
                size="sm"
              />
              Move File
            </MenuItem>
          ) : null}
          {adminEnabled && nodeIsDir ? (
            <MenuItem
              key="delete-folder"
              onClick={openDeleteFolderConfirmation}
            >
              <FontAwesomeIcon
                icon={faTrash}
                style={{ marginRight: "var(--spacing-l)" }}
                size="sm"
              />
              Delete Folder
            </MenuItem>
          ) : null}
          {haveContextNode && nodeIsNotDir ? (
            <>
              <MenuItem key="share-file" onClick={handleShareFile}>
                <CopyToClipboard
                  component={
                    <div>
                      <FontAwesomeIcon
                        icon={faShare}
                        style={{ marginRight: "var(--spacing-l)" }}
                        size="sm"
                      />
                      Share File
                    </div>
                  }
                  {...generalFileShareLinkProps(node.name, node.path)}
                />
              </MenuItem>
              {isEnabled("GeneralFiles") ? (
                <MenuItem key="download-file" onClick={handleFileDownload}>
                  <FontAwesomeIcon
                    icon={faFileDownload}
                    style={{ marginRight: "var(--spacing-l)" }}
                    size="sm"
                  />
                  Download File
                </MenuItem>
              ) : null}
            </>
          ) : null}
          {haveSelectedNodes ? (
            <>
              {haveContextNode ? <Divider /> : null}
              {isEnabled("GeneralFiles") ? (
                <MenuItem
                  key="download-selected-files"
                  onClick={handleZipFileDownload}
                >
                  <FontAwesomeIcon
                    icon={faFileDownload}
                    style={{ marginRight: "var(--spacing-l)" }}
                    size="sm"
                  />
                  Download Selected Files
                </MenuItem>
              ) : null}
              <MenuItem
                key="clear-selected-files"
                onClick={handleClearSelectedFiles}
              >
                <FontAwesomeIcon
                  icon={faBroom}
                  style={{ marginRight: "var(--spacing-l)" }}
                  size="sm"
                />
                Clear Selected Files
              </MenuItem>
            </>
          ) : null}
          {adminEnabled ? (
            <>
              {haveContextNode || haveSelectedNodes ? <Divider /> : null}
              <MenuItem key="upload-file" onClick={openUploadFile}>
                <FontAwesomeIcon
                  icon={faUpload}
                  style={{ marginRight: "var(--spacing-l)" }}
                  size="sm"
                />
                Upload File
              </MenuItem>
              <MenuItem key="create-folder" onClick={openCreateFolder}>
                <FontAwesomeIcon
                  icon={faFolderPlus}
                  style={{ marginRight: "var(--spacing-l)" }}
                  size="sm"
                />
                Create Folder
              </MenuItem>
            </>
          ) : null}
          {haveContextNode || haveSelectedNodes || adminEnabled ? (
            <Divider />
          ) : null}
          <SubMenu key="sort" title="Sort">
            <MenuItem key="name" onClick={sortHandler("name")}>
              By Name
            </MenuItem>
            <MenuItem key="ma" onClick={sortHandler("ma")}>
              By Modified At
            </MenuItem>
            <MenuItem key="ca" onClick={sortHandler("ca")}>
              By Created At
            </MenuItem>
          </SubMenu>
        </Menu>
      </PositionedPortal>
    </FolderActionContext.Provider>
  );
});

export default FilesExplorer;
