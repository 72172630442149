import { getSelectedValues } from "@enfusion-ui//utils";
import { AssetMeasureOptions } from "@enfusion-ui/types";
import { ControlledInputBase, MultiSelect } from "@enfusion-ui/web-components";
import * as React from "react";

export const AssetMeasureSelect = ({
  value,
  onChange,
  label = "Asset Measure",
  ...rest
}: {
  value: string[] | null;
  onChange: (result: string[] | null) => void;
  label?: string;
}) => {
  return (
    <MultiSelect
      {...rest}
      value={getSelectedValues(value || [], AssetMeasureOptions)}
      onChange={(options) => {
        const result = options?.length ? options.map((o) => o?.value) : null;
        return onChange(result);
      }}
      label={label}
      placeholder="All"
      options={AssetMeasureOptions}
      inputId="asset-measure-multi-selection-id"
    />
  );
};

export const ControlledAssetMeasureSelect: React.FC<{
  name?: string;
  label?: string;
}> = ({ name = "assetMeasures", label }) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...rest }) => (
        <AssetMeasureSelect label={label} {...rest} />
      )}
    />
  );
};
