import { WidgetComponentsDefinition } from "../../../types";
import PdfSourceIdsSelectionWidget from "./PdfSourceIdsSelectionWidget";
import SourceIdsSelectionEditWidget from "./SourceIdsSelectionEditWidget";
import SourceIdsSelectionWidget from "./SourceIdsSelectionWidget";

export const sourceIdsSelection: WidgetComponentsDefinition = {
  renderComponent: SourceIdsSelectionWidget,
  editComponent: SourceIdsSelectionEditWidget,
  renderPdfComponent: PdfSourceIdsSelectionWidget,
};
