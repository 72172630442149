import { ThemeDefinition } from "@enfusion-ui/core";
import { withTheme } from "@enfusion-ui/web-core";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { Path, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import * as React from "react";

import { pxToPtConversion } from "./utils";

const pdfStyles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    gap: pxToPtConversion(8),
  },
  centerContent: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    marginBottom: pxToPtConversion(4),
  },
  messageContainer: {
    fontFamily: "Lato",
    fontSize: pxToPtConversion(16),
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const PdfContentMessage = withTheme(
  ({
    icon,
    iconColor,
    message,
    theme,
  }: {
    icon?: IconDefinition;
    iconColor?: string;
    message?: string;
    theme: ThemeDefinition;
  }) => {
    return (
      <View style={pdfStyles.container}>
        <View style={pdfStyles.centerContent}>
          {icon && (
            <View style={[pdfStyles.iconContainer]}>
              <Svg
                width={pxToPtConversion(50)}
                height={pxToPtConversion(50)}
                viewBox={`0 0 ${icon.icon[0]} ${icon.icon[1]}`}
              >
                <Path
                  d={icon.icon[4] as string}
                  stroke={iconColor ? iconColor : theme.colors.textMuted}
                  fill={iconColor ? iconColor : theme.colors.textMuted}
                />
              </Svg>
            </View>
          )}
          {message && (
            <View
              style={[
                pdfStyles.messageContainer,
                { color: theme.colors.textMuted },
              ]}
            >
              <Text>{message}</Text>
            </View>
          )}
        </View>
      </View>
    );
  }
);

export default PdfContentMessage;
