import {
  ExplorerFolderProvider,
  ExplorerFolderProviderWrapperProps,
  getCopyFilePath,
} from "@app-context/explorer/explorerFolders/ExplorerFolderProvider";
import { DashboardDataV2 } from "@enfusion-ui/dashboards";
import { useRefCallback } from "@enfusion-ui/hooks";
import { DashboardRoot, NodeData } from "@enfusion-ui/types";
import { getFileParts } from "@enfusion-ui/utils";
import { REST_API, useAuth } from "@enfusion-ui/web-core";
import * as React from "react";

const getRoot = REST_API.DASHBOARD.GET_ROOT_NODE_FACTORY();

export const DashboardFolderProvider: React.FC<
  ExplorerFolderProviderWrapperProps
> = ({ children, root }) => {
  const { hasPerm } = useAuth();

  const copyFile = useRefCallback(
    async (node: NodeData, targetRoot: DashboardRoot, targetPath: string) => {
      try {
        const nodePath = typeof node === "string" ? node : node.path;
        const { datasources, ...rest } =
          await REST_API.DASHBOARD.DOWNLOAD<DashboardDataV2>(nodePath);

        if (targetRoot === "shared" || targetRoot === "global") {
          const userDataSource = (datasources || []).filter(
            (dataSource) => dataSource.path.split("/")[0] === "user"
          );
          if (userDataSource.length > 0) {
            throw new Error(
              `Cannot add user's datasource in ${targetRoot} dashboard.`
            );
          }
        }

        const { name } = getFileParts(node.name);
        const path = getCopyFilePath(node.name, targetPath);

        const res = await REST_API.DASHBOARD.STORE_DASHBOARD({
          ...rest,
          name,
          datasources,
          path,
          rootPath: targetRoot.toLowerCase(),
        });

        if (res.success) return true;
        throw new Error("");
      } catch (err) {
        let msg = (err as Error).message
          .replace(/save/g, "copy")
          .replace("Failed to copy dashboard", "")
          .trim();
        if ((err as Error).message?.includes("already exists")) {
          msg = "Dashboard already exists";
        }
        throw new Error(msg);
      }
    },
    []
  );

  return (
    <ExplorerFolderProvider
      root={root}
      section="Dashboards"
      getRoot={getRoot}
      hasGlobal={hasPerm("DashboardEditor")}
      createFolder={REST_API.DASHBOARD.CREATE_FOLDER}
      renameEntry={REST_API.DASHBOARD.RENAME_FOLDER}
      deleteEntry={REST_API.DASHBOARD.DELETE_ENTRY}
      moveEntry={REST_API.DASHBOARD.MOVE_ENTRY}
      copyEntry={copyFile}
    >
      {children}
    </ExplorerFolderProvider>
  );
};
