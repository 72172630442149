import { IRSOrderFormValues, sideDisplaySettings } from "@enfusion-ui/core";
import { ExecutionOrderSide } from "@enfusion-ui/types";
import { formatDateToStandardFormat } from "@enfusion-ui/utils";
import { FormPanel, PanelRow } from "@enfusion-ui/web-components";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import { GridView } from "../../../../components/display/GridView";
import { getDisplayValue } from "../../utils/getDisplayValue";
import {
  LabeledValue,
  NumericLabeledValue,
  VarColorText,
} from "../styledComponents";
import { OrderSummaryGrid } from "./styles";

const IRSOrderSummaryPanelGrid: React.FC<{
  tenor: string | null;
  orderSide: ExecutionOrderSide | null;
  notional: string | null;
  clearingType?: string | null;
  fcmdcmShortName?: string | null;
  ccpShortName?: string | null;
  startDate?: string;
  endDate?: string;
}> = ({
  tenor,
  orderSide,
  notional,
  clearingType,
  fcmdcmShortName,
  ccpShortName,
  startDate,
  endDate,
}) => {
  const settings = sideDisplaySettings(false);
  const values = (orderSide && settings[orderSide]) || {
    name: null,
    color: null,
  };

  const { name, color } = values;

  const gridData = [
    {
      fieldName: "Side",
      fieldValue: name ?? "",
      showColored: {
        onValueChanged: (value: string) => value ?? null,
        data: color,
      },
    },
    {
      fieldName: "Notional",
      fieldValue: getDisplayValue(notional, 2),
    },
    { fieldName: "Tenor", fieldValue: tenor ?? "" },
    {
      fieldName: "Clearing Type",
      fieldValue: clearingType ?? "",
    },
    { fieldName: "CCP", fieldValue: ccpShortName ?? "" },
    { fieldName: "FCM/DCM", fieldValue: fcmdcmShortName ?? "" },
    {
      fieldName: "Start Date",
      fieldValue: startDate ?? "",
    },
    {
      fieldName: "End Date",
      fieldValue: endDate ?? "",
    },
  ];

  return <GridView gridData={gridData} />;
};

export const IRSOrderSummaryPanel: React.FC<{
  title?: string;
  viewMode?: string;
}> = ({ title = "CDXOrder Summary", viewMode = "grid" }) => {
  const { watch } = useFormContext<IRSOrderFormValues>();
  const {
    orderSide,
    fixedNotional,
    tenor,
    clearingType,
    fcmdcmShortName,
    ccpShortName,
    startDate,
    endDate,
  } = watch([
    "orderSide",
    "fixedNotional",
    "tenor",
    "clearingType",
    "fcmdcmShortName",
    "ccpShortName",
    "startDate",
    "endDate",
  ]);
  const startDateDisplay = startDate
    ? formatDateToStandardFormat(new Date(startDate))
    : "";
  const endDateDisplay = endDate
    ? formatDateToStandardFormat(new Date(endDate))
    : "";
  const settings = sideDisplaySettings(false);
  const values = (orderSide && settings[orderSide]) || {
    name: null,
    color: null,
  };

  const { name, color } = values;

  if (viewMode === "grid") {
    return (
      <>
        <IRSOrderSummaryPanelGrid
          orderSide={orderSide}
          tenor={tenor}
          notional={fixedNotional}
          clearingType={clearingType}
          ccpShortName={ccpShortName}
          fcmdcmShortName={fcmdcmShortName}
          startDate={startDateDisplay}
          endDate={endDateDisplay}
        />
      </>
    );
  }

  return (
    <FormPanel title={title}>
      <PanelRow grow>
        <OrderSummaryGrid>
          <LabeledValue label="Side" title={name ?? "-"}>
            <VarColorText colorValue={color}>{name ?? "-"}</VarColorText>
          </LabeledValue>
          <NumericLabeledValue
            label="Notional"
            title={getDisplayValue(fixedNotional, 0)}
          >
            {getDisplayValue(fixedNotional, 0)}
          </NumericLabeledValue>
          <LabeledValue label="Tenor" title={tenor ?? "-"}>
            {tenor ?? "-"}
          </LabeledValue>
          <LabeledValue label="Clearing Type" title={clearingType ?? "-"}>
            {clearingType ?? "-"}
          </LabeledValue>
          <LabeledValue label="CCP" title={ccpShortName ?? "-"}>
            {ccpShortName ?? "-"}
          </LabeledValue>
          <LabeledValue label="FCM/DCM" title={fcmdcmShortName ?? "-"}>
            {fcmdcmShortName ?? "-"}
          </LabeledValue>
          <LabeledValue label="Start Date" title={startDate ?? "-"}>
            {startDate ?? "-"}
          </LabeledValue>
          <LabeledValue label="End Date" title={endDate ?? "-"}>
            {endDate ?? "-"}
          </LabeledValue>
        </OrderSummaryGrid>
      </PanelRow>
    </FormPanel>
  );
};

export default IRSOrderSummaryPanel;
