import { ReportSettingsDefinition } from "../../types";
import OrderBlotterQuickFilter from "./OrderBlotterQuickFilter";
import OrderBlotterSettings from "./OrderBlotterSettings";

export const WebOrderBlotterQuery: ReportSettingsDefinition = {
  name: "OrderBlotter",
  queryType: "WebOrderBlotterQuery",
  quickFilterComponent: OrderBlotterQuickFilter,
  settingsComponent: OrderBlotterSettings,
};
