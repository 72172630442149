import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useMount } from "react-use";

const Container = styled.div`
  overflow-y: auto;
  height: calc(var(--vh) * 100);
  overflow-x: hidden;
  width: calc(var(--vw) * 100);

  #Content {
    display: flex;
    flex-direction: column;
  }

  .contentLayout2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 900px;
  }

  .columnLayout {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .columnLayout.three-equal .cell {
    flex: 1 1 0%;
    flex-basis: 33.33%;
  }

  img.confluence-embedded-image {
    width: 100%;
    height: auto;
  }

  .columnLayout.two-left-sidebar .aside {
    flex: 1;
    flex-basis: 33.33%;
  }

  .columnLayout.two-left-sidebar .normal {
    flex: 2;
    flex-basis: 66.66%;
  }

  h2 {
    color: var(--text-normal) !important;
  }
`;

const getPageId = (parts: string[]) => {
  const pageIdx = parts.indexOf("pages");
  return parts[pageIdx + 1];
};

export const ConfluencePage: React.FC<unknown> = () => {
  const [content, setContent] = React.useState("");

  useMount(() => {
    const id = getPageId(window.location.pathname.split("/"));
    fetch(`/confluence/wiki/api/v2/pages/${id}?body-format=view`)
      .then((response) => {
        console.log(`Response: ${response.status} ${response.statusText}`);
        return response.json();
      })
      .then((res) => {
        const idx = res.body.view.value.indexOf("<base ");
        console.log(res.body.view.value.slice(idx));
        setContent(
          res.body.view.value
            .replaceAll(
              '<base href="https://enfusiontest.atlassian.net/wiki">',
              ""
            )
            .replaceAll("@aui-grid", "var(--spacing)")
            .replaceAll("@aui-text-color", "var(--text-normal)")
        );
      })
      .catch((err) => console.error(err));
  });

  return (
    <>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://d2mgsob4fppcrd.cloudfront.net/enfusiontest.atlassian.net/wiki/s/e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855-CDN/-2012557454/h/7ea7e593ef77ab80541ab90490ae8933fd4faf694558e6fe3fe44c3d699d8d5a/_/download/contextbatch/css/_super/batch.css?assetVersion=1000.0.0-9c83946abfb913400&externals=__local-default__&relative-url=true"
      />
      <Container dangerouslySetInnerHTML={{ __html: content }} />
    </>
  );
};
