/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRefCallback } from "@enfusion-ui/hooks";
import { SelectOptionsType } from "@enfusion-ui/types";
import { ICellEditorParams } from "ag-grid-community";
import * as React from "react";

import ColumnSelect from "../../../../components/inputs/ColumnSelect";
import { OrderTypeOptions } from "../../../oems/components/ElectronicLimitDetailEntry";

export const TypeCellEditor = React.memo(
  React.forwardRef<any, ICellEditorParams>(({ value, api }, ref) => {
    const [internalValue, setInternalValue] = React.useState<string | null>(
      value
    );

    React.useImperativeHandle(
      ref,
      () => {
        return {
          // the final value to send to the grid, on completion of editing
          getValue: () => {
            return internalValue;
          },

          // Gets called once before editing starts, to give editor a chance to
          // cancel the editing before it even starts.
          isCancelBeforeStart: () => false,

          // Gets called once when editing is finished (eg if Enter is pressed).
          // If you return true, then the result of the edit will be ignored.
          isCancelAfterEnd: () => false,
        };
      },
      [internalValue]
    );

    const handleChange = useRefCallback(
      (data: SelectOptionsType<string>) => {
        setInternalValue(data?.value ?? null);
        requestAnimationFrame(() => api.stopEditing());
      },
      [api]
    );

    return (
      <div
        data-testid="order-type-cell-editor"
        onBlur={() => requestAnimationFrame(() => api.stopEditing())}
      >
        <ColumnSelect
          autoFocus
          menuIsOpen
          minWidth={50}
          name="orderType"
          mobileBasis="50%"
          clearable={false}
          value={internalValue}
          onChange={handleChange}
          options={OrderTypeOptions.orderType}
        />
      </div>
    );
  })
);
