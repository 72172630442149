import { useSelectDataValue } from "@app-views/dashboards/pdf/utils";
import {
  QuoteSetIdsSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { basicInfoMap, useRestAbortableOptions } from "@enfusion-ui/hooks";
import { ChannelDataType } from "@enfusion-ui/types";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

import PdfSelectionWidget from "../PdfSelectionWidget";

const PdfQuoteSetIdsSelectionWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const { defaultValue, key } = config as QuoteSetIdsSelectionWidgetConfig;
  const [inputValue, setInputValue] = React.useState<string | null>(null);

  useSelectDataValue(
    key,
    "Quote Set IDs",
    ChannelDataType.Number,
    defaultValue
  );

  const { options: quoteSetOptions } = useRestAbortableOptions(
    REST_API.LEGALENTITY.GET_QUOTE_SETS.FETCH,
    basicInfoMap
  );

  React.useEffect(() => {
    if (defaultValue) {
      const selectedValues = defaultValue
        .map((value) => {
          const selectedValue = quoteSetOptions.find(
            ({ value: val }) => val === value
          );
          return selectedValue?.label;
        })
        .filter((value) => value);

      const formattedValue = selectedValues.join(", ");
      setInputValue(formattedValue);
    }
  }, [quoteSetOptions, defaultValue]);

  return <PdfSelectionWidget label={key} value={inputValue} />;
};

export default PdfQuoteSetIdsSelectionWidget;
