import { EMPTY_ARRAY } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { NodeData, WebAccountsDescriptor } from "@enfusion-ui/types";
import {
  ControlledInputBase,
  HierarchySelect,
} from "@enfusion-ui/web-components";
import {
  FullnessEntry,
  hierarchyTextFormatter,
  useReports,
} from "@enfusion-ui/web-core";
import { orderBy } from "lodash";
import * as React from "react";
import { FieldErrors, useFormContext } from "react-hook-form";

import { InputContainer } from "./ReportBaseForm";

function getFormValues(entries: LedgerFullnessEntry[]) {
  return entries.reduce<{
    accountIds: number[];
    glIds: number[];
    parentAccountIds: number[];
  }>(
    (res, entry) => {
      if (entry.depth === 1) {
        res.glIds = [...res.glIds, Number(entry.id)];
      } else if (entry.depth === 2) {
        res.parentAccountIds = [...res.parentAccountIds, Number(entry.id)];
      } else {
        res.accountIds = [...res.accountIds, Number(entry.id)];
      }
      return res;
    },
    { accountIds: [], glIds: [], parentAccountIds: [] }
  );
}

type LedgerFullnessEntry = FullnessEntry & { id: string };

export function getLedgerFullnessEntry(nodes: NodeData[]) {
  const selectedTopNodes = nodes?.reduce<NodeData[]>((res, entry) => {
    if (!entry.file) {
      res = res.filter((node) => !node.path.startsWith(`${entry.path}/`));
    }
    res.push(entry);
    return res;
  }, []);
  return selectedTopNodes.map((node) => ({
    name: node.depth === 0 ? "All" : node.name,
    depth: node.depth ?? 0,
    id: node.id,
  }));
}

const defaultTextFormatter = (nodes?: NodeData[] | null) =>
  hierarchyTextFormatter(nodes ?? [], 4);

type LedgerSelectProps = {
  values?: NodeData[];
  selectedIds?: string[];
  onChange: (values: NodeData[] | null) => void;
  label?: string;
  name?: string;
  errors?: FieldErrors;
  required?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  multiSelect?: boolean;
  checkSelections?: boolean;
  textFormatter?: (nodes?: NodeData[] | null) => string;
};

const LedgerHierarchySelect: React.FC<LedgerSelectProps> = ({
  onChange,
  values = EMPTY_ARRAY,
  label,
  selectedIds = EMPTY_ARRAY,
  textFormatter,
  ...rest
}) => {
  const { glNodes: nodes } = useReports();

  return (
    <>
      <HierarchySelect
        {...rest}
        value={values}
        defaultValues={selectedIds}
        onChange={onChange}
        autoFocus={false}
        textFormatter={textFormatter ?? defaultTextFormatter}
        label={label}
        options={nodes}
        loading={nodes?.length === 0 ? true : false}
        clearable
      />
    </>
  );
};

export const ControlledLedgerHierarchySelect: React.FC<{
  name: string;
  label?: string;
  defaultValue?: WebAccountsDescriptor;
}> = ({ name, label, defaultValue }) => {
  const { setValue } = useFormContext();

  const fullnessEntries = React.useRef<LedgerFullnessEntry[]>([]);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

  React.useEffect(() => {
    const ids =
      [
        ...(defaultValue?.accountDefinitionIds ?? []),
        ...(defaultValue?.generalLedgerIds ?? []),
        ...(defaultValue?.parentAccountDefinitionIds ?? []),
      ].map((id) => `${id}`) ?? [];
    if (ids?.length) setSelectedIds(ids);
  }, [defaultValue]);

  const handleOnChange = useRefCallback(
    (nodeData: NodeData[] | null) => {
      if (nodeData === null) {
        setSelectedIds([]);
        setValue(
          name,
          {
            ...defaultValue,
            generalLedgerIds: [],
            parentAccountDefinitionIds: [],
            accountDefinitionIds: [],
          },
          { shouldDirty: true }
        );
        return;
      } else {
        const sortedNodes =
          ((nodeData?.sort(
            (a, b) => (b.depth ?? 0) - (a.depth ?? 0)
          ) as Array<NodeData>) ||
            []) ??
          [];
        fullnessEntries.current = getLedgerFullnessEntry(sortedNodes);

        let holdId: {
          accountIds: number[];
          glIds: number[];
          parentAccountIds: number[];
        } = { accountIds: [], glIds: [], parentAccountIds: [] };

        if (fullnessEntries.current?.some((entry) => entry.depth === 0)) {
          //Root level selected - select all the gls
          const rootNode = nodeData?.find((node) => node.depth === 0);
          holdId.glIds =
            rootNode?.nodes?.map((entry) => Number(entry.id)) ?? [];
        } else {
          holdId = getFormValues(fullnessEntries.current);
        }

        setValue(
          name,
          {
            ...defaultValue,
            generalLedgerIds: [...new Set(holdId?.glIds)],
            parentAccountDefinitionIds: [...new Set(holdId?.parentAccountIds)],
            accountDefinitionIds: [...new Set(holdId?.accountIds)],
          },
          { shouldDirty: true }
        );
      }
    },
    [defaultValue]
  );

  const textFormatter = useRefCallback(
    (nodes?: NodeData[] | null) => {
      if (!nodes?.length) {
        return "";
      }
      return orderBy(fullnessEntries.current, ["depth"], ["asc"])
        .map((i) => i.name)
        .join(", ");
    },
    [fullnessEntries]
  );

  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...rest }) => (
        <InputContainer>
          <LedgerHierarchySelect
            {...rest}
            label={label}
            selectedIds={selectedIds}
            onChange={handleOnChange}
            textFormatter={textFormatter}
            checkSelections
          />
        </InputContainer>
      )}
    />
  );
};

export default LedgerHierarchySelect;
