import { WidgetComponentsDefinition } from "../../../types";
import CalculationTypeSelectionEditWidget from "./CalculationTypeSelectionEditWidget";
import CalculationTypeSelectionWidget from "./CalculationTypeSelectionWidget";
import PdfCalculationTypeSelectionWidget from "./PdfCalculationTypeSelectionWidget";

export const calculationTypeSelection: WidgetComponentsDefinition = {
  renderComponent: CalculationTypeSelectionWidget,
  editComponent: CalculationTypeSelectionEditWidget,
  renderPdfComponent: PdfCalculationTypeSelectionWidget,
};
