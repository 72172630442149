import { BSSO_REDIRECT_KEY, BSSO_TOKEN_KEY } from "@enfusion-ui/core";
import { useUserAgent } from "@enfusion-ui/hooks";
import {
  Button,
  CenterContent,
  ErrorPageContent,
  Spinner,
  SVGBackground,
} from "@enfusion-ui/web-components";
import {
  errorToast,
  styled,
  ThemeProvider,
  useIsMobileDevice,
} from "@enfusion-ui/web-core";
import { faMobile } from "@fortawesome/pro-regular-svg-icons";
import { BSSO } from "bsso";
import * as React from "react";
import { ToastContainer } from "react-toastify";

const SpacedButton = styled(Button)`
  margin-top: var(--spacing-l);
`;

const storedBSSO = localStorage.getItem(BSSO_REDIRECT_KEY);
const bsso = new BSSO(
  storedBSSO
    ? JSON.parse(storedBSSO)
    : {
        clientId: process.env.REACT_APP_BLOOMBERG_APP,
        redirectUri: window.origin + "/bloomberg",
      }
);

function performCheckURI() {
  const tokenFromRedirectUrl = new URLSearchParams(window.location.hash).get(
    "#token"
  );

  if (tokenFromRedirectUrl) {
    // if we have #token, we are ready for redirect
    const redirectUrl = `enfusion://bloomberg#token=${tokenFromRedirectUrl}`;
    console.log("redirect", redirectUrl);
    window.location.href = redirectUrl;
  } else {
    const code = new URLSearchParams(window.location.search).get("code");

    if (code) {
      // if we have #token, we are ready for redirect
      const redirectUrl = `enfusion://bloomberg?code=${code}`;
      console.log("redirect", redirectUrl);
      window.location.href = redirectUrl;
    } else {
      console.info("redirect has nothing to pass");
      errorToast("Failed to get Bloomberg token or code", { autoClose: false });
    }
  }
}

async function performCheck() {
  const tokenFromRedirectUrl = new URLSearchParams(window.location.hash).get(
    "#token"
  );

  if (tokenFromRedirectUrl) {
    // if we have #token, we are ready for redirect
    const redirectUrl = await bsso.getRedirectURL(tokenFromRedirectUrl);
    console.log("redirect", redirectUrl);
    localStorage.setItem(BSSO_REDIRECT_KEY, JSON.stringify(bsso));
    window.location.replace(redirectUrl);
  } else {
    const code = new URLSearchParams(window.location.search).get("code");
    window.localStorage.removeItem(BSSO_REDIRECT_KEY);

    if (code) {
      // if we have the code, we are ready to continue getting token for authorization
      try {
        const activeToken = await bsso.getToken(code);
        console.log("token", activeToken);
        if (activeToken) {
          window.localStorage.setItem(
            BSSO_TOKEN_KEY,
            JSON.stringify(bsso.toJSON())
          );
          window.location.href = "/";
        } else {
          console.info("No active token");
          errorToast("Failed to get Bloomberg token or code", {
            autoClose: false,
          });
        }
      } catch (err) {
        console.error("bloomberg token error", err);
        errorToast("Failed to get Bloomberg token or code", {
          autoClose: false,
        });
      }
    } else {
      console.info("No code or token");
      errorToast("Failed to get Bloomberg token or code", { autoClose: false });
    }
  }
}

const Bloomberg: React.FC<unknown> = () => {
  const isMobile = useIsMobileDevice();
  const agent = useUserAgent();
  const isNonAndroidChrome = React.useMemo(
    () =>
      agent.features.mobile &&
      ["ios", "osx"].includes(agent.os.name) &&
      ["chrome"].includes(agent.browser.name),
    [agent]
  );
  const [check, setCheck] = React.useState(!isNonAndroidChrome);

  React.useEffect(() => {
    if (check) performCheck();
  }, [check]);

  return (
    <ThemeProvider>
      <SVGBackground>
        <CenterContent>
          {isNonAndroidChrome && !check ? (
            <ErrorPageContent
              icon={faMobile}
              message="Would you like to open this view in the mobile app?"
            >
              <SpacedButton
                style={{ marginTop: "var(--spacing-xxl)" }}
                theme="primary"
                onClick={performCheckURI}
              >
                Continue to App
              </SpacedButton>
              <SpacedButton onClick={() => setCheck(true)}>
                Stay in Browser
              </SpacedButton>
            </ErrorPageContent>
          ) : (
            <Spinner />
          )}
        </CenterContent>
      </SVGBackground>
      <ToastContainer
        autoClose={5000}
        position={isMobile ? "bottom-right" : "top-right"}
        hideProgressBar
        pauseOnHover
        closeOnClick={false}
        draggable
      />
    </ThemeProvider>
  );
};

export default Bloomberg;
