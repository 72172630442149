import { useTradeTicket } from "@enfusion-ui/trades";
import * as React from "react";

import { SwapDetailsDividendSwap } from "./SwapDetailsDividendSwap";
import { SwapDetailsVarSwap } from "./SwapDetailsVarSwap";

export const SwapDetailsPanel: React.FC = () => {
  const { isVarSwap, isDividendSwap } = useTradeTicket();

  if (isVarSwap) return <SwapDetailsVarSwap />;
  if (isDividendSwap) return <SwapDetailsDividendSwap />;
  return null;
};
