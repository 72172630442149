import { TOAST_CONTENT } from "@enfusion-ui/core";
import { ComplianceState } from "@enfusion-ui/types";
import { ConfirmationModal, IconButton } from "@enfusion-ui/web-components";
import { errorToast, styled, successToast } from "@enfusion-ui/web-core";
import { faBroadcastTower, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { ICellRendererParams } from "ag-grid-enterprise";
import * as React from "react";

import { cancelOrder, performTransmit } from "../../../utils/actions";

const ActionButtonContainer = styled.div`
  display: flex;
`;

const TransmitCancelCellRenderer: React.FC<ICellRendererParams> = React.memo(
  ({ data }) => {
    const [transmitConfirmationOpen, setTransmitConfirmationOpen] =
      React.useState<boolean>(false);

    const [cancelConfirmationOpen, setCancelConfirmationOpen] =
      React.useState<boolean>(false);

    if (!data) {
      return null;
    }

    const {
      transmittable,
      cancellable,
      orderId,
      columnValues: { Compliance },
    } = data;

    const handleTransmitSubmit = async () => {
      setTransmitConfirmationOpen(false);
      try {
        await performTransmit(
          orderId,
          (Compliance?.complianceState as ComplianceState) !== "Failed"
        );

        successToast(TOAST_CONTENT.oems.transmit.success);
      } catch (err) {
        console.error("Error while transmitting the order", err);
        errorToast(TOAST_CONTENT.oems.transmit.failure, (err as Error).message);
      }
    };

    const handleCancelSubmit = async () => {
      setCancelConfirmationOpen(false);

      try {
        await cancelOrder(orderId);

        successToast(`Order(${orderId}) successfully cancelled.`);
      } catch (err) {
        console.error(`Error while canceling the order(${orderId}).`, err);
        errorToast(
          `Order(${orderId}) failed to cancel.`,
          (err as Error).message
        );
      }
    };

    return (
      <ActionButtonContainer>
        <IconButton
          icon={faBroadcastTower}
          onClick={() => setTransmitConfirmationOpen(true)}
          disabled={!transmittable}
          color={!transmittable ? "var(--background-accent)" : "var(--primary)"}
          title="Transmit"
        />
        <span>T</span>
        <IconButton
          icon={faTimes}
          onClick={() => setCancelConfirmationOpen(true)}
          disabled={!cancellable}
          title="Cancel"
          color={!cancellable ? "var(--background-accent)" : "var(--danger)"}
        />
        <span>C</span>

        <ConfirmationModal
          title="Transmit Order"
          onSubmit={handleTransmitSubmit}
          submitActionTheme="warning"
          open={transmitConfirmationOpen}
          onCancel={() => setTransmitConfirmationOpen(false)}
        >
          Are you sure you want to transmit the order?
        </ConfirmationModal>

        <ConfirmationModal
          title="Cancel Order"
          onSubmit={handleCancelSubmit}
          submitActionTheme="danger"
          open={cancelConfirmationOpen}
          onCancel={() => setCancelConfirmationOpen(false)}
        >
          Are you sure you want to cancel the order?
        </ConfirmationModal>
      </ActionButtonContainer>
    );
  }
);

TransmitCancelCellRenderer.displayName = "TransmitCancelCellRenderer";
export default TransmitCancelCellRenderer;
