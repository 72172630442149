import { AdvancedSearchBtn } from "@app-components/inputs/components/AdvancedSearchButton";
import { AdvancedSearchBtnWrapper } from "@app-components/inputs/styledComponents";
import { useWidget } from "@enfusion-ui/dashboards";
import { ColumnGrid, FormElement, Select } from "@enfusion-ui/web-components";
import { faSlidersH } from "@fortawesome/pro-solid-svg-icons";

export const InstrumentSelectMobile: React.FC<unknown> = () => {
  const {
    config: { showAdvancedSearch = true },
  } = useWidget();
  return (
    <FormElement>
      <Select disabled placeholder="Unknown" label="Instrument" />
      {showAdvancedSearch ? (
        <AdvancedSearchBtnWrapper style={{ paddingLeft: "4px" }}>
          <AdvancedSearchBtn
            style={{ marginTop: "20px" }}
            icon={faSlidersH}
            iconRotation={90}
          />
        </AdvancedSearchBtnWrapper>
      ) : null}
    </FormElement>
  );
};

export const InstrumentPanelWidgetMobile = () => {
  const {
    config: { showCurrentPosition = true },
  } = useWidget();

  return (
    <ColumnGrid isMobile numColumns={1}>
      <InstrumentSelectMobile />
      {showCurrentPosition ? (
        <FormElement>
          <div style={{ padding: "8px 1px" }}>
            <strong>Position:</strong> 0
          </div>
        </FormElement>
      ) : null}
    </ColumnGrid>
  );
};
