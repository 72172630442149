import { useRefCallback } from "@enfusion-ui/hooks";
import { createTestId } from "@enfusion-ui/utils";
import { IconButton } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { IRowNode } from "ag-grid-community";
import * as React from "react";

const StyledIconButton = styled(IconButton)`
  width: 100%;
  height: 100%;
`;

export const PencilIconButtonCellRenderer: React.FC<{
  node: IRowNode;
  onClick: (node: IRowNode) => void;
  dataTestId?: string;
}> = React.memo(({ node, onClick, dataTestId }) => {
  const handleClick = useRefCallback(() => onClick(node), [node]);

  return (
    <StyledIconButton
      title="Modify"
      icon={faPencil}
      onClick={handleClick}
      data-testid={createTestId(dataTestId ?? "pencil-icon")}
    />
  );
});
