import { useAnalyticsContext } from "@app-context/analytics/context";
import { PanelItemBase } from "@app-views/analytics/components/styledComponents";
import { ThemeDefinition } from "@enfusion-ui/core";
import { formatCurrency, formatPercentage, hexToRgb } from "@enfusion-ui/utils";
import { styled, useTheme } from "@enfusion-ui/web-core";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

import { SparkLineBlock } from "./SparkLineBlock";

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: auto;
  gap: var(--spacing);
`;

const RowSection = styled.div<{ flex?: number; count?: number }>`
  width: 100%;
  display: grid;
  gap: var(--spacing);
  flex: ${({ flex = 1 }) => flex};
  grid-template-columns: repeat(${({ count = 2 }) => count}, 1fr);
`;

const ValueHighlightContainer = styled(PanelItemBase)`
  min-width: 8.5rem;
  height: 90px;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-l);
  gap: var(--spacing);
`;

const ValueHighlightLabel = styled.div`
  flex: 2;
  font-size: 12px;
  font-weight: 500;
  color: var(--text-normal);

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

const ValueHighlightContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ValueHighlightContentWrapper = styled.div`
  flex: 3;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ValueHighlightChange = styled.div<{ negative: boolean }>`
  font-size: 24px;
  margin-right: var(--spacing);
  color: var(--${({ negative }) => (negative ? "danger" : "success")});
`;

const ValueHighlightPercentChange = styled.div<{
  negative: boolean;
  theme: ThemeDefinition;
}>`
  line-height: 16px;
  display: flex;
  font-weight: 700;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  color: var(--text-normal);
  padding-left: var(--spacing-s);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  border-radius: 25px;
  background-color: ${({ theme, negative }) =>
    hexToRgb(theme.colors[negative ? "danger" : "success"], 0.5)};
  border: 1px solid
    var(--${({ negative }) => (negative ? "danger" : "success")});
`;

const ValueHighlight: React.FC<{
  label: string;
  value: number;
  change?: number | null;
  currency?: string | null;
}> = ({ label, value, change, currency }) => {
  const { theme } = useTheme();
  const negative = value < 0;

  return (
    <ValueHighlightContainer>
      <ValueHighlightLabel>{label}</ValueHighlightLabel>
      <ValueHighlightContentWrapper>
        <ValueHighlightContent>
          <ValueHighlightChange negative={negative}>
            {formatCurrency(value, currency ?? "USD", {
              fractionDigits: 0,
              formatNegative: false,
              reduce: true,
            })}
          </ValueHighlightChange>
          {typeof change === "number" && (
            <ValueHighlightPercentChange theme={theme} negative={negative}>
              {formatPercentage(Math.abs(change), 0, false, undefined, true)}
              <FontAwesomeIcon
                icon={negative ? faCaretDown : faCaretUp}
                style={{
                  marginLeft: "var(--spacing-s)",
                }}
              />
            </ValueHighlightPercentChange>
          )}
        </ValueHighlightContent>
      </ValueHighlightContentWrapper>
    </ValueHighlightContainer>
  );
};

export const AnalyticsPerformancePanelTopRow: React.FC = () => {
  const { data } = useAnalyticsContext();

  if (!data) return null;

  return (
    <RowContainer>
      <RowSection flex={4} count={3}>
        <ValueHighlight
          label="Year-To-Date"
          value={data?.performance.ytd.value}
          change={data?.performance.ytd.change}
          currency={data?.performance.ytd.currency}
        />
        <ValueHighlight
          label="Month-To-Date"
          value={data?.performance.mtd.value}
          change={data?.performance.mtd.change}
          currency={data?.performance.mtd.currency}
        />
        <ValueHighlight
          label="Day-To-Date"
          value={data?.performance.dtd.value}
          change={data?.performance.dtd.change}
          currency={data?.performance.dtd.currency}
        />
      </RowSection>
      <RowSection flex={6}>
        <SparkLineBlock
          label="NAV"
          value={data?.performance.nav.value}
          change={data?.performance.nav.change}
          currency={data?.performance.nav.currency}
          data={data?.performance.nav.data}
        />
        <SparkLineBlock
          label="POSITIONS"
          value={data?.performance.positions.value}
          change={data?.performance.positions.change}
          currency={data?.performance.positions.currency}
          data={data?.performance.positions.data}
        />
      </RowSection>
    </RowContainer>
  );
};
