import { useCookieConsent } from "@use-cookie-consent/core";
import * as React from "react";

import { GDPRContext } from "./context";

const GDPRProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { consent, acceptCookies } = useCookieConsent();

  const [acceptAllCookiesModalOpen, setAcceptAllCookiesModalOpen] =
    React.useState(false);
  const [settingCookiesModalOpen, setSettingCookiesModalOpen] =
    React.useState(false);
  const [refreshBrowserModalOpen, setRefreshBrowserModalOpen] =
    React.useState(false);

  const [strictlyNecessaryOn, setStrictlyNecessaryOn] = React.useState(
    consent.necessary || false
  );
  const [performanceCookiesOn, setPerformanceCookiesOn] = React.useState(
    consent.statistics || false
  );
  const [functionalCookiesOn, setFunctionalCookiesOn] = React.useState(
    consent.preferences || false
  );
  const [targetingCookiesOn, setTargetingCookiesOn] = React.useState(
    consent.marketing || false
  );

  const toggleAcceptAllCookiesModalOpen = () =>
    setAcceptAllCookiesModalOpen((state) => !state);

  const toggleSettingCookiesModalOpen = () =>
    setSettingCookiesModalOpen((state) => !state);

  const toggleRefreshBrowserModalOpen = () =>
    setRefreshBrowserModalOpen((state) => !state);

  const toggleCustomizeCookies = () => {
    setAcceptAllCookiesModalOpen(false);
    setSettingCookiesModalOpen(true);
  };

  const toggleStrictlyNecessaryChange = () => {
    setStrictlyNecessaryOn((state) => !state);
  };

  const togglePerformanceCookiesChange = () => {
    setPerformanceCookiesOn((state) => !state);
  };

  const toggleFunctionalCookiesChange = () => {
    setFunctionalCookiesOn((state) => !state);
  };

  const toggleTargetingCookiesChange = () => {
    setTargetingCookiesOn((state) => !state);
  };

  const acceptAll = () => {
    acceptCookies({
      necessary: true,
      statistics: true,
      preferences: true,
      marketing: true,
    });
    setStrictlyNecessaryOn(true);
    setPerformanceCookiesOn(true);
    setFunctionalCookiesOn(true);
    setTargetingCookiesOn(true);
    setAcceptAllCookiesModalOpen(false);
    setSettingCookiesModalOpen(false);
    setRefreshBrowserModalOpen(true);
  };

  const confirmCookies = () => {
    acceptCookies({
      necessary: strictlyNecessaryOn,
      statistics: performanceCookiesOn,
      preferences: functionalCookiesOn,
      marketing: targetingCookiesOn,
    });

    setAcceptAllCookiesModalOpen(false);
    setSettingCookiesModalOpen(false);
    setRefreshBrowserModalOpen(true);
  };

  return (
    <GDPRContext.Provider
      value={{
        acceptAllCookiesModalOpen,
        settingCookiesModalOpen,
        refreshBrowserModalOpen,
        strictlyNecessaryOn,
        performanceCookiesOn,
        functionalCookiesOn,
        targetingCookiesOn,
        setAcceptAllCookiesModalOpen,
        toggleAcceptAllCookiesModalOpen,
        toggleSettingCookiesModalOpen,
        toggleRefreshBrowserModalOpen,
        toggleCustomizeCookies,
        toggleStrictlyNecessaryChange,
        togglePerformanceCookiesChange,
        toggleFunctionalCookiesChange,
        toggleTargetingCookiesChange,
        acceptAll,
        confirmCookies,
      }}
    >
      {children}
    </GDPRContext.Provider>
  );
};

export default GDPRProvider;
