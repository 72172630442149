const compareLabels = (a, b) => a.label.localeCompare(b.label);
export const COL_GROUP_ROW_ID_SEP = "|-|";
export const COL_GROUP_ROW_EMPTY = "(Blanks)";
export const PWB_STORAGE_KEY = "PWB-KEY";
export const NUMERATOR_AGGREGATION_OPTIONS = [
    { label: "Market Value", value: "NotionalMarketValue" },
    { label: "Delta Adjusted Exposure", value: "DeltaAdjusted" },
    { label: "DV01 Exposure", value: "DV01" },
    { label: "CS01", value: "CS01" },
    { label: "Delta One Exposure", value: "DeltaOneExposure" },
    { label: "Notional Dirty MV", value: "NotionalDirtyMV" },
    { label: "Notional Quantity", value: "NotionalQuantity" },
].sort(compareLabels);
export const DENOMINATOR_OPTIONS = [
    { label: "Fund Administrator NAV", value: "FundNAV" },
    { label: "GL NAV", value: "GLNAV" },
    { label: "Manual NAV", value: "ManualNAV" },
    { label: "Fund NAV + MTD PnL", value: "FundNAVPlusMTDPnL" },
    { label: "Underlying Exposure", value: "UnderlyingExposure" },
    { label: "Allocated Notional", value: "AllocatedNotional" },
    { label: "Fund Hierarchy NAV", value: "FundHierarchyNAV" },
    { label: "RT Fund Administrator NAV", value: "FundNAVPlusDailyPnL" },
    { label: "RT GL NAV", value: "GLSODNAVPlusDailyPnL" },
].sort(compareLabels);
export const MODEL_BM_MAPPING_OPTIONS = [
    { value: false, label: "Fund", title: "Fund" },
    {
        value: true,
        label: "Fund + Book",
        title: "Fund + Book",
    },
];
export const INCLUDE_ACTIVE_ORDERS_OPTIONS = [
    { label: "Executed Portion", value: false },
    { label: "All", value: true },
];
export const REBALANCE_CALCULATION_LEVEL_OPTIONS = [
    { label: "Fund", value: "Fund" },
    { label: "Account", value: "Account" },
];
export const REBALANCE_DURATION_OPTIONS = [
    { label: "Modified Duration", value: "ModifiedDuration" },
    { label: "Spread Duration", value: "SpreadDuration" },
    { label: "Macaulay Duration", value: "Duration" },
];
export const PORTFOLIO_TARGET_DIST_METHODS = {
    matchExisting: "MatchExisting",
    equalWeight: "EqualWeightInstruments",
};
export const ROUND_TO_LOT_SIZE_INFO_TEXT = "When adjustments are calculated if the order quantity should round to units of the instrument's lot size";
export const TOAST_MESSAGES = {
    adjustment: {
        info: "Adjustment processing",
        infoSub: "May take a few minutes",
        error: "Adjustment failed",
        complete: "Adjustment complete",
    },
    testCompliance: {
        info: "Checking compliance",
        infoSub: "May take a few minutes",
        error: "Failed checking compliance",
        complete: "Checking compliance complete",
    },
    submitOrders: {
        info: "Submitting orders",
        infoSub: "May take a few minutes",
        error: "Failed submitting orders",
        errorSubOverrideAllowed: "Failed compliance",
        errorSubOverrideNotAllowed: "Failed compliance, unable to override",
        complete: "Orders submitted",
    },
    reload: {
        noLiveOrders: "Live orders are not included. This will impact shown position.",
    },
    modifyOrders: {
        info: "Modifying orders",
        infoSub: "May take a few minutes",
        error: "Failed to modify orders",
        complete: "Orders modified",
    },
};
export const CONSTITUENT_DISPLAY_INFO_TEXT = 'This setting will condense all zero-weighted positions not held in a fund but in the benchmark to be consolidated into an "all others" section.';
export const NORMALIZE_INFO_TEXT = "This setting controls whether cash is excluded in the rebalancing so that the proceeds of the investments are rebalanced pro-rata, excluding cash.";
export const PROPOSED_ORDERS_SUBMIT_CONFIRMATION = {
    title: "Submit Orders?",
    buttonText: "Yes, I'm sure",
    message: "Are you sure you would like to submit the proposed orders?",
};
export const PROPOSED_ORDERS_REMOVAL_CONFIRMATION = {
    title: "Remove Orders?",
    buttonText: "Remove Orders",
    message: "Removing these allocation entries would result in one or more orders with no allocations.",
};
export const PROPOSED_ORDERS_IGNORE_COMPLIANCE_CONFIRMATION = {
    title: "Compliance Failure",
    buttonText: "Yes",
    message: "One or more proposed orders have failed compliance. Do you wish to continue with order staging?",
};
export const COMPLIANCE_STATE_SEVERITY = [
    {
        severity: 1,
        state: "Failed",
    },
    {
        severity: 2,
        state: "Warning",
    },
    { severity: 3, state: "WarningWithNote" },
    {
        severity: 4,
        state: "OverrideNotAllowed",
    },
    {
        severity: 5,
        state: "Tracking",
    },
    {
        severity: 6,
        state: "Disabled",
    },
    {
        severity: 7,
        state: "PendingCheck",
    },
    {
        severity: 8,
        state: "Passed",
    },
];
export const currencyColumns = [
    "currencyInputCell",
    "priceColumn",
    "currencyColumn",
];
export const numericColumns = [
    "percentColumn",
    "doubleColumn",
    "percentInputCell",
    "doubleInputCell",
].concat(currencyColumns);
