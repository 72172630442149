import {
  ArgsWithGetParentRows,
  getGroupRowComplianceState,
} from "@enfusion-ui/portfolios";
import { DashboardRoot, FileTreeEntry } from "@enfusion-ui/types";
import { formatPercentage } from "@enfusion-ui/utils";
import { TabConfig } from "@enfusion-ui/web-core";
import { faAbacus } from "@fortawesome/pro-regular-svg-icons";
import { ColDef, IRowNode, ITooltipParams } from "ag-grid-community";
import { isUndefined } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { getRowData } from "./components/utils";

type NewPortfolioConfig = {
  node?: FileTreeEntry;
  root?: DashboardRoot;
  path?: string;
  name?: string;
};

export const getNewPortfolioTabContent = ({
  root = "user",
  path,
  node,
  name = "New Workbench",
}: NewPortfolioConfig) =>
  ({
    component: "portfolio-workbench",
    name,
    icon: faAbacus,
    unique: `portfolio-${path}`,
    config: { id: uuidv4(), filePath: path, node, root },
  } as TabConfig);

type A = ArgsWithGetParentRows<ITooltipParams>;
export function priceStatusToolTipGetter({
  node,
  valueFormatted,
  context,
  value,
}: A) {
  if (!node) return null;

  let displayValue = valueFormatted;
  const { priceStatus, price } = getRowData(node, context?.getParentRows) ?? {};
  const hasPriceStatus =
    !isUndefined(priceStatus?.value) && priceStatus.value !== null;
  if (typeof price?.value === "number" && price.quoteType === "PercentOfPar") {
    displayValue = formatPercentage(value, 2);
  }
  return `${displayValue}${hasPriceStatus ? `: ${priceStatus.value}` : ""}`;
}

export function complianceStateToolTipGetter(params: A) {
  if (params?.node?.group) {
    const allLeafNode: IRowNode<any>[] = params.node.allLeafChildren;

    if (allLeafNode?.length)
      return getGroupRowComplianceState(
        allLeafNode || [],
        (params.colDef as ColDef<any, any>)?.cellRendererParams.defaultState
      );
  }

  if (!params.node || !params.data) return "";

  const res = params.data.complianceResult;
  if (!res) return "";
  return res.complianceState;
}
