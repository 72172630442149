/* eslint-disable @typescript-eslint/no-explicit-any */
import { omit } from "lodash";
import { errorToast, infoToast, isPopOut, openTabWindow } from "../../utils";
const infoToastShownCache = new Set([
    "factset-market-watch",
    "factset-full-quote",
]);
export function reducer(state, action) {
    switch (action.type) {
        case "add": {
            const { tabs, selectedTabId, undockedTabs = [], onTabWillCloseSubscriptions = {}, } = state;
            let addedTabs = 0;
            let newTabs = [...tabs];
            let newUndockedTabs = [...undockedTabs];
            const newOnTabWillCloseSubscriptions = { ...onTabWillCloseSubscriptions };
            let newSelectedTabId = selectedTabId;
            for (const tabConfig of action.payload) {
                const { id, name, unique, component, icon, config } = tabConfig;
                // if unique and already have a tab for this
                const undockedTabDef = undockedTabs.find((i) => i.id === unique);
                if (unique) {
                    if (!!undockedTabDef) {
                        // if window is already closed clean it up
                        if (!undockedTabDef.window || undockedTabDef.window.closed) {
                            newUndockedTabs = newUndockedTabs.filter((i) => i.id !== unique);
                            delete newOnTabWillCloseSubscriptions[unique];
                        }
                        else {
                            undockedTabDef?.window?.focus();
                            break;
                        }
                    }
                    else if (tabs.some((i) => i.id === unique)) {
                        newSelectedTabId = unique;
                        if (!infoToastShownCache.has(unique)) {
                            infoToastShownCache.add(unique);
                            infoToast(`Only one instance of a "${name}" tab can be open at a time.`, "Existing tab will be focused.");
                        }
                        action.failCallback?.();
                        continue;
                    }
                }
                const tabDef = {
                    id,
                    icon,
                    name,
                    config,
                    unique,
                    component,
                };
                newTabs = [...newTabs, tabDef];
                addedTabs += 1;
                if (tabConfig.focusIfFound !== false)
                    newSelectedTabId = tabDef.id;
            }
            if (addedTabs === 0) {
                return {
                    ...state,
                    selectedTabId: newSelectedTabId,
                };
            }
            return {
                ...state,
                tabs: newTabs,
                undockedTabs: newUndockedTabs,
                selectedTabId: newSelectedTabId,
                onTabWillCloseSubscriptions: newOnTabWillCloseSubscriptions,
            };
        }
        case "remove": {
            const { id, undockedOnly } = action.payload;
            const { tabs, undockedTabs = [], selectedTabId, onTabCloseSubscriptions = [], onTabWillCloseSubscriptions = {}, } = state;
            const newOnTabWillCloseSubscriptions = { ...onTabWillCloseSubscriptions };
            const newTabs = [...tabs].filter((i) => i.id !== id);
            const newUndockedTabs = [...undockedTabs].filter((i) => i.id !== id);
            const removedNormalTab = tabs.find((i) => i.id === id);
            const removedUndockedTab = undockedTabs.find((i) => i.id === id);
            const removedTab = removedNormalTab || removedUndockedTab;
            const undocked = !!removedUndockedTab;
            if (undockedOnly && !undocked)
                return { ...state };
            if (removedTab) {
                // Check if we don't want a tab to be closed. IE unsaved changes
                if (onTabWillCloseSubscriptions[id]) {
                    if (onTabWillCloseSubscriptions[id]
                        .map((callback) => typeof callback === "function" ? callback(removedTab) : true)
                        .some((i) => i === false)) {
                        return { ...state };
                    }
                }
                for (const callback of onTabCloseSubscriptions) {
                    if (typeof callback === "function") {
                        callback(removedTab);
                    }
                }
                if (isPopOut())
                    window.close();
            }
            delete newOnTabWillCloseSubscriptions[id];
            return {
                ...state,
                tabs: newTabs,
                undockedTabs: newUndockedTabs,
                selectedTabId: undocked
                    ? selectedTabId
                    : id === selectedTabId
                        ? newTabs.length
                            ? newTabs[newTabs.length - 1].id
                            : undefined
                        : selectedTabId,
                onTabWillCloseSubscriptions: newOnTabWillCloseSubscriptions,
            };
        }
        case "focus": {
            const { id } = action.payload;
            const { tabs, undockedTabs = [] } = state;
            const tabIdx = tabs.findIndex((i) => i.id === id);
            const undockedTabIdx = undockedTabs.findIndex((i) => i.id === id);
            console.log("focus", { id, tabIdx, undockedTabIdx });
            if (tabIdx < 0 && undockedTabIdx < 0)
                return { ...state };
            if (tabIdx > -1)
                return { ...state, selectedTabId: id };
            if (undockedTabIdx > -1)
                undockedTabs[undockedTabIdx]?.window?.focus();
            return { ...state };
        }
        case "rename": {
            const { id, name, config } = action.payload;
            const { tabs, undockedTabs = [] } = state;
            const tabIdx = tabs.findIndex((i) => i.id === id);
            const undockedTabIdx = undockedTabs.findIndex((i) => i.id === id);
            if (tabIdx < 0 && undockedTabIdx < 0)
                return { ...state };
            if (tabIdx > -1) {
                const newTabs = [...tabs];
                newTabs[tabIdx] = {
                    ...newTabs[tabIdx],
                    name,
                    config: config
                        ? { ...newTabs[tabIdx].config, ...config }
                        : newTabs[tabIdx].config,
                };
                return {
                    ...state,
                    tabs: newTabs,
                };
            }
            const newTabs = [...undockedTabs];
            newTabs[undockedTabIdx] = {
                ...newTabs[undockedTabIdx],
                name,
                config: config
                    ? { ...newTabs[undockedTabIdx].config, ...config }
                    : newTabs[undockedTabIdx].config,
            };
            return {
                ...state,
                undockedTabs: newTabs,
            };
        }
        case "undock": {
            const { id, width, height } = action.payload;
            const { tabs, undockedTabs = [], selectedTabId, layoutModel } = state;
            const tabIdx = tabs.findIndex((i) => i.id === id);
            if (tabIdx < 0)
                return { ...state };
            const newTabs = [...tabs].filter((i) => i.id !== id);
            const tabNode = layoutModel.getNodeById(id);
            const newWindow = openTabWindow(id, width, height);
            if (newWindow) {
                const newUndockedTabs = [
                    ...undockedTabs.filter((i) => i.id !== id),
                    {
                        ...tabs[tabIdx],
                        window: newWindow,
                        width,
                        height,
                        tabSetId: tabNode.getParent()?.getId(),
                    },
                ];
                return {
                    ...state,
                    undockedTabs: newUndockedTabs,
                    selectedTabId: id === selectedTabId
                        ? newTabs.length
                            ? newTabs[newTabs.length - 1].id
                            : undefined
                        : selectedTabId,
                    tabs: newTabs,
                };
            }
            errorToast("Failed to pop out tab");
            return { ...state };
        }
        case "reUndock": {
            const { ids } = action.payload;
            const { undockedTabs = [] } = state;
            const newTabs = [...undockedTabs].map((tabDef) => {
                if (!ids.includes(tabDef.id))
                    return tabDef;
                const newWindow = openTabWindow(tabDef.id, tabDef.width, tabDef.height);
                if (!newWindow) {
                    console.warn("Failed to open pop out for", tabDef);
                    return null;
                }
                return {
                    ...tabDef,
                    window: newWindow,
                };
            });
            return {
                ...state,
                undockedTabs: newTabs.filter(Boolean),
            };
        }
        case "dock": {
            const { id } = action.payload;
            const { undockedTabs = [], selectedTabId, tabs } = state;
            const newTabs = [...tabs];
            let newUndockedTabs = [...undockedTabs];
            let newSelectedTabId = selectedTabId;
            const tabDef = undockedTabs.find((i) => i.id === id);
            if (!tabDef) {
                errorToast("No tab found to dock");
                return { ...state };
            }
            newSelectedTabId = tabDef.id;
            newTabs.push(omit(tabDef, ["tabSetId", "width", "height", "window"]));
            newUndockedTabs = newUndockedTabs.filter((i) => i.id !== tabDef.id);
            tabDef?.window?.close?.();
            return {
                ...state,
                tabs: newTabs,
                undockedTabs: newUndockedTabs,
                selectedTabId: newSelectedTabId,
            };
        }
        case "checkUndocked": {
            const { undockedTabs = [], onTabWillCloseSubscriptions = {} } = state;
            const newOnTabWillCloseSubscriptions = { ...onTabWillCloseSubscriptions };
            const newTabs = [...undockedTabs].reduce((res, tabDef) => {
                if (!tabDef.window || tabDef.window.closed) {
                    delete newOnTabWillCloseSubscriptions[tabDef.id];
                    return res;
                }
                return [...res, tabDef];
            }, []);
            return {
                ...state,
                undockedTabs: newTabs,
                onTabWillCloseSubscriptions: newOnTabWillCloseSubscriptions,
            };
        }
        case "subscribeOnClose": {
            const { onTabCloseSubscriptions = [] } = state;
            const { callback } = action.payload;
            const newSubscriptions = [
                ...onTabCloseSubscriptions.filter((i) => i !== callback),
                callback,
            ];
            return { ...state, onTabCloseSubscriptions: newSubscriptions };
        }
        case "unsubscribeOnClose": {
            const { onTabCloseSubscriptions = [] } = state;
            const { callback } = action.payload;
            const newSubscriptions = [
                ...onTabCloseSubscriptions.filter((i) => i !== callback),
            ];
            return { ...state, onTabCloseSubscriptions: newSubscriptions };
        }
        case "subscribeOnWillClose": {
            const { onTabWillCloseSubscriptions = {} } = state;
            const { id, callback } = action.payload;
            const newSubscriptions = {
                ...onTabWillCloseSubscriptions,
                [id]: [
                    ...(onTabWillCloseSubscriptions[id]?.filter((i) => i !== callback) ||
                        []),
                    callback,
                ],
            };
            return { ...state, onTabWillCloseSubscriptions: newSubscriptions };
        }
        case "unsubscribeOnWillClose": {
            const { onTabWillCloseSubscriptions = {} } = state;
            const { id, callback } = action.payload;
            const newSubscriptions = {
                ...onTabWillCloseSubscriptions,
                [id]: onTabWillCloseSubscriptions[id]?.filter((i) => i !== callback) || [],
            };
            return { ...state, onTabWillCloseSubscriptions: newSubscriptions };
        }
        default:
            return { ...state };
    }
}
