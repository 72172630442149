import { WidgetComponentsDefinition } from "../../types";
import PdfTextInputWidget from "./PdfTextInputWidget";
import TextInputEditWidget from "./TextInputEditWidget";
import TextInputWidget from "./TextInputWidget";

export const textInput: WidgetComponentsDefinition = {
  renderComponent: TextInputWidget,
  editComponent: TextInputEditWidget,
  renderPdfComponent: PdfTextInputWidget,
};
