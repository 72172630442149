import {
  UNKNOWN_INSTRUMENT_ID,
  useInstrument,
  useOEMSOrderForm,
} from "@enfusion-ui/core";
import { QuantityNotionalType } from "@enfusion-ui/dashboards";
import { useRefCallback } from "@enfusion-ui/hooks";
import { CurrencySymbol, currencySymbols } from "@enfusion-ui/utils";
import {
  ButtonGroupSelect,
  ControlledInputBase,
  defaultFormatValue,
  FormElementHeader,
  NumericInputAsString,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

import { ElementWrapper } from "./InstrumentAndStrategyPanel";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: var(--spacing-l) 0;
`;

const InputsWrapper = styled.div`
  display: flex;
  gap: var(--spacing-xl);
  align-items: center;
  flex-wrap: wrap;
`;

const ReadOnlyInputsWrapper = styled.div`
  display: flex;
  margin-top: var(--spacing-xl);
  padding: 0 var(--spacing);
  flex-wrap: wrap;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xl);
  font-size: 0.8rem;
`;

const InputLabel = styled.label`
  white-space: nowrap;
`;

const ReadOnlyInputBase = styled.input.attrs({
  disabled: true,
})`
  color: var(--text-normal);
  background: none;
  border: none;
  width: 100%;
  padding: 0;
`;

const ReadOnlyInput: React.FC<{
  name: string;
  defaultValue: string;
  decimalPlaces?: number;
}> = ({ name, defaultValue, decimalPlaces = 2 }) => {
  const { quantity, localNotional, notional } = useWatch({
    name: [QUANTITY, LOCAL_NOTIONAL, NOTIONAL],
  });
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, onChange, ...rest }) => (
        <ReadOnlyInputBase
          {...rest}
          value={
            defaultFormatValue(
              name === QUANTITY
                ? quantity
                : name === NOTIONAL
                ? notional
                : localNotional,
              decimalPlaces
            ) ?? defaultValue
          }
          onChange={onChange}
        />
      )}
    />
  );
};

const QUANTITY = "quantity";
const NOTIONAL = "notional";
const LOCAL_NOTIONAL = "localNotional";

export const inputOptions = (ccy = "$", ccyCode?: string) => [
  { value: QUANTITY, label: "#", title: "Quantity" },
  {
    value: NOTIONAL,
    label: ccy,
    title: `Notional${ccyCode ? ` (${ccyCode})` : ""}`,
  },
];
export const QuantityNotionalPanel: React.FC<
  QuantityNotionalType & {
    disabled?: boolean;
  }
> = ({
  disabled: disabledExternally,
  input = "quantity",
  showCalculationInfoText = true,
}) => {
  const [activeInput, setActiveInput] = React.useState<string | null>(QUANTITY);
  const {
    handleQuantity,
    handleNotional,
    minQuantity = 0,
  } = useOEMSOrderForm();
  const { quantity, localNotional, orderSide, notionalCcy, localNotionalCcy } =
    useWatch({
      name: [
        QUANTITY,
        LOCAL_NOTIONAL,
        "orderSide",
        "notionalCcy",
        "localNotionalCcy",
      ],
    });
  const instrument = useInstrument();
  const valueRef = React.useRef<string | null>("0");
  const notionalCurrency = notionalCcy || "USD";
  const currency = localNotionalCcy || instrument?.currency?.code;
  const isNonUSD = !!currency && currency !== "USD";

  const disabled =
    disabledExternally ||
    !instrument ||
    instrument?.id === UNKNOWN_INSTRUMENT_ID ||
    !orderSide;

  const currencySymbol = currency
    ? currencySymbols.base[currency as CurrencySymbol]
    : "$";

  const optionOrder = instrument?.option ?? false;
  const notionalLabel = optionOrder ? "Premium" : "Notional";

  React.useEffect(() => {
    setActiveInput(input);
  }, [input]);

  const handleQuantityChange = useRefCallback(
    (value: string | null) => {
      valueRef.current = value;
      handleQuantity(value);
    },
    [handleQuantity]
  );

  React.useEffect(() => {
    if (
      valueRef.current !== quantity &&
      instrument &&
      instrument.id !== UNKNOWN_INSTRUMENT_ID &&
      instrument.financialSubType?.description !== "Future Spread"
    ) {
      handleQuantity(quantity);
    }
  }, [quantity, instrument, orderSide, handleQuantity]);

  return (
    <Wrapper>
      <FormElementHeader>
        {activeInput === QUANTITY ? "Quantity" : notionalLabel}
      </FormElementHeader>
      <InputsWrapper>
        <ElementWrapper>
          <ButtonGroupSelect
            selectedValue={activeInput}
            onSelect={(value) => setActiveInput(value)}
            options={inputOptions(currencySymbol, currency)}
            disableDeselect
            disabled={disabled}
            neutralSeparatorColor
          />
        </ElementWrapper>
        <ElementWrapper>
          {activeInput === QUANTITY ? (
            <NumericInputAsString
              id="quantity-id"
              key={QUANTITY}
              hideLabel
              dataTestId="quantity-input"
              value={quantity}
              onChange={handleQuantityChange}
              disabled={disabled}
              step={instrument?.lotSize || 1}
              forceStep={false}
              enableMultiplier
              bigControls
              min={minQuantity}
            />
          ) : (
            <NumericInputAsString
              id="quantity-id"
              key={LOCAL_NOTIONAL}
              hideLabel
              value={localNotional}
              onChange={handleNotional}
              disabled={disabled}
              enableMultiplier
              bigControls
            />
          )}
        </ElementWrapper>
      </InputsWrapper>

      {showCalculationInfoText && (
        <ReadOnlyInputsWrapper>
          <InputWrapper>
            <InputLabel>Quantity</InputLabel>
            <ReadOnlyInput name={QUANTITY} defaultValue="0" />
          </InputWrapper>
          {isNonUSD && !!instrument ? (
            <InputWrapper>
              <InputLabel>
                {notionalLabel}
                {!!notionalCurrency ? ` (${notionalCurrency})` : ""}
              </InputLabel>
              <ReadOnlyInput
                name={NOTIONAL}
                defaultValue="0.00"
                decimalPlaces={optionOrder ? 0 : 2}
              />
            </InputWrapper>
          ) : null}
          <InputWrapper>
            <InputLabel>
              {notionalLabel}
              {!!currency ? ` (${currency})` : ""}
            </InputLabel>
            <ReadOnlyInput
              name={LOCAL_NOTIONAL}
              defaultValue="0.00"
              decimalPlaces={optionOrder ? 0 : 2}
            />
          </InputWrapper>
        </ReadOnlyInputsWrapper>
      )}
    </Wrapper>
  );
};

export default QuantityNotionalPanel;
