import { RoundedDataGrid } from "@app-components/DataGrid";
import { useReconciliation } from "@app-context/reconciliation/context";
import { ContextItemEntry } from "@app-views/portfolios/components/MainPortfolioGrid";
import { LoadingContent } from "@app-views/reconciliation/components/LoadingContent";
import { GridWrapper } from "@app-views/reconciliation/components/styles";
import {
  BREAK_TYPE_COL_DEF,
  breakMap,
} from "@app-views/reconciliation/utils/constants";
import { cashBreakTypes } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  DiffReportData,
  DiffReportSyncRow,
  FilterColumns,
  ImpactColumns,
} from "@enfusion-ui/types";
import { FormPanel } from "@enfusion-ui/web-components";
import { useTheme } from "@enfusion-ui/web-core";
import {
  ColDef,
  GetContextMenuItemsParams,
  GridReadyEvent,
  IRowNode,
} from "ag-grid-community";
import { isNull } from "lodash";
import React from "react";

import {
  getFilteredData,
  getRearrangedColumns,
  getReconStorageKey,
} from "../../utils/commonUtils";

type CashBalanceGridProps = {
  selectedFilter: string | null;
  id: string;
  doesExternalFilterPass: (node: IRowNode) => boolean;
  filterColumns: FilterColumns;
  impactColumns: ImpactColumns;
  reportData?: DiffReportData;
  getContextMenuItems: (
    params: GetContextMenuItemsParams
  ) => ContextItemEntry[];
};

const CashBalanceGridContent: React.FC<CashBalanceGridProps> = ({
  selectedFilter,
  id,
  doesExternalFilterPass,
  filterColumns,
  impactColumns,
  reportData,
  getContextMenuItems,
}) => {
  const [cashBalanceRowData, setCashBalanceRowData] = React.useState<
    DiffReportSyncRow[]
  >([]);
  const gridRef = React.useRef<GridReadyEvent | null>(null);
  const { theme } = useTheme();
  const { filterState, metadata, reportsLoadingStatus } = useReconciliation();
  const loading = reportsLoadingStatus?.[id];

  React.useEffect(() => {
    let allBreaksData: DiffReportSyncRow[] = [];
    Object.entries(reportData ?? {}).forEach(([type, rowData]) => {
      if (type !== "statistics")
        allBreaksData = [
          ...allBreaksData,
          ...(rowData as DiffReportSyncRow[]).map((row: DiffReportSyncRow) => {
            row.columnValues["breakType"] = {
              value: breakMap[type] ?? breakMap.default,
            };
            return row;
          }),
        ];
    });
    setCashBalanceRowData(
      getFilteredData(filterState, allBreaksData, {
        ...filterColumns,
        currency: impactColumns.cashBalanceCurrency,
      })
    );
  }, [filterState, selectedFilter, metadata, id]);

  const getCashBalanceGridColDefs = useRefCallback(() => {
    const reportColDefs = (metadata?.[id]?.defs ?? []).map((colDef) => ({
      ...colDef,
      filter: true,
    }));
    const rearrangedColumns = getRearrangedColumns(reportColDefs);
    const colDefs = [...rearrangedColumns, BREAK_TYPE_COL_DEF] as ColDef<
      any,
      any
    >[];
    return colDefs;
  }, [id, metadata]);

  const handleGridReady = useRefCallback(
    (event) => {
      gridRef.current = event;
      const savedColumnState = localStorage.getItem(getReconStorageKey(id));
      const initialColumnState = !isNull(savedColumnState)
        ? JSON.parse(savedColumnState)
        : getCashBalanceGridColDefs();
      gridRef.current?.api.applyColumnState({
        state: initialColumnState,
      });
    },
    [gridRef.current]
  );

  const handleColumnStateChanged = useRefCallback(() => {
    const currentState = gridRef.current?.api.getColumnState();
    localStorage.setItem(getReconStorageKey(id), JSON.stringify(currentState));
  }, [gridRef.current]);

  return (
    <GridWrapper className={theme.agGridClass.trim()} height={150}>
      {loading ? (
        <LoadingContent />
      ) : (
        <RoundedDataGrid
          columnDefs={getCashBalanceGridColDefs()}
          rowData={cashBalanceRowData}
          rowSelection="multiple"
          animateRows={false}
          suppressRowDrag
          height="100%"
          onGridReady={handleGridReady}
          onColumnStateChanged={handleColumnStateChanged}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          getContextMenuItems={getContextMenuItems}
          isExternalFilterPresent={() => true}
          doesExternalFilterPass={doesExternalFilterPass}
        />
      )}
    </GridWrapper>
  );
};

export const CashBalanceGrid: React.FC<CashBalanceGridProps> = ({
  ...props
}) => {
  return (
    <FormPanel
      lineStyle
      collapsible
      keepRendered
      numColumns={1}
      defaultOpen
      title={cashBreakTypes.CashBalance}
      showTextCollapseButton
      gapSize="l"
      titleStyle={{
        padding: "0 var(--spacing-l) var(--spacing-l) var(--spacing-l)",
        fontSize: "14px",
      }}
      thinContainer
      render={() => <CashBalanceGridContent {...props} />}
    />
  );
};
