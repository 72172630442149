import { NodeData } from "@enfusion-ui/types";

export const findAccountData = (
  value: number,
  nodes: NodeData[] | null,
  fundNode?: NodeData,
  custodianNode?: NodeData
) => {
  const selectedNodeList = nodes?.reduce<NodeData[]>((res, entry) => {
    const fund = entry.level === "Fund" ? entry : undefined;
    const custodian = entry.level === "Custodian" ? entry : undefined;
    if (entry.accountId === value) {
      res.push(entry);
      if (fundNode) res.push(fundNode);
      if (custodianNode) res.push(custodianNode);
    } else if (entry.nodes) {
      const subNodes = findAccountData(
        value,
        entry.nodes,
        fund ?? fundNode,
        custodian ?? custodianNode
      );
      if (subNodes) {
        // eslint-disable-next-line no-param-reassign
        res = [...res, ...subNodes];
      }
    }
    return res;
  }, [] as Array<NodeData>);
  return selectedNodeList;
};

export const findNode = (value: number | null, nodes: NodeData[], key = "id") =>
  nodes.reduce((res, entry) => {
    if (entry[key] && entry[key] === value) {
      res.push(entry);
    } else if (entry.nodes) {
      const subNodes = findNode(value, entry.nodes, key);
      if (subNodes) {
        // eslint-disable-next-line no-param-reassign
        res = [...res, ...subNodes];
      }
    }
    return res;
  }, [] as NodeData[]);

export const findNodes = (values: number[], nodes: NodeData[], key = "id") =>
  nodes.reduce((res, entry) => {
    const selectedNode = values.find(
      (value) => entry[key] && entry[key] === value
    );
    if (selectedNode) {
      res.push(entry);
    } else if (entry.nodes) {
      const subNodes = findNodes(values, entry.nodes, key);
      if (subNodes) {
        // eslint-disable-next-line no-param-reassign
        res = [...res, ...subNodes];
      }
    }
    return res;
  }, [] as NodeData[]);

export const getNodesHierarchy = (
  values: Array<number>,
  nodes: Array<NodeData>
) => {
  const selectedNodeList = nodes.reduce<Array<NodeData>>((res, entry) => {
    const selectedNode = values.find((value) => entry.accountId === value);
    if (selectedNode) {
      res.push(entry);
    } else if (entry.nodes) {
      const subNodes = getNodesHierarchy(values, entry.nodes);
      if (subNodes?.length > 0) {
        res = [...res, ...subNodes, entry];
      }
    }
    return res;
  }, [] as Array<NodeData>);
  return selectedNodeList;
};
