import { WidgetComponentsDefinition } from "@app-views/dashboards/widgets/types";
import MarketInfoEditWidget from "@app-views/oems/OrderTicket/CustomTicket/widgets/marketInfo/MarketInfoEditWidget";
import MarketInfoWidget from "@app-views/oems/OrderTicket/CustomTicket/widgets/marketInfo/MarketInfoWidget";
import MarketInfoPanel from "@app-views/oems/OrderTicketForm/panels/MarketInfoPanel";

import MarketInfoMobileWidget from "./MarketInfoMobileWidget";

export const marketSecurityInfoPanel: WidgetComponentsDefinition = {
  renderComponent: () => <MarketInfoPanel margin={false} />,
};

export const marketInfoPanel: WidgetComponentsDefinition = {
  renderComponent: () => <MarketInfoWidget />,
  editComponent: () => <MarketInfoEditWidget />,
  renderMobileComponent: () => <MarketInfoMobileWidget />,
};
