import { useLocalStorage, useRefCallback } from "@enfusion-ui/hooks";
import { ColumnState, GridReadyEvent } from "ag-grid-community";
import { debounce } from "lodash";
import * as React from "react";

type UseStoredGridColumnStateArgs = {
  storageKey: string;
  onGridReady?: (e: GridReadyEvent) => void;
  onColumnStateChanged?: (e: GridReadyEvent) => void;
};

export function useStoredGridColumnState({
  storageKey,
  onGridReady,
  onColumnStateChanged,
}: UseStoredGridColumnStateArgs) {
  const gridReadyEventRef = React.useRef<GridReadyEvent>();

  const [gridColumnState, setGridColumnState] = useLocalStorage<
    ColumnState[] | null
  >(storageKey, null);

  const handleColumnStateChanged = useRefCallback(
    debounce(() => {
      if (gridReadyEventRef.current) {
        const { columnApi } = gridReadyEventRef.current;
        const currentState = columnApi.getColumnState();
        setGridColumnState(currentState);
        onColumnStateChanged?.(gridReadyEventRef.current);
      }
    }, 1000),
    [gridReadyEventRef, onColumnStateChanged]
  );

  const handleGridReady = useRefCallback(
    (e: GridReadyEvent) => {
      gridReadyEventRef.current = e;
      onGridReady?.(e);
      if (gridColumnState) {
        e.api.applyColumnState({
          state: gridColumnState,
          applyOrder: true,
        });
      }
    },
    [onGridReady, gridColumnState]
  );

  return {
    gridReadyEventRef,
    gridColumnState,
    onColumnStateChanged: handleColumnStateChanged,
    onGridReady: handleGridReady,
  };
}
