import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TempTriangleIcon = () => {
  return (
    <div style={{ marginLeft: "0.4rem" }}>
      <FontAwesomeIcon icon={faTriangleExclamation} size="sm" />
    </div>
  );
};
