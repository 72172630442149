import { WebPositionReportQuery } from "@enfusion-ui/types";

export const sunburstQuery = {
  type: "WebPositionReportQuery",
  path: "shared/DANG and JG/SunBurstMyHeart.ppr",
  reportTemplateId: 0,
  reportFilter: {
    filterGroups: [
      {
        expressions: [],
        predicate: "All",
        groupPredicate: null,
      },
    ],
    enabled: true,
  },
  postGroupingFilter: {
    filterGroups: [
      {
        expressions: [],
        predicate: "All",
        groupPredicate: null,
      },
    ],
    enabled: true,
  },
  disableHighlightWarnings: false,
  levelInfos: [
    {
      levelId: "PositionIdentifierScenario",
      sortOrder: [],
    },
  ],
  totalsIncludeFilteredRows: false,
  rowInvalidationPeriodInMinutes: 0,
  autoResort: false,
  hideSettingsOnOpen: false,
  accountSelection: {
    fundIds: [
      691592, 708233, 691593, 691590, 190319, 691591, 309610, 691589, 729787,
      694296, 729593, 694297, 729788, 680592, 680593, 703601, 286296, 436634,
      729590, 693076, 729589,
    ],
    includeAllAccounts: null,
    accountIds: [],
    fundGroupNames: [],
    fundCustodianPairs: [],
    liquidationTypes: [],
    includeTestContainers: false,
    enableExcludeExpiredFund: false,
    includeExpiredFund: false,
    expiredAsOfDate: null,
  },
  bookSelection: {
    fundIds: [],
    includeAllAccounts: null,
    bookIds: [],
    ignoreBookSelection: false,
  },
  portfolioGroupIds: null,
  dealId: null,
  positionBlock: null,
  trsId: null,
  marginFundId: null,
  scenarioSetId: null,
  daysBeforeInactive: null,
  marketEnvironmentId: 5,
  includeUnderlyings: false,
  includeExpiredFunds: false,
  inGridShockEffect: "ShockSelectedPosition",
  positionRequestType: "SecuritiesOnly",
  dateAdjustment: "NeverAdjust",
  datePeriodSelection: {
    dateSelection: {
      asOfDate: null,
      dateSelectionType: "Latest",
    },
    period: null,
    increment: 1,
    beforeCount: 0,
    afterCount: 0,
  },
  instrumentIds: [],
  ignoredTradeTypes: [],
  financialSubTypes: [],
  ignoredTradeBookingStatus: [],
} as WebPositionReportQuery;
