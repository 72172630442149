import { IRSOrderFormValues } from "@enfusion-ui/core";
import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { IRSQuickTemplateInfo } from "@enfusion-ui/types";
import { getTerminationDate } from "@enfusion-ui/utils";
import { ControlledSelect } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export type IRSQuickTemplatesSelectProps = {
  disabled?: boolean;
};
const IRSQuickTemplatesSelect: React.FC<IRSQuickTemplatesSelectProps> = ({
  disabled,
}) => {
  const { setValue } = useFormContext<IRSOrderFormValues>();
  const irsTemplates = React.useRef<IRSQuickTemplateInfo[]>([]);
  const { tenor, quickTemplate } = useWatch({
    name: ["tenor", "quickTemplate"],
  });
  const { options, loading } = useRestAbortableOptions(
    REST_API.INSTRUMENT.GET_QUICK_TEMPLATES.FETCH,
    (t) => {
      irsTemplates.current.push(t);
      return { label: t.description, value: t.name };
    }
  );

  React.useEffect(() => {
    if (tenor && quickTemplate) {
      const selectedTemplate = irsTemplates.current.find(
        (template: IRSQuickTemplateInfo) => template.name === quickTemplate
      );
      setValue("startDate", selectedTemplate?.startDate);
      setValue(
        "endDate",
        getTerminationDate(tenor, selectedTemplate?.startDate ?? "")
      );
    }
  }, [tenor, quickTemplate]);

  return (
    <ControlledSelect
      name="quickTemplate"
      label="Quick Templates"
      options={options}
      isLoading={loading}
      disabled={disabled}
      inputId="irs-quick-templates-input-id"
    />
  );
};

export default IRSQuickTemplatesSelect;
