import { KeywordsTabCore } from "@app-components/tickets/keywordsTab/KeywordsTabCore";
import { useTradeTicket } from "@enfusion-ui/trades";
import { FormPanel } from "@enfusion-ui/web-components";
import * as React from "react";

import { TabContentContainer } from "./components/styledComponents";

export const TradeKeywordsTab: React.FC = () => {
  const { keywordsControl } = useTradeTicket();

  return (
    <TabContentContainer>
      <FormPanel
        numColumns={1}
        style={{ height: "100%" }}
        contentStyle={{ height: "100%" }}
        data-e2d-id="trade-keyowrds-tab"
      >
        <KeywordsTabCore keywordsControl={keywordsControl} />
      </FormPanel>
    </TabContentContainer>
  );
};
