import {
  getDetailRowHeight,
  REPORT_HEADER_ROW_HEIGHT,
  useTheme,
} from "@enfusion-ui/web-core";
import {
  GridOptions,
  GridReadyEvent,
  IRowNode,
  MenuItemDef,
  RowClassParams,
} from "ag-grid-community";
import { debounce } from "lodash";
import * as React from "react";

import { ReportGrid, ReportGridProps } from "./ReportGrid";
import ReportSearchBar from "./ReportSearchBar";

export type SearchableReportViewProps = GridOptions &
  ReportGridProps & {
    height?: string;
    width?: string;
    rounded?: boolean;
    isMobile: boolean;
    isWidget?: boolean;
    breakWidth?: number;
    showSearchBar?: boolean;
    showRowCount?: boolean;
    noBackground?: boolean;
    showGroupingRow?: boolean;
    getContextMenu?: (e: (string | MenuItemDef)[]) => (string | MenuItemDef)[];
  };

export const SearchableReportView: React.FC<SearchableReportViewProps> = ({
  rowData,
  reportId,
  onGridReady,
  height = "200px",
  width = "100%",
  showSearchBar = true,
  isMobile,
  showRowCount = true,
  isWidget = false,
  rounded = false,
  noBackground = false,
  showGroupingRow = false,
  breakWidth,
  ...rest
}) => {
  const { theme } = useTheme();
  const [matchSearchTextCase, setMatchSearchTextCase] =
    React.useState<boolean>(false);

  const searchText = React.useRef<string>("");
  const gridApiRef = React.useRef<GridReadyEvent | null>(null);
  const matchedRows = React.useRef<Array<number>>([]);
  const currentHighlightedRowIndex = React.useRef<number>();
  const highlightFilteredRows = React.useRef<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const oldMatchedRows = React.useRef<IRowNode<any>[]>([]);

  React.useEffect(() => {
    searchText.current && onSearchTextChanged(searchText.current);
  }, [matchSearchTextCase]);

  const handleGridReady = React.useCallback(
    (api: GridReadyEvent) => {
      gridApiRef.current = api;
      onGridReady?.(api);
    },
    [onGridReady]
  );

  const debouncedSearchTextChanged = debounce((value: string) => {
    searchText.current = value;
    //save previously matched rows
    oldMatchedRows.current = [];
    matchedRows.current?.forEach((index) => {
      const row = gridApiRef.current?.api.getDisplayedRowAtIndex(index);
      row && oldMatchedRows.current.push(row);
    });

    //filter the rows based on search text
    matchedRows.current = [];
    const text = matchSearchTextCase ? value : new RegExp(value, "i");
    if (searchText.current?.length > 0) {
      gridApiRef.current?.api.forEachNode((node) => {
        if (node.data) {
          for (const key in node.data) {
            if (
              node.rowIndex !== null &&
              ((node.data[key]?.value &&
                node.data[key].value.toString().search(text) !== -1) ||
                (node.data[key]?.formattedValue &&
                  node.data[key].formattedValue.toString().search(text) !== -1))
            ) {
              matchedRows.current.push(node.rowIndex);
              break;
            }
          }
        }
      });
    }

    //redraw the rows - this will invoke getRowClass callback
    if (matchedRows.current.length > 0) {
      matchedRows.current.sort((a, b) => a - b);

      if (highlightFilteredRows.current === true) {
        highlightAllToggled(true);
      } else {
        currentHighlightedRowIndex.current = matchedRows.current[0];
        const row = gridApiRef.current?.api.getDisplayedRowAtIndex(
          matchedRows.current[0]
        );
        row &&
          gridApiRef.current?.api.redrawRows({
            rowNodes: [...oldMatchedRows.current, row],
          });
        gridApiRef.current?.api.ensureIndexVisible(
          matchedRows.current[0],
          "middle"
        );
      }
    } else {
      currentHighlightedRowIndex.current = -1;
      gridApiRef.current?.api.redrawRows({
        rowNodes: [...oldMatchedRows.current],
      });
    }
  }, 500);

  const onSearchTextChanged = React.useCallback(
    (value: string) => {
      debouncedSearchTextChanged(value);
    },
    [gridApiRef.current, matchSearchTextCase]
  );

  const navigateToNextMatchedRows = React.useCallback(
    (direction: "up" | "down") => {
      if (
        currentHighlightedRowIndex.current !== undefined &&
        searchText.current?.length > 0
      ) {
        const currentSelection = gridApiRef.current?.api.getDisplayedRowAtIndex(
          currentHighlightedRowIndex.current
        );
        const index = matchedRows.current.findIndex(
          (value) => currentHighlightedRowIndex.current === value
        );
        if (direction === "up") {
          currentHighlightedRowIndex.current =
            index === 0
              ? matchedRows.current[matchedRows.current?.length - 1]
              : matchedRows.current[index - 1];
        } else {
          currentHighlightedRowIndex.current =
            index === matchedRows.current?.length - 1
              ? matchedRows.current[0]
              : matchedRows.current[index + 1];
        }
        const nextSelection = gridApiRef.current?.api.getDisplayedRowAtIndex(
          currentHighlightedRowIndex.current
        );

        nextSelection &&
          gridApiRef.current?.api.redrawRows({
            rowNodes: currentSelection
              ? [currentSelection, nextSelection]
              : [nextSelection],
          });
        gridApiRef.current?.api.ensureIndexVisible(
          currentHighlightedRowIndex.current,
          "middle"
        );
      }
    },
    [gridApiRef.current]
  );

  const highlightAllToggled = React.useCallback((value: boolean) => {
    highlightFilteredRows.current = value;
    if (searchText.current) {
      // filter out the rowNodes using the matchedRows array
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filteredRows: IRowNode<any>[] = [];
      matchedRows.current?.forEach((index) => {
        const row = gridApiRef.current?.api.getDisplayedRowAtIndex(index);
        row && filteredRows.push(row);
      });
      //redraw all the filtered rows, this will invoke the getRowClass callback
      currentHighlightedRowIndex.current = matchedRows.current[0];
      gridApiRef.current?.api.redrawRows({
        rowNodes: [...oldMatchedRows.current, ...filteredRows],
      });
    }
  }, []);

  const getRowClass = React.useCallback(
    ({ node }: RowClassParams) => {
      if (
        node.rowIndex !== null &&
        !node.rowPinned &&
        ((highlightFilteredRows.current &&
          matchedRows.current?.indexOf(node.rowIndex) !== -1) ||
          (!highlightFilteredRows.current &&
            currentHighlightedRowIndex.current === node.rowIndex))
      ) {
        return "grid-highlighted-row";
      }
      return "";
    },
    [highlightFilteredRows.current]
  );

  return (
    <>
      <div
        style={{
          flex: "1 1 auto",
          position: "relative",
          height,
          width,
        }}
        className={isWidget ? undefined : theme.agGridClass}
        data-e2e-id="report-grid-container"
      >
        <ReportGrid
          reportId={reportId}
          onGridReady={handleGridReady}
          getRowClass={getRowClass}
          getRowHeight={getDetailRowHeight}
          headerHeight={REPORT_HEADER_ROW_HEIGHT}
          rowData={rowData}
          rounded={rounded}
          isWidget={isWidget}
          showGroupingRow={showGroupingRow}
          {...rest}
        />
      </div>
      <ReportSearchBar
        reportId={reportId}
        onTextChanged={onSearchTextChanged}
        onMatchCaseChanged={(value) => setMatchSearchTextCase(value)}
        onNavigatingUpDown={navigateToNextMatchedRows}
        moveNext={() => navigateToNextMatchedRows("down")}
        onHighlightFlagToggle={highlightAllToggled}
        searchVisible={showSearchBar}
        countVisible={showRowCount}
        isMobile={isMobile}
        isWidget={isWidget}
        rowCount={rowData?.length}
        width={width}
        breakWidth={breakWidth}
        noBackground={noBackground}
      />
    </>
  );
};
