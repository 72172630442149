import GeneralInfoPanel, {
  AllocationReasonSelect,
  ComplianceNotesInput,
  InstructionsInput,
  OfferingTypeSelect,
  ParentOrderInput,
  TradeReasonSelect,
  WorkFlowStateSelect,
} from "@app-views/oems/OrderTicket/panels/GeneralInfoPanel";
import { BoldText } from "@app-views/watchList/components/styles";
import { WidgetComponentsDefinition } from "@enfusion-ui/dashboards";
import { ColumnGrid, Select } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";

const TextAreaContainer = styled.div`
  height: 100%;
  display: flex;
`;

const GeneralInfoPanelMobile: React.FC = () => {
  return (
    <>
      <BoldText style={{ textAlign: "center", marginTop: 8 }}>
        General Info
      </BoldText>
      <ColumnGrid isMobile={false} numColumns={2}>
        <Select minWidth={100} label="Trade Reason" disabled />
        <Select minWidth={100} label="Alloc Reason" disabled />
      </ColumnGrid>
      <ColumnGrid isMobile={false} numColumns={1}>
        <InstructionsInput disabled />
      </ColumnGrid>
    </>
  );
};

export const generalInfoPanel: WidgetComponentsDefinition = {
  renderComponent: GeneralInfoPanel,
  renderMobileComponent: GeneralInfoPanelMobile,
};
export const workflowStateSelect: WidgetComponentsDefinition = {
  renderComponent: () => <WorkFlowStateSelect disabled={false} />,
};
export const offeringTypeSelect: WidgetComponentsDefinition = {
  renderComponent: () => <OfferingTypeSelect disabled={false} />,
};
export const parentOrderInput: WidgetComponentsDefinition = {
  renderComponent: () => <ParentOrderInput disabled={false} />,
};
export const tradeReasonSelect: WidgetComponentsDefinition = {
  renderComponent: () => <TradeReasonSelect disabled={false} />,
  renderMobileComponent: () => (
    <Select minWidth={100} label="Trade Reason" disabled />
  ),
};
export const allocationReasonSelect: WidgetComponentsDefinition = {
  renderComponent: () => <AllocationReasonSelect disabled={false} />,
  renderMobileComponent: () => (
    <Select minWidth={100} label="Alloc Reason" disabled />
  ),
};
export const instructionsInput: WidgetComponentsDefinition = {
  renderComponent: () => (
    <TextAreaContainer>
      <InstructionsInput disabled={false} />
    </TextAreaContainer>
  ),
  renderMobileComponent: () => (
    <TextAreaContainer>
      <InstructionsInput disabled />
    </TextAreaContainer>
  ),
};
export const complianceNotesInput: WidgetComponentsDefinition = {
  renderComponent: () => (
    <TextAreaContainer>
      <ComplianceNotesInput disabled={false} />
    </TextAreaContainer>
  ),
};
