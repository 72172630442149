import { pxToPtConversion } from "@app-views/dashboards/pdf/utils";
import { ThemeDefinition } from "@enfusion-ui/core";
import { TileWidgetStyles, useWidget } from "@enfusion-ui/dashboards";
import { withTheme } from "@enfusion-ui/web-core";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import * as React from "react";

const pdfStyles = StyleSheet.create({
  tileContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
    height: "100%",
    borderRadius: pxToPtConversion(5),
    minWidth: pxToPtConversion(86),
    minHeight: pxToPtConversion(57),
  },
  headerContainer: {
    width: "100%",
    minHeight: "50%",
    paddingHorizontal: pxToPtConversion(8),
  },
  valueContainer: {
    width: "100%",
    minHeight: "40%",
    paddingHorizontal: pxToPtConversion(8),
  },
  headerText: {
    fontFamily: "Lato",
  },
  valueText: {
    fontFamily: "Lato",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const FONTSIZE_UNIT_MAP = {
  medium: 16,
  large: 20,
  "x-large": 24,
  small: 12,
  "x-small": 8,
};

const FONTWEIGHT_UNIT_MAP = {
  bolder: "extrabold",
  bold: "bold",
  normal: "normal",
  lighter: "extralight",
};

type FONTWEIGHT_TYPE = "extrabold" | "bold" | "normal" | "extralight";

const PdfTile = withTheme(
  ({
    header,
    value,
    isNegative,
    theme,
  }: {
    header: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    isNegative: boolean;
    theme: ThemeDefinition;
  }) => {
    const { config } = useWidget();
    const {
      showBorder = true,
      borderColor,
      negativeBorderColor,
      titleColor,
      negativeTitleColor,
      justifyTitle,
      alignTitle,
      titleFontSize,
      alignValue,
      justifyValue,
      valueFontSize,
      valueColor,
      negativeValueColor,
      titleFontWeight,
      valueFontWeight,
      titleHeight,
      titleShowBackground = false,
    } = config as TileWidgetStyles;

    return (
      <View
        style={[
          pdfStyles.tileContainer,
          {
            backgroundColor: theme.colors.backgroundColor1,
          },
          showBorder
            ? {
                borderLeft: `1.5px solid ${
                  isNegative
                    ? negativeBorderColor
                    : borderColor
                    ? borderColor
                    : theme.colors.backgroundAccent
                }`,
              }
            : {},
        ]}
      >
        <View
          style={[
            pdfStyles.headerContainer,
            {
              // justifyContent and alignItems are reversed
              alignItems: justifyTitle ? justifyTitle : "flex-start",
              justifyContent: alignTitle ? alignTitle : "center",
              backgroundColor: titleShowBackground
                ? theme.colors.backgroundColor1Hover
                : "transparent",
            },
            titleHeight
              ? {
                  flex: titleHeight === "half" ? 1 : undefined,
                  display: titleHeight === "none" ? "none" : undefined,
                  padding:
                    titleHeight !== "half" && titleHeight !== "none"
                      ? "4 8"
                      : undefined,
                }
              : {},
          ]}
        >
          <Text
            style={[
              pdfStyles.headerText,
              {
                fontSize: titleFontSize ? FONTSIZE_UNIT_MAP[titleFontSize] : 16,
                fontWeight: titleFontWeight
                  ? (FONTWEIGHT_UNIT_MAP[titleFontWeight] as FONTWEIGHT_TYPE)
                  : "normal",
                color: negativeTitleColor
                  ? negativeTitleColor
                  : titleColor
                  ? titleColor
                  : theme.colors.primary,
              },
            ]}
          >
            {header}
          </Text>
        </View>
        <View
          style={[
            pdfStyles.valueContainer,
            {
              // justifyContent and alignItems are reversed
              alignItems: justifyValue ? justifyValue : "flex-start",
              justifyContent: alignValue ? alignValue : "center",
            },
          ]}
        >
          <Text
            style={[
              pdfStyles.valueText,
              {
                fontSize: valueFontSize ? FONTSIZE_UNIT_MAP[valueFontSize] : 16,
                fontWeight: valueFontWeight
                  ? (FONTWEIGHT_UNIT_MAP[valueFontWeight] as FONTWEIGHT_TYPE)
                  : "normal",
                color: isNegative
                  ? negativeValueColor
                  : valueColor
                  ? valueColor
                  : theme.colors.textNormal,
              },
            ]}
          >
            {value}
          </Text>
        </View>
      </View>
    );
  }
);

export default PdfTile;
