import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import { fetchInstrument } from "@app-views/oems/utils/actions";
import {
  BlotterRow,
  OrderFormProvider,
  OrderFormProviderProps,
  OrderFormValues,
  UNKNOWN_INSTRUMENT_ID,
  useOEMSOrderForm,
} from "@enfusion-ui/core";
import { usePrevious, useRefCallback } from "@enfusion-ui/hooks";
import { ConfirmationModal } from "@enfusion-ui/web-components";
import {
  errorToast,
  OEMS_EVENTS,
  OemsBroadcastEvent,
  restServer,
  successToast,
  useOEMS,
  useThisTab,
  warningToast,
} from "@enfusion-ui/web-core";
import { useWorkerModule } from "@enfusion-ui/web-workers";
import { debounce, isEqual } from "lodash";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useUnmount } from "react-use";

export type OrderFormSyncState = {
  postOrderFormState: (currentState: OrderFormValues | null) => void;
};

// order form sync context
export const OrderFormSyncContext = React.createContext<
  OrderFormSyncState | undefined
>(undefined);

export function useOrderFormSync() {
  const context = React.useContext(OrderFormSyncContext);
  if (context === undefined) {
    throw new Error(
      "useOrderFormSync must be used within a OrderFormSyncProvider within a OrderFormProvider"
    );
  }
  return context;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filteredObject = (order: Record<string, any>) => {
  const {
    instrument,
    swapOrder,
    orderSide,
    quantity,
    qoeValue,
    assignedTradingDesk,
    orderType,
    limitPrice,
    notional,
  } = order;
  return {
    instrument,
    swapOrder,
    orderSide,
    quantity,
    qoeValue,
    assignedTradingDesk,
    orderType,
    limitPrice,
    notional,
  };
};

const InnerContext = React.createContext<{
  syncedOrder: null | OrderFormValues;
}>({ syncedOrder: null });

export const OrderFormSyncProviderCore: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { orderId, reInitForm } = useOEMSOrderForm();
  const { isInitialized } = useOEMS();
  const { watch, reset } = useFormContext();
  const { postMessage } = useWorkerModule("keyValue");
  const { syncedOrder } = React.useContext(InnerContext);
  const { config } = useThisTab();
  const prevConfig = usePrevious(config);

  const [showOverrideFormConfirm, setShowOverrideFormConfirm] =
    React.useState(false);
  const [overrideConfirmed, setOverrideConfirmed] =
    React.useState<boolean>(false);

  const watchAllFields = watch();
  const watchAllFieldsRef = React.useRef(watchAllFields);
  watchAllFieldsRef.current = watchAllFields;

  useBroadcastChannel<OemsBroadcastEvent>(OEMS_EVENTS, async (e) => {
    if (e.action === "instrument-selected") {
      const { instrumentId, ...formValues } = e.payload;
      if (instrumentId) {
        formValues.instrument = await fetchInstrument(instrumentId);
      }
      reset({ ...watchAllFieldsRef.current, ...formValues });
    }
  });

  React.useEffect(() => {
    checkForUpdates();
  }, [syncedOrder]);

  const checkForUpdates = React.useCallback(
    debounce(() => {
      if (syncedOrder) {
        const currentState = filteredObject(watchAllFields);
        const workerState = filteredObject(syncedOrder);

        if (
          !isEqual(currentState, workerState) &&
          !isEqual(prevConfig, config)
        ) {
          setShowOverrideFormConfirm(true);
        }
      }
    }, 1000),
    [syncedOrder]
  );

  const postOrderFormState = useRefCallback(
    (currentState: OrderFormValues | null) => {
      //avoiding posting to worker unless user select instrument
      if (currentState === null) {
        postMessage({
          command: "remove",
          payload: {
            key: orderId,
            type: "order",
          },
        });
      } else if (
        currentState?.instrument &&
        currentState?.instrumentId !== null &&
        currentState?.instrumentId !== UNKNOWN_INSTRUMENT_ID
      ) {
        postMessage({
          command: "set",
          payload: {
            value: currentState,
            key: orderId,
            type: "order",
          },
        });
      }
    },
    [orderId]
  );

  const handleOverrideForm = React.useCallback(() => {
    setOverrideConfirmed(true);
    if (syncedOrder) reset(syncedOrder);
    setShowOverrideFormConfirm(false);
  }, [syncedOrder]);

  const cancelOverrideForm = React.useCallback(() => {
    setShowOverrideFormConfirm(false);
  }, []);

  React.useEffect(() => {
    if (!!prevConfig && !isEqual(config, prevConfig))
      setShowOverrideFormConfirm(true);
  }, [config]);

  React.useEffect(() => {
    if ((config && !prevConfig) || (config && overrideConfirmed)) {
      if (config?.dismissToast) config.dismissToast();
      if (isInitialized) reInitForm();
    }
    setOverrideConfirmed(false);
  }, [overrideConfirmed, config]);

  return (
    <OrderFormSyncContext.Provider value={{ postOrderFormState }}>
      {children}
      <ConfirmationModal
        open={showOverrideFormConfirm}
        onSubmit={handleOverrideForm}
        onCancel={cancelOverrideForm}
        title="Warning"
        submitActionTheme="warning"
      >
        There are changes in the form state. Do you want to override the order
        ticket?
      </ConfirmationModal>
    </OrderFormSyncContext.Provider>
  );
};

export const OrderFormSyncProvider: React.FC<
  React.PropsWithChildren<
    Omit<
      OrderFormProviderProps,
      | "getFormState"
      | "isInitialized"
      | "restServer"
      | "errorToast"
      | "successToast"
      | "warningToast"
      | "flags"
    >
  >
> = ({ children, orderId, ...props }) => {
  const { isInitialized, setNewOrderTicketReady } = useOEMS();
  const { postMessage, subscribeToModule } = useWorkerModule("keyValue");
  const [syncedOrder, setSyncedOrder] = React.useState<
    [number, OrderFormValues] | null
  >(null);

  const [routesList, setRoutesList] = React.useState<BlotterRow[]>([]);

  const { postMessage: postOEMSMessage } = useWorkerModule("oms");

  const onNewOrderFormReady = useRefCallback(
    () => setNewOrderTicketReady(true),
    [setNewOrderTicketReady]
  );

  useUnmount(() => setNewOrderTicketReady(false));

  React.useEffect(() => {
    const getRoutesList = async () => {
      try {
        const res = postOEMSMessage({
          command: "routes",
          payload: { orderId },
        });
        if (res) {
          const {
            payload: { rows },
          } = await res;

          setRoutesList(rows);
        }
      } catch (error) {
        console.error("Error when fetching routes for order id: ", orderId);
      }
    };

    getRoutesList();
  }, [orderId]);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return subscribeToModule(async (message: any) => {
      const { type, payload } = message;
      switch (type) {
        case "sync-value": {
          const { key, type, date, value } = payload;
          if (type === "order" && orderId === key) {
            setSyncedOrder([date, value]);
          }
        }
      }
    });
  }, []);

  const getInitialValue = useRefCallback(async () => {
    try {
      const { payload } = await postMessage({
        command: "get",
        payload: {
          key: orderId,
          type: "order",
        },
      });
      setSyncedOrder(payload ? [payload.date, payload.value] : null);
      return payload ? payload.value : null;
    } catch (err) {
      console.warn(`Failed to get value: ${orderId}`, err);
      return null;
    }
  }, [orderId]);

  return (
    <OrderFormProvider
      {...props}
      getFormState={getInitialValue}
      {...{
        errorToast,
        successToast,
        warningToast,
        isInitialized,
        restServer,
        orderId,
        getRoutes: (orderId: number) =>
          routesList.filter(
            (eachRoute: BlotterRow) =>
              eachRoute.columnValues.ParentOrderId === orderId
          ),
      }}
      flags={{
        fxAllowed: true,
        cdxAllowed: true,
        varSwapAllowed: !!process.env.REACT_APP_ENABLE_VAR_SWAP_ORDERS,
        irsAllowed: !!process.env.REACT_APP_ENABLE_IRS_ORDERS,
      }}
      onNewOrderFormReady={onNewOrderFormReady}
    >
      <InnerContext.Provider
        value={{ syncedOrder: syncedOrder ? syncedOrder[1] : null }}
      >
        <OrderFormSyncProviderCore>{children}</OrderFormSyncProviderCore>
      </InnerContext.Provider>
    </OrderFormProvider>
  );
};
