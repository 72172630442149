import { ConnectionStatus } from "@enfusion-ui/types";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { ConnectionStatusItem } from "../data/connectionStatusText";

const ConnectionDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;
const ConnectionDetailsTitle = styled.span`
  font-weight: bold;
`;
const ConnectionDetailsDescription = styled.span`
  font-size: small;
`;

export const ConnectionStatusDetails: React.FC<{
  item: ConnectionStatusItem;
  status?: ConnectionStatus;
}> = ({ item, status }) => (
  <ConnectionDetails>
    <ConnectionDetailsTitle data-e2e-id="connection-status-text">
      {item[`${status}`].title}
    </ConnectionDetailsTitle>
    <ConnectionDetailsDescription>
      {item[`${status}`].description}
    </ConnectionDetailsDescription>
  </ConnectionDetails>
);

export default ConnectionStatusDetails;
