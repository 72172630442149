import { useDimensions } from "@enfusion-ui/web-core";
import * as React from "react";
import ContentLoader, { IContentLoaderProps } from "react-content-loader";

const rowPadding = 15;
const rowContentHeight = 15;
const borderHeight = 2;
const circleRadius = 11;
const headerHeight = 52;
const borderRadius = 3;
const hPadding = 30;

const rowInnerHeight = rowPadding * 2 + rowContentHeight;
const rowHeight = rowInnerHeight + borderHeight;
const circleOffset = rowInnerHeight / 2;
const circleHOffset = rowPadding / 2 + circleRadius;

const spacesAll = [141, 299, 141, 180, 180, 299, 141, 141, 180, 299];
const items = [1, 1, 2, 1, 1, 1, 2, 2, 1, 1];

const Row = ({
  index,
  width,
  spaces,
}: {
  index: number;
  width: number;
  spaces: number[];
}) => {
  const yTop = headerHeight + index * rowHeight;

  const result = spaces.reduce(
    (res, entry, idx) => {
      if (items[idx] === 1) {
        res[0].push(
          <rect
            key={`${idx}-${entry}`}
            x={res[1] + hPadding}
            y={yTop + rowPadding}
            rx={borderRadius}
            ry={borderRadius}
            width={entry}
            height={rowContentHeight}
          />
        );
      } else {
        const half = entry / 2;
        const middle = res[1] + hPadding + half;
        res[0].push(
          <circle
            key={`${idx}-${entry}-1`}
            cx={middle - circleHOffset}
            cy={yTop + circleOffset}
            r={circleRadius}
          />
        );
        res[0].push(
          <circle
            key={`${idx}-${entry}-2`}
            cx={middle + circleHOffset}
            cy={yTop + circleOffset}
            r={circleRadius}
          />
        );
      }
      res[1] = res[1] + hPadding * 2 + entry;
      return res;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [[], borderHeight] as any[]
  )[0];

  return (
    <>
      {result}
      <rect
        x="0"
        y={yTop + rowInnerHeight}
        rx={borderRadius}
        ry={borderRadius}
        width={width}
        height={borderHeight}
      />
    </>
  );
};

const TableLoaderCore = ({
  rows = 1,
  width = 906,
  ...props
}: Omit<IContentLoaderProps, "width"> & { rows?: number; width?: number }) => {
  const spaces = React.useMemo(() => {
    let availableWidth = width - borderHeight * 2;
    let idx = 0;
    const res: number[] = [];
    while (availableWidth > 0) {
      res.push(spacesAll[idx]);
      availableWidth -= spacesAll[idx];
      idx += 1;
    }
    return res;
  }, [width]);

  const headerRow = spaces.reduce(
    (res, entry, idx) => {
      res[0].push(
        <rect
          key={`${idx}-1`}
          x={res[1]}
          y="17"
          rx="0"
          ry="0"
          width={hPadding + entry / 10}
          height="18"
        />
      );
      res[0].push(
        <rect
          key={`${idx}-2`}
          x={res[1] + hPadding + (entry / 10) * 9}
          y="17"
          rx="0"
          ry="0"
          width={hPadding + entry / 10}
          height="18"
        />
      );
      res[1] = res[1] + hPadding * 2 + entry;
      return res;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [[], borderHeight] as any[]
  )[0];

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={headerHeight + rows * rowHeight}
      viewBox={`0 0 ${width} ${headerHeight + rows * rowHeight}`}
      backgroundColor="var(--background-color-1)"
      foregroundColor="var(--background-color-0)"
      animate
      {...props}
    >
      <rect x="0" y="0" rx="3" ry="3" width={width} height="17" />
      {headerRow}
      <rect x="0" y="35" rx="0" ry="0" width={width} height="17" />

      <rect
        x="0"
        y="3"
        rx="3"
        ry="3"
        width="2"
        height={headerHeight + rows * rowHeight - 3}
      />
      <rect
        x={width - 2}
        y="3"
        rx="3"
        ry="3"
        width="2"
        height={headerHeight + rows * rowHeight - 3}
      />

      {new Array(rows).fill("-").map((_, idx) => (
        <Row key={idx} index={idx} width={width} spaces={spaces} />
      ))}
    </ContentLoader>
  );
};

export const TableLoader = ({
  rows = 1,
  fillArea = false,
  ...props
}: Omit<IContentLoaderProps, "width"> & {
  rows?: number;
  fillArea?: boolean;
  width?: number;
}) => {
  const { width, height } = useDimensions();
  const fillHeight = Math.max(height || 0, headerHeight + rowHeight);
  let rowCount = fillArea
    ? Math.floor((fillHeight - headerHeight) / rowHeight)
    : rows;
  return (
    <TableLoaderCore
      {...props}
      rows={rowCount}
      width={fillArea ? width || props.width : props.width}
      // height={fillArea ? fillHeight || props.height : props.height}
    />
  );
};
