import { useReconciliation } from "@app-context/reconciliation/context";
import { useReportRows } from "@app-context/reports/ReportsProvider";
import {
  ValueContainer,
  ValueContent,
  ValueHeader,
} from "@app-views/reconciliation/components/styles";
import { BreakDetailsCellRendererProps } from "@app-views/reconciliation/types";
import {
  getBreaksData,
  getFilteredData,
} from "@app-views/reconciliation/utils/commonUtils";
import { formatCurrency } from "@enfusion-ui/utils";
import React from "react";

export type CashBalanceWidgetProps = {
  data: BreakDetailsCellRendererProps;
  id: string;
};

const CashBalanceWidget: React.FC<CashBalanceWidgetProps> = ({ data, id }) => {
  const { config, metadata, filterState } = useReconciliation();
  const { filterColumns, impactColumns, reportData } = data;
  const { rowsStore } = useReportRows();

  const cashBalance = React.useMemo(() => {
    if (
      !filterState.currency?.value ||
      !filterState.serviceAccount?.value?.length
    )
      return "-";
    const cashBalanceColumnId = config.reconReports.find(
      (r) => r.reconReportId === id
    )?.impactColumns?.cashBalance;
    if (!cashBalanceColumnId) return "-";

    const cashBalanceReportData = reportData.cashBalanceRowsStore;
    if (!cashBalanceReportData) return "-";
    const cashBalanceFilteredData = getFilteredData(
      filterState,
      getBreaksData(cashBalanceReportData),
      { ...filterColumns, currency: impactColumns?.cashBalanceCurrency }
    );
    const cashBalanceValue = cashBalanceFilteredData.reduce((res, current) => {
      const columnValue = current.columnValues[cashBalanceColumnId]?.value;
      return res + (typeof columnValue === "number" ? columnValue : 0);
    }, 0);
    return formatCurrency(cashBalanceValue, filterState.currency.value, {
      fractionDigits: 2,
    });
  }, [rowsStore, config, filterState, metadata]);

  return (
    <ValueContainer>
      <ValueHeader>Cash Balance</ValueHeader>
      <ValueContent>{cashBalance}</ValueContent>
    </ValueContainer>
  );
};

export default CashBalanceWidget;
