import { TradeTicketFormTypes, useTradeTicket } from "@enfusion-ui/trades";
import { FormPanel } from "@enfusion-ui/web-components";
import * as React from "react";

import { StyledFormElementHeader } from "../../../components/styledComponents";
import { AssetSwapOptionTerms } from "./AssetSwapOptionTerms";
import { DualDigitalOptionTerms } from "./DualDigitalOptionTerms";
import { GeneralTerms } from "./GeneralTerms";
import { OTCRealizedVarTerms } from "./OTCRealizedVarTerms";

const TermOptionsInputs: React.FC<unknown> = () => {
  const { formDataType } = useTradeTicket();

  switch (formDataType) {
    case TradeTicketFormTypes.OTC_OPTION:
    case TradeTicketFormTypes.EQUITY_FLEX_OPTION:
    case TradeTicketFormTypes.OTC_SPREAD_OPTIONS:
    case TradeTicketFormTypes.OTC_OUTPERFORMANCE_OPTION:
      return <GeneralTerms />;
    case TradeTicketFormTypes.ASSET_SWAP_CONVERTIBLE_OPTION:
      return <AssetSwapOptionTerms />;
    case TradeTicketFormTypes.OTC_REALIZED_VARIANCE_OPTION:
      return <OTCRealizedVarTerms />;
    case TradeTicketFormTypes.NEW_DUAL_DIGITAL_OPTION:
      return <DualDigitalOptionTerms />;
    default:
      return null;
  }
};

export const TermsPanel: React.FC<{}> = () => {
  return (
    <FormPanel
      title="Option Details"
      numColumns={1}
      collapsible
      defaultOpen
      keepRendered
      defaultRendered
      lineStyle
      tabFocusOutline={false}
      dataE2EId="terms-panel"
    >
      <StyledFormElementHeader>Terms</StyledFormElementHeader>
      <TermOptionsInputs />
    </FormPanel>
  );
};
