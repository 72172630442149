import { WidgetComponentsDefinition } from "../../types";
import PdfTileWidget from "./PdfTileWidget";
import TileEditWidget from "./TileEditWidget";
import TileWidget from "./TileWidget";

export const tile: WidgetComponentsDefinition = {
  pdfContentAsync: true,
  renderComponent: TileWidget,
  editComponent: TileEditWidget,
  renderPdfComponent: PdfTileWidget,
};
