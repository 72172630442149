import { OEMS_FILTERS, } from "@enfusion-ui/web-core";
import { SocketController } from "../utils/socketController";
import { getOmsBlotterRows, setOmsBlotterRows } from "./oms.module.store";
import { WebWorkerMessenger, } from "./types";
const messenger = new WebWorkerMessenger();
const MessageType = {
    subscribe: "subscribe",
    unsubscribe: "unsubscribe",
    stateUpdate: "state-update",
    blotterSync: "blotter-sync",
    blotterRowsUpdate: "blotter-rows-update",
    blotterRowUpdate: "blotter-row-update",
    blotterRowRemove: "order-delete",
    initStatus: "init-status",
    initStatusCompleted: "init-status-completed",
    close: "close",
    error: "error",
    status: "status",
    routes: "routes",
    fetchCount: "fetch-count",
};
let oemsContextState = {
    isInitialized: false,
    hasError: false,
    errorMessage: null,
    readyState: undefined,
};
function reset(readyState) {
    oemsContextState = {
        isInitialized: false,
        hasError: false,
        errorMessage: null,
        readyState,
    };
    setOmsBlotterRows([]);
}
// function resetSocket(socket: WebSocket) {
//   reset();
//   socket.close();
//   setTimeout(() => socketController.init(), RECONNECT_INTERVAL);
// }
const socketController = new SocketController({
    path: `wss://${self.location.host}/oms`,
    onOpen: (socket) => {
        socket.send(JSON.stringify({
            command: "CONNECT",
            "accept-version": "1.2",
            "sub-to": "/oms",
        }));
        oemsContextState = {
            ...oemsContextState,
            readyState: socket.readyState,
        };
        messenger.broadcast({
            type: MessageType.stateUpdate,
            payload: { readyState: socket.readyState },
        });
    },
    onMessage: async (event, socket) => {
        const eventMessage = JSON.parse(event.data);
        //should not send message if some provider has terminated the worker/socket
        if (eventMessage.command === "MESSAGE" &&
            socket.readyState !== WebSocket.CLOSED &&
            socket.readyState !== WebSocket.CLOSING) {
            switch (eventMessage.payload.type) {
                case MessageType.initStatus: {
                    const { payload: { error, completed, message }, } = eventMessage;
                    if (error) {
                        // resetting socket
                        // resetSocket(socket);
                        reset(socket.readyState);
                        oemsContextState = {
                            ...oemsContextState,
                            hasError: true,
                            errorMessage: message,
                        };
                        messenger.broadcast({
                            type: MessageType.error,
                            payload: {
                                hasError: true,
                                errorMessage: message,
                            },
                        });
                        break;
                    }
                    if (completed) {
                        socket.send(JSON.stringify({
                            command: "SUBSCRIBE",
                            destination: "/oms/blotter/complete",
                        }));
                    }
                    messenger.broadcast({
                        type: MessageType.initStatus,
                        payload: {
                            message: message,
                            completed,
                        },
                    });
                    break;
                }
                case MessageType.status: {
                    const { payload: { error }, } = eventMessage;
                    if (error) {
                        setOmsBlotterRows([]);
                        messenger.broadcast({
                            type: MessageType.close,
                            payload: { readyState: socket.readyState },
                        });
                        socket.close();
                    }
                    break;
                }
                case MessageType.blotterSync: {
                    const { payload: { blotterRows }, } = eventMessage;
                    oemsContextState = { ...oemsContextState, isInitialized: true };
                    setOmsBlotterRows(blotterRows);
                    messenger.broadcast({
                        type: MessageType.blotterSync,
                        payload: {
                            isInitialized: true,
                            rows: blotterRows,
                        },
                    });
                    break;
                }
                // Market data updated
                case MessageType.blotterRowsUpdate: {
                    const { payload: { blotterRows }, } = eventMessage;
                    let existingBlotterRows = getOmsBlotterRows();
                    const transactionalRows = {
                        add: [],
                        update: [],
                        idx: {},
                    };
                    blotterRows.forEach((updatedRow) => {
                        const { orderId: updatedOrderId } = updatedRow;
                        const idx = existingBlotterRows.findIndex((row) => updatedOrderId === row.orderId);
                        if (idx > -1) {
                            transactionalRows.update.push(updatedRow);
                            transactionalRows.idx[updatedOrderId] = idx;
                        }
                        else
                            transactionalRows.add.push(updatedRow);
                    });
                    transactionalRows.update.forEach((e) => {
                        existingBlotterRows[transactionalRows.idx[e.orderId]] = e;
                    });
                    transactionalRows.add.forEach((e) => {
                        existingBlotterRows = [e, ...existingBlotterRows];
                    });
                    if (transactionalRows.add && transactionalRows.add.length > 0) {
                        setOmsBlotterRows(existingBlotterRows);
                    }
                    messenger.broadcast({
                        type: MessageType.blotterRowsUpdate,
                        payload: {
                            add: transactionalRows.add,
                            update: transactionalRows.update,
                        },
                    });
                    break;
                }
                case MessageType.blotterRowUpdate: {
                    const { payload: { newOrder, blotterRow }, } = eventMessage;
                    const existingBlotterRows = getOmsBlotterRows();
                    let index = -1;
                    if (newOrder) {
                        setOmsBlotterRows([blotterRow, ...existingBlotterRows]);
                    }
                    else {
                        index = existingBlotterRows.findIndex((row) => row.orderId === blotterRow.orderId);
                        if (index !== -1) {
                            existingBlotterRows[index] = blotterRow;
                        }
                    }
                    messenger.broadcast({
                        type: MessageType.blotterRowUpdate,
                        payload: {
                            newOrder,
                            row: blotterRow,
                        },
                    });
                    break;
                }
                case MessageType.blotterRowRemove: {
                    const { payload: { orderId }, } = eventMessage;
                    const existingBlotterRows = getOmsBlotterRows();
                    const index = existingBlotterRows.findIndex((row) => row.orderId === orderId);
                    const rowToDelete = existingBlotterRows[index];
                    if (index !== -1) {
                        existingBlotterRows.splice(index, 1);
                    }
                    messenger.broadcast({
                        type: MessageType.blotterRowRemove,
                        payload: { ...eventMessage.payload, rowToDelete },
                    });
                    break;
                }
                default: {
                    console.error(`Unexpected message type: ${JSON.stringify(eventMessage)}`);
                }
            }
        }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (err, socket) => {
        console.error(`Error in OMS Worker: ${JSON.stringify(err)}`);
        reset(socket.readyState);
        oemsContextState = {
            ...oemsContextState,
            hasError: true,
            errorMessage: err?.message || JSON.stringify(err),
        };
        messenger.broadcast({
            type: MessageType.error,
            payload: {
                hasError: true,
                errorMessage: err?.message || JSON.stringify(err),
            },
        });
    },
    onClose: (ev, socket) => {
        console.warn("OMS socket close", ev);
        reset(socket.readyState);
        messenger.broadcast({
            type: MessageType.close,
            payload: { readyState: socket.readyState },
        });
    },
    onDisconnect: (socket) => {
        reset(socket.readyState);
        messenger.broadcast({
            type: MessageType.close,
            payload: { readyState: socket.readyState },
        });
    },
});
export const OMSModule = {
    enable: (postMessage) => {
        messenger.send = postMessage;
        socketController.init();
    },
    disable: () => {
        socketController.close("oms");
        reset();
    },
    getCurrentState: () => {
        return {
            socketStatus: socketController.socketStatus,
            currentState: oemsContextState,
            rows: getOmsBlotterRows(),
        };
    },
    onTerminate: () => {
        socketController.close("oms");
    },
    onMessage: (_message, _hostId) => {
        const { payload, command } = _message;
        switch (command) {
            case MessageType.routes: {
                const { orderId } = payload;
                const omsBlotterRows = getOmsBlotterRows();
                return {
                    rows: omsBlotterRows.filter(({ columnValues }) => {
                        return (typeof orderId === "number" &&
                            (columnValues.ParentOrderId === orderId ||
                                (columnValues.Method !== "Undecided" &&
                                    columnValues.OrderId === orderId)));
                    }),
                };
            }
            case MessageType.fetchCount: {
                const { filterKey } = payload;
                const omsBlotterRows = getOmsBlotterRows();
                return {
                    count: (OEMS_FILTERS[filterKey].filter(omsBlotterRows) ??
                        []).length,
                };
            }
        }
    },
};
