import { SingleRowContainer } from "@app-views/dashboards/widgets/styles";
import { SecurityInfoPanelConfig, useWidget } from "@enfusion-ui/dashboards";
import { SelectOptionsType, ViewModeOptions } from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  FormSectionAccordion,
  MultiSelect,
  Select,
} from "@enfusion-ui/web-components";
import * as React from "react";

import { SecurityInfoPanelFields } from "./types";

const SecurityInfoEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();
  const { fields = [], viewMode = "horizontal" } =
    editedConfig as SecurityInfoPanelConfig;

  const handleSecurityKeysChange = (values: string[] | null) => {
    changeConfigKeyToBeApplied("fields", values ?? []);
  };
  const handleViewModeChange = (layout: SelectOptionsType<string> | null) => {
    changeConfigKeyToBeApplied("viewMode", layout?.value ?? []);
  };
  return (
    <>
      <FormSectionAccordion title="Security Panel Options">
        <SingleRowContainer noMargin>
          <MultiSelect
            value={getSelectedValues(fields || [], SecurityInfoPanelFields)}
            onChange={(options) =>
              handleSecurityKeysChange(options?.map((o) => o?.value) ?? null)
            }
            label="Security Keys"
            placeholder="All"
            options={SecurityInfoPanelFields}
            inputId="security-info-multi-selection-id"
          />
          <Select
            label="Layout"
            value={ViewModeOptions.find(({ value: val }) => viewMode === val)}
            options={ViewModeOptions}
            onChange={handleViewModeChange}
            inputId="view-mode-selection-id"
          />
        </SingleRowContainer>
      </FormSectionAccordion>
    </>
  );
};

export default SecurityInfoEditWidget;
