import ReportLoadingStatus from "@app-components/display/ReportLoadingStatus";
import { useReportRows } from "@app-context/reports/ReportsProvider";
import WidgetTitle from "@app-views/dashboards/components/WidgetTitle";
import { useReportDatasource } from "@app-views/dashboards/hooks/useReportDatasource";
import {
  GraphWidgetConfig,
  useDashboard,
  useWidget,
} from "@enfusion-ui/dashboards";
import { createTestId } from "@enfusion-ui/utils";
import {
  Button,
  CenterContent,
  ContentMessage,
  ContentMessageWrapper,
  ErrorBoundary,
  Spinner,
} from "@enfusion-ui/web-components";
import { styled, useAuth, useTheme } from "@enfusion-ui/web-core";
import {
  faRedoAlt,
  faSkullCrossbones,
  faTable,
} from "@fortawesome/pro-solid-svg-icons";
import { MenuItem } from "rc-menu";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
import { useMeasure } from "react-use";

import { WidgetContentContainerFill } from "../../../widget/components/WidgetContentContainer";
import { MenuItemsIcon, MenuItemsTitle } from "../../../widget/WidgetContainer";
import RefGraphRenderer from "./RefGraphRenderer";

const GraphInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const GraphWidget: React.FC<unknown> = () => {
  const { theme } = useTheme();
  const { config, addWidgetActions, setEditing } = useWidget();
  const { canEdit } = useDashboard();
  const { title, subTitle, datasourceId } = config as GraphWidgetConfig;
  const { ref: resizeRef } = useResizeDetector();
  const [containerLoaded, setContainerLoaded] = React.useState(false);
  const [measureRef, { width, height }] = useMeasure<HTMLDivElement>();
  const { isUserType } = useAuth();

  const {
    metadata,
    errorLoading,
    gridOptions,
    datasource,
    reloadReport,
    openDrillDown,
    tableId,
  } = useReportDatasource();

  const { rowsStore } = useReportRows();

  const rowOptions = tableId ? rowsStore?.[tableId] ?? null : null;

  const handleRef = (ref: HTMLDivElement) => {
    resizeRef.current = ref;
    measureRef(ref);
    setContainerLoaded(true);
  };

  React.useEffect(() => {
    addWidgetActions(
      !datasource
        ? null
        : {
            all: [
              <MenuItem key="refresh" onClick={reloadReport}>
                <MenuItemsIcon icon={faRedoAlt} />
                <MenuItemsTitle>Refresh</MenuItemsTitle>
              </MenuItem>,
              isUserType("Express") ? null : (
                <MenuItem key="drill-down" onClick={openDrillDown}>
                  <MenuItemsIcon icon={faTable} />
                  <MenuItemsTitle>Drill Down</MenuItemsTitle>
                </MenuItem>
              ),
            ],
          }
    );
  }, [!!datasource]);

  const isWidgetConfigurable = !datasourceId && canEdit;

  return (
    <ErrorBoundary
      showReset={false}
      showDetails={false}
      key={JSON.stringify(config)}
    >
      <WidgetContentContainerFill ref={handleRef}>
        {errorLoading ? (
          <CenterContent>
            <ContentMessage
              icon={faSkullCrossbones}
              message="We couldn't load this report data right now."
              details={errorLoading}
              parentHeight={height}
              parentWidth={width}
            />
          </CenterContent>
        ) : null}
        {datasourceId === null ? (
          <CenterContent>
            <ContentMessageWrapper
              isConfigurable={isWidgetConfigurable}
              onMouseDown={(e) => {
                if (isWidgetConfigurable) {
                  e.stopPropagation();
                  e.preventDefault();
                  setEditing(true);
                }
              }}
            >
              <ContentMessage
                message="Please configure Graph widget"
                size="3x"
                contentStyle={{ textAlign: "center" }}
                parentHeight={height}
                parentWidth={width}
              />
              {isWidgetConfigurable ? (
                <Button
                  theme="primary"
                  data-e2e-id="graph-widget-open-settings"
                  data-testid={createTestId("graph-widget-open-settings")}
                >
                  Open Settings
                </Button>
              ) : null}
            </ContentMessageWrapper>
          </CenterContent>
        ) : null}
        {!errorLoading ? (
          <GraphInnerContainer>
            <WidgetTitle title={title} subTitle={subTitle} />
            {datasourceId &&
            (!gridOptions || !containerLoaded || metadata?.loading) ? (
              <CenterContent>
                {metadata?.progressSteps ? (
                  <ReportLoadingStatus
                    steps={metadata.progressSteps}
                    parentHeight={height}
                    parentWidth={width}
                  />
                ) : (
                  <Spinner />
                )}
              </CenterContent>
            ) : (
              <RefGraphRenderer
                width={width!}
                height={height!}
                config={config as unknown as GraphWidgetConfig}
                gridOptions={gridOptions}
                theme={theme}
                rowOptions={rowOptions}
              />
            )}
          </GraphInnerContainer>
        ) : null}
      </WidgetContentContainerFill>
    </ErrorBoundary>
  );
};

export default GraphWidget;
