import AllocationsPanel from "@app-components/inputs/allocations/AllocationsPanel";
import { FormPanel, PanelRow } from "@enfusion-ui/web-components";
import * as React from "react";

export const Allocations: React.FC<unknown> = () => {
  return (
    <FormPanel
      title="Allocations"
      numColumns={1}
      collapsible
      defaultOpen
      keepRendered
      defaultRendered
      lineStyle
      tabFocusOutline={false}
    >
      <AllocationsPanel />
      <PanelRow style={{ padding: "var(--spacing-l) 0" }}>Total 0.00%</PanelRow>
    </FormPanel>
  );
};
