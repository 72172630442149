import { OrderFormValues } from "@enfusion-ui/core";
import { InstrumentInfo } from "@enfusion-ui/types";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import PositionFinderModal from "../../OrderTicketForm/components/PositionFinderModal";

const PositionSelectText = styled.span`
  color: var(--primary);
  cursor: pointer;
`;

export const SelectPositionAction: React.FC = () => {
  const { setValue } = useFormContext<OrderFormValues>();
  const [positionFinderOpen, setPositionFinderOpen] = React.useState(false);
  const openPositionFinder = () => setPositionFinderOpen(true);
  const closePositionFinder = () => setPositionFinderOpen(false);

  const handlePositionSubmit = (instrument: InstrumentInfo | null) => {
    setValue("instrument", instrument);
    closePositionFinder();
  };

  return (
    <>
      <div style={{ marginLeft: "var(--spacing)" }}>
        <PositionSelectText onClick={openPositionFinder}>
          Select from an existing position
        </PositionSelectText>
      </div>
      <PositionFinderModal
        open={positionFinderOpen}
        onClose={closePositionFinder}
        onSelect={handlePositionSubmit}
      />
    </>
  );
};
