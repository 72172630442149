import { DashboardTheme, themesForDashboard } from "@enfusion-ui/core";
import { useDashboard } from "@enfusion-ui/dashboards";
import { styled } from "@enfusion-ui/web-core";
import { useWindowSize } from "@react-hook/window-size/throttled";
import * as React from "react";

import { PAPER_WIDTH } from "../pdf/utils";

const Wrapper = styled.div<{ active: boolean; width: number; height: number }>`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  display: ${({ active }) => (active ? "block" : "none")};
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

type GridBackgroundProps = {
  active: boolean;
  cellSize: number;
  cols: number;
  width: number;
  height: number;
};

function drawLine(
  ctx: CanvasRenderingContext2D,
  lineColor: string,
  start: [number, number],
  end: [number, number]
) {
  ctx.strokeStyle = lineColor;

  ctx.lineWidth = 1;
  ctx.setLineDash([]);

  ctx.beginPath();
  ctx.moveTo(...start);
  ctx.lineTo(...end);
  ctx.stroke();
}

function drawDashedLine(
  ctx: CanvasRenderingContext2D,
  lineWidth: number,
  cellSize: number,
  start: [number, number],
  end: [number, number]
) {
  ctx.lineWidth = lineWidth;
  ctx.beginPath();
  ctx.setLineDash([lineWidth, cellSize - lineWidth]);
  ctx.moveTo(...start);
  ctx.lineTo(...end);
  ctx.stroke();
}

function draw(
  ctx: CanvasRenderingContext2D,
  cellSize: number,
  width: number,
  cols: number,
  height: number
) {
  ctx.clearRect(0, 0, width, height);
  ctx.strokeStyle = "#72767d";
  const lineWidth = 2;
  const unit = Math.max(width / cols, 1);
  for (let x = 0; x <= width; x = Math.trunc((x + unit) * 100) / 100) {
    const xPos = Math.max(x - lineWidth, 0);
    drawDashedLine(ctx, lineWidth, cellSize, [xPos, -1], [xPos, height]);
  }
}

const GridBackground: React.FC<GridBackgroundProps> = ({
  active,
  cellSize,
  cols,
  width,
  height,
}) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [widowWidth, windowHeight] = useWindowSize();

  const { orientation, enablePdfLayout, settings } = useDashboard();

  const selectedTheme = settings.theme
    ? themesForDashboard[settings.theme as DashboardTheme]
    : themesForDashboard.dashDark;

  React.useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      if (context) {
        draw(context, cellSize, width, cols, height);

        if (enablePdfLayout) {
          const pdfWidth = PAPER_WIDTH[orientation];
          const heightFlag =
            orientation === "portrait" ? "landscape" : "portrait";
          const pdfHeight = PAPER_WIDTH[heightFlag];

          const pdfTotalPage = height / pdfHeight + 1;

          for (let i = 1; i <= pdfTotalPage; i++) {
            drawLine(
              context,
              selectedTheme.colors.primary,
              [0, pdfHeight * i],
              [pdfWidth, pdfHeight * i]
            );
          }
        }
      }
    }
  }, [
    widowWidth,
    windowHeight,
    width,
    height,
    cols,
    orientation,
    enablePdfLayout,
  ]);

  return (
    <Wrapper width={width} height={height} active={active}>
      <canvas width={width} height={height} ref={canvasRef} />
    </Wrapper>
  );
};

export default React.memo(GridBackground);
