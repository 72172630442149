import { formatNumber } from "@enfusion-ui/utils";

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function addPriceIndicator(Highcharts: any) {
  var _modules = Highcharts ? Highcharts._modules : {};
  function _registerModule(obj: any, path: any, args: any, fn: any) {
    if (!obj.hasOwnProperty(path)) {
      obj[path] = fn.apply(null, args);
    }
  }
  _registerModule(
    _modules,
    "Extensions/PriceIndication.js",
    [_modules["Core/Series/Series.js"], _modules["Core/Utilities.js"]],
    function (Series: any, U: any) {
      /**
       * (c) 2009-2021 Sebastian Bochann
       *
       * Price indicator for Highcharts
       *
       * License: www.highcharts.com/license
       *
       *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
       */
      var addEvent = U.addEvent,
        isArray = U.isArray,
        merge = U.merge;
      /**
       * The line marks the last price from visible range of points.
       *
       * @sample {highstock} stock/indicators/last-visible-price
       *         Last visible price
       *
       * @declare   Highcharts.SeriesLastVisiblePriceOptionsObject
       * @product   highstock
       * @requires  modules/price-indicator
       * @apioption plotOptions.series.lastVisiblePrice
       */
      /**
       * Enable or disable the indicator.
       *
       * @type      {boolean}
       * @product   highstock
       * @default   false
       * @apioption plotOptions.series.lastVisiblePrice.enabled
       */
      /**
       * @declare   Highcharts.SeriesLastVisiblePriceLabelOptionsObject
       * @extends   yAxis.crosshair.label
       * @since     7.0.0
       * @apioption plotOptions.series.lastVisiblePrice.label
       */
      /**
       * @since     7.0.0
       * @apioption plotOptions.series.lastVisiblePrice.label.align
       */
      /**
       * @since     7.0.0
       * @apioption plotOptions.series.lastVisiblePrice.label.backgroundColor
       */
      /**
       * The border color for the `lastVisiblePrice` label.
       *
       * @type      {Highcharts.ColorType}
       * @since     7.0.0
       * @product   highstock
       * @apioption plotOptions.series.lastVisiblePrice.label.borderColor
       */
      /**
       * The border corner radius of the `lastVisiblePrice` label.
       *
       * @type      {number}
       * @default   3
       * @since     7.0.0
       * @product   highstock
       * @apioption plotOptions.series.lastVisiblePrice.label.borderRadius
       */
      /**
       * Flag to enable `lastVisiblePrice` label.
       *
       *
       * @type      {boolean}
       * @default   false
       * @since     7.0
       * @product   highstock
       * @apioption plotOptions.series.lastVisiblePrice.label.enabled
       */
      /**
       * A format string for the `lastVisiblePrice` label. Defaults to `{value}` for
       * numeric axes and `{value:%b %d, %Y}` for datetime axes.
       *
       * @type      {string}
       * @since     7.0
       * @product   highstock
       * @apioption plotOptions.series.lastVisiblePrice.label.format
       */
      /**
       * @since     7.0.0
       * @apioption plotOptions.series.lastVisiblePrice.label.formatter
       */
      /**
       * @since     7.0.0
       * @apioption plotOptions.series.lastVisiblePrice.label.padding
       */
      /**
       * @since     7.0.0
       * @apioption plotOptions.series.lastVisiblePrice.label.shape
       */
      /**
       * Text styles for the `lastVisiblePrice` label.
       *
       * @type      {Highcharts.CSSObject}
       * @default   {"color": "white", "fontWeight": "normal", "fontSize": "11px", "textAlign": "center"}
       * @since     7.0
       * @product   highstock
       * @apioption plotOptions.series.lastVisiblePrice.label.style
       */
      /**
       * The border width for the `lastVisiblePrice` label.
       *
       * @type      {number}
       * @default   0
       * @since     7.0
       * @product   highstock
       * @apioption plotOptions.series.lastVisiblePrice.label.borderWidth
       */
      /**
       * Padding inside the `lastVisiblePrice` label.
       *
       * @type      {number}
       * @default   8
       * @since     7.0
       * @product   highstock
       * @apioption plotOptions.series.lastVisiblePrice.label.padding
       */
      /**
       * The line marks the last price from all points.
       *
       * @sample {highstock} stock/indicators/last-price
       *         Last price
       *
       * @declare   Highcharts.SeriesLastPriceOptionsObject
       * @product   highstock
       * @requires  modules/price-indicator
       * @apioption plotOptions.series.lastPrice
       */
      /**
       * Enable or disable the indicator.
       *
       * @type      {boolean}
       * @product   highstock
       * @default   false
       * @apioption plotOptions.series.lastPrice.enabled
       */
      /**
       * @declare   Highcharts.SeriesLastPriceLabelOptionsObject
       * @extends   yAxis.crosshair.label
       * @since     7.0.0
       * @apioption plotOptions.series.lastPrice.label
       */
      /**
       * @since     7.0.0
       * @apioption plotOptions.series.lastPrice.label.align
       * */
      /**
       * @since     7.0.0
       * @apioption plotOptions.series.lastPrice.label.backgroundColor
       * */
      /**
       * The border color of `lastPrice` label.
       * @since     7.0.0
       * @apioption plotOptions.series.lastPrice.label.borderColor
       * */
      /**
       * The border radius of `lastPrice` label.
       * @since     7.0.0
       * @apioption plotOptions.series.lastPrice.label.borderRadius
       * */
      /**
       * The border width of `lastPrice` label.
       * @since     7.0.0
       * @apioption plotOptions.series.lastPrice.label.borderWidth
       * */
      /**
       * Flag to enable `lastPrice` label.
       * @since     7.0.0
       * @apioption plotOptions.series.lastPrice.label.enabled
       * */
      /**
       * A format string for the `lastPrice` label. Defaults to `{value}` for
       * numeric axes and `{value:%b %d, %Y}` for datetime axes.
       *
       * @type      {string}
       * @since     7.0
       * @product   highstock
       * @apioption plotOptions.series.lastPrice.label.format
       */
      /**
       * @since     7.0.0
       * @apioption plotOptions.series.lastPrice.label.formatter
       */
      /**
       * @since     7.0.0
       * @apioption plotOptions.series.lastPrice.label.padding
       */
      /**
       * @since     7.0.0
       * @apioption plotOptions.series.lastPrice.label.shape
       */
      /**
       * Text styles for the `lastPrice` label.
       *
       * @type      {Highcharts.CSSObject}
       * @default   {"color": "white", "fontWeight": "normal", "fontSize": "11px", "textAlign": "center"}
       * @since     7.0
       * @product   highstock
       * @apioption plotOptions.series.lastPrice.label.style
       */
      /**
       * The border width for the `lastPrice` label.
       *
       * @type      {number}
       * @default   0
       * @since     7.0
       * @product   highstock
       * @apioption plotOptions.series.lastPrice.label.borderWidth
       */
      /**
       * Padding inside the `lastPrice` label.
       *
       * @type      {number}
       * @default   8
       * @since     7.0
       * @product   highstock
       * @apioption plotOptions.series.lastPrice.label.padding
       */
      /**
       * The color of the line of last price.
       * By default, the line has the same color as the series.
       *
       * @type      {string}
       * @product   highstock
       * @apioption plotOptions.series.lastPrice.color
       *
       */
      /* eslint-disable no-invalid-this */
      addEvent(Series, "afterRender", function (this: any) {
        /* eslint-disable no-invalid-this */
        let series: any = this;
        let seriesOptions = series.options;
        let pointRange = seriesOptions.pointRange;
        let lastVisiblePrice = seriesOptions.lastVisiblePrice;
        let lastPrice = seriesOptions.lastPrice;
        if (
          (lastVisiblePrice || lastPrice) &&
          seriesOptions.id !== "highcharts-navigator-series" &&
          series.yData.length > 0
        ) {
          var xAxis = series.xAxis,
            yAxis = series.yAxis,
            origOptions = yAxis.crosshair,
            origGraphic = yAxis.cross,
            origLabel = yAxis.crossLabel,
            points = series.points,
            pLength = points.length,
            x = series.xData[series.xData.length - 1],
            y = series.yData[series.yData.length - 1],
            lastPoint: any = void 0;

          const yValue = isArray(y) ? y[3] : y;
          const point = {
            x: x,
            y: yValue,
            plotX: xAxis.toPixels(x, true),
            plotY: yAxis.toPixels(yValue, true),
          };

          const lastVisiblePriceBase =
            series.chart.userOptions.series[0].lastVisiblePrice;
          const lastVisiblePriceEnabled =
            lastVisiblePriceBase && lastVisiblePriceBase.enabled;

          if (lastPrice && lastPrice.enabled && pLength > 0) {
            yAxis.crosshair = yAxis.options.crosshair = seriesOptions.lastPrice;
            if (
              !series.chart.styledMode &&
              yAxis.crosshair &&
              yAxis.options.crosshair &&
              seriesOptions.lastPrice
            ) {
              // Set the default color from the series, #14888.
              yAxis.crosshair.color = yAxis.options.crosshair.color =
                seriesOptions.lastPrice.color || series.color;
            }
            yAxis.cross = series.lastPrice;
            yAxis.drawCrosshair(null, point);
            // Save price
            if (series.yAxis.cross) {
              series.lastPrice = series.yAxis.cross;
              series.lastPrice.addClass(
                "highcharts-color-" + series?.colorIndex
              ); // #15222
              series.lastPrice.y = yValue;
            }
          }

          if (lastVisiblePrice && lastVisiblePrice.enabled && pLength > 0) {
            yAxis.crosshair = yAxis.options.crosshair = merge(
              {
                color: "transparent", // line invisible by default
                label: {
                  enabled: true,
                  formatter: function (value: any) {
                    return formatNumber(value, [0, 2, 2]);
                  },
                },
              },
              seriesOptions.lastVisiblePrice
            );
            yAxis.cross = series.lastVisiblePrice;
            if (lastVisiblePriceEnabled) {
              const crop =
                points[pLength - 1].x === x || pointRange === null ? 1 : 2;
              lastPoint = points[pLength - crop];
            } else {
              const crop =
                points[pLength - 1].x === x || pointRange === null ? 1 : 2;
              const lastVisiblePoint = points[pLength - crop];
              lastPoint = {
                ...lastVisiblePoint,
                y: point.y,
                plotY: point.plotY,
              };
            }
            if (series.crossLabel) {
              series.crossLabel.destroy();
            }
            // Set to undefined to avoid collision with
            // the yAxis crosshair #11480
            // Delete the crossLabel each time the code is invoked, #13876.
            delete yAxis.crossLabel;
            // Save price
            // console.log(
            //   "[DRAW PRICE LABEL]",
            //   JSON.stringify(lastPoint),
            //   JSON.stringify(series)
            // );
            yAxis.drawCrosshair(null, lastPoint);
            if (yAxis.cross) {
              series.lastVisiblePrice = yAxis.cross;
              if (typeof lastPoint.y === "number") {
                series.lastVisiblePrice.y = lastPoint.y;
              }
            }
            series.crossLabel = yAxis.crossLabel;
          }
          // Restore crosshair:
          yAxis.crosshair = yAxis.options.crosshair = origOptions;
          yAxis.cross = origGraphic;
          yAxis.crossLabel = origLabel;
        }
      });
    }
  );
  _registerModule(
    _modules,
    "masters/modules/price-indicator.src.js",
    [],
    function () {}
  );
}
