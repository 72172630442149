import {
  WebDatePeriodSelection,
  WebDateRangeSelection,
  WebDateSelection,
} from "@enfusion-ui/types";
import { getPresetValue } from "@enfusion-ui/utils";
import {
  DatePresetInput,
  DatePresetInputProps,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { getDatePreset, getDateSelection } from "./utils";

type DateSelectionInputProps = Omit<
  DatePresetInputProps,
  "value" | "onChange"
> & {
  value?: WebDateSelection | null;
  defaultValue?: WebDateSelection | null;
  onChange: (value: WebDateSelection | null | undefined) => void;
  datePickerWidth?: number;
};

export const DateSelectionInput: React.FC<DateSelectionInputProps> = ({
  value,
  defaultValue,
  onChange,
  datePickerWidth,
  ...rest
}) => {
  const valueParam = React.useMemo(() => {
    if (!value) return null;
    if (value.asOfDate) return getPresetValue(value);
    return getDatePreset(value);
  }, [value]);

  return (
    <DatePresetInput
      {...rest}
      value={valueParam}
      onChange={(valueDate: string | Date | null) => {
        onChange(getDateSelection(valueDate, defaultValue));
      }}
      datePickerWidth={datePickerWidth}
    />
  );
};

type DatePeriodSelectionInputProps = Omit<
  DateSelectionInputProps,
  "value" | "onChange" | "defaultValue"
> & {
  value?: WebDatePeriodSelection | null;
  defaultValue?: WebDatePeriodSelection | null;
  onChange: (value: WebDatePeriodSelection | null | undefined) => void;
};

export const DatePeriodSelectionInput: React.FC<
  DatePeriodSelectionInputProps
> = ({ value, defaultValue, onChange, ...rest }) => {
  return (
    <DateSelectionInput
      {...rest}
      value={value?.dateSelection}
      onChange={(newValue: WebDateSelection | null | undefined) => {
        onChange({
          ...(value || {
            period: null,
            increment: 0,
            before: 0,
            after: 0,
          }),
          dateSelection: newValue ?? null,
        } as WebDatePeriodSelection);
      }}
      defaultValue={defaultValue?.dateSelection}
      optionSet="period"
    />
  );
};

type DateRangeSelectionInputProps = Omit<
  DateSelectionInputProps,
  "value" | "onChange" | "defaultValue" | "label"
> & {
  value?: WebDateRangeSelection | null;
  defaultValue?: WebDateRangeSelection | null;
  onChange: (value: WebDateRangeSelection | null | undefined) => void;
  startDateLabel: string;
  endDateLabel: string;
  datePickerWidth?: number;
};

const DateRangeSelectionContainer = styled.div`
  display: flex;
  grid-gap: var(--spacing-xl);
`;

const DateRangeSelectionInputContainer = styled.div`
  width: 100%;
`;

export const DateRangeSelectionInput: React.FC<
  DateRangeSelectionInputProps
> = ({
  value,
  defaultValue,
  onChange,
  startDateLabel,
  endDateLabel,
  clearable,
  datePickerWidth,
}) => {
  const [startDate, setStartDate] = React.useState<
    WebDateSelection | null | undefined
  >(defaultValue?.startDate);
  const [endDate, setEndDate] = React.useState<
    WebDateSelection | null | undefined
  >(defaultValue?.endDate);

  React.useEffect(() => {
    if (value) {
      const { startDate, endDate } = value;
      startDate && setStartDate(startDate);
      endDate && setEndDate(endDate);
    }
  }, [value]);

  React.useEffect(() => {
    onChange && onChange({ startDate, endDate });
  }, [startDate, endDate]);

  return (
    <DateRangeSelectionContainer>
      <DateRangeSelectionInputContainer>
        <DateSelectionInput
          label={startDateLabel}
          value={startDate}
          onChange={setStartDate}
          clearable={clearable}
          datePickerWidth={datePickerWidth}
        />
      </DateRangeSelectionInputContainer>
      <DateRangeSelectionInputContainer>
        <DateSelectionInput
          label={endDateLabel}
          value={endDate}
          onChange={setEndDate}
          clearable={clearable}
          datePickerWidth={datePickerWidth}
        />
      </DateRangeSelectionInputContainer>
    </DateRangeSelectionContainer>
  );
};
