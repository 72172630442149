import { EMPTY_ARRAY } from "@enfusion-ui/core";
import {
  AccountSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { NodeData } from "@enfusion-ui/types/src";
import { reduceSelectedNodes } from "@enfusion-ui/web-core";
import { orderBy, uniq } from "lodash";
import * as React from "react";

import PdfSelectionWidget from "../PdfSelectionWidget";

const PdfAccountSelectionWidget: React.FC<unknown> = () => {
  const { config, addChannelKeys, setChannelData } = useWidget();
  const { defaultValues = EMPTY_ARRAY, key = "Accounts" } =
    config as AccountSelectionWidgetConfig;
  const [inputValue, setInputValue] = React.useState<string | null>(null);

  React.useEffect(() => {
    addChannelKeys([
      {
        name: `${key} - Account Ids(s)`,
        description: "Account Id(s)",
        type: ChannelDataType.Number,
      },
      {
        name: `${key} - GL Id(s)`,
        description: "General Ledger Id(s)",
        type: ChannelDataType.Number,
      },
      {
        name: `${key} - Fund Id(s)`,
        description: "Fund Id(s)",
        type: ChannelDataType.Number,
      },
    ]);
    const ids = defaultValues.reduce(
      (res, e) => {
        if (e.file) {
          if (typeof e.accountId !== "undefined")
            res.accountIds.push(e.accountId as number);
          if (typeof e.fundId !== "undefined")
            res.fundIds.push(e.fundId as number);
          if (typeof e.glId !== "undefined") res.glIds.push(e.glId as number);
        }
        return res;
      },
      { accountIds: [], glIds: [], fundIds: [] } as {
        accountIds: Array<number>;
        glIds: Array<number>;
        fundIds: Array<number>;
      }
    );
    setChannelData([uniq(ids.accountIds), uniq(ids.glIds), uniq(ids.fundIds)]);
  }, [key, defaultValues]);

  React.useEffect(() => {
    if (defaultValues.length > 0) {
      const selectedNodes = reduceSelectedNodes(
        (defaultValues as Array<NodeData>) || []
      );
      const formattedValue = orderBy(selectedNodes, ["depth"], ["asc"])
        .map((i) => i.name)
        .join(", ");

      setInputValue(formattedValue);
    }
  }, [defaultValues]);

  return <PdfSelectionWidget label={key} value={inputValue} filterBtn />;
};

export default PdfAccountSelectionWidget;
