import { useOEMSOrderForm } from "@enfusion-ui/core";
import { ExecutionOrderCapacity } from "@enfusion-ui/types";
import { ControlledSelect, PanelRow } from "@enfusion-ui/web-components";
import * as React from "react";
import { useWatch } from "react-hook-form";

import CounterpartySelection from "./CounterpartySelection";
import RoutingBrokerAccountSelection from "./RoutingBrokerAccountSelection";
import RoutingDestinationSelection from "./RoutingDestinationSelection";
import RoutingStrategySelection from "./RoutingStrategySelection";
import RoutingTargetSelection from "./RoutingTargetSelection";

type EnumOptionsType<T> = Array<{ value: T; label: string }>;

type Options = {
  capacity: EnumOptionsType<ExecutionOrderCapacity>;
};

const RoutingOptions: Options = {
  capacity: [
    { value: "Agency", label: "Agency" },
    { value: "Principal", label: "Principal" },
  ],
};

export const requiredFieldRule = { required: "required field" };

export const RoutingCapacitySelection = ({ disabled = false }) => {
  return (
    <ControlledSelect
      disabled={disabled}
      name="capacity"
      label="Capacity"
      options={RoutingOptions.capacity}
      mobileBasis="50%"
      inputId="electronic-routing-capacity-selection-id"
    />
  );
};

const ElectronicRoutingDetailEntry: React.FC<{ disabled?: boolean }> = ({
  disabled,
}) => {
  const { isFxOrder, isCdxOrder, isVarSwapOrder, isIrsOrder } =
    useOEMSOrderForm();

  const { destination } = useWatch({
    name: ["destination"],
  });

  return (
    <>
      <PanelRow>
        <RoutingTargetSelection disabled={disabled} />
        <CounterpartySelection
          disabled={
            typeof destination === "string" ||
            !destination?.enableCounterparties ||
            disabled
          }
          controllerName="counterpartyId"
        />
      </PanelRow>
      <PanelRow>
        <RoutingDestinationSelection disabled={disabled} />
        <RoutingBrokerAccountSelection disabled={disabled} />
      </PanelRow>
      {!isFxOrder && !isCdxOrder && !isVarSwapOrder && !isIrsOrder && (
        <PanelRow>
          <RoutingStrategySelection disabled={disabled} />
          <RoutingCapacitySelection disabled={disabled} />
        </PanelRow>
      )}
    </>
  );
};

export default ElectronicRoutingDetailEntry;
