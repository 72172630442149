import { useAppTutorial } from "@app-components/Tour/context";
import { TutorialType } from "@app-components/Tour/utils";
import {
  ContextMenuIcon,
  ContextMenuItemContainer,
  useExplorerContextMenu,
} from "@app-context/explorer/explorerContextMenu/useExplorerContextMenu";
import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import { usePortfolioExplorer } from "@app-context/portfolios/PortfolioExplorerProvider";
import { useModalState, useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { DashboardRoot, FileTreeEntry, NodeData } from "@enfusion-ui/types";
import { getFileName } from "@enfusion-ui/utils";
import {
  AccordionContext,
  CenterContent,
  ErrorAlert,
  FileExplorer,
  MenuItemOption,
  MenuOptions,
  Spinner,
  useNavBarState,
} from "@enfusion-ui/web-components";
import {
  REST_API,
  styled,
  useAuth,
  useFolderActionContext,
  useTabs,
} from "@enfusion-ui/web-core";
import { faAbacus } from "@fortawesome/pro-regular-svg-icons";
import { faFolderPlus, faPlus, faSave } from "@fortawesome/pro-solid-svg-icons";
import { useTour } from "@reactour/tour";
import { debounce } from "lodash";
import { MenuItem } from "rc-menu";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";

import { SaveAsActionPortfolioModal } from "./components/SaveAsActionPortfolioModal";

const PortfolioFileExplorer = styled(FileExplorer)`
  flex: 1;
  overflow-x: hidden;
`;

type PortfolioExplorerPropType = {
  open: boolean;
  root: DashboardRoot;
  tutorial?: TutorialType | null;
  canEdit?: boolean;
};

const PortfolioExplorer: React.FC<PortfolioExplorerPropType> = ({
  open,
  tutorial,
  root = "user",
  canEdit = true,
}) => {
  const { isOpen } = useTour();
  const [nodes, setNodes] = React.useState<NodeData[] | null>(null);
  const [error, setError] = React.useState<null | Error>(null);
  const [loading, setLoading] = React.useState(false);

  const { openTab } = useTabs();
  const isMounted = useMounted();
  const { closeNavBarTabOnMobile } = useNavBarState();
  const { retrieveNodes } = useFolderActionContext();
  const { setMenu, setAllowClickOutside } = React.useContext(AccordionContext);
  const { hasPerm, isAdmin, isInternalPo } = useAuth();

  const globalAdminEnabled = hasPerm("DashboardEditor");
  const adminEnabledIgnored = globalAdminEnabled || isAdmin() || isInternalPo();

  const { openCreatePortfolioModal, openCreateFolderModal, deletePortfolio } =
    usePortfolioExplorer();

  const { setFileNodes, tutorialType } = useAppTutorial();

  const saveAsModalState = useModalState<NodeData>();

  React.useEffect(() => {
    setAllowClickOutside(!!isOpen);
  }, [isOpen]);

  const getRootFiles = useRefCallback(
    debounce(() => {
      if (open) {
        REST_API.PORTFOLIO.GET_ROOT_NODE(root)
          .then((rootNodes: NodeData[]) => {
            if (isMounted()) {
              setNodes(rootNodes);

              if (tutorialType === TutorialType.Portfolio)
                setFileNodes?.(rootNodes);
            }
          })
          .catch((err: Error | null) => {
            if (isMounted()) {
              setError(err);
            }
          })
          .finally(() => {
            if (isMounted()) {
              setLoading(false);
            }
          });
      }
    }, 500),
    [root, open]
  );

  React.useEffect(() => {
    if (open && nodes === null) {
      getRootFiles();
    }
  }, [root, nodes, open]);

  const reloadEvent = useRefCallback(
    (eventRoot: DashboardRoot, event: ExplorerEventsAction) => {
      if (eventRoot === root && event === ExplorerEventsAction.Refetch) {
        getRootFiles();
      }
    },
    [root, getRootFiles]
  );
  useExplorerEvents("Portfolios", reloadEvent);

  const getMenuConfig = useRefCallback(() => {
    let menuOptions: MenuOptions = [];
    if (canEdit) {
      menuOptions = [
        {
          key: "createPortfolio",
          title: "Add Workbench",
          icon: faPlus,
          onClick: () => openCreatePortfolioModal(root),
        } as MenuItemOption,
        {
          key: "createFolder",
          title: "Add Folder",
          icon: faFolderPlus,
          onClick: () => openCreateFolderModal(root, ""),
        } as MenuItemOption,
      ];
    }
    return { menu: menuOptions, selectedKeys: [] };
  }, [canEdit]);

  React.useEffect(() => {
    setMenu(getMenuConfig());
  }, [canEdit]);

  const handleEntryClick = useRefCallback(
    (node: NodeData) => {
      const data = node as unknown as FileTreeEntry;
      if (node.file) {
        openTab({
          component: "portfolio-workbench",
          name: getFileName(node),
          icon: faAbacus,
          unique: `portfolio-${data.path}`,
          config: {
            root,
            data,
            id: uuidv4(),
            filePath: data.path,
          },
        });
        closeNavBarTabOnMobile();
      }
    },
    [closeNavBarTabOnMobile, root]
  );

  const ExplorerContextMenu = useExplorerContextMenu(
    {
      root,
      section: "Portfolios",
      editEnabled: canEdit,
      shareEnabled: false,
      openCreate: openCreatePortfolioModal,
      openCreateFolder: openCreateFolderModal,
      deleteFile: deletePortfolio,
    },
    (contextMenuNode) =>
      contextMenuNode?.node?.file
        ? [
            <MenuItem
              key="save-as-file"
              data-e2e-id="save-as-menu-item"
              onClick={() => {
                saveAsModalState.openModal(contextMenuNode?.node!);
              }}
            >
              <ContextMenuItemContainer>
                <ContextMenuIcon icon={faSave} />
                Save As
              </ContextMenuItemContainer>
            </MenuItem>,
          ]
        : null
  );

  if (error) {
    return (
      <CenterContent fillHeight={false}>
        <ErrorAlert error={error} />
      </CenterContent>
    );
  }

  if (loading || nodes === null) {
    return (
      <CenterContent fillHeight={false}>
        <Spinner dataTestId="explorer-spinner" />
      </CenterContent>
    );
  }

  return (
    <>
      <PortfolioFileExplorer
        nodes={nodes}
        tutorial={tutorial}
        getFileIcon={() => faAbacus}
        retrieveNodes={retrieveNodes}
        onEntryClick={handleEntryClick}
        onEntryContext={ExplorerContextMenu.handleEntryContext}
      />

      {ExplorerContextMenu.content}

      <SaveAsActionPortfolioModal
        root={root}
        open={saveAsModalState.open}
        closeModal={saveAsModalState.closeModal}
        node={saveAsModalState.openContentRef.current}
      />
    </>
  );
};

export default PortfolioExplorer;
