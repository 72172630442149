import { useAllocationsControl } from "@enfusion-ui/core";
import { useMounted } from "@enfusion-ui/hooks";
import { CurrencyInfo } from "@enfusion-ui/types";
import { ControlledSelect, PanelRow } from "@enfusion-ui/web-components";
import { REST_API, styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { AllocationTemplatesInput } from "./AllocationTemplatesInput";
import { OptimizationInput } from "./OptimizationInput";

const AllocationEntryPanelContainer = styled(PanelRow)`
  margin-bottom: var(--spacing);
`;

export type ApplyAllocationsPanelProps = {
  setError?: (error: string | null) => void;
  selectedValue?: number;
  disabled?: boolean;
};

export type SettlementCurrencyOptions = {
  value: number;
  label: string;
};

const ApplyAllocationsPanel: React.FC<ApplyAllocationsPanelProps> = ({
  setError,
  disabled = true,
}: ApplyAllocationsPanelProps) => {
  const [settlementCurrencyOptions, setSettlementCurrencyOptions] =
    React.useState<SettlementCurrencyOptions[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const isMounted = useMounted();
  const {
    isEntryCompleted,
    isFxEntry,
    isFutureSpreadEntry,
    isEntryTransmitted,
  } = useAllocationsControl();

  React.useEffect(() => {
    const getAllCurrencies = async () => {
      try {
        if (isMounted()) setIsLoading(true);
        const currencies =
          await REST_API.INSTRUMENT.GET_SETTLEMENT_CURRENCIES.FETCH();

        const currOptions = currencies.map((eachCurrency: CurrencyInfo) => {
          return { value: eachCurrency.id, label: eachCurrency.code };
        });

        if (isMounted()) setSettlementCurrencyOptions(currOptions);
      } catch (err) {
        console.error(err);
      } finally {
        if (isMounted()) setIsLoading(false);
      }
    };

    getAllCurrencies();
  }, []);

  return (
    <AllocationEntryPanelContainer>
      <OptimizationInput disabled={isEntryCompleted} />

      <AllocationTemplatesInput
        setError={setError}
        disabled={isEntryCompleted}
      />

      {!isFxEntry && !isFutureSpreadEntry ? (
        <ControlledSelect
          name="settleCcyId"
          label="Settle CCY"
          options={settlementCurrencyOptions}
          isClearable
          disabled={disabled || isEntryCompleted || isEntryTransmitted}
          isLoading={isLoading}
          mobileBasis="50%"
          inputId="apply-alloc-settle-currency-selection-id"
        />
      ) : null}
    </AllocationEntryPanelContainer>
  );
};

export default ApplyAllocationsPanel;
