import { FormPanel } from "@enfusion-ui/web-components";
import * as React from "react";

import { Dividends } from "./components/tabs/Dividends";

export const DividendsPanel: React.FC<unknown> = () => {
  return (
    <FormPanel
      title="Dividends"
      numColumns={1}
      collapsible
      defaultOpen
      keepRendered
      defaultRendered
      lineStyle
      tabFocusOutline={false}
      dataE2EId="dividends-panel"
    >
      <Dividends />
    </FormPanel>
  );
};
