import { WidgetComponentsDefinition } from "../../types";
import MarketDataEditWidget from "./MarketDataEditWidget";
import MarketDataWidget from "./MarketDataWidget";
import PdfMarketDataWidget from "./PdfMarketDataWidget";

export const marketdata: WidgetComponentsDefinition = {
  pdfContentAsync: true,
  renderComponent: MarketDataWidget,
  editComponent: MarketDataEditWidget,
  renderPdfComponent: PdfMarketDataWidget,
};
