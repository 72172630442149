import * as React from "react";
export function providerError(key, prov) {
    return new Error(`${key} must be used within an ${prov}`);
}
export function useCtx(ctx, name, prov) {
    const context = React.useContext(ctx);
    if (typeof context === "undefined")
        throw providerError(name, prov);
    return context;
}
