import {
  Button,
  ControlledSelect,
  PanelRow,
} from "@enfusion-ui/web-components";

const AllocationsSelection: React.FC<unknown> = () => {
  return (
    <>
      <PanelRow>
        <Button disabled>View Allocated Trade</Button>
      </PanelRow>
      <PanelRow style={{ marginBottom: "var(--spacing-xl)" }}>
        <ControlledSelect
          placeholder="Selected Positions"
          desktopBasis="50%"
          name="selectedPositions"
          options={undefined}
          label="Allocate Using"
        />
      </PanelRow>
    </>
  );
};

export default AllocationsSelection;
