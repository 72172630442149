import {
  ExplorerFolderProvider,
  ExplorerFolderProviderWrapperProps,
  getCopyFilePath,
} from "@app-context/explorer/explorerFolders/ExplorerFolderProvider";
import { useRefCallback } from "@enfusion-ui/hooks";
import { NodeData } from "@enfusion-ui/types";
import { DashboardRoot } from "@enfusion-ui/types/src";
import { REST_API, useAuth } from "@enfusion-ui/web-core";
import * as React from "react";

const getRoot = REST_API.REPORTS.GET_ROOT_NODE_FACTORY();

export const ReportsFolderProvider: React.FC<
  ExplorerFolderProviderWrapperProps
> = ({ root, children }) => {
  const { hasPerm } = useAuth();

  const copyFile = useRefCallback(
    async (node: NodeData, targetRoot: DashboardRoot, targetPath: string) => {
      try {
        const nodePath = node.path;
        const reportQuery = await REST_API.REPORTS.GET_SAVED_QUERY.FETCH(
          nodePath
        );

        const path = getCopyFilePath(node.name, targetPath);

        const res = await REST_API.REPORTS.STORE_REPORT({
          reportQuery,
          path,
          subRoot: targetRoot.toLowerCase() as DashboardRoot,
        });

        if (res.success) return true;
        throw new Error("");
      } catch (err) {
        let msg = (err as Error).message
          .replace(/save/g, "copy")
          .replace("Failed to copy report", "")
          .trim();
        if ((err as Error).message?.includes("already exists")) {
          msg = "Report already exists";
        }
        throw new Error(msg);
      }
    },
    []
  );

  return (
    <ExplorerFolderProvider
      root={root}
      section="Reports"
      getRoot={getRoot}
      hasGlobal={hasPerm("DashboardEditor")}
      createFolder={REST_API.REPORTS.CREATE_FOLDER}
      renameEntry={REST_API.REPORTS.RENAME_FOLDER}
      deleteEntry={REST_API.REPORTS.DELETE_ENTRY}
      moveEntry={REST_API.REPORTS.MOVE_ENTRY}
      copyEntry={copyFile}
    >
      {children}
    </ExplorerFolderProvider>
  );
};
