import {
  InputWrapper,
  SingleRowContainer,
} from "@app-views/dashboards/widgets/styles";
import { MarketInfoWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import {
  MultiSelectOptionsType,
  SelectOptionsType,
  ViewModeOptions,
} from "@enfusion-ui/types";
import { getSelectedValues, getSelectOption } from "@enfusion-ui/utils";
import {
  FormSectionAccordion,
  MultiSelect,
  Select,
} from "@enfusion-ui/web-components";
import * as React from "react";
import { OptionsType } from "react-select";

const fieldOptions: SelectOptionsType<string>[] = [
  { value: "Bid", label: "Bid" },
  { value: "Ask", label: "Ask" },
  { value: "Last", label: "Last" },
  { value: "Change", label: "Change" },
  { value: "Change %", label: "Change %" },
  { value: "Volume", label: "Volume" },
  { value: "CCY", label: "Currency" },
  { value: "Exchange", label: "Exchange" },
];

const MarketInfoEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const { viewMode = "vertical", fields = [] } =
    editedConfig as MarketInfoWidgetConfig;

  const handleMultiSelectChange = (
    selectedOptions: OptionsType<SelectOptionsType<any>> | null
  ) => {
    const values =
      selectedOptions?.map((o: MultiSelectOptionsType) => o?.value as string) ??
      [];
    changeConfigKeyToBeApplied("fields", values);
  };

  const handleSelectChange = (
    selectedOption: SelectOptionsType<string> | null
  ) => {
    const result = selectedOption?.value ?? "vertical";
    changeConfigKeyToBeApplied("viewMode", result);
    if (result === "vertical" && fields.length) {
      changeConfigKeyToBeApplied(
        "fields",
        fields.filter((val) => !(val === "CCY" || val === "Exchange"))
      );
    }
  };

  const options = React.useMemo(
    () =>
      viewMode === "horizontal"
        ? fieldOptions
        : fieldOptions.filter(
            (val) => !(val.label === "Currency" || val.label === "Exchange")
          ),
    [viewMode]
  );

  return (
    <FormSectionAccordion title="Market Info Panel Options">
      <SingleRowContainer noMargin>
        <InputWrapper>
          <MultiSelect
            label="Market Info keys"
            onChange={handleMultiSelectChange}
            value={getSelectedValues(fields || [], fieldOptions)}
            options={options}
            placeholder="Select keys"
            isMulti
          />
        </InputWrapper>

        <InputWrapper>
          <Select
            options={ViewModeOptions}
            label="Layout"
            onChange={handleSelectChange}
            value={getSelectOption(ViewModeOptions, viewMode)}
            placeholder="Select alignment"
          />
        </InputWrapper>
      </SingleRowContainer>
    </FormSectionAccordion>
  );
};

export default MarketInfoEditWidget;
