import { DateWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  DateValue,
  DateWidgetInputResult,
  WebDatePeriodSelection,
  WebDateRangeSelection,
  WebDateRangeSymbolSelection,
  WebDateSelection,
  WebReportTableParam,
} from "@enfusion-ui/types";
import { DATE_INPUT_TYPE_OPTIONS } from "@enfusion-ui/utils";
import {
  Checkbox,
  FormSectionAccordion,
  Select,
  TextInput,
} from "@enfusion-ui/web-components";
import { isEmpty } from "lodash";
import * as React from "react";

import { SelectOption } from "../../../../../components/control/SimpleSelect";
import DatasourceParamsFilter from "../../../widget/components/DatasourceParamsFilter";
import DateWidgetInput from "./DateWidgetInput";

const params: WebReportTableParam[] = [
  {
    name: "after",
    description: "After",
    supportsMultiple: false,
    required: false,
    type: "date",
  },
  {
    name: "before",
    description: "Before",
    supportsMultiple: false,
    required: false,
    type: "date",
  },
];

const DateEditWidget = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();
  const { defaultValue, key, inputType, noLatest } =
    editedConfig as DateWidgetConfig;

  const handleChange = useRefCallback(
    (value: DateWidgetInputResult) => {
      const [
        date,
        dOneIgnored,
        presetValue,
        dTwoIgnored,
        rangeValue,
        dThreeIgnored,
        periodOrSymbolValue,
      ] = value;
      let val: DateValue = date;
      if (inputType === "preset") {
        val = presetValue;

        if (val) {
          if (
            (val &&
              val.dateSelectionType !==
                (defaultValue as WebDateSelection)?.dateSelectionType) ||
            (val.dateSelectionType === "AsOfDate" &&
              val.asOfDate !== (defaultValue as WebDateSelection)?.asOfDate) ||
            !defaultValue
          ) {
            changeConfigKeyToBeApplied("defaultValue", val);
          }
        }

        return;
      } else if (inputType === "range") {
        val = rangeValue;
        if (
          val &&
          (val?.startDate?.asOfDate?.toString() !==
            (
              defaultValue as WebDateRangeSelection
            )?.startDate?.asOfDate?.toString() ||
            val?.endDate?.asOfDate?.toString() !==
              (
                defaultValue as WebDateRangeSelection
              )?.endDate?.asOfDate?.toString())
        ) {
          changeConfigKeyToBeApplied("defaultValue", val);
        }
        return;
      } else if (inputType === "date") {
        val = date;
        if (val && val.toString() !== defaultValue?.toString()) {
          changeConfigKeyToBeApplied("defaultValue", val);
        }
        return;
      }
      if (inputType === "period") {
        val = periodOrSymbolValue;
        if (val && !isEmpty(val)) {
          if (
            (val as WebDatePeriodSelection)?.dateSelection
              ?.dateSelectionType !==
              (defaultValue as WebDatePeriodSelection)?.dateSelection
                ?.dateSelectionType ||
            ((val as WebDatePeriodSelection)?.dateSelection
              ?.dateSelectionType === "AsOfDate" &&
              (val as WebDatePeriodSelection)?.dateSelection?.asOfDate !==
                (defaultValue as WebDatePeriodSelection)?.dateSelection
                  ?.asOfDate) ||
            !defaultValue ||
            isEmpty(defaultValue)
          ) {
            changeConfigKeyToBeApplied("defaultValue", {
              dateSelection: (val as WebDatePeriodSelection).dateSelection,
            });
          }
        }
        return;
      } else {
        val = periodOrSymbolValue;

        const periodSymbol: WebDateRangeSymbolSelection =
          val as WebDateRangeSymbolSelection;
        if (
          periodSymbol?.symbol === "AllDates" ||
          periodSymbol?.symbol === "Custom"
        ) {
          periodSymbol.fromDate =
            rangeValue.startDate?.asOfDate === null
              ? undefined
              : rangeValue.startDate?.asOfDate;
          periodSymbol.toDate =
            rangeValue.endDate?.asOfDate === null
              ? undefined
              : rangeValue.endDate?.asOfDate;
        }

        const defaultSymbolVal: WebDateRangeSymbolSelection | undefined =
          defaultValue as WebDateRangeSymbolSelection;
        if (
          periodSymbol &&
          (periodSymbol?.symbol !== defaultSymbolVal?.symbol ||
            periodSymbol?.fromDate !== defaultSymbolVal?.fromDate ||
            periodSymbol?.toDate !== defaultSymbolVal?.toDate)
        ) {
          changeConfigKeyToBeApplied("defaultValue", val);
        }
        return;
      }
    },
    [inputType, defaultValue]
  );

  const handleTextChange = useRefCallback(
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    },
    []
  );

  const handleTypeChange = useRefCallback((option: SelectOption | null) => {
    changeConfigKeyToBeApplied("inputType", option?.value);
    changeConfigKeyToBeApplied("defaultValue", null);
  }, []);

  return (
    <>
      <FormSectionAccordion title="Date Options">
        <TextInput
          label="Key"
          value={key}
          name="key"
          onChange={handleTextChange("key")}
          data-e2e-id="date-key-input"
        />
        <Select
          label="Input Type"
          value={DATE_INPUT_TYPE_OPTIONS.find(
            ({ value: val }) => inputType === val
          )}
          options={DATE_INPUT_TYPE_OPTIONS}
          onChange={handleTypeChange}
          inputId="date-input-type-selection-id"
        />
        <DateWidgetInput
          label="Default Value"
          onChange={handleChange}
          defaultValue={defaultValue}
          inputType={inputType}
          noLatest={noLatest}
        />
        {(inputType === "period" || inputType === "preset") && (
          <Checkbox
            style={{ margin: "var(--spacing) 0" }}
            onChange={(checked: boolean) => {
              changeConfigKeyToBeApplied("noLatest", checked);
            }}
            checked={noLatest ?? false}
            label="No Latest"
            labelPlacement="right"
          />
        )}
      </FormSectionAccordion>

      <FormSectionAccordion title="Date Boundaries">
        {params.map((param) => (
          <DatasourceParamsFilter key={param.name} param={param} />
        ))}
      </FormSectionAccordion>
    </>
  );
};

export default DateEditWidget;
