import { ReportSettingsDefinition } from "../../types";
import PositionCashFlowQuickFilter from "./PositionCashFlowQuickFilter";
import PositionCashFlowSettings from "./PositionCashFlowSettings";

export const WebPositionCashFlowQuery: ReportSettingsDefinition = {
  name: "PositionCashFlow",
  queryType: "WebPositionCashFlowQuery",
  quickFilterComponent: PositionCashFlowQuickFilter,
  settingsComponent: PositionCashFlowSettings,
};
