/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeDefinition } from "@enfusion-ui/core";
import {
  getColumnDef,
  getDefaultColor,
  GraphWidgetConfig,
} from "@enfusion-ui/dashboards";
import {
  aggregateGraphData,
  formatDate,
  formatReportValue,
  SeriesEntry,
  SeriesEntryData,
} from "@enfusion-ui/utils";
import {
  ReportContextDataStoreEntry,
  ReportContextRowsStoreEntry,
} from "@enfusion-ui/web-core";
import { parseISO } from "date-fns";
import HighchartsBase from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { orderBy, sortBy } from "lodash";
import * as React from "react";

type RefGraphRendererProps = {
  width: number;
  height: number;
  config: GraphWidgetConfig;
  onLoad?: (url: string) => void;
  gridOptions: ReportContextDataStoreEntry | null;
  theme: ThemeDefinition;
  rowOptions: ReportContextRowsStoreEntry | null;
};

const RefGraphRenderer: React.FC<RefGraphRendererProps> = ({
  width,
  height,
  onLoad,
  config,
  gridOptions,
  rowOptions,
  theme,
}) => {
  const {
    type,
    title,
    subTitle,
    series,
    categories,
    timeSeries = false,
    yMin,
    xMin,
    datasourceId,
    tooltipFractions,
    showLegend = true,
    dataSorting = true,
    showDataLabels = false,
    sortByKey = 0,
    sortDir = "desc",
    aggregationMethod = "max",
  } = config as GraphWidgetConfig;

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const formatLabel = React.useCallback(
    (value: string) => {
      try {
        return type.endsWith("line") && timeSeries ? formatDate(value) : value;
      } catch (err) {
        return value;
      }
    },
    [type, timeSeries]
  );

  const rect =
    containerRef.current?.getBoundingClientRect() ||
    ({ width: 0, height: 0 } as DOMRect);

  const seriesData = React.useMemo(() => {
    if (!rowOptions || !rowOptions?.rows || !gridOptions || series.length === 0)
      return { series: [], categories: [], yAxisTitle: "", xAxisTitle: "" };

    const seriesKey =
      getColumnDef(series[0].value, gridOptions?.metadata.columns)?.name || "";

    const { data, colors } = categories.reduce(
      (res, cat, idx) => {
        res.data.push({
          name: cat.label || "",
          key:
            getColumnDef(cat.value, gridOptions?.metadata.columns)?.name || "",
          colorByPoint: type === "pie",
          zoneAxis: type === "bar" || type === "column" ? "y" : undefined,
          data: [] as SeriesEntryData[],
          negativeColor: cat.negativeColor || cat.color,
        });
        cat.color
          ? res.colors.push(cat.color)
          : res.colors.push(getDefaultColor(idx));
        return res;
      },
      { data: [] as Array<SeriesEntry>, colors: [] as Array<string> }
    );
    const orderByKey = sortByKey > data.length ? 0 : sortByKey;

    // convert data into a structure that can be sorted and keep the name
    const dataRes = rowOptions.rows.reduce(
      (res, row) => {
        if (row[seriesKey]) {
          const name =
            type.endsWith("line") && timeSeries
              ? parseISO(row[seriesKey].value as unknown as string).valueOf()
              : row[seriesKey].value || "-";

          return [
            ...res,
            {
              name,
              data: data.reduce((result: any, entry: SeriesEntry) => {
                return {
                  ...result,
                  [entry.key]:
                    type === "pie"
                      ? Math.abs(row[entry.key].value)
                      : Number(row[entry.key].value),
                };
              }, {}),
            },
          ];
        }

        return res;
      },
      [] as Array<{
        name: string | number;
        data: Record<string, number>;
      }>
    );

    const sortedData = timeSeries
      ? sortBy(dataRes, ["name"])
      : dataSorting
      ? orderBy(dataRes, [`data.${data[orderByKey].key}`], [sortDir])
      : dataRes;

    // convert the sorted data into graph data structure
    for (const dataEntry of sortedData) {
      for (const entry of data) {
        entry.data.push({
          name: dataEntry.name,
          y: dataEntry.data[entry.key],
        });
      }
    }

    return {
      series: aggregateGraphData(data ?? [], aggregationMethod),
      xAxisTitle: series[0].label,
      yAxisTitle: "",
      colors: colors.length === 0 ? undefined : colors,
    };
  }, [
    series,
    categories,
    datasourceId,
    rowOptions?.rows,
    JSON.stringify(Object.keys(gridOptions?.metadata.columns ?? {})),
    type,
    timeSeries,
    dataSorting,
    sortByKey,
    sortDir,
    aggregationMethod,
    aggregateGraphData,
  ]);

  const options = React.useMemo(() => {
    const titleAreaHeight = title ? 32 + (subTitle ? 14 : 0) : 0;
    // Currency should remain same for all the values to be plotted on the y-axis. Hence, selecting only once from the first series.
    const currency =
      rowOptions?.rows?.[0]?.[seriesData.series?.[0]?.key]?.currency;

    const graphOptions: any = {
      series: seriesData.series,
      chart: {
        type,
        animation: false,
        width,
        // subtracting height of the title div with the container
        height: height - titleAreaHeight,
        backgroundColor: theme.colors.backgroundColor1,
        events: {
          load: onLoad,
        },
      },
      legend: {
        itemStyle: {
          color: theme.colors.textNormal,
        },
      },
      yAxis: {
        min: yMin,
        title: {
          text: seriesData.yAxisTitle,
          style: {
            color: theme.colors.textNormal,
          },
        },
        labels: {
          formatter: function (): any {
            const self: any = this as unknown;
            const col = getColumnDef(
              categories[0].value,
              gridOptions?.metadata.columns
            );
            if (!col) return "Column Error";
            return formatReportValue(
              {
                value: self.value,
                currency,
              },
              col
            );
          },
          style: {
            color: theme.colors.textNormal,
          },
        },
      },
      xAxis: {
        title: {
          text: seriesData.xAxisTitle,
          style: {
            color: theme.colors.textNormal,
          },
        },
        labels: {
          style: {
            color: theme.colors.textNormal,
          },
        },
        min: xMin,
        type: "category",
        crosshair: true,
        ...(type && type.endsWith("line") && timeSeries
          ? {
              type: "datetime",
              labels: {
                formatter: function (): any {
                  const self: any = this as unknown;
                  return seriesData.series[0].data[self.pos]
                    ? formatLabel(
                        new Date(
                          seriesData.series[0].data[self.pos].name
                        ).toISOString()
                      )
                    : "Missing Date";
                },
                style: {
                  color: theme.colors.textNormal,
                },
              },
            }
          : {}),
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          animation: false,
          pointPadding: 0.2,
          borderWidth: 0,
          dataLabels: {
            enabled: showDataLabels,
            formatter: function columnDataLabels() {
              const self = this as unknown as HighchartsBase.PointLabelObject;
              const col = getColumnDef(
                categories[0].value,
                gridOptions?.metadata.columns
              );
              if (!col) return "Column Error";
              return formatReportValue(
                {
                  value: self.y,
                  currency,
                },
                col
              );
            },
          },
          showInLegend: showLegend,
        },

        pie: {
          allowPointSelect: false,
          cursor: "default",
          dataLabels: {
            enabled: showDataLabels,
          },
        },
        line: {},
        spline: {},
      },
      tooltip: {
        xDateFormat: "%b/%e/%Y",
        headerFormat:
          '<span style="font-size:10px"><b>{point.key}</b></span><table>',
        pointFormatter: function pointFormatter() {
          const self = this as unknown as HighchartsBase.Point;
          const color = (self.series as any).color;
          const col = getColumnDef(
            categories[0].value,
            gridOptions?.metadata.columns
          );
          return `<tr><td style="color:${color};padding:0">${
            self.series.name
          }: </td>
               <td style="padding:0;padding-left:3px"><b>${
                 type === "pie"
                   ? self.percentage
                   : col
                   ? formatReportValue(
                       {
                         value: self.y,
                         currency,
                       },
                       col
                     )
                   : "Column Error"
               }${type === "pie" ? "%" : ""}</b></td></tr>`;
        },
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
    };
    if (type !== "pie" && seriesData.colors) {
      graphOptions["colors"] = seriesData.colors;
    }
    return graphOptions;
  }, [
    type,
    showDataLabels,
    tooltipFractions,
    showLegend,
    dataSorting,
    JSON.stringify(rect),
    seriesData,
    xMin,
    yMin,
    width,
    height,
    title,
    subTitle,
  ]);

  return (
    <HighchartsReact key={type} highcharts={HighchartsBase} options={options} />
  );
};

export default RefGraphRenderer;
