import { EmptyView } from "@enfusion-ui/web-components";
import * as React from "react";

export const TextConfluenceView: React.FC<unknown> = () => {
  return (
    <EmptyView>
      <iframe
        title="Help"
        style={{ borderWidth: 0, width: "100%", height: "100%" }}
        src="/wiki/spaces/~6193e11b3618cd006f18c95c/pages/33217"
      />
    </EmptyView>
  );
};
