import HolidayCenterPicker from "@app-views/oems/OrderTicket/panels/varSwap/HolidayCenterPicker";
import {
  businessDayConventionOptions,
  dayCountOptions,
} from "@enfusion-ui/trades";
import {
  ControlledCheckbox,
  ControlledDatePicker,
  ControlledNumericInput,
  ControlledSelect,
  FormPanel,
  LabeledButton,
  PanelRow,
} from "@enfusion-ui/web-components";
import { useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";

import { StyledFormElementHeader } from "../../../components/styledComponents";
import { ActivationDetails } from "./ActivationDetails";

export const SwapDetailsVarSwap: React.FC = () => {
  const isMobile = useIsContentMobile();
  return (
    <FormPanel
      lineStyle
      collapsible
      defaultOpen
      keepRendered
      numColumns={1}
      defaultRendered
      title="Swap Details"
      tabFocusOutline={false}
    >
      <StyledFormElementHeader>Term</StyledFormElementHeader>
      <PanelRow>
        <ControlledDatePicker
          required
          label="Effective Date"
          name="effectiveDate"
        />
        <ControlledDatePicker required label="Expiry Date" name="expiryDate" />
      </PanelRow>

      <PanelRow>
        <ControlledSelect
          required
          label="Day Count"
          name="dayCount"
          options={dayCountOptions}
        />
        <ControlledNumericInput
          required
          label="Strike"
          name="strike"
          formatStyle="percent"
        />
      </PanelRow>

      <PanelRow>
        <ControlledNumericInput
          label="Lower Cap"
          name="lowerCap"
          formatStyle="percent"
        />
        <ControlledNumericInput
          label="Upper Cap"
          name="upperCap"
          formatStyle="percent"
        />
      </PanelRow>

      <PanelRow>
        <ControlledNumericInput
          label="Lower Barrier"
          name="lowerBarrier"
          fractionDigits={2}
        />
        <ControlledNumericInput
          label="Upper Barrier"
          name="upperBarrier"
          fractionDigits={2}
        />
      </PanelRow>

      <PanelRow>
        <ControlledSelect
          options={businessDayConventionOptions}
          label="Business Day Convention"
          name="businessDayConvention"
        />
        <HolidayCenterPicker />
      </PanelRow>

      <PanelRow>
        <LabeledButton disabled hideLabelPlaceholder={isMobile}>
          None
        </LabeledButton>

        <ControlledCheckbox
          label="Final Day Only"
          labelPlacement="right"
          style={{ flex: "0 1" }}
          topLabelPlaceholder={!isMobile}
          name="finalDayOnly"
        />
      </PanelRow>
      <ActivationDetails />
    </FormPanel>
  );
};
