import { TextWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const TextWidgetDiv = styled.div`
  overflow-y: auto;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;

const TextWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const {
    content,
    wrap = false,
    justifyContent = "flex-start",
    alignItems = "flex-start",
    fontSize = "medium",
    fontWeight = "normal",
    color = "inherit",
    showBackground = false,
    style = {},
  } = config as TextWidgetConfig;

  const fixedStyles = {
    fontSize,
    color,
    justifyContent,
    alignItems,
    fontWeight,
    padding: showBackground ? "var(--spacing)" : 0,
    backgroundColor: showBackground
      ? "var(--background-color-0)"
      : "transparent",
    borderRadius: "var(--radius)",
    boxShadow: showBackground ? "var(--shadow)" : "none",
    whiteSpace: "pre-line",
  } as React.CSSProperties;

  if (!wrap) {
    fixedStyles.overflow = "hidden";
    fixedStyles.whiteSpace = "nowrap";
    fixedStyles.textOverflow = "ellipsis";
  }

  return (
    <TextWidgetDiv style={{ ...style, ...fixedStyles }}>
      {content}
    </TextWidgetDiv>
  );
};

export default TextWidget;
