import { OptionSearchResult } from "@enfusion-ui/types";
import { formatDate } from "@enfusion-ui/utils";
import { Checkbox, Spinner } from "@enfusion-ui/web-components";
import {
  AppLogging,
  REST_API,
  styled,
  useIsMobileDevice,
} from "@enfusion-ui/web-core";
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  RowDataUpdatedEvent,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import * as React from "react";

import { RoundedDataGrid } from "../../../DataGrid";

const optionColDefs: ColDef[] = [
  { headerName: "Id", field: "id" },
  { headerName: "Description", field: "description" },
  {
    headerName: "Expiry",
    field: "expirationDate",
    valueFormatter: ({ value }) => formatDate(value) || "",
  },
  { headerName: "Type", field: "callPut" },
  { headerName: "Strike", field: "strike" },
  { headerName: "Reuters Code", field: "ric" },
  { headerName: "Bloomberg Code", field: "bbyellow" },
];

type OptionState = {
  options: OptionSearchResult[];
  optionsLoading: boolean;
};

const defaultOptionState: OptionState = {
  options: [],
  optionsLoading: false,
};

type ExpirationDatesAction =
  | { type: "loading" }
  | { type: "reset" }
  | {
      type: "set";
      expirationDates: string[];
    }
  | { type: "select"; selectedExpirationDate: string };

type ExpirationDatesState = {
  expirationDates: string[];
  expirationDatesLoading: boolean;
  selectedExpirationDate: string;
};

const defaultExpirationDatesState: ExpirationDatesState = {
  expirationDates: [],
  expirationDatesLoading: false,
  selectedExpirationDate: "",
};

const expirationDatesReducer = (
  state: ExpirationDatesState,
  action: ExpirationDatesAction
): ExpirationDatesState => {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        expirationDates: [],
        expirationDatesLoading: true,
        selectedExpirationDate: "",
      };
    case "reset":
      return {
        ...state,
        expirationDates: [],
        expirationDatesLoading: false,
        selectedExpirationDate: "",
      };
    case "set": {
      const { expirationDates } = action;

      const sortedExpirationDates = expirationDates.sort();

      return {
        ...state,
        expirationDates: sortedExpirationDates,
        expirationDatesLoading: false,
        selectedExpirationDate:
          sortedExpirationDates.length > 0 ? sortedExpirationDates[0] : "",
      };
    }
    case "select": {
      const { selectedExpirationDate } = action;
      return { ...state, selectedExpirationDate };
    }
    default:
      return state;
  }
};

const ExpiryOptionContainer = styled.div<{ selected: boolean }>`
  padding: 4px 4px 4px 4px;
  cursor: pointer;
  width: 100%;
  background-color: ${({ selected }: { selected: boolean }) =>
    selected ? "var(--primary-hover)" : "var(--background-color-0)"};
  color: var(--text-normal);
  font-size: 0.875rem;
  border-bottom: 1px solid var(--background-accent);
  :hover {
    background-color: var(--primary-hover);
  }
`;

type ExpiryOptionProps = {
  expirationDate: string;
  selected: boolean;
  onSelect: (expirationDate: string) => void;
};

const ExpiryOption: React.FC<ExpiryOptionProps> = ({
  expirationDate,
  selected,
  onSelect,
}) => {
  const handleClick = () => {
    onSelect(expirationDate);
  };

  return (
    <ExpiryOptionContainer
      key={expirationDate}
      selected={selected}
      onClick={handleClick}
    >
      {expirationDate}
    </ExpiryOptionContainer>
  );
};

const OptionResultsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 150px 5fr;
  grid-gap: 8px;
`;

const MobileOptionResultsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 5fr 150px;
  grid-template-columns: auto;
  grid-gap: 12px;
`;

const Label = styled.div`
  font-size: 14px;
`;

const ExpirationContainer = styled.div`
  display: grid;
  grid-template-rows: 15px auto 1fr;
  grid-gap: 8px;
`;

const MobileHistoricalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`;

const DetailsContainer = styled.div`
  display: grid;
  grid-template-rows: 15px auto;
  grid-gap: 8px;
`;

const OptionExpiryMenuDrawer = styled.div`
  background-color: var(--background-color-2);
  height: 110px;
  overflow-y: auto;
`;

const MobileOptionExpiryMenuDrawer = styled(OptionExpiryMenuDrawer)`
  height: 60px;
`;

export type OptionSearchResultPanelProps = {
  instrumentId: number | null;
  onInstrumentSelect: (instrumentId: number, description: string) => void;
  isOnScreen?: boolean;
};

const OptionSearchResultPanel: React.FC<OptionSearchResultPanelProps> = ({
  onInstrumentSelect,
  instrumentId,
  isOnScreen,
}) => {
  const [optionGridApi, setOptionGridApi] = React.useState<GridApi | null>(
    null
  );
  const [showHistorical, setShowHistorical] = React.useState(false);
  const [
    { expirationDates, expirationDatesLoading, selectedExpirationDate },
    setExpirationDatesState,
  ] = React.useReducer(expirationDatesReducer, defaultExpirationDatesState);

  const isMobile = useIsMobileDevice();

  const [{ options, optionsLoading }, setOptionState] = React.useReducer<
    React.Reducer<OptionState, OptionState>
  >((_oldState, newState) => {
    return newState;
  }, defaultOptionState);

  React.useEffect(() => {
    if (optionGridApi) {
      if (optionsLoading || expirationDatesLoading) {
        optionGridApi.showLoadingOverlay();
      } else if (!options.length) {
        optionGridApi.showNoRowsOverlay();
      } else {
        optionGridApi.hideOverlay();
      }
    }
  }, [optionsLoading, expirationDatesLoading, options]);

  React.useEffect(() => {
    const loadExpirationDates = async () => {
      try {
        if (instrumentId !== null) {
          const expirationDates =
            await REST_API.INSTRUMENT.OPTIONS.GET_EXPIRATION_DATES.FETCH(
              instrumentId,
              showHistorical
            );

          setExpirationDatesState({
            type: "set",
            expirationDates: expirationDates ? expirationDates : [],
          });
        }
      } catch (error) {
        AppLogging.error("Failed to get optionsExpirationDates", error);
      }
    };

    setOptionState(defaultOptionState);

    if (instrumentId) {
      setExpirationDatesState({ type: "loading" });
      loadExpirationDates();
    } else {
      setExpirationDatesState({ type: "reset" });
    }
  }, [instrumentId, showHistorical]);

  React.useEffect(() => {
    const loadOptions = async () => {
      try {
        if (instrumentId !== null) {
          const options = await REST_API.INSTRUMENT.OPTIONS.GET_OPTIONS.FETCH(
            instrumentId,
            selectedExpirationDate
          );

          setOptionState({
            options: options ? options : [],
            optionsLoading: false,
          });
        }
      } catch (error) {
        // TODO : need to surface the error
        AppLogging.error("Failed to load options", error);
      }
    };

    if (selectedExpirationDate) {
      setOptionState({ options: [], optionsLoading: true });
      loadOptions();
    } else {
      setOptionState(defaultOptionState);
    }
  }, [selectedExpirationDate]);

  const handleExpiryOptionSelect = (expirationDate: string) => {
    setExpirationDatesState({
      type: "select",
      selectedExpirationDate: expirationDate,
    });
  };

  const handleOptionGridReady = (event: GridReadyEvent) => {
    setOptionGridApi(event.api);
  };

  const autoSizeOnDataChange = (event: RowDataUpdatedEvent) => {
    if (isOnScreen) event.api.autoSizeAllColumns();
  };

  const handleRowDoubleClick = (event: RowDoubleClickedEvent) => {
    if (onInstrumentSelect) {
      onInstrumentSelect(event.data.id, event.data.description);
    }
  };

  if (isMobile) {
    return (
      <MobileOptionResultsContainer>
        <DetailsContainer>
          <Label>Order Details</Label>
          <RoundedDataGrid
            height={153}
            rowData={options}
            columnDefs={optionColDefs}
            defaultColDef={{
              resizable: true,
              suppressHeaderMenuButton: true,
            }}
            rowSelection="single"
            suppressCellFocus
            onRowDoubleClicked={handleRowDoubleClick}
            onRowDataUpdated={autoSizeOnDataChange}
            onGridReady={handleOptionGridReady}
            overlayNoRowsTemplate="No options to display"
            overlayLoadingTemplate="Searching..."
            suppressContextMenu
            suppressMovableColumns
          />
        </DetailsContainer>
        <ExpirationContainer>
          <Label>Choose Expiration:</Label>
          <MobileOptionExpiryMenuDrawer>
            {expirationDatesLoading ? (
              <Spinner />
            ) : (
              expirationDates.map((date: string) => (
                <ExpiryOption
                  key={date}
                  expirationDate={date}
                  selected={date === selectedExpirationDate}
                  onSelect={handleExpiryOptionSelect}
                />
              ))
            )}
          </MobileOptionExpiryMenuDrawer>
          <MobileHistoricalContainer>
            <Checkbox
              labelPlacement="right"
              label="Show Historical"
              checked={showHistorical}
              onChange={setShowHistorical}
            />
          </MobileHistoricalContainer>
        </ExpirationContainer>
      </MobileOptionResultsContainer>
    );
  }

  return (
    <OptionResultsContainer>
      <ExpirationContainer>
        <Label>Choose Expiration:</Label>
        <OptionExpiryMenuDrawer>
          {expirationDatesLoading ? (
            <Spinner />
          ) : (
            expirationDates.map((date: string) => (
              <ExpiryOption
                key={date}
                expirationDate={date}
                selected={date === selectedExpirationDate}
                onSelect={handleExpiryOptionSelect}
              />
            ))
          )}
        </OptionExpiryMenuDrawer>
        <Checkbox
          labelPlacement="right"
          label="Show Historical"
          checked={showHistorical}
          onChange={setShowHistorical}
        />
      </ExpirationContainer>

      <DetailsContainer>
        <Label>Order Details</Label>
        <RoundedDataGrid
          height={153}
          rowData={options}
          columnDefs={optionColDefs}
          defaultColDef={{
            resizable: true,
            suppressHeaderMenuButton: true,
          }}
          rowSelection="single"
          suppressCellFocus
          onRowDoubleClicked={handleRowDoubleClick}
          onRowDataUpdated={autoSizeOnDataChange}
          onGridReady={handleOptionGridReady}
          overlayNoRowsTemplate="No options to display"
          overlayLoadingTemplate="Searching..."
          suppressContextMenu
          suppressMovableColumns
        />
      </DetailsContainer>
    </OptionResultsContainer>
  );
};

export default OptionSearchResultPanel;
