import { Button, ButtonThemeOptions, Modal } from "@enfusion-ui/web-components";
import { useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";

import { CDXOrderSummaryPanel } from "./CDXOrderSummaryPanel";
import {
  ActionContainer,
  InLineGridWrapper,
  InLineGridWrapperMobile,
  MobileWrapper,
  TitleContainer,
  WebWrapper,
} from "./styles";

export const CDXOrderSummaryModalCore: React.FC = () => {
  const isMobileDevice = useIsContentMobile();

  if (isMobileDevice) {
    return (
      <MobileWrapper>
        <InLineGridWrapperMobile padding="0rem 1rem 0rem 0.5rem">
          <CDXOrderSummaryPanel />
        </InLineGridWrapperMobile>
      </MobileWrapper>
    );
  }
  return (
    <WebWrapper>
      <InLineGridWrapper width="16rem">
        <CDXOrderSummaryPanel />
      </InLineGridWrapper>
    </WebWrapper>
  );
};

export type CDXOrderSummaryModalProps = {
  open: boolean;
  title?: string;
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
  submitActionTheme?: ButtonThemeOptions;
  children: React.ReactElement;
};

const CDXOrderSummaryModal: React.FC<CDXOrderSummaryModalProps> = (
  props: CDXOrderSummaryModalProps
) => {
  const { open, title, onCancel, onSubmit, submitActionTheme, children } =
    props;

  return (
    <Modal
      isOpen={open}
      onClose={onCancel}
      title={<TitleContainer>{title}</TitleContainer>}
      content={
        <div style={{ display: "flex", flexDirection: "column" }}>
          {children}
          <ActionContainer>
            <Button
              theme={submitActionTheme}
              onClick={onSubmit}
              data-e2e-id="summary-btn-proceed"
            >
              Proceed
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </ActionContainer>
        </div>
      }
    />
  );
};

export default CDXOrderSummaryModal;
