import { useRefCallback } from "@enfusion-ui/hooks";
import {
  DisplayExecutionOrderStatus,
  ORDER_STATUS,
  SelectOptionsType,
} from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import * as React from "react";
import { useWatch } from "react-hook-form";

import ControlledAssignedTraderSelect from "../../../components/ReportQueryComponents/AssignedTraderSelect";
import ComplianceStateSelect from "../../../components/ReportQueryComponents/ComplianceSelect";
import { DateRangeSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledMaxResultsInput } from "../../../components/ReportQueryComponents/MaxResultsInput";

export type ExecutionOrderStatus = Omit<
  DisplayExecutionOrderStatus,
  "ContactBroker" | "Acknowledged"
>;

export type OrderStatusMultiSelectProps = Omit<
  Partial<MultiSelectProps<ExecutionOrderStatus>>,
  "options" | "value" | "onChange"
> & {
  value?: ExecutionOrderStatus[] | null;
  onChange?: (values: ExecutionOrderStatus[]) => void;
  label: string;
};

const OrderStatusMultiSelect: React.FC<OrderStatusMultiSelectProps> = ({
  value: values,
  onChange,
  label,
  ...rest
}) => {
  const [selectedOrderStatuses, setSelectedOrderStatuses] = React.useState<
    ExecutionOrderStatus[] | null
  >([]);

  React.useEffect(() => {
    values && setSelectedOrderStatuses(values);
  }, [values]);

  const handleChange = useRefCallback(
    (values: SelectOptionsType<ExecutionOrderStatus>[]) => {
      const newOrderStatuses = values?.length
        ? values.map(
            (opt: SelectOptionsType<ExecutionOrderStatus>) => opt?.value
          )
        : [];
      setSelectedOrderStatuses(newOrderStatuses);
      onChange?.(newOrderStatuses);
    },
    [onChange]
  );

  return (
    <MultiSelect
      {...rest}
      value={
        selectedOrderStatuses
          ? getSelectedValues(selectedOrderStatuses, ORDER_STATUS)
          : []
      }
      onChange={(selectedOptions) =>
        handleChange(
          selectedOptions as SelectOptionsType<ExecutionOrderStatus>[]
        )
      }
      label={label}
      options={ORDER_STATUS}
    />
  );
};

const ComplianceBlotterQuickFilter = () => {
  const assignedTraderIds = useWatch<Array<number>>({
    name: "assignedTraderIds",
  });
  const defaultAssignedTraderIdsRef = React.useRef(assignedTraderIds ?? []);

  return (
    <>
      <DateRangeSelection
        name="orderDateRange"
        startDateLabel="Order Date"
        endDateLabel="To"
      />
      <ControlledAssignedTraderSelect
        defaultAssignedTrader={defaultAssignedTraderIdsRef.current}
      />
      <ComplianceStateSelect />
      <ControlledInputBase
        name="orderStatuses"
        render={({ ref: _ref, ...rest }) => (
          <OrderStatusMultiSelect label="Order Status" {...rest} />
        )}
      />
      <ControlledMaxResultsInput label="Limit" />
    </>
  );
};

export default ComplianceBlotterQuickFilter;
