import {
  CenterContent,
  ContentMessage,
  TertiaryTabList,
} from "@enfusion-ui/web-components";
import { styled, useAuth } from "@enfusion-ui/web-core";
import { faArrowAltCircleUp } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";

import OEMSQuotesProvider from "../../../context/oems/OEMSQuotesProvider";
import OEMSRoutesProvider from "../../../context/oems/OEMSRoutesProvider";
import { ComplianceTable } from "../OrderTicket/components/ComplianceTable";
import AllocationsList from "./blotters/AllocationsList";
import useRoutes from "./blotters/behavior/useRoutes";
import RoutesAndFillsList from "./blotters/RoutesAndFillsList";
import RoutesBlotter from "./blotters/RoutesBlotter";
import QuotesView from "./QuotesView";

const OrderDetailsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: var(--spacing-l);
`;

type OrderDetailsProps = {
  orderId: number | null;
  parentHeight?: number;
  parentWidth?: number;
};

const CountTitle: React.FC = () => {
  const { routes } = useRoutes();
  const [routesCount, setRoutesCount] = React.useState<number>(0);

  React.useEffect(() => {
    setRoutesCount(routes.length);
  }, [routes]);

  return <>{routesCount}</>;
};

const DetailsContent = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
`;

export const OrderDetails: React.FC<OrderDetailsProps> = ({
  orderId,
  parentHeight,
  parentWidth,
}) => {
  const { user } = useAuth();
  const [selectedTab, setSelectedTab] = React.useState<string | undefined>(
    "compliance"
  );

  const orderDetailsTabs = [
    {
      label: "Compliance",
      key: "compliance",
    },
    {
      label: "Fills",
      key: "fills",
    },
    ...(!user?.flags?.OMS.readOnly
      ? [
          {
            label: "Selected Routes",
            key: "routes",
            count: <CountTitle />,
          },
          {
            label: "Order Allocation",
            key: "allocations",
          },
          // {
          //   label: "Quotes",
          //   value: "quotes",
          // },
        ]
      : []),
  ];

  if (!orderId) {
    return (
      <CenterContent fillHeight style={{ paddingTop: "var(--spacing-l)" }}>
        <ContentMessage
          icon={faArrowAltCircleUp}
          message="Select an order to view details"
          parentHeight={(parentHeight || 8) - 8}
          parentWidth={parentWidth}
        />
      </CenterContent>
    );
  }

  return (
    <OrderDetailsWrapper>
      <OEMSRoutesProvider orderId={orderId}>
        <div style={{ marginBottom: "var(--spacing)" }}>
          <TertiaryTabList
            tabs={orderDetailsTabs}
            value={selectedTab}
            onSelect={(val) => setSelectedTab(val)}
            scrollToView={false}
          />
        </div>

        <DetailsContent>
          {selectedTab === "compliance" && (
            <ComplianceTable orderId={orderId} />
          )}
          {selectedTab === "fills" && (
            <RoutesAndFillsList allowRefresh="above" />
          )}
          {selectedTab === "allocations" && (
            <AllocationsList orderId={orderId} height="100%"></AllocationsList>
          )}
          {selectedTab === "quotes" && (
            <OEMSQuotesProvider orderId={orderId}>
              <QuotesView />
            </OEMSQuotesProvider>
          )}
          {selectedTab === "routes" && <RoutesBlotter />}
        </DetailsContent>
      </OEMSRoutesProvider>
    </OrderDetailsWrapper>
  );
};
