import {
  basicInfoMap,
  useRefCallback,
  useRestAbortableOptions,
} from "@enfusion-ui/hooks";
import { SelectOptionsType } from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

export const SourceIdsSelect: React.FC<
  Omit<Partial<MultiSelectProps<number>>, "options" | "value" | "onChange"> & {
    value?: number[] | null;
    onChange?: (value: number[] | null) => void;
  }
> = ({
  value,
  onChange,
  label = "Source",
  placeholder = "All",
  inputId = "source-ids-multi-selection-id",
  ...rest
}) => {
  const { options, loading } = useRestAbortableOptions(
    REST_API.LEGALENTITY.GET_SOURCES.FETCH,
    basicInfoMap
  );

  const handleChange = useRefCallback(
    (values: SelectOptionsType<number>[]) => {
      const ids = values?.length
        ? values.map((option: SelectOptionsType<number>) => option.value)
        : null;
      onChange?.(ids);
    },
    [onChange]
  );

  return (
    <MultiSelect
      {...rest}
      value={getSelectedValues(value || [], options)}
      onChange={(options) =>
        handleChange(options as SelectOptionsType<number>[])
      }
      label={label}
      options={options}
      isLoading={loading}
      inputId={inputId}
      placeholder={placeholder}
    />
  );
};

export const ControlledSourceIdsSelect: React.FC<{
  name?: string;
  label?: string;
}> = ({ name = "sourceIds", label }) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...rest }) => (
        <SourceIdsSelect label={label} {...rest} />
      )}
    />
  );
};
