import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

export type FillerProps = {
  percent: number;
  rounded: boolean;
  bgColor?: string;
};

const LoadingBarContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  padding: 2px;
`;

const FillerContainer = styled.div<FillerProps>`
  width: ${(props: FillerProps) => props.percent + "%"};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "var(--primary)")};
  border-radius: ${({ rounded }) => (rounded ? "2px" : "0px")};
  transition: width 300ms ease-in-out;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
`;

const Label = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-size: 0.7rem;
  line-height: 20px;
`;

const LoadingBar: React.FC<{
  percent: number;
  text?: string;
  className?: string;
  style?: React.CSSProperties;
  rounded?: boolean;
  backgroundColor?: string;
  progressBarColor?: string;
}> = ({
  text = "",
  style,
  percent,
  className,
  rounded = true,
  backgroundColor = "var(--text-muted)",
  progressBarColor = "var(--primary)",
}) => {
  return (
    <LoadingBarContainer
      className={className}
      style={{ backgroundColor, ...style }}
    >
      <Label>{text}</Label>
      <FillerContainer
        percent={percent}
        rounded={rounded}
        bgColor={progressBarColor}
      />
    </LoadingBarContainer>
  );
};

export default LoadingBar;
