import { UNKNOWN_INSTRUMENT_INFO, useInstrument } from "@enfusion-ui/core";
import { Quote, ViewModeTypes } from "@enfusion-ui/types";
import { styled, useIsContentMobile } from "@enfusion-ui/web-core";
import { noop } from "lodash";
import * as React from "react";

import { GridView } from "../../../components/display/GridView";
import { useMarketData } from "../../../context/marketData/context";
import { getDisplayValue } from "../utils/getDisplayValue";
import {
  LabeledValue,
  NumericLabeledValue,
  VarColorText,
} from "./styledComponents";

export type MarketDataDisplayCoreProps = {
  bid?: number | null;
  ask?: number | null;
  last?: number | null;
  volume?: number | null;
  change?: number | null;
  changePct?: number | null;
  currencyCode?: string | null;
  exchange?: string | null;
  exception?: string | null;
  orientation?: ViewModeTypes;
  view?: "summary";
  fields?: string[];
};

const MarketDataRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const DataLabel = styled.div`
  font-weight: bold;
  font-size: 0.8em;
  line-height: 1.25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DataValue = styled.div`
  font-size: 1em;
  line-height: 1.25em;
  height: 1.25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MarketDataGridView = ({
  exception = null,
  bid = null,
  ask = null,
  last = null,
  volume = null,
  change = null,
  changePct = null,
  fields = [],
}: Omit<MarketDataDisplayCoreProps, "viewMode">) => {
  const gridData = [
    {
      fieldName: "Bid",
      fieldValue: getDisplayValue(bid),
      fieldTitle: exception,
    },
    {
      fieldName: "Ask",
      fieldValue: getDisplayValue(ask),
      fieldTitle: exception,
    },
    {
      fieldName: "Last",
      fieldValue: getDisplayValue(last),
      fieldTitle: exception,
    },
    {
      fieldName: "Change",
      fieldValue: getDisplayValue(change),
      fieldTitle: exception,
    },
    {
      fieldName: "Change %",
      fieldValue: getDisplayValue(changePct, 2, "percent"),
      fieldTitle: exception,
    },
    { fieldName: "Volume", fieldValue: volume ?? "-", fieldTitle: exception },
  ].filter((data) => fields.includes(data.fieldName));

  return (
    <GridView
      gridData={gridData}
      showColored={{
        onValueChanged: (value: number | string | null) =>
          !!exception
            ? "danger"
            : !value || typeof value === "string"
            ? null
            : value > 0
            ? "success"
            : "danger",
      }}
    />
  );
};

export const MarketDataOrderSummary = ({
  exception = null,
  bid = null,
  ask = null,
  last = null,
  change = null,
  currencyCode = null,
}: Omit<MarketDataDisplayCoreProps, "viewMode">) => {
  const gridData = [
    {
      fieldName: "Bid",
      fieldValue: getDisplayValue(bid),
      fieldTitle: exception,
    },
    {
      fieldName: "Ask",
      fieldValue: getDisplayValue(ask),
      fieldTitle: exception,
    },
    {
      fieldName: "Last",
      fieldValue: getDisplayValue(last),
      fieldTitle: exception,
    },
    {
      fieldName: "Change",
      fieldValue: getDisplayValue(change),
      fieldTitle: exception,
    },
    {
      fieldName: "CCY",
      fieldValue: currencyCode ?? "N/A",
      fieldTitle: exception,
    },
  ];

  return (
    <GridView
      gridData={gridData}
      showColored={{
        onValueChanged: (value: number | string | null) =>
          !!exception
            ? "danger"
            : !value || typeof value === "string"
            ? null
            : value > 0
            ? "success"
            : "danger",
      }}
    />
  );
};

const MarketDataRowView = ({
  bid = null,
  ask = null,
  last = null,
  volume = null,
  change = null,
  changePct = null,
  currencyCode = null,
  exchange = null,
  fields = [],
}: Omit<MarketDataDisplayCoreProps, "viewMode">) => {
  const isMobileDevice = useIsContentMobile();
  const changeColor = !change ? null : change > 0 ? "success" : "danger";

  const marketDataElements = React.useMemo(() => {
    const restrictedFields = ["CCY", "Change", "Exchange"];
    return [
      {
        fieldName: "Bid",
        fieldElement: (
          <NumericLabeledValue label="Bid" title={getDisplayValue(bid)}>
            {getDisplayValue(bid)}
          </NumericLabeledValue>
        ),
      },
      {
        fieldName: "Ask",
        fieldElement: (
          <NumericLabeledValue label="Ask" title={getDisplayValue(ask)}>
            {getDisplayValue(ask)}
          </NumericLabeledValue>
        ),
      },
      {
        fieldName: "Last",
        fieldElement: (
          <NumericLabeledValue label="Last" title={getDisplayValue(last)}>
            {getDisplayValue(last)}
          </NumericLabeledValue>
        ),
      },
      {
        fieldName: "Change",
        fieldElement: (
          <NumericLabeledValue label="Chg" title={getDisplayValue(change)}>
            <VarColorText colorValue={changeColor}>
              {getDisplayValue(change)}
            </VarColorText>
          </NumericLabeledValue>
        ),
      },
      {
        fieldName: "Change %",
        fieldElement: (
          <NumericLabeledValue
            label="Chg %"
            title={getDisplayValue(changePct, 2, "percent")}
          >
            <VarColorText colorValue={changeColor}>
              {getDisplayValue(changePct, 2, "percent")}
            </VarColorText>
          </NumericLabeledValue>
        ),
      },
      {
        fieldName: "Volume",
        fieldElement: (
          <NumericLabeledValue
            label="Volume"
            title={getDisplayValue(volume, 0)}
          >
            {getDisplayValue(volume, 0, "number", true)}
          </NumericLabeledValue>
        ),
      },
      {
        fieldName: "CCY",
        fieldElement: (
          <LabeledValue label="Ccy" title={currencyCode ?? "N/A"}>
            {currencyCode ?? "N/A"}
          </LabeledValue>
        ),
      },
      {
        fieldName: "Exchange",
        fieldElement: (
          <LabeledValue label="Exchange" title={exchange ?? "N/A"}>
            {exchange ?? "N/A"}
          </LabeledValue>
        ),
      },
    ].filter((data) =>
      fields.includes(data.fieldName)
        ? isMobileDevice && restrictedFields.includes(data.fieldName)
          ? false
          : true
        : false
    );
  }, [fields]);

  return (
    <MarketDataRow>
      {marketDataElements.map(({ fieldElement }) => fieldElement)}
    </MarketDataRow>
  );
};

export const MarketDataDisplayCore: React.FC<MarketDataDisplayCoreProps> = ({
  orientation = "vertical",
  view,
  fields,
  ...rest
}) => {
  if (!fields?.length && view !== "summary") {
    return <MarketDataRow>No Fields selected</MarketDataRow>;
  }

  let content = <MarketDataRowView fields={fields} {...rest} />;

  if (view === "summary") {
    content = <MarketDataOrderSummary {...rest} />;
  } else if (orientation === "vertical") {
    content = <MarketDataGridView fields={fields} {...rest} />;
  }

  return <>{content}</>;
};

export type MarketDataDisplayProps = {
  orientation?: ViewModeTypes;
  view?: "summary";
  fields?: string[];
};

const MarketDataDisplay: React.FC<MarketDataDisplayProps> = ({
  orientation,
  view,
  fields,
}) => {
  const instrument = useInstrument();
  const { subscribeToMarketData } = useMarketData();
  const [quote, setQuote] = React.useState<Quote | null>(null);

  React.useEffect(() => {
    let unsubscribe = noop;
    if (instrument && instrument.id !== UNKNOWN_INSTRUMENT_INFO.id) {
      unsubscribe = subscribeToMarketData(instrument.id, (value) =>
        setQuote(value)
      );
    } else {
      setQuote(null);
    }

    return unsubscribe;
  }, [instrument]);

  const currencyCode = instrument?.currency?.code ?? null;
  const exchange = instrument?.exchangeName ?? null;

  return (
    <MarketDataDisplayCore
      orientation={orientation}
      view={view}
      {...(quote || {})}
      currencyCode={currencyCode}
      exchange={exchange}
      fields={fields}
    />
  );
};

export default MarketDataDisplay;
