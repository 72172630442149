import HighchartsBase from "highcharts";
import more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import * as React from "react";
import { useMeasure } from "react-use";

import { SizedChartContainer } from "./styles";

more(HighchartsBase);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatterCreator(cb: (scope: any) => string) {
  const a = {
    a: function formatter() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const self = this as any;
      return cb(self);
    },
  };
  return a.a;
}

export const SizedChart: React.FC<
  Omit<HighchartsReact.Props, "options"> & {
    className?: string;
    getOptions: (
      width: number,
      height: number
    ) => HighchartsReact.Props["options"];
  }
> = ({ className, getOptions, ...props }) => {
  const [measureRef, { width, height }] = useMeasure<HTMLDivElement>();

  const options = React.useMemo(
    () => ({
      colors: [
        ...new Array(13)
          .fill("0")
          .map((_, idx) => `var(--dashboard-color-${idx + 2})`),
        ...new Array(13)
          .fill("0")
          .map((_, idx) => `var(--dashboard-color-${idx + 2})`),
      ],
      ...getOptions(width, height),
    }),
    [width, height, getOptions]
  );

  return (
    <div
      ref={measureRef}
      style={{
        flex: 1,
        width: "100%",
        maxWidth: "100%",
        position: "relative",
        borderRadius: "var(--radius)",
      }}
    >
      <SizedChartContainer>
        <HighchartsReact
          highcharts={HighchartsBase}
          {...props}
          options={options}
          containerProps={{
            ...(props.containerProps || {}),
            className,
          }}
        />
      </SizedChartContainer>
    </div>
  );
};
