import * as React from "react";

import { useGDPR } from "../../context/gdpr/context";
import AcceptAllCookiesModal from "./AcceptAllCookiesModal";
import RefreshBrowserModal from "./RefreshBrowserModal";
import SettingCookiesModal from "./SettingCookiesModal";

export type GDPRModalsProps = {
  acceptAllCookiesModalOpen: boolean;
  settingCookiesModalOpen: boolean;
};

const GDPRModals: React.FC<GDPRModalsProps> = ({
  acceptAllCookiesModalOpen,
  settingCookiesModalOpen,
}) => {
  const {
    refreshBrowserModalOpen,
    toggleAcceptAllCookiesModalOpen,
    toggleSettingCookiesModalOpen,
    toggleRefreshBrowserModalOpen,
    toggleCustomizeCookies,
    acceptAll,
  } = useGDPR();

  return (
    <>
      <AcceptAllCookiesModal
        open={acceptAllCookiesModalOpen}
        onAccept={acceptAll}
        onCustomize={toggleCustomizeCookies}
        onCancel={toggleAcceptAllCookiesModalOpen}
      />
      <SettingCookiesModal
        open={settingCookiesModalOpen}
        onCancel={toggleSettingCookiesModalOpen}
      />
      <RefreshBrowserModal
        open={refreshBrowserModalOpen}
        onClose={toggleRefreshBrowserModalOpen}
      />
    </>
  );
};

export default GDPRModals;
