import { ICellRendererParams } from "ag-grid-enterprise";
import * as React from "react";

import LoadingBar from "../../../../../components/display/LoadingBar";

const PercentageCellRenderer: React.FC<ICellRendererParams> = React.memo(
  function PercentageCellRenderer({ data, value, valueFormatted }) {
    if (!data) return null;
    const opacity = data.completed ? 0.45 : 0.95;
    return (
      <LoadingBar
        style={{ opacity }}
        rounded
        percent={Number(value) * 100}
        text={valueFormatted || ""}
        backgroundColor="transparent"
      />
    );
  }
);

export default PercentageCellRenderer;
