import { useMounted } from "@enfusion-ui/hooks";
import {
  ConnectionStatus,
  LocateRow,
  PrimeBrokerEnquire,
} from "@enfusion-ui/types";
import { AppLogging, LocatesContext } from "@enfusion-ui/web-core";
import { useWorkerModule } from "@enfusion-ui/web-workers";
import * as React from "react";

import {
  ConnectionType,
  useConnectionStatus,
} from "../../../context/connectionStatus/context";

const LocatesProvider: React.FC<
  React.PropsWithChildren<{
    enabled: boolean;
  }>
> = ({ children, enabled }) => {
  const isMounted = useMounted();

  const {
    enableModule,
    disableModule,
    subscribeToModule,
    getCurrentState,
    postMessage,
  } = useWorkerModule("locate");
  const [locateRows, setLocateRows] = React.useState<LocateRow[]>([]);
  const [enquiryRows, setEnquiryRows] = React.useState<LocateRow[]>([]);
  const [isConnected, setIsConnected] = React.useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = React.useState<boolean>(false);

  const { updateStatus } = useConnectionStatus();

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return subscribeToModule(async (message: any) => {
      const { type, payload } = message;
      switch (type) {
        case "error": {
          updateStatus(ConnectionType.Locates, ConnectionStatus.ERROR);
          console.error("Error occurred while connecting to locates");
          break;
        }
        case "locates-error": {
          const { errorMessage } = payload;
          updateStatus(ConnectionType.Locates, ConnectionStatus.ERROR);
          console.error(
            "Error occurred while connecting to locates",
            errorMessage
          );
          break;
        }

        case "locates-subscribe-status": {
          const { isSubscribed } = payload;

          if (isSubscribed) {
            setIsSubscribed(true);
          } else {
            console.error("Error occurred while subscribing to locates");
            setIsSubscribed(false);
          }

          break;
        }
        case "init-status": {
          const { isConnected } = payload;
          updateStatus(ConnectionType.Locates, ConnectionStatus.CONNECTED);
          if (isConnected) {
            setIsConnected(true);
          }
          break;
        }
        case "locates-update": {
          const { locateRows, enquiryRows } = payload;
          AppLogging.info("Locates and Enquiries received.");
          setEnquiryRows(enquiryRows);
          setLocateRows(locateRows);
          break;
        }

        case "close": {
          updateStatus(ConnectionType.Locates, ConnectionStatus.DISCONNECTED);
          if (isMounted()) {
            setLocateRows([]);
            setEnquiryRows([]);
            setIsConnected(false);
            setIsSubscribed(false);
          }

          break;
        }
        case "terminated": {
          updateStatus(ConnectionType.Locates, ConnectionStatus.DISCONNECTED);

          if (isMounted()) {
            setLocateRows([]);
            setEnquiryRows([]);
            setIsConnected(false);
            setIsSubscribed(false);
          }

          break;
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (enabled && isMounted()) {
      enableModule();
      getCurrentState()?.then(
        async (msg: { payload: { socketStatus: number } }) => {
          const { socketStatus } = msg.payload;

          if (socketStatus === WebSocket.OPEN)
            updateStatus(ConnectionType.Locates, ConnectionStatus.CONNECTED);
        }
      );
    }

    return () => {
      disableModule();
    };
  }, [enabled]);

  const subscribeInstrument = (instrumentId: number) => {
    postMessage({
      command: "subscribe-instrument",
      payload: { instrumentId },
    });
    // TODO may need to think about handling unsubscribe
  };

  const unsubscribeInstrument = (instrumentId: number) => {
    setLocateRows([]);
    setEnquiryRows([]);
    postMessage({
      command: "unsubscribe-instrument",
      payload: { instrumentId },
    });
  };

  const enquireForQuantity = (
    instrumentId: number | null,
    quantity: number,
    brokers: PrimeBrokerEnquire[]
  ) => {
    postMessage({
      command: "enquire-quantity",
      payload: { instrumentId, quantity, brokers },
    });
  };

  return (
    <LocatesContext.Provider
      value={{
        locateRows,
        enquiryRows,
        subscribeInstrument,
        unsubscribeInstrument,
        enquireForQuantity,
        isConnected,
        isSubscribed,
      }}
    >
      {children}
    </LocatesContext.Provider>
  );
};

export default LocatesProvider;
