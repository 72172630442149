/* eslint-disable @typescript-eslint/no-explicit-any */
import DatasourcePicker from "@app-views/dashboards/widget/components/DatasourcePicker";
import {
  ListViewWidgetConfig,
  useDatasourceColumnsList,
  useWidget,
} from "@enfusion-ui/dashboards";
import { getSelectOption } from "@enfusion-ui/utils";
import {
  Checkbox,
  FormSectionAccordion,
  Select,
  TextInput,
} from "@enfusion-ui/web-components";
import { useReports } from "@enfusion-ui/web-core";
import * as React from "react";

import { InputWrapper, SingleRowContainer } from "../../styles";

const ListViewEditWidget: React.FC<unknown> = () => {
  const { changeConfigKeyToBeApplied, editedConfig } = useWidget();
  const [errors, setErrors] = React.useState<Record<string, string>>({});

  const {
    column,
    key = "List View",
    showTitle = true,
    autoSelectFirst = false,
  } = editedConfig as ListViewWidgetConfig;

  const selectorOptions = useDatasourceColumnsList(useReports);

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange = (key: string) => (value: boolean) => {
    changeConfigKeyToBeApplied(key, value);
  };

  const handleSelectChange = (key: string) => (value: any) => {
    try {
      changeConfigKeyToBeApplied(key, value?.value);
    } catch (err: any) {
      setErrors((errs) => ({ ...errs, [key]: err?.message }));
    }
  };

  return (
    <>
      <FormSectionAccordion title="List View Options">
        <TextInput
          label="Key"
          value={key}
          name="key"
          onChange={handleTextChange("key")}
        />
        <SingleRowContainer noMargin>
          <Checkbox
            checked={showTitle}
            label="Show Title"
            onChange={handleBasicChange("showTitle")}
            topLabelPlaceholder
          />
          <Checkbox
            checked={autoSelectFirst}
            label="Auto Select First"
            onChange={handleBasicChange("autoSelectFirst")}
            topLabelPlaceholder
          />
        </SingleRowContainer>
      </FormSectionAccordion>

      <FormSectionAccordion title="Datasource">
        <DatasourcePicker />
      </FormSectionAccordion>

      <FormSectionAccordion title="Columns">
        <InputWrapper>
          <Select
            maxWidth={150}
            label="Column"
            name="column"
            options={selectorOptions}
            value={column ? getSelectOption(selectorOptions, column) : null}
            onChange={handleSelectChange("column")}
            errors={errors}
            inputId="list-view-column-selection-id"
          />
        </InputWrapper>
      </FormSectionAccordion>
    </>
  );
};

export default ListViewEditWidget;
