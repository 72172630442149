import { useAppTutorial } from "@app-components/Tour/context";
import { TutorialType } from "@app-components/Tour/utils";
import { useRefCallback } from "@enfusion-ui/hooks";
import { NodeData, WebReportAccountSelection } from "@enfusion-ui/types";
import { ControlledInputBase } from "@enfusion-ui/web-components";
import { isEqual } from "lodash";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import AccountSelect from "../../../../components/inputs/account/AccountSelect";
import { InputContainer } from "./ReportBaseForm";

type FundInfo = {
  fundIds: number[];
  accountIds: number[];
};

function getSelectedIds(selection: FundInfo | undefined) {
  if (selection) {
    return [
      ...(selection?.accountIds?.map((i) => String(i)) ?? []),
      ...(selection?.fundIds?.map((i) => String(i)) ?? []),
    ];
  }
}

// This account select is specific for reports module
const ControlledAccountSelect: React.FC<{
  name?: string;
  defaultAccountSelection?: WebReportAccountSelection;
  label?: string;
  inline?: boolean;
  required?: boolean;
  performDirtyCheck?: boolean;
  allowClickOutSide?: boolean;
  containerStyle?: React.CSSProperties;
}> = ({
  name = "accountSelection",
  defaultAccountSelection,
  label = "Default Accounts",
  inline,
  required,
  performDirtyCheck,
  allowClickOutSide,
  containerStyle = {},
}) => {
  const { setValue } = useFormContext();
  const { tutorialType, setMeta, step } = useAppTutorial();
  const selectedIdsValue = useWatch<FundInfo>({ name });
  const selectedIds = React.useMemo(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    () => getSelectedIds(defaultAccountSelection as any),
    [defaultAccountSelection]
  );
  const isSelectFundStepPwbTour =
    tutorialType === TutorialType.Portfolio && step?.name === "select-fund";

  React.useEffect(() => {
    if (isSelectFundStepPwbTour) setMeta({ disableButton: true });
  }, [isSelectFundStepPwbTour]);

  const handleOnChange = useRefCallback(
    (nodeData: NodeData[]) => {
      const holdId: { accountIds: number[]; fundIds: number[] } =
        nodeData?.reduce<{ accountIds: number[]; fundIds: number[] }>(
          (res, data) => {
            switch (data.level) {
              case "Custodian":
                if (data.nodes?.length) {
                  res["accountIds"] = [
                    ...res["accountIds"],
                    ...data.nodes.map((x) => Number(x.id)),
                  ];
                }
                break;
              case "Account":
                res["accountIds"].push(Number(data.accountId));
                break;
              case "Fund":
                res["fundIds"].push(Number(data.fundId));
                break;
              case "FundContainer":
                if (data.nodes?.length) {
                  res["fundIds"] = [
                    ...res["fundIds"],
                    ...data.nodes.map((x) => Number(x.fundId)),
                  ];
                }
                break;
            }
            return res;
          },
          { accountIds: [], fundIds: [] }
        );

      setValue(
        name,
        {
          ...selectedIdsValue,
          accountIds: [...new Set(holdId.accountIds)],
          fundIds: [...new Set(holdId.fundIds)],
        },
        {
          shouldDirty: !performDirtyCheck
            ? true
            : !isEqual(
                getSelectedIds(selectedIdsValue)?.sort(),
                getSelectedIds(holdId)?.sort()
              ),
        }
      );

      if (isSelectFundStepPwbTour)
        setMeta({ disableButton: !holdId.fundIds.length });
    },
    [
      defaultAccountSelection,
      selectedIds,
      isSelectFundStepPwbTour,
      selectedIdsValue,
    ]
  );

  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...rest }) => (
        <InputContainer style={containerStyle}>
          <AccountSelect
            {...rest}
            values={selectedIds}
            label={label}
            inline={inline}
            onChange={handleOnChange}
            checkSelections
            required={required}
            allowClickOutSide={allowClickOutSide}
          />
        </InputContainer>
      )}
    />
  );
};

export default ControlledAccountSelect;
