import { useAnalyticsContext } from "@app-context/analytics/context";
import {
  createPieChartSubtitle,
  formatterCreator,
} from "@app-views/analytics/utils";
import { formatNumber, formatPercentage } from "@enfusion-ui/utils";
import * as React from "react";

import { PieChartBlock } from "./PieChartBlock";

const num = (val?: number | null) =>
  formatNumber(val ?? 0, {
    fractionDigits: [0, 2, 1],
    reduce: true,
  });

const per = (val?: number | null) =>
  formatPercentage(val ?? 0, [0, 2], false, undefined, true);

export const NameTurnoverSummary: React.FC = () => {
  const { data } = useAnalyticsContext();

  const { centerText, seriesData, tooltipFormatter } = React.useMemo(() => {
    const nameTurnover = data?.portfolioMovement.nameTurnover ?? null;
    return {
      centerText: createPieChartSubtitle({
        title: num(nameTurnover?.value ?? 0),
        description: "Of Beginning Period Held Positions",
        subText: per(nameTurnover?.change ?? 0),
        subTextColor: (nameTurnover?.change ?? 0) < 0 ? "danger" : "success",
      }),
      seriesData: [
        { name: "New", y: nameTurnover?.data.new ?? 0 },
        { name: "Removed", y: nameTurnover?.data.removed ?? 0 },
        { name: "Increased", y: nameTurnover?.data.increased ?? 0 },
        { name: "Decreased", y: nameTurnover?.data.decreased ?? 0 },
      ],
      tooltipFormatter: formatterCreator(
        ({ color, key, y, percentage }) =>
          `<span style="color: ${color}">${key}</span>: ${y}(${num(
            percentage
          )}%)`
      ),
    };
  }, [data?.portfolioMovement.nameTurnover]);

  return (
    <PieChartBlock
      title="Name Turnover"
      centerText={centerText}
      tooltipFormatter={tooltipFormatter}
      seriesData={seriesData}
    />
  );
};
