import { pxToPtConversion } from "@app-views/dashboards/pdf/utils";
import { ThemeDefinition } from "@enfusion-ui/core";
import { TextInputWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { withTheme } from "@enfusion-ui/web-core";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import * as React from "react";

const pdfStyles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    paddingTop: pxToPtConversion(12),
  },
  label: {
    width: "100%",
    fontFamily: "Lato",
    fontSize: pxToPtConversion(8),
    fontWeight: "medium",
    marginLeft: pxToPtConversion(4),
    paddingTop: pxToPtConversion(4),
  },
  input: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderRadius: pxToPtConversion(4),
    padding: pxToPtConversion(4),
  },
  text: {
    fontSize: pxToPtConversion(10),
    fontFamily: "Lato",
    width: "100%",
    textOverflow: "ellipsis",
  },
  icon: {
    width: pxToPtConversion(15),
    height: pxToPtConversion(15),
    alignItems: "center",
    justifyContent: "center",
  },
});

const PdfTextInputWidget = withTheme(
  ({ icon, theme }: { icon: React.ReactNode; theme: ThemeDefinition }) => {
    const { config, addChannelKeys, setChannelData } = useWidget();
    const { defaultValue, textarea, key } = config as TextInputWidgetConfig;

    React.useEffect(() => {
      addChannelKeys([
        {
          name: key,
          description: "Text Input value",
          type: ChannelDataType.String,
        },
      ]);
      setChannelData([defaultValue]);
    }, [setChannelData, defaultValue, key]);

    return (
      <View style={[pdfStyles.container]}>
        <Text
          style={[
            pdfStyles.label,
            {
              color: theme.colors.textNormal,
            },
          ]}
        >
          {key}
        </Text>
        <View
          style={[
            pdfStyles.input,
            {
              backgroundColor: theme.colors.inputBackground,
              border: `1px solid ${theme.colors.inputBorder}`,
              height: textarea ? "100%" : pxToPtConversion(30),
              alignItems: textarea ? "flex-start" : "center",
            },
          ]}
        >
          {defaultValue && (
            <Text style={[pdfStyles.text, { color: theme.colors.textNormal }]}>
              {defaultValue}
            </Text>
          )}
          {!textarea && defaultValue && icon ? (
            <View style={[pdfStyles.icon]}>{icon}</View>
          ) : null}
        </View>
      </View>
    );
  }
);

export default PdfTextInputWidget;
