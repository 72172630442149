/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { InstrumentInfo } from "@enfusion-ui/types";
import { Modal } from "@enfusion-ui/web-components";
import {
  errorToast,
  useIsMobileDevice,
  usePositionReportData,
} from "@enfusion-ui/web-core";
import { RowDoubleClickedEvent } from "ag-grid-community";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";

import { SearchableReportView } from "../../../reports/components/SearchableReportView";
import { fetchInstrument } from "../../utils/actions";

const getRowId = (params: any) => {
  return params.data.__row_idx;
};

export type PositionFinderModalProps = {
  open: boolean;
  onClose: VoidFunction;
  onSelect: (instrument: InstrumentInfo | null) => void;
};

const PositionFinderModal: React.FC<PositionFinderModalProps> = ({
  open,
  onClose,
  onSelect,
}) => {
  const isMounted = useMounted();
  const isMobileDevice = useIsMobileDevice();

  const { columnDefs, rowData } = usePositionReportData();

  const onRowDoubleClicked = useRefCallback(
    async (rowDoubleClickEvent: RowDoubleClickedEvent) => {
      const row = rowDoubleClickEvent.data;

      const instrumentInfo = await fetchInstrument(
        row.positionInstrumentId?.value
      );

      if (instrumentInfo) {
        onSelect(instrumentInfo);
      } else {
        errorToast("No valid instrument");
      }
    },
    [onSelect]
  );

  const reportId = React.useMemo(() => uuidv4(), []);

  return (
    <Modal
      title="Position Finder"
      size="medium"
      isOpen={open}
      onClose={onClose}
      content={
        isMounted() && (
          <div style={{ marginTop: "var(--spacing-l)" }}>
            <SearchableReportView
              reportId={reportId}
              columnDefs={columnDefs}
              rowData={rowData}
              onRowDoubleClicked={onRowDoubleClicked}
              getRowId={getRowId}
              height={isMobileDevice ? "50vh" : "332px"}
              width={isMobileDevice ? "80vw" : "100%"}
              isMobile={isMobileDevice}
              breakWidth={650}
              noBackground
              rounded
            />
          </div>
        )
      }
    />
  );
};

export default PositionFinderModal;
