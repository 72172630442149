import { OrderFormValues, useOEMSOrderForm } from "@enfusion-ui/core";
import { Button, ErrorAlert, Modal } from "@enfusion-ui/web-components";
import { useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import MarketDataDisplay from "../MarketDataDisplay";
import { CDXOrderSummaryModalCore } from "./CDXOrderSummary";
import { FXOrderSummaryModalCore } from "./FXOrderSummary";
import IRSOrderSummaryModalCore from "./IRSOrderSummary";
import OrderSummaryPanel, { OrderSummaryPanelProps } from "./OrderSummaryPanel";
import {
  ActionContainer,
  InLineGridWrapper,
  InLineGridWrapperMobile,
  MobileWrapper,
  OrderSummaryModalTitle,
  TitleContainer,
  WebWrapper,
} from "./styles";
import { VarSwapOrderSummaryModalCore } from "./VarSwapOrderSummary";

const EquityOrderSummaryModalCore: React.FC<unknown> = () => {
  const isMobileDevice = useIsContentMobile();
  const { watch, setValue } = useFormContext<OrderFormValues>();
  const {
    instrument,
    orderSide,
    quantity,
    localNotional,
    notional,
    limitPrice,
    stopPrice,
  } = watch([
    "instrument",
    "orderSide",
    "quantity",
    "notional",
    "localNotional",
    "limitPrice",
    "stopPrice",
  ]);

  React.useEffect(() => {
    setValue("instrument", instrument);
  }, [instrument.id]);

  const values = {
    quantity,
    orderSide,
    localNotional,
    nativeNotional: notional,
    stopPrice,
    limitPrice,
    financialSubType: instrument?.financialSubType,
    instrument,
  } as OrderSummaryPanelProps;

  const title = (
    <OrderSummaryModalTitle label="Instrument">
      {instrument?.description} - {instrument?.id}
    </OrderSummaryModalTitle>
  );

  if (isMobileDevice) {
    return (
      <>
        {title}
        <MobileWrapper>
          <InLineGridWrapperMobile padding="2rem 1rem 0rem 0.5rem">
            <MarketDataDisplay view="summary" />
          </InLineGridWrapperMobile>

          <InLineGridWrapperMobile padding="0rem 1rem 0rem 0.5rem">
            <OrderSummaryPanel {...values} viewMode="grid" />
          </InLineGridWrapperMobile>
        </MobileWrapper>
      </>
    );
  }
  return (
    <>
      {title}
      <WebWrapper>
        <InLineGridWrapper margin="0 0 0 6rem">
          <MarketDataDisplay view="summary" />
        </InLineGridWrapper>

        <InLineGridWrapper margin="0 4rem 0 0">
          <OrderSummaryPanel {...values} viewMode="grid" />
        </InLineGridWrapper>
      </WebWrapper>
    </>
  );
};

const OrderSummary: React.FC<unknown> = () => {
  const { formType } = useOEMSOrderForm();

  switch (formType) {
    case "cdx":
      return <CDXOrderSummaryModalCore />;
    case "varSwap":
      return <VarSwapOrderSummaryModalCore />;
    case "fx":
      return <FXOrderSummaryModalCore />;
    case "irs":
      return <IRSOrderSummaryModalCore />;
    default:
      return <EquityOrderSummaryModalCore />;
  }
};

export type OrderSummaryModalProps = {
  open: boolean;
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
  showValidationWarning: string | null;
};

export const OrderSummaryModal: React.FC<OrderSummaryModalProps> = ({
  open,
  onCancel,
  onSubmit,
  showValidationWarning,
}) => {
  return (
    <Modal
      isOpen={open}
      onClose={onCancel}
      title={<TitleContainer>Order Summary</TitleContainer>}
      content={
        <div style={{ display: "flex", flexDirection: "column" }}>
          {showValidationWarning ? (
            <ErrorAlert error={showValidationWarning} />
          ) : null}
          <OrderSummary />
          <ActionContainer>
            <Button
              theme="primary"
              onClick={onSubmit}
              data-e2e-id="summary-btn-proceed"
            >
              Proceed
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </ActionContainer>
        </div>
      }
    />
  );
};
