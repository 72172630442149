import { useRefCallback } from "@enfusion-ui/hooks";
import { Button } from "@enfusion-ui/web-components";
import { styled, ThemeProvider } from "@enfusion-ui/web-core";
import { useTour } from "@reactour/tour";
import * as React from "react";

import { TourStepMeta } from "./config";
import { useAppTutorial } from "./context";

// #region styles
const StyledTourContent = styled.div`
  color: #000000;
  font-size: 0.9rem;
`;

const ContentHeader = styled.h1`
  font-weight: bold;
  padding-bottom: 8px;
  line-height: 1;
  margin: 0;
`;
//# endregion
const ContentBody = styled.div`
  padding-bottom: 8px;

  > p {
    padding-bottom: 8px;
  }
`;
// #endregion

// #region types
export enum TutorialType {
  ServicesMarketing = "ServicesMarketing",
  ServicesClient = "ServicesClient",
  Reports = "Reports",
  Portfolio = "Portfolio",
}

export type TourConfig = {
  stepsRequiringTargetClick?: number[];
  steps: any[];
  welcomeModalContent?: { title: string; body: string };
  user?: string | null;
};
export type StepSelector = { step: number; selector: string };

// #endregion

// #region utils
export const triggerRightClick = (element: HTMLElement) => {
  const events: string[] = ["mousedown", "mouseup", "contextmenu"];
  events.forEach((event: string) => {
    element.dispatchEvent(
      new MouseEvent(event, {
        bubbles: true,
        cancelable: false,
        view: window,
        button: 2,
        buttons: event === "mousedown" ? 2 : 0,
        clientX: element.getBoundingClientRect().x,
        clientY: element.getBoundingClientRect().y,
      })
    );
  });
};

const delay = (t: number) => {
  return () => new Promise((resolve) => setTimeout(resolve, t));
};

export const triggerClick = (selector: string) => {
  return new Promise<void>((resolve, reject) => {
    const el = document.querySelector(`${selector}`) as
      | HTMLElement
      | HTMLInputElement
      | HTMLButtonElement;
    if (el) {
      el.click();
      resolve();
    } else {
      reject();
    }
  });
};

export const actionCallback = (
  clickSelector: string,
  promiseDelay = 0,
  delayAmount = 0
) => {
  if (window.document) {
    const domLoadedState = document.readyState;
    const actions = new Promise((resolve) => {
      setTimeout(() => {
        resolve("actions");
      }, promiseDelay);
    });
    if (domLoadedState === "complete") {
      actions
        .then(() => {
          return triggerClick(clickSelector);
        })
        .then(delay(delayAmount))
        .catch((e) => console.warn("promise error", e));
    }
  }
};

export const requiredClickSelectors: any = (config: TourConfig) =>
  config?.stepsRequiringTargetClick
    ? config.stepsRequiringTargetClick
        .map((step: number) => {
          const { selector } = config.steps[step];

          return {
            step: step,
            selector: selector !== undefined ? selector : "",
          };
        })
        .filter((stepSelector: StepSelector) => stepSelector.selector !== "")
    : [];

export const triggerMouseEvent = (node: HTMLElement, eventType: string) => {
  var clickEvent = document.createEvent("MouseEvents");
  clickEvent.initEvent(eventType, true, true);
  node.dispatchEvent(clickEvent);
};
// #endregion

// #region components
export const CustomBadge = () => {
  const { steps, currentStep } = useTour();
  return <>{`${currentStep + 1}/${steps.length}`}</>;
};

const CustomNavigation = () => {
  const { tutorialType, meta, step } = useAppTutorial();
  const { setSteps, setIsOpen, onClickClose, setCurrentStep, currentStep } =
    useTour();

  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  const listener = useRefCallback(
    (e: MouseEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (e.target === buttonRef.current) {
        if (!meta.disableButton) {
          if (!step?.disableActions) {
            if (step?.complete) {
              onClickClose?.({
                setIsOpen,
                setSteps,
                setCurrentStep,
                currentStep,
              });
              setIsOpen(false);
            } else {
              setCurrentStep(currentStep + 1);
            }
          }
        }
      }
    },
    [currentStep, step, setCurrentStep]
  );

  React.useEffect(() => {
    window.addEventListener("click", listener);
    return () => window.removeEventListener("click", listener);
  }, []);

  if (
    tutorialType === TutorialType.Portfolio &&
    step?.name === "portfolio-loading"
  )
    return null;

  return (
    <Button ref={buttonRef} theme="primary" disabled={meta.disableButton}>
      {step?.complete ? "Complete" : "Next"}
    </Button>
  );
};

export const TourContent: React.FC<
  React.PropsWithChildren<{
    header: string;
    content?: string;
  }>
> = ({ header, children }) => {
  return (
    <ThemeProvider defaultTheme="light" scoped>
      <StyledTourContent>
        <ContentHeader>{header}</ContentHeader>
        <ContentBody>{children}</ContentBody>
        <CustomNavigation />
      </StyledTourContent>
    </ThemeProvider>
  );
};
// #endregion
