import { UNKNOWN_INSTRUMENT_ID } from "@enfusion-ui/core";
import {
  ExecutionOrderTimeInForce,
  ExecutionOrderType,
  Quote,
  SelectOptionsType,
} from "@enfusion-ui/types";
import {
  Checkbox,
  ControlledSelect,
  ControlledTextInput,
  ErrorAlert,
  FormController,
  NumericInput,
  PanelRow,
} from "@enfusion-ui/web-components";
import { styled, useAuth } from "@enfusion-ui/web-core";
import * as React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { useMarketData } from "../../../context/marketData/context";
import { DarkPoolSelect } from "../OrderTicket/components/inputs/DarkPoolSelect";
import { BorrowBrokerPanel } from "../OrderTicket/panels/BorrowBroker/BorrowBrokerPanel";
import PriceAdjustmentButtons from "./PriceAdjustmentButtons";

const DEFAULT_TICK_SIZE = 10;

export const timeInForceOptions: SelectOptionsType<ExecutionOrderTimeInForce>[] =
  [
    { value: "Day", label: "Day" },
    { value: "GTC", label: "GTC" },
    { value: "IOC", label: "Immediate Or Cancel" },
    { value: "FOC", label: "Fill Or Kill" },
    { value: "ATO", label: "At the Open" },
    { value: "ATC", label: "At the Close" },
  ];

const orderTypeOptions: SelectOptionsType<ExecutionOrderType>[] = [
  { value: "Market", label: "Market" },
  { value: "Limit", label: "Limit" },
  { value: "MarketOnClose", label: "Market On Close" },
];

const defaultValues = {
  orderType: null,
  timeInForce: null,
  limitPrice: 0,
  swap: false,
  darkPools: null,
  counterpartyMemo: "",
};

const ExecutionInfoContainer = styled.div`
  display: grid;
  row-gap: var(--spacing);
`;

const ErrorContainer = styled.div`
  padding-bottom: var(--spacing);
`;

export type ExecutionInfoPanelProps = { compressed?: boolean };
const ExecutionInfoPanel: React.FC<ExecutionInfoPanelProps> = ({
  compressed = false,
}) => {
  const { isUserType } = useAuth();
  const { setValue, watch } = useFormContext();
  const [priceAdjError, setPriceAdjError] = React.useState<string | null>(null);
  const { instrument, orderType, limitPrice } = watch([
    "instrument",
    "orderType",
    "limitPrice",
  ]);

  const [marketData, setMarketData] = React.useState<Quote | null>();
  const { subscribeToMarketData } = useMarketData();

  React.useEffect(() => {
    if (instrument) {
      if (instrument && instrument.id !== UNKNOWN_INSTRUMENT_ID) {
        return subscribeToMarketData(instrument.id, (v) => setMarketData(v));
      }

      setMarketData(null);
    }
  }, [instrument]);

  const instrumentFinancialType = instrument?.financialSubType?.parent ?? null;
  const instrumentTickSize = instrument?.tickSize ?? DEFAULT_TICK_SIZE;
  const askPrice = marketData?.ask ?? null;
  const bidPrice = marketData?.bid ?? null;

  useEffect(() => {
    if (orderType !== "Limit") {
      setValue("limitPrice", defaultValues.limitPrice);
    }
  }, [orderType]);

  useEffect(() => {
    setPriceAdjError(null);
  }, [limitPrice]);

  const applyPriceAdjustment = (price: number) => {
    if (price <= 0 && instrumentFinancialType !== "Future") {
      setPriceAdjError("Limit price cannot be 0.");
    } else {
      setValue("limitPrice", price);
    }
  };

  const setOrderType = (newOrderType: ExecutionOrderType) =>
    setValue("orderType", newOrderType);

  return (
    <ExecutionInfoContainer>
      {priceAdjError && (
        <ErrorContainer>
          <ErrorAlert error={priceAdjError} />
        </ErrorContainer>
      )}
      <PanelRow>
        <ControlledSelect
          name="timeInForce"
          label="TIF"
          options={timeInForceOptions}
          basis="33%"
          inputId="electronic-info-tif-selection-id"
        />
        <ControlledSelect
          name="orderType"
          label="Type"
          options={orderTypeOptions}
          basis="33%"
          inputId="electronic-info-order-type-selection-id"
        />
        <FormController
          name="limitPrice"
          basis="33%"
          render={(props) => (
            <NumericInput
              {...props}
              label="Limit"
              disabled={orderType !== "Limit"}
              minWidth="110px"
            />
          )}
        />
      </PanelRow>
      <PanelRow grow>
        <PriceAdjustmentButtons
          askPrice={askPrice}
          bidPrice={bidPrice}
          instrumentTickSize={instrumentTickSize}
          limitPrice={limitPrice}
          disabled={orderType !== "Limit"}
          applyPriceAdjustment={applyPriceAdjustment}
          setOrderType={setOrderType}
        />
      </PanelRow>
      {!compressed && (
        <PanelRow>
          <FormController
            name="swapOrder"
            render={({ value, ref: _ref, ...rest }) => (
              <div style={{ width: "calc(50% - var(--spacing))", height: 38 }}>
                <Checkbox checked={value} label="Swap?" {...rest} />
              </div>
            )}
          />
          {!isUserType("Express") && <DarkPoolSelect />}
          <BorrowBrokerPanel />
        </PanelRow>
      )}
      <ControlledTextInput name="counterpartyMemo" label="Memo" textarea />
    </ExecutionInfoContainer>
  );
};

export default ExecutionInfoPanel;
