import { TutorialType } from "@app-components/Tour/utils";
import { useExplorerContextMenu } from "@app-context/explorer/explorerContextMenu/useExplorerContextMenu";
import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import { useReportsExplorer } from "@app-context/reports/ReportsExplorerProvider";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import {
  AppEvent,
  AppEventCategories,
  DashboardRoot,
  FileTreeEntry,
  NodeData,
} from "@enfusion-ui/types";
import {
  encodeFilePath,
  getFileExt,
  getFileIcon,
  getFileName,
  reportFileExtensions,
} from "@enfusion-ui/utils";
import {
  AccordionContext,
  CenterContent,
  ErrorAlert,
  FileExplorer,
  MenuItemOption,
  MenuOptions,
  Spinner,
  TextInput,
  useNavBarState,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  errorToast,
  REST_API,
  styled,
  useAuth,
  useReports,
} from "@enfusion-ui/web-core";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { debounce } from "lodash";
import * as React from "react";

const ReportFileExplorer = styled(FileExplorer)`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const SearchPanel = styled.div`
  padding: 0.5rem;
  background-color: var(--background-accent);
  margin-bottom: var(--spacing);
`;

const getNodes = REST_API.REPORTS.GET_ROOT_NODE_FACTORY();

const ReportsExplorer: React.FC<{
  root: DashboardRoot;
  open: boolean;
  tutorial?: TutorialType | null;
}> = React.memo(function ReportsExplorer({ root, open, tutorial }) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [nodes, setNodes] = React.useState<null | Array<NodeData>>(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<null | Error>(null);

  const { openReportTab } = useReports();
  const { setMenu } = React.useContext(AccordionContext);
  const { closeNavBarTabOnMobile } = useNavBarState();
  const { setSearch, setForceSearchIcon } = React.useContext(AccordionContext);
  const { hasPerm, isAdmin, isInternalPo } = useAuth();

  const searchTermRef = React.useRef(searchTerm);

  const inMounted = useMounted();

  const { openGenerateReportModal, openCreateFolderModal, deleteReport } =
    useReportsExplorer();

  const globalAdminEnabled = hasPerm("DashboardEditor");
  const adminEnabled = globalAdminEnabled || isAdmin() || isInternalPo();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value;

    if (newSearchTerm.trim().length === 1)
      AppLogging.event({
        event: AppEvent.SearchFiles,
        category: AppEventCategories.Reports,
      });

    if (newSearchTerm.trim().length > 0) {
      setSearchTerm(newSearchTerm);
      setForceSearchIcon(true);
    } else {
      setSearchTerm("");
      setForceSearchIcon(false);
    }
  };

  const handleClearSearchTerm = () => {
    setSearchTerm("");
    setForceSearchIcon(false);
  };

  React.useEffect(() => {
    setSearch(
      <SearchPanel>
        <TextInput
          label="Search"
          name="name"
          value={searchTerm}
          onChange={handleSearchChange}
          clearable
          onClearValue={handleClearSearchTerm}
        />
      </SearchPanel>
    );
  }, [searchTerm]);

  const handleOpenTab = useRefCallback(
    (node: NodeData, force = false) => {
      const data = node as unknown as FileTreeEntry;
      if (data.file) {
        const ext = getFileExt(data.path);
        if (reportFileExtensions.includes(ext)) {
          openReportTab({ name: data.name, path: data.path, root }, force);
        } else {
          errorToast(`Reports of extension "${ext}" not yet supported.`);
        }
        closeNavBarTabOnMobile();
      }
    },
    [closeNavBarTabOnMobile]
  );

  const handleClick = useRefCallback(
    (node: NodeData) => handleOpenTab(node),
    [handleOpenTab]
  );

  const forceTabOpen = useRefCallback(
    (node: NodeData) => handleOpenTab(node, true),
    [handleOpenTab]
  );

  const loadReportList = React.useCallback(async () => {
    try {
      const rootNodes = await REST_API.REPORTS.GET_ROOT_NODE(
        encodeFilePath(root)
      );
      if (inMounted()) setNodes(rootNodes);
    } catch (err) {
      if (inMounted()) setError(err as Error);
    } finally {
      if (inMounted()) setLoading(false);
    }
  }, []);

  const loadReportSearchResultList = debounce(async () => {
    const reportFileTreeData = await REST_API.REPORTS.GET_SEARCH_ROOT_NODE(
      root,
      searchTerm
    );
    if (inMounted()) {
      setNodes(reportFileTreeData ? reportFileTreeData : []);
      setLoading(false);
    }
  }, 400);

  React.useEffect(() => {
    if (open && !loading && !error) {
      if (searchTermRef.current !== searchTerm) {
        loadReportSearchResultList.cancel();
        setLoading(true);

        if (searchTerm.length === 0) {
          loadReportList();
        } else {
          loadReportSearchResultList();
        }

        searchTermRef.current = searchTerm;
      } else if (nodes === null) {
        setLoading(true);
        loadReportList();
      }
    }
  }, [nodes, root, open, searchTerm, loading, error, inMounted]);

  React.useEffect(() => {
    let menuOptions: MenuOptions = [];
    if (process.env.REACT_APP_ENABLE_PMS_PHASE_ONE && adminEnabled) {
      menuOptions = [
        ...menuOptions,
        {
          key: "generateReport",
          title: "Generate Report",
          icon: faPlus,
          onClick: () => openGenerateReportModal(root),
        } as MenuItemOption,
        {
          key: "create-folder",
          title: "Create Folder",
          icon: faPlus,
          onClick: () => openCreateFolderModal(root, ""),
        } as MenuItemOption,
      ];
    }

    setMenu({ menu: menuOptions, selectedKeys: [] });
  }, []);

  const reloadEvent = useRefCallback(
    (eventRoot: DashboardRoot, event: ExplorerEventsAction) => {
      if (eventRoot === root && event === ExplorerEventsAction.Refetch) {
        setLoading(true);
        if (searchTerm.length === 0) {
          loadReportList();
        } else {
          loadReportSearchResultList();
        }
      }
    },
    [root, loadReportList, loadReportSearchResultList]
  );
  useExplorerEvents("Reports", reloadEvent);

  const ExplorerContextMenu = useExplorerContextMenu({
    root,
    section: "Reports",
    globalAdminEnabled,
    editEnabled: true,
    shareEnabled: true,
    openCreate:
      process.env.REACT_APP_ENABLE_PMS_PHASE_ONE && adminEnabled
        ? openGenerateReportModal
        : undefined,
    openCreateFolder:
      process.env.REACT_APP_ENABLE_PMS_PHASE_ONE && adminEnabled
        ? openCreateFolderModal
        : undefined,
    deleteFile: deleteReport,
    forceOpenTab: forceTabOpen,
  });

  if (error) {
    return (
      <CenterContent fillHeight={false}>
        <ErrorAlert error={error} />
      </CenterContent>
    );
  }

  if (loading || nodes === null) {
    return (
      <CenterContent fillHeight={false}>
        <Spinner />
      </CenterContent>
    );
  }

  return (
    <>
      <ReportFileExplorer
        nodes={nodes}
        getFileIcon={getFileIcon}
        getFileName={getFileName}
        onEntryClick={handleClick}
        retrieveNodes={getNodes}
        multiSelect={false}
        tutorial={tutorial}
        defaultFoldersOpen={searchTerm?.trim().length > 0}
        onEntryContext={ExplorerContextMenu.handleEntryContext}
      />
      {ExplorerContextMenu.content}
    </>
  );
});

export default ReportsExplorer;
