import { replaceDoubleSlashWithSingleSlash } from "@enfusion-ui/utils";
import { createBroadcastMessage } from "./broadcastMessages";
export const AUTH_EVENT = {
    FORBIDDEN: "auth-fb",
    LOGOUT: "auth-lo",
    CONFLICT: "auth-cf",
};
export const authEventMessage = (event, meta) => createBroadcastMessage(event, meta);
export function createRestServer(AppLogging, broadcastMessage, fetchCall, urlPrefix = "", getStaticHeaders = () => Promise.resolve({})) {
    async function restServerBase(endpoint, params = { credentials: "include" }) {
        const headers = {
            ...params?.headers,
            ...(await getStaticHeaders(params)),
        };
        let body = params?.body;
        if (!(params?.body instanceof FormData)) {
            headers["Content-Type"] = "text/plain";
            if (typeof params?.body === "object") {
                headers["Content-Type"] = "application/json";
                body = JSON.stringify(body);
            }
        }
        const config = {
            method: body ? "POST" : "GET",
            ...params,
            body,
            headers,
        };
        config.method = config.method.toLocaleUpperCase();
        let cleanEndpoint = endpoint.startsWith(urlPrefix)
            ? endpoint.replace(urlPrefix, "")
            : endpoint;
        cleanEndpoint = replaceDoubleSlashWithSingleSlash(`${cleanEndpoint.startsWith("/") ? "" : "/"}${cleanEndpoint}`);
        const REQ_URL = `${urlPrefix}${cleanEndpoint}`;
        AppLogging.info(`[REST] ${config.method} ${cleanEndpoint}`);
        const response = await fetchCall(REQ_URL, config);
        const logMsg = (content) => AppLogging.info(`[REST]: ${content} (${response.status}): ${cleanEndpoint}`);
        if (response.status === 418) {
            logMsg("auth conflict");
            if (params?.ignoreAuth !== true)
                broadcastMessage(authEventMessage(AUTH_EVENT.CONFLICT));
            throw new Error("Conflict");
        }
        if (response.status === 503) {
            logMsg("server issue");
            throw new Error("Service Not Available");
        }
        if (response.status === 403) {
            logMsg("auth issue");
            broadcastMessage(authEventMessage(AUTH_EVENT.FORBIDDEN, [cleanEndpoint]));
        }
        if (response.status === 401) {
            logMsg("auth error");
            if (params?.ignoreAuth !== true)
                broadcastMessage(authEventMessage(AUTH_EVENT.LOGOUT, ["Unauthorized"]));
            throw new Error("Unauthorized");
        }
        if (response.headers.get("Content-Type")?.startsWith("application/json")) {
            const json = (await response.json());
            return response.ok ? [json, response] : Promise.reject(json);
        }
        return [(await response.text()), response];
    }
    async function restServer(endpoint, params) {
        const res = await restServerBase(endpoint, params);
        return res[0];
    }
    return { restServer, restServerBase };
}
