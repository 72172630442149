import { TextInputWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { TextInput, WidthHeight100 } from "@enfusion-ui/web-components";
import * as React from "react";
import { useDebounce } from "react-use";

const TextInputWidget: React.FC<unknown> = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, textarea, key } = config as TextInputWidgetConfig;
  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Text Input value",
        type: ChannelDataType.String,
      },
    ]);
  }, [key]);

  useDebounce(() => setChannelData([value]), 200, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <WidthHeight100>
      <TextInput
        textarea={textarea}
        value={value}
        onChange={handleChange}
        label={key}
      />
    </WidthHeight100>
  );
};

export default TextInputWidget;
