import { useWidget } from "@enfusion-ui/dashboards";
import { SelectOptionsType } from "@enfusion-ui/types";
import { Select } from "@enfusion-ui/web-components";
import * as React from "react";

export const GenericInputSelect: React.FC<{
  value: string | null;
  onChange: (option: SelectOptionsType<string> | null) => void;
  label?: string;
  showLabel?: boolean;
}> = ({
  value,
  onChange,
  label = "Generic Input Select",
  showLabel = true,
}) => {
  const { editedConfig } = useWidget();
  return (
    <Select
      label={showLabel ? label : ""}
      value={
        editedConfig?.genericInputOptions?.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ value: val }: any) => value === val
        ) ?? null
      }
      options={editedConfig?.genericInputOptions}
      onChange={onChange}
      inputId="generic-input-selection-id"
    />
  );
};
