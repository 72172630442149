/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAsyncCall, useRefCallback } from "@enfusion-ui/hooks";
import { ExecutionFillInfo } from "@enfusion-ui/types";
import { errorToast, REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

function useAPICall<R = any, P = Record<string, any>>(
  apiParams: P | undefined,
  apiMethod: (params: P) => Promise<R>,
  onError?: (err: any) => void
) {
  const callApi = useRefCallback(() => {
    if (apiParams) return apiMethod(apiParams);
    return Promise.resolve(null);
  }, [apiParams]);

  const handleError = useRefCallback(
    (error: any) => {
      console.error("Api call failed", error);
      onError?.(error);
    },
    [onError]
  );

  const { result, loading, errored, call } = useAsyncCall(
    callApi,
    handleError,
    true
  );

  React.useEffect(() => {
    call();
  }, [call, JSON.stringify(apiParams)]);

  return { result, loading, errored, refetch: call };
}

const useFillsList = (routeOrderId: number | undefined) => {
  const { result, loading, errored, refetch } = useAPICall<ExecutionFillInfo[]>(
    typeof routeOrderId === "number" ? { routeOrderId } : undefined,
    async ({ routeOrderId }) => {
      const orderFills = await REST_API.OEMS.GET_FILLS.FETCH(routeOrderId);
      return orderFills?.fills ?? ([] as ExecutionFillInfo[]);
    },
    () => {
      errorToast("Failed to get route fills");
    }
  );

  return { fills: result ?? [], loading, errored, refetch };
};

export default useFillsList;
