import { WidgetComponentsDefinition } from "../../../types";
import FundBenchmarkTypeSelectionEditWidget from "./FundBenchmarkTypeSelectionEditWidget";
import FundBenchmarkTypeSelectionWidget from "./FundBenchmarkTypeSelectionWidget";
import PdfFundBenchmarkTypeSelectionWidget from "./PdfFundBenchmarkTypeSelectionWidget";

export const fundBenchmarkTypeSelection: WidgetComponentsDefinition = {
  renderComponent: FundBenchmarkTypeSelectionWidget,
  editComponent: FundBenchmarkTypeSelectionEditWidget,
  renderPdfComponent: PdfFundBenchmarkTypeSelectionWidget,
};
