import {
  BreakDetailsCellRendererProps,
  GraphBaseField,
  ReconciliationMetaData,
} from "@app-views/reconciliation/types";
import { useCtx } from "@enfusion-ui/hooks";
import {
  ReconciliationConfig,
  ReconciliationDatasourceDef,
  ReconciliationFilterState,
  StoreReconciliationArgs,
} from "@enfusion-ui/types";
import * as React from "react";

export type ReconciliationContextType = {
  config: ReconciliationConfig;
  filterState: ReconciliationFilterState;
  loading: boolean;
  updateConfig: (configData: ReconciliationConfig) => void;
  changeFilterState: (filterState: ReconciliationFilterState) => void;
  loadAddedDatasources: (
    addedDatasources: ReconciliationDatasourceDef[],
    removedDatasources: ReconciliationDatasourceDef[]
  ) => void;
  storeReconciliationDashboard: (params: StoreReconciliationArgs) => Promise<{
    success: boolean;
    filePath?: string | undefined;
  }>;
  reloadReconciliationDashboard: () => void;
  metadata: ReconciliationMetaData | undefined;
  setMetadata: React.Dispatch<
    React.SetStateAction<ReconciliationMetaData | undefined>
  >;

  reportsLoadingStatus: Record<string, boolean> | undefined;
  setReportsLoadingStatus: React.Dispatch<
    React.SetStateAction<Record<string, boolean> | undefined>
  >;
};

export type ReconciliationBreakDetailsContextType = {
  breakDetailsRendererData: BreakDetailsCellRendererProps;
  graphFilterState: Record<string, Record<GraphBaseField, string>>;
  setGraphFilterState: React.Dispatch<
    React.SetStateAction<Record<string, Record<GraphBaseField, string>>>
  >;
};

export const ReconciliationContext = React.createContext<
  ReconciliationContextType | undefined
>(undefined);

export const ReconciliationBreakDetailsContext = React.createContext<
  ReconciliationBreakDetailsContextType | undefined
>(undefined);

export function useReconciliation() {
  return useCtx(
    ReconciliationContext,
    "useReconciliation",
    "ReconciliationProvider"
  );
}

export function useReconciliationBreakDetails() {
  return useCtx(
    ReconciliationBreakDetailsContext,
    "useReconciliationBreakDetails",
    "ReconciliationBreakDetailsProvider"
  );
}
