import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { BaseTabList } from "./BaseTabs";
const TabContainer = styled.div `
  font-size: 14px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  min-width: min-content;
  height: 35px;
  position: relative;
  cursor: pointer;
  color: ${({ active }) => (active ? "var(--primary)" : "inherit")};

  :hover {
    color: var(--${({ active }) => (active ? "primary-hover" : "text-hover")});
  }
`;
const TabTitleContainer = styled.div `
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 14px;
  white-space: nowrap;
  padding-right: var(--spacing);
  min-width: 0px;
  overflow: hidden;
`;
const Count = styled.div `
  height: 14px;
  font-size: 9px;
  padding: 1px 4px 1px 4px;
  margin-left: 8px;
  border-radius: 2px;
  color: var(--text-normal);
  background-color: var(
    --${({ active }) => (active ? "primary" : "background-accent")}
  );

  justify-content: center;
  align-items: center;
  display: flex;
  line-height: 10px;
`;
const Tab = React.forwardRef(function Tab({ tab, onClick, active }, ref) {
    return (React.createElement(TabContainer, { onClick: onClick, active: active, title: tab.label, ref: ref, "data-e2e-id": tab.key },
        React.createElement(TabTitleContainer, null,
            React.createElement("div", null, tab.label),
            tab.count && React.createElement(Count, { active: active }, tab.count))));
});
export const SecondaryTabList = (props) => {
    return (React.createElement(BaseTabList, { ...props, height: 33, TabComponent: Tab, borderBottom: true, underlineSelectedTab: true }));
};
