import { WidgetComponentsDefinition } from "../../types";
import ListViewEditWidget from "./ListViewEditWidget";
import ListViewWidget from "./ListViewWidget";
import PdfListViewWidget from "./PdfListViewWidget";

export const listView: WidgetComponentsDefinition = {
  pdfContentAsync: true,
  renderComponent: ListViewWidget,
  editComponent: ListViewEditWidget,
  renderPdfComponent: PdfListViewWidget,
};
