import { faCheck, faExclamationCircle, faExclamationTriangle, } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { omit } from "lodash";
import * as React from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { styled } from "./styledComponents";
const Line2Text = styled.span `
  opacity: 0.7;
  font-size: 14px;
`;
const defaultToastOption = (content, type, { toastId, icon: iconOverride, } = {}) => {
    const iconDef = getIcon(type);
    const icon = iconOverride ? (iconOverride) : iconDef !== null ? (React.createElement(FontAwesomeIcon, { icon: iconDef, style: { marginRight: "var(--spacing-l)" } })) : null;
    return {
        icon,
        closeOnClick: true,
        draggablePercent: 60,
        toastId: toastId
            ? toastId
            : typeof content === "string"
                ? content
                : uuidv4(),
    };
};
const getIcon = (type) => {
    switch (type) {
        case "warning":
            return faExclamationCircle;
        case "error":
            return faExclamationTriangle;
        case "success":
            return faCheck;
        default:
            return null;
    }
};
const generalToast = (args) => {
    const { type, line1, line2, options } = args;
    const defaultOptions = defaultToastOption(line1, type, options);
    const { onClick, dismissToastId, toastId, icon, ...restOptions } = {
        ...defaultOptions,
        ...omit(options || {}, ["toastId"]),
    };
    if (toastId) {
        const content = typeof line1 !== "string" ? (line1) : (React.createElement(React.Fragment, null,
            icon,
            React.createElement("span", null, line1),
            line2 ? (React.createElement(React.Fragment, null,
                React.createElement("br", null),
                React.createElement(Line2Text, null, line2))) : null));
        const body = (React.createElement("div", { "data-testid": `${type}-toast`, onClick: onClick }, content));
        const args = {
            ...restOptions,
            toastId,
            type,
            className: `${type}-toast`,
        };
        if (!toast.isActive(toastId)) {
            toast(body, args);
        }
        else {
            toast.update(toastId, {
                render: () => body,
                ...args,
            });
        }
    }
    console.info(`[TOAST] ${line1} (${line2})`);
    dismissToastId && toast.dismiss(dismissToastId);
    return toast;
};
const toastFactory = (type) => (content, line2orOption, options) => {
    const lineTwoType = typeof line2orOption;
    return generalToast({
        type,
        line1: content,
        line2: lineTwoType === "string"
            ? line2orOption
            : line2orOption?.line2,
        options: lineTwoType !== "undefined" && lineTwoType !== "string"
            ? line2orOption
            : options,
    });
};
export const warningToast = toastFactory("warning");
export const errorToast = toastFactory("error");
export const successToast = toastFactory("success");
export const infoToast = toastFactory("info");
export const defaultToast = toastFactory("default");
export function closeToast(id) {
    if (!!id)
        toast.dismiss(id);
}
