import { AssetMeasureWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { FormSectionAccordion, TextInput } from "@enfusion-ui/web-components";
import * as React from "react";

import { AssetMeasureSelect } from "../../../../../reports/components/ReportQueryComponents/AssetMeasureSelect";
import { EditContainer } from "../../../styles";

const AssetMeasureEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange = (key: string) => (values: string[] | null) => {
    changeConfigKeyToBeApplied(key, values ?? []);
  };

  const { defaultValue = null, key = "Asset Measures" } =
    editedConfig as AssetMeasureWidgetConfig;

  return (
    <EditContainer>
      <FormSectionAccordion title="Asset Measure Options">
        <TextInput
          label="Key"
          value={editedConfig?.key || key}
          name="key"
          onChange={handleTextChange("key")}
        />
        <AssetMeasureSelect
          value={editedConfig?.defaultValue || defaultValue}
          label="Default Asset Measures"
          onChange={handleBasicChange("defaultValue")}
        />
      </FormSectionAccordion>
    </EditContainer>
  );
};

export default AssetMeasureEditWidget;
