import { NodeData } from "@enfusion-ui/types";
import * as React from "react";

import { TourMeta, TourStepType } from "./config";
import { TutorialType } from "./utils";

type AppTutorialContextState = {
  tutorialType: TutorialType | null;
  setTourReady: VoidFunction;
  setFileNodes?: (nodes: NodeData[]) => void;
  fileNodes?: NodeData[];
  setMeta: (meta: Partial<TourMeta>) => void;
  meta: TourMeta;
  step: TourStepType | null;
};

export const TourContext = React.createContext<
  AppTutorialContextState | undefined
>(undefined);

export function useAppTutorial() {
  const context = React.useContext(TourContext);
  if (typeof context === "undefined") {
    throw new Error("useAppTutorial must be used within a AppTutorialProvider");
  }
  return context;
}
