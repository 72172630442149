import DataGrid, { RoundedDataGrid } from "@app-components/DataGrid";
import { InitialValues } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { isPositionReport, OrderActionsMenuItems } from "@enfusion-ui/reports";
import { AccountAllocationInfo, ExecutionOrderSide } from "@enfusion-ui/types";
import { copyToKeyboardAction } from "@enfusion-ui/web-components";
import {
  closeToast,
  errorToast,
  infoToast,
  styled,
  useAuth,
  useOEMS,
  useReports,
  useTabs,
  useTheme,
  useThisTab,
  valueTooltipGetter,
} from "@enfusion-ui/web-core";
import { icon } from "@fortawesome/fontawesome-svg-core";
import {
  AgGridEvent,
  ColDef,
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GridSizeChangedEvent,
  ICellRendererParams,
  IDetailCellRendererParams,
  MenuItemDef,
  RowClassParams,
  RowStyle,
} from "ag-grid-community";
import { noop, omit } from "lodash";
import * as React from "react";

import { reportShareLinkProps } from "../../../utils/shareLinks";
import {
  clearState,
  getState,
  loadColumnState,
  persistOnColumnEvent,
} from "../hooks/usePersistColumnState";
import useRowMetadata from "../hooks/useRowMetadata";
import HTMLLinkCellRenderer from "./HTMLLinkCellRenderer";

const GridWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`;

export type ColumnSettings = {
  columns?: ColDef[];
  subGridColumns?: Record<string, ColDef[]>;
};

type OrderAction = "new" | "add" | "unwind";

type OrderParams = {
  initialValues: {
    instrumentId?: string;
    orderSide?: ExecutionOrderSide;
    quantity?: string;
    accountAllocation?: AccountAllocationInfo;
    strategyBookId?: number | null;
  };
  dismissToast: () => void;
};

function getRowStyle({ node }: RowClassParams): RowStyle {
  if (node.rowPinned)
    return {
      fontWeight: "bold",
      backgroundColor: "var(--background-accent)",
    };
  return {};
}

function getRowNodeId(params: GetRowIdParams) {
  return params.data.__row_id;
}

const components = {
  htmlLinkCellRenderer: HTMLLinkCellRenderer,
};

const defaultColDef = {
  resizable: true,
  tooltipValueGetter: valueTooltipGetter,
};

export type ReportGridProps = {
  rounded?: boolean;
  getContextMenu?: (e: (string | MenuItemDef)[]) => (string | MenuItemDef)[];
  isWidget?: boolean;
  isBasicTable?: boolean;
  showGroupingRow?: boolean;
  reportId: string;
  name?: string;
  path?: string;
  keepColumnDefs?: boolean;
};

export const ReportGrid: React.FC<GridOptions & ReportGridProps> = ({
  rounded,
  isWidget,
  isBasicTable,
  showGroupingRow,
  onGridReady,
  reportId,
  name,
  path,
  columnDefs: baseColumnDefs,
  keepColumnDefs = true,
  getRowId,
  ...props
}) => {
  const { openNewOrderTab } = useOEMS();
  const { orderActionsEnabled, dataStore } = useReports();
  const { subscribeToRowMetadata } = useRowMetadata();
  const { openTab, onTabClose } = useTabs();
  const { selector } = useThisTab();
  const { theme } = useTheme();
  const { user } = useAuth();

  const gridApiRef = React.useRef<GridApi>();

  const exportAllowed = true;

  const Grid = React.useMemo(() => {
    return rounded ? RoundedDataGrid : DataGrid;
  }, [rounded]);

  const columnDefsRef = React.useRef(
    (baseColumnDefs || []).map((i) => omit(i, "name"))
  );
  columnDefsRef.current = React.useMemo(() => {
    return (baseColumnDefs || []).map((i) => omit(i, "name"));
  }, [baseColumnDefs]);

  const initColumns = useRefCallback(
    async (event: GridReadyEvent) => {
      const username = user?.username ?? "";
      const storedColSettings = await getState({
        reportId,
        username,
      });
      try {
        if (storedColSettings) {
          const updatedColDefs = await loadColumnState(
            {
              reportId,
              username,
            },
            columnDefsRef.current
          );

          if (updatedColDefs && updatedColDefs.length > 0) {
            event.api.updateGridOptions({ columnDefs: updatedColDefs });
          }
        } else if (columnDefsRef.current) {
          event.api.updateGridOptions({ columnDefs: columnDefsRef.current });
        }
      } catch (e) {
        if (columnDefsRef.current)
          event.api.updateGridOptions({ columnDefs: columnDefsRef.current });
      }
    },
    [reportId, user?.username]
  );

  const handleOnGridReadyEvent = useRefCallback(
    (event: GridReadyEvent) => {
      gridApiRef.current = event.api;
      initColumns(event);
      onGridReady?.(event);
    },
    [onGridReady]
  );

  const handleGridSizeChanged = useRefCallback(
    ({ clientHeight, clientWidth, api }: GridSizeChangedEvent) => {
      requestAnimationFrame(() => {
        if (clientHeight > 0 && clientWidth > 0) {
          const allColumnIds = (api.getColumns() || []).reduce((res, col) => {
            if (typeof col.getColDef().initialWidth !== "number")
              res.push(col.getColId());
            return res;
          }, [] as string[]);
          api.autoSizeColumns(allColumnIds);
        }
      });
    },
    []
  );

  const openOrder = useRefCallback(
    async (action: OrderAction, tableId: string, rowId: number) => {
      infoToast("Loading order...", {
        autoClose: false,
        toastId: "position-order-loading",
      });
      const unsubscribe = subscribeToRowMetadata(
        { reportId, tableId, rowId },
        (res) => {
          if (res === null) {
            try {
              unsubscribe();
            } catch (err) {
              // noop
            } finally {
              errorToast("Failed to get position data.", {
                dismissToastId: "position-order-loading",
              });
            }
            return;
          }

          if (res.length > 1) {
            unsubscribe();
            errorToast("Multi-order actions not yet supported.", {
              dismissToastId: "position-order-loading",
            });
            return;
          }

          const [metadata] = res;

          const {
            instrumentId,
            accountId,
            bookId,
            fundId,
            portfolioTotalReturnSwapId,
            borrowAgreementId,
            dealId,
            positionBlock,
          } = metadata;
          let orderParams: OrderParams = {
            initialValues: {
              instrumentId,
              accountAllocation: {
                id: 0,
                allocationEntries: [
                  {
                    id: 0,
                    // eslint-disable-next-line camelcase
                    __row_key: "0",
                    accountId: Number.parseInt(accountId),
                    bookId: Number.parseInt(bookId),
                    fundId: Number.parseInt(fundId),
                    portfolioTotalReturnSwapId: Number.parseInt(
                      portfolioTotalReturnSwapId
                    ),
                    borrowAgreementId: Number.parseInt(borrowAgreementId),
                    dealId: dealId,
                    allocationQuantity: 1,
                    positionBlock: positionBlock,
                  },
                ],
              },
            },
            dismissToast: () => closeToast("position-order-loading"),
          };

          switch (action) {
            case "add": {
              const { addToPositionSide: orderSide, bookId: strategyBookId } =
                metadata;
              orderParams.initialValues.orderSide = orderSide;
              orderParams.initialValues.strategyBookId =
                Number.parseInt(strategyBookId);
              break;
            }
            case "unwind": {
              const {
                unwindSide: orderSide,
                unwindQuantity: quantity,
                bookId: strategyBookId,
              } = metadata;

              orderParams.initialValues.orderSide = orderSide;
              orderParams.initialValues.quantity = quantity;
              orderParams.initialValues.strategyBookId =
                Number.parseInt(strategyBookId);
              break;
            }
            case "new":
            default:
              break;
          }

          if (action === "unwind" && !metadata.unwindQuantity)
            errorToast("No active position found to unwind.");
          else
            openNewOrderTab(
              "equity",
              "prefer",
              orderParams.initialValues as InitialValues
            );
          closeToast("position-order-loading");
        }
      );
    },
    [openTab, subscribeToRowMetadata, reportId, openNewOrderTab]
  );

  const handleColumnStateChange = useRefCallback(
    ({ api, context }: AgGridEvent) => {
      if (keepColumnDefs) {
        persistOnColumnEvent(
          api.getAllGridColumns(),
          {
            reportId,
            username: user?.username ?? "",
          },
          context.depth === 0 ? context.tableId : `depth-${context.depth}`,
          context.isChildGrid
        );
      }
    },
    [persistOnColumnEvent, reportId, user?.username, keepColumnDefs]
  );

  const getContextMenuItems = useRefCallback(
    (params: GetContextMenuItemsParams) => {
      const { node, context } = params;
      if (!node) return exportAllowed ? ["export"] : [];

      let menuItems = [
        isBasicTable || isWidget || !path || !name
          ? null
          : {
              name: "Share",
              action: () => {
                copyToKeyboardAction(reportShareLinkProps(name, path));
              },
            },
        isBasicTable || isWidget || !path || !name ? null : "separator",
        "copy",
        "copyWithHeaders",
        exportAllowed ? "separator" : null,
        exportAllowed ? "export" : null,
      ].filter(Boolean) as unknown as (
        | string
        | { name: string; action: () => void }
      )[];

      if (!node) return menuItems;
      const { __row_idx: rowId } = node.data;
      if (
        !node.isRowPinned() &&
        isPositionReport(path) &&
        orderActionsEnabled
      ) {
        menuItems = [
          ...OrderActionsMenuItems.map((item) => ({
            action: () =>
              openOrder(item.key as OrderAction, context.tableId, rowId),
            name: item.text,
            icon: icon(item.icon).html?.[0],
          })),
          "separator",
          ...menuItems,
        ];
      }

      return props.getContextMenu?.(menuItems) ?? menuItems;
    },
    [
      orderActionsEnabled,
      isWidget,
      isBasicTable,
      exportAllowed,
      name,
      path,
      props.getContextMenu,
    ]
  );

  React.useEffect(() => {
    return onTabClose(async () => {
      await clearState({ reportId, username: user?.username ?? "" });
    });
  }, []);

  const args = React.useMemo(
    () =>
      ({
        suppressCellFocus: true,
        detailRowAutoHeight: true,
        suppressColumnMoveAnimation: true,
        multiSortKey: "ctrl",
        defaultColDef,
        getRowStyle,
        onGridSizeChanged: handleGridSizeChanged,
        getRowId: getRowId ? getRowId : getRowNodeId,
        enableCellChangeFlash: theme.cellFlashing,
        suppressDragLeaveHidesColumns: showGroupingRow,
        rowGroupPanelShow: showGroupingRow ? "always" : undefined,
        suppressScrollOnNewData: true,
        suppressRowGroupHidesColumns: true,
        getContextMenuItems,
        onColumnResized: handleColumnStateChange,
        onDragStopped: handleColumnStateChange,
        onColumnPinned: handleColumnStateChange,
        onColumnVisible: handleColumnStateChange,
        onColumnMoved: handleColumnStateChange,
        onColumnRowGroupChanged: handleColumnStateChange,
        onSortChanged: handleColumnStateChange,
      } as GridOptions),
    [theme, showGroupingRow, getContextMenuItems, handleColumnStateChange]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const detailCellRendererParams: any = useRefCallback(
    (params: ICellRendererParams) => {
      return {
        detailGridOptions: {
          ...props,
          ...args,
          components,
          detailCellRendererParams,
          context: {
            isChildGrid: true,
            tableId: params.data.__row_id,
            depth: params.context.depth + 1,
            path: [...params.context.path, params.data.__row_id],
          },
          popupParent: document.getElementById(selector) ?? undefined,
        } as GridOptions,
        getDetailRowData: noop,
      } as IDetailCellRendererParams;
    },
    [props, args, dataStore]
  );

  return (
    <GridWrapper>
      <Grid
        {...props}
        {...args}
        height="100%"
        context={{ tableId: reportId, path: [reportId], depth: 0 }}
        isWidget={isWidget}
        onGridReady={handleOnGridReadyEvent}
        detailCellRendererParams={detailCellRendererParams}
        ignoreColumns
      />
    </GridWrapper>
  );
};
