import { useAccounts } from "@app-context/accounts/context";
import {
  MultiSelectOptionsType,
  NodeData,
  SelectOptionsType,
} from "@enfusion-ui/types";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import { sortBy } from "lodash";
import * as React from "react";

export type FundSelectProps = Omit<
  Partial<MultiSelectProps<number>>,
  "options" | "value" | "onChange"
> & {
  value?: number[] | null;
  onChange?: (values: number[] | null) => void;
};

const getValues = (
  values: number[],
  options: MultiSelectOptionsType[] = []
): MultiSelectOptionsType[] => {
  const result: MultiSelectOptionsType[] = [];
  values.forEach((value) => {
    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption) {
      result.push(selectedOption);
    }
  });
  return result;
};

function fundDataToFundOptions(
  nodes: Array<NodeData>
): SelectOptionsType<number>[] {
  const fundOptions = nodes?.reduce(
    (res: SelectOptionsType<number>[], entry) => {
      const entries: SelectOptionsType<number>[] = [];
      entry.nodes?.forEach((node: NodeData) => {
        if (node.level === "Fund") {
          entries.push({ label: node.name, value: Number(node.fundId) });
        }
      });
      res = res.concat([...(entries ?? [])]);
      return res;
    },
    [] as SelectOptionsType<number>[]
  );

  return sortBy(fundOptions, (item) => item.label);
}

const FundSelect: React.FC<FundSelectProps> = ({
  value: values,
  onChange,
  placeholder = "All",
  label = "Funds",
  ...rest
}) => {
  const { nodes } = useAccounts();
  const [selectedFund, setSelectedFund] = React.useState<number[] | null>([]);

  const fundOptions = React.useMemo(
    () => fundDataToFundOptions(nodes?.[0].nodes ?? []),
    [nodes]
  );

  React.useEffect(() => {
    values && setSelectedFund(values);
  }, [values]);

  return (
    <MultiSelect
      {...rest}
      value={selectedFund ? getValues(selectedFund, fundOptions) : []}
      onChange={(selectedOptions) => {
        const newFunds = selectedOptions?.length
          ? selectedOptions.map(
              (opt: MultiSelectOptionsType) => opt?.value as number
            )
          : null;
        setSelectedFund(newFunds);
        onChange?.(newFunds);
      }}
      label={label}
      placeholder={placeholder}
      options={fundOptions}
    />
  );
};

const ControlledFundSelect: React.FC<
  Omit<FundSelectProps, "value" | "onChange"> & {
    name?: string;
  }
> = ({ name = "fundIds", ...props }) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...renderProps }) => (
        <FundSelect {...renderProps} {...props} />
      )}
    />
  );
};

export default ControlledFundSelect;
