import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import { ExplorerSection } from "@app-context/explorer/types";
import { errorCatch } from "@app-utils/errorCatch";
import { TOAST_CONTENT } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  AppEvent,
  AppEventCategories,
  NodeData,
  StorageRoot,
} from "@enfusion-ui/types";
import {
  AppLogging,
  FolderActionContext,
  REST_API,
  successToast,
  useOperationFiles,
  useServicesFiles,
} from "@enfusion-ui/web-core";
import * as React from "react";

const targetRoot = "shared";

export const ServicesSectionMap = {
  general: "GeneralFiles",
  services: "Services",
  operations: "Operations",
} as Record<StorageRoot, ExplorerSection>;

type ExplorerStorageFolderProviderProps = {
  root: StorageRoot;
  retrieveNodes: (node: NodeData | string) => Promise<NodeData[]>;
};

export const ExplorerStorageFolderProvider: React.FC<
  React.PropsWithChildren<ExplorerStorageFolderProviderProps>
> = ({ root, retrieveNodes, children }) => {
  const { section, eventCategory, toastContent } = React.useMemo(() => {
    const section = ServicesSectionMap[root];
    const eventCategory = AppEventCategories[section];
    const toastContent = TOAST_CONTENT[section];
    return { section, eventCategory, toastContent };
  }, [root]);
  const explorerChannel = useExplorerEvents(section);

  const refetch = useRefCallback(() => {
    explorerChannel.broadcast(targetRoot, ExplorerEventsAction.Refetch);
  }, [explorerChannel]);

  const createFolder = useRefCallback(
    async (folderName: string, folderPath: string) => {
      errorCatch(async () => {
        const path = folderPath + folderName;
        await REST_API.STORAGE.CREATE_FOLDER.FETCH(
          root,
          folderName,
          folderPath
        );

        successToast(TOAST_CONTENT.folder.create.success);
        refetch();
        AppLogging.event(
          { event: AppEvent.CreateFile, category: eventCategory },
          { path, file: false }
        );
      }, TOAST_CONTENT.folder.create.failure);
    },
    [refetch, eventCategory]
  );

  const moveFile = useRefCallback(
    async (node: NodeData, targetPath: string) => {
      errorCatch(async () => {
        await REST_API.STORAGE.MOVE_NODE.FETCH(root, node.path, targetPath);

        successToast(toastContent.move.success);
        refetch();
        AppLogging.event(
          { event: AppEvent.MoveFile, category: eventCategory },
          {
            targetPath,
            targetRoot,
            sourceRoot: targetRoot,
            path: node.path,
          }
        );
      }, toastContent.move.failure);
    },
    [refetch, eventCategory, toastContent]
  );

  const deleteFile = useRefCallback(
    async (node: NodeData) => {
      const toastEntry = !node.file ? TOAST_CONTENT.folder : toastContent;
      errorCatch(async () => {
        const path = node.path;
        await REST_API.STORAGE.DELETE_NODE.FETCH(root, path);

        successToast(toastEntry.delete.success);
        refetch();
        AppLogging.event(
          { event: AppEvent.DeleteFile, category: eventCategory },
          { path, file: !!node.file }
        );
      }, toastEntry.delete.failure);
    },
    [refetch, eventCategory, toastContent]
  );

  return (
    <FolderActionContext.Provider
      value={{ createFolder, moveFile, deleteFile, retrieveNodes }}
    >
      {children}
    </FolderActionContext.Provider>
  );
};

export const ServicesFolderProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { retrieveNodes } = useServicesFiles();

  return (
    <ExplorerStorageFolderProvider
      retrieveNodes={retrieveNodes}
      root="services"
    >
      {children}
    </ExplorerStorageFolderProvider>
  );
};

export const OperationsFolderProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { retrieveNodes } = useOperationFiles();

  return (
    <ExplorerStorageFolderProvider
      retrieveNodes={retrieveNodes}
      root="operations"
    >
      {children}
    </ExplorerStorageFolderProvider>
  );
};
