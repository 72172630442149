import {
  LedgerSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { FormSectionAccordion, TextInput } from "@enfusion-ui/web-components";
import * as React from "react";

import LedgerSelect from "../../../../../reports/components/ReportQueryComponents/LedgerSelect";

const LedgerSelectionEditWidget: React.FC = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange = (key: string) => (ledgerIds: string[] | null) => {
    changeConfigKeyToBeApplied(key, ledgerIds ?? []);
  };

  const { defaultValue = null, key = "Ledger" } =
    editedConfig as LedgerSelectionWidgetConfig;

  return (
    <FormSectionAccordion title="Ledger Options">
      <TextInput
        label="Key"
        value={key}
        name="key"
        onChange={handleTextChange("key")}
      />
      <LedgerSelect
        label="Ledger"
        value={defaultValue}
        onChange={handleBasicChange("defaultValue")}
      />
    </FormSectionAccordion>
  );
};

export default LedgerSelectionEditWidget;
