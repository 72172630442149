import { useRefCallback } from "@enfusion-ui/hooks";
import React from "react";
export const useTouch = () => {
    const cellTouchedCountRef = React.useRef(new Map());
    const getTouched = useRefCallback((rowId, field) => {
        return (cellTouchedCountRef.current.get(rowId)?.get(field) ?? 0) > 0;
    }, []);
    const adjustTouchedCount = useRefCallback((rowId, field, decrease = false) => {
        const map = cellTouchedCountRef.current;
        if (!map.has(rowId)) {
            map.set(rowId, new Map());
        }
        const curr = map.get(rowId).get(field) ?? 0;
        map.get(rowId).set(field, decrease ? Math.max(curr - 1, 0) : curr + 1);
    }, []);
    const resetTouchedCount = useRefCallback(() => cellTouchedCountRef.current.clear(), []);
    return {
        getTouched,
        adjustTouchedCount,
        resetTouchedCount,
    };
};
