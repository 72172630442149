import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import {
  DASHBOARD_CONTEXT_CHANNEL_NAME,
  DASHBOARD_CONTEXT_REFRESH,
  DashboardContextChannelEvent,
  useChannelDataValues,
  useDashboard,
  useDashboardDatasourceParams,
  useWidget,
  WidgetWithDatasourceConfig,
} from "@enfusion-ui/dashboards";
import { useRefCallback } from "@enfusion-ui/hooks";
import { DashboardRoot } from "@enfusion-ui/types";
import { errorToast, useReports } from "@enfusion-ui/web-core";
import { debounce } from "lodash";
import * as React from "react";
import { usePrevious } from "react-use";

export function useReportDatasource(deps: React.DependencyList = []) {
  const { config } = useWidget();
  const { getDatasource, filePath } = useDashboard();
  const { openReportTab, loadReport, dataStore, metaStore } = useReports();

  const { datasourceId, subTableDatasourceChannelId } =
    config as WidgetWithDatasourceConfig;

  const { subTableDatasource } = useChannelDataValues({
    subTableDatasource: subTableDatasourceChannelId,
  });

  const tableId = subTableDatasource
    ? subTableDatasource.reportId
    : datasourceId;

  const metadata = tableId ? metaStore?.[tableId] ?? null : null;
  const errorLoading = metadata ? metadata.error : undefined;

  const gridOptions = tableId ? dataStore?.[tableId] ?? null : null;

  const datasource = React.useMemo(() => {
    if (!getDatasource) return undefined;
    if (subTableDatasource) return getDatasource(subTableDatasource.reportId);
    return datasourceId ? getDatasource(datasourceId) : undefined;
  }, [subTableDatasource?.reportId, datasourceId, getDatasource]);
  const oldDatasource = usePrevious(datasource);

  const params = useDashboardDatasourceParams();

  const reloadReport = useRefCallback(
    debounce(() => {
      if (datasource && datasource.path && datasource.name) {
        loadReport({
          reportId: datasource.id,
          name: datasource.name,
          path: datasource.path,
          pathParams: datasource.pathParams,
          params,
          closeFirst: true,
        });
      }
    }, 300),
    [params, datasource]
  );

  React.useEffect(() => {
    reloadReport();
  }, [JSON.stringify(params), ...deps]);

  useBroadcastChannel<DashboardContextChannelEvent>(
    DASHBOARD_CONTEXT_CHANNEL_NAME,
    (ev) => {
      if (ev.path === filePath.replace(/\//g, "-")) {
        if (ev.action === DASHBOARD_CONTEXT_REFRESH) {
          reloadReport();
        }
      }
    }
  );

  const openDrillDown = useRefCallback(() => {
    if (datasource) {
      const root = datasource?.path?.substring(
        0,
        datasource.path.indexOf("/")
      ) as DashboardRoot;
      if (root) {
        openReportTab({
          name: datasource.name,
          path: datasource.path,
          params,
          root,
        });
      } else {
        errorToast("Error while getting root of the selected report");
      }
    }
  }, [openReportTab, datasource, params]);

  React.useEffect(() => {
    if (!oldDatasource || oldDatasource?.id !== datasource?.id) {
      reloadReport();
    }
  }, [datasource?.id]);

  const reportViewConfig = React.useMemo(() => {
    return datasource
      ? {
          reportId: datasource.id,
          name: datasource.name,
          path: datasource.path,
          tableId: subTableDatasource?.tableId,
          params,
        }
      : null;
  }, [datasource?.id, JSON.stringify(params), subTableDatasource?.tableId]);

  return {
    errorLoading,
    gridOptions,
    datasource,
    openDrillDown,
    reloadReport,
    metadata,
    reportViewConfig,
    tableId,
  };
}
