import { getComplianceIcon } from "@app-views/oems/components/ComplianceStateCellRenderer";
import { ComplianceResult, ComplianceState } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ITooltipParams } from "ag-grid-community";
import { get } from "lodash";
import * as React from "react";

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--background-accent);
  color: var(--text-normal);
  border: none;
  border-radius: 8px;
  padding: var(--spacing);
  min-height: 25px;
  max-width: 300px;
  min-width: 300px;
  overflow: hidden;
  gap: var(--spacing);
`;

const ComplianceStateMessageText = styled.span`
  margin-left: var(--spacing-l);
`;

const ToolTip = styled.div`
  margin-left: var(--spacing);
`;

const ComplianceStateToolTipRecord = ({
  state,
  text,
  count,
}: {
  state: ComplianceState;
  text: string;
  count?: number;
}) => {
  const { icon, color, message } = getComplianceIcon(
    state,
    true,
    (c) => `var(--${c})`
  );
  return (
    <ToolTip>
      <FontAwesomeIcon icon={icon} color={color} />
      {count && (
        <ComplianceStateMessageText style={{ color }}>
          {count}
        </ComplianceStateMessageText>
      )}
      <ComplianceStateMessageText style={{ color }}>
        {message}
      </ComplianceStateMessageText>
      <ToolTip style={{ marginLeft: "var(--spacing-l)" }}>{text}</ToolTip>
    </ToolTip>
  );
};

export type GetComplianceStateTooltipRecordsParams = {
  value: ComplianceResult;
  data: ITooltipParams["data"];
  node: ITooltipParams["node"];
  api: ITooltipParams["api"];
};

export type GetComplianceStateTooltipRecords = (
  args: GetComplianceStateTooltipRecordsParams
) => ComplianceResult["complianceRecords"];

export const ComplianceStateTooltip = ({
  resultField,
  getRecords = (args) => args.value.complianceRecords,
  node,
  api,
  rowIndex,
  value,
}: ITooltipParams & {
  resultField?: string;
  getRecords?: GetComplianceStateTooltipRecords;
}) => {
  const data = React.useMemo(
    () => api.getDisplayedRowAtIndex(rowIndex!)!.data,
    []
  );

  const isGroupComplianceState = Array.isArray(value);

  if (isGroupComplianceState) {
    return (
      <TooltipContainer>
        {value.map((complianceState) => (
          <ComplianceStateToolTipRecord
            key={complianceState.state}
            state={complianceState.state}
            count={complianceState.count}
            text=""
          />
        ))}
      </TooltipContainer>
    );
  }

  if (!resultField || !node || !data) return null;

  const complianceValue = get(data, resultField) as ComplianceResult;
  if (!complianceValue) return null;

  const records = getRecords({ value: complianceValue, data, node, api });

  return (
    <TooltipContainer>
      {records.slice(0, 4).map((e, idx) => (
        <ComplianceStateToolTipRecord
          key={e.id + idx}
          state={e.complianceState}
          text={e.text}
        />
      ))}
      {records.length > 4 && (
        <CenterContent style={{ flex: 1 }}>
          ({records.length - 4} More)
        </CenterContent>
      )}
      {records.length === 0 ? (
        complianceValue.complianceState ? (
          <ComplianceStateToolTipRecord
            state={complianceValue.complianceState}
            text=""
          />
        ) : (
          <CenterContent fillHeight style={{ flex: 1 }}>
            No matching records
          </CenterContent>
        )
      ) : (
        <CenterContent fillHeight style={{ flex: 1 }}>
          No matching records
        </CenterContent>
      )}
    </TooltipContainer>
  );
};
