import { getOrderFormType, UNKNOWN_INSTRUMENT_ID } from "@enfusion-ui/core";
import {
  FinancialSubType,
  FutureSpreadLegInfo,
  InstrumentInfo,
} from "@enfusion-ui/types";
import {
  Button,
  ButtonThemeOptions,
  ControlledEmpty,
  ControlledInputBase,
  ControlledNumericInput,
  ControlledNumericInputAsString,
  DatePicker as DatePickerInput,
  Modal,
  ModalTitle,
  Spinner,
} from "@enfusion-ui/web-components";
import { REST_API, styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import CounterpartySelection from "../CounterpartySelection";

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: var(--spacing-xl);
  gap: var(--spacing-xl);
  overflow-x: hidden;
`;

const ContentContainer = styled.div`
  margin-top: var(--spacing-l);
`;

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--spacing-l);
`;

const SpinnerContainer = styled.div`
  height: 50px;
`;

export type VoiceOrderFillModalProps = {
  open: boolean;
  title?: string;
  onSubmit: (fills: VoiceOrderFormValues) => void;
  onCancel: VoidFunction;
  submitActionTheme?: ButtonThemeOptions;
  instrumentSubtype?: FinancialSubType;
  orderId?: number | null;
};

const TitleContainer = styled(ModalTitle)`
  font-size: 0.875rem;
`;

export const defaultLeg = { price: null, instrumentId: UNKNOWN_INSTRUMENT_ID };

export type VoiceOrderFormValues = {
  tradeDate: Date | null;
  price: number | null;
  quantity: number | null;
  counterpartyId: number | null;
  counterpartyShortName: string;
  farLegLastQty: number | null;
  farLegLastPx: number | null;
  notional: number | null;
  tradeSpread: number | null;
  upfrontFee: number | null;
  legs: FutureSpreadLegInfo[] | null;
  upfrontFees: number | null;
  fxRateLeg1ToLeg2: number | null;
};

export const voiceOrderFormDefaultValues: VoiceOrderFormValues = {
  tradeDate: null,
  price: null,
  quantity: null,
  counterpartyId: null,
  counterpartyShortName: "",
  farLegLastQty: null,
  farLegLastPx: null,
  notional: null,
  tradeSpread: null,
  upfrontFee: null,
  legs: null,
  upfrontFees: null,
  fxRateLeg1ToLeg2: null,
};

export const VoiceOrderFillModal: React.FC<VoiceOrderFillModalProps> = (
  props: VoiceOrderFillModalProps
) => {
  const {
    open,
    title,
    instrumentSubtype,
    orderId,
    onCancel,
    onSubmit,
    submitActionTheme,
  } = props;

  const type = getOrderFormType(instrumentSubtype, {
    varSwap: !process.env.REACT_APP_ENABLE_VAR_SWAP_ORDERS,
    irs: !process.env.REACT_APP_ENABLE_IRS_ORDERS,
  });

  const formMethods = useForm<VoiceOrderFormValues>();

  const futureLegsInfo = React.useRef<FutureSpreadLegInfo[]>([
    defaultLeg,
    defaultLeg,
  ]);
  const [counterpartyId, setCounterpartyId] = React.useState<number | null>();
  const [instrumentId, setInstrumentId] = React.useState<number | null>(null);
  const [futureSpreadLegs, setFutureSpreadLegs] = React.useState<
    InstrumentInfo[] | null
  >(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const { reset, setValue, handleSubmit } = formMethods;

  React.useEffect(() => {
    const getCounterParty = async () => {
      if (orderId) {
        const orderDetails = await REST_API.OEMS.GET_ORDER("equity", orderId);
        setInstrumentId(orderDetails.instrumentId);
        setCounterpartyId(orderDetails?.counterpartyId);
      }
    };
    getCounterParty();
  }, [orderId]);

  React.useEffect(() => {
    const fetchLegDetails = async () => {
      try {
        if (instrumentId) {
          setLoading(true);
          const instrument =
            await REST_API.INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(instrumentId);
          if (instrument?.nearLeg && instrument.farLeg) {
            const values = await Promise.all([
              REST_API.INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(
                instrument?.nearLeg.futureId
              ),
              REST_API.INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(
                instrument?.farLeg?.futureId
              ),
            ]);

            setFutureSpreadLegs(values);
          } else {
            setFutureSpreadLegs(null);
          }
        }
      } catch (err) {
        console.error("Future spread info load error", err);
      } finally {
        setLoading(false);
      }
    };
    if (type === "futureSpread") {
      fetchLegDetails();
    }
  }, [instrumentId, type]);

  const closeModal = () => {
    reset();
    onCancel();
  };

  const onLegPxChange = (legPx: number | null, idx: number, id: number) => {
    if (futureLegsInfo.current?.length > idx) {
      futureLegsInfo.current = futureLegsInfo.current.map((x, i) =>
        i === idx ? { ...x, price: legPx, instrumentId: id } : x
      );
      setValue("legs", futureLegsInfo.current);
    }
  };

  return (
    <Modal
      isOpen={open}
      onClose={closeModal}
      title={<TitleContainer>{title}</TitleContainer>}
      content={
        <FormProvider {...formMethods}>
          <form>
            <FormContainer>
              <ContentContainer>
                <ControlledEmpty name="legs" />
                <ControlledInputBase
                  name="tradeDate"
                  defaultValue={new Date()}
                  render={({ ref: _ref, onChange, value, ...rest }) => (
                    <DatePickerInput
                      {...rest}
                      label="Trade Date"
                      clearable
                      value={value}
                      onChange={onChange}
                      dateFormat="yyyy-MM-dd"
                    />
                  )}
                />

                {type === "futureSpread" ? (
                  !loading ? (
                    futureSpreadLegs?.map(
                      (leg: InstrumentInfo, idx: number) => (
                        <ControlledNumericInput
                          key={`price${idx}`}
                          name={`price${idx}`}
                          label={`${leg.description} Price`}
                          onChange={(px: number | null) =>
                            onLegPxChange(px, idx, leg.id)
                          }
                        />
                      )
                    )
                  ) : (
                    <SpinnerContainer>
                      <Spinner size="1x" />
                    </SpinnerContainer>
                  )
                ) : (
                  <ControlledNumericInput
                    name="price"
                    label="Price"
                    defaultValue={null}
                  />
                )}
                {type !== "cdx" && type !== "irs" && (
                  <ControlledNumericInputAsString
                    name="quantity"
                    label="Quantity"
                    forceStep={false}
                    min={0}
                    defaultValue={null}
                  />
                )}
                <CounterpartySelection
                  defaultValue={counterpartyId}
                  rules={{ required: "Counterparty is required" }}
                />

                {(type === "cdx" || type === "irs") && (
                  <ControlledNumericInputAsString
                    name="notional"
                    label="Notional"
                    forceStep={false}
                    min={0}
                    defaultValue={null}
                  />
                )}
                {instrumentSubtype === "FXSwap" && (
                  <>
                    <ControlledNumericInput
                      name="farLegLastQty"
                      label="Far Leg Last Qty"
                      forceStep={false}
                      min={0}
                      defaultValue={null}
                    />

                    <ControlledNumericInput
                      label="Far Leg Last Price"
                      name="farLegLastPx"
                      defaultValue={null}
                    />
                  </>
                )}
                {type === "cdx" && (
                  <>
                    <ControlledNumericInputAsString
                      name="tradeSpread"
                      label="Trade Spread"
                      forceStep={false}
                      min={0}
                      defaultValue={null}
                    />
                    <ControlledNumericInputAsString
                      name="upfrontFee"
                      label="Up Front Fee"
                      forceStep={false}
                      min={0}
                      defaultValue={null}
                    />
                  </>
                )}
                {type === "irs" && (
                  <>
                    <ControlledNumericInputAsString
                      name="upfrontFees"
                      label="Up Front Fees"
                      forceStep={false}
                      min={0}
                      defaultValue={null}
                    />
                    <ControlledNumericInputAsString
                      name="fxRateLeg1ToLeg2"
                      label="Fx Rate Leg1 To Leg2"
                      forceStep={false}
                      min={0}
                      defaultValue={null}
                    />
                  </>
                )}
              </ContentContainer>

              <ActionContainer>
                <Button onClick={closeModal}>Cancel</Button>
                <Button
                  theme={submitActionTheme}
                  onClick={handleSubmit(onSubmit)}
                  data-e2e-id="voice-order-fill-save-btn"
                >
                  Save and Close
                </Button>
              </ActionContainer>
            </FormContainer>
          </form>
        </FormProvider>
      }
    />
  );
};

export default VoiceOrderFillModal;
