import { ControlledInstrumentSelect } from "@app-components/inputs/InstrumentSelect/ControlledInstrumentSelect";
import { useTradeTicket } from "@enfusion-ui/trades";
import { FormElementHeader } from "@enfusion-ui/web-components";
import { styled, useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";

const TRADE_INSTRUMENT_LABEL = "Trade Instrument";

const Container = styled.div`
  margin-bottom: var(--spacing-l);
  display: flex;
  align-items: center;
  gap: var(--spacing-l);
`;

export const TradeInstrumentSelect: React.FC<{
  label?: string;
  name?: string;
}> = ({ label = TRADE_INSTRUMENT_LABEL, name = "instrument" }) => {
  const { newTrade, completed, isFutureSpreadTrade } = useTradeTicket();
  const isMobile = useIsContentMobile();

  const disabled = React.useMemo(
    () => !newTrade || completed,
    [newTrade, completed]
  );

  return (
    <>
      <Container id="trade-instrument-select">
        <FormElementHeader
          showDivider={false}
          style={{ minWidth: "max-content" }}
        >
          {label}
        </FormElementHeader>

        <ControlledInstrumentSelect
          inline
          name={name}
          disabled={disabled}
          autoFocus={!disabled}
          hideAdvancedSearchButtonText={isMobile}
          financialSubTypes={isFutureSpreadTrade ? ["FutureSpread"] : undefined}
          style={{ width: "content-fit" }}
          required
          // searchOnlyPrimary={/* TODO */}
        />
      </Container>
    </>
  );
};
