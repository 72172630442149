import { ReportSettingsDefinition } from "../../types";
import GLBalanceSheetQuickFilter from "./GLBalanceSheetQuickFilter";
import GLBalanceSheetSettings from "./GLBalanceSheetSettings";

export const WebGLBalanceSheetQuery: ReportSettingsDefinition = {
  name: "GLBalanceSheet",
  queryType: "WebGLBalanceSheetQuery",
  quickFilterComponent: GLBalanceSheetQuickFilter,
  settingsComponent: GLBalanceSheetSettings,
};
