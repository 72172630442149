import {
  COMMISSION_OPTIONS,
  isAutoCalculated,
  isForceImpliedInPrice,
} from "@enfusion-ui/trades";
import * as React from "react";

import { FeeCommissionControl } from "./FeeCommissionControl";

// Keep as its own component as in the future should be pulling typeOptions form the api
export const Commissions: React.FC<unknown> = () => {
  return (
    <FeeCommissionControl
      label="Commission(s)"
      name="commissions"
      typeOptions={COMMISSION_OPTIONS}
      isAutoCalculated={isAutoCalculated}
      isForceImpliedInPrice={isForceImpliedInPrice}
    />
  );
};
