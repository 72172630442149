import { DeskSelectionWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import { DeskSelect } from "../../../../../reports/components/ReportQueryComponents/AssignedDeskSelect";

const DeskSelectionWidget = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, key } = config as DeskSelectionWidgetConfig;

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Desk IDs",
        type: ChannelDataType.Number,
      },
    ]);
  }, [addChannelKeys, key]);

  React.useEffect(() => {
    setChannelData([defaultValue]);
  }, [setChannelData, defaultValue]);

  return (
    <CenterContent>
      <DeskSelect
        selectProps={{ label: key }}
        value={defaultValue}
        onChange={(ids) => setChannelData([ids])}
      />
    </CenterContent>
  );
};

export default DeskSelectionWidget;
