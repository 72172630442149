import { usePortfolio } from "@app-context/portfolios/PortfolioProvider";
import { useRefCallback } from "@enfusion-ui/hooks";
import { bigValue, parseStringToNumber } from "@enfusion-ui/utils";
import { ICellEditorParams } from "ag-grid-enterprise";
import BigNumber from "bignumber.js";
import * as React from "react";

import { HighlightedInputCell } from "../HighlightedInputCell";
import { getRowData } from "../utils";

export const NumericInputCellEditor = React.memo(
  React.forwardRef<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    ICellEditorParams & {
      min?: number;
      max?: number;
      step?: number;
      useValue?: boolean;
      convertValue?: (newValue: number | null) => number | null;
      reverseConvertValue?: (newValue: number | null) => number | null;
      cellStyle?: React.CSSProperties;
    }
  >((props, ref) => {
    const {
      node,
      colDef,
      value,
      api,
      min,
      max,
      step = 1,
      cellStyle = {},
      useValue = false,
      convertValue = (newValue: number | null) => newValue,
      reverseConvertValue = (newValue: number | null) => newValue,
    } = props;

    const { isPortfolioAdjusting, getParentRows } = usePortfolio();

    const { data, benchmark, model, isDiff } = React.useMemo(() => {
      if (!colDef?.colId) return { data: {}, benchmark: null, model: null };
      const id = colDef.colId;
      let isDiff = false;
      let cleanId = colDef.colId
        ? colDef.colId.replace("final", "").replace("incremental", "")
        : "--";
      if (colDef.colId.startsWith("incremental")) {
        cleanId += "Difference";
        if (cleanId === "ExposurePercentNAVDifference") cleanId = "PercentDifference";
        isDiff = true;
      };
      const res = getRowData(node, getParentRows) ?? {};
      return {
        data: res[id],
        isDiff,
        benchmark: res[`benchmark${cleanId}`] || null,
        model: res[`model${cleanId}`] || null,
      };
    }, [node, node.aggData, node.data, getParentRows, colDef?.colId]);

    const [internalValue, setInternalValue] = React.useState<string | null>(
      bigValue(convertValue(isNaN(value) ? null : value))
    );
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const parseMatches = useRefCallback(
      (val: string) => {
        if (val.toLocaleUpperCase() === "M" && model) return `${convertValue((isDiff ? -1 : 1 ) * Number(model.value)) || 0}`;
        if (val.toLocaleUpperCase() === "B" && benchmark) return `${convertValue((isDiff ? -1 : 1 ) * Number(benchmark.value)) || 0}`;
        return val;
      },
      [benchmark, model]
    );

    React.useImperativeHandle(
      ref,
      () => {
        return {
          // the final value to send to the grid, on completion of editing
          getValue: () => {
            const valueBase =
              parseStringToNumber(internalValue, {
                enableMultiplier: true,
                extraParser: parseMatches,
              })?.toNumber() ?? null;

            if (useValue) return reverseConvertValue(valueBase);

            return {
              ...data,
              value: reverseConvertValue(valueBase),
              isEqualWeightDist: internalValue?.endsWith("="),
            };
          },

          // Gets called once before editing starts, to give editor a chance to
          // cancel the editing before it even starts.
          isCancelBeforeStart: () => false,

          // Gets called once when editing is finished (eg if Enter is pressed).
          // If you return true, then the result of the edit will be ignored.
          isCancelAfterEnd: () => false,
        };
      },
      [internalValue, useValue, data]
    );

    const handleChange = useRefCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(e.currentTarget.value);
      },
      []
    );

    const handleKeyPress = useRefCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;

        if (key === "ArrowDown" || key === "ArrowUp") {
          e.preventDefault();
          let newAmount = (
            internalValue
              ? parseStringToNumber(internalValue, {
                  enableMultiplier: true,
                  extraParser: parseMatches,
                }) ?? new BigNumber(0)
              : new BigNumber(0)
          ).plus(key === "ArrowUp" ? step : -step);
          if (typeof min !== "undefined") {
            newAmount = BigNumber.maximum(min, newAmount);
          }
          if (typeof max !== "undefined") {
            newAmount = BigNumber.minimum(max, newAmount);
          }
          setInternalValue(newAmount.valueOf());
        }
      },
      [internalValue, step, min, max]
    );

    const handleBlur = useRefCallback(() => {
      requestAnimationFrame(() => {
        api.stopEditing();
      });
    }, []);

    const handleFocus = useRefCallback(() => {
      requestAnimationFrame(() => {
        inputRef.current?.select();
      });
    }, []);

    if (!node) return null;

    return (
      <HighlightedInputCell
        autoFocus
        hideLabel
        minWidth="50px"
        ref={inputRef}
        defaultValue="0"
        cellStyle={cellStyle}
        value={internalValue ?? ""}
        disabled={isPortfolioAdjusting || node.isSelected()}
        data-testid="numeric-input-cell-editor"
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
      />
    );
  })
);
