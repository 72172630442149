/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelectDataValue } from "@app-views/dashboards/pdf/utils";
import {
  FundBenchmarkTypeSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import * as React from "react";

import PdfSelectionWidget from "../PdfSelectionWidget";

const PdfFundBenchmarkTypeSelectionWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const { defaultValue, key } =
    config as FundBenchmarkTypeSelectionWidgetConfig;

  useSelectDataValue(
    key,
    "Fund Benchmark Type",
    ChannelDataType.String,
    defaultValue
  );

  return <PdfSelectionWidget label={key} value={defaultValue} />;
};

export default PdfFundBenchmarkTypeSelectionWidget;
