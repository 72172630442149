import { WidgetComponentsDefinition } from "../../../types";
import CalculationMethodSelectionEditWidget from "./CalculationMethodSelectionEditWidget";
import CalculationMethodSelectionWidget from "./CalculationMethodSelectionWidget";
import PdfCalculationMethodSelectionWidget from "./PdfCalculationMethodSelectionWidget";

export const calculationMethodSelection: WidgetComponentsDefinition = {
  renderComponent: CalculationMethodSelectionWidget,
  editComponent: CalculationMethodSelectionEditWidget,
  renderPdfComponent: PdfCalculationMethodSelectionWidget,
};
