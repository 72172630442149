import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import {
  ControlledSelect,
  ControlledTextInput,
  PanelRow,
} from "@enfusion-ui/web-components";
import { REST_API, styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

const AssignmentPanelContainer = styled.div`
  padding-bottom: 8px;
`;

export const PortfolioManagerSelect: React.FC<{ disabled: boolean }> = ({
  disabled = false,
}) => {
  const { options, loading } = useRestAbortableOptions(
    REST_API.SECURITY.GET_PORTFOLIO_MANAGERS.FETCH,
    (pm) => ({ value: pm.id, label: pm.fullName })
  );

  return (
    <ControlledSelect
      mobileBasis="50%"
      name="portfolioManager"
      label="PM"
      options={options}
      isLoading={loading}
      disabled={disabled}
      inputId="assignment-portfolio-manager-selection-id"
    />
  );
};

export const TradingDeskSelect: React.FC<{ disabled: boolean }> = ({
  disabled = false,
}) => {
  const { setValue } = useFormContext();
  const { options, loading } = useRestAbortableOptions(
    REST_API.SECURITY.GET_TRADING_DESKS.FETCH,
    (td) => ({ value: td.id, label: td.groupName })
  );

  return (
    <ControlledSelect
      mobileBasis="50%"
      name="assignedTradingDesk"
      onChange={() => setValue("assignedTrader", null)}
      label="Desk"
      options={options}
      isLoading={loading}
      disabled={disabled}
      inputId="assignment-desk-selection-id"
    />
  );
};

export const AssignedTraderSelect: React.FC<{ disabled: boolean }> = ({
  disabled = false,
}) => {
  const td = useWatch<number>({ name: "assignedTradingDesk" });

  const fetchTraderOptions = React.useCallback(
    (ac?: AbortController) => REST_API.SECURITY.GET_TRADERS.FETCH(td!, ac),
    [td]
  );

  const { options, loading } = useRestAbortableOptions(
    typeof td === "number" ? fetchTraderOptions : null,
    (t) => ({ value: t.id, label: t.fullName })
  );

  return (
    <ControlledSelect
      name="assignedTrader"
      label="Trader"
      options={options}
      isLoading={loading}
      minWidth={150}
      disabled={disabled}
      inputId="assignment-trader-selection-id"
    />
  );
};

export const ComplianceStateInput: React.FC<unknown> = () => {
  return (
    <ControlledTextInput
      readOnly
      name="complianceState"
      label="State"
      mobileBasis="50%"
    />
  );
};

export const ReviewedByInput: React.FC<unknown> = () => {
  return (
    <ControlledTextInput
      readOnly
      name="reviewedBy"
      label="Reviewed By"
      mobileBasis="50%"
    />
  );
};

export const ReviewedOnInput: React.FC<unknown> = () => {
  return <ControlledTextInput readOnly name="reviewedOn" label="Reviewed On" />;
};

export const AssignmentControls: React.FC<{ disabled: boolean }> = ({
  disabled = false,
}) => {
  return (
    <div>
      <PanelRow>
        <PortfolioManagerSelect disabled={disabled} />
        <TradingDeskSelect disabled={disabled} />
      </PanelRow>
      <PanelRow>
        <AssignedTraderSelect disabled={disabled} />
      </PanelRow>
    </div>
  );
};

export const ComplianceStateControls: React.FC = () => {
  return (
    <div>
      <PanelRow>
        <ComplianceStateInput />
      </PanelRow>
      <PanelRow>
        <ReviewedByInput />
        <ReviewedOnInput />
      </PanelRow>
    </div>
  );
};

export type AssignmentPanelProps = {
  showCompliance?: boolean;
  disabled?: boolean;
};

const AssignmentPanel: React.FC<AssignmentPanelProps> = ({
  showCompliance = false,
  disabled = false,
}) => {
  return (
    <AssignmentPanelContainer>
      <AssignmentControls disabled={disabled} />
      {showCompliance && <ComplianceStateControls />}
    </AssignmentPanelContainer>
  );
};

export default AssignmentPanel;
