import ChangePasswordModal from "@app-components/modal/ChangePasswordModal";
import GDPRModals from "@app-components/modal/GDPRModals";
import { useGDPR } from "@app-context/gdpr/context";
import { useSession } from "@app-context/session/context";
import { useSettings } from "@app-context/settings/context";
import { useAllowCustomOrderTicket } from "@app-utils/useAllowCustomOrderTicket";
import {
  AppTheme,
  AUTH_EVENT,
  authEventMessage,
  ThemeDensity,
  themes,
} from "@enfusion-ui/core";
import {
  AlignRightRow,
  Checkbox,
  FormPanel,
  IconButton,
  InfoPopover,
  NumericInput,
  Select,
  TabTopActionBar,
  ViewContainer,
  ViewContent,
} from "@enfusion-ui/web-components";
import {
  AUTH_CHANNEL,
  styled,
  useAuth,
  useTabs,
  useTheme,
} from "@enfusion-ui/web-core";
import {
  faCookie,
  faNote,
  faUserCog,
} from "@fortawesome/pro-regular-svg-icons";
import { faSignOutAlt } from "@fortawesome/pro-solid-svg-icons";
import { capitalize } from "lodash";
import * as React from "react";

import { contentTabs } from "../../app/AuthenticatedApp";

const StyledCheckbox = styled(Checkbox).attrs({ labelPlacement: "top" })`
  margin-top: var(--spacing);
`;

const Panel = styled(FormPanel).attrs({
  lineStyle: true,
  collapsible: true,
  defaultOpen: true,
  defaultRendered: true,
})`
  margin-top: var(--spacing-xl);
  max-width: 1000px;
`;

const ContentWrapper = styled(ViewContent)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100%;
  max-height: 100%;
  padding: var(--spacing-xxl);
  padding-top: var(--spacing);
  overflow: hidden auto;

  hr {
    width: 100%;
    color: var(--text-muted);
    border-style: solid;
    border-width: 1px;
    border-top-width: 0px;
  }

  h1 {
    margin-bottom: var(--spacing-l);
    font-weight: bold;
  }

  h2 {
    margin-top: var(--spacing-l);
    font-weight: bold;
  }
`;

const themeOptions = Object.values(themes).map((i) => ({
  label: i.name,
  value: i.key,
}));

const densityOptions = ["high", "medium", "low"].map((i) => ({
  value: i,
  label: capitalize(i),
}));

const SettingsView: React.FC<unknown> = () => {
  const { openTab } = useTabs();
  const { user } = useAuth();
  const { theme, setThemeColor, setThemeOptions } = useTheme();
  const { timeout, enabled, setTimeoutValue, setEnabled } = useSession();
  const { settingCookiesModalOpen, toggleSettingCookiesModalOpen } = useGDPR();
  const allowCustomOrderTicket = useAllowCustomOrderTicket();
  const {
    enableOEMSRealtimeUpdate,
    enableReportRealtimeUpdate,
    toggleOEMSRealtimeUpdate,
    toggleReportRealtimeUpdate,
    enableKeepTicketOpenAfterCreate,
    enableClearTicketAfterCreate,
    enablePreferCustomOrderForm,
    toggleKeepTicketOpenAfterCreate,
    toggleClearTicketAfterCreate,
    togglePreferCustomOrderForm,
  } = useSettings();

  const [changePasswordModalOpen, setChangePasswordModalOpen] =
    React.useState(false);

  const openChangePasswordModal = () => setChangePasswordModalOpen(true);
  const closeChangePasswordModal = () => setChangePasswordModalOpen(false);

  const handleChangeTheme = (value: { value: string } | null) => {
    if (value) setThemeColor(value.value as AppTheme);
  };

  const handleChangeCellFlashing = (checked: boolean) => {
    setThemeOptions({ cellFlashing: checked });
  };

  const handleChangeDensity = (value: { value: string } | null) => {
    if (value)
      setThemeOptions({ density: value.value as unknown as ThemeDensity });
  };

  const openReleaseNotesView = () => {
    openTab(contentTabs.releaseNotes);
  };

  return (
    <ViewContainer>
      <TabTopActionBar>
        <AlignRightRow flex>
          {!!process.env.REACT_APP_ENABLE_RELEASE_NOTES && (
            <IconButton
              hoverable
              icon={faNote}
              onClick={openReleaseNotesView}
              title="Release Notes"
              size="sm"
            />
          )}
          <IconButton
            hoverable
            icon={faCookie}
            onClick={toggleSettingCookiesModalOpen}
            title="Cookie Settings"
            size="sm"
          />
          {!user?.adminUser && (
            <IconButton
              hoverable
              icon={faUserCog}
              onClick={openChangePasswordModal}
              title="Change Password"
              size="sm"
            />
          )}
          <IconButton
            hoverable
            icon={faSignOutAlt}
            onClick={() =>
              AUTH_CHANNEL.broadcastMessage(
                authEventMessage(AUTH_EVENT.LOGOUT, [
                  "User Action - Settings View",
                ])
              )
            }
            title="Sign Out"
            size="sm"
          />
        </AlignRightRow>
      </TabTopActionBar>
      <ContentWrapper>
        <h1>User Settings</h1>
        <hr />
        <Panel title="Theme Settings">
          <Select
            label="Color Theme"
            options={themeOptions}
            value={{ value: theme.key, label: theme.name }}
            clearable={false}
            onChange={handleChangeTheme}
          />
          {process.env.REACT_APP_ENABLE_VIEW_OPTIONS && (
            <Select
              label="Theme Density"
              options={densityOptions}
              value={{
                value: theme.density || "medium",
                label: capitalize(theme.density || "medium"),
              }}
              clearable={false}
              onChange={handleChangeDensity}
            />
          )}
        </Panel>
        <Panel title="Security Settings">
          <Checkbox
            label={
              <div style={{ flex: 1, paddingLeft: 0, fontSize: "0.75rem" }}>
                Auto Timeout Enabled
                <InfoPopover style={{ marginLeft: "var(--spacing)" }}>
                  When enabled this will allow the session to be auto expired
                  for security.
                </InfoPopover>
              </div>
            }
            labelPlacement="top"
            checked={enabled}
            onChange={setEnabled}
          />
          <NumericInput
            value={timeout}
            disabled={!enabled}
            onChange={(value: number | null) => setTimeoutValue(value || 15)}
            step={5}
            min={1}
            max={1440}
            label={
              <div style={{ flex: 1, paddingLeft: 0, fontSize: "0.75rem" }}>
                Auto Timeout (Minutes)
                <InfoPopover style={{ marginLeft: "var(--spacing)" }}>
                  The amount of time in minutes without activity before the
                  application will auto expire the session.
                </InfoPopover>
              </div>
            }
          />
        </Panel>

        <Panel title="OEMS Settings">
          <StyledCheckbox
            label="Keep Ticket Open After Create"
            checked={enableKeepTicketOpenAfterCreate}
            onChange={toggleKeepTicketOpenAfterCreate}
          />
          <StyledCheckbox
            label="Clear Ticket After Create"
            checked={enableClearTicketAfterCreate}
            onChange={toggleClearTicketAfterCreate}
            disabled={!enableKeepTicketOpenAfterCreate}
          />
          {allowCustomOrderTicket && (
            <StyledCheckbox
              label="Prefer Custom Order Form"
              checked={enablePreferCustomOrderForm}
              onChange={togglePreferCustomOrderForm}
            />
          )}
        </Panel>

        <Panel title="Grid Settings">
          <StyledCheckbox
            label="OEMS Realtime Updates"
            checked={enableOEMSRealtimeUpdate}
            onChange={toggleOEMSRealtimeUpdate}
          />
          <StyledCheckbox
            label="Report Realtime Updates"
            checked={enableReportRealtimeUpdate}
            onChange={toggleReportRealtimeUpdate}
          />
          {process.env.REACT_APP_ENABLE_VIEW_OPTIONS && (
            <StyledCheckbox
              label="Cell Flashing"
              checked={theme.cellFlashing ?? false}
              onChange={handleChangeCellFlashing}
            />
          )}
        </Panel>
      </ContentWrapper>
      <ChangePasswordModal
        open={changePasswordModalOpen}
        onCancel={closeChangePasswordModal}
      />
      <GDPRModals
        acceptAllCookiesModalOpen={false}
        settingCookiesModalOpen={settingCookiesModalOpen}
      />
    </ViewContainer>
  );
};

export default SettingsView;
