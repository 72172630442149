import { RoundedDataGrid } from "@app-components/DataGrid";
import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { ComplianceRecordType } from "@enfusion-ui/types";
import { formatDateTime } from "@enfusion-ui/utils";
import {
  CenterContent,
  ContentMessage,
  Spinner,
} from "@enfusion-ui/web-components";
import {
  OEMS_EVENTS,
  OemsBroadcastEvent,
  REST_API,
} from "@enfusion-ui/web-core";
import { faSkullCrossbones } from "@fortawesome/pro-solid-svg-icons";
import { ColDef, GetRowIdParams } from "ag-grid-community";
import * as React from "react";

import ComplianceStateCellRenderer from "../../components/ComplianceStateCellRenderer";

export const colDefs: ColDef[] = [
  {
    headerName: "Id",
    field: "runId",
    sort: "asc",
    width: 60,
  },
  {
    headerName: "State",
    field: "complianceState",
    width: 100,
    type: "complianceState",
  },
  {
    headerName: "Date/Time",
    field: "dateTime",
    valueFormatter: ({ value }) => formatDateTime(value) || "",
    width: 200,
  },
  { headerName: "Fund", field: "fundName", width: 200 },
  { headerName: "Reason/Text", field: "text", width: 350 },
  { headerName: "Rule", field: "ruleName", width: 200 },
  { headerName: "Note", field: "note" },
];

export type ComplianceTableProps = {
  orderId: number | null;
};

let controller: AbortController;

export const ComplianceTable: React.FC<ComplianceTableProps> = ({
  orderId,
}) => {
  const [complianceRecords, setComplianceRecords] = React.useState<
    ComplianceRecordType[] | null
  >(null);
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const isMounted = useMounted();

  const fetchComplianceData = React.useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      if (orderId) {
        controller?.abort();
        controller = new AbortController();
        const response = await REST_API.OEMS.GET_COMPLIANCE.FETCH(
          orderId,
          controller
        );
        const runs = new Set();
        const records = response
          .map((entry) => {
            runs.add(entry.dateTime);
            return { ...entry, runId: runs.size };
          })
          .reverse();
        setComplianceRecords(records);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.name === "AbortError") {
        console.info(
          `Api request for fetching compliance for orderId: ${orderId} has been aborted`
        );
      } else {
        setError("Failed to fetch compliance records");
      }
    } finally {
      setLoading(false);
    }
  }, [orderId]);

  const handleMessage = useRefCallback(
    (event: OemsBroadcastEvent) => {
      if (
        event.action === "compliance-update" &&
        event.payload.orderId === orderId
      ) {
        fetchComplianceData();
      }
    },
    [fetchComplianceData, orderId]
  );
  useBroadcastChannel<OemsBroadcastEvent>(OEMS_EVENTS, handleMessage);

  React.useEffect(() => {
    fetchComplianceData();
  }, [orderId]);

  if (error) {
    return (
      <CenterContent>
        <ContentMessage icon={faSkullCrossbones} message={error} />
      </CenterContent>
    );
  } else if (loading) {
    return (
      <CenterContent>
        <Spinner />
      </CenterContent>
    );
  } else if (isMounted()) {
    return (
      <RoundedDataGrid
        height="100%"
        // animateRows
        suppressCellFocus
        rowSelection="single"
        multiSortKey="ctrl"
        rowGroupPanelShow="always"
        groupDefaultExpanded={-1}
        rowData={complianceRecords}
        columnDefs={colDefs}
        getRowId={(params: GetRowIdParams) => params.data?.id}
        defaultColDef={{
          resizable: true,
          enableRowGroup: true,
          filter: true,
        }}
        columnTypes={{
          complianceState: { cellRenderer: ComplianceStateCellRenderer },
        }}
        overlayNoRowsTemplate="No records to display"
        overlayLoadingTemplate="Searching..."
        suppressMovableColumns
        components={{
          complianceStateCellRenderer: ComplianceStateCellRenderer,
        }}
        gridName="compliance-table"
      />
    );
  }
  return <></>;
};

export default ComplianceTable;
