import {
  ALLOCATION_TYPE,
  convertFormValuesToOrder,
  OrderFormValues,
  useAllocationsControl,
} from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { AllocationTemplateBodyInfo } from "@enfusion-ui/types";
import { ControlledSelect, FormElement } from "@enfusion-ui/web-components";
import { REST_API, styled, useAuth, useOEMS } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext } from "react-hook-form";

export type AllocationTemplatesInputProps = {
  selectedTemplate?: number;
  setError?: (error: string | null) => void;
  disabled?: boolean;
};

export type TemplateOptions = {
  label: string;
  value: number;
};

const TemplateContainer = styled(FormElement)`
  display: flex;
  flex-flow: row;
`;

export const AllocationTemplatesInput: React.FC<
  AllocationTemplatesInputProps
> = ({ setError, disabled }: AllocationTemplatesInputProps) => {
  const { isUserType } = useAuth();

  const { allocationTemplate } = useOEMS();
  const { isEntryTransmitted, setIsFormBusy } = useAllocationsControl();
  const { getValues, setValue } = useFormContext<OrderFormValues>();
  const checkExpressUser = isUserType("Express");
  const templates: TemplateOptions[] = allocationTemplate
    ? allocationTemplate.map((eachScheme) => {
        return { label: eachScheme.name, value: eachScheme.id };
      })
    : [];

  const applyAllocationTemplate = useRefCallback(
    async (allocationTemplateId: number) => {
      if (allocationTemplate) {
        setIsFormBusy(true);
        try {
          const body: AllocationTemplateBodyInfo = {
            order: convertFormValuesToOrder(getValues()),
            templateRatios: [
              { templateId: allocationTemplateId ?? 0, ratio: 1.0 },
            ],
          };

          const orderData = await REST_API.OEMS.APPLY_ALLOCATION_TEMPLATE.FETCH(
            body
          );

          if (orderData) {
            setValue("accountAllocation", orderData.accountAllocation, {
              shouldDirty: true,
            });
            setValue(
              "type",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (orderData.accountAllocation as any).allocationNumberType ??
                ALLOCATION_TYPE.percent
            );
            setValue(
              "strategyBookId",
              checkExpressUser ? null : orderData.strategyBookId
            );
            setError?.(null);
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          if (err && typeof err === "object") {
            setError?.(err.errors?.toString() ?? err.message);
          } else if (typeof err === "string") {
            setError?.(JSON.parse(err)?.message);
          }
        } finally {
          setIsFormBusy(false);
        }
      }
    },
    [
      convertFormValuesToOrder,
      getValues,
      setValue,
      setError,
      setIsFormBusy,
      allocationTemplate,
    ]
  );

  return (
    <TemplateContainer mobileBasis="50%">
      <ControlledSelect
        name="allocationTemplateId"
        label="Templates"
        options={templates}
        clearable={false}
        disabled={disabled || isEntryTransmitted}
        onChange={(id) => {
          if (id) applyAllocationTemplate(id as number);
        }}
        inputId="allocation-template-selection-id"
      />
    </TemplateContainer>
  );
};
