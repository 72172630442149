/* eslint-disable @typescript-eslint/no-explicit-any */
import { RoundedDataGrid } from "@app-components/DataGrid";
import { TableLoader } from "@app-components/display/loader/TableLoader";
import { useAppTutorial } from "@app-components/Tour/context";
import { TutorialType } from "@app-components/Tour/utils";
import { usePortfolio } from "@app-context/portfolios/PortfolioProvider";
import { contractOrExpandParentRows } from "@app-utils/gridRowExpand";
import { useGeneralSearchGrid } from "@app-utils/useGeneralSearchGrid";
import {
  useLocalStorage,
  useModalState,
  useRefCallback,
} from "@enfusion-ui/hooks";
import {
  baseMapColumns,
  ColIdToAdjustmentMap,
  groupingColumns,
  isolationKeywords,
  keywordToColumnMap,
  mainPortfolioGridColumnDefs,
  parseFilterModal,
  PORTFOLIO_TARGET_DIST_METHODS,
  PortfolioAdjustmentColIds,
  PWB_STORAGE_KEY,
} from "@enfusion-ui/portfolios";
import { ColumnChooserColumn } from "@enfusion-ui/types";
import {
  ColumnChooserModal,
  useConfirmationModal,
  VisibleIf,
} from "@enfusion-ui/web-components";
import {
  css,
  dataEntry,
  errorToast,
  infoToast,
  styled,
  useAuth,
  useGridApi,
  useThisTab,
} from "@enfusion-ui/web-core";
import { icon, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowsToEye,
  faColumns3,
  faEye,
} from "@fortawesome/pro-regular-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import {
  CellEditingStoppedEvent,
  ColDef,
  ColumnRowGroupChangedEvent,
  ColumnState,
  ExpandCollapseAllEvent,
  FilterChangedEvent,
  FilterModel,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  IRowNode,
  MenuItemDef,
  RowDataUpdatedEvent,
  RowGroupOpenedEvent,
  RowNode,
  SelectionChangedEvent,
  SuppressKeyboardEventParams,
  ValueGetterParams,
} from "ag-grid-community";
import { debounce, omit, partition, sortBy } from "lodash";
import * as React from "react";

import { NumericInputCellEditor } from "./cellRenderers/NumericInputCellEditor";
import { NumericInputCellRenderer } from "./cellRenderers/NumericInputCellRenderer";
import { StatusCellRenderer } from "./cellRenderers/StatusCellRenderer";
import { HorizontalResizeBar } from "./HorizontalResizeBar";
import { SizeGridWrapper } from "./SizeGridWrapper";
import {
  aggFuncs,
  DECIMAL_PRECISION,
  getExpandContractActions,
  getMainMenuExpandContractActions,
  getParentRowValues,
  mainPortfolioGridColumnTypes,
} from "./utils";

export type ContextItemEntry = string | MenuItemDef;

const MainPortfolioGridWrapper = styled.div<{ shouldScroll: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  ${({ shouldScroll }) =>
    shouldScroll
      ? css`
          .ag-body-horizontal-scroll {
            display: flex !important;
            max-height: 8px !important;
            height: 8px !important;
          }
        `
      : ""}
`;

const GridWrapper = styled(RoundedDataGrid)`
  flex: 1;

  > div {
    width: 100%;
  }

  .ag-advanced-filter-header {
    z-index: 200;
  }

  .ag-root-wrapper-body .ag-checkbox-input-wrapper.ag-checked::after {
    font-family: "FontAwesome";
    content: "\f023" !important;
    top: 0px !important;
    left: 4px !important;
    font-size: 12px;
  }
  .ag-header-cell[col-id="selection"] {
    padding: 5px;
  }
  .ag-row-selected {
    background-color: var(--info);
  }
  .ag-row-selected .ag-cell {
    background-color: var(--info) !important;
  }
  .ag-row-selected .ag-react-container input {
    background-color: var(--info-hover) !important;
  }
  .ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }
  .ag-header {
    border-top-right-radius: 0px;
  }

  .ag-pinned-right-cols-container {
    min-height: 100% !important;
    min-width: var(--pwb-right-width, 200px) !important;
    width: var(--pwb-right-width, 200px) !important;
    overflow: auto !important;
    margin-bottom: -8px;
  }

  .ag-pinned-right-cols-container::-webkit-scrollbar {
    height: 0px;
  }

  .ag-pinned-right-cols-container .ag-row {
    width: var(--pwb-right-inner-width);
  }

  .ag-pinned-right-sticky-top {
    width: var(--pwb-right-width, 200px) !important;
    max-width: var(--pwb-right-width, 200px) !important;
    min-width: var(--pwb-right-width, 200px) !important;
    overflow: hidden !important;
  }

  .ag-pinned-right-sticky-top .ag-row {
    width: var(--pwb-right-inner-width);
  }

  .ag-horizontal-right-spacer {
    --sp-w: calc(
      var(--pwb-right-width, 200px) + var(--pwb-vertical-scroll-width, 0px)
    );
    width: var(--sp-w) !important;
    max-width: var(--sp-w) !important;
    min-width: var(--sp-w) !important;
    overflow: auto !important;
    border-left-width: 0px !important;
  }

  .ag-horizontal-right-spacer:before {
    content: "";
    display: block;
    width: var(--pwb-right-inner-width);
    height: 8px;
  }

  .ag-pinned-right-header {
    --sp-w: calc(
      var(--pwb-right-width, 200px) + var(--pwb-vertical-scroll-width, 0px)
    );
    width: var(--sp-w) !important;
    max-width: var(--sp-w) !important;
    min-width: var(--sp-w) !important;
    overflow: hidden !important;
  }

  .ag-pinned-right-header .ag-header-cell-resize {
    left: -1px;
  }
`;

const GROPING_ROW_HEIGHT = 32;

const ResizeOverTop = styled(HorizontalResizeBar)`
  position: absolute !important;
  top: ${GROPING_ROW_HEIGHT}px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 30;
`;

const defaultColDef = {
  cellStyle: {
    padding: "0px 4px",
    textAlign: "right",
    height: "21px",
    lineHeight: "21px",
    borderWidth: "0px",
  },
  resizable: true,
  enableRowGroup: false,
  valueGetter: (args: ValueGetterParams) => {
    const data = dataEntry(args);
    if (typeof data === "object") return data.value || null;
    return data;
  },
  sortable: true,
  filter: true,
  suppressKeyboardEvent: (params: SuppressKeyboardEventParams<any>) => {
    if (params.event.key === "Tab") {
      params.api.stopEditing();
      return true;
    }
    return false;
  },
  menuTabs: ["generalMenuTab", "filterMenuTab"],
} as ColDef;

const autoGroupColumnDef = {
  initialWidth: 100,
  cellStyle: {
    padding: "0px 3px",
    textAlign: "left",
    height: "21px",
    lineHeight: "21px",
    borderWidth: "0px",
  },
  cellRendererParams: {
    suppressCount: true,
  },
  sortable: true,
  filter: true,
} as ColDef;

const groupColIds = groupingColumns.map((i) => i.colId);

function handleRowUpdatedToastClick(
  api: GridApi,
  rowNodes: IRowNode<any>[],
  flash = false
) {
  contractOrExpandParentRows(rowNodes[0], true);
  requestAnimationFrame(() => {
    api.ensureNodeVisible(rowNodes[0], "middle");
    if (flash) api.flashCells({ rowNodes });
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getRowNodeId = (args: GetRowIdParams<any>) => args.data.rowId;

const mapChooserCols = (
  defs: ColDef[],
  defaultColumns: ColumnChooserColumn[] = []
) => {
  return defs.reduce((res, entry: ColDef, idx) => {
    if (!entry.headerCheckboxSelection && !entry.lockVisible)
      res.push({
        id: entry.colId || "Unknown",
        name: entry.headerName || "Unknown",
        selected: !entry.hide,
        category: (entry.headerClass as string) || "Unknown",
        groupName: entry.pinned === "right" ? "calculation" : "informative",
        orderIndex: idx,
        tooltip: entry.headerTooltip,
      });

    return res;
  }, defaultColumns);
};

function getFilteredRowNodeIds(event: FilterChangedEvent) {
  const res = new Set<string>();
  const visibleIds = new Set<String>();
  event.api.forEachNodeAfterFilter((node: IRowNode) => {
    if (!node.group && typeof node.id !== "undefined") visibleIds.add(node.id);
  });
  event.api.forEachNode((node: IRowNode) => {
    if (
      !node.group &&
      typeof node.id !== "undefined" &&
      node.data?.issuer?.description !== "Cash" &&
      !visibleIds.has(node.id)
    )
      res.add(node.id);
  });
  return [...res];
}

const ROW_BUFFER = 30;
const COL_GROUP_ROW_ID_SEP = "|-|";
const COL_GROUP_ROW_EMPTY = "(Blanks)";
const DEFAULT_RIGHT_SIDE_WIDTH = 400;
const RIGHT_SIDE_MIN_WIDTH = 200;

const getGroupRowKey = (colId: string | null, value: string | null) =>
  `${colId}${COL_GROUP_ROW_ID_SEP}${value || COL_GROUP_ROW_EMPTY}`;

const getGroupRowState = (state: string[]) =>
  state.reduce<Map<string, Set<string>>>((res, e) => {
    if (e.indexOf(COL_GROUP_ROW_ID_SEP) <= 0) return res;
    const [colId, value] = e.split(COL_GROUP_ROW_ID_SEP);
    if (!res.has(colId)) res.set(colId, new Set());
    res.get(colId)?.add(value);
    return res;
  }, new Map());

const getBaseElm = (selector: string) =>
  document.getElementById(selector)?.querySelector("#main-portfolio-grid");

const cleanPx = (val: string | number) => Number(`${val}`.replace("px", ""));

const runOnScroll = (selector: string, evt: any) => {
  // not the most exciting thing, but a thing nonetheless
  const base = getBaseElm(selector);

  base
    ?.querySelector(".ag-pinned-right-header")
    ?.scrollTo(evt.srcElement.scrollLeft, 0);

  base
    ?.querySelector(".ag-pinned-right-sticky-top")
    ?.scrollTo(evt.srcElement.scrollLeft, 0);

  return base;
};

export const MainPortfolioGrid = () => {
  const {
    removeSecurity,
    config: { filePath },
    regroup,
    setLockedRowIds,
    adjust,
    registerHandlers,
    syncReceived,
    changeGridSettings,
    settings,
    getParentRows,
    setFilteredRowIds,
    reloaded,
    securitiesList,
  } = usePortfolio();
  const { user } = useAuth();
  const { selector, width: tabWidth } = useThisTab();
  const { tutorialType, step } = useAppTutorial();

  const [hasPinned, setHasPinned] = React.useState(false);
  const [rightSideMeasure, setRightSideMeasure] = React.useState({
    innerWidth: 0,
    scrollBarWidth: 0,
  });
  const [{ rightSideWidth }, setStore] = useLocalStorage<{
    rightSideWidth: number;
  }>(`${PWB_STORAGE_KEY}-${user?.username}-${filePath.replaceAll(" ", "-")}`, {
    rightSideWidth: DEFAULT_RIGHT_SIDE_WIDTH,
  });

  const [rightSideMaxWidth, setRightSideMaxWidth] = React.useState<number>();

  React.useEffect(() => {
    if (tabWidth) {
      const maxWidth = tabWidth * 0.9;
      const validWidth = Math.max(
        Math.min(rightSideWidth, maxWidth),
        RIGHT_SIDE_MIN_WIDTH
      );
      setRightSideMaxWidth(maxWidth);
      if (validWidth !== rightSideWidth) {
        setStore((prev) => ({ ...prev, rightSideWidth: validWidth }));
      }
    }
  }, [tabWidth]);

  const [isolationKey, setIsolationKey] = React.useState<string | null>(null);

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const groupExpandedRowsRef = React.useRef(
    new Set<string>(
      (settings.groupRowExpandedState ?? [])
        .map((e) => (e.indexOf(COL_GROUP_ROW_ID_SEP) <= 0 ? null : e))
        .filter((i) => i !== null) as Array<string>
    )
  );

  const measureForRightSide = useRefCallback(
    debounce(() => {
      const base = getBaseElm(selector);

      if (base) {
        const rightSideContainer = getComputedStyle(
          base.querySelector(".ag-pinned-right-cols-container")!
        );

        const scrollContainer = getComputedStyle(
          base.querySelector(".ag-body-vertical-scroll")!
        );

        const innerWidth = cleanPx(rightSideContainer.maxWidth);
        const scrollBarWidth =
          innerWidth === 0 ? 0 : cleanPx(scrollContainer.width);

        setRightSideMeasure({ innerWidth, scrollBarWidth });
      }
    }, 300),
    [selector]
  );

  const updateRowGroupState = useRefCallback(
    (api: GridApi<any>, groupRowExpandedState?: string[]) => {
      if (
        typeof groupRowExpandedState !== "undefined" &&
        groupRowExpandedState.length > 0
      ) {
        requestAnimationFrame(() => {
          const rows = getGroupRowState(groupRowExpandedState);

          if (rows.size > 0) {
            api.forEachNode((node) => {
              const colId = node.field;
              if (
                !!node &&
                node.group &&
                node.key !== null &&
                typeof colId === "string" &&
                rows.has(colId)
              ) {
                if (rows.get(colId)?.has(node.key || COL_GROUP_ROW_EMPTY)) {
                  node.setExpanded(true);
                }
              }
            });
          }
        });
      }
    },
    []
  );

  const applyGridSettingsState = useRefCallback(() => {
    if (!gridRef.current) return;
    const { api } = gridRef.current;

    if (settings.gridColumnState) {
      api.applyColumnState({
        state: settings.gridColumnState,
        applyOrder: true,
      });
      setHasPinned(
        settings.gridColumnState.some((i) => i.pinned === "right" && !i.hide)
      );
    } else {
      setHasPinned(true);
    }

    if (settings.filterModel || settings.advFilterModel) {
      requestAnimationFrame(() => {
        settings.advFilterModel
          ? api.setAdvancedFilterModel(
              Object.keys(settings.advFilterModel)?.length
                ? settings.advFilterModel
                : null
            )
          : api.setFilterModel(
              parseFilterModal(undefined, settings.filterModel) as FilterModel
            );
      });
    }
    updateRowGroupState(api, settings.groupRowExpandedState);
  }, [settings, updateRowGroupState]);

  const { gridApiRef: gridRef, onGridReady: handleGridReady } = useGridApi(
    () => {
      const runOnScrollContainer = (evt: any) => {
        runOnScroll(selector, evt)
          ?.querySelector(".ag-horizontal-right-spacer")
          ?.scrollTo(evt.srcElement.scrollLeft, 0);
      };

      const runOnScrollSpacer = (evt: any) => {
        runOnScroll(selector, evt)
          ?.querySelector(".ag-pinned-right-cols-container")
          ?.scrollTo(evt.srcElement.scrollLeft, 0);
      };

      getBaseElm(selector)
        ?.querySelector(".ag-pinned-right-cols-container")
        ?.addEventListener("scroll", runOnScrollContainer, { passive: true });

      getBaseElm(selector)
        ?.querySelector(".ag-horizontal-right-spacer")
        ?.addEventListener("scroll", runOnScrollSpacer, { passive: true });

      checkFullyReady();
      applyGridSettingsState();
      measureForRightSide();
    }
  );

  const generalSearchProps = useGeneralSearchGrid(`PWB-${filePath}`, gridRef);

  React.useEffect(() => {
    if (reloaded) applyGridSettingsState();
  }, [reloaded]);

  React.useEffect(() => {
    if (gridRef.current && tutorialType === TutorialType.Portfolio) {
      if (step?.highlightColumn) {
        gridRef.current.api.ensureColumnVisible(step.highlightColumn, "start");
      }
    }
  }, [tutorialType, step]);

  const onGridColumnStateChanged = useRefCallback(
    debounce(() => {
      const gridColumnState = gridRef.current?.api.getColumnState();
      measureForRightSide();
      changeGridSettings({ gridColumnState });
    }, 300),
    [changeGridSettings]
  );

  const columnChooserColumnsRef = React.useRef<ColumnChooserColumn[]>([]);
  const columnChooserState = useModalState({
    beforeOpen: () => {
      const defs = gridRef.current?.api.getColumnDefs() || [];
      columnChooserColumnsRef.current = mapChooserCols(defs);
    },
  });

  const updateColumnChooser = useRefCallback(
    (columns: Array<ColumnChooserColumn>) => {
      columnChooserState.closeModal();
      gridRef.current?.api.showLoadingOverlay();

      const [newCalcCols, newInfoCols] = partition(columns, {
        groupName: "calculation",
      });

      const orderedCols = [...newInfoCols, ...newCalcCols];

      const currState =
        gridRef.current?.api.getColumnState() || ([] as ColumnState[]);

      const newColumnState = orderedCols.reduce((res, { id, selected }) => {
        const state = currState.find((s) => id === s.colId);
        if (state) res.push({ ...state, hide: !selected });
        return res;
      }, [] as ColumnState[]);

      gridRef.current?.api.applyColumnState({
        state: newColumnState,
        applyOrder: true,
      });

      setHasPinned(newColumnState.some((i) => i.pinned === "right" && !i.hide));

      setTimeout(() => gridRef.current?.api.hideOverlay(), 500);
    },
    []
  );

  const RemoveSecurityModal = useConfirmationModal<{
    value: number;
    description: string;
  }>({
    title: "Remove security?",
    submitButtonText: "Yes, I'm sure",
    onSubmit: (security) => {
      if (security) removeSecurity(security?.value);
    },
    renderContent: (security) =>
      `Are you sure you would like to remove the security ${security?.description}?`,
  });

  const handleColumnRowGroupChanged = useRefCallback(
    (event: ColumnRowGroupChangedEvent<any, any>) => {
      const columnState = event.api.getColumnState();
      const groupings: { colId: string; idx: number }[] = [];
      for (const state of columnState) {
        if (!groupColIds.includes(state.colId)) continue;
        if (state.rowGroup === true)
          groupings.push({ colId: state.colId!, idx: state.rowGroupIndex! });
      }

      regroup(sortBy(groupings, ["idx"]).map((i) => i.colId));

      let groupRowExpandedState = new Set<string>();
      const rows = getGroupRowState([...groupExpandedRowsRef.current]);

      event.api.forEachNode((rowNode: IRowNode) => {
        if (
          rowNode.group &&
          typeof rowNode.field === "string" &&
          !!rowNode.id
        ) {
          const colId = rowNode.field;
          if (rows.has(colId)) {
            for (const o of rows.get(colId)!) {
              groupRowExpandedState.add(getGroupRowKey(colId, o));
            }
          }
        }
      });
      groupExpandedRowsRef.current = groupRowExpandedState;
      handleGroupRowExpandedStateChange();
      updateRowGroupState(event.api, [...groupExpandedRowsRef.current]);
    },
    [gridRef, regroup]
  );

  const handleSelectionChangedCore = useRefCallback(
    debounce(
      (
        event: SelectionChangedEvent<any, any> | FilterChangedEvent<any, any>
      ) => {
        const rowIds = event.api.getSelectedNodes().reduce((res, i) => {
          if (i.data.rowId) res.add(i.data.rowId);
          return res;
        }, new Set<string>());
        setLockedRowIds([...rowIds]);
      },
      250
    ),
    [setLockedRowIds]
  );

  const handleSelectionChanged = useRefCallback(
    (event: SelectionChangedEvent<any, any>) => {
      handleSelectionChangedCore(event);
    },
    []
  );
  const checkFullyReady = useRefCallback(() => {
    if (!!gridRef.current) {
      registerHandlers({
        initData: (rows) => {
          gridRef.current?.api.updateGridOptions({ rowData: rows });
        },
        updateData: (updates) => {
          if (
            updates.add !== null &&
            updates.add !== undefined &&
            updates.add.length > 0
          ) {
            addedRowsRef.current = updates.add;
          }
          gridRef.current?.api.applyTransaction(updates);
          requestAnimationFrame(() => {
            gridRef.current?.api.refreshCells({
              force: true,
              columns: ["price"],
            });
          });
        },
      });
    }
  }, [registerHandlers, gridRef, securitiesList]);

  const isolateMenuItemAction = useRefCallback(
    (keyword: string | null): void => {
      const showColumnsIds = keyword
        ? [...(keywordToColumnMap.get(keyword) || []), ...baseMapColumns]
        : [...baseMapColumns];

      const changeDefs = (
        event?:
          | GetMainMenuItemsParams
          | GetContextMenuItemsParams
          | GridReadyEvent
      ) => {
        if (event) {
          const currentColDefs =
            event?.api.getColumnDefs()?.map((d) => omit(d, "width")) ?? [];
          const newColDefs =
            currentColDefs?.map((colDef: ColDef) => {
              const col = event.api.getColumn(colDef.colId ?? colDef);
              const getDef = (hideElse: boolean) =>
                ({
                  ...colDef,
                  rowGroup: colDef.enableRowGroup
                    ? col?.isRowGroupActive()
                    : false,
                  hide:
                    colDef.enableRowGroup && col?.isRowGroupActive()
                      ? true
                      : hideElse,
                } as ColDef);

              if (colDef.lockVisible) return getDef(!col?.isVisible());

              const toShow = keyword
                ? showColumnsIds.includes(colDef.colId ?? "") &&
                  col?.isVisible()
                : true;
              return getDef(!toShow);
            }) ?? [];

          setHasPinned(newColDefs.some((i) => i.pinned === "right" && !i.hide));

          event.api.updateGridOptions({ columnDefs: newColDefs });
        }
      };

      changeDefs(gridRef.current);
      setIsolationKey(keyword);
    },
    [isolationKey, setIsolationKey]
  );

  const getMenuItems = useRefCallback(
    (
      params: GetMainMenuItemsParams | GetContextMenuItemsParams,
      preItems: Array<ContextItemEntry | null> = [],
      postItems: Array<ContextItemEntry | null> = []
    ): Array<ContextItemEntry> => {
      const colHeaderClass = params.column?.getColDef().headerClass;
      const keyword = isolationKeywords.find((key) => colHeaderClass === key);
      let defaultItems = params?.defaultItems || ["export"];
      const newKey = isolationKey ? null : keyword ? keyword : null;

      const realPreItems = preItems?.filter(
        Boolean
      ) as unknown as Array<ContextItemEntry>;

      if (defaultItems[0] === "pinSubMenu")
        defaultItems = defaultItems.slice(2);

      if (params.column?.isPinnedRight()) {
        defaultItems = defaultItems.filter(
          (i) => !["autoSizeThis", "autoSizeAll"].includes(i)
        );
      }
      while (defaultItems[0] === "separator") defaultItems.shift();

      const entries = [
        ...defaultItems,
        defaultItems.length > 0 && realPreItems.length > 0 ? "separator" : null,
        ...realPreItems,
        realPreItems.length > 0 || defaultItems.length > 0 ? "separator" : null,
        {
          name: "Customize Columns",
          icon: icon(faColumns3 as IconDefinition).html?.[0],
          action: columnChooserState.openModal,
        },
        isolationKey || newKey
          ? {
              name: newKey ? `Isolate by ${newKey}` : "Show All Columns",
              icon: icon((newKey ? faArrowsToEye : faEye) as IconDefinition)
                .html?.[0],
              action: () => {
                isolateMenuItemAction(newKey);
              },
            }
          : null,
        ...postItems,
      ] as (ContextItemEntry | null)[];

      return entries.filter(Boolean) as unknown as ContextItemEntry[];
    },
    [isolationKey]
  );

  const getMainMenuItems = useRefCallback(
    (params: GetMainMenuItemsParams): ContextItemEntry[] => {
      return getMenuItems(params, getMainMenuExpandContractActions(params));
    },
    [getMenuItems]
  );

  const getContextMenuItems = useRefCallback(
    (params: GetContextMenuItemsParams): ContextItemEntry[] => {
      return getMenuItems(params, getExpandContractActions(params), [
        params.node?.data?.instrument
          ? {
              name: `Remove ${params.node!.data.instrument.description}`,
              icon: icon(faTrash).html?.[0],
              action: () => {
                RemoveSecurityModal.openModal(params.node!.data.instrument);
              },
            }
          : null,
        // PHASE 2
        // params.node?.data?.instrument
        //   ? {
        //       name: "Change Benchmark/Model",
        //       icon: icon(faWrench as IconDefinition).html?.[0],
        //     }
        //   : null,
      ]);
    },
    [RemoveSecurityModal, isolationKey]
  );

  const handleCellEditingStopped = useRefCallback(
    (args: CellEditingStoppedEvent) => {
      const { oldValue: ovb, newValue: nvb, colDef, node, api } = args;

      api.refreshCells({ rowNodes: [node] });
      if ([undefined, null].includes(nvb)) return;

      const newValue = typeof nvb === "number" ? nvb : Number(nvb?.value);
      const oldValue = typeof ovb === "number" ? ovb : Number(ovb?.value);

      if (
        typeof newValue === "number" &&
        oldValue.toFixed(DECIMAL_PRECISION) !==
          newValue.toFixed(DECIMAL_PRECISION)
      ) {
        const command =
          ColIdToAdjustmentMap[colDef.colId as PortfolioAdjustmentColIds];
        if (!command) {
          infoToast(`No adjustment exits for ${colDef.colId}`);
        } else {
          const data = node.group
            ? getParentRowValues(node, getParentRows)
            : node.data;

          if (data === null) {
            errorToast("Failed to run adjustment");
            return;
          }

          adjust(
            command,
            data.rowId,
            newValue,
            typeof nvb === "object" && nvb.isEqualWeightDist
              ? PORTFOLIO_TARGET_DIST_METHODS.equalWeight
              : PORTFOLIO_TARGET_DIST_METHODS.matchExisting
          );
        }
      }
    },
    [adjust, getParentRows]
  );

  const handleGroupRowExpandedStateChange = useRefCallback(
    debounce(() => {
      changeGridSettings({
        groupRowExpandedState: [...groupExpandedRowsRef.current].sort(),
      });
    }, 300),
    []
  );

  const handleRowGroupOpened = useRefCallback((event: RowGroupOpenedEvent) => {
    if (event.node.id) {
      const key = getGroupRowKey(event.node.field, event.node.key);

      if (!event.expanded) {
        groupExpandedRowsRef.current.delete(key);
      } else {
        groupExpandedRowsRef.current.add(key);
      }

      handleGroupRowExpandedStateChange();
      measureForRightSide();
    }
  }, []);

  const handleRowGroupExpandOrCollapseAll = useRefCallback(
    (event: ExpandCollapseAllEvent) => {
      if (event.source === "expandAll") {
        event.api.forEachNode((node) => {
          if (node.group) {
            groupExpandedRowsRef.current.add(
              getGroupRowKey(node.field, node.key)
            );
          }
        });
      } else {
        groupExpandedRowsRef.current.clear();
      }
      handleGroupRowExpandedStateChange();
      measureForRightSide();
    },
    []
  );

  const storeChangeRowGroupState = useRefCallback((api: GridApi<any>) => {
    // make into row ids
    let groupRowExpandedState = new Set<string>();

    api.forEachNode((rowNode: IRowNode) => {
      if (rowNode.group && rowNode.expanded === true && !!rowNode.id) {
        groupRowExpandedState.add(getGroupRowKey(rowNode.field, rowNode.key));
      }
    });
    groupExpandedRowsRef.current = groupRowExpandedState;
    handleGroupRowExpandedStateChange();
    measureForRightSide();
  }, []);

  const handleFilterChanged = useRefCallback(
    (event: FilterChangedEvent) => {
      const advancedFilterState =
        event.source === "advancedFilter"
          ? event.api.getAdvancedFilterModel() ?? {}
          : null;
      const filterState = !advancedFilterState
        ? parseFilterModal(event.api.getFilterModel())
        : null;

      changeGridSettings({
        filterModel: filterState,
        advFilterModel: advancedFilterState,
      });
      handleSelectionChangedCore(event);
      setFilteredRowIds(getFilteredRowNodeIds(event));
      storeChangeRowGroupState(event.api);
      measureForRightSide();
    },
    [storeChangeRowGroupState, settings]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addedRowsRef = React.useRef<Array<Record<string, any>>>([]);

  const rowDataUpdated = useRefCallback(
    (params: RowDataUpdatedEvent) => {
      const instrumentSet = [
        ...(addedRowsRef.current.reduce((all, node) => {
          all.add(node.instrument.description);
          return all;
        }, new Set<string>()) as Set<string>),
      ];

      if (instrumentSet.length > 1) {
        infoToast(`Multiple(${instrumentSet.length}) securities added.`, {
          delay: 5,
        });
      } else {
        const rowNodes = addedRowsRef.current
          .map((i) => params.api.getRowNode(i.rowId))
          .filter(Boolean) as RowNode[];
        if (rowNodes.length > 0)
          infoToast(
            `Security added (${instrumentSet[0]}).`,
            "Click here to jump to new rows",
            {
              onClick: () =>
                handleRowUpdatedToastClick(params.api, rowNodes, true),
              delay: 10,
            }
          );
      }
      measureForRightSide();
      addedRowsRef.current = [];
    },
    [addedRowsRef]
  );

  const handleResize = useRefCallback((rightSideWidth: number) => {
    setStore((state) => ({ ...state, rightSideWidth }));
  }, []);

  const handleResizeInter = useRefCallback(
    (rightSideWidth: number) => {
      new Promise(() => {
        if (containerRef.current) {
          let width = hasPinned ? rightSideWidth : 0;
          const innerWidth = Math.max(
            hasPinned ? rightSideMeasure.innerWidth : 0,
            width
          );

          containerRef.current.style.setProperty(
            "--pwb-right-width",
            `${width}px`
          );
          containerRef.current.style.setProperty(
            "--pwb-right-inner-width",
            `${innerWidth}px`
          );
        }
      });
    },
    [hasPinned, rightSideMeasure.innerWidth, rightSideMeasure.scrollBarWidth]
  );

  const rightSide = React.useMemo(() => {
    let width = hasPinned ? rightSideWidth : 0;
    const innerWidth = Math.max(
      hasPinned ? rightSideMeasure.innerWidth : 0,
      width
    );
    return {
      width,
      innerWidth,
      scrollWidth: rightSideMeasure.scrollBarWidth,
      shouldScroll: Math.round(width) < Math.round(innerWidth),
    };
  }, [
    hasPinned,
    rightSideWidth,
    rightSideMeasure.innerWidth,
    rightSideMeasure.scrollBarWidth,
  ]);

  return (
    <MainPortfolioGridWrapper
      ref={containerRef}
      shouldScroll={rightSide.shouldScroll}
      style={
        {
          "--pwb-right-width": `${rightSide.width}px`,
          "--pwb-right-inner-width": `${rightSide.innerWidth}px`,
          "--pwb-vertical-scroll-width": `${rightSide.scrollWidth}px`,
        } as any
      }
    >
      <SizeGridWrapper
        style={{ overflow: "hidden", zIndex: 20, display: "block" }}
      >
        <VisibleIf visible={syncReceived}>
          <GridWrapper
            gridName={`PWB-${filePath}`}
            id="main-portfolio-grid"
            groupDisplayType="singleColumn"
            rowGroupPanelShow="always"
            rowSelection="multiple"
            height="100%"
            context={{ getParentRows }}
            components={{
              numericInputCellEditor: NumericInputCellEditor,
              numericInputCellRenderer: NumericInputCellRenderer,
              statusCellRenderer: StatusCellRenderer,
            }}
            enableAdvancedFilterState={!!settings.advFilterModel}
            tooltipShowDelay={0}
            rowBuffer={ROW_BUFFER}
            aggFuncs={aggFuncs}
            onSelectionChanged={handleSelectionChanged}
            onRowGroupOpened={handleRowGroupOpened}
            onExpandOrCollapseAll={handleRowGroupExpandOrCollapseAll}
            onCellEditingStopped={handleCellEditingStopped}
            onColumnRowGroupChanged={handleColumnRowGroupChanged}
            onFilterChanged={handleFilterChanged}
            getContextMenuItems={getContextMenuItems}
            onGridReady={handleGridReady}
            getMainMenuItems={getMainMenuItems}
            getRowId={getRowNodeId}
            onRowDataUpdated={rowDataUpdated}
            onColumnStateChanged={onGridColumnStateChanged}
            columnDefs={mainPortfolioGridColumnDefs}
            columnTypes={mainPortfolioGridColumnTypes}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            alwaysShowVerticalScroll={false}
            rowMultiSelectWithClick={false}
            suppressRowClickSelection
            suppressAggFuncInHeader
            enableCellChangeFlash
            groupSelectsChildren
            groupSelectsFiltered
            suppressAutoSize
            deltaSort
            enableGroupEdit
            singleClickEdit
            {...generalSearchProps.gridProps}
            suppressRowGroupHidesColumns
            maintainColumnOrder
          />
          <ResizeOverTop
            right={rightSideWidth}
            max={rightSideMaxWidth}
            min={RIGHT_SIDE_MIN_WIDTH}
            onResizeStop={handleResize}
            onResizeInter={handleResizeInter}
            open={syncReceived && hasPinned}
            offset={rightSideMeasure.scrollBarWidth}
          />
        </VisibleIf>

        {!syncReceived && (
          <TableLoader fillArea data-e2e-id="portfolio-grid-loader" />
        )}
      </SizeGridWrapper>

      <ColumnChooserModal
        open={columnChooserState.open}
        onAccept={updateColumnChooser}
        onCancel={columnChooserState.closeModal}
        columns={columnChooserColumnsRef.current}
      />

      {RemoveSecurityModal.content}
    </MainPortfolioGridWrapper>
  );
};
