/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccordionListView } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { TutorialType } from "../../components/Tour/utils";
import GeneralFilesExplorer from "./GeneralFilesExplorer";

const createExplorer = (tutorial?: TutorialType | null) =>
  React.memo(function GeneralFileExplorer({ open }: { open: boolean }) {
    return <GeneralFilesExplorer open={open} tutorial={tutorial} />;
  });

const defaultOpen = { file: true };

const getItems = (tutorial?: TutorialType | null) => {
  const items = [
    {
      key: "file",
      title: "FILES",
      contentComponent: createExplorer(tutorial),
    },
  ];
  return items;
};

const GeneralFilesSidebarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const GeneralFilesSidebarTitle = styled.div`
  height: 40px;
  padding: 0 0.25rem 0 0.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const GeneralFilesSidebar: React.FC<{
  tutorial?: TutorialType | null;
}> = ({ tutorial }) => {
  const listItems = React.useMemo(() => getItems(tutorial), [tutorial]);

  return (
    <GeneralFilesSidebarContainer
      data-tutorial-step={
        tutorial ? `${tutorial}__general-files-sidebar` : undefined
      }
    >
      <GeneralFilesSidebarTitle>Files Explorer</GeneralFilesSidebarTitle>
      <AccordionListView defaultOpen={defaultOpen} items={listItems} />
    </GeneralFilesSidebarContainer>
  );
};

export default GeneralFilesSidebar;
