import { IconButton } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import {
  faChevronLeft,
  faChevronRight,
  faClose,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useToggle } from "react-use";

const SectionContainer = styled.div`
  position: relative;
  height: 100%;
  z-index: 999;
`;

const DrawerContainer = styled.div<{ open: boolean }>`
  width: 18.75rem;
  position: absolute;
  bottom: 4.5rem;
  right: ${({ open }) => (open ? "0" : "-18.75rem")};

  padding: var(--spacing-xl);
  box-shadow: -1px 1px 2px 0px var(--border);
  border-top-left-radius: var(--radius-l);
  border-bottom-left-radius: var(--radius-l);
  background-color: var(--background-color-0);
  transition: all 150ms ease-in;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
`;

const DrawerButton = styled.div`
  position: absolute;
  left: -3rem;
  width: 3rem;
  height: 6.25rem;
  bottom: 2rem;

  background-color: var(--background-color-0);
  box-shadow: -1px 1px 2px 0px var(--border);
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;

  cursor: pointer;
  transition: all 150ms ease-in;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const DrawerButtonText = styled.div`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
`;

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ScrollContainer = styled.div`
  max-height: 60vh;
  overflow: auto;
  padding-right: var(--spacing);
`;

export const AnalyticsPivotsDrawerContainer: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const [open, toggle] = useToggle(false);

  return (
    <SectionContainer>
      <DrawerContainer open={open}>
        <DrawerButton onClick={toggle}>
          <FontAwesomeIcon icon={open ? faChevronRight : faChevronLeft} />
          <DrawerButtonText>Pivots</DrawerButtonText>
        </DrawerButton>

        <Label>
          Pivots and Calculation
          <IconButton icon={faClose} onClick={toggle} />
        </Label>

        <ScrollContainer>{children}</ScrollContainer>
      </DrawerContainer>
    </SectionContainer>
  );
};
