import {
  ComplianceStateSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import ComplianceStatesSelect from "./ComplianceStatesSelect";

const ComplianceStatesSelectionWidget = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, key } = config as ComplianceStateSelectionWidgetConfig;

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Compliance States",
        type: ChannelDataType.String,
      },
    ]);
  }, [addChannelKeys, key]);

  React.useEffect(() => {
    setChannelData([defaultValue]);
  }, [setChannelData, defaultValue]);

  return (
    <CenterContent>
      <ComplianceStatesSelect
        label={key}
        value={defaultValue}
        onChange={(ids: string[] | null) => setChannelData([ids])}
      />
    </CenterContent>
  );
};

export default ComplianceStatesSelectionWidget;
