import { BlotterColumnKey, OrderColumnInfo } from "@enfusion-ui/core";
import { SortOrder, WebColumnPin } from "@enfusion-ui/types";
import { ColumnState } from "ag-grid-enterprise";

export default function columnStateToOrderColumnInfo(
  currentState?: ColumnState[]
): OrderColumnInfo[] {
  return (
    currentState?.reduce((res, state) => {
      if (!state.rowGroup && !state.pivot && state.hide && !!state.colId)
        return res;
      const colDef: OrderColumnInfo = {
        key: state.colId as BlotterColumnKey,
        pinned:
          state.pinned === "left"
            ? ("Left" as WebColumnPin)
            : state.pinned === "right"
            ? ("Right" as WebColumnPin)
            : ("None" as WebColumnPin),
        sortOrder:
          state.sort === "asc"
            ? ("Ascending" as SortOrder)
            : state.sort === "desc"
            ? ("Decending" as SortOrder)
            : ("None" as SortOrder),
        width: state.width,
      };
      if (typeof state.hide === "boolean") {
        colDef.hide = state.hide;
      }
      if (typeof state.rowGroupIndex === "number") {
        colDef.group = state.rowGroupIndex;
      }
      if (typeof state.sortIndex === "number") {
        colDef.sortIndex = state.sortIndex;
      }
      return [...res, colDef];
    }, [] as OrderColumnInfo[]) || []
  );
}
