import { ControlledFinancialSubTypeSelect } from "@app-views/reports/components/ReportQueryComponents/FinancialSubTypeSelect";
import { ControlledMarketEnvironmentSelect } from "@app-views/reports/components/ReportQueryComponents/MarketEnvironmentSelect";
import { ControlledMaxResultsInput } from "@app-views/reports/components/ReportQueryComponents/MaxResultsInput";
import {
  ControlledCheckbox,
  ControlledTextInput,
  PanelRow,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";

const StyledPanelRow = styled(PanelRow)`
  margin-top: 1%;
  margin-bottom: 1%;
`;

const SecurityMasterSettings = () => {
  return (
    <>
      <ControlledFinancialSubTypeSelect
        name="financialSubTypes"
        selectProps={{ label: "Instrument type" }}
      />
      <StyledPanelRow>
        <ControlledTextInput
          name="freeForm"
          label="Search terms"
          defaultValue=""
        />
        <ControlledMaxResultsInput label="Search Limit" />
      </StyledPanelRow>
      <StyledPanelRow>
        <ControlledCheckbox
          name="primaryOnly"
          label="Primary Only"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
        <ControlledCheckbox
          name="ignoreInactive"
          label="Ignore Inactive"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
        <ControlledCheckbox
          name="restrictedOnly"
          label="Only Restricted"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </StyledPanelRow>
      <ControlledMarketEnvironmentSelect label="Market Environemnt" />
    </>
  );
};

export default SecurityMasterSettings;
