import { defaultDashboardConfigV2 } from "@enfusion-ui/dashboards";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { Blank, FixedHeader, Simplified } from "./templateGraphics";

export type DashboardTemplateKey = "blank" | "simplified" | "fixedHeader";

export const DEFAULT_TEMPLATE = "blank";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dashboardTemplates: Record<DashboardTemplateKey, any> = {
  blank: defaultDashboardConfigV2,
  simplified: defaultDashboardConfigV2,
  fixedHeader: defaultDashboardConfigV2,
};

type TemplateSelectProps = {
  onChange: (templateKey: DashboardTemplateKey) => void;
  value: string;
};

type TemplateOption = {
  label: string;
  value: DashboardTemplateKey;
  graphic: React.FunctionComponent;
};

const options: TemplateOption[] = [
  {
    label: "Simplified",
    value: "simplified",
    graphic: Simplified,
  },
  { label: "Fixed Header", value: "fixedHeader", graphic: FixedHeader },
  { label: "Blank", value: "blank", graphic: Blank },
];

const Title = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const Options = styled.div`
  display: flex;
  margin: 2rem;
  gap: var(--spacing-l);
`;

const Option = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
  background-color: var(--background-color-1);
  border: ${({ active }) =>
    active ? "1px solid var(--primary)" : "1px solid transparent"};
  border-radius: var(--radius);
  padding: var(--spacing-l);
`;

const RadioOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: var(--spacing-s);

  input {
    accent-color: var(--primary);
    cursor: pointer;
  }
`;

const DashboardTemplateSelect: React.FC<TemplateSelectProps> = ({
  onChange,
  value,
}) =>
  true ? ( // TODO implement templates
    <></>
  ) : (
    <div>
      <Title>Choose a template</Title>
      <Options>
        {options.map((option: TemplateOption) => {
          const { graphic: Graphic } = option;
          return (
            <Option
              key={option.label + option.value}
              onClick={() => onChange(option.value)}
              active={value === option.value}
            >
              <Graphic />
              <RadioOption>
                <span style={{ fontSize: "small" }}>{option.label}</span>
                <input
                  type="radio"
                  value={option.value}
                  checked={value === option.value}
                  onChange={() => {
                    onChange(option.value);
                  }}
                  style={{ marginRight: "3px" }}
                />
              </RadioOption>
            </Option>
          );
        })}
      </Options>
    </div>
  );

export default DashboardTemplateSelect;
