import { View } from "@react-pdf/renderer";
import * as React from "react";

import { isOddNumber, WarningFlagsData } from "./utils";

export const CellFlags: React.FC<{
  valueObject: WarningFlagsData;
  pdfReportWidgetStyles: any;
  indexNumber: number;
}> = ({ valueObject, pdfReportWidgetStyles, indexNumber }) => {
  if (!valueObject?.warningCell && !valueObject?.errorCell) return null;
  const baseColor =
    pdfReportWidgetStyles[isOddNumber(indexNumber) ? "oddRow" : "evenRow"]
      .backgroundColor;
  return (
    <View
      style={[
        pdfReportWidgetStyles.warningCellBase,
        {
          borderTopColor: baseColor,
          borderBottomColor: baseColor,
          borderRightColor:
            pdfReportWidgetStyles[
              valueObject?.errorCell ? "rowTextDangerColor" : "borderWarning"
            ].color,
        },
      ]}
    ></View>
  );
};
