import {
  HorizontallyResizable,
  HorizontallyResizableProps,
} from "@enfusion-ui/web-components";
import { styled, useThisTab } from "@enfusion-ui/web-core";
import * as React from "react";

import {
  ANALYTICS_CONTENT__MIN_WIDTH,
  ANALYTICS_PIVOTS_DEFAULT_WIDTH,
  ANALYTICS_PIVOTS_MIN_WIDTH,
} from "../utils";

const SectionContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ContentContainer = styled.div`
  min-height: 100%;
  background-color: var(--background-color-0);
  padding: var(--spacing-xl);
  border-radius: var(--radius);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
`;

export const AnalyticsPivotsResizableContainer: React.FC<
  React.PropsWithChildren<{
    onResizeCallBack: HorizontallyResizableProps["onResizeStop"];
  }>
> = ({ children, onResizeCallBack }) => {
  const { width } = useThisTab();

  const maxWidth = React.useMemo(
    () => (width ? width - ANALYTICS_CONTENT__MIN_WIDTH : undefined),
    [width]
  );

  return (
    <HorizontallyResizable
      open
      scope="tab"
      maxWidth={maxWidth}
      onResizeStop={onResizeCallBack}
      minWidth={ANALYTICS_PIVOTS_MIN_WIDTH}
      width={ANALYTICS_PIVOTS_DEFAULT_WIDTH}
    >
      <SectionContainer>
        <ContentContainer>
          <div>Pivots and Calculations</div>
          {children}
        </ContentContainer>
      </SectionContainer>
    </HorizontallyResizable>
  );
};
