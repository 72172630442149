import { TradeTicketFormTypes, useTradeTicket } from "@enfusion-ui/trades";

import { TradeInstrumentSelect } from "./TradeInstrumentSelect";

export function TradeInstrument() {
  const { formDataType } = useTradeTicket();
  switch (formDataType) {
    case TradeTicketFormTypes.EQUITY_FLEX_OPTION:
    case TradeTicketFormTypes.OTC_REALIZED_VARIANCE_OPTION:
    case TradeTicketFormTypes.OTC_OPTION:
    case TradeTicketFormTypes.VARIANCE_SWAP:
    case TradeTicketFormTypes.DIVIDEND_SWAP:
      return <TradeInstrumentSelect label="Underlying" />;
    case TradeTicketFormTypes.NEW_DUAL_DIGITAL_OPTION:
    case TradeTicketFormTypes.OTC_SPREAD_OPTIONS:
    case TradeTicketFormTypes.OTC_OUTPERFORMANCE_OPTION:
      return (
        <>
          <TradeInstrumentSelect label="Underlying 1" />
          <TradeInstrumentSelect label="Underlying 2" name="underlyingTwo" />
        </>
      );
    case TradeTicketFormTypes.ASSET_SWAP_CONVERTIBLE_OPTION:
      return <TradeInstrumentSelect label="Trade Instrument Bond" />;
    default:
      return <TradeInstrumentSelect />;
  }
}
