import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { ConnectionStatus, Quote } from "@enfusion-ui/types";
import { useWorkerModule } from "@enfusion-ui/web-workers";
import * as React from "react";

import {
  ConnectionType,
  useConnectionStatus,
} from "../connectionStatus/context";
import { eventType, MarketDataContext, UpdateHandlerFunction } from "./context";

const MarketDataProvider: React.FC<
  React.PropsWithChildren<{
    enabled: boolean;
  }>
> = React.memo(({ children, enabled }) => {
  const isMounted = useMounted();
  const {
    enableModule,
    disableModule,
    subscribeToModule,
    postMessage,
    getCurrentState,
  } = useWorkerModule("marketData");
  const subscriptionStoreRef = React.useRef<{
    [instrumentId: string]: UpdateHandlerFunction[];
  }>({});
  const quoteStoreRef = React.useRef<{
    [instrumentId: string]: Quote;
  }>({});
  const { updateStatus } = useConnectionStatus();

  React.useEffect(() => {
    subscribeToModule((message: Record<string, unknown>) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { payload, type } = message as { type: string; payload: any };

      switch (type) {
        case eventType.connected: {
          updateStatus(ConnectionType.MarketData, ConnectionStatus.CONNECTED);
          break;
        }
        case eventType.marketDataUpdate: {
          quoteStoreRef.current[payload.instrumentId] = {
            ...payload.quote,
            instrumentId: payload.instrumentId,
          };
          subscriptionStoreRef.current[payload.instrumentId]?.forEach((cb) =>
            cb(quoteStoreRef.current[payload.instrumentId], () =>
              unsubscribeFromMarketData(payload.instrumentId, cb)
            )
          );
          break;
        }
        case eventType.error: {
          updateStatus(ConnectionType.MarketData, ConnectionStatus.ERROR);
          break;
        }
        default:
          break;
      }
    });
  }, []);

  React.useEffect(() => {
    if (enabled && isMounted()) {
      enableModule();
      getCurrentState()?.then(({ payload }) => {
        if (payload.socketStatus === WebSocket.OPEN) {
          updateStatus(ConnectionType.MarketData, ConnectionStatus.CONNECTED);
        }
      });
    }

    return () => {
      disableModule();
      updateStatus(ConnectionType.MarketData, ConnectionStatus.DISCONNECTED);
    };
  }, [enabled]);

  const unsubscribeFromMarketData = useRefCallback(
    (instrumentId: number, updateHandler: UpdateHandlerFunction) => {
      if (subscriptionStoreRef.current[instrumentId]) {
        const newSubscriptions = subscriptionStoreRef.current[
          instrumentId
        ].filter((cb) => cb !== updateHandler);

        if (newSubscriptions.length === 0) {
          delete subscriptionStoreRef.current[instrumentId];
          postMessage({
            type: eventType.unsubscribeFromMarketData,
            payload: { instrumentId },
          });
        } else {
          subscriptionStoreRef.current[instrumentId] = newSubscriptions;
        }
      }
    },
    [postMessage]
  );

  const subscribeToMarketData = useRefCallback(
    (instrumentId: number, updateHandler: UpdateHandlerFunction) => {
      if (!subscriptionStoreRef.current[instrumentId]) {
        subscriptionStoreRef.current[instrumentId] = [updateHandler];
        postMessage({
          type: eventType.subscribeToMarketData,
          payload: { instrumentId },
        });
      } else if (
        !subscriptionStoreRef.current[instrumentId].includes(updateHandler)
      ) {
        subscriptionStoreRef.current[instrumentId].push(updateHandler);
        updateHandler(quoteStoreRef.current[instrumentId], () =>
          unsubscribeFromMarketData(instrumentId, updateHandler)
        );
      }
      return () => unsubscribeFromMarketData(instrumentId, updateHandler);
    },
    [postMessage, unsubscribeFromMarketData]
  );

  const value = React.useMemo(
    () => ({ subscribeToMarketData }),
    [subscribeToMarketData]
  );

  return (
    <MarketDataContext.Provider value={value}>
      {children}
    </MarketDataContext.Provider>
  );
});

export default MarketDataProvider;
