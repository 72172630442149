import { useRefCallback } from "@enfusion-ui/hooks";
import {
  ColumnGrid,
  FormContainer,
  IconButton,
} from "@enfusion-ui/web-components";
import { css, styled, useIsContentMobile } from "@enfusion-ui/web-core";
import { faRedoAlt } from "@fortawesome/pro-solid-svg-icons";
import { RowClickedEvent, RowDataUpdatedEvent } from "ag-grid-community";
import * as React from "react";

import useRoutes from "./behavior/useRoutes";
import FillsList, { FillsListApi } from "./FillsList";
import RoutesList from "./RoutesList";

const RoutesContainer = styled(ColumnGrid)`
  width: 100%;
  height: 100%;
  min-height: 200px;
`;

const BlotterTitle = styled.div`
  font-size: small;
  width: 100%;
  padding-top: var(--spacing-s);
`;

const BlotterContainerBase = styled.div<{ mobile: boolean }>`
  display: flex;
  flex-flow: column;
  flex: 1;
  min-height: 200px;
  height: ${({ mobile }) => (mobile ? "50%" : "100%")};
`;

const RefreshActionContainer = styled.div<{ location: "inline" | "above" }>`
  position: absolute;
  height: 23px;
  ${({ location }) =>
    location === "above"
      ? css`
          right: 0;
          margin-top: -27px;
        `
      : css`
          right: var(--spacing);
          margin-top: var(--spacing-l);
        `}
`;

function BlotterContainer({
  children,
  title,
  isMobile,
}: {
  title: string;
  isMobile: boolean;
  children: React.ReactNode;
}) {
  return (
    <BlotterContainerBase mobile={isMobile}>
      <BlotterTitle>{title}</BlotterTitle>
      {children}
    </BlotterContainerBase>
  );
}

type RouteAndFillsListProps = {
  allowRefresh?: "inline" | "above";
  padding?: boolean;
};

function RoutesAndFillsList({ allowRefresh, padding }: RouteAndFillsListProps) {
  const { selectedRouteOrderId, setSelectedRouteOrderId, refetch } =
    useRoutes();
  const fillsListRef = React.useRef<FillsListApi | null>(null);
  const isMobile = useIsContentMobile();

  const handleRouteOrderRowClicked = useRefCallback(
    ({ data }: RowClickedEvent) => {
      setSelectedRouteOrderId(data.orderId);
    },
    [setSelectedRouteOrderId]
  );

  const handleRowDataUpdated = useRefCallback(
    ({ api }: RowDataUpdatedEvent) => {
      if (api.getSelectedNodes().length === 0) {
        const firstRowNode = api.getDisplayedRowAtIndex(0);
        if (firstRowNode) {
          firstRowNode.setSelected(true, true);
          setSelectedRouteOrderId(firstRowNode.data.orderId);
        }
      }
    },
    [setSelectedRouteOrderId]
  );

  const handleRefresh = useRefCallback(() => {
    refetch();
    fillsListRef.current?.refetch();
  }, [refetch, fillsListRef]);

  return (
    <>
      {allowRefresh && (
        <RefreshActionContainer location={allowRefresh}>
          <IconButton
            hoverable
            title="Refresh"
            icon={faRedoAlt}
            onClick={handleRefresh}
          />
        </RefreshActionContainer>
      )}
      <FormContainer
        style={padding ? { paddingTop: 0, paddingBottom: 0 } : { padding: 0 }}
      >
        <RoutesContainer isMobile={isMobile} numColumns={2} gapSize="m">
          <BlotterContainer title="Routes" isMobile={isMobile}>
            <RoutesList
              height="100%"
              onRowDataUpdated={handleRowDataUpdated}
              onRowClicked={handleRouteOrderRowClicked}
            />
          </BlotterContainer>

          <BlotterContainer title="Fills" isMobile={isMobile}>
            <FillsList
              height="100%"
              ref={fillsListRef}
              routeOrderId={selectedRouteOrderId}
            />
          </BlotterContainer>
        </RoutesContainer>
      </FormContainer>
    </>
  );
}

export default RoutesAndFillsList;
