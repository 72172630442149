/* eslint-disable @typescript-eslint/no-explicit-any */
import { EMPTY_RECORD } from "@enfusion-ui/core";
import { formatReportValue } from "@enfusion-ui/utils";
import HighchartsBase from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as React from "react";

import { SunburstEntry } from "./utils";

export type CurrencyPointChartProps = {
  title: string;
  width: number;
  height: number;
  onLoad: VoidFunction;
};

export type CurrencyPointChartRef = {
  setData: (rows: SunburstEntry[]) => void;
  chart: HighchartsReact.RefObject | null;
};

export const createCurrencyPointChart = (
  highcharts: typeof HighchartsBase,
  series: Record<string, any>,
  extraOptions: Record<string, any> = EMPTY_RECORD
) => {
  const CurrencyPointChart = React.forwardRef<
    CurrencyPointChartRef,
    CurrencyPointChartProps
  >(({ title, onLoad, width, height }, ref) => {
    const [data, setData] = React.useState<SunburstEntry[]>([]);
    const innerRef = React.useRef<HighchartsReact.RefObject>(null);

    React.useImperativeHandle(ref, () => ({
      setData: (rows) => {
        setData(rows);
      },
      chart: innerRef.current,
    }));

    const options = React.useMemo(() => {
      return {
        chart: {
          height,
          width,
          backgroundColor: "var(--background-color-1)",
          borderWidth: 0,
          events: { load: onLoad },
          animation: false,
        },
        title: {
          text: title,
          style: { color: "var(--text-normal)" },
        },
        tooltip: {
          headerFormat: "",
          formatter: function (): any {
            const self: any = this as unknown;
            return `<b>${self.key}</b>: ${formatReportValue(
              {
                value: self.point.value,
                currency: "USD",
              },
              { dataType: "Double", numberStyle: "Currency" } as any
            )}`;
          },
        },
        credits: { enabled: false },

        series: [
          {
            ...series,
            data,
            name: "Root",
            allowDrillToNode: true,
            animationLimit: 1000,
            borderRadius: 3,
            borderColor: "var(--background-color-1)",
            cursor: "pointer",
            accessibility: { exposeAsGroupOnly: true },
          },
        ],

        ...extraOptions,
      };
    }, [height, width, onLoad, JSON.stringify(data)]);

    return (
      <div
        style={{
          height,
          width,
        }}
      >
        <HighchartsReact
          highcharts={highcharts}
          ref={innerRef}
          options={options}
        />
      </div>
    );
  });

  return CurrencyPointChart;
};
