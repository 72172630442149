import { Button, ButtonProps } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faFilter, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

const StyledButton = styled(Button)<{ isFiltered: boolean }>`
  &&& {
    height: 38px;
    color: ${({ isFiltered }) => {
      return isFiltered ? "var(--primary)" : "var(--text-normal)";
    }};
  }
`;

export const FilterButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & { isFiltered: boolean; icon?: IconDefinition }
>(function FilterButton(props, ref) {
  const { icon, ...rest } = props;
  return (
    <StyledButton ref={ref} {...rest}>
      <FontAwesomeIcon icon={icon ? icon : faFilter} />
    </StyledButton>
  );
});
