import { ComplianceState } from "@enfusion-ui/types";
import { styled } from "@enfusion-ui/web-core";
import {
  faBinoculars,
  faCheckDouble,
  faClock,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICellRendererParams } from "ag-grid-enterprise";
import * as React from "react";

const ComplianceIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MessageText = styled.span`
  margin-left: var(--spacing-l);
`;

export const getComplianceIcon = (
  state?: ComplianceState,
  fullMsg = false,
  modifyColor: (color: string) => string = (i) => i
) => {
  let icon = faInfoCircle;
  let color = "text-normal";
  let message = "Working";

  switch (state) {
    case "Disabled":
      icon = faSlash;
      color = "text-muted";
      message = "Disabled";
      break;

    case "Failed":
      icon = faExclamationCircle;
      color = "danger";
      message = "Failed";
      break;

    case "OverrideNotAllowed":
      icon = faExclamationCircle;
      color = "danger";
      message = fullMsg ? "Failed - Override Not Allowed" : "Failed";
      break;

    case "Passed":
      icon = faCheckDouble;
      color = "success";
      message = "Passed";
      break;

    case "PendingCheck":
      icon = faClock;
      message = "Pending";
      break;

    case "Warning":
      icon = faExclamationTriangle;
      color = "warning";
      message = "Warning";
      break;

    case "WarningWithNote":
      icon = faExclamationTriangle;
      color = "warning";
      message = fullMsg ? "Warning - Notes Needed" : "Warning";
      break;

    case "Tracking":
      icon = faBinoculars;
      message = "Tracking";
      break;

    default:
      break;
  }

  return { icon, color: modifyColor(color), message };
};

export default function ComplianceStateCellRenderer(
  props: ICellRendererParams & {
    defaultState?: ComplianceState;
    allowGroup?: boolean;
  }
) {
  const { value, defaultState } = props;
  let state = value || defaultState;
  const { icon, color, message } = getComplianceIcon(
    state,
    false,
    (c) => `var(--${c})`
  );

  if (!props.allowGroup && props.node.group) {
    return <>{props.value !== null ? message : null}</>;
  }

  return (
    <ComplianceIconContainer>
      <FontAwesomeIcon icon={icon} color={color} />
      <MessageText style={{ color }}>{message}</MessageText>
    </ComplianceIconContainer>
  );
}
