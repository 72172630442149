import { RoundedDataGrid } from "@app-components/DataGrid";
import {
  listCellFormatter,
  orderDetailsCellStyle,
  useGridApi,
} from "@enfusion-ui/web-core";
import { ColDef, GetRowIdParams, GridOptions } from "ag-grid-community";
import React, { useImperativeHandle } from "react";

import { fillsMetaData, getGridColumnTypes } from "./behavior/columnsMetadata";
import useFillsList from "./behavior/useFillsList";

export type FillsListProps = {
  routeOrderId: number | undefined;
  height?: number | string;
} & GridOptions;

const metadata = fillsMetaData;
const columnDefs: ColDef[] = Object.entries(metadata).map(([key, value]) => {
  return {
    headerName: value.name,
    field: key === "execDate" || key === "execTime" ? "execDateTime" : key,
    colId: key,
    type: getGridColumnTypes(value.columnTypeCategory),
    sort: key === "tradeDate" ? "desc" : undefined,
    filter: true,
    enableRowGroup: value.columnTypeCategory === "Number" ? false : true,
  };
});

export type FillsListApi = { refetch: () => Promise<void> };

const FillsList = React.forwardRef<FillsListApi, FillsListProps>(
  ({ routeOrderId, height, ...rest }, ref) => {
    const { fills, loading, refetch } = useFillsList(routeOrderId);
    const { gridApiRef, onGridReady } = useGridApi();

    React.useEffect(() => {
      if (loading) gridApiRef.current?.api.showLoadingOverlay();
      else if (fills.length === 0) gridApiRef.current?.api.showNoRowsOverlay();
      else gridApiRef.current?.api.hideOverlay();
    }, [loading, fills]);

    useImperativeHandle(ref, () => ({ refetch }), [refetch]);

    return (
      <>
        <RoundedDataGrid
          onGridReady={onGridReady}
          height={height}
          suppressCellFocus
          suppressRowGroupHidesColumns
          rowSelection="single"
          multiSortKey="ctrl"
          rowGroupPanelShow="always"
          groupDefaultExpanded={-1}
          context={{ metadata }}
          columnDefs={columnDefs}
          rowData={fills ?? []}
          getRowId={(params: GetRowIdParams) => params.data?.execID}
          defaultColDef={{
            resizable: true,
            sortable: true,
            suppressKeyboardEvent: () => true,
            valueFormatter: listCellFormatter,
            cellStyle: orderDetailsCellStyle,
          }}
          gridName="fills"
          {...rest}
        />
      </>
    );
  }
);

export default FillsList;
