import {
  TabTopActionBar,
  ViewContainer,
  ViewContent,
} from "@enfusion-ui/web-components";
import * as React from "react";

import OrderTicket from "./OrderTicket/OrderTicket";

type OrderViewProps = {
  config: {
    orderId: number;
    completed?: boolean;
    autoFocus?: string;
    splitOrder?: boolean;
    submissionStatus?: string;
    custom?: boolean;
  };
};

const OrderView: React.FC<OrderViewProps> = ({ config }) => {
  return (
    <ViewContainer>
      <TabTopActionBar thin></TabTopActionBar>
      <ViewContent style={{ borderTopWidth: 0 }}>
        <OrderTicket {...config} />
      </ViewContent>
    </ViewContainer>
  );
};

export default OrderView;
