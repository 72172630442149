import { ThemeDefinition } from "@enfusion-ui/core";
import {
  BREAK_POINTS_MAP_V2,
  CELL_SIZE,
  useDashboard,
} from "@enfusion-ui/dashboards";
import { DashboardOrientation } from "@enfusion-ui/types";
import {
  CenterContent,
  ContentMessage,
  Spinner,
} from "@enfusion-ui/web-components";
import {
  styled,
  ThemeProviderBase,
  useAuth,
  useThisTab,
} from "@enfusion-ui/web-core";
import { Document, Font, PDFViewer, StyleSheet } from "@react-pdf/renderer";
import * as React from "react";

import { PDFAsyncContext, PDFAsyncProvider, usePDFAsyncState } from "./context";
import { PDFContextChild, PDFContextParent } from "./ContextPassThrough";
import { PDFPage } from "./PDFPage";
import { createPageLayouts, PAPER_WIDTH, usePDFGridSizing } from "./utils";

type DashboardPdfPreviewProps = {};

const fontEntry = (
  src: string,
  fontWeight: any = "normal",
  fontStyle = "normal"
) => ({ src: `/fonts/${src}`, fontWeight, fontStyle });

const fontEntryItalic = (src: string, fontWeight?: string) =>
  fontEntry(src, fontWeight, "italic");

Font.register({
  family: "Lato",
  fonts: [
    fontEntry("Lato/Lato-Regular.ttf"),
    fontEntry("Lato/Lato-Bold.ttf", "bold"),
    fontEntry("Lato/Lato-Black.ttf", "ultrabold"),
    fontEntry("Lato/Lato-Light.ttf", "light"),
    fontEntry("Lato/Lato-Thin.ttf", "thin"),
    fontEntryItalic("Lato/Lato-Italic.ttf"),
    fontEntryItalic("Lato/Lato-BoldItalic.ttf", "bold"),
    fontEntryItalic("Lato/Lato-BlackItalic.ttf", "ultrabold"),
    fontEntryItalic("Lato/Lato-LightItalic.ttf", "light"),
    fontEntryItalic("Lato/Lato-ThinItalic.ttf", "thin"),
  ],
});

const pdfStyles = StyleSheet.create({
  pdfViewer: {
    height: "100%",
    width: "100%",
    border: "none",
  },
});

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--overlay);
`;

const LoadingOverlay: React.FC<unknown> = () => {
  return (
    <Overlay>
      <CenterContent>
        <Spinner />
      </CenterContent>
    </Overlay>
  );
};

const DashboardPdfPreviewCore: React.FC<
  React.PropsWithChildren<{
    id: string;
    name: string;
    author: string;
    theme: ThemeDefinition;
  }>
> = React.memo(({ id, name, author, theme, children }) => {
  const pdfAsyncContext = usePDFAsyncState();

  return (
    <>
      <PDFContextParent
        id={id}
        complete={
          typeof pdfAsyncContext.loading === "boolean"
            ? !pdfAsyncContext.loading
            : false
        }
      >
        <PDFViewer
          key={pdfAsyncContext.renderKey}
          showToolbar
          style={pdfStyles.pdfViewer}
        >
          <PDFAsyncContext.Provider value={pdfAsyncContext}>
            <ThemeProviderBase theme={theme}>
              <Document
                title={name}
                author={author}
                creator="Enfusion"
                producer="Enfusion"
              >
                <PDFContextChild id={id}>{children}</PDFContextChild>
              </Document>
            </ThemeProviderBase>
          </PDFAsyncContext.Provider>
        </PDFViewer>
      </PDFContextParent>
      {pdfAsyncContext.loading && <LoadingOverlay />}
    </>
  );
});

export const DashboardPdfPreview: React.FC<DashboardPdfPreviewProps> = () => {
  const { id } = useThisTab();
  const { config, orientation, loading, name, theme } = useDashboard();
  const { user } = useAuth();

  const { breakpointKey, sizing } = usePDFGridSizing(config?.main, orientation);

  const [colWidth, colCount, pageRowCount] = React.useMemo(() => {
    const count = BREAK_POINTS_MAP_V2[breakpointKey];
    const pageHeight =
      PAPER_WIDTH[orientation === "portrait" ? "landscape" : "portrait"];
    const pageRowCount = Math.floor(pageHeight / CELL_SIZE);
    return [
      PAPER_WIDTH[orientation as DashboardOrientation] / count,
      count,
      pageRowCount,
    ];
  }, [breakpointKey, orientation]);

  const layout = React.useMemo(
    () =>
      createPageLayouts(
        sizing?.dimensions?.main?.layout[breakpointKey],
        pageRowCount
      ),
    [breakpointKey, sizing?.dimensions?.main, pageRowCount]
  );

  const content = React.useMemo(() => {
    if (!layout || loading) return null;
    return layout.map((entry, pageIndex) => (
      <PDFPage
        key={pageIndex}
        index={pageIndex}
        orientation={orientation}
        widgets={config.main.widgets}
        colWidth={colWidth}
        colCount={colCount}
        pageRowCount={pageRowCount}
        layout={entry}
        theme={theme}
      />
    ));
  }, [
    loading,
    layout,
    orientation,
    colWidth,
    colCount,
    pageRowCount,
    config?.main.widgets,
    theme,
  ]);

  if (loading) {
    return (
      <CenterContent>
        <Spinner />
      </CenterContent>
    );
  }

  if (!layout) {
    return (
      <CenterContent>
        <ContentMessage message="No useable layout" />
      </CenterContent>
    );
  }

  return (
    <PDFAsyncProvider>
      <DashboardPdfPreviewCore
        id={id}
        name={name}
        author={user?.username || "Enfusion"}
        theme={theme}
      >
        {content}
      </DashboardPdfPreviewCore>
    </PDFAsyncProvider>
  );
};
