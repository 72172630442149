import {
  SourceIdsSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { FormSectionAccordion, TextInput } from "@enfusion-ui/web-components";
import * as React from "react";

import { SourceIdsSelect } from "../../../../../reports/components/ReportQueryComponents/SourceIdsSelect";

const SourceIdsSelectionEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange = (key: string) => (sourceIds: number[] | null) => {
    changeConfigKeyToBeApplied(key, sourceIds?.map((val) => `${val}`) ?? []);
  };

  const { defaultValue = null, key = "Source Ids" } =
    editedConfig as SourceIdsSelectionWidgetConfig;

  return (
    <FormSectionAccordion title="Source Options">
      <TextInput
        label="Key"
        value={key}
        name="key"
        onChange={handleTextChange("key")}
      />
      <SourceIdsSelect
        value={defaultValue?.map((val) => Number(val))}
        onChange={handleBasicChange("defaultValue")}
        label="Default Source"
      />
    </FormSectionAccordion>
  );
};

export default SourceIdsSelectionEditWidget;
