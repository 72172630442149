import { AppEvent, AppEventCategories } from "@enfusion-ui/types";
import { TabTopActionBar } from "@enfusion-ui/web-components";
import { AppLogging, gaModalView, styled } from "@enfusion-ui/web-core";
import * as React from "react";

const ServicesMarketingViewContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ServiceFileActionBar = styled(TabTopActionBar)`
  justify-content: flex-end;
`;

const ServicesMarketingView: React.FC<unknown> = () => {
  React.useEffect(() => {
    gaModalView("/services/marketing");
    AppLogging.event({
      event: AppEvent.ServicesMarketingOpened,
      category: AppEventCategories.Services,
    });
  }, []);

  return (
    <ServicesMarketingViewContainer>
      <ServiceFileActionBar></ServiceFileActionBar>
      <iframe
        title="About Services"
        src="https://enfusion.com/strategic-services/"
        frameBorder={0}
        style={{
          width: "100%",
          height: "100%",
          border: "1px solid var(--input-border)",
          background: "white",
        }}
      />
    </ServicesMarketingViewContainer>
  );
};

export default ServicesMarketingView;
