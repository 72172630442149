import { WidgetComponentsDefinition } from "../../../types";
import PdfQuoteSetIdsSelectionWidget from "./PdfQuoteSetIdsSelectionWidget";
import QuoteSetIdsSelectionEditWidget from "./QuoteSetIdsSelectionEditWidget";
import QuoteSetIdsSelectionWidget from "./QuoteSetIdsSelectionWidget";

export const quoteSetIdsSelection: WidgetComponentsDefinition = {
  renderComponent: QuoteSetIdsSelectionWidget,
  editComponent: QuoteSetIdsSelectionEditWidget,
  renderPdfComponent: PdfQuoteSetIdsSelectionWidget,
};
