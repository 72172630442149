import {
  accrualFrequencyOptions,
  accrualMethodOptions,
  businessDayConventionOptions,
  CompoundingFrequencyValues,
  PaymentTypeValues,
  TradeTicketFormTypes,
  useTradeTicket,
} from "@enfusion-ui/trades";
import { createSelectOptions } from "@enfusion-ui/utils";
import {
  ControlledDatePicker,
  ControlledNumericInput,
  ControlledSelect,
  FormElementHeader,
  FormPanel,
  PanelRow,
} from "@enfusion-ui/web-components";
import * as React from "react";

import { AmortizingSchedule } from "./AmortizingSchedule";
import { PaymentSection } from "./PaymentSection";

const paymentTypeOptions = createSelectOptions(PaymentTypeValues);
const compoundingFrequencyOptions = createSelectOptions(
  CompoundingFrequencyValues
);

export const FinancingPanel: React.FC<unknown> = () => {
  const { formDataType } = useTradeTicket();
  return (
    <FormPanel
      lineStyle
      collapsible
      defaultOpen
      keepRendered
      numColumns={1}
      defaultRendered
      title="Financing"
      tabFocusOutline={false}
    >
      <FormElementHeader style={{ margin: "var(--spacing-l) 0" }}>
        Payments
      </FormElementHeader>
      <PanelRow>
        <ControlledSelect
          required
          name="paymentType"
          label="Payment Type"
          options={paymentTypeOptions}
        />
        <ControlledSelect
          required
          name="paymentFrequency"
          label="Payment Frequency"
          options={accrualFrequencyOptions}
        />
      </PanelRow>
      <PanelRow>
        <ControlledSelect
          required
          name="accrualMethod"
          label="Accrual Method"
          options={accrualMethodOptions}
        />
        <ControlledSelect
          required
          name="paymentBDC"
          label="Payment BDC"
          options={businessDayConventionOptions}
        />
      </PanelRow>
      <PanelRow>
        <ControlledDatePicker
          name="firstPaymentDate"
          label="First Payment Date"
        />
        <ControlledDatePicker name="lastPayment" label="Last Payment" />
      </PanelRow>
      <PanelRow>
        <ControlledSelect
          name="compoundingFrequency"
          label="Compounding Frequency"
          options={compoundingFrequencyOptions}
        />
        <ControlledNumericInput
          name="settleAdjustmentDays"
          label="Settle Adj. Days"
        />
      </PanelRow>
      <PaymentSection />
      {formDataType === TradeTicketFormTypes.STOCK_LOAN_BORROW && (
        <AmortizingSchedule />
      )}
    </FormPanel>
  );
};
