import { ICellRendererParams } from "ag-grid-enterprise";
import * as React from "react";

import LoadingBar from "../../../components/display/LoadingBar";

const QuoteCellRenderer: React.FC<ICellRendererParams> = React.memo(
  ({ value, data }) => {
    if (data.quoteType.percent) {
      return (
        <LoadingBar
          style={{
            width: 100,
          }}
          rounded
          percent={Number(value) * 100}
          text={`${(Number(value) * 100).toString()}%`}
          backgroundColor="transparent"
        />
      );
    }
    return value;
  }
);

QuoteCellRenderer.displayName = "QuoteCellRenderer";
export default QuoteCellRenderer;
