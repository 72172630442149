import { ThemeDefinition } from "@enfusion-ui/core";
import { WebReportQuery } from "@enfusion-ui/types";
import {
  Button,
  ControlledCheckbox,
  ControlledSelect,
  FormPanel,
  FormSectionAccordion,
  Modal,
  PanelRow,
  RadioGroup,
  Spinner,
} from "@enfusion-ui/web-components";
import { REST_API, styled, useTheme } from "@enfusion-ui/web-core";
import { faFileImage, faFilter } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { InputGroup, InputWrapper } from "../../dashboards/widgets/styles";
import { LabelText } from "../../watchList/components/styles";
import GroupingTemplateSelect from "../components/ReportQueryComponents/GroupingTemplateSelect";
import { REPORT_SETTINGS_COMPONENT_DEFINITIONS } from "./definitions";
import { ReportFormContent } from "./ReportFormContent";

const ContentContainer = styled.div`
  width: 550px;
  height: 100%;
`;

const SettingsContainer = styled.div<{ theme: ThemeDefinition }>`
  background-color: ${({ theme }: { theme: ThemeDefinition }) =>
    theme.colors.backgroundColor1};
  margin-top: var(--spacing-xl);
  border-radius: 10px;
  width: 100%;
  min-height: 200px;
  max-height: 350px;
  overflow: scroll;
  position: relative;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-xl);
`;

const AccordionsContainer = styled.div`
  margin-top: var(--spacing-xl);
`;

const RadioSelectionWrapper = styled.div`
  display: flex;
  margin-bottom: var(--spacing);
`;

const ButtonWrapper = styled.div`
  margin: var(--spacing-l) 0 var(--spacing-l) 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: var(--spacing);
`;

const StyledButton = styled(Button)`
  height: auto;
  font-weight: normal;
`;

const StyledFAIcon = styled(FontAwesomeIcon)<{ theme: ThemeDefinition }>`
  position: absolute;
  top: 30%;
  left: 44%;
  color: ${({ theme }: { theme: ThemeDefinition }) => theme.colors.textMuted};
`;

const StyledButtonIcon = styled(FontAwesomeIcon)`
  margin-right: var(--spacing-l);
`;

const SettingDetailsContainer = styled.div`
  padding: var(--spacing-xl);
`;

const StyledText = styled(LabelText)<{ theme: ThemeDefinition }>`
  margin-top: var(--spacing-l);
  position: absolute;
  top: 70%;
  left: 36%;
  color: ${({ theme }: { theme: ThemeDefinition }) => theme.colors.textMuted};
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 45%;
`;

const reportOptions = [
  { label: "Cash Flows", value: "WebPositionCashFlowQuery" },
  { label: "Security Master", value: "WebInstrumentQuery" },
  { label: "Order Blotter", value: "WebOrderBlotterQuery" },
  { label: "Positions", value: "WebPositionReportQuery" },
  { label: "Custodian Accounts", value: "WebCustodianAccountQuery" },
  { label: "Journal Entries", value: "WebGLDistributionLineQuery" },
  { label: "Cash Flow Statement", value: "WebGLCashFlowStatementQuery" },
  { label: "Market Rates", value: "WebQuotesQuery" },
  { label: "Position Performance(New)", value: "WebGLPositionQuery" },
  { label: "Balance Sheet", value: "WebGLBalanceSheetQuery" },
  { label: "Portfolio Analytics", value: "WebTradeValuationReportQuery" },
  { label: "Trade Blotter", value: "WebTradeBlotterQuery" },
  { label: "Cash Balances", value: "WebGLCashBalancesQuery" },
  { label: "Income Statement", value: "WebGLIncomeStatementQuery" },
  { label: "Trial Balances", value: "WebGLTrialBalancesQuery" },
  { label: "Cash Activity", value: "WebGLCashActivityQuery" },
  {
    label: "Tax Lots",
    value: "WebPositionEventReportQuery",
  },
  {
    label: "Counterparty Commission Report",
    value: "WebCounterpartyCommissionQuery",
  },
  { label: "Book", value: "WebBookReportQuery" },
  { label: "Investment Report", value: "WebInvestmentReportQuery" },
  { label: "Compliance Blotter", value: "WebComplianceBlotterQuery" },
  { label: "Corporate Actions", value: "WebCorporateActionReportQuery" },
];

const radioGroupOptions = [
  { label: "Dynamic", value: "dynamic" },
  { label: "Static", value: "static" },
];

const ReportSettingsModal: React.FC<{
  open: boolean;
  onClose: VoidFunction;
  reportQuery?: WebReportQuery;
  runReport: (reportQuery: WebReportQuery) => Promise<void>;
}> = ({ open, onClose, reportQuery, runReport }) => {
  const [renderingType, setRenderingType] = React.useState<string>("static");
  const [loading, setLoading] = React.useState<boolean>(false);

  const formMethods = useForm<WebReportQuery>();
  const { theme } = useTheme();

  const { getValues, reset, watch } = formMethods;
  const { type: reportType } = watch(["type"]);

  React.useEffect(() => {
    const getInitialData = async () => {
      try {
        if (reportType) {
          setLoading(true);
          const name =
            REPORT_SETTINGS_COMPONENT_DEFINITIONS[reportType]?.name ?? null;
          if (name) {
            const defaultQuery = await REST_API.REPORTS.GET_DEFAULT_QUERY.FETCH(
              name
            );
            reset({ ...defaultQuery });
          }
        }
      } catch (ex: any) {
        console.error(ex);
      } finally {
        setLoading(false);
      }
    };
    if (open) {
      if (reportQuery) {
        reset(reportQuery);
      } else {
        getInitialData();
      }
    }
  }, [JSON.stringify(reportQuery), reportType, open]);

  const onSubmit = async () => {
    onClose();
    const reportQuery = getValues() as WebReportQuery;
    runReport(reportQuery);
    reset({});
  };

  const showSpecificSettingsPanel = React.useMemo(() => {
    if (!reportType) return true;
    return Boolean(
      REPORT_SETTINGS_COMPONENT_DEFINITIONS[reportType]?.settingsComponent
    );
  }, [reportType]);

  const onModalClose = () => {
    onClose();
    reset({});
  };

  return (
    <Modal
      title="Generate Report"
      isOpen={open}
      onClose={onModalClose}
      content={
        <FormProvider {...formMethods}>
          <ContentContainer>
            <ControlledSelect
              name="type"
              options={reportOptions}
              label="Select Report Type"
              clearable
              disabled={!!reportQuery}
              inputId="select-report-type"
            />

            <AccordionsContainer data-e2e-id="report-settings-filters">
              <FormSectionAccordion title="Filters">
                <PanelRow>
                  <InputWrapper>
                    <RadioSelectionWrapper>
                      <RadioGroup
                        name=""
                        options={radioGroupOptions}
                        value={renderingType}
                        onChange={(val: string) => {
                          setRenderingType(val);
                        }}
                        label="Rendering Type: "
                        fontSize="x-small"
                        inline
                      />
                    </RadioSelectionWrapper>
                  </InputWrapper>
                </PanelRow>
                <PanelRow>
                  <InputWrapper>
                    <GroupingTemplateSelect />
                  </InputWrapper>
                </PanelRow>
                <PanelRow>
                  <ButtonWrapper>
                    <StyledButton>
                      <StyledButtonIcon icon={faFilter} />
                      Pre-Grouping (None Set)
                    </StyledButton>
                    <StyledButton>
                      <StyledButtonIcon icon={faFilter} />
                      Post-Grouping (None Set)
                    </StyledButton>
                    <StyledButton>
                      <StyledButtonIcon icon={faFilter} />
                      Color (None Set)
                    </StyledButton>
                  </ButtonWrapper>
                </PanelRow>
                <PanelRow>
                  <InputWrapper>
                    <ControlledCheckbox
                      name="totalsIncludeFilteredRows"
                      label="Totals Include Excluded Rows"
                      labelPlacement="right"
                    />
                  </InputWrapper>
                </PanelRow>
              </FormSectionAccordion>
            </AccordionsContainer>

            <AccordionsContainer data-e2e-id="report-settings-type">
              <FormSectionAccordion title="Type">
                <InputGroup>
                  <InputWrapper>
                    <ControlledCheckbox
                      name="disableHighlightWarnings"
                      label="Do not highlight warnings"
                      labelPlacement="right"
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputWrapper>
                      <ControlledCheckbox
                        name="hideSettingsOnOpen"
                        label="Hide settings when report is open"
                        labelPlacement="right"
                      />
                    </InputWrapper>
                  </InputWrapper>
                </InputGroup>
              </FormSectionAccordion>
            </AccordionsContainer>

            {showSpecificSettingsPanel && (
              <SettingsContainer theme={theme}>
                {reportType ? (
                  loading ? (
                    <SpinnerContainer>
                      <Spinner />
                    </SpinnerContainer>
                  ) : (
                    <SettingDetailsContainer data-e2e-id="specific-settings-container">
                      <FormPanel title="Specific Settings" numColumns={1}>
                        <ReportFormContent
                          type={reportType}
                          renderContentType="settingsComponent"
                        />
                      </FormPanel>
                    </SettingDetailsContainer>
                  )
                ) : (
                  <>
                    <StyledFAIcon icon={faFileImage} size="5x" theme={theme} />
                    <StyledText theme={theme}>Select a Report Type</StyledText>
                  </>
                )}
              </SettingsContainer>
            )}
          </ContentContainer>
        </FormProvider>
      }
      footerContent={
        <FooterContainer>
          <Button
            onClick={onModalClose}
            data-e2e-id="report-settings-close-btn"
          >
            Close
          </Button>
          <Button
            id="run-report"
            onClick={onSubmit}
            primary
            disabled={!reportType || loading}
            data-e2e-id="report-settings-run-report-btn"
          >
            Run Report
          </Button>
        </FooterContainer>
      }
    />
  );
};

export default ReportSettingsModal;
