/* eslint-disable @typescript-eslint/no-explicit-any */
import { useGridSizing as useGridSizingCore } from "@enfusion-ui/dashboards";
import { DashboardGridLayoutConfig } from "@enfusion-ui/types";
import { SCROLL_BAR_WIDTH, useThisTab } from "@enfusion-ui/web-core";

export const useGridSizing = (
  editMode: boolean,
  mainGridProps: DashboardGridLayoutConfig<any>,
  stickyGridProps?: DashboardGridLayoutConfig<any>,
  numOfCols?: number | null
) => {
  const { width, height } = useThisTab();

  return useGridSizingCore(
    width,
    height,
    SCROLL_BAR_WIDTH,
    editMode,
    mainGridProps,
    stickyGridProps,
    numOfCols
  );
};
