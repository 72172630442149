import {
  TestChannelWidgetConfig,
  useChannelDataValue,
  useDashboard,
  useWidget,
} from "@enfusion-ui/dashboards";
import { WidthHeight100 } from "@enfusion-ui/web-components";
import * as React from "react";

const TestChannelWidget: React.FC<unknown> = () => {
  const { editedConfig } = useWidget();
  const { channelKeys, channelData } = useDashboard();

  const { channelDataId } = editedConfig as TestChannelWidgetConfig;

  const data = useChannelDataValue<[Date | null, Date | null]>(channelDataId);

  return (
    <WidthHeight100 style={{ overflowY: "auto" }}>
      Specific Data
      <pre>{JSON.stringify(data, null, 2)}</pre>
      Channel Data
      <pre>{JSON.stringify(channelData, null, 2)}</pre>
      All Keys
      <pre>{JSON.stringify(channelKeys, null, 2)}</pre>
    </WidthHeight100>
  );
};

export default TestChannelWidget;
