import { useAppTutorial } from "@app-components/Tour/context";
import { TutorialType } from "@app-components/Tour/utils";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  DashboardRoot,
  FileTreeEntry,
  NodeData,
  SelectOptionsType,
} from "@enfusion-ui/types";
import {
  getSelectOption,
  replaceDoubleSlashWithSingleSlash,
} from "@enfusion-ui/utils";
import {
  Button,
  ControlledSelect,
  ControlledTextInput,
  FolderSelector,
  FormController,
  IconButton,
  ModalActionContainer,
  MutedText,
} from "@enfusion-ui/web-components";
import { styled, useAuth } from "@enfusion-ui/web-core";
import { faFolderPlus } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { ControllerRenderProps, FormProvider, useForm } from "react-hook-form";

const InputContainer = styled.div`
  display: flex;
  flex: 0 1 40%;
`;

const ROOT_OPTIONS = [
  {
    label: "User",
    value: "user",
  },
  {
    label: "Shared",
    value: "shared",
  },
  {
    label: "Global",
    value: "global",
  },
] as SelectOptionsType<DashboardRoot>[];

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const determineFilePath = (folder: string, fileName: string) => {
  // use ends with check to make sure we allow for folders of the same name ie /test/test.json
  if (folder.endsWith(`${fileName}.json`)) {
    folder = folder.slice(0, 0 - (fileName.length + 5)); // slice negative the length of the file name and the ext
  }
  return replaceDoubleSlashWithSingleSlash(`${folder}/`);
};

const checkFileNameExist = (nodes: NodeData[], name: string) => {
  return nodes.find((node) => node.name === name);
};

export type DocumentSaveFormSubmitCallback = (
  name: string,
  filePath: string,
  rootPath: DashboardRoot,
  args: Record<string, unknown>
) => Promise<void>;

const DocumentSaveForm: React.FC<
  React.PropsWithChildren<{
    onSubmit: DocumentSaveFormSubmitCallback;
    onCancel?: () => void;
    onRootChange?: (root: DashboardRoot) => void;
    fullyQualifiedPath?: string;
    fileName?: string;
    root?: DashboardRoot;
    submitButtonText?: string;
    keyword?: string;
    defaultValues?: Record<string, unknown>;
    additionalRequiredFieldNames?: string[];
  }>
> = ({
  keyword = "dashboard",
  fileName = "New Dashboard",
  fullyQualifiedPath = "/",
  root = "user",
  onSubmit,
  onCancel = () => null,
  onRootChange = () => null,
  submitButtonText = "Save",
  children,
  defaultValues,
  additionalRequiredFieldNames = [],
}) => {
  const { hasPerm } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [assignDirOpen, setAssignDirOpen] = React.useState(false);
  const canGlobalEdit = hasPerm("DashboardEditor");
  const { tutorialType, setMeta, step } = useAppTutorial();

  const formMethods = useForm({
    defaultValues: {
      ...defaultValues,
      name: fileName,
      root,
      filePath: determineFilePath(fullyQualifiedPath, fileName),
    },
  });

  const {
    root: rootValue,
    filePath: filePathValue,
    name: nameValue,
    ...additionalRequiredValues
  } = formMethods.watch([
    "root",
    "filePath",
    "name",
    ...additionalRequiredFieldNames,
  ]);

  const { fileNodes } = useAppTutorial();

  React.useEffect(() => {
    if (
      tutorialType === TutorialType.Portfolio &&
      step?.name === "name-new-workbench" &&
      !!fileNodes
    ) {
      setMeta({
        disableButton: !!checkFileNameExist(fileNodes, `${nameValue}.json`),
      });
    }
  }, [nameValue, tutorialType, step, fileNodes]);

  const disableSubmit = React.useMemo(
    () =>
      !rootValue ||
      !nameValue ||
      Object.values(additionalRequiredValues)?.some((i) => !i),
    [rootValue, nameValue, additionalRequiredValues]
  );

  const submitMiddleware = useRefCallback(
    async (args: any) => {
      setLoading(true);
      try {
        let path = args.filePath || "/";

        if (path.charAt(0) === "/") {
          path = path.substring(1);
        }

        if (path.charAt(path.length - 1) !== "/") {
          path += "/";
        }

        if (!path) {
          formMethods.setError("name", {
            type: "required",
            message: "Name is required",
          });
          return;
        }

        await onSubmit(
          args.name,
          replaceDoubleSlashWithSingleSlash(path),
          args.root,
          args
        );
        formMethods.reset();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        formMethods.setError("name", {
          type: "required",
          message: err.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [onSubmit]
  );

  const handleDirChange = useRefCallback(
    (onChange, node: NodeData | null) => {
      const destinationPath = node
        ? (node as unknown as FileTreeEntry).path
        : "";
      onChange(
        rootValue
          ? destinationPath.replace(`${rootValue}/`, "")
          : destinationPath
      );
    },
    [rootValue]
  );

  const handleRootChange = useRefCallback(
    (value) => onRootChange?.(value as DashboardRoot),
    [onRootChange]
  );

  const toggleAssignDirOpen = () => {
    setAssignDirOpen((state) => !state);

    formMethods.reset({
      ...formMethods.getValues(),
      filePath: determineFilePath(fullyQualifiedPath, fileName),
    });
  };

  const options = React.useMemo(
    () => ROOT_OPTIONS.filter((i) => i.value !== "global" || canGlobalEdit),
    [canGlobalEdit]
  );

  return (
    <FormProvider {...formMethods}>
      <FormContainer onSubmit={formMethods.handleSubmit(submitMiddleware)}>
        <ControlledSelect
          name="root"
          label="Root"
          options={options}
          clearable={false}
          inputId="root-selection-id"
          onChange={handleRootChange}
          defaultValue={getSelectOption(options, root)}
        />
        <InputContainer>
          <ControlledTextInput
            errors={formMethods.errors}
            required
            name="name"
            label="Name"
          />
          <IconButton
            type="button"
            icon={faFolderPlus}
            title="Set Directory"
            onClick={toggleAssignDirOpen}
            style={{
              marginTop: 20,
              color: filePathValue === "/" ? undefined : "var(--primary)",
            }}
          />
        </InputContainer>
        {assignDirOpen ? (
          <FormController
            name="filePath"
            render={({ onChange }: ControllerRenderProps) => {
              return (
                <FolderSelector
                  required
                  clearable
                  root={rootValue}
                  label="Directory"
                  defaultValue={formMethods.getValues("filePath")}
                  onSelect={(node) => handleDirChange(onChange, node)}
                />
              );
            }}
          />
        ) : filePathValue === "/" ? (
          <MutedText
            style={{ fontSize: "small", marginTop: "var(--spacing-l)" }}
          >
            The {keyword} will be saved to the root folder.
          </MutedText>
        ) : null}
        {children}

        <ModalActionContainer style={{ marginTop: "var(--spacing-l)" }}>
          <Button
            primary
            type="submit"
            busy={loading}
            disabled={disableSubmit}
            data-e2e-id="save-form-save-btn"
            data-testid="save-form-submit-btn"
          >
            {submitButtonText}
          </Button>
          <Button
            busy={loading}
            onClick={onCancel}
            data-e2e-id="save-form-cancel-btn"
            data-testid="save-form-cancel-btn"
          >
            Cancel
          </Button>
        </ModalActionContainer>
      </FormContainer>
    </FormProvider>
  );
};

export default DocumentSaveForm;
