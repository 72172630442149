import OMSContentView from "@app-components/display/OMSContentView";
import {
  OrderFormSyncProvider,
  useOrderFormSync,
} from "@app-context/oems/OrderFormSyncProvider";
import { useSettings } from "@app-context/settings/context";
import { usePermissions } from "@app-utils/usePermissions";
import {
  FormType,
  OrderFormValues,
  OrderInitialArguments,
  UNKNOWN_INSTRUMENT_ID,
  useCanSaveOrder,
  useOEMSOrderForm,
} from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { AppEvent, AppEventCategories, OrderInfo } from "@enfusion-ui/types";
import {
  Button,
  ButtonContainer,
  CenterContent,
  ContentMessage,
  Spinner,
  useConfirmationModal,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  styled,
  TabDef,
  useAuth,
  useIsContentUnderWidth,
  useLocates,
  useTabs,
  useThisTab,
  useTrackUnsavedChanges,
} from "@enfusion-ui/web-core";
import { useWorkerModule } from "@enfusion-ui/web-workers";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import OEMSRoutesProvider from "../../../context/oems/OEMSRoutesProvider";
import { OrderSummaryModal } from "../components/OrderSummary/OrderSummary";
import { ComplianceConfirmation } from "./components/ComplianceConfirmation";
import { WarningConfirmation } from "./components/WarningConfirmation";
import { CustomTicket } from "./CustomTicket/CustomTicket";
import { FullTicket } from "./FullTicket/FullTicket";

type OrderTicketProps = {
  orderId?: number;
  completed?: boolean;
  splitOrder?: boolean;
  formType?: FormType;
  custom?: boolean;
  submissionStatus?: string;
  autoFocus?: string;
  onOrderSubmissionWithWarnings?: VoidFunction;
  onSubmit?: VoidFunction;
  onSubmitNewOrder?: VoidFunction;
};

const OrderTicketContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const OrderTicketContent = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const StatusContainer = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin: var(--spacing);
  padding: 2px;
  color: var(--danger);
`;

const defaultWatchFields = [
  "instrumentId",
  "orderSide",
  "quantity",
  "notional",
  "orderType",
  "limitPrice",
  "stopPrice",
  "portfolioManager",
  "assignedTradingDesk",
  "assignedTrader",
  "webOrderType",
  "destination",
  "targetCompId",
];

const getWatchFields = (formType?: FormType) => {
  if (formType === "cdx") {
    return [
      ...defaultWatchFields,
      "ccp",
      "fcmDcmId",
      "cdxIndex",
      "tenor",
      "cdxRoot",
    ];
  } else if (formType === "fx") {
    return [
      ...defaultWatchFields,
      "currency",
      "settleDate",
      "farSettleDate",
      "currencyPair",
    ];
  } else if (formType === "varSwap") {
    return [
      ...defaultWatchFields,
      "underlyingId",
      "startDate",
      "expiryDate",
      "notionalCurrency",
      "vegaNotional",
    ];
  } else if (formType === "irs") {
    return [
      ...defaultWatchFields,
      "clearingType",
      "quickTemplate",
      "tenor",
      "ccp",
      "fcmDcm",
      "startDate",
      "endDate",
    ];
  }
  return defaultWatchFields;
};

// Handle OEMS order form status
const OrderTicketCore: React.FC<OrderTicketProps> = ({
  orderId = 0,
  autoFocus = "details",
  onOrderSubmissionWithWarnings,
  onSubmit,
  onSubmitNewOrder,
}) => {
  const {
    initialLoading,
    error,
    isFormBusy,
    completed,
    splitOrder,
    submitOrder,
    resetForm,
    isFxOrder,
    isCdxOrder,
    isVarSwapOrder,
    isIrsOrder,
    formType,
    custom,
    handleQuantity,
    getCurrentOrder,
    warningRecords,
  } = useOEMSOrderForm();

  const { getValues, watch, handleSubmit } = useFormContext<OrderFormValues>();

  const [autoFocusSection, setAutoFocusSection] = React.useState(autoFocus);
  const shortTransmitText = useIsContentUnderWidth(450);
  const { orders } = usePermissions();
  const { canAccept, issues, canTransmit } = useCanSaveOrder(
    orders.readOnly,
    splitOrder,
    !!isFxOrder,
    !!isCdxOrder,
    !!isVarSwapOrder,
    !!isIrsOrder,
    getWatchFields(formType)
  );

  const shouldTransmit = React.useRef(false);
  const { postMessage } = useWorkerModule("locate");

  const [showOrderSummaryModal, setShowOrderSummaryModal] =
    React.useState(false);

  const [showValidationWarning, setShowValidationWarning] = React.useState<
    string | null
  >(null);

  const { instrument, quantity } = watch(["instrument", "quantity"]);

  React.useEffect(() => {
    handleQuantity(getValues("quantity"));

    return () => {
      postMessage({
        command: "clear-instrument-data",
        payload: { instrumentId: instrument?.id },
      });
    };
  }, [instrument]);

  const openSummaryHandler = (transmit: boolean) => () => {
    shouldTransmit.current = transmit;
    const lotSize = instrument?.lotSize ?? 1;

    if (!isFxOrder && !isCdxOrder && Number(quantity) % lotSize !== 0) {
      setShowValidationWarning("Order quantity has odd lots");
    } else if (showValidationWarning) {
      setShowValidationWarning(null);
    }

    setShowOrderSummaryModal(true);
  };

  const hideOrderSummaryModal = () => setShowOrderSummaryModal(false);

  const doSubmit = useRefCallback(() => {
    hideOrderSummaryModal();

    AppLogging.event({
      event: shouldTransmit.current
        ? AppEvent.SubmitAndTransmitOrder
        : AppEvent.SubmitOrder,
      category: AppEventCategories.OEMS,
    });

    handleSubmit(() =>
      submitOrder(
        getCurrentOrder() as Partial<OrderInfo>,
        shouldTransmit.current,
        false,
        false
      )
    )()
      .then(() => {
        if ((orderId === 0 || splitOrder) && onSubmitNewOrder) {
          onSubmitNewOrder();
        } else {
          onSubmit?.();
        }
      })
      .catch((err) => console.error(err));
  }, [submitOrder, shouldTransmit, splitOrder]);

  const autoFocusIssue = useRefCallback(() => {
    if (issues.length > 0) {
      const issue = issues[0];
      setAutoFocusSection(issue.section);
      if (!!issue.inputId) {
        const id = issue.inputId;
        setTimeout(() => {
          requestAnimationFrame(() => {
            const el = document.getElementById(id);
            if (el) {
              el.scrollIntoView({ block: "center" });
            }
          });
        }, 300);
      }
    }
  }, [setAutoFocusSection, issues]);

  if (error) {
    return (
      <CenterContent>
        <ContentMessage
          icon={faExclamationTriangle}
          message="Something went wrong loading the order."
        />
      </CenterContent>
    );
  }

  if (initialLoading) {
    return (
      <CenterContent>
        <Spinner />
      </CenterContent>
    );
  }
  return (
    <OEMSRoutesProvider orderId={orderId}>
      <OrderTicketContainer>
        <OrderTicketContent>
          {custom ? (
            <CustomTicket />
          ) : (
            <FullTicket
              autoFocus={autoFocusSection}
              onChange={() => setAutoFocusSection("")}
            />
          )}
        </OrderTicketContent>

        <StatusContainer
          data-e2e-id="validation-message-test-id"
          onClick={autoFocusIssue}
        >
          {issues.length > 0 ? `* ${issues[0].message}` : ""}
        </StatusContainer>
        <div
          style={{
            backgroundColor: "var(--background-color-0)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonContainer>
            <Button
              busy={isFormBusy}
              onClick={openSummaryHandler(false)}
              disabled={isFormBusy || !canAccept || completed}
              id="accept-order"
              data-e2e-id="order-ticket-btn-accept"
            >
              Accept
            </Button>
            <Button
              busy={isFormBusy}
              onClick={openSummaryHandler(true)}
              disabled={isFormBusy || !canAccept || !canTransmit || completed}
            >
              {shortTransmitText ? "" : "Accept "}+ Transmit
            </Button>
            <Button
              name="reset"
              onClick={resetForm}
              disabled={completed}
              data-e2e-id="order-ticket-btn-reset"
            >
              Reset
            </Button>
          </ButtonContainer>
        </div>
      </OrderTicketContainer>

      <OrderSummaryModal
        open={showOrderSummaryModal}
        onSubmit={doSubmit}
        onCancel={hideOrderSummaryModal}
        showValidationWarning={showValidationWarning}
      />

      <ComplianceConfirmation canTransmit={canTransmit} />
      <WarningConfirmation
        shouldTransmit={shouldTransmit}
        warningRecords={warningRecords ?? []}
        onOrderSubmissionWithWarnings={onOrderSubmissionWithWarnings}
      />
    </OEMSRoutesProvider>
  );
};

const NewOrderTicketCore: React.FC<OrderTicketProps> = (props) => {
  const { onTabClose } = useTabs();
  const { closeTab, onTabWillClose } = useThisTab();
  const { postOrderFormState } = useOrderFormSync();

  const canClose = React.useRef(false);

  const { formState, getValues } = useFormContext<OrderFormValues>();

  const {
    instrument,
    swapOrder,
    orderSide,
    quantity,
    assignedTradingDesk,
    orderType,
    qoeValue,
    limitPrice,
    notional,
    localNotional,
  } = useWatch<OrderFormValues>({
    name: [
      "instrument",
      "swapOrder",
      "orderSide",
      "quantity",
      "assignedTradingDesk",
      "orderType",
      "qoeValue",
      "limitPrice",
      "notional",
      "localNotional",
    ],
  });
  const {
    splitOrder,
    isFxOrder,
    isCdxOrder,
    isVarSwapOrder,
    isIrsOrder,
    formType,
    resetForm,
  } = useOEMSOrderForm();
  const { user } = useAuth();

  const { canTransmit } = useCanSaveOrder(
    !!user?.flags?.OMS.readOnly,
    splitOrder,
    !!isFxOrder,
    !!isCdxOrder,
    !!isVarSwapOrder,
    !!isIrsOrder,
    getWatchFields(formType),
    !!process.env.REACT_APP_ENABLE_STRATEGY_PARAMETER_VALIDATION
  );

  const { unsubscribeInstrument } = useLocates();
  const { enableClearTicketAfterCreate, enableKeepTicketOpenAfterCreate } =
    useSettings();

  React.useEffect(
    () =>
      onTabClose((tabDef: TabDef) => {
        if (tabDef.component === "new-oems-order") {
          // Clear the form state
          postOrderFormState(null);
        }
      }),
    []
  );

  const closeForm = useRefCallback(() => {
    unsubscribeInstrument?.(instrument?.id ?? UNKNOWN_INSTRUMENT_ID);
    canClose.current = true;
    closeTab();
  }, [closeTab, unsubscribeInstrument, instrument]);

  const onSubmitNewOrder = useRefCallback(() => {
    if (enableKeepTicketOpenAfterCreate && enableClearTicketAfterCreate) {
      resetForm();
    } else if (!enableKeepTicketOpenAfterCreate) {
      closeForm();
    }
  }, [
    resetForm,
    closeForm,
    enableKeepTicketOpenAfterCreate,
    enableClearTicketAfterCreate,
  ]);

  const CloseConfirmation = useConfirmationModal({
    title: "Warning",
    submitActionTheme: "warning",
    onSubmit: closeForm,
    renderContent: () =>
      "Do you want to close the order entry form without saving?",
  });

  const checkCanClose = useRefCallback(() => {
    if (formState.isDirty && !canClose.current) {
      CloseConfirmation.openModal();
      return false;
    }
    return true;
  }, [formState.isDirty, CloseConfirmation.openModal]);

  React.useEffect(() => {
    return onTabWillClose(checkCanClose);
  }, [onTabWillClose]);

  useTrackUnsavedChanges(formState.isDirty && !canClose.current);

  const handleSubmit = () => {
    canClose.current = true;
  };

  React.useEffect(() => {
    if (!canClose.current) {
      postOrderFormState(getValues());
    }
  }, [
    instrument,
    swapOrder,
    orderSide,
    quantity,
    assignedTradingDesk,
    orderType,
    qoeValue,
    limitPrice,
    notional,
    localNotional,
  ]);

  return (
    <>
      <OrderTicketCore
        onSubmitNewOrder={onSubmitNewOrder}
        onOrderSubmissionWithWarnings={onSubmitNewOrder}
        onSubmit={handleSubmit}
        {...props}
      />
      {CloseConfirmation.content}
      <ComplianceConfirmation
        handleOrderCancellation={closeForm}
        handleOrderSubmissionWithCompliance={onSubmitNewOrder}
        canTransmit={canTransmit}
      />
    </>
  );
};

// Handle OMS init status
const OrderTicket: React.FC<OrderTicketProps & OrderInitialArguments> = ({
  orderId = 0,
  completed = false,
  splitOrder = false,
  formType = "equity",
  autoFocus,
  submissionStatus,
  ...orderArgs
}) => {
  React.useEffect(() => {
    let prefix = orderId === 0 ? "New " : "";
    if (splitOrder) prefix = `${prefix}Split `;

    AppLogging.event(
      {
        event: AppEvent.OrderOpened,
        category: AppEventCategories.OEMS,
        gaLabel: `${prefix}${formType} Order`,
      },
      {
        new: orderId === 0,
        orderId,
        completed,
        splitOrder,
        formType,
      }
    );
  }, []);

  const OrderCore =
    orderId === 0 || splitOrder ? NewOrderTicketCore : OrderTicketCore;
  return (
    <OrderFormSyncProvider
      {...orderArgs}
      orderId={orderId}
      completed={completed}
      splitOrder={splitOrder}
      formType={formType}
      submissionStatus={submissionStatus}
    >
      <OMSContentView>
        <OrderCore orderId={orderId} autoFocus={autoFocus} />
      </OMSContentView>
    </OrderFormSyncProvider>
  );
};

export default OrderTicket;
