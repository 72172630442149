import { InstrumentOption } from "@app-components/inputs/InstrumentSelect/types";
import { getSelectorValue } from "@app-components/inputs/InstrumentSelect/utils";
import { useSelectDataValue } from "@app-views/dashboards/pdf/utils";
import { fetchInstrument } from "@app-views/oems/utils/actions";
import {
  InstrumentSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import * as React from "react";

import PdfSelectionWidget from "../PdfSelectionWidget";

const fetchInstruments = async (ids: number[]) =>
  Promise.all(ids.map((id) => fetchInstrument(id)));

const PdfInstrumentSelectionWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const { defaultValue, key } = config as InstrumentSelectionWidgetConfig;
  const [instrumentValues, setInstrumentValues] = React.useState<
    InstrumentOption[] | null
  >(null);
  const [inputValue, setInputValue] = React.useState<string | null>(null);

  useSelectDataValue(
    key,
    "Instrument IDs",
    ChannelDataType.Number,
    defaultValue
  );

  React.useEffect(() => {
    if (defaultValue?.length) {
      fetchInstruments(defaultValue).then((values) => {
        const instruments = values.filter(Boolean);
        const options = instruments.map(getSelectorValue);
        setInstrumentValues(options as InstrumentOption[]);
      });
    } else {
      setInstrumentValues(null);
    }
  }, [defaultValue]);

  React.useEffect(() => {
    if (defaultValue && instrumentValues) {
      const selectedValues = defaultValue
        .map((value) => {
          const selectedValue = instrumentValues.find(
            ({ value: val }) => val === value
          );
          return selectedValue?.label;
        })
        .filter((value) => value);

      const formattedValue = selectedValues.join(", ");
      setInputValue(formattedValue);
    }
  }, [instrumentValues, defaultValue]);

  return <PdfSelectionWidget label={key} value={inputValue} />;
};

export default PdfInstrumentSelectionWidget;
