import { UseModalStateResults } from "@enfusion-ui/hooks";
import {
  IconButton,
  Portal,
  PortalProps,
  TextInput,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import {
  faChevronDown,
  faChevronUp,
  faClose,
  faFilter,
  faFontCase,
  faPipe,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

import {
  GeneralSearchDirection,
  GeneralSearchFilterMode,
} from "../../utils/useGeneralSearchEvents";

type GeneralSearchPortalProps = {
  searchText: string;
  resultCount: number;
  resultIndex: number;
  matchCase: boolean;
  filterMode: GeneralSearchFilterMode;
  modalState: UseModalStateResults;
  changeText: (text: string) => void;
  toggleMatchCase: VoidFunction;
  toggleFilterMode: VoidFunction;
  navigateMatch: (dir: GeneralSearchDirection) => void;
  nextMatch: VoidFunction;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: var(--spacing);
  background: var(--background-color-2);
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
`;

export const GeneralSearchPortal: React.FC<
  Omit<PortalProps, "open" | "key"> & GeneralSearchPortalProps
> = ({
  searchText,
  resultCount,
  resultIndex,
  matchCase,
  filterMode,
  modalState,
  changeText,
  toggleMatchCase,
  toggleFilterMode,
  navigateMatch,
  nextMatch,
  ...props
}) => {
  return (
    <Portal {...props} open={modalState.open}>
      <Container>
        <TextInput
          value={searchText}
          onChange={(e) => {
            changeText(e.target.value);
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              nextMatch?.();
              e.preventDefault();
            }
          }}
          hideLabel
          iconContainer={
            <div
              style={{
                lineHeight: "13px",
                fontSize: "11px",
                color: "var(--text-muted)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "2px",
              }}
            >
              {resultCount > 0 ? resultIndex + 1 : resultIndex}/{resultCount}
            </div>
          }
        />
        <IconButton
          style={{ marginLeft: "var(--spacing)" }}
          icon={faChevronDown}
          onClick={() => navigateMatch("down")}
          title="Next Match"
          hoverable
        />
        <IconButton
          icon={faChevronUp}
          onClick={() => navigateMatch("up")}
          title="Prev Match"
          hoverable
        />
        <FontAwesomeIcon
          icon={faPipe}
          color="var(--text-muted)"
          style={{
            marginLeft: "var(--spacing-l)",
            marginRight: "var(--spacing-l)",
          }}
        />
        <IconButton
          icon={faFontCase}
          onClick={toggleMatchCase}
          title={`Match Case${filterMode === "filter" ? " - Filter" : ""}`}
          color={`var(--${
            filterMode === "filter"
              ? "text-muted"
              : matchCase
              ? "primary"
              : "text-normal"
          }`}
          hoverable
          tabFocusOutline={false}
          size="sm"
          iconStyle={{ marginTop: "-1px" }}
          disabled={filterMode === "filter"}
        />
        <IconButton
          icon={faFilter}
          onClick={toggleFilterMode}
          title="Isolate to Matched Rows (Case Insensitive)"
          color={
            filterMode === "filter" ? "var(--primary)" : "var(--text-normal)"
          }
          tabFocusOutline={false}
          hoverable
          size="sm"
        />
        <IconButton
          style={{ marginLeft: "var(--spacing)" }}
          icon={faClose}
          onClick={modalState.closeModal}
          hoverable
        />
      </Container>
    </Portal>
  );
};
