import InstrumentCard from "@app-components/inputs/InstrumentSelect/components/InstrumentCard";
import { fetchCDXInstrument } from "@app-components/inputs/InstrumentSelect/utils";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { InstrumentInfo, SelectOptionsType } from "@enfusion-ui/types";
import { Select, SelectContainer } from "@enfusion-ui/web-components";
import { AppLogging } from "@enfusion-ui/web-core";
import { debounce } from "lodash";
import * as React from "react";
import { components, OptionProps } from "react-select";

export type CDXIndexSelectProps = {
  onChange: (instrument: InstrumentInfo | null) => void;
  label?: string;

  disabled: boolean;
  cdxOptions: { cdxRoot: string | undefined; otrOnly: boolean };
  value: InstrumentInfo;
};

export type CDXIndexOption = {
  label: string;
  value: number;
  instrument: InstrumentInfo | null;
};

type CDXOptionProps = OptionProps<SelectOptionsType<number>, boolean>;

export const CDXOption: React.FC<CDXOptionProps> = (props) => {
  const { instrument } = props.data as CDXIndexOption;
  return (
    <components.Option {...props}>
      <InstrumentCard
        description={instrument?.description || "----Missing"}
        subType=""
        exchange=""
        ticker={instrument?.id.toString() || "----Missing"}
      />
    </components.Option>
  );
};

export const CDXIndexSelect: React.FC<CDXIndexSelectProps> = ({
  onChange,
  label = "CDX Index",

  disabled,
  cdxOptions,
  value,
}) => {
  const isMounted = useMounted();
  const [loading, setLoading] = React.useState(false);
  const [enteredValue, setEnteredValue] = React.useState<string>("");

  const selectRef = React.useRef(null);

  const [instrumentValue, setInstrumentValue] =
    React.useState<CDXIndexOption | null>(null);
  const [options, setOptions] = React.useState<CDXIndexOption[] | undefined>();

  const optionsCache = React.useRef<CDXIndexOption[] | undefined>();

  React.useEffect(() => {
    value &&
      setInstrumentValue({
        value: value.id,
        label: value.description,
        instrument: value,
      });
  }, [value]);

  React.useEffect(() => {
    handleChange(null);
    setOptions([]);
    optionsCache.current = [];
    searchInstruments("");
  }, [cdxOptions?.cdxRoot, cdxOptions?.otrOnly]);

  const searchInstruments = useRefCallback(
    debounce(async (searchText: string) => {
      if (isMounted()) {
        setLoading(true);
        setOptions([]);
        let opts = optionsCache.current;
        if (!optionsCache.current?.length && cdxOptions) {
          opts = await fetchCDXInstrument(cdxOptions);
          optionsCache.current = opts;
        }

        if (!searchText.trim()) {
          setOptions(opts);
          setLoading(false);
          if (opts?.length === 1) handleChange(opts[0]);
          return;
        }
        try {
          opts = opts?.filter((cdx: CDXIndexOption) =>
            cdx?.label.toUpperCase().includes(searchText.toUpperCase())
          );
          setOptions(opts);
          setLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          AppLogging.error(
            "Failed to fetch instrument search result data",
            err
          );
        } finally {
          if (isMounted()) setLoading(false);
        }
      }
    }, 100),
    [
      isMounted,
      setLoading,
      cdxOptions?.cdxRoot,
      cdxOptions?.otrOnly,
      setOptions,
    ]
  );

  const handleChange = useRefCallback(
    async (option: CDXIndexOption | null) => {
      if (isMounted()) {
        setInstrumentValue(option);
        onChange(option?.instrument ?? null);
      }
    },
    [cdxOptions?.cdxRoot, cdxOptions?.otrOnly]
  );

  return (
    <>
      <SelectContainer ref={selectRef}>
        <Select
          inputId="cdx-index-select"
          label={label}
          value={instrumentValue}
          options={options}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(value: any | null) => {
            handleChange(value as CDXIndexOption);
          }}
          onInputChange={(value: string) => {
            if (enteredValue !== value) {
              searchInstruments(value);
              setEnteredValue(value);
            }
          }}
          components={{ Option: CDXOption }}
          isLoading={loading}
          clearable
          disabled={disabled}
        />
      </SelectContainer>
    </>
  );
};

export default CDXIndexSelect;
