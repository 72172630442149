import { pxToPtConversion } from "@app-views/dashboards/pdf/utils";
import { ThemeDefinition } from "@enfusion-ui/core";
import { withTheme } from "@enfusion-ui/web-core";
import { faChevronDown, faSlidersH } from "@fortawesome/pro-solid-svg-icons";
import { Path, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import * as React from "react";

type PdfSelectionWidgetProps = {
  label: string;
  value: string | null;
  placeholder?: string;
  filterBtn?: boolean;
};

const pdfStyles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: pxToPtConversion(12),
  },
  label: {
    width: "100%",
    fontFamily: "Lato",
    fontSize: pxToPtConversion(12),
    lineHeight: 1.5,
    fontWeight: "light",
    marginLeft: pxToPtConversion(4),
  },
  input: {
    width: "100%",
    flexDirection: "row",
  },
  textInputContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: pxToPtConversion(4),
    padding: pxToPtConversion(4),
  },
  textInput: {
    width: "100%",
    height: "100%",
    // justifyContent and alignItems are reversed
    justifyContent: "center",
    alignItems: "flex-start",
  },
  text: {
    fontSize: pxToPtConversion(12),
    fontFamily: "Lato",
    width: "100%",
    maxLines: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  optionBtn: {
    width: pxToPtConversion(27),
    height: pxToPtConversion(23),
    justifyContent: "center",
    alignItems: "center",
    marginLeft: pxToPtConversion(4),
  },
  filterBtn: {
    width: pxToPtConversion(38),
    height: pxToPtConversion(38),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: pxToPtConversion(4),
    borderRadius: pxToPtConversion(4),
    transform: "rotate(90deg)",
  },
});

const PdfSelectionWidget = withTheme(
  ({
    label,
    value,
    placeholder,
    filterBtn,
    theme,
  }: PdfSelectionWidgetProps & { theme: ThemeDefinition }) => {
    return (
      <View style={[pdfStyles.container]}>
        <Text
          style={[
            pdfStyles.label,
            {
              color: theme.colors.textNormal,
            },
          ]}
        >
          {label}
        </Text>
        <View style={[pdfStyles.input]}>
          <View
            style={[
              pdfStyles.textInputContainer,
              {
                backgroundColor: theme.colors.inputBackground,
                border: `1px solid ${theme.colors.inputBorder}`,
              },
            ]}
          >
            {value ? (
              <View style={[pdfStyles.textInput]}>
                <Text
                  style={[
                    pdfStyles.text,
                    {
                      color: theme.colors.textNormal,
                    },
                  ]}
                >
                  {value}
                </Text>
              </View>
            ) : (
              <View style={[pdfStyles.textInput]}>
                <Text
                  style={[
                    pdfStyles.text,
                    {
                      color: theme.colors.textMuted,
                    },
                  ]}
                >
                  {placeholder ? placeholder : "Select..."}
                </Text>
              </View>
            )}
            <View
              style={[
                pdfStyles.optionBtn,
                !filterBtn
                  ? {
                      borderLeft: `1px solid ${theme.colors.textMuted}`,
                      paddingLeft: pxToPtConversion(4),
                    }
                  : {},
              ]}
            >
              <Svg
                width={pxToPtConversion(12)}
                height={pxToPtConversion(12)}
                viewBox={`0 0 ${faChevronDown.icon[0]} ${faChevronDown.icon[1]}`}
              >
                <Path
                  d={faChevronDown.icon[4] as string}
                  stroke={theme.colors.textMuted}
                  fill={theme.colors.textMuted}
                />
              </Svg>
            </View>
          </View>
          {filterBtn && (
            <View
              style={[
                pdfStyles.filterBtn,
                {
                  backgroundColor: theme.colors.backgroundColor0,
                },
              ]}
            >
              <Svg
                width={pxToPtConversion(12)}
                height={pxToPtConversion(12)}
                viewBox={`0 0 ${faSlidersH.icon[0]} ${faSlidersH.icon[1]}`}
              >
                <Path
                  d={faSlidersH.icon[4] as string}
                  stroke={theme.colors.primary}
                  fill={theme.colors.primary}
                />
              </Svg>
            </View>
          )}
        </View>
      </View>
    );
  }
);

export default PdfSelectionWidget;
