import { NumericInputWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { NumericInput, WidthHeight100 } from "@enfusion-ui/web-components";
import * as React from "react";
import { useDebounce } from "react-use";

const NumericInputWidget: React.FC<unknown> = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, clearable, stepSize, minValue, key } =
    config as NumericInputWidgetConfig;
  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Numeric Input value",
        type: ChannelDataType.Number,
      },
    ]);
  }, [key]);

  useDebounce(() => setChannelData([value]), 200, [value]);

  const handleChange = (val: number | null) => {
    if (val !== null && !isNaN(val)) setValue(val);
    else setValue(null);
  };

  return (
    <WidthHeight100>
      <NumericInput
        value={value}
        onChange={handleChange}
        label={key}
        clearable={clearable}
        step={stepSize ? parseFloat(stepSize) : undefined}
        min={minValue ? parseFloat(minValue) : undefined}
        hideControls={!stepSize}
        enableMultiplier
      />
    </WidthHeight100>
  );
};

export default NumericInputWidget;
