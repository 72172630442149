import { ConnectionStatus } from "@enfusion-ui/types";
import {
  APIConnectedIcon,
  APIDisconnectedIcon,
  BloombergBIcon,
  FactSetIcon,
} from "@enfusion-ui/web-components";
import { styled, useAuth, useTheme } from "@enfusion-ui/web-core";
import { faTable } from "@fortawesome/pro-regular-svg-icons";
import {
  faDollarSign,
  faSortAmountDown,
} from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";

import {
  ConnectionType,
  useConnectionStatus,
} from "../../../context/connectionStatus/context";
import { statusText } from "../data/connectionStatusText";
import { getColor } from "../utils/getIconColor";
import { ConnectionStatusDetails } from "./ConnectionStatusDetails";
import { ConnectionStatusIcon as DefaultIcon } from "./ConnectionStatusIcon";
// import { ConnectionStatusOverview } from "./ConnectionStatusOverview";

const ConnectionStatusIcon = styled(DefaultIcon).attrs({ minWidth: 2.1 })``;

const ConnectionStatusPopoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const ConnectionStatusRowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ConnectionStatusRow = styled.div`
  display: flex;
`;

const ICON_SIZE = 20;

export const ConnectionStatusPopover: React.FC = () => {
  const { isEnabled } = useAuth();
  const { theme } = useTheme();
  const {
    apiStatus,
    marketDataConnectionStatus,
    oemsConnectionStatus,
    factsetConnectionStatus,
    bloombergConnectionStatus,
    reportSocketConnectionStatus,
  } = useConnectionStatus();

  const APIConnectionIcon =
    apiStatus === ConnectionStatus.CONNECTED
      ? APIConnectedIcon
      : APIDisconnectedIcon;

  return (
    <ConnectionStatusPopoverWrapper>
      <div>
        {/* <ConnectionStatusOverview /> */}
        <ConnectionStatusRowsWrapper>
          <ConnectionStatusRow key="api-connection-status">
            <ConnectionStatusIcon
              svg={
                <APIConnectionIcon
                  size={ICON_SIZE}
                  color={getColor(apiStatus, theme)}
                />
              }
            />
            <ConnectionStatusDetails
              item={statusText[ConnectionType.API]}
              status={apiStatus}
            />
          </ConnectionStatusRow>
          <ConnectionStatusRow key="report-socket-connection-status">
            <ConnectionStatusIcon
              size="lg"
              icon={faTable}
              status={reportSocketConnectionStatus}
            />
            <ConnectionStatusDetails
              item={statusText[ConnectionType.Reports]}
              status={reportSocketConnectionStatus}
            />
          </ConnectionStatusRow>
          {isEnabled("OMS") && (
            <ConnectionStatusRow key="market-data-connection-status">
              <ConnectionStatusIcon
                size="lg"
                icon={faDollarSign}
                status={marketDataConnectionStatus}
              />
              <ConnectionStatusDetails
                item={statusText[ConnectionType.MarketData]}
                status={marketDataConnectionStatus}
              />
            </ConnectionStatusRow>
          )}
          {isEnabled("OMS") && (
            <ConnectionStatusRow key="oems-connection-status">
              <ConnectionStatusIcon
                size="lg"
                icon={faSortAmountDown}
                status={oemsConnectionStatus}
              />
              <ConnectionStatusDetails
                item={statusText[ConnectionType.OEMS]}
                status={oemsConnectionStatus}
              />
            </ConnectionStatusRow>
          )}
          {factsetConnectionStatus !== ConnectionStatus.DISABLED && (
            <ConnectionStatusRow key="factset-connection-status">
              <ConnectionStatusIcon
                svg={
                  <FactSetIcon
                    size={ICON_SIZE + 5}
                    color={getColor(factsetConnectionStatus, theme)}
                  />
                }
                status={factsetConnectionStatus}
              />
              <ConnectionStatusDetails
                item={statusText[ConnectionType.Factset]}
                status={factsetConnectionStatus}
              />
            </ConnectionStatusRow>
          )}
          {bloombergConnectionStatus !== ConnectionStatus.DISABLED && (
            <ConnectionStatusRow key="bloomberg-connection-status">
              <ConnectionStatusIcon
                svg={
                  <BloombergBIcon
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                    color={getColor(bloombergConnectionStatus, theme)}
                  />
                }
                status={bloombergConnectionStatus}
              />
              <ConnectionStatusDetails
                item={statusText[ConnectionType.BloombergRealtime]}
                status={bloombergConnectionStatus}
              />
            </ConnectionStatusRow>
          )}
        </ConnectionStatusRowsWrapper>
      </div>
    </ConnectionStatusPopoverWrapper>
  );
};

export default ConnectionStatusPopover;
