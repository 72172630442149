import {
  CashBreakDatasourceDef,
  DatasourceDef,
  DiffReportData,
  DiffReportSyncRow,
  FilterColumns,
  RECONCILIATION_TYPES,
  ReconciliationConfig,
  ReconciliationDatasourceDef,
  ReconciliationFilterState,
  ReconciliationTypes,
  ReconReportConfig,
  ReconReportTableSyncRow,
  SelectOptionsType,
  SummaryReportConfig,
  WebReconciliationReportMetadata,
  WebReportMetadata,
} from "@enfusion-ui/types";
import { getFileNameDirect } from "@enfusion-ui/utils";
import { createReportColumnDefs, REST_API } from "@enfusion-ui/web-core";
import { ColDef } from "ag-grid-community";
import { memoize } from "lodash";

import { BREAK_DETAILS_GRID_PRIORITY_COLUMNS } from "./constants";

export const isCashDataSource = (reconciliationType: ReconciliationTypes) =>
  reconciliationType === RECONCILIATION_TYPES.Cash;

export const getRowData = (
  diffReportData: DiffReportData,
  config: ReconReportConfig,
  filterState: ReconciliationFilterState,
  filterColumns: FilterColumns
) => {
  const statistics = diffReportData?.statistics;

  const reportName = isCashDataSource(config.reconciliationType)
    ? (config.datasource as CashBreakDatasourceDef)?.cashActivity?.name
    : (config.datasource as DatasourceDef)?.name;

  const { currency: currencyColumn } = config.filterColumns ?? {};
  const breaksData = getFilteredData(
    filterState,
    getBreaksData(diffReportData),
    filterColumns
  );

  return {
    id: config.reconReportId,
    reconciliationType: config.reconciliationType,
    reportData: isCashDataSource(config.reconciliationType)
      ? {
          cashActivityRowsStore: diffReportData,
        }
      : { rowsStore: diffReportData },
    breakCount:
      statistics?.differences +
      statistics?.missingFromSource +
      statistics?.missingFromTarget,
    breakImpact: filterState.currency.value
      ? breaksData
          .filter((row) => {
            return (
              filterState.currency.value ===
                row.columnValues[currencyColumn.source as string]?.value ||
              filterState.currency.value ===
                row.columnValues[currencyColumn.target as string]?.value
            );
          })
          .reduce((total: number, row: DiffReportSyncRow) => {
            const columnValue =
              row.columnValues[config?.impactColumns?.breakImpact as string];
            return total + parseFloat(Number(columnValue?.value).toString());
          }, 0)
      : "-",
    filterColumns: config.filterColumns,
    impactColumns: config.impactColumns,
    matchPercentage:
      diffReportData?.matchedRows?.length /
      (diffReportData?.targetOnlyRows?.length +
        diffReportData?.sourceOnlyRows?.length +
        diffReportData?.differenceRows?.length +
        diffReportData?.matchedRows?.length),
    reportName: reportName ? getFileNameDirect(reportName) ?? "-" : "-",
  };
};

export const hasEmptyMandatoryFields = (config: ReconciliationConfig) => {
  const { reconReports, summaryReports } = config;
  let hasEmptyFields = false;

  const allDatasources = getAllDatasources(config);
  hasEmptyFields = allDatasources?.some(
    (datasource) => !datasource?.datasource
  );
  reconReports.forEach((report) => {
    if (!report.reconciliationType || !report.filterColumns?.currency)
      hasEmptyFields = true;
  });
  summaryReports.forEach((report) => {
    if (!report.column) hasEmptyFields = true;
  });
  return hasEmptyFields;
};

export function camelCaseToWords(s: string) {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const getAllDatasources = (config: ReconciliationConfig) => {
  const reports = [...config.summaryReports, ...config.reconReports];
  let datasources: ReconciliationDatasourceDef[] = [];
  reports.forEach((report) => {
    if (
      (report as ReconReportConfig).reconciliationType ===
        RECONCILIATION_TYPES.Cash &&
      report.datasource
    ) {
      Object.keys(report.datasource).forEach((key) => {
        datasources.push({
          datasource: (report.datasource as CashBreakDatasourceDef)[
            key as unknown as keyof CashBreakDatasourceDef
          ] as DatasourceDef,
          reconId:
            key === "cashActivity"
              ? getCashActivityReportId(
                  (report as ReconReportConfig).reconReportId
                )
              : getCashBalanceReportId(
                  (report as ReconReportConfig).reconReportId
                ),
        });
      });
    } else {
      datasources.push({
        datasource: report.datasource as DatasourceDef,
        reconId: (report as ReconReportConfig).reconciliationType
          ? (report as ReconReportConfig).reconReportId
          : (report as SummaryReportConfig).summaryReportId,
      });
    }
  });
  return datasources;
};

export const getFilteredData = (
  filterState: ReconciliationFilterState,
  data: DiffReportSyncRow[],
  filterColumns: FilterColumns
) => {
  let filteredData = data ?? [];
  Object.keys(filterState).forEach((key) => {
    const typedKey = key as unknown as keyof ReconciliationFilterState;
    if (filterState[typedKey]?.value?.length) {
      filteredData = filteredData.filter((row) => {
        const columnValue = row.columnValues;
        const column = filterColumns?.[typedKey];
        if (column)
          return (
            (column.source &&
              filterState[typedKey].value?.includes(
                columnValue[column.source]?.value?.toString()
              )) ||
            (column.target &&
              filterState[typedKey].value?.includes(
                columnValue[column.target]?.value?.toString()
              ))
          );
      });
    }
  });
  return filteredData;
};

export const getFilteredBreaksData = (
  filterState: ReconciliationFilterState,
  reportData: DiffReportData,
  filterColumns: FilterColumns
) => ({
  differenceRows:
    getFilteredData(filterState, reportData?.differenceRows, filterColumns) ??
    [],
  sourceOnlyRows:
    getFilteredData(filterState, reportData?.sourceOnlyRows, filterColumns) ??
    [],
  targetOnlyRows:
    getFilteredData(filterState, reportData?.targetOnlyRows, filterColumns) ??
    [],
});

export const getCashActivityReportId = (id: string) => {
  return `cash-activity-${id}`;
};

export const getCashBalanceReportId = (id: string) => {
  return `cash-balance-${id}`;
};

export const getReportId = (
  id: string,
  reconciliationType: ReconciliationTypes
) => {
  return isCashDataSource(reconciliationType)
    ? getCashActivityReportId(id)
    : id;
};

export const getColumnOptions = memoize(
  (
    columnDefs?: ColDef[],
    columnType?:
      | "numberColumn"
      | "dateColumn"
      | "standardRightColumn"
      | "standardColumn"
  ) => {
    const options: SelectOptionsType<string>[] = [];
    columnDefs?.forEach((column) => {
      if (
        (!columnType || columnType === column.type) &&
        !column.hide &&
        column.colId
      )
        options.push({
          label: column.headerName ?? column.colId,
          value: column.colId,
        });
    });
    return options;
  },

  (
    columnDefs?: ColDef[],
    columnType?:
      | "numberColumn"
      | "dateColumn"
      | "standardRightColumn"
      | "standardColumn"
  ) => ({
    columnDefs,
    columnType,
  })
);

export const formatColumnName = (col: string) => {
  return col.charAt(0).toLowerCase() + col.slice(1);
};

const addKey = (
  values: ReconReportTableSyncRow,
  colId: string,
  reconColId: string,
  revertSign = false,
  targetRow = true
) => {
  const formattedColumnId = `${targetRow ? "target" : "source"}(${colId})`;
  const targetValue = values[formatColumnName(formattedColumnId)];
  return {
    ...values,
    [reconColId]: {
      ...targetValue,
      ...(targetValue && {
        value:
          revertSign && typeof targetValue?.value === "number"
            ? targetValue.value * -1
            : targetValue.value,
      }),
    },
  };
};

export const populateDiffAndJoinColumnValues = (
  diffRows: DiffReportData | undefined,
  metadata: WebReconciliationReportMetadata | undefined
) => {
  if (diffRows && metadata) {
    const updatedTargetOnlyRows = diffRows.targetOnlyRows.map((row) => {
      let values = row.columnValues;

      metadata.diffColumnPairs.forEach((pair) => {
        values = addKey(values, pair.targetColumnId, pair.reconColumnId, true);
      });
      metadata.joinColumnPairs.forEach((pair) => {
        values = addKey(values, pair.targetColumnId, pair.reconColumnId);
      });
      return { ...row, columnValues: values } as DiffReportSyncRow;
    });

    const updatedSourceOnlyRows = diffRows.sourceOnlyRows.map((row) => {
      let values = row.columnValues;
      [...metadata.diffColumnPairs, ...metadata.joinColumnPairs].forEach(
        (pair) => {
          values = addKey(
            values,
            pair.sourceColumnId,
            pair.reconColumnId,
            false,
            false
          );
        }
      );

      return { ...row, columnValues: values } as DiffReportSyncRow;
    });

    return {
      ...diffRows,
      targetOnlyRows: updatedTargetOnlyRows,
      sourceOnlyRows: updatedSourceOnlyRows,
    } as DiffReportData;
  }
};

export const isCurrencyColumn = (name: string) => {
  return /currency|ccy/.test(name?.toLowerCase());
};

export const isInstrumentColumn = (name: string) => {
  return /instrument|instr/.test(name?.toLowerCase());
};

export const getBreaksData = (rowData: DiffReportData | undefined) => {
  return rowData
    ? [
        ...(rowData?.targetOnlyRows ?? []),
        ...(rowData?.sourceOnlyRows ?? []),
        ...(rowData?.differenceRows ?? []),
      ]
    : [];
};

export const fetchMetadata = async (path: string, isSummaryReport: boolean) => {
  const response: WebReconciliationReportMetadata | WebReportMetadata =
    isSummaryReport
      ? (await REST_API.REPORTS.GET_REPORT_METADATA.FETCH(path)) ?? undefined
      : (await REST_API.RECONCILIATION.GET_RECONCILIATION_METADATA.FETCH(
          path
        )) ?? undefined;

  const outputMetadata =
    (response as WebReconciliationReportMetadata).outputColumnMetadata ??
    response;
  const defs = createReportColumnDefs({
    columns: outputMetadata.columns ?? [],
    hasChildren: !!outputMetadata.hasChildren,
    columnSort: outputMetadata.columnSort ?? [],
  });
  return { defs, metadata: response };
};

export const getUpdatedDataSources = (
  config: ReconciliationConfig,
  unsavedConfig: ReconciliationConfig
) => {
  const oldConfigDatasources = getAllDatasources(config);
  const newConfigDatasources = getAllDatasources(unsavedConfig);

  const removedDatasources = oldConfigDatasources.filter((datasource) => {
    const item = newConfigDatasources.find(
      (i) => i.reconId === datasource.reconId
    );
    return !item || item.datasource?.path !== datasource.datasource?.path;
  });

  const addedDatasources = newConfigDatasources.filter((datasource) => {
    const item = oldConfigDatasources.find(
      (i) => i.reconId === datasource.reconId
    );
    return !item || item.datasource?.path !== datasource.datasource?.path;
  });

  return { addedDatasources, removedDatasources };
};

export const getColId = (
  columnValues: ReconReportTableSyncRow,
  column: string
) => Object.keys(columnValues).find((key) => key.includes(column)) ?? column;

export const getReconStorageKey = (id: string) => `recon-dashboard-${id}`;

export const getRearrangedColumns = (allColDefs: ColDef<any, any>[]) => {
  const result = allColDefs.reduce(
    (res, colDef) => {
      res[
        BREAK_DETAILS_GRID_PRIORITY_COLUMNS.some((col) =>
          colDef.colId?.includes(col)
        )
          ? "priority"
          : "rest"
      ].push(colDef);
      return res;
    },
    { priority: [], rest: [] } as {
      priority: ColDef<any, any>[];
      rest: ColDef<any, any>[];
    }
  );
  return [...result.priority, ...result.rest];
};
