import { useOEMSOrderForm, workflowStateOptions } from "@enfusion-ui/core";
import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { OfferingType } from "@enfusion-ui/types";
import {
  ControlledSelect,
  ControlledTextInput,
  PanelRow,
} from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

const offeringTypeOptions: Array<{
  value: OfferingType;
  label: OfferingType;
}> = [
  { value: "IPO", label: "IPO" },
  { value: "Secondary", label: "Secondary" },
];

export type GeneralInfoPanelProps = {
  disabled?: boolean;
};

export const OfferingTypeSelect: React.FC<GeneralInfoPanelProps> = ({
  disabled,
}) => {
  return (
    <ControlledSelect
      name="offeringType"
      label="Offering Type"
      options={offeringTypeOptions}
      disabled={disabled}
      inputId="general-info-offering-type-selection-id"
    />
  );
};

export const WorkFlowStateSelect: React.FC<GeneralInfoPanelProps> = ({
  disabled,
}) => {
  const { settings } = useOEMSOrderForm();
  return (
    <ControlledSelect
      name="workflowState"
      label="Workflow State"
      options={workflowStateOptions(
        settings?.enableFinalReviewWorkflowState ?? false
      )}
      disabled={disabled}
      clearable={false}
      inputId="general-info-workflow-state-selection-id"
    />
  );
};

export const ParentOrderInput: React.FC<GeneralInfoPanelProps> = ({
  disabled,
}) => {
  return (
    <ControlledTextInput
      readOnly
      name="parentOrderId"
      label="Parent Order"
      defaultValue="No Parent Exists"
      nullValue={0}
      disabled={disabled}
    />
  );
};

export const TradeReasonSelect: React.FC<GeneralInfoPanelProps> = ({
  disabled,
}) => {
  const { options: tradeReasonOptions, loading: tradeReasonsLoading } =
    useRestAbortableOptions(REST_API.OEMS.GET_TRADE_REASON_TEMPLATES.FETCH);

  return (
    <ControlledSelect
      name="tradeReason"
      label="Trade Reason"
      options={tradeReasonOptions}
      isLoading={tradeReasonsLoading}
      noOptionsMessage={() =>
        "No templates found. Contact help desk to configure."
      }
      disabled={disabled}
      creatable
      inputId="trade-reason-id"
    />
  );
};

export const AllocationReasonSelect: React.FC<GeneralInfoPanelProps> = ({
  disabled,
}) => {
  const { options: allocReasonOptions, loading: allocReasonsLoading } =
    useRestAbortableOptions(
      REST_API.OEMS.GET_ALLOCATION_REASON_TEMPLATES.FETCH
    );

  return (
    <ControlledSelect
      name="allocReason"
      label="Alloc Reason"
      options={allocReasonOptions}
      isLoading={allocReasonsLoading}
      noOptionsMessage={() =>
        "No templates found. Contact help desk to configure."
      }
      disabled={disabled}
      creatable
      inputId="general-info-alloc-reason-selection-id"
    />
  );
};

export const InstructionsInput: React.FC<GeneralInfoPanelProps> = ({
  disabled,
}) => {
  return (
    <ControlledTextInput
      name="instructions"
      label="Instructions"
      textarea
      disabled={disabled}
      style={{ resize: "vertical", minHeight: "4.75rem" }}
    />
  );
};

export const ComplianceNotesInput: React.FC<GeneralInfoPanelProps> = ({
  disabled,
}) => {
  return (
    <ControlledTextInput
      name="complianceNotes"
      label="Compliance Notes"
      textarea
      disabled={disabled}
      style={{ resize: "vertical", minHeight: "4.75rem" }}
      maxLength={250}
    />
  );
};

export const GeneralInfoPanel: React.FC<GeneralInfoPanelProps> = ({
  disabled = false,
}) => {
  return (
    <div>
      <PanelRow>
        <OfferingTypeSelect disabled={disabled} />
        <WorkFlowStateSelect disabled={disabled} />
      </PanelRow>
      <PanelRow>
        <ParentOrderInput disabled={disabled} />
      </PanelRow>
      <PanelRow>
        <TradeReasonSelect disabled={disabled} />
        <AllocationReasonSelect disabled={disabled} />
      </PanelRow>
      <PanelRow>
        <InstructionsInput disabled={disabled} />
        <ComplianceNotesInput disabled={disabled} />
      </PanelRow>
    </div>
  );
};

export default GeneralInfoPanel;
