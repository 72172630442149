import { useSelectDataValue } from "@app-views/dashboards/pdf/utils";
import { EMPTY_RECORD } from "@enfusion-ui/core";
import {
  TraderSelectionWidgetConfig,
  useChannelDataValues,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType, SelectOptionsType, Trader } from "@enfusion-ui/types";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

import PdfSelectionWidget from "../PdfSelectionWidget";

const PdfTraderSelectionWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const {
    defaultValue,
    key,
    paramChannelIds = EMPTY_RECORD,
    paramStaticValues = EMPTY_RECORD,
  } = config as TraderSelectionWidgetConfig;

  useSelectDataValue(key, "Trader IDs", ChannelDataType.Number, defaultValue);

  const paramChannelData = useChannelDataValues(paramChannelIds);
  const params = React.useMemo(() => {
    return { ...paramChannelData, ...paramStaticValues };
  }, [paramChannelData, paramStaticValues]);

  const [traderOptions, setTraderOptions] = React.useState<
    SelectOptionsType<number>[]
  >([]);
  const [inputValue, setInputValue] = React.useState<string | null>(null);

  React.useEffect(() => {
    const traderOptions: SelectOptionsType<number>[] = [];

    const getTraderOptions = async (id?: number) => {
      const traders = id
        ? await REST_API.SECURITY.GET_TRADERS.FETCH(id)
        : await REST_API.SECURITY.GET_ALL_TRADERS.FETCH();
      traders?.forEach((trader: Trader) => {
        traderOptions.push({ value: trader.id, label: trader.fullName });
      });
      setTraderOptions(traderOptions);
    };

    const loadTraderOptions = async () => {
      try {
        params.desk && params.desk.length
          ? params.desk.forEach((id: number) => getTraderOptions(id))
          : getTraderOptions();
      } catch (err) {
        console.error("trader list load error", err);
      }
    };

    loadTraderOptions();
  }, [params.desk]);

  React.useEffect(() => {
    if (defaultValue) {
      const selectedValues = defaultValue
        .map((value) => {
          const selectedValue = traderOptions.find(
            ({ value: val }) => val === value
          );
          return selectedValue?.label;
        })
        .filter((value) => value);

      const formattedValue = selectedValues.join(", ");
      setInputValue(formattedValue);
    }
  }, [traderOptions, defaultValue]);

  return <PdfSelectionWidget label={key} value={inputValue} />;
};

export default PdfTraderSelectionWidget;
