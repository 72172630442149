import * as React from "react";

function TemplateGraphic() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="148"
      height="114"
      fill="none"
      viewBox="0 0 148 114"
    >
      <rect
        width="147.5"
        height="113.5"
        x="0.25"
        y="0.25"
        fill="#202225"
        stroke="#4F545C"
        strokeWidth="0.5"
        rx="1.75"
      ></rect>
      <rect width="88" height="31" x="53" y="8" fill="#2F3136" rx="2"></rect>
      <rect width="43" height="15" x="8" y="8" fill="#25272B" rx="2"></rect>
      <rect width="43" height="14" x="8" y="25" fill="#25272B" rx="2"></rect>
      <rect width="88" height="31" x="53" y="41" fill="#2F3136" rx="2"></rect>
      <rect width="43" height="31" x="8" y="41" fill="#2F3136" rx="2"></rect>
      <rect width="43" height="31" x="8" y="74" fill="#2F3136" rx="2"></rect>
      <rect width="88" height="31" x="53" y="74" fill="#2F3136" rx="2"></rect>
    </svg>
  );
}

export default TemplateGraphic;
