import { Button, ButtonContainer } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faClone, faSave } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";

const TRADE_TICKET_ACTION_BUTTONS = {
  cancel: "Cancel",
  duplicate: "Duplicate",
  save: "Save",
  saveAndClose: "Save & Close",
} as const;

const ActionButtonsContainer = styled(ButtonContainer)`
  background-color: var(--background-color-0);
  padding: var(--spacing-l) var(--spacing-xl);
  display: flex;
  justify-content: flex-end;
  max-width: unset;
`;

const ActionButton = styled(Button)`
  flex-shrink: 0;
  width: max-content;
`;

export const TradeTicketActionButtons: React.FC = () => {
  return (
    <ActionButtonsContainer>
      <ActionButton>{TRADE_TICKET_ACTION_BUTTONS.cancel}</ActionButton>

      <ActionButton icon={faClone} iconPosition="left" disabled>
        {TRADE_TICKET_ACTION_BUTTONS.duplicate}
      </ActionButton>

      <ActionButton icon={faSave} iconPosition="left" disabled>
        {TRADE_TICKET_ACTION_BUTTONS.save}
      </ActionButton>

      <ActionButton primary>
        {TRADE_TICKET_ACTION_BUTTONS.saveAndClose}
      </ActionButton>
    </ActionButtonsContainer>
  );
};
