import {
  CashFlowType,
  cashFlowTypesOption,
  MultiSelectOptionsType,
} from "@enfusion-ui/types";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import * as React from "react";

export type CashFlowTypesSelectProps = Omit<
  Partial<MultiSelectProps<CashFlowType>>,
  "options" | "value" | "onChange"
> & {
  value?: CashFlowType[] | null;
  onChange?: (values: CashFlowType[] | null) => void;
};

const getValues = (
  values: CashFlowType[],
  options: MultiSelectOptionsType[] = []
): MultiSelectOptionsType[] => {
  const result: MultiSelectOptionsType[] = [];
  values.forEach((value) => {
    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption) {
      result.push(selectedOption);
    }
  });
  return result;
};

const CashFlowSelect: React.FC<CashFlowTypesSelectProps> = ({
  value: values,
  onChange,
  placeholder = "All",
  label = "Cash Flow Type(s)",
  ...rest
}) => {
  const [selectedCashFlowTypes, setSelectedCashFlowTypes] = React.useState<
    CashFlowType[] | null
  >([]);

  React.useEffect(() => {
    values && setSelectedCashFlowTypes(values);
  }, [values]);

  return (
    <MultiSelect
      {...rest}
      value={
        selectedCashFlowTypes
          ? getValues(selectedCashFlowTypes, cashFlowTypesOption)
          : []
      }
      onChange={(selectedOptions) => {
        const newCashFlowTypes = selectedOptions?.length
          ? selectedOptions?.map(
              (opt: MultiSelectOptionsType) => opt?.value as CashFlowType
            )
          : null;
        setSelectedCashFlowTypes(newCashFlowTypes);
        onChange?.(newCashFlowTypes);
      }}
      label={label}
      placeholder={placeholder}
      options={cashFlowTypesOption}
    />
  );
};

export const ControlledCashFlowSelect: React.FC<
  Omit<CashFlowTypesSelectProps, "value" | "onChange"> & {
    name?: string;
  }
> = ({ name = "cashFlowTypes", ...props }) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...renderProps }) => (
        <CashFlowSelect {...renderProps} {...props} />
      )}
    />
  );
};

export default CashFlowSelect;
