/* eslint-disable @typescript-eslint/no-explicit-any */
import { EMPTY_ARRAY } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { WebReportQuery } from "@enfusion-ui/types";
import { Button } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { pick } from "lodash";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  FormsConfig,
  ParamConfig,
} from "./ReportQueryComponents/config/FormConfig";
import ReportBaseForm from "./ReportQueryComponents/ReportBaseForm";
import ReportQueryFormWidget from "./ReportQueryForms/ReportQueryFormWidget";

export const reportBaseParams = (reportType: WebReportQuery["type"]) => {
  switch (reportType) {
    case "WebPositionReportQuery": {
      return [
        "accountSelection",
        "datePeriodSelection",
        "instrumentIds",
        "portfolioGroupIds",
        "marketEnvironmentId",
      ];
    }
    case "WebPositionCashFlowQuery": {
      return [
        "accountSelection",
        "dateSelection",
        "instrumentIds",
        "marketEnvironmentId",
      ];
    }
    case "WebGLBalanceSheetQuery": {
      return ["generalLedgerIds", "valueDate", "accountingMethod"];
    }
    case "WebGLIncomeStatementQuery": {
      return ["generalLedgerIds", "period", "accountingMethod"];
    }
    case "WebInstrumentQuery": {
      return [
        "freeForm",
        "financialSubTypes",
        "maxResults",
        "primaryOnly",
        "ignoreInactive",
      ];
    }
    case "WebOrderBlotterQuery": {
      return [
        "dateRange",
        "assignedTraderIds",
        "assignedDeskIds",
        "complianceStates",
        "maxResults",
      ];
    }
    case "WebGLCashBalancesQuery": {
      return ["generalLedgerIds", "accountingMethod", "valueDate"];
    }
    case "WebGLCashFlowStatementQuery": {
      return ["generalLedgerIds", "period"];
    }
    case "WebGLCashActivityQuery": {
      return ["period", "modificationDateRange", "includeAllGLActivity"];
    }
    case "WebGLDistributionLineQuery": {
      return [
        "period",
        "modificationDateRange",
        "maxResults",
        "entrySources",
        "excludeTemplateEntries",
      ];
    }
    case "WebGLPositionQuery": {
      return ["generalLedgerIds", "accountingMethod", "period"];
    }
    case "WebGLTrialBalancesQuery": {
      return [
        "generalLedgerIds",
        "accountingMethod",
        "period",
        "calculationMethod",
      ];
    }
    case "WebTradeBlotterQuery": {
      return [
        "portfolioGroupIds",
        "instrumentIds",
        "instrumentTypes",
        "valueDate",
        "maxResults",
      ];
    }
    case "WebTradeValuationReportQuery": {
      return [
        "instrumentIds",
        "financialSubTypes",
        "valueDatePeriodSelection",
        "benchmarkNAVSource",
        "benchmarkCalculationType",
        "portfolioAnalyticsSources",
        "fundBenchmarkType",
        "includeUnderlyings",
      ];
    }
    case "WebQuotesQuery": {
      return [
        "quoteSet",
        "source",
        "fromDate",
        "toDate",
        "assetMeasure",
        "instrumentIds",
        "maxResults",
      ];
    }
    default:
      return [];
  }
};

type ReportQueryFormsConfigPropType = {
  reportQuery: WebReportQuery;
  onSubmit?: (query: WebReportQuery) => void;
  onFormValuesChanged?: (query: WebReportQuery) => void;
  initValues: WebReportQuery;
  asWidget?: boolean;
  fixedReport?: boolean;
};

const FormContainer = styled.div<{ direction: "column" | "row" }>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  grid-gap: var(--spacing-l);
  overflow: auto;
  padding-bottom: var(--spacing);
`;

const FormElement = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
`;

const InlineFormElement = styled(FormElement)`
  max-width: 17rem;
`;

const ButtonWrapper = styled.div`
  margin: 0 var(--spacing);
  width: 120px;
  height: 38px;
  display: flex;
  align-self: flex-end;

  :focus-within {
    border: 1px solid var(--primary);
  }
`;

export const SimpleForm: React.FC<React.PropsWithChildren<{
  defaultValues: Record<string, any>;
  onSubmit?: (values: any) => void;
  onFormValuesChanged?: (values: any) => void;
  initValues: Record<string, any>;
  keysToWatch: string[];
  direction: "column" | "row";
}>> = ({
  defaultValues,
  onSubmit,
  onFormValuesChanged,
  initValues,
  keysToWatch = EMPTY_ARRAY,
  children,
  direction,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formMethods = useForm<Record<string, any>>({
    defaultValues: initValues,
  });

  const {
    handleSubmit,
    getValues,
    watch,
    formState: { isDirty },
  } = formMethods;

  const valuesToCheck = watch(keysToWatch);
  const dirtyRef = React.useRef<boolean>(isDirty);

  /*
   * In event when the parent component wants to act on form submit of the form, onSubmit callback can be passed
   */
  const submitForm = React.useCallback((data: Record<string, unknown>) => {
    onSubmit?.({
      ...defaultValues,
      ...data,
    });
  }, []);

  /*
   * In event when the parent component does not want to act on form submit
   * but on form value changes, like in case of ReportEditWidget, onFormValuesChanged callback can be passed
   */
  React.useEffect(() => {
    if (isDirty || dirtyRef.current)
      onFormValuesChanged?.({
        ...defaultValues,
        ...getValues(),
      });
    dirtyRef.current = isDirty;
  }, [getValues, ...Object.values(valuesToCheck), isDirty]);

  return (
    <FormProvider {...formMethods}>
      <FormContainer
        as="form"
        onSubmit={handleSubmit(submitForm)}
        direction={direction}
      >
        {children}
      </FormContainer>
    </FormProvider>
  );
};

const ReportQueryForm: React.FC<ReportQueryFormsConfigPropType> = ({
  reportQuery,
  asWidget = false,
  onSubmit,
  onFormValuesChanged,
  initValues,
  fixedReport,
}) => {
  const queryRef = React.useRef(reportQuery);
  queryRef.current = reportQuery;

  const reportType = reportQuery.type;
  let configs = FormsConfig[reportType as WebReportQuery["type"]];
  if (fixedReport) {
    configs = configs.filter((config) => config.name !== "accountSelection");
  }

  const handleFormChange = useRefCallback(
    (values: any) => {
      const keys = configs.map((i) => i.name);
      return onFormValuesChanged?.(pick(values, keys) as any);
    },
    [configs, onFormValuesChanged]
  );

  return (
    <SimpleForm
      defaultValues={reportQuery}
      initValues={initValues}
      onSubmit={onSubmit}
      onFormValuesChanged={handleFormChange}
      keysToWatch={reportBaseParams(reportType)}
      direction={asWidget ? "column" : "row"}
    >
      {configs.map((eachConfig: ParamConfig, idx: number) =>
        asWidget ? (
          <FormElement key={eachConfig.name} tabIndex={idx}>
            <ReportQueryFormWidget
              reportQuery={reportQuery}
              param={eachConfig}
              key={eachConfig.name}
            />
          </FormElement>
        ) : (
          <InlineFormElement key={eachConfig.name} tabIndex={idx}>
            <ReportBaseForm
              paramType={eachConfig.staticType ?? ""}
              key={eachConfig.name}
              reportQuery={reportQuery}
              name={eachConfig.name}
            />
          </InlineFormElement>
        )
      )}
      {!asWidget && (
        <ButtonWrapper tabIndex={configs.length}>
          <Button type="submit" name="apply">
            Apply
          </Button>
        </ButtonWrapper>
      )}
    </SimpleForm>
  );
};

export default ReportQueryForm;
