import { usePortfolio } from "@app-context/portfolios/PortfolioProvider";
import { ICellRendererParams } from "ag-grid-community";
import * as React from "react";

import ColumnSelect from "../../../../components/inputs/ColumnSelect";

const PortfolioManagerCellRenderer: React.FC<
  ICellRendererParams & {
    allowGroup?: boolean;
  }
> = (params) => {
  const {
    portfolioManagerData: { options, loading },
  } = usePortfolio();

  if (!params.allowGroup && params.node.group) {
    return <>{params.valueFormatted ?? null}</>;
  }

  return (
    <div style={{ pointerEvents: "none" }}>
      <ColumnSelect
        name="portfolioManager"
        options={options}
        isLoading={loading}
        clearable={false}
        mobileBasis="50%"
        minWidth={50}
        {...params}
      />
    </div>
  );
};

export default PortfolioManagerCellRenderer;
