import {
  basicInfoMap,
  useRefCallback,
  useRestAbortableOptions,
} from "@enfusion-ui/hooks";
import { SelectOptionsType } from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

export type ShareClassMultiselectProps = Omit<
  Partial<MultiSelectProps<number>>,
  "options" | "value" | "onChange"
> & {
  value?: number[] | null;
  onChange?: (values: number[]) => void;
  label?: string;
};

const ShareClassMultiselect: React.FC<ShareClassMultiselectProps> = ({
  value: values,
  onChange,
  label,
  ...rest
}) => {
  const [shareClass, setShareClass] = React.useState<number[]>([]);

  const { options: shareClassOptions, loading } = useRestAbortableOptions(
    REST_API.FUND.GET_SHARE_CLASSES.FETCH,
    basicInfoMap
  );

  React.useEffect(() => {
    setShareClass(values ?? []);
  }, [values]);

  const handleChange = useRefCallback(
    (values: SelectOptionsType<number>[]) => {
      const ids = values?.length
        ? values.map((option: SelectOptionsType<number>) => option.value)
        : [];
      setShareClass(ids);
      onChange?.(ids);
    },
    [onChange]
  );

  return (
    <MultiSelect
      {...rest}
      value={shareClass ? getSelectedValues(shareClass, shareClassOptions) : []}
      onChange={(values) => handleChange(values as SelectOptionsType<number>[])}
      label={label}
      options={shareClassOptions}
      loading={loading}
    />
  );
};

export const ControlledShareClassMultiselect: React.FC<
  Omit<ShareClassMultiselectProps, "value" | "onChange"> & { name?: string }
> = ({ label = "Share Class", name = "shareClassIds", ...props }) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...renderProps }) => (
        <ShareClassMultiselect label={label} {...renderProps} {...props} />
      )}
    />
  );
};

export default ShareClassMultiselect;
