import { TextInput } from "@enfusion-ui/web-components";
import { REPORT_ROW_HEIGHT } from "@enfusion-ui/web-core";
import { ICellRendererParams } from "ag-grid-enterprise";
import * as React from "react";

const TextInputCellRenderer: React.FC<
  ICellRendererParams & {
    cellStyle?: React.CSSProperties;
    allowGroup?: boolean;
  }
> = ({ value, cellStyle, allowGroup, node }) => {
  if (!allowGroup && node.group) return null;

  return (
    <TextInput
      minWidth="50px"
      style={{
        height: REPORT_ROW_HEIGHT - 3,
        pointerEvents: "none",
        ...cellStyle,
      }}
      value={value}
      hideLabel
    />
  );
};

export default TextInputCellRenderer;
