import { EMPTY_RECORD } from "@enfusion-ui/core";
import { useWidget, WidgetWithDatasourceConfig } from "@enfusion-ui/dashboards";
import { ChannelDataIdentifier, WebReportQuery } from "@enfusion-ui/types";
import { RadioGroup, RadioOption } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { startCase } from "lodash";
import * as React from "react";

import ChannelDataPicker from "../../../dashboards/widget/components/ChannelDataPicker";
import { ParamConfig } from "../ReportQueryComponents/config/FormConfig";
import ReportBaseForm from "../ReportQueryComponents/ReportBaseForm";
import { useReportWidgetQueryFormContext } from "./context";

const InputContainerStatic = styled.div`
  margin-right: var(--spacing);
  min-width: 250px;
  width: 80%;
`;

const InputContainer = styled.div`
  margin-right: var(--spacing);
  min-width: 250px;
  width: 80%;
`;

export type FilterFieldMode = "static" | "dynamic";

interface FilterFieldModeOption extends RadioOption {
  label: string;
  value: FilterFieldMode;
}

const fieldModeOptions: FilterFieldModeOption[] = [
  { label: "Static", value: "static" },
  { label: "Dynamic", value: "dynamic" },
];

type ReportQueryFormWidgetProps = {
  reportQuery: WebReportQuery;
  param: ParamConfig;
  allowFieldModeChange?: boolean;
};

const ReportQueryFormWidget: React.FC<ReportQueryFormWidgetProps> = ({
  param,
  reportQuery,
  allowFieldModeChange: allowFieldModeChangeOverride,
}) => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const { defaultFieldMode, allowFieldModeChange: allowFieldModeChangeBase } =
    useReportWidgetQueryFormContext();

  const allowFieldModeChange =
    allowFieldModeChangeOverride ?? allowFieldModeChangeBase;

  const handleParamChannelIdChange =
    (key: string) => (value: ChannelDataIdentifier | null) => {
      changeConfigKeyToBeApplied("paramChannelIds", {
        ...paramChannelIds,
        [key]: value,
      });
    };

  const handleFilterModeChange = (
    key: string,
    selectedMode: FilterFieldMode
  ) => {
    changeConfigKeyToBeApplied("isStaticFilterSelection", {
      ...isStaticFilterSelection,
      [key]: selectedMode === "static",
    });

    if (selectedMode === "static") {
      delete paramChannelIds[key];
      changeConfigKeyToBeApplied("paramChannelIds", paramChannelIds);
    }
    return EMPTY_RECORD;
  };

  const {
    paramChannelIds = EMPTY_RECORD,
    isStaticFilterSelection = EMPTY_RECORD,
  } = editedConfig as WidgetWithDatasourceConfig;

  const [fieldMode, setFieldMode] = React.useState<FilterFieldMode>(
    defaultFieldMode ??
      (!(param.name in isStaticFilterSelection) ||
        isStaticFilterSelection[param.name])
      ? "static"
      : "dynamic"
  );

  return (
    <>
      {fieldMode === "static" ? (
        <InputContainerStatic>
          <ReportBaseForm
            paramType={param.staticType ?? ""}
            key={param.name}
            reportQuery={reportQuery}
            name={param.name}
          />
        </InputContainerStatic>
      ) : (
        <InputContainer>
          <ChannelDataPicker
            type={param.dynamicType}
            key={param.name}
            label={startCase(param.name)}
            value={paramChannelIds[param.name]}
            onChange={handleParamChannelIdChange(param.name)}
          />
        </InputContainer>
      )}

      {allowFieldModeChange && (
        <div style={{ marginTop: -4 }}>
          <RadioGroup
            name=""
            options={fieldModeOptions}
            value={fieldMode}
            onChange={(val) => {
              setFieldMode(val as FilterFieldMode);
              handleFilterModeChange(param.name, val as FilterFieldMode);
            }}
            vertical
            labelPlaceholder
          />
        </div>
      )}
    </>
  );
};

export default ReportQueryFormWidget;
