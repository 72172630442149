import { TradeTicketFormType } from "@enfusion-ui/trades";
import { EmptyView } from "@enfusion-ui/web-components";
import * as React from "react";

import { TradeTicket } from "./TradeTicket";

type NewTradeViewProps = {
  config: {
    formType: TradeTicketFormType;
  };
};

export const NewTradeView: React.FC<NewTradeViewProps> = ({ config }) => {
  return (
    <EmptyView thinTopBar contentStyles={{ borderTopWidth: 0 }}>
      <TradeTicket tradeId={0} {...config} />
    </EmptyView>
  );
};
