import { ControlledInstrumentSelect } from "@app-components/inputs/InstrumentSelect/ControlledInstrumentSelect";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  conditionalRadioOptions,
  ConditionalValue,
  conventionBtnOptions,
  knockInOutRadioOptions,
  KnockInOutValue,
  knockOutBtnOptions,
  NONE_RADIO,
  underlyingBtnOptions,
} from "@enfusion-ui/trades";
import {
  ButtonGroupSelect,
  ControlledDatePicker,
  ControlledSelect,
  ControlledTextInput,
  InputLabel,
  LabeledButton,
  PanelRow,
} from "@enfusion-ui/web-components";
import { useIsContentMobile } from "@enfusion-ui/web-core";
import React from "react";

import {
  HorizontalRule,
  StyledFormElementHeader,
} from "../../../components/styledComponents";

export const ActivationDetails: React.FC<unknown> = () => {
  const isMobile = useIsContentMobile();
  const [showConditonalInputs, setShowConditionalInputs] =
    React.useState(false);

  const [showSingleBarrierInputs, setShowSingleBarrierInputs] =
    React.useState(false);

  const [knockInOutValue, setKnockInOutValue] =
    React.useState<KnockInOutValue>("NoBarriers");

  const [conditionalValue, setConditionalValue] =
    React.useState<ConditionalValue>("None");

  const [knockOutPaymentsValue, setKnockOutPaymentsValue] = React.useState<
    string | null
  >(null);

  const [barrierInstrumentValue, setBarrierInstrumentValue] = React.useState<
    string | null
  >(null);

  const [conventionValue, setConventionValue] = React.useState<string | null>(
    null
  );

  const handleKnockInOutSelect = useRefCallback(
    (value) => {
      if (value) {
        if (value === "singleBarrier") {
          setShowSingleBarrierInputs(true);
        } else {
          setShowSingleBarrierInputs(false);
        }
        setKnockInOutValue(value);
      }
    },
    [knockInOutValue]
  );

  const handleConditionalSelect = useRefCallback(
    (value: ConditionalValue) => {
      if (value) {
        if (value !== NONE_RADIO) {
          setShowConditionalInputs(true);
        } else {
          setShowConditionalInputs(false);
        }
        setConditionalValue(value);
      }
    },
    [conditionalValue]
  );
  return (
    <>
      <StyledFormElementHeader>Knock In/Out</StyledFormElementHeader>
      <PanelRow>
        <ButtonGroupSelect
          options={knockInOutRadioOptions}
          selectedValue={knockInOutValue}
          onSelect={(value) => handleKnockInOutSelect(value!)}
        />
      </PanelRow>
      <HorizontalRule />
      {showSingleBarrierInputs && (
        <>
          <PanelRow>
            <ControlledSelect
              options={[]}
              label="Barrier Type"
              name="barrierType"
            />
            <ControlledTextInput name="barrierLevel" label="Barrier Level" />
          </PanelRow>

          <StyledFormElementHeader>Activation Details</StyledFormElementHeader>

          <InputLabel>Knock-Out Payment</InputLabel>
          <PanelRow>
            <ButtonGroupSelect
              options={knockOutBtnOptions}
              onSelect={(value) => setKnockOutPaymentsValue(value!)}
              selectedValue={knockOutPaymentsValue}
            />
          </PanelRow>
          <InputLabel>Barrier Instrument</InputLabel>
          <PanelRow>
            <ButtonGroupSelect
              options={underlyingBtnOptions}
              onSelect={(value) => setBarrierInstrumentValue(value!)}
              selectedValue={barrierInstrumentValue}
            />
          </PanelRow>
        </>
      )}
      <ControlledDatePicker name="activationDate" label="Activation Date" />
      <InputLabel>Conditional</InputLabel>
      <PanelRow>
        <ButtonGroupSelect
          options={conditionalRadioOptions}
          selectedValue={conditionalValue}
          onSelect={(value) => handleConditionalSelect(value!)}
        />
      </PanelRow>
      {showConditonalInputs && (
        <>
          <PanelRow>
            <ControlledTextInput label="Lower Barrier" name="lowerBarrier" />
            <ControlledTextInput label="Upper Barrier" name="upperBarrier" />
          </PanelRow>
          <InputLabel>Convention</InputLabel>
          <PanelRow>
            <ButtonGroupSelect
              options={conventionBtnOptions}
              selectedValue={conventionValue}
              onSelect={(value) => setConventionValue(value!)}
            />
          </PanelRow>
          <InputLabel>Barrier Instrument</InputLabel>
          <PanelRow>
            <ButtonGroupSelect
              options={underlyingBtnOptions}
              onSelect={(value) => setBarrierInstrumentValue(value!)}
              selectedValue={barrierInstrumentValue}
            />
          </PanelRow>
        </>
      )}

      <StyledFormElementHeader>Barrier Details</StyledFormElementHeader>
      <PanelRow>
        <ControlledInstrumentSelect
          hideAdvancedSearchButtonText
          label="Alternative Barrier Instrument"
          name="alternativeBarrierInstrument"
        />
        <LabeledButton disabled hideLabelPlaceholder={isMobile}>
          None
        </LabeledButton>
      </PanelRow>
    </>
  );
};
