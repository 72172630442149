import { WidgetComponentsDefinition } from "../../../types";
import AccountingMethodSelectionEditWidget from "./AccountingMethodSelectionEditWidget";
import AccountingMethodSelectionWidget from "./AccountingMethodSelectionWidget";
import PdfAccountingMethodSelectionWidget from "./PdfAccountingMethodSelectionWidget";

export const accountingMethodSelection: WidgetComponentsDefinition = {
  renderComponent: AccountingMethodSelectionWidget,
  editComponent: AccountingMethodSelectionEditWidget,
  renderPdfComponent: PdfAccountingMethodSelectionWidget,
};
