import InstrumentAndStrategyPanel from "@app-views/oems/OrderTicket/panels/InstrumentAndStrategyPanel";
import { useWidget } from "@enfusion-ui/dashboards";
import { WidthHeight100 } from "@enfusion-ui/web-components";
import * as React from "react";

const InstrumentWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const {
    showAdvancedSearch,
    showBookStrategy,
    showCurrentPosition,
    showExistingPositionLink,
  } = config;

  return (
    <WidthHeight100>
      <InstrumentAndStrategyPanel
        showAdvancedSearch={showAdvancedSearch}
        showBookStrategy={showBookStrategy}
        showExistingPositionLink={showExistingPositionLink}
        showPosition={showCurrentPosition}
      />
    </WidthHeight100>
  );
};

export default InstrumentWidget;
