import { pxToPtConversion } from "@app-views/dashboards/pdf/utils";
import { ThemeDefinition } from "@enfusion-ui/core";
import { useWidget } from "@enfusion-ui/dashboards";
import {
  ChannelDataType,
  DateWidgetInputResult,
  WebDateRangeSelection,
  WebDateRangeSymbolSelection,
} from "@enfusion-ui/types";
import {
  generalDateFormat,
  getDateRange,
  getDateRangeSymbol,
  getDateSelectionValue,
  getEvalDate,
  getPeriodDateSelectionValue,
} from "@enfusion-ui/utils";
import { useAuth, withTheme } from "@enfusion-ui/web-core";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { Path, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import * as React from "react";

import PdfDateInput from "./PdfDateInput";
import PdfDateRangeInput from "./PdfDateRangeInput";
import PdfSymbolRangeInput from "./PdfSymbolRangeInput";

const pdfStyles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingVertical: pxToPtConversion(8),
  },
  label: {
    width: "100%",
    fontFamily: "Lato",
    fontSize: pxToPtConversion(12),
    lineHeight: 1.5,
    fontWeight: "light",
    marginLeft: pxToPtConversion(4),
    marginTop: pxToPtConversion(8),
  },
  inputContainer: {
    width: "100%",
    height: "100%",
  },
  input: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textInputContainer: {
    width: "100%",
    minHeight: pxToPtConversion(38),
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: pxToPtConversion(4),
    padding: pxToPtConversion(4),
  },
  textInput: {
    width: "100%",
    height: "100%",
    // justifyContent and alignItems are reversed
    justifyContent: "center",
    alignItems: "flex-start",
  },
  text: {
    fontSize: pxToPtConversion(12),
    fontFamily: "Lato",
    width: "100%",
    maxLines: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  optionBtn: {
    width: pxToPtConversion(27),
    height: pxToPtConversion(23),
    justifyContent: "center",
    alignItems: "center",
  },
});

type PdfDateInputWrapperProps = {
  label?: string;
  value: string | null;
  isSelect?: boolean;
};

export const PdfDateInputWrapper = withTheme(
  ({
    label,
    value,
    isSelect,
    theme,
  }: PdfDateInputWrapperProps & { theme: ThemeDefinition }) => {
    return (
      <View
        style={[
          pdfStyles.container,
          isSelect
            ? {
                marginRight: pxToPtConversion(4),
              }
            : {},
        ]}
      >
        <Text
          style={[
            pdfStyles.label,
            {
              color: theme.colors.textNormal,
            },
          ]}
        >
          {label ? label : " "}
        </Text>
        <View style={[pdfStyles.inputContainer]}>
          <View style={[pdfStyles.input]}>
            <View
              style={[
                pdfStyles.textInputContainer,
                {
                  backgroundColor: theme.colors.inputBackground,
                  border: `1px solid ${theme.colors.inputBorder}`,
                },
              ]}
            >
              {value ? (
                <View style={[pdfStyles.textInput]}>
                  <Text
                    style={[
                      pdfStyles.text,
                      {
                        color: theme.colors.textNormal,
                      },
                    ]}
                  >
                    {value}
                  </Text>
                </View>
              ) : (
                <View style={[pdfStyles.textInput]}>
                  <Text
                    style={[
                      pdfStyles.text,
                      {
                        color: theme.colors.textMuted,
                      },
                    ]}
                  >
                    {isSelect ? "Select..." : "MM/DD/YYYY"}
                  </Text>
                </View>
              )}
              {isSelect && (
                <View
                  style={[
                    pdfStyles.optionBtn,
                    {
                      borderLeft: `1px solid ${theme.colors.textMuted}`,
                      paddingLeft: pxToPtConversion(4),
                    },
                  ]}
                >
                  <Svg
                    width={pxToPtConversion(10)}
                    height={pxToPtConversion(10)}
                    viewBox={`0 0 ${faChevronDown.icon[0]} ${faChevronDown.icon[1]}`}
                  >
                    <Path
                      d={faChevronDown.icon[4] as string}
                      stroke={theme.colors.textMuted}
                      fill={theme.colors.textMuted}
                    />
                  </Svg>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    );
  }
);

const PdfDateWidget: React.FC<unknown> = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { user } = useAuth();

  const { key, defaultValue, inputType = "preset" } = config;

  React.useEffect(() => {
    const options = [
      {
        name: `${key} - Start Pure Date`,
        description: "Start Pure Date Value",
        type: ChannelDataType.Date,
      },
      {
        name: `${key} - End Pure Date`,
        description: "End Pure Date Value",
        type: ChannelDataType.Date,
      },
      {
        name: `${key} - Start Selection Date`,
        description: "Start Selection Date Value",
        type: ChannelDataType.DateSelection,
      },
      {
        name: `${key} - End Selection Date`,
        description: "End Selection Date Value",
        type: ChannelDataType.DateSelection,
      },
      {
        name: `${key} - Date Range`,
        description: "Date Range Value",
        type: ChannelDataType.DateRange,
      },
    ];
    if (inputType === "symbol-range") {
      options.push({
        name: `${key} - Date Symbol Range`,
        description: "Date Symbol Range Value",
        type: ChannelDataType.DateSymbolRange,
      });
    }
    if (inputType === "period") {
      options.push({
        name: `${key} - Period Selection`,
        description: "Period Selection Value",
        type: ChannelDataType.DatePeriodSelection,
      });
    }
    addChannelKeys(options);
  }, [key, inputType]);

  React.useEffect(() => {
    const timeZone = user?.flags?.timeZone || "America/Chicago";

    const periodValue = getPeriodDateSelectionValue(defaultValue, inputType);
    const startValue = getDateSelectionValue(defaultValue, inputType, "end");
    const endValue = getDateSelectionValue(defaultValue, inputType, "start");
    const symbol = getDateRangeSymbol(defaultValue, inputType);

    const start = startValue ? { ...startValue } : null;
    const end = endValue ? { ...endValue } : null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const options: any[] = [
      getEvalDate(timeZone, startValue),
      getEvalDate(timeZone, endValue),
      start,
      end,
      { startDate: start, endDate: end },
    ];
    if (inputType === "symbol-range") {
      const dateRange = symbol
        ? getDateRange(symbol, generalDateFormat, timeZone)
        : [null, null];
      options.push({
        symbol,
        fromDate: dateRange[0],
        toDate: dateRange[1],
      });
    }
    if (inputType === "period") {
      options.push({ ...periodValue });
    }
    setChannelData(options as DateWidgetInputResult);
  }, [defaultValue, inputType]);

  if (inputType === "range") {
    return <PdfDateRangeInput value={defaultValue as WebDateRangeSelection} />;
  } else if (inputType === "symbol-range") {
    return (
      <PdfSymbolRangeInput
        value={defaultValue as WebDateRangeSymbolSelection}
      />
    );
  }
  return <PdfDateInput value={defaultValue} label={key} type={inputType} />;
};

export default PdfDateWidget;
