/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { OrderFormValues } from "@enfusion-ui/core";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { ExecutionDestination } from "@enfusion-ui/types";
import { ControlledSelect } from "@enfusion-ui/web-components";
import { errorToast, REST_API } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext } from "react-hook-form";

export type RoutingDestinationOptions = {
  label: string;
  value: ExecutionDestination;
};

let controller: AbortController;

const RoutingDestinationSelection: React.FC<{
  disabled?: boolean;
}> = ({ disabled = false }) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const {
    setValue,
    watch,
    formState: { isDirty },
  } = useFormContext<
    OrderFormValues & { destinationFixName?: string | null }
  >();
  const isMounted = useMounted();
  const {
    targetCompId,
    instrument,
    assignedTradingDesk,
    swapOrder,
    accountAllocation,
    destination,
    counterpartyId,
    capacity,
    brokerAccount,
    destinationFixName,
  } = watch([
    "targetCompId",
    "instrument",
    "assignedTradingDesk",
    "swapOrder",
    "accountAllocation",
    "destination",
    "counterpartyId",
    "capacity",
    "brokerAccount",
    "destinationFixName",
  ]);
  const destinationRef = React.useRef(destination);
  destinationRef.current = destination;
  const optionsLoadedForRef = React.useRef<string | null>(null);

  const [routingDestinationOptions, setRoutingDestinationOptions] =
    React.useState<RoutingDestinationOptions[]>();

  const setRelatedDropdownState = useRefCallback(
    (destination: ExecutionDestination) => {
      setValue("counterpartyId", destination?.defaultCounterpartyId);
      setValue("destinationFixName", destination?.fixName);
      setValue("capacity", destination?.defaultOrderCapacity);
      setValue("brokerAccount", destination?.defaultBrokerAccountNumber);
    },
    [setValue]
  );

  const resetOtherDropDownState = useRefCallback(() => {
    setValue("counterpartyId", counterpartyId, { shouldDirty: true });
    setValue("destinationFixName", destinationFixName, { shouldDirty: true });
    setValue("capacity", capacity, { shouldDirty: true });
    setValue("brokerAccount", brokerAccount, { shouldDirty: true });
  }, [
    counterpartyId,
    capacity,
    brokerAccount,
    destinationFixName,
    destination,
  ]);

  React.useEffect(() => {
    const getAllElectronicRoutingDestinations = async () => {
      try {
        controller?.abort();
        controller = new AbortController();
        const availableDestinations =
          await REST_API.OEMS.GET_ELECTRONIC_ROUTING_DESTINATIONS.FETCH(
            {
              targetCompId,
              instrumentId: instrument?.id,
              financialSubType: instrument?.financialSubType?.description,
              assignedTradingDesk,
              swapOrder,
              accountAllocation,
            },
            controller
          );
        setRoutingDestinationOptions(
          availableDestinations.map(
            (eachDestination: ExecutionDestination) => ({
              label: eachDestination.description,
              value: eachDestination,
            })
          )
        );
        if (
          availableDestinations &&
          availableDestinations.length === 1 &&
          (typeof destination === "string"
            ? destination
            : destination?.description) !==
            availableDestinations?.[0].description
        ) {
          setValue("destination", availableDestinations?.[0], {
            shouldDirty: true,
          });
          setRelatedDropdownState(availableDestinations[0]);
        } else {
          resetOtherDropDownState();
          if (
            isDirty &&
            !availableDestinations.find((availableDestination) => {
              return (
                availableDestination.description ===
                (typeof destinationRef.current === "object"
                  ? destinationRef.current?.description
                  : destination)
              );
            })
          ) {
            setValue("destination", null);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        optionsLoadedForRef.current = targetCompId;
        setLoading(false);
      }
    };
    if (isMounted()) {
      if (targetCompId) {
        setLoading(true);
        optionsLoadedForRef.current = null;
        getAllElectronicRoutingDestinations();
      } else {
        setRoutingDestinationOptions([]);
        setValue("destination", null);
        setLoading(false);
      }
    }
  }, [
    targetCompId,
    instrument?.id,
    instrument?.financialSubType?.description,
    assignedTradingDesk,
    swapOrder,
    accountAllocation,
  ]);

  const setOptionOnSetChange = useRefCallback(() => {
    const destinationToSet = routingDestinationOptions?.findIndex((e) => {
      if (typeof destinationRef.current === "string") {
        return e.label === destinationRef.current;
      } else {
        return e.label === destinationRef.current?.description;
      }
    });
    if (destinationToSet === -1 && destinationRef.current && !loading) {
      errorToast(
        `The destination ${
          typeof destinationRef.current === "string"
            ? destinationRef.current
            : destinationRef.current.description
        } no longer exists`,
        {
          toastId: JSON.stringify(destinationRef.current),
        }
      );
    } else if (
      routingDestinationOptions?.length &&
      destinationToSet !== undefined
    ) {
      setValue(
        "destination",
        routingDestinationOptions[destinationToSet]?.value,
        {
          shouldDirty: true,
        }
      );
    }
  }, [routingDestinationOptions, loading]);

  React.useEffect(() => {
    setOptionOnSetChange();
  }, [routingDestinationOptions]);

  React.useEffect(() => {
    if (
      typeof destinationRef.current === "string" &&
      optionsLoadedForRef.current === targetCompId &&
      !loading
    ) {
      setOptionOnSetChange();
    }
  }, [JSON.stringify(destinationRef.current), targetCompId, loading]);

  return (
    <ControlledSelect
      disabled={disabled}
      name="destination"
      label="Destination"
      options={routingDestinationOptions}
      isLoading={loading}
      onChange={(value) => {
        setRelatedDropdownState(value as ExecutionDestination);
      }}
      mobileBasis="50%"
      inputId="destination-selection-id"
    />
  );
};

export default RoutingDestinationSelection;
