import UploadFileModal from "@app-components/modal/UploadFileModal";
import { StorageRoot } from "@enfusion-ui/types";

import { useServicesExplorer } from "../../../context/services/ServicesExplorerProvider";
import { filesProviderMap } from "../ServicesSidebar";

export default function ServicesUploadFileModal({
  storageRoot,
}: {
  storageRoot: StorageRoot;
}) {
  const { uploadFileModalPath, closeUploadFileModal, section } =
    useServicesExplorer();

  const useFiles = filesProviderMap[storageRoot];
  const { uploadFiles, checkForDuplicates } = useFiles();

  return (
    <UploadFileModal
      open={storageRoot === section && uploadFileModalPath !== null}
      filePath={uploadFileModalPath ?? undefined}
      onCancel={closeUploadFileModal}
      checkDuplicateFiles={checkForDuplicates}
      onSubmit={uploadFiles}
    />
  );
}
