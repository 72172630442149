import {
  useReconciliation,
  useReconciliationBreakDetails,
} from "@app-context/reconciliation/context";
import { LoadingContent } from "@app-views/reconciliation/components/LoadingContent";
import {
  MetricsSectionContainer,
  PaddedSectionContainer,
} from "@app-views/reconciliation/components/styles";
import { getReportId } from "@app-views/reconciliation/utils/commonUtils";
import { FormPanel } from "@enfusion-ui/web-components";
import React from "react";

import { BreaksByCode } from "./BreaksByCode";
import { BreaksByCurrency } from "./BreaksByCurrency";
import { BreaksByCustodian } from "./BreaksByCustodian";

export const BreakDetailsMetricsPanel: React.FC<{}> = React.memo(() => {
  const { reportsLoadingStatus } = useReconciliation();
  const { breakDetailsRendererData: data } = useReconciliationBreakDetails();
  const loading =
    reportsLoadingStatus?.[getReportId(data.id, data.reconciliationType)];
  return (
    <FormPanel
      lineStyle
      collapsible
      keepRendered
      numColumns={1}
      defaultOpen
      title="Metrics"
      showTextCollapseButton
      gapSize="l"
      titleStyle={{
        padding: "0 var(--spacing-l) var(--spacing-l) var(--spacing-l)",
        fontSize: "14px",
      }}
      thinContainer
      render={() => (
        <PaddedSectionContainer>
          <MetricsSectionContainer id="metrics">
            {loading ? (
              <LoadingContent />
            ) : (
              <>
                <BreaksByCode />
                <BreaksByCustodian />
                <BreaksByCurrency />
              </>
            )}
          </MetricsSectionContainer>
        </PaddedSectionContainer>
      )}
    />
  );
});
