import {
  DateValue,
  WebDatePeriodSelection,
  WebDateSelection,
} from "@enfusion-ui/types";
import { formatDate } from "@enfusion-ui/utils";
import * as React from "react";

import { PdfDateInputWrapper } from "./PdfDateWidget";

type PdfDateInputProps = {
  value: DateValue;
  label: string;
  type: string;
};

const PdfDateInput: React.FC<PdfDateInputProps> = ({ value, label, type }) => {
  const [inputValue, setInputValue] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (value) {
      const formattedValue =
        type === "date"
          ? formatDate(value as string)
          : type === "preset"
          ? (value as WebDateSelection).dateSelectionType
          : (value as WebDatePeriodSelection).dateSelection.dateSelectionType;
      setInputValue(formattedValue);
    }
  }, [value]);

  return (
    <PdfDateInputWrapper
      value={inputValue}
      label={label}
      isSelect={type !== "date"}
    />
  );
};

export default PdfDateInput;
