import { ReportSettingsDefinition } from "../../types";
import PortfolioAnalyticsQuickFilter from "./PortfolioAnalyticsQuickFilter";
import PortfolioAnalyticsSettings from "./PortfolioAnalyticsSettings";

export const WebTradeValuationReportQuery: ReportSettingsDefinition = {
  name: "TradeValuation",
  queryType: "WebTradeValuationReportQuery",
  quickFilterComponent: PortfolioAnalyticsQuickFilter,
  settingsComponent: PortfolioAnalyticsSettings,
};
