import { PORTFOLIO_LATEST_CONFIG_VERSION, } from "@enfusion-ui/core";
import { format } from "date-fns";
import { cloneDeep, isEqual, omit } from "lodash";
import { mainPortfolioGridColumnDefs } from "./columns";
import { defaultPWBColumnState } from "./defaultColumnState";
export const defaultPortfolioGroupingsV1 = ["displayInstrument"];
const defaultConfigV1 = {
    instrumentIds: [],
    fundIds: [],
    groupings: [...defaultPortfolioGroupingsV1],
};
export const defaultPortfolioSettingsValueV1 = {
    numeratorAggregation: "DeltaAdjusted",
    constituentDisplay: true,
    denominator: "GLNAV",
    includeActiveOrders: false,
    roundToLotSize: true,
    lotSizeType: "SecurityLotSize",
    lotSize: 1,
    normalize: true,
    rollUp: false,
    quantity: null,
    exposure: null,
    perIncremental: null,
    perDiff: null,
    checkComplianceAutomatically: false,
    rebalancingDurationType: "ModifiedDuration",
    rebalanceCalculationLevel: "Fund",
    valueDate: format(new Date(), "yyyy-MM-dd"),
    marketEnvironment: null,
    resolveBenchmarksByBook: false,
    gridColumnState: [...defaultPWBColumnState],
};
export const portfolioDefaultState = {
    name: "",
    id: null,
    version: 0,
    configVersion: PORTFOLIO_LATEST_CONFIG_VERSION,
    hasUnsavedChanges: false,
    oldConfig: {
        ...cloneDeep(defaultConfigV1),
    },
    oldSettings: {
        ...cloneDeep(defaultPortfolioSettingsValueV1),
    },
    config: {
        ...cloneDeep(defaultConfigV1),
    },
    settings: {
        ...cloneDeep(defaultPortfolioSettingsValueV1),
    },
    error: null,
    loading: true,
    historyStack: [],
    futureStack: [],
};
function updateConfig(config) {
    config.groupings = (config.groupings || []).map((i) => i === "instrument" ? "displayInstrument" : i);
    return config;
}
function updateSettings(settings) {
    if (!settings.gridColumnState?.length) {
        settings.gridColumnState = defaultPWBColumnState;
    }
    const validColIds = new Set([
        ...mainPortfolioGridColumnDefs.map((d) => d.colId),
        "ag-Grid-AutoColumn",
    ]);
    settings.gridColumnState = settings.gridColumnState.filter((i) => validColIds.has(i.colId));
    if (!settings.groupRowExpandedState)
        settings.groupRowExpandedState = [];
    return settings;
}
export function portfoliosProviderReducer(state, action) {
    if (action.type === "hydrate") {
        const { config, version, settings = portfolioDefaultState.settings, name, id, } = action.payload;
        return {
            ...state,
            version,
            name,
            hasUnsavedChanges: false,
            config: cloneDeep(updateConfig(config)),
            settings: cloneDeep(updateSettings(settings)),
            oldConfig: cloneDeep(updateConfig(config)),
            oldSettings: cloneDeep(updateSettings(settings)),
            error: null,
            loading: false,
            id,
            historyStack: [],
            futureStack: [],
        };
    }
    else if (action.type === "set-max-sockets-reached" ||
        action.type === "set-error") {
        return {
            ...state,
            error: action.payload,
            loading: false,
        };
    }
    else if (action.type === "revert") {
        return {
            ...state,
            config: cloneDeep(state.oldConfig),
            settings: cloneDeep(state.oldSettings),
            hasUnsavedChanges: false,
        };
    }
    else if (action.type === "save") {
        return {
            ...state,
            name: action.payload.name,
            version: state.version + 1,
            oldConfig: cloneDeep(state.config),
            oldSettings: cloneDeep(state.settings),
            hasUnsavedChanges: false,
        };
    }
    else if (action.type === "change-settings") {
        if (action.payload.send) {
            action.payload.prevSettings = cloneDeep(state.settings);
        }
        return {
            ...state,
            settings: {
                ...state.settings,
                ...action.payload.settings,
            },
            hasUnsavedChanges: state.hasUnsavedChanges || action.payload.send,
        };
    }
    else if (action.type === "regroup") {
        return {
            ...state,
            config: { ...state.config, groupings: action.payload.groupings },
            hasUnsavedChanges: state.hasUnsavedChanges ||
                !isEqual(state.oldConfig.groupings, action.payload.groupings),
        };
    }
    else if (action.type === "update-grid-settings") {
        const keys = Object.keys(action.payload);
        const hasUnsavedChanges = state.hasUnsavedChanges ||
            keys.some((key) => !isEqual(state.settings[key], action.payload[key]));
        return {
            ...state,
            settings: {
                ...state.settings,
                ...action.payload,
            },
            hasUnsavedChanges,
        };
    }
    else if (action.type === "change-funds") {
        return {
            ...state,
            id: action.payload.id,
            config: { ...state.config, fundIds: action.payload.fundIds },
            hasUnsavedChanges: true,
        };
    }
    else if (action.type === "adjust" || action.type === "price-override") {
        return {
            ...state,
            hasUnsavedChanges: true,
        };
    }
    return { ...state };
}
export function portfoliosProviderHistoryReducer(state, action) {
    /* Actions from Re-do need to keep future stack */
    const add = (event, keepFuture) => ({
        ...state,
        historyStack: [
            ...state.historyStack,
            omit(event, ["keepFuture"]),
        ],
        futureStack: keepFuture ? [...state.futureStack] : [],
    });
    if (action.type === "add-security" ||
        action.type === "remove-security" ||
        action.type === "adjust" ||
        action.type === "modify-orders" ||
        action.type === "price-override") {
        return add(action, action.keepFuture);
    }
    else if (action.type === "change-settings" &&
        action.payload.send &&
        action.payload.prevSettings) {
        return add({
            ...action,
            payload: {
                ...action.payload,
                prevSettings: { ...action.payload.prevSettings },
            },
        }, action.keepFuture);
    }
    if (action.type === "undo") {
        const data = state.historyStack.pop();
        if (!data)
            return state;
        return {
            ...state,
            futureStack: [...state.futureStack, data],
            hasUnsavedChanges: state.historyStack.length > 0,
        };
    }
    else if (action.type === "redo") {
        const data = state.futureStack.pop();
        if (!data)
            return state;
        return {
            ...state,
            historyStack: [...state.historyStack, data],
        };
    }
    else if (action.type === "remove-history") {
        return {
            ...state,
            historyStack: state.historyStack.filter((h) => h.historyId !== action.payload.historyId),
        };
    }
    return state;
}
