import FileErrorBoundary from "@app-components/ErrorBoundary/FileErrorBoundary";
import FileRenameModal from "@app-components/modal/FileRenameModal";
import { ServicesSectionMap } from "@app-context/services/ServicesFolderProvider";
import { downloadStorageFile } from "@app-utils/downloadStorageFile";
import { serviceFileShareLinkProps } from "@app-utils/shareLinks";
import { filesProviderMap } from "@app-views/services/ServicesSidebar";
import {
  AppEvent,
  FileTreeEntry,
  NodeData,
  StorageRoot,
  UserFlagKeys,
} from "@enfusion-ui/types";
import { formatDateTime, getFileIcon } from "@enfusion-ui/utils";
import {
  BoldText,
  Button,
  ButtonContainer,
  CenterContent,
  ConfirmationModal,
  ContentMessage,
  CopyToClipboard,
  FilePreview,
  IconButton,
  InfoModal,
  TabTopActionBar,
  ViewContainer,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  errorToast,
  gaModalView,
  getStorageFileUrl,
  REST_API,
  styled,
  successToast,
  useAuth,
  useThisTab,
  warningToast,
} from "@enfusion-ui/web-core";
import {
  faExclamationTriangle,
  faFileDownload,
  faInfoCircle,
  faShare,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

const FILE_SIZE_WARNING =
  typeof process.env.REACT_APP_FILE_SIZE_WARNING !== "undefined"
    ? Number(process.env.REACT_APP_FILE_SIZE_WARNING)
    : 20; // MB
const FILE_SIZE_VIEWER_THRESHOLD =
  typeof process.env.REACT_APP_FILE_SIZE_VIEWER_THRESHOLD !== "undefined"
    ? Number(process.env.REACT_APP_FILE_SIZE_VIEWER_THRESHOLD)
    : 45; // MB

const FileIconWrapper = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: var(--spacing-l);
`;

const ServiceFileActionBar = styled(TabTopActionBar)`
  justify-content: flex-end;
  padding-right: var(--spacing);
`;

export type ServiceFileViewProps = {
  config: {
    root?: StorageRoot;
    fileName: string;
    filePath: string;
    data: FileTreeEntry;
    size?: number;
  };
};

const ServiceFileView: React.FC<ServiceFileViewProps> = ({ config }) => {
  const { user, isEnabled } = useAuth();
  const { closeTab } = useThisTab();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);
  const [renameModalOpen, setRenameModalOpen] = React.useState(false);
  const [infoModalOpen, setInfoModalOpen] = React.useState(false);
  const [{ fileName, filePath, data, root = "services", size = 0 }] =
    React.useState(config);
  const [forceLoad, setForceLoad] = React.useState(false);

  const useFiles = filesProviderMap[root];
  const { deleteFile } = useFiles();

  const gaCategory = root === "general" ? "General Files" : "Services";
  const gaModalRoot = root === "general" ? "general-file" : root;
  const fileSizeMb = size / 1000 / 1000;

  React.useEffect(() => {
    AppLogging.event(
      {
        event: AppEvent.FileOpened,
        category: gaCategory,
        gaValue: 0,
      },
      { filePath }
    );

    if (fileSizeMb >= FILE_SIZE_WARNING) {
      warningToast(
        "Large file may load slowly",
        `This file is over ${FILE_SIZE_WARNING}Mb in size.`
      );
    }
  }, []);

  const toggleRenameModalOpen = () => {
    setRenameModalOpen((state) => !state);
  };

  const toggleInfoModalOpen = () => {
    if (!infoModalOpen) {
      gaModalView(`/${gaModalRoot}/file-info`);
    }
    setInfoModalOpen((state) => !state);
  };

  const toggleDeleteConfirmation = () => {
    gaModalView(`/${gaModalRoot}/delete-file-confirmation`);
    setDeleteConfirmationOpen((state) => !state);
  };

  const handleDeleteFile = () => {
    toggleDeleteConfirmation();
    deleteFile(filePath, closeTab);
  };

  const handleDownloadFile = async () => {
    AppLogging.event(
      {
        event: AppEvent.DownloadFile,
        category: gaCategory,
      },
      { root, filePath }
    );
    downloadStorageFile(root, filePath);
  };

  const handleRenameFile = async (filePath: string, newName: string) => {
    try {
      const response = await REST_API.STORAGE.RENAME_NODE.FETCH(
        root,
        filePath,
        newName
      );
      if (response) {
        successToast("Folder renamed Successfully");
        toggleRenameModalOpen();
      } else {
        errorToast("Unable to rename folder");
      }
    } catch (error) {
      AppLogging.error("Unable to rename file", error);
      errorToast("Unable to rename file");
    }
  };

  const handleForceLoad = () => setForceLoad(true);

  return (
    <FileErrorBoundary section={ServicesSectionMap[root]} filePath={filePath}>
      <ViewContainer>
        <ServiceFileActionBar>
          <CopyToClipboard
            component={
              <IconButton hoverable title="Share File" icon={faShare} />
            }
            {...serviceFileShareLinkProps(fileName, filePath)}
          />
          <IconButton
            hoverable
            title="File Info"
            icon={faInfoCircle}
            onClick={toggleInfoModalOpen}
          />
          {isEnabled(gaCategory.replace(" ", "") as UserFlagKeys) &&
          user?.adminUser ? (
            <>
              {/* <IconButton hoverable
              title="Rename File"
              icon={faEdit}
              onClick={toggleRenameModalOpen}
            /> */}
              <IconButton
                hoverable
                title="Delete File"
                icon={faTrashAlt}
                onClick={toggleDeleteConfirmation}
              />
            </>
          ) : null}
          {isEnabled(gaCategory.replace(" ", "") as UserFlagKeys) ? (
            <IconButton
              hoverable
              icon={faFileDownload}
              title="Download File"
              onClick={handleDownloadFile}
            />
          ) : null}
        </ServiceFileActionBar>
        {fileSizeMb < FILE_SIZE_VIEWER_THRESHOLD || forceLoad ? (
          <FilePreview
            fileName={fileName}
            filePath={getStorageFileUrl(root, filePath)}
          />
        ) : (
          <CenterContent>
            <ContentMessage
              icon={faExclamationTriangle}
              message={`This file is over ${FILE_SIZE_VIEWER_THRESHOLD}Mb. The load time for this file could be very long.`}
              containerStyle={{
                maxWidth: "75%",
              }}
            >
              <ButtonContainer>
                <Button primary onClick={handleDownloadFile}>
                  Download File
                </Button>
                <Button warning onClick={handleForceLoad}>
                  Load Anyway
                </Button>
              </ButtonContainer>
            </ContentMessage>
          </CenterContent>
        )}
        <ConfirmationModal
          title="Delete File"
          onSubmit={handleDeleteFile}
          submitActionTheme="danger"
          open={deleteConfirmationOpen}
          onCancel={toggleDeleteConfirmation}
        >
          Are you sure you want to delete {fileName}?
        </ConfirmationModal>
        <FileRenameModal
          filePath={filePath}
          fileName={fileName}
          onClose={toggleRenameModalOpen}
          onSubmit={handleRenameFile}
          open={renameModalOpen}
        />
        <InfoModal
          onClose={toggleInfoModalOpen}
          open={infoModalOpen}
          title="File Info"
        >
          <FileIconWrapper>
            <FontAwesomeIcon
              icon={getFileIcon({ name: fileName } as unknown as NodeData)}
            />{" "}
            {fileName}
          </FileIconWrapper>
          {user?.adminUser && (
            <div>
              <BoldText>Created By:</BoldText>{" "}
              {(data?.creationUser || "").replace("adminuser|", "")}
            </div>
          )}
          <div>
            <BoldText>Created At:</BoldText>{" "}
            {data.creationDateTime
              ? formatDateTime(data.creationDateTime)
              : "N/A"}
          </div>
          <div>
            <BoldText>Updated At:</BoldText>{" "}
            {data.modifiedDateTime
              ? formatDateTime(data.modifiedDateTime)
              : "N/A"}
          </div>
          <div>
            <BoldText>Path:</BoldText> /{data.path.replace(fileName, "")}
          </div>
        </InfoModal>
      </ViewContainer>
    </FileErrorBoundary>
  );
};

export default ServiceFileView;
