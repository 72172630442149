import { useOEMSOrderForm } from "@enfusion-ui/core";
import {
  ColumnGrid,
  FormContainer,
  FormContent,
  FormPanel,
} from "@enfusion-ui/web-components";
import { styled, useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";

import { ComplianceErrorList } from "../../components/ComplianceErrorList";
import AssignmentPanel from "../../panels/AssignmentPanel";
import ExecutionSummaryPanel from "../../panels/ExecutionSummaryPanel";
import GeneralInfoPanel from "../../panels/GeneralInfoPanel";

const GeneralTabFormContent = styled(FormContent)`
  width: 100%;
`;

export const GeneralTab: React.FC<unknown> = () => {
  const isMobile = useIsContentMobile();
  const { completed } = useOEMSOrderForm();

  return (
    <FormContainer>
      <GeneralTabFormContent isMobile={isMobile}>
        <ComplianceErrorList />
        <ColumnGrid isMobile={isMobile} numColumns={2}>
          <FormPanel
            title="General Info"
            numColumns={1}
            contentStyle={{ height: "100%" }}
          >
            <GeneralInfoPanel disabled={completed} />
          </FormPanel>
          <FormPanel
            title="Compliance and Assignment"
            numColumns={1}
            contentStyle={{ height: "100%" }}
          >
            <AssignmentPanel disabled={completed} showCompliance />
          </FormPanel>
        </ColumnGrid>
        <ColumnGrid isMobile={isMobile} numColumns={1}>
          <FormPanel popStyle={false} numColumns={1}>
            <ExecutionSummaryPanel />
          </FormPanel>
        </ColumnGrid>
      </GeneralTabFormContent>
    </FormContainer>
  );
};
