import { VarSwapOrderFormValues, sideDisplaySettings } from "@enfusion-ui/core";
import { ExecutionOrderSide } from "@enfusion-ui/types";
import {
  formatDateToStandardFormat,
  formatPercentage,
} from "@enfusion-ui/utils";
import { FormPanel, PanelRow } from "@enfusion-ui/web-components";
import * as React from "react";
import { useWatch } from "react-hook-form";

import { GridView } from "../../../../components/display/GridView";
import { getDisplayValue } from "../../utils/getDisplayValue";
import {
  LabeledValue,
  NumericLabeledValue,
  VarColorText,
} from "../styledComponents";
import { OrderSummaryGrid, SummaryModalTitle } from "./styles";

const VarSwapOrderSummaryPanelGrid: React.FC<{
  orderSide: ExecutionOrderSide | null | undefined;
  effectiveDate: string | null | undefined;
  expiryDate: string | null | undefined;
  vegaNotional?: number | null | undefined;
  strike?: string;
  notionalCcy?: string | null | undefined;
}> = ({
  orderSide,
  effectiveDate,
  expiryDate,
  vegaNotional,
  strike,
  notionalCcy,
}) => {
  const settings = sideDisplaySettings(false);
  const values = (orderSide && settings[orderSide]) || {
    name: null,
    color: null,
  };

  const { name, color } = values;

  const gridData = [
    {
      fieldName: "Side",
      fieldValue: name ?? "",
      showColored: {
        onValueChanged: (value: string) => value ?? null,
        data: color,
      },
    },
    {
      fieldName: "Vega Notional",
      fieldValue: vegaNotional ?? "",
    },
    {
      fieldName: "Strike",
      fieldValue: strike ?? "",
    },
    {
      fieldName: "Effective Date",
      fieldValue: effectiveDate ?? "",
    },
    {
      fieldName: "Expiry Date",
      fieldValue: expiryDate ?? "",
    },

    { fieldName: "Notional CCY", fieldValue: notionalCcy ?? "" },
  ];

  return <GridView gridData={gridData} />;
};

export const VarSwapOrderSummaryPanel: React.FC<{
  title?: string;
  viewMode?: string;
}> = ({ title = "VarSwapOrder Summary", viewMode = "grid" }) => {
  const {
    orderSide,
    vegaNotional,
    expiryDate,
    strike,
    notionalCcy,
    effectiveDate,
    underlyingInstrument,
  } = useWatch<VarSwapOrderFormValues>({
    name: [
      "orderSide",
      "vegaNotional",
      "expiryDate",
      "strike",
      "notionalCcy",
      "effectiveDate",
      "underlyingInstrument",
    ],
  });

  const effectiveDateDisplay = effectiveDate
    ? formatDateToStandardFormat(new Date(effectiveDate))
    : "";
  const expiryDateDisplay = expiryDate
    ? formatDateToStandardFormat(new Date(expiryDate))
    : "";
  const strikeDisplay = formatPercentage(Number(strike) / 100);
  const settings = sideDisplaySettings(false);
  const values = (orderSide && settings[orderSide]) || {
    name: null,
    color: null,
  };

  const { name, color } = values;

  if (viewMode === "grid") {
    return (
      <>
        <SummaryModalTitle label="Underlying Instrument">
          {underlyingInstrument?.description}
        </SummaryModalTitle>
        <VarSwapOrderSummaryPanelGrid
          orderSide={orderSide}
          vegaNotional={vegaNotional}
          strike={strikeDisplay}
          effectiveDate={effectiveDateDisplay}
          expiryDate={expiryDateDisplay}
          notionalCcy={notionalCcy}
        />
      </>
    );
  }

  return (
    <FormPanel title={title}>
      <PanelRow grow>
        <OrderSummaryGrid>
          <LabeledValue label="Side" title={name ?? "-"}>
            <VarColorText colorValue={color}>{name ?? "-"}</VarColorText>
          </LabeledValue>
          <NumericLabeledValue
            label="Vega Notional"
            title={getDisplayValue(vegaNotional ?? "", 0)}
          >
            {getDisplayValue(vegaNotional ?? "", 0)}
          </NumericLabeledValue>
          <NumericLabeledValue
            label="Strike"
            title={getDisplayValue(strike ?? "", 0)}
          >
            {getDisplayValue(strike ?? "", 0)}
          </NumericLabeledValue>
          <LabeledValue label="Effective Date" title={effectiveDate ?? "-"}>
            {effectiveDate ?? "-"}
          </LabeledValue>
          <LabeledValue label="Expiry Date" title={expiryDate ?? "-"}>
            {expiryDate ?? "-"}
          </LabeledValue>
          <LabeledValue label="Notional CCY" title={notionalCcy ?? "-"}>
            {notionalCcy ?? "-"}
          </LabeledValue>
        </OrderSummaryGrid>
      </PanelRow>
    </FormPanel>
  );
};

export default VarSwapOrderSummaryPanel;
