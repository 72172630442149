import { checkBbgEnabled } from "@enfusion-ui/core";
import { useRefCallback, useUserAgent } from "@enfusion-ui/hooks";
import { ConnectionStatus } from "@enfusion-ui/types";
import { useAuth } from "@enfusion-ui/web-core";
import * as React from "react";

import { ConnectionStatusContext, ConnectionType } from "./context";

const ConnectionStatusProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { isEnabled, apiError, user } = useAuth();
  const { os } = useUserAgent();

  const [apiStatus, setApiStatus] = React.useState<ConnectionStatus>(
    ConnectionStatus.CONNECTING
  );

  const isBggEnabled = React.useMemo(
    () =>
      checkBbgEnabled(
        isEnabled,
        user,
        process.env.REACT_APP_BLOOMBERG_APP,
        os.name
      ),
    [user, os.name]
  );

  const isOMSEnabled = React.useMemo(() => isEnabled("OMS"), [isEnabled]);

  const defaultStatus = React.useMemo(() => {
    const marketDataNeeded = isEnabled("FactSet") || isBggEnabled;

    const omsStatus = isOMSEnabled
      ? marketDataNeeded
        ? ConnectionStatus.WAITING_ON_MARKET_DATA
        : ConnectionStatus.CONNECTING
      : ConnectionStatus.DISABLED;

    return {
      reportMK: marketDataNeeded
        ? ConnectionStatus.WAITING_ON_MARKET_DATA
        : ConnectionStatus.CONNECTING,
      report: ConnectionStatus.CONNECTING,
      omsMK: omsStatus,
      oms: isOMSEnabled
        ? ConnectionStatus.CONNECTING
        : ConnectionStatus.DISABLED,
    };
  }, []);

  const [reportSocketConnectionStatus, setReportSocketConnectionStatus] =
    React.useState<ConnectionStatus>(() => defaultStatus.reportMK);

  const [marketDataConnectionStatus, setMarketDataConnectionStatus] =
    React.useState<ConnectionStatus>(() => defaultStatus.omsMK);

  const [oemsConnectionStatus, setOEMSConnectionStatus] =
    React.useState<ConnectionStatus>(() => defaultStatus.omsMK);

  const [factsetConnectionStatus, setFactsetConnectionStatus] =
    React.useState<ConnectionStatus>(() =>
      isEnabled("FactSet")
        ? ConnectionStatus.CONNECTING
        : ConnectionStatus.DISABLED
    );

  const [bloombergConnectionStatus, setBloombergConnectionStatus] =
    React.useState<ConnectionStatus>(() =>
      checkBbgEnabled(
        isEnabled,
        user,
        process.env.REACT_APP_BLOOMBERG_APP,
        os.name
      )
        ? ConnectionStatus.CONNECTING
        : ConnectionStatus.DISABLED
    );

  const [locatesConnectionStatus, setLocatesConnectionStatus] =
    React.useState<ConnectionStatus>(ConnectionStatus.DISCONNECTED);

  const flags = React.useMemo(() => {
    return {
      oms: isOMSEnabled,
      factset: isEnabled("FactSet"),
      marketData: isOMSEnabled,
      locates: isOMSEnabled,
      bloomberg: isBggEnabled,
    };
  }, [isEnabled, isOMSEnabled, isBggEnabled]);

  React.useEffect(() => {
    if (!flags.oms) setOEMSConnectionStatus(ConnectionStatus.DISABLED);
    if (!flags.marketData)
      setMarketDataConnectionStatus(ConnectionStatus.DISABLED);
    if (!flags.factset) setFactsetConnectionStatus(ConnectionStatus.DISABLED);
    if (!flags.bloomberg)
      setBloombergConnectionStatus(ConnectionStatus.DISABLED);
  }, [flags]);

  React.useEffect(() => {
    apiError !== null
      ? setApiStatus(ConnectionStatus.DISCONNECTED)
      : setApiStatus(ConnectionStatus.CONNECTED);
  }, [apiError]);

  const updateStatus = useRefCallback(
    (connectionType: ConnectionType, status: ConnectionStatus) => {
      switch (connectionType) {
        case ConnectionType.API:
          setApiStatus(status);
          break;
        case ConnectionType.Reports:
          setReportSocketConnectionStatus(status);
          break;
        case ConnectionType.MarketData:
          if (flags.marketData) setMarketDataConnectionStatus(status);
          break;
        case ConnectionType.OEMS:
          if (flags.oms) setOEMSConnectionStatus(status);
          break;
        case ConnectionType.Locates:
          if (flags.locates) setLocatesConnectionStatus(status);
          break;
        case ConnectionType.Factset: {
          console.log("factset", { flags, status, bloombergConnectionStatus });
          if (flags.factset) setFactsetConnectionStatus(status);
          if (
            (!flags.bloomberg ||
              (flags.bloomberg &&
                bloombergConnectionStatus !== ConnectionStatus.CONNECTING)) &&
            status !== ConnectionStatus.CONNECTING
          ) {
            console.log("here", { defaultStatus });
            setReportSocketConnectionStatus(defaultStatus.report);
            setOEMSConnectionStatus(defaultStatus.oms);
            setMarketDataConnectionStatus(defaultStatus.oms);
          }
          break;
        }
        case ConnectionType.BloombergRealtime: {
          console.log("bbg", { flags, status, factsetConnectionStatus });
          if (flags.bloomberg) setBloombergConnectionStatus(status);
          if (
            (!flags.factset ||
              (flags.factset &&
                factsetConnectionStatus !== ConnectionStatus.CONNECTING)) &&
            status !== ConnectionStatus.CONNECTING
          ) {
            setReportSocketConnectionStatus(defaultStatus.report);
            setOEMSConnectionStatus(defaultStatus.oms);
            setMarketDataConnectionStatus(defaultStatus.oms);
          }
          break;
        }
      }
    },
    [flags, factsetConnectionStatus, bloombergConnectionStatus]
  );

  return (
    <ConnectionStatusContext.Provider
      value={{
        apiStatus,
        marketDataConnectionStatus,
        factsetConnectionStatus,
        oemsConnectionStatus,
        locatesConnectionStatus,
        bloombergConnectionStatus,
        reportSocketConnectionStatus,
        updateStatus,
      }}
    >
      {children}
    </ConnectionStatusContext.Provider>
  );
};

export default ConnectionStatusProvider;
