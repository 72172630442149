import { TOAST_CONTENT } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { ExecutionOrderSide } from "@enfusion-ui/types";
import { formatDateToStandardFormat } from "@enfusion-ui/utils";
import {
  ButtonGroupSelect,
  ControlledNumericInput,
  FormPanel,
  PanelRow,
} from "@enfusion-ui/web-components";
import { errorToast, REST_API, styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import CalculatedSidePanel from "../CalculatedSidePanel";

export type VarSwapSideQuantityProps = {};

const QuantityWrapper = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? "none" : "")};
`;

const VarSwapSideQuantity: React.FC<VarSwapSideQuantityProps> = () => {
  const [activeInput, setActiveInput] = React.useState<string | null>(
    "vegaNotional"
  );

  const { setValue } = useFormContext();
  const {
    orderSide,
    id,
    holidayCenters,
    strike,
    effectiveDate,
    expiryDate,
    vegaNotional,
    varianceUnits,
  } = useWatch({
    name: [
      "orderSide",
      "id",
      "holidayCenters",
      "strike",
      "effectiveDate",
      "expiryDate",
      "vegaNotional",
      "varianceUnits",
    ],
  });

  React.useEffect(() => {
    if (
      (orderSide as ExecutionOrderSide) === "SellToClose" ||
      (orderSide as ExecutionOrderSide) === "BuyToClose"
    )
      setActiveInput("varianceUnits");
    else setActiveInput("vegaNotional");
  }, [orderSide]);

  const handleOrderSideSelect = useRefCallback(
    (value: string) => {
      if (value === "SellToClose" || value === "BuyToClose")
        setValue("atm", false);
      else setValue("atm", true);
    },
    [setValue, setActiveInput]
  );

  React.useEffect(() => {
    const getUnitsOrNotional = async () => {
      try {
        const response = await REST_API.OEMS.CALCULATE_VARIANCE_NOTIONAL.FETCH({
          orderId: id,
          vegaNotional,
          varianceUnits,
          orderSide,
          strike: strike ? strike * 0.01 : 0,
          holidayCenters,
          effectiveDate: effectiveDate
            ? formatDateToStandardFormat(effectiveDate)
            : undefined,
          expiryDate: expiryDate
            ? formatDateToStandardFormat(expiryDate)
            : undefined,
        });

        setValue("vegaNotional", response.vegaNotional);
        setValue("varianceUnits", response.varianceUnits);
        setValue("nratio", response.nratio);
      } catch (e) {
        errorToast(
          TOAST_CONTENT.oems.networkError.varianceNotional.fetchFailed,
          (e as Error).message
        );
      }
    };

    getUnitsOrNotional();
  }, [
    setValue,
    id,
    orderSide,
    strike,
    holidayCenters,
    effectiveDate,
    expiryDate,
  ]);

  const handleVegaNotionalChange = useRefCallback(
    (value: number | null) => {
      const getUnitsOrNotional = async () => {
        try {
          const response =
            await REST_API.OEMS.CALCULATE_VARIANCE_NOTIONAL.FETCH({
              orderId: id,
              vegaNotional: value,
              varianceUnits: undefined,
              orderSide,
              strike: strike ? strike * 0.01 : 0,
              holidayCenters,
              effectiveDate: effectiveDate
                ? formatDateToStandardFormat(effectiveDate)
                : undefined,
              expiryDate: expiryDate
                ? formatDateToStandardFormat(expiryDate)
                : undefined,
            });
          setValue("varianceUnits", response.varianceUnits);
          setValue("nratio", response.nratio);
        } catch (e) {
          errorToast(
            TOAST_CONTENT.oems.networkError.varianceNotional.fetchFailed,
            (e as Error).message
          );
        }
      };
      getUnitsOrNotional();
    },
    [setValue, id, orderSide, strike, holidayCenters, effectiveDate, expiryDate]
  );

  const handleVarianceUnitsChange = useRefCallback(
    (value: number | null) => {
      const getUnitsOrNotional = async () => {
        try {
          const response =
            await REST_API.OEMS.CALCULATE_VARIANCE_NOTIONAL.FETCH({
              orderId: id,
              vegaNotional: undefined,
              varianceUnits: value,
              orderSide,
              strike: strike ? strike * 0.01 : 0,
              holidayCenters,
              effectiveDate: effectiveDate
                ? formatDateToStandardFormat(effectiveDate)
                : undefined,
              expiryDate: expiryDate
                ? formatDateToStandardFormat(expiryDate)
                : undefined,
            });
          setValue("vegaNotional", response.vegaNotional);
          setValue("nratio", response.nratio);
        } catch (e) {
          errorToast(
            TOAST_CONTENT.oems.networkError.varianceNotional.fetchFailed,
            (e as Error).message
          );
        }
      };
      getUnitsOrNotional();
    },
    [setValue, id, orderSide, strike, holidayCenters, effectiveDate, expiryDate]
  );

  return (
    <FormPanel numColumns={1} title="Side & Quantity">
      <CalculatedSidePanel
        showVarSwapOptions
        onSelect={handleOrderSideSelect}
      />
      <FormPanel contentStyle={{ padding: 0 }}>
        <PanelRow>
          <ButtonGroupSelect
            selectedValue={activeInput}
            onSelect={setActiveInput}
            style={{ marginTop: "auto" }}
            options={[
              { label: "Vega Notional", value: "vegaNotional" },
              { label: "Variance Units", value: "varianceUnits" },
            ]}
            disableDeselect
            neutralSeparatorColor
          />

          <QuantityWrapper hide={activeInput === "varianceUnits"}>
            <ControlledNumericInput
              name="vegaNotional"
              onChange={handleVegaNotionalChange}
            />
          </QuantityWrapper>
          <QuantityWrapper hide={activeInput === "vegaNotional"}>
            <ControlledNumericInput
              name="varianceUnits"
              onChange={handleVarianceUnitsChange}
            />
          </QuantityWrapper>
        </PanelRow>
        <PanelRow>
          <ControlledNumericInput
            name="nratio"
            label="N Ratio"
            disabled={
              (orderSide as ExecutionOrderSide) === "SellToClose" ||
              (orderSide as ExecutionOrderSide) === "BuyToClose"
            }
          />
        </PanelRow>
      </FormPanel>
    </FormPanel>
  );
};

export default VarSwapSideQuantity;
