import {
  useReconciliation,
  useReconciliationBreakDetails,
} from "@app-context/reconciliation/context";
import { BreaksByCurrencyContainer } from "@app-views/reconciliation/components/styles";
import { GraphBaseField } from "@app-views/reconciliation/types";
import {
  getBreaksData,
  getFilteredData,
  isCashDataSource,
} from "@app-views/reconciliation/utils/commonUtils";
import { DEFAULT_DIFF_REPORT_DATA } from "@app-views/reconciliation/utils/constants";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  DiffReportSyncRow,
  FilterColumns,
  ImpactColumns,
  ReconciliationColumnType,
  ReconciliationFilterState,
} from "@enfusion-ui/types";
import { ButtonGroupSelect } from "@enfusion-ui/web-components";
import React from "react";

import { ChartBlock, ChartBlockProps } from "./ChartBlock";

type SelectedColumns = FilterColumns & ImpactColumns;

const TOGGLE_GRAPH_OPTIONS = [
  { label: "Impact", value: "impact" },
  { label: "Count", value: "count" },
];

const FilterStateKeysArray = ["currency", "serviceAccount", "instrument"];

const getStackedBarGraphProps = (
  chartData: DiffReportSyncRow[],
  column: string | ReconciliationColumnType,
  id: string
) => {
  return {
    data: getGraphData(chartData, null, column),
    title: "Breaks by Currency",
    type: "stacked bar",
    baseField: "currency",
    id,
  } as ChartBlockProps;
};

const getSortedGraphData = (
  graphData: {
    name: string;
    data: number[];
  }[],
  limit = 10
) => {
  const sortedData = graphData.sort((a, b) => {
    return Number(b.data[0]) - Number(a.data[0]);
  });
  return sortedData.slice(0, limit);
};

const getChartBlockProps = (
  filterState: ReconciliationFilterState,
  selectedColumns: SelectedColumns,
  chartData: DiffReportSyncRow[],
  graphSwitch: string | null,
  id: string
) => {
  if (
    !selectedColumns.breakImpact ||
    (!filterState.currency?.value && graphSwitch === "count")
  ) {
    return getStackedBarGraphProps(
      chartData,
      selectedColumns.currency ?? "",
      id
    );
  }

  let graphProps = {} as ChartBlockProps;

  FilterStateKeysArray.some((key, idx) => {
    const typedKey = key as unknown as keyof ReconciliationFilterState;

    const allFilterSelected = idx === FilterStateKeysArray.length - 1;

    // Impact graph should be shown for the filter state that is not selected
    //if all filters are selected default graph should be of instrument
    if (!filterState[typedKey].value?.length || allFilterSelected) {
      let graphData = getGraphData(
        chartData,
        selectedColumns.breakImpact ?? "",
        selectedColumns?.[typedKey] ?? ""
      );
      if (typedKey === "instrument") {
        graphData = getSortedGraphData(graphData);
      }
      graphProps = {
        data: graphData,
        title: `Breaks by ${filterState[typedKey].label}(Impact)`,
        yAxisTitle: "Break Impact",
        type: "column",
        baseField: key as GraphBaseField,
        id,
      };
      return true;
    }
  });

  return graphProps;
};

const createUniqueKeyMap = (
  data: DiffReportSyncRow[],
  breakImpactColumn: string | null,
  baseField: string | ReconciliationColumnType,
  noCountGraph = false
) => {
  const uniqueKeyMap = new Map();
  data.forEach((row) => {
    let key;
    if (typeof baseField === "string") {
      key = row.columnValues[baseField]?.value;
    } else {
      const sourceKey = (baseField as ReconciliationColumnType).source;
      const targetKey = (baseField as ReconciliationColumnType).target;
      const reconColumnKey =
        sourceKey && row.columnValues[sourceKey]
          ? row.columnValues[sourceKey]
          : targetKey && row.columnValues[targetKey]
          ? row.columnValues[targetKey]
          : undefined;
      key = reconColumnKey ? reconColumnKey?.value : "";
    }
    const breakKey = row.columnValues[breakImpactColumn ?? ""]?.value;
    if (key) {
      let val =
        breakKey || noCountGraph ? (breakKey ? Number(breakKey) : 0) : 1;
      if (uniqueKeyMap.has(key)) val = uniqueKeyMap.get(key) + val;
      uniqueKeyMap.set(key, val);
    }
  });
  return uniqueKeyMap;
};

const getGraphData = (
  data: DiffReportSyncRow[],
  breakImpactColumn: string | null,
  baseField: string | ReconciliationColumnType,
  noCountGraph = false
) => {
  const uniqueKeyMap = createUniqueKeyMap(
    data,
    breakImpactColumn,
    baseField,
    noCountGraph
  );
  let graphData: Array<{
    name: string;
    data: number[];
  }> = [];
  uniqueKeyMap.forEach((val, key) => {
    graphData.push({ name: key, data: [val] });
  });
  return graphData;
};

export const BreaksByCurrency: React.FC<{}> = () => {
  const { filterState, config } = useReconciliation();
  const { breakDetailsRendererData: data } = useReconciliationBreakDetails();
  const { currency, serviceAccount, instrument } = filterState;
  const { setGraphFilterState } = useReconciliationBreakDetails();
  const [chartData, setChartData] = React.useState<DiffReportSyncRow[]>([]);
  const [graphSwitch, setGraphSwitch] = React.useState<string | null>("count");
  const { id, reportData, filterColumns, reconciliationType } = data;
  const allBreaksData = getBreaksData(
    (isCashDataSource(reconciliationType)
      ? reportData.cashActivityRowsStore
      : reportData.rowsStore) ?? DEFAULT_DIFF_REPORT_DATA
  );

  const breakImpactColumn = React.useMemo(
    () =>
      config.reconReports.find((config) => config.reconReportId === id)
        ?.impactColumns?.breakImpact ?? null,
    [config]
  );

  const clearGraphFilters = useRefCallback(
    (clearAll = false) => {
      setGraphFilterState((current) => {
        if (clearAll) {
          const filterState = { ...current };
          delete filterState[data.id];
          return filterState;
        }
        const rowFilterState = current[data.id];
        if (rowFilterState)
          FilterStateKeysArray.forEach((key) => {
            delete rowFilterState[key as GraphBaseField];
          });
        return { ...current, [data.id]: rowFilterState };
      });
    },
    [setGraphFilterState, data.id]
  );

  const handleGraphSwitchChange = useRefCallback(
    (value: string | null) => {
      setGraphSwitch(value);
      clearGraphFilters();
    },
    [setGraphSwitch, clearGraphFilters]
  );

  React.useEffect(() => {
    clearGraphFilters(true);
    setChartData(getFilteredData(filterState, allBreaksData, filterColumns));
  }, [serviceAccount, currency, instrument]);

  return (
    <BreaksByCurrencyContainer>
      {breakImpactColumn && !filterState.currency?.value && (
        <ButtonGroupSelect
          options={TOGGLE_GRAPH_OPTIONS}
          onSelect={handleGraphSwitchChange}
          selectedValue={graphSwitch}
          size="small"
          style={{ marginBottom: 10 }}
          disableDeselect
        />
      )}
      <ChartBlock
        {...getChartBlockProps(
          filterState,
          {
            breakImpact: breakImpactColumn,
            instrument: filterColumns?.instrument,
            currency: filterColumns?.currency,
            serviceAccount: filterColumns?.serviceAccount,
          } as SelectedColumns,
          chartData,
          graphSwitch,
          data.id
        )}
      />
    </BreaksByCurrencyContainer>
  );
};
