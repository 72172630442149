/* eslint-disable @typescript-eslint/no-explicit-any */
import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import {
  ConnectionStatusProviderPassthrough,
  useConnectionStatus,
} from "@app-context/connectionStatus/context";
import {
  MarketDataProviderPassthrough,
  useMarketData,
} from "@app-context/marketData/context";
import {
  DashboardProviderPassthrough,
  useDashboard,
} from "@enfusion-ui/dashboards";
import {
  AuthProviderPassthrough,
  OEMSProviderPassthrough,
  ReportsProviderPassthrough,
  useAuth,
  useOEMS,
  useReports,
} from "@enfusion-ui/web-core";
import * as React from "react";

import { PDF_DASHBOARD_CACHE_CHANNEL_NAME } from "../constants";

const Cache = new Map();

function useContextValues() {
  const dashboardContext = useDashboard();
  const authContext = useAuth();
  const connectionStatusContext = useConnectionStatus();

  const oemsContext = useOEMS();
  const reportsContext = useReports();
  const marketdataContext = useMarketData();

  return React.useMemo(
    () => ({
      dashboardContext,
      authContext,
      connectionStatusContext,
      oemsContext,
      reportsContext,
      marketdataContext,
    }),
    [
      dashboardContext,
      authContext,
      connectionStatusContext,
      oemsContext,
      reportsContext,
      marketdataContext,
    ]
  );
}

export const PDFContextParent: React.FC<
  React.PropsWithChildren<{ id: string; complete?: boolean }>
> = React.memo(({ id, complete, children }) => {
  const values = useContextValues();
  const channel = useBroadcastChannel(PDF_DASHBOARD_CACHE_CHANNEL_NAME);

  Cache.set(id, values);

  React.useEffect(() => {
    Cache.set(id, values);
    if (!complete) channel.broadcastMessage(id);
  }, [id, complete, values]);

  return <>{children}</>;
});

export const PDFContextChild: React.FC<
  React.PropsWithChildren<{ id: string }>
> = React.memo(({ id, children }) => {
  const [
    {
      dashboardContext,
      authContext,
      connectionStatusContext,
      oemsContext,
      reportsContext,
      marketdataContext,
    },
    setContextContent,
  ] = React.useState<any>(() => Cache.get(id) || {});

  useBroadcastChannel(PDF_DASHBOARD_CACHE_CHANNEL_NAME, (msg) => {
    if (msg === id) {
      setContextContent(Cache.get(id) || {});
    }
  });

  return (
    <AuthProviderPassthrough {...authContext}>
      <ReportsProviderPassthrough {...reportsContext}>
        <OEMSProviderPassthrough {...oemsContext}>
          <ConnectionStatusProviderPassthrough {...connectionStatusContext}>
            <MarketDataProviderPassthrough {...marketdataContext}>
              <DashboardProviderPassthrough {...dashboardContext}>
                {children}
              </DashboardProviderPassthrough>
            </MarketDataProviderPassthrough>
          </ConnectionStatusProviderPassthrough>
        </OEMSProviderPassthrough>
      </ReportsProviderPassthrough>
    </AuthProviderPassthrough>
  );
});
