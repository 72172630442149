import { ControlledInstrumentSelect } from "@app-components/inputs/InstrumentSelect/ControlledInstrumentSelect";
import {
  UNKNOWN_INSTRUMENT_ID,
  useInstrument,
  useOEMSOrderForm,
} from "@enfusion-ui/core";
import { basicInfoMap, useRestAbortableOptions } from "@enfusion-ui/hooks";
import { FinancialSubType, WebReportTableSyncRow } from "@enfusion-ui/types";
import { formatNumber } from "@enfusion-ui/utils";
import {
  ControlledEmpty,
  ControlledSelect,
  ControlledSelectWrapperProps,
  InputLabel as PositionLabel,
  panelContentStyles,
  PanelRow,
} from "@enfusion-ui/web-components";
import {
  REST_API,
  styled,
  useAuth,
  usePositionReportData,
} from "@enfusion-ui/web-core";
import * as React from "react";

import { VarColorText } from "../../components/styledComponents";
import { SelectPositionAction } from "../components/SelectPositionAction";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  height: 100%;
  ${panelContentStyles};
`;

const PositionValue = styled.div`
  height: 38px;
  display: flex;
  align-items: center;

  > div {
    padding-left: var(--spacing-l);
    padding-right: var(--spacing);
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const ElementWrapper = styled.div`
  flex: 1;
`;

export type OrderFormInstrumentSelectProps = Omit<
  InstrumentAndStrategyPanelProps,
  "showPosition" | "showBookStrategy" | "showExistingPositionLink"
>;

export const OrderFormInstrumentSelect: React.FC<
  OrderFormInstrumentSelectProps
> = ({
  disabled,
  financialSubTypes,
  showAdvancedSearch,
}: OrderFormInstrumentSelectProps) => {
  const { settings } = useOEMSOrderForm();

  return (
    <>
      <PanelRow>
        <ControlledInstrumentSelect
          basis="50%"
          mobileBasis="100%"
          label="Symbol"
          name="instrument"
          disabled={disabled}
          autoFocus={!disabled}
          financialSubTypes={financialSubTypes}
          showAdvancedSearch={showAdvancedSearch}
          searchOnlyPrimary={settings?.searchOnlyPrimary ?? false}
        />
      </PanelRow>
      <ControlledEmpty name="instrumentId" />
    </>
  );
};

export const StrategyBookIdSelect: React.FC<
  Pick<
    ControlledSelectWrapperProps<number, false>,
    | "basis"
    | "mobileBasis"
    | "tabletBasis"
    | "desktopBasis"
    | "disabled"
    | "label"
    | "isClearable"
    | "inputId"
  > & { name?: string }
> = ({
  name = "strategyBookId",
  label = "Strategy",
  inputId = "strategy-book-selection-id",
  isClearable = true,
  ...props
}) => {
  const { options, loading } = useRestAbortableOptions(
    REST_API.FUND.GET_STRATEGY_BOOKS.FETCH,
    basicInfoMap
  );

  return (
    <ControlledSelect
      {...props}
      name={name}
      label={label}
      inputId={inputId}
      isClearable={isClearable}
      options={options}
      isLoading={loading}
    />
  );
};

export type InstrumentAndStrategyPanelProps = {
  disabled?: boolean;
  financialSubTypes?: FinancialSubType[];
  showPosition?: boolean;
  showAdvancedSearch?: boolean;
  showBookStrategy?: boolean;
  showExistingPositionLink?: boolean;
};

const InstrumentAndStrategyPanel: React.FC<InstrumentAndStrategyPanelProps> = ({
  disabled,
  financialSubTypes,
  showPosition = true,
  showAdvancedSearch = true,
  showBookStrategy = true,
  showExistingPositionLink = true,
}) => {
  const { isUserType } = useAuth();

  const { completed, isFxOrder, isFutureSpreadOrder } = useOEMSOrderForm();
  const instrument = useInstrument();

  const { rowData } = usePositionReportData(instrument?.id ?? undefined);
  const [positionQty, setPositionQty] = React.useState<number>(0);

  React.useEffect(() => {
    if (!instrument?.id || instrument?.id === UNKNOWN_INSTRUMENT_ID) {
      setPositionQty(0);
    } else {
      const posVal = rowData.reduce(
        (total: number, eachRow: WebReportTableSyncRow) =>
          total + eachRow.openQuantity?.value,
        0
      );

      setPositionQty(posVal);
    }
  }, [instrument?.id, rowData]);

  return (
    <Wrapper>
      <OrderFormInstrumentSelect
        disabled={disabled}
        financialSubTypes={financialSubTypes}
        showAdvancedSearch={showAdvancedSearch}
      />

      <PanelRow>
        {showPosition && (
          <ElementWrapper>
            <PositionLabel>Position</PositionLabel>
            <PositionValue tabIndex={-1}>
              <VarColorText
                colorValue={
                  !positionQty ? null : positionQty > 0 ? "success" : "danger"
                }
              >
                {formatNumber(positionQty ?? 0, isFxOrder ? 2 : 0)}
              </VarColorText>
            </PositionValue>
          </ElementWrapper>
        )}

        {!isUserType("Express") && showBookStrategy ? (
          <ElementWrapper>
            <StrategyBookIdSelect disabled={completed} />
          </ElementWrapper>
        ) : null}
      </PanelRow>
      {!isFutureSpreadOrder && !disabled && showExistingPositionLink ? (
        <PanelRow>
          <SelectPositionAction />
        </PanelRow>
      ) : null}
    </Wrapper>
  );
};

export default React.memo(InstrumentAndStrategyPanel);
