/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRefCallback } from "@enfusion-ui/hooks";
import { errorToast } from "@enfusion-ui/web-core";
import { noop } from "lodash";
import { PWBHost } from "promise-worker-bi";
import * as React from "react";
import semver from "semver";
import { WorkerContext } from "./context";
const REQUIRED_WORKER_VERSION = process.env.REACT_APP_VERSION;
const RECONNECT_TIMEOUT = 5000;
const BAD_WORKER_KEY = "ef-bad-worker";
const validateVersions = (versionToCompare) => {
    if (semver.valid(REQUIRED_WORKER_VERSION) && semver.valid(versionToCompare)) {
        return semver.lte(REQUIRED_WORKER_VERSION, versionToCompare);
    }
    console.error("The required or worker version vars are not valid version strings");
    return false;
};
export const WorkerProvider = ({ children, EntryWorker, storageKeyBase = BAD_WORKER_KEY }) => {
    const hostRef = React.useRef();
    const broadcastSubscriptions = React.useRef({});
    const enabledModulesRef = React.useRef(new Set());
    const preOpenMessages = React.useRef([]);
    const postMessage = useRefCallback((type, payload) => {
        if (!hostRef.current) {
            return new Promise((accept) => {
                preOpenMessages.current.push({ type, payload, callback: accept });
            });
        }
        return hostRef.current?.postMessage({
            type,
            payload,
        });
    }, [hostRef]);
    const terminateWorker = useRefCallback(() => {
        // sending terminate event to the worker. Worker will broadcast this event to all listeners.
        // Also it will close the socket connection and the shared worker
        // once the worker is closed(worker is broadcasting terminate event to all listeners)
        // we create a new instance of worker object in the callback
        console.log("Terminating worker");
        return postMessage("terminate");
    }, []);
    React.useEffect(() => {
        window.terminateWorker = terminateWorker;
    }, [terminateWorker]);
    const initializeWorker = useRefCallback(() => {
        const worker = new EntryWorker();
        // console.log("Initializing worker");
        hostRef.current = new PWBHost(worker);
        hostRef.current.register(async (message) => {
            const { type, payload } = message;
            if (type === "terminated") {
                errorToast("Worker has been terminated");
                hostRef.current = undefined;
                preOpenMessages.current = [
                    ...Array.from(enabledModulesRef.current).map((moduleKey) => ({
                        payload: { moduleKey },
                        type: "enable-module",
                        callback: noop,
                    })),
                    ...preOpenMessages.current,
                ];
                setTimeout(initializeWorker, RECONNECT_TIMEOUT);
            }
            else if (type === "module-broadcast") {
                const { moduleKey, message } = payload;
                if (broadcastSubscriptions.current[moduleKey]) {
                    for (const callback of broadcastSubscriptions.current[moduleKey]) {
                        try {
                            callback(message);
                        }
                        catch (err) {
                            console.error("Broadcast Subscription Error", err);
                        }
                    }
                }
            }
            else {
                console.warn("Unhandled Worker message", message);
            }
        });
        // there is an error in the worker
        hostRef.current.registerError((error) => console.error("worker error", error));
        for (const preOpenMessage of preOpenMessages.current) {
            preOpenMessage.callback(postMessage(preOpenMessage.type, preOpenMessage.payload));
        }
        preOpenMessages.current = [];
    }, []);
    React.useEffect(() => {
        if (localStorage.getItem(storageKeyBase) === "true") {
            try {
                const worker = new EntryWorker();
                worker.port?.close();
                worker.terminate?.();
            }
            catch (err) {
                console.error("Error while creating worker", err);
            }
        }
        localStorage.setItem(storageKeyBase, "true");
        initializeWorker();
        postMessage("open").then(() => {
            // Validate worker version
            let retry = 0;
            const getAndValidate = () => {
                postMessage("get-version")?.then(({ version }) => {
                    if (typeof version === "string" && version.trim().length > 0) {
                        if (!validateVersions(version)) {
                            console.warn("terminate worker because of the version", version);
                            terminateWorker();
                        }
                    }
                    else if (retry < 2) {
                        retry += 1;
                        console.warn("no valid version", version);
                        getAndValidate();
                    }
                });
            };
            getAndValidate();
        });
        return () => {
            localStorage.removeItem(storageKeyBase);
            postMessage("close");
        };
    }, []);
    const enableModule = useRefCallback((moduleKey) => {
        enabledModulesRef.current.add(moduleKey);
        return postMessage("enable-module", { moduleKey });
    }, [postMessage]);
    const disableModule = useRefCallback((moduleKey) => {
        enabledModulesRef.current.delete(moduleKey);
        return postMessage("disable-module", { moduleKey });
    }, [postMessage]);
    const getCurrentState = useRefCallback((moduleKey) => {
        return postMessage("current-state", { moduleKey });
    }, [postMessage]);
    const postModuleMessage = useRefCallback((moduleKey, payload) => {
        return postMessage("module-message", { moduleKey, payload });
    }, [postMessage]);
    const subscribeToModule = useRefCallback((moduleKey, callback) => {
        broadcastSubscriptions.current[moduleKey] = [
            ...(broadcastSubscriptions.current[moduleKey] || []).filter((i) => i !== callback),
            callback,
        ];
        return () => {
            broadcastSubscriptions.current[moduleKey] = [
                ...(broadcastSubscriptions.current[moduleKey] || []).filter((i) => i !== callback),
            ];
        };
    }, [broadcastSubscriptions]);
    const value = React.useMemo(() => ({
        enableModule,
        disableModule,
        postMessage: postModuleMessage,
        subscribeToModule,
        getCurrentState,
    }), [
        enableModule,
        disableModule,
        postModuleMessage,
        subscribeToModule,
        getCurrentState,
    ]);
    return (React.createElement(WorkerContext.Provider, { value: value }, children));
};
