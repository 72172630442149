import { blobToDataURL, getBlobFromXHR, getXHRContent, statusText, styled, } from "@enfusion-ui/web-core";
import * as React from "react";
import { Spinner } from "../../display";
import { ContentWrapper } from "./styles";
const ImageWrapper = styled.div `
  flex: 1 1 0%;
  width: 100%;
  padding: 0.75rem;
  overflow: auto;
  display: grid;
  border: 1px solid var(--input-border);
  place-items: center;
`;
const Image = styled.img `
  width: 100%;
  margin-bottom: var(--spacing-xl);
  border: 1px solid var(--input-border);
`;
export const IMGPreview = ({ filePath, contentType, setError, }) => {
    const [dataUrl, setDataUrl] = React.useState(null);
    React.useEffect(() => {
        const readFile = async () => {
            const req = await getXHRContent(filePath);
            if (req.status >= 200 && req.status <= 299) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const url = await blobToDataURL(getBlobFromXHR(req, contentType).blob);
                setDataUrl(url);
            }
            else {
                setError(statusText(req.status));
            }
        };
        readFile();
    }, []);
    if (dataUrl === null) {
        return (React.createElement(ContentWrapper, null,
            React.createElement(Spinner, null)));
    }
    return (React.createElement(ImageWrapper, null,
        React.createElement(Image, { src: dataUrl })));
};
