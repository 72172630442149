import CurrencySelect from "@app-views/reports/reportSettings/generalLedgerAccounting/glDistributionLine/CurrencySelect";
import { orderTypeOptions } from "@enfusion-ui/trades";
import {
  ControlledNumericInput,
  ControlledSelect,
  FormPanel,
  PanelRow,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { HorizontalRule } from "../../../components/styledComponents";
import { Commissions } from "./components/Commissions";
import { Fees } from "./components/Fees";

const NumberInput = styled(ControlledNumericInput).attrs({
  desktopBasis: "33.3%",
})``;

const CCYInput = styled(CurrencySelect).attrs({
  desktopBasis: "33.3%",
})``;

export const CostPanelGeneralListed: React.FC = () => {
  return (
    <FormPanel
      id="trade-cost-section"
      title="Cost"
      numColumns={1}
      collapsible
      defaultOpen
      keepRendered
      defaultRendered
      lineStyle
      tabFocusOutline={false}
    >
      <PanelRow>
        <ControlledSelect
          desktopBasis="33.3%"
          required
          name="orderType"
          label="Order Type"
          options={orderTypeOptions}
        />
        <NumberInput
          name="quantity"
          label="Quantity"
          placeholder="0.00"
          fractionDigits={[0, 4, 2]}
        />
        <NumberInput
          name="orderPrice"
          label="Price"
          placeholder="0.00"
          fractionDigits={2}
        />
      </PanelRow>
      <PanelRow>
        <CCYInput name="tradeCCY" label="Trade CCY" required />
        <NumberInput name="fxRate" label="FX Rate (Trade to Book)" />
        <NumberInput name="fxRateDivisor" label="1/FX" />
      </PanelRow>
      <PanelRow>
        <CCYInput name="settleCCY" label="Settle CCY" />
        <NumberInput name="settleFxRate" label="FX Rate (Trade to Settle)" />
        <NumberInput name="settleFxRateDivisor" label="1/FX" />
      </PanelRow>

      <HorizontalRule />
      <Commissions />
      <Fees />
    </FormPanel>
  );
};
