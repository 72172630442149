import { useWidget, WidgetWithDatasourceConfig } from "@enfusion-ui/dashboards";
import { useRefCallback } from "@enfusion-ui/hooks";
import { faSkullCrossbones, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { startCase } from "lodash";
import * as React from "react";

import { useReportDatasource } from "../hooks/useReportDatasource";
import { PDFAsyncCacheEntry, PDFAsyncCallback, usePDFAsync } from "./context";
import PdfContentMessage from "./PdfContentMessage";
import PdfReportLoadingStatus from "./PdfReportLoadingStatus";
import { isProgressStepsComplete } from "./utils";

export const AsyncDatasourceContent: React.FC<{
  afterDataCb?: PDFAsyncCallback;
  children:
    | ((cachedContent: PDFAsyncCacheEntry) => React.ReactElement)
    | React.ReactElement;
}> = ({ afterDataCb, children }) => {
  const { config, id, type } = useWidget();
  const { metadata, errorLoading, datasource, gridOptions } =
    useReportDatasource();
  const { datasourceId } = config as WidgetWithDatasourceConfig;

  const name = startCase(type);

  const check = useRefCallback(
    (resolve, reject) => {
      if (errorLoading) {
        reject(new Error(errorLoading));
      } else if (!datasourceId) {
        reject(new Error("Missing datasource"));
      } else if (metadata?.loading || !gridOptions) {
        setTimeout(() => check(resolve, reject), 1500);
      } else {
        setTimeout(() => {
          if (afterDataCb) afterDataCb().then(resolve).catch(reject);
          else resolve({ status: "success" });
        }, 300);
      }
    },
    [id, errorLoading, datasourceId, metadata, afterDataCb, gridOptions]
  );

  const checkBase = useRefCallback(
    (() => {
      return new Promise((resolve, reject) => {
        check(resolve, reject);
      });
    }) as PDFAsyncCallback,
    [check]
  );

  const cachedContent = usePDFAsync(id, checkBase);

  if (datasource && errorLoading) {
    return (
      <PdfContentMessage
        icon={faSkullCrossbones}
        message={`Currently not able to load this ${name} widget's data right now.`}
      />
    );
  } else if (!datasource) {
    return <PdfContentMessage message={`Please configure ${name} widget.`} />;
  } else if (cachedContent.loading) {
    return (
      <>
        {metadata?.progressSteps ? (
          <PdfReportLoadingStatus steps={metadata?.progressSteps || []} />
        ) : (
          <PdfContentMessage icon={faSpinner} iconColor="#4f545c" />
        )}
      </>
    );
  } else if (isProgressStepsComplete(metadata)) {
    return (
      <>{typeof children === "function" ? children(cachedContent) : children}</>
    );
  }

  return null;
};
