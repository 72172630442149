import {
  GenericInputSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { SelectOptionsType } from "@enfusion-ui/types";
import {
  Checkbox,
  FormSectionAccordion,
  TextInput,
} from "@enfusion-ui/web-components";
import { isEqual } from "lodash";
import * as React from "react";

import { InputWrapper, SingleRowContainer } from "../../../styles";
import GenericInput from "./GenericInput";
import { GenericInputSelect } from "./GenericInputSelect";
import { GenericInputTypeSelect } from "./GenericInputTypeSelect";

const GenericInputSelectionEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const {
    key = "Generic Input",
    type = "string",
    defaultValue = null,
    showLabel = true,
    genericInputOptions = [],
  } = editedConfig as GenericInputSelectionWidgetConfig;

  const [defaultInputSelect, setDefaultInputSelect] = React.useState<
    string | null
  >(defaultValue);

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleOptionChange =
    (key: string) => (option: SelectOptionsType<string> | null) => {
      setDefaultInputSelect(option?.value ?? null);
      changeConfigKeyToBeApplied(key, option?.value);
    };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBasicChange = (key: string) => (value: any) => {
    if (key === "genericInputOptions") {
      if (isEqual(genericInputOptions, value)) return;
      changeConfigKeyToBeApplied(key, value);

      if (defaultInputSelect) setDefaultInputSelect("");

      return;
    }
    changeConfigKeyToBeApplied(key, value);
  };

  return (
    <>
      <FormSectionAccordion title="Data Options">
        <TextInput
          label="Key"
          value={key}
          name="key"
          onChange={handleTextChange("key")}
        />
        <SingleRowContainer>
          <InputWrapper>
            <Checkbox
              label="Show Label"
              name="showLabel"
              checked={showLabel}
              onChange={handleBasicChange("showLabel")}
              topLabelPlaceholder
            />
          </InputWrapper>
          <InputWrapper>
            <GenericInputTypeSelect
              value={type}
              onChange={handleOptionChange("type")}
            />
          </InputWrapper>
        </SingleRowContainer>
      </FormSectionAccordion>
      <FormSectionAccordion title="Generic Input Options">
        <InputWrapper>
          <GenericInput
            key="GenericInput"
            label="Generic Input"
            name="Generic Input"
            onChange={handleBasicChange("genericInputOptions")}
          />
        </InputWrapper>
        <GenericInputSelect
          value={defaultInputSelect}
          label="Default Generic Input Select"
          onChange={handleOptionChange("defaultValue")}
        />
      </FormSectionAccordion>
    </>
  );
};

export default GenericInputSelectionEditWidget;
