import {
  TRADE_TICKET_TAB_CONTENT_MIN_WIDTH,
  TRADE_TICKET_TAB_SUMMARY_DEFAULT_WIDTH,
  TRADE_TICKET_TAB_SUMMARY_MIN_WIDTH,
} from "@app-views/trade/TradeTicket/utils";
import {
  ContentContainer,
  HorizontallyResizable,
  HorizontallyResizableProps,
} from "@enfusion-ui/web-components";
import { styled, useThisTab } from "@enfusion-ui/web-core";
import * as React from "react";

const SectionContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: var(--spacing-xl);
  border-left: solid 1px var(--border);

  overflow-y: auto;
  overflow-x: hidden;
`;

export const ContentSummaryResizableContainer: React.FC<
  React.PropsWithChildren<{
    onResizeCallBack: HorizontallyResizableProps["onResizeStop"];
  }>
> = ({ children, onResizeCallBack }) => {
  const { width } = useThisTab();

  const maxWidth = React.useMemo(
    () => (width ? width - TRADE_TICKET_TAB_CONTENT_MIN_WIDTH : undefined),
    [width]
  );

  return (
    <HorizontallyResizable
      open
      scope="tab"
      maxWidth={maxWidth}
      onResizeStop={onResizeCallBack}
      minWidth={TRADE_TICKET_TAB_SUMMARY_MIN_WIDTH}
      width={TRADE_TICKET_TAB_SUMMARY_DEFAULT_WIDTH}
    >
      <SectionContainer data-testid="tt-resizable-container">
        <ContentContainer>
          <div>Trade Information</div>
          {children}
        </ContentContainer>
      </SectionContainer>
    </HorizontallyResizable>
  );
};
