import { useRefCallback } from "@enfusion-ui/hooks";
import {
  ComplianceState,
  complianceStateTypeOptions,
  SelectOptionsType,
} from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import { MultiSelect, MultiSelectProps } from "@enfusion-ui/web-components";
import * as React from "react";

export type ComplianceStatesSelectProps = Omit<
  Partial<MultiSelectProps<string>>,
  "options" | "value" | "onChange"
> & {
  value?: string[] | null;
  onChange?: (ids: string[] | null) => void;
};

const ComplianceStatesSelect: React.FC<ComplianceStatesSelectProps> = ({
  value,
  onChange,
  placeholder = "All",
  label = "Compliance States",
  inputId = "compliance-state-multi-selection-id",
  ...rest
}) => {
  const [complianceStates, setComplianceStates] = React.useState<
    string[] | null
  >();

  React.useEffect(() => {
    if (value) {
      setComplianceStates(value);
    }
  }, [value]);

  const handleChange = useRefCallback(
    (values: SelectOptionsType<ComplianceState>[]) => {
      const complianceTypes =
        values?.map((option) => option.value as ComplianceState) ?? null;
      setComplianceStates(complianceTypes);
      onChange?.(complianceTypes);
    },
    [onChange]
  );

  return (
    <MultiSelect
      {...rest}
      placeholder={placeholder}
      label={label}
      value={
        complianceStates
          ? getSelectedValues(complianceStates, complianceStateTypeOptions)
          : []
      }
      onChange={(values) =>
        handleChange(values as SelectOptionsType<ComplianceState>[])
      }
      options={complianceStateTypeOptions}
      inputId={inputId}
    />
  );
};

export default ComplianceStatesSelect;
