import { RoundedDataGrid } from "@app-components/DataGrid";
import { useMarketData } from "@app-context/marketData/context";
import { useWatchList } from "@app-context/watchList/context";
import { useRefCallback } from "@enfusion-ui/hooks";
import { InstrumentInfo, Quote } from "@enfusion-ui/types";
import { useGridApi, valueTooltipGetter } from "@enfusion-ui/web-core";
import {
  CellClickedEvent,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import * as React from "react";

import PriceCellRenderer from "./PriceCellRenderer";
import { COLUMN_DEFS } from "./utils";

type MarketData = {
  contract: string;
  currency: string;
} & Quote;

type WatchListBlotterProps = {
  instruments: Array<InstrumentInfo>;
};

const WatchListBlotter: React.FC<WatchListBlotterProps> = React.memo(
  ({ instruments }) => {
    const { subscribeToMarketData } = useMarketData();
    const subscribedInstruments = React.useRef<number[]>([]);
    const { openOrder } = useWatchList();

    const transactionRef = React.useRef<{
      add: MarketData[];
      update: MarketData[];
    }>({ add: [], update: [] });

    const applyTransaction = useRefCallback((gridApi: GridApi) => {
      gridApi.applyTransaction(transactionRef.current);
      transactionRef.current = { add: [], update: [] };
    }, []);

    const { gridApiRef, onGridReady } = useGridApi((e: GridReadyEvent) => {
      applyTransaction(e.api);
    });

    const marketDataChangeHandler = useRefCallback(
      (instrument: InstrumentInfo) => (quote: Quote) => {
        const item: MarketData = {
          ...quote,
          contract: instrument.description || "",
          currency: instrument.currency?.code || "",
        };
        const key = subscribedInstruments.current?.some(
          (subscribe) => subscribe === instrument.id
        )
          ? "update"
          : "add";
        transactionRef.current[key].push(item);

        if (gridApiRef.current) {
          applyTransaction(gridApiRef.current.api);
        }
      },
      []
    );

    React.useEffect(() => {
      const unsubscribers = instruments?.map((instrument) =>
        subscribeToMarketData(
          instrument.id,
          marketDataChangeHandler(instrument)
        )
      );
      return () => unsubscribers.forEach((unsubscribe) => unsubscribe());
    }, [instruments]);

    const onCellClicked = useRefCallback(
      (event: CellClickedEvent) => {
        const colField = event.colDef.field;
        const { instrumentId, bid, ask, last } = event.data as MarketData;
        switch (colField) {
          case "bid": {
            openOrder({
              instrumentId,
              orderSide: "Sell",
              limitPrice: bid,
              orderType: "Limit",
            });
            break;
          }
          case "ask": {
            openOrder({
              instrumentId,
              orderSide: "Buy",
              limitPrice: ask,
              orderType: "Limit",
            });
            break;
          }
          case "last": {
            openOrder({
              instrumentId,
              limitPrice: last,
              orderType: "Limit",
            });
            break;
          }
          default: {
            openOrder({
              instrumentId,
            });
          }
        }
      },
      [openOrder]
    );

    return (
      <RoundedDataGrid
        height="100%"
        onGridReady={onGridReady}
        getRowId={(params: GetRowIdParams) => params.data.instrumentId}
        suppressRowClickSelection
        suppressColumnMoveAnimation
        columnDefs={COLUMN_DEFS}
        defaultColDef={{
          resizable: true,
          suppressKeyboardEvent: () => true,
          tooltipValueGetter: valueTooltipGetter,
        }}
        components={{ priceCellRenderer: PriceCellRenderer }}
        onCellClicked={onCellClicked}
      />
    );
  }
);

export default WatchListBlotter;
