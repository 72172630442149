import { AccountingMethodOptions } from "@enfusion-ui/types";
import { ControlledSelect, PanelRow } from "@enfusion-ui/web-components";

import { DateRangeSymbolSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledLedgerSelect } from "../../../components/ReportQueryComponents/LedgerSelect";

const GLPositionQuickFilter = () => {
  return (
    <>
      <PanelRow>
        <ControlledLedgerSelect name="generalLedgerIds" label="Ledger" />
      </PanelRow>
      <PanelRow>
        <DateRangeSymbolSelection name="period" label="Period (From/To)" />
      </PanelRow>
      <PanelRow>
        <ControlledSelect
          name="accountingMethod"
          options={AccountingMethodOptions}
          label="Accounting Method"
        />
      </PanelRow>
    </>
  );
};

export default GLPositionQuickFilter;
