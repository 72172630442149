/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
import { flattenPortfolioTree, spreadPortfolioColumnValues, } from "@enfusion-ui/portfolios";
import { pick } from "lodash";
import { SocketController } from "../utils/socketController";
import { WebWorkerMessenger, } from "./types";
const messenger = new WebWorkerMessenger();
const MessageType = {
    update: "update",
    sync: "sync",
    error: "error",
    argumentError: "argumentError",
    close: "close",
    flag: "flag",
};
const sockets = new Map();
const SOCKET_PATHS = ["oms", "construction"];
const DESTINATION_PATHS = ["/oms/construction", "/construction"];
const MAX_SOCKETS = 10;
const CLOSE_SOCKET_TIMEOUT_IDS = new Map();
let socketPath = SOCKET_PATHS[1];
let destinationPath = DESTINATION_PATHS[1];
const getController = (id) => {
    if (sockets.has(id))
        return sockets.get(id);
    const socketController = new SocketController({
        autoReady: false,
        path: `wss://${self.location.host}/${socketPath}`,
        onOpen: (socket) => {
            const socketRef = sockets.get(id);
            let config = {
                command: "CONNECT",
                "accept-version": "1.2",
                "sub-to": destinationPath,
            };
            if (socketRef?.session)
                config.session = socketRef.session;
            socket.send(JSON.stringify(config));
        },
        onMessage: async (event, socket) => {
            const eventMessage = JSON.parse(event.data);
            //should not send message if some provider has terminated the worker/socket
            if (socket.readyState !== WebSocket.CLOSED &&
                socket.readyState !== WebSocket.CLOSING) {
                if (eventMessage.command === "MESSAGE") {
                    switch (eventMessage.payload.type) {
                        case MessageType.update: {
                            const res = sockets.get(id);
                            const payload = eventMessage.payload;
                            const proposedOrders = payload.proposedOrders ?? [];
                            const rowsUpdated = payload.rowsUpdated ?? [];
                            const rowsDeleted = payload.rowsDeleted ?? [];
                            const allRowsUpdated = payload.allRowsUpdated ?? [];
                            const rootRowAdded = payload.rootRowAdded;
                            const added = rootRowAdded
                                ? flattenPortfolioTree(rootRowAdded)
                                : [[], []];
                            // add to map refs on add
                            // parent nodes / leaf nodes
                            for (const entry of [...added[1], ...added[0]]) {
                                res.rootState.set(entry.rowId, entry);
                            }
                            const [parentsUpdated, updated] = allRowsUpdated.reduce((res, i) => {
                                if (rowsUpdated.findIndex((e) => e.rowId === i.rowId) < 0)
                                    res[0].push(i);
                                else
                                    res[1].push(i);
                                return res;
                            }, [[], []]);
                            const removed = rowsDeleted.map((i) => {
                                res.rootState.delete(i);
                                res.diffRef.delete(i);
                                return { rowId: i };
                            });
                            res.diffRef = createDiffMap(res.diffRef, res.rootState, allRowsUpdated);
                            messenger.broadcast({
                                id,
                                type: "update",
                                payload: {
                                    changed: simplifyDiff(res.diffRef),
                                    parentsUpdated,
                                    added,
                                    updated,
                                    removed,
                                },
                            });
                            messenger.broadcast({
                                id,
                                type: "proposed-orders",
                                payload: { orders: proposedOrders },
                            });
                            break;
                        }
                        case MessageType.sync: {
                            const { payload } = eventMessage;
                            const socketEntry = sockets.get(id);
                            const positionRows = flattenPortfolioTree(payload.rootRow);
                            const cashRows = flattenPortfolioTree(payload.cashRoot);
                            const initData = [
                                ...spreadPortfolioColumnValues(positionRows[0]),
                                ...spreadPortfolioColumnValues(cashRows[0]),
                            ];
                            socketEntry.rootState = initData.reduce((res, entry) => {
                                res.set(entry.rowId, entry);
                                return res;
                            }, new Map());
                            const parentTreeNodes = [
                                ...positionRows[1],
                                ...cashRows[1],
                            ].reduce((res, entry) => {
                                socketEntry.rootState.set(entry.rowId, entry);
                                res[entry.rowId] = entry;
                                return res;
                            }, {});
                            messenger.broadcast({
                                id,
                                payload: {
                                    settings: payload.settings,
                                    parentTreeNodes,
                                    initData,
                                },
                                type: "sync",
                            });
                            break;
                        }
                        case MessageType.argumentError: {
                            const { payload } = eventMessage;
                            messenger.broadcast({
                                id,
                                payload,
                                type: "argument-error",
                            });
                            break;
                        }
                        case MessageType.flag: {
                            const { payload } = eventMessage;
                            messenger.broadcast({
                                id,
                                payload,
                                type: "flag",
                            });
                            break;
                        }
                        case MessageType.error: {
                            const { payload } = eventMessage;
                            messenger.broadcast({
                                id,
                                payload,
                                type: "set-error",
                            });
                            break;
                        }
                    }
                }
                else if (eventMessage.command === "CONNECTED") {
                    const res = sockets.get(id);
                    const hasSession = !!res.session;
                    socketController.ready(hasSession);
                    res.session = eventMessage.session;
                }
            }
        },
        onError: (err) => {
            console.error("Error in Portfolio Worker:", err);
            messenger.broadcast({
                id,
                type: MessageType.error,
                payload: {
                    message: err.message || "Error in Portfolio Worker",
                },
            });
            socketController.close();
        },
        onClose: (ev, socket) => {
            console.warn("Portfolios socket close", ev);
            messenger.broadcast({
                id,
                type: MessageType.close,
                payload: { readyState: socket.readyState },
            });
        },
        onWillReconnect: (socket) => {
            socket.send(JSON.stringify({
                destination: destinationPath,
                command: "UNSUBSCRIBE",
            }));
        },
        onDisconnect: (socket) => {
            socket.send(JSON.stringify({
                destination: destinationPath,
                command: "UNSUBSCRIBE",
            }));
            messenger.broadcast({
                id,
                type: MessageType.close,
                payload: { readyState: socket.readyState },
            });
        },
        onReady: (_s, forceSub) => {
            const socketRef = sockets.get(id);
            if (socketRef && (socketRef.reconnect !== null || forceSub)) {
                const payload = { ...(socketRef.reconnect || {}) };
                sockets.set(id, { ...socketRef, reconnect: null });
                socketController.send(JSON.stringify({
                    command: "SUBSCRIBE",
                    destination: destinationPath,
                    payload,
                }));
            }
        },
    });
    sockets.set(id, {
        controller: socketController,
        rootState: new Map(),
        diffRef: new Map(),
        reconnect: null,
    });
    socketController.init();
    return sockets.get(id);
};
function simplifyDiff(diffMap) {
    const res = {};
    for (const [key, entry] of diffMap.entries())
        res[key] = [...entry];
    return res;
}
function createDiffMap(diffMapOld, rootList, updateList) {
    const diffMap = new Map(diffMapOld);
    for (const entry of updateList) {
        const oldValues = rootList.get(entry.rowId)?.columnValues;
        if (oldValues) {
            for (const [key, newValue] of Object.entries(entry.columnValues)) {
                if (["rowId", "parentRowId"].includes(key))
                    continue;
                if (typeof oldValues[key] === "undefined")
                    continue;
                if (oldValues[key]?.value !== newValue?.value) {
                    const diffEntry = diffMap.get(entry.rowId) ?? new Set();
                    diffEntry.add(key);
                    diffMap.set(entry.rowId, diffEntry);
                }
            }
        }
    }
    return diffMap;
}
export const PortfoliosModule = {
    enable: (postMessage) => {
        messenger.send = postMessage;
    },
    disable: () => {
        sockets.forEach(({ controller }) => controller.close("portfolios"));
    },
    getCurrentState: () => {
        return {
            socketStatus: [...sockets.entries()].map((i) => [
                i[0],
                i[1].controller.socketStatus,
            ]),
        };
    },
    onTerminate: () => {
        sockets.forEach(({ controller }) => controller.close());
    },
    onMessage: (_message, _hostId) => {
        const { payload, command, destination: id } = _message;
        if (typeof payload)
            if (!sockets.has(id) && sockets.size === MAX_SOCKETS) {
                messenger.broadcast({
                    id,
                    type: "max-sockets-reached",
                    payload: {
                        message: "Max number of portfolio sockets open",
                    },
                });
            }
            else {
                if (command === "load") {
                    const canCreate = typeof payload.canCreateOrders === "boolean"
                        ? payload.canCreateOrders
                        : false;
                    const idx = canCreate ? 0 : 1;
                    socketPath = SOCKET_PATHS[idx];
                    destinationPath = DESTINATION_PATHS[idx];
                }
                const { controller } = getController(id);
                switch (command) {
                    case "load": {
                        const { canCreateOrders: canCreateOrderIgnored, ...rest } = payload;
                        controller.send(JSON.stringify({
                            command: "SUBSCRIBE",
                            destination: destinationPath,
                            payload: rest,
                        }));
                        break;
                    }
                    case "reload": {
                        const { canCreateOrders: canCreateOrderIgnored, ...rest } = payload;
                        const socketRef = sockets.get(id);
                        if (socketRef && socketRef.reconnect === null) {
                            sockets.set(id, {
                                ...socketRef,
                                reconnect: rest,
                                session: undefined,
                            });
                            controller.reconnect(true);
                        }
                        break;
                    }
                    case "close": {
                        if (payload?.delay) {
                            CLOSE_SOCKET_TIMEOUT_IDS.set(id, setTimeout(() => {
                                controller.close("close command");
                                sockets.delete(id);
                                CLOSE_SOCKET_TIMEOUT_IDS.delete(id);
                            }, payload.delay));
                            break;
                        }
                        controller.close("close command");
                        sockets.delete(id);
                        break;
                    }
                    case "cancel-close": {
                        if (CLOSE_SOCKET_TIMEOUT_IDS.has(id)) {
                            clearTimeout(CLOSE_SOCKET_TIMEOUT_IDS.get(id));
                            CLOSE_SOCKET_TIMEOUT_IDS.delete(id);
                        }
                        break;
                    }
                    case "add-security": {
                        controller.send(JSON.stringify({
                            command: "SEND",
                            destination: destinationPath,
                            payload: {
                                action: "AddSecurity",
                                instrumentId: payload.instrumentId,
                            },
                        }));
                        break;
                    }
                    case "remove-security": {
                        controller.send(JSON.stringify({
                            command: "SEND",
                            destination: destinationPath,
                            payload: {
                                action: "RemoveSecurity",
                                instrumentId: payload.instrumentId,
                            },
                        }));
                        break;
                    }
                    case "regroup": {
                        controller.send(JSON.stringify({
                            command: "SEND",
                            destination: `${destinationPath}/group`,
                            payload: {
                                groupings: payload.groupings,
                            },
                        }));
                        break;
                    }
                    case "rebalance": {
                        controller.send(JSON.stringify({
                            command: "SEND",
                            destination: destinationPath,
                            payload: {
                                action: "Rebalance",
                                lockedRowIds: payload.lockedRowIds,
                            },
                        }));
                        break;
                    }
                    case "adjust": {
                        controller.send(JSON.stringify({
                            command: "SEND",
                            destination: destinationPath,
                            payload: {
                                action: "Adjustment",
                                commandType: payload.command,
                                targetRowId: payload.targetRowId,
                                target: payload.target,
                                lockedRowIds: payload.lockedRowIds,
                                targetDistributionMethod: payload.targetDistributionMethod,
                            },
                        }));
                        break;
                    }
                    case "undo-adjustment": {
                        controller.send(JSON.stringify({
                            command: "SEND",
                            destination: destinationPath,
                            payload: {
                                action: "UndoLastAdjustment",
                            },
                        }));
                        break;
                    }
                    case "price-override": {
                        controller.send(JSON.stringify({
                            command: "SEND",
                            destination: destinationPath,
                            payload: {
                                action: "PriceOverride",
                                target: payload.target,
                                targetRowId: payload.targetRowId,
                            },
                        }));
                        break;
                    }
                    case "change-settings": {
                        controller.send(JSON.stringify({
                            command: "SEND",
                            destination: `${destinationPath}/settings`,
                            payload: {
                                ...pick(payload, [
                                    "rebalancingDurationType",
                                    "rebalanceCalculationLevel",
                                    "valueDate",
                                    "marketEnvironment",
                                    "checkComplianceAutomatically",
                                    "includeActiveOrders",
                                    "resolveBenchmarksByBook",
                                ]),
                                rebalancingExposureType: payload.numeratorAggregation,
                                rebalancingBenchmarkType: payload.denominator,
                                rebalancingDurationType: payload.rebalancingDurationType,
                                deminimusQuantity: payload.quantity,
                                deminimusExposure: payload.exposure,
                                deminimusIncrementalExposurePercent: payload.perIncremental,
                            },
                        }));
                        break;
                    }
                    case "modify-orders": {
                        controller.send(JSON.stringify({
                            command: "SEND",
                            destination: destinationPath,
                            payload: {
                                action: "ModifyOrders",
                                orders: payload.orders,
                            },
                        }));
                        break;
                    }
                    case "submit-orders": {
                        controller.send(JSON.stringify({
                            command: "SEND",
                            destination: `${destinationPath}/generate`,
                            payload: {
                                stageOrdersOnComplianceFailure: payload.stageOrdersOnComplianceFailure,
                            },
                        }));
                        break;
                    }
                    case "test-compliance": {
                        controller.send(JSON.stringify({
                            command: "SEND",
                            destination: destinationPath,
                            payload: {
                                action: "RunCompliance",
                            },
                        }));
                        break;
                    }
                }
            }
    },
};
