import { getCanCreateOrders, PORTFOLIO_LATEST_CONFIG_VERSION, REST_API as createRestAPI, } from "@enfusion-ui/core";
import { basicInfoMap, useMounted, useReducerWithMiddleware, useRefCallback, useRestAbortableOptions, } from "@enfusion-ui/hooks";
import { PortfolioRevertMode, } from "@enfusion-ui/types";
import { getFileName, IndexSetStore } from "@enfusion-ui/utils";
import { cloneDeep, debounce, isEqual, pick } from "lodash";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useDataHandlers, usePortfolioEventState, useTouch } from "../hooks";
import { AdjustmentToColIdMap, ColIdToAdjustmentMap, } from "./columns";
import { PORTFOLIO_TARGET_DIST_METHODS, TOAST_MESSAGES } from "./constants";
import { PortfolioContext } from "./context";
import { portfolioDefaultState, portfoliosProviderHistoryReducer, portfoliosProviderReducer, } from "./reducer";
import { getOrder, getProposedOrderChanges, getProposedOrderTransaction, spreadPortfolioColumnValues, } from "./utils";
export const PortfolioProvider = ({ children, restServer, user, config: initialConfig, middlewareFns, onLoad, onSave, onRemoveProposedAllocation, afterLockedRowsChanged, errorToast, successToast, infoToast, warningToast, closeTab, }) => {
    const { id, filePath } = initialConfig;
    const isMounted = useMounted();
    const { triggerDataHandler, registerHandlers } = useDataHandlers();
    const { getTouched, adjustTouchedCount, resetTouchedCount } = useTouch();
    const restApi = React.useRef(createRestAPI(restServer));
    const [state, dispatch] = useReducerWithMiddleware(portfoliosProviderReducer, portfolioDefaultState, [], [portfoliosProviderHistoryReducer, ...(middlewareFns ?? [])], true);
    const [configState, setConfigState] = React.useState({
        ...initialConfig,
        filePath: initialConfig.filePath,
    });
    const [revertMode, setRevertModeInternal] = React.useState(PortfolioRevertMode.REVERT);
    const [syncReceived, setSyncReceived] = React.useState(false);
    const [changedFields, setChangedFields] = React.useState({});
    const [hasProposedOrders, setHasProposedOrders] = React.useState(false);
    const [hasRowUpdates, setHasRowUpdates] = React.useState(false);
    const [selectedOrders, setSelectedOrders] = React.useState([]);
    const [reloaded, setReloaded] = React.useState(false);
    const parentTreeNodesRef = React.useRef(new Map());
    const rowPathRef = React.useRef(new Map());
    const proposedOrdersRef = React.useRef([]);
    const lockedRowIds = React.useRef(new Set());
    const filteredRowIds = React.useRef(new Set());
    const rowUpdatesRef = React.useRef(new Set());
    const fundsChangedRef = React.useRef(false);
    const securities = React.useRef(new IndexSetStore());
    const { hasUnsavedChanges } = state;
    const setLockedRowIds = useRefCallback((rowIds) => {
        lockedRowIds.current = new Set(rowIds);
        afterLockedRowsChanged?.();
    }, []);
    const setFilteredRowIds = useRefCallback((rowIds) => {
        filteredRowIds.current = new Set(rowIds);
    }, []);
    const getParentRows = useRefCallback(() => ({
        rows: parentTreeNodesRef.current,
        paths: rowPathRef.current,
    }), []);
    const adjustmentState = usePortfolioEventState(initialConfig.id, TOAST_MESSAGES.adjustment, errorToast, successToast, infoToast);
    const modifyingOrdersState = usePortfolioEventState(initialConfig.id, TOAST_MESSAGES.modifyOrders, errorToast, successToast, infoToast);
    const complianceTestState = usePortfolioEventState(initialConfig.id, TOAST_MESSAGES.testCompliance, errorToast, successToast, infoToast);
    const submittingOrdersState = usePortfolioEventState(initialConfig.id, TOAST_MESSAGES.submitOrders, errorToast, successToast, infoToast);
    const hydrate = React.useCallback((payload) => {
        dispatch({
            type: "hydrate",
            payload,
        });
    }, [dispatch]);
    const tradingDeskData = useRestAbortableOptions(restApi.current.SECURITY.GET_TRADING_DESKS.FETCH, {
        map: (td) => ({ value: td.id, label: td.groupName }),
    });
    const strategyData = useRestAbortableOptions(restApi.current.FUND.GET_STRATEGY_BOOKS.FETCH, basicInfoMap);
    const portfolioManagerData = useRestAbortableOptions(restApi.current.SECURITY.GET_PORTFOLIO_MANAGERS.FETCH, {
        map: (pm) => ({ value: pm.id, label: pm.fullName }),
    });
    const traderData = useRestAbortableOptions(restApi.current.SECURITY.GET_ALL_TRADERS.FETCH, {
        map: (td) => ({ value: td.id, label: td.fullName }),
    });
    const formatProposedOrderRowGroupValue = useRefCallback((field, value, emptyValue = "(Blank)") => {
        function getLabel(options) {
            return (options?.find((opt) => opt.value === parseInt(value))?.label ||
                emptyValue);
        }
        switch (field) {
            case "trader":
                return getLabel(traderData.options);
            case "tradingDesk":
                return getLabel(tradingDeskData.options);
            case "bookId":
                return getLabel(strategyData.options);
            case "portfolioManager":
                return getLabel(portfolioManagerData.options);
            default:
                return value || emptyValue;
        }
    }, [
        traderData.options,
        tradingDeskData.options,
        strategyData.options,
        portfolioManagerData.options,
    ]);
    const resetSelectedOrders = useRefCallback(() => setSelectedOrders([]), [setSelectedOrders]);
    const getPortfolio = useRefCallback((filePath) => restApi.current.PORTFOLIO.DOWNLOAD_PORTFOLIO(filePath), [restApi]);
    const storePortfolio = useRefCallback((params) => restApi.current.PORTFOLIO.STORE_PORTFOLIO(params), [restApi]);
    const loadPortfolio = useRefCallback(async (filePath, reload) => {
        try {
            const portfolioData = await getPortfolio(filePath);
            /**
             * Earlier file name was taken from dashboardData.
             * However it seems the rename API does not change the dashboardData.
             * Therefore for now the new fileName is extracted from the ${filePath}
             */
            let dashboardDisplayName = getFileName({
                id: filePath,
                name: filePath,
                path: filePath,
            });
            /**
             * getFileName gives the entire path including the root.
             * So we need to separate just the filename by substring-ing from "/"
             * So basically we take "user/fileName.json" and extract out just "fileName"
             */
            const rootPrefixIndex = dashboardDisplayName.lastIndexOf("/");
            if (rootPrefixIndex !== -1) {
                dashboardDisplayName = dashboardDisplayName.substring(rootPrefixIndex + 1);
            }
            if (portfolioData) {
                const { settings, config, version, configVersion } = portfolioData;
                hydrate({
                    config,
                    settings,
                    version,
                    configVersion: typeof configVersion === "number"
                        ? configVersion
                        : PORTFOLIO_LATEST_CONFIG_VERSION,
                    name: dashboardDisplayName,
                    id,
                });
                onLoad?.(id, dashboardDisplayName, config, settings, reload, true);
                if (reload)
                    setReloaded(true);
            }
        }
        catch (err) {
            dispatch({
                type: "set-error",
                payload: { message: "Failed to load" },
            });
            console.error("workbench load error", err);
        }
    }, [id, getPortfolio, dispatch, hydrate, onLoad]);
    const savePortfolio = useRefCallback(async (newFileName, newFilePath, newRootPath, forceWrite) => {
        try {
            const path = newFilePath;
            const res = await storePortfolio({
                path,
                rootPath: newRootPath,
                name: newFileName,
                version: state.version + 1,
                config: state.config,
                settings: state.settings,
                forceWrite,
            });
            if (res.success) {
                dispatch({
                    type: "save",
                    payload: { name: newFileName },
                });
                // Post saving the New dashboard, update the config object
                setConfigState((currentState) => ({
                    ...currentState,
                    filePath: res.filePath,
                    root: newRootPath,
                }));
                if (isMounted() && revertMode !== PortfolioRevertMode.CLOSE_TAB) {
                    setRevertModeInternal(PortfolioRevertMode.REVERT);
                }
                onSave?.({
                    path,
                    rootPath: newRootPath,
                    name: newFileName,
                    version: state.version + 1,
                    config: state.config,
                    settings: state.settings,
                    forceWrite,
                });
                successToast("Workbench saved successfully");
            }
            else {
                console.error("workbench save error", res);
                throw new Error("Failed to save workbench changes");
            }
        }
        catch (err) {
            console.error("workbench save error", err);
            if (err.message?.includes("already exists")) {
                throw new Error("Failed to save workbench. Workbench already exists");
            }
            if (err.message?.startsWith("Failed to save workbench")) {
                throw err;
            }
            throw new Error("Failed to save workbench changes");
        }
    }, [state, storePortfolio, dispatch, onSave, isMounted, revertMode]);
    const saveChanges = useRefCallback(() => {
        return savePortfolio(state.name, configState.filePath.slice(configState.root.length + 1), configState.root, true);
    }, [state, configState, savePortfolio]);
    React.useEffect(() => {
        loadPortfolio(filePath);
    }, [filePath]);
    React.useEffect(() => {
        if (isMounted() && revertMode === PortfolioRevertMode.CLOSE_TAB) {
            setTimeout(() => closeTab?.(), 500);
        }
    }, [revertMode]);
    const setRevertMode = (mode) => {
        setRevertModeInternal(mode);
    };
    const cleanup = useRefCallback(() => {
        setHasProposedOrders(false);
        setSyncReceived(false);
        setReloaded(false);
        setChangedFields({});
        setHasRowUpdates(false);
        setSelectedOrders([]);
        resetTouchedCount();
        parentTreeNodesRef.current = new Map();
        proposedOrdersRef.current = [];
        rowPathRef.current = new Map();
        lockedRowIds.current = new Set();
        filteredRowIds.current = new Set();
        rowUpdatesRef.current = new Set();
        fundsChangedRef.current = false;
        if (adjustmentState.getActive())
            adjustmentState.fail();
        else
            adjustmentState.clearTimeouts();
        if (complianceTestState.getActive())
            complianceTestState.fail();
        else
            complianceTestState.clearTimeouts();
    }, []);
    const reloadPortfolio = useRefCallback(() => {
        cleanup();
        return loadPortfolio(filePath, true);
    }, [filePath, loadPortfolio]);
    const addSecurity = useRefCallback((instrumentId, keepFuture = false) => {
        dispatch({
            type: "add-security",
            keepFuture,
            payload: { instrumentId },
        });
    }, [dispatch]);
    const removeSecurity = useRefCallback((instrumentId, keepFuture = false) => {
        dispatch({
            type: "remove-security",
            keepFuture,
            payload: { instrumentId },
        });
    }, [dispatch]);
    const changeSettings = useRefCallback((settings, send = true, keepFuture = false) => {
        dispatch({
            type: "change-settings",
            keepFuture,
            payload: {
                send,
                settings,
            },
        });
    }, [dispatch]);
    const modifyOrders = useRefCallback((orders, keepFuture = false) => {
        modifyingOrdersState.start();
        dispatch({
            type: "modify-orders",
            keepFuture,
            payload: { orders },
        });
    }, [dispatch]);
    const removeHistory = useRefCallback((historyId) => dispatch({ type: "remove-history", payload: { historyId } }), [dispatch]);
    const adjust = useRefCallback((command, targetRowId, target, targetDistributionMethod = PORTFOLIO_TARGET_DIST_METHODS.matchExisting, keepFuture = false) => {
        const historyId = uuidv4();
        if (command === ColIdToAdjustmentMap.price) {
            dispatch({
                type: "price-override",
                keepFuture,
                historyId,
                payload: { targetRowId, target },
            });
        }
        else {
            dispatch({
                type: "adjust",
                keepFuture,
                historyId,
                payload: {
                    command,
                    targetRowId,
                    target,
                    lockedRowIds: [
                        ...new Set([...lockedRowIds.current, ...filteredRowIds.current]),
                    ],
                    targetDistributionMethod,
                },
            });
        }
        adjustmentState.start({
            onComplete: () => adjustTouchedCount(targetRowId, AdjustmentToColIdMap[command]),
            onFail: () => removeHistory(historyId),
        });
    }, [dispatch, adjustTouchedCount, removeHistory]);
    const undoAction = useRefCallback(() => {
        if (state.historyStack?.length > 0) {
            const lastAction = state.historyStack[state.historyStack.length - 1];
            const { type, payload } = lastAction;
            if (type === "adjust" || type === "price-override") {
                const field = type === "adjust" ? AdjustmentToColIdMap[payload.command] : "price";
                adjustmentState.start({
                    onComplete: () => adjustTouchedCount(payload.targetRowId, field, true),
                });
            }
            else if (type === "modify-orders") {
                modifyingOrdersState.start();
            }
            dispatch({ type: "undo", payload: lastAction });
        }
        return Promise.resolve();
    }, [dispatch, state.historyStack]);
    const redoAction = useRefCallback(() => {
        if (state.futureStack?.length > 0) {
            const futureAction = state.futureStack[state.futureStack.length - 1];
            const keepFutureStack = true;
            switch (futureAction.type) {
                case "adjust": {
                    adjust(futureAction.payload.command, futureAction.payload.targetRowId, futureAction.payload.target, futureAction.payload.targetDistributionMethod, keepFutureStack);
                    break;
                }
                case "price-override": {
                    adjust(ColIdToAdjustmentMap.price, futureAction.payload.targetRowId, futureAction.payload.target, PORTFOLIO_TARGET_DIST_METHODS.matchExisting, keepFutureStack);
                    break;
                }
                case "add-security": {
                    addSecurity(futureAction.payload.instrumentId, keepFutureStack);
                    break;
                }
                case "remove-security": {
                    removeSecurity(futureAction.payload.instrumentId, keepFutureStack);
                    break;
                }
                case "change-settings": {
                    changeSettings(futureAction.payload.settings, true, keepFutureStack);
                    break;
                }
                case "modify-orders": {
                    modifyOrders(futureAction.payload.orders, keepFutureStack);
                    break;
                }
            }
            dispatch({ type: "redo" });
        }
        return Promise.resolve();
    }, [dispatch, state.futureStack]);
    const testCompliance = useRefCallback(() => {
        complianceTestState.start();
        dispatch({ type: "test-compliance" });
    }, [dispatch]);
    const submitProposedOrders = useRefCallback((ignoreCompliance) => {
        submittingOrdersState.start();
        dispatch({
            type: "submit-orders",
            payload: {
                stageOrdersOnComplianceFailure: ignoreCompliance ?? false,
            },
        });
    }, [dispatch]);
    const rebalance = useRefCallback(() => {
        dispatch({
            type: "rebalance",
            payload: {
                lockedRowIds: [
                    ...new Set([...lockedRowIds.current, ...filteredRowIds.current]),
                ],
            },
        });
    }, []);
    const revertChanges = useRefCallback((revertModeOverride) => {
        const currentRevertMode = revertModeOverride ?? revertMode;
        if (hasUnsavedChanges)
            dispatch({
                type: "revert",
                payload: { revertMode: currentRevertMode },
            });
        if (currentRevertMode !== PortfolioRevertMode.CLOSE_TAB) {
            setRevertMode(PortfolioRevertMode.REVERT);
        }
    }, [dispatch, revertMode, hasUnsavedChanges]);
    const changeFundsAndSettings = useRefCallback((settings, fundIds) => {
        changeSettings(settings);
        // TODO: Update fund changes as a change-settings once the API gets updated
        if (!isEqual(fundIds.sort(), state.config.fundIds.sort())) {
            fundsChangedRef.current = true;
            const id = uuidv4();
            dispatch({
                type: "change-funds",
                payload: {
                    fundIds,
                    id,
                },
            });
            setConfigState((state) => ({ ...state, id }));
            onLoad?.(id, state.name, { ...state.config, fundIds }, { ...state.settings, ...settings }, false, false);
        }
    }, [dispatch, state]);
    const regroup = useRefCallback(debounce((groupings) => {
        if (!isEqual(groupings, state.config.groupings))
            dispatch({
                type: "regroup",
                payload: {
                    groupings,
                },
            });
    }, 500), [dispatch, state.config.groupings]);
    const changeGridSettings = useRefCallback((payload) => {
        dispatch({
            type: "update-grid-settings",
            payload,
        });
    }, [dispatch]);
    const handleSocketSubscriptionCore = useRefCallback(async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    message, onMessage, onComplianceFailure) => {
        const { type, payload, id: destination, } = message;
        if (destination === configState.id ?? id) {
            switch (type) {
                case "sync": {
                    setSyncReceived(true);
                    securities.current = new IndexSetStore();
                    parentTreeNodesRef.current = new Map(Object.entries(payload.parentTreeNodes));
                    rowPathRef.current = new Map();
                    payload.initData.forEach((entry) => {
                        rowPathRef.current.set(entry.rowId, entry.path);
                        if (entry.columnValues.instrument)
                            securities.current.add([
                                [entry.columnValues.instrument.value, entry.rowId],
                            ]);
                    });
                    triggerDataHandler("initData", [payload.initData]);
                    if (fundsChangedRef.current)
                        infoToast("Funds updated");
                    fundsChangedRef.current = false;
                    changeSettings({
                        ...pick(payload.settings, [
                            "checkComplianceAutomatically:",
                            "rebalancingDurationType",
                            "rebalanceCalculationLevel",
                            "marketEnvironment",
                            "resolveBenchmarksByBook",
                            "includeActiveOrders",
                        ]),
                        numeratorAggregation: payload.settings.rebalancingExposureType,
                        denominator: payload.settings.rebalancingBenchmarkType,
                        quantity: payload.settings.deminimusQuantity,
                        exposure: payload.settings.deminimusExposure,
                        perIncremental: payload.settings.deminimusIncrementalExposurePercent,
                    }, false);
                    break;
                }
                case "update": {
                    adjustmentState.complete();
                    modifyingOrdersState.complete();
                    const { changed, added = [[], []], parentsUpdated = [], updated = [], removed = [], } = payload;
                    // add to map refs on add
                    for (const entry of added[1]) {
                        parentTreeNodesRef.current.set(entry.rowId, entry);
                    }
                    for (const entry of added[0]) {
                        rowPathRef.current.set(entry.rowId, entry.path);
                        if (entry.columnValues.instrument)
                            securities.current.add([
                                [entry.columnValues.instrument.value, entry.rowId],
                            ]);
                    }
                    // update map refs on update
                    for (const row of parentsUpdated) {
                        if (parentTreeNodesRef.current.has(row.rowId)) {
                            const val = parentTreeNodesRef.current.get(row.rowId);
                            parentTreeNodesRef.current.set(row.rowId, {
                                ...(val || {}),
                                columnValues: {
                                    ...(val?.columnValues || {}),
                                    ...row.columnValues,
                                },
                            });
                        }
                    }
                    // clean up map refs on remove
                    const remove = [];
                    for (const row of removed) {
                        if (!parentTreeNodesRef.current.has(row.rowId))
                            remove.push(row);
                        rowPathRef.current.delete(row.rowId);
                        parentTreeNodesRef.current.delete(row.rowId);
                        securities.current.removeIndexSync(row.rowId);
                    }
                    setChangedFields(changed);
                    const transaction = {
                        add: spreadPortfolioColumnValues(added[0]),
                        update: spreadPortfolioColumnValues(updated),
                        remove,
                    };
                    triggerDataHandler("updateData", [transaction]);
                    break;
                }
                case "proposed-orders": {
                    const { orders } = payload;
                    complianceTestState.complete();
                    const transaction = getProposedOrderTransaction(proposedOrdersRef.current, orders);
                    proposedOrdersRef.current = orders;
                    setHasProposedOrders(orders.length > 0);
                    if (transaction) {
                        triggerDataHandler("updateProposedOrders", [transaction]);
                    }
                    else {
                        triggerDataHandler("initProposedOrders", [[...orders]]);
                    }
                    modifyingOrdersState.complete();
                    break;
                }
                case "terminated":
                case "close":
                    adjustmentState.fail("Lost socket connection");
                    complianceTestState.fail("Lost socket connection");
                    submittingOrdersState.fail("Lost socket connection");
                    setSyncReceived(false);
                    break;
                case "argument-error": {
                    adjustmentState.fail(payload.message);
                    complianceTestState.fail(payload.message);
                    submittingOrdersState.fail(payload.message);
                    break;
                }
                case "flag": {
                    if (submittingOrdersState.getActive()) {
                        if (payload.success) {
                            submittingOrdersState.complete();
                            if (!state.settings.includeActiveOrders)
                                warningToast(TOAST_MESSAGES.reload.noLiveOrders);
                            cleanup();
                            onLoad?.(id, state.name, { ...state.config }, { ...state.settings }, true, false);
                        }
                        else {
                            submittingOrdersState.fail(payload.failedCompliance
                                ? payload.overrideNotAllowed
                                    ? TOAST_MESSAGES.submitOrders.errorSubOverrideNotAllowed
                                    : TOAST_MESSAGES.submitOrders.errorSubOverrideAllowed
                                : undefined);
                            if (payload.failedCompliance && !payload.overrideNotAllowed) {
                                onComplianceFailure?.(message);
                            }
                        }
                    }
                    break;
                }
                case "error":
                case "set-error": {
                    adjustmentState.fail();
                    dispatch({
                        type: "set-error",
                        payload: {
                            message: payload.message,
                        },
                    });
                    console.error("Set error occurred while connecting to portfolios.");
                    break;
                }
                case "max-sockets-reached": {
                    dispatch({
                        type: "set-max-sockets-reached",
                        payload: {
                            message: payload.message,
                            maxSocketsReached: true,
                        },
                    });
                    break;
                }
            }
            onMessage?.(message);
        }
    }, [id, state, configState]);
    const handleSocketSubscription = useRefCallback((onMessage, onComplianceFailure) => 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (message) => {
        await handleSocketSubscriptionCore(message, onMessage, onComplianceFailure);
    }, []);
    const applyProposedOrderUpdates = useRefCallback(debounce((addIds) => {
        if (typeof addIds !== "undefined")
            addIds.forEach((id) => rowUpdatesRef.current.add(id));
        if (rowUpdatesRef.current.size === 0)
            return;
        setHasRowUpdates(true);
        if (modifyingOrdersState.getActive()) {
            setTimeout(applyProposedOrderUpdates, 50);
        }
        else {
            const orders = [...rowUpdatesRef.current].reduce((res, id) => {
                const order = proposedOrdersRef.current.find((o) => o.id === id);
                if (order)
                    res.push(order);
                return res;
            }, []);
            rowUpdatesRef.current.clear();
            setHasRowUpdates(false);
            modifyOrders(orders);
        }
    }, 50), [modifyingOrdersState, fundsChangedRef]);
    const updateProposedOrdersUtil = useRefCallback((modify) => {
        const orders = [...proposedOrdersRef.current];
        let changes = false;
        const addOrder = (id) => {
            const orderIndex = orders.findIndex((o) => o.id === id);
            if (orderIndex > -1) {
                changes = true;
                rowUpdatesRef.current.add(id);
                const order = orders[orderIndex];
                orders[orderIndex] = {
                    ...order,
                    ...modify(order),
                };
            }
        };
        const completeUpdates = () => {
            if (changes)
                triggerDataHandler("updateProposedOrders", [
                    getProposedOrderTransaction(proposedOrdersRef.current, orders),
                ]);
            proposedOrdersRef.current = orders;
            applyProposedOrderUpdates();
        };
        return {
            addOrder,
            completeUpdates,
        };
    }, [proposedOrdersRef, triggerDataHandler, applyProposedOrderUpdates]);
    const updateProposedOrderData = useRefCallback((rowId, field, value) => {
        const triggerRow = getOrder([...proposedOrdersRef.current], rowId);
        if (!triggerRow)
            return;
        const { addOrder, completeUpdates } = updateProposedOrdersUtil((order) => getProposedOrderChanges(field, value, order, triggerRow));
        addOrder(rowId);
        for (const entry of selectedOrders) {
            const id = entry.data?.id;
            if (id === rowId)
                continue;
            addOrder(id);
        }
        completeUpdates();
    }, [selectedOrders]);
    const bulkUpdateProposedOrderData = useRefCallback((fields) => {
        if (selectedOrders.length > 0) {
            const { addOrder, completeUpdates } = updateProposedOrdersUtil(() => fields);
            for (const entry of selectedOrders) {
                addOrder(entry.data?.id);
            }
            completeUpdates();
        }
    }, [selectedOrders]);
    const removeOrders = useRefCallback((args) => {
        if (args === null)
            return;
        const { removals, changes, orders } = args;
        const removalChanges = [];
        for (const id of removals) {
            const orderIndex = orders.findIndex((i) => i.id === id);
            if (orderIndex !== null) {
                removalChanges.push(id);
                orders[orderIndex].quantity = 0;
            }
        }
        if (changes.length > 0 || removalChanges.length > 0) {
            triggerDataHandler("updateProposedOrders", [
                getProposedOrderTransaction(proposedOrdersRef.current, orders),
            ]);
            proposedOrdersRef.current = orders;
            applyProposedOrderUpdates([
                ...new Set([...removalChanges, ...changes]),
            ]);
        }
    }, [triggerDataHandler, applyProposedOrderUpdates]);
    const removeAndActionWrapper = useRefCallback((entries, callback) => {
        const orders = cloneDeep(proposedOrdersRef.current);
        const changes = new Set();
        const removals = new Set();
        for (const entry of entries) {
            const order = getOrder(orders, entry.orderId);
            if (order) {
                const allocations = order.proposedAllocations;
                const selectedAllocationIndex = allocations.findIndex((a) => a.id === entry.id);
                let selectedEntry = allocations.find((a) => a.id === entry.id);
                selectedEntry = selectedEntry ? { ...selectedEntry } : selectedEntry;
                if (selectedEntry) {
                    order.proposedAllocations.splice(selectedAllocationIndex, 1);
                    changes.add(entry.orderId);
                    if (Math.abs(order.quantity) - Math.abs(selectedEntry?.allocation) ===
                        0 ||
                        allocations.length === 0) {
                        removals.add(entry.orderId);
                    }
                    else {
                        callback?.(order, selectedEntry);
                    }
                }
            }
        }
        if (removals.size > 0) {
            onRemoveProposedAllocation([...removals], [...changes], orders, removeOrders);
        }
        else if (changes.size > 0) {
            triggerDataHandler("updateProposedOrders", [
                getProposedOrderTransaction(proposedOrdersRef.current, orders),
            ]);
            proposedOrdersRef.current = orders;
            applyProposedOrderUpdates([...changes]);
        }
    }, [onRemoveProposedAllocation, removeOrders]);
    const removeAndDownsize = useRefCallback((entries) => {
        removeAndActionWrapper(entries, (order, selectedAllocation) => {
            order.quantity = order.quantity - selectedAllocation.allocation;
        });
    }, [removeAndActionWrapper]);
    const removeAndRedistribute = useRefCallback((entries) => removeAndActionWrapper(entries), [removeAndActionWrapper]);
    const triggerInitProposedOrders = useRefCallback(() => {
        if (proposedOrdersRef.current.length > 0) {
            triggerDataHandler("initProposedOrders", [
                [...proposedOrdersRef.current],
            ]);
        }
    }, [triggerDataHandler]);
    const canCreateOrders = React.useMemo(() => getCanCreateOrders(user), [user]);
    const value = React.useMemo(() => ({
        fundIds: state.config.fundIds,
        settings: state.settings,
        oldSettings: state.oldSettings,
        loading: state.loading,
        error: state.error,
        hasUndo: state.historyStack.length > 0,
        hasRedo: state.futureStack.length > 0,
        name: state.name,
        config: configState,
        stateConfig: state.config,
        savePortfolio,
        saveChanges,
        reloadPortfolio,
        revertMode,
        setRevertMode,
        handleSocketSubscription,
        onLoad,
        triggerDataHandler,
        registerHandlers,
        adjustTouchedCount,
        getTouched,
        adjust,
        undoAction,
        redoAction,
        addSecurity,
        modifyOrders,
        removeSecurity,
        testCompliance,
        submitProposedOrders,
        rebalance,
        revertChanges,
        changeFundsAndSettings,
        changeSettings,
        changeGridSettings,
        regroup,
        getParentRows,
        hasProposedOrders,
        hasUnsavedChanges,
        syncReceived,
        reloaded,
        changedFields,
        setFilteredRowIds,
        setLockedRowIds,
        securitiesList: securities.current,
        hasRowUpdates,
        canCreateOrders,
        selectedOrders,
        setSelectedOrders,
        resetSelectedOrders,
        fundsChanged: fundsChangedRef.current,
        updateProposedOrderData,
        bulkUpdateProposedOrderData,
        removeAndDownsize,
        removeAndRedistribute,
        triggerInitProposedOrders,
        isPortfolioAdjusting: adjustmentState.isActive,
        isTestingCompliance: complianceTestState.isActive,
        isSubmitting: submittingOrdersState.isActive,
        isModifyingProposedOrders: modifyingOrdersState.isActive,
        tradingDeskData,
        strategyData,
        portfolioManagerData,
        traderData,
        formatProposedOrderRowGroupValue,
    }), [
        state.config.fundIds,
        state.settings,
        state.oldSettings,
        state.loading,
        state.error,
        state.historyStack.length,
        state.futureStack.length,
        state.name,
        configState,
        state.config,
        savePortfolio,
        saveChanges,
        reloadPortfolio,
        revertMode,
        setRevertMode,
        handleSocketSubscription,
        onLoad,
        triggerDataHandler,
        registerHandlers,
        adjustTouchedCount,
        getTouched,
        adjust,
        undoAction,
        redoAction,
        addSecurity,
        modifyOrders,
        removeSecurity,
        testCompliance,
        submitProposedOrders,
        rebalance,
        revertChanges,
        changeFundsAndSettings,
        changeSettings,
        changeGridSettings,
        regroup,
        getParentRows,
        hasProposedOrders,
        hasUnsavedChanges,
        syncReceived,
        reloaded,
        changedFields,
        setFilteredRowIds,
        setLockedRowIds,
        hasRowUpdates,
        canCreateOrders,
        selectedOrders,
        setSelectedOrders,
        resetSelectedOrders,
        updateProposedOrderData,
        bulkUpdateProposedOrderData,
        removeAndDownsize,
        removeAndRedistribute,
        triggerInitProposedOrders,
        adjustmentState.isActive,
        complianceTestState.isActive,
        submittingOrdersState.isActive,
        modifyingOrdersState.isActive,
        tradingDeskData,
        strategyData,
        portfolioManagerData,
        traderData,
        formatProposedOrderRowGroupValue,
    ]);
    return (React.createElement(PortfolioContext.Provider, { value: value }, children));
};
export default PortfolioProvider;
