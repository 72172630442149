import {
  FINANCIAL_SUBTYPE_OPTIONS,
  FinancialSubtype,
} from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import { sortBy } from "lodash";
import * as React from "react";

const financialSubTypeOptions = sortBy(
  FINANCIAL_SUBTYPE_OPTIONS,
  (item) => item.label
);

export type FinancialSubTypeProps = {
  value?: FinancialSubtype[] | null;
  onChange?: (selectedSubTypes: FinancialSubtype[] | null) => void;
  selectProps?: Omit<
    Partial<MultiSelectProps<number>>,
    "options" | "value" | "onChange"
  >;
};

export const ControlledFinancialSubTypeSelect: React.FC<
  Omit<FinancialSubTypeProps, "value" | "onchange"> & {
    name: string;
  }
> = ({ name, ...props }) => {
  return (
    <ControlledInputBase
      name={name}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ ref, ...renderProps }) => (
        <FinancialSubTypeSelect {...renderProps} {...props} />
      )}
    />
  );
};

const FinancialSubTypeSelect: React.FC<FinancialSubTypeProps> = ({
  onChange,
  value: values,
  selectProps = {},
}) => {
  const {
    placeholder = "All",
    label = "Financial Types",
    inputId = "financial-subtype-multi-selection-id",
    ...rest
  } = selectProps;

  return (
    <MultiSelect
      {...rest}
      value={values ? getSelectedValues(values, financialSubTypeOptions) : []}
      onChange={(options) =>
        onChange?.(
          options?.length ? options.map((option) => option.value) : null
        )
      }
      label={label}
      options={financialSubTypeOptions}
      placeholder={placeholder}
      clearable
      inputId={inputId}
    />
  );
};

export default FinancialSubTypeSelect;
