import { useNPS } from "@app-utils/useNPS";
import { useRefCallback } from "@enfusion-ui/hooks";
import { Modal } from "@enfusion-ui/web-components";
import { styled, useAuth } from "@enfusion-ui/web-core";
import * as React from "react";

const ContentContainer = styled.div`
  width: 85vw;
  max-width: 650px;
  overflow: hidden;
`;

const NPSSurveyModal: React.FC<unknown> = () => {
  const { user } = useAuth();
  const { npsOpen, closeNPS } = useNPS();
  const redirectCount = React.useRef(0);

  const handleRedirect = useRefCallback(() => {
    redirectCount.current += 1;
    if (redirectCount.current > 1) {
      closeNPS();
    }
  }, []);

  return (
    <Modal
      isOpen={npsOpen}
      title=" "
      onClose={closeNPS}
      maxWidth="650px"
      allowDismissal
      containerStyle={{ overflow: "hidden", maxWidth: 650 }}
      content={
        <ContentContainer>
          <iframe
            style={{ width: "100%", maxWidth: "650px" }}
            id="nps survey frame"
            title="nps survey frame"
            src={`https://www.surveymonkey.com/r/B6PHLVV?u=${user?.flags?.userId}&p=${user?.flags?.processingOrdId}`}
            height="350"
            frameBorder={0}
            onLoad={handleRedirect}
          />
        </ContentContainer>
      }
    />
  );
};

export default NPSSurveyModal;
