/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDashboard, WidgetType } from "@enfusion-ui/dashboards";

import WidgetContainer from "./WidgetContainer";

const Content = ({ type }: { type: WidgetType }) => {
  const { widgetDefinitions, mobile } = useDashboard();

  const ContentComponent =
    (mobile && widgetDefinitions[type as WidgetType]?.renderMobileComponent
      ? widgetDefinitions[type as WidgetType]?.renderMobileComponent
      : widgetDefinitions[type as WidgetType]?.renderComponent) ||
    (() => <></>);

  return <ContentComponent />;
};

export function renderWidgetContent(
  type: WidgetType
): React.ReactElement | null {
  return (
    <WidgetContainer>
      <Content type={type} />
    </WidgetContainer>
  );
}
