import { WidgetComponentsDefinition } from "../../../types";
import DeskSelectionEditWidget from "./DeskSelectionEditWidget";
import DeskSelectionWidget from "./DeskSelectionWidget";
import PdfDeskSelectionWidget from "./PdfDeskSelectionWidget";

export const deskSelection: WidgetComponentsDefinition = {
  renderComponent: DeskSelectionWidget,
  editComponent: DeskSelectionEditWidget,
  renderPdfComponent: PdfDeskSelectionWidget,
};
