import { useInstrument, useOEMSOrderForm } from "@enfusion-ui/core";
import {
  ExecutionOrderInstructions,
  ExecutionOrderTimeInForce,
  ExecutionOrderType,
} from "@enfusion-ui/types";
import {
  ColumnGrid,
  ControlledNumericInput,
  ControlledSelect,
  ControlledTextInput,
  FormController,
  MultiSelect,
  PanelRow,
} from "@enfusion-ui/web-components";
import { useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

type EnumOptionsType<T> = Array<{ value: T; label: string }>;

type Options = {
  orderType: EnumOptionsType<ExecutionOrderType>;
  timeInForce: EnumOptionsType<ExecutionOrderTimeInForce>;
};

export const OrderTypeOptions: Options = {
  orderType: [
    { value: "Market", label: "Market" },
    { value: "Limit", label: "Limit" },
    { value: "Stop", label: "Stop" },
    { value: "StopLimit", label: "Stop Limit" },
    { value: "MarketOnClose", label: "Market On Close" },
  ],
  timeInForce: [
    { value: "Day", label: "Day" },
    { value: "GTC", label: "GTC" },
    { value: "IOC", label: "Immediate Or Cancel" },
    { value: "FOC", label: "Fill Or Kill" },
    { value: "ATO", label: "At the Open" },
    { value: "ATC", label: "At the Close" },
  ],
};

type ExecutionOption = {
  value: ExecutionOrderInstructions;
  label: string;
};

const EXECUTION_INSTRUCTION: ExecutionOption[] = [
  { value: "AllOrNone", label: "All Or None" },
  { value: "Held", label: "Held" },
  { value: "NotHeld", label: "Not Held" },
  { value: "MidPricePeg", label: "Mid-Price Peg" },
  { value: "PrimaryPeg", label: "Primary Peg" },
  { value: "NoCross", label: "No Cross" },
  { value: "OkToCross", label: "Ok to Cross" },
];

export const OrderType: React.FC<{
  disabled?: boolean;
  required?: boolean;
  dataE2EId?: string;
}> = ({ disabled, required = true, dataE2EId }) => {
  return (
    <ControlledSelect
      data-e2e-id={dataE2EId}
      name="orderType"
      label="Type"
      options={OrderTypeOptions.orderType}
      clearable={false}
      required={required}
      mobileBasis="50%"
      disabled={disabled}
      inputId="electronic-limit-order-type-selection-id"
    />
  );
};

export const TimeInForce: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  return (
    <ControlledSelect
      name="timeInForce"
      label="Time in Force"
      options={OrderTypeOptions.timeInForce}
      clearable={false}
      required
      mobileBasis="50%"
      disabled={disabled}
      inputId="electronic-limit-tif-selection-id"
    />
  );
};
export const LimitPrice: React.FC<{
  disabled: boolean;
  tickSize: number;
  min: number | undefined;
  clearable?: boolean;
}> = ({ disabled, tickSize, min, clearable = false }) => {
  return (
    <ControlledNumericInput
      id="limit-price-id"
      name="limitPrice"
      label="Limit Price"
      disabled={disabled}
      mobileBasis="50%"
      step={tickSize}
      min={min}
      clearable={clearable}
    />
  );
};

export const StopPrice: React.FC<{
  disabled: boolean;
  tickSize: number;
  min: number | undefined;
  clearable?: boolean;
}> = ({ disabled, tickSize, min, clearable = false }) => {
  return (
    <ControlledNumericInput
      id="stop-price-id"
      name="stopPrice"
      label="Stop Price"
      disabled={disabled}
      mobileBasis="50%"
      step={tickSize}
      min={min}
      clearable={clearable}
    />
  );
};

export const CounterpartyMemo: React.FC<{ disabled?: boolean }> = ({
  disabled,
}) => {
  return (
    <ControlledTextInput
      name="counterpartyMemo"
      label="Counterparty Memo"
      textarea
      style={{ resize: "vertical", minHeight: "4.75rem" }}
      disabled={disabled}
    />
  );
};

export const ExecutionInstructions: React.FC<{ disabled?: boolean }> = ({
  disabled,
}) => {
  return (
    <FormController
      name="executionInstructions"
      render={({ ref: _ref, value, onChange, ...rest }) => {
        return (
          <MultiSelect
            {...rest}
            label="Instructions"
            value={value?.map((eachValue: string) =>
              EXECUTION_INSTRUCTION.find((e) => e.value === eachValue)
            )}
            options={EXECUTION_INSTRUCTION}
            onChange={(selectedValues) =>
              onChange(selectedValues?.map((e) => e.value) || [])
            }
            disabled={disabled}
            inputId="electronic-limit-instructions-multi-selection-id"
          />
        );
      }}
    />
  );
};

export const ElectronicLimitDetailEntry: React.FC<{
  disabled?: boolean;
  singular?: boolean;
}> = ({ disabled, singular }) => {
  const mobileContent = useIsContentMobile();
  const { setValue } = useFormContext();
  const {
    isFxOrder,
    isFutureSpreadOrder,
    isCdxOrder,
    isVarSwapOrder,
    isIrsOrder,
  } = useOEMSOrderForm();
  const orderType = useWatch({ name: "orderType" });
  const instrument = useInstrument();
  const tickSize = instrument?.tickSize;
  const limitPriceDisabled = orderType !== "Limit" && orderType !== "StopLimit";
  const stopPriceDisabled = orderType !== "Stop" && orderType !== "StopLimit";
  const timeInForceDisabled = orderType === "MarketOnClose";

  React.useEffect(() => {
    if (limitPriceDisabled) setValue("limitPrice", "0");
    if (stopPriceDisabled) setValue("stopPrice", "0");
  }, [orderType]);

  return (
    <PanelRow style={{ width: "100%" }}>
      <ColumnGrid
        numColumns={
          isFxOrder || isCdxOrder || isVarSwapOrder || isIrsOrder || singular
            ? 1
            : 2
        }
        isMobile={mobileContent}
        style={{ width: "100%" }}
      >
        {!isFxOrder && !isCdxOrder && !isVarSwapOrder && !isIrsOrder && (
          <div>
            <PanelRow>
              <OrderType disabled={disabled} />
              <LimitPrice
                disabled={disabled || limitPriceDisabled}
                tickSize={tickSize || 0.01}
                min={isFutureSpreadOrder ? undefined : 0}
              />
            </PanelRow>
            <PanelRow separateOnMobile>
              <TimeInForce disabled={disabled || timeInForceDisabled} />
              <StopPrice
                disabled={disabled || stopPriceDisabled}
                tickSize={tickSize || 0.01}
                min={isFutureSpreadOrder ? undefined : 0}
              />
            </PanelRow>
          </div>
        )}
        <div
          style={{
            width: !isFxOrder && !isCdxOrder && !isIrsOrder ? "100%" : "auto",
          }}
        >
          {!isFxOrder && !isCdxOrder && !isVarSwapOrder && !isIrsOrder && (
            <PanelRow>
              <ExecutionInstructions disabled={disabled} />
            </PanelRow>
          )}
          <PanelRow>
            <CounterpartyMemo disabled={disabled} />
          </PanelRow>
        </div>
      </ColumnGrid>
    </PanelRow>
  );
  // }

  // return (
  //   <>
  //     {!isFxOrder && !isCdxOrder && (
  //       <>
  //         <PanelRow>
  //           <OrderType disabled={disabled} />
  //           <LimitPrice
  //             disabled={disabled || limitPriceDisabled}
  //             tickSize={tickSize || 0.01}
  //             min={isFutureSpreadOrder ? undefined : 0}
  //           />
  //         </PanelRow>
  //         <PanelRow>
  //           <TimeInForce disabled={disabled || timeInForceDisabled} />
  //           <StopPrice
  //             disabled={disabled || stopPriceDisabled}
  //             tickSize={tickSize || 0.01}
  //             min={isFutureSpreadOrder ? undefined : 0}
  //           />
  //         </PanelRow>
  //         <PanelRow>
  //           <ExecutionInstructions disabled={disabled} />
  //         </PanelRow>
  //       </>
  //     )}
  //     <PanelRow>
  //       <CounterpartyMemo disabled={disabled} />
  //     </PanelRow>
  //   </>
  // );
};

export default ElectronicLimitDetailEntry;
