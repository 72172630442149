import { ControlledInstrumentSelect } from "@app-components/inputs/InstrumentSelect/ControlledInstrumentSelect";
import { useMarketData } from "@app-context/marketData/context";
import { UNKNOWN_INSTRUMENT_INFO, useOEMSOrderForm } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { Quote } from "@enfusion-ui/types";
import { formatPercentage } from "@enfusion-ui/utils";
import {
  ControlledEmpty,
  ControlledNumericInput,
  FormPanel,
} from "@enfusion-ui/web-components";
import { noop } from "lodash";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export type VarSwapValuationsProps = {};

const VarSwapValuations: React.FC<VarSwapValuationsProps> = () => {
  const [quote, setQuote] = React.useState<Quote | null>(null);
  const referenceInstrumentRef = React.useRef(UNKNOWN_INSTRUMENT_INFO);
  const unsubscribe = React.useRef(noop);

  const { settings } = useOEMSOrderForm();
  const { subscribeToMarketData } = useMarketData();
  const { setValue } = useFormContext();

  const { referenceInstrument } = useWatch({ name: ["referenceInstrument"] });

  const handleReferenceInstrumentChange = useRefCallback((value) => {
    setValue("referenceInstrumentId", value?.id);
    if (value && value.id !== UNKNOWN_INSTRUMENT_INFO.id) {
      unsubscribe.current = subscribeToMarketData(value.id, setQuote);
    } else {
      setQuote(null);
    }
  }, []);

  React.useEffect(() => {
    if (referenceInstrumentRef.current !== referenceInstrument) {
      setValue("referenceInstrumentPrice", quote?.last);
      unsubscribe.current();
    }
    referenceInstrumentRef.current = referenceInstrument;
  }, [quote]);

  return (
    <FormPanel title="Valuations" gapSize="m">
      <ControlledNumericInput
        label="Indicative Strike"
        name="indicativeStrike"
        bigControls={false}
        defaultValue={0}
        formatValue={(num) => formatPercentage(Number(num) / 100)}
      />

      <ControlledInstrumentSelect
        hideOptions
        label="Fixing Rate"
        name="fixingRateInstrument"
        searchOnlyPrimary={settings?.searchOnlyPrimary ?? false}
        onChange={(value) => setValue("fixingRate", value?.id)}
      />
      <ControlledEmpty name="fixingRate" />

      <ControlledNumericInput
        name="referenceInstrumentPrice"
        label="Reference Inst Price"
      />
      <ControlledInstrumentSelect
        hideOptions
        label="Reference Instrument"
        name="referenceInstrument"
        searchOnlyPrimary={settings?.searchOnlyPrimary ?? false}
        onChange={handleReferenceInstrumentChange}
      />
      <ControlledEmpty name="referenceInstrumentId" />
    </FormPanel>
  );
};

export default VarSwapValuations;
