import { styled } from "@enfusion-ui/web-core";
import { ICellRendererParams } from "ag-grid-enterprise";
import { isEqual } from "lodash";
import * as React from "react";

const HTMLContainer = styled.a`
  color: var(--primary);
`;

const HTMLLinkCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const value = props.valueFormatted as any;

  if (!value) {
    return <span></span>;
  }

  const href =
    value.href.trim().search(/https?:\/\//) === 0
      ? value.href
      : `//${value.href}`;

  return <HTMLContainer href={href}>{value.text}</HTMLContainer>;
};

export default React.memo(HTMLLinkCellRenderer, (prevProps, nextProps) => {
  return isEqual(prevProps?.valueFormatted, nextProps?.valueFormatted);
});
