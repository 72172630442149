import { DiffReportData, ReconReportConfig } from "@enfusion-ui/types";
import { columnTypes } from "@enfusion-ui/web-core";
import { ColDef } from "ag-grid-community";

import { GraphBaseField } from "../types";

export const SUMMARY_REPORT_IDS = {
  NAV: "NAV",
  PNL: "PNL",
  CASH: "CASH",
};

export const FILTER_FIELDS = {
  serviceAccount: "Services Account Name",
  currency: "Currency",
  breakCode: "Break Code",
  custodian: "Custodian",
};

export const GRAPH_FILTER_KEYS: Record<GraphBaseField, GraphBaseField> = {
  breakCode: "breakCode",
  custodianName: "custodianName",
  currency: "currency",
  serviceAccount: "serviceAccount",
  instrument: "instrument",
};

export const DEFAULT_DIFF_REPORT_DATA: DiffReportData = {
  targetIgnoredRows: [],
  targetOnlyRows: [],
  sourceIgnoredRows: [],
  sourceOnlyRows: [],
  matchedRows: [],
  differenceRows: [],
  statistics: {
    differences: 0,
    forcedJoined: 0,
    forcedMatched: 0,
    ignored: 0,
    knownDifferences: 0,
    matched: 0,
    missingFromSource: 0,
    missingFromTarget: 0,
    totalSource: 0,
    totalTarget: 0,
  },
};

export const breakMap: Record<string, string> = {
  differenceRows: "Difference",
  matchedRows: "Match",
  default: "Missing",
};

export const ROW_SELECTION_COL_DEF: ColDef<any, any> = {
  maxWidth: 40,
  minWidth: 40,
  resizable: true,
  field: "selection",
  checkboxSelection: true,
  headerCheckboxSelection: true,
  pinned: "left",
};

export const BREAK_TYPE_COL_DEF: ColDef<any, any> = {
  colId: "breakType",
  headerName: "Break Type",
  valueGetter: ({ data }) => data.columnValues?.breakType?.value,
};

export const DEFAULT_RECON_REPORT: ReconReportConfig = {
  reconReportId: "",
  reconciliationType: null,
  datasource: null,
  impactColumns: {
    breakImpact: null,
    cashBalance: null,
    cashBalanceCurrency: { source: null, target: null },
  },
  filterColumns: {
    currency: { source: null, target: null },
    serviceAccount: { source: null, target: null },
    instrument: { source: null, target: null },
  },
};

export const BREAK_DETAILS_MODAL_COLUMN_TYPES = {
  numberColumn: {
    filter: "agMultiColumnFilter",
    enableRowGroup: false,
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  ...columnTypes,
};

export const BREAK_DETAILS_GRID_PRIORITY_COLUMNS = [
  "breakCode",
  "overrideAction",
  "comments",
];
