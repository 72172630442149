import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { debounce, noop } from "lodash";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";

// #region types
export type PDFAsyncCallback = () => Promise<
  | {
      status: "success";
      content?: unknown;
    }
  | { status: "error"; content?: string }
>;

export type PDFAsyncCacheEntry =
  | {
      loading: boolean;
      content?: unknown;
      status?: unknown;
    }
  | {
      loading: boolean;
      content?: unknown;
      status: "success";
    }
  | {
      loading: boolean;
      content?: string;
      status: "error";
    };

type PDFAsyncState = {
  renderKey: string;
  cache: Map<string, PDFAsyncCacheEntry>;
  cbCache: Map<string, PDFAsyncCallback>;
};

type PDFAsyncAction =
  | {
      type: "subscribe";
      payload: {
        id: string;
        callback: PDFAsyncCallback;
        sideAffect?: VoidFunction;
      };
    }
  | {
      type: "set-cache";
      payload: Map<string, PDFAsyncCacheEntry>;
    };

type PDFAsyncActions = {
  subscribe: (id: string, cb: PDFAsyncCallback) => VoidFunction;
  loading: boolean | null;
};
// #endregion

// #region context
export const PDFAsyncContext = React.createContext<
  (PDFAsyncState & PDFAsyncActions) | undefined
>(undefined);
// #endregion

// #region use hook
export const usePDFAsync = (id: string, callback: PDFAsyncCallback) => {
  const context = React.useContext(PDFAsyncContext);
  const cb = useRefCallback(() => callback(), [callback]);
  const sub = context?.subscribe;

  React.useEffect(() => {
    return sub?.(id, cb);
  }, []);

  if (context === undefined) return { loading: true };
  return context.cache.get(id) || { loading: true };
};

export const usePDFAsyncEmpty = (id: string) => {
  const context = React.useContext(PDFAsyncContext);

  const cb = useRefCallback(() => {
    return Promise.resolve({
      status: "success" as const,
    });
  }, []);

  return useRefCallback(() => {
    return context?.subscribe?.(id, cb);
  }, [context?.subscribe, id, cb]);
};

const defaultContext = {
  renderKey: "init",
  cache: new Map(),
  cbCache: new Map(),
  loading: true,
  subscribe: noop,
};
export function usePDFAsyncState() {
  const context = React.useContext(PDFAsyncContext);
  if (context === undefined)
    return defaultContext as PDFAsyncState & PDFAsyncActions;
  return context;
}
// #endregion

// #region State Logic
function pdfAsyncProviderReducer(state: PDFAsyncState, action: PDFAsyncAction) {
  switch (action.type) {
    case "set-cache": {
      return { ...state, cache: action.payload, renderKey: uuidv4() };
    }
    case "subscribe": {
      const { id, callback, sideAffect } = action.payload;
      const cbCache = new Map(state.cbCache);
      const cache = new Map(state.cache);

      cbCache.set(id, callback);
      if (!state.cbCache.has(id)) {
        requestAnimationFrame(() => {
          sideAffect?.();
        });
      }
      if (!cache.get(id)) cache.set(id, { loading: true });
      return {
        ...state,
        cache,
        cbCache,
      };
    }

    default:
      return state;
  }
}

export const PDFAsyncProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const isMounted = useMounted();
  const [state, dispatchBase] = React.useReducer<
    React.Reducer<PDFAsyncState, PDFAsyncAction>
  >(pdfAsyncProviderReducer, {
    renderKey: "init",
    cache: new Map(),
    cbCache: new Map(),
  });
  const dispatch: React.Dispatch<PDFAsyncAction> = useRefCallback(
    (action) => {
      if (isMounted()) dispatchBase(action);
    },
    [dispatchBase, isMounted]
  );

  const executeCore = useRefCallback(() => {
    Promise.all<Array<Promise<[string, PDFAsyncCacheEntry]>>>(
      [...state.cbCache.entries()].map(([id, cb]) => {
        return new Promise((resolve) => {
          const cachedVal = state.cache.get(id);
          if (!!cachedVal && !cachedVal.loading) {
            resolve([id, cachedVal]);
          } else {
            cb()
              .then((res) => {
                resolve([id, { ...res, loading: false }]);
              })
              .catch((err) => {
                resolve([
                  id,
                  { loading: false, content: err.message, status: "error" },
                ]);
              });
          }
        });
      })
    ).then((res) => {
      dispatch({ type: "set-cache", payload: new Map(res) });
    });
  }, [state]);

  const execute = useRefCallback(
    debounce(() => {
      executeCore();
    }, 1500),
    []
  );

  const subscribe = useRefCallback((id: string, callback: PDFAsyncCallback) => {
    dispatch({
      type: "subscribe",
      payload: { id, callback, sideAffect: execute },
    });
  }, []);

  return (
    <PDFAsyncContext.Provider
      value={
        {
          ...state,
          loading:
            state.cache.size === 0
              ? null
              : [...state.cache.values()].some((i) => i.loading),
          subscribe,
        } as PDFAsyncState & PDFAsyncActions
      }
    >
      {children}
    </PDFAsyncContext.Provider>
  );
};
// #endregion
