import * as React from "react";
import ContentLoader, { IContentLoaderProps } from "react-content-loader";

export const TabButtonLoader = (props: IContentLoaderProps) => {
  return (
    <ContentLoader
      speed={2}
      width={262}
      height={23}
      viewBox="0 0 262 23"
      backgroundColor="var(--background-color-1)"
      foregroundColor="var(--background-color-0)"
      animate
      {...props}
    >
      <rect x="0" y="0" rx={5} ry={5} height="23" width="110.88" />
      <rect x="118.88" y="0" rx={5} ry={5} height="23" width="144.14" />
    </ContentLoader>
  );
};

export const ActionBarLoader = (props: IContentLoaderProps) => {
  return (
    <ContentLoader
      speed={2}
      width={220}
      height={22}
      viewBox="0 0 220 22"
      backgroundColor="var(--background-color-1)"
      foregroundColor="var(--background-color-0)"
      animate
      {...props}
    >
      <rect x="0" y="0" rx={5} ry={5} height="22" width="22" />
      <rect x="24" y="0" rx={5} ry={5} height="22" width="22" />
      <rect x="48" y="0" rx={5} ry={5} height="22" width="22" />
      <rect x="86" y="0" rx={5} ry={5} height="22" width="2" />
      <rect x="100" y="0" rx={5} ry={5} height="22" width="22" />
      <rect x="124" y="0" rx={5} ry={5} height="22" width="22" />
      <rect x="148" y="0" rx={5} ry={5} height="22" width="22" />
      <rect x="172" y="0" rx={5} ry={5} height="22" width="22" />
      <rect x="196" y="0" rx={5} ry={5} height="22" width="22" />
    </ContentLoader>
  );
};

export const ActionButtonLoader = (props: IContentLoaderProps) => {
  return (
    <ContentLoader
      speed={2}
      width={462}
      height={30}
      viewBox="0 0 462 30"
      backgroundColor="var(--background-color-1)"
      foregroundColor="var(--background-color-0)"
      animate
      {...props}
    >
      <rect x="0" y="0" rx={5} ry={5} height="30" width="150" />
      <rect x="154" y="0" rx={5} ry={5} height="30" width="150" />
      <rect x="308" y="0" rx={5} ry={5} height="30" width="150" />
    </ContentLoader>
  );
};
