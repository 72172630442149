import {
  OrderFormValues,
  useInstrument,
  useOEMSOrderForm,
} from "@enfusion-ui/core";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import {
  Broker,
  MultiSelectOptionsType,
  SelectOptionsType,
} from "@enfusion-ui/types";
import {
  ControlledTextInput,
  FormController,
  FormElement as DefaultFormElement,
  IconButton,
  InputLabel,
  MultiSelect,
} from "@enfusion-ui/web-components";
import { REST_API, styled } from "@enfusion-ui/web-core";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import LocatesModal from "./LocatesModal";

const FormElement = styled(DefaultFormElement)`
  padding-left: 0;
`;

const SearchIconContainer = styled(IconButton)`
  height: 38px;
  flex: 0 0 30px;
  margin-top: var(--spacing-xxl);
`;

const InputsWrapper = styled.div`
  display: flex;
  gap: var(--spacing-xl);
  flex-wrap: wrap;
`;

const ElementWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const Wrapper = styled.div`
  flex-direction: column;
`;

function getOptions(
  options: Array<SelectOptionsType<string>>,
  values: Array<string> | null
) {
  const selectedOptions: SelectOptionsType<string>[] = [];
  values?.forEach((id) => {
    const match = options?.find((opt) => opt.value === id);
    selectedOptions.push(match ? match : { label: id, value: id });
  });

  return selectedOptions;
}

export const BorrowBrokerPanel: React.FC<{
  disabled?: boolean;
}> = ({ disabled }) => {
  const [showBorrowModal, setShowBorrowModal] = React.useState(false);

  const { setValue } = useFormContext<OrderFormValues>();

  const instrument = useInstrument();
  const isOption = instrument?.option;
  const { orderSide, locateBrokerIds } = useWatch({
    name: ["orderSide", "locateBrokerIds"],
  });
  const defaultBorrowBrokerIdRef = React.useRef<boolean>(false);
  const isSellShort = orderSide === "SellShort";
  const { newOrder } = useOEMSOrderForm();

  const [options, setOptions] = React.useState<SelectOptionsType<string>[]>([]);
  const [loading, setLoading] = React.useState(true);
  const isMounted = useMounted();

  const getSelectOptions = useRefCallback(async () => {
    setLoading(true);
    try {
      const res = await REST_API.OEMS.GET_LOCATE_BROKERS_CUSTOM.FETCH();
      defaultBorrowBrokerIdRef.current = res.defaultBorrowBrokerId;
      setOptions(
        res.customBorrowBrokerIds.map((i) => ({ label: i, value: i }))
      );
    } catch (err) {}
  }, []);

  React.useEffect(() => {
    if (isMounted()) getSelectOptions();
  }, []);

  React.useEffect(() => {
    if (!isSellShort) {
      setValue("locateBrokerIds", undefined);
      setValue("locateId", undefined);
    } else if (
      defaultBorrowBrokerIdRef.current &&
      options?.length > 0 &&
      (!locateBrokerIds || locateBrokerIds?.length === 0) &&
      newOrder
    ) {
      setValue("locateBrokerIds", [options[0].value]);
    }
  }, [isSellShort]);

  const handleLocatesSelect = useRefCallback((selectedBrokers: Broker[]) => {
    const options = selectedBrokers.reduce(
      (res, broker) => {
        if (broker.locateId) res.locateIds.push(broker.locateId);
        if (broker.brokerCode) res.locateBrokerIds.push(broker.brokerCode);
        return res;
      },
      { locateIds: [], locateBrokerIds: [] } as {
        locateIds: string[];
        locateBrokerIds: string[];
      }
    );
    setValue("locateBrokerIds", options.locateBrokerIds);
    setValue("locateId", options.locateIds.join(","));

    setShowBorrowModal(false);
  }, []);

  const openBorrowModal = useRefCallback(
    () => setShowBorrowModal(true),
    [setShowBorrowModal]
  );

  return (
    <Wrapper>
      <InputsWrapper>
        <ElementWrapper>
          <FormElement>
            <ControlledTextInput
              label="Borrow Locate Id(s)"
              name="locateId"
              disabled={disabled || !isSellShort || isOption}
            />
          </FormElement>
        </ElementWrapper>
        <ElementWrapper>
          <FormController
            name="locateBrokerIds"
            render={({ ref: _ref, value, onChange, ...rest }) => (
              <MultiSelect
                {...rest}
                value={getOptions(options, value)}
                onChange={(selectedOptions) => {
                  onChange(
                    selectedOptions?.map((i: MultiSelectOptionsType) => i.value)
                  );
                }}
                label="Borrow Broker Id(s)"
                options={options}
                emptyText="No prime brokers found"
                disabled={disabled || !isSellShort || isOption}
                loading={loading}
                inputId="borrow-broker-multi-selection-id"
                creatable
              />
            )}
          />
          <InputLabel>&nbsp;</InputLabel>
          <SearchIconContainer
            icon={faSearch}
            onClick={openBorrowModal}
            disabled={disabled || !isSellShort || isOption}
          />
        </ElementWrapper>
      </InputsWrapper>
      <LocatesModal
        open={showBorrowModal}
        onClose={() => setShowBorrowModal(false)}
        onLocatesSelect={handleLocatesSelect}
      />
    </Wrapper>
  );
};
