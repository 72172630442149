import { ControlledInstrumentSelect } from "@app-components/inputs/InstrumentSelect/ControlledInstrumentSelect";
import CurrencySelect from "@app-views/reports/reportSettings/generalLedgerAccounting/glDistributionLine/CurrencySelect";
import {
  callPutsInputOptions,
  exerciseTypeOptions,
  settlementTypeOptions,
  TradeTicketFormTypes,
  transactionsSelectOptions,
  useTradeTicket,
} from "@enfusion-ui/trades";
import {
  ControlledDatePicker,
  ControlledNumericInput,
  ControlledSelect,
  PanelRow,
} from "@enfusion-ui/web-components";
import * as React from "react";

export const GeneralTerms: React.FC<unknown> = () => {
  const { formDataType } = useTradeTicket();
  const renderVolatilityProxyTwo =
    formDataType === TradeTicketFormTypes.OTC_SPREAD_OPTIONS ||
    formDataType === TradeTicketFormTypes.OTC_OUTPERFORMANCE_OPTION;

  return (
    <>
      <PanelRow>
        <ControlledSelect
          name="transaction"
          label="Transaction"
          options={transactionsSelectOptions}
          required
        />
        <ControlledSelect
          name="callPut"
          label="Call/Put"
          options={callPutsInputOptions}
          required
        />
      </PanelRow>
      <PanelRow>
        <ControlledNumericInput name="quantity" label="Quantity" required />
        <ControlledNumericInput
          name="contractSize"
          label="Contract Size"
          required
          fractionDigits={2}
        />
      </PanelRow>
      <PanelRow>
        <ControlledNumericInput
          name="strike"
          label="Strike"
          required
          fractionDigits={2}
        />
        <ControlledDatePicker name="expiry" label="Expiry" required />
      </PanelRow>

      <PanelRow>
        <ControlledSelect
          name="exerciseType"
          label="Exercise Type"
          options={exerciseTypeOptions}
          required
        />
        <ControlledNumericInput
          name="tradeToBook"
          label="FX Rate (Trade to Book)"
        />
      </PanelRow>

      <PanelRow>
        <ControlledNumericInput
          name="pricePerContract"
          label="Price(Per Contract)"
          required
        />
        <CurrencySelect
          name="premiumCurrency"
          label="Premium Currency"
          required
        />
      </PanelRow>
      {formDataType === TradeTicketFormTypes.OTC_OUTPERFORMANCE_OPTION && (
        <PanelRow>
          <ControlledNumericInput
            name="underlyingInitialPrice"
            label="Underlying 1 Initial Price"
            fractionDigits={[2, 4, 2]}
          />
          <ControlledNumericInput
            name="underlyingInitialPrice"
            label="Underlying 2 Initial Price"
            fractionDigits={[2, 4, 2]}
          />
        </PanelRow>
      )}
      <PanelRow>
        <ControlledInstrumentSelect
          name="volatilityProxy"
          label={`Volatility Proxy${renderVolatilityProxyTwo ? " 1" : ""}`}
          hideAdvancedSearchButtonText
        />
        {renderVolatilityProxyTwo && (
          <ControlledInstrumentSelect
            name="volatilityProxy2"
            label="Volatility Proxy 2"
            hideAdvancedSearchButtonText
          />
        )}
        {!renderVolatilityProxyTwo && (
          <ControlledSelect
            name="settlementType"
            label="Settlement Type"
            options={settlementTypeOptions}
          />
        )}
      </PanelRow>
    </>
  );
};
