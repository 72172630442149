/* eslint-disable @typescript-eslint/no-shadow */
import { TutorialType } from "@app-components/Tour/utils";
import { useDashboardsExplorer } from "@app-context/dashboards/DashboardsExplorerProvider";
import { useExplorerContextMenu } from "@app-context/explorer/explorerContextMenu/useExplorerContextMenu";
import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { DashboardRoot, NodeData } from "@enfusion-ui/types";
import { getFileName } from "@enfusion-ui/utils";
import {
  AccordionContext,
  CenterContent,
  ErrorAlert,
  FileExplorer,
  MenuItemOption,
  MenuOptions,
  SidebarContext,
  Spinner,
  useNavBarState,
} from "@enfusion-ui/web-components";
import {
  REST_API,
  styled,
  useAuth,
  useFolderActionContext,
  useTabs,
} from "@enfusion-ui/web-core";
import { faChartBar } from "@fortawesome/pro-regular-svg-icons";
import { faFolderPlus } from "@fortawesome/pro-solid-svg-icons";
import { debounce } from "lodash";
import * as React from "react";

import { getNewDashboardTabContent } from "./core/utils";

const DashboardsFileExplorer = styled(FileExplorer)`
  flex: 1;
  overflow-x: hidden;
`;

const getFileIcon = (_node: NodeData) => {
  return faChartBar;
};

const DashboardsExplorer: React.FC<{
  root: DashboardRoot;
  open: boolean;
  disableEdit?: boolean;
  tutorial?: TutorialType | null;
  extraNodes?: NodeData[];
  onExtraNodeClick?: (node: NodeData) => void;
}> = React.memo(function DashboardsExplorer({
  root,
  open,
  disableEdit,
  tutorial,
  extraNodes = [],
  onExtraNodeClick,
}) {
  const { hasPerm, isAdmin, isInternalPo, isUserType } = useAuth();
  const { openTab } = useTabs();
  const { closeNavBarTabOnMobile } = useNavBarState();
  const { setMenu } = React.useContext(AccordionContext);
  const { refreshFlag } = React.useContext(SidebarContext);
  // const [searchTerm, setSearchTerm] = React.useState("");
  const [nodes, setNodes] = React.useState<null | Array<NodeData>>(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<null | Error>(null);

  const { retrieveNodes } = useFolderActionContext();

  const isMounted = useMounted();

  const globalAdminEnabled = hasPerm("DashboardEditor");
  const adminEnabled = globalAdminEnabled || isAdmin() || isInternalPo();
  const canEdit = React.useMemo(() => {
    if (disableEdit) return false;
    if (isUserType("Express") || root.startsWith("global/")) return false;
    if (root === "global" && !globalAdminEnabled) return false;
    if (adminEnabled) return true;
    if (root === "user" || root === "shared") return true;
    return false;
  }, [
    isInternalPo,
    isAdmin,
    globalAdminEnabled,
    adminEnabled,
    root,
    isUserType,
    disableEdit,
  ]);

  const { openCreateDashboardModal, openCreateFolderModal, deleteDashboard } =
    useDashboardsExplorer();

  const getRootFiles = useRefCallback(
    debounce(() => {
      if (open) {
        REST_API.DASHBOARD.GET_ROOT_NODE(root)
          .then((rootNodes: NodeData[]) => {
            if (isMounted()) {
              setNodes([
                ...rootNodes,
                ...extraNodes.map((i) => ({ ...i, extraNode: true })),
              ]);
            }
          })
          .catch((err) => {
            if (isMounted()) {
              setError(err);
            }
          })
          .finally(() => {
            if (isMounted()) {
              setLoading(false);
            }
          });
      }
    }, 500),
    [root, open, extraNodes]
  );

  const reloadEvent = useRefCallback(
    (eventRoot: DashboardRoot, event: ExplorerEventsAction) => {
      if (eventRoot === root && event === ExplorerEventsAction.Refetch) {
        getRootFiles();
      }
    },
    [root, getRootFiles]
  );
  useExplorerEvents("Dashboards", reloadEvent);

  React.useEffect(() => {
    if (open && nodes === null) {
      getRootFiles();
    }
  }, [root, nodes, open, refreshFlag]);

  React.useEffect(() => {
    setMenu(getMenuConfig());
  }, [canEdit]);

  //#region Handle accordion action interactions
  // TODO handle dashboards search
  // const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  // AppLogging.event({
  //   event: AppEvent.SearchFiles,
  //   category: AppEventCategories.Dashboards,
  // });
  //   setSearchTerm(e.target.value);
  //   setForceSearchIcon(e.target.value.trim().length > 0);
  // };

  React.useEffect(() => {
    // setSearch(
    //   <SearchPanel>
    //     <SearchForm>
    //       <TextInput label="Search" name="name" onChange={handleSearchChange} />
    //     </SearchForm>
    //   </SearchPanel>
    // );
  }, []);
  //#endregion

  //#region FileExplorer handlers
  const filteredNodes = React.useMemo(() => {
    if (nodes === null) return [];
    return nodes;
  }, [nodes]);

  const handleClick = React.useCallback(
    (node: NodeData) => {
      if (node.extraNode && onExtraNodeClick) {
        onExtraNodeClick(node);
      } else if (node.file) {
        openTab(
          getNewDashboardTabContent({
            path: node.path,
            root: node.path.split("/")[0] as DashboardRoot,
            name: getFileName(node),
          })
        );
        closeNavBarTabOnMobile();
      }
    },
    [closeNavBarTabOnMobile, onExtraNodeClick]
  );
  //#endregion

  //#region Handle context menu
  const getMenuConfig = useRefCallback(() => {
    let menuOptions: MenuOptions = [];
    if (canEdit) {
      menuOptions = [
        ...menuOptions,
        {
          key: "createDashboard",
          title: "Add Dashboard",
          icon: faChartBar,
          onClick: () => openCreateDashboardModal(root),
        } as MenuItemOption,
        {
          key: "createFolder",
          title: "Create Folder",
          icon: faFolderPlus,
          onClick: () => openCreateFolderModal(root, ""),
        } as MenuItemOption,
      ];
    }
    return { menu: menuOptions, selectedKeys: [] };
  }, [canEdit]);
  //#endregion

  const ExplorerContextMenu = useExplorerContextMenu({
    root,
    section: "Dashboards",
    globalAdminEnabled,
    editEnabled: canEdit,
    openCreate: openCreateDashboardModal,
    openCreateFolder: openCreateFolderModal,
    deleteFile: deleteDashboard,
  });

  if (error) {
    return (
      <CenterContent fillHeight={false}>
        <ErrorAlert error={error} />
      </CenterContent>
    );
  }

  if (loading || nodes === null) {
    return (
      <CenterContent fillHeight={false}>
        <Spinner />
      </CenterContent>
    );
  }

  return (
    <>
      <DashboardsFileExplorer
        nodes={filteredNodes}
        onEntryContext={ExplorerContextMenu.handleEntryContext}
        onEntryClick={handleClick}
        getFileIcon={getFileIcon}
        getFileName={getFileName}
        retrieveNodes={retrieveNodes}
        tutorial={tutorial}
        multiSelect={false}
      />
      {ExplorerContextMenu.content}
    </>
  );
});

export default DashboardsExplorer;
