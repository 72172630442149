import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { usePortfolio } from "@enfusion-ui/portfolios";
import { REST_API } from "@enfusion-ui/web-core";
import { ICellRendererParams } from "ag-grid-community";
import * as React from "react";

import ColumnSelect from "../../../../components/inputs/ColumnSelect";

const TraderCellRenderer: React.FC<
  ICellRendererParams & {
    allowGroup?: boolean;
  }
> = React.memo(({ allowGroup, node, valueFormatted, value, data }) => {
  const { tradingDeskData } = usePortfolio();

  const hasDesk = React.useMemo(() => {
    if (typeof data?.tradingDesk === "undefined") return false;
    return tradingDeskData.options.some((i) => i.value === data.tradingDesk);
  }, [tradingDeskData.options, data]);

  const fetchTradersOptions = React.useCallback(
    (ac?: AbortController) =>
      REST_API.SECURITY.GET_TRADERS.FETCH(data?.tradingDesk, ac),
    [data?.tradingDesk]
  );

  const { options, loading } = useRestAbortableOptions(
    hasDesk ? fetchTradersOptions : null,
    (td) => ({ value: td.id, label: td.fullName })
  );

  if (!allowGroup && node.group) return <>{valueFormatted ?? null}</>;

  return (
    <div style={{ pointerEvents: "none" }}>
      <ColumnSelect
        name="trader"
        options={options}
        isLoading={loading}
        clearable={false}
        mobileBasis="50%"
        minWidth={50}
        value={hasDesk ? value : undefined}
      />
    </div>
  );
});

export default TraderCellRenderer;
