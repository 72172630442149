import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import {
  CoreBroadcastChannelInterface,
  useRefCallback,
} from "@enfusion-ui/hooks";
import { SEARCH_EVENTS } from "@enfusion-ui/web-core";
import * as React from "react";

export type GeneralSearchDirection = "up" | "down";
export type GeneralSearchFilterMode = "highlight" | "filter";

type GeneralSearchEvent = {
  key: string;
  event:
    | {
        action: "text-change";
        payload: {
          text: string;
          matchCase: boolean;
          filterMode: GeneralSearchFilterMode;
        };
      }
    | {
        action: "navigate-match";
        payload: GeneralSearchDirection;
      }
    | {
        action: "next-match";
        payload?: undefined;
      }
    | {
        action: "result-count";
        payload: number;
      }
    | {
        action: "result-index";
        payload: number;
      };
};

type GeneralSearchEventHandlers = {
  onTextChange?: (
    text: string,
    matchCase: boolean,
    mode: GeneralSearchFilterMode,
    changeResultCount: (count: number) => void,
    changeResultIndex: (count: number) => void
  ) => void;
  onNavigateMatch?: (
    dir: GeneralSearchDirection,
    changeResultIndex: (count: number) => void
  ) => void;
  onNextMatch?: (changeResultIndex: (count: number) => void) => void;
  onResultCount?: (count: number) => void;
  onResultIndex?: (count: number) => void;
};

type GSEChannel = CoreBroadcastChannelInterface<GeneralSearchEvent>;

const sendActions = (channel: GSEChannel, key: string) => {
  return {
    changeText: (
      text: string,
      matchCase: boolean,
      filterMode: GeneralSearchFilterMode
    ) => {
      channel.broadcastMessage({
        key,
        event: {
          action: "text-change",
          payload: { text, matchCase, filterMode },
        },
      });
    },
    navigateMatch: (payload: GeneralSearchDirection) => {
      channel.broadcastMessage({
        key,
        event: {
          action: "navigate-match",
          payload: payload,
        },
      });
    },
    nextMatch: () => {
      channel.broadcastMessage({
        key,
        event: {
          action: "next-match",
          payload: undefined,
        },
      });
    },
    changeResultCount: (count: number) => {
      channel.broadcastMessage({
        key,
        event: {
          action: "result-count",
          payload: count,
        },
      });
    },
    changeResultIndex: (index: number) => {
      channel.broadcastMessage({
        key,
        event: {
          action: "result-index",
          payload: index,
        },
      });
    },
  };
};

export const useGeneralSearchEvents = (
  key: string,
  {
    onTextChange,
    onNavigateMatch,
    onNextMatch,
    onResultCount,
    onResultIndex,
  }: GeneralSearchEventHandlers
) => {
  const handleEvents = useRefCallback(
    ({ key: eventKey, event }: GeneralSearchEvent, channel: GSEChannel) => {
      if (eventKey === key) {
        const actions = sendActions(channel, key);
        switch (event.action) {
          case "text-change":
            const { text, matchCase, filterMode } = event.payload;
            onTextChange?.(
              text,
              matchCase,
              filterMode,
              actions.changeResultCount,
              actions.changeResultIndex
            );
            break;
          case "navigate-match":
            onNavigateMatch?.(event.payload, actions.changeResultIndex);
            break;
          case "next-match":
            onNextMatch?.(actions.changeResultIndex);
            break;
          case "result-count":
            onResultCount?.(event.payload);
            break;
          case "result-index":
            onResultIndex?.(event.payload);
            break;
          default:
            break;
        }
      }
    },
    [key]
  );

  const channel = useBroadcastChannel<GeneralSearchEvent>(
    SEARCH_EVENTS,
    handleEvents
  );

  const changeText = useRefCallback(
    (text: string, matchCase: boolean, filterMode: GeneralSearchFilterMode) => {
      sendActions(channel, key).changeText(text, matchCase, filterMode);
    },
    [channel, key]
  );

  const navigateMatch = useRefCallback(
    (payload: GeneralSearchDirection) => {
      sendActions(channel, key).navigateMatch(payload);
    },
    [channel]
  );

  const nextMatch = useRefCallback(() => {
    sendActions(channel, key).nextMatch();
  }, [channel, key]);

  const changeResultCount = useRefCallback(
    (count: number) => {
      sendActions(channel, key).changeResultCount(count);
    },
    [channel, key]
  );

  const changeResultIndex = useRefCallback(
    (index: number) => {
      sendActions(channel, key).changeResultIndex(index);
    },
    [channel, key]
  );

  return React.useMemo(
    () => ({
      changeText,
      navigateMatch,
      nextMatch,
      changeResultCount,
      changeResultIndex,
    }),
    [changeText, navigateMatch, nextMatch, changeResultCount, changeResultIndex]
  );
};
