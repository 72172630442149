import {
  useLocalStorage,
  useMounted,
  usePrevious,
  useRefCallback,
} from "@enfusion-ui/hooks";
import { NodeData } from "@enfusion-ui/types";
import { useConfirmationModal } from "@enfusion-ui/web-components";
import {
  useAuth,
  useIsTabletDevice,
  useServicesFiles,
  useTabs,
} from "@enfusion-ui/web-core";
import { TourProps, TourProvider, useTour } from "@reactour/tour";
import { contentTabs, TABS } from "app/AuthenticatedApp";
import { isEqual, noop } from "lodash";
import * as React from "react";

import { getTourConfig, TourMeta, TourStepType } from "./config";
import { TourContext } from "./context";
import { StepSelector, TourConfig, TutorialType } from "./utils";

type TourConfigState = Omit<TourConfig, "stepsRequiringTargetClick"> & {
  requiredClickSelectors?: StepSelector[];
};

type AppTutorialProps = React.PropsWithChildren<{
  onClose?: (tutorialType: TutorialType) => void;
  tab: string;
}>;

export const CustomBadge = () => {
  const { steps, currentStep } = useTour();
  return <>{`${currentStep + 1}/${steps.length}`}</>;
};

const TourApi = React.forwardRef<TourProps, { onChange: VoidFunction }>(
  ({ onChange }, ref) => {
    const args = useTour();
    const prev = usePrevious(args);
    const changed = isEqual(prev, args);
    React.useImperativeHandle(ref, () => args, [args, changed]);
    React.useEffect(() => onChange(), [changed]);
    return null;
  }
);

export const AppTutorialProvider: React.FC<AppTutorialProps> = ({
  children,
  tab,
}: AppTutorialProps) => {
  const tourApiRef = React.useRef<TourProps | null>(null);
  const { user, isEnabled, isUserType } = useAuth();
  const isTabletDevice = useIsTabletDevice();

  const isExpressUser = isUserType("Express");
  const { openTab } = useTabs();
  const openMarketingTab = () => {
    openTab(contentTabs.marketing);
  };
  const [tourReady, setTourReady] = React.useState<boolean>(false);
  const [tutorialType, setTutorialType] = React.useState<TutorialType | null>(
    null
  );
  const [meta, setMeta] = React.useState<TourMeta>({});
  const [changeCount, setChangeCount] = React.useState(0);

  const [fileNodes, setFileNodes] = React.useState<NodeData[]>([]);
  const { loading: servicesLoading } = useServicesFiles();

  // #region local storage
  const [firstTimeServices, setFirstTimeServices] = useLocalStorage(
    `e-first-services-load-${user?.username}`,
    true
  );
  const [firstTimeReports, setFirstTimeReports] = useLocalStorage(
    `e-first-reports-load-${user?.username}`,
    true
  );

  const [firstTimePortfolios, setFirstTimePortfolios] = useLocalStorage(
    `e-first-portfolios-load-${user?.username}`,
    true
  );
  // #endregion

  const handleTourModalConfirm = useRefCallback(() => {
    tourApiRef.current?.setCurrentStep?.(0);
    tourApiRef.current?.setIsOpen?.(true);
  }, []);

  const handleTutorialClose = useRefCallback(() => {
    if (
      tutorialType === TutorialType.ServicesMarketing ||
      tutorialType === TutorialType.ServicesClient
    ) {
      setFirstTimeServices(false);
    } else if (tutorialType === TutorialType.Reports) {
      setFirstTimeReports(false);
    } else if (tutorialType === TutorialType.Portfolio) {
      setFirstTimePortfolios(false);
    }
    tourApiRef.current?.setIsOpen(false);
    setTutorialType(null);
    confirmationModal.closeModal();
  }, [tutorialType]);

  // confirmation modal
  const confirmationModal = useConfirmationModal<
    Required<TourConfigState>["welcomeModalContent"]
  >({
    renderContent: (res) => res?.body ?? "",
    renderTitle: (res) => res?.title ?? "",
    defaultOpen: false,
    onCancel: handleTutorialClose,
    cancelButtonText: "No thank you",
    onSubmit: handleTourModalConfirm,
    submitButtonText: "Start tour",
    submitActionTheme: "primary",
    allowDismissal: true,
  });

  React.useEffect(() => {
    if (!tourApiRef.current?.isOpen) {
      let tutorialType = null;

      if (!isExpressUser && !isTabletDevice) {
        if (
          tab === TABS.services &&
          process.env.REACT_APP_ENABLE_SERVICES_MARKETING &&
          firstTimeServices
        ) {
          tutorialType = isEnabled("Services")
            ? TutorialType.ServicesClient
            : TutorialType.ServicesMarketing;
        } else if (
          tab === TABS.reports &&
          process.env.REACT_APP_ENABLE_REPORTS_TUTORIAL &&
          firstTimeReports
        ) {
          tutorialType = TutorialType.Reports;
        } else if (
          tab === TABS.portfolios &&
          process.env.REACT_APP_ENABLE_PORTFOLIOS_TUTORIAL &&
          firstTimePortfolios
        ) {
          tutorialType = TutorialType.Portfolio;
        }
      }

      if (
        tutorialType === TutorialType.ServicesMarketing ||
        (tutorialType === TutorialType.ServicesClient && !servicesLoading) ||
        tutorialType === TutorialType.Reports ||
        tutorialType === TutorialType.Portfolio
      ) {
        const config = getTourConfig(tutorialType, openMarketingTab, user);
        if (tourReady && config) {
          confirmationModal.openModal(config.welcomeModalContent);
          tourApiRef.current?.setSteps?.(config.steps);
        }
      }
      setTutorialType(tutorialType);
    }
  }, [tab, isTabletDevice, isExpressUser, tourReady]);

  const setMetaChecked = useRefCallback((newMeta: Partial<TourMeta>) => {
    if (tourApiRef.current?.isOpen) {
      setMeta(newMeta);
    }
  }, []);

  const setTourReadyMethod = useRefCallback(
    () => setTourReady(true),
    [setTourReady]
  );

  const step = React.useMemo(() => {
    if (!tourApiRef.current) return null;
    return (
      (tourApiRef.current.steps[
        tourApiRef.current.currentStep
      ] as TourStepType) ?? null
    );
  }, [changeCount]);

  const value = React.useMemo(
    () => ({
      tutorialType,
      fileNodes,
      setMeta: setMetaChecked,
      setFileNodes,
      setTourReady: setTourReadyMethod,
      meta,
      step,
    }),
    [
      tutorialType,
      fileNodes,
      setFileNodes,
      setTourReadyMethod,
      setMetaChecked,
      meta,
      step,
    ]
  );

  const isMounted = useMounted();

  const handleChange = useRefCallback(() => {
    if (isMounted()) setChangeCount((i) => i + 1);
  }, []);

  return (
    <TourContext.Provider value={value}>
      <TourProvider
        steps={[]}
        disableInteraction
        onClickMask={noop}
        position="right"
        showNavigation={false}
        badgeContent={CustomBadge}
        onClickClose={handleTutorialClose}
        disableKeyboardNavigation
      >
        <TourApi ref={tourApiRef} onChange={handleChange} />
        {children}
        {confirmationModal.content}
      </TourProvider>
    </TourContext.Provider>
  );
};
