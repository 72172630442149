import { COMPLIANCE_STATE_SEVERITY, numericColumns } from "./constants";
export function flattenPortfolioTree(rootNode, parentPath = [], leafNodes = [], parentNodes = []) {
    let newLeafNodes = [...leafNodes];
    let newParentNodes = [...parentNodes];
    if (typeof rootNode.children !== "undefined") {
        newParentNodes.push({
            rowId: rootNode.rowId,
            path: [...parentPath],
            columnValues: { ...rootNode.columnValues },
        });
        const path = [...parentPath, rootNode.rowId];
        for (const subNode of rootNode.children) {
            const [nextLeafNodes, nextParentNodes] = flattenPortfolioTree(subNode, path, newLeafNodes, newParentNodes);
            newLeafNodes = [...nextLeafNodes];
            newParentNodes = [...nextParentNodes];
        }
    }
    else {
        newLeafNodes.push({
            rowId: rootNode.rowId,
            path: [...parentPath],
            columnValues: { ...rootNode.columnValues },
        });
    }
    return [newLeafNodes, newParentNodes];
}
export function spreadPortfolioColumnValues(rows) {
    return rows.map((i) => ({
        ...i.columnValues,
        ...i,
    }));
}
export function getProposedOrderTransaction(oldOrders, newOrders) {
    if (oldOrders.length === 0)
        return { add: newOrders };
    if (newOrders.length === 0)
        return { remove: oldOrders };
    let add = [];
    let remove = [];
    let update = [];
    for (const order of newOrders) {
        if (!oldOrders.some((i) => i.id === order.id)) {
            add.push(order);
        }
        else if (order.quantity === 0 ||
            order.allocation === 0) {
            remove.push(order);
        }
        else {
            update.push(order);
        }
    }
    for (const order of oldOrders) {
        if (!newOrders.some((i) => i.id === order.id)) {
            remove.push(order);
        }
    }
    return { add, update, remove };
}
export function getOrder(orders, orderId) {
    const idx = orders.findIndex((i) => i.id === orderId);
    return orders[idx];
}
export function getProposedOrderChanges(field, value, order, triggerRow) {
    const validatePrice = (v) => !isNaN(parseInt(v)) && parseInt(v) > 0;
    const hasLimit = validatePrice(order.limitPrice);
    const hasStop = validatePrice(order.stopPrice);
    const res = {};
    switch (field) {
        case "limitPrice":
            if (validatePrice(value)) {
                res.orderType = hasStop ? "StopLimit" : "Limit";
            }
            else {
                value = "0";
                if (hasStop) {
                    res.orderType = "Stop";
                }
            }
            break;
        case "stopPrice":
            if (validatePrice(value)) {
                res.orderType = hasLimit ? "StopLimit" : "Stop";
            }
            else {
                value = "0";
                if (hasLimit) {
                    res.orderType = "Limit";
                }
            }
            break;
        case "orderType":
            if (value !== "Limit" && value !== "StopLimit") {
                res.limitPrice = "0";
            }
            if (value !== "Stop" && value !== "StopLimit") {
                res.stopPrice = "0";
            }
            break;
        case "tradingDesk":
            res.trader = undefined;
            break;
        case "trader":
            /* To handle properly multi-select edit */
            if (order.tradingDesk !== triggerRow.tradingDesk) {
                res.tradingDesk = triggerRow.tradingDesk;
            }
            break;
    }
    return { ...res, [field]: value };
}
const getColumnFilterState = (filterModal) => {
    const { filterType } = filterModal;
    let filterState;
    const getFilters = (filterModal, conditions) => {
        return {
            conditions: conditions ?? filterModal.conditions,
            filterType: filterModal.filterType,
            joinOperator: filterModal.operator ?? "OR",
        };
    };
    if (filterType === "multi") {
        const { filterModels } = filterModal;
        filterState = filterModels;
        if (filterModels?.[0]) {
            const multiFilterModel = filterModels[0];
            filterState[0] = multiFilterModel?.conditions
                ? getFilters(multiFilterModel)
                : getFilters(multiFilterModel, [multiFilterModel]);
        }
    }
    else if (filterType === "number") {
        filterState = filterModal.conditions
            ? [getFilters(filterModal)]
            : [getFilters(filterModal, [filterModal])];
    }
    return filterState;
};
const getConditions = (conditionsArr) => {
    const conditionsList = {};
    conditionsArr.forEach((con, index) => {
        conditionsList[`condition${index + 1}`] = con;
    });
    return conditionsList;
};
const getConditionalFilterModel = (conditionalFilters) => {
    if (conditionalFilters.conditions?.length > 1) {
        return {
            conditions: conditionalFilters.conditions,
            filterType: conditionalFilters.filterType,
            operator: conditionalFilters.joinOperator,
            ...getConditions(conditionalFilters.conditions),
        };
    }
    else
        return {
            ...conditionalFilters.conditions[0],
        };
};
export const parseFilterModal = (webFilterModal, mobileFilterModal) => {
    if (webFilterModal) {
        return Object.keys(webFilterModal)?.reduce((res, key, idx) => {
            res[key] = {
                filterIndex: idx,
                filterType: webFilterModal[key].filterType,
                id: key,
                filters: getColumnFilterState(webFilterModal[key]),
            };
            return res;
        }, {});
    }
    else if (mobileFilterModal) {
        const parsedFilterModal = {};
        for (const key in mobileFilterModal) {
            const { filterType, filters } = mobileFilterModal[key];
            if (filterType === "multi") {
                const filter = { filterType, filterModels: filters };
                if (filters?.[0])
                    filter.filterModels[0] = getConditionalFilterModel(filters?.[0]);
                parsedFilterModal[key] = filter;
            }
            else if (filterType === "number") {
                let filter = filters?.[0]
                    ? getConditionalFilterModel(filters?.[0])
                    : filters;
                parsedFilterModal[key] = filter;
            }
        }
        return parsedFilterModal;
    }
};
export const parseAdvancedFilterModel = (advancedFilterModel) => {
    if (advancedFilterModel) {
        const filterModelArray = advancedFilterModel.conditions
            ? advancedFilterModel.conditions
            : [advancedFilterModel];
        return Object.entries(filterModelArray)?.reduce((res, conditionalFilterModel, idx) => {
            res[`${conditionalFilterModel[1].colId}`] = {
                filterIndex: idx,
                filterType: conditionalFilterModel[1].filterType === "text"
                    ? "multi"
                    : conditionalFilterModel[1].filterType,
                id: conditionalFilterModel[1].colId,
                filters: [
                    {
                        filterType: conditionalFilterModel[1].filterType,
                        joinOperator: advancedFilterModel.type ?? "OR",
                        conditions: res[`${conditionalFilterModel[1].colId}`]?.filters
                            .conditions
                            ? [
                                ...res[`${conditionalFilterModel[1].colId}`].filters
                                    .conditions,
                                conditionalFilterModel[1],
                            ]
                            : [conditionalFilterModel[1]],
                    },
                ],
            };
            return res;
        }, {});
    }
    return null;
};
export const getGroupRowComplianceState = (rows, defaultState) => {
    const numberOfComplianceState = new Map();
    for (const rowItem of rows) {
        const currentComplianceState = (rowItem.data?.complianceResult
            ?.complianceState ?? defaultState);
        numberOfComplianceState.set(currentComplianceState, (numberOfComplianceState.get(currentComplianceState) ?? 0) + 1);
    }
    return COMPLIANCE_STATE_SEVERITY.map((obj) => ({
        ...obj,
        count: numberOfComplianceState.get(obj.state) ?? 0,
    }))
        .filter((state) => state.count)
        .sort((a, b) => a.severity - b.severity);
};
export const isDiffColumn = (type = "", field = "") => {
    return numericColumns.indexOf(type) > -1 && field.endsWith("Difference");
};
