import { WidgetComponentsDefinition } from "@app-views/dashboards/widgets/types";
import CounterpartySelection from "@app-views/oems/components/CounterpartySelection";
import {
  ElectronicExecutionDetails,
  ExecutionSchemeSelect,
} from "@app-views/oems/components/ElectronicExecutionDetails";
import ElectronicLimitDetailEntry, {
  CounterpartyMemo,
  ExecutionInstructions,
  LimitPrice,
  OrderType,
  StopPrice,
  TimeInForce,
} from "@app-views/oems/components/ElectronicLimitDetailEntry";
import ElectronicRoutingDetailEntry, {
  RoutingCapacitySelection,
} from "@app-views/oems/components/ElectronicRoutingDetailEntry";
import ExecutionMethodSelection, {
  WebOrderTypeSelection,
} from "@app-views/oems/components/ExecutionMethodSelection";
import RoutingBrokerAccountSelection from "@app-views/oems/components/RoutingBrokerAccountSelection";
import RoutingDestinationSelection from "@app-views/oems/components/RoutingDestinationSelection";
import RoutingStrategySelection from "@app-views/oems/components/RoutingStrategySelection";
import RoutingTargetSelection from "@app-views/oems/components/RoutingTargetSelection";
import VoiceDetailEntry, {
  OrderStatusSelect,
} from "@app-views/oems/components/VoiceDetailEntry";
import { useInstrument, useOEMSOrderForm } from "@enfusion-ui/core";
import {
  ButtonGroupSelect,
  ColumnGrid,
  IconButton,
  NumericInput,
  PanelRow,
  Select,
  TextInput,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

const EmptyContainer = styled.div<{ height?: number }>`
  border: 1px dotted var(--primary);
  height: ${({ height }) => height ?? 1}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ElectronicInstructionsSelectMobile = () => (
  <Select label="Instructions" disabled />
);
const CounterpartyMemoInputMobile = () => <TextInput label="Memo" disabled />;
const ElectronicExecutionSchemeSelectMobile = () => (
  <>
    <EmptyContainer height={114}>Electronic Execution Schemes</EmptyContainer>
    <Select label="" disabled />
  </>
);
const RoutingCounterpartySelectMobile = () => (
  <Select label="Counterparty" disabled minWidth={100} />
);
const RoutingTargetSelectMobile = () => <Select label="Target" disabled />;
const RoutingDestinationSelectMobile = () => (
  <Select label="Destination" disabled />
);
const RoutingBrokerAccountSelectMobile = () => (
  <Select label="Broker Account" disabled minWidth={100} />
);
const RoutingStrategySelectMobile = () => (
  <Select label="Strategy" disabled minWidth={100} />
);
const RoutingCapacitySelectMobile = () => (
  <Select label="Capacity" disabled minWidth={100} />
);
const OrderStatusSelectMobile = () => (
  <Select label="Order Status" disabled minWidth={100} />
);
const WebOrderTypeSelectMobile = () => (
  <ButtonGroupSelect
    selectedValue="electronic"
    options={[
      { label: "Parent", value: "parent" },
      { label: "Electronic", value: "electronic" },
      { label: "Voice", value: "voice" },
    ]}
    onSelect={() => {}}
  />
);
const OrderTypeSelectMobile = () => (
  <ButtonGroupSelect
    title="Type"
    selectedValue="MKT"
    options={[
      { label: "MKT", value: "MKT" },
      { label: "Limit", value: "Limit" },
    ]}
    onSelect={() => {}}
  />
);

const TimeInForceSelectMobile = () => (
  <ButtonGroupSelect
    title="TIF"
    selectedValue="DAY"
    options={[
      { label: "DAY", value: "DAY" },
      { label: "GTC", value: "GTC" },
    ]}
    onSelect={() => {}}
  />
);
const LimitPriceInputMobile = () => (
  <NumericInput
    bigControls
    value={0}
    disabled
    onChange={() => {}}
    label="Limit"
  />
);
const StopPriceInputMobile = () => (
  <NumericInput
    bigControls
    value={0}
    disabled
    onChange={() => {}}
    label="Stop"
  />
);

const ElectronicRoutingDetailsPanelMobile = () => (
  <PanelRow>
    <RoutingTargetSelectMobile />
    <RoutingDestinationSelectMobile />
    <ColumnGrid numColumns={2} isMobile={false} style={{ flex: 1 }}>
      <RoutingBrokerAccountSelectMobile />
      <RoutingCounterpartySelectMobile />
    </ColumnGrid>
    <ColumnGrid numColumns={2} isMobile={false} style={{ flex: 1 }}>
      <RoutingStrategySelectMobile />
      <RoutingCapacitySelectMobile />
    </ColumnGrid>
  </PanelRow>
);

const ElectronicLimitDetailsPanelMobile = () => (
  <>
    <TimeInForceSelectMobile />
    <OrderTypeSelectMobile />
    <LimitPriceInputMobile />
    <ElectronicInstructionsSelectMobile />
    <CounterpartyMemoInputMobile />
  </>
);

const ExecutionMethodPanelMobile = () => (
  <>
    <ElectronicLimitDetailsPanelMobile />
    <div style={{ marginTop: "4px" }}>
      <WebOrderTypeSelectMobile />
    </div>
    <PanelRow>
      <IconButton icon={faChevronDown} disabled></IconButton>
      <div
        style={{
          marginTop: "2px",
          fontSize: "smaller",
        }}
      >
        Electronic Execution Details
      </div>
    </PanelRow>
    <ElectronicExecutionSchemeSelectMobile />
    <ElectronicRoutingDetailsPanelMobile />
  </>
);

export const executionMethodPanel: WidgetComponentsDefinition = {
  renderComponent: ExecutionMethodSelection,
  renderMobileComponent: ExecutionMethodPanelMobile,
};

export const electronicLimitDetailsPanel: WidgetComponentsDefinition = {
  renderComponent: ElectronicLimitDetailEntry,
  renderMobileComponent: ElectronicLimitDetailsPanelMobile,
};

export const electronicExecutionDetailsPanel: WidgetComponentsDefinition = {
  renderComponent: () => <ElectronicExecutionDetails show />,
  renderMobileComponent: () => (
    <>
      <ElectronicExecutionSchemeSelectMobile />
      <ElectronicRoutingDetailsPanelMobile />
    </>
  ),
};

export const voiceExecutionPanel: WidgetComponentsDefinition = {
  renderComponent: () => <VoiceDetailEntry show />,
  renderMobileComponent: () => (
    <>
      <CounterpartySelection />
      <OrderStatusSelect />
    </>
  ),
};

export const electronicRoutingDetailsPanel: WidgetComponentsDefinition = {
  renderComponent: ElectronicRoutingDetailEntry,
  renderMobileComponent: ElectronicRoutingDetailsPanelMobile,
};

export const webOrderTypeSelect: WidgetComponentsDefinition = {
  renderComponent: WebOrderTypeSelection,
  renderMobileComponent: WebOrderTypeSelectMobile,
};

export const orderTypeSelect: WidgetComponentsDefinition = {
  renderComponent: OrderType,
  renderMobileComponent: OrderTypeSelectMobile,
};

const LimitPriceWidget = () => {
  const { isFutureSpreadOrder } = useOEMSOrderForm();
  const instrument = useInstrument();
  const tickSize = instrument?.tickSize;

  return (
    <LimitPrice
      disabled={false}
      tickSize={tickSize || 0.01}
      min={isFutureSpreadOrder ? undefined : 0}
    />
  );
};

export const limitPriceInput: WidgetComponentsDefinition = {
  renderComponent: LimitPriceWidget,
  renderMobileComponent: LimitPriceInputMobile,
};

export const timeInForceSelect: WidgetComponentsDefinition = {
  renderComponent: TimeInForce,
  renderMobileComponent: TimeInForceSelectMobile,
};

const StopPriceWidget = () => {
  const { isFutureSpreadOrder } = useOEMSOrderForm();
  const instrument = useInstrument();
  const tickSize = instrument?.tickSize;

  return (
    <StopPrice
      disabled={false}
      tickSize={tickSize || 0.01}
      min={isFutureSpreadOrder ? undefined : 0}
    />
  );
};

export const stopPriceInput: WidgetComponentsDefinition = {
  renderComponent: StopPriceWidget,
  renderMobileComponent: StopPriceInputMobile,
};

export const electronicInstructionsSelect: WidgetComponentsDefinition = {
  renderComponent: ExecutionInstructions,
  renderMobileComponent: ElectronicInstructionsSelectMobile,
};

export const counterpartyMemoInput: WidgetComponentsDefinition = {
  renderComponent: CounterpartyMemo,
  renderMobileComponent: CounterpartyMemoInputMobile,
};

export const electronicExecutionSchemeSelect: WidgetComponentsDefinition = {
  renderComponent: ExecutionSchemeSelect,
  renderMobileComponent: ElectronicExecutionSchemeSelectMobile,
};

export const routingCounterpartySelect: WidgetComponentsDefinition = {
  renderComponent: CounterpartySelection,
  renderMobileComponent: RoutingCounterpartySelectMobile,
};

export const routingTargetSelect: WidgetComponentsDefinition = {
  renderComponent: RoutingTargetSelection,
  renderMobileComponent: RoutingTargetSelectMobile,
};

export const routingDestinationSelect: WidgetComponentsDefinition = {
  renderComponent: RoutingDestinationSelection,
  renderMobileComponent: RoutingDestinationSelectMobile,
};

export const routingBrokerAccountSelect: WidgetComponentsDefinition = {
  renderComponent: RoutingBrokerAccountSelection,
  renderMobileComponent: RoutingBrokerAccountSelectMobile,
};

export const routingStrategySelect: WidgetComponentsDefinition = {
  renderComponent: RoutingStrategySelection,
  renderMobileComponent: RoutingStrategySelectMobile,
};

export const routingCapacitySelect: WidgetComponentsDefinition = {
  renderComponent: RoutingCapacitySelection,
  renderMobileComponent: RoutingCapacitySelectMobile,
};

export const orderStatusSelect: WidgetComponentsDefinition = {
  renderComponent: OrderStatusSelect,
  renderMobileComponent: OrderStatusSelectMobile,
};
