import AdvancedInstrumentSearch from "@app-components/inputs/InstrumentSelect/components/AdvancedInstrumentSearch";
import { InstrumentOption } from "@app-components/inputs/InstrumentSelect/types";
import { usePortfolio } from "@app-context/portfolios/PortfolioProvider";
import { UNKNOWN_INSTRUMENT_ID } from "@enfusion-ui/core";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { Button, Modal } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-width: 610px;
`;

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
`;

type AddSecurityModalProps = {
  open: boolean;
  closeModal: () => void;
};

const AddSecurityModal: React.FC<AddSecurityModalProps> = ({
  open,
  closeModal,
}) => {
  const { addSecurity, securitiesList } = usePortfolio();

  const isMounted = useMounted();

  const [instrumentValue, setInstrumentValue] =
    React.useState<InstrumentOption | null>(null);

  const handleCloseModal = useRefCallback(() => {
    setInstrumentValue(null);
    closeModal();
  }, [closeModal]);

  const handleChange = useRefCallback(
    async (option: InstrumentOption | null) => {
      if (isMounted()) {
        setInstrumentValue(option);
      }
    },
    []
  );

  const submitForm = useRefCallback(() => {
    addSecurity(instrumentValue?.value ?? UNKNOWN_INSTRUMENT_ID);
    handleCloseModal();
  }, [instrumentValue, handleCloseModal]);

  return (
    <Modal
      title="Add Security"
      isOpen={open}
      onClose={handleCloseModal}
      content={
        <ContentContainer>
          <AdvancedInstrumentSearch
            onInstrumentSelect={(id, description) =>
              handleChange({
                label: description,
                value: id,
                instrument: null,
              })
            }
            autoFocus={open}
            dataGridHeight={200}
            reverseSearchWidgetLayout
            twoColumnCheckbox
            hideOptions
            doubleClickSelect={false}
            getExcludedSecurities={securitiesList.get}
          />
          <ActionContainer>
            <Button
              data-testid="btn-save"
              data-e2e-id="add-to-grid-btn"
              theme="primary"
              onClick={submitForm}
              disabled={!instrumentValue?.value}
            >
              Add to Grid
            </Button>
            <Button onClick={handleCloseModal} data-testid="btn-cancel">
              Cancel
            </Button>
          </ActionContainer>
        </ContentContainer>
      }
    />
  );
};

export default AddSecurityModal;
