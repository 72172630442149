import InstrumentMultiSelect from "@app-components/inputs/InstrumentSelect/InstrumentMultiSelect";
import { InstrumentInfo } from "@enfusion-ui/types";
import { ControlledInputBase } from "@enfusion-ui/web-components";

import { InputContainer } from "./ReportBaseForm";

export const InstrumentsMultiSelect = () => {
  return (
    <ControlledInputBase
      name="instrumentIds"
      render={({ ref: _ref, onChange, ...rest }) => (
        <InputContainer>
          <InstrumentMultiSelect
            {...rest}
            label="Instrument(s)"
            onChange={(instruments: InstrumentInfo[] | null) => {
              onChange(instruments?.map((instrument) => instrument.id));
            }}
          />
        </InputContainer>
      )}
    />
  );
};
