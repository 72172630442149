import QuickOrderEntry from "@app-components/QuickOrderEntry/QuickOrderEntry";
import { WidgetComponentsDefinition } from "@app-views/dashboards/widgets/types";
import { DarkPoolSelect } from "@app-views/oems/OrderTicket/components/inputs/DarkPoolSelect";
import { BorrowBrokerPanel } from "@app-views/oems/OrderTicket/panels/BorrowBroker/BorrowBrokerPanel";
import CalculatedSidePanel from "@app-views/oems/OrderTicket/panels/CalculatedSidePanel";
import { useInstrument, useOEMSOrderForm } from "@enfusion-ui/core";
import {
  Button,
  ButtonGroupSelect,
  Checkbox,
  ControlledCheckbox,
} from "@enfusion-ui/web-components";
import { faBookOpen } from "@fortawesome/pro-solid-svg-icons";

import { OrderDetailsEditWidget } from "./OrderDetailsEditWidget";
import { OrderDetailsWidget } from "./OrderDetailsWidget";
import { OrderDetailsWidgetMobile } from "./OrderDetailsWidgetMobile";

export const OrderSideSelectMobile = () => (
  <ButtonGroupSelect
    title="Side"
    options={[
      { label: "B", value: "B" },
      { label: "S", value: "S" },
      { label: "SS", value: "SS" },
      { label: "BC", value: "BC" },
    ]}
    onSelect={() => {}}
    disabled
  />
);

export const QuickEntryButton = () => (
  <Button
    icon={faBookOpen}
    iconPosition="left"
    style={{ textAlign: "left", width: "50%", marginTop: 8 }}
  >
    Quick Order Entry
  </Button>
);

const OrderSideSelectWidget = () => {
  const { splitOrder, isFutureSpreadOrder, isTransmitted, isSplit, completed } =
    useOEMSOrderForm();
  const disabled = completed || isTransmitted || isSplit || splitOrder;

  return (
    <CalculatedSidePanel
      disabled={splitOrder || disabled}
      showLimitedOptions={isFutureSpreadOrder}
    />
  );
};
const QuickOrderEntryWidget = () => {
  const { splitOrder, isTransmitted, isSplit, completed } = useOEMSOrderForm();
  const disabled = completed || isTransmitted || isSplit || splitOrder;

  return <QuickOrderEntry disabled={disabled} label="Expression" />;
};

const TradersDiscretionWidget = () => {
  const instrument = useInstrument();
  const optionsOrder = instrument?.option ?? false;
  return (
    <ControlledCheckbox
      name="traderDiscretion"
      label="Trader Discretion"
      labelPlacement="right"
      style={{ marginTop: "var(--spacing)" }}
      disabled={!optionsOrder}
    />
  );
};

export const orderDetailsPanel: WidgetComponentsDefinition = {
  renderComponent: OrderDetailsWidget,
  editComponent: OrderDetailsEditWidget,
  renderMobileComponent: OrderDetailsWidgetMobile,
};

export const borrowBrokerPanel: WidgetComponentsDefinition = {
  renderComponent: BorrowBrokerPanel,
};

export const orderSideSelect: WidgetComponentsDefinition = {
  renderComponent: OrderSideSelectWidget,
  renderMobileComponent: OrderSideSelectMobile,
};

export const darkPoolSelect: WidgetComponentsDefinition = {
  renderComponent: DarkPoolSelect,
};

export const quickOrderEntryInput: WidgetComponentsDefinition = {
  renderComponent: QuickOrderEntryWidget,
  renderMobileComponent: QuickEntryButton,
};

export const swapOrderCheckbox: WidgetComponentsDefinition = {
  renderComponent: () => (
    <ControlledCheckbox
      name="swapOrder"
      label="Route To Swap Desk"
      labelPlacement="right"
      style={{ marginTop: "var(--spacing)" }}
      disabled={false}
    />
  ),
  renderMobileComponent: () => (
    <Checkbox checked={false} disabled label="Swap?" />
  ),
};

export const traderDiscretionCheckbox: WidgetComponentsDefinition = {
  renderComponent: TradersDiscretionWidget,
  renderMobileComponent: () => (
    <Checkbox checked={false} disabled label="Trader Discretion" />
  ),
};
