import DocumentSaveForm, {
  DocumentSaveFormSubmitCallback,
} from "@app-components/modal/DocumentSaveForm";
import { DashboardFolderProvider } from "@app-context/dashboards/DashboardFolderProvider";
import { DashboardRoot } from "@enfusion-ui/types";
import { Modal } from "@enfusion-ui/web-components";
import * as React from "react";

export const SaveDashboardModal = React.memo(
  ({
    filePath,
    onSubmit,
    root,
    open,
    fileName,
    onClose,
  }: {
    filePath: string;
    fileName: string;
    root?: DashboardRoot;
    open: boolean;
    onClose: VoidFunction;
    onSubmit: DocumentSaveFormSubmitCallback;
  }) => (
    <Modal
      isOpen={open}
      onClose={onClose}
      title="Save Dashboard"
      content={
        <DashboardFolderProvider root={root}>
          <DocumentSaveForm
            root={root}
            onSubmit={onSubmit}
            onCancel={onClose}
            fileName={fileName}
            fullyQualifiedPath={filePath.split("/").slice(1).join("/")}
          />
        </DashboardFolderProvider>
      }
    />
  )
);
