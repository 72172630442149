import { ReportSettingsDefinition } from "../../types";
import SecurityMasterQuickFilter from "./SecurityMasterQuickFilter";
import SecurityMasterSettings from "./SecurityMasterSettings";

export const WebInstrumentQuery: ReportSettingsDefinition = {
  name: "Instrument",
  queryType: "WebInstrumentQuery",
  quickFilterComponent: SecurityMasterQuickFilter,
  settingsComponent: SecurityMasterSettings,
};
