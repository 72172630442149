import { ContextItemEntry } from "@app-views/portfolios/components/MainPortfolioGrid";
import { useLocalStorage, useRefCallback } from "@enfusion-ui/hooks";
import {
  REPORT_HEADER_ROW_HEIGHT,
  REPORT_ROW_HEIGHT,
  styled,
  useTheme,
  useThisTab,
} from "@enfusion-ui/web-core";
import {
  ColumnRowGroupChangedEvent,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridOptions,
  GridReadyEvent,
  IMenuActionParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import * as React from "react";

export type DataGridProps = {
  height: string | number | undefined;
  width?: string | number | undefined;
  id?: string;
  ignoreColumns?: boolean;
  gridName?: string;
  onColumnStateChanged?: () => void;
  onColumnRowGroupChanged?: (event: ColumnRowGroupChangedEvent) => void;
  isWidget?: boolean;
  rowHeight?: number;
  headerHeight?: number;
  hide?: boolean;
  className?: string;
  updateAdvancedFilterSettings?: (enableAdvancedFilter: boolean) => void;
  enableAdvancedFilterState?: boolean;
} & GridOptions;

export const STORAGE_KEY = "-enable-advanced-filter";

const DataGrid = React.forwardRef<HTMLDivElement, DataGridProps>(
  (
    {
      height,
      rowHeight = REPORT_ROW_HEIGHT,
      headerHeight = REPORT_HEADER_ROW_HEIGHT,
      width = "100%",
      ignoreColumns = false,
      columnDefs,
      onGridReady,
      onColumnStateChanged,
      onColumnRowGroupChanged,
      onSortChanged,
      isWidget,
      groupDisplayType = "groupRows",
      hide = false,
      id,
      className,
      getContextMenuItems,
      getMainMenuItems,
      gridName,
      defaultColDef,
      onFilterChanged,
      enableAdvancedFilterState,
      ...rest
    },
    ref
  ) => {
    const { theme } = useTheme();
    const { selector } = useThisTab();
    const [enableAdvancedFilter, setEnableAdvancedFilter] =
      React.useState<boolean>(
        enableAdvancedFilterState ??
          localStorage.getItem(`${gridName}${STORAGE_KEY}`) === "true"
      );
    const enableAdvancedFilterOption = React.useMemo(
      () => [
        {
          name: `${
            enableAdvancedFilter ? "Disable" : "Enable"
          } Advanced Filters`,
          action: (params: IMenuActionParams<any>) => {
            const newEnabledState = !enableAdvancedFilter;
            setEnableAdvancedFilter(newEnabledState);

            params.api.onFilterChanged(
              newEnabledState ? "advancedFilter" : "columnFilter"
            );

            localStorage.setItem(
              `${gridName}${STORAGE_KEY}`,
              `${newEnabledState}`
            );
          },
        },
      ],
      [enableAdvancedFilter]
    );

    const fetchMainMenuItems = useRefCallback(
      (params: GetMainMenuItemsParams): ContextItemEntry[] => {
        return [
          ...(getMainMenuItems
            ? getMainMenuItems(params)
            : params.defaultItems ?? []),
          ...enableAdvancedFilterOption,
        ];
      },
      [enableAdvancedFilterOption, getMainMenuItems]
    );

    const fetchContextMenuItems = useRefCallback(
      (params: GetContextMenuItemsParams): ContextItemEntry[] => {
        return [
          ...(getContextMenuItems
            ? getContextMenuItems(params)
            : params.defaultItems ?? []),
          ...enableAdvancedFilterOption,
        ];
      },
      [enableAdvancedFilterOption, getContextMenuItems]
    );

    const [advancedFilterState, setAdvancedFilterState] = useLocalStorage(
      `${gridName}-advanced-filter-model`,
      "null"
    );

    const handleFilterChange = React.useCallback(
      (event: FilterChangedEvent) => {
        onFilterChanged?.(event);
        if (
          gridName &&
          event.type === "filterChanged" &&
          event.source === "advancedFilter"
        )
          setAdvancedFilterState(
            JSON.stringify(event.api.getAdvancedFilterModel())
          );
      },
      [onFilterChanged, gridName]
    );

    const handleGridReady = useRefCallback(
      (event: GridReadyEvent) => {
        const filterModel = JSON.parse(advancedFilterState);
        if (filterModel !== null) event.api.setAdvancedFilterModel(filterModel);
        onGridReady?.(event);
      },
      [onGridReady]
    );

    return (
      <div
        ref={ref}
        className={
          isWidget
            ? className
            : `${className || ""} ${theme.agGridClass}`.trim()
        }
        style={{ height, width, display: hide ? "none" : "inherit" }}
        id={id}
      >
        {(ignoreColumns || !!columnDefs) && (
          <AgGridReact
            rowHeight={rowHeight}
            headerHeight={headerHeight}
            onColumnResized={onColumnStateChanged}
            onDragStopped={onColumnStateChanged}
            onColumnPinned={onColumnStateChanged}
            onColumnVisible={onColumnStateChanged}
            onColumnMoved={onColumnStateChanged}
            onColumnRowGroupChanged={(event) => {
              onColumnStateChanged?.();
              onColumnRowGroupChanged?.(event);
            }}
            onSortChanged={(event) => {
              onColumnStateChanged?.();
              onSortChanged?.(event);
            }}
            popupParent={document.getElementById(selector) ?? undefined}
            {...rest}
            groupDisplayType={groupDisplayType}
            columnDefs={columnDefs}
            onGridReady={handleGridReady}
            animateRows={false}
            getMainMenuItems={fetchMainMenuItems}
            getContextMenuItems={fetchContextMenuItems}
            enableAdvancedFilter={enableAdvancedFilter}
            defaultColDef={{ ...defaultColDef, cellDataType: false }}
            processUnpinnedColumns={() => []}
            onFilterChanged={handleFilterChange}
          />
        )}
      </div>
    );
  }
);

export const RoundedDataGrid = styled(DataGrid)`
  > div {
    width: 100%;
  }

  .ag-root-wrapper {
    border-width: 0px !important;
    background-color: transparent;
  }

  .ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
    border: solid 1px;
    border-color: #424242;
    border-color: var(--ag-border-color, #424242);
    border-radius: var(--radius);
    background-color: var(--ag-background-color);
  }

  .ag-root-wrapper .ag-column-drop-wrapper .ag-column-drop-horizontal {
    border-bottom-width: 0px;
    background-color: transparent !important;
    padding-left: var(--spacing) !important;
  }

  .ag-header {
    border-top-left-radius: calc(var(--radius) + 1.5px);
    border-top-right-radius: calc(var(--radius) + 1.5px);
  }

  .ag-center-cols-container {
    min-width: 100% !important;
  }
`;

export default DataGrid;
