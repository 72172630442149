import EntrySourcesSelect from "@app-views/reports/reportSettings/generalLedgerAccounting/glDistributionLine/EntrySourcesSelect";
import {
  AnalyticsSourceOptions,
  CalculationMethodOptions,
  CalculationTypeOptions,
  ComplianceState,
  complianceStateTypeOptions,
  FundBenchmarkTypeOptions,
  InstrumentInfo,
  MultiSelectOptionsType,
  NAVSourceOptions,
  NodeData,
  WebDateSelection,
  WebHierarchyReportQuery,
  WebOrderBlotterQuery,
  WebPositionReportQuery,
  WebReportAccountSelection,
  WebReportQuery,
  WebTradeValuationReportQuery,
} from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  ControlledCheckbox,
  ControlledInputBase,
  ControlledSelect,
  ControlledTextInput,
  MultiSelect,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { startCase } from "lodash";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import InstrumentMultiSelect from "../../../../components/inputs/InstrumentSelect/InstrumentMultiSelect";
import ControlledAccountSelect from "./AccountSelect";
import { ControlledAssetMeasureSelect } from "./AssetMeasureSelect";
import ControlledAssignedDeskSelect from "./AssignedDeskSelect";
import ControlledAssignedTraderSelect from "./AssignedTraderSelect";
import CashModeSelect from "./CashModeSelect";
import { ParamName } from "./config/FormConfig";
import { ControlledCorporateActionsSelect } from "./CorporateActionsSelect";
import {
  DatePeriodSelection,
  DateRangeSelection,
  DateRangeSymbolSelection,
  DateSelection,
} from "./Date/ControlledDateSelection";
import { ControlledFinancialSubTypeSelect } from "./FinancialSubTypeSelect";
import { ControlledLedgerSelect } from "./LedgerSelect";
import { ControlledMarketEnvironmentSelect } from "./MarketEnvironmentSelect";
import { ControlledMaxResultsInput } from "./MaxResultsInput";
import { ControlledPortfolioGroupsSelect } from "./PortfolioGroupsSelect";
import { ControlledQuoteSetIdsSelect } from "./QuoteSetIdsSelect";
import { ControlledSourceIdsSelect } from "./SourceIdsSelect";

export const InputContainer = styled.div`
  min-width: 250px;
`;

export const getSelectedAccounts = (reportQuery: WebReportQuery) => {
  const { accountSelection } = reportQuery as WebHierarchyReportQuery;

  const { accountIds } = accountSelection as WebReportAccountSelection;
  return accountIds.map(
    (id) =>
      ({
        accountId: id,
        id: id.toString(),
        name: "",
        path: "",
      } as NodeData)
  );
};

export const AccountingMethodOptions = [
  { label: "Cash Basis", value: "CashBasis" },
  { label: "Accrual Basis", value: "AccrualBasis" },
];

const ReportBaseForm: React.FC<{
  reportQuery: WebReportQuery;
  paramType: ParamName | "--custom--" | "";
  name?: string;
}> = ({ paramType, reportQuery, name }) => {
  const { setValue, watch } = useFormContext();

  switch (paramType) {
    case "--custom--":
      return reportQuery as unknown as React.ReactElement;
    case "accountSelection": {
      return (
        <ControlledAccountSelect
          defaultAccountSelection={
            (reportQuery as WebHierarchyReportQuery)?.accountSelection
          }
        />
      );
    }
    case "datePeriodSelection": {
      return (
        <DatePeriodSelection
          name="datePeriodSelection"
          label="Value Date"
          defaultValue={
            (reportQuery as WebPositionReportQuery)?.datePeriodSelection
          }
        />
      );
    }
    case "portfolioGroupIds": {
      return (
        <InputContainer>
          <ControlledPortfolioGroupsSelect name="portfolioGroupIds" />
        </InputContainer>
      );
    }
    case "marketEnvironmentId": {
      return (
        <InputContainer>
          <ControlledMarketEnvironmentSelect />
        </InputContainer>
      );
    }
    case "generalLedgerIds": {
      return (
        <ControlledLedgerSelect
          label="Ledger"
          disabled={false}
          name="generalLedgerIds"
        />
      );
    }
    case "valueDate": {
      return (
        <DateSelection
          name={name || "valueDate"}
          label={name ? startCase(name) : "Value Date"}
          defaultValue={
            (reportQuery as unknown as Record<string, WebDateSelection>)?.[
              name || "valueDate"
            ] ?? undefined
          }
        />
      );
    }
    case "fromDate": {
      return (
        <DateSelection
          name="fromDate"
          label="From Date"
          defaultValue={
            (reportQuery as { fromDate: WebDateSelection })?.fromDate ??
            undefined
          }
        />
      );
    }
    case "toDate": {
      return (
        <DateSelection
          name="toDate"
          label="To Date"
          defaultValue={
            (reportQuery as { toDate: WebDateSelection })?.toDate ?? undefined
          }
        />
      );
    }
    case "accountingMethod": {
      return (
        <ControlledSelect
          name="accountingMethod"
          label="Accounting Method"
          options={AccountingMethodOptions}
          clearable={false}
          inputId="report-base-accounting-method-selection-id"
        />
      );
    }
    case "period": {
      return <DateRangeSymbolSelection name="period" label="Period" />;
    }
    case "freeForm": {
      return <ControlledTextInput name="freeForm" label="Search terms" />;
    }

    case "financialSubTypes": {
      return (
        <InputContainer>
          <ControlledFinancialSubTypeSelect
            name="financialSubTypes"
            selectProps={{ label: "Financial Subtype(s)" }}
          />
        </InputContainer>
      );
    }

    case "maxResults": {
      return <ControlledMaxResultsInput />;
    }
    case "primaryOnly": {
      return (
        <ControlledCheckbox
          style={{ alignSelf: "flex-end" }}
          label="Primary Only"
          labelPlacement="right"
          nullValue={false}
          name="primaryOnly"
          topLabelPlaceholder
        />
      );
    }
    case "ignoreInactive": {
      return (
        <ControlledCheckbox
          style={{ alignSelf: "flex-end" }}
          name="ignoreInactive"
          label="Ignore Inactive"
          labelPlacement="right"
          nullValue={false}
          topLabelPlaceholder
        />
      );
    }
    case "dateRange": {
      return (
        <DateRangeSelection
          name={name || "dateRange"}
          startDateLabel={name ? startCase(name) : "Order Date"}
          endDateLabel="to"
        />
      );
    }

    case "assignedTraderIds": {
      return (
        <ControlledAssignedTraderSelect
          defaultAssignedTrader={
            (reportQuery as WebOrderBlotterQuery).assignedTraderIds
          }
        />
      );
    }

    case "assignedDeskIds": {
      return (
        <ControlledAssignedDeskSelect
          defaultAssignedDesk={
            (reportQuery as WebOrderBlotterQuery).assignedDeskIds
          }
        />
      );
    }

    case "complianceStates": {
      const complianceStates = watch("complianceStates");

      return (
        <ControlledInputBase
          name="complianceStates"
          render={({ onChange, ...rest }) => (
            <InputContainer>
              <MultiSelect
                {...rest}
                label="Compliance State"
                placeholder="All"
                options={complianceStateTypeOptions}
                value={
                  complianceStates
                    ? getSelectedValues(
                        complianceStates,
                        complianceStateTypeOptions
                      )
                    : []
                }
                onChange={(selectedOption) => {
                  const complianceTypes = selectedOption?.length
                    ? selectedOption.map(
                        (option: MultiSelectOptionsType) =>
                          option.value as ComplianceState
                      )
                    : null;
                  setValue("complianceStates", complianceTypes);
                  onChange(complianceTypes);
                }}
                inputId="report-base-compliance-state-multi-selection-id"
              />
            </InputContainer>
          )}
        />
      );
    }

    case "modificationDateRange": {
      return (
        <DateRangeSelection
          name="modificationDateRange"
          startDateLabel="Modification Date"
          endDateLabel="to"
        />
      );
    }

    case "includeAllGLActivity": {
      return (
        <ControlledCheckbox
          style={{ alignSelf: "flex-end" }}
          name="includeAllGLActivity"
          label="Include All GL Activity"
          labelPlacement="right"
          nullValue={false}
          topLabelPlaceholder
        />
      );
    }
    case "entrySources": {
      return <EntrySourcesSelect />;
    }

    case "excludeTemplateEntries": {
      return (
        <ControlledCheckbox
          style={{ alignSelf: "flex-end" }}
          nullValue={false}
          labelPlacement="right"
          name="excludeTemplateEntries"
          label="Exclude Template Entries"
          topLabelPlaceholder
        />
      );
    }

    case "calculationMethod": {
      return (
        <ControlledSelect
          name="calculationMethod"
          label="Calculation Method"
          options={CalculationMethodOptions}
          clearable={false}
          inputId="report-base-calculation-method-selection-id"
        />
      );
    }

    case "instrumentTypes": {
      return (
        <InputContainer>
          <ControlledFinancialSubTypeSelect
            name="instrumentTypes"
            selectProps={{ label: "Instrument type(s)" }}
          />
        </InputContainer>
      );
    }

    case "valueDatePeriodSelection": {
      return (
        <DatePeriodSelection
          name="valueDatePeriodSelection"
          label="Value Date"
          defaultValue={
            (reportQuery as WebTradeValuationReportQuery)
              ?.valueDatePeriodSelection
          }
        />
      );
    }

    case "benchmarkNAVSource": {
      return (
        <ControlledSelect
          name="benchmarkNAVSource"
          label="NAV Source"
          options={NAVSourceOptions}
          clearable={false}
          inputId="report-base-benchmark-nav-selection-id"
        />
      );
    }

    case "benchmarkCalculationType": {
      return (
        <ControlledSelect
          name="benchmarkCalculationType"
          label="Calculation Type"
          options={CalculationTypeOptions}
          minWidth={150}
          clearable={false}
          inputId="report-base-benchmark-calculation-type-selection-id"
        />
      );
    }

    case "portfolioAnalyticsSources": {
      return (
        <ControlledSelect
          name="portfolioAnalyticsSources"
          label="Position Source"
          options={AnalyticsSourceOptions}
          minWidth={150}
          clearable={false}
          inputId="report-base-portfolio-analytics-selection-id"
        />
      );
    }

    case "fundBenchmarkType": {
      return (
        <ControlledSelect
          name="fundBenchmarkType"
          label="Fund Benchmark"
          options={FundBenchmarkTypeOptions}
          minWidth={150}
          clearable={false}
          inputId="report-base-fund-benchmark-selection-id"
        />
      );
    }

    case "includeUnderlyings": {
      return (
        <ControlledCheckbox
          style={{ alignSelf: "flex-end" }}
          nullValue={false}
          labelPlacement="right"
          name="includeUnderlyings"
          label="Include Underlyings"
          topLabelPlaceholder
        />
      );
    }

    case "instrumentIds": {
      return (
        <ControlledInputBase
          name="instrumentIds"
          render={({ ref: _ref, onChange, ...rest }) => (
            <InputContainer>
              <InstrumentMultiSelect
                {...rest}
                label="Instrument(s)"
                onChange={(instruments: InstrumentInfo[] | null) => {
                  onChange(instruments?.map((instrument) => instrument.id));
                }}
              />
            </InputContainer>
          )}
        />
      );
    }
    case "cashMode": {
      return <CashModeSelect />;
    }
    case "quoteSetIds": {
      return <ControlledQuoteSetIdsSelect />;
    }
    case "sourceIds": {
      return <ControlledSourceIdsSelect />;
    }
    case "assetMeasures": {
      return <ControlledAssetMeasureSelect />;
    }
    case "corporateActionTypes": {
      return <ControlledCorporateActionsSelect />;
    }
    default:
      return null;
  }
};

export default ReportBaseForm;
