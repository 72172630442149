import { ReportSettingsDefinition } from "../../types";
import GLPositionQuickFilter from "./GLPositionQuickFilter";
import GLPositionSettings from "./GLPositionSettings";

export const WebGLPositionQuery: ReportSettingsDefinition = {
  name: "GLPosition",
  queryType: "WebGLPositionQuery",
  quickFilterComponent: GLPositionQuickFilter,
  settingsComponent: GLPositionSettings,
};
