/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRefCallback, useRestAbortableOptions } from "@enfusion-ui/hooks";
import { SelectOptionsType } from "@enfusion-ui/types";
import { getSelectOption } from "@enfusion-ui/utils";
import { ControlledEmpty, ControlledSelect } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";
import { RegisterOptions, useFormContext, useWatch } from "react-hook-form";

export type CounterpartyOptions = {
  label: string;
  value: number;
};

export type CounterpartySelectionProps = {
  disabled?: boolean;
  defaultValue?: number | null;
  controllerName?: string;
  rules?: RegisterOptions;
  required?: boolean;
};

const CounterpartySelection = ({
  disabled = false,
  defaultValue,
  controllerName = "counterpartyId",
  rules,
  required = false,
}: CounterpartySelectionProps) => {
  const [defaultOption, setDefaultOption] =
    React.useState<SelectOptionsType<number> | null>();
  const { options, loading } = useRestAbortableOptions(
    REST_API.LEGALENTITY.GET_ALL_COUNTERPARTIES.FETCH,
    (c) => ({
      value: c.counterpartyId,
      company: c.companyShortName,
      shortName: c.counterpartyShortName || "n / a",
      label: `[${c.counterpartyShortName || "n / a"}] ${c.counterpartyName}`,
    })
  );

  const { setValue } = useFormContext();
  const counterpartyId = useWatch<number | null>({ name: controllerName });
  const destinationFixName = useWatch<string | null>({
    name: "destinationFixName",
  });

  const setCpId = useRefCallback(
    (cid?: number | null, setDirect = false) => {
      if (!loading && typeof cid === "number") {
        if (setDirect) setValue("counterpartyId", cid);
        const selected = getSelectOption(options, cid);
        if (!!selected)
          setValue(
            "counterpartyShortName",
            selected?.label.substring(1, selected?.label.indexOf("]"))
          );
      }
    },
    [loading, options]
  );

  const getFixNameCpId = useRefCallback(
    (cidBase: number | null = null) => {
      let cid: number | null = cidBase;
      if (destinationFixName !== null) {
        const found = options.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (i) => (i as any).company === destinationFixName
        );
        if (!!found) cid = found.value;
        setValue("destinationFixName", null);
      }
      return cid;
    },
    [destinationFixName, options]
  );

  React.useEffect(() => {
    if (destinationFixName === null) setCpId(counterpartyId);
  }, [counterpartyId]);

  React.useEffect(() => {
    if (!loading) {
      const id = getFixNameCpId();
      if (id !== null) setCpId(id, true);
    }
  }, [destinationFixName]);

  React.useEffect(() => {
    if (!loading) setCpId(getFixNameCpId(counterpartyId), true);
  }, [loading, options]);

  React.useEffect(() => {
    if (!loading && defaultValue) {
      const selected = getSelectOption(options, defaultValue, true);
      setDefaultOption(selected);
      setValue(controllerName, (selected as any)?.value);
      setValue("counterpartyShortName", (selected as any)?.shortName || "");
    }
  }, [options, defaultValue, loading]);

  return (
    <>
      <ControlledSelect
        required={required}
        name={controllerName}
        label="Counterparty"
        options={options}
        isLoading={loading}
        disabled={disabled}
        rules={rules}
        mobileBasis="50%"
        defaultValue={defaultOption}
        inputId="counterparty-selection-id"
        onChange={(e) => setValue(controllerName, e, { shouldDirty: true })}
      />
      <ControlledEmpty defaultValue="" name="counterpartyShortName" />
      <ControlledEmpty defaultValue="" name="destinationFixName" />
    </>
  );
};

export default CounterpartySelection;
