import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import {
  PWB_EVENTS_ACTIONS,
  usePortfolio,
} from "@app-context/portfolios/PortfolioProvider";
import { PWB_EVENTS, styled, useThisTab } from "@enfusion-ui/web-core";
import { ICellRendererParams } from "ag-grid-enterprise";
import * as React from "react";

import { HighlightedInputCell } from "../HighlightedInputCell";
import { useRowData } from "../utils";

const Container = styled.div`
  width: 100%;
`;

export const NumericInputCellRenderer: React.FC<
  ICellRendererParams & {
    useValue?: boolean;
    cellStyle?: React.CSSProperties;
    placeholder?: string;
    allowGroup?: boolean;
  }
> = React.memo((params) => {
  const {
    valueFormatted,
    useValue = false,
    cellStyle = {},
    colDef,
    node,
    placeholder = "",
    allowGroup = true,
  } = params;

  const { config } = useThisTab();
  const { changedFields, getTouched, getParentRows } = usePortfolio();
  const changeState = React.useState(0);

  useBroadcastChannel(PWB_EVENTS, (msg) => {
    if (
      msg.id === config.id &&
      msg.action === PWB_EVENTS_ACTIONS.LockedRowsChanged
    ) {
      // force a rerender
      requestAnimationFrame(() => {
        changeState[1]((c) => (c > 10 ? 0 : c + 1));
      });
    }
  });

  const rowId = useRowData(node, getParentRows, "rowId", true);

  if (!allowGroup && node.group) return null;

  const field = colDef?.colId!;
  const updated = changedFields[rowId]?.includes(field);
  const touched = !useValue && getTouched(rowId, field);

  return (
    <Container style={node.isSelected() ? { cursor: "not-allowed" } : {}}>
      <HighlightedInputCell
        hideLabel
        disabled={node.isSelected()}
        minWidth="50px"
        updated={updated}
        touched={touched}
        cellStyle={cellStyle}
        placeholder={placeholder}
        value={valueFormatted ?? ""}
      />
    </Container>
  );
});
