import { styled } from "@enfusion-ui/web-core";
import { FontAwesomeIcon as DefaultIcon } from "@fortawesome/react-fontawesome";

export const WatchListItemContainer = styled.div<{ widgetMode?: boolean }>`
  display: flex;
  justify-content: space-between;
  column-gap: var(--spacing);
  align-items: center;
  width: ${({ widgetMode }) => (widgetMode ? "100%" : "32rem")};
  height: ${({ widgetMode }) => (widgetMode ? "100%" : "6rem")};
  padding: var(--spacing-xl) var(--spacing-l);
  margin-bottom: var(--spacing);
  border-bottom-width: 1px;
  border-color: var(--border);
  border-radius: 4px;
  background-color: var(--background-color-0);

  :hover {
    background-color: var(--background-color-1);
  }
`;

export const ListItemContent = styled.div`
  width: 100%;
`;

export const ListItemTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
`;

export const ListItemValuesContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const ValueSection = styled.div<{ width?: string }>`
  width: ${({ width }) => width || "33.33%"};
  margin: var(--spacing) 2px;
`;

export const BoldText = styled.div`
  font-weight: 700;
  cursor: pointer;
`;

export const ValueText = styled.div``;

export const LabelText = styled.div`
  color: ${({ theme }) => theme.colors.textColor1};
  margin-right: var(--spacing-s);
`;

export const ValueContainer = styled.div<{ clickable?: boolean }>`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin: var(--spacing) 0 0;

  ${({ clickable }) =>
    clickable
      ? `
      cursor: pointer;
      :hover {
        font-weight: bold;
      }
    `
      : null};
`;

export const ArrowIcon = styled(DefaultIcon)`
  margin: 4px 8px;
  cursor: pointer;
`;
