export const data = {
  syntax: {
    title: "Syntax Summary",
    description:
      "Once a formula is entered, the system will leverage the built in calculators to calculate the appropriate quantity and optionally select price and execution strategy.",
    examples: [
      {
        input: "b 200 @12.0",
        description: "Buy 200 shares at a limit price of 12.0",
      },
      {
        input: "bc 200k @12.0",
        description: "Buy To Cover 200,000 shares with price limit of 12.0",
      },
      {
        input: "ss 200k",
        description: "Sell Short 200,000 shares",
      },
      {
        input: "fq -200k",
        description: "Set Final Quantity to -200,000 shares",
      },
      {
        input: "iq 200k",
        description: "Increment Long/Short position by 200,000 shares",
      },
      {
        input: "iq -200k",
        description: "Decrement Long/Short position by 200,000 shares",
      },
      {
        input: "in $200k",
        description:
          "Increment Long/Short position by the notional of 200,000 (Book CCY)&nbsp;",
      },
      {
        input: "in -$200k",
        description:
          "Decrement Long/Short position by the notional of 200,000 (Book CCY)&nbsp;",
      },
      {
        input: "s $2m a",
        description:
          "Sell 2 million notional denominated in the base currency of the fund. Use calculated allocations to populate the allocation section in the order ticker.",
      },
      {
        input: "+2",
        description: "Increment position by 2 basis points",
      },
      {
        input: '-2% s="MSVWAP"',
        description: "Decrement position by 2%. Use 'MSVWAP' as route",
      },
      {
        input: "to 2% @13.0",
        description: "Set position to 2% of NAV with limit price of 13.0",
      },
      {
        input: "+2% s=ctrl+space",
        description:
          "Increment position by 2%. Select hot button from available routes",
      },
    ],
  },
  categories: {
    title: "Formula Categories",
    sections: [
      {
        title: "Common Specification Terms",
        content: [
          `The following specifications are common across certain categories. They are described in the following section to avoid repetition. Any specification with a following "*" is considered optional.&nbsp;`,
          [
            "<em>&lt;limit specification&gt;</em>*: &nbsp;Allows for specifying an execution price limit. It is an optional field in all formulas. It follows @limit_price pattern. For example. @14.0 will set the order limit price to 14.0. All calculations will also use the specified limit price rather than the current market price.",
            `<em>&lt;allocation specification&gt;</em>*: &nbsp;The letter "a". In cases where a user wishes to use the allocations as calculated by the system, specifying the letter "a" will cause the allocations on the order ticket to be updated.`,
            `<em>&lt;strategy specification&gt;</em>*: &nbsp;This is specified by using 's="Strategy Name"'. The name must be one that appears on the order ticket as a hot button. A user does not need to manually type in the name. Hitting the Ctrl-Space key after the "s=" will popup a window to choose a strategy from.`,
          ],
        ],
      },
      {
        title: "Explicit Share Quantity Specification",
        content: [
          [
            `Input Format: &nbsp;<em>&lt;buy/sell indicator&gt; &lt;quantity specification&gt; &lt;limit specification&gt;* &lt;strategy specification&gt;*</em>`,
            `Input Format 2: &nbsp;<em>&lt;fq&gt; &lt;signed quantity specification&gt; &lt;limit specification&gt; &lt;strategy specification&gt;</em>`,
            `Input Format 3: &nbsp;<em>&lt;iq&gt; &lt;signed quantity specification&gt; &lt;limit specification&gt; &lt;strategy specification&gt;</em>`,
            `Input Format 4: &nbsp;<em>&lt;in&gt; &lt;signed money quantity specification&gt; &lt;limit specification&gt;* &lt;strategy specification&gt;*</em>`,
            `<em>&lt;buy/sell indicator&gt;</em>: &nbsp;Valid values are &lsquo;b&rsquo;, &lsquo;s&rsquo;, &lsquo;ss&rsquo; and &lsquo;bc&rsquo; which correspond to &ldquo;Buy&rdquo;, &ldquo;Sell&rdquo;, &ldquo;Sell Short&rdquo; and &ldquo;Buy to Cover&rdquo; respectively.`,
            `<em>&lt;fq indicator&gt;</em>: &nbsp;Valid value is &lsquo;fq&rsquo; which corresponds to &ldquo;Final Quantity&rdquo;.`,
            `<em>&lt;iq indicator&gt;</em>: &nbsp;Valid value is &lsquo;iq&rsquo; which corresponds to &ldquo;Incremental Quantity&rdquo;.`,
            `<em>&lt;in indicator&gt;</em>: &nbsp;Valid value is &lsquo;in&rsquo; which corresponds to &ldquo;Incremental Notional&rdquo;.`,
            `<em>&lt;quantity specification&gt;</em>: &nbsp;Number of shares with an optional suffix multiplier. Valid multipliers are &lsquo;t&rsquo;, &lsquo;k&rsquo;, &lsquo;m&rsquo;, &lsquo;b&rsquo;. Number of shares is adjusted by the multiplier. &ldquo;Thousand&rdquo;, &ldquo;Thousand&rdquo;, &ldquo;Million&rdquo; and &ldquo;Billion&rdquo;.&nbsp;`,
          ],
        ],
      },
      {
        title: "Exposure Specification",
        content: [
          [
            `Input Format: &nbsp;<em>&lt;buy/sell indicator&gt; &lt;money quantity specification&gt; &lt;limit specification&gt;*&nbsp;</em><em>&lt;<em>allocation specification</em>&gt;</em>*&nbsp;<em>&lt;strategy specification&gt;*</em>`,
            `<em>&lt;buy/sell indicator&gt;</em>: &nbsp;Valid values are &lsquo;b&rsquo;, &lsquo;s&rsquo;, &lsquo;ss&rsquo; and &lsquo;bc&rsquo; which correspond to &ldquo;Buy&rdquo;, &ldquo;Sell&rdquo;, &ldquo;Sell Short&rdquo; and &ldquo;Buy to Cover&rdquo; respectively.`,
            `<em>&lt;money quantity specification&gt;</em>:&nbsp; Amount of cash <u>in the fund's base currency</u> that will be converted into the number of shares. The '$' is used as an indicator of money in the base currency of the funds (e.g. For funds denominated in EUR, '$2m' equates to buying 2 million EUR of the security specified. The base currency cash amount is FX'd at the prevailing FX rate into the cash equivalent of the instrument's native currency to perform the calculation. An optional suffix multiplier can also be specified. Valid multipliers are &lsquo;t&rsquo;, &lsquo;k&rsquo;, &lsquo;m&rsquo;, &lsquo;b&rsquo;. The money amount is adjusted by the multiplier. &ldquo;Thousand&rdquo;, &ldquo;Thousand&rdquo;, &ldquo;Million&rdquo; and &ldquo;Billion&rdquo;.&nbsp;`,
          ],
        ],
      },
      {
        title: "Incremental Position Adjustment Category",
        content: [
          [
            `Input Format: &nbsp;<em>&lt;+/-&gt; &lt;incremental specification&gt; &lt;limit specification&gt;*&nbsp;</em><em>&lt;<em>allocation specification</em>&gt;</em>*&nbsp;<em>&lt;strategy specification&gt;*</em></span>`,
            `<em>&lt;+/-&gt;</em>:&nbsp; A &ldquo;+&rdquo; will increase a position by a specific amount. A &ldquo;-&rdquo; will decrease a position by a specific amount.`,
            `<em>&lt;incremental specification&gt;</em>: &nbsp;This number is specified in either basis points or percent. For example +1 will increase a position by 1 basis point. +1% will increase the position by 100 basis points.&nbsp;`,
          ],
        ],
      },
      {
        title: "Final Target Adjustment Category",
        content: [
          [
            `Format: &nbsp;<em>&lt;target specification&gt; &lt;limit specification&gt;*&nbsp;</em><em>&lt;a<em>llocation specification</em>&gt;</em>*&nbsp;<em>&lt;strategy specification&gt;*</em>`,
            `<em>&lt;target specification&gt;</em>: &nbsp;<em>to Number.&nbsp;</em>This number is specified in either basis points or percent. For example "to 1" will set the final position to 1 basis point as a percent of NAV. To liquidate a position, a simple entry of "<em>to 0"</em> will do.`,
          ],
        ],
      },
    ],
  },
};
