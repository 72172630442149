import { useRefCallback } from "@enfusion-ui/hooks";
import {
  DateRangeSymbol,
  WebDateRangeSymbolSelection,
} from "@enfusion-ui/types";
import { SEMI_CUSTOM_RANGE_SYMBOLS } from "@enfusion-ui/utils";
import { DateRangePicker, Select } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { format, parse } from "date-fns";
import * as React from "react";

import {
  chicagoTimeZone,
  DateRangePresetEval,
  generalDateFormat,
  getDateRange,
  SYMBOL_RANGE_OPTIONS,
} from "./utils";

const getOption = (value: string | null | undefined) => {
  if (typeof value === "string") {
    return SYMBOL_RANGE_OPTIONS.find((i) => i.value === value);
  }
};

const Container = styled.div`
  display: flex;
  flex: 1 1 0;
  min-width: 300px;
  grid-gap: var(--spacing-l);
`;

const SelectWrapper = styled.div`
  width: 200px;
  align-self: flex-end;
`;

const DateRangeSymbolSelectionInput: React.FC<{
  label?: string;
  value?: WebDateRangeSymbolSelection;
  timezone?: string;
  clearable?: boolean;
  onChange: (value?: WebDateRangeSymbolSelection | null) => void;
  datePickerWidth?: number;
}> = ({
  label,
  value,
  onChange,
  clearable,
  timezone = chicagoTimeZone,
  datePickerWidth,
}) => {
  const [defaultDateRange, setDefaultDateRange] = React.useState<
    [Date | null, Date | null]
  >([null, null]);

  const [selectedSymbol, setSelectedSymbol] = React.useState<
    { label: string; value: string } | undefined
  >();

  const webDateRangeSymbolSelection =
    React.useRef<WebDateRangeSymbolSelection | null>(null);

  React.useEffect(() => {
    if (value) {
      var dateRange = value.symbol
        ? (DateRangePresetEval[
            value.symbol as unknown as keyof typeof DateRangePresetEval
          ](timezone) as [Date | null, Date | null])
        : [null, null];
      const startDate = value.fromDate
        ? parse(value.fromDate, generalDateFormat, new Date())
        : dateRange[0];
      const endDate = value.toDate
        ? parse(value.toDate, generalDateFormat, new Date())
        : dateRange[1];
      webDateRangeSymbolSelection.current = value
        ? {
            symbol: value.symbol,
            fromDate: startDate
              ? format(startDate, generalDateFormat)
              : undefined,
            toDate: endDate ? format(endDate, generalDateFormat) : undefined,
          }
        : null;
      setDefaultDateRange([startDate, endDate]);
      setSelectedSymbol(getOption(value.symbol));
    }
  }, [JSON.stringify(value)]);

  const handleSymbolChange = useRefCallback(
    (symbol: { label: string; value: string } | null) => {
      if (symbol?.value) {
        const dateRange = getDateRange(
          symbol.value as unknown as keyof typeof DateRangePresetEval,
          generalDateFormat,
          timezone
        );
        webDateRangeSymbolSelection.current = {
          symbol: symbol.value as unknown as DateRangeSymbol,
          fromDate: undefined,
          toDate: undefined,
        };
        const startDate =
          dateRange[0] && parse(dateRange[0], generalDateFormat, new Date());
        const endDate =
          dateRange[1] && parse(dateRange[1], generalDateFormat, new Date());
        setDefaultDateRange([startDate || null, endDate || null]);
        onChange(webDateRangeSymbolSelection.current);
      } else {
        setDefaultDateRange([null, null]);
        onChange(null);
      }
    },
    []
  );

  const handleDateChange = useRefCallback(
    (dateRange: [Date | null, Date | null]) => {
      const endDate = dateRange[1]
        ? format(dateRange[1], generalDateFormat)
        : undefined;
      const symbol =
        webDateRangeSymbolSelection.current?.symbol ||
        (selectedSymbol?.value as unknown as DateRangeSymbol);

      const updateSymbol =
        !SEMI_CUSTOM_RANGE_SYMBOLS.includes(symbol) ||
        endDate !== webDateRangeSymbolSelection.current?.toDate;

      webDateRangeSymbolSelection.current = {
        symbol: updateSymbol ? "Custom" : symbol,
        fromDate: dateRange[0]
          ? format(dateRange[0], generalDateFormat)
          : undefined,
        toDate: updateSymbol ? endDate : undefined,
      };
      onChange(webDateRangeSymbolSelection.current);
    },
    [onChange, selectedSymbol, webDateRangeSymbolSelection.current]
  );

  return (
    <Container>
      <SelectWrapper>
        <Select
          label={label}
          value={selectedSymbol}
          onChange={handleSymbolChange}
          options={SYMBOL_RANGE_OPTIONS}
          minWidth={130}
          clearable={clearable}
          defaultValue={selectedSymbol}
          inputId="data-range-symbol-selection-id"
        />
      </SelectWrapper>
      <DateRangePicker
        startLabel="From"
        startName="From"
        endLabel="To"
        endName="To"
        minWidth={`${datePickerWidth}px` ?? "200px"}
        onChange={handleDateChange}
        defaultValue={defaultDateRange}
      />
    </Container>
  );
};

export default DateRangeSymbolSelectionInput;
