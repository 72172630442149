import { useReconciliation } from "@app-context/reconciliation/context";
import { useReportRows } from "@app-context/reports/ReportsProvider";
import {
  PaddedSectionContainer,
  TopPanelContainer,
  ValueHighlightChange,
  ValueHighlightChangePercentage,
  ValueHighlightContainer,
  ValueHighlightContent,
  ValueHighlightContentWrapper,
  ValueHighlightLabel,
} from "@app-views/reconciliation/components/styles";
import { ValueHighlightProps } from "@app-views/reconciliation/types";
import { SummaryReportConfig } from "@enfusion-ui/types";
import { formatCurrency } from "@enfusion-ui/utils";
import { FormPanel, Spinner } from "@enfusion-ui/web-components";
import { ReportRowDataEntry, useReports } from "@enfusion-ui/web-core";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const getTotalValue = (rows: ReportRowDataEntry[], column: string) => {
  return rows?.reduce(
    (res, row) => {
      const value = row[column]?.value;
      const currency = row[column]?.currency ?? res.currency;
      if (!value || typeof value !== "number") return { ...res, currency };
      return { value: (res.value ?? 0) + value, currency };
    },
    { value: null, currency: "" } as { value: number | null; currency: string }
  );
};

export const ValueHighlight: React.FC<ValueHighlightProps> = ({
  label,
  value,
  change,
  loading,
  negative,
}) => {
  return (
    <ValueHighlightContainer>
      <ValueHighlightLabel>{label}</ValueHighlightLabel>
      <ValueHighlightContentWrapper>
        {loading ? (
          <Spinner size="lg" />
        ) : (
          <ValueHighlightContent>
            <ValueHighlightChange negative={negative}>
              {value === "" ? "-" : value}
            </ValueHighlightChange>
            {change && (
              <ValueHighlightChangePercentage negative={negative}>
                {change}%
                <FontAwesomeIcon
                  icon={negative ? faCaretDown : faCaretUp}
                  style={{
                    marginLeft: "var(--spacing-s)",
                  }}
                />
              </ValueHighlightChangePercentage>
            )}
          </ValueHighlightContent>
        )}
      </ValueHighlightContentWrapper>
    </ValueHighlightContainer>
  );
};

const ReconciliationTopPanelContent: React.FC = () => {
  const { rowsStore } = useReportRows();
  const { metaStore } = useReports();
  const { config } = useReconciliation();

  return (
    <PaddedSectionContainer height={72}>
      <TopPanelContainer>
        {config.summaryReports.map(
          (report: SummaryReportConfig, idx: number) => {
            const totalsRow =
              rowsStore?.[report.summaryReportId]?.totalsRow?.[
                report?.column ?? ""
              ];
            const totalsValue =
              !report.column ||
              (typeof totalsRow?.value === "number" && !!totalsRow?.currency)
                ? null
                : getTotalValue(
                    rowsStore?.[report.summaryReportId]?.rows,
                    report.column
                  );
            const value = totalsRow?.value ?? totalsValue?.value ?? null;
            const currency = totalsRow?.currency ?? totalsValue?.currency ?? "";
            return (
              <ValueHighlight
                key={idx}
                label={report.summaryReportId}
                value={
                  typeof value === "number"
                    ? formatCurrency(value, currency, {
                        fractionDigits: 2,
                        reduce: true,
                        formatNegative: false,
                      })
                    : "-"
                }
                loading={metaStore[report.summaryReportId]?.loading ?? false}
                negative={typeof value === "number" && value < 0}
              />
            );
          }
        )}
      </TopPanelContainer>
    </PaddedSectionContainer>
  );
};

const TopSummaryPanel = () => {
  return (
    <FormPanel
      lineStyle
      collapsible
      keepRendered
      numColumns={1}
      defaultOpen
      title="Summary"
      showTextCollapseButton
      gapSize="l"
      titleStyle={{ padding: "0 var(--spacing-l)" }}
      thinContainer
      render={() => <ReconciliationTopPanelContent />}
    />
  );
};

export default TopSummaryPanel;
