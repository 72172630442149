import { ReportSettingsDefinition } from "../../types";
import TradeBlotterQuickFilter from "./TradeBlotterQuickFilter";
import TradeBlotterSettings from "./TradeBlotterSettings";

export const WebTradeBlotterQuery: ReportSettingsDefinition = {
  name: "TradeBlotter",
  queryType: "WebTradeBlotterQuery",
  quickFilterComponent: TradeBlotterQuickFilter,
  settingsComponent: TradeBlotterSettings,
};
