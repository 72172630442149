import { useRefCallback } from "@enfusion-ui/hooks";
import {
  LOT_TYPE_OPTIONS,
  LotType,
  SelectOptionsType,
} from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import * as React from "react";

export type LotTypesSelectProps = Omit<
  Partial<MultiSelectProps<LotType>>,
  "options" | "value" | "onChange"
> & {
  value?: LotType[] | null;
  onChange?: (values: LotType[]) => void;
};

const LotTypeSelect: React.FC<LotTypesSelectProps> = ({
  value: values,
  onChange,
  placeholder = "Any",
  label = "Lot Types",
  ...rest
}) => {
  const [selectedLotTypes, setSelectedLotTypes] = React.useState<
    LotType[] | null
  >([]);

  React.useEffect(() => {
    values && setSelectedLotTypes(values);
  }, [values]);

  const handleChange = useRefCallback(
    (values: SelectOptionsType<LotType>[]) => {
      const newLotTypes = values?.length
        ? values.map((opt: SelectOptionsType<LotType>) => opt?.value)
        : [];
      setSelectedLotTypes(newLotTypes);
      onChange?.(newLotTypes);
    },
    [onChange]
  );

  return (
    <MultiSelect
      {...rest}
      value={
        selectedLotTypes
          ? getSelectedValues(selectedLotTypes, LOT_TYPE_OPTIONS)
          : []
      }
      onChange={(selectedOptions) =>
        handleChange(selectedOptions as SelectOptionsType<LotType>[])
      }
      label={label}
      placeholder={placeholder}
      options={LOT_TYPE_OPTIONS}
    />
  );
};

export const ControlledLotTypeSelect: React.FC<
  Omit<LotTypesSelectProps, "value" | "onChange"> & {
    name?: string;
  }
> = ({ name = "tradeTypes", ...props }) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...renderProps }) => (
        <LotTypeSelect {...renderProps} {...props} />
      )}
    />
  );
};

export default LotTypeSelect;
