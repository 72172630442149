import { useRefCallback } from "@enfusion-ui/hooks";
import {
  FilterColumns,
  ImpactColumns,
  ReconReportConfig,
  WebReconciliationReportMetadata,
} from "@enfusion-ui/types";
import {
  Button,
  ControlledSelect,
  FormSectionAccordion,
  Modal,
  useConfirmationModal,
} from "@enfusion-ui/web-components";
import { errorToast, successToast } from "@enfusion-ui/web-core";
import { isEmpty } from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { ReconciliationMetaData } from "../types";
import {
  getCashActivityReportId,
  getCashBalanceReportId,
  getColumnOptions,
  getReportId,
  isCashDataSource,
  isCurrencyColumn,
  isInstrumentColumn,
} from "../utils/commonUtils";
import {
  InputsContainer,
  InputWrapper,
  ReconciliationSettingsModalContainer,
  RowSection,
} from "./styles";

type SettingsModalProps = {
  isOpen: boolean;
  closeModal: VoidFunction;
  initialFilterConfig: ReconReportConfig;
  handleReportSettingsApply: (props: SelectedColumns) => void;
  metadata?: ReconciliationMetaData;
};

type SelectedColumns = FilterColumns & ImpactColumns;

const hasEmptyMandatoryFilterFields = (selectedColumns?: SelectedColumns) => {
  if (
    isEmpty(
      selectedColumns?.currency.source ||
        isEmpty(selectedColumns?.currency.target)
    )
  )
    return true;
  return false;
};

export const ReconciliationReportSettingsModal: React.FC<
  SettingsModalProps
> = ({
  isOpen,
  closeModal,
  initialFilterConfig,
  metadata,
  handleReportSettingsApply,
}) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] =
    React.useState<boolean>(false);
  const formMethods = useForm<SelectedColumns>();

  const { reset, getValues, formState } = formMethods;

  React.useEffect(() => {
    if (isOpen)
      reset({
        ...initialFilterConfig.filterColumns,
        ...initialFilterConfig.impactColumns,
      });
  }, [isOpen, initialFilterConfig]);

  const { reconReportId: selectedRowId, reconciliationType } =
    initialFilterConfig ?? {};

  const UnsavedChangesModal = useConfirmationModal({
    onSubmit: () => {
      UnsavedChangesModal.closeModal();
      closeModal();
    },
    onCancel: () => {
      UnsavedChangesModal.closeModal();
    },
    allowDismissal: false,
    title: "Cancel Changes?",
    submitButtonText: "Confirm",
    submitActionTheme: "danger",
    cancelButtonText: "Cancel",
    renderContent: () =>
      "All the unapplied changes will be lost. Do you want to cancel the changes?",
  });

  const handleApply = useRefCallback(async () => {
    const formValues = getValues();
    if (hasEmptyMandatoryFilterFields(formValues)) {
      errorToast("Please fill all the mandatory fields");
      return;
    }
    handleReportSettingsApply(formValues);
    successToast("Changes Saved Successfully");
    closeModal();
  }, [selectedRowId, getValues]);

  const handleClose = useRefCallback(() => {
    if (hasUnsavedChanges) {
      UnsavedChangesModal.openModal();
    } else {
      closeModal();
    }
  }, [hasUnsavedChanges]);

  const getBreakColumnOptions = useRefCallback(() => {
    const gridId = getReportId(selectedRowId, reconciliationType);
    const { defs, metadata: columnsMetaData } = metadata?.[gridId] ?? {};

    const diffColumnPairs = (
      columnsMetaData as WebReconciliationReportMetadata
    )?.diffColumnPairs.map((value) => value.reconColumnId);

    return getColumnOptions(defs).filter((i) =>
      diffColumnPairs?.includes(i.value)
    );
  }, [
    getColumnOptions,
    getCashActivityReportId,
    metadata,
    selectedRowId,
    reconciliationType,
  ]);

  const getCurrencyColumnOptions = useRefCallback(
    (gridId: string) => {
      const { defs } = metadata?.[gridId] ?? {};
      return getColumnOptions(defs, "standardColumn").filter((i) =>
        isCurrencyColumn(i.label)
      );
    },
    [metadata, selectedRowId, reconciliationType, getColumnOptions]
  );

  const getInstrumentColumnOptions = useRefCallback(() => {
    const gridId = getReportId(selectedRowId, reconciliationType);
    const { defs } = metadata?.[gridId] ?? {};
    return getColumnOptions(defs).filter((i) => isInstrumentColumn(i.label));
  }, [metadata, selectedRowId, reconciliationType, getColumnOptions]);

  const getServicesAccountNamesColumnOptions = useRefCallback(() => {
    const gridId = getReportId(selectedRowId, reconciliationType);
    const { defs } = metadata?.[gridId] ?? {};
    return getColumnOptions(defs).filter((i) =>
      i.value.includes("servicesAccountName")
    );
  }, [metadata, selectedRowId, reconciliationType, getColumnOptions]);

  React.useEffect(() => {
    setHasUnsavedChanges(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <>
      <Modal
        title="Reconciliation Filters Settings"
        isOpen={isOpen}
        onClose={handleClose}
        content={
          <FormProvider {...formMethods}>
            <ReconciliationSettingsModalContainer>
              <FormSectionAccordion title="Impact Columns">
                <InputsContainer>
                  <InputWrapper>
                    <ControlledSelect
                      name="breakImpact"
                      label="Break Impact Column"
                      options={getBreakColumnOptions()}
                    />
                  </InputWrapper>

                  {isCashDataSource(reconciliationType) && (
                    <>
                      <InputWrapper>
                        <ControlledSelect
                          name="cashBalance"
                          label="Cash Balance Column"
                          options={
                            getColumnOptions(
                              metadata?.[getCashBalanceReportId(selectedRowId)]
                                ?.defs,
                              "numberColumn"
                            ) ?? []
                          }
                        />
                      </InputWrapper>
                      <RowSection gap="xl">
                        <InputWrapper>
                          <ControlledSelect
                            name="cashBalanceCurrency.source"
                            label="Source Cash Balance Currency"
                            options={getCurrencyColumnOptions(
                              getCashBalanceReportId(selectedRowId)
                            )}
                            required
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <ControlledSelect
                            name="cashBalanceCurrency.target"
                            label="Target Cash Balance Currency"
                            options={getCurrencyColumnOptions(
                              getCashBalanceReportId(selectedRowId)
                            )}
                            required
                          />
                        </InputWrapper>
                      </RowSection>
                    </>
                  )}
                </InputsContainer>
              </FormSectionAccordion>
              <FormSectionAccordion title="Filter Columns">
                <InputsContainer>
                  <RowSection gap="xl">
                    <InputWrapper>
                      <ControlledSelect
                        name="currency.source"
                        label={`Source ${
                          isCashDataSource(reconciliationType)
                            ? "Cash Activity"
                            : ""
                        } Currency`}
                        options={getCurrencyColumnOptions(
                          getReportId(selectedRowId, reconciliationType)
                        )}
                        required
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <ControlledSelect
                        name="currency.target"
                        label={`Target ${
                          isCashDataSource(reconciliationType)
                            ? "Cash Activity"
                            : ""
                        } Currency`}
                        options={getCurrencyColumnOptions(
                          getReportId(selectedRowId, reconciliationType)
                        )}
                        required
                      />
                    </InputWrapper>
                  </RowSection>
                  {!isCashDataSource(reconciliationType) && (
                    <RowSection gap="xl">
                      <InputWrapper>
                        <ControlledSelect
                          name="instrument.source"
                          label="Source Instrument"
                          options={getInstrumentColumnOptions()}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <ControlledSelect
                          name="instrument.target"
                          label="Target Instrument"
                          options={getInstrumentColumnOptions()}
                        />
                      </InputWrapper>
                    </RowSection>
                  )}
                  <RowSection>
                    <InputWrapper>
                      <ControlledSelect
                        name="serviceAccount.source"
                        label="Source Service Account Name"
                        options={getServicesAccountNamesColumnOptions()}
                        clearable={false}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <ControlledSelect
                        name="serviceAccount.target"
                        label="Target Service Account Name"
                        options={getServicesAccountNamesColumnOptions()}
                        clearable={false}
                      />
                    </InputWrapper>
                  </RowSection>
                </InputsContainer>
              </FormSectionAccordion>
            </ReconciliationSettingsModalContainer>
          </FormProvider>
        }
        footerContent={
          <RowSection gap="xl">
            <Button
              style={{
                backgroundColor: `var(--${
                  hasUnsavedChanges ? "primary" : "background-color-2"
                })`,
              }}
              onClick={handleApply}
              disabled={!hasUnsavedChanges}
            >
              Save
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </RowSection>
        }
      />
      {UnsavedChangesModal.content}
    </>
  );
};
