import { Button, Modal } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentMessage = styled.div`
  margin-bottom: var(--spacing-l);
`;

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
`;

export type CloseTabWithUnsavedChangeModalProps = {
  open: boolean;
  onSaveClose: VoidFunction;
  onDiscardClose: VoidFunction;
  onCancel: VoidFunction;
};

const CloseTabWithUnsavedChangeModal: React.FC<
  CloseTabWithUnsavedChangeModalProps
> = ({ open, onSaveClose, onDiscardClose, onCancel }) => {
  return (
    <Modal
      isOpen={open}
      onClose={onCancel}
      title="Close Portfolio?"
      maxWidth={450}
      content={
        <ContentContainer>
          <ContentMessage>
            You have unsaved changes. Are you sure you want to close the current
            portfolio without saving the changes?
          </ContentMessage>
          <ActionContainer>
            <Button
              data-testid="btn-save"
              theme="primary"
              onClick={onSaveClose}
            >
              Save & Close
            </Button>
            <Button
              data-testid="btn-discard"
              theme="danger"
              onClick={onDiscardClose}
            >
              Discard & Close
            </Button>
            <Button
              data-testid="btn-cancel"
              theme="secondary"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </ActionContainer>
        </ContentContainer>
      }
    />
  );
};

export default CloseTabWithUnsavedChangeModal;
