import { OPTIONS } from "@app-views/oems/components/VoiceDetailEntry";
import {
  ControlledSelect,
  ControlledTextInput,
} from "@enfusion-ui/web-components";
import { styled, useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";

const GridWrapper = styled.div`
  border: 1px solid var(--primary);
  border-radius: var(--radius-l);
`;

const InlineGrids = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: var(--background-color-0);
  border-bottom-left-radius: var(--radius-l);
  border-bottom-right-radius: var(--radius-l);
  padding: var(--spacing-xl);
  gap: var(--spacing-xl);
`;

const MobileInlineGrids = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
  background-color: var(--background-color-0);
  border-bottom-left-radius: var(--radius-l);
  border-bottom-right-radius: var(--radius-l);
  padding: var(--spacing-xl);
`;

const GridHeader = styled.div`
  background-color: var(--background-color-2);
  color: var(--primary);
  border-top-left-radius: var(--radius-l);
  border-top-right-radius: var(--radius-l);
  padding: var(--spacing-l) var(--spacing-xl);
`;

const DesktopGridContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileLayout: React.FC = () => {
  return (
    <GridWrapper>
      <GridHeader>Execution Summary</GridHeader>
      <MobileInlineGrids>
        <ControlledTextInput
          readOnly
          name="filledQty"
          label="Filled Quantity"
        />
        <ControlledTextInput
          readOnly
          name="tradeReportingIndicator"
          label="Trade Report Ind"
        />
        <ControlledTextInput
          readOnly
          name="lastStatusText"
          label="Status Text"
        />
        <ControlledSelect
          label="Status"
          disabled
          name="orderStatus"
          options={OPTIONS.orderStatus}
          inputId="execution-summary-order-status-selection-id"
        />
        <ControlledTextInput
          readOnly
          name="lastExecutionUpdateDateTime"
          label="Last Update"
        />
        <ControlledTextInput readOnly name="openQty" label="Open Quantity" />
        <ControlledTextInput readOnly name="avgPrice" label="Average Price" />
      </MobileInlineGrids>
    </GridWrapper>
  );
};

const DesktopLayout: React.FC = () => {
  return (
    <GridWrapper>
      <GridHeader>Execution Summary</GridHeader>
      <InlineGrids>
        <DesktopGridContent>
          <div>
            <ControlledTextInput
              readOnly
              name="filledQty"
              label="Filled Quantity"
            />
            <ControlledTextInput
              readOnly
              name="tradeReportingIndicator"
              label="Trade Report Ind"
            />
            <ControlledTextInput
              readOnly
              name="lastStatusText"
              label="Status Text"
            />
          </div>
        </DesktopGridContent>
        <DesktopGridContent>
          <div>
            <ControlledSelect
              label="Status"
              disabled
              name="orderStatus"
              options={OPTIONS.orderStatus}
              inputId="execution-summary-order-status-selection-id"
            />
            <ControlledTextInput
              readOnly
              name="lastExecutionUpdateDateTime"
              label="Last Update"
            />
            <ControlledTextInput
              readOnly
              name="openQty"
              label="Open Quantity"
            />
            <ControlledTextInput
              readOnly
              name="avgPrice"
              label="Average Price"
            />
          </div>
        </DesktopGridContent>
      </InlineGrids>
    </GridWrapper>
  );
};

const ExecutionSummaryPanel: React.FC<unknown> = () => {
  const isMobile = useIsContentMobile();

  return isMobile ? <MobileLayout /> : <DesktopLayout />;
};

export default ExecutionSummaryPanel;
