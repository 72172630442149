/* eslint-disable @typescript-eslint/no-explicit-any */
// import queryString from "query-string";
// import "highcharts/css/highcharts.css";
import "highcharts/css/stocktools/gui.css";
import "highcharts/css/annotations/popup.css";

import { ThemeDefinition } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { AppEvent, AppEventCategories } from "@enfusion-ui/types";
import { chicagoTimeZone, formatNumber, nowZoned } from "@enfusion-ui/utils";
import {
  CenterContent,
  ContentMessage,
  ErrorBoundary,
  NumericInput,
  Spinner,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  FULLSTORY_ENABLED,
  styled,
  ThemeProvider,
} from "@enfusion-ui/web-core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import * as FullStory from "@fullstory/browser";
import Highcharts, { XAxisOptions, YAxisOptions } from "highcharts/highstock";
import Indicators from "highcharts/indicators/indicators-all.js";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced.js";
import DragPanes from "highcharts/modules/drag-panes.js";
import FullScreen from "highcharts/modules/full-screen.js";
import Heikinashi from "highcharts/modules/heikinashi";
import HollowCandleStick from "highcharts/modules/hollowcandlestick";
// import PriceIndicator from "highcharts/modules/price-indicator.js";
import StockTools from "highcharts/modules/stock-tools.js";
import { debounce, last, noop, omit, pick, sortBy, uniqueId } from "lodash";
import * as React from "react";
import { useMount } from "react-use";

import {
  HighchartsReact,
  HighchartsReactRef,
} from "./EmbedCharting/HighchartsReact";
import addPriceIndicator from "./EmbedCharting/priceIndicator";

// init the module
Indicators(Highcharts);
DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
addPriceIndicator(Highcharts);
FullScreen(Highcharts);
Heikinashi(Highcharts);
HollowCandleStick(Highcharts);
StockTools(Highcharts);

declare const window: any;

function safeError(msg: string, err?: Error | unknown) {
  AppLogging.remoteOnly.event(
    {
      event: AppEvent.CaughtError,
      category: AppEventCategories.EmbedCharting,
    },
    {
      msg,
      errorMessage: (err as any)?.message || "",
      stack: (err as any)?.stack || "",
    }
  );
  AppLogging.localOnly.safeError(msg, err);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onLookbackChange = debounce(function onLookbackChange(value: number) {
  AppLogging.localOnly.log(`handleLookbackChange: ${value}`);
  window.handleLookbackChange?.(value);
}, 800);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onBucketSizeChange = debounce(function onBucketSizeChange(value: number) {
  AppLogging.localOnly.log(`handleBucketSizeChange: ${value}`);
  window.handleBucketSizeChange?.(value);
}, 800);

// #region styled components
const GraphInnerContainer = styled.div<{
  textColor: string;
  backgroundColor: string;
}>`
  --text-normal: ${({ textColor }) => textColor};

  height: 100%;
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};

  .highcharts-axis-title {
    fill: var(--text-normal) !important;
  }

  .highcharts-data-label text,
  .highcharts-xaxis-labels text,
  .highcharts-yaxis-labels text,
  .highcharts-legend-item text {
    font-family: var(--default-font);
    font-style: normal !important;
    font-weight: normal !important;
    text-align: center;
    fill: var(--text-normal) !important;
  }

  .highcharts-text-outline {
    fill: var(--text-normal) !important;
    stroke: var(--text-normal) !important;
    stroke-width: 0.5px;
  }

  g.highcharts-label.highcharts-tooltip-header.highcharts-tooltip-box
    .highcharts-label-box {
    fill: black !important;
  }
`;

const LoadingSpinner = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
`;

const ErrorBackdrop = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: black !important;
  background-color: rgba(255, 255, 255, 0.5);

  div {
    color: black !important;
  }
`;

const ChartContainer = styled.div<{ visible?: boolean; fullHide?: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${({ visible }) => (visible ? "auto" : -1)};
  display: ${({ fullHide = false, visible }) =>
    fullHide ? (visible ? "block" : "none") : "block"};
`;

const GraphInputOuterDivContainer = styled.div<{
  theme: ThemeDefinition;
  textColor: string;
}>`
  position: absolute;
  right: var(--spacing-xl);
  top: var(--spacing-xl);
  gap: var(--spacing);
  display: flex;
  z-index: 5;

  label {
    --text-normal: ${({ textColor }) => textColor};
    color: var(--text-normal);
  }
  input {
    height: 30px;
  }

  button {
    font-size: 12px;
  }
`;

const InputContainer = styled.div`
  width: 200px;
`;
// #endregion styled components

// #region utils
function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

window.getTestData = function getTestData(count: number, interval = 1) {
  const entries = [];
  for (let i = 0; i < count; i += 1) {
    entries.push([
      Date.now() - 1000 * 60 * interval * (count - i),
      randomIntFromInterval(150, 180),
      randomIntFromInterval(150, 180),
      randomIntFromInterval(150, 180),
      randomIntFromInterval(150, 180),
      randomIntFromInterval(65000000, 80000000),
    ]);
  }
  return entries;
};

let randInterval: number | undefined;
const getData = async () => {
  const res = await fetch(
    "https://demo-live-data.highcharts.com/aapl-ohlcv.json"
  );
  const data = await res.json();

  randInterval = setInterval(() => {
    window.addEntries([
      [
        Date.now(),
        randomIntFromInterval(150, 180),
        randomIntFromInterval(150, 180),
        randomIntFromInterval(150, 180),
        randomIntFromInterval(150, 180),
        randomIntFromInterval(65000000, 80000000),
      ],
    ]);
  }, 2000) as unknown as number;
  return data;
};

function checkEntry<T>(obj: Record<string | number, T>, index: string) {
  try {
    return typeof obj[index] !== "undefined";
  } catch (err) {
    return false;
  }
}

function getEntry<T>(
  obj: Record<string | number, T>,
  index: string,
  defaultValue: T
) {
  const exists = checkEntry(obj, index);
  return exists ? obj[index] ?? defaultValue : defaultValue;
}

function convertIndexedObjectToArray<T>(
  indexObject?: Record<string | number, T> | null
) {
  try {
    if (!indexObject) return [];
    let entries = Object.entries(indexObject);
    if (entries.length === 0) {
      if (!checkEntry(indexObject, "i0")) return [];
      let idx = 0;
      while (checkEntry(indexObject, `i${idx}`)) {
        entries.push([`${idx}`, indexObject[`i${idx}`]]);
        idx += 1;
      }
    }
    return sortBy(
      entries.map((i) => ({
        key: `${i[0]}`.replace("i", ""),
        value: i[1],
      })),
      "key"
    ).map((i) => i.value);
  } catch (err) {
    safeError("convertIndexedObjectToArray failed", err);
    return [];
  }
}

const updateAxisColor = (
  axisOptions:
    | XAxisOptions
    | YAxisOptions
    | XAxisOptions[]
    | YAxisOptions[]
    | undefined,
  color: string | undefined = "#ED561B",
  modify: <T>(entry: T, idx: number) => T = (entry) => entry
) => {
  try {
    if (!axisOptions) {
      return modify({ gridLineColor: color }, 0);
    }
    if (Array.isArray(axisOptions)) {
      return axisOptions.map((option, idx) => {
        return modify({ ...option, gridLineColor: color }, idx);
      });
    }
    return modify({ ...axisOptions, gridLineColor: color }, 0);
  } catch (err) {
    safeError("updateAxisColor failed", err);
  }
};
// #endregion utils

// #region default values
const baseTimeFormat = {
  millisecond: "%l:%M:%S %P",
  second: "%l:%M:%S %P",
  minute: "%l:%M %P",
  hour: "%l:%M %P",
  day: "%e. %b",
  week: "%e. %b",
  month: "%b '%y",
  year: "%Y",
};

const IGNORE_SERIES = ["highcharts-navigator-series"];
const PRICE_SERIES_ID = "price";
const VOL_SERIES_ID = "volume";
let baseOptions = {
  chart: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    type: "datetime" as const,
    dateTimeLabelFormats: baseTimeFormat,
    ordinal: true,
  },
  yAxis: [
    {
      labels: {
        align: "left" as const,
      },
      height: "80%",
      resize: {
        enabled: true,
      },
      dateTimeLabelFormats: baseTimeFormat,
    },
    {
      labels: {
        align: "left" as const,
      },
      top: "80%",
      height: "20%",
      offset: 0,
      dateTimeLabelFormats: baseTimeFormat,
    },
  ],
  plotOptions: {
    series: {
      dataGrouping: { enabled: false },
    },
  },
  tooltip: {
    shape: "rect",
    headerShape: "callout" as const,
    borderWidth: 0,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    style: { color: "#ffffff" },
    shadow: false,
    xDateFormat: "%m/%d %H:%M %P",
    positioner: function (
      width: number,
      height: any,
      point: {
        isHeader: any;
        plotX: any;
        plotY: any;
        series: any; // { chart: { plotLeft: any }; yAxis: { top: number } };
      }
    ) {
      var chart = (this as any).chart,
        position;

      if (point.isHeader) {
        position = {
          x: Math.max(
            // Left side limit
            chart.plotLeft,
            Math.min(
              point.plotX + chart.plotLeft - width / 2,
              // Right side limit
              chart.chartWidth - width - chart.marginRight
            )
          ),
          y: point.plotY,
        };
      } else {
        position = {
          x: point.series.chart.plotLeft,
          y: point.series.yAxis.top - chart.plotTop,
        };
      }

      return position;
    },
  },
  series: [
    {
      type: "ohlc" as const,
      id: PRICE_SERIES_ID,
      name: "Price",
      useOhlcData: true,
      data: [],
    },
    {
      type: "column" as const,
      id: VOL_SERIES_ID,
      name: "Volume",
      data: [],
      yAxis: 1,
    },
  ],
  stockTools: {
    gui: {
      buttons: [
        "indicators",
        "zoomChange",
        "typeChange",
        "currentPriceIndicator",
      ],
      enabled: true,
    },
  },
  title: {},
  subtitle: {},
};

function getRangeButtons(minInterval: number, maxRangeHours: number) {
  try {
    const buttons: any[] = [];
    if (maxRangeHours >= 1) {
      if (maxRangeHours > 1 && minInterval < 60) {
        buttons.push({
          type: "hour",
          count: 1,
          text: "1h",
          title: "View 1 hour",
        });
        if (maxRangeHours > 2 && minInterval < 120) {
          buttons.push({
            type: "hour",
            count: 2,
            text: "2h",
            title: "View 2 hours",
          });
        }
        if (maxRangeHours > 4 && minInterval < 240) {
          buttons.push({
            type: "hour",
            count: 4,
            text: "4h",
            title: "View 4 hours",
          });
        }
        if (maxRangeHours > 6 && minInterval < 360) {
          buttons.push({
            type: "hour",
            count: 6,
            text: "6h",
            title: "View 6 hours",
          });
        }
        if (maxRangeHours > 12) {
          buttons.push({
            type: "hour",
            count: 12,
            text: "12h",
            title: "View 12 hours",
          });
        }
        if (maxRangeHours > 18 && minInterval === 360) {
          buttons.push({
            type: "hour",
            count: 18,
            text: "18h",
            title: "View 18 hours",
          });
        }
      } else if (minInterval < 30) {
        buttons.push({
          type: "minute",
          count: 30,
          text: "30m",
          title: "View 30 minutes",
        });
      }
    } else if (maxRangeHours > 0 && minInterval < 15) {
      buttons.push({
        type: "minute",
        count: 15,
        text: "15m",
        title: "View 15 minutes",
      });
    }
    return buttons;
  } catch (err) {
    safeError("updateAxisColor failed", err);
    return [];
  }
}

const defaultColors = [
  "#8A4EB6",
  "#50B432",
  "#058DC7",
  "#DDDF00",
  "#24CBE5",
  "#64E572",
  "#FF9655",
  "#FFF263",
  "#6AF9C4",
];
// #endregion default values

// #region types
type Settings = {
  reInitKey?: string;
  bucketSize: number;
  lookback: number;
  timezone: string;
  forceZoom?: boolean;

  colors?: string[];
  backgroundColor?: string;
  openLineColor?: string;
  lastPriceColor?: string;
  xAxisColor?: string;
  yAxisColor?: string;
  textColor?: string;
  titleColor?: string;
  subtitleColor?: string;
};

type SettingOptions = Omit<Settings, "colors"> & {
  username?: string;
  processingOrgName?: string;
  colors: Record<string, string>;
  initRandom?: boolean;
  initEntries?: Array<Array<any>>;
  initEntriesStr?: string;
};

type InstrumentInfo = {
  instrumentName: string;
  lastClosePrice: null | number;
};

type InstrumentOptions = InstrumentInfo & {
  initRandom?: boolean;
  initEntries?: Array<Array<any>>;
  initEntriesStr?: string;
};

export const defaultSettings: SettingOptions = {
  bucketSize: 1,
  lookback: 4,
  initRandom: true,
  forceZoom: false,
  timezone: chicagoTimeZone,
  colors: defaultColors.reduce(
    (res, entry, idx) => ({ ...res, [`${idx}`]: entry }),
    {} as Record<string, string>
  ),
  backgroundColor: "#36393f",
  openLineColor: "#8a4eb6",
  lastPriceColor: "#1c294c",
  textColor: "#dcddde",
  xAxisColor: "#4f545c",
  yAxisColor: "#4f545c",
  titleColor: "#dcddde",
  subtitleColor: "#dcddde",
};
// #endregion types

function getLastClose(ohlc: Array<[number, number, number, number, number]>) {
  try {
    const today = new Date().getDay();
    let lastPrevPriceIdx =
      ohlc.findIndex((e) => new Date(e[0]).getDay() === today) - 1;
    if (lastPrevPriceIdx < 0) lastPrevPriceIdx = 0;
    return ohlc[lastPrevPriceIdx]?.[4] ?? null;
  } catch (err) {
    safeError("getLastClose failed", err);
    return null;
  }
}

let timeout: any;
function handleReInitChart() {
  try {
    clearTimeout(timeout);
    if (typeof window.getCurrentState === "function") {
      if (typeof window.initChart !== "function") {
        timeout = setTimeout(() => {
          handleReInitChart();
        }, 100);
      } else {
        requestAnimationFrame(() => {
          window.initChart?.(window.getCurrentState());
        });
      }
    }
  } catch (err) {
    safeError("handleReInitChart failed", err);
  }
}

function parseInitEntries(key: string, value: string | Array<any>) {
  try {
    return typeof value === "string"
      ? (JSON.parse(value) as Array<any>)
      : value;
  } catch (err) {
    safeError(`failed to parse ${key}: ${value}`, err);
  }
}

function setWindowVarsNoop() {
  window.addEntries = noop;
  window.initChart = noop;
  window.initSettings = noop;
  window.clearInstrument = noop;
  window.showError = noop;
  window.triggerError = noop;
}

// const CauseError = () => {
//   useMount(() => {
//     let a = null;
//     if (Object.hasOwnProperty.call(a, "thing")) {
//       console.log("here");
//     }
//   });
//   return null;
// };

const EmbedChartingCore: React.FC<unknown> = () => {
  const chartComponentRef = React.useRef<HighchartsReactRef | null>(null);
  const [settings, setSettings] = React.useState<Settings | null>(null);
  const instrumentInfo = React.useRef<InstrumentInfo | null>(null);
  const [storedData, setStoredData] = React.useState<[any[], any[]] | null>(
    null
  );
  const [bucketSize, setBucketSize] = React.useState<number | null>(
    settings?.bucketSize ?? null
  );
  const [lookback, setLookback] = React.useState<number | null>(
    settings?.lookback ?? null
  );
  const [errorMessage, setError] = React.useState<string | null>(null);
  const [rerenderKey, setRerenderKey] = React.useState("init");

  React.useEffect(() => {
    const handler = debounce(() => {
      try {
        chartComponentRef.current?.resize(
          window.innerWidth,
          window.innerHeight
        );
        baseOptions.chart = {
          width: window.innerWidth,
          height: window.innerHeight,
        };
      } catch (err) {
        safeError("Failed to resize chart", err);
      }
    });

    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, [window.innerWidth, window.innerHeight]);

  const getPlotLine = useRefCallback(
    () =>
      ({
        id: "lastClose",
        value: instrumentInfo.current?.lastClosePrice!,
        color: settings?.openLineColor || defaultSettings.openLineColor,
        dashStyle: "ShortDash",
        width: 2,
        label: {
          text: `Last Close (${formatNumber(
            instrumentInfo.current?.lastClosePrice!,
            2
          )})`,
          style: {
            color: settings?.openLineColor || defaultSettings.openLineColor,
          },
        },
      } as const),
    [settings, defaultSettings, instrumentInfo]
  );

  const setChartData = useRefCallback(
    (newOhlc: any[], newVolume: any[], retry = true) => {
      let shouldRerender = false;
      try {
        chartComponentRef.current?.setSeriesData(0, newOhlc);
      } catch (err) {
        safeError("Failed to set newOhlc data", err);

        if (!Array.isArray(newOhlc)) {
          AppLogging.localOnly.info(
            `newOhlc is not an array: ${typeof newOhlc} ${JSON.stringify(
              newOhlc
            )}`
          );
        }

        if (retry) {
          try {
            const filteredOhlc = Array.isArray(newOhlc)
              ? newOhlc.filter(
                  (i) =>
                    Array.isArray(i) &&
                    !i.includes(null) &&
                    !i.includes(undefined)
                )
              : [];

            if (
              !Array.isArray(newOhlc) ||
              filteredOhlc.length !== newOhlc.length
            ) {
              AppLogging.localOnly.info("Retrying to set newOhlc data.");
              chartComponentRef.current?.setSeriesData(0, filteredOhlc);
            } else {
              AppLogging.localOnly.info("Clearing newOhlc data.");
              chartComponentRef.current?.resetSeries(1, "Volume");
              AppLogging.localOnly.info("Retrying to set newOhlc data.");
              chartComponentRef.current?.setSeriesData(1, filteredOhlc);
            }
          } catch (errInner) {
            safeError("Failed to retry set newOhlc data", errInner);
            shouldRerender = true;
            try {
              if (rerenderKey !== "init") {
                AppLogging.localOnly.info(`newOhlc count: ${newOhlc.length}`);
              }
              chartComponentRef.current?.resetSeries(0, "Price");
            } catch (errInnerTwo) {
              safeError("Failed to clear newOhlc data", errInnerTwo);
            }
          }
        } else {
          try {
            chartComponentRef.current?.resetSeries(0, "Price");
          } catch (errInner) {
            safeError("Failed to clear newOhlc data", errInner);
          }
        }

        try {
          AppLogging.localOnly.info(
            JSON.stringify({
              newOhlc: Array.isArray(newOhlc)
                ? newOhlc?.filter(
                    (i) =>
                      !Array.isArray(i) ||
                      i.includes(null) ||
                      i.includes(undefined)
                  ).length
                : 0,
            })
          );
        } catch (_err) {}
      }

      if (!shouldRerender) {
        try {
          if (
            typeof instrumentInfo.current !== "undefined" &&
            instrumentInfo.current !== null &&
            typeof instrumentInfo.current.lastClosePrice !== "number"
          ) {
            instrumentInfo.current.lastClosePrice = getLastClose(newOhlc);
            try {
              chartComponentRef.current?.removePlotLine("lastClose");
            } catch (err) {
              safeError("Failed to remove lastPrice plot line", err);
            }
            chartComponentRef.current?.addPlotLine(getPlotLine());
          }
        } catch (err) {
          safeError("Failed to change lastPrice", err);
        }

        try {
          chartComponentRef.current?.setSeriesData(1, newVolume);
        } catch (err) {
          safeError("Failed to set newVolume data", err);

          if (!Array.isArray(newVolume)) {
            AppLogging.localOnly.info(
              `newVolume is not an array: ${typeof newVolume} ${JSON.stringify(
                newVolume
              )}`
            );
          }

          if (retry) {
            try {
              const filteredVolume = Array.isArray(newVolume)
                ? newVolume.filter(
                    (i) =>
                      Array.isArray(i) &&
                      !i.includes(null) &&
                      !i.includes(undefined)
                  )
                : [];

              if (
                !Array.isArray(newVolume) ||
                filteredVolume.length !== newVolume.length
              ) {
                AppLogging.localOnly.info("Retrying to set newVolume data.");
                chartComponentRef.current?.setSeriesData(1, filteredVolume);
              } else {
                AppLogging.localOnly.info("Clearing newVolume data.");
                chartComponentRef.current?.resetSeries(1, "Volume");
                AppLogging.localOnly.info("Retrying to set newVolume data.");
                chartComponentRef.current?.setSeriesData(1, filteredVolume);
              }
            } catch (errInner) {
              safeError("Failed to retry set newVolume data", errInner);
              shouldRerender = true;
              try {
                if (rerenderKey !== "init") {
                  AppLogging.localOnly.info(
                    `newVolume count: ${newVolume.length}`
                  );
                }
                chartComponentRef.current?.resetSeries(1, "Volume");
              } catch (errInnerTwo) {
                safeError("Failed to clear newVolume data", errInnerTwo);
              }
            }
          } else {
            try {
              chartComponentRef.current?.resetSeries(1, "Volume");
            } catch (errInner) {
              safeError("Failed to clear newVolume data", errInner);
            }
          }
          try {
            AppLogging.localOnly.info(
              JSON.stringify({
                newVolume: Array.isArray(newVolume)
                  ? newVolume?.filter(
                      (i) =>
                        !Array.isArray(i) ||
                        i.includes(null) ||
                        i.includes(undefined)
                    ).length
                  : 0,
              })
            );
          } catch (_err) {}
        }
      }

      if (shouldRerender) {
        chartComponentRef.current = null;
        setRerenderKey(new Date().toISOString());
      }
    },
    [chartComponentRef, instrumentInfo]
  );

  const handleEntries = useRefCallback(
    (entries: Array<any>, settingsOverride?: Settings) => {
      try {
        const settingsValue = settingsOverride || settings;
        const bucketSize = (settingsValue?.bucketSize || 1) * 60000;
        const bucketId = (time: number) => Math.floor(time / bucketSize);

        setStoredData((storedData) => {
          const storedOHLC = storedData?.[0] ?? [];
          const storedVolume = storedData?.[1] ?? [];

          const res = entries
            .sort((a, b) => a[0] - b[0])
            .reduce(
              (acc: [any[], any[]], entry: any) => {
                try {
                  const lastEntry = last(acc[0]);
                  const [date, open, high, low, close, volume] = entry;

                  if (
                    !!lastEntry &&
                    bucketId(lastEntry[0]) === bucketId(date)
                  ) {
                    acc[0].pop();
                    acc[1].pop();
                  }

                  acc[0].push([date, open, high, low, close]);
                  acc[1].push([date, volume]);
                } catch (err) {
                  safeError("Failed to add data point", err);
                }
                return acc;
              },
              [[...storedOHLC], [...storedVolume]] as [any[], any[]]
            );

          setChartData(res[0], res[1]);
          return res;
        });
      } catch (err) {
        safeError("Failed to update stored entries", err);
      }
    },
    [settings]
  );

  const addEntriesCore = useRefCallback(
    (entriesBase?: string | Array<any>) => {
      AppLogging.debug.info(`addEntires (${!!entriesBase})`);
      if (instrumentInfo.current !== null) {
        if (entriesBase) {
          try {
            const entries = parseInitEntries("entries", entriesBase);
            if (entries) handleEntries(entries);
          } catch (err: any) {
            safeError(`Failed to parse entries: ${entriesBase}`, err);
          }
        }
      } else {
        AppLogging.localOnly.info("addEntries called before initInstrument");
      }
    },
    [handleEntries]
  );

  const clearInstrument = useRefCallback(() => {
    try {
      clearInterval(randInterval as number);
      chartComponentRef.current?.resetSeries(0, "Price");
      chartComponentRef.current?.resetSeries(1, "Volume");
      chartComponentRef.current?.removePlotLine("lastClose");

      instrumentInfo.current = null;
      setStoredData(null);
      setError(null);
      AppLogging.localOnly.info("Clear instrument");
    } catch (err) {
      safeError("Failed to clear instrument", err);
    }
  }, []);

  const initInstrument = useRefCallback(
    async ({
      instrumentName = "",
      lastClosePrice = null,
      initRandom,
      initEntriesStr,
      initEntries,
    }: InstrumentOptions) => {
      instrumentInfo.current = { instrumentName, lastClosePrice };

      AppLogging.remoteOnly.event(
        {
          event: AppEvent.InitInstrument,
          category: AppEventCategories.EmbedCharting,
        },
        { instrumentName, lastClosePrice }
      );
      AppLogging.localOnly.info(
        `Init Instrument: ${instrumentName} at ${lastClosePrice}`
      );
      try {
        if (chartComponentRef.current) {
          try {
            try {
              chartComponentRef.current.removePlotLine("lastClose");
            } catch (err) {
              safeError("Failed to remove lastClose line", err);
            }
            if (typeof lastClosePrice === "number") {
              chartComponentRef.current.addPlotLine(getPlotLine());
            }
          } catch (err) {
            safeError("Failed to change lastClose", err);
          }
        }
      } catch (err) {
        safeError("Failed to init chart", err);
      }

      setStoredData(() => null);
      setError(null);

      if (initRandom) {
        addEntriesCore(await getData());
      } else {
        let entries: any[] | null = null;
        const setEntiresIfValid = (key: string, value: string | Array<any>) => {
          const parsed = parseInitEntries(key, value);
          if (typeof parsed !== "undefined") entries = parsed;
        };
        try {
          if (!!initEntriesStr) {
            setEntiresIfValid("initEntriesStr", initEntriesStr);
          }
          if (entries === null && !!initEntries) {
            setEntiresIfValid("initEntries", initEntries);
          }
        } catch (err) {
          safeError("failed to add entries on init", err);
        }
        if (entries !== null) addEntriesCore(entries);
      }
      addEntriesCore([]);

      try {
        AppLogging.localOnly.info(
          JSON.stringify({
            msg: "try to clear clearExtremes",
            hasRef: !!chartComponentRef.current,
          })
        );

        // if (settings?.forceZoom === true) {
        chartComponentRef.current?.clearExtremes();
        // }
      } catch (err) {
        safeError("Failed to set extremes", err);
      }
    },
    [settings]
  );

  const initSettings = useRefCallback(
    (settingOptions: SettingOptions = {} as any as SettingOptions) => {
      try {
        // key to refresh Highchart when initChart is called
        // setKey(Math.random() + Date.now());
        AppLogging.debug.info(
          `Init settings: ${JSON.stringify(
            pick(settingOptions, [
              "colors",
              "timezone",
              "forceZoom",
              "backgroundColor",
              "openLineColor",
              "lastPriceColor",
              "xAxisColor",
              "yAxisColor",
              "textColor",
              "titleColor",
              "subtitleColor",
              "username",
            ])
          )}`
        );
        AppLogging.remoteOnly.event(
          {
            event: AppEvent.InitChartSettings,
            category: AppEventCategories.EmbedCharting,
          },
          pick(settingOptions, [
            "colors",
            "timezone",
            "forceZoom",
            "backgroundColor",
            "openLineColor",
            "lastPriceColor",
            "xAxisColor",
            "yAxisColor",
            "textColor",
            "titleColor",
            "subtitleColor",
          ])
        );

        if (FULLSTORY_ENABLED && !!settingOptions?.username) {
          try {
            const userAttributes = {
              displayName: `${settingOptions?.username} (${getEntry(
                settingOptions,
                "processingOrgName",
                "unknown"
              )})`,
              email: settingOptions?.username || "",
              po: getEntry(settingOptions, "processingOrgName", "unknown"),
            };
            FullStory.setUserVars(userAttributes);
          } catch (err) {
            safeError("failed to set full story user info", err);
          }
        }

        const colors = convertIndexedObjectToArray(
          settingOptions?.colors || defaultSettings.colors
        );

        const lookback = settingOptions?.lookback || 12;
        const baseBucketSize = settingOptions?.bucketSize || 5;

        setSettings((prevSettings) => ({
          ...(prevSettings || {}),
          bucketSize: baseBucketSize,
          lookback,
          timezone: settingOptions?.timezone || chicagoTimeZone,
          colors,
          ...pick(settingOptions, [
            "forceZoom",
            "backgroundColor",
            "openLineColor",
            "lastPriceColor",
            "xAxisColor",
            "yAxisColor",
            "textColor",
            "titleColor",
            "subtitleColor",
          ]),
        }));
        setLookback(lookback);
        setBucketSize(baseBucketSize);
        setError(null);
      } catch (err) {
        safeError("Failed to set settings", err);
      }
    },
    []
  );

  const showError = useRefCallback((message: string | null) => {
    AppLogging.localOnly.warn(`Show error: ${message}`);
    setError(message);
  }, []);

  const triggerError = useRefCallback((message: string) => {
    Promise.reject(message);
  }, []);

  useMount(() => {
    try {
      window.addEntries = addEntriesCore;
      // Exposes the example settings to the global scope for development purposes
      // window.defaultChartSettings = defaultSettings;

      window.initChart = async (
        settingOptions: SettingOptions &
          InstrumentInfo = {} as any as SettingOptions & InstrumentInfo
      ) => {
        await initSettings(settingOptions);
        await initInstrument(
          pick(settingOptions, [
            "instrumentName",
            "lastClosePrice",
            "initRandom",
            "initEntriesStr",
            "initEntries",
          ])
        );
      };

      window.initSettings = initSettings;

      window.initInstrument = initInstrument;

      window.clearInstrument = clearInstrument;

      window.showError = showError;

      window.triggerError = triggerError;

      window.clearExtremes = () => chartComponentRef.current?.clearExtremes();
    } catch (err) {
      safeError("mount issue", err);
      setWindowVarsNoop();
    }
  });

  const seriesData = React.useRef([...(baseOptions.series || [])]);

  const handleChartRender = useRefCallback((series: any = []) => {
    let newSeries = [];
    const priceSeries = series.find(
      (i: any) => i.userOptions.id === PRICE_SERIES_ID
    );
    if (priceSeries) newSeries.push(omit(priceSeries.userOptions, "data"));
    const volSeries = series.find(
      (i: any) => i.userOptions.id === VOL_SERIES_ID
    );
    if (volSeries) newSeries.push(omit(volSeries.userOptions, "data"));
    const otherSeries = series
      .filter(
        (i: any) =>
          ![PRICE_SERIES_ID, VOL_SERIES_ID, ...IGNORE_SERIES].includes(
            i.userOptions.id
          )
      )
      .map((i: any) => omit(i.userOptions, "data"));

    seriesData.current =
      newSeries.length === 0 ? [] : [...newSeries, ...otherSeries];
  }, []);

  const getCombinedOptions = useRefCallback(() => {
    return {
      ...baseOptions,
      series: [
        {
          ...(seriesData.current[0] || {}),
          name: "Price",
          lastPrice: {
            color: settings?.lastPriceColor || defaultSettings.lastPriceColor,
          },
          data: storedData?.[0] ?? [],
        },
        {
          ...(seriesData.current[1] || {}),
          name: "Volume",
          data: storedData?.[1] ?? [],
        },
        ...seriesData.current.slice(2),
      ],
      colors: settings?.colors || defaultColors,
      chart: {
        ...baseOptions.chart,
        backgroundColor:
          settings?.backgroundColor || defaultSettings.backgroundColor,
        events: {
          render: function () {
            handleChartRender((this as any).series);
          },
        },
      },
      time: {
        timezoneOffset: nowZoned(
          settings?.timezone || chicagoTimeZone
        ).getTimezoneOffset(),
      },
      xAxis: updateAxisColor(
        baseOptions.xAxis,
        settings?.xAxisColor || defaultSettings.xAxisColor
      ) as XAxisOptions | XAxisOptions[] | undefined,
      yAxis: updateAxisColor(
        baseOptions.yAxis,
        settings?.yAxisColor || defaultSettings.yAxisColor
      ) as YAxisOptions | YAxisOptions[] | undefined,
      title: {
        ...baseOptions.title,
        style: {
          color: settings?.titleColor || defaultSettings.titleColor,
        },
      },
      subtitle: {
        ...baseOptions.subtitle,
        style: {
          color: settings?.subtitleColor || defaultSettings.subtitleColor,
        },
      },
      rangeSelector: {
        inputEnabled: false,
        buttons: [
          {
            type: "millisecond",
            count: (settings?.bucketSize || 1) * 60000 * 10,
            text: "Now",
            title: "View Latest",
          },
          ...getRangeButtons(
            settings?.bucketSize || 5,
            settings?.lookback || 4
          ),
          {
            type: "all",
            text: "All",
            title: "View all",
          },
        ],
      },
    };
  }, [
    baseOptions,
    settings,
    seriesData,
    storedData,
    defaultSettings,
    getRangeButtons,
    handleChartRender,
  ]);

  const combinedOptions = React.useMemo(
    () => getCombinedOptions(),
    [baseOptions, settings, rerenderKey]
  );

  const onBucketSizeChanged = React.useCallback(
    (value: number | null) => {
      setBucketSize(value);
      if (typeof value === "number") onBucketSizeChange(value);
    },
    [setBucketSize]
  );

  const onLookBackChanged = React.useCallback(
    (value: number | null) => {
      setLookback(value);
      if (typeof value === "number") onLookbackChange(value);
    },
    [setLookback]
  );

  return (
    <>
      <GraphInnerContainer
        textColor={settings?.textColor || defaultSettings.textColor!}
        backgroundColor={
          settings?.backgroundColor || defaultSettings.backgroundColor!
        }
        onMouseLeave={() => chartComponentRef.current?.chart?.tooltip.destroy()}
      >
        {(settings === null || !storedData) && !errorMessage ? (
          <LoadingSpinner>
            <CenterContent>
              <Spinner />
            </CenterContent>
          </LoadingSpinner>
        ) : null}
        {!!storedData && (!storedData[0] || storedData[0].length === 0) ? (
          <LoadingSpinner>
            <CenterContent>No Data</CenterContent>
          </LoadingSpinner>
        ) : null}
        <ChartContainer visible={!!storedData}>
          <ErrorBoundary
            message="There was a issue mounting the chart. Please reset. If the issue persists please close the charts and tools/refresh."
            showDetails
            onError={handleBoundaryError}
            onReset={() => {
              setSettings((s) => (s ? { ...s, reInitKey: uniqueId() } : s));
            }}
            icon={faExclamationTriangle as IconProp}
            showReset
            style={{ minHeight: "100%", minWidth: "100%" }}
          >
            <HighchartsReact
              key={rerenderKey}
              ref={chartComponentRef}
              highcharts={Highcharts}
              options={combinedOptions as any}
              constructorType="stockChart"
            />
          </ErrorBoundary>
        </ChartContainer>
        <ChartContainer visible={!!errorMessage} fullHide>
          <ErrorBackdrop>
            <CenterContent>
              <ContentMessage
                icon={faExclamationTriangle}
                size="3x"
                message={errorMessage ?? ""}
                containerStyle={{
                  maxWidth: "75%",
                }}
              ></ContentMessage>
            </CenterContent>
          </ErrorBackdrop>
        </ChartContainer>
      </GraphInnerContainer>
      <GraphInputOuterDivContainer
        textColor={settings?.textColor || defaultSettings.textColor!}
      >
        <InputContainer>
          <NumericInput
            label="Interval &nbsp; (Mins):"
            inline
            min={0}
            max={360}
            minWidth="100px"
            value={bucketSize}
            onChange={onBucketSizeChanged}
          />
        </InputContainer>
        <InputContainer>
          <NumericInput
            label="Lookback (Hrs):"
            inline
            min={0}
            max={24}
            step={0.5}
            minWidth="100px"
            value={lookback}
            onChange={onLookBackChanged}
          />
        </InputContainer>
      </GraphInputOuterDivContainer>
    </>
  );
};

const handleBoundaryError = (err: Error) => {
  setWindowVarsNoop();
  AppLogging.event(
    {
      event: AppEvent.ErrorBoundary,
      category: AppEventCategories.EmbedCharting,
    },
    { message: err.message }
  );
  safeError("Error boundary", err);
};

const EmbedCharting: React.FC<unknown> = () => {
  return (
    <ErrorBoundary
      message="There was an issue with the styles. Please close the view and tools/refresh."
      onError={handleBoundaryError}
      onReset={handleReInitChart}
      showDetails
      showReset={typeof window.getCurrentState !== "undefined"}
    >
      <ThemeProvider noCharting>
        <ErrorBoundary
          message="There was an issue with the web view. Please close the view and tools/refresh."
          showDetails
          onError={handleBoundaryError}
          onReset={handleReInitChart}
          showReset={typeof window.getCurrentState !== "undefined"}
        >
          <EmbedChartingCore />
        </ErrorBoundary>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default EmbedCharting;
