import { useRefCallback } from "@enfusion-ui/hooks";
import {
  AccountingMethod,
  CashModeOptions,
  SelectOptionsType,
} from "@enfusion-ui/types";
import {
  ControlledCheckbox,
  PanelRow,
  Select,
} from "@enfusion-ui/web-components";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import {
  DateRangeSelection,
  DateRangeSymbolSelection,
} from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledLedgerHierarchySelect } from "../../../components/ReportQueryComponents/LedgerHierarchySelect";

export function getOptionValue(
  options: SelectOptionsType<string>[],
  accountingMethod: AccountingMethod
) {
  return accountingMethod === "CashBasis"
    ? options.find((option) => option.value === "SettleDate")
    : options.find((option) => option.value === "TradeDate");
}

const GLCashActivitySettings = () => {
  const { setValue } = useFormContext();
  const { accountsDescriptor } = useWatch({ name: ["accountsDescriptor"] });
  const defaultAccountDescriptor = React.useRef(accountsDescriptor);

  const handleCashModeChange = useRefCallback(
    (option: any) => {
      if (option.value === "SettleDate") {
        setValue("accountsDescriptor", {
          ...accountsDescriptor,
          accountingMethod: "CashBasis",
        });
      } else if (option.value === "TradeDate") {
        setValue("accountsDescriptor", {
          ...accountsDescriptor,
          accountingMethod: "AccrualBasis",
        });
      }
    },
    [setValue]
  );

  return (
    <>
      <ControlledLedgerHierarchySelect
        label="General Ledger(s)"
        name="accountsDescriptor"
        defaultValue={defaultAccountDescriptor.current}
      />
      <DateRangeSymbolSelection name="period" label="Period" />
      <DateRangeSelection
        name="modificationDateRange"
        startDateLabel="Modification Date (From)"
        endDateLabel="To"
      />
      <PanelRow>
        <Select
          minWidth={150}
          options={CashModeOptions}
          label="Cash Mode"
          value={
            getOptionValue(
              CashModeOptions,
              accountsDescriptor?.accountingMethod
            ) ?? null
          }
          onChange={handleCashModeChange}
          clearable
        />
        <ControlledCheckbox
          name="includeAllGLActivity"
          label="Include All GL Activity"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </PanelRow>
    </>
  );
};

export default GLCashActivitySettings;
