import { useOEMSOrderForm } from "@enfusion-ui/core";
import {
  Checkbox,
  FormController,
  PanelRow,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import CalculatedSidePanel from "../CalculatedSidePanel";
import { CDXClearingPanelProps } from "./CDXClearingPanel";

const CDXSideDetailsPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
  background-color: var(--background-color-0);
  border-radius: var(--radius-l);
`;

const CDXSideDetailsPanel: React.FC<CDXClearingPanelProps> = ({
  disabled = false,
}) => {
  const { completed, isTransmitted } = useOEMSOrderForm();

  return (
    <CDXSideDetailsPanelWrapper>
      <PanelRow grow>
        <CalculatedSidePanel showCDXOptions disabled={disabled} />
        <FormController
          name="cdxUnwind"
          inputId="cdx-unwind"
          mobileBasis="50%"
          style={{ alignItems: "flex-end" }}
          render={({ value, ref: _ref, ...rest }) => (
            <Checkbox
              {...rest}
              label="Unwind ?"
              checked={value}
              labelPlacement="left"
              disabled={completed || isTransmitted || disabled}
            />
          )}
        />
      </PanelRow>
    </CDXSideDetailsPanelWrapper>
  );
};

export default CDXSideDetailsPanel;
