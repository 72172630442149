import { useRefCallback } from "@enfusion-ui/hooks";
import { TimeProxy } from "@enfusion-ui/utils";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
const FORTY_FIVE_SECONDS = TimeProxy.Seconds.FortyFive.AsMilliseconds;
const FIVE_MINUTES = TimeProxy.Minutes.Five.AsMilliseconds;
export function usePortfolioEventState(toastId, toastMessages, errorToast, successToast, infoToast) {
    const [isActive, setActive] = React.useState(false);
    const timerRef = React.useRef();
    const failureTimerRef = React.useRef();
    const cbRef = React.useRef({});
    const getActive = useRefCallback(() => isActive, [isActive]);
    const clearTimeouts = useRefCallback(() => {
        cbRef.current = {};
        timerRef.current && clearTimeout(timerRef.current);
        failureTimerRef.current && clearTimeout(failureTimerRef.current);
    }, [timerRef, failureTimerRef]);
    const complete = useRefCallback(() => {
        cbRef.current.onComplete?.();
        clearTimeouts();
        setActive((active) => {
            if (active)
                successToast(toastMessages.complete, {
                    dismissToastId: toastId,
                });
            return false;
        });
    }, [toastId, toastMessages, clearTimeouts, successToast]);
    const fail = useRefCallback((line2) => {
        cbRef.current.onFail?.();
        clearTimeouts();
        setActive((active) => {
            if (active)
                errorToast(toastMessages.error, {
                    line2,
                    dismissToastId: toastId,
                });
            return false;
        });
    }, [toastId, toastMessages, clearTimeouts]);
    const start = useRefCallback(({ onComplete, onFail } = {}) => {
        cbRef.current = { onComplete, onFail };
        setActive(true);
        infoToast(toastMessages.info, {
            icon: (React.createElement(FontAwesomeIcon, { spin: true, icon: faSpinnerThird, style: { marginRight: "var(--spacing-l)" } })),
            toastId,
            autoClose: false,
        }, [infoToast]);
        timerRef.current = setTimeout(() => {
            infoToast(toastMessages.info, toastMessages.infoSub, {
                icon: (React.createElement(FontAwesomeIcon, { spin: true, icon: faSpinnerThird, style: { marginRight: "var(--spacing-l)" } })),
                toastId,
                autoClose: false,
            });
        }, FORTY_FIVE_SECONDS);
        failureTimerRef.current = setTimeout(() => fail(), FIVE_MINUTES);
    }, [infoToast, toastId, toastMessages, fail]);
    return React.useMemo(() => ({ isActive, start, complete, clearTimeouts, fail, getActive }), [isActive, start, complete, clearTimeouts, fail, getActive]);
}
