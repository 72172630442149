import { useModalState, useRefCallback } from "@enfusion-ui/hooks";
import { Portal } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faAngleUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);

  font-size: 0.75rem;
  font-weight: 700;
  white-space: nowrap;
`;

const ButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing);

  font: inherit;
  border: none;
  background: none;
  color: var(--primary);

  :hover {
    cursor: pointer;
  }
`;

const Icon = styled(FontAwesomeIcon)<{ open: boolean }>`
  transform: ${({ open }) => (open ? "rotate(-180deg)" : "rotate(0)")};
`;

const MenuContainer = styled.div`
  overflow: hidden;
  font-size: 0.875rem;
  color: var(--text-normal);
  border-radius: var(--radius);
  background-color: var(--background-color-0);

  max-height: 320px;
  overflow-y: auto;
`;

const MenuItemContainer = styled.div<{ selected: boolean }>`
  min-width: 5rem;
  padding: var(--spacing-l) var(--spacing-xl);
  background-color: ${({ selected }) =>
    selected ? "var(--primary-hover)" : "inherit"};

  :hover {
    background-color: var(--primary);
    cursor: pointer;
  }
`;

export type DropdownOption<T> = { value: T; label: string };

type TextButtonDropdownProps<T = string> = {
  value: T;
  label?: string;
  options: DropdownOption<T>[];
  onChange: (value: T) => void;
};

export const TextButtonDropdown: React.FC<TextButtonDropdownProps> = ({
  value,
  label,
  options,
  onChange,
}) => {
  const menuState = useModalState();
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const menuContainerRef = React.useRef<HTMLDivElement>(null);

  const onClickMenuItem = useRefCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onChange((e.target as HTMLDivElement).getAttribute("data-value")!);
      menuState.closeModal();
    },
    [onChange, menuState.closeModal]
  );

  React.useEffect(() => {
    if (menuState.open) {
      const list = Array.from(menuContainerRef.current?.children ?? []);
      const el = list.find((i) => i.getAttribute("data-selected") === "true");
      el?.scrollIntoView({ block: "center" });
    }
  }, [menuState.open]);

  return (
    <>
      <Container>
        {label ? <label>{label}:</label> : null}
        <ButtonContainer ref={buttonRef} onClick={menuState.openModal}>
          {options.find((opt) => opt.value === value)?.label}
          <Icon size="sm" open={menuState.open} icon={faAngleUp} />
        </ButtonContainer>
      </Container>

      <Portal
        open={menuState.open}
        topOffset={5}
        watchPosition
        attachedRef={buttonRef}
        onClickOutside={menuState.closeModal}
      >
        <MenuContainer ref={menuContainerRef} onClick={onClickMenuItem}>
          {options.map((opt, idx) => (
            <MenuItemContainer
              key={idx}
              selected={opt.value === value}
              data-value={opt.value}
              data-selected={opt.value === value}
            >
              {opt.label}
            </MenuItemContainer>
          ))}
        </MenuContainer>
      </Portal>
    </>
  );
};
