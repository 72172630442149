import { EMPTY_RECORD } from "@enfusion-ui/core";
import { useWidget, WidgetWithDatasourceConfig } from "@enfusion-ui/dashboards";
import { WebReportTableParam } from "@enfusion-ui/types";
import { RadioGroup, RadioOption } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import ChannelDataPicker from "./ChannelDataPicker";
import { useDatasourceParamsFilterBehaviour } from "./DatasourceParamsFilterBehaviour";

const DataFilterContainer = styled.div`
  display: flex;
`;

const InputContainer = styled.div`
  flex: 1 0 auto;
`;

export type FilterFieldMode = "static" | "dynamic";

interface FilterFieldModeOption extends RadioOption {
  label: string;
  value: FilterFieldMode;
}

export type DatasourceParamsContextProps = {
  param: WebReportTableParam;
  defaultFieldMode?: FilterFieldMode;
  allowFieldModeChange?: boolean;
};

const fieldModeOptions: FilterFieldModeOption[] = [
  { label: "Static", value: "static" },
  { label: "Dynamic", value: "dynamic" },
];

const DatasourceParamsFilter: React.FC<DatasourceParamsContextProps> = ({
  param,
  defaultFieldMode,
  allowFieldModeChange = true,
}) => {
  const { editedConfig } = useWidget();

  const {
    getStaticInputBasedOnFieldType,
    handleParamChannelIdChange,
    handleFilterModeChange,
  } = useDatasourceParamsFilterBehaviour(param);

  const {
    paramChannelIds = EMPTY_RECORD,
    isStaticFilterSelection = EMPTY_RECORD,
  } = editedConfig as WidgetWithDatasourceConfig;

  const staticFieldType = getStaticInputBasedOnFieldType();

  const fieldMode: FilterFieldMode =
    defaultFieldMode ?? isStaticFilterSelection[param.name]
      ? "static"
      : "dynamic";

  return (
    <DataFilterContainer>
      {fieldMode === "dynamic" ? (
        <InputContainer>
          <ChannelDataPicker
            key={param.name}
            label={param.description}
            value={paramChannelIds[param.name]}
            onChange={handleParamChannelIdChange(param.name)}
          />
        </InputContainer>
      ) : (
        <InputContainer>{staticFieldType}</InputContainer>
      )}
      {allowFieldModeChange ? (
        <div style={{ marginTop: "1px" }}>
          <RadioGroup
            name=""
            options={fieldModeOptions}
            value={fieldMode}
            onChange={(val) =>
              handleFilterModeChange(param.name, val as FilterFieldMode)
            }
            vertical
            labelPlaceholder
          />
        </div>
      ) : null}
    </DataFilterContainer>
  );
};
export default DatasourceParamsFilter;
