import { useRefCallback, useSessionStorage } from "@enfusion-ui/hooks";
import { useAuth } from "@enfusion-ui/web-core";
import * as React from "react";

export function useNPS() {
  const { user } = useAuth();

  const [npsOpen, setNpsOpen] = useSessionStorage(
    `e-nsp-open-${user?.username}`,
    false
  );

  const openNPS = useRefCallback(() => {
    setNpsOpen(true);
  }, []);

  const closeNPS = useRefCallback(() => {
    setNpsOpen(false);
  }, []);

  return React.useMemo(
    () => ({
      openNPS,
      closeNPS,
      npsOpen,
    }),
    [npsOpen]
  );
}
