import { formatReportValue } from "@enfusion-ui/utils";
import { dataEntry, PriceCellRenderer as DoubleTypeCellRenderer, valueTooltipGetter, } from "../../utils";
// Column data types that should be formatted as numbers
const getReportColumnType = (alignment, dataType) => {
    if (dataType === "Double" || dataType === "Integer")
        return "numberColumn";
    if (dataType === "Date")
        return "dateColumn";
    if (alignment === "Right")
        return "standardRightColumn";
    return "standardColumn";
};
const PARENT_CELL = "agGroupCellRenderer";
export function createReportColumnDefs({ columns, columnSort, hasChildren, }) {
    let parentSet = false;
    const columnDefs = columns.map((column) => {
        const { name, displayName, dataType, columnFormat, visible } = column;
        const sortIdx = columnSort.findIndex((sortSetting) => {
            return sortSetting.columnName === name;
        });
        const sortOptions = {};
        if (sortIdx !== -1) {
            sortOptions.sort = columnSort[sortIdx].ascending ? "asc" : "desc";
            sortOptions.sortIndex = sortIdx;
        }
        const type = getReportColumnType(columnFormat?.alignment, dataType);
        const hide = "visible" in column ? !visible : false;
        let cellRenderer = dataType === "HTMLLink"
            ? "htmlLinkCellRenderer"
            : dataType === "Double"
                ? DoubleTypeCellRenderer
                : undefined;
        if (!parentSet && !hide && hasChildren && !cellRenderer) {
            cellRenderer = PARENT_CELL;
            parentSet = true;
        }
        return {
            ...sortOptions,
            colId: name,
            headerName: displayName,
            name,
            type,
            pinned: cellRenderer === PARENT_CELL ? "left" : undefined,
            sortable: true,
            maxWidth: cellRenderer === PARENT_CELL ? window.innerWidth / 3 : undefined,
            valueGetter: (args) => dataEntry(args).value || null,
            valueFormatter: (args) => {
                const data = dataEntry(args);
                return data ? formatReportValue(data, column) : null;
            },
            cellRenderer,
            cellClassRules: {
                warningCell: (args) => dataEntry(args).status === "Warning",
                errorCell: (args) => dataEntry(args).status === "Error",
                errorText: ({ value }) => {
                    if (value === null)
                        return false;
                    if (columnFormat?.redIfNegative && value < 0)
                        return true;
                    switch (dataType) {
                        case "Integer":
                        case "Double": {
                            return value < 0;
                        }
                        default:
                            return false;
                    }
                },
            },
            tooltipValueGetter: valueTooltipGetter,
            hide,
        };
    });
    return [
        ...columnDefs,
        { field: "___changed", colId: "___changed", hide: true, lockVisible: true },
    ];
}
