import { PortfolioFolderProvider } from "@app-context/portfolios/PortfolioFolderProvider";
import { usePortfolio } from "@app-context/portfolios/PortfolioProvider";
import * as React from "react";

import { SaveAsPortfolioModalBase } from "./SaveAsPortfolioModalBase";

export const SaveAsPortfolioModal: React.FC<{
  open: boolean;
  closeModal: VoidFunction;
}> = ({ open, closeModal }) => {
  const { name, fundIds, settings, config, stateConfig } = usePortfolio();

  return (
    <PortfolioFolderProvider root={config.root}>
      <SaveAsPortfolioModalBase
        loading={false}
        open={open}
        closeModal={closeModal}
        name={name}
        root={config.root}
        filePath={config.filePath}
        fundIds={fundIds}
        settings={settings}
        config={stateConfig}
      />
    </PortfolioFolderProvider>
  );
};
