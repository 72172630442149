import { CDXOrderFormValues } from "@enfusion-ui/core";
import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { ControlledEmpty, ControlledSelect } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export type FCMDCMOptions = {
  label: string;
  value: string;
};

export type FCMDCMSelectProps = {
  disabled?: boolean;
  label?: string;
};

const FCMDCMSelect = ({
  disabled = false,
  label = "FCM/DCM",
}: FCMDCMSelectProps) => {
  const { setValue } = useFormContext<CDXOrderFormValues>();
  const fcmDcmId = useWatch<number | null>({ name: "fcmDcmId" });

  const { options, loading } = useRestAbortableOptions(
    REST_API.LEGALENTITY.GET_FCM.FETCH,
    (fcm) => ({ value: fcm.id, label: fcm.shortName })
  );

  React.useEffect(() => {
    const selectedFcm = options.find((option) => option.value === fcmDcmId);
    setValue("fcmdcmShortName", selectedFcm?.label);
  }, [fcmDcmId, options]);

  return (
    <>
      <ControlledEmpty name="fcmdcmShortName" />
      <ControlledSelect
        name="fcmDcmId"
        label={label}
        options={options}
        isLoading={loading}
        disabled={disabled}
        inputId="cdx-fcm-dcm-input-id"
      />
    </>
  );
};

export default FCMDCMSelect;
