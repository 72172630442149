import HighchartsBase from "highcharts";
import more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import * as React from "react";
import { useMeasure } from "react-use";

import {
  HighchartsWithCustomResetButton,
  ZoomResetButtonPosition,
} from "./HighchartsWithCustomResetButton";

more(HighchartsBase);

type SizedChartProps = Omit<HighchartsReact.Props, "options" | "highcharts"> & {
  className?: string;
  getOptions: (
    width: number,
    height: number
  ) => HighchartsReact.Props["options"];
  zoomResetButtonPosition?: ZoomResetButtonPosition;
};

export const SizedChart = React.forwardRef<
  HighchartsReact.RefObject,
  SizedChartProps
>(({ className, getOptions, zoomResetButtonPosition, ...props }, ref) => {
  const [measureRef, { width, height }] = useMeasure<HTMLDivElement>();

  const options = React.useMemo(
    () => ({
      colors: [
        ...new Array(13)
          .fill("0")
          .map((_, idx) => `var(--dashboard-color-${idx + 2})`),
        ...new Array(13)
          .fill("0")
          .map((_, idx) => `var(--dashboard-color-${idx + 2})`),
      ],
      ...getOptions(width, height),
    }),
    [width, height, getOptions]
  );

  return (
    <div
      ref={measureRef}
      style={{
        flex: 1,
        height: "100%",
        width: "100%",
        maxWidth: "100%",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: "flex",
          overflow: "hidden",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <HighchartsWithCustomResetButton
          {...props}
          ref={ref}
          options={options}
          highcharts={HighchartsBase}
          zoomResetButtonPosition={zoomResetButtonPosition}
          containerProps={{ ...(props.containerProps || {}), className }}
        />
      </div>
    </div>
  );
});
