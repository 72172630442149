import { useAnalyticsContext } from "@app-context/analytics/context";
import {
  createPieChartSubtitle,
  formatterCreator,
} from "@app-views/analytics/utils";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "@enfusion-ui/utils";
import * as React from "react";

import { PieChartBlock } from "./PieChartBlock";

const ccy = (val?: number | null, ccyCode: string | null = "USD") =>
  formatCurrency(val ?? 0, ccyCode!, {
    fractionDigits: [0, 2, 1],
    forceAverage: "million",
    reduce: true,
  });

const per = (val?: number | null) =>
  formatPercentage(val ?? 0, [0, 2], false, undefined, true);

export const DollarTurnoverSummary: React.FC = () => {
  const { data } = useAnalyticsContext();

  const { centerText, seriesData, tooltipFormatter } = React.useMemo(() => {
    const dollarTurnover = data?.portfolioMovement.dollarTurnover ?? null;
    return {
      centerText: createPieChartSubtitle({
        title: ccy(dollarTurnover?.value ?? 0, dollarTurnover?.currency),
        description: "Of Beginning Period Fund NAV",
        subText: per(dollarTurnover?.change ?? 0),
        subTextColor: (dollarTurnover?.change ?? 0) < 0 ? "danger" : "success",
      }),
      seriesData: [
        { name: "New", y: dollarTurnover?.data.new ?? 0 },
        { name: "Removed", y: dollarTurnover?.data.removed ?? 0 },
        { name: "Increased", y: dollarTurnover?.data.increased ?? 0 },
        { name: "Decreased", y: dollarTurnover?.data.decreased ?? 0 },
      ],
      tooltipFormatter: formatterCreator(
        ({ color, key, y, percentage }) =>
          `<span style="color: ${color}">${key}</span>: ${ccy(
            y,
            dollarTurnover?.currency
          )}(${formatNumber(percentage, {
            fractionDigits: [0, 2, 1],
            reduce: true,
          })}%)`
      ),
    };
  }, [data?.portfolioMovement.dollarTurnover]);

  return (
    <PieChartBlock
      title="Dollar Turnover"
      centerText={centerText}
      tooltipFormatter={tooltipFormatter}
      seriesData={seriesData}
    />
  );
};
