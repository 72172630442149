import { useAuth } from "@enfusion-ui/web-core";
import React from "react";

const adminPerms = { orders: { create: true, update: true, readOnly: false } };
const readOnlyPerms = {
  orders: { create: false, update: false, readOnly: true },
};

export function usePermissions() {
  const { user, hasPerm, isEnabled } = useAuth();

  return React.useMemo(() => {
    if (!!user?.adminUser) return { ...adminPerms };
    if (!isEnabled("OMS") || !!user?.flags?.OMS.readOnly)
      return { ...readOnlyPerms };
    const canUpdateOrders = hasPerm("UpdateExecutionOrder");
    const canCreateOrders = hasPerm("CreateExecutionOrder");
    return {
      orders: {
        create: canCreateOrders,
        update: canUpdateOrders,
        readOnly: !canUpdateOrders,
      },
    };
  }, [user, hasPerm, isEnabled]);
}
