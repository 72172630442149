import { GeneralSearchPortal } from "@app-components/control/GeneralSearchPortal";
import { usePortfolio } from "@app-context/portfolios/PortfolioProvider";
import { useGeneralSearchPortal } from "@app-utils/useGeneralSearchPortal";
import {
  MenuItemsIcon,
  MenuItemsTitle,
} from "@app-views/dashboards/widget/WidgetContainer";
import { useModalState, useRefCallback } from "@enfusion-ui/hooks";
import {
  AlignRightRow,
  IconButton,
  Portal,
  TabTopActionBar,
  useConfirmationModal,
} from "@enfusion-ui/web-components";
import { useTabs, useThisTab } from "@enfusion-ui/web-core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faPipe } from "@fortawesome/pro-regular-svg-icons";
import {
  faEllipsisV,
  faGear,
  faPlus,
  faReply,
  faRotateRight,
  faSave,
  faSearch,
  faShare,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu, { MenuItem } from "rc-menu";
import * as React from "react";
import { useHotkeys } from "react-hotkeys-hook";

import AddSecurityModal from "./AddSecurityModal";
import { ActionBarLoader } from "./Loaders";
import { SaveAsPortfolioModal } from "./SaveAsPortfolioModal";
import SettingsModal from "./SettingsModal";

const TopActionBar: React.FC<unknown> = () => {
  const {
    hasUnsavedChanges,
    saveChanges,
    undoAction,
    redoAction,
    loading,
    reloadPortfolio,
    hasUndo,
    hasRedo,
    rebalance,
    syncReceived,
    fundsChanged,
    config,
  } = usePortfolio();

  const { selectedTabId } = useTabs();
  const { id, selector } = useThisTab();

  const checkHotkeyEnabled = useRefCallback(() => {
    return selectedTabId === id;
  }, [selectedTabId, id]);

  const menuIconRef = React.useRef<HTMLButtonElement | null>(null);
  const menuPortalState = useModalState();

  const settingsModalState = useModalState();
  const addSecurityModalState = useModalState();
  const saveAsModalState = useModalState();

  const SaveConfirmation = useConfirmationModal({
    title: "Save Changes?",
    renderContent: () => "Do you want to save the changes to this workbench?",
    onSubmit: () => saveChanges(),
    submitActionTheme: "primary",
  });

  const ReloadConfirmation = useConfirmationModal({
    title: "Reload?",
    renderContent: () =>
      "Do you want to reload this workbench? Any rebalancing will be lost.",
    onSubmit: () => reloadPortfolio(),
    submitActionTheme: "primary",
  });

  const RebalanceConfirmation = useConfirmationModal({
    title: "Rebalance?",
    renderContent: () => "Do you want to rebalance this workbench?",
    onSubmit: () => rebalance(),
    submitActionTheme: "primary",
  });

  const searchRef = React.useRef<HTMLButtonElement | null>(null);
  const generalSearch = useGeneralSearchPortal(`PWB-${config.filePath}`);

  useHotkeys(
    "meta+f",
    () => {
      generalSearch.modalState.openModal();
    },
    {
      preventDefault: true,
      enabled: checkHotkeyEnabled,
      scopes: `PWB-${config.filePath}`,
    },
    [selectedTabId, id]
  );

  return (
    <>
      <TabTopActionBar
        style={
          syncReceived
            ? {
                zIndex: 21,
                backgroundColor: "transparent",
                position: "absolute",
                right: 0,
                width: "max-content",
              }
            : {}
        }
      >
        {syncReceived ? (
          <AlignRightRow flex>
            <IconButton
              hoverable
              icon={faSearch}
              title="Search"
              disabled={loading}
              size="sm"
              onClick={generalSearch.modalState.openModal}
              ref={searchRef}
            />
            <IconButton
              hoverable
              icon={faReply as IconDefinition}
              title="Undo"
              disabled={loading || !hasUndo}
              size="sm"
              onClick={undoAction}
            />
            <IconButton
              hoverable
              icon={faShare}
              title="Redo"
              disabled={loading || !hasRedo}
              size="sm"
              onClick={redoAction}
            />
            <FontAwesomeIcon
              icon={faPipe}
              color="var(--text-muted)"
              style={{ marginLeft: "10px", marginRight: "10px" }}
            />
            {/* Phase 2 */}
            {/* <IconButton
              hoverable
              icon={faScaleBalanced as IconDefinition}
              title="Rebalance"
              size="sm"
              disabled={loading}
              onClick={RebalanceConfirmation.openModal}
            /> */}
            <IconButton
              hoverable
              disabled={loading}
              icon={faPlus}
              onClick={addSecurityModalState.openModal}
              title="Add Security"
              size="sm"
              data-testid="btn-add-security"
              data-e2e-id="btn-add-security"
            />
            {/* Phase 2 */}
            {/* <IconButton
              hoverable
              icon={faShareAlt as IconDefinition}
              title="Share Workbench"
              size="sm"
              disabled
              onClick={() => infoToast("Not yet implemented")}
            /> */}
            <IconButton
              hoverable
              disabled={loading || !hasUnsavedChanges}
              icon={faSave}
              color={hasUnsavedChanges ? "var(--danger)" : undefined}
              title="Save Workbench"
              size="sm"
              onClick={SaveConfirmation.openModal}
            />
            <IconButton
              data-e2e-id="settings-action"
              hoverable
              disabled={loading || fundsChanged}
              icon={faGear}
              title="Settings"
              size="sm"
              onClick={settingsModalState.openModal}
              data-testid="btn-open-setting"
            />
            <IconButton
              ref={menuIconRef}
              data-e2e-id="other-menu-action"
              hoverable
              disabled={loading}
              icon={faEllipsisV}
              title="Menu"
              size="sm"
              onClick={menuPortalState.openModal}
              data-testid="btn-open-menu"
              style={
                menuPortalState.open
                  ? { backgroundColor: "var(--primary)" }
                  : {}
              }
            />
          </AlignRightRow>
        ) : (
          <AlignRightRow flex>
            <ActionBarLoader />
          </AlignRightRow>
        )}
      </TabTopActionBar>
      <SettingsModal
        isOpen={settingsModalState.open}
        closeModal={settingsModalState.closeModal}
      />
      <AddSecurityModal
        open={addSecurityModalState.open}
        closeModal={addSecurityModalState.closeModal}
      />
      <SaveAsPortfolioModal
        open={saveAsModalState.open}
        closeModal={saveAsModalState.closeModal}
      />

      {SaveConfirmation.content}
      {ReloadConfirmation.content}
      {RebalanceConfirmation.content}

      <Portal
        open={menuPortalState.open}
        attachedRef={menuIconRef}
        align="right"
        onClickOutside={menuPortalState.closeModal}
      >
        <Menu selectedKeys={[]}>
          <MenuItem
            key="reload-pwb"
            disabled={loading}
            onClick={ReloadConfirmation.openModal}
            data-e2e-id="menu-item-reload-pwb"
          >
            <MenuItemsIcon icon={faRotateRight}></MenuItemsIcon>
            <MenuItemsTitle>Reload</MenuItemsTitle>
          </MenuItem>

          <MenuItem key="save-as" onClick={saveAsModalState.openModal}>
            <MenuItemsIcon icon={faSave}></MenuItemsIcon>
            <MenuItemsTitle>Save As</MenuItemsTitle>
          </MenuItem>
        </Menu>
      </Portal>
      <GeneralSearchPortal
        {...generalSearch}
        mountRoot={selector}
        attachedRef={searchRef}
        align="right"
      />
    </>
  );
};

export default TopActionBar;
