import { useReportRows } from "@app-context/reports/ReportsProvider";
import { Checkbox, IconButton, TextInput } from "@enfusion-ui/web-components";
import { css, styled, useThisTab } from "@enfusion-ui/web-core";
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faHighlighter,
  faSearch,
} from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";

const BottomBarWrapper = styled.div<{
  noBackground: boolean;
  isWidget?: boolean;
}>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  column-gap: var(--spacing-l);
  font-size: ${({ isWidget }) => (!isWidget ? "0.9rem" : "0.5rem")};
  width: 100%;
  padding: ${({ isWidget }) =>
    !isWidget ? "var(--spacing-l)" : "var(--spacing)"};
  background: ${({ noBackground }) =>
    noBackground ? "transparent" : "var(--background-color-2)"};
`;

const RowCountContainer = styled.div<{ isMobile: boolean; isWidget?: boolean }>`
  display: flex;
  justify-content: ${({ isWidget }) => (isWidget ? "flex-start" : "flex-end")};
  align-items: center;
  flex: ${({ isMobile }) => (!isMobile ? "1 0 auto" : "0")};
  ${({ isWidget = false }) =>
    !isWidget ? " margin: 0 var(--spacing-l);" : "margin-top: var(--spacing);"}
`;

const SearchWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex: ${({ isMobile }) => (!isMobile ? "1 0 auto" : "1")};
`;

const SearchControls = styled.div<{ isMobile: boolean; breakWidth: boolean }>`
  display: flex;
  gap: var(--spacing);
  flex-wrap: ${({ isMobile }) => (!isMobile ? "nowrap" : "wrap")};
  flex: ${({ isMobile }) => (!isMobile ? "1 0 auto" : "1")};
  ${({ breakWidth }) =>
    breakWidth
      ? css`
          max-width: 15rem;
        `
      : ""}
`;

const Label = styled.div`
  margin: 0 var(--spacing-l) 0;
  align-self: center;
`;

export type ReportSearchBarPropType = {
  label?: string;
  onTextChanged?: (value: string) => void;
  onMatchCaseChanged?: (value: boolean) => void;
  onNavigatingUpDown?: (value: "up" | "down") => void;
  moveNext?: () => void;
  onHighlightFlagToggle?: (value: boolean) => void;
  searchVisible?: boolean;
  countVisible?: boolean;
  isMobile?: boolean;
  isWidget?: boolean;
  rowCount?: number | null;
  width?: number | string;
  breakWidth?: number;
  noBackground?: boolean;
  reportId: string;
};

const RowCount: React.FC<{
  reportId: string;
  rowCount?: number | null;
  isMobile: boolean;
  isWidget?: boolean;
}> = ({ rowCount: rowCountBase, reportId, isMobile, isWidget }) => {
  const { rowsStore } = useReportRows();
  const rowCount =
    typeof rowCountBase === "number"
      ? rowCountBase
      : rowsStore?.[reportId]?.rows.length ?? 0;
  return (
    <RowCountContainer isMobile={isMobile} isWidget={isWidget}>
      Received {rowCount} row(s)
    </RowCountContainer>
  );
};

const ReportSearchBar: React.FC<ReportSearchBarPropType> = ({
  label = "Find",
  reportId,
  onTextChanged,
  onMatchCaseChanged,
  moveNext,
  onNavigatingUpDown,
  onHighlightFlagToggle,
  searchVisible = true,
  countVisible = true,
  isMobile = false,
  isWidget = false,
  noBackground = false,
  rowCount,
  width,
  breakWidth,
}: ReportSearchBarPropType) => {
  const { width: innerWidth } = useThisTab();
  const [isCollapsed, setIsCollapsed] = React.useState(isMobile);
  const [searchText, setSearchText] = React.useState<string>("");
  const [matchCase, setMatchCase] = React.useState<boolean>(false);
  const [highlightAll, setHighlightAll] = React.useState<boolean>(false);

  const searchTextRef = React.createRef<HTMLInputElement>();

  React.useEffect(() => {
    onTextChanged?.(searchVisible ? searchText : "");

    if (!searchVisible) {
      setHighlightAll(false);
      onHighlightFlagToggle && onHighlightFlagToggle(false);
    }
  }, [searchVisible]);

  const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    onTextChanged?.(e.target.value);
  };

  const onMatchCaseChange = (value: boolean) => {
    setMatchCase(value);
    onMatchCaseChanged && onMatchCaseChanged(value);
  };

  const onHighlightToggle = () => {
    setHighlightAll((state) => !state);
    onHighlightFlagToggle && onHighlightFlagToggle(!highlightAll);
  };

  React.useEffect(() => {
    setIsCollapsed(isMobile);
  }, [isMobile]);

  const short = !searchVisible || isCollapsed;

  if (!searchVisible && !countVisible) return null;

  return (
    <BottomBarWrapper
      noBackground={noBackground}
      isWidget={isWidget}
      style={{ width: width }}
    >
      {searchVisible ? (
        isCollapsed ? (
          <IconButton
            icon={faSearch}
            onClick={() => setIsCollapsed((prev) => !prev)}
          />
        ) : (
          <SearchWrapper isMobile={isMobile}>
            <Label>{label}:</Label>
            <SearchControls
              isMobile={isMobile}
              breakWidth={
                typeof breakWidth !== "undefined" && innerWidth
                  ? innerWidth < breakWidth
                  : false
              }
            >
              <TextInput
                value={searchText}
                onChange={onSearchTextChange}
                clearable
                onClearValue={() => {
                  setSearchText("");
                  onTextChanged?.("");
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === "Enter") {
                    moveNext?.();
                    e.preventDefault();
                  }
                }}
                ref={searchTextRef}
                tabIndex={10}
                hideLabel
              />
              <Checkbox
                label="Match Case"
                checked={matchCase}
                onChange={onMatchCaseChange}
                style={{ maxWidth: "15rem" }}
                tabIndex={11}
              />
              <div style={{ display: "flex" }}>
                {isMobile && (
                  <IconButton
                    icon={faSearch}
                    onClick={() => setIsCollapsed((prev) => !prev)}
                  />
                )}
                <IconButton
                  title="Find the next occurrence of the phrase"
                  icon={faArrowCircleDown}
                  disabled={!searchText.trim()}
                  tabIndex={searchText.trim() ? 12 : undefined}
                  onClick={() => onNavigatingUpDown?.("down")}
                />
                <IconButton
                  title="Find the previous occurrence of the phrase"
                  icon={faArrowCircleUp}
                  disabled={!searchText.trim()}
                  tabIndex={searchText.trim() ? 13 : undefined}
                  onClick={() => onNavigatingUpDown?.("up")}
                />
                <IconButton
                  title="Highlight all the occurrences of the phrase"
                  icon={faHighlighter}
                  color={highlightAll ? "yellow" : undefined}
                  onClick={onHighlightToggle}
                  tabIndex={searchText.trim() ? 14 : 12}
                  onKeyDown={(e) => {
                    if (e.key === "Tab") {
                      searchTextRef.current?.focus();
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </SearchControls>
          </SearchWrapper>
        )
      ) : null}
      {countVisible ? (
        <RowCount
          reportId={reportId}
          rowCount={rowCount}
          isMobile={!short && isMobile}
          isWidget={isWidget}
        />
      ) : null}
    </BottomBarWrapper>
  );
};

export default ReportSearchBar;
