import { useSelectDataValue } from "@app-views/dashboards/pdf/utils";
import { AssetMeasureWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import * as React from "react";

import PdfSelectionWidget from "../PdfSelectionWidget";

const PdfAssetMeasureSelectionWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const { defaultValue, key } = config as AssetMeasureWidgetConfig;
  const [inputValue, setInputValue] = React.useState<string | null>(null);

  useSelectDataValue(
    key,
    "Asset Measures",
    ChannelDataType.String,
    defaultValue
  );

  React.useEffect(() => {
    if (defaultValue) {
      const formattedValue = defaultValue.join(", ");
      setInputValue(formattedValue);
    }
  }, [defaultValue]);

  return (
    <PdfSelectionWidget label={key} value={inputValue} placeholder="All" />
  );
};

export default PdfAssetMeasureSelectionWidget;
