import * as React from "react";

import { TabContentContainer } from "./components/styledComponents";
import { TradeInstrument } from "./components/TradeTicketInstrument";
import { TradeTicketPanels } from "./components/TradeTicketPanels";

export const GeneralTab: React.FC = () => {
  return (
    <TabContentContainer>
      <TradeInstrument />
      <TradeTicketPanels />
    </TabContentContainer>
  );
};
