import { basicInfoMap, useRestAbortableOptions } from "@enfusion-ui/hooks";
import { ReportDefinitions } from "@enfusion-ui/reports";
import { WebReportQuerySubType } from "@enfusion-ui/types";
import { ControlledSelect } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import React from "react";
import { useWatch } from "react-hook-form";

const GroupingTemplateSelect = () => {
  const type = useWatch<WebReportQuerySubType>({ name: "type" });

  const fetchReportGroupingTemplates = React.useCallback(
    (abortController?: AbortController) => {
      return REST_API.REPORTS.GET_GROUPING_TEMPLATES.FETCH(
        type ? ReportDefinitions[type] : "",
        abortController
      );
    },
    [type]
  );

  const { options: groupingTemplateOptions, loading } = useRestAbortableOptions(
    !type ? null : fetchReportGroupingTemplates,
    basicInfoMap
  );

  return (
    <ControlledSelect
      name="reportTemplateId"
      options={groupingTemplateOptions}
      label="Grouping Template"
      isLoading={loading}
      inputId="grouping-template"
    />
  );
};

export default GroupingTemplateSelect;
