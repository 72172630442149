import { useMounted } from "@enfusion-ui/hooks";
import { FundTreeEntry, NodeData } from "@enfusion-ui/types";
import { REST_API } from "@enfusion-ui/web-core";
import { omit } from "lodash";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";

import { AccountsContext } from "./context";

const sortByName = (a: NodeData, b: NodeData) => a.name.localeCompare(b.name);

export const fundTreeToNodeData = (
  files: Array<FundTreeEntry> = [],
  depth = 1,
  baseIds: {
    gl?: number;
    fund?: number;
    parent?: string;
  } = {}
): Array<NodeData> => {
  const isFile = depth === 5;

  return files
    .reduce((res, entry) => {
      const glId = baseIds.gl || entry.glId;
      const fundId = entry.level === "Fund" ? entry.id : baseIds.fund;

      const parentPath =
        baseIds.parent ??
        entry.path.substring(0, entry.path.indexOf(entry.name) - 1);
      const path = entry.name.includes("/")
        ? `${parentPath}/${encodeURIComponent(entry.name)}`
        : `${parentPath}/${entry.name}`;

      if (res.findIndex((i) => i.id === entry.path) === -1) {
        const subNodes = fundTreeToNodeData(entry.children, depth + 1, {
          gl: glId,
          fund: fundId,
          parent: path,
        });
        res.push({
          ...omit(entry, ["children"]),
          id: entry.id ? `${entry.id}` : uuidv4(),
          file: isFile,
          glId,
          fundId,
          path,
          parentId: baseIds.parent,
          accountId: entry.level === "Account" ? entry.id : undefined,
          nodes: subNodes,
          nodeCount: subNodes.length,
          depth,
          shortName: entry.shortName,
        });
      }
      return res;
    }, [] as Array<NodeData>)
    .sort(sortByName);
};

export const AccountsProvider: React.FC<
  React.PropsWithChildren<{ enabled: boolean }>
> = ({ children, enabled }) => {
  const [nodes, setNodes] = React.useState<NodeData[] | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<Error | null>(null);
  const isMounted = useMounted();

  const fetchStorageList = async () => {
    try {
      const res = await REST_API.FUND.GET_FUND_HIERARCHY.FETCH();
      if (isMounted() && res.children) {
        const subNodes = fundTreeToNodeData(res.children);
        const rootNode = {
          ...omit(res, ["children"]),
          id: `${res.id}` ?? "",
          file: false,
          nodes: subNodes,
          nodeCount: subNodes.length,
          depth: 0,
          shortName: res.shortName,
          defaultOpen: true,
        };
        setNodes([rootNode]);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (isMounted()) setError(err);
    }
  };

  React.useEffect(() => {
    async function fetchFiles() {
      if (isMounted()) setLoading(true);
      try {
        await fetchStorageList();
      } finally {
        if (isMounted()) setLoading(false);
      }
    }

    if (enabled) void fetchFiles();
  }, [enabled]);

  return (
    <AccountsContext.Provider
      value={{
        nodes,
        loading,
        error,
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
};
