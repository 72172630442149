import CurrencySelect from "@app-views/reports/reportSettings/generalLedgerAccounting/glDistributionLine/CurrencySelect";
import { useRefCallback } from "@enfusion-ui/hooks";
import { AccrualStepEntity } from "@enfusion-ui/trades";
import {
  Button,
  DatePicker,
  Modal,
  NumericInput,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const ModalContainer = styled.div`
  min-width: 21.25rem;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

const RowContainer = styled.div`
  display: flex;
  gap: var(--spacing-xl);
`;

const ActionContainer = styled.div`
  display: flex;
  height: 2.25rem;
  gap: var(--spacing-l);
  justify-content: space-between;
  margin-top: var(--spacing-xl);
`;

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onSubmit: (newStep: AccrualStepEntity) => void;
};

export const TradeTicketAccrualStepsModal: React.FC<Props> = ({
  open,
  onClose,
  onSubmit: onSubmitProp,
}) => {
  const [effectiveDate, setEffectiveDate] = React.useState<Date | null>(null);
  const [terminationDate, setTerminationDate] = React.useState<Date | null>(
    null
  );
  const [amount, setAmount] = React.useState<number | null>(null);
  const [currencyId, setCurrencyId] = React.useState<number | null>(null);

  const closeModal = useRefCallback(() => {
    setEffectiveDate(null);
    setTerminationDate(null);
    setAmount(null);
    setCurrencyId(null);
    onClose();
  }, []);

  const onSubmit = useRefCallback(() => {
    if (!effectiveDate || !terminationDate || !amount || !currencyId) return;
    onSubmitProp({
      effectiveDate,
      terminationDate,
      amount,
      currencyId,
    });
    closeModal();
  }, [effectiveDate, terminationDate, amount, currencyId, onSubmitProp]);

  return (
    <Modal
      dataE2EId="accrual-new-step-modal"
      isOpen={open}
      onClose={closeModal}
      title="Accrual Step"
      content={
        <ModalContainer>
          <RowContainer>
            <DatePicker
              data-e2e-id="accruals-effictive-date-picker"
              required
              value={effectiveDate}
              label="Effective Date"
              onChange={setEffectiveDate}
              dataTestId="tt-accrual-step-eff-date"
            />

            <DatePicker
              required
              value={terminationDate}
              label="Terminating Date"
              onChange={setTerminationDate}
              dataTestId="tt-accrual-step-term-date"
            />
          </RowContainer>

          <RowContainer>
            <NumericInput
              required
              clearable
              label="Amount"
              value={amount}
              dataTestId="tt-accrual-step-amount"
              onClearValue={() => setAmount(null)}
              onChange={(val) => setAmount(() => (val ? val : null))}
              name="trade-amount-input"
            />

            <CurrencySelect
              data-e2e-id="accruals-currency-input"
              required
              isClearable={false}
              inputId="tt-accrual-step-currency"
              onChange={(val) => setCurrencyId(val as number)}
            />
          </RowContainer>

          <ActionContainer>
            <Button
              dataE2EId="accruals-save-btn"
              theme="primary"
              onClick={onSubmit}
              data-testid="tt-accrual-step-submit-button"
              disabled={
                !effectiveDate || !terminationDate || !amount || !currencyId
              }
            >
              Save
            </Button>
            <Button
              onClick={closeModal}
              data-testid="tt-accrual-step-close-button"
            >
              Cancel
            </Button>
          </ActionContainer>
        </ModalContainer>
      }
    ></Modal>
  );
};
