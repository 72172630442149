import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { renderToString } from "react-dom/server";
const sizes = {
    "2xs": "0.6em",
    xs: "0.75em",
    sm: "0.875em",
    lg: "1.33em",
    "1x": "1em",
    "2x": "2em",
    xl: "2em",
    "3x": "3em",
    "2xl": "3em",
    "4x": "4em",
    "5x": "5em",
    "6x": "6em",
    "7x": "7em",
    "8x": "8em",
    "9x": "9em",
    "10x": "10em",
};
const SpinnerContainer = styled.div `
  color: var(--background-accent);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  user-select: none;
`;
const SVGSpinner = styled.svg `
  width: ${({ size }) => size};
  height: auto;
`;
export const Spinner = ({ size = "3x", dataE2EId = "", dataTestId = "", style }) => {
    return (React.createElement(SpinnerContainer, { "data-e2e-id": dataE2EId, "data-testid": dataTestId, style: style },
        React.createElement(SVGSpinner, { size: sizes[size], version: "1.1", xmlns: "http://www.w3.org/2000/svg", viewBox: "25 25 50 50" },
            React.createElement("circle", { cx: "50", cy: "50", r: "20", fill: "none", strokeWidth: "5", stroke: "#4f545c", strokeLinecap: "round", strokeDashoffset: "0", strokeDasharray: "100, 200" },
                React.createElement("animateTransform", { attributeName: "transform", attributeType: "XML", type: "rotate", from: "0 50 50", to: "360 50 50", dur: "2.5s", repeatCount: "indefinite" }),
                React.createElement("animate", { attributeName: "stroke-dashoffset", values: "0;-30;-124", dur: "1.25s", repeatCount: "indefinite" }),
                React.createElement("animate", { attributeName: "stroke-dasharray", values: "0,200;110,200;110,200", dur: "1.25s", repeatCount: "indefinite" })))));
};
export const SpinnerHTML = renderToString(React.createElement(Spinner, null));
