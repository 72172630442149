import { OrderStatusOptions } from "@enfusion-ui/types";
import { ControlledSelect, PanelRow } from "@enfusion-ui/web-components";
import * as React from "react";
import { useWatch } from "react-hook-form";

import ControlledAssignedDeskSelect from "../../../components/ReportQueryComponents/AssignedDeskSelect";
import ControlledAssignedTraderSelect from "../../../components/ReportQueryComponents/AssignedTraderSelect";
import ComplianceStateSelect from "../../../components/ReportQueryComponents/ComplianceSelect";
import { DateRangeSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledMaxResultsInput } from "../../../components/ReportQueryComponents/MaxResultsInput";

const OrderBlotterQuickFilter = () => {
  const { assignedTraderIds, assignedDeskIds } = useWatch({
    name: ["assignedTraderIds", "assignedDeskIds"],
  });

  const defaultAssignedTraderIdsRef = React.useRef(assignedTraderIds);
  const defaultAssignedDeskIdsRef = React.useRef(assignedDeskIds);

  return (
    <PanelRow>
      <DateRangeSelection
        name="dateRange"
        startDateLabel="Order Date (From)"
        endDateLabel="To"
        datePickerWidth={110}
      />
      <ControlledAssignedTraderSelect
        defaultAssignedTrader={defaultAssignedTraderIdsRef.current}
      />
      <ControlledAssignedDeskSelect
        defaultAssignedDesk={defaultAssignedDeskIdsRef.current}
      />
      <ComplianceStateSelect />
      <ControlledSelect
        label="Order Status"
        name="orderStatus"
        options={OrderStatusOptions}
      />
      <ControlledMaxResultsInput label="Limit" />
    </PanelRow>
  );
};

export default OrderBlotterQuickFilter;
