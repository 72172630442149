import ColumnSelect from "@app-components/inputs/ColumnSelect";
import { useRefCallback } from "@enfusion-ui/hooks";
import { SelectOptionsType } from "@enfusion-ui/types";
import { ICellEditorParams } from "ag-grid-community";
import * as React from "react";
import { useClickAway } from "react-use";

type EditorProps<T> = ICellEditorParams & {
  options: SelectOptionsType<T>[];
};

function ColumnSelectCellEditorCore<T>(
  { value, stopEditing, options }: EditorProps<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any
) {
  const [internalValue, setInternalValue] = React.useState<T | null>(value);

  const containerRef = React.useRef<HTMLDivElement>(null);
  useClickAway(containerRef, () => stopEditing());

  React.useImperativeHandle(
    ref,
    () => ({
      getValue: () => internalValue,
      isCancelBeforeStart: () => false,
      isCancelAfterEnd: () => false,
    }),
    [internalValue]
  );

  const handleChange = useRefCallback((option: SelectOptionsType<T>) => {
    setInternalValue(option.value);
    setTimeout(stopEditing, 50);
  }, []);

  return (
    <div ref={containerRef}>
      <ColumnSelect
        autoFocus
        menuIsOpen
        minWidth={50}
        options={options}
        clearable={false}
        value={internalValue}
        onChange={handleChange}
      />
    </div>
  );
}

export const ColumnSelectCellEditor = React.forwardRef(
  ColumnSelectCellEditorCore
);
