/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  OperationsFolderProvider,
  ServicesFolderProvider,
} from "@app-context/services/ServicesFolderProvider";
import { StorageRoot } from "@enfusion-ui/types";
import { AccordionListView } from "@enfusion-ui/web-components";
import {
  FilesContextState,
  styled,
  useAuth,
  useOperationFiles,
  useServicesFiles,
} from "@enfusion-ui/web-core";
import * as React from "react";

import { TutorialType } from "../../components/Tour/utils";
import ServicesExplorerProvider from "../../context/services/ServicesExplorerProvider";
import CreateServicesFolderModal from "./components/CreateServicesFolderModal";
import ServicesUploadFileModal from "./components/ServicesUploadFileModal";
import ServicesExplorer from "./ServicesExplorer";

const folderProviderMap = {
  services: ServicesFolderProvider,
  operations: OperationsFolderProvider,
} as Record<StorageRoot, React.FC<React.PropsWithChildren>>;

export const filesProviderMap = {
  services: useServicesFiles,
  operations: useOperationFiles,
} as Record<StorageRoot, () => FilesContextState>;

const createExplorer = (
  storageRoot: StorageRoot,
  tutorial?: TutorialType | null
) =>
  function ServiceFileExplorer({ open }: { open: boolean }) {
    const FolderProvider = folderProviderMap[storageRoot];
    return (
      <FolderProvider>
        <ServicesExplorer
          open={open}
          tutorial={tutorial}
          storageRoot={storageRoot}
          useFiles={filesProviderMap[storageRoot]}
        />
      </FolderProvider>
    );
  };

const defaultOpen = { file: true, dashboards: true, msOps: true };

const getItems = (tutorial?: TutorialType | null, user?: any) => {
  let items = [
    {
      key: "file",
      title: "FILES",
      contentComponent: createExplorer("services", tutorial),
    },
    ...(user?.adminUser &&
    process.env.REACT_APP_ENABLE_MS_OPERATIONS?.toLowerCase() === "true"
      ? [
          {
            key: "msOps",
            title: "MS OPERATIONS",
            contentComponent: createExplorer("operations"),
          },
        ]
      : []),
  ];
  return items;
};

const ServicesSidebarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ServicesSidebarTitle = styled.div`
  height: 40px;
  padding: 0 0.25rem 0 0.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const ServicesSidebar: React.FC<{
  tutorial?: TutorialType | null;
}> = ({ tutorial }) => {
  const { user } = useAuth();
  const listItems = React.useMemo(() => getItems(tutorial, user), [tutorial]);

  return (
    <ServicesSidebarContainer
      data-tutorial-step={
        tutorial ? `${tutorial}__services-sidebar` : undefined
      }
    >
      <ServicesSidebarTitle>Services Explorer</ServicesSidebarTitle>

      <ServicesExplorerProvider>
        <AccordionListView defaultOpen={defaultOpen} items={listItems} />

        <ServicesFolderProvider>
          <ServicesUploadFileModal storageRoot="services" />
          <CreateServicesFolderModal storageRoot="services" />
        </ServicesFolderProvider>
        <OperationsFolderProvider>
          <ServicesUploadFileModal storageRoot="operations" />
          <CreateServicesFolderModal storageRoot="operations" />
        </OperationsFolderProvider>
      </ServicesExplorerProvider>
    </ServicesSidebarContainer>
  );
};

export default ServicesSidebar;
