import { styled } from "@enfusion-ui/web-core";
import { motion } from "framer-motion";

export const animationAttrs = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    delay: 0.1,
  },
};

export const WidgetListContainer = styled(motion.div).attrs(animationAttrs)`
  padding: var(--spacing-l);
  padding-bottom: 0;
  overflow-y: auto;
  flex: 1;
`;

export const SettingsListContainer = styled(motion.div).attrs(animationAttrs)`
  padding: var(--spacing-l);
  /* overflow-y: auto; */
`;

export const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  gap: var(--spacing-xl);
`;

export const WidgetName = styled.h4`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
