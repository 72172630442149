import { useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";

import { IRSOrderSummaryPanel } from "./IRSOrderSummaryPanel";
import {
  InLineGridWrapper,
  InLineGridWrapperMobile,
  MobileWrapper,
  WebWrapper,
} from "./styles";

const IRSOrderSummaryModalCore: React.FC = () => {
  const isMobileDevice = useIsContentMobile();
  if (isMobileDevice) {
    return (
      <MobileWrapper>
        <InLineGridWrapperMobile padding="0rem 1rem 0rem 0.5rem">
          <IRSOrderSummaryPanel />
        </InLineGridWrapperMobile>
      </MobileWrapper>
    );
  }
  return (
    <WebWrapper>
      <InLineGridWrapper width="16rem">
        <IRSOrderSummaryPanel />
      </InLineGridWrapper>
    </WebWrapper>
  );
};
export default IRSOrderSummaryModalCore;
