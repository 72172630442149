import { useRefCallback } from "@enfusion-ui/hooks";
import * as React from "react";

type InterTabContextStateValue = {
  [index: string]: any;
};

type SelectedPositionType = {
  bookNetAverageCost: InterTabContextStateValue;
  borrowAgreementId: InterTabContextStateValue;
  custodianAccountDisplayName: InterTabContextStateValue;
  custodianName: InterTabContextStateValue;
  fundName: InterTabContextStateValue;
  hasBorrowAgreement: InterTabContextStateValue;
  notionalQuantity: InterTabContextStateValue;
  tRSDescription: InterTabContextStateValue;
  __row_idx: string;
};

type InterTabContextState = {
  selectedPosition?: SelectedPositionType;
};
export type InterTabContextType = InterTabContextState & {
  setInterTabContext: (key: keyof InterTabContextState, value: any) => void;
};

export const InterTabContext = React.createContext<
  InterTabContextType | undefined
>(undefined);

export function useInterTabContext() {
  const context = React.useContext(InterTabContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useInterTabContext must be used within a InterTabContextProvider"
    );
  }
  return context;
}

export const InterTabContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [state, setState] = React.useState<InterTabContextState>({});

  const setInterTabContext = useRefCallback(
    (key: keyof InterTabContextState, value: any) => {
      setState((curr) => ({ ...curr, [key]: value }));
    },
    []
  );

  return (
    <InterTabContext.Provider
      value={{
        ...state,
        setInterTabContext,
      }}
    >
      {children}
    </InterTabContext.Provider>
  );
};
