import {
  Accordion,
  TabTopActionBar,
  ViewContent,
} from "@enfusion-ui/web-components";
import styled from "styled-components";

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  padding: var(--spacing-xl);
  overflow-y: auto;
  height: 100%;
`;

export const TopActionBarContainer = styled(TabTopActionBar)`
  height: 38px;
  label {
    margin: 8px 0 4px 4px;
  }
  div {
    gap: var(--spacing);
  }
  && {
    gap: var(--spacing);
  }
`;

export const Column = styled.div<{ gap?: "l" | "xl" }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) =>
    gap === "l"
      ? "var(--spacing-l)"
      : gap === "xl"
      ? "var(--spacing-xl)"
      : "var(--spacing)"};
`;

export const RowSection = styled.div<{
  flex?: number;
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between";
  stretchHeight?: boolean;
  gap?: "l" | "xl";
}>`
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  flex: ${({ flex }) => (flex ? flex : null)};
  gap: ${({ gap }) =>
    gap === "l"
      ? "var(--spacing-l)"
      : gap === "xl"
      ? "var(--spacing-xl)"
      : "var(--spacing)"};
  align-items: ${({ stretchHeight }) => (stretchHeight ? "stretch" : "center")};
`;

export const ValueContainer = styled.div`
  flex: 1;
  background-color: var(--background-color-1);
  display: flex;
  flex-direction: column;
  border-radius: var(--radius);
`;

export const ValueHeader = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: var(--background-color-0);
  padding: var(--spacing);
  border-top-right-radius: var(--radius);
  border-top-left-radius: var(--radius);
`;
export const ValueContent = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-l);
`;

export const GridContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: var(--radius);
`;

export const BreakDetailsPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  padding: var(--spacing-l);
  border-radius: var(--radius);
  background-color: var(--background-color-2);
`;

export const BreakDetailsModalContainer = styled.div`
  height: 60vh;
  width: 42.5vw;
  min-width: 612px;
  border: 1px solid var(--background-color-1);
  border-radius: var(--radius);
  overflow-x: hidden;
  .diff-columns {
    background-color: var(--danger);
  }
  .cell-align-center {
    display: flex;
    justify-content: center;
  }
  .cell-align-right {
    display: flex;
    justify-content: right;
  }
  .ag-header-cell-label {
    justify-content: center;
  }
  .ag-cell-wrapper .ag-row-group-leaf-indent {
    margin-left: 0;
  }
`;

export const Table = styled.table`
  width: 100%;
  font-size: 12px;
  border-spacing: 0;
  border-radius: var(--radius);
  background-color: var(--background-color-1);
`;

export const TableRow = styled.tr<{ isDiff?: boolean }>`
  width: 100%;
  height: 30px;
  &:nth-child(odd) {
    background-color: var(--background-color-2);
  }
  background-color: var(
    --${({ isDiff }) => (isDiff ? "danger" : null)}
  ) !important;
`;

export const TableHeaderRow = styled(TableRow)`
  position: sticky;
  top: 0px;
  color: var(--text-color-1);
`;

export const TableCell = styled.td`
  text-align: center;
  border-bottom: 1px solid var(--background-color-0);
  width: 35%;

  &:first-child {
    text-align: right;
    border-right: 1px solid var(--background-color-0);
    padding-right: var(--spacing);
    width: 30%;
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: var(--radius);
  height: 100%;
  width: 100%;
  padding: 0 var(--spacing);
`;

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  height: 20px;
`;

export const PaddedSectionContainer = styled.div<{
  height?: number;
}>`
  background-color: var(--background-color-0);
  border-radius: var(--radius);
  height: ${({ height }) => height}px;
`;

export const MetricsSectionContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: var(--spacing-l);
  padding: var(--spacing-l);
  background-color: var(--background-color-2);
  border: 1px solid var(--background-accent);
  border-radius: var(--radius);
  height: 280px;
`;

export const TopPanelContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: var(--spacing);
  overflow: auto;
`;

export const ValueHighlightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: var(--spacing-l);
  border-radius: var(--radius);
  border: 1px solid var(--background-accent);
  background-color: var(--background-color-2);
  gap: var(--spacing);
`;

export const ValueHighlightLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: var(--text-normal);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const ValueHighlightContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-l);
  height: 100%;
  color: var(--success);
`;

export const ValueHighlightChange = styled.div<{ negative: boolean | null }>`
  font-size: 24px;
  color: var(
    --${({ negative }) => (negative === null ? "var(--text-normal)" : negative ? "danger" : "success")}
  );
`;
export const ValueHighlightChangePercentage = styled.div<{
  negative: boolean | null;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 10px;
  gap: var(--spacing);
  padding-top: var(--spacing);
  padding-bottom: var(--spacing);
  padding-left: var(--spacing-xl);
  padding-right: var(--spacing-xl);
  border-radius: 25px;
  background-color: ${({ negative }) =>
    negative === null
      ? "var(--text-normal)"
      : negative
      ? "var(--danger)"
      : "var(--success)"};
  border: 1px solid
    var(--${({ negative }) => (negative ? "danger" : "success")});
`;
export const ValueHighlightContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const GridWrapper = styled.div<{ height?: number }>`
  height: ${({ height }) => (height ? height : 100)}px;
  width: 100%;
  overflow: hidden;

  .ag-root-wrapper {
    border-width: 0px !important;
    background-color: var(--background-color-1);
    overflow: hidden;
  }

  .ag-root-wrapper.ag-layout-normal {
    border-radius: calc(var(--radius) + 1.5px);
  }

  .ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
    border: solid 1px;
    border-color: #424242;
    border-color: var(--ag-border-color, #424242);
    border-radius: calc(var(--radius) + 1.5px);
    overflow: hidden;
  }
  .ag-root-wrapper .ag-column-drop-wrapper .ag-column-drop-horizontal {
    border-bottom-width: 0px;
    background-color: transparent !important;
  }

  .ag-header {
    border-top-left-radius: calc(var(--radius) + 1.5px);
    border-top-right-radius: calc(var(--radius) + 1.5px);
  }
`;
export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  padding: var(--spacing-l) 0;
`;

// export const PostAndConfirmEntriesModalContainer = styled.div`
//   height: 55vh;
//   width: 52vw;
// `; for phase - 2

export const PostAndConfirmModalAccordion = styled(Accordion).attrs({
  style: ({ open }: { open?: boolean }) => ({
    boxShadow: "none",
    fontWeight: "normal",
    backgroundColor: "var(--background-accent)",
    borderBottom: "1px solid var(--background-color-0)",
    padding: "var(--spacing-l)",
    borderBottomLeftRadius: open ? "0px" : "var(--spacing)",
    borderBottomRightRadius: open ? "0px" : "var(--spacing)",
    borderTopLeftRadius: "var(--radius)",
    borderTopRightRadius: "var(--radius)",
  }),
})`
  > span {
    margin: 0;
  }
`;

export const CommentModalContainer = styled.div`
  width: 42.5vw;
  padding: var(--spacing);
  label {
    margin: 0;
  }
`;

export const ReconciliationSettingsModalContainer = styled.div`
  width: 75vw;
  padding: var(--spacing);
  label {
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
`;

export const InputWrapper = styled.div`
  flex: 1;
  label {
    margin: 8px 0 4px 4px;
  }
  @media (max-width: 630px) {
    min-width: 33%;
  }
`;

export const ButtonWrapper = styled.div`
  margin: auto 0 0;
  height: 38px;
  min-width: 38px;
`;

export const ButtonGroupSelectWrapper = styled.div`
  div {
    width: fit-content;
    gap: var(--spacing);
    justify-content: flex-start;
  }

  button {
    padding: 0 var(--spacing-l);
    border-radius: var(--radius);
    font-size: 12px;
    width: auto;
  }
`;

export const ContentWrapper = styled(ViewContent)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100%;
  max-height: 100%;
  padding: var(--spacing-xxl);
  padding-top: var(--spacing);
  overflow: hidden auto;
`;

export const BreaksByCurrencyContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SizedChartContainer = styled.div`
  position: absolute;
  align-items: center;
  border-radius: var(--radius);
`;

export const ProgressBarContainer = styled.div`
  border: 2px solid;
  border-color: var(--primary);
  border-radius: 16px;
  padding: 2px;
  width: 192px;
`;

export const ContentProgressContainer = styled.div`
  color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ContentProgressText = styled.div`
  font-size: 0.75em;
  font-weight: bold;
  margin-top: 8px;
`;

export const ProgressBar = styled.div<{ loading?: number }>`
  border-radius: 16px;
  height: 8px;
  background-color: var(--primary);
  animation: indeterminate 1s infinite linear alternate;
  width: ${({ loading }) => (loading ? 25 : 0)}% !important;

  @keyframes indeterminate {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: 75%;
    }
  }
`;
