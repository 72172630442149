import { useMounted } from "@enfusion-ui/hooks";
import {
  ContentComponent,
  SecondaryTabList,
  TabListTab,
  TabViewContent,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const topHeight = 32;

const TabBarContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1px;
  flex-direction: row;
  border-bottom-width: 1px;
  max-height: ${topHeight + 1}px;
  border-color: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.backgroundSecondary};
  overflow-y: hidden;
  overflow-x: auto;
`;

export type TabViewProps = React.PropsWithChildren<{
  defaultOpen?: string;
  onChange?: (activeKey: string) => void;
  keepRendered?: boolean;
  defaultRendered?: boolean;
  items: Array<
    TabListTab & {
      contentComponent: ContentComponent;
    }
  >;
}>;

const TabViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
`;

const TabViewContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
`;

const TabViewInnerContentWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`;

export const TabView: React.FC<TabViewProps> = React.memo(function TabView({
  items,
  onChange,
  children,
  defaultOpen,
  keepRendered = true,
  defaultRendered = false,
}) {
  const isMounted = useMounted();
  const [openSection, setOpenSection] = React.useState(
    defaultOpen || items[0]?.key
  );

  React.useEffect(() => {
    if (!openSection && !!items.length) {
      setOpenSection(items[0].key);
    }
  }, [items, openSection]);

  React.useEffect(() => {
    if (defaultOpen) setOpenSection(defaultOpen);
  }, [defaultOpen]);

  const handleClick = React.useCallback(
    (key: string) => {
      if (isMounted()) {
        setOpenSection(key);
        onChange?.(key);
      }
    },
    [onChange, setOpenSection, isMounted]
  );

  return (
    <TabViewContainer>
      <TabBarContainer>
        <SecondaryTabList
          tabs={items}
          onSelect={handleClick}
          value={openSection}
        />
      </TabBarContainer>
      <TabViewContentWrapper>
        <TabViewInnerContentWrapper>
          {items.map((tab) => (
            <TabViewContent
              key={tab.key}
              keepRendered={keepRendered}
              defaultRendered={defaultRendered}
              active={openSection === tab.key}
              tabKey={tab.key}
              Content={tab.contentComponent}
            />
          ))}
        </TabViewInnerContentWrapper>
        {children}
      </TabViewContentWrapper>
    </TabViewContainer>
  );
});
