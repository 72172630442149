import { ControlledInstrumentSelect } from "@app-components/inputs/InstrumentSelect/ControlledInstrumentSelect";
import { SelectOptionsType } from "@enfusion-ui/types";
import {
  ControlledDatePicker,
  ControlledNumericInput,
  ControlledSelect,
  FormElement,
  PanelRow,
} from "@enfusion-ui/web-components";
import { noop } from "lodash";
import * as React from "react";

const barrierTypeSelectOptions: SelectOptionsType<string>[] = [
  { label: "Up/In", value: "UpIn" },
  { label: "Down/In", value: "DownIn" },
  { label: "Up/Out", value: "UpOut" },
  { label: "Down/Out", value: "DownOut" },
];

export const SingleBarrier: React.FC<unknown> = () => {
  return (
    <div data-e2e-id="single-barrier-tab">
      <PanelRow>
        <ControlledSelect
          name="single-barrier-type"
          label="Barrier Type"
          options={barrierTypeSelectOptions}
          required
        />
        <ControlledNumericInput
          name="barrierLevel"
          label="Barrier Level"
          required
        />
      </PanelRow>
      <PanelRow>
        <ControlledDatePicker name="effectiveDate" label="Effective Date" />
        <ControlledDatePicker name="terminatingDate" label="Terminating Date" />
      </PanelRow>
      <PanelRow>
        <FormElement>
          <ControlledNumericInput name="rebate" label="Rebate" />
        </FormElement>
        <FormElement>
          <ControlledInstrumentSelect
            onChange={noop}
            name="barrierInstrumentSelect"
            label="Barrier Instrument"
            hideAdvancedSearchButtonText
          />
        </FormElement>
      </PanelRow>
    </div>
  );
};
