import {
  InputWrapper,
  SingleRowContainer,
} from "@app-views/dashboards/widgets/styles";
import { useDashboard, useWidget } from "@enfusion-ui/dashboards";
import { Checkbox, FormSectionAccordion } from "@enfusion-ui/web-components";
import * as React from "react";

import { InstrumentWidgetConfig } from "./types";

const InstrumentEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();
  const { mobile } = useDashboard();

  const {
    showAdvancedSearch = true,
    showBookStrategy = true,
    showCurrentPosition = true,
    showExistingPositionLink = true,
  } = editedConfig as InstrumentWidgetConfig;

  const handleChange = (key: string) => (val: boolean) => {
    changeConfigKeyToBeApplied(key, val);
  };

  return (
    <FormSectionAccordion title="Instrument Panel Options">
      <SingleRowContainer noMargin>
        <InputWrapper>
          <Checkbox
            checked={showAdvancedSearch}
            onChange={handleChange("showAdvancedSearch")}
            label="Show Advanced Search"
            style={{ marginTop: "var(--spacing-xl)" }}
          />
        </InputWrapper>
        <InputWrapper>
          <Checkbox
            checked={showCurrentPosition}
            onChange={handleChange("showCurrentPosition")}
            label="Show Position"
            style={{ marginTop: "var(--spacing-xl)" }}
          />
        </InputWrapper>
      </SingleRowContainer>
      {!mobile ? (
        <SingleRowContainer noMargin>
          <InputWrapper>
            <Checkbox
              checked={showBookStrategy}
              onChange={handleChange("showBookStrategy")}
              label="Show Book Strategy"
              style={{ marginTop: "var(--spacing-xl)" }}
            />
          </InputWrapper>
          <InputWrapper>
            <Checkbox
              checked={showExistingPositionLink}
              onChange={handleChange("showExistingPositionLink")}
              label="Show Existing Position Link"
              style={{ marginTop: "var(--spacing-xl)" }}
            />
          </InputWrapper>
        </SingleRowContainer>
      ) : null}
    </FormSectionAccordion>
  );
};

export default InstrumentEditWidget;
