import {
  FormElement,
  FormPanel,
  LabeledButton,
  PanelRow,
} from "@enfusion-ui/web-components";
import * as React from "react";

import {
  FullHeightContainer,
  TabContentContainer,
} from "./components/styledComponents";
import { LotReliefTabLotPanel } from "./panels/LotReliefTabLotPanel";

export const LotReliefTab: React.FC = () => {
  return (
    <TabContentContainer>
      <FullHeightContainer data-e2e-id="trade-lot-relief-tab">
        <LotReliefTabLotPanel />

        <FormPanel numColumns={1} title="Illogical Adjustments">
          <PanelRow>
            <FormElement
              basis="max-content"
              mobileBasis="100%"
              style={{ flexGrow: 0 }}
            >
              <LabeledButton
                label="Original Trade ID"
                style={{ cursor: "auto" }}
              >
                None
              </LabeledButton>
            </FormElement>
          </PanelRow>
        </FormPanel>
      </FullHeightContainer>
    </TabContentContainer>
  );
};
