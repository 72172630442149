import * as React from "react";
import { ResizeCallbackData } from "react-resizable";

import { AnalyticsPivotsDrawerContainer } from "./AnalyticsPivotsDrawerContainer";
import { AnalyticsPivotsResizableContainer } from "./AnalyticsPivotsResizableContainer";
import { AnalyticsPivotsSectionContent } from "./AnalyticsPivotsSectionContent";

type AnalyticsViewPivotsSectionProps = {
  showDrawer: boolean;
  onResizeCallBack: (e: React.SyntheticEvent, data: ResizeCallbackData) => void;
};
export const AnalyticsViewPivotsSection: React.FC<
  AnalyticsViewPivotsSectionProps
> = ({ showDrawer, onResizeCallBack }) => {
  const PivotsSectionContainer = showDrawer
    ? AnalyticsPivotsDrawerContainer
    : AnalyticsPivotsResizableContainer;

  return (
    <PivotsSectionContainer onResizeCallBack={onResizeCallBack}>
      <AnalyticsPivotsSectionContent />
    </PivotsSectionContainer>
  );
};
