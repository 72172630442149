import { PanelRow } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

import { DateRangeSymbolSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledLedgerHierarchySelect } from "../../../components/ReportQueryComponents/LedgerHierarchySelect";
import { ControlledMaxResultsInput } from "../../../components/ReportQueryComponents/MaxResultsInput";

const InputContainer = styled.div`
  min-width: 230px;
`;

const DateRangeContainer = styled.div`
  min-width: 350px;
`;

const GLDistributionLineQuickFilter = () => {
  const { accountsDescriptor } = useWatch({ name: ["accountsDescriptor"] });
  const defaultAccountDescriptor = React.useRef(accountsDescriptor);

  return (
    <PanelRow>
      <InputContainer>
        <ControlledLedgerHierarchySelect
          label="General Ledger(s)"
          name="accountsDescriptor"
          defaultValue={defaultAccountDescriptor.current}
        />
      </InputContainer>
      <DateRangeContainer>
        <DateRangeSymbolSelection name="period" label="Period" />
      </DateRangeContainer>
      <InputContainer>
        <ControlledMaxResultsInput />
      </InputContainer>
    </PanelRow>
  );
};

export default GLDistributionLineQuickFilter;
