import { TextInput, TextInputProps } from "@enfusion-ui/web-components";
import { css, REPORT_ROW_HEIGHT, styled } from "@enfusion-ui/web-core";
import * as React from "react";

const StyledTextInput = styled(TextInput)<{
  updated: boolean;
  touched: boolean;
}>`
  pointer-events: none;
  border: 1px solid transparent;
  text-align: right;

  color: ${({ touched }) => (touched ? "var(--primary)" : "inherit")};
  ${({ updated, touched }) =>
    updated || touched
      ? css`
          font-size: 0.9em;
          border-bottom: 1px dashed var(--text-muted);
        `
      : ""};

  :disabled {
    background-color: transparent;
  }
`;

type Props = {
  value: string;
  cellStyle?: React.CSSProperties;
  placeholder?: string;
  updated?: boolean;
  touched?: boolean;
} & TextInputProps;

export const HighlightedInputCell = React.memo(
  React.forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(
    (
      {
        value,
        cellStyle = {},
        placeholder = "",
        updated = false,
        touched = false,
        ...rest
      },
      ref
    ) => {
      return (
        <StyledTextInput
          ref={ref}
          hideLabel
          minWidth="50px"
          value={value}
          updated={updated}
          touched={touched}
          placeholder={placeholder}
          style={{
            ...cellStyle,
            height: REPORT_ROW_HEIGHT - 3,
          }}
          {...rest}
        />
      );
    }
  )
);
