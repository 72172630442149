export const defaultPWBColumnState = [
    {
        colId: "ag-Grid-AutoColumn",
        width: 160,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "selection",
        width: 27,
        hide: false,
        pinned: "left",
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "bbYellow",
        width: 120,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "fund",
        width: 120,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "account",
        width: 120,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "price",
        width: 100,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "currentQuantity",
        width: 140,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "currentExposure",
        width: 120,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "currentExposurePercentNAV",
        width: 140,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "sector",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "issuer",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "displayInstrument",
        width: 140,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: true,
        rowGroupIndex: 0,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "benchmarkName",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "duration",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "currentNAV",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "currentNMV",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "benchmarkExposurePercentNAV",
        width: 140,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "benchmarkDuration",
        width: 140,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "benchmarkPercentDifference",
        width: 190,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "instrumentType",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "instrumentSubtype",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "assetClass",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "country",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "riskCountry",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "instrumentCcy",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "industryGroup",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "industry",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "subIndustry",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "ticker",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "ric",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "cusip",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "isin",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "sedol",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "fundShortName",
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "textAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "modelExposurePercentNAV",
        width: 130,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "modelPercentDifference",
        width: 130,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "benchmarkQuantity",
        width: 130,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "modelQuantity",
        width: 130,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "benchmarkExposure",
        width: 130,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "modelExposure",
        width: 130,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "benchmarkQuantityDifference",
        width: 130,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "modelQuantityDifference",
        width: 130,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "benchmarkExposureDifference",
        width: 130,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "modelExposureDifference",
        width: 130,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "incrementalExposurePercentNAV",
        width: 130,
        hide: false,
        pinned: "right",
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "finalExposurePercentNAV",
        width: 130,
        hide: false,
        pinned: "right",
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "incrementalExposure",
        width: 130,
        hide: false,
        pinned: "right",
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "finalExposure",
        width: 130,
        hide: false,
        pinned: "right",
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "incrementalQuantity",
        width: 130,
        hide: false,
        pinned: "right",
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "finalQuantity",
        width: 130,
        hide: false,
        pinned: "right",
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "incrementalNAV",
        width: 130,
        hide: false,
        pinned: "right",
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "finalNAV",
        width: 130,
        hide: false,
        pinned: "right",
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "finalNMV",
        width: 130,
        hide: true,
        pinned: "right",
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
    {
        colId: "incrementalNMV",
        width: 130,
        hide: true,
        pinned: "right",
        sort: null,
        sortIndex: null,
        aggFunc: "doubleAgg",
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
    },
];
