import { PanelItemBase } from "@app-views/analytics/components/styledComponents";
import { FormPanel } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { PortfolioMovementPanelTopRow } from "./PortfolioMovementPanelTopRow";

const Container = styled(PanelItemBase)`
  height: 335px;
  display: grid;
  grid-template-columns: 1fr 340px;
`;

export const AnalyticsPortfolioMovementPanel: React.FC = () => {
  return (
    <FormPanel
      lineStyle
      collapsible
      keepRendered
      numColumns={1}
      title="Portfolio Movement"
      showTextCollapseButton
      tabFocusOutline={false}
      componentAlwaysShown={<PortfolioMovementPanelTopRow />}
      titleStyle={{
        padding: "var(--spacing-s) var(--spacing-l) 0 var(--spacing-l)",
      }}
      gapSize="m"
      thinContainer
      render={() => (
        <Container>
          <div></div>
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          ></div>
        </Container>
      )}
    />
  );
};
