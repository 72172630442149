import { ThemeDefinition } from "@enfusion-ui/core";
import { ConnectionStatus } from "@enfusion-ui/types";

export const getColor = (
  status: ConnectionStatus | undefined,
  theme: ThemeDefinition
) => {
  if (status === ConnectionStatus.CONNECTED) return theme.colors.success;
  if (status === ConnectionStatus.CONNECTING) return theme.colors.textNormal;
  if (status === ConnectionStatus.RECONNECTING) return theme.colors.warning;
  return theme.colors.danger;
};

export default getColor;
