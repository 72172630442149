import { ChannelDataType, WebReportQuery } from "@enfusion-ui/types";

export type ParamName =
  | "accountSelection"
  | "datePeriodSelection"
  | "portfolioGroupIds"
  | "marketEnvironmentId"
  | "generalLedgerIds"
  | "valueDate"
  | "accountingMethod"
  | "period"
  | "freeForm"
  | "financialSubTypes"
  | "maxResults"
  | "primaryOnly"
  | "ignoreInactive"
  | "dateRange"
  | "assignedTraderIds"
  | "assignedDeskIds"
  | "complianceStates"
  | "modificationDateRange"
  | "includeAllGLActivity"
  | "entrySources"
  | "excludeTemplateEntries"
  | "calculationMethod"
  | "instrumentTypes"
  | "valueDatePeriodSelection"
  | "benchmarkNAVSource"
  | "benchmarkCalculationType"
  | "portfolioAnalyticsSources"
  | "fundBenchmarkType"
  | "includeUnderlyings"
  | "instrumentIds"
  | "cashMode"
  | "quoteSetIds"
  | "sourceIds"
  | "assetMeasures"
  | "fromDate"
  | "toDate"
  | "shareClassIds"
  | "corporateActionTypes";

export type ParamConfig =
  | {
      name: ParamName;
      description?: string;
      staticType?: ParamName | "--custom--";
      dynamicType?: ChannelDataType;
      element?: React.ReactElement;
    }
  | {
      name: string;
      description?: string;
      staticType: ParamName | "--custom--";
      dynamicType: ChannelDataType;
      element?: React.ReactElement;
    };

type ParamTypes = Record<ParamName, ParamConfig>;

const paramTypes: ParamTypes = {
  accountSelection: {
    name: "accountSelection",
    staticType: "accountSelection",
    dynamicType: ChannelDataType.Number,
  },
  datePeriodSelection: {
    name: "datePeriodSelection",
    staticType: "datePeriodSelection",
    dynamicType: ChannelDataType.DatePeriodSelection,
  },
  instrumentIds: {
    name: "instrumentIds",
    staticType: "instrumentIds",
    dynamicType: ChannelDataType.Number,
  },
  portfolioGroupIds: {
    name: "portfolioGroupIds",
    staticType: "portfolioGroupIds",
    dynamicType: ChannelDataType.Number,
  },
  marketEnvironmentId: {
    name: "marketEnvironmentId",
    staticType: "marketEnvironmentId",
    dynamicType: ChannelDataType.Number,
  },
  generalLedgerIds: {
    name: "generalLedgerIds",
    staticType: "generalLedgerIds",
    dynamicType: ChannelDataType.Number,
  },
  valueDate: {
    name: "valueDate",
    staticType: "valueDate",
    dynamicType: ChannelDataType.DateSelection,
  },
  accountingMethod: {
    name: "accountingMethod",
    staticType: "accountingMethod",
    dynamicType: ChannelDataType.String,
  },
  period: {
    name: "period",
    staticType: "period",
    dynamicType: ChannelDataType.DateSymbolRange,
  },
  freeForm: {
    name: "freeForm",
    staticType: "freeForm",
    dynamicType: ChannelDataType.String,
  },
  financialSubTypes: {
    name: "financialSubTypes",
    staticType: "financialSubTypes",
    dynamicType: ChannelDataType.String,
  },
  maxResults: {
    name: "maxResults",
    staticType: "maxResults",
    dynamicType: ChannelDataType.Number,
  },
  primaryOnly: {
    name: "primaryOnly",
    staticType: "primaryOnly",
    dynamicType: ChannelDataType.Boolean,
  },
  ignoreInactive: {
    name: "ignoreInactive",
    staticType: "ignoreInactive",
    dynamicType: ChannelDataType.Boolean,
  },
  dateRange: {
    name: "dateRange",
    staticType: "dateRange",
    dynamicType: ChannelDataType.DateRange,
  },
  assignedTraderIds: {
    name: "assignedTraderIds",
    staticType: "assignedTraderIds",
    dynamicType: ChannelDataType.Number,
  },
  assignedDeskIds: {
    name: "assignedDeskIds",
    staticType: "assignedDeskIds",
    dynamicType: ChannelDataType.Number,
  },
  complianceStates: {
    name: "complianceStates",
    staticType: "complianceStates",
    dynamicType: ChannelDataType.String,
  },
  cashMode: {
    name: "cashMode",
    staticType: "cashMode",
    dynamicType: ChannelDataType.String,
  },
  modificationDateRange: {
    name: "modificationDateRange",
    staticType: "modificationDateRange",
    dynamicType: ChannelDataType.DateRange,
  },
  includeAllGLActivity: {
    name: "includeAllGLActivity",
    staticType: "includeAllGLActivity",
    dynamicType: ChannelDataType.Boolean,
  },
  entrySources: {
    name: "entrySources",
    staticType: "entrySources",
    dynamicType: ChannelDataType.String,
  },
  excludeTemplateEntries: {
    name: "excludeTemplateEntries",
    staticType: "excludeTemplateEntries",
    dynamicType: ChannelDataType.Boolean,
  },
  calculationMethod: {
    name: "calculationMethod",
    staticType: "calculationMethod",
    dynamicType: ChannelDataType.String,
  },
  instrumentTypes: {
    name: "instrumentTypes",
    staticType: "instrumentTypes",
    dynamicType: ChannelDataType.String,
  },
  valueDatePeriodSelection: {
    name: "valueDatePeriodSelection",
    staticType: "valueDatePeriodSelection",
    dynamicType: ChannelDataType.DatePeriodSelection,
  },
  benchmarkNAVSource: {
    name: "benchmarkNAVSource",
    staticType: "benchmarkNAVSource",
    dynamicType: ChannelDataType.String,
  },
  benchmarkCalculationType: {
    name: "benchmarkCalculationType",
    staticType: "benchmarkCalculationType",
    dynamicType: ChannelDataType.String,
  },
  portfolioAnalyticsSources: {
    name: "portfolioAnalyticsSources",
    staticType: "portfolioAnalyticsSources",
    dynamicType: ChannelDataType.String,
  },
  fundBenchmarkType: {
    name: "fundBenchmarkType",
    staticType: "fundBenchmarkType",
    dynamicType: ChannelDataType.String,
  },
  includeUnderlyings: {
    name: "includeUnderlyings",
    staticType: "includeUnderlyings",
    dynamicType: ChannelDataType.Boolean,
  },
  quoteSetIds: {
    name: "quoteSetIds",
    staticType: "quoteSetIds",
    dynamicType: ChannelDataType.Number,
  },
  sourceIds: {
    name: "sourceIds",
    staticType: "sourceIds",
    dynamicType: ChannelDataType.Number,
  },
  assetMeasures: {
    name: "assetMeasures",
    staticType: "assetMeasures",
    dynamicType: ChannelDataType.String,
  },
  fromDate: {
    name: "fromDate",
    staticType: "fromDate",
    dynamicType: ChannelDataType.Date,
  },
  toDate: {
    name: "toDate",
    staticType: "toDate",
    dynamicType: ChannelDataType.Date,
  },
  shareClassIds: {
    name: "shareClassIds",
    staticType: "shareClassIds",
    dynamicType: ChannelDataType.Number,
  },
  corporateActionTypes: {
    name: "corporateActionTypes",
    staticType: "corporateActionTypes",
    dynamicType: ChannelDataType.String,
  },
};

export const FormsConfig: Record<WebReportQuery["type"], ParamConfig[]> = {
  WebCounterpartyCommissionQuery: [],
  WebPositionEventReportQuery: [
    { ...paramTypes.dateRange, name: "tradeDateRange" } as ParamConfig,
  ], // todo add these
  WebPositionReportQuery: [
    { ...paramTypes.accountSelection },
    { ...paramTypes.datePeriodSelection },
    { ...paramTypes.instrumentIds },
    { ...paramTypes.portfolioGroupIds },
    { ...paramTypes.marketEnvironmentId },
  ],
  WebPositionCashFlowQuery: [
    { ...paramTypes.accountSelection },
    { ...paramTypes.valueDate, name: "dateSelection" } as ParamConfig,
    { ...paramTypes.marketEnvironmentId },
    { ...paramTypes.instrumentIds },
  ],
  WebGLBalanceSheetQuery: [
    { ...paramTypes.generalLedgerIds },
    { ...paramTypes.valueDate },
    { ...paramTypes.accountingMethod },
  ],
  WebGLIncomeStatementQuery: [
    { ...paramTypes.generalLedgerIds },
    { ...paramTypes.period },
    { ...paramTypes.accountingMethod },
  ],
  WebInstrumentQuery: [
    { ...paramTypes.freeForm },
    { ...paramTypes.financialSubTypes },
    { ...paramTypes.maxResults },
    { ...paramTypes.primaryOnly },
    { ...paramTypes.ignoreInactive },
  ],

  WebOrderBlotterQuery: [
    { ...paramTypes.dateRange },
    { ...paramTypes.assignedTraderIds },
    { ...paramTypes.complianceStates },
    { ...paramTypes.maxResults },
  ],

  WebGLCashBalancesQuery: [
    { ...paramTypes.generalLedgerIds },
    { ...paramTypes.valueDate },
    { ...paramTypes.cashMode },
  ],
  WebGLCashFlowStatementQuery: [
    { ...paramTypes.generalLedgerIds },
    { ...paramTypes.period },
  ],
  WebGLCashActivityQuery: [
    { ...paramTypes.period },
    { ...paramTypes.modificationDateRange },
    { ...paramTypes.includeAllGLActivity },
  ],
  WebGLDistributionLineQuery: [
    { ...paramTypes.period },
    { ...paramTypes.modificationDateRange },
    { ...paramTypes.maxResults },
    { ...paramTypes.entrySources },
    { ...paramTypes.excludeTemplateEntries },
  ],
  WebGLPositionQuery: [
    { ...paramTypes.generalLedgerIds },
    { ...paramTypes.accountingMethod },
    { ...paramTypes.period },
  ],
  WebGLTrialBalancesQuery: [
    { ...paramTypes.generalLedgerIds },
    { ...paramTypes.accountingMethod },
    { ...paramTypes.period },
  ],
  WebTradeBlotterQuery: [
    { ...paramTypes.portfolioGroupIds },
    { ...paramTypes.instrumentIds },
    { ...paramTypes.instrumentTypes },
    { ...paramTypes.valueDate },
    { ...paramTypes.maxResults },
  ],
  WebTradeValuationReportQuery: [
    { ...paramTypes.instrumentIds },
    { ...paramTypes.financialSubTypes },
    { ...paramTypes.valueDatePeriodSelection },
    { ...paramTypes.benchmarkNAVSource },
    { ...paramTypes.benchmarkCalculationType },
    { ...paramTypes.portfolioAnalyticsSources },
    { ...paramTypes.fundBenchmarkType },
    { ...paramTypes.includeUnderlyings },
  ],
  WebBookReportQuery: [],
  WebCustodianAccountQuery: [],
  WebQuotesQuery: [
    { ...paramTypes.quoteSetIds },
    { ...paramTypes.sourceIds },
    { ...paramTypes.fromDate },
    { ...paramTypes.toDate },
    { ...paramTypes.assetMeasures },
    { ...paramTypes.instrumentIds },
  ],
  WebInvestmentReportQuery: [
    { ...paramTypes.valueDate },
    { ...paramTypes.shareClassIds },
  ],
  WebComplianceBlotterQuery: [
    { ...paramTypes.dateRange, name: "orderDateRange" } as ParamConfig,
    { ...paramTypes.assignedTraderIds },
    { ...paramTypes.complianceStates },
    { ...paramTypes.maxResults },
  ],
  WebCorporateActionReportQuery: [
    { ...paramTypes.dateRange, name: "effectiveDateRange" } as ParamConfig,
    { ...paramTypes.corporateActionTypes },
  ],
};
