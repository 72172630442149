import { PDF_DASHBOARD_CACHE_CHANNEL_NAME } from "@app-views/dashboards/constants";
import {
  DASHBOARD_CONTEXT_CHANNEL,
  DASHBOARD_CONTEXT_CHANNEL_NAME,
} from "@enfusion-ui/dashboards";
import {
  CoreBroadcastChannelInterface,
  createCoreBroadcastChannel,
} from "@enfusion-ui/hooks";
import {
  AUTH_CHANNEL,
  AUTH_CHANNEL_NAME,
  OEMS_EVENTS,
  PWB_EVENTS,
  SEARCH_EVENTS,
  WATCHLIST_EVENTS,
} from "@enfusion-ui/web-core";
import { noop } from "lodash";
import * as React from "react";

import { EXPLORER_EVENTS_CHANNEL_NAME } from "../explorer/explorerEvents/context";

const CHANNELS = {
  [AUTH_CHANNEL_NAME]: AUTH_CHANNEL,
  [DASHBOARD_CONTEXT_CHANNEL_NAME]: DASHBOARD_CONTEXT_CHANNEL,
  [EXPLORER_EVENTS_CHANNEL_NAME]: createCoreBroadcastChannel(
    EXPLORER_EVENTS_CHANNEL_NAME,
    { webWorkerSupport: false }
  ),
  [OEMS_EVENTS]: createCoreBroadcastChannel(OEMS_EVENTS, {
    webWorkerSupport: false,
  }),
  [SEARCH_EVENTS]: createCoreBroadcastChannel(SEARCH_EVENTS, {
    webWorkerSupport: false,
  }),
  [PDF_DASHBOARD_CACHE_CHANNEL_NAME]: createCoreBroadcastChannel(
    PDF_DASHBOARD_CACHE_CHANNEL_NAME,
    { webWorkerSupport: false }
  ),
  [PWB_EVENTS]: createCoreBroadcastChannel(PWB_EVENTS, {
    webWorkerSupport: false,
  }),
  [WATCHLIST_EVENTS]: createCoreBroadcastChannel(WATCHLIST_EVENTS, {
    webWorkerSupport: false,
  }),
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useBroadcastChannel<T = any>(
  name: keyof typeof CHANNELS,
  cb?: (msg: T, channel: CoreBroadcastChannelInterface<T>) => void
) {
  React.useEffect(() => {
    let undoSub = noop;
    if (cb) {
      undoSub = CHANNELS[name]?.subscribe((msg) =>
        cb(msg, CHANNELS[name] as CoreBroadcastChannelInterface<T>)
      );
    }
    return () => {
      undoSub?.();
    };
  }, [cb, name]);

  return React.useMemo(
    () => CHANNELS[name] as CoreBroadcastChannelInterface<T>,
    [name]
  );
}
