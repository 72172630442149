import { OEMSRoutesContextState, useOEMSRoutes } from "@enfusion-ui/web-core";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import * as React from "react";

const useRoutes = () => {
  const [selectedRouteOrderId, setSelectedRouteOrderId] = React.useState<
    number | undefined
  >();

  const routesContext: OEMSRoutesContextState = useOEMSRoutes();

  React.useEffect(() => {
    /*
     * If no routes are seen, on change of order id, ensure to check for Fills
     */
    if (
      routesContext &&
      routesContext.orderId &&
      (!routesContext.routes || routesContext.routes.length === 0)
    ) {
      setSelectedRouteOrderId(routesContext.orderId);
    }
  }, [routesContext.orderId]);

  const gridApi = React.useRef<GridApi>();
  const onGridReady = ({ api }: GridReadyEvent) => {
    gridApi.current = api;
  };

  return {
    setSelectedRouteOrderId,
    selectedRouteOrderId,
    gridApi,
    onGridReady,
    routes: routesContext.routes,
    loading: routesContext.loading,
    refetch: routesContext.refetchRoutes,
  };
};

export default useRoutes;
