import { SelectOptionsType } from "@enfusion-ui/types";
import { Select } from "@enfusion-ui/web-components";
import * as React from "react";

const options = [
  { label: "String", value: "string" },
  { label: "Number", value: "number" },
];

export const GenericInputTypeSelect: React.FC<{
  value: string | null;
  onChange: (option: SelectOptionsType<string> | null) => void;
  label?: string;
  defaultValue?: string;
}> = ({ value, onChange, label = "Value Type", defaultValue = "string" }) => {
  return (
    <Select
      label={label}
      value={options.find(({ value: val }) => value === val)}
      options={options}
      defaultValue={options.find(({ value: val }) => defaultValue === val)}
      onChange={onChange}
      clearable={false}
      inputId="generic-input-type-selection-id"
    />
  );
};
