import { TradeTicketFormValues } from "@enfusion-ui/trades";
import { ControlledSelect } from "@enfusion-ui/web-components";
import * as React from "react";
import { useWatch } from "react-hook-form";

import { StyledFormElementHeader } from "../../../components/styledComponents";

export const PNLAdjustmentAccountsSection: React.FC = () => {
  const fees = useWatch<TradeTicketFormValues["fees"]>({ name: "fees" });

  const [enableAllAccounts, enableRealizedAccountOnly] = React.useMemo(() => {
    if (!fees) return [false, false];
    let uar = false;
    let rlzd = false;
    for (const f of fees) {
      if (!uar && f.type === "PNLUnrlzdAndRlzdAdjustment") uar = true;
      if (!rlzd && f.type === "PNLRealizedAdjustment") rlzd = true;
      if (uar && rlzd) break;
    }

    return [uar && !rlzd, !uar && rlzd];
  }, [fees]);

  return (
    <>
      <StyledFormElementHeader>
        P&L Adjustment GL Accounts
      </StyledFormElementHeader>

      <ControlledSelect
        placeholder="None"
        name="assetLiabilityAccrualAccount"
        label="Asset/Liability Accrual Account"
        options={[]} // TODO: verify options
        disabled={!enableAllAccounts}
        inputId="asset-liability-accrual-account-select"
      />

      <ControlledSelect
        placeholder="None"
        name="incomeExpenseAccrualAccount"
        label="Income/Expense Accrual Account"
        options={[]} // TODO: verify options
        disabled={!enableAllAccounts}
        inputId="income-expense-accrual-account-select"
      />

      <ControlledSelect
        placeholder="None"
        name="incomeExpenseRealizedAccount"
        label="Income/Expense Realized Account"
        options={[]} // TODO: verify options
        inputId="income-expense-realized-account-select"
        disabled={!(enableAllAccounts || enableRealizedAccountOnly)}
      />
    </>
  );
};
