import FileErrorBoundary from "@app-components/ErrorBoundary/FileErrorBoundary";
import { useReconciliation } from "@app-context/reconciliation/context";
import { ReconciliationProvider } from "@app-context/reconciliation/ReconciliationProvider";
import { useReportRows } from "@app-context/reports/ReportsProvider";
import { WebReconciliationReportMetadata } from "@enfusion-ui/types";
import React from "react";

import { SectionContainer } from "./components/styles";
import TopActionBar from "./components/TopActionBar";
import { SnapshotDetailsPanel } from "./panels/SnapshotDetailsPanel";
import TopSummaryPanel from "./panels/TopSummaryPanel";
import { ReconciliationRowsStore } from "./types";
import {
  getCashActivityReportId,
  getCashBalanceReportId,
  isCashDataSource,
  populateDiffAndJoinColumnValues,
} from "./utils/commonUtils";

const RECONCILIATION_FILE_PATH = "shared/recon-dashboard.json";

const ReconciliationContent: React.FC = () => {
  const [reconciliationRowStore, setReconciliationRowStore] =
    React.useState<ReconciliationRowsStore>({});

  const { config, metadata, loading } = useReconciliation();
  const { rowsStore } = useReportRows();

  React.useEffect(() => {
    let result: ReconciliationRowsStore = {};
    config.reconReports.forEach((reconConfig) => {
      const reportId = reconConfig.reconReportId;
      if (isCashDataSource(reconConfig.reconciliationType)) {
        const cashActivityReportId = getCashActivityReportId(reportId);
        const cashBalanceReportId = getCashBalanceReportId(reportId);

        result = {
          ...result,
          [cashActivityReportId]: populateDiffAndJoinColumnValues(
            rowsStore[cashActivityReportId]?.diffRows,
            metadata?.[cashActivityReportId]
              ?.metadata as WebReconciliationReportMetadata
          ),
          [cashBalanceReportId]: populateDiffAndJoinColumnValues(
            rowsStore[cashBalanceReportId]?.diffRows,
            metadata?.[cashBalanceReportId]
              ?.metadata as WebReconciliationReportMetadata
          ),
        } as ReconciliationRowsStore;
      } else {
        result = {
          ...result,
          [reportId]: populateDiffAndJoinColumnValues(
            rowsStore[reconConfig.reconReportId]?.diffRows,
            metadata?.[reconConfig.reconReportId]
              ?.metadata as WebReconciliationReportMetadata
          ),
        } as ReconciliationRowsStore;
      }
    });
    setReconciliationRowStore(result);
  }, [rowsStore, config.reconReports]);

  return (
    <FileErrorBoundary
      loading={loading}
      section="Dashboards"
      filePath={RECONCILIATION_FILE_PATH}
    >
      <SectionContainer>
        <TopActionBar reconciliationRowStore={reconciliationRowStore} />
        <TopSummaryPanel />
        <SnapshotDetailsPanel reconciliationRowStore={reconciliationRowStore} />
      </SectionContainer>
    </FileErrorBoundary>
  );
};

const ReconciliationView: React.FC = () => {
  return (
    <ReconciliationProvider>
      <ReconciliationContent />
    </ReconciliationProvider>
  );
};

export default ReconciliationView;
