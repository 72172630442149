import { FormTableListControl, useRefCallback } from "@enfusion-ui/hooks";
import { GetContextMenuItemsParams, MenuItemDef } from "ag-grid-community";

export function useFormTableListContext<T>(
  control: FormTableListControl<T>,
  getContextMenuItems: (params: GetContextMenuItemsParams) => {
    pre?: (string | MenuItemDef)[];
    post?: (string | MenuItemDef)[];
  } = () => ({}),
  deps: React.DependencyList = []
) {
  return useRefCallback((params: GetContextMenuItemsParams) => {
    const selectedIds = params.api
      .getSelectedNodes()
      .map((node) => node.data.__row_id);
    const nodeId = params.node?.data.__row_id;
    const otherItems = getContextMenuItems(params);

    return [
      ...(otherItems?.pre ?? []),
      ...control.getContextMenuItems(selectedIds, nodeId),
      ...(otherItems?.post ?? []),
    ];
  }, deps);
}
