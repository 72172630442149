import {
  EntrySourceSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { GeneralLedgerEntrySource } from "@enfusion-ui/types";
import { FormSectionAccordion, TextInput } from "@enfusion-ui/web-components";
import * as React from "react";

import { EntrySourceSelect } from "./EntrySourceSelect";

const EntrySourceSelectionEditWidget: React.FC<unknown> = () => {
  const { unsavedConfig, config, changeConfigKeyToBeApplied } = useWidget();

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange =
    (key: string) => (option: GeneralLedgerEntrySource[] | null) => {
      changeConfigKeyToBeApplied(key, option);
    };

  const { defaultValue = null, key = "Entry Source" } =
    config as EntrySourceSelectionWidgetConfig;

  return (
    <FormSectionAccordion title="Entry Source Options">
      <TextInput
        label="Key"
        value={unsavedConfig?.key || key}
        name="key"
        onChange={handleTextChange("key")}
      />
      <EntrySourceSelect
        value={unsavedConfig?.defaultValue || defaultValue}
        label="Default Entry Source"
        onChange={handleBasicChange("defaultValue")}
      />
    </FormSectionAccordion>
  );
};

export default EntrySourceSelectionEditWidget;
