/* eslint-disable @typescript-eslint/no-explicit-any */

// #region -- Imports & Styles
import FileErrorBoundary from "@app-components/ErrorBoundary/FileErrorBoundary";
import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import { useOEMSOrderForm } from "@enfusion-ui/core";
import {
  DASHBOARD_CONTEXT_CHANNEL_NAME,
  DASHBOARD_CONTEXT_REFRESH,
  DASHBOARD_CONTEXT_REVERT,
  DashboardContextChannelEvent,
  DashboardDataV2,
  DashboardInternalStorageState,
  DashboardProvider,
  StoreDashboardArgs,
  useDashboard,
  WidgetDefinitionCategory,
} from "@enfusion-ui/dashboards";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import {
  DashboardRevertMode,
  DashboardRoot,
  DashboardViewProps,
} from "@enfusion-ui/types";
import {
  replaceDoubleSlashWithSingleSlash,
  trimSlash,
} from "@enfusion-ui/utils";
import {
  ContentMessage,
  EmptyView,
  useConfirmationModal,
  useNavBarState,
} from "@enfusion-ui/web-components";
import {
  errorToast,
  REST_API,
  SCROLL_BAR_WIDTH_AFTER_BORDER,
  styled,
  successToast,
  useAuth,
  useContextMenu,
  useReports,
  useTabs,
  useThisTab,
  useTrackUnsavedChanges,
} from "@enfusion-ui/web-core";
import { faSkullCrossbones } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { DashboardViewActionBar } from "./components/ViewActionBar";
import { SaveDashboardModal } from "./components/ViewModals";
import Dashboard from "./core/Dashboard";
import { getNewDashboardTabContent } from "./core/utils";
import { WIDGET_COMPONENT_DEFINITIONS } from "./widgets/definitions";

export const ContentMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .highcharts-container * {
    font-family: var(--default-font);
  }

  *::-webkit-scrollbar {
    height: ${SCROLL_BAR_WIDTH_AFTER_BORDER}px;
    width: ${SCROLL_BAR_WIDTH_AFTER_BORDER}px;
    border-radius: ${SCROLL_BAR_WIDTH_AFTER_BORDER}px;
    background-color: var(--background-color-2);
  }

  .ag-floating-top::-webkit-scrollbar {
    background-color: transparent;
  }
`;
// #endregion

const getFolderName = (filePath: string, name: string): string => {
  const fullyQualifiedPath =
    "/" +
    replaceDoubleSlashWithSingleSlash(filePath.split("/").slice(1).join("/"));

  let folder = fullyQualifiedPath;
  if (fullyQualifiedPath.endsWith(`${name}.json`)) {
    folder = folder.slice(0, 0 - (name.length + 5));
  }

  return replaceDoubleSlashWithSingleSlash(`/${folder}/`);
};

type OverwriteArgs = {
  newFileName: string;
  newFilePath: string;
  newRootPath: DashboardRoot;
  reopenTab?: boolean;
};

export const DashboardViewCore = React.memo(
  ({
    category,
    showSearchBar,
  }: {
    category: WidgetDefinitionCategory;
    showSearchBar?: boolean;
  }) => {
    // #region -- Variables
    const isMounted = useMounted();
    const { onTabWillClose, closeTab } = useThisTab();
    const { isAdmin } = useAuth();
    const [saveModalOpen, setSaveModalOpen] = React.useState(false);

    const { handleContextEvent } = useContextMenu();
    const { setValue } = useFormContext() ?? {};
    const { custom } = useOEMSOrderForm();
    const { openTab } = useTabs();

    const {
      pdf,
      hasUnsavedChanges,
      filePath,
      setRevertMode,
      startEditMode,
      stopEditMode,
      configVersion,
      instanceId,
      reload,
      loading,
      config,
      datasources,
      settings,
      name,
      version,
      editMode,
      canEdit,
      revertChanges,
      clearAllWidgets,
      save,
      root,
      revertMode,
      refresh,
      error,
      hydrate,
      fileMissing,
    } = useDashboard({
      onClearStorage: () => {
        clearInternalStorage();
      },
    });

    (window as any).showDashboardConfig = () =>
      console.info("dashboard config", { config, datasources, settings });

    const dashboardChannel = useBroadcastChannel<DashboardContextChannelEvent>(
      DASHBOARD_CONTEXT_CHANNEL_NAME
    );

    const explorerChannel = useExplorerEvents("Dashboards");

    const handleContextMenu = useRefCallback(
      (e: any) => {
        if (editMode) handleContextEvent(e);
      },
      [editMode, handleContextEvent]
    );
    // #endregion

    // #region -- Edit Mode
    const handleExitEditMode = () => {
      if (hasUnsavedChanges) {
        setRevertMode(DashboardRevertMode.CLOSE_EDIT);
        RevertChangesModal.openModal();
      } else {
        try {
          stopEditMode();
        } catch (e: any) {
          errorToast(e.message);
        }
      }
    };
    // #endregion

    // #region -- Internal Storage
    const [unsavedState, setUnsavedState] =
      React.useState<DashboardInternalStorageState | null>(null);

    const clearInternalStorage = () => setUnsavedState(null);

    const loadFromInternalStorage = React.useCallback(() => {
      if (unsavedState) {
        hydrate({
          config: unsavedState.config,
          datasources: unsavedState.datasources,
          version: unsavedState.version,
          settings: unsavedState.settings,
          name: unsavedState.name,
          configVersion: configVersion,
          instanceId: instanceId || uuidv4(),
          hasUnsavedChanges: true,
        });
      }
    }, [hydrate, unsavedState, configVersion, instanceId]);

    // Save to internal storage on every change
    React.useEffect(() => {
      if (hasUnsavedChanges && !pdf) {
        setUnsavedState({
          config,
          datasources,
          settings,
          name,
          version,
          instanceId,
        });
      }
    }, [hasUnsavedChanges, config, datasources, settings, name, version, pdf]);

    // Handle changes from a different window
    React.useEffect(() => {
      const hasChangesInOtherWindow =
        unsavedState && unsavedState.instanceId !== instanceId;

      if (!loading && hasChangesInOtherWindow && canEdit && !pdf) {
        UnsavedChangesModal.openModal();
      }
    }, [unsavedState, canEdit, pdf, loading]);

    React.useEffect(() => {
      if (setValue) setValue("customOrderEditMode", editMode);
    }, [editMode]);
    // #endregion

    // #region -- Unsaved Changes Modal
    const continueEditing = useRefCallback(async () => {
      loadFromInternalStorage();
      startEditMode();
    }, [loadFromInternalStorage, startEditMode]);

    const discardUnsavedChanges = useRefCallback(async () => {
      reload();
      clearInternalStorage();
      startEditMode();
    }, [reload, clearInternalStorage, startEditMode]);

    const UnsavedChangesModal = useConfirmationModal({
      onSubmit: continueEditing,
      onCancel: discardUnsavedChanges,
      allowDismissal: false,
      title: "You have unsaved changes",
      submitButtonText: "Continue Editing",
      submitActionTheme: "warning",
      cancelButtonText: "Revert to Saved",
      renderContent: () =>
        "Do you want to revert all changes to last saved state?",
    });
    // #endregion

    // #region -- Revert Modal
    const closeRevertModal = () => {
      setRevertMode(DashboardRevertMode.REVERT);
    };

    const handleRevert = useRefCallback(() => {
      clearInternalStorage();
      dashboardChannel.broadcastMessage({
        action: DASHBOARD_CONTEXT_REVERT,
        path: filePath.replace(/\//g, "-"),
      });
      revertChanges();
      closeRevertModal();
    }, [dashboardChannel, revertChanges, filePath]);

    const handleRevertDefault = useRefCallback(() => {
      revertChanges(DashboardRevertMode.REVERT_DEFAULT);
      RevertToDefaultModal.closeModal();
    }, [revertChanges]);

    const RevertChangesModal = useConfirmationModal({
      onSubmit: handleRevert,
      onCancel: closeRevertModal,
      title:
        revertMode !== DashboardRevertMode.REVERT
          ? "Discard Unsaved Changes?"
          : "Revert All Changes?",
      submitActionTheme: "warning",
      renderContent: () =>
        `Do you want to revert all changes to last saved state${
          revertMode !== DashboardRevertMode.REVERT
            ? ` and ${
                revertMode === DashboardRevertMode.CLOSE_TAB
                  ? "close"
                  : "exit edit mode"
              }`
            : ""
        }?`,
    });

    const RevertToDefaultModal = useConfirmationModal({
      onSubmit: handleRevertDefault,
      title: "Revert All Changes to default?",
      submitActionTheme: "warning",
      renderContent: () => (
        <>
          Are you sure you want to revert to the default layout?
          <br />
          All changes done will be discarded.
        </>
      ),
    });

    // Make sure to not close a tab if has unsaved changes
    React.useEffect(() => {
      return onTabWillClose(() => {
        if (
          !pdf &&
          !custom &&
          hasUnsavedChanges &&
          canEdit &&
          revertMode !== DashboardRevertMode.CLOSE_TAB
        ) {
          if (isMounted()) {
            setRevertMode(DashboardRevertMode.CLOSE_TAB);
            RevertChangesModal.openModal();
          }
          return false;
        }
        return true;
      });
    }, [hasUnsavedChanges, canEdit, onTabWillClose, isMounted, revertMode]);

    useTrackUnsavedChanges(hasUnsavedChanges);
    // #endregion

    // #region -- Refresh Modal
    const refreshView = useRefCallback(() => {
      // Revert to last saved state before re-mounting the component
      refresh();
      dashboardChannel.broadcastMessage({
        action: DASHBOARD_CONTEXT_REFRESH,
        path: filePath.replace(/\//g, "-"),
      });
      clearInternalStorage();
    }, [dashboardChannel, refresh, hasUnsavedChanges, filePath]);

    const RefreshModal = useConfirmationModal({
      onSubmit: refreshView,
      title: "Proceed with Refresh?",
      submitActionTheme: "warning",
      renderContent: () => (
        <>
          Refreshing will discard all unsaved changes.
          <br />
          Do you want to continue?
        </>
      ),
    });
    // #endregion

    // #region -- Clear All Widgets Modal
    const ClearAllWidgetsModal = useConfirmationModal({
      onSubmit: clearAllWidgets,
      title: "Clear dashboard?",
      submitActionTheme: "warning",
      renderContent: () => (
        <>
          Clearing will remove all the widgets.
          <br />
          Do you want to continue?
        </>
      ),
    });
    // #endregion

    // #region -- Save Modal
    const openSaveAsModal = () => setSaveModalOpen(true);
    const closeSaveModal = () => setSaveModalOpen(false);

    const handleSave = useRefCallback(
      async (
        newFileName: string,
        newFilePath: string,
        newRoot: DashboardRoot,
        forceWrite?: boolean,
        reopenTab?: boolean
      ) => {
        const path = `/${newFilePath}${newFileName}.json`;
        try {
          clearInternalStorage();
          await save(newFileName, newFilePath, newRoot, forceWrite);
          closeSaveModal();
          if (reopenTab) {
            explorerChannel.broadcast(newRoot, ExplorerEventsAction.Refetch);
            closeTab?.();
            openTab?.(
              getNewDashboardTabContent({
                root: newRoot,
                path: replaceDoubleSlashWithSingleSlash(`${newRoot}${path}`),
                name: newFileName,
              })
            );
          }
        } catch (err: any) {
          setUnsavedState(unsavedState);
          if (!forceWrite) {
            OverwriteModal.openModal({
              newFileName,
              newFilePath,
              newRootPath: newRoot,
              reopenTab,
            });
          } else {
            errorToast(err.message || "Failed to save dashboard changes");
          }
        }
      },
      [save, unsavedState, explorerChannel]
    );

    const openSaveModal = useRefCallback(async () => {
      // Open DashboardSaveForm when dashboard is not saved -0 identified by state.name as undefined
      if (!name) {
        setSaveModalOpen(true);
      } else {
        await handleSave(
          name,
          getFolderName(filePath, name),
          root || "user",
          true
        );
      }
    }, [name, filePath, root]);

    const handleSaveAsDefault = async () => {
      if (isAdmin()) {
        const folderName = getFolderName(filePath, name);
        await handleSave(name, folderName, "user", true);
        await handleSave(name, folderName, "shared", true);
      }
    };

    const SaveAsDefaultModal = useConfirmationModal({
      onSubmit: handleSaveAsDefault,
      title: "Save as default",
      submitActionTheme: "warning",
      renderContent: () => (
        <>
          Do you want to save this layout as default?
          <br />
          This action will overwrite the default layout.
        </>
      ),
    });
    // #endregion

    // #region -- Overwrite Modal
    const handleOverwriteDashboard = useRefCallback(
      (args: OverwriteArgs | null) => {
        if (args) {
          handleSave(
            args.newFileName,
            args.newFilePath,
            args.newRootPath,
            true,
            args.reopenTab
          );
        }
      },
      [handleSave]
    );

    const OverwriteModal = useConfirmationModal<OverwriteArgs>({
      onSubmit: handleOverwriteDashboard,
      title: "Overwrite Dashboard",
      submitActionTheme: "danger",
      renderContent: (args) => (
        <>
          Are you sure you want to force write to{" "}
          {trimSlash(`${args?.newFilePath}${args?.newFileName}`)}?
          <br />
          You can not undo this action.
        </>
      ),
    });
    // #endregion

    return (
      <FileErrorBoundary
        loading={loading}
        section="Dashboards"
        filePath={filePath}
        fileMissingError={fileMissing}
        errorContent={
          error && (
            <EmptyView>
              <ContentMessage
                icon={faSkullCrossbones}
                message="Something went wrong loading the dashboard."
              />
            </EmptyView>
          )
        }
      >
        <ContentMenuWrapper onContextMenu={handleContextMenu}>
          <DashboardViewActionBar
            category={category}
            editMode={editMode}
            canEdit={canEdit}
            hasUnsavedChanges={hasUnsavedChanges}
            refreshView={refreshView}
            openSaveModal={openSaveModal}
            startEditMode={startEditMode}
            exitEditMode={handleExitEditMode}
            openSaveAsModal={openSaveAsModal}
            openRefreshModal={RefreshModal.openModal}
            openRevertModal={RevertChangesModal.openModal}
            openSaveAsDefaultModal={SaveAsDefaultModal.openModal}
            openRevertDefaultModal={RevertToDefaultModal.openModal}
            openClearAllWidgetsModal={ClearAllWidgetsModal.openModal}
          />
          <Dashboard category={category} showSearchBar={showSearchBar} />
          <SaveDashboardModal
            root={root}
            fileName={name}
            filePath={filePath}
            open={saveModalOpen}
            onClose={closeSaveModal}
            onSubmit={(fileName, filePath, rootPath) =>
              handleSave(fileName, filePath, rootPath, false, !!name)
            }
          />
          {UnsavedChangesModal.content}
          {RevertChangesModal.content}
          {RefreshModal.content}
          {ClearAllWidgetsModal.content}
          {SaveAsDefaultModal.content}
          {RevertToDefaultModal.content}
          {OverwriteModal.content}
        </ContentMenuWrapper>
      </FileErrorBoundary>
    );
  }
);

const loadDashboard = (filePath: string) =>
  REST_API.DASHBOARD.DOWNLOAD<DashboardDataV2>(filePath);

const storeDashboard = (params: StoreDashboardArgs) =>
  REST_API.DASHBOARD.STORE_DASHBOARD(params);

const DashboardView: React.FC<DashboardViewProps> = React.memo(({ config }) => {
  const { user, hasPerm, isUserType, isPoId } = useAuth();
  const { loadReport } = useReports();
  const { onSave } = useNavBarState();
  const { renameTab, closeTab } = useThisTab();

  const handleSave = React.useCallback(() => {
    successToast("Saved changes to dashboard.");
    onSave();
  }, [onSave]);

  return (
    <DashboardProvider
      loadDashboard={loadDashboard}
      storeDashboard={storeDashboard}
      user={user}
      hasPerm={hasPerm}
      isPoId={isPoId}
      isUserType={isUserType}
      loadReport={loadReport}
      onSave={handleSave}
      renameTab={renameTab}
      closeTab={closeTab}
      widgetDefinitions={WIDGET_COMPONENT_DEFINITIONS}
      {...config}
    >
      <DashboardViewCore showSearchBar category="dashboard" />
    </DashboardProvider>
  );
});

export default DashboardView;
