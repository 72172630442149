import { ValueOf } from "@enfusion-ui/types";

export const analyticsRollUpValues = {
  Issuer: "Issuer",
  Instrument: "Instrument",
  Trade: "Trade",
  Ticker: "Ticker",
} as const;
export type AnalyticsRollUp = ValueOf<typeof analyticsRollUpValues>;

export const analyticsBenchmarkValues = {
  "S&P 500": "SP500",
} as const;
export type AnalyticsBenchmark = ValueOf<typeof analyticsBenchmarkValues>;

export const analyticsTopNValues = {
  "10": 10,
  "25": 25,
  "50": 50,
  "100": 100,
} as const;
export type AnalyticsTopN = ValueOf<typeof analyticsTopNValues>;

export const getTimeMap = (val?: string) => {
  if (!val) return "Daily";
  if (val === "ITD" || val.includes("Y")) return "YTD";
  if (val.includes("M")) return "MTD";
  if (val.includes("Q")) return "QTD";
  return "Daily";
};

export const analyticsTimeFrameValues = {
  DTD: "DTD",
  YDAY: "Yesterday",
  WTD: "WTD",
  "1W": "OneWeek",
  MTD: "MTD",
  "1M": "OneMonth",
  QTD: "QTD",
  "1Q": "OneQuarter",
  YTD: "YTD",
  "1Y": "OneYear",
  ITD: "ITD",
} as const;
export type AnalyticsTimeFrame = ValueOf<typeof analyticsTimeFrameValues>;

export const analyticsFrequencyValues = {
  Annual: "Annual",
  Semiannual: "Semiannual",
  Quarterly: "Quarterly",
  Bimonthly: "Bimonthly",
  Monthly: "Monthly",
  Weekly: "Weekly",
  Biweekly: "Biweekly",
  Daily: "Daily",
  Term: "Term",
  "28 Day": "TwentyEightDay",
  "35 Day": "ThirtyFiveDay",
  "42 Day": "FortyTwoDay",
  "91 Day": "NinetyOneDay",
  "182 Day": "OneEightyTwoDay",
} as const;
export type AnalyticsFrequency = ValueOf<typeof analyticsFrequencyValues>;

export const analyticsPivotsPerformanceValues = {
  Returns: "Returns",
  PnL: "PnL",
  Contribution: "Contribution",
  "Batting Avg.": "BattingAvg",
} as const;
export type AnalyticsPivotsPerformance = ValueOf<
  typeof analyticsPivotsPerformanceValues
>;

export const analyticsPivotsRiskValues = {
  Liquidity: "Liquidity",
  Exposure: "Exposure",
  Volatility: "Volatility",
  Drawdown: "Drawdown",
} as const;
export type AnalyticsPivotsRisk = ValueOf<typeof analyticsPivotsRiskValues>;

export type TopNGridEntry = {
  instrumentId: number;
  description: string;
  ticker: string;
  exchange: string | null;
  totalPnl: number;
  return: number;
  exposure: number;
  currency: string;
};

export type PerformanceDetailsInstrumentValue = {
  id: number;
  currency: string;
  dataPnL: Array<[number, number]>;
  dataReturns: Array<[number, number]>;
};
