/* eslint-disable @typescript-eslint/no-explicit-any */
import { StorageRoot } from "@enfusion-ui/types";
import {
  getBlobFromXHR,
  getStorageFileUrl,
  getXHRContent,
} from "@enfusion-ui/web-core";

export const downloadStorageFile = async (
  root: StorageRoot,
  filePath: string
) => {
  const xhr = await getXHRContent(getStorageFileUrl(root, filePath));
  const { filename, blob } = getBlobFromXHR(xhr);

  if (typeof (window.navigator as any).msSaveBlob !== "undefined") {
    (window.navigator as any).msSaveBlob(blob, filename);
  } else {
    const URL = window.URL || window.webkitURL;
    const downloadUrl = URL.createObjectURL(blob);

    if (filename) {
      // use HTML5 a[download] attribute to specify filename
      const a = document.createElement("a");
      // safari doesn't support this yet
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    } else {
      window.location.href = downloadUrl;
    }
    setTimeout(function () {
      URL.revokeObjectURL(downloadUrl);
    }, 100); // cleanup
  }
};
