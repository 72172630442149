import { getXHRContent, statusText, useTheme } from "@enfusion-ui/web-core";
import { AgGridReact } from "ag-grid-react";
import Papa from "papaparse";
import * as React from "react";
import { Spinner } from "../../display";
import { ContentWrapper } from "./styles";
import { PreviewInternalWrapper, PreviewWrapper, SheetContainer, } from "./XLSX/XLSXPreview";
function structureData(res) {
    const columnKeys = res[0];
    let extraCount = 0;
    const rows = [];
    for (let idx = 1; idx < res.length; idx += 1) {
        let row = {};
        const entry = res[idx];
        if (entry) {
            for (let rowIdx = 0; rowIdx < entry.length; rowIdx += 1) {
                let field = columnKeys[rowIdx];
                if (!field) {
                    extraCount += 1;
                    field = `Extra ${extraCount}`;
                    columnKeys.push(field);
                }
                row[field] = entry[rowIdx];
            }
            rows.push(row);
        }
    }
    return { rows, columnKeys };
}
export const CSVPreview = ({ filePath, setError, }) => {
    const { theme } = useTheme();
    const [res, setRes] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        const readFile = async () => {
            setLoading(true);
            try {
                const req = await getXHRContent(filePath, "text");
                if (req.status >= 200 && req.status <= 299) {
                    Papa.parse(req.responseText, {
                        header: false,
                        complete: (res) => {
                            const errors = (res.errors || []).filter((i) => !["UndetectableDelimiter"].includes(i.code));
                            if (errors.length > 0) {
                                setError(errors.map((i) => i.message).join(", "));
                            }
                            else {
                                const { rows, columnKeys } = structureData(res.data);
                                setRes({
                                    rows,
                                    columnDefs: columnKeys.map((field) => ({
                                        field,
                                    })),
                                });
                            }
                        },
                    });
                }
                else {
                    setError(statusText(req.status));
                }
            }
            catch (err) {
                console.warn("filed to handle csv file", err);
                setError(err.message);
            }
            finally {
                setLoading(false);
            }
        };
        readFile();
    }, []);
    if (loading || !res) {
        return (React.createElement(ContentWrapper, null,
            React.createElement(Spinner, null)));
    }
    return (React.createElement(PreviewWrapper, null,
        React.createElement(PreviewInternalWrapper, null,
            React.createElement(SheetContainer, { className: theme.agGridClass, isActive: true },
                React.createElement(AgGridReact, { suppressCellFocus: true, suppressRowHoverHighlight: true, suppressBrowserResizeObserver: true, columnDefs: res.columnDefs, multiSortKey: "ctrl", defaultColDef: {
                        resizable: true,
                        autoHeight: true,
                        wrapText: true,
                    }, rowData: res.rows, overlayNoRowsTemplate: "Empty csv file." })))));
};
