import { createContext, useContext } from "react";

import { FilterFieldMode } from "./ReportQueryFormWidget";

export type ReportWidgetQueryFormContextState = {
  defaultFieldMode?: FilterFieldMode;
  allowFieldModeChange?: boolean;
};

export const ReportWidgetQueryFormContext =
  createContext<ReportWidgetQueryFormContextState>({});

export function useReportWidgetQueryFormContext() {
  const context = useContext(ReportWidgetQueryFormContext);
  if (context === undefined) {
    throw new Error(
      "useReportWidgetQueryFormContext must be used within a ReportWidgetQueryFormProvider"
    );
  }
  return context;
}
