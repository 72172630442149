import { useRefCallback } from "@enfusion-ui/hooks";
import React from "react";
export const useDataHandlers = () => {
    const dataHandlers = React.useRef({});
    const missedDataHandlers = React.useRef([]);
    const triggerDataHandler = useRefCallback((handler, args) => {
        const action = dataHandlers.current[handler];
        if (action) {
            action(...args);
        }
        else {
            missedDataHandlers.current.push({ handler, args });
        }
    }, []);
    const replayMissedDataHandlers = useRefCallback(() => {
        missedDataHandlers.current = missedDataHandlers.current.reduce((res, entry) => {
            const handler = dataHandlers.current?.[entry.handler];
            if (!handler) {
                res.push(entry);
            }
            else {
                triggerDataHandler(entry.handler, entry.args);
            }
            return res;
        }, []);
    }, [missedDataHandlers]);
    const registerHandlers = useRefCallback((handlers) => {
        dataHandlers.current = { ...dataHandlers.current, ...handlers };
        replayMissedDataHandlers();
    }, [dataHandlers]);
    const resetDataHandlers = useRefCallback(() => {
        dataHandlers.current = {};
        missedDataHandlers.current = [];
    }, []);
    return {
        dataHandlers,
        triggerDataHandler,
        registerHandlers,
        resetDataHandlers,
    };
};
