import { RoundedDataGrid } from "@app-components/DataGrid";
import { useInterTabContext } from "@app-context/InterTab/InterTabContextProvider";
import { createRelatedPositionsMockData } from "@enfusion-ui/trades";
import { formatCurrency, formatNumber } from "@enfusion-ui/utils";
import { TabTopActionBar, ViewContainer } from "@enfusion-ui/web-components";
import { ColDef } from "ag-grid-community";
import React from "react";

const colDefs: ColDef[] = [
  {
    field: "description",
    headerName: "Description",
    type: "standardColumn",
  },
  {
    field: "custodian",
    headerName: "Custodian",
    type: "standardColumn",
  },
  {
    field: "account",
    headerName: "Account",
    type: "standardColumn",
  },
  {
    field: "strategyBook",
    headerName: "Strategy Book",
    type: "standardColumn",
  },
  {
    field: "quantity",
    headerName: "Quantity",
    type: "doubleColumn",
    valueFormatter: (params) => {
      return formatNumber(params.value);
    },
  },
  {
    field: "trs",
    headerName: "TRS",
    type: "standardColumn",
  },
  {
    field: "dealId",
    headerName: "dealId",
    type: "standardColumn",
  },
  {
    field: "avgCost",
    headerName: "Avg Cost",
    type: "currencyColumn",
    valueFormatter: (params) => {
      return formatCurrency(params.value, params.data.currency);
    },
  },
];

function RelatedPositionsTab() {
  const { selectedPosition } = useInterTabContext();

  return (
    <ViewContainer>
      <TabTopActionBar></TabTopActionBar>
      <RoundedDataGrid
        columnDefs={colDefs}
        rowData={createRelatedPositionsMockData(selectedPosition)}
        height="100%"
        defaultColDef={{
          resizable: true,
          enableRowGroup: false,
          sortable: true,
          filter: true,
        }}
      />
    </ViewContainer>
  );
}

export default RelatedPositionsTab;
