import {
  PortfolioGroupsSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { FormSectionAccordion, TextInput } from "@enfusion-ui/web-components";
import * as React from "react";

import PortfolioGroupsSelect from "../../../../../reports/components/ReportQueryComponents/PortfolioGroupsSelect";

const PortfolioGroupsSelectionEditWidget: React.FC = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange = (key: string) => (ids: number[] | null) => {
    changeConfigKeyToBeApplied(key, ids ?? []);
  };

  const { defaultValue = null, key = "PortfolioGroups" } =
    editedConfig as PortfolioGroupsSelectionWidgetConfig;

  return (
    <FormSectionAccordion title="Portfolio Source Options">
      <TextInput
        label="Key"
        value={key}
        name="key"
        onChange={handleTextChange("key")}
        data-e2e-id="portfolio-group-key-input"
      />
      <PortfolioGroupsSelect
        value={defaultValue}
        onChange={handleBasicChange("defaultValue")}
        label="Default Portfolio Group(s)"
      />
    </FormSectionAccordion>
  );
};

export default PortfolioGroupsSelectionEditWidget;
