import { Quote } from "@enfusion-ui/types";
import * as React from "react";

export const eventType = {
  connected: "connected",
  disconnect: "disconnect",
  error: "error",
  marketDataUpdate: "marketdata-update",
  subscribeToMarketData: "subscribe-to-market-data",
  unsubscribeFromMarketData: "unsubscribe-from-market-data",
};

export type UpdateHandlerFunction = (
  quote: Quote,
  unsubscribe: VoidFunction
) => void;

export type MarketDataContextState = {
  subscribeToMarketData: (
    instrumentId: number,
    callback: UpdateHandlerFunction
  ) => VoidFunction;
};

export const MarketDataContext = React.createContext<
  MarketDataContextState | undefined
>(undefined);

export function useMarketData() {
  const context = React.useContext(MarketDataContext);
  if (context === undefined) {
    throw new Error("useMarketData must be used within a MarketDataProvider");
  }
  return context;
}

export const MarketDataProviderPassthrough: React.FC<
  React.PropsWithChildren<MarketDataContextState>
> = ({ children, ...value }) => {
  return (
    <MarketDataContext.Provider value={value}>
      {children}
    </MarketDataContext.Provider>
  );
};
