import { useRefCallback } from "@enfusion-ui/hooks";
import { SelectOptionsType, Trader } from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext } from "react-hook-form";

export type TraderSelectProps = {
  value?: number[] | null;
  onChange?: (ids: number[] | null) => void;
  selectProps?: Omit<
    Partial<MultiSelectProps<number>>,
    "options" | "value" | "onChange"
  >;
  desks?: number[];
  name?: string;
};

export const TraderSelect: React.FC<TraderSelectProps> = ({
  value,
  onChange,
  selectProps = {},
  desks,
  name,
}) => {
  const [assignedTraderIds, setAssignedTraderIds] = React.useState<
    number[] | null
  >(null);
  const [traderOptions, setTraderOptions] = React.useState<
    SelectOptionsType<number>[]
  >([]);

  const {
    label,
    placeholder = "All",
    inputId = "assigned-trader-multi-selection-id",
    ...rest
  } = selectProps;

  React.useEffect(() => {
    if (value) {
      setAssignedTraderIds(value);
    }
  }, [value]);

  React.useEffect(() => {
    const traderOptions: SelectOptionsType<number>[] = [];

    const getTraderOptions = async (id?: number) => {
      const traders = id
        ? await REST_API.SECURITY.GET_TRADERS.FETCH(id)
        : await REST_API.SECURITY.GET_ALL_TRADERS.FETCH();
      traders?.forEach((trader: Trader) => {
        traderOptions.push({ value: trader.id, label: trader.fullName });
      });
      setTraderOptions(traderOptions);
    };

    const loadTraderOptions = async () => {
      try {
        desks && desks.length
          ? desks.forEach((id: number) => getTraderOptions(id))
          : getTraderOptions();
      } catch (err) {
        console.error("trader list load error", err);
      }
    };

    loadTraderOptions();
  }, [desks]);

  const handleChange = useRefCallback(
    (values: SelectOptionsType<number>[]) => {
      const ids = values?.length
        ? values.map((option: SelectOptionsType<number>) => option.value)
        : null;
      setAssignedTraderIds(ids);
      onChange?.(ids);
    },
    [onChange]
  );

  return (
    <MultiSelect
      {...rest}
      name={name}
      label={label ?? "Traders"}
      placeholder={placeholder}
      options={traderOptions}
      value={
        assignedTraderIds
          ? getSelectedValues(assignedTraderIds, traderOptions)
          : []
      }
      onChange={(values) => handleChange(values as SelectOptionsType<number>[])}
      inputId={inputId}
    />
  );
};

const ControlledAssignedTraderSelect: React.FC<{
  defaultAssignedTrader: Array<number>;
}> = ({ defaultAssignedTrader }) => {
  const { watch } = useFormContext();
  const { assignedTraderIds, assignedDeskIds } = watch();

  return (
    <ControlledInputBase
      name="assignedTraderIds"
      render={({ ref: _ref, onChange, ...rest }) => (
        <TraderSelect
          selectProps={rest}
          value={assignedTraderIds}
          onChange={onChange}
          desks={assignedDeskIds}
        />
      )}
      defaultValue={defaultAssignedTrader}
    />
  );
};

export default ControlledAssignedTraderSelect;
