import { KeywordsTabCore } from "@app-components/tickets/keywordsTab/KeywordsTabCore";
import { useOEMSOrderForm } from "@enfusion-ui/core";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const FormContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: var(--spacing-xl) var(--spacing) 0;
`;

export const OrderKeywordsTab: React.FC = () => {
  const { initialLoading, keywordsControl } = useOEMSOrderForm();

  return (
    <FormContainer>
      {keywordsControl && (
        <KeywordsTabCore
          initialLoading={initialLoading}
          keywordsControl={keywordsControl}
        />
      )}
    </FormContainer>
  );
};
