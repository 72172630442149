import { WebReportTableSyncRow } from "@enfusion-ui/types";
import { REPORT_ROW_HEIGHT, ReportRowDataEntry } from "@enfusion-ui/web-core";
import { ColDef } from "ag-grid-community";

//types
export type WarningFlagsData = {
  value?: any;
  errorCell: boolean;
  warningCell: boolean;
  errorText: boolean;
  width: any;
  isExpandable: boolean;
};

// constants
const CHARACTER_WIDTH = 7;
export const minColWidth = 50;
export const rowHeight = REPORT_ROW_HEIGHT;
// utils
export const pxToPtConversion = (px: number): number => {
  return (3 / 4) * px;
};

export const isOddNumber = (value: number) => value % 2 !== 0;

export const getCellClassWarningValues = (
  row: WebReportTableSyncRow,
  defCol: ColDef,
  definition: string
): WarningFlagsData => {
  let cellClassWarningValue: any = {
    errorCell: false,
    warningCell: false,
    errorText: false,
  };
  if (defCol.cellClassRules !== undefined) {
    for (const key of Object.keys(defCol?.cellClassRules)) {
      const warningFunc = defCol?.cellClassRules[key] as (arg: any) => boolean;
      if (typeof warningFunc === "function") {
        cellClassWarningValue[key] = warningFunc({
          value: row[definition].value,
          data: row,
          colDef: defCol,
        });
      }
    }
  }
  return cellClassWarningValue;
};

export const formatValues = (
  row: ReportRowDataEntry,
  columnDefs: Array<ColDef>,
  formatValue: boolean
) => {
  return columnDefs.reduce((res, def) => {
    const definition = def?.colId;
    if (definition !== undefined) {
      const value = !formatValue
        ? (row[def.colId!] as any).formattedText
        : (def.valueFormatter as Function)?.({
            colDef: def,
            data: row,
            value: row[definition],
          });

      const cellClassValues = getCellClassWarningValues(row, def, definition);
      res.push({
        value,
        errorCell: cellClassValues.errorCell,
        warningCell: cellClassValues.warningCell,
        errorText: cellClassValues.errorText,
        width: def.width,
        isExpandable: def.cellRenderer === "agGroupCellRenderer",
      });
    }
    return res;
  }, [] as WarningFlagsData[]);
};

export function findFitColumns(
  colDefs: Array<ColDef>,
  rows: Array<ReportRowDataEntry>,
  widgetWidth: number,
  minColumnWidth: number
): [Array<ColDef>, Array<ReportRowDataEntry>] {
  const reducedColumnDefs = colDefs
    .filter((i) => {
      return typeof i.colId !== "undefined" && i.hide === false;
    })
    .slice(0, Math.ceil(widgetWidth / minColumnWidth));

  let reduceNumberOfRows = [...rows];

  const colDefsWithWidth: Array<ColDef> = [];
  let width = widgetWidth;
  for (let i = 0; i < reducedColumnDefs.length; i += 1) {
    if (width > 0) {
      const def = reducedColumnDefs[i];
      const key = def.colId!;

      const [colWidth, newRows] = reduceNumberOfRows.reduce(
        (res, row, index) => {
          const value = row[key];
          if (value === null || typeof value === "undefined") return res;
          const formattedText = (def.valueFormatter as Function)?.({
            colDef: def,
            data: row,
            value,
          });
          res[1][index][key] = { ...res[1][index][key], formattedText } as any;
          return [Math.max(res[0], formattedText?.length), res[1]];
        },
        [Number(minColumnWidth / CHARACTER_WIDTH), [...reduceNumberOfRows]]
      );
      reduceNumberOfRows = newRows;
      def.width = colWidth * CHARACTER_WIDTH;
      width = width - def.width;
      colDefsWithWidth.push(def);
    } else {
      break;
    }
  }
  return [colDefsWithWidth, reduceNumberOfRows];
}
