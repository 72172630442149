import { Text, View } from "@react-pdf/renderer";
import { ColDef } from "ag-grid-community";
import * as React from "react";

import { pxToPtConversion } from "./utils";

export const TableHeaders: React.FC<{
  columnDefs: Array<ColDef>;
  pdfReportWidgetStyles: any;
}> = ({ columnDefs, pdfReportWidgetStyles }) => {
  return (
    <View
      style={[
        pdfReportWidgetStyles.tableRow,
        pdfReportWidgetStyles["tableHeaderContainerBackGroundColor"],
      ]}
    >
      {columnDefs.map((def: ColDef, index: number) => {
        const isFirstCell = index === 0 ? true : false;
        if (!def.hide && def.width !== undefined) {
          return (
            <View
              key={`${def.headerName}-${index}`}
              style={[
                pdfReportWidgetStyles.tableRow,
                {
                  width: isFirstCell
                    ? pxToPtConversion(def.width + 15)
                    : pxToPtConversion(def.width),
                },
                { justifyContent: "space-between" },
              ]}
            >
              <Text
                style={[
                  pdfReportWidgetStyles.tableHeader,
                  pdfReportWidgetStyles["rowsTextNormal"],
                ]}
              >
                {def.headerName}
              </Text>

              <View style={pdfReportWidgetStyles.headerSeperator}></View>
            </View>
          );
        }
      })}
    </View>
  );
};
