/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  GraphWidgetConfig,
  useDatasourceColumnsList,
  useWidget,
} from "@enfusion-ui/dashboards";
import { AggregateDataMethod, getSelectOption } from "@enfusion-ui/utils";
import {
  Checkbox,
  FormSectionAccordion,
  NumericInput,
  Select,
  TextInput,
} from "@enfusion-ui/web-components";
import { useReports } from "@enfusion-ui/web-core";
import { isEqual } from "lodash";
import * as React from "react";

import DatasourcePicker from "../../../widget/components/DatasourcePicker";
import ValuesInput from "../../../widget/components/ValuesInput";
import { InputGroup, InputWrapper, SingleRowContainer } from "../../styles";

const TYPE_OPTIONS = [
  {
    label: "Column",
    value: "column",
  },
  {
    label: "Bar",
    value: "bar",
  },
  {
    label: "Pie",
    value: "pie",
  },
  {
    label: "Line",
    value: "line",
  },
  {
    label: "Smooth Line",
    value: "spline",
  },
];

const SORT_DIR_OPTIONS = [
  {
    label: "Desc",
    value: "desc",
  },
  {
    label: "Asc",
    value: "asc",
  },
];

const AGGREGATE_METHOD_OPTIONS: Array<{
  label: string;
  value: AggregateDataMethod;
}> = [
  {
    label: "Max",
    value: "max",
  },
  {
    label: "Min",
    value: "min",
  },
  {
    label: "Sum",
    value: "sum",
  },
  {
    label: "Avg",
    value: "avg",
  },
  {
    label: "None",
    value: "none",
  },
];

function getSeriesOptions(type: string) {
  let label = "Series (X Axis)";
  if (type === "pie") {
    label = "Sections (X Axis)";
  } else if (type === "bar") {
    label = "Y Axis";
  } else if (type === "column") {
    label = "X Axis";
  } else if (type.endsWith("line")) {
    label = "Date Column (X Axis)";
  }
  return { max: 1, label };
}

function getCategoriesOptions(type: string) {
  const label = `Categories (${type === "bar" ? "X" : "Y"} Axis)`;
  return { label };
}

const GraphEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();
  const [selectedChartType, setSelectedChartType] = React.useState<string>("");

  const {
    type = "column",
    title,
    subTitle,
    series,
    categories,
    timeSeries = false,
    yMin = null,
    xMin = null,
    tooltipFractions = null,
    showLegend = true,
    dataSorting = true,
    showDataLabels = false,
    sortDir = "desc",
    sortByKey = 0,
    aggregationMethod = "max",
  } = editedConfig as GraphWidgetConfig;

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange = (key: string) => (value: any) => {
    if (!isEqual(editedConfig[key], value)) {
      changeConfigKeyToBeApplied(key, value);
    }
  };

  const handleGraphValueChange = (key: string) => (value: any) => {
    if (!isEqual(editedConfig[key], value)) {
      changeConfigKeyToBeApplied(key, value);
    }
  };

  const handleSelectChange = (key: string) => (value: any) => {
    changeConfigKeyToBeApplied(key, value.value);
    if (key === "type") {
      setSelectedChartType(value.value);
    }
  };

  const selectorOptions = useDatasourceColumnsList(useReports);

  const categoryOptions = React.useMemo(() => {
    if (!categories || categories.length === 0) {
      return [{ label: "Categories needed", value: 0 }];
    }
    return categories.map((i, idx) => ({
      value: idx,
      label: i.label || i.value,
    }));
  }, [categories]);

  return (
    <>
      <FormSectionAccordion
        title="Container Options"
        dataE2EId="graph-widget-container-options-accordion"
      >
        <SingleRowContainer>
          <TextInput
            label="Title"
            value={title}
            name="title"
            onChange={handleTextChange("title")}
          />
          <TextInput
            label="Sub Title"
            value={subTitle}
            name="subTitle"
            onChange={handleTextChange("subTitle")}
          />
        </SingleRowContainer>
      </FormSectionAccordion>
      <FormSectionAccordion
        title="Data Options"
        dataE2EId="graph-widget-data-options-accordion"
      >
        <InputGroup enabled={type.endsWith("line")}>
          <InputWrapper>
            <Select
              label="Type"
              name="type"
              value={getSelectOption(TYPE_OPTIONS, type)}
              options={TYPE_OPTIONS}
              onChange={handleSelectChange("type")}
              inputId="graph-widget-type-selection-id"
            />
          </InputWrapper>
          {type.endsWith("line") && (
            <InputWrapper>
              <Checkbox
                checked={timeSeries}
                label="Time Series"
                onChange={handleBasicChange("timeSeries")}
                labelPlacement="top"
              />
            </InputWrapper>
          )}
        </InputGroup>
        <SingleRowContainer>
          <NumericInput
            step={1}
            label="Tooltip Fractions"
            value={tooltipFractions}
            name="tooltipFractions"
            defaultValue={null}
            min={tooltipFractions === null ? undefined : 0}
            onChange={handleBasicChange("tooltipFractions")}
            clearable
            dataTestId="tool-tip-fractions"
          />
          <Checkbox
            checked={showLegend}
            label="Show Legend"
            onChange={handleBasicChange("showLegend")}
            labelPlacement="top"
          />
        </SingleRowContainer>
        <SingleRowContainer>
          <Checkbox
            checked={showDataLabels}
            label="Show Data Labels"
            onChange={handleBasicChange("showDataLabels")}
            labelPlacement="top"
            dataE2EId="show-data-labels-checkbox"
          />
          <Checkbox
            checked={dataSorting}
            label="Sort Data"
            onChange={handleBasicChange("dataSorting")}
            labelPlacement="top"
            disabled={timeSeries}
          />
        </SingleRowContainer>
        <SingleRowContainer count={3}>
          <Select
            label="Sort Dir"
            name="sortDir"
            value={getSelectOption(SORT_DIR_OPTIONS, sortDir)}
            options={SORT_DIR_OPTIONS}
            onChange={handleSelectChange("sortDir")}
            clearable={false}
            disabled={timeSeries || !dataSorting}
            inputId="graph-widget-sort-dir-selection-id"
          />
          <Select
            label="Sort By"
            name="sortByKey"
            value={getSelectOption(categoryOptions, sortByKey)}
            options={categoryOptions}
            onChange={handleSelectChange("sortByKey")}
            disabled={timeSeries || !dataSorting || categories?.length < 2}
            clearable={false}
            inputId="graph-widget-sort-key-selection-id"
          />
          <Select
            label="Aggregate By"
            name="aggregationMethod"
            value={getSelectOption(AGGREGATE_METHOD_OPTIONS, aggregationMethod)}
            options={AGGREGATE_METHOD_OPTIONS}
            onChange={handleSelectChange("aggregationMethod")}
            disabled={timeSeries || !dataSorting}
            clearable={false}
            inputId="graph-widget-agg-key-selection-id"
          />
        </SingleRowContainer>
        <SingleRowContainer noMargin>
          <NumericInput
            label="Y Min"
            value={yMin}
            defaultValue={null}
            name="yMin"
            onChange={handleBasicChange("yMin")}
            clearable
            dataTestId="ymin-input"
          />
          <NumericInput
            label="X Min"
            value={xMin}
            defaultValue={null}
            name="xMin"
            onChange={handleBasicChange("xMin")}
            clearable
            dataTestId="xmin-input"
          />
        </SingleRowContainer>
      </FormSectionAccordion>

      <FormSectionAccordion
        title="Datasource"
        dataE2EId="graph-widget-data-source-accordion"
      >
        <DatasourcePicker
          e2eTestId="graph-widget-datasource"
          onDatasourceChange={() => ({
            categories: [],
            series: [],
          })}
        />
      </FormSectionAccordion>

      <FormSectionAccordion
        title={getSeriesOptions(type).label}
        dataE2EId="graph-widget-x-axis-accordion"
      >
        <ValuesInput
          key={type}
          name="series"
          emptyLabelPlaceholder={false}
          defaultValues={series}
          max={getSeriesOptions(type).max}
          options={selectorOptions}
          onChange={handleGraphValueChange("series")}
          btnTestId="graph-widget-xAxis-add-btn"
        />
      </FormSectionAccordion>
      <FormSectionAccordion
        title={getCategoriesOptions(type).label}
        dataE2EId="graph-widget-y-axis-accordion"
      >
        <ValuesInput
          key={type}
          name="categories"
          emptyLabelPlaceholder={false}
          defaultValues={categories}
          options={selectorOptions}
          includeColorPicker={selectedChartType !== "pie" && type !== "pie"}
          onChange={handleGraphValueChange("categories")}
          btnTestId="graph-widget-yAxis-add-btn"
        />
      </FormSectionAccordion>
    </>
  );
};

export default GraphEditWidget;
