import { useReportRows } from "@app-context/reports/ReportsProvider";
import { useReportDatasource } from "@app-views/dashboards/hooks/useReportDatasource";
import { AsyncDatasourceContent } from "@app-views/dashboards/pdf/AsyncDatasourceContent";
import { pxToPtConversion } from "@app-views/dashboards/pdf/utils";
import { ThemeDefinition } from "@enfusion-ui/core";
import {
  getColumnDef,
  ListViewWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { DashboardSubTableDatasource } from "@enfusion-ui/types";
import { formatReportValue } from "@enfusion-ui/utils";
import { useReports, withTheme } from "@enfusion-ui/web-core";
import {
  faFolder,
  faFolderOpen,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { Path, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import { uniq } from "lodash";
import * as React from "react";

const pdfStyles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
  },
  innerContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: pxToPtConversion(5),
  },
  widgetTitle: {
    width: "100%",
    height: pxToPtConversion(45),
    minHeight: pxToPtConversion(32),
    display: "flex",
    flexDirection: "column",
    // justifyContent and alignItems are reversed
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: pxToPtConversion(4),
  },
  title: {
    fontFamily: "Lato",
    fontSize: pxToPtConversion(20),
    paddingVertical: pxToPtConversion(4),
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
  listItem: {
    marginTop: pxToPtConversion(8),
    paddingLeft: pxToPtConversion(10),
  },
  listItemContainer: {
    display: "flex",
    flexDirection: "row",
    width: "98%",
    borderRadius: pxToPtConversion(5),
  },
  iconContainer: {
    marginHorizontal: pxToPtConversion(4),
    justifyContent: "center",
    alignItems: "center",
  },
  listItemText: {
    fontFamily: "Lato",
    fontSize: pxToPtConversion(12),
    // justifyContent and alignItems are reversed
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: pxToPtConversion(4),
  },
});

const PdfIcon = withTheme(
  ({ icon, theme }: { icon: IconDefinition; theme: ThemeDefinition }) => {
    return (
      <View style={[pdfStyles.iconContainer]}>
        <Svg
          width={pxToPtConversion(12)}
          height={pxToPtConversion(12)}
          viewBox={`0 0 ${icon.icon[0]} ${icon.icon[1]}`}
        >
          <Path
            d={icon.icon[4] as string}
            stroke={theme.colors.textNormal}
            fill={theme.colors.textNormal}
          />
        </Svg>
      </View>
    );
  }
);

type MenuItem = {
  title: string;
  key: string;
  icon?: IconDefinition;
  isSelected: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PdfListViewWidget = withTheme(({ theme }: { theme: ThemeDefinition }) => {
  const { config, setChannelData } = useWidget();
  const { datasource, metadata, gridOptions, tableId } = useReportDatasource();
  const { openDetailTable } = useReports();

  const { rowsStore } = useReportRows();

  const rowOptions = tableId ? rowsStore?.[tableId] ?? null : null;

  const {
    datasourceId,
    column,
    key,
    showTitle = true,
    autoSelectFirst = false,
  } = config as ListViewWidgetConfig;
  const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);
  const [selectedItem, setSelectedItem] = React.useState<string | null>(null);

  const onSelectionChanged = React.useCallback(
    (selectedItem: string | null, menuItemsOverride?: MenuItem[]) => {
      const reportColumn = getColumnDef(column, gridOptions?.metadata.columns);
      const reportRow = reportColumn
        ? rowOptions?.rows.find(
            (i) =>
              (i[reportColumn.name].value as unknown as string) === selectedItem
          )
        : undefined;
      const items = menuItemsOverride || menuItems;
      const updateMenuItems = items.map((item) =>
        item.title === selectedItem
          ? { ...item, isSelected: true }
          : { ...item, isSelected: false }
      );
      const args = {
        destination: metadata?.destination || "",
        label: selectedItem || "",
        tableId: `${reportRow?.["__row_id"]}`,
        row: `${reportRow?.["__row_idx"]}`,
        reportId: datasource?.id || "",
        parentTableId: datasource?.id || "",
      };
      if (reportRow) openDetailTable(args);
      setMenuItems(updateMenuItems);
      setChannelData([selectedItem, args as DashboardSubTableDatasource]);
      setSelectedItem(selectedItem);
    },
    [
      datasource,
      menuItems,
      column,
      JSON.stringify(Object.keys(gridOptions?.metadata.columns ?? {})),
      rowOptions?.rows,
      metadata?.destination,
    ]
  );

  React.useEffect(() => {
    if (autoSelectFirst && selectedItem === null && menuItems.length > 0) {
      onSelectionChanged(menuItems[0].title);
    }
  }, [autoSelectFirst, menuItems?.length, selectedItem]);

  React.useEffect(() => {
    if (column) {
      const reportColumn = getColumnDef(column, gridOptions?.metadata.columns);

      const uniqueColumnValues = reportColumn
        ? uniq(
            rowOptions?.rows?.map((row: any) =>
              formatReportValue(row[reportColumn.name], reportColumn, {
                numberReductionFormat: config?.numberReductionFormat,
              })
            )
          )?.map(
            (data) =>
              ({
                title: data,
                key: data,
                isSelected: false,
              } as MenuItem)
          )
        : [];

      if (autoSelectFirst) {
        onSelectionChanged(uniqueColumnValues?.[0]?.title, uniqueColumnValues);
      } else {
        setMenuItems(uniqueColumnValues);
      }
    }
  }, [
    datasourceId,
    column,
    JSON.stringify(Object.keys(gridOptions?.metadata.columns ?? {})),
    autoSelectFirst,
  ]);

  return (
    <AsyncDatasourceContent>
      <View style={[pdfStyles.container]}>
        {menuItems.length > 0 ? (
          <View
            style={[
              pdfStyles.innerContainer,
              {
                backgroundColor: theme.colors.backgroundColor1,
                borderColor: theme.colors.border,
              },
            ]}
          >
            <View
              style={[
                pdfStyles.widgetTitle,
                {
                  backgroundColor: theme.colors.backgroundColor1Hover,
                },
              ]}
            >
              <Text
                style={[
                  pdfStyles.title,
                  {
                    color: theme.colors.textNormal,
                  },
                ]}
              >
                {showTitle ? key : ""}
              </Text>
            </View>
            {menuItems.map((item: MenuItem, idx: number) => {
              let selectFirst = autoSelectFirst && idx === 0;
              return (
                <View
                  key={`${item.key}-${item.title}`}
                  style={[
                    pdfStyles.listItem,
                    {
                      color: theme.colors.textNormal,
                    },
                  ]}
                >
                  <View
                    style={[
                      pdfStyles.listItemContainer,
                      item.isSelected || selectFirst
                        ? {
                            backgroundColor: theme.colors.primary,
                          }
                        : {},
                    ]}
                  >
                    {item.icon ? (
                      <PdfIcon icon={item.icon} />
                    ) : item.isSelected || selectFirst ? (
                      <PdfIcon icon={faFolderOpen} />
                    ) : (
                      <PdfIcon icon={faFolder} />
                    )}
                    <View style={[pdfStyles.listItemText]}>
                      <Text>{item.title}</Text>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
        ) : null}
      </View>
    </AsyncDatasourceContent>
  );
});

export default PdfListViewWidget;
