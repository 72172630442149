import {
  AccountingMethodOptions,
  CalculationMethodOptions,
} from "@enfusion-ui/types";
import { ControlledSelect, PanelRow } from "@enfusion-ui/web-components";

import { DateRangeSymbolSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledLedgerSelect } from "../../../components/ReportQueryComponents/LedgerSelect";

const GLPositionSettings = () => {
  return (
    <>
      <ControlledLedgerSelect name="generalLedgerIds" label="Ledger" />
      <PanelRow>
        <ControlledSelect
          name="accountingMethod"
          options={AccountingMethodOptions}
          label="Accounting Method"
        />
        <ControlledSelect
          name="calculationMethod"
          options={CalculationMethodOptions}
          label="Calculation Method"
        />
      </PanelRow>
      <DateRangeSymbolSelection name="period" label="Period (From/To)" />
    </>
  );
};

export default GLPositionSettings;
