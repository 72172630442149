/* eslint-disable @typescript-eslint/no-explicit-any */
import { SwitchWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import {
  Checkbox,
  FormSectionAccordion,
  TextInput,
} from "@enfusion-ui/web-components";
import * as React from "react";

import { InputWrapper } from "../../styles";

const SwitchEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange = (key: string) => (value: any) => {
    changeConfigKeyToBeApplied(key, value);
  };

  const { defaultValue = false, key = "switch" } =
    editedConfig as SwitchWidgetConfig;

  return (
    <FormSectionAccordion title="Switch Options">
      <TextInput
        label="Key"
        value={key}
        name="key"
        onChange={handleTextChange("key")}
      />
      <InputWrapper margin="var(--spacing-xl) 0px 0px">
        <Checkbox
          checked={defaultValue}
          label="Default Value"
          onChange={handleBasicChange("defaultValue")}
        />
      </InputWrapper>
    </FormSectionAccordion>
  );
};

export default SwitchEditWidget;
