import { usePortfolio } from "@app-context/portfolios/PortfolioProvider";
import { isDiffColumn } from "@enfusion-ui/portfolios";
import { ValueOf } from "@enfusion-ui/types";
import { css, PriceCellRenderer, styled } from "@enfusion-ui/web-core";
import { ICellRendererParams } from "ag-grid-enterprise";
import * as React from "react";

import { useRowData } from "../utils";

const PriceStatusColors = {
  "Streaming RT": "success",
  StreamingRT: "success", // hopefully will get fixed
  "Streaming Delayed": "warning",
  StreamingDelayed: "warning",
  Static: "text-normal",
  Stale: "danger",
  Error: "text-muted",
  Override: "text-normal",
} as const;
type PriceStatusColor = keyof typeof PriceStatusColors;
type StatusColorKeys = ValueOf<typeof PriceStatusColors>;

const getFlagColor = (status?: PriceStatusColor | StatusColorKeys) => {
  let color = status as StatusColorKeys;
  if (!status) color = PriceStatusColors.Error;
  else if (Object.keys(PriceStatusColors).includes(status))
    color = PriceStatusColors[status as PriceStatusColor];
  return `var(--${color})`;
};

export const PriceCellWrapper = styled.div<{
  status?: PriceStatusColor | StatusColorKeys | null;
  touched?: boolean;
  updated?: boolean;
  diffColumnColor?: string;
}>`
  position: relative;

  color: ${({ touched, diffColumnColor }) =>
    touched ? "var(--primary)" : diffColumnColor ? diffColumnColor : "inherit"};
  ${({ updated, touched }) =>
    updated || touched
      ? css`
          border-bottom: 1px dashed var(--text-muted);
        `
      : ""};

  ${({ status }) => {
    return !status
      ? ""
      : css`
          :after {
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 7px 0;
            min-width: 50px;
            border-color: transparent ${getFlagColor(status)} transparent
              transparent;
          }
        `;
  }}
`;

const requiredStyles: React.CSSProperties = {
  height: "21px",
  lineHeight: "21px",
  textAlign: "right",
  pointerEvents: "none",
};

export const StatusCellRenderer: React.FC<
  ICellRendererParams & {
    cellStyle?: React.CSSProperties;
    statusValueGetter?: (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: Record<string, any> | null
    ) => PriceStatusColor | StatusColorKeys | null;
  }
> = React.memo(
  ({
    cellStyle = {},
    statusValueGetter = () => null,
    ...cellRendererParams
  }) => {
    const { valueFormatted, colDef, node } = cellRendererParams;
    const { changedFields, getTouched, getParentRows } = usePortfolio();
    let data = useRowData(node, getParentRows, null, false, [
      valueFormatted,
      node.data?.priceStatus?.value,
    ]);
    if (!node) return null;

    if (!data || Object.keys(data).length === 0) {
      return null;
    }

    const rowId = data?.rowId;
    const field = colDef?.colId!;
    const updated = changedFields[rowId]?.includes(field);
    const touched = getTouched(rowId, field);

    let diffColumnColor;
    if (
      typeof colDef?.type === "string" &&
      isDiffColumn(colDef?.type, colDef?.field)
    ) {
      const n = data?.[field]?.value;
      diffColumnColor = n >= 0 ? "var(--success)" : "var(--danger)";
    }

    return (
      <PriceCellWrapper
        touched={touched}
        updated={updated}
        status={statusValueGetter(data)}
        diffColumnColor={diffColumnColor}
        style={{ ...cellStyle, ...requiredStyles }}
      >
        <PriceCellRenderer {...cellRendererParams} data={data} />
      </PriceCellWrapper>
    );
  }
);
