import { GridData, GridView } from "@app-components/display/GridView";
import { NumericLabeledValue } from "@app-views/oems/components/styledComponents";
import { useInstrument } from "@enfusion-ui/core";
import {
  SecurityInfoPanelConfig,
  securityInfoPanelFieldsMap,
} from "@enfusion-ui/dashboards";
import { InstrumentInfo } from "@enfusion-ui/types";
import { getKeywordFromInstrument } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";

const SecurityInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const SecurityInfoPanel = ({
  fields = [],
  viewMode = "horizontal",
}: SecurityInfoPanelConfig) => {
  const instrument = useInstrument();
  if (fields.length === 0)
    return <SecurityInfoRow>No Fields Selected</SecurityInfoRow>;

  const values = {
    ticker: instrument?.ticker ?? "",
    isin: getKeywordFromInstrument(instrument as InstrumentInfo, "ISIN"),
    sedol: getKeywordFromInstrument(instrument as InstrumentInfo, "SEDOL"),
    ric: getKeywordFromInstrument(instrument as InstrumentInfo, "RIC"),
    exchangeShortname: instrument?.exchangeName ?? "",
    currencyCode: instrument?.currency?.code ?? "",
  } as const;
  const gridData = fields.reduce((res, entry) => {
    return [
      ...res,
      {
        fieldName: securityInfoPanelFieldsMap[entry],
        fieldValue: values[entry],
      },
    ];
  }, [] as GridData);

  if (viewMode === "vertical") return <GridView gridData={gridData} />;

  return (
    <SecurityInfoRow>
      {gridData.map((entry) => (
        <NumericLabeledValue label={entry.fieldName} title={entry.fieldName}>
          {entry.fieldValue || "-"}
        </NumericLabeledValue>
      ))}
    </SecurityInfoRow>
  );
};

export default SecurityInfoPanel;
