/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const RECONNECT_INTERVAL = 5000;
const MAX_RECONNECT_INTERVAL = 5 * 60 * 1000;
export const mockFn = () => { };
export class SocketController {
    params;
    socket;
    preOpenMessages = [];
    reconnectCount = 1;
    readyState = false;
    constructor(params) {
        this.params = {
            ...params,
            reconnectInterval: params.reconnectInterval || RECONNECT_INTERVAL,
        };
    }
    callError = (event) => {
        if (this.params.onError) {
            try {
                this.params.onError(event, this.socket);
            }
            catch (err) {
                console.error("Socket error handling error", this.params.path, event);
            }
        }
        else {
            console.error("Socket error", this.params.path, event);
        }
    };
    callback = (method, ...args) => {
        try {
            method(...args);
        }
        catch (err) {
            this.callError(err);
        }
    };
    init = () => {
        try {
            this.socket = new WebSocket(this.params.path);
            this.socket.addEventListener("open", () => {
                this.callback(this.params.onOpen, this.socket);
                if (this.params.autoReady !== false) {
                    this.ready();
                }
            });
            this.socket.addEventListener("message", (event) => {
                this.callback(this.params.onMessage, event, this.socket);
            });
            this.socket.addEventListener("error", (event) => {
                this.callError(event);
            });
            this.socket.addEventListener("close", (event) => {
                this.readyState = false;
                if (this.params.onClose)
                    this.callback(this.params.onClose, event, this.socket);
                if (event.code === 1000)
                    return; // Normal close
                setTimeout(() => {
                    this.init();
                    this.reconnectCount += 1;
                }, Math.min((this.params.reconnectInterval || RECONNECT_INTERVAL) * this.reconnectCount, MAX_RECONNECT_INTERVAL));
            });
        }
        catch (err) {
            this.callError(err);
        }
    };
    send = (message) => {
        if (this.socket &&
            this.socket.readyState === WebSocket.OPEN &&
            this.readyState) {
            try {
                this.socket.send(message);
            }
            catch (err) {
                this.callError(err);
            }
        }
        else {
            this.preOpenMessages.push(message);
        }
    };
    close = (msg) => {
        this.readyState = false;
        if (this.socket) {
            console.log("close socket", msg);
            this.callback(this.params.onDisconnect, this.socket);
            try {
                this.socket.close(1000, "close command");
            }
            catch (err) {
                this.callError(err);
            }
        }
    };
    reconnect = (force = false) => {
        if (this.readyState === true && !force)
            return;
        this.readyState = false;
        if (this.socket) {
            try {
                if (typeof this.params.onWillReconnect === "function")
                    this.callback(this.params.onWillReconnect, this.socket);
                this.socket.close(3000, "reconnect");
            }
            catch (err) {
                this.callError(err);
            }
        }
    };
    ready = (param) => {
        this.readyState = true;
        if (typeof this.params.onReady === "function")
            this.callback(this.params.onReady, this.socket, param);
        this.preOpenMessages.forEach((msg) => this.socket.send(msg));
        this.preOpenMessages.length = 0;
    };
    get socketStatus() {
        if (this.socket) {
            return this.socket.readyState;
        }
        return WebSocket.CLOSED;
    }
}
