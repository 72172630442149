import MarketDataDisplay from "@app-views/oems/components/MarketDataDisplay";
import { useWidget } from "@enfusion-ui/dashboards";
import * as React from "react";

const MarketInfoWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const { fields = [], viewMode = "vertical" } = config;

  return <MarketDataDisplay orientation={viewMode} fields={fields} />;
};

export default MarketInfoWidget;
