import {
  PortfolioGroupsSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import PortfolioGroupsSelect from "../../../../../reports/components/ReportQueryComponents/PortfolioGroupsSelect";

const PortfolioGroupsSelectionWidget = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, key } = config as PortfolioGroupsSelectionWidgetConfig;

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Portfolio Groups",
        type: ChannelDataType.Number,
      },
    ]);
  }, [addChannelKeys, key]);

  React.useEffect(() => {
    setChannelData([defaultValue]);
  }, [setChannelData, defaultValue]);

  return (
    <CenterContent>
      <PortfolioGroupsSelect
        label={key}
        value={defaultValue}
        onChange={(ids: number[] | null) => setChannelData([ids])}
      />
    </CenterContent>
  );
};

export default PortfolioGroupsSelectionWidget;
