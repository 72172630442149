import { irsTenors } from "@enfusion-ui/core";
import {
  Button,
  ControlledEmpty,
  ControlledSelect,
  DatePicker as DatePickerInput,
  FormController,
  PanelRow,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";
import IRSQuickTemplatesSelect from "views/oems/components/irs/IRSQuickTemplatesSelect";

const PanelWrapper = styled.div`
  background-color: var(--background-color-0);
  border-radius: var(--radius-l);
  padding: 16px;
`;

const ButtonContainer = styled(Button)`
  margin-top: 24px;
`;

export type IRSOrderDetailsPanelProps = {
  disabled?: boolean;
};

const IRSOrderDetailsPanel: React.FC<IRSOrderDetailsPanelProps> = ({
  disabled,
}) => {
  const { quickTemplate, tenor } = useWatch({
    name: ["quickTemplate", "tenor"],
  });
  const dateDisabled = quickTemplate && tenor;

  return (
    <PanelWrapper>
      <ControlledEmpty name="irsInstrument" />
      <PanelRow>
        <IRSQuickTemplatesSelect disabled={disabled} />
        <ControlledSelect
          name="tenor"
          label="Tenor"
          options={irsTenors}
          disabled={disabled}
          inputId="irs-tenor-select-id"
        />
      </PanelRow>
      <PanelRow>
        <FormController
          name="startDate"
          inputId="start-date"
          render={({ ref: _ref, onChange, value, ...rest }) => (
            <DatePickerInput
              {...rest}
              label="Start Date"
              value={value ? new Date(value) : null}
              onChange={onChange}
              dateFormat="MM/dd/yyyy"
              clearable
              disabled={disabled || dateDisabled}
            />
          )}
        />
        <FormController
          name="endDate"
          inputId="end-date"
          render={({ ref: _ref, onChange, value, ...rest }) => (
            <DatePickerInput
              {...rest}
              label="End Date"
              value={value ? new Date(value) : null}
              onChange={onChange}
              dateFormat="MM/dd/yyyy"
              clearable
              disabled={disabled || dateDisabled}
            />
          )}
        />

        <ButtonContainer disabled> Swap Details </ButtonContainer>
      </PanelRow>
    </PanelWrapper>
  );
};

export default IRSOrderDetailsPanel;
