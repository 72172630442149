import { useRefCallback } from "@enfusion-ui/hooks";
import {
  IconButton,
  Panel,
  PanelContent,
  PanelTitle,
  TextButton,
} from "@enfusion-ui/web-components";
import { css, styled, useIsContentMobile } from "@enfusion-ui/web-core";
import { faAngleDown, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";

const SummarySection = styled.div`
  gap: var(--spacing);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const SummaryItemContainer = styled.div<{ selected?: boolean }>`
  width: 100%;
  height: 280px;
  position: relative;
  cursor: pointer;
  /* box-shadow: 0px 0px 0px 1px var(--background-accent); */
  border-radius: var(--radius);

  ::after {
    content: "";
    z-index: 1;
    position: absolute;
    bottom: calc(0px - var(--spacing));
    height: var(--spacing);
    width: calc(100% + 8px);
    margin-left: -4px;
    border-radius: var(--radius-l);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background-color: var(--background-color-0);
  }

  ${({ selected }) =>
    selected
      ? css`
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;

          ::after {
            z-index: 0;
            bottom: -12px;
            height: 12px;
            width: calc(100% + 10px);
            margin-left: -5px;
            border-radius: 0px;
            background-color: var(--background-color-2);
          }

          :last-child {
            ::after {
              width: calc(100% + 5px);
            }
          }

          :first-child {
            ::after {
              width: calc(100% + 5px);
              margin-left: 0px;
            }
          }
        `
      : ""}
`;

const SummaryItemContent = styled.div<{ selected: boolean }>`
  z-index: 2;
  position: relative;
  height: 100%;
  padding: var(--spacing-l);
  border-radius: var(--radius);
  overflow: hidden;
  background-color: var(--background-color-2);
  border: 1px solid var(--background-accent);

  ${({ selected }) =>
    selected
      ? css`
          border-bottom-color: transparent;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        `
      : ""}
`;

const TabPanelSummaryItem: React.FC<
  React.PropsWithChildren<{
    onClick: VoidFunction;
    selected: boolean;
  }>
> = React.memo(({ children, onClick, selected }) => (
  <SummaryItemContainer selected={selected} onClick={onClick}>
    <SummaryItemContent selected={selected}>{children}</SummaryItemContent>
  </SummaryItemContainer>
));

const TabPanelDetailItem = styled.div<{ visible: boolean }>`
  padding: var(--spacing-l);
  border-radius: var(--radius);
  background-color: var(--background-color-2);
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

const Content = styled(PanelContent)`
  padding: var(--spacing);
  background-color: var(--background-color-0);
  border-radius: var(--radius);
`;

export type TabPanelContent = {
  key: string;
  summaryComponent: React.ReactNode;
  detailComponent: React.ReactNode;
};

export type TabPanelProps = {
  title?: string;
  contents: TabPanelContent[];
};

export const TabPanel: React.FC<TabPanelProps> = ({ title, contents }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<string>(
    contents[0]?.key ?? ""
  );
  const isMobile = useIsContentMobile();

  const onSelectChange = useRefCallback(
    (tabKey: string) => {
      if (!open) setOpen(true);
      setSelectedTab(tabKey);
    },
    [open]
  );

  return (
    <Panel>
      {title && (
        <PanelTitle
          style={{
            padding: "var(--spacing-s) var(--spacing-l) 0 var(--spacing-l)",
          }}
          role="button"
          onClick={() => setOpen((prev) => !prev)}
        >
          <div style={{ flex: 1, lineHeight: "21px" }}>{title}</div>
          {isMobile ? (
            <IconButton
              tabFocusOutline={false}
              icon={open ? faAngleDown : faAngleRight}
              style={{
                height: "21px",
                padding: "0 8px",
                marginRight: "-8px",
              }}
            />
          ) : (
            <TextButton
              hoverEffect={false}
              labelPlacement="right"
              defaultColor="var(--text-color)"
              icon={open ? faAngleDown : faAngleRight}
              style={{
                height: "21px",
                padding: "0 8px",
                marginRight: "-8px",
              }}
            >
              {open ? "Show Less" : "Show More"}
            </TextButton>
          )}
        </PanelTitle>
      )}
      <Content gapSize="m" numColumns={1} isMobile={isMobile}>
        <SummarySection>
          {contents.map(({ key, summaryComponent }) => (
            <TabPanelSummaryItem
              key={key}
              onClick={() => onSelectChange(key)}
              selected={open && selectedTab === key}
            >
              {summaryComponent}
            </TabPanelSummaryItem>
          ))}
        </SummarySection>

        {contents.map(({ key, detailComponent }) => (
          <TabPanelDetailItem key={key} visible={open && selectedTab === key}>
            {detailComponent}
          </TabPanelDetailItem>
        ))}
      </Content>
    </Panel>
  );
};
