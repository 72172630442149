import { pxToPtConversion } from "@app-views/dashboards/pdf/utils";
import { ThemeDefinition } from "@enfusion-ui/core";
import { TextWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { FontWeightOptions } from "@enfusion-ui/types";
import { useDimensions, withTheme } from "@enfusion-ui/web-core";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import * as React from "react";

const pdfStyles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  text: {
    fontFamily: "Lato",
    height: "100%",
  },
});

const UNIT_MAP = {
  medium: 16,
  large: 18,
  "x-large": 22,
  small: 13,
  "x-small": 10,
};

function mapUnit(value: number | string) {
  if (typeof value === "number" || !isNaN(Number(value))) return Number(value);

  const key = value.toLowerCase() as keyof typeof UNIT_MAP;
  if (UNIT_MAP[key]) return UNIT_MAP[key];

  return UNIT_MAP.medium;
}

function mapWeight(weight: FontWeightOptions) {
  return weight === "bolder"
    ? "extrabold"
    : weight === "lighter"
    ? "extralight"
    : weight;
}

const LINE_HEIGHT_UNIT_MAP = {
  medium: 1.4,
  large: 1.45,
  "x-large": 1.45,
  small: 1.4,
  "x-small": 1.4,
};

function mapLineHeight(value: number | string, h: number) {
  if (h < 30) return 0.5;
  if (typeof value === "number" || !isNaN(Number(value)))
    return LINE_HEIGHT_UNIT_MAP.medium;

  const key = value.toLowerCase() as keyof typeof LINE_HEIGHT_UNIT_MAP;
  if (LINE_HEIGHT_UNIT_MAP[key]) return LINE_HEIGHT_UNIT_MAP[key];

  return LINE_HEIGHT_UNIT_MAP.medium;
}

const PdfTextWidget = withTheme(({ theme }: { theme: ThemeDefinition }) => {
  const { height } = useDimensions();
  const { config } = useWidget();
  const {
    content,
    wrap = false,
    justifyContent = "flex-start",
    alignItems = "flex-start",
    fontSize = "medium",
    fontWeight = "normal",
    color,
    showBackground = false,
  } = config as TextWidgetConfig;

  const marginTop = mapUnit(fontSize) - (height! - (showBackground ? 8 : 0));

  return (
    <View
      style={[
        pdfStyles.container,
        {
          // pdf render reverses this values
          justifyContent: alignItems,
          alignItems: justifyContent,
          borderRadius: pxToPtConversion(5),
        },
        showBackground
          ? {
              backgroundColor: theme.colors.backgroundColor0,
              padding: pxToPtConversion(4),
              // missing boxShadow
            }
          : {},
      ]}
    >
      <Text
        style={[
          pdfStyles.text,
          {
            marginTop: marginTop > 0 ? 0 - marginTop : 0,
            fontWeight: mapWeight(fontWeight),
            color: color || theme.colors.textNormal,
            lineHeight: mapLineHeight(fontSize, height!),
            fontSize: mapUnit(fontSize),
          },
          !wrap
            ? {
                maxLines: 1,
                overflow: height! > 30 ? "hidden" : undefined,
                textOverflow: "ellipsis",
              }
            : {},
        ]}
      >
        {content}
      </Text>
    </View>
  );
});

export default PdfTextWidget;
