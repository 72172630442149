import { RoundedDataGrid } from "@app-components/DataGrid";
import { useReconciliation } from "@app-context/reconciliation/context";
import { ContextItemEntry } from "@app-views/portfolios/components/MainPortfolioGrid";
import { GridContainer } from "@app-views/reconciliation/components/styles";
import {
  BreakDetailsCellRendererProps,
  ReconciliationRowsStore,
} from "@app-views/reconciliation/types";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  CashBreakDatasourceDef,
  DatasourceDef,
  DiffReportData,
  ReconReportConfig,
} from "@enfusion-ui/types";
import { FormPanel } from "@enfusion-ui/web-components";
import { useReports, useTabs } from "@enfusion-ui/web-core";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { faExternalLink } from "@fortawesome/pro-solid-svg-icons";
import { GetContextMenuItemsParams, GetRowIdParams } from "ag-grid-community";
import React from "react";

import { reconciliationSnapshotColumns } from "../utils/colDefs";
import {
  getAllDatasources,
  getCashActivityReportId,
  getCashBalanceReportId,
  getRowData,
  isCashDataSource,
} from "../utils/commonUtils";
import { BreakDetailsCellRenderer } from "./breakDetailsPanel/BreakDetailsCellRenderer";

const ReconciliationSnapshotDetails: React.FC<{
  reconciliationRowStore: ReconciliationRowsStore;
}> = ({ reconciliationRowStore }) => {
  const { config, filterState, setReportsLoadingStatus } = useReconciliation();
  const { metaStore } = useReports();
  const { openTab } = useTabs();

  const [rowData, setRowData] =
    React.useState<BreakDetailsCellRendererProps[]>();
  const getRowId = (params: GetRowIdParams) => {
    return params.data.id;
  };

  React.useEffect(() => {
    const datasources = getAllDatasources(config);
    datasources.forEach((datasource) => {
      if (
        reconciliationRowStore[datasource?.reconId] &&
        datasource.datasource?.path === metaStore[datasource.reconId]?.path
      ) {
        setReportsLoadingStatus((prev) => ({
          ...prev,
          [datasource?.reconId]: false,
        }));
      }
    });
    const reportsData = config.reconReports.map((row: ReconReportConfig) => {
      let rowDataStore = {} as DiffReportData;
      if (isCashDataSource(row.reconciliationType)) {
        if (
          (row.datasource as CashBreakDatasourceDef)?.cashActivity?.path ===
          metaStore[getCashActivityReportId(row.reconReportId)]?.path
        ) {
          rowDataStore =
            reconciliationRowStore[getCashActivityReportId(row.reconReportId)];
        }
      } else {
        if (
          (row.datasource as DatasourceDef)?.path ===
          metaStore[row.reconReportId]?.path
        ) {
          rowDataStore = reconciliationRowStore[row.reconReportId];
        }
      }

      const rowData = getRowData(
        rowDataStore,
        row,
        filterState,
        row.filterColumns
      ) as BreakDetailsCellRendererProps;

      if (isCashDataSource(row.reconciliationType)) {
        rowData.reportData = {
          ...rowData.reportData,
          cashBalanceRowsStore:
            reconciliationRowStore[getCashBalanceReportId(row.reconReportId)],
        };
      }
      return rowData;
    });

    setRowData(reportsData);
  }, [config, filterState.currency, reconciliationRowStore, metaStore]);

  const getContextMenuItems = useRefCallback(
    (params: GetContextMenuItemsParams) => {
      const defaultItems = params?.defaultItems || ["export"];
      const entries = [
        ...defaultItems,
        "separator",
        {
          name: "Open break details",
          icon: icon(faExternalLink).html?.[0],
          action: () => {
            openBreaksTab(params?.node?.data);
          },
        },
      ] as (ContextItemEntry | null)[];

      return entries.filter(
        (entry) => entry !== null
      ) as unknown as ContextItemEntry[];
    },
    []
  );

  const openBreaksTab = useRefCallback(
    (args: any) => {
      openTab({
        name: args.reconciliationType ?? "",
        component: "reconciliation-break-details",
        unique: `${args.reconciliationType ?? "default"}-breaks-view`,
        config: args,
      });
    },
    [openTab]
  );

  return (
    <GridContainer>
      <RoundedDataGrid
        columnDefs={reconciliationSnapshotColumns()}
        autoSizeStrategy={{
          type: "fitGridWidth",
        }}
        height="100%"
        width="100%"
        rowData={rowData}
        masterDetail
        detailCellRenderer={BreakDetailsCellRenderer}
        detailRowAutoHeight
        getRowId={getRowId}
        getContextMenuItems={getContextMenuItems}
      />
    </GridContainer>
  );
};

export const SnapshotDetailsPanelContainer: React.FC<{
  title: string;
  render: () => React.ReactNode;
}> = ({ title, render }) => {
  return (
    <div style={{ height: "100%" }}>
      <FormPanel
        lineStyle
        collapsible
        keepRendered
        numColumns={1}
        defaultOpen
        title={title}
        showTextCollapseButton
        gapSize="l"
        render={render}
        titleStyle={{ padding: "0 var(--spacing-l)" }}
        thinContainer
        contentStyle={{ flexGrow: 1 }}
        style={{ height: "100%" }}
      />
    </div>
  );
};

export const SnapshotDetailsPanel: React.FC<{
  reconciliationRowStore: ReconciliationRowsStore;
}> = ({ reconciliationRowStore }) => {
  return (
    <>
      <SnapshotDetailsPanelContainer
        title="Reconciliation Snapshot"
        render={() => (
          <ReconciliationSnapshotDetails
            reconciliationRowStore={reconciliationRowStore}
          />
        )}
      />
    </>
  );
};
