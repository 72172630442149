import { SelectOption } from "@app-components/control/SimpleSelect";
import { ControlledInstrumentSelect } from "@app-components/inputs/InstrumentSelect/ControlledInstrumentSelect";
import {
  TOAST_CONTENT,
  useOEMSOrderForm,
  VarSwapOrderFormValues,
} from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { AccountAllocationInfo, HolidayCenterOption } from "@enfusion-ui/types";
import { DayCount } from "@enfusion-ui/types/src";
import { formatPercentage } from "@enfusion-ui/utils";
import {
  ButtonGroupSelect,
  ColumnGrid,
  ControlledCheckbox,
  ControlledEmpty,
  ControlledInputBase,
  ControlledNumericInput,
  ControlledSelect,
  DatePicker as DatePickerInput,
  FormController,
  PanelRow,
} from "@enfusion-ui/web-components";
import {
  errorToast,
  REST_API,
  useIsContentMobile,
  useOEMS,
} from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import HolidayCenterPicker from "./HolidayCenterPicker";

const startingButtonGroupOptions = [
  { value: "SpotStarting", label: "Spot Starting" },
  { value: "ForwardStarting", label: "Forward Starting" },
];

export type VarSwapProductDetailsProps = {
  editMode?: boolean;
};

const VarSwapProductDetails: React.FC<VarSwapProductDetailsProps> = ({
  editMode,
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dayCountOptions, setDayCountOptions] = React.useState<SelectOption[]>(
    []
  );
  const [expiryDisableState, setExpiryDisableState] =
    React.useState<boolean>(false);

  const { currencyDetails } = useOEMS();
  const { settings } = useOEMSOrderForm();
  const isMobile = useIsContentMobile();
  const { setValue } = useFormContext();
  const { atm, orderSide, accountAllocation, underlyingInstrument } =
    useWatch<VarSwapOrderFormValues>({
      name: ["atm", "orderSide", "accountAllocation", "underlyingInstrument"],
    });

  React.useEffect(() => {
    if (atm) setValue("strike", 0);
  }, [atm]);

  React.useEffect(() => {
    async function getDayCounts() {
      setLoading(true);
      try {
        const options = await REST_API.OEMS.GET_DAY_COUNTS.FETCH();
        setDayCountOptions(
          options.map((option: DayCount) => {
            return { label: option.description, value: option.name };
          })
        );
        setLoading(false);
      } catch (e) {
        errorToast(
          TOAST_CONTENT.oems.networkError.dayCounts.fetchFailed,
          (e as Error).message
        );
      }
    }
    getDayCounts();
  }, []);

  React.useEffect(() => {
    if (
      underlyingInstrument?.financialSubType?.parent === "Currency Pair" ||
      underlyingInstrument?.financialSubType?.parent === "Equity"
    ) {
      setExpiryDisableState(true);
      setValue("expiryType", undefined);
    } else {
      setExpiryDisableState(false);
    }
  }, [underlyingInstrument]);

  const handleUnderlyingChange = useRefCallback(
    async (value) => {
      if (value) {
        setValue("underlyingInstrumentId", value?.id);
        setValue("notionalCcy", value?.currency?.code);
        const holidayCenters: HolidayCenterOption[] =
          await REST_API.HOLIDAYS.GET_HOLIDAY_CENTERS.FETCH(
            value?.id,
            (
              accountAllocation as AccountAllocationInfo
            )?.allocationEntries?.map((i) => i.fundId) ?? []
          );
        const referenceInstrument =
          await REST_API.INSTRUMENT.GET_FRONT_MONTH_FUTURE.FETCH(value?.id);
        setValue("referenceInstrument", referenceInstrument);
        setValue("referenceInstrumentId", referenceInstrument?.id);

        if (
          value.financialSubType.parent === "Currency Pair" ||
          value.financialSubType.parent === "Equity"
        ) {
          setExpiryDisableState(true);
          setValue("expiryType", undefined);
        } else {
          setExpiryDisableState(false);
        }
        setValue(
          "holidayCenters",
          holidayCenters.map((holidayCenter) => holidayCenter.code)
        );
      }
    },
    [setValue, setExpiryDisableState, accountAllocation?.allocationEntries]
  );
  const currencyOptions = React.useMemo(() => {
    return (
      Object.keys(currencyDetails ?? {}).map((i) => {
        return { value: i, label: i };
      }) ?? []
    );
  }, [currencyDetails]);

  const formatValue = React.useCallback(
    (num: string | number | null) =>
      num !== null ? formatPercentage(Number(num) / 100) : "",
    []
  );

  return (
    <ColumnGrid numColumns={2} isMobile={isMobile} gapSize="m">
      <PanelRow>
        <ControlledInstrumentSelect
          hideOptions
          label="Underlying"
          name="underlyingInstrument"
          onChange={handleUnderlyingChange}
          financialSubTypes={["Equity", "CurrencyPair", "Index"]}
          searchOnlyPrimary={settings?.searchOnlyPrimary ?? false}
        />
      </PanelRow>

      <ControlledEmpty name="underlyingInstrumentId" />
      <PanelRow style={{ width: "50%" }}>
        <ControlledInputBase
          name="expiryType"
          render={({ value, onChange }) => (
            <ButtonGroupSelect
              title="Expiry Date"
              selectedValue={value}
              onSelect={onChange}
              options={startingButtonGroupOptions}
              disableDeselect
              disabled={editMode || expiryDisableState}
            />
          )}
          defaultValue={null}
        />
      </PanelRow>
      <PanelRow>
        <FormController
          name="effectiveDate"
          render={({ ref: _ref, value, ...rest }) => (
            <DatePickerInput
              {...rest}
              label="Effective Date"
              value={value ? new Date(value) : null}
              dateFormat="MM/dd/yyyy"
              clearable
            />
          )}
        />
        <FormController
          name="expiryDate"
          render={({ ref: _ref, value, ...rest }) => (
            <DatePickerInput
              {...rest}
              label="Expiry Date"
              value={value ? new Date(value) : null}
              dateFormat="MM/dd/yyyy"
              clearable
            />
          )}
        />
      </PanelRow>
      <PanelRow>
        <ControlledNumericInput
          disabled={atm ?? false}
          name="strike"
          label="Strike"
          defaultValue={0}
          formatValue={formatValue}
        />
        <ControlledCheckbox
          disabled={orderSide === "SellToClose" || orderSide === "BuyToClose"}
          name="atm"
          style={{ marginTop: "auto" }}
          label="ATM"
        />
        <ControlledSelect
          name="dayCount"
          options={dayCountOptions}
          isLoading={loading}
          label="Day Count"
        />
      </PanelRow>

      <PanelRow>
        <ControlledNumericInput
          name="lowerCap"
          label="Lower Cap"
          formatValue={formatValue}
        />
        <ControlledNumericInput
          name="upperCap"
          label="Upper Cap"
          formatValue={formatValue}
        />
      </PanelRow>
      <PanelRow>
        <ControlledNumericInput name="lowerBarrier" label="Lower Barrier" />
        <ControlledNumericInput name="upperBarrier" label="Upper Barrier" />
      </PanelRow>
      <PanelRow>
        <ControlledSelect
          options={currencyOptions}
          name="notionalCcy"
          label="Notional Ccy"
          inputId="var-swap-notional-ccy-input-id"
        />
        <HolidayCenterPicker />
      </PanelRow>
    </ColumnGrid>
  );
};

export default VarSwapProductDetails;
