import { WidgetComponentsDefinition } from "../../../types";
import NavSourceSelectionEditWidget from "./NavSourceSelectionEditWidget";
import NavSourceSelectionWidget from "./NavSourceSelectionWidget";
import PdfNavSourceSelectionWidget from "./PdfNavSourceSelectionWidget";

export const navSourceSelection: WidgetComponentsDefinition = {
  renderComponent: NavSourceSelectionWidget,
  editComponent: NavSourceSelectionEditWidget,
  renderPdfComponent: PdfNavSourceSelectionWidget,
};
