import { ControlledDatePicker, PanelRow } from "@enfusion-ui/web-components";
import * as React from "react";

import {
  FullHeightContainer,
  TabContentContainer,
} from "./components/styledComponents";
import { HistoryTabRevisionPanel } from "./panels/HistoryTabRevisionPanel";
import { HistoryTabSummaryPanel } from "./panels/HistoryTabSummaryPanel";

export const HistoryTab: React.FC = () => {
  return (
    <TabContentContainer>
      <FullHeightContainer>
        <PanelRow>
          <ControlledDatePicker
            basis="250px"
            mobileBasis="100%"
            controllerStyle={{ flexGrow: 0 }}
            clearable
            placeholder=""
            label="Archive Date"
            name="historyArchiveDate"
          />
        </PanelRow>

        <HistoryTabSummaryPanel />
        <HistoryTabRevisionPanel />
      </FullHeightContainer>
    </TabContentContainer>
  );
};
