import { AccountingMethodOptions } from "@enfusion-ui/types";
import { ControlledSelect } from "@enfusion-ui/web-components";

import { InputWrapper } from "../../../../dashboards/widgets/styles";
import { DateSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledLedgerSelect } from "../../../components/ReportQueryComponents/LedgerSelect";

const GLBalanceSheetQuickFilter = () => {
  return (
    <>
      <InputWrapper>
        <ControlledLedgerSelect name="generalLedgerIds" label="Ledger" />
      </InputWrapper>
      <InputWrapper>
        <DateSelection name="valueDate" label="Value Date" />
      </InputWrapper>
      <InputWrapper>
        <ControlledSelect
          name="accountingMethod"
          options={AccountingMethodOptions}
          label="Accounting Method"
        />
      </InputWrapper>
    </>
  );
};

export default GLBalanceSheetQuickFilter;
