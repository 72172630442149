import {
  BREAK_POINT_KEY_V2,
  BREAK_POINTS_MAP_V2,
  BREAK_POINTS_V2,
  CELL_SIZE,
  DEFAULT_DROPPED_ID,
  getBreakPointKey,
  useDashboard,
  Widget,
  WIDGET_DEFINITIONS,
  WidgetType,
} from "@enfusion-ui/dashboards";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  DashboardCompactType,
  DashboardGridProps,
  FlexLayout,
} from "@enfusion-ui/types";
import { ErrorBoundary } from "@enfusion-ui/web-components";
import { DimensionsProvider, styled } from "@enfusion-ui/web-core";
import { debounce } from "lodash";
import * as React from "react";
import { Layout, Responsive as ReactGridLayout } from "react-grid-layout";

import { renderWidgetContent } from "../widget/renderWidgetContent";
import GridBackground from "./GridBackground";

export const WidgetWrapper = styled.div`
  z-index: 1;
`;

const WidgetWrapperCore: React.FC<
  React.PropsWithChildren<{
    layout?: FlexLayout[];
    id: string;
    colWidth: number;
  }>
> = ({ id, layout, colWidth, children }) => {
  const placement = layout?.find((e) => e.i === id) || {
    w: 0,
    h: 0,
    x: 0,
    y: 0,
    minW: 1,
    minH: 1,
  };

  return (
    <DimensionsProvider
      width={placement.w * colWidth}
      height={placement.h * CELL_SIZE}
    >
      {children}
    </DimensionsProvider>
  );
};

const getDefaultSize = (layout: Layout, type: WidgetType): Layout => {
  const defaultSize = WIDGET_DEFINITIONS[type]?.defaultSize;
  if (defaultSize) return { ...layout, h: defaultSize[0], w: defaultSize[1] };
  return layout;
};

export const Grid: React.FC<DashboardGridProps<WidgetType>> = ({
  gridId,
  compactType = null,
  numOfCols = null,
  dimensions,
  widgets,
  height,
  width,
}) => {
  const { updateLayout, addWidget, editMode, mobile } = useDashboard();
  const breakpointKey = React.useMemo(
    () => getBreakPointKey(width, numOfCols),
    [width, numOfCols]
  );

  const layoutChangesRef = React.useRef<Record<string, FlexLayout[]>>({});
  const applyLayoutChange = useRefCallback(
    debounce(() => {
      const changes = { ...layoutChangesRef.current };
      layoutChangesRef.current = {};
      updateLayout(gridId, changes);
    }, 500),
    [updateLayout, breakpointKey]
  );
  const handleLayoutChange = useRefCallback(
    (layout: Layout[], allLayouts: Record<BREAK_POINT_KEY_V2, Layout[]>) => {
      layoutChangesRef.current[breakpointKey] = allLayouts[breakpointKey];
      applyLayoutChange();
    },
    [updateLayout, breakpointKey]
  );

  const handleDrop = useRefCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_layout: Layout[], layoutItem: Layout, event: any) => {
      const type = event.dataTransfer.getData(
        "text/plain"
      ) as unknown as WidgetType;
      addWidget(
        gridId,
        type,
        mobile ? layoutItem : getDefaultSize(layoutItem, type),
        breakpointKey
      );
    },
    [addWidget]
  );

  return (
    <>
      <GridBackground
        active={editMode}
        cols={BREAK_POINTS_MAP_V2[breakpointKey]}
        cellSize={CELL_SIZE}
        width={width}
        height={height}
      />
      <ReactGridLayout
        isDroppable={editMode}
        className="layout"
        layouts={dimensions.layout}
        breakpoints={BREAK_POINTS_V2(
          typeof numOfCols === "number" ? breakpointKey : undefined
        )}
        cols={BREAK_POINTS_MAP_V2}
        width={width}
        rowHeight={CELL_SIZE}
        compactType={compactType as DashboardCompactType}
        onLayoutChange={handleLayoutChange}
        autoSize={false}
        onDrop={handleDrop}
        droppingItem={{ i: DEFAULT_DROPPED_ID, h: 4, w: 2 }}
        draggableCancel=".not-moveable"
        isBounded
        containerPadding={[0, 0]}
        margin={[4, 0]}
      >
        {widgets.map((widget, index) => (
          <WidgetWrapper key={widget.id}>
            <WidgetWrapperCore
              id={widget.id}
              colWidth={width / BREAK_POINTS_MAP_V2[breakpointKey]}
              layout={dimensions.layout[breakpointKey]}
            >
              <ErrorBoundary
                style={{ minHeight: "80%", maxHeight: "95%", height: "100%" }}
              >
                <Widget
                  {...widget}
                  index={index}
                  gridId={gridId}
                  renderWidgetContent={renderWidgetContent}
                />
              </ErrorBoundary>
            </WidgetWrapperCore>
          </WidgetWrapper>
        ))}
      </ReactGridLayout>
    </>
  );
};
