import { ReportRowDataEntry } from "@enfusion-ui/web-core";
import { View } from "@react-pdf/renderer";
import { ColDef } from "ag-grid-community";
import * as React from "react";

import { ReportRow } from "./ReportRow";
import { TableHeaders } from "./TableHeaders";
import {
  findFitColumns,
  formatValues,
  isOddNumber,
  minColWidth,
  rowHeight,
} from "./utils";

export const TableRows: React.FC<{
  rows: Array<ReportRowDataEntry>;
  columnDefs: Array<ColDef>;
  pdfReportWidgetStyles: any;
  totalsRow: any;
  showTotalsRow: boolean;
  widgetWidth: number;
  widgetHeight: number;
}> = ({
  rows,
  columnDefs,
  totalsRow,
  pdfReportWidgetStyles,
  showTotalsRow,
  widgetWidth,
  widgetHeight,
}) => {
  const [colDefsWithWidth, newRows] = findFitColumns(
    columnDefs,
    rows,
    widgetWidth,
    minColWidth
  );

  let rowsWithFormattedValue = newRows
    .filter((row: ReportRowDataEntry) => typeof row !== "undefined")
    .slice(0, Math.ceil(widgetHeight / rowHeight));
  const createRowsValuesObjects = () =>
    rowsWithFormattedValue.map((row) => {
      return formatValues(row, colDefsWithWidth, false);
    });
  return (
    <>
      <TableHeaders
        pdfReportWidgetStyles={pdfReportWidgetStyles}
        columnDefs={colDefsWithWidth}
      />
      {showTotalsRow && (
        <View style={[pdfReportWidgetStyles.tableRow]}>
          <ReportRow
            values={formatValues(totalsRow, colDefsWithWidth, true)}
            pdfReportWidgetStyles={pdfReportWidgetStyles}
            upperIndex={0}
            showFlags={false}
            isTotalsRow
          />
        </View>
      )}
      {createRowsValuesObjects().map((row, j) => {
        return (
          <View
            key={`${j}-${row[j]?.value}`}
            style={[
              pdfReportWidgetStyles[isOddNumber(j) ? "oddRow" : "evenRow"],
              pdfReportWidgetStyles.tableRow,
            ]}
          >
            <ReportRow
              values={row}
              pdfReportWidgetStyles={pdfReportWidgetStyles}
              upperIndex={j}
              showFlags
            />
          </View>
        );
      })}
    </>
  );
};
