import { ReportWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { Checkbox, FormSectionAccordion } from "@enfusion-ui/web-components";
import * as React from "react";

import DatasourcePicker from "../../../widget/components/DatasourcePicker";
import { InputGroup, InputWrapper } from "../../styles";

const ReportEditWidget: React.FC<unknown> = () => {
  const { changeConfigKeyToBeApplied, editedConfig } = useWidget();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBasicChange = (key: string) => (value: any) => {
    changeConfigKeyToBeApplied(key, value);
  };

  const {
    showTitle = true,
    showTotalsRow = true,
    showGroupingRow = false,
  } = editedConfig as ReportWidgetConfig;

  return (
    <>
      <FormSectionAccordion title="Container Options">
        <InputGroup>
          <InputWrapper>
            <Checkbox
              checked={showTitle}
              label="Show Title"
              onChange={handleBasicChange("showTitle")}
            />
          </InputWrapper>
          <InputWrapper>
            <Checkbox
              checked={showTotalsRow}
              label="Show Totals Row"
              onChange={handleBasicChange("showTotalsRow")}
            />
          </InputWrapper>
          <InputWrapper>
            <Checkbox
              checked={showGroupingRow}
              label="Show Grouping Row"
              onChange={handleBasicChange("showGroupingRow")}
            />
          </InputWrapper>
        </InputGroup>
      </FormSectionAccordion>
      <FormSectionAccordion
        title="Datasource"
        dataE2EId="report-widget-datasource"
      >
        <DatasourcePicker
          canUseSubTable
          e2eTestId="report-widget-datasource-select"
        />
      </FormSectionAccordion>
    </>
  );
};

export default ReportEditWidget;
