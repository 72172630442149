import { styled } from "@enfusion-ui/web-core";

export const EditContainer = styled.div`
  width: 100%;
`;

export const InputGroup = styled.div<{ enabled?: boolean }>`
  display: ${({ enabled = true }) => (enabled ? "grid" : "block")};
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-l);
  @media (max-width: 500px) {
    display: flex;
    flex-flow: row wrap;
  }
`;

export const FlexGroup = styled.div`
  display: flex;
  gap: var(--spacing-l);

  @media (max-width: 500px) {
    display: flex;
    flex-flow: row wrap;
  }
`;

export const InputWrapper = styled.div<{ margin?: string }>`
  width: 100%;
  min-width: 250px;
  margin: ${({ margin }) => (margin ? margin : "0px")};
`;

export const SingleRowContainer = styled.div<{
  count?: number;
  noMargin?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ count = 2 }) => `repeat(${count}, 1fr)`};
  grid-column-gap: var(--spacing-l);
  margin-bottom: ${({ noMargin }) => (noMargin ? "0px" : "var(--spacing-l)")};
`;
