import { useReportRows } from "@app-context/reports/ReportsProvider";
import { useReportDatasource } from "@app-views/dashboards/hooks/useReportDatasource";
import { AsyncDatasourceContent } from "@app-views/dashboards/pdf/AsyncDatasourceContent";
import { ThemeDefinition } from "@enfusion-ui/core";
import { ReportWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { NodeData } from "@enfusion-ui/types";
import { getFileName } from "@enfusion-ui/utils";
import {
  ReportRowDataEntry,
  useDimensions,
  useReports,
  withTheme,
} from "@enfusion-ui/web-core";
import ReactPDF, { StyleSheet, Text, View } from "@react-pdf/renderer";
import { merge } from "lodash";
import * as React from "react";

import { basePdfReportWidgetStyles } from "./PdfReportWidgetStyles";
import { TableRows } from "./TableRows";
import { rowHeight } from "./utils";

const PdfReportWidget = withTheme(({ theme }: { theme: ThemeDefinition }) => {
  const { config } = useWidget();
  const { width, height } = useDimensions();
  const {
    showTitle = true,
    showTotalsRow = true,
    showGroupingRow = false,
  } = config as ReportWidgetConfig;
  const { datasource, reportViewConfig } = useReportDatasource([
    showGroupingRow,
  ]);
  const { dataStore } = useReports();
  const { rowsStore } = useReportRows();
  const title = React.useMemo(() => {
    return showTitle && datasource
      ? getFileName({
          name: datasource.name,
        } as NodeData)
      : undefined;
  }, [showTitle, datasource?.name]);
  const pdfReportWidgetStyles: ReactPDF.Styles = React.useMemo(() => {
    return StyleSheet.create(
      merge(basePdfReportWidgetStyles, {
        tableWrapper: {
          backgroundColor: theme.colors.backgroundColor1,
        },
        tableInnerWrapperBorderDark: {
          border: "1px solid #424242",
        },
        tableInnerWrapperBorderLight: {
          border: "1px solid #BDC3C7",
        },
        titleBackgroundColor: {
          backgroundColor: theme.colors.backgroundColor1Hover,
        },
        tableHeaderContainerBackGroundColor: {
          // backgroundColor: theme.colors.agBackgroundColor, // TODO
        },
        tableHeaderColorDark: {
          color: theme.colors.backgroundColor1Hover,
        },
        evenRow: {
          // backgroundColor: theme.colors.agBackgroundColor, // TODO
        },
        oddRow: {
          // backgroundColor: theme.colors.agOddRowBackgroundColor, // TODO
        },
        evenRowBorder: {
          // borderColor: theme.colors.agBackgroundColor, // TODO
        },
        oddRowBorderColor: {
          // borderColor: theme.colors.agOddRowBackgroundColor, // TODO
        },
        totalsRow: {
          backgroundColor: theme.colors.backgroundAccent,
          // borderTop: `1px solid ${theme.colors.agGridBorderColor}`, // TODO
          // borderBottom: `1px solid ${theme.colors.agGridBorderColor}`, // TODO
        },
        rowsTextNormal: {
          color: theme.colors.textNormal,
        },
        rowTextDangerColor: {
          color: theme.colors.danger,
        },
        borderWarning: {
          color: theme.colors.warning,
        },
        staticThemeRightBorder: {
          borderRight: `${
            theme.name === "Dark" ? "1px solid #424242" : "1px solid #bdc3c7"
          }`,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as any
    );
  }, [theme]);

  const tableId =
    (reportViewConfig?.tableId as string) || reportViewConfig?.reportId;

  return (
    <AsyncDatasourceContent>
      {() => (
        <View style={pdfReportWidgetStyles.reportContainer}>
          {showTitle && (
            <View
              style={[
                pdfReportWidgetStyles.titleContainer,
                pdfReportWidgetStyles["titleBackgroundColor"],
              ]}
            >
              <Text
                style={[
                  pdfReportWidgetStyles.title,
                  pdfReportWidgetStyles["rowsTextNormal"],
                ]}
              >
                {title}
              </Text>
            </View>
          )}
          <View style={pdfReportWidgetStyles.tableWrapper}>
            <View
              style={[
                pdfReportWidgetStyles.tableInnerWrapper,
                pdfReportWidgetStyles[
                  theme.name === "Dark"
                    ? "tableInnerWrapperBorderDark"
                    : "tableInnerWrapperBorderLight"
                ],
              ]}
            >
              {reportViewConfig && tableId && width && height && (
                <TableRows
                  rows={rowsStore[tableId].rows}
                  columnDefs={dataStore[tableId].columnDefs}
                  pdfReportWidgetStyles={pdfReportWidgetStyles}
                  totalsRow={rowsStore[tableId]?.totalsRow}
                  showTotalsRow={showTotalsRow}
                  widgetWidth={width}
                  widgetHeight={height}
                />
              )}
            </View>
            {reportViewConfig && tableId && width && (
              <View style={pdfReportWidgetStyles.rowsTextContainer}>
                <Text
                  style={[
                    pdfReportWidgetStyles.rowsLengthText,
                    pdfReportWidgetStyles["rowsTextNormal"],
                  ]}
                >{`received ${
                  rowsStore[tableId].rows
                    .filter(
                      (row: ReportRowDataEntry) => typeof row !== "undefined"
                    )
                    .slice(0, Math.ceil(width / rowHeight)).length
                } row(s)`}</Text>
              </View>
            )}
          </View>
        </View>
      )}
    </AsyncDatasourceContent>
  );
});

export default PdfReportWidget;
