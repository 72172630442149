import AccountTree from "@app-components/inputs/account/AccountTree";
import { NodeData } from "@enfusion-ui/types";
import { Button, Portal } from "@enfusion-ui/web-components";
import { errorToast, styled } from "@enfusion-ui/web-core";
import * as React from "react";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: var(--background-color-1);
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  border-radius: var(--radius);
`;

const OptionsContainer = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: var(--background-color-0);
  grid-column-gap: var(--spacing);
  margin-top: var(--spacing);
  padding: var(--spacing);
  border-radius: 2px;
  height: 38px;
`;

const AllocationAccountSelect = React.forwardRef((props: any, ref) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const selectedValue = React.useRef<NodeData[] | null>(null);
  const allocationAccountSelectRef = React.useRef(null);
  const selectedAccountNodeRef = React.useRef<NodeData>();

  AllocationAccountSelect.displayName = "AllocationAccountSelect";

  const cell = props.eGridCell;
  const { accountId } = props.data;

  const selectedAccount = React.useMemo(() => [`${accountId}`], [accountId]);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  React.useImperativeHandle(ref, () => ({
    getValue() {
      return selectedAccountNodeRef.current;
    },

    isCancelBeforeStart() {
      setMenuOpen(true);
      return false;
    },

    isPopup() {
      return true;
    },
  }));

  const onSubmit = async () => {
    try {
      closeMenu();
      const value = selectedValue.current?.find((i) => i.file);
      if (value) {
        selectedAccountNodeRef.current = value;
      }
    } catch (ex: any) {
      errorToast(ex?.message ?? ex);
    } finally {
      props.stopEditing();
    }
  };

  const handleOnChange = React.useCallback((nodes: NodeData[] | null) => {
    selectedValue.current = nodes;
  }, []);

  const onClose = () => {
    closeMenu();
    props.stopEditing();
  };

  return (
    <div ref={allocationAccountSelectRef}>
      <Portal
        open={menuOpen}
        attachedRef={allocationAccountSelectRef}
        onClickOutside={onClose}
        align="left"
        leftOffset={cell?.clientLeft ?? 5}
        topOffset={cell?.offsetHeight ?? 28}
        minWidth={cell?.clientWidth + 100 ?? 300}
      >
        <ContentContainer>
          <OptionsContainer>
            <AccountTree
              filterEmpty={false}
              values={selectedAccount}
              onSelectionChange={handleOnChange}
              checkSelections
              multiSelect={false}
            />
          </OptionsContainer>
          <ActionContainer>
            <Button
              data-e2e-id="account-selector-cancel-button"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              data-e2e-id="account-selector-proceed-button"
              primary
              onClick={onSubmit}
            >
              Proceed
            </Button>
          </ActionContainer>
        </ContentContainer>
      </Portal>
    </div>
  );
});

export default AllocationAccountSelect;
