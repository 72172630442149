import { WidgetComponentsDefinition } from "@app-views/dashboards/widgets/types";
import AssignmentPanel, {
  AssignedTraderSelect,
  ComplianceStateInput,
  PortfolioManagerSelect,
  ReviewedByInput,
  ReviewedOnInput,
  TradingDeskSelect,
} from "@app-views/oems/OrderTicket/panels/AssignmentPanel";
import { BoldText } from "@app-views/watchList/components/styles";
import { ColumnGrid, Select } from "@enfusion-ui/web-components";

const AssignmentPanelMobile: React.FC = () => {
  return (
    <>
      <BoldText style={{ textAlign: "center", marginTop: 8 }}>
        Assignment
      </BoldText>
      <ColumnGrid isMobile={false} numColumns={2}>
        <Select minWidth={100} label="PM" disabled />
        <Select minWidth={100} label="Desk" disabled />
      </ColumnGrid>
      <ColumnGrid isMobile={false} numColumns={1}>
        <Select minWidth={100} label="Trader" disabled />
      </ColumnGrid>
    </>
  );
};
export const assignmentPanel: WidgetComponentsDefinition = {
  renderComponent: () => <AssignmentPanel showCompliance />,
  renderMobileComponent: AssignmentPanelMobile,
};

export const portfolioManagerSelect: WidgetComponentsDefinition = {
  renderComponent: PortfolioManagerSelect,
  renderMobileComponent: () => <Select minWidth={100} label="PM" disabled />,
};

export const assignedTradingDeskSelect: WidgetComponentsDefinition = {
  renderComponent: TradingDeskSelect,
  renderMobileComponent: () => <Select minWidth={100} label="Desk" disabled />,
};

export const assignedTraderSelect: WidgetComponentsDefinition = {
  renderComponent: AssignedTraderSelect,
  renderMobileComponent: () => (
    <Select minWidth={100} label="Trader" disabled />
  ),
};

export const complianceStateInput: WidgetComponentsDefinition = {
  renderComponent: ComplianceStateInput,
};

export const reviewedByInput: WidgetComponentsDefinition = {
  renderComponent: ReviewedByInput,
};

export const reviewedOnInput: WidgetComponentsDefinition = {
  renderComponent: ReviewedOnInput,
};
