import {
  basicInfoMap,
  useRefCallback,
  useRestAbortableOptions,
} from "@enfusion-ui/hooks";
import { SelectOptionsType } from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

export type ComplianceRulesMultiselectProps = Omit<
  Partial<MultiSelectProps<number>>,
  "options" | "value" | "onChange"
> & {
  value?: number[] | null;
  onChange?: (values: number[]) => void;
  label?: string;
};

const ComplianceRulesMultiselect: React.FC<ComplianceRulesMultiselectProps> = ({
  value: values,
  onChange,
  label,
  ...rest
}) => {
  const [complianceRules, setComplianceRules] = React.useState<number[]>([]);

  const { options, loading } = useRestAbortableOptions(
    REST_API.OEMS.GET_COMPLIANCE_RULES.FETCH,
    basicInfoMap
  );

  React.useEffect(() => {
    setComplianceRules(values ?? []);
  }, [values]);

  const handleChange = useRefCallback(
    (values: SelectOptionsType<number>[]) => {
      const ids = values?.length
        ? values.map((option: SelectOptionsType<number>) => option.value)
        : [];
      setComplianceRules(ids);
      onChange?.(ids);
    },
    [onChange]
  );

  return (
    <MultiSelect
      {...rest}
      value={complianceRules ? getSelectedValues(complianceRules, options) : []}
      onChange={(values) => handleChange(values as SelectOptionsType<number>[])}
      label={label}
      options={options}
      loading={loading}
    />
  );
};

export const ControlledComplianceRulesMultiselect: React.FC<
  Omit<ComplianceRulesMultiselectProps, "value" | "onChange"> & {
    name?: string;
  }
> = ({
  name = "complianceRuleIds",
  label = "Compliance Rule Id(s)",
  ...props
}) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...renderProps }) => (
        <ComplianceRulesMultiselect label={label} {...renderProps} {...props} />
      )}
    />
  );
};

export default ComplianceRulesMultiselect;
