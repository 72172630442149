import { TrashIconButtonCellRenderer } from "@app-components/cells";
import { RoundedDataGrid } from "@app-components/DataGrid";
import { useFormTableListContext } from "@app-utils/useFormTableListContext";
import { TicketTabHeaderButton } from "@app-views/trade/TradeTicket/components/tabs/components/styledComponents";
import {
  FormTableListControl,
  useModalState,
  useRefCallback,
} from "@enfusion-ui/hooks";
import { KeywordType, SelectOptionsType } from "@enfusion-ui/types";
import { getSelectOption } from "@enfusion-ui/utils";
import {
  Button,
  CenterContent,
  ControlledEmpty,
  Modal,
  Select,
  Spinner,
} from "@enfusion-ui/web-components";
import { styled, useGridApi } from "@enfusion-ui/web-core";
import {
  CellValueChangedEvent,
  ColDef,
  GridReadyEvent,
  IRowNode,
} from "ag-grid-community";
import { uniqBy } from "lodash";
import * as React from "react";
import { useWatch } from "react-hook-form";

import { KeywordCellEditor } from "./KeywordCellEditor";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

const ModalContainer = styled.div`
  min-width: 21.25rem;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

const ActionContainer = styled.div`
  display: flex;
  height: 2.25rem;
  gap: var(--spacing-l);
  justify-content: space-between;
  margin-top: var(--spacing-xl);
`;

const keywordsTabColumnDefs: ColDef[] = [
  {
    width: 40,
    headerName: "",
    field: "selection",
    pinned: "left",
    lockPinned: true,
    resizable: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    initialWidth: 240,
    headerName: "NAME",
    field: "name",
    editable: true,
    type: "editable",
    cellEditorPopup: true,
  },
  {
    initialWidth: 240,
    headerName: "Value",
    field: "value",
    editable: true,
    type: "editable",
    cellEditorPopup: true,
  },
  {
    width: 40,
    headerName: "",
    field: "delete",
    pinned: "right",
    lockPinned: true,
    resizable: false,
    type: "removeAction",
    cellStyle: { padding: "0" },
  },
];

type KeywordsTabCoreProps = {
  keywordsControl: FormTableListControl<KeywordType>;
  initialLoading?: boolean;
};

export const KeywordsTabCore: React.FC<KeywordsTabCoreProps> = ({
  keywordsControl,
  initialLoading = false,
}) => {
  const { keywords = [] } = useWatch({
    name: ["keywords"],
  }) as { keywords: KeywordType[] };

  const [name, setKeyName] = React.useState<string | null>(null);
  const [value, setKeyValue] = React.useState<string | null>(null);

  const { open, openModal, closeModal } = useModalState({
    onClose: () => {
      setKeyName(null);
      setKeyValue(null);
    },
  });

  const { onGridReady } = useGridApi((event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
  });

  const keywordOptions = React.useMemo(
    () =>
      uniqBy(keywords, "name").map((item) => ({
        label: item.name,
        value: item.name,
      })),
    [keywords]
  );

  const valueOptions = React.useMemo(
    () =>
      uniqBy(keywords, "value").map((item) => ({
        label: item.value,
        value: item.value,
      })),
    [keywords]
  );

  const handleSave = useRefCallback(() => {
    const foundEntry = keywords.some((x) => x.name === name);
    if (!foundEntry) {
      keywordsControl.addRow({ name: name ?? "", value: value ?? "" });
    } else {
      const updatedList = keywords.map((item) =>
        item.name === name ? { name: item.name, value: value ?? "" } : item
      );
      keywordsControl.initializeData(updatedList);
    }
    closeModal();
  }, [keywords, name, value]);

  const onCellValueChanged = useRefCallback(
    ({ data }: CellValueChangedEvent) => {
      keywordsControl.modifyRow(data.__row_id, data);
    },
    []
  );

  const onClickTrashIconCell = useRefCallback((node: IRowNode) => {
    keywordsControl.deleteRows([node.data.__row_id]);
  }, []);

  const getContextMenuItems = useFormTableListContext(keywordsControl);

  return (
    <Container>
      <ControlledEmpty name="keywords" />

      <TicketTabHeaderButton
        theme="primary"
        onClick={openModal}
        data-testid="keywords-tab-new-button"
        data-e2e-id="add-new-keyword-btn"
      >
        New Keyword
      </TicketTabHeaderButton>

      {initialLoading ? (
        <CenterContent fillHeight>
          <Spinner />
        </CenterContent>
      ) : (
        <RoundedDataGrid
          height="100%"
          suppressCellFocus
          rowData={keywords}
          multiSortKey="ctrl"
          rowSelection="multiple"
          onGridReady={onGridReady}
          suppressRowClickSelection
          stopEditingWhenCellsLoseFocus
          columnDefs={keywordsTabColumnDefs}
          getRowId={keywordsControl.getRowId}
          onCellValueChanged={onCellValueChanged}
          getContextMenuItems={getContextMenuItems}
          defaultColDef={{
            sortable: true,
            resizable: true,
            suppressHeaderMenuButton: true,
            suppressMovable: true,
            suppressKeyboardEvent: () => true,
          }}
          columnTypes={{
            editable: {
              cellEditor: KeywordCellEditor,
              cellEditorParams: { keywords },
            },
            removeAction: {
              cellRenderer: TrashIconButtonCellRenderer,
              cellRendererParams: { onClick: onClickTrashIconCell },
            },
          }}
        />
      )}

      <Modal
        isOpen={open}
        onClose={closeModal}
        title="Keyword Editor New"
        dataE2EId="new-keyword-modal"
        content={
          <ModalContainer>
            <Select
              required
              clearable
              creatable
              minWidth={110}
              label="Keyword"
              options={keywordOptions}
              value={name ? getSelectOption(keywordOptions, name) : undefined}
              onChange={(option: SelectOptionsType<string> | null) =>
                option && setKeyName(option.value)
              }
              inputId="select-keyword-option"
            />

            <Select
              required
              clearable
              creatable
              label="Value"
              minWidth={110}
              options={valueOptions}
              value={value ? getSelectOption(valueOptions, value) : undefined}
              onChange={(option: SelectOptionsType<string> | null) =>
                option && setKeyValue(option.value)
              }
              inputId="select-keyword-value"
            />

            <ActionContainer>
              <Button
                theme="primary"
                onClick={handleSave}
                disabled={!name || !value}
                dataE2EId="trade-keyword-save-btn"
              >
                Save and Close
              </Button>
              <Button onClick={closeModal}>Cancel</Button>
            </ActionContainer>
          </ModalContainer>
        }
      />
    </Container>
  );
};
