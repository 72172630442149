import { SelectOptionsType } from "@enfusion-ui/types";

import {
  AnalyticsBenchmark,
  analyticsBenchmarkValues,
  AnalyticsFrequency,
  analyticsFrequencyValues,
  AnalyticsPivotsPerformance,
  analyticsPivotsPerformanceValues,
  AnalyticsPivotsRisk,
  analyticsPivotsRiskValues,
  AnalyticsRollUp,
  analyticsRollUpValues,
  AnalyticsTimeFrame,
  analyticsTimeFrameValues,
  AnalyticsTopN,
  analyticsTopNValues,
} from "./types";

export const ANALYTICS_PIVOTS_DEFAULT_WIDTH = 400;
export const ANALYTICS_PIVOTS_MIN_WIDTH = 300;
export const ANALYTICS_CONTENT__MIN_WIDTH = 600;

export const analyticsRollUpOptions: SelectOptionsType<AnalyticsRollUp>[] =
  Object.entries(analyticsRollUpValues).map(([label, value]) => ({
    label,
    value,
  }));

export const analyticsBenchmarkOptions: SelectOptionsType<AnalyticsBenchmark>[] =
  Object.entries(analyticsBenchmarkValues).map(([label, value]) => ({
    label,
    value,
  }));

export const analyticsTopNOptions: SelectOptionsType<AnalyticsTopN>[] =
  Object.entries(analyticsTopNValues).map(([label, value]) => ({
    label,
    value,
  }));

export const analyticsPeriodOptions: SelectOptionsType<AnalyticsTimeFrame>[] =
  Object.entries(analyticsTimeFrameValues).map(([label, value]) => ({
    label,
    value,
  }));

export const analyticsFrequencyOptions: SelectOptionsType<AnalyticsFrequency>[] =
  Object.entries(analyticsFrequencyValues).map(([label, value]) => ({
    label,
    value,
  }));

export const analyticsPivotsPerformanceOptions: SelectOptionsType<AnalyticsPivotsPerformance>[] =
  Object.entries(analyticsPivotsPerformanceValues).map(([label, value]) => ({
    label,
    value,
  }));

export const analyticsPivotsRiskOptions: SelectOptionsType<AnalyticsPivotsRisk>[] =
  Object.entries(analyticsPivotsRiskValues).map(([label, value]) => ({
    label,
    value,
  }));

export const ANALYTICS_ERROR_MESSAGE = "There was a issue loading the data";

export const createPieChartSubtitle = (args: {
  title: string;
  description: string;
  subText?: string;
  subTextColor?: "success" | "danger";
}) => {
  const { title, description, subText, subTextColor = "text-normal" } = args;
  const subLine = subText
    ? `<div style="font-size: 16px; font-weight: 700; color:var(--${subTextColor};">${subText}</div>`
    : "";

  return `<div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 4px;">
    <div style="font-size: 24px; font-weight: 700; color: var(--text-normal);">${title}</div>
    ${subLine}
    <div style="font-size: 12px; color: var(--text-color-1); width: 100px; white-space: wrap; text-align: center;">
      ${description}
    </div>
  </div>`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatterCreator<T = any>(cb: (scope: T) => string) {
  const a = {
    a: function formatter() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const self = this as any;
      return cb(self);
    },
  };
  return a.a;
}
