import { WidgetComponentsDefinition } from "@app-views/dashboards/widgets/types";

import SecurityInfoEditWidget from "./SecurityInfoEditWidget";
import SecurityInfoWidget from "./SecurityInfoWidget";

export const securityInfoPanel: WidgetComponentsDefinition = {
  renderComponent: SecurityInfoWidget,
  editComponent: SecurityInfoEditWidget,
  renderMobileComponent: SecurityInfoWidget,
};
