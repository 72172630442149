import { WidgetComponentsDefinition } from "../../types";
import PdfSwitchWidget from "./PdfSwitchWidget";
import SwitchEditWidget from "./SwitchEditWidget";
import SwitchWidget from "./SwitchWidget";

export const toggle: WidgetComponentsDefinition = {
  renderComponent: SwitchWidget,
  editComponent: SwitchEditWidget,
  renderPdfComponent: PdfSwitchWidget,
};
