import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { InstrumentInfo, InstrumentSearchResult } from "@enfusion-ui/types";
import {
  MultiSelect,
  Portal,
  SelectContainer,
} from "@enfusion-ui/web-components";
import { AppLogging, useIsMobileDevice } from "@enfusion-ui/web-core";
import { debounce, isEqual } from "lodash";
import * as React from "react";

import { fetchInstrument } from "../../../views/oems/utils/actions";
import { AdvancedSearchButton } from "../components/AdvancedSearchButton";
import { AdvancedSearchContainer } from "../styledComponents";
import AdvancedInstrumentSearch from "./components/AdvancedInstrumentSearch";
import { CustomOption } from "./components/CustomOption";
import {
  InstrumentMultiSelectProps,
  InstrumentOption,
  InstrumentSearchBy,
} from "./types";
import { fetchInstrumentOptions, getSelectorValue } from "./utils";

const fetchInstruments = async (ids: number[]) =>
  Promise.all(ids.map((id) => fetchInstrument(id)));

export const InstrumentMultiSelect: React.FC<InstrumentMultiSelectProps> = ({
  value = null,
  onChange,
  label = "Instrument",
  autoFocus,
  disabled,
  enableAdvanced = false,
  defaultSearchBy = InstrumentSearchBy.MARKET_IDENTIFIER,
  financialSubTypes,
  searchOnlyPrimary = false,
}) => {
  const isMounted = useMounted();
  const [loading, setLoading] = React.useState(false);
  const [showAdvanced, setShowAdvanced] = React.useState(false);
  const selectRef = React.useRef(null);

  const [inputValue, setInputValue] = React.useState<string>("");
  const [instrumentValues, setInstrumentValues] = React.useState<
    InstrumentOption[] | null
  >(null);
  const [options, setOptions] = React.useState<
    InstrumentOption[] | undefined
  >();

  const isMobile = useIsMobileDevice();

  const hasChanges = useRefCallback(() => {
    return !isEqual(
      instrumentValues?.map(({ value }) => value),
      value
    );
  }, [value, instrumentValues]);

  React.useEffect(() => {
    const getInstruments = async () => {
      try {
        if (value?.length) {
          setLoading(true);
          const instrumentInfo = await fetchInstruments(value);
          const options = instrumentInfo.filter(Boolean)?.map(getSelectorValue);
          setInstrumentValues(options as InstrumentOption[]);
        } else {
          setInstrumentValues(null);
        }
      } catch (err: any) {
        AppLogging.error("Failed to fetch instrument data", err);
      } finally {
        setLoading(false);
      }
    };

    if (hasChanges()) {
      getInstruments();
    }
  }, [value]);

  const searchInstruments = useRefCallback(
    debounce(async (searchText: string) => {
      setLoading(true);
      try {
        const options = await fetchInstrumentOptions(
          searchText,
          financialSubTypes,
          defaultSearchBy === InstrumentSearchBy.DESCRIPTION,
          searchOnlyPrimary
        );

        if (isMounted()) {
          setOptions(options as InstrumentOption[] | undefined);
        }
      } catch (err) {
        AppLogging.error("Failed to fetch instrument search result data", err);
      } finally {
        setLoading(false);
      }
    }, 200),
    [financialSubTypes, defaultSearchBy]
  );

  const handleChange = useRefCallback(
    debounce(async (options: InstrumentOption[] | null) => {
      setInstrumentValues(options);
      const instrument = options?.[0]?.instrument as InstrumentSearchResult;

      const valueText =
        defaultSearchBy === InstrumentSearchBy.MARKET_IDENTIFIER
          ? instrument?.ticker
          : instrument?.description;
      setInputValue(valueText ?? "");
      if (showAdvanced) setShowAdvanced(false);
    }, 200),
    [defaultSearchBy]
  );

  const onMenuClose = useRefCallback(async () => {
    if (instrumentValues?.length) {
      const ids = instrumentValues.map(({ value }) => value);
      const instruments = await fetchInstruments(ids);
      onChange(instruments as InstrumentInfo[]);
    } else {
      setInputValue("");
      onChange(null);
    }
  }, [instrumentValues]);

  const toggleAdvancedMenu = debounce(
    (hide) => setShowAdvanced((prev) => (hide ? false : !prev)),
    150
  );

  return (
    <>
      <SelectContainer ref={selectRef}>
        <MultiSelect
          name="instrument"
          label={label}
          value={instrumentValues}
          options={options}
          onChange={(value) => {
            handleChange(value as InstrumentOption[]);
          }}
          onInputChange={(value: string) => {
            searchInstruments(value);
          }}
          components={{ Option: CustomOption }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          filterOption={(option: any) => !!option}
          isLoading={loading}
          autoFocus={autoFocus}
          onMenuClose={onMenuClose}
          clearable
          disabled={disabled}
          isMulti
          inputId="instrument-multi-selection-id"
        />
        {enableAdvanced && (
          <AdvancedSearchButton
            onClick={() => toggleAdvancedMenu(false)}
            disabled={disabled}
            active={showAdvanced}
          />
        )}
      </SelectContainer>
      {enableAdvanced && (
        <Portal
          open={showAdvanced}
          attachedRef={selectRef}
          onClickOutside={() => toggleAdvancedMenu(true)}
        >
          <AdvancedSearchContainer
            animate={{ opacity: showAdvanced ? 1 : 0 }}
            isMobile={isMobile}
          >
            <AdvancedInstrumentSearch
              defaultInputValue={inputValue}
              defaultSearchBy={defaultSearchBy}
              financialSubTypes={financialSubTypes}
              defaultPrimaryOnly={searchOnlyPrimary}
              onInstrumentSelect={(id, description) =>
                handleChange([
                  {
                    label: description,
                    value: id,
                    instrument: null,
                  },
                ])
              }
            />
          </AdvancedSearchContainer>
        </Portal>
      )}
    </>
  );
};

export default InstrumentMultiSelect;
