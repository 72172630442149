import { EMPTY_RECORD } from "@enfusion-ui/core";
import {
  TraderSelectionWidgetConfig,
  useChannelDataValues,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import { TraderSelect } from "../../../../../reports/components/ReportQueryComponents/AssignedTraderSelect";

const TraderSelectionWidget = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const {
    defaultValue,
    key,
    paramChannelIds = EMPTY_RECORD,
    paramStaticValues = EMPTY_RECORD,
  } = config as TraderSelectionWidgetConfig;

  const paramChannelData = useChannelDataValues(paramChannelIds);
  const params = React.useMemo(() => {
    return { ...paramChannelData, ...paramStaticValues };
  }, [paramChannelData, paramStaticValues]);

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Trader IDs",
        type: ChannelDataType.Number,
      },
    ]);
  }, [addChannelKeys, key]);

  React.useEffect(() => {
    setChannelData([defaultValue]);
  }, [setChannelData, defaultValue]);

  return (
    <CenterContent>
      <TraderSelect
        selectProps={{ label: key }}
        value={defaultValue}
        onChange={(ids) => setChannelData([ids])}
        desks={params.desks}
      />
    </CenterContent>
  );
};

export default TraderSelectionWidget;
