import { SocketController } from "../utils/socketController";
import { WebWorkerMessenger, } from "./types";
const messenger = new WebWorkerMessenger();
const MessageType = {
    subscribe: "subscribe",
    unsubscribe: "unsubscribe",
    stateUpdate: "state-update",
    initStatus: "init-status",
    close: "close",
    error: "error",
    locatesError: "locates-error",
    locatesSubscribeStatus: "locates-subscribe-status",
    locatesUpdate: "locates-update",
};
let locateContextState = {
    locatePerInstrument: {},
    enquiryPerInstrument: {},
    subscriptionPerInstrument: {},
};
const convertToLocatesRow = (allLocatesData) => {
    return allLocatesData?.map((eachLocate) => {
        return {
            id: eachLocate.id,
            broker: eachLocate.primeBroker?.code,
            availableQty: eachLocate.availableQuantity,
            cost: eachLocate.cost,
            costType: eachLocate.costType,
            lastPollTime: eachLocate.enquiry?.lastPollingTime,
            locateId: eachLocate.locateID,
            inquiryState: eachLocate?.enquiry?.stockBorrowEnquiryState,
            locateState: eachLocate?.enquiry?.borrowEnquiryLocateState,
            lastMessage: eachLocate?.enquiry?.lastMessage,
            requestedQty: eachLocate?.enquiry?.quantity,
            enquiryType: eachLocate?.enquiry?.enquiryType ?? "Daily",
        };
    });
};
const convertToEnquiryRow = (allEnquiriesData, allLocatesData) => {
    return allEnquiriesData?.map((eachEnquiry) => {
        const locate = allLocatesData?.find((locate) => locate.enquiry?.id === eachEnquiry.id) ??
            null;
        return {
            id: eachEnquiry.id,
            broker: eachEnquiry.primeBroker?.code,
            groupId: eachEnquiry.groupId,
            requestedQty: eachEnquiry.quantity,
            availableQty: locate?.availableQuantity,
            cost: locate?.cost,
            costType: locate?.costType,
            inquiryState: eachEnquiry.stockBorrowEnquiryState,
            locateState: eachEnquiry.borrowEnquiryLocateState,
            lastPollTime: eachEnquiry.lastPollingTime,
            locateId: locate?.locateID,
            lastMessage: eachEnquiry.lastMessage,
            enquiryType: eachEnquiry.enquiryType ?? "Daily",
        };
    });
};
function reset() {
    locateContextState = {
        locatePerInstrument: {},
        enquiryPerInstrument: {},
        subscriptionPerInstrument: {},
        readyState: undefined,
    };
}
const socketController = new SocketController({
    path: `wss://${self.location.host}/oms`,
    onOpen: (socket) => {
        socket.send(JSON.stringify({
            command: "CONNECT",
            "accept-version": "1.2",
            "sub-to": "/locates",
        }));
    },
    onMessage: async (event, socket) => {
        const eventMessage = JSON.parse(event.data);
        //should not send message if some provider has terminated the worker/socket
        if (eventMessage.command === "CONNECTED" &&
            socket.readyState !== WebSocket.CLOSED &&
            socket.readyState !== WebSocket.CLOSING) {
            messenger.broadcast({
                type: MessageType.initStatus,
                payload: {
                    isConnected: true,
                },
            });
        }
        if (eventMessage.command === "MESSAGE" &&
            socket.readyState !== WebSocket.CLOSED &&
            socket.readyState !== WebSocket.CLOSING) {
            switch (eventMessage.payload.type) {
                case MessageType.locatesSubscribeStatus: {
                    const { payload: { error, instrumentId, subscribed }, } = eventMessage;
                    if (error) {
                        locateContextState.subscriptionPerInstrument[instrumentId] = false;
                        messenger.broadcast({
                            type: MessageType.locatesError,
                            payload: {
                                instrumentId,
                                errorMessage: `Error while subscribing to locates for instrument: ${instrumentId}`,
                            },
                        });
                    }
                    else {
                        locateContextState.subscriptionPerInstrument[instrumentId] =
                            subscribed;
                        messenger.broadcast({
                            type: MessageType.locatesSubscribeStatus,
                            payload: {
                                instrumentId,
                                isSubscribed: subscribed,
                            },
                        });
                    }
                    break;
                }
                case MessageType.locatesUpdate: {
                    const { 
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    payload: { error, locates, enquiries, instrumentId }, } = eventMessage;
                    if (error) {
                        locateContextState.locatePerInstrument[instrumentId] = [];
                        locateContextState.enquiryPerInstrument[instrumentId] = [];
                        messenger.broadcast({
                            type: MessageType.locatesError,
                            payload: {
                                instrumentId,
                                errorMessage: `Error when fetching locates for: ${instrumentId}`,
                            },
                        });
                        break;
                    }
                    const locateRows = convertToLocatesRow(locates ?? []);
                    const enquiryRows = convertToEnquiryRow(enquiries, locates);
                    locateContextState.locatePerInstrument[instrumentId] = locates;
                    locateContextState.enquiryPerInstrument[instrumentId] = enquiries;
                    messenger.broadcast({
                        type: MessageType.locatesUpdate,
                        payload: {
                            locateRows,
                            enquiryRows,
                        },
                    });
                    break;
                }
            }
        }
    },
    onError: (err) => {
        console.error(`Error in Locates Worker: ${err}`);
        messenger.broadcast({
            type: MessageType.error,
        });
        socketController.close();
    },
    onClose: (ev, socket) => {
        console.warn("Locates socket close", ev);
        locateContextState = {
            ...locateContextState,
            readyState: socket.readyState,
        };
        messenger.broadcast({
            type: MessageType.close,
            payload: { readyState: socket.readyState },
        });
        reset();
    },
    onDisconnect: (socket) => {
        socket.send(JSON.stringify({ command: "DISCONNECT" }));
        messenger.broadcast({
            type: MessageType.close,
            payload: { readyState: socket.readyState },
        });
        reset();
    },
});
export const LocatesModule = {
    enable: (postMessage) => {
        messenger.send = postMessage;
        socketController.init();
    },
    disable: () => {
        socketController.close("locate");
    },
    getCurrentState: () => {
        return {
            socketStatus: socketController.socketStatus,
        };
    },
    onTerminate: () => {
        socketController.close();
    },
    onMessage: (_message, _hostId) => {
        const { payload, command } = _message;
        switch (command) {
            case "subscribe-instrument": {
                const { instrumentId } = payload;
                locateContextState = {
                    ...locateContextState,
                    readyState: socketController.socketStatus,
                };
                const existingLocatesData = locateContextState?.locatePerInstrument[instrumentId];
                const existingEnquiryData = locateContextState?.enquiryPerInstrument[instrumentId];
                if (existingLocatesData || existingEnquiryData) {
                    messenger.broadcast({
                        type: MessageType.locatesUpdate,
                        payload: {
                            isInitialized: true,
                            locateRows: convertToLocatesRow(existingLocatesData),
                            enquiryRows: existingEnquiryData,
                        },
                    });
                }
                if (!locateContextState.subscriptionPerInstrument[instrumentId]) {
                    socketController.send(JSON.stringify({
                        command: "SUBSCRIBE",
                        destination: `/oms/locates/${instrumentId}`,
                    }));
                }
                break;
            }
            case "unsubscribe-instrument": {
                const { instrumentId } = payload;
                socketController.send(JSON.stringify({
                    command: "UNSUBSCRIBE",
                    destination: `/oms/locates/${instrumentId}`,
                }));
                break;
            }
            case "enquire-quantity": {
                const { instrumentId, quantity, brokers } = payload;
                socketController.send(JSON.stringify({
                    command: "SEND",
                    "accept-version": "1.2",
                    destination: `/oms/locates/${instrumentId}`,
                    payload: {
                        quantity,
                        specificBrokers: brokers,
                    },
                }));
                break;
            }
            case "clear-instrument-data": {
                const { instrumentId } = payload;
                delete locateContextState.locatePerInstrument[instrumentId];
                delete locateContextState.enquiryPerInstrument[instrumentId];
                delete locateContextState.subscriptionPerInstrument[instrumentId];
                locateContextState.readyState = socketController.socketStatus;
            }
        }
    },
};
