import { NodeData } from "@enfusion-ui/types";
import {
  Button,
  ErrorAlert,
  FolderSelector,
  Modal,
  TextInput,
} from "@enfusion-ui/web-components";
import { AppLogging, styled } from "@enfusion-ui/web-core";
import * as React from "react";

const ModalContentContainer = styled.div`
  margin-top: 1rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  width: 100%;
  min-width: 300px;
  margin: 5px 0px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

type FileMoveFormProps = {
  currentFolder: string;
  onSubmit: (selectedNode: NodeData | null) => void;
  root?: string;
};

export type FileMoveModalProps = {
  open: boolean;
  filePath: string;
  onClose: VoidFunction;
  onSubmit: (selectedNode: NodeData | null) => void;
  root?: string;
};

const FileMoveForm: React.FC<FileMoveFormProps> = ({
  currentFolder,
  onSubmit,
  root,
}) => {
  const [selectedNode, setSelectedNode] = React.useState<NodeData | null>(null);

  const handleSelectionChanged = (node: NodeData | null) => {
    setSelectedNode(node);
  };

  const handleSubmit = () => {
    onSubmit(selectedNode);
  };

  return (
    <InputContainer>
      <InputWrapper>
        <TextInput
          name="currentFolder"
          label="Current Folder"
          value={currentFolder}
          autoFocus
          disabled
          data-e2e-id="move-file-modal-current-selector"
        />
      </InputWrapper>
      <InputWrapper>
        <FolderSelector
          label="Move File To"
          onSelect={handleSelectionChanged}
          root={root}
          clearable
          data-e2e-id="move-file-modal-folder-selector"
        />
        <ButtonWrapper>
          <Button
            primary
            type="submit"
            onClick={handleSubmit}
            data-e2e-id="move-file-modal-submit-btn"
          >
            Submit
          </Button>
        </ButtonWrapper>
      </InputWrapper>
    </InputContainer>
  );
};

const FileMoveModal: React.FC<FileMoveModalProps> = (
  props: FileMoveModalProps
) => {
  const { open, onClose, filePath: fileName, onSubmit, root } = props;
  const [error, setError] = React.useState<Error | null>();

  const handleSubmit = (selectedNode: NodeData | null) => {
    try {
      onSubmit(selectedNode);
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError(err as any);
      AppLogging.error("Failed to move file", err);
    }
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title="Move File"
      allowDismissal
      content={
        <ModalContentContainer>
          {error && <ErrorAlert error={error} />}
          <FileMoveForm
            currentFolder={fileName}
            onSubmit={handleSubmit}
            root={root}
          />
        </ModalContentContainer>
      }
    />
  );
};

export default FileMoveModal;
