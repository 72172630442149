import {
  PortfolioConfigV1,
  PortfolioSettingsV1,
  PortfolioStoredConfigV1,
} from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { DashboardRoot, NodeData } from "@enfusion-ui/types";
import { errorToast, REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

import { SaveAsPortfolioModalBase } from "./SaveAsPortfolioModalBase";

export const SaveAsActionPortfolioModal = ({
  open,
  closeModal,
  node,
  root,
}: {
  open: boolean;
  closeModal: VoidFunction;
  root: DashboardRoot;
  node: NodeData | null;
}) => {
  const [loading, setLoading] = React.useState(true);
  const [{ settings, config, name }, setState] = React.useState<{
    settings: PortfolioSettingsV1;
    config: PortfolioConfigV1;
    name: string;
  }>({
    settings: {},
    config: { fundIds: [] },
    name: node?.name.replace(".json", "") ?? "",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);

  const getContent = useRefCallback(async () => {
    if (node) {
      setLoading(true);
      try {
        const res: PortfolioStoredConfigV1 =
          await REST_API.PORTFOLIO.DOWNLOAD_PORTFOLIO(node.path);
        setState({
          settings: res.settings,
          config: res.config,
          name: res.name,
        });
      } catch (err) {
        errorToast("Failed to load workbench");
        closeModal();
      } finally {
        setLoading(false);
      }
    }
  }, [root, node, closeModal]);

  React.useEffect(() => {
    if (open && !!node) getContent();
  }, [node?.path, root, open]);

  return (
    <SaveAsPortfolioModalBase
      loading={loading}
      open={open}
      closeModal={closeModal}
      name={name}
      root={root}
      filePath={node?.path ?? ""}
      fundIds={config.fundIds}
      settings={settings}
      config={config}
    />
  );
};
