// #region -- Imports & Styles
import {
  useDashboard,
  WidgetDefinitionCategory,
} from "@enfusion-ui/dashboards";
import {
  AlignRightRow,
  IconButton,
  Portal,
  TabTopActionBar,
} from "@enfusion-ui/web-components";
import {
  css,
  styled,
  useAuth,
  useTabs,
  useThisTab,
} from "@enfusion-ui/web-core";
import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faImageLandscape,
  faImagePortrait,
  faTimesCircle,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faBroom,
  faEdit,
  faEllipsisV,
  faFilePdf,
  faRedoAlt,
  faSave,
  faUndoAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu, { MenuItem } from "rc-menu";
import * as React from "react";

import { getNewDashboardTabContent } from "../core/utils";
import { MenuItemsIcon, MenuItemsTitle } from "../widget/WidgetContainer";

const ActionContainer = styled.div<{ background?: boolean }>`
  top: 0px;
  right: 0px;
  z-index: var(--widget-z);
  border: 1px solid transparent;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ background }) =>
    background
      ? css`
          background: var(--primary);
        `
      : ""}
  border-radius: 5px;
`;
// #endregion

export const DashboardViewActionBar: React.FC<{
  editMode: boolean;
  canEdit: boolean;
  hasUnsavedChanges: boolean;
  exitEditMode: VoidFunction;
  startEditMode: VoidFunction;
  openClearAllWidgetsModal: VoidFunction;
  openRevertModal: VoidFunction;
  openRevertDefaultModal: VoidFunction;
  openSaveModal: VoidFunction;
  openSaveAsModal: VoidFunction;
  openSaveAsDefaultModal: VoidFunction;
  openRefreshModal: VoidFunction;
  refreshView: VoidFunction;
  category: WidgetDefinitionCategory;
}> = ({
  editMode,
  canEdit,
  hasUnsavedChanges,
  exitEditMode,
  startEditMode,
  refreshView,
  openRevertModal,
  openRevertDefaultModal,
  openSaveModal,
  openSaveAsModal,
  openSaveAsDefaultModal,
  openRefreshModal,
  openClearAllWidgetsModal,
  category = "dashboard",
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = () => setMenuOpen(false);

  const isCustomOrder = category === "orderForm";
  const menuIconRef = React.useRef<HTMLDivElement | null>(null);
  const { openTab } = useTabs();
  const { config } = useThisTab();
  const { pdf, orientation, setOrientation, settings } = useDashboard();
  const { isAdmin } = useAuth();

  const nextOrientation = React.useMemo(
    () => (orientation === "portrait" ? "landscape" : "portrait"),
    [orientation, settings.orientation]
  );

  return (
    <TabTopActionBar>
      <AlignRightRow flex>
        {pdf && (
          <>
            <IconButton
              hoverable
              icon={
                orientation === "portrait"
                  ? (faImageLandscape as IconDefinition)
                  : (faImagePortrait as IconDefinition)
              }
              title={`Set to ${nextOrientation}`}
              onClick={() => {
                setOrientation(nextOrientation);
              }}
              size="sm"
            />
          </>
        )}
        {!pdf && editMode && (
          <>
            <IconButton
              hoverable
              icon={faSave}
              style={{
                color: hasUnsavedChanges ? "var(--danger)" : undefined,
              }}
              disabled={!canEdit || !hasUnsavedChanges}
              title="Save Changes"
              onClick={openSaveModal}
              size="sm"
              data-e2e-id="action-bar-save-btn"
            />
          </>
        )}
        {!pdf && editMode && (
          <>
            <IconButton
              hoverable
              icon={faTimesCircle}
              title="Exit Edit"
              onClick={exitEditMode}
              size="sm"
              data-e2e-id="action-bar-exit-edit-btn"
            />
          </>
        )}
        {!pdf && !editMode && canEdit && (
          <IconButton
            hoverable
            icon={faEdit}
            title="Edit"
            onClick={startEditMode}
            size="sm"
            iconStyle={{ transform: "scale(0.85)" }}
            data-e2e-id="action-bar-edit-btn"
          />
        )}
        {!pdf && (
          <>
            {(!isCustomOrder || editMode) && (
              <ActionContainer
                onClick={() => setMenuOpen(true)}
                background={menuOpen}
                ref={menuIconRef}
                id="more-options"
                data-e2e-id="action-bar-more-options-btn"
              >
                <FontAwesomeIcon icon={faEllipsisV} size="sm" />
              </ActionContainer>
            )}
            <Portal
              open={menuOpen}
              attachedRef={menuIconRef}
              align="right"
              onClickOutside={closeMenu}
            >
              <Menu selectedKeys={[]}>
                {editMode && (
                  <MenuItem
                    key="clear-all-widget"
                    onClick={openClearAllWidgetsModal}
                    data-e2e-id="menu-item-clear-dashboard"
                  >
                    <MenuItemsIcon icon={faBroom as IconProp}></MenuItemsIcon>
                    <MenuItemsTitle>Clear Dashboard</MenuItemsTitle>
                  </MenuItem>
                )}
                {editMode && isCustomOrder && isAdmin() && (
                  <MenuItem
                    key="save-as-default"
                    onClick={openSaveAsDefaultModal}
                    data-e2e-id="menu-item-save-as-default"
                  >
                    <MenuItemsIcon icon={faSave}></MenuItemsIcon>
                    <MenuItemsTitle>Save as default</MenuItemsTitle>
                  </MenuItem>
                )}

                {canEdit && hasUnsavedChanges && (
                  <MenuItem key="revert" onClick={openRevertModal}>
                    <MenuItemsIcon icon={faUndoAlt}></MenuItemsIcon>
                    <MenuItemsTitle>Revert Changes</MenuItemsTitle>
                  </MenuItem>
                )}
                {editMode && isCustomOrder && (
                  <MenuItem
                    key="revert-default"
                    onClick={openRevertDefaultModal}
                  >
                    <MenuItemsIcon icon={faUndoAlt}></MenuItemsIcon>
                    <MenuItemsTitle>Revert to Default</MenuItemsTitle>
                  </MenuItem>
                )}
                {!isCustomOrder && !editMode && hasUnsavedChanges && (
                  <>
                    <MenuItem key="save" onClick={openSaveModal}>
                      <MenuItemsIcon
                        icon={faSave}
                        style={{
                          color: hasUnsavedChanges
                            ? "var(--danger)"
                            : undefined,
                        }}
                      ></MenuItemsIcon>
                      <MenuItemsTitle>Save Changes</MenuItemsTitle>
                    </MenuItem>
                  </>
                )}
                {!isCustomOrder && canEdit && (
                  <MenuItem key="save-as" onClick={openSaveAsModal}>
                    <MenuItemsIcon icon={faSave}></MenuItemsIcon>
                    <MenuItemsTitle>Save As</MenuItemsTitle>
                  </MenuItem>
                )}
                {!isCustomOrder && (
                  <MenuItem
                    key="refresh"
                    onClick={() => {
                      if (hasUnsavedChanges) {
                        openRefreshModal();
                      } else {
                        refreshView();
                      }
                    }}
                  >
                    <MenuItemsIcon icon={faRedoAlt}></MenuItemsIcon>
                    <MenuItemsTitle>Refresh</MenuItemsTitle>
                  </MenuItem>
                )}
                {!isCustomOrder && process.env.REACT_APP_ENABLE_PDF_DASHBOARDS && (
                  <MenuItem
                    key="export-as-pdf"
                    onClick={() => {
                      closeMenu();
                      openTab(
                        getNewDashboardTabContent({
                          pdf: true,
                          name: "PDF DASHBOARD VIEW",
                          icon: faFilePdf,
                          root: config.root,
                          path: config.filePath,
                        })
                      );
                    }}
                  >
                    <MenuItemsIcon icon={faFilePdf}></MenuItemsIcon>
                    <MenuItemsTitle>Export as PDF</MenuItemsTitle>
                  </MenuItem>
                )}
              </Menu>
            </Portal>
          </>
        )}
      </AlignRightRow>
    </TabTopActionBar>
  );
};
