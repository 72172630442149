/* eslint-disable @typescript-eslint/no-explicit-any */

import { TextWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import {
  Checkbox,
  ColorPicker,
  FontSize,
  FontWeight,
  FormSectionAccordion,
  HorizontalAlignment,
  TextInput,
  VerticalAlignment,
} from "@enfusion-ui/web-components";
import * as React from "react";

import { SingleRowContainer } from "../../styles";

const TextEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();
  const [errors, setErrors] = React.useState<Record<string, string>>({});

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleJSONChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        changeConfigKeyToBeApplied(key, JSON.parse(e.target.value));
      } catch (err) {
        setErrors((errs) => ({ ...errs, [key]: "Not valid json" }));
      }
    };

  const handleBasicChange = (key: string) => (value: any) => {
    try {
      changeConfigKeyToBeApplied(key, value);
    } catch (err: any) {
      setErrors((errs) => ({ ...errs, [key]: err?.message }));
    }
  };

  const handleSelectChange = (key: string) => (value: any) => {
    try {
      changeConfigKeyToBeApplied(key, value.value);
    } catch (err: any) {
      setErrors((errs) => ({ ...errs, [key]: err?.message }));
    }
  };

  const {
    content,
    wrap = false,
    showBackground = false,
    justifyContent = "flex-start",
    alignItems = "flex-start",
    fontSize = "medium",
    fontWeight = "normal",
    color = "var(--text-normal)",
    style = {},
  } = editedConfig as TextWidgetConfig;

  return (
    <>
      <FormSectionAccordion
        title="Container Options"
        dataE2EId="text-widget-container-options-accordion"
      >
        <SingleRowContainer noMargin>
          <Checkbox
            checked={wrap}
            label="Wrap Text"
            onChange={handleBasicChange("wrap")}
            dataE2EId="text-edit-wrap-text-checkbox"
          />
          <Checkbox
            checked={showBackground}
            label="Background"
            onChange={handleBasicChange("showBackground")}
            dataE2EId="text-edit-background-checkbox"
          />
        </SingleRowContainer>
      </FormSectionAccordion>
      <FormSectionAccordion defaultOpen title="Content">
        <TextInput
          textarea
          rows={3}
          hideLabel
          value={content}
          name="content"
          onChange={handleTextChange("content")}
          data-e2e-id="text-edit-content-input"
          data-testid="text-edit-content-input"
        />
      </FormSectionAccordion>
      <FormSectionAccordion title="Text Styles">
        <SingleRowContainer count={3}>
          <FontSize
            name="fontSize"
            errors={errors}
            onChange={handleSelectChange("fontSize")}
            selectedValue={fontSize}
          />
          <FontWeight
            name="fontWeight"
            errors={errors}
            onChange={handleSelectChange("fontWeight")}
            selectedValue={fontWeight}
          />
          <ColorPicker
            label="Font Color"
            name="color"
            value={color}
            defaultValue="var(--text-normal)"
            onChange={handleBasicChange("color")}
            errors={errors}
            clearable
          />
        </SingleRowContainer>
        <SingleRowContainer noMargin>
          <HorizontalAlignment
            label="Horizontal Align"
            name="justifyContent"
            errors={errors}
            onChange={handleSelectChange("justifyContent")}
            selectedValue={justifyContent}
          />
          <VerticalAlignment
            label="Vertical Align"
            name="alignItems"
            errors={errors}
            onChange={handleSelectChange("alignItems")}
            selectedValue={alignItems}
          />
        </SingleRowContainer>
      </FormSectionAccordion>

      <div style={{ display: "none" }}>
        <TextInput
          textarea
          disabled
          label="Style"
          defaultValue={JSON.stringify(style, null, 2)}
          name="style"
          onBlur={handleJSONChange("style")}
          errors={errors}
        />
      </div>
    </>
  );
};

export default TextEditWidget;
