import {
  MarketEnvironmentSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { FormSectionAccordion, TextInput } from "@enfusion-ui/web-components";
import * as React from "react";

import MarketEnvironmentSelect from "../../../../../reports/components/ReportQueryComponents/MarketEnvironmentSelect";

const MarketEnvironmentSelectionEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange = (key: string) => (value: number | null) => {
    changeConfigKeyToBeApplied(key, value);
  };

  const { defaultValue = null, key = "Market Environment" } =
    editedConfig as MarketEnvironmentSelectionWidgetConfig;

  return (
    <FormSectionAccordion title="Market Environment Options">
      <TextInput
        label="Key"
        value={editedConfig?.key || key}
        name="key"
        onChange={handleTextChange("key")}
        data-e2e-id="market-env-key-input"
      />
      <MarketEnvironmentSelect
        value={editedConfig?.defaultValue || defaultValue}
        label="Default Market Environment"
        onChange={handleBasicChange("defaultValue")}
      />
    </FormSectionAccordion>
  );
};

export default MarketEnvironmentSelectionEditWidget;
