import { startCase } from "lodash";
export function createSelectOptions(values, labelModification = startCase) {
    if (Array.isArray(values))
        return values.map((i) => ({
            value: i,
            label: labelModification(i),
        }));
    return Object.entries(values).map(([l, v]) => ({
        label: labelModification(l),
        value: v,
    }));
}
export function getSelectOption(set, value, noDefaultReturn = false) {
    let option = set.find((i) => i.value === value);
    if (Array.isArray(value)) {
        option = set.filter((i) => value.includes(i.value));
    }
    if (Array.isArray(option) ? option.length > 0 : !!option)
        return option;
    if (value === null || typeof value === "undefined" || noDefaultReturn)
        return undefined;
    return (Array.isArray(value)
        ? value.map((v) => ({ label: `${v}`, value: v }))
        : { label: `${value}`, value: value });
}
