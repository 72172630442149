import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import DatePickerBase from "react-datepicker";
import { TextInput } from "../TextInput";
import { DEFAULT_PLACEHOLDER, DEFAULT_PORTAL_ID } from "./constants";
const CalendarContainer = styled.div `
  background-color: ${({ theme }) => theme.colors.backgroundColor1};
  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.backgroundColor1};
  }
  .react-datepicker__month .react-datepicker__week .react-datepicker__day,
  .react-datepicker__header .react-datepicker__current-month,
  .react-datepicker__header
    .react-datepicker__day-names
    .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.textNormal};
  }
  .react-datepicker__month
    .react-datepicker__week
    .react-datepicker__day:hover {
    background-color: ${({ theme }) => theme.colors.backgroundAccent};
  }
`;
export function DatePicker({ onChange, label, name, value = null, placeholder = DEFAULT_PLACEHOLDER, errors, portalId = DEFAULT_PORTAL_ID, minWidth, clearable, disabled = false, hideLabel = false, inline = false, dataTestId, ...rest }) {
    return (React.createElement(DatePickerBase, { customInput: React.createElement(TextInput, { name: name, label: label, hideLabel: hideLabel, inline: inline, errors: errors, clearable: clearable, onClearValue: () => onChange(null), minWidth: minWidth, disabled: disabled, "data-e2e-id": `date-picker-${typeof label === "string"
                ? label.toLowerCase().split(" ").join("-")
                : name?.toLowerCase()}`, "data-testid": dataTestId }), name: name, selected: value, onChange: (date) => onChange(date), placeholderText: placeholder, portalId: portalId, ...rest, disabled: disabled, calendarContainer: CalendarContainer }));
}
