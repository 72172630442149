import { useRefCallback } from "@enfusion-ui/hooks";
import { CashModeOptions } from "@enfusion-ui/types";
import { Select } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { DateRangeSymbolSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledLedgerHierarchySelect } from "../../../components/ReportQueryComponents/LedgerHierarchySelect";
import { getOptionValue } from "./GLCashActivitySettings";

const InputContainer = styled.div`
  min-width: 230px;
`;

const DateRangeContainer = styled.div`
  min-width: 350px;
`;

const GLCashActivityQuickFilter = () => {
  const { setValue } = useFormContext();
  const { accountsDescriptor } = useWatch({ name: ["accountsDescriptor"] });
  const defaultAccountDescriptor = React.useRef(accountsDescriptor);

  const handleCashModeChange = useRefCallback(
    (option: any) => {
      if (option.value === "SettleDate") {
        setValue("accountsDescriptor", {
          ...accountsDescriptor,
          accountingMethod: "CashBasis",
        });
      } else if (option.value === "TradeDate") {
        setValue("accountsDescriptor", {
          ...accountsDescriptor,
          accountingMethod: "AccrualBasis",
        });
      }
    },
    [setValue]
  );

  return (
    <>
      <InputContainer>
        <ControlledLedgerHierarchySelect
          label="General Ledger(s)"
          name="accountsDescriptor"
          defaultValue={defaultAccountDescriptor.current}
        />
      </InputContainer>
      <DateRangeContainer>
        <DateRangeSymbolSelection name="period" label="Period" />
      </DateRangeContainer>
      <InputContainer>
        <Select
          minWidth={150}
          options={CashModeOptions}
          label="Cash Mode"
          value={
            getOptionValue(
              CashModeOptions,
              accountsDescriptor?.accountingMethod
            ) ?? null
          }
          onChange={handleCashModeChange}
          clearable
        />
      </InputContainer>
    </>
  );
};

export default GLCashActivityQuickFilter;
