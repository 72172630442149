import { useDashboard, useWidget } from "@enfusion-ui/dashboards";
import { ChannelDataIdentifier, ChannelDataType } from "@enfusion-ui/types";
import { createTestId } from "@enfusion-ui/utils";
import { InputLabel, Select } from "@enfusion-ui/web-components";
import * as React from "react";

export type ChannelDataPickerProps = {
  type?: ChannelDataType;
  value?: ChannelDataIdentifier | null;
  onChange: (value: ChannelDataIdentifier | null) => void;
  name?: string;
  label: string;
  disabled?: boolean;
  inputId?: string;
};

function getOption(
  set: Array<{ label: string; value: ChannelDataIdentifier }>,
  value?: ChannelDataIdentifier | null
) {
  if (!value) return undefined;
  const option = set.find(
    (i) => i.value.key === value.key && i.value.widgetId === value.widgetId
  );
  if (option) return option;
  return undefined;
}

const ChannelDataPicker: React.FC<ChannelDataPickerProps> = ({
  type,
  value,
  onChange,
  name,
  label,
  disabled,
  inputId = "channel-data-picker-selection-id",
}) => {
  const { channelKeys } = useDashboard();
  const { id } = useWidget();
  const options = React.useMemo(() => {
    let channelOptions = channelKeys.filter((key) => key.widgetId !== id);
    if (Number(type) >= 0) {
      channelOptions = channelOptions?.filter((i) => i.type === type);
    }
    return channelOptions.map((i) => ({
      ...i,
      label: i.name,
      value: { key: i.key, widgetId: i.widgetId },
    }));
  }, [channelKeys]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (value: any) => {
    onChange(value?.value);
  };

  return (
    <div>
      {!label && <InputLabel htmlFor={name}>‎</InputLabel>}
      <Select
        name={name}
        label={label}
        onChange={handleChange}
        value={getOption(options, value)}
        options={options}
        clearable
        disabled={disabled}
        inputId={inputId}
        dataTestId={createTestId("testid", inputId)}
      />
    </div>
  );
};

export default ChannelDataPicker;
