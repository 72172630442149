import {
  InputWrapper,
  SingleRowContainer,
} from "@app-views/dashboards/widgets/styles";
import { OrderDetailsType, useWidget } from "@enfusion-ui/dashboards";
import { SelectOptionsType } from "@enfusion-ui/types";
import {
  Checkbox,
  FormSectionAccordion,
  Select,
} from "@enfusion-ui/web-components";

const inputOptions = [
  { label: "Quantity", value: "quantity" },
  { label: "Notional", value: "notional" },
];

export const OrderDetailsEditWidget = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();
  const { input = "quantity", showCalculationInfoText = true } =
    editedConfig as OrderDetailsType;

  const handleInputChange =
    (key: string) => (input: SelectOptionsType<string> | null) => {
      changeConfigKeyToBeApplied(key, input?.value);
    };

  const handleChange = (key: string) => (val: boolean) => {
    changeConfigKeyToBeApplied(key, val);
  };

  return (
    <FormSectionAccordion title="Order Details Options">
      <SingleRowContainer noMargin>
        <Select
          label="Input Type"
          value={inputOptions.find(({ value: val }) => input === val)}
          options={inputOptions}
          onChange={handleInputChange("input")}
          inputId="input-selection-id"
        />
        <InputWrapper>
          <Checkbox
            checked={showCalculationInfoText}
            onChange={handleChange("showCalculationInfoText")}
            label="Show Calculation Text"
            topLabelPlaceholder
          />
        </InputWrapper>
      </SingleRowContainer>
    </FormSectionAccordion>
  );
};
