import { useOEMSOrderForm } from "@enfusion-ui/core";
import { SecurityInfoPanelFieldOptions } from "@enfusion-ui/dashboards";
import { ButtonGroupSelect, VisibleIf } from "@enfusion-ui/web-components";
import { styled, useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";

import MarketDataDisplay from "../../components/MarketDataDisplay";
import FutureSpreadPanel from "../../OrderTicket/panels/FutureSpreadPanel";
import SecurityInfoPanel from "./SecurityInfoPanel";

const tabs = [
  { value: "market", label: "Market Info" },
  { value: "security", label: "Security Info" },
];

const MobileWrapper = styled.div<{ margin: boolean }>`
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  margin: ${({ margin = true }) => (margin ? "0 var(--spacing)" : "0")};
  height: 100%;
`;

const GridWrapper = styled.div`
  background-color: var(--background-color-0);
  border: 1px solid var(--primary);
  border-radius: var(--radius-l);
  height: 100%;
`;

const InlineGrids = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const GridHeader = styled.div`
  background-color: var(--background-color-2);
  color: var(--primary);
  text-align: center;
  padding: 0.5rem 0;
`;

const DesktopGridContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerGridWrapper = styled.div`
  padding: 1rem 2rem;
`;

const fields: Array<SecurityInfoPanelFieldOptions> = [
  "ticker",
  "isin",
  "sedol",
  "ric",
  "exchangeShortname",
  "currencyCode",
];

const marketInfoFields = ["Bid", "Ask", "Last", "Change", "Change %", "Volume"];

const MobileLayout: React.FC<{ margin: boolean }> = ({ margin }) => {
  const [activeTab, setActiveTab] = React.useState<string>(tabs[0].value);
  const { isFutureSpreadOrder } = useOEMSOrderForm();
  return (
    <MobileWrapper margin={margin}>
      <ButtonGroupSelect
        selectedValue={activeTab}
        onSelect={(value) => setActiveTab(value as string)}
        options={tabs}
        disableDeselect
      />
      <GridWrapper>
        <InnerGridWrapper>
          <VisibleIf visible={activeTab === tabs[0].value}>
            {isFutureSpreadOrder ? (
              <FutureSpreadPanel />
            ) : (
              <MarketDataDisplay
                fields={marketInfoFields}
                orientation="vertical"
              />
            )}
          </VisibleIf>
          <VisibleIf visible={activeTab !== tabs[0].value}>
            <SecurityInfoPanel fields={fields} viewMode="vertical" />
          </VisibleIf>
        </InnerGridWrapper>
      </GridWrapper>
    </MobileWrapper>
  );
};

const DesktopLayout: React.FC = () => {
  const { isFutureSpreadOrder } = useOEMSOrderForm();
  return (
    <GridWrapper>
      <InlineGrids>
        <DesktopGridContent>
          <GridHeader style={{ borderRadius: "var(--radius-l) 0 0 0" }}>
            {tabs[0].label}
          </GridHeader>
          <InnerGridWrapper>
            {isFutureSpreadOrder ? (
              <FutureSpreadPanel />
            ) : (
              <MarketDataDisplay
                fields={marketInfoFields}
                orientation="vertical"
              />
            )}
          </InnerGridWrapper>
        </DesktopGridContent>
        <DesktopGridContent>
          <GridHeader style={{ borderRadius: "0 var(--radius-l) 0 0" }}>
            {tabs[1].label}
          </GridHeader>
          <InnerGridWrapper>
            <SecurityInfoPanel fields={fields} viewMode="vertical" />
          </InnerGridWrapper>
        </DesktopGridContent>
      </InlineGrids>
    </GridWrapper>
  );
};

const MarketInfoPanel: React.FC<{ margin: boolean }> = ({ margin }) => {
  const isMobile = useIsContentMobile();
  return isMobile ? <MobileLayout margin={margin} /> : <DesktopLayout />;
};

export default MarketInfoPanel;
