import { WidgetComponentsDefinition } from "../../../types";
import ComplianceStateSelectionEditWidget from "./ComplianceStatesSelectionEditWidget";
import ComplianceStateSelectionWidget from "./ComplianceStatesSelectionWidget";
import PdfComplianceStateSelectionWidget from "./PdfComplianceStateSelectionWidget";

export const complianceStateSelection: WidgetComponentsDefinition = {
  renderComponent: ComplianceStateSelectionWidget,
  editComponent: ComplianceStateSelectionEditWidget,
  renderPdfComponent: PdfComplianceStateSelectionWidget,
};
