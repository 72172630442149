import AccountSelect, {
  AccountSelectProps,
} from "@app-components/inputs/account/AccountSelect";
import { useAppTutorial } from "@app-components/Tour/context";
import { TutorialType } from "@app-components/Tour/utils";
import { useRefCallback } from "@enfusion-ui/hooks";
import { NodeData } from "@enfusion-ui/types";
import { ControlledInputBase } from "@enfusion-ui/web-components";
import { isEqual } from "lodash";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";

export const InputContainer = styled.div`
  min-width: 250px;
`;

export type FundInfo = {
  fundIds?: number[];
  accountIds?: number[];
};

function mergeIds(value?: FundInfo) {
  if (value) {
    return [
      ...(value.accountIds?.map((i) => String(i)) ?? []),
      ...(value.fundIds?.map((i) => String(i)) ?? []),
    ];
  }
}

const ControlledAccountSelect: React.FC<
  Omit<AccountSelectProps, "onChange"> & {
    defaultValue?: FundInfo;
    onChange?: (value: FundInfo) => void;
    performDirtyCheck?: boolean;
    containerStyle?: React.CSSProperties;
  }
> = ({
  name = "accountSelection",
  defaultValue,
  label = "Default Accounts",
  onChange,
  performDirtyCheck,
  containerStyle = {},
  ...prop
}) => {
  const { setValue } = useFormContext();
  const { tutorialType, setMeta, step } = useAppTutorial();
  const watchValue = useWatch<any>({ name });

  const defaultSelectedIds = React.useRef(mergeIds(defaultValue) ?? []);

  const isSelectFundStepPwbTour =
    tutorialType === TutorialType.Portfolio && step?.name === "select-fund";

  React.useEffect(() => {
    if (isSelectFundStepPwbTour) setMeta({ disableButton: true });
  }, [isSelectFundStepPwbTour]);

  const handleOnChange = useRefCallback(
    (nodeData: NodeData[]) => {
      const holdId: { accountIds: number[]; fundIds: number[] } =
        nodeData?.reduce<{ accountIds: number[]; fundIds: number[] }>(
          (res, data) => {
            switch (data.level) {
              case "Custodian":
                if (data.nodes?.length) {
                  res["accountIds"] = [
                    ...res["accountIds"],
                    ...data.nodes.map((x) => Number(x.id)),
                  ];
                }
                break;
              case "Account":
                res["accountIds"].push(Number(data.accountId));
                break;
              case "Fund":
                res["fundIds"].push(Number(data.fundId));
                break;
              case "FundContainer":
                if (data.nodes?.length) {
                  res["fundIds"] = [
                    ...res["fundIds"],
                    ...data.nodes.map((x) => Number(x.fundId)),
                  ];
                }
                break;
            }
            return res;
          },
          { accountIds: [], fundIds: [] }
        );

      setValue(
        name,
        {
          ...watchValue,
          accountIds: [...new Set(holdId.accountIds)],
          fundIds: [...new Set(holdId.fundIds)],
        },
        {
          shouldDirty: !performDirtyCheck
            ? true
            : !isEqual(mergeIds(watchValue)?.sort(), mergeIds(holdId)?.sort()),
        }
      );
      onChange?.(holdId);

      if (isSelectFundStepPwbTour)
        setMeta({ disableButton: !!holdId.fundIds.length });
    },
    [onChange, watchValue, isSelectFundStepPwbTour]
  );

  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, value, ...rest }) => {
        const selectedIds = mergeIds(value);
        return (
          <InputContainer style={containerStyle}>
            <AccountSelect
              {...rest}
              {...prop}
              values={selectedIds ?? defaultSelectedIds.current}
              onChange={handleOnChange}
              label={label}
              checkSelections
            />
          </InputContainer>
        );
      }}
    />
  );
};

export default ControlledAccountSelect;
