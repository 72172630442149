import { useDashboard, useWidget } from "@enfusion-ui/dashboards";
import { useMouseOverSimple, useRefCallback } from "@enfusion-ui/hooks";
import { testId } from "@enfusion-ui/utils";
import {
  Button,
  ConfirmationModal,
  Modal,
  Portal,
} from "@enfusion-ui/web-components";
import { css, styled } from "@enfusion-ui/web-core";
import { faCog, faEllipsisH, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty, isEqual } from "lodash";
import Menu, { MenuItem } from "rc-menu";
import * as React from "react";
import { ReactNode } from "react";

const ActionContainer = styled.div<{ background?: boolean }>`
  top: 0px;
  right: 0px;
  position: absolute;
  z-index: calc(var(--widget-z));
  border: 1px solid transparent;
  margin: 5px;
  width: 31px;
  height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  ${({ background }) =>
    background
      ? css`
          background: var(--primary);
        `
      : ""}
`;

const FormContainer = styled.div`
  > :not(:last-child) {
    margin-bottom: var(--spacing-l);
  }
  > :last-child {
    margin-bottom: var(--spacing-xl);
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-xl);
`;

const StyledWidgetContainer = styled.div<{ index: number }>`
  width: 100%;
  height: 100%;
  ${({ index }) =>
    css`
      z-index: ${index};
    `};

  position: relative;
  overflow: hidden;

  padding: 4px 2px;
`;

const StyledWidgetInnerContainer = styled.div<{
  editMode: boolean;
  bordered: boolean;
}>`
  width: 100%;
  height: 100%;

  position: relative;
  border: ${({ editMode }) =>
    editMode ? "1px dashed var(--primary)" : undefined};
  border-radius: var(--radius);
`;
const dataTestId = (label: string) => testId("widget", label);

export const MenuItemsTitle = styled.span`
  font-family: Lato;
  font-size: 14px;
  line-height: 14px;
  color: var(--text-normal);
  margin: 0px var(--spacing-l);
`;

export const MenuItemsIcon = styled(FontAwesomeIcon)`
  width: 11px !important;
`;

const WidgetContainer: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { widgetDefinitions } = useDashboard();
  const {
    id,
    gridId,
    widgetDef,
    index,
    type: widgetType,
    editing,
    setEditing,
    widgetActions,
    revertUnsavedConfigChanges,
    applyUnsavedChanges,
    unsavedConfig,
    config,
    applyUnsavedProperties,
    revertUnsavedProperties,
    unsavedProperties,
    properties,
    revertConfigAndProperties,
  } = useWidget();
  const menuIconRef = React.useRef<HTMLDivElement | null>(null);

  const { removeWidget, editMode, oldConfig } = useDashboard();

  const [removeConfirmOpen, setRemoveConfirm] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const [cancelChangesOpen, setCancelChangesOpen] = React.useState(false);
  const [revertConfigOpen, setRevertConfigOpen] = React.useState(false);

  const { onMouseEnter, onMouseLeave, over } = useMouseOverSimple(300);

  React.useEffect(() => {
    if (!over) setMenuOpen(false);
  }, [over]);

  const resetConfigurationChanges = () => {
    revertUnsavedConfigChanges();
    revertUnsavedProperties();
    toggleEditing();
  };
  const toggleEditing = () => setEditing((state) => !state);

  const openRemoveConfirm = () => setRemoveConfirm(true);
  const closeRemoveConfirm = () => setRemoveConfirm(false);
  const handleRemoveWidget = () => {
    closeRemoveConfirm();
    removeWidget(gridId, id);
  };

  const menuItems = React.useMemo(() => {
    const editModeMenuItems = editMode
      ? [
          <MenuItem
            key="trash"
            onClick={openRemoveConfirm}
            data-e2e-id="widget-ellipsis-remove"
          >
            <MenuItemsIcon icon={faTrash}></MenuItemsIcon>
            <MenuItemsTitle>Remove</MenuItemsTitle>
          </MenuItem>,
          <MenuItem
            key="config"
            onClick={toggleEditing}
            data-e2e-id="widget-ellipsis-configure"
            data-testid={dataTestId("ellipsis-configure")}
          >
            <MenuItemsIcon icon={faCog}></MenuItemsIcon>
            <MenuItemsTitle>Configure</MenuItemsTitle>
          </MenuItem>,
        ]
      : [];
    return widgetActions?.all
      ? widgetActions?.all?.map((action) => action).concat(editModeMenuItems)
      : editModeMenuItems;
  }, [widgetActions, editMode]);

  const openMenu = useRefCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setMenuOpen(true);
  }, []);
  const closeMenu = () => setMenuOpen(false);

  const getWidgetConfig = React.useMemo(() => {
    return oldConfig[gridId].widgets.find((eachWidget) => id === eachWidget.id);
  }, [oldConfig, gridId, id]);

  const noUnsavedChanges = isEmpty(unsavedConfig) && isEmpty(unsavedProperties);

  const widgetConfigOrProperties = {
    ...getWidgetConfig?.config,
    ...getWidgetConfig?.properties,
  };

  const noPreservedConfig =
    isEmpty(widgetConfigOrProperties) ||
    isEqual({ ...config, ...properties }, widgetConfigOrProperties);

  // Actions for cancel modal
  const openCancelConfirm = () => {
    // Show confirmation dialog only when there are unsaved changes
    if (noUnsavedChanges) {
      resetConfigurationChanges();
    } else {
      setCancelChangesOpen(true);
    }
  };

  const closeCancelConfirm = () => setCancelChangesOpen(false);
  const handleCancelChanges = () => {
    closeCancelConfirm();
    resetConfigurationChanges();
  };

  //#region Actions for Revert modal
  const openRevertConfirm = () => {
    setRevertConfigOpen(true);
  };
  const closeRevertConfirm = () => setRevertConfigOpen(false);
  const handleRevertChanges = () => {
    revertConfigAndProperties();
    closeRevertConfirm();
    toggleEditing();
  };
  //#endregion Actions for Revert modal

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const EditComponent = widgetDefinitions[widgetType]?.editComponent;
  const widgetName = widgetDef?.name.split(" ").join("-").toLowerCase();

  return (
    <StyledWidgetContainer
      index={index}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-e2e-id={`widget-${widgetName}`}
      data-testid={`widget-${widgetName}`}
      ref={containerRef}
    >
      <StyledWidgetInnerContainer
        editMode={editMode}
        bordered={widgetDef?.type === "display"}
      >
        {over && menuItems.length > 0 && (
          <ActionContainer
            onMouseDown={openMenu}
            background={menuOpen}
            ref={menuIconRef}
            data-e2e-id={`${widgetName}-config-button`}
            data-testid={`${widgetName}-config-button`}
          >
            <FontAwesomeIcon icon={faEllipsisH} size="xs" />
          </ActionContainer>
        )}

        <Portal
          open={menuOpen}
          attachedRef={menuIconRef}
          align="right"
          onClickOutside={closeMenu}
        >
          <Menu
            onMouseEnter={onMouseEnter}
            className="rc-menu-dashboard"
            selectedKeys={[]}
            data-e2e-id="config-and-remove-buttons"
            data-testid={dataTestId("config-context-menu")}
          >
            {menuItems}
          </Menu>
        </Portal>
        {children}
      </StyledWidgetInnerContainer>
      <Modal
        dataE2EId="widget-settings-modal"
        title={`Edit ${widgetDef?.name} Widget`}
        isOpen={editing}
        onClose={openCancelConfirm}
        content={
          <FormContainer
            style={{
              width: "75vw",
            }}
          >
            {EditComponent && <EditComponent />}
          </FormContainer>
        }
        footerContent={
          <FooterContainer>
            <Button
              data-e2e-id="edit-widget-apply-btn-testId"
              data-testid="edit-widget-apply-btn-testId"
              disabled={noUnsavedChanges}
              onClick={() => {
                applyUnsavedChanges();
                applyUnsavedProperties();
                setEditing(false);
              }}
              primary
            >
              Apply
            </Button>
            <Button
              disabled={noPreservedConfig}
              theme="secondary"
              onClick={openRevertConfirm}
            >
              Revert
            </Button>
            <Button theme="secondary" onClick={openCancelConfirm}>
              Cancel
            </Button>
          </FooterContainer>
        }
      />

      <ConfirmationModal
        title="Remove Widget?"
        submitActionTheme="danger"
        open={removeConfirmOpen}
        onCancel={closeRemoveConfirm}
        onSubmit={handleRemoveWidget}
        dataE2EId="remove-widget-modal"
      >
        Do you want to remove this widget?
      </ConfirmationModal>

      <ConfirmationModal
        title="Cancel Changes?"
        submitActionTheme="danger"
        open={cancelChangesOpen}
        onCancel={closeCancelConfirm}
        onSubmit={handleCancelChanges}
      >
        All the unapplied changes will be lost. Do you want to cancel the
        changes?
      </ConfirmationModal>

      <ConfirmationModal
        title="Revert Configuration?"
        submitActionTheme="danger"
        open={revertConfigOpen}
        onCancel={closeRevertConfirm}
        onSubmit={handleRevertChanges}
      >
        Do you want to revert to the last saved state?
      </ConfirmationModal>
    </StyledWidgetContainer>
  );
};

export default WidgetContainer;
