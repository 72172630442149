import {
  DisplayExecutionOrderStatus,
  ExecutionOrderTimeInForce,
  ExecutionOrderType,
} from "@enfusion-ui/types";
import {
  ControlledSelect,
  FormElementHeader,
} from "@enfusion-ui/web-components";
import { css, styled, useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";

import CounterpartySelection from "./CounterpartySelection";

type EnumOptionsType<T> = Array<{ value: T; label: string }>;

type Options = {
  orderStatus: EnumOptionsType<DisplayExecutionOrderStatus>;
  timeInForce: EnumOptionsType<ExecutionOrderTimeInForce>;
  orderType: EnumOptionsType<ExecutionOrderType>;
};

const InputsWrapper = styled.div`
  display: flex;
  gap: var(--spacing-xl);
  flex-wrap: wrap;
`;

const VoiceDetailEntryContainer = styled.div<{ show?: boolean }>`
  ${({ show }) =>
    show
      ? css`
          height: 100%;
        `
      : css`
          height: 0px;
          max-height: 0px;
          overflow: hidden;
        `}
  flex-direction: column;
`;

export const OPTIONS: Options = {
  orderType: [{ value: "Limit", label: "Limit" }],
  orderStatus: [
    { value: "Acknowledged", label: "Acknowledged" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "DoneForDay", label: "Done for Day" },
    { value: "Filled", label: "Filled" },
    { value: "NewlyCreated", label: "New" },
    { value: "PartiallyFilled", label: "Partially Filled" },
    { value: "Rejected", label: "Rejected" },
  ],
  timeInForce: [
    { value: "Day", label: "Day" },
    { value: "GTC", label: "GTC" },
  ],
};

const VoiceDetailInput = styled.div`
  display: flex;
  flex: 1;
`;

export type CounterpartyOptions = {
  label: string;
  value: number;
};

export const OrderStatusSelect: React.FC<{ disabled?: boolean }> = ({
  disabled,
}) => (
  <ControlledSelect
    name="orderStatus"
    label="Order Status"
    options={OPTIONS.orderStatus}
    minWidth={150}
    clearable={false}
    disabled={disabled}
    inputId="order-status-selection-id"
  />
);

const VoiceDetailEntry: React.FC<{ disabled?: boolean; show?: boolean }> = ({
  disabled,
  show,
}) => {
  const isMobile = useIsContentMobile();
  return (
    <VoiceDetailEntryContainer show={show}>
      <FormElementHeader style={{ margin: isMobile ? "0" : "1.5rem 0 0" }}>
        Voice Execution
      </FormElementHeader>
      <InputsWrapper>
        <VoiceDetailInput>
          <CounterpartySelection disabled={disabled} />
        </VoiceDetailInput>
        <VoiceDetailInput>
          <OrderStatusSelect disabled={disabled} />
        </VoiceDetailInput>
      </InputsWrapper>
    </VoiceDetailEntryContainer>
  );
};

export default VoiceDetailEntry;
