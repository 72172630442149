import { GridData, GridView } from "@app-components/display/GridView";
import { getDisplayValue } from "@app-views/oems/utils/getDisplayValue";
import { ExecutionOrderSide, FXType } from "@enfusion-ui/types";
import { getUTCDateString } from "@enfusion-ui/utils";
import { FormPanel, PanelRow } from "@enfusion-ui/web-components";

import {
  LabeledValue,
  NumericLabeledValue,
  VarColorText,
} from "../styledComponents";
import { sideDisplaySettings } from "@enfusion-ui/core";
import { OrderSummaryGrid, OrderSummaryPanelGridWrapper } from "./styles";

const FXOrderSummaryPanelGrid: React.FC<{
  leftGridData: GridData;
  rightGridData: GridData;
  fieldNameWidth?: number;
  fieldValueWidth?: number;
}> = ({ leftGridData, rightGridData, fieldNameWidth, fieldValueWidth }) => {
  return (
    <OrderSummaryPanelGridWrapper>
      <GridView
        gridData={leftGridData}
        fieldNameWidth={fieldNameWidth}
        fieldValueWidth={fieldValueWidth}
      />
      <GridView
        gridData={rightGridData}
        fieldNameWidth={fieldNameWidth}
        fieldValueWidth={fieldValueWidth}
      />
    </OrderSummaryPanelGridWrapper>
  );
};

const FXOrderSummaryPanelGridMobile: React.FC<{
  gridData: GridData;
  fieldNameWidth?: number;
  fieldValueWidth?: number;
}> = ({ gridData, fieldNameWidth, fieldValueWidth }) => {
  return (
    <OrderSummaryPanelGridWrapper>
      <GridView
        gridData={gridData}
        fieldNameWidth={fieldNameWidth}
        fieldValueWidth={fieldValueWidth}
      />
    </OrderSummaryPanelGridWrapper>
  );
};

export type FXOrderSummaryModalCoreProps = {
  orderSide: ExecutionOrderSide | null;
  quantity: string;
  currencyPair: string;
  fixingDate: string;
  farFixingDate: string;
  settleDate: string;
  farSettleDate: string;
  fxType: FXType;
  isMobileDevice: boolean;
};

export const FXOrderSummaryPanel: React.FC<
  FXOrderSummaryModalCoreProps & { viewMode: string }
> = ({
  orderSide,
  quantity,
  currencyPair,
  fixingDate,
  farFixingDate,
  settleDate,
  farSettleDate,
  viewMode = "row",
  isMobileDevice,
}) => {
  const settings = sideDisplaySettings(false);
  const values = (orderSide && settings[orderSide]) || {
    name: null,
    color: null,
  };

  const { name, color } = values;

  const fixingDateDisplay = fixingDate
    ? getUTCDateString(new Date(fixingDate))
    : "-";

  const farFixingDateDisplay = farFixingDate
    ? getUTCDateString(new Date(farFixingDate))
    : "-";

  const forwardDateDisplay = settleDate
    ? getUTCDateString(new Date(settleDate))
    : "-";

  const farForwardDateDisplay = farSettleDate
    ? getUTCDateString(new Date(farSettleDate))
    : "-";

  if (viewMode === "grid") {
    const gridData = [
      {
        fieldName: "Side",
        fieldValue: name ?? "",
        showColored: {
          onValueChanged: (value: string) => value ?? null,
          data: color,
        },
      },
      { fieldName: "Quantity", fieldValue: getDisplayValue(quantity, 0) },
      {
        fieldName: "Currency Pair",
        fieldValue: currencyPair ?? "-",
      },
      {
        fieldName: "Fixing Date",
        fieldValue: fixingDateDisplay,
      },
      {
        fieldName: "Forward Date",
        fieldValue: forwardDateDisplay,
      },
      {
        fieldName: "Far Fixing Date",
        fieldValue: farFixingDateDisplay,
      },
      {
        fieldName: "Far Forward Date",
        fieldValue: farForwardDateDisplay,
      },
    ];

    if (isMobileDevice) {
      return (
        <FXOrderSummaryPanelGridMobile
          gridData={gridData}
          fieldValueWidth={8}
          fieldNameWidth={8}
        />
      );
    }

    const leftGridData = gridData.slice(0, 4);
    const rightGridData = gridData.slice(4);

    return (
      <FXOrderSummaryPanelGrid
        leftGridData={leftGridData}
        rightGridData={rightGridData}
        fieldValueWidth={8}
        fieldNameWidth={8}
      />
    );
  }

  return (
    <FormPanel title="Order Summary">
      <PanelRow grow>
        <OrderSummaryGrid>
          <LabeledValue label="Side" title={name ?? "-"}>
            <VarColorText colorValue={color}>{name ?? "-"}</VarColorText>
          </LabeledValue>
          <NumericLabeledValue
            label="Quantity"
            title={getDisplayValue(quantity, 0)}
          >
            {getDisplayValue(quantity, 0)}
          </NumericLabeledValue>
          <LabeledValue label="Currency Pair" title={currencyPair ?? "-"}>
            {currencyPair ?? "-"}
          </LabeledValue>
          <LabeledValue label="Fixing Date" title={fixingDateDisplay}>
            {fixingDateDisplay}
          </LabeledValue>
          <LabeledValue label="Forward Date" title={forwardDateDisplay}>
            {forwardDateDisplay}
          </LabeledValue>
          <LabeledValue label="Far Fixing Date" title={farFixingDateDisplay}>
            {farFixingDateDisplay}
          </LabeledValue>
          <LabeledValue label="Far Forward Date" title={farForwardDateDisplay}>
            {farForwardDateDisplay}
          </LabeledValue>
        </OrderSummaryGrid>
      </PanelRow>
    </FormPanel>
  );
};
