import { useAccounts } from "@app-context/accounts/context";
import { EventKeyModifiers } from "@enfusion-ui/core";
import { NodeData } from "@enfusion-ui/types";
import { getFileIcon, getFileName } from "@enfusion-ui/utils";
import {
  CenterContent,
  ErrorAlert,
  FileExplorer,
  getFileTooltipText,
  Spinner,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { TutorialType } from "../../Tour/utils";

const Explorer = styled(FileExplorer)`
  flex: 1;
  overflow-x: hidden;
`;

const FilterDef = {
  excludeInactive: (node: NodeData) => !node.inactive,
  excludeExternal: (node: NodeData) => !node.external,
  //By default skipping levels beyond funds
  excludeLevel: (node: NodeData, skipLevel = 2) =>
    skipLevel >= (node.depth ?? 0),
};

const applyFilters = (
  node: NodeData,
  filterExternal?: boolean,
  filterInactive?: boolean,
  filterAccounts?: boolean
) => {
  if (!filterExternal && !filterInactive && !filterAccounts) {
    return true;
  }

  let applyFilter = true;
  if (filterExternal) {
    applyFilter = applyFilter && FilterDef.excludeExternal(node);
  }

  if (filterInactive) {
    applyFilter = applyFilter && FilterDef.excludeInactive(node);
  }

  if (filterAccounts) {
    applyFilter = applyFilter && FilterDef.excludeLevel(node);
  }

  return applyFilter;
};

const filterNodesReduce =
  (
    searchTerm: string,
    filterEmpty: boolean,
    showId?: boolean,
    showShortName?: boolean,
    filterExternal?: boolean,
    filterInactive?: boolean,
    filterAccounts?: boolean
  ) =>
  (res: Array<NodeData>, node: NodeData) => {
    if ((!node.nodes || node.nodes.length === 0) && !node.file && filterEmpty) {
      return res;
    }
    const { name, shortName, accountId } = node;

    const formattedName = `${name}${
      showShortName && shortName ? ` - ${shortName}` : ""
    }${showId && accountId ? ` - ${accountId}` : ""}`;

    if (!!node.nodes?.length) {
      const subNodes = node.nodes.reduce(
        filterNodesReduce(
          searchTerm,
          filterEmpty,
          showId,
          showShortName,
          filterExternal,
          filterInactive,
          filterAccounts
        ),
        []
      );
      if (
        (filterAccounts && FilterDef.excludeLevel(node)) ||
        !filterEmpty ||
        subNodes.length > 0
      ) {
        res.push({
          ...node,
          name: formattedName,
          nodes: subNodes,
          nodeCount: subNodes.length,
          file: !!(filterAccounts && node.level === "Fund") || node.file,
        });
      }
    } else if (
      (searchTerm.length === 0 ||
        formattedName.toLocaleLowerCase().includes(searchTerm)) &&
      applyFilters(node, filterExternal, filterInactive, filterAccounts)
    ) {
      res.push({ ...node, name: formattedName });
    }

    return res;
  };

export type AccountTreeProps = {
  onClick?: (node: NodeData, modifiers: EventKeyModifiers) => void;
  searchTerm?: string;
  values?: Array<string>;
  onSelectionChange?: (nodes: Array<NodeData>) => void;
  emptyText?: string;
  multiSelect?: boolean;
  checkSelections?: boolean;
  tutorial?: TutorialType | null;
  filterEmpty: boolean;
  showId?: boolean;
  showShortName?: boolean;
  filterExternal?: boolean;
  filterInactive?: boolean;
  defaultFoldersOpen?: boolean;
  filterAccounts?: boolean;
};

const AccountTree: React.FC<AccountTreeProps> = React.memo(
  function AccountTree({
    onClick,
    searchTerm = "",
    values,
    onSelectionChange,
    emptyText = "No options found",
    multiSelect = false,
    checkSelections = false,
    filterEmpty,
    tutorial,
    showId,
    showShortName,
    filterExternal,
    filterInactive,
    defaultFoldersOpen,
    filterAccounts,
  }: AccountTreeProps) {
    const { loading, error, nodes } = useAccounts();

    const filteredNodes = React.useMemo(() => {
      if (!nodes) return [] as NodeData[];
      if (
        searchTerm.trim().length > 0 ||
        filterEmpty ||
        showShortName ||
        showId
      ) {
        return nodes.reduce(
          filterNodesReduce(
            searchTerm.toLocaleLowerCase(),
            filterEmpty,
            showId,
            showShortName,
            filterExternal,
            filterInactive,
            filterAccounts
          ),
          []
        );
      }
      return nodes;
    }, [
      searchTerm,
      filterEmpty,
      nodes,
      showId,
      showShortName,
      filterExternal,
      filterInactive,
      filterAccounts,
    ]);

    if (error) {
      return (
        <CenterContent fillHeight={false}>
          <ErrorAlert error={error} />
        </CenterContent>
      );
    }

    if (loading || nodes === null) {
      return (
        <CenterContent fillHeight={false}>
          <Spinner />
        </CenterContent>
      );
    }

    if (
      nodes.length === 0 ||
      (searchTerm.length && filteredNodes.length === 0)
    ) {
      return (
        <CenterContent style={{ minHeight: 30 }}>{emptyText}</CenterContent>
      );
    }

    return (
      <Explorer
        values={values}
        filteredNodes={filteredNodes}
        nodes={nodes}
        getFileIcon={getFileIcon}
        getFileName={getFileName}
        getTooltipText={getFileTooltipText}
        onEntryClick={onClick}
        multiSelect={multiSelect}
        checkSelections={checkSelections}
        onSelectionChange={onSelectionChange}
        tutorial={tutorial}
        defaultFoldersOpen={defaultFoldersOpen}
      />
    );
  }
);

export default AccountTree;
