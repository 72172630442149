import { SelectOption } from "@app-components/control/SimpleSelect";
import { RoundedDataGrid } from "@app-components/DataGrid";
import { useModalState, useRefCallback } from "@enfusion-ui/hooks";
import { MOCK_FA_GRID_PORTAL_DATA } from "@enfusion-ui/trades";
import { ControlledSelect, SelectBaseProps } from "@enfusion-ui/web-components";
import { columnTypes, styled } from "@enfusion-ui/web-core";
import { ColDef, RowClickedEvent } from "ag-grid-community";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import { AdvancedSearchButton } from "./components/AdvancedSearchButton";
import { AdvancedSearchContentPortal } from "./components/AdvancedSearchContentPortal";

const MOCK_OPTIONS: SelectOption<number>[] = MOCK_FA_GRID_PORTAL_DATA.map(
  (el) => ({ value: el.id, label: el.name })
);

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: var(--spacing);
`;

const financeAgreementColDefs: ColDef[] = [
  {
    headerName: "Name",
    field: "name",
    pinned: "left",
  },
  {
    headerName: "Frequency",
    field: "frequency",
  },
  {
    headerName: "Currency",
    field: "currency",
  },
  {
    headerName: "Start Date",
    field: "startDate",
    type: "dateColumn",
  },
  {
    headerName: "End Date",
    field: "endDate",
    type: "dateColumn",
  },
];

type FinancingAgreementSelectProps = SelectBaseProps & {
  showAdvancedSearch?: boolean;
};

export const FinancingAgreementSelect: React.FC<
  FinancingAgreementSelectProps
> = ({
  showAdvancedSearch,
  name = "financingAgreementId",
  label = "Financing Agreement",
  ...props
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { open, openModal, closeModal } = useModalState();
  const { setValue } = useFormContext();

  const onRowClicked = useRefCallback((e: RowClickedEvent) => {
    setValue(name, e.data.id);
    closeModal();
  }, []);

  return (
    <Container ref={containerRef}>
      <ControlledSelect
        name={name}
        label={label}
        options={MOCK_OPTIONS}
        inputId="finance-agreement-select"
        {...props}
      />

      {showAdvancedSearch ? (
        <>
          <AdvancedSearchButton hideText onClick={openModal} />
          <AdvancedSearchContentPortal
            open={open}
            hideOptions
            closePopup={closeModal}
            attachedRef={containerRef}
          >
            <RoundedDataGrid
              height="100%"
              suppressCellFocus
              columnTypes={columnTypes}
              onRowClicked={onRowClicked}
              rowData={MOCK_FA_GRID_PORTAL_DATA}
              getRowId={(params) => params.data.id}
              columnDefs={financeAgreementColDefs}
              defaultColDef={{
                sortable: true,
                resizable: true,
                suppressMovable: true,
                initialWidth: 120,
                type: "standardColumn",
              }}
            />
          </AdvancedSearchContentPortal>
        </>
      ) : null}
    </Container>
  );
};
