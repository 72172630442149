import { FXOrderFormValues } from "@enfusion-ui/core";
import { useIsContentMobile } from "@enfusion-ui/web-core";
import { useFormContext } from "react-hook-form";

import { FXOrderSummaryPanel } from "./FXOrderSummaryPanel";
import {
  FXInLineGridWrapper,
  InLineGridWrapperMobile,
  MobileWrapper,
  WebWrapper,
} from "./styles";

export const FXOrderSummaryModalCore = () => {
  const isMobileDevice = useIsContentMobile();
  const { watch } = useFormContext<FXOrderFormValues>();

  const {
    orderSide,
    quantity,
    currencyPair,
    fixingDate,
    farFixingDate,
    settleDate,
    farSettleDate,
    fxType,
  } = watch([
    "orderSide",
    "quantity",
    "currencyPair",
    "fixingDate",
    "farFixingDate",
    "settleDate",
    "farSettleDate",
    "fxType",
  ]);

  if (isMobileDevice) {
    return (
      <MobileWrapper>
        <InLineGridWrapperMobile padding="0rem 1rem 0rem 0.5rem">
          <FXOrderSummaryPanel
            viewMode="row"
            orderSide={orderSide}
            quantity={quantity}
            currencyPair={currencyPair}
            fixingDate={fixingDate}
            farFixingDate={farFixingDate}
            settleDate={settleDate}
            farSettleDate={farSettleDate}
            fxType={fxType}
            isMobileDevice={isMobileDevice}
          />
        </InLineGridWrapperMobile>
      </MobileWrapper>
    );
  }
  return (
    <WebWrapper>
      <FXInLineGridWrapper margin="0 4rem 0 0">
        <FXOrderSummaryPanel
          viewMode="grid"
          orderSide={orderSide}
          quantity={quantity}
          currencyPair={currencyPair}
          fixingDate={fixingDate}
          farFixingDate={farFixingDate}
          settleDate={settleDate}
          farSettleDate={farSettleDate}
          fxType={fxType}
          isMobileDevice={isMobileDevice}
        />
      </FXInLineGridWrapper>
    </WebWrapper>
  );
};
