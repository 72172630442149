import DocumentSaveForm from "@app-components/modal/DocumentSaveForm";
import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import ControlledAccountSelect from "@app-views/reports/components/ReportQueryComponents/AccountSelect";
import { PortfolioConfigV1, PortfolioSettingsV1 } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  AppEvent,
  AppEventCategories,
  DashboardRoot,
} from "@enfusion-ui/types";
import {
  CenterContent,
  Modal,
  Spinner,
  useNavBarState,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  errorToast,
  REST_API,
  useTabs,
} from "@enfusion-ui/web-core";
import { cloneDeep } from "lodash";
import * as React from "react";

import { getNewPortfolioTabContent } from "../utils";

export const SaveAsPortfolioModalBase = ({
  open,
  closeModal,
  name,
  root,
  filePath,
  fundIds,
  settings,
  config,
  loading,
}: {
  open: boolean;
  closeModal: VoidFunction;
  name: string;
  root: DashboardRoot;
  filePath: string;
  fundIds: number[];
  settings: PortfolioSettingsV1;
  config: PortfolioConfigV1;
  loading: boolean;
}) => {
  const { openTab } = useTabs();
  const { closeNavBarTabOnMobile } = useNavBarState();
  const explorerChannel = useExplorerEvents("Portfolios");

  const handleSubmit = useRefCallback(
    async (
      name: string,
      filePath: string,
      rootPath: DashboardRoot,
      args: Record<string, unknown>
    ) => {
      const path = `/${filePath}${name}.json`;
      const { fundIds } = args.accountSelect as { fundIds: number[] };

      if (fundIds.length === 0) {
        errorToast("Funds are required to save a workbench");
        return;
      }

      const result = await REST_API.PORTFOLIO.STORE_PORTFOLIO({
        name,
        path,
        rootPath,
        version: 0,
        config: {
          ...cloneDeep(config),
          instrumentIds: [],
          fundIds,
        },
        settings: { ...cloneDeep(settings) },
      });

      if (result.success && result.filePath) {
        explorerChannel.broadcast(rootPath, ExplorerEventsAction.Refetch);
        closeNavBarTabOnMobile();
        AppLogging.event(
          {
            event: AppEvent.CreateFile,
            category: AppEventCategories.Portfolios,
          },
          { name, root: rootPath, path: result.filePath }
        );
        openTab(
          getNewPortfolioTabContent({
            name,
            root: rootPath,
            path: result.filePath,
          })
        );
        closeModal();
      } else {
        errorToast("Failed to create workbench");
      }
    },
    [explorerChannel, settings, config, closeModal]
  );

  return (
    <Modal
      animateEnabled={open}
      key={open ? "open" : "closed"}
      isOpen={open}
      onClose={closeModal}
      title="Save As"
      dataE2EId="SaveAsWorkbenchModal"
      content={
        loading ? (
          <CenterContent>
            <Spinner />
          </CenterContent>
        ) : (
          <DocumentSaveForm
            root={root || undefined}
            fileName={name}
            fullyQualifiedPath={filePath}
            keyword="portfolio"
            onSubmit={handleSubmit}
            onCancel={closeModal}
            submitButtonText="Save As"
            additionalRequiredFieldNames={["accountSelect"]}
          >
            <div style={{ width: "100%", overflow: "hidden" }}>
              <ControlledAccountSelect
                name="accountSelect"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                defaultAccountSelection={{ fundIds: fundIds } as any}
                performDirtyCheck
                label="Funds"
                required
              />
            </div>
          </DocumentSaveForm>
        )
      }
    />
  );
};
