import { useRefCallback } from "@enfusion-ui/hooks";
import { ERROR_SEVERITIES, MultiSelectOptionsType } from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  ControlledInputBase,
  MultiSelect,
  PanelRow,
  TextInput,
} from "@enfusion-ui/web-components";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import ControlledAssignedTraderSelect from "../../../components/ReportQueryComponents/AssignedTraderSelect";
import { ControlledComplianceRulesMultiselect } from "../../../components/ReportQueryComponents/ComplianceRulesMultiselect";
import ComplianceStateSelect from "../../../components/ReportQueryComponents/ComplianceSelect";
import { DateRangeSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledMaxResultsInput } from "../../../components/ReportQueryComponents/MaxResultsInput";

const ComplianceBlotterSettings = () => {
  const { assignedTraderIds, errorSeverities } = useWatch({
    name: ["assignedTraderIds", "errorSeverities"],
  });
  const defaultAssignedTraderIdsRef = React.useRef(assignedTraderIds ?? []);
  const currentOrderIds = React.useRef<Array<string>>([]);
  const currentComplianceIds = React.useRef<Array<string>>([]);
  const { setValue } = useFormContext();

  const handleChange = useRefCallback(
    (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
      const re = /^[0-9,\b]+$/;
      if (name === "orderIds") {
        currentOrderIds.current = e.target.value
          ? re.test(e.target.value)
            ? e.target.value.replace(/,+/g, ",").split(",")
            : currentOrderIds.current
          : [];
        setValue(
          "orderIds",
          currentOrderIds.current
            ?.filter((item: String) => item !== "")
            ?.map(Number)
        );
      } else if (name === "complianceRecordIds") {
        currentComplianceIds.current = e.target.value
          ? re.test(e.target.value)
            ? e.target.value.replace(/,+/g, ",").split(",")
            : currentComplianceIds.current
          : [];
        setValue(
          "complianceRecordIds",
          currentComplianceIds.current
            ?.filter((item: String) => item !== "")
            ?.map(Number)
        );
      }
    },
    []
  );

  return (
    <>
      <DateRangeSelection
        name="orderDateRange"
        startDateLabel="Order Date"
        endDateLabel="To"
      />
      <DateRangeSelection
        name="complianceRecordDateRange"
        startDateLabel="Record Date"
        endDateLabel="To"
      />
      <ControlledAssignedTraderSelect
        defaultAssignedTrader={defaultAssignedTraderIdsRef.current}
      />
      <ControlledInputBase
        name="orderIds"
        render={({ ref: _ref, ...rest }) => (
          <TextInput
            {...rest}
            type="text"
            label="Order Id(s)"
            value={currentOrderIds.current?.join(",")}
            onChange={(e) => handleChange(e, "orderIds")}
          />
        )}
      />
      <ControlledInputBase
        name="complianceRecordIds"
        render={({ ref: _ref, ...rest }) => (
          <TextInput
            {...rest}
            type="text"
            label="Comp Record Id(s)"
            value={currentComplianceIds.current?.join(",")}
            onChange={(e) => handleChange(e, "complianceRecordIds")}
          />
        )}
      />
      <ControlledComplianceRulesMultiselect />
      <ComplianceStateSelect />
      <PanelRow>
        <ControlledInputBase
          name="errorSeverities"
          render={({ ref: _ref, onChange, ...rest }) => (
            <MultiSelect
              {...rest}
              value={
                errorSeverities
                  ? getSelectedValues(errorSeverities, ERROR_SEVERITIES)
                  : []
              }
              onChange={(selectedOptions) => {
                const errorSeverities =
                  selectedOptions?.map(
                    (option: MultiSelectOptionsType) => option.value
                  ) || [];
                setValue("errorSeverities", errorSeverities);
                onChange(errorSeverities);
              }}
              label="Error Severities"
              options={ERROR_SEVERITIES}
            />
          )}
        />
        <ControlledMaxResultsInput label="Limit" />
      </PanelRow>
    </>
  );
};

export default ComplianceBlotterSettings;
