import AllocationsPanel from "@app-components/inputs/allocations/AllocationsPanel";
import PositionDetailsPanel from "@app-components/inputs/positionDetailsPanel/PositionDetailsPanel";
import { useOEMSOrderForm } from "@enfusion-ui/core";
import {
  ColumnGrid,
  ControlledEmpty,
  FormContainer,
  FormContent,
  FormPanel,
} from "@enfusion-ui/web-components";
import { useAuth, useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

import ExecutionMethodSelection from "../../../components/ExecutionMethodSelection";
import MarketInfoPanel from "../../../OrderTicketForm/panels/MarketInfoPanel";
import { ComplianceErrorList } from "../../components/ComplianceErrorList";
import CalculatedSidePanel from "../../panels/CalculatedSidePanel";
import CDXClearingPanel from "../../panels/cdx/CDXClearingPanel";
import CDXOrderDetailsPanel from "../../panels/cdx/CDXOrderDetailsPanel";
// import FutureSpreadPanel from "../../panels/FutureSpreadPanel";
import FXDatesPanel from "../../panels/FXDatesPanel";
import FXOrderDetailsPanel from "../../panels/FXOrderDetailsPanel";
import InstrumentAndStrategyPanel from "../../panels/InstrumentAndStrategyPanel";
import IRSClearingPanel from "../../panels/irs/IRSClearingPanel";
import IRSOrderDetailsPanel from "../../panels/irs/IRSOrderDetailsPanel";
import { OrderDetailsPanel } from "../../panels/OrderDetailsPanel";
import VarSwapProductDetails from "../../panels/varSwap/VarSwapProductDetails";
import VarSwapSideQuantity from "../../panels/varSwap/VarSwapSideQuantity";
import VarSwapValuations from "../../panels/varSwap/VarSwapValuations";

const DetailsTabCore: React.FC<unknown> = () => {
  const {
    isSplit,
    newOrder,
    completed,
    isTransmitted,
    isFxOrder,
    isCdxOrder,
    isVarSwapOrder,
    isIrsOrder,
    isFutureSpreadOrder,
    splitOrder,
    orderId,
  } = useOEMSOrderForm();
  const { parentOrderId, irsInstrument } = useWatch({
    name: ["parentOrderId", "irsInstrument"],
  });
  const isMobile = useIsContentMobile();
  const { isUserType } = useAuth();

  const disableEditing = isTransmitted || isSplit;

  const isExpressUser = isUserType("Express");

  let detailsContent = (
    <>
      <FormPanel title="Product Information" popStyle={false}>
        <InstrumentAndStrategyPanel
          disabled={!newOrder || splitOrder || completed || disableEditing}
          financialSubTypes={isFutureSpreadOrder ? ["FutureSpread"] : undefined}
          showPosition={!isFutureSpreadOrder}
        />
        <MarketInfoPanel margin />
      </FormPanel>
      {!isFutureSpreadOrder && (
        <FormPanel
          title="Position Details"
          numColumns={1}
          collapsible
          contentStyle={{ gap: 0 }}
        >
          <PositionDetailsPanel disabled={completed} />
        </FormPanel>
      )}
      <FormPanel
        title="Order Details"
        collapsible
        defaultOpen
        lineStyle
        keepRendered
        defaultRendered
      >
        <OrderDetailsPanel
          disabled={completed || disableEditing || splitOrder}
          input="quantity"
          showCalculationInfoText
        />
      </FormPanel>
    </>
  );
  if (isFxOrder) {
    detailsContent = (
      <FormPanel
        title="Order Details"
        collapsible
        defaultOpen
        lineStyle
        keepRendered
        defaultRendered
      >
        <FXOrderDetailsPanel
          editMode={!newOrder || splitOrder || disableEditing}
          disabled={disableEditing || splitOrder || completed}
        />
        <FXDatesPanel editMode={splitOrder || disableEditing || completed} />
      </FormPanel>
    );
  } else if (isCdxOrder) {
    detailsContent = (
      <FormPanel
        title="Product Details"
        popStyle={false}
        collapsible
        defaultOpen
        lineStyle
        keepRendered
        defaultRendered
      >
        <CDXOrderDetailsPanel
          disabled={splitOrder || completed || disableEditing || orderId !== 0}
        />
        <CDXClearingPanel
          disabled={splitOrder || disableEditing || completed}
        />
      </FormPanel>
    );
  } else if (isVarSwapOrder) {
    detailsContent = (
      <FormPanel popStyle={false} numColumns={1}>
        <FormPanel title="Product Details" numColumns={1}>
          <VarSwapProductDetails editMode={!newOrder} />
        </FormPanel>
        <ColumnGrid numColumns={2} isMobile={isMobile}>
          <VarSwapSideQuantity />
          <VarSwapValuations />
        </ColumnGrid>
      </FormPanel>
    );
  } else if (isIrsOrder) {
    detailsContent = (
      <>
        <FormPanel
          title="Product Details"
          popStyle={false}
          info={`Instrument : ${irsInstrument?.description ?? "Unknown"}`}
        >
          <IRSClearingPanel
            disabled={
              splitOrder || completed || !!parentOrderId || isTransmitted
            }
          />
          <IRSOrderDetailsPanel
            disabled={
              splitOrder || completed || !!parentOrderId || isTransmitted
            }
          />
        </FormPanel>
        <FormPanel numColumns={1}>
          <CalculatedSidePanel
            showIRSOptions
            disabled={
              splitOrder || completed || !!parentOrderId || isTransmitted
            }
          />
        </FormPanel>
      </>
    );
  }

  return (
    <FormContainer>
      <FormContent isMobile={isMobile}>
        <ComplianceErrorList />
        {detailsContent}
        <FormPanel
          title="Execution Method"
          numColumns={1}
          collapsible
          defaultOpen
          lineStyle
          keepRendered
          defaultRendered
        >
          <ExecutionMethodSelection
            hideUndecided={splitOrder || !!parentOrderId}
            disableMethodSelection={disableEditing}
            disableAllInputs={completed}
          />
        </FormPanel>

        <FormPanel
          title="Allocations"
          numColumns={1}
          collapsible
          defaultOpen
          lineStyle
          keepRendered
          defaultRendered
        >
          <AllocationsPanel
            disabled={completed || isTransmitted}
            compressed={isExpressUser}
          />
        </FormPanel>
        <ControlledEmpty name="accountAllocation" />
        <ControlledEmpty name="locates" />
        <ControlledEmpty name="notionalCcy" />
        <ControlledEmpty name="localNotionalCcy" />
      </FormContent>
    </FormContainer>
  );
};

export const DetailsTab = React.memo(DetailsTabCore);
