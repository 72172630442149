import { DashboardRoot } from "@enfusion-ui/types";

export enum ExplorerEventsAction {
  Refetch = "refetch",
  Delete = "delete",
}

export type ExplorerEventsSubscriptionCallback = (
  root: DashboardRoot,
  event: ExplorerEventsAction,
  meta?: string
) => void;
