import { useRefCallback } from "@enfusion-ui/hooks";
import { ValueOf } from "@enfusion-ui/types";
import { TabListTab, TertiaryTabList } from "@enfusion-ui/web-components";
import { styled, useGridApi } from "@enfusion-ui/web-core";
import * as React from "react";

import { AnalyticsTopN, TopNGridEntry } from "../types";
import { TopNGrid } from "./TopNGrid";

const SectionContainer = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-xl);
  padding: var(--spacing) 0 var(--spacing) var(--spacing-l);
`;

const Title = styled.h4`
  white-space: nowrap;
`;

const GridContainer = styled.div`
  height: 100%;
  padding: var(--spacing-l);
  border-top: 1px solid var(--background-color-1);
`;

const TopNTabKeys = {
  Contributors: "contributors",
  Detractors: "detractors",
} as const;
type TabKey = ValueOf<typeof TopNTabKeys>;

const tabsBase: TabListTab[] = Object.entries(TopNTabKeys).map(
  ([label, key]) => ({ label, key })
);

type TopNGridPanelProps = {
  data: TopNGridEntry[];
  topN: AnalyticsTopN;
  setSelectedIds: (selectedIds: Set<number>) => void;
};

export const TopNGridPanel: React.FC<TopNGridPanelProps> = React.memo(
  ({ data, topN, setSelectedIds }) => {
    const [tabs, setTabs] = React.useState<TabListTab[]>(tabsBase);
    const [selectedTab, setSelectedTab] =
      React.useState<TabKey>("contributors");

    const { contributors, detractors } = React.useMemo(
      () => ({
        contributors: data.slice(0, topN),
        detractors: data.slice(-topN).reverse(),
      }),
      [data, topN]
    );

    const { gridApiRef: contributorsRef, onGridReady: onContributorsReady } =
      useGridApi();
    const { gridApiRef: detractorsRef, onGridReady: onDetractorsReady } =
      useGridApi();

    const onSelectionChanged = useRefCallback(() => {
      const contributorIds =
        contributorsRef.current?.api
          .getSelectedRows()
          .map((row) => row.instrumentId) ?? [];
      const detractorIds =
        detractorsRef.current?.api
          .getSelectedRows()
          .map((row) => row.instrumentId) ?? [];

      setSelectedIds(new Set([...contributorIds, ...detractorIds]));
      setTabs((tabs) =>
        tabs.map((t) => ({
          ...t,
          count:
            t.key === "contributors"
              ? contributorIds.length || undefined
              : detractorIds.length || undefined,
        }))
      );
    }, [setSelectedIds]);

    return (
      <SectionContainer>
        <Header>
          <Title>Top {topN}</Title>
          <div>
            <TertiaryTabList
              tabs={tabs}
              value={selectedTab}
              scrollToView={false}
              onSelect={(key) => setSelectedTab(key as TabKey)}
            />
          </div>
        </Header>

        <GridContainer>
          <TopNGrid
            name="contributors"
            rowData={contributors}
            onGridReady={onContributorsReady}
            hide={selectedTab !== "contributors"}
            onSelectionChanged={onSelectionChanged}
          />
          <TopNGrid
            name="detractors"
            rowData={detractors}
            onGridReady={onDetractorsReady}
            hide={selectedTab !== "detractors"}
            onSelectionChanged={onSelectionChanged}
          />
        </GridContainer>
      </SectionContainer>
    );
  }
);
