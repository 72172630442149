import { SimplePortalMenu } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faSortDown, faSortUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

export type SelectOption<T = unknown> = { value: T; label: string };

export type SimpleSelectProps = {
  options: Array<SelectOption>;
  onChange: (value: unknown) => void;
  defaultValue?: unknown;
  className?: string;
  styles?: React.CSSProperties;
};

const SimpleSelectContainer = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: auto;
  min-width: 0.25em;
`;

export default function SimpleSelect({
  options,
  defaultValue,
  onChange,
  className,
  styles = {},
}: SimpleSelectProps) {
  const [value, setValue] = React.useState<SelectOption | null>(() => {
    if (typeof defaultValue === "undefined") {
      return null;
    }
    // Object.is is used instead of === since we are not limiting type so in this case NaN will still work
    const val = options.find((i) => Object.is(i.value, defaultValue));
    if (!val) {
      return null;
    }
    return val;
  });

  const [open, setOpen] = React.useState(false);
  const ref = React.useRef(null);

  const handleSelect = (index: number) => {
    const entry = options[index];

    setValue(entry);
    onChange(entry.value);
  };

  const handleClick = () => setOpen((open) => !open);

  return (
    <SimpleSelectContainer
      className={className}
      style={styles}
      onClick={handleClick}
      ref={ref}
    >
      {value?.label}
      <FontAwesomeIcon
        icon={open ? faSortUp : faSortDown}
        style={{
          fontSize: 9,
          verticalAlign: "middle",
          marginTop: open ? 0 : -5,
        }}
      />
      <SimplePortalMenu
        value={value?.value}
        options={options}
        onSelect={handleSelect}
        open={open}
        attachedRef={ref}
        placeholder={value?.value ? `${value.value}` : ""}
        onClickOutside={() => setOpen(false)}
      />
    </SimpleSelectContainer>
  );
}
