/* eslint-disable no-nested-ternary */
// import FormPanel from "@app-components/layout/FormPanel";
import { useMarketData } from "@app-context/marketData/context";
import { FutureLegLabeledValue } from "@app-views/oems/components/styledComponents";
import { getDisplayValue } from "@app-views/oems/utils/getDisplayValue";
import { OrderFormValues, useInstrument } from "@enfusion-ui/core";
import { useMounted } from "@enfusion-ui/hooks";
import { InstrumentInfo, Quote } from "@enfusion-ui/types";
import { getKeywordFromInstrument } from "@enfusion-ui/utils";
import { ControlledInputBase } from "@enfusion-ui/web-components";
import { REST_API, styled } from "@enfusion-ui/web-core";
import { noop } from "lodash";
import * as React from "react";
import { useFormContext } from "react-hook-form";

const LegsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: var(--spacing);
`;

const getMarketChangeColor = (change: number | null) => {
  if (!change) return undefined;
  return change > 0 ? "success" : "danger";
};

const FutureSpreadPanel: React.FC<unknown> = () => {
  const { setValue } = useFormContext<OrderFormValues>();
  const instrument = useInstrument();
  const isMounted = useMounted();
  const { subscribeToMarketData } = useMarketData();
  const [legs, setLegs] = React.useState<InstrumentInfo[] | null>(null);
  const [nearLegQuote, setNearLegQuote] = React.useState<Quote | null>(null);
  const [farLegQuote, setFarLegQuote] = React.useState<Quote | null>(null);

  React.useEffect(() => {
    const fetchLegDetails = async () => {
      if (!instrument?.nearLeg || !instrument?.farLeg) {
        setLegs(null);
        return null;
      }

      try {
        Promise.all([
          REST_API.INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(
            instrument?.nearLeg.futureId
          ),
          REST_API.INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(
            instrument?.farLeg.futureId
          ),
        ]).then((values) => {
          setLegs(values);
        });
      } catch (err) {
        console.error("future spread info load error", err);
      }

      return null;
    };
    if (isMounted()) fetchLegDetails();
  }, [isMounted, instrument]);

  React.useEffect(() => {
    let unsubscribeNearLeg = noop;
    let unsubscribeFarLeg = noop;

    if (legs && legs.length && legs[0]?.id && legs[1]?.id) {
      unsubscribeNearLeg = subscribeToMarketData(legs[0].id, (v) =>
        setNearLegQuote(v)
      );
      unsubscribeFarLeg = subscribeToMarketData(legs[1].id, (v) =>
        setFarLegQuote(v)
      );
      setValue(
        "nearLeg",
        getKeywordFromInstrument(legs[0], "RIC") || legs?.[0]?.description || ""
      );
      setValue(
        "farLeg",
        getKeywordFromInstrument(legs[1], "RIC") || legs?.[1]?.description || ""
      );
    } else {
      setValue("nearLeg", "");
      setValue("farLeg", "");
      setNearLegQuote(null);
      setFarLegQuote(null);
    }
    return () => {
      unsubscribeNearLeg();
      unsubscribeFarLeg();
    };
  }, [legs, subscribeToMarketData]);

  return (
    <LegsContainer>
      <ControlledInputBase
        name="nearLeg"
        render={({ ref: _ref, value }) => (
          <FutureLegLabeledValue
            label="Near Leg"
            futureLeg={value}
            themeColor={getMarketChangeColor(nearLegQuote?.change ?? null)}
          >
            {getDisplayValue(nearLegQuote?.last ?? null)}
          </FutureLegLabeledValue>
        )}
      />
      <ControlledInputBase
        name="farLeg"
        render={({ ref: _ref, value }) => (
          <FutureLegLabeledValue
            label="Far Leg"
            futureLeg={value}
            themeColor={getMarketChangeColor(farLegQuote?.change ?? null)}
          >
            {getDisplayValue(farLegQuote?.last ?? null)}
          </FutureLegLabeledValue>
        )}
      />
    </LegsContainer>
  );
};

export default React.memo(FutureSpreadPanel);
