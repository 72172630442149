import * as React from "react";

export type SettingsContextState = {
  enableOEMSRealtimeUpdate: boolean;
  enableReportRealtimeUpdate: boolean;
  toggleOEMSRealtimeUpdate: VoidFunction;
  toggleReportRealtimeUpdate: VoidFunction;

  enableKeepTicketOpenAfterCreate: boolean;
  enableClearTicketAfterCreate: boolean;
  enablePreferCustomOrderForm: boolean;
  toggleKeepTicketOpenAfterCreate: VoidFunction;
  toggleClearTicketAfterCreate: VoidFunction;
  togglePreferCustomOrderForm: VoidFunction;
};

export const SettingsContext = React.createContext<
  SettingsContextState | undefined
>(undefined);

export function useSettings() {
  const context = React.useContext(SettingsContext);
  if (context === undefined) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
}
