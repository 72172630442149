import { RoundedDataGrid } from "@app-components/DataGrid";
import { Label } from "@app-views/oems/components/styledComponents";
import { TOAST_CONTENT, VarSwapOrderFormValues } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { HolidayCenterOption, HolidayOption } from "@enfusion-ui/types";
import {
  Button,
  Checkbox,
  ControlledEmpty,
  FormElement,
  Modal,
  PanelRow,
  Portal,
  TextInput,
} from "@enfusion-ui/web-components";
import { errorToast, REST_API, styled, useAuth } from "@enfusion-ui/web-core";
import {
  CellValueChangedEvent,
  ColDef,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  IRowNode,
  MenuItemDef,
  SelectionChangedEvent,
} from "ag-grid-community";
import { motion } from "framer-motion";
import { Dictionary } from "highcharts";
import { groupBy } from "lodash";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

const GridWrapper = styled(RoundedDataGrid)`
  .ag-header-cell {
    padding-left: 8px;
  }
  .ag-cell {
    padding-left: 8px;
  }
`;

const ShowHolidayButton = styled(Button)`
  position: absolute;
  right: 8px;
  top: 8px;
  width: inherit;
`;
const PortalContainer = styled(motion.div)`
  box-shadow: 0px 3px 8px 1px var(--overlay);
  background-color: var(--background-color-0);
  border: 1px solid var(--input-border);
  border-radius: 3px;
  padding: 8px;
  padding-top: 42px;
`;

const holidaysGridColDefs = [
  {
    headerName: "Date",
    field: "holiday",
    colId: "holiday",
    width: 100,
    lockPinned: true,
    lockVisible: true,
    suppressHeaderMenuButton: true,
    resizable: false,
    rowGroup: false,
    sortable: true,
    suppressNavigable: true,
    suppressMovable: true,
  },
  {
    headerName: "Description",
    field: "description",
    colId: "description",
    width: 185,
    sortable: true,
    lockPinned: true,
    lockVisible: true,
    suppressHeaderMenuButton: true,
    resizable: true,
    rowGroup: false,
    suppressNavigable: true,
    suppressMovable: true,
  },
  {
    headerName: "Bank",
    field: "holidayCenter",
    colId: "holidayCenter",
    width: 185,
    sortable: true,
    lockPinned: true,
    lockVisible: true,
    suppressHeaderMenuButton: true,
    rowGroup: false,
    resizable: true,
    suppressNavigable: true,
    suppressMovable: true,
  },

  {
    headerName: "Id",
    field: "id",
    colId: "id",
    width: 100,
    lockPinned: true,
    lockVisible: true,
    suppressHeaderMenuButton: true,
    resizable: false,
    rowGroup: false,
    suppressNavigable: true,
    suppressMovable: true,
    sortable: true,
  },
] as ColDef[];

const holidaysCentersGridColDefs = [
  {
    headerName: "",
    field: "selection",
    colId: "selection",
    width: 32,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    pinned: "left",
    lockPinned: true,
    lockVisible: true,
    suppressHeaderMenuButton: true,
    resizable: false,
    rowGroup: false,
    suppressNavigable: true,
    suppressMovable: true,
  },
  {
    headerName: "Code",
    field: "code",
    colId: "code",
    width: 125,
    lockPinned: true,
    lockVisible: true,
    suppressHeaderMenuButton: true,
    resizable: false,
    rowGroup: false,
    suppressNavigable: true,
    suppressMovable: true,
    sort: "asc",
  },

  {
    headerName: "Description",
    field: "description",
    colId: "description",
    width: 300,
    lockPinned: true,
    lockVisible: true,
    suppressHeaderMenuButton: true,
    resizable: false,
    rowGroup: false,
    suppressNavigable: true,
    suppressMovable: true,
  },
  {
    headerName: "Country",
    field: "country",
    colId: "country",
    width: 75,
    lockPinned: true,
    lockVisible: true,
    suppressHeaderMenuButton: true,
    resizable: false,
    rowGroup: false,
    suppressNavigable: true,
    suppressMovable: true,
  },
  {
    headerName: "Currency",
    field: "currency",
    colId: "currency",
    width: 75,
    lockPinned: true,
    lockVisible: true,
    suppressHeaderMenuButton: true,
    resizable: false,
    rowGroup: false,
    suppressNavigable: true,
    suppressMovable: true,
  },
  {
    headerName: "Id",
    field: "id",
    colId: "id",
    width: 75,
    lockPinned: true,
    lockVisible: true,
    suppressHeaderMenuButton: true,
    resizable: false,
    rowGroup: false,
    suppressNavigable: true,
    suppressMovable: true,
  },
] as ColDef[];

const HolidayCenterPicker: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const [includeHistorical, setIncludeHistorical] = React.useState(false);
  const [includeFuture, setIncludeFuture] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [showHolidaysListModal, setShowHolidaysListModal] =
    React.useState(false);
  const [selectedHolidayCenter, setSelectedHolidayCenter] =
    React.useState<HolidayCenterOption[]>();
  const [holidayCentersOptions, setHolidayCentersOptions] =
    React.useState<HolidayCenterOption[]>();
  const [holidaysGridData, setHolidaysGridData] = React.useState<
    HolidayOption[]
  >([]);
  const [holidaysData, setHolidaysData] = React.useState<
    Dictionary<HolidayOption[]>
  >({});

  const { setValue } = useFormContext<VarSwapOrderFormValues>();
  const { holidayCenters } = useWatch<VarSwapOrderFormValues>({
    name: ["holidayCenters"],
  });

  const inputRef = React.useRef(null);
  const gridApiRef = React.useRef<GridApi | null>(null);
  const { isAdmin } = useAuth();

  React.useEffect(() => {
    async function fetchHolidayCenter() {
      try {
        const options = await REST_API.HOLIDAYS.GET_HOLIDAY_CENTERS.FETCH();

        setHolidayCentersOptions(options);
      } catch (err) {
        errorToast(
          TOAST_CONTENT.oems.networkError.holidayCenter.fetchFailed,
          (err as Error).message
        );
      }
    }
    fetchHolidayCenter();
  }, []);

  React.useEffect(() => {
    gridApiRef.current?.forEachNode((rowNode: IRowNode) => {
      if (holidayCenters?.includes(rowNode.data.code))
        rowNode.setSelected(true);
      else rowNode.setSelected(false);
    });
  }, [holidayCenters]);

  React.useEffect(() => {
    if (holidayCenters) {
      setInputValue(
        holidayCentersOptions
          ?.filter((item) => holidayCenters?.includes(item.code))
          .map((item) => {
            return item.code;
          })
          .join("; ") ?? ""
      );
    } else {
      setInputValue("");
    }
  }, [holidayCenters, setInputValue, holidayCentersOptions]);

  const handleFocus = useRefCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  React.useEffect(() => {
    setHolidaysGridData(normalizeData());
  }, [includeFuture, includeHistorical, holidaysData, setHolidaysGridData]);

  const handleClickOutside = useRefCallback(() => {
    if (showHolidaysListModal) return;
    setShowModal(false);
  }, [setShowModal, showHolidaysListModal]);

  const handleOnGridReady = useRefCallback(
    (event: GridReadyEvent) => {
      gridApiRef.current = event.api;
      event.api.setColumnVisible("id", isAdmin());
      event.api.forEachNode((rowNode: IRowNode) => {
        if (holidayCenters?.includes(rowNode.data.code))
          rowNode.setSelected(true);
      });
    },
    [holidayCenters]
  );

  const handleSelectionChanged = useRefCallback(
    (event: SelectionChangedEvent) => {
      const selectedRows = event.api.getSelectedRows();
      setSelectedHolidayCenter(selectedRows);
      setValue(
        "holidayCenters",
        selectedRows.map((item) => item.code)
      );
    },
    [setSelectedHolidayCenter, setValue]
  );

  const normalizeData = useRefCallback(() => {
    const normalizedHolidays = [];
    for (const property in holidaysData) {
      const year = new Date(property).getFullYear();
      const currentYear = new Date().getFullYear();
      if (
        year === currentYear ||
        year === currentYear + 1 ||
        (includeFuture && year > currentYear) ||
        (includeHistorical && year < currentYear)
      ) {
        if (holidaysData[property].length === 1) {
          normalizedHolidays.push(holidaysData[property][0]);
          continue;
        }
        normalizedHolidays.push(
          holidaysData[property].reduce(
            (a, b) =>
              ({
                holiday: b.holiday,
                description: a.description.includes(b.description)
                  ? a.description
                  : a.description + ", " + b.description,
                holidayCenter: a.holidayCenter + ", " + b.holidayCenter,
                id: a.id + ", " + b.id,
              } as HolidayOption)
          )
        );
      }
    }
    return normalizedHolidays;
  }, [includeFuture, includeHistorical, holidaysData]);

  const handleShowHolidays = useRefCallback(async () => {
    setShowHolidaysListModal(true);
    const holidays = await REST_API.HOLIDAYS.GET_HOLIDAYS.FETCH(
      selectedHolidayCenter?.map((holiday) => holiday.id) ?? []
    );
    setHolidaysData(
      groupBy(holidays.flat(1) as unknown as HolidayOption[], "holiday")
    );
  }, [selectedHolidayCenter, setHolidaysGridData]);

  return (
    <FormElement>
      <ControlledEmpty name="holidayCenters" />
      <TextInput
        label="Holiday Centers"
        onFocus={handleFocus}
        value={inputValue}
        ref={inputRef}
        disabled={disabled}
      />
      <Portal
        attachedRef={inputRef}
        open={showModal}
        onClickOutside={handleClickOutside}
      >
        <PortalContainer>
          <GridWrapper
            height={300}
            width={400}
            rowSelection="multiple"
            columnDefs={holidaysCentersGridColDefs}
            rowData={holidayCentersOptions}
            getContextMenuItems={(params: GetContextMenuItemsParams) => {
              return [
                {
                  name: "Show holidays",
                  action: () => {
                    setSelectedHolidayCenter([params.node?.data]);
                    handleShowHolidays();
                  },
                } as MenuItemDef,
              ];
            }}
            onGridReady={handleOnGridReady}
            onSelectionChanged={handleSelectionChanged}
            onCellValueChanged={(event: CellValueChangedEvent) => {
              if (event.rowIndex || event.rowIndex === 0) {
                const tempOptions = holidayCentersOptions ?? [];
                tempOptions[event.rowIndex].isSelected = event.newValue;
                setHolidayCentersOptions(tempOptions);
              }
            }}
          />
          <ShowHolidayButton
            primary
            disabled={!selectedHolidayCenter || !selectedHolidayCenter?.length}
            onClick={handleShowHolidays}
          >
            Show Holidays
          </ShowHolidayButton>
        </PortalContainer>
      </Portal>

      <Modal
        isOpen={showHolidaysListModal}
        onClose={() => {
          setHolidaysGridData([]);
          setShowHolidaysListModal(false);
        }}
        title={`List of Holidays ${
          selectedHolidayCenter && selectedHolidayCenter.length === 1
            ? `for ${selectedHolidayCenter?.[0].code}` ?? ""
            : ""
        }`}
        content={
          <>
            <Label>Include</Label>
            <PanelRow
              style={{ gap: "var(--spacing)", margin: "var(--spacing) 0" }}
            >
              <Checkbox
                checked={includeHistorical}
                label="Historical Dates"
                onChange={(val) => {
                  setIncludeHistorical(val);
                }}
              />
              <Checkbox
                checked={includeFuture}
                label="Future Dates"
                onChange={(val) => {
                  setIncludeFuture(val);
                }}
              />
            </PanelRow>
            <RoundedDataGrid
              height={400}
              width={400}
              columnDefs={holidaysGridColDefs}
              onGridReady={({ columnApi }: GridReadyEvent) =>
                columnApi.setColumnVisible("id", isAdmin())
              }
              rowData={holidaysGridData}
              suppressContextMenu
              groupRemoveSingleChildren
              groupDisplayType="multipleColumns"
            />
          </>
        }
      />
    </FormElement>
  );
};

export default HolidayCenterPicker;
