import { ControlledInstrumentSelect } from "@app-components/inputs/InstrumentSelect/ControlledInstrumentSelect";
import HolidayCenterPicker from "@app-views/oems/OrderTicket/panels/varSwap/HolidayCenterPicker";
import CurrencySelect from "@app-views/reports/reportSettings/generalLedgerAccounting/glDistributionLine/CurrencySelect";
import { useOEMSOrderForm } from "@enfusion-ui/core";
import {
  callPutsInputOptions,
  dayCountOptions,
  transactionsSelectOptions,
} from "@enfusion-ui/trades";
import {
  ControlledDatePicker,
  ControlledNumericInput,
  ControlledSelect,
  PanelRow,
} from "@enfusion-ui/web-components";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import { HorizontalRule } from "../../../components/styledComponents";

export const OTCRealizedVarTerms: React.FC<unknown> = () => {
  const { setValue } = useFormContext();
  const { settings } = useOEMSOrderForm();
  return (
    <>
      <PanelRow>
        <ControlledDatePicker
          name="effectiveDate"
          label="Effective Date"
          required
        />
        <ControlledDatePicker name="ExpiryDate" label="Expiry Date" required />
      </PanelRow>
      <PanelRow>
        <ControlledSelect
          name="callPuts"
          label="Call/Put"
          options={callPutsInputOptions}
          required
        />
        <ControlledNumericInput
          name="strike"
          label="Strike"
          required
          formatStyle="percent"
        />
      </PanelRow>
      <PanelRow>
        <ControlledSelect
          name="businessDayConvention"
          label="Business Day Convention"
          options={undefined}
        />
        <HolidayCenterPicker />
      </PanelRow>
      <PanelRow>
        <ControlledInstrumentSelect
          hideOptions
          label="Fixing Rate"
          name="fixingRateInstrument"
          searchOnlyPrimary={settings?.searchOnlyPrimary ?? false}
          onChange={(value) => setValue("fixingRate", value?.id)}
          hideAdvancedSearchButtonText
        />

        <ControlledSelect
          required
          label="Day Count"
          name="dayCount"
          options={dayCountOptions}
        />
      </PanelRow>
      <HorizontalRule />
      <PanelRow>
        <ControlledSelect
          name="orderType"
          label="Order Type"
          options={transactionsSelectOptions}
          required
        />
        <ControlledNumericInput
          name="vegaNotional"
          label="Vega Notional"
          required
        />
      </PanelRow>
      <PanelRow>
        <CurrencySelect name="notionalCcy" label="Notional Currency" required />
        <ControlledNumericInput name="premium" label="Premium" required />
      </PanelRow>
    </>
  );
};
