import { CalculationTypeOptions, SelectOptionsType } from "@enfusion-ui/types";
import { Select } from "@enfusion-ui/web-components";
import * as React from "react";

export const BenchmarkCalculationTypeSelect: React.FC<{
  value: string | null;
  onChange: (option: SelectOptionsType<string> | null) => void;
  label?: string;
}> = ({ value, onChange, label = "Calculation Type" }) => {
  return (
    <Select
      label={label}
      value={CalculationTypeOptions.find(({ value: val }) => value === val)}
      options={CalculationTypeOptions}
      onChange={onChange}
      inputId="calculation-type-selection-id"
    />
  );
};
