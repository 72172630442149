import { RoundedDataGrid } from "@app-components/DataGrid";
import { useMounted } from "@enfusion-ui/hooks";
import {
  CenterContent,
  ContentMessage,
  Spinner,
} from "@enfusion-ui/web-components";
import {
  listCellFormatter,
  orderDetailsCellStyle,
} from "@enfusion-ui/web-core";
import { faSkullCrossbones } from "@fortawesome/pro-solid-svg-icons";
import { ColDef, GetRowIdParams } from "ag-grid-community";
import * as React from "react";

import {
  allocationMetadata,
  getGridColumnTypes,
} from "./behavior/columnsMetadata";
import useAllocationsList from "./behavior/useAllocationsList";

export type AllocationsListProps = {
  orderId: number | undefined;
  height?: number | string;
};
const metadata = allocationMetadata;
const columnDefs: ColDef[] = Object.entries(metadata).map(([key, value]) => {
  return {
    headerName: value.name,
    field: key,
    colId: key,
    type: getGridColumnTypes(value.columnTypeCategory),
    filter: true,
    enableRowGroup: value.columnTypeCategory === "Number" ? false : true,
  };
});

const AllocationsList: React.FC<AllocationsListProps> = ({
  height,
  orderId,
  ...rest
}) => {
  const isMounted = useMounted();
  const { allocations, onGridReady, error, loading } =
    useAllocationsList(orderId);

  if (error) {
    return (
      <CenterContent>
        <ContentMessage icon={faSkullCrossbones} message={error} />
      </CenterContent>
    );
  } else if (loading) {
    return (
      <CenterContent>
        <Spinner />
      </CenterContent>
    );
  } else if (isMounted()) {
    return (
      <>
        <RoundedDataGrid
          height={height}
          animateRows
          suppressCellFocus
          rowSelection="single"
          multiSortKey="ctrl"
          rowGroupPanelShow="always"
          groupDefaultExpanded={-1}
          rowData={allocations}
          context={{ metadata }}
          columnDefs={columnDefs}
          getRowId={(params: GetRowIdParams) => params.data?.__row_key}
          onGridReady={onGridReady}
          defaultColDef={{
            resizable: true,
            sortable: true,
            suppressKeyboardEvent: () => true,
            valueFormatter: listCellFormatter,
            cellStyle: orderDetailsCellStyle,
          }}
          gridName="allocations"
          {...rest}
        />
      </>
    );
  }
  return <></>;
};

export default AllocationsList;
