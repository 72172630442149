import { RoundedDataGrid } from "@app-components/DataGrid";
import { TradeTicketFormValues } from "@enfusion-ui/trades";
import { ControlledEmpty, FormPanel } from "@enfusion-ui/web-components";
import { useGridApi } from "@enfusion-ui/web-core";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import * as React from "react";
import { useWatch } from "react-hook-form";

import {
  FullHeightContainer,
  TabContentContainer,
} from "./components/styledComponents";

const columnDefs: ColDef[] = [
  {
    headerName: "Source",
    field: "source",
    initialWidth: 180,
  },
  {
    headerName: "Identifier Value",
    field: "identifierValue",
    initialWidth: 180,
  },
];

export const ExternalIdentifiersTab: React.FC = () => {
  const { externalIdentifiers = [] } = useWatch<TradeTicketFormValues>({
    name: ["externalIdentifiers"],
  });

  const { onGridReady } = useGridApi((event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
  });

  return (
    <>
      <ControlledEmpty name="externalIdentifiers" />
      <TabContentContainer>
        <FormPanel
          numColumns={1}
          style={{ height: "100%" }}
          contentStyle={{ height: "100%" }}
        >
          <FullHeightContainer>
            <RoundedDataGrid
              height="100%"
              multiSortKey="ctrl"
              suppressContextMenu
              columnDefs={columnDefs}
              onGridReady={onGridReady}
              rowData={externalIdentifiers}
              defaultColDef={{
                sortable: true,
                resizable: true,
                suppressHeaderMenuButton: true,
                suppressMovable: true,
              }}
            />
          </FullHeightContainer>
        </FormPanel>
      </TabContentContainer>
    </>
  );
};
