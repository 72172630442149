export const getKeywordFromInstrument = (instrument, fieldName) => {
    if (!instrument) {
        return "";
    }
    return (instrument?.keywords?.find((element) => element.name === fieldName)?.value ?? "");
};
export function getNormalizedInstrumentInfo(instrument) {
    const currency = instrument?.currency ??
        instrument?.contract?.currency ??
        instrument?.contractCurrency;
    const exchange = instrument?.exchange ?? instrument?.contract?.exchange;
    const exchangeName = exchange?.shortName || exchange?.name || null;
    const tickSize = instrument?.tickSize ?? instrument?.contract?.tickSize;
    const option = instrument?.financialSubType?.parent.endsWith("Option") ?? false;
    const ticker = getKeywordFromInstrument(instrument, "BBYELLOW") ||
        getKeywordFromInstrument(instrument, "TICKER");
    return { currency, exchange, tickSize, option, ticker, exchangeName };
}
