import CurrencySelect from "@app-views/reports/reportSettings/generalLedgerAccounting/glDistributionLine/CurrencySelect";
import {
  COMMISSION_OPTIONS,
  FEE_OPTIONS,
  isAutoCalculated,
  isForceImpliedInPrice,
} from "@enfusion-ui/trades";
import {
  ControlledNumericInput,
  FormPanel,
  PanelRow,
} from "@enfusion-ui/web-components";
import { kebabCase } from "lodash";

import { FeeCommissionControl } from "./CostPanel/components/FeeCommissionControl";

export const PaymentsPanel: React.FC<{ title?: string }> = ({
  title = "Payments",
}) => {
  return (
    <FormPanel
      title={title}
      numColumns={1}
      collapsible
      defaultOpen
      keepRendered
      lineStyle
      tabFocusOutline={false}
      dataE2EId={kebabCase(title + "-panel")}
    >
      <PanelRow>
        <CurrencySelect name="settleCCY" label="Settle CCY" />
        <ControlledNumericInput
          name="settleCCY"
          label="FX Rate"
          fractionDigits={2}
        />
      </PanelRow>

      <FeeCommissionControl
        label="Commission(s)"
        name="commissions"
        typeOptions={COMMISSION_OPTIONS}
        isAutoCalculated={isAutoCalculated}
        isForceImpliedInPrice={isForceImpliedInPrice}
      />
      <FeeCommissionControl
        label="Fee(s)"
        name="fees"
        typeOptions={FEE_OPTIONS}
        isAutoCalculated={isAutoCalculated}
        isForceImpliedInPrice={isForceImpliedInPrice}
      />
    </FormPanel>
  );
};
