import { WidgetComponentsDefinition } from "../../types";
import PdfTextWidget from "./PdfTextWidget";
import TextEditWidget from "./TextEditWidget";
import TextWidget from "./TextWidget";

export const text: WidgetComponentsDefinition = {
  renderComponent: TextWidget,
  editComponent: TextEditWidget,
  renderPdfComponent: PdfTextWidget,
};

export const label: WidgetComponentsDefinition = {
  renderComponent: TextWidget,
  editComponent: TextEditWidget,
};
