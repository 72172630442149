import { IndexedDBPersistence } from "@enfusion-ui/web-core";

export type ReportModelKey = {
  reportId: string;
  username: string;
};

export type ReportModel = ReportModelKey & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  report: any;
};

const indexedDBPersistence = new IndexedDBPersistence<ReportModel>("reports");

class ReportsDAO {
  public addReport = async (reportData: ReportModel) => {
    await indexedDBPersistence.upsert(reportData);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fetchReportsByUser = async (username: string): Promise<any[]> => {
    return (
      await indexedDBPersistence.fetchWithFilter(
        (x: ReportModel) => x.username === username
      )
    ).map((eachModel: ReportModel) => eachModel.report);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fetchReportByKey = async (reportId: string): Promise<any[]> => {
    const reportData = await indexedDBPersistence.fetchOne({
      reportId: reportId,
    });
    return reportData?.report ?? undefined;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fetchUniqueReport = async (key: ReportModelKey): Promise<any> => {
    const reportData = await indexedDBPersistence.fetchOne(key);
    return reportData?.report ?? undefined;
  };

  public deleteReport = async (key: ReportModelKey): Promise<void> => {
    await indexedDBPersistence.deleteByKeys({ ...key });
  };
}

export const reportsDAO = new ReportsDAO();
