import { v4 as uuidv4 } from "uuid";
import { SocketController } from "../utils/socketController";
import { getReportProviderState, setReportProviderState, } from "./reports.module.store";
import { WebWorkerMessenger } from "./types";
const messenger = new WebWorkerMessenger();
const path = "/reports";
const socketController = new SocketController({
    path: `wss://${self.location.host}${path}`,
    onOpen: (socket) => {
        socket.send(JSON.stringify({
            command: "CONNECT",
            "accept-version": "1.2",
            "sub-to": "/report-feed",
        }));
        messenger.broadcast({
            command: "connected",
            payload: { readyState: socket.readyState },
        });
    },
    onMessage: async (event) => {
        const message = JSON.parse(event.data);
        if (message.command === "MESSAGE") {
            const { payload } = message;
            switch (payload.type) {
                case "init-status": {
                    const { tableId, progressSteps } = payload;
                    const reportProviderState = getReportProviderState();
                    const metaStoreEntry = reportProviderState.metaStore[tableId];
                    reportProviderState.metaStore = {
                        ...reportProviderState.metaStore,
                        [tableId]: {
                            ...metaStoreEntry,
                            progressSteps,
                        },
                    };
                    messenger.broadcast({
                        command: "init-status",
                        payload: { metaStore: reportProviderState.metaStore },
                    });
                    break;
                }
                case "table-sync": {
                    const { destination } = message;
                    const { tableId, rows } = payload;
                    let reportProviderState = getReportProviderState();
                    const metaStoreEntry = reportProviderState.metaStore[tableId];
                    const tableDataWithUniqueRowIds = {
                        ...payload,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        rows: rows.map((i, idx) => ({
                            ...i,
                            __row_id: uuidv4(),
                            __row_idx: idx,
                        })),
                    };
                    reportProviderState = {
                        ...reportProviderState,
                        metaStore: {
                            ...reportProviderState.metaStore,
                            [tableId]: metaStoreEntry
                                ? {
                                    ...metaStoreEntry,
                                    loading: false,
                                    error: undefined,
                                    destination,
                                }
                                : metaStoreEntry,
                        },
                        tableData: {
                            ...reportProviderState.tableData,
                            [tableId]: tableDataWithUniqueRowIds,
                        },
                    };
                    setReportProviderState(reportProviderState);
                    messenger.broadcast({
                        command: "table-sync",
                        payload: {
                            metaStore: reportProviderState.metaStore,
                            tableData: reportProviderState.tableData[tableId],
                            reportId: tableId,
                        },
                    });
                    break;
                }
                case "recon-sync": {
                    const { payload, destination } = message;
                    const { reportId, targetOnlyRows, sourceOnlyRows, targetIgnoredRows, sourceIgnoredRows, matchedRows, differenceRows, statistics, } = payload;
                    let reportProviderState = getReportProviderState();
                    const metaStoreEntry = reportProviderState.metaStore[reportId];
                    const tableRowData = {
                        ...payload,
                        targetIgnoredRows,
                        targetOnlyRows,
                        sourceIgnoredRows,
                        sourceOnlyRows,
                        matchedRows,
                        differenceRows,
                        statistics,
                    };
                    reportProviderState = {
                        ...reportProviderState,
                        metaStore: {
                            ...reportProviderState.metaStore,
                            [reportId]: metaStoreEntry
                                ? {
                                    ...metaStoreEntry,
                                    loading: false,
                                    error: undefined,
                                    destination,
                                }
                                : metaStoreEntry,
                        },
                        tableData: {
                            ...reportProviderState.tableData,
                            [reportId]: tableRowData,
                        },
                    };
                    setReportProviderState(reportProviderState);
                    messenger.broadcast({
                        command: "recon-sync",
                        payload: {
                            metaStore: reportProviderState.metaStore,
                            tableData: reportProviderState.tableData[reportId],
                            reportId,
                        },
                    });
                    break;
                }
                case "rows-update": {
                    const { tableId, rowUpdates, totalsRow } = payload;
                    const reportProviderState = getReportProviderState();
                    const metaStoreEntry = reportProviderState.metaStore[tableId];
                    const tableDataEntry = reportProviderState.tableData[tableId];
                    const add = [];
                    if (tableDataEntry) {
                        let newRows = [...tableDataEntry.rows];
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        for (const { reportRow, index, newRow } of rowUpdates) {
                            // A new row gets spliced in at the given index
                            if (newRow) {
                                const newRowData = {
                                    __row_id: uuidv4(),
                                    __row_idx: index,
                                    ...reportRow,
                                };
                                add.push(newRowData);
                                newRows.splice(index, 0, newRowData);
                                continue;
                            }
                            // In existing rows, only given cells are overwritten
                            newRows[index] = {
                                ...newRows[index],
                                ...reportRow,
                            };
                        }
                        newRows = newRows.map((i, idx) => ({ ...i, __row_idx: idx }));
                        reportProviderState.tableData = {
                            ...reportProviderState.tableData,
                            [tableId]: {
                                ...tableDataEntry,
                                rows: newRows,
                                totalsRow: totalsRow || tableDataEntry.totalsRow,
                            },
                        };
                    }
                    reportProviderState.metaStore = {
                        ...reportProviderState.metaStore,
                        [tableId]: metaStoreEntry
                            ? { ...metaStoreEntry, loading: false, error: undefined }
                            : metaStoreEntry,
                    };
                    messenger.broadcast({
                        command: "rows-update",
                        payload: {
                            metaStore: reportProviderState.metaStore,
                            newRows: reportProviderState.tableData[tableId].rows,
                            totalsRow: reportProviderState.tableData[tableId].totalsRow,
                            tableId,
                            add,
                        },
                    });
                    break;
                }
                case "row-delete": {
                    const { tableId } = payload;
                    const reportProviderState = getReportProviderState();
                    const metaStoreEntry = reportProviderState.metaStore[tableId];
                    const tableDataEntry = reportProviderState.tableData[tableId];
                    const { rowIndex } = tableDataEntry;
                    if (tableDataEntry) {
                        let newRows = [...(tableDataEntry.rows || [])];
                        newRows = newRows.map((i, idx) => ({ ...i, __row_idx: idx }));
                        reportProviderState.tableData = {
                            ...reportProviderState.tableData,
                            [tableId]: {
                                ...tableDataEntry,
                                rows: newRows,
                            },
                        };
                    }
                    reportProviderState.metaStore = {
                        ...reportProviderState.metaStore,
                        [tableId]: metaStoreEntry
                            ? { ...metaStoreEntry, loading: false, error: undefined }
                            : metaStoreEntry,
                    };
                    messenger.broadcast({
                        command: "row-delete",
                        payload: {
                            metaStore: reportProviderState.metaStore,
                            newRows: reportProviderState.tableData[tableId].rows,
                            tableId: tableId,
                            rowIndex,
                        },
                    });
                    break;
                }
                case "row-metadata": {
                    const { destination } = message;
                    const { metadata } = payload;
                    messenger.broadcast({
                        command: "row-metadata",
                        payload: {
                            destination,
                            metadata,
                        },
                    });
                    break;
                }
                case "argumentError":
                case "syncError": {
                    const { reportId, message } = payload;
                    const reportProviderState = getReportProviderState();
                    const metaStoreEntry = reportProviderState.metaStore[reportId];
                    reportProviderState.metaStore = {
                        ...reportProviderState.metaStore,
                        [reportId]: { ...metaStoreEntry, loading: false, error: message },
                    };
                    messenger.broadcast({
                        command: "load-report",
                        payload: {
                            metaStore: reportProviderState.metaStore,
                            reportId: reportId,
                            error: message,
                        },
                    });
                    break;
                }
                case "metadataError": {
                    const { destination } = message;
                    const { reportId } = payload; // reportId and message value are switched
                    messenger.broadcast({
                        command: "metadata-error",
                        payload: {
                            destination,
                            message: reportId,
                        },
                    });
                    break;
                }
            }
        }
    },
    onError: (err) => {
        console.error("Reports socket error", err);
        messenger.broadcast({
            command: "socket-error",
            payload: { readyState: socketController.socketStatus },
        });
    },
    onClose: (event) => {
        console.warn("Reports socket close", event);
        messenger.broadcast({
            command: "reconnect",
            payload: { readyState: socketController.socketStatus },
        });
    },
    onDisconnect: (socket) => {
        console.warn("Reports socket disconnect");
        socket.send(JSON.stringify({ command: "DISCONNECT" }));
        messenger.broadcast({
            command: "disconnected",
            payload: { readyState: socketController.socketStatus },
        });
    },
});
export const ReportsModule = {
    enable: (postMessage) => {
        messenger.send = postMessage;
        socketController.init();
    },
    disable: () => {
        socketController.close("reports");
    },
    getCurrentState: () => {
        const reportProviderState = getReportProviderState();
        return {
            socketStatus: socketController.socketStatus,
            openSubscriptions: reportProviderState.openSubscriptions,
            metaStore: reportProviderState.metaStore,
            tableData: reportProviderState.tableData,
        };
    },
    onTerminate: () => {
        socketController.close();
    },
    onMessage: (data) => {
        const { payload } = data;
        const command = data
            .command;
        const isDiff = payload.diff ?? (payload.path || "").endsWith(".diff");
        const getBasicDest = (id) => `/report-feed/${isDiff ? "reconciliation" : "report"}/${id}`;
        switch (command) {
            case "load-report": {
                const { reportId, name, path, reportQuery, params } = payload;
                const reportProviderState = getReportProviderState();
                // check for params, if params is not null or empty then send params as a query
                const query = Object.keys(params).length === 0
                    ? reportQuery
                    : { ...reportQuery, ...params };
                socketController.send(JSON.stringify({
                    command: "SUBSCRIBE",
                    destination: getBasicDest(reportId),
                    payload: isDiff ? { reportURL: path } : { reportQuery: query },
                }));
                const currentState = reportProviderState.metaStore[reportId] || {};
                const entry = {
                    ...currentState,
                    loading: true,
                    error: undefined,
                    progressSteps: undefined,
                    id: reportId,
                    name,
                    path,
                    reportQuery: query,
                    breadcrumbs: [
                        {
                            label: null,
                            tableId: reportId,
                            parentTableId: null,
                            siblings: [],
                            row: "",
                        },
                    ],
                };
                setReportProviderState({
                    ...reportProviderState,
                    openSubscriptions: {
                        ...reportProviderState.openSubscriptions,
                        [reportId]: JSON.stringify(payload),
                    },
                    metaStore: {
                        ...reportProviderState.metaStore,
                        [reportId]: entry,
                    },
                });
                messenger.broadcast({
                    command: "update-open-subscriptions",
                    payload: {
                        openSubscriptions: {
                            [reportId]: reportProviderState.openSubscriptions[reportId],
                        },
                    },
                });
                break;
            }
            case "subscribe-row-metadata": {
                const { destination } = payload;
                socketController.send(JSON.stringify({
                    command: "SEND",
                    id: destination,
                    destination,
                }));
                break;
            }
            case "unsubscribe": {
                const { reportIdToClose } = payload;
                socketController.send(JSON.stringify({
                    command: "UNSUBSCRIBE",
                    id: getBasicDest(reportIdToClose),
                    destination: getBasicDest(reportIdToClose),
                }));
                break;
            }
            case "add-breadcrumb": {
                const { reportId, entry, replace = false } = payload;
                const reportProviderState = getReportProviderState();
                socketController.send(JSON.stringify({
                    command: "SEND",
                    destination: payload.destination,
                    payload: payload.params,
                }));
                const storeEntry = reportProviderState.metaStore[reportId];
                if (storeEntry) {
                    if (replace !== false) {
                        storeEntry.breadcrumbs = [
                            ...storeEntry.breadcrumbs.slice(0, replace),
                            entry,
                        ];
                    }
                    else {
                        storeEntry.breadcrumbs.push(entry);
                    }
                    reportProviderState.metaStore = {
                        ...reportProviderState.metaStore,
                        [reportId]: storeEntry,
                    };
                }
                messenger.broadcast({
                    command: "load-report",
                    payload: {
                        metaStore: reportProviderState.metaStore,
                    },
                });
                break;
            }
            case "select-breadcrumb": {
                const { reportId, tableId } = payload;
                const reportProviderState = getReportProviderState();
                const storeEntry = reportProviderState.metaStore[reportId];
                if (storeEntry) {
                    const selectedIndex = storeEntry.breadcrumbs.findIndex((b) => b.tableId === tableId);
                    storeEntry.breadcrumbs = storeEntry.breadcrumbs.slice(0, selectedIndex + 1);
                    reportProviderState.metaStore = {
                        ...reportProviderState.metaStore,
                        [reportId]: storeEntry,
                    };
                    messenger.broadcast({
                        command: "load-report",
                        payload: {
                            metaStore: reportProviderState.metaStore,
                        },
                    });
                }
                break;
            }
            case "close-report": {
                const { reportIdToClose } = payload;
                const reportProviderState = getReportProviderState();
                const newOpenSubscriptions = {
                    ...reportProviderState.openSubscriptions,
                };
                if (newOpenSubscriptions) {
                    delete newOpenSubscriptions[reportIdToClose];
                    setReportProviderState({
                        ...reportProviderState,
                        openSubscriptions: newOpenSubscriptions,
                    });
                }
                break;
            }
            case "sync-error": {
                const { reportId, message } = payload;
                const reportProviderState = getReportProviderState();
                const metaStoreEntry = reportProviderState.metaStore[reportId];
                reportProviderState.metaStore = {
                    ...reportProviderState.metaStore,
                    [reportId]: { ...metaStoreEntry, loading: false, error: message },
                };
                messenger.broadcast({
                    command: "load-report",
                    payload: {
                        metaStore: reportProviderState.metaStore,
                    },
                });
                break;
            }
            case "save-report": {
                const { reportId, path, name } = payload;
                const reportProviderState = getReportProviderState();
                const metaStoreEntry = reportProviderState.metaStore[reportId];
                reportProviderState.metaStore = {
                    ...reportProviderState.metaStore,
                    [reportId]: {
                        ...metaStoreEntry,
                        path,
                        name,
                        error: undefined,
                        breadcrumbs: [
                            {
                                label: null,
                                tableId: reportId,
                                parentTableId: null,
                                siblings: [],
                                row: "",
                            },
                        ],
                    },
                };
                messenger.broadcast({
                    command: "load-report",
                    payload: {
                        metaStore: reportProviderState.metaStore,
                    },
                });
                break;
            }
            default:
                return;
        }
    },
};
