/* eslint-disable @typescript-eslint/no-explicit-any */
import { convertFromDarkPool, OrderFormValues } from "@enfusion-ui/core";
import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { MultiSelectOptionsType } from "@enfusion-ui/types";
import { FormController, MultiSelect } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export const DarkPoolSelect: React.FC<{ disabled?: boolean }> = ({
  disabled,
}) => {
  const webOrderType = useWatch<string | undefined>({
    name: "webOrderType",
  });
  const { setValue } = useFormContext<OrderFormValues>();
  const isDarkPoolDisabled = webOrderType !== "Undecided";

  React.useEffect(() => {
    if (isDarkPoolDisabled) setValue("darkPools", null);
  }, [isDarkPoolDisabled]);

  const { options: darkPoolOptions, loading: darkPoolsLoading } =
    useRestAbortableOptions(REST_API.OEMS.GET_DARK_POOLS.FETCH, (value) => ({
      value,
      label: value.description,
    }));

  return (
    <FormController
      name="darkPools"
      render={({ ref: _ref, value, onChange, ...rest }) => (
        <MultiSelect
          {...rest}
          label="Dark Pools"
          value={convertFromDarkPool(value)}
          options={darkPoolOptions}
          loading={darkPoolsLoading}
          onChange={(selectedOption) => {
            const darkPools = selectedOption?.map(
              (option: MultiSelectOptionsType) => option.value
            );
            onChange?.(darkPools);
          }}
          getOptionValue={(option: any) => option.value.name}
          disabled={disabled || isDarkPoolDisabled}
          inputId="darkpools-multi-selection-id"
        />
      )}
    />
  );
};
