import { WebReportQuerySubType } from "@enfusion-ui/types";

import * as glAccounting from "./generalLedgerAccounting/definitions";
import * as instrumentDefinition from "./instruments/definitions";
import * as othersDefinition from "./others/definitions";
import * as partnershipAccounting from "./partnershipAccounting/definitions";
import * as positionDefinition from "./positions/definitions";
import * as priceDefinition from "./prices/definitions";
import * as tradesAndOrders from "./tradesAndOrders/definitions";
import { ReportSettingsDefinition } from "./types";

export const REPORT_SETTINGS_COMPONENT_DEFINITIONS = {
  ...glAccounting,
  ...instrumentDefinition,
  ...othersDefinition,
  ...positionDefinition,
  ...priceDefinition,
  ...tradesAndOrders,
  ...partnershipAccounting,
} as Record<WebReportQuerySubType, ReportSettingsDefinition>;
