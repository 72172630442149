import { useOEMSOrderForm } from "@enfusion-ui/core";
import { ConfirmationModal } from "@enfusion-ui/web-components";
import { errorToast, styled } from "@enfusion-ui/web-core";
import * as React from "react";

const WarningMessage = styled.div`
  max-width: 48rem;
  margin: var(--spacing) 0;
`;

export const WarningConfirmation: React.FC<{
  warningRecords: string[];
  onOrderSubmissionWithWarnings?: () => void;
  shouldTransmit: React.MutableRefObject<boolean>;
}> = ({ warningRecords, onOrderSubmissionWithWarnings, shouldTransmit }) => {
  const { submitOrder, getCurrentOrder, setWarningRecords } =
    useOEMSOrderForm();

  const doSubmit = async () => {
    const currentOrder = getCurrentOrder();
    try {
      await submitOrder(
        {
          ...currentOrder,
        },
        shouldTransmit.current,
        false,
        true
      );
    } catch (err: any) {
      errorToast("Failed to stage order:", err?.message);
    }
    onOrderSubmissionWithWarnings?.();
  };

  return (
    <ConfirmationModal
      title="Warning"
      open={!!warningRecords?.length}
      onCancel={() => setWarningRecords?.(null)}
      onSubmit={doSubmit}
      submitActionTheme="primary"
      submitButtonText={
        shouldTransmit.current ? "Transmit Anyway" : "Stage Order"
      }
    >
      {warningRecords.map((record, idx) => (
        <WarningMessage key={idx}>{record}</WarningMessage>
      ))}
    </ConfirmationModal>
  );
};
