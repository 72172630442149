import { ConnectionStatus } from "@enfusion-ui/types";
import {
  APIConnectedIcon,
  APIDisconnectedIcon,
  BloombergBIcon,
  FactSetIcon,
  Portal,
} from "@enfusion-ui/web-components";
import { styled, useAuth, useTheme } from "@enfusion-ui/web-core";
import { faTable } from "@fortawesome/pro-regular-svg-icons";
import {
  faDollarSign,
  faSortAmountDown,
} from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";

import { useConnectionStatus } from "../../context/connectionStatus/context";
import { ConnectionStatusIcon } from "./components/ConnectionStatusIcon";
import { ConnectionStatusPopover } from "./components/ConnectionStatusPopover";
import { getColor } from "./utils/getIconColor";

const StatusPopover = styled.div`
  padding: 1rem;
  background-color: var(--background-accent);
  border-radius: var(--radius);
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));

  :before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border: 5px solid transparent;
    border-top: 0;
    border-bottom: 10px solid var(--background-accent);
    top: -10px;
    left: 15px;
  }
`;

const ConnectionStatusIcons = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing);
  margin-right: var(--spacing);
`;

const ConnectionStatusView = () => {
  const { isEnabled } = useAuth();
  const { theme } = useTheme();
  const {
    apiStatus,
    marketDataConnectionStatus,
    oemsConnectionStatus,
    factsetConnectionStatus,
    bloombergConnectionStatus,
    reportSocketConnectionStatus,
  } = useConnectionStatus();

  const ref = React.useRef<HTMLDivElement>(null);
  const [detailsOpen, setDetailsOpen] = React.useState(false);

  React.useEffect(() => {
    if (ref.current) {
      ref.current.onmouseenter = () => {
        setDetailsOpen(true);
      };
      ref.current.onmouseleave = () => {
        setDetailsOpen(false);
      };
    }
  }, [ref]);

  const APIConnectionIcon =
    apiStatus === ConnectionStatus.CONNECTED
      ? APIConnectedIcon
      : APIDisconnectedIcon;

  return (
    <div style={{ display: "block" }} ref={ref}>
      <ConnectionStatusIcons>
        <ConnectionStatusIcon
          svg={
            <APIConnectionIcon size={12} color={getColor(apiStatus, theme)} />
          }
          dataE2EId="connection-status-icon-api"
        />
        <ConnectionStatusIcon
          icon={faTable}
          status={reportSocketConnectionStatus}
          dataE2EId="connection-status-icon-reports"
        />
        {isEnabled("OMS") && (
          <ConnectionStatusIcon
            icon={faDollarSign}
            status={marketDataConnectionStatus}
            dataE2EId="connection-status-icon-market-data"
          />
        )}
        {isEnabled("OMS") && (
          <ConnectionStatusIcon
            icon={faSortAmountDown}
            status={oemsConnectionStatus}
            dataE2EId="connection-status-icon-oems"
          />
        )}
        {factsetConnectionStatus !== ConnectionStatus.DISABLED && (
          <ConnectionStatusIcon
            svg={
              <FactSetIcon
                size={15}
                color={getColor(factsetConnectionStatus, theme)}
              />
            }
            status={factsetConnectionStatus}
            dataE2EId="connection-status-icon-factset"
          />
        )}
        {bloombergConnectionStatus !== ConnectionStatus.DISABLED && (
          <ConnectionStatusIcon
            svg={
              <BloombergBIcon
                color={getColor(bloombergConnectionStatus, theme)}
              />
            }
            status={bloombergConnectionStatus}
            dataE2EId="connection-status-icon-bloomberg"
          />
        )}
      </ConnectionStatusIcons>
      <Portal
        open={detailsOpen}
        attachedRef={ref}
        onClickOutside={() => setDetailsOpen(false)}
        topOffset={10}
        leftOffset={20}
      >
        <StatusPopover>
          <ConnectionStatusPopover />
        </StatusPopover>
      </Portal>
    </div>
  );
};

export default ConnectionStatusView;
