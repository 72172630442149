import { TabView } from "@app-components/layout/TabView";
import { useOEMSOrderForm } from "@enfusion-ui/core";
import * as React from "react";

import RoutesAndFillsList from "../../components/blotters/RoutesAndFillsList";
import { ComplianceTable } from "../components/ComplianceTable";
import { DetailsTab } from "./components/DetailsTab";
import { GeneralTab } from "./components/GeneralTab";
import { OrderKeywordsTab } from "./components/OrderKeywordsTab";

const FullTicketCore: React.FC<{
  autoFocus: string;
  onChange?: (activeKey: string) => void;
}> = ({ autoFocus, onChange }) => {
  const { orderId, newOrder, splitOrder } = useOEMSOrderForm();
  const options = React.useMemo(() => {
    let tabOptions = [
      {
        label: "General",
        key: "general",
        contentComponent: GeneralTab,
      },
      {
        label: "Order Details",
        key: "details",
        contentComponent: DetailsTab,
      },
    ];

    if (!newOrder && !splitOrder) {
      tabOptions = [
        ...tabOptions,
        {
          label: "Compliance Records",
          key: "complianceRecords",
          // eslint-disable-next-line react/display-name
          contentComponent: () => (
            <div
              style={{
                height: "100%",
                width: "100%",
                paddingLeft: "var(--spacing)",
                paddingRight: "var(--spacing)",
              }}
            >
              <ComplianceTable orderId={orderId} />
            </div>
          ),
        },
        {
          label: "Fills",
          key: "fills",
          contentComponent: () => (
            <RoutesAndFillsList allowRefresh="inline" padding />
          ),
        },
        {
          label: "Keywords",
          key: "keywords",
          contentComponent: () => <OrderKeywordsTab />,
        },
      ];
    }
    return tabOptions;
  }, [orderId, newOrder]);

  return (
    <TabView
      defaultRendered
      items={options}
      defaultOpen={autoFocus}
      onChange={onChange}
    />
  );
};

export const FullTicket = React.memo(FullTicketCore);
