import GripHandle from "@app-components/control/GripHandle";
import { useAppTutorial } from "@app-components/Tour/context";
import { TutorialType } from "@app-components/Tour/utils";
import { usePortfolio } from "@app-context/portfolios/PortfolioProvider";
import { useModalState, useRefCallback } from "@enfusion-ui/hooks";
import {
  PortfolioProposedOrderEditFields,
  PROPOSED_ORDERS_SUBMIT_CONFIRMATION,
} from "@enfusion-ui/portfolios";
import {
  Button,
  ButtonProps,
  TertiaryTabList,
  useConfirmationModal,
  VerticallyResizable,
} from "@enfusion-ui/web-components";
import {
  styled,
  useIsContentUnderWidth,
  useThisTab,
} from "@enfusion-ui/web-core";
import {
  faLayerGroupMinus,
  faMaximize,
  faPencilAlt,
  faSave,
  faTasks,
} from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";

import BulkEditOrderModal from "./BulkEditOrderModal";
import { ActionButtonLoader, TabButtonLoader } from "./Loaders";
import ProposedAllocationGrid from "./ProposedAllocationGrid";
import ProposedOrderGrid from "./ProposedOrderGrid";

const PortfolioDetailsWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-top: var(--spacing-l);
  background-color: var(--background-color-1-hover);
  display: flex;
  flex-direction: column;
`;

const PortfolioDetailsContentWrapper = styled.div`
  overflow-y: hidden;
  flex-grow: 1;
  padding: var(--spacing);
  padding-top: 0px;
`;

const PortfolioDetailsButtonWrapper = styled.div`
  margin-bottom: var(--spacing);
  padding: var(--spacing) var(--spacing-l);
  display: flex;
  align-items: center;
  gap: var(--spacing);
`;

const TopRightButtonCore = styled(Button)`
  flex-basis: 240px;
  white-space: nowrap;

  :disabled {
    cursor: not-allowed !important;
    opacity: 0.7;

    :hover {
      opacity: 0.7;
    }
  }
`;

const TopRightButton = ({
  title,
  isSmall,
  ...rest
}: Omit<ButtonProps, "title"> & { title: string; isSmall: boolean }) => {
  return (
    <TopRightButtonCore {...rest} title={title}>
      {isSmall ? "" : title}
    </TopRightButtonCore>
  );
};

type PortfolioDetailsProps = {};

const portfolioDetailsTabs = [
  {
    label: "Proposed Orders",
    key: "proposed_orders",
  },
  {
    label: "Proposed Allocations",
    key: "proposed_allocations",
  },
];

const defaultDrawerHeight = 40;
const raisedDrawerHeight = 250;

const PortfolioDetails: React.FC<PortfolioDetailsProps> = () => {
  const editModalState = useModalState();
  const [selectedTab, setSelectedTab] = React.useState<string | undefined>(
    "proposed_orders"
  );

  const { tutorialType, step } = useAppTutorial();

  const { width } = useThisTab();
  const {
    canCreateOrders,
    submitProposedOrders,
    hasProposedOrders,
    testCompliance,
    selectedOrders,
    resetSelectedOrders,
    bulkUpdateProposedOrderData,
    syncReceived,
    removeAndDownsize,
    removeAndRedistribute,
    isTestingCompliance,
    isSubmitting,
    hasRowUpdates,
    isModifyingProposedOrders,
  } = usePortfolio();

  const handleModifyProposedOrder = useRefCallback(
    (fields: Partial<PortfolioProposedOrderEditFields>) => {
      bulkUpdateProposedOrderData(fields);
      editModalState.closeModal();
    },
    [editModalState.closeModal, bulkUpdateProposedOrderData]
  );

  const ProposedOrdersConfirmation = useConfirmationModal({
    submitActionTheme: "primary",
    onSubmit: () => submitProposedOrders(),
    title: PROPOSED_ORDERS_SUBMIT_CONFIRMATION.title,
    submitButtonText: PROPOSED_ORDERS_SUBMIT_CONFIRMATION.buttonText,
    renderContent: () => PROPOSED_ORDERS_SUBMIT_CONFIRMATION.message,
  });

  const handleRemoveAndDownsize = useRefCallback(() => {
    removeAndDownsize(
      selectedOrders.map((i) => ({
        orderId: i.data?.orderId,
        id: i.data?.id,
      }))
    );
  }, [selectedOrders, removeAndDownsize]);

  const handleRemoveAndRedistribute = useRefCallback(() => {
    removeAndRedistribute(
      selectedOrders.map((i) => ({
        orderId: i.data?.orderId,
        id: i.data?.id,
      }))
    );
  }, [selectedOrders, removeAndRedistribute]);

  const handleTabSelection = useRefCallback(
    (val) => {
      setSelectedTab(val);
      resetSelectedOrders();
    },
    [setSelectedTab, resetSelectedOrders]
  );

  const tabContent = React.useMemo(() => {
    if (selectedTab === "proposed_allocations")
      return <ProposedAllocationGrid />;
    return <ProposedOrderGrid openEditModal={editModalState.openModal} />;
  }, [selectedTab, editModalState.openModal]);

  const isSmall = useIsContentUnderWidth(
    selectedTab === "proposed_orders" ? 730 : 950
  );

  const [drawerHeight, setDrawerHeight] = React.useState(defaultDrawerHeight);

  React.useEffect(() => {
    if (tutorialType === TutorialType.Portfolio) {
      if (step?.openDrawer) {
        setDrawerHeight(raisedDrawerHeight);
      } else if (step?.closeDrawer) {
        setDrawerHeight(defaultDrawerHeight);
      }
    }
  }, [tutorialType, step]);

  return (
    <>
      <VerticallyResizable
        open
        height={drawerHeight}
        minHeight={40}
        width={width}
        handle={<GripHandle />}
        dataE2EId="portfolio-workbench-details"
      >
        <PortfolioDetailsWrapper data-e2e-id={selectedTab}>
          <PortfolioDetailsButtonWrapper>
            {syncReceived ? (
              <TertiaryTabList
                value={selectedTab}
                scrollToView={false}
                tabs={portfolioDetailsTabs}
                onSelect={handleTabSelection}
                disabled={hasRowUpdates}
              />
            ) : (
              <div
                style={{
                  flex: 1,
                  width: "100%",
                  overflow: "hidden",
                  height: 23,
                }}
              >
                <TabButtonLoader />
              </div>
            )}
            {syncReceived ? (
              <>
                {selectedTab === "proposed_orders" ? (
                  <TopRightButton
                    data-e2e-id="modify-selected-orders"
                    icon={faPencilAlt}
                    iconPosition="left"
                    disabled={
                      isSubmitting ||
                      selectedOrders.length < 1 ||
                      isModifyingProposedOrders
                    }
                    onClick={editModalState.openModal}
                    title="Modify Selected"
                    isSmall={isSmall}
                  />
                ) : (
                  <>
                    <TopRightButton
                      icon={faLayerGroupMinus}
                      iconPosition="left"
                      disabled={
                        isSubmitting ||
                        selectedOrders.length < 1 ||
                        isModifyingProposedOrders
                      }
                      onClick={handleRemoveAndDownsize}
                      title="Remove & Downsize"
                      isSmall={isSmall}
                    />
                    <TopRightButton
                      icon={faMaximize}
                      iconPosition="left"
                      disabled={
                        isSubmitting ||
                        selectedOrders.length < 1 ||
                        isModifyingProposedOrders
                      }
                      onClick={handleRemoveAndRedistribute}
                      title="Remove & Redistribute"
                      isSmall={isSmall}
                    />
                  </>
                )}
                <TopRightButton
                  icon={faTasks}
                  iconPosition="left"
                  onClick={testCompliance}
                  busy={isTestingCompliance}
                  disabled={
                    isSubmitting ||
                    !hasProposedOrders ||
                    hasRowUpdates ||
                    isModifyingProposedOrders
                  }
                  title="Test Compliance"
                  isSmall={isSmall}
                  data-e2d-id="test-compliance-button"
                />
                {canCreateOrders && (
                  <TopRightButton
                    icon={faSave}
                    iconPosition="left"
                    disabled={
                      !hasProposedOrders ||
                      hasRowUpdates ||
                      isModifyingProposedOrders
                    }
                    busy={isSubmitting}
                    onClick={ProposedOrdersConfirmation.openModal}
                    title="Create Orders"
                    isSmall={isSmall}
                    data-e2d-id="create-orders-button"
                  />
                )}
              </>
            ) : (
              <ActionButtonLoader />
            )}
          </PortfolioDetailsButtonWrapper>
          <PortfolioDetailsContentWrapper>
            {tabContent}
          </PortfolioDetailsContentWrapper>
        </PortfolioDetailsWrapper>
      </VerticallyResizable>
      {ProposedOrdersConfirmation.content}
      <BulkEditOrderModal
        open={editModalState.open}
        onClose={editModalState.closeModal}
        onSave={handleModifyProposedOrder}
      />
    </>
  );
};

export default PortfolioDetails;
