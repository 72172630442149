import { AnalyticsFormValues } from "@app-context/analytics/AnalyticsContextProvider";
import { SizedChart } from "@app-views/analytics/components/SizedChart";
import {
  DropdownOption,
  TextButtonDropdown,
} from "@app-views/analytics/components/TextButtonDropdown";
import { PerformanceDetailsInstrumentValue } from "@app-views/analytics/types";
import { formatterCreator } from "@app-views/analytics/utils";
import { ValueOf } from "@enfusion-ui/types";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "@enfusion-ui/utils";
import {
  CenterContent,
  TabListTab,
  TertiaryTabList,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { getYear, sub } from "date-fns";
import { SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as React from "react";
import { useWatch } from "react-hook-form";

import {
  defaultChartOptions,
  defaultTooltipOptions,
} from "../../../chartConfigs";

function getExtremes(key: ExtremeKey, endDate?: Date | null) {
  const end = endDate ? endDate.valueOf() : Date.now();
  switch (key) {
    case "all":
      return [undefined, end];
    case "year":
      return [sub(end, { years: 1 }).valueOf(), end];
    case "6months":
      return [sub(end, { months: 6 }).valueOf(), end];
    case "3months":
      return [sub(end, { months: 3 }).valueOf(), end];
    case "month":
      return [sub(end, { months: 1 }).valueOf(), end];
    case "ytd":
      /* TODO: check the requirements for the case when end date is past year*/
      return [new Date(getYear(end), 0, 1).valueOf(), end];
    default:
      throw new Error("Invalid extreme key");
  }
}

const CHART_HEIGHT = 570;

const ChartContainer = styled.div`
  position: relative;
  height: ${CHART_HEIGHT}px;
`;

const MessageContainer = styled.div`
  color: var(--text-muted);
  padding: var(--spacing-xl);
  border-radius: var(--radius);
  border: 1px solid var(--background-color-1);
  white-space: nowrap;
`;

const ControlPanelTop = styled.div`
  padding: var(--spacing) var(--spacing-l);
`;

const ControlPanelBottom = styled.div`
  border-top: 1px solid var(--background-color-1);
  padding: var(--spacing) 0 var(--spacing) var(--spacing-l);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-l);
`;

const TabKeys = { "P&L": "PNL", Returns: "Returns" } as const;
type TabKey = ValueOf<typeof TabKeys>;
const tabs: TabListTab[] = Object.entries(TabKeys).map(([label, key]) => ({
  label,
  key,
}));

const ExtremeKeys = {
  "1M": "month",
  "3M": "3months",
  "6M": "6months",
  "1Y": "year",
  YTD: "ytd",
  ALL: "all",
} as const;
type ExtremeKey = ValueOf<typeof ExtremeKeys>;
const extremes: TabListTab[] = Object.entries(ExtremeKeys).map(
  ([label, key]) => ({ label, key })
);

const DropdownValues = {
  "Asset Class": "AssetClass",
  Industry: "Industry",
  Country: "Country",
  Fund: "Fund",
  "Instrument Type": "InstrumentType",
  "Instrument Subtype": "InstrumentSubtype",
  Issuer: "Issuer",
  "Market Cap": "MarketCap",
  Region: "Region",
  Sector: "Sector",
  Direction: "Direction",
  Factor: "Factor",
  Liquidity: "Liquidity",
  Exposure: "Exposure",
} as const;
type DropdownValue = ValueOf<typeof DropdownValues>;
const dropdownOptions: DropdownOption<DropdownValue>[] = Object.entries(
  DropdownValues
).map(([label, value]) => ({
  label,
  value,
}));

type Props = {
  selectedIds: Set<number>;
  instrumentValues: PerformanceDetailsInstrumentValue[];
  getInstrumentName: (id: string | number) => string | undefined;
};

export const PerformanceDetailsLeftSection: React.FC<Props> = React.memo(
  ({ selectedIds, instrumentValues, getInstrumentName }) => {
    const ref = React.useRef<HighchartsReact.RefObject>(null);
    const [selectedKey, setSelectedKey] = React.useState<TabKey>("PNL");
    const [dropdownValue, setDropdownValue] =
      React.useState<DropdownValue>("Fund");
    const [selectedPeriod, setSelectedPeriod] = React.useState<
      ExtremeKey | undefined
    >("all");

    const endDate = useWatch<AnalyticsFormValues["endDate"]>({
      name: "endDate",
    });

    React.useEffect(() => {
      if (!selectedPeriod) return;
      ref.current?.chart?.xAxis[0].setExtremes(
        ...getExtremes(selectedPeriod, endDate)
      );
    }, [selectedPeriod]);

    const getOptions = React.useCallback(
      (width: number) => {
        const returnsSeries: SeriesOptionsType[] = instrumentValues.map(
          ({ id, dataPnL, dataReturns, currency }) => ({
            yAxis: 2,
            type: "spline",
            name: getInstrumentName(id),
            // NOTE: have to pass copies to avoid overwriting source data between switching
            data: selectedKey === "PNL" ? [...dataPnL] : [...dataReturns],
            custom: { currency },
          })
        );

        const options: Highcharts.Options = {
          chart: {
            ...defaultChartOptions,
            width: width ?? 1,
            height: CHART_HEIGHT,
            zooming: { type: "x" },
            events: {
              load: () => {
                setSelectedPeriod("all");
              },
              selection: (e) => {
                const isManualZoom = !e.resetSelection;
                if (isManualZoom) setSelectedPeriod(undefined);
                return true;
              },
            },
          },
          title: { text: "" },
          credits: { enabled: false },
          legend: { enabled: false },
          tooltip: {
            ...defaultTooltipOptions,
            shared: true,
            useHTML: true,
            xDateFormat: "%m/%d/%Y",
            headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
            footerFormat: "</table>",
            pointFormatter: formatterCreator<Highcharts.Point>(
              ({ y, color, series }) => {
                const yVal = y ?? 0;
                const valueColor = `var(--${
                  yVal > 0 ? "success" : yVal < 0 ? "danger" : "text-muted"
                })`;
                const currency = series.options.custom?.currency;
                const formattedValue =
                  selectedKey === "PNL"
                    ? formatCurrency(yVal, currency, {
                        fractionDigits: 0,
                        formatNegative: false,
                      })
                    : formatPercentage(yVal, [0, 2]);
                return `<tr>
            <td style="color:${color}; padding-top: 3px;">&#9210</td>
            <td style="max-width:120px; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;">${series.name}</td>
            <td style="text-align:right; color:${valueColor};"><b>${formattedValue}</b></td>
          </tr>`;
              }
            ),
          },
          yAxis: [
            {
              top: "10px",
              height: "190px",
              title: {
                text: "Revenue (millions)",
              },
              lineWidth: 1,
              gridLineColor: "var(--background-accent)",
            },
            {
              top: "230px",
              height: "75px",
              max: 25,
              min: 0,
              minPadding: 0,
              maxPadding: 0,
              startOnTick: false,
              endOnTick: false,
              offset: 0,
              title: {
                text: "Profit margin",
              },
              labels: {
                format: "{text}%",
                style: { color: "var(--text-normal)" },
              },
              gridLineColor: "var(--background-accent)",
              plotBands: [
                {
                  from: 0,
                  to: 25,
                  color: "var(--background-color-1-hover)",
                },
              ],
              lineWidth: 1,
            },
            {
              top: "335px",
              height: "190px",
              offset: 0,
              title: { text: selectedKey === "PNL" ? "P&L" : "Returns" },
              labels: {
                formatter:
                  formatterCreator<Highcharts.AxisLabelsFormatterContextObject>(
                    ({ value }) => {
                      const numValue =
                        typeof value === "string" ? parseInt(value) : value;
                      return selectedKey === "PNL"
                        ? formatNumber(numValue, {
                            fractionDigits: 0,
                            formatNegative: false,
                            reduce: true,
                          })
                        : formatPercentage(numValue, 0);
                    }
                  ),
              },
              lineWidth: 1,
              gridLineColor: "var(--background-accent)",
            },
          ],
          xAxis: {
            minPadding: 0.04,
            maxPadding: 0.04,
            type: "datetime",
            dateTimeLabelFormats: {
              day: "%b %e",
              week: "%b %e",
              month: "%y/%m",
              year: "%Y",
            },
          },
          plotOptions: {
            series: {
              states: {
                inactive: {
                  enabled: false,
                },
              },
              label: {
                style: { color: "var(--text-normal)" },
              },
            },
            column: {
              borderWidth: 0,
              borderRadius: 5,
            },
          },
          series: [...returnsSeries],
        };

        return options;
      },
      [instrumentValues, getInstrumentName, selectedKey]
    );

    return (
      <>
        <ControlPanelTop>
          <TertiaryTabList
            tabs={tabs}
            value={selectedKey}
            scrollToView={false}
            onSelect={(key) => setSelectedKey(key as TabKey)}
          />
        </ControlPanelTop>

        <ControlPanelBottom>
          <TextButtonDropdown
            value={dropdownValue}
            options={dropdownOptions}
            label="Ending Period P&L by"
            onChange={(val) => setDropdownValue(val as DropdownValue)}
          />
          <div>
            <TertiaryTabList
              tabs={extremes}
              scrollToView={false}
              value={selectedPeriod}
              disabled={selectedIds.size === 0}
              onSelect={(key) => setSelectedPeriod(key as ExtremeKey)}
            />
          </div>
        </ControlPanelBottom>

        <ChartContainer>
          {!selectedIds.size ? (
            <CenterContent>
              <MessageContainer>
                Select Instrument(s) from right panel.
              </MessageContainer>
            </CenterContent>
          ) : (
            <SizedChart ref={ref} getOptions={getOptions} />
          )}
        </ChartContainer>
      </>
    );
  }
);
