import { useGDPR } from "@app-context/gdpr/context";
import { useNPS } from "@app-utils/useNPS";
import { AUTH_EVENT, authEventMessage } from "@enfusion-ui/core";
import { createTestId } from "@enfusion-ui/utils";
import {
  NavBar,
  NavBarProps,
  NavBarSettingsBottomItem,
} from "@enfusion-ui/web-components";
import { AUTH_CHANNEL, styled, useAuth, useTabs } from "@enfusion-ui/web-core";
import {
  faCog,
  faCookie,
  faNote,
  faSignOutAlt,
  faUserCog,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu, { MenuItem } from "rc-menu";
import * as React from "react";

import { contentTabs } from "../../app/AuthenticatedApp";
import ZendeskLauncher from "../control/ZendeskLauncher";
import ChangePasswordModal from "../modal/ChangePasswordModal";
import GDPRModals from "../modal/GDPRModals";
import NPSSurveyModal from "../modal/NPSSurveyModal";
import NPSSurveyIcon from "../NPSSurveyIcon";

export type NPSOpenInfoType = {
  lastOpenTime: Date;
  redirectTimes: number;
};

const MenuItemTitle = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-gap: var(--spacing);
  align-items: center;
  background-color: transparent;
`;

const WebAppNavBar: React.FC<NavBarProps> = ({ horizontal = false, style }) => {
  const { openTab } = useTabs();
  const { user } = useAuth();
  const {
    acceptAllCookiesModalOpen,
    settingCookiesModalOpen,
    toggleSettingCookiesModalOpen,
  } = useGDPR();

  const [changePasswordModalOpen, setChangePasswordModalOpen] =
    React.useState(false);

  const { openNPS } = useNPS();

  const toggleChangePasswordModalOpen = () =>
    setChangePasswordModalOpen((state) => !state);

  const openSettingsView = () => {
    openTab({
      component: "user-settings",
      unique: "user-settings",
      icon: faCog,
      name: "User Settings",
    });
  };

  const openReleaseNotesView = () => {
    openTab({ ...contentTabs.releaseNotes, focusIfFound: true });
  };

  return (
    <>
      <NavBar
        style={style}
        horizontal={horizontal}
        bottomContent={
          <>
            {!!process.env.REACT_APP_ZENDESK_KEY ? (
              <NavBarSettingsBottomItem horizontal={horizontal}>
                <ZendeskLauncher />
              </NavBarSettingsBottomItem>
            ) : null}
            <NavBarSettingsBottomItem horizontal={horizontal} onClick={openNPS}>
              <NPSSurveyIcon isHighlighted={false} />
            </NavBarSettingsBottomItem>
          </>
        }
        cogMenuContent={
          <Menu
            style={{ marginLeft: "var(--spacing)" }}
            selectedKeys={[]} /* [] used to not allow selected styles */
          >
            <MenuItem onClick={openSettingsView} key="settings">
              <MenuItemTitle data-e2e-id="navbar-config-settings">
                <FontAwesomeIcon icon={faCog} />
                Settings
              </MenuItemTitle>
            </MenuItem>
            {!user?.adminUser ? (
              <MenuItem
                onClick={toggleChangePasswordModalOpen}
                key="change-password"
                data-e2e-id="settings-view-change-password"
                data-testid={createTestId("settings-change-password")}
              >
                <MenuItemTitle>
                  <FontAwesomeIcon icon={faUserCog} />
                  Change Password
                </MenuItemTitle>
              </MenuItem>
            ) : null}
            <MenuItem
              onClick={toggleSettingCookiesModalOpen}
              key="cookie-settings"
            >
              <MenuItemTitle>
                <FontAwesomeIcon icon={faCookie} />
                Cookie Settings
              </MenuItemTitle>
            </MenuItem>
            {!!process.env.REACT_APP_ENABLE_RELEASE_NOTES && (
              <MenuItem onClick={openReleaseNotesView} key="release-notes">
                <MenuItemTitle>
                  <FontAwesomeIcon icon={faNote} />
                  Release Notes
                </MenuItemTitle>
              </MenuItem>
            )}
            <MenuItem
              onClick={() =>
                AUTH_CHANNEL.broadcastMessage(
                  authEventMessage(AUTH_EVENT.LOGOUT, ["User Action - Nav Bar"])
                )
              }
              key="sign-out"
              data-e2e-id="settings-view-sign-out"
            >
              <MenuItemTitle>
                <FontAwesomeIcon icon={faSignOutAlt} />
                Sign Out
              </MenuItemTitle>
            </MenuItem>
          </Menu>
        }
      />
      <ChangePasswordModal
        open={changePasswordModalOpen}
        onCancel={toggleChangePasswordModalOpen}
      />
      <GDPRModals
        acceptAllCookiesModalOpen={acceptAllCookiesModalOpen}
        settingCookiesModalOpen={settingCookiesModalOpen}
      />
      <NPSSurveyModal />
    </>
  );
};

export default WebAppNavBar;
