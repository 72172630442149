/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetContextMenuItemsParams, IRowNode } from "ag-grid-community";

export function contractOrExpandNode(
  node: IRowNode<any> | null,
  shouldExpand: boolean
) {
  if (!node || !node.group) return;
  node.setExpanded(shouldExpand);
}

export function contractOrExpandAllRows(
  gridApi: GetContextMenuItemsParams["api"],
  shouldExpand: boolean
) {
  shouldExpand ? gridApi.expandAll() : gridApi.collapseAll();
}

export function contractOrExpandSubRows(
  node: IRowNode<any> | null,
  shouldExpand: boolean,
  includeSubRows = true
) {
  if (node && node.group) {
    node.childrenAfterFilter?.forEach((entry) => {
      if (entry.group) {
        contractOrExpandNode(entry, shouldExpand);
        if (includeSubRows) contractOrExpandSubRows(entry, shouldExpand);
      }
    });
  }
}

export function contractOrExpandParentRows(
  node: IRowNode<any> | null,
  shouldExpand: boolean
) {
  if (node && node.parent) {
    contractOrExpandNode(node.parent, shouldExpand);
    if (node.parent.parent) {
      contractOrExpandParentRows(node.parent, shouldExpand);
    }
  }
}
