import {
  AnalyticsData,
  AnalyticsPivotData,
  useAnalyticsContext,
} from "@app-context/analytics/context";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  AccordionListView,
  ControlledSelect,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { AnalyticsPivotTable } from "../components/AnalyticsPivotTable";
// import { MOCK_ANALYTICS_PIVOT_DATA } from "../mock-data";
import {
  analyticsPivotsPerformanceOptions,
  analyticsPivotsRiskOptions,
} from "../utils";

const ControlsRow = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-l);
  overflow: auto;
`;

const ControlLabel = styled.div`
  flex: 0;
  font-size: 12px;
  margin-top: 20px;
`;

const ControlInputs = styled.div`
  flex: 1;
  flex-basis: 50%;
  display: flex;
  gap: var(--spacing-l);
  margin-top: -5px;
`;

const AccordionContainer = styled.div``;

// const itemsOld = [
//   {
//     key: "asset-class",
//     title: "Asset Class",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
//   {
//     key: "industry",
//     title: "Industry",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
//   {
//     key: "country",
//     title: "Country",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
//   {
//     key: "instrument-type",
//     title: "Instrument Type",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
//   {
//     key: "instrument-subtype",
//     title: "Instrument Subtype",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
//   {
//     key: "issuer",
//     title: "Issuer",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
//   {
//     key: "market-cap",
//     title: "Market Cap",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
//   {
//     key: "region",
//     title: "Region",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
//   {
//     key: "sector",
//     title: "Sector",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
//   {
//     key: "strategy",
//     title: "Strategy",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
//   {
//     key: "direction",
//     title: "Direction",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
//   {
//     key: "factor",
//     title: "Factor",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
//   {
//     key: "liquidity-category",
//     title: "Liquidity Category",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
//   {
//     key: "exposure-quartile",
//     title: "Exposure Quartile",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
//   {
//     key: "return-quartile",
//     title: "Return Quartile",
//     contentComponent: () => (
//       <AnalyticsPivotTable data={MOCK_ANALYTICS_PIVOT_DATA} />
//     ),
//   },
// ];

const convert = (data: AnalyticsPivotData) => {
  const keys = Object.keys(data);
  let res = [];
  for (const key of keys) {
    res.push({
      key,
      performance: data[key].return,
      risk: data[key].exposure,
    });
  }
  return res;
};

export const AnalyticsPivotsSectionContent: React.FC = () => {
  const { data } = useAnalyticsContext();
  const { pivots, setPivots } = useAnalyticsContext();

  const handleRowPress = useRefCallback(
    (section: keyof AnalyticsData["pivots"], categories: string) => {
      setPivots((d) => {
        const idx = d.findIndex((i) => i.section === section);
        if (idx < 0) return [...d, { section, categories: [categories] }];
        if (d[idx].categories.includes(categories)) {
          d[idx].categories = d[idx].categories.filter((e) => e !== categories);
        } else {
          d[idx].categories = [...d[idx].categories, categories];
        }
        if (d[idx].categories.length === 0)
          d = d.filter((i) => i.section !== section);

        return [...d];
      });
    },
    []
  );

  const items = React.useMemo(() => {
    if (!data) return [];
    const entry = (key: keyof AnalyticsData["pivots"], title: string) => {
      return {
        key,
        title,
        contentComponent: () => (
          <AnalyticsPivotTable
            section={key}
            data={convert(data.pivots[key])}
            selected={pivots.find((i) => i.section === key)?.categories}
            onRowPress={handleRowPress}
          />
        ),
      };
    };
    return [
      entry("assetClass", "Asset Class"),
      entry("gicSector", "Sector"),
      entry("instrumentType", "Instrument Type"),
      entry("issuer", "Issuer"),
      entry("riskCountry", "County"),
    ];
  }, [data?.pivots, pivots]);

  return (
    <>
      <ControlsRow>
        <ControlLabel>Standard Calculations</ControlLabel>
        <ControlInputs>
          <ControlledSelect
            minWidth={110}
            placeholder=""
            name="performance"
            label="Performance"
            isClearable={false}
            controllerStyle={{ alignItems: "center" }}
            options={analyticsPivotsPerformanceOptions}
          />
          <ControlledSelect
            name="risk"
            label="Risk"
            minWidth={110}
            placeholder=""
            isClearable={false}
            options={analyticsPivotsRiskOptions}
            controllerStyle={{ alignItems: "center" }}
          />
        </ControlInputs>
      </ControlsRow>

      <AccordionContainer>
        <AccordionListView
          items={items}
          defaultOpen={{}}
          style={{
            backgroundColor: "var(--background-accent)",
            borderRadius: "var(--radius)",
          }}
          accordionTitleStyle={{
            boxShadow: "none",
            fontWeight: "normal",
            textTransform: "none",
            backgroundColor: "var(--background-accent)",
            borderBottom: "1px solid var(--background-color-0)",
          }}
          contentContainerStyle={{
            padding: "var(--spacing) ",
          }}
        />
      </AccordionContainer>
    </>
  );
};
