/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRefCallback, useRestAbortableOptions } from "@enfusion-ui/hooks";
import { NodeData } from "@enfusion-ui/types";
import { buildPBTree } from "@enfusion-ui/utils";
import { HierarchySelect } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

export const PrimeBrokerSelect: React.FC<{
  disabled?: boolean;
  value?: NodeData[] | null;
  onChange?: (nodes: NodeData[] | null) => void;
}> = ({ disabled, onChange }) => {
  const { options, loading } = useRestAbortableOptions(
    REST_API.OEMS.GET_LOCATE_BROKERS.FETCH,
    (value) => ({ value, label: value.description })
  );

  const textFormatter = useRefCallback(
    (nodes?: NodeData[] | null) => {
      if (
        !nodes ||
        nodes.length === 0 ||
        nodes?.some((node) => node.depth === 0)
      ) {
        return "<ALL>";
      }
      let inputText = "";
      const childNodes = nodes?.filter((node) => node.file);
      const parentNodes = nodes?.filter((node) => !node.file);

      if (
        parentNodes?.length === 1 &&
        parentNodes[0]?.nodes?.length === 1 &&
        childNodes?.length === 0
      ) {
        const childNode = parentNodes[0]?.nodes[0];
        inputText = `${childNode.name}${
          childNode.groupId ? ` - ${childNode.groupId}` : ""
        }`;
      } else if (childNodes?.length === 1) {
        const leafNode = childNodes[0];
        inputText = `${leafNode.name}${
          leafNode.groupId ? ` - ${leafNode.groupId}` : ""
        }`;
      } else {
        inputText = "Multiple PBs";
      }

      return inputText;
    },
    [options]
  );

  const selectOptions = React.useMemo(() => {
    if (!loading) return buildPBTree(options.map((i) => i.value));
  }, [options, loading]);

  return (
    <HierarchySelect
      name="Borrows"
      onChange={onChange}
      label="Request Borrows From"
      options={selectOptions ?? []}
      disabled={disabled}
      loading={loading}
      textFormatter={textFormatter}
      id="locates-enquiry-borrows-multi-selection-id"
    />
  );
};
