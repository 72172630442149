import { pxToPtConversion } from "./utils";

export const basePdfReportWidgetStyles = {
  reportContainer: {
    borderRadius: pxToPtConversion(5),
    margin: pxToPtConversion(10),
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: pxToPtConversion(32),
    borderTopLeftRadius: pxToPtConversion(5),
    borderTopRightRadius: pxToPtConversion(5),
  },
  title: {
    fontFamily: "Lato",
    fontSize: pxToPtConversion(19),
  },

  tableWrapper: {
    borderBottomRightRadius: pxToPtConversion(5),
    borderBottomLeftRadius: pxToPtConversion(5),
    overflow: "hidden",
    padding: pxToPtConversion(8),
    height: "100%",
  },
  tableInnerWrapper: {
    borderRadius: pxToPtConversion(5),
    minHeight: pxToPtConversion(186),
    height: "100%",
  },
  tableHeader: {
    fontSize: pxToPtConversion(12),
    fontWeight: 600,
    fontFamily: "Lato",
  },
  headerSeperator: {
    width: 1,
    height: "50%",
    backgroundColor: "#424242",
    opacity: 0.5,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    height: pxToPtConversion(32),
    overflow: "hidden",
    paddingLeft: pxToPtConversion(5),
    paddingRight: pxToPtConversion(5),
  },
  tableData: {
    fontSize: pxToPtConversion(12),
    fontFamily: "Lato",
    paddingLeft: pxToPtConversion(3),
    paddingRight: pxToPtConversion(3),
  },
  rowsTextContainer: {
    padding: pxToPtConversion(4),
  },

  rowsLengthText: {
    fontSize: pxToPtConversion(8),
    fontFamily: "Lato",
    marginTop: pxToPtConversion(4),
  },

  warningCellBase: {
    position: "absolute",
    right: 0,
    top: pxToPtConversion(3),
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderTopWidth: 0,
    borderRightWidth: pxToPtConversion(10),
    borderBottomWidth: pxToPtConversion(10),
    borderLeftWidth: 0,
    transform: "rotate(0deg)",
  },

  chevron: {
    marginRight: pxToPtConversion(5),
    fontSize: pxToPtConversion(12),
  },
};
