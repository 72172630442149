import { inputOptions } from "@app-views/oems/OrderTicket/panels/QuantityNotionalPanel";
import { useWidget } from "@enfusion-ui/dashboards";
import {
  ButtonGroupSelect,
  FormElement,
  NumericInput,
  PanelRow,
} from "@enfusion-ui/web-components";

export const QuantityNotionalWidgetMobile = () => {
  const { config } = useWidget();
  const { input = "quantity", showCalculationInfoText = true } = config;
  return (
    <PanelRow>
      <FormElement>
        <ButtonGroupSelect
          selectedValue={input}
          title="Quantity"
          onSelect={() => {}}
          disabled
          options={inputOptions("$")}
        />
        <NumericInput
          disabled
          style={{ marginLeft: "2px" }}
          value={0}
          bigControls
          onChange={() => {}}
        />
      </FormElement>
      {showCalculationInfoText && (
        <FormElement>
          <div style={{ flex: 1 }}>
            Quantity
            <br />
            0.00
          </div>
          <div style={{ flex: 1 }}>
            Notional ($)
            <br />
            0.00
          </div>
        </FormElement>
      )}
    </PanelRow>
  );
};
