import { TabView } from "@app-components/layout/TabView";
import { useRefCallback } from "@enfusion-ui/hooks";
import { TradeTicketFormType, TradeTicketProvider } from "@enfusion-ui/trades";
import {
  DimensionsProvider,
  styled,
  useIsContentUnderWidth,
  useThisTab,
} from "@enfusion-ui/web-core";
import * as React from "react";
import { ResizeCallbackData } from "react-resizable";

import { TradeTicketContentSummary } from "./components/summary/TradeTicketContentSummary";
import { TradeTicketActionButtons } from "./components/TradeTicketActionButtons";
import { TradeTicketTabs } from "./components/TradeTicketContent";
import { TRADE_TICKET_TAB_SUMMARY_DEFAULT_WIDTH } from "./utils";

const TradeTicketContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TabContentContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

type TradeTicketProps = {
  tradeId: number;
  formType?: TradeTicketFormType;
  autoFocus?: string;
};

export const TradeTicket: React.FC<TradeTicketProps> = ({
  tradeId,
  formType = "equity",
  autoFocus = "general",
}) => {
  const { width } = useThisTab();
  const [autoFocusSection, setAutoFocusSection] = React.useState(autoFocus);
  const showDrawer = useIsContentUnderWidth(600);
  const drawerWidth = React.useRef(TRADE_TICKET_TAB_SUMMARY_DEFAULT_WIDTH);

  const [contentWidth, setContentWidth] = React.useState<number | undefined>(
    () => {
      if (width) return width - (showDrawer ? 0 : drawerWidth.current);
    }
  );

  React.useEffect(() => {
    if (width) setContentWidth(width - (showDrawer ? 0 : drawerWidth.current));
  }, [width, showDrawer]);

  const onResizeCallBack = useRefCallback(
    (e: React.SyntheticEvent, data: ResizeCallbackData) => {
      drawerWidth.current = data.size.width;
      if (width) setContentWidth(width - data.size.width);
    },
    [width]
  );

  const focusSection = useRefCallback(
    (section: string, itemId?: string) => {
      setAutoFocusSection(section);
      if (itemId) {
        setTimeout(() => {
          requestAnimationFrame(() => {
            const el = document.getElementById(itemId);
            if (el) {
              el.scrollIntoView({ block: "center" });
            }
          });
        }, 300);
      }
    },
    [setAutoFocusSection]
  );

  return (
    <TradeTicketProvider
      focusSection={focusSection}
      tradeId={tradeId}
      formDataType={formType}
    >
      <TradeTicketContainer>
        <DimensionsProvider width={contentWidth}>
          <TabContentContainer>
            <TabView
              defaultRendered
              items={TradeTicketTabs}
              defaultOpen={autoFocusSection}
              onChange={() => setAutoFocusSection("")}
            >
              <TradeTicketContentSummary
                showDrawer={showDrawer}
                onResizeCallBack={onResizeCallBack}
              />
            </TabView>
          </TabContentContainer>
        </DimensionsProvider>
        <TradeTicketActionButtons />
      </TradeTicketContainer>
    </TradeTicketProvider>
  );
};
