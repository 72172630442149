import { ReportSettingsDefinition } from "../../types";
import IncomeStatementQuickFilter from "./IncomeStatementQuickFilter";
import IncomeStatementSettings from "./IncomeStatementSettings";

export const WebGLIncomeStatementQuery: ReportSettingsDefinition = {
  name: "GLIncomeStatement",
  queryType: "WebGLIncomeStatementQuery",
  quickFilterComponent: IncomeStatementQuickFilter,
  settingsComponent: IncomeStatementSettings,
};
