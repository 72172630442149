import { GridData, GridView } from "@app-components/display/GridView";
import { useRefCallback } from "@enfusion-ui/hooks";
import { TradeTicketFormValues, useTradeTicket } from "@enfusion-ui/trades";
import { InstrumentInfo } from "@enfusion-ui/types";
import {
  getKeywordFromInstrument,
  getNormalizedInstrumentInfo,
} from "@enfusion-ui/utils";
import { IconButton, InfoPopover } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { useWatch } from "react-hook-form";

import {
  SummaryEditButton,
  SummaryPanel,
  SummaryPanelLabel,
} from "./styledComponents";

const InstrumentDescContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-xl);
`;

const InstrumentDesc = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-l);
  overflow-x: hidden;
`;

const InstrumentDescText = styled.div`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

function getInstrumentInfoGridData(instrument: InstrumentInfo): GridData {
  if (!instrument) return [];
  const { currency, exchangeName } = getNormalizedInstrumentInfo(instrument);
  const record = {
    OSI: undefined, // TODO
    "CUSIP/CINS": getKeywordFromInstrument(instrument, "CUSIP"),
    RIC: getKeywordFromInstrument(instrument, "RIC"),
    Exchange: exchangeName,
    SEDOL: getKeywordFromInstrument(instrument, "SEDOL"),
    "Pair Red": undefined, // TODO
    Currency: currency?.code,
    ISIN: getKeywordFromInstrument(instrument, "ISIN"),
    "BB Ticker": getKeywordFromInstrument(instrument, "BBYELLOW"),
  };

  return Object.entries(record).reduce(
    (data, [key, value]) =>
      data.concat({ fieldName: key, fieldValue: value ?? "-" }),
    [] as GridData
  );
}

export const ContentSummaryPanelInstrument: React.FC = () => {
  const { focusSection } = useTradeTicket();
  const { instrument } = useWatch<TradeTicketFormValues>({
    name: ["instrument"],
  });

  const popoverData = React.useMemo(
    () => getInstrumentInfoGridData(instrument as InstrumentInfo),
    [instrument]
  );

  const onClickEditButton = useRefCallback(() => {
    focusSection("general", "trade-instrument-select");
  }, []);

  return (
    <SummaryPanel>
      <SummaryPanelLabel>Instrument</SummaryPanelLabel>
      {instrument ? (
        <InstrumentDescContainer>
          <InstrumentDesc>
            <InstrumentDescText>
              {instrument.description} {instrument.id}
            </InstrumentDescText>
            <InfoPopover
              title="Instrument Identifiers"
              testId="tt-instrument-popover"
              iconTestId="tt-instrument-info-icon"
            >
              <GridView
                gridData={popoverData}
                fieldValueDefaultColor="var(--text-muted)"
              />
            </InfoPopover>
          </InstrumentDesc>

          <IconButton
            size="sm"
            icon={faPencil}
            onClick={onClickEditButton}
            style={{
              backgroundColor: "var(--background-color-1)",
              padding: "0.3rem 0.25rem",
            }}
          />
        </InstrumentDescContainer>
      ) : (
        <SummaryEditButton onClick={onClickEditButton}>
          Select a Trade Instrument
        </SummaryEditButton>
      )}
    </SummaryPanel>
  );
};
