import {
  InstrumentSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { InstrumentInfo } from "@enfusion-ui/types";
import { FormSectionAccordion, TextInput } from "@enfusion-ui/web-components";
import * as React from "react";

import InstrumentMultiSelect from "../../../../../../components/inputs/InstrumentSelect/InstrumentMultiSelect";

const InstrumentSelectionEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const { defaultValue = null, key = "Instruments" } =
    editedConfig as InstrumentSelectionWidgetConfig;

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange =
    (key: string) => (instruments: InstrumentInfo[] | null) => {
      changeConfigKeyToBeApplied(key, instruments?.map(({ id }) => id) ?? []);
    };

  return (
    <FormSectionAccordion title="Instrument Options" defaultOpen>
      <TextInput
        label="Key"
        value={key}
        name="key"
        onChange={handleTextChange("key")}
        data-e2e-id="instrument-key"
      />
      <InstrumentMultiSelect
        label="Default Instruments"
        value={defaultValue}
        onChange={handleBasicChange("defaultValue")}
      />
    </FormSectionAccordion>
  );
};

export default InstrumentSelectionEditWidget;
