import { FormElement, LabeledButton } from "@enfusion-ui/web-components";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { noop } from "lodash";
import * as React from "react";

type SelectEllipsisProps = {
  ellipsisHandler: () => void;
};

export const EllipsisLabeledButton: React.FC<SelectEllipsisProps> = ({
  ellipsisHandler = noop,
}) => {
  return (
    <FormElement basis="max-content" style={{ flexGrow: 0 }}>
      <LabeledButton icon={faEllipsis} onClick={ellipsisHandler} />
    </FormElement>
  );
};
