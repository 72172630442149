import { useAllocationsControl } from "@enfusion-ui/core";
import {
  ColumnGrid,
  ErrorAlert,
  FormController,
  FormElement,
  PanelRow,
  TextInput,
} from "@enfusion-ui/web-components";
import { styled, useAuth, useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import AllocationsGrid from "./AllocationsGrid";
import AllocationsSelection from "./AllocationsSelection";
import ApplyAllocationsPanel from "./ApplyAllocationsPanel";
import OTCOrdersAllocationPanel from "./OTCOrdersAllocationsPanel";
import PreferredAllocationSchemeSelect from "./PreferredAllocationSchemeSelect";

const AllocationPanelContainer = styled.div`
  width: 100%;
`;

const ErrorContainer = styled.div`
  padding-bottom: var(--spacing);
`;

const AllocationsPanel: React.FC<{
  compressed?: boolean;
  disabled?: boolean;
}> = ({ compressed = false, disabled = false }) => {
  const { isUserType } = useAuth();
  const isMobile = useIsContentMobile();
  const [error, setError] = React.useState<string | null>();
  const { watch } = useFormContext();
  const {
    isEntryTransmitted,
    isEntryCompleted,
    formType,
    isCdxEntry,
    isVarSwapEntry,
    isIrsEntry,
    entryType,
  } = useAllocationsControl();

  const { swapOrder, quantity, notional, vegaNotional, fixedNotional } = watch([
    "swapOrder",
    "quantity",
    "notional",
    "vegaNotional",
    "fixedNotional",
  ]);

  const allocQty = React.useMemo(() => {
    if (isCdxEntry) return notional;
    else if (isVarSwapEntry) return vegaNotional;
    else if (isIrsEntry) return fixedNotional;
    return quantity;
  }, [quantity, notional, isCdxEntry, isVarSwapEntry, vegaNotional]);

  return (
    <AllocationPanelContainer>
      {error ? (
        <ErrorContainer>
          <ErrorAlert error={error} />
        </ErrorContainer>
      ) : null}

      {formType !== "equity" && !isUserType("Express") ? (
        <OTCOrdersAllocationPanel />
      ) : null}

      <ColumnGrid
        numColumns={entryType === "trade" ? 1 : 2}
        isMobile={isMobile}
      >
        {entryType !== "trade" && (
          <PanelRow>
            <FormController
              name="allocationSchemeId"
              render={({ ref: _ref, ...rest }) => (
                <PreferredAllocationSchemeSelect
                  {...rest}
                  setError={setError}
                  disabled={disabled}
                />
              )}
            />
          </PanelRow>
        )}
        <PanelRow>
          <FormElement
            style={
              entryType !== "order" ? { marginBottom: "var(--spacing-xl)" } : {}
            }
          >
            <FormController
              name="allocationInstructions"
              render={({ ref: _ref, ...rest }) => (
                <TextInput
                  {...rest}
                  textarea
                  hideLabel
                  style={{
                    resize: "vertical",
                    minHeight: isMobile ? "4.75rem" : "7.25rem",
                  }}
                  placeholder="Enter allocation instructions here"
                  disabled={disabled}
                />
              )}
            />
          </FormElement>
        </PanelRow>

        {entryType !== "order" && <AllocationsSelection />}
      </ColumnGrid>

      {!compressed && (
        <>
          <ApplyAllocationsPanel setError={setError} disabled={swapOrder} />

          <AllocationsGrid
            height="200px"
            disabled={disabled || isEntryCompleted || !!isEntryTransmitted}
            quantity={allocQty}
            suppressClickEdit={
              disabled || isEntryCompleted || isEntryTransmitted
            }
          />
        </>
      )}
    </AllocationPanelContainer>
  );
};

export default AllocationsPanel;
