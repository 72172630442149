/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  TileWidgetConfig,
  useDashboard,
  useWidget,
} from "@enfusion-ui/dashboards";
import { SelectOptionsType, ValuesInputType } from "@enfusion-ui/types";
import { getSelectOption } from "@enfusion-ui/utils";
import {
  Checkbox,
  ColorPicker,
  FontSize,
  FontWeight,
  FormSectionAccordion,
  HorizontalAlignment,
  Select,
  VerticalAlignment,
} from "@enfusion-ui/web-components";
import { useReports } from "@enfusion-ui/web-core";
import { isEqual } from "lodash";
import * as React from "react";

import DatasourcePicker from "../../../widget/components/DatasourcePicker";
import ValuesInput from "../../../widget/components/ValuesInput";
import { SingleRowContainer } from "../../styles";

const TITLE_HEIGHT_OPTIONS = [
  {
    label: "Half",
    value: "half",
  },
  {
    label: "Small",
    value: "small",
  },
  {
    label: "Hidden",
    value: "none",
  },
];

const defaultBorderColor = "var(--background-accent)";
const defaultTitleColor = "var(--primary)";
const defaultValueColor = "var(--text-normal)";

function getOptionsFromColumns(columns: Array<any> | undefined) {
  return columns?.reduce<SelectOptionsType<string>[]>((res, column) => {
    const { headerName, name, hidden } = column;
    if (name && headerName) {
      res.push({
        label: hidden ? `${headerName} (hidden)` : headerName,
        value: name,
      });
    }
    return res;
  }, []);
}

const TileEditWidget: React.FC<unknown> = () => {
  const { getDatasource } = useDashboard();
  const { changeConfigKeyToBeApplied, editedConfig } = useWidget();
  const { dataStore } = useReports();

  const {
    datasourceId,
    columns = [],
    wrap = false,
  } = editedConfig as TileWidgetConfig;

  const datasource = React.useMemo(
    () => (datasourceId ? getDatasource(datasourceId) : undefined),
    [datasourceId]
  );

  const datasourceOptions = datasource ? dataStore[datasource.id] : null;

  const columnOptions = React.useMemo(
    () => getOptionsFromColumns(datasourceOptions?.columnDefs) ?? [],
    [datasourceOptions]
  );

  const handleBasicChange = (key: string) => (value: any) => {
    changeConfigKeyToBeApplied(key, value);
  };

  const handleValueInputChange =
    (key: string) => (value: ValuesInputType[]) => {
      if (!editedConfig[key]) {
        if (value && value.length > 0) changeConfigKeyToBeApplied(key, value);
        return;
      }
      if (!isEqual(editedConfig[key], value)) {
        changeConfigKeyToBeApplied(key, value);
      }
    };

  const handleSelectChange = (key: string) => (value: any) => {
    changeConfigKeyToBeApplied(key, value.value);
  };

  const handleColorChange =
    (key1: string, key2: string) => (value?: string[]) => {
      changeConfigKeyToBeApplied(key1, value?.[0]);
      changeConfigKeyToBeApplied(key2, value?.[1]);
    };

  const {
    showBorder = true,
    borderColor = defaultBorderColor,
    negativeBorderColor = defaultBorderColor,
    titleColor = defaultTitleColor,
    negativeTitleColor = defaultTitleColor,
    valueColor = defaultValueColor,
    negativeValueColor = defaultValueColor,
    justifyTitle = "flex-start",
    alignTitle = "center",
    titleFontSize = "medium",
    alignValue = "center",
    justifyValue = "flex-start",
    valueFontSize = "medium",
    numberReductionFormat = false,
    titleFontWeight = "normal",
    valueFontWeight = "normal",
    titleHeight = "half",
    titleShowBackground = false,
  } = editedConfig as TileWidgetConfig;

  const widgetName = "tile-widget";
  return (
    <>
      <FormSectionAccordion
        title="Container Options"
        dataE2EId={`${widgetName}-container-options-accordion`}
      >
        <SingleRowContainer>
          <Checkbox
            checked={wrap}
            label="Wrap Tiles"
            onChange={handleBasicChange("wrap")}
          />

          <Checkbox
            checked={numberReductionFormat}
            label="Reduced Number Format"
            onChange={handleBasicChange("numberReductionFormat")}
          />
        </SingleRowContainer>

        <SingleRowContainer noMargin>
          <Checkbox
            checked={showBorder}
            label="Show Border"
            onChange={handleBasicChange("showBorder")}
            topLabelPlaceholder
          />
          <ColorPicker
            label="Border Color"
            multi
            value={[borderColor, negativeBorderColor]}
            defaultValue={[defaultBorderColor, defaultBorderColor]}
            valueLabel={["Positive Color", "Negative Color"]}
            onChange={handleColorChange("borderColor", "negativeBorderColor")}
            clearable
          />
        </SingleRowContainer>
      </FormSectionAccordion>
      <FormSectionAccordion
        title="Title Styles"
        dataE2EId={`${widgetName}-tile-widget-accordion`}
      >
        <SingleRowContainer count={3}>
          <FontSize
            label="Title Font Size"
            name="titleFontSize"
            onChange={handleSelectChange("titleFontSize")}
            selectedValue={titleFontSize}
            minWidth={150}
          />
          <FontWeight
            label="Title Font Weight"
            name="titleFontWeight"
            onChange={handleSelectChange("titleFontWeight")}
            selectedValue={titleFontWeight}
            minWidth={150}
          />
          <ColorPicker
            label="Title Color"
            multi
            value={[titleColor, negativeTitleColor]}
            defaultValue={[defaultTitleColor, defaultTitleColor]}
            valueLabel={["Positive Color", "Negative Color"]}
            onChange={handleColorChange("titleColor", "negativeTitleColor")}
            clearable
          />
        </SingleRowContainer>

        <SingleRowContainer>
          <VerticalAlignment
            label="Vertically Align Title"
            name="alignTitle"
            onChange={handleSelectChange("alignTitle")}
            selectedValue={alignTitle}
            minWidth={150}
          />
          <HorizontalAlignment
            label="Horizontally Align Title"
            name="justifyTitle"
            onChange={handleSelectChange("justifyTitle")}
            selectedValue={justifyTitle}
            minWidth={150}
          />
        </SingleRowContainer>

        <SingleRowContainer noMargin>
          <Select
            label="Title Height"
            name="titleHeight"
            value={getSelectOption(TITLE_HEIGHT_OPTIONS, titleHeight)}
            options={TITLE_HEIGHT_OPTIONS}
            onChange={handleSelectChange("titleHeight")}
            minWidth={150}
            inputId="tile-title-height-selection-id"
          />
          <Checkbox
            topLabelPlaceholder
            checked={titleShowBackground}
            label="Title Background"
            onChange={handleBasicChange("titleShowBackground")}
          />
        </SingleRowContainer>
      </FormSectionAccordion>
      <FormSectionAccordion
        title="Value Styles"
        dataE2EId={`${widgetName}-value-styles-accordion`}
      >
        <SingleRowContainer count={3}>
          <FontSize
            label="Value Font Size"
            name="valueFontSize"
            onChange={handleSelectChange("valueFontSize")}
            selectedValue={valueFontSize}
            minWidth={125}
          />
          <FontWeight
            label="Value Font Weight"
            name="valueFontWeight"
            onChange={handleSelectChange("valueFontWeight")}
            selectedValue={valueFontWeight}
            minWidth={125}
          />
          <ColorPicker
            label="Value Color"
            multi
            value={[valueColor, negativeValueColor]}
            defaultValue={[defaultValueColor, defaultValueColor]}
            valueLabel={["Positive Color", "Negative Color"]}
            onChange={handleColorChange("valueColor", "negativeValueColor")}
            clearable
          />
        </SingleRowContainer>
        <SingleRowContainer noMargin>
          <VerticalAlignment
            label="Vertically Align Value"
            name="alignValue"
            onChange={handleSelectChange("alignValue")}
            selectedValue={alignValue}
            minWidth={150}
          />
          <HorizontalAlignment
            label="Horizontally Align Value"
            name="justifyValue"
            onChange={handleSelectChange("justifyValue")}
            selectedValue={justifyValue}
            minWidth={150}
          />
        </SingleRowContainer>
      </FormSectionAccordion>
      <FormSectionAccordion
        title="Datasource"
        dataE2EId={`${widgetName}-datasource-accordion`}
      >
        <DatasourcePicker
          canUseSubTable
          e2eTestId="tile-widget-datasource"
          // dataE2EId="tile-widget"
          onDatasourceChange={() => ({
            columns: [],
          })}
        />
      </FormSectionAccordion>
      <FormSectionAccordion
        title="Columns"
        dataE2EId={`${widgetName}columns-accordion`}
      >
        <ValuesInput
          name="columns"
          emptyLabelPlaceholder={false}
          defaultValues={columns}
          options={columnOptions}
          onChange={handleValueInputChange("columns")}
          btnTestId="tile-widget-columns-add-btn"
        />
      </FormSectionAccordion>
    </>
  );
};

export default TileEditWidget;
