import { useConnectionStatus } from "@app-context/connectionStatus/context";
import { useMarketData } from "@app-context/marketData/context";
import {
  PDFAsyncCallback,
  usePDFAsync,
} from "@app-views/dashboards/pdf/context";
import PdfContentMessage from "@app-views/dashboards/pdf/PdfContentMessage";
import { pxToPtConversion } from "@app-views/dashboards/pdf/utils";
import { getDisplayValue } from "@app-views/oems/utils/getDisplayValue";
import { ThemeDefinition, UNKNOWN_INSTRUMENT_ID } from "@enfusion-ui/core";
import { MarketDataWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { useRefCallback } from "@enfusion-ui/hooks";
import { ConnectionStatus, Quote } from "@enfusion-ui/types";
import { withTheme } from "@enfusion-ui/web-core";
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faCircle,
  faSkullCrossbones,
  faSpinner,
} from "@fortawesome/pro-solid-svg-icons";
import { Path, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import * as React from "react";

const pdfStyles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: pxToPtConversion(4),
  },
  titleContainer: {
    width: "100%",
    height: pxToPtConversion(45),
    minHeight: pxToPtConversion(32),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontFamily: "Lato",
    fontSize: pxToPtConversion(16),
    paddingVertical: pxToPtConversion(4),
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
  watchListItemContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    columnGap: pxToPtConversion(4),
    alignItems: "center",
    paddingHorizontal: pxToPtConversion(12),
    paddingVertical: pxToPtConversion(8),
    marginBottom: pxToPtConversion(4),
    borderBottomWidth: 1,
    borderRadius: pxToPtConversion(4),
  },
  arrowIconContainer: {
    width: "10%",
    height: "100%",
  },
  arrowIcon: {
    marginHorizontal: pxToPtConversion(8),
    marginVertical: pxToPtConversion(8),
  },
  listItemValuesContainer: {
    width: "90%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  valueSection: {
    width: "35%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    // justifyContent and alignItems are reversed
    justifyContent: "center",
  },
  valueContainer: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    marginTop: pxToPtConversion(4),
  },
  labelText: {
    marginRight: pxToPtConversion(2),
  },
  text: {
    fontFamily: "Lato",
    fontSize: pxToPtConversion(12),
  },
  centerContent: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getIcon = (change: number, _theme: any) => {
  if (change === 0) return faCircle;
  if (change < 0) return faArrowCircleDown;
  return faArrowCircleUp;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getIconColor = (change: number, theme: any) => {
  if (change === 0) return theme.colors.backgroundAccent;
  if (change < 0) return theme.colors.danger;
  return theme.colors.success;
};

const FormattedValue = withTheme(
  ({
    label,
    value,
    theme,
  }: {
    label: string;
    value: string;
    theme: ThemeDefinition;
  }) => {
    return (
      <View style={[pdfStyles.valueContainer]}>
        <View style={[pdfStyles.labelText, { color: theme.colors.textColor1 }]}>
          <Text style={[pdfStyles.text]}>{`${label}: `}</Text>
        </View>
        <View style={[{ color: theme.colors.textNormal }]}>
          <Text style={[pdfStyles.text]}>{value}</Text>
        </View>
      </View>
    );
  }
);

const PdfMarketDataWidget = withTheme(
  ({ theme }: { theme: ThemeDefinition }) => {
    const { config, id } = useWidget();
    const { marketDataConnectionStatus } = useConnectionStatus();
    const { subscribeToMarketData } = useMarketData();

    const { defaultValue } = config as MarketDataWidgetConfig;
    const quoteRef = React.useRef<Quote | null>(null);

    const contextMsg = React.useMemo(() => {
      switch (marketDataConnectionStatus) {
        case ConnectionStatus.CONNECTING:
          return "Market data connecting...";
        case ConnectionStatus.DISABLED:
        case ConnectionStatus.DISCONNECTED:
          return "Market data disconnected";
        case ConnectionStatus.ERROR:
          return "Error while connecting to market data";
        default:
          return null;
      }
    }, [marketDataConnectionStatus]);

    const isWidgetConfigurable = defaultValue?.id === UNKNOWN_INSTRUMENT_ID;

    const counterRef = React.useRef(0);

    const check = useRefCallback(
      (resolve, reject) => {
        if (isWidgetConfigurable || counterRef.current >= 4) {
          reject(new Error("Needs config"));
        } else if (!quoteRef.current) {
          counterRef.current += 1;
          setTimeout(() => check(resolve, reject), 1500);
        } else {
          setTimeout(() => {
            resolve({ status: "success", content: quoteRef.current });
          }, 500);
        }
      },
      [id, isWidgetConfigurable, quoteRef.current]
    );

    const checkBase = useRefCallback(
      (() => {
        return new Promise((resolve, reject) => {
          check(resolve, reject);
        });
      }) as PDFAsyncCallback,
      [check]
    );

    const cachedContent = usePDFAsync(id, checkBase);

    const setQuote = useRefCallback(
      (quote: Quote, unsubscribe: VoidFunction) => {
        quoteRef.current = quote;
        unsubscribe();
      },
      []
    );

    React.useEffect(() => {
      if (
        cachedContent.loading &&
        !isWidgetConfigurable &&
        !!defaultValue?.id
      ) {
        subscribeToMarketData(defaultValue.id, setQuote);
      }
    }, [defaultValue?.id, isWidgetConfigurable, cachedContent.loading]);

    if (cachedContent.loading)
      return <PdfContentMessage icon={faSpinner} iconColor="#4f545c" />;

    if (contextMsg)
      return (
        <PdfContentMessage icon={faSkullCrossbones} message={contextMsg} />
      );

    if (isWidgetConfigurable) {
      return (
        <View style={pdfStyles.container}>
          <View style={pdfStyles.centerContent}>
            <View
              style={[
                pdfStyles.text,
                {
                  color: theme.colors.textMuted,
                },
              ]}
            >
              <Text>Please configure MarketData widget</Text>
            </View>
          </View>
        </View>
      );
    }

    const quote = cachedContent.content as Quote | null;

    return (
      <View style={[pdfStyles.container]}>
        <View
          style={[
            pdfStyles.titleContainer,
            {
              backgroundColor: theme.colors.backgroundColor1Hover,
            },
          ]}
        >
          <Text
            style={[
              pdfStyles.title,
              {
                color: theme.colors.textNormal,
              },
            ]}
          >
            {defaultValue?.description}
          </Text>
        </View>
        <View
          style={[
            pdfStyles.watchListItemContainer,
            {
              backgroundColor: theme.colors.backgroundColor0,
              borderColor: theme.colors.border,
            },
          ]}
        >
          <View style={[pdfStyles.arrowIconContainer]}>
            <View style={[pdfStyles.arrowIcon]}>
              <Svg
                width="100%"
                height="100%"
                viewBox={`0 0 ${getIcon(quote?.change || 0, theme).icon[0]} ${
                  getIcon(quote?.change || 0, theme).icon[1]
                }`}
              >
                <Path
                  d={getIcon(quote?.change || 0, theme).icon[4] as string}
                  stroke={getIconColor(quote?.change || 0, theme)}
                  fill={getIconColor(quote?.change || 0, theme)}
                />
              </Svg>
            </View>
          </View>
          <View style={[pdfStyles.listItemValuesContainer]}>
            <View style={[pdfStyles.valueSection]}>
              <FormattedValue
                label="Bid"
                value={getDisplayValue(quote?.bid as number) ?? ""}
              />
              <FormattedValue
                label="Ask"
                value={getDisplayValue(quote?.ask as number) ?? ""}
              />
            </View>
            <View style={[pdfStyles.valueSection]}>
              <FormattedValue
                label="Last"
                value={getDisplayValue(quote?.last as number) ?? ""}
              />
              <FormattedValue
                label="Volume"
                value={getDisplayValue(quote?.volume as number) ?? ""}
              />
            </View>
            <View style={[pdfStyles.valueSection]}>
              <FormattedValue
                label="Change"
                value={getDisplayValue(quote?.change as number) ?? ""}
              />

              <FormattedValue
                label="Currency"
                value={
                  defaultValue?.currency?.code ??
                  defaultValue?.contract?.currency?.code ??
                  defaultValue?.contractCurrency?.code ??
                  ""
                }
              />
            </View>
          </View>
        </View>
      </View>
    );
  }
);

export default PdfMarketDataWidget;
