import { AnalyticsSourceOptions, SelectOptionsType } from "@enfusion-ui/types";
import { Select } from "@enfusion-ui/web-components";
import * as React from "react";

export const PortfolioAnalyticsSourcesSelect: React.FC<{
  value: string | null;
  onChange: (option: SelectOptionsType<string> | null) => void;
  label?: string;
}> = ({ value, onChange, label = "Position Source" }) => {
  return (
    <Select
      label={label}
      value={AnalyticsSourceOptions.find(({ value: val }) => value === val)}
      options={AnalyticsSourceOptions}
      onChange={onChange}
      inputId="portfolio-analytics-sources-selection-id"
    />
  );
};
