import { ReportSettingsDefinition } from "../../types";
import GLTrialBalanceQuickFilter from "./GLTrialBalanceQuickFilter";
import GLTrialBalanceSettings from "./GLTrialBalanceSettings";

export const WebGLTrialBalancesQuery: ReportSettingsDefinition = {
  name: "GLTrialBalances",
  queryType: "WebGLTrialBalancesQuery",
  quickFilterComponent: GLTrialBalanceQuickFilter,
  settingsComponent: GLTrialBalanceSettings,
};
