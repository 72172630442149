import { WidgetComponentsDefinition } from "../../../types";
import InstrumentSelectionEditWidget from "./InstrumentSelectionEditWidget";
import InstrumentSelectionWidget from "./InstrumentSelectionWidget";
import PdfInstrumentSelectionWidget from "./PdfInstrumentSelectionWidget";

export const instrumentSelection: WidgetComponentsDefinition = {
  renderComponent: InstrumentSelectionWidget,
  editComponent: InstrumentSelectionEditWidget,
  renderPdfComponent: PdfInstrumentSelectionWidget,
};
