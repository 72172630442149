import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { AnalyticsPerformancePanel } from "./panels/performancePanel/AnalyticsPerformancePanel";
import { AnalyticsPortfolioMovementPanel } from "./panels/portfolioMovementPanel/AnalyticsPortfolioMovementPanel";
import { AnalyticsRiskPanel } from "./panels/riskPanel/AnalyticsRiskPanel";

const SectionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
  padding: var(--spacing-xl);
  overflow: auto;
`;

export const AnalyticsViewContent: React.FC = () => {
  return (
    <SectionContainer>
      <AnalyticsPerformancePanel />
      <AnalyticsRiskPanel />
      <AnalyticsPortfolioMovementPanel />
    </SectionContainer>
  );
};
