import { isEqual, kebabCase } from "lodash";
import * as React from "react";
import { useWatch } from "react-hook-form";
import { FormController } from "../layout/FormPanel";
import { Select } from "./Select";
function getOptionValue(options, value, isMulti = false, creatable = false) {
    if (creatable && value && !(options && options.length)) {
        return { label: value, value: value };
    }
    if (!options || !options.length)
        return null;
    const val = isMulti ? value : [value];
    const matchedOptions = options.filter((opt) => val.findIndex((o) => isEqual(o, opt.value)) > -1);
    const unMatchedOptions = val
        .filter((o) => matchedOptions.findIndex((opt) => isEqual(o, opt.value)) < 0)
        .map((entry) => ({ label: entry, value: entry }));
    if (isMulti) {
        if (creatable) {
            if (!!matchedOptions && !!unMatchedOptions)
                return null;
            return [...matchedOptions, ...unMatchedOptions];
        }
        return matchedOptions ?? null;
    }
    if (creatable) {
        if (!!matchedOptions[0] && !!unMatchedOptions[0])
            return null;
        return matchedOptions[0] ?? unMatchedOptions[0] ?? null;
    }
    return matchedOptions ?? null;
}
export function ControlledSelect({ options, isMulti, name, basis, mobileBasis, tabletBasis, desktopBasis, onChange: onChangeExternal, controllerStyle, inputStyle, defaultValue, isHorizontal, creatable, rules, ...rest }) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const watchValue = useWatch({ name });
    return (React.createElement(FormController, { name: name, basis: basis, mobileBasis: mobileBasis, tabletBasis: tabletBasis, desktopBasis: desktopBasis, style: controllerStyle, rules: rules, render: ({ ref: _ref, onChange, value: _value, ...props }, { invalid }) => {
            const handleChange = (option) => {
                const options = option;
                const val = isMulti
                    ? options.length > 0
                        ? options.map((opt) => opt.value)
                        : null
                    : option?.value ?? null;
                onChange(val);
                onChangeExternal?.(val);
            };
            return (React.createElement(Select, { minWidth: 150, ...rest, ...props, isHorizontal: isHorizontal, invalid: invalid, options: options, value: getOptionValue(options, watchValue, isMulti, creatable) ?? null, onChange: handleChange, isMulti: isMulti, creatable: creatable, defaultValue: defaultValue, dataE2EId: `select-${kebabCase(name)}`, style: inputStyle }));
        } }));
}
