import { useTradeTicket } from "@enfusion-ui/trades";
import * as React from "react";

import { CostPanelGeneralListed } from "./CostPanelGeneralListed";
import { CostPanelVarSwap } from "./CostPanelVarSwap";

export const CostPanel: React.FC = () => {
  const { isVarSwap } = useTradeTicket();

  if (isVarSwap) return <CostPanelVarSwap />;

  return <CostPanelGeneralListed />;
};
