import { useEverTrue } from "@enfusion-ui/hooks";
import { css, errorToast, getXHRContent, statusText, styled, useTheme, } from "@enfusion-ui/web-core";
import XLSX from "@sheet/cryptopivot";
import { pick } from "lodash";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "../../../control";
import { ErrorAlert, Spinner } from "../../../display";
import { ControlledTextInput } from "../../../inputs/ControlledTextInput";
import { SingleColumnGridWithGap } from "../../../Styled";
import { Sheet } from "./Sheet";
import { loadSheet } from "./utils";
const CardWrapper = styled.div `
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--input-border);
`;
export const PreviewInternalWrapper = styled.div `
  flex: 1 1 0%;
  width: 100%;
  position: relative;

  .ag-cell-auto-height {
    height: 100%;
  }

  .ag-cell-wrapper {
    display: block;
  }

  .ag-cell-auto-height .ag-cell-value {
    height: 100%;
  }
`;
export const PreviewWrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const SpinnerWrapper = styled.div `
  height: 100%;
  width: 100%;
  object-position: center;
  border: 1px solid var(--input-border);
`;
const TabButtons = styled.div `
  width: 100%;
  padding-bottom: var(--spacing);
  padding-right: var(--spacing);
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
`;
const Card = styled.div `
  background: var(--background-color-0);
  padding: 40px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: grid;
  grid-gap: 24px;
  width: 420px;
`;
const defaultState = {
    workbook: null,
    activeSheet: null,
    focusedCell: undefined,
    columnDefs: {},
    data: {},
    pinnedData: {},
    gridApiRefs: {},
    sheets: [],
};
function focusSheetCell(api, focusCell) {
    const rowNode = api.getRowNode(`${focusCell.r}`);
    if (rowNode) {
        rowNode.setSelected(true, true);
    }
    api.ensureColumnVisible(XLSX.utils.encode_col(focusCell.c));
    api.ensureIndexVisible(focusCell.r);
    if (rowNode) {
        api.refreshCells({ force: true });
    }
}
function reducer(state, action) {
    if (action.type === "load") {
        const { workbook } = action.payload;
        const sheets = workbook.Workbook?.Sheets?.reduce((res, sheet, idx) => {
            if (!sheet.Hidden) {
                res.push(sheet.name || workbook.SheetNames[idx]);
            }
            return res;
        }, []) || workbook.SheetNames;
        const activeSheet = sheets[0];
        return {
            ...state,
            ...loadSheet(pick(state, ["data", "columnDefs", "pinnedData"]), workbook, activeSheet),
            sheets,
            workbook,
            activeSheet,
        };
    }
    else if (action.type === "gridReady") {
        const { gridApiRef, sheet } = action.payload;
        const { focusedCell, ...restOfState } = state;
        if (focusedCell) {
            focusSheetCell(gridApiRef.api, focusedCell);
        }
        return {
            ...restOfState,
            focusedCell: undefined,
            gridApiRefs: { ...state.gridApiRefs, [sheet]: gridApiRef },
        };
    }
    else if (action.type === "changeSheet") {
        const { sheet, focusCell } = action.payload;
        const { gridApiRefs, activeSheet, ...rest } = state;
        let restOfState = { ...rest };
        const gridApiRef = gridApiRefs[sheet];
        if (restOfState.workbook && !restOfState.workbook.Sheets[sheet]) {
            errorToast("Invalid reference to sheet");
            return state;
        }
        if (!gridApiRef && restOfState.workbook) {
            restOfState = {
                ...rest,
                ...loadSheet(pick(restOfState, ["data", "columnDefs", "pinnedData"]), restOfState.workbook, sheet),
            };
        }
        // handle deselection and selection
        if (activeSheet && gridApiRefs[activeSheet]) {
            const selectedNodes = gridApiRefs[activeSheet].api.getSelectedNodes();
            if (selectedNodes[0]) {
                selectedNodes[0].setSelected(false, true);
                requestAnimationFrame(() => {
                    gridApiRefs[activeSheet].api.refreshCells({ force: true });
                });
            }
        }
        let focusedCell = focusCell;
        if (gridApiRef && focusCell) {
            focusedCell = undefined;
            focusSheetCell(gridApiRef.api, focusCell);
        }
        // make sure selected sheet's tab is visible
        requestAnimationFrame(() => {
            document
                .getElementById(`sheet-button-${sheet.split(" ").join("-")}`)
                ?.scrollIntoView();
        });
        return {
            ...restOfState,
            gridApiRefs,
            focusedCell,
            activeSheet: sheet,
        };
    }
    return state;
}
const PasswordForm = ({ onSubmit, }) => {
    const formMethods = useForm();
    const handleSubmitMiddle = ({ password }) => onSubmit(password);
    return (React.createElement(FormProvider, { ...formMethods },
        React.createElement(SingleColumnGridWithGap, { as: "form", onSubmit: formMethods.handleSubmit(handleSubmitMiddle) },
            React.createElement(ControlledTextInput, { control: formMethods.control, name: "password", type: "password", label: "Password", rules: { required: true } }),
            React.createElement(Button, { primary: true, type: "submit" }, "Unlock File"))));
};
const passwordReducer = (state, action) => {
    if (action.type === "password-protected") {
        return { passwordProtected: true, password: "", error: null };
    }
    else if (action.type === "password") {
        return {
            passwordProtected: true,
            password: action.payload.password,
            error: null,
        };
    }
    else if (action.type === "clear-error") {
        return { ...state, error: null };
    }
    else if (action.type === "set-error") {
        return { ...state, error: action.payload.error };
    }
    return state;
};
const TabButton = styled.div `
  cursor: pointer;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  padding: var(--spacing);
  margin-left: var(--spacing);
  width: auto;
  display: inline-block;
  :hover {
    background-color: var(--background-color-1);
  }

  ${({ isActive }) => isActive &&
    css `
      cursor: default;
      background-color: var(--background-accent);

      :hover {
        background-color: var(--background-accent);
      }
    `}
`;
const SheetContainerCore = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: ${({ isActive }) => (isActive ? "auto" : "none")};
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  [role="gridcell"] {
    padding: 0;
  }
`;
export const SheetContainer = ({ className, isActive = false, children }) => {
    const active = useEverTrue(isActive);
    return (React.createElement(SheetContainerCore, { className: className, isActive: isActive }, active && children));
};
export const XLSXPreview = ({ filePath, setError, }) => {
    const { theme } = useTheme();
    const [arrayData, setData] = React.useState(null);
    const [{ passwordProtected, password, error }, passwordDispatch] = React.useReducer(passwordReducer, { password: "", passwordProtected: false, error: null });
    const [{ activeSheet, data, pinnedData, columnDefs, workbook, sheets }, dispatch,] = React.useReducer(reducer, defaultState);
    const sheetRef = React.useRef(null);
    const readFileContent = React.useCallback((data) => {
        try {
            const workbook = XLSX.read(data, {
                type: "array",
                cellStyles: true,
                cellNF: true,
                password: password,
                nodim: true,
            });
            dispatch({ type: "load", payload: { workbook } });
            setData(null);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }
        catch (error) {
            if (error.message.includes("Incorrect Password")) {
                passwordDispatch({ type: "password-protected" });
            }
            else {
                passwordDispatch({ type: "set-error", payload: { error } });
            }
        }
    }, [password]);
    React.useEffect(() => {
        if (arrayData !== null) {
            if ((!passwordProtected && !password.length) ||
                (passwordProtected && !!password.length)) {
                passwordDispatch({ type: "clear-error" });
                readFileContent(arrayData);
            }
        }
    }, [passwordProtected, password]);
    React.useEffect(() => {
        setError(error?.message ?? error);
    }, [error]);
    React.useEffect(() => {
        const readFile = async () => {
            const req = await getXHRContent(filePath);
            if (req.status >= 200 && req.status <= 299) {
                const data = new Uint8Array(req.response);
                setData(data);
                readFileContent(data);
            }
            else {
                passwordDispatch({
                    type: "set-error",
                    payload: { error: new Error(statusText(req.status)) },
                });
            }
        };
        readFile();
    }, []);
    const handlePassword = (password) => {
        passwordDispatch({ type: "password", payload: { password } });
    };
    if (passwordProtected && !password.length) {
        return (React.createElement(CardWrapper, null,
            React.createElement(Card, { style: {
                    position: "absolute",
                    top: "15vh",
                } },
                React.createElement(ErrorAlert, { error: "This file is password protected. Please enter a password to continue." }),
                React.createElement(PasswordForm, { onSubmit: handlePassword }))));
    }
    if (workbook === null || activeSheet === null) {
        return (React.createElement(SpinnerWrapper, null,
            React.createElement(Spinner, null)));
    }
    sheetRef.current = workbook.Sheets[activeSheet];
    const handleSheetChange = (sheet) => () => {
        if (sheet !== activeSheet) {
            dispatch({
                type: "changeSheet",
                payload: { sheet },
            });
        }
    };
    const focusRow = (sheet, focusCell) => {
        dispatch({
            type: "changeSheet",
            payload: { sheet, focusCell },
        });
    };
    const handleGridReady = (sheet) => (event) => {
        dispatch({ type: "gridReady", payload: { gridApiRef: event, sheet } });
    };
    const getSheetColor = (sheetName) => {
        const color = workbook.Sheets[sheetName]["!tabcolor"]?.rgb;
        return color ? `#${color}` : "transparent";
    };
    return (React.createElement(PreviewWrapper, null,
        React.createElement(PreviewInternalWrapper, null, sheets.map((sheet) => {
            const active = activeSheet === sheet;
            return (React.createElement(SheetContainer, { key: sheet, className: theme.agGridClass, isActive: active },
                React.createElement(Sheet, { defaultColumnDefs: columnDefs[sheet], defaultRowData: data[sheet], defaultPinnedData: pinnedData[sheet], focusRow: focusRow, sheetRef: workbook.Sheets[sheet], onGridReady: handleGridReady(sheet), active: active })));
        })),
        React.createElement("div", { style: {
                width: "100%",
                backgroundColor: "var(--background-accent)",
                height: 3,
            } }),
        React.createElement(TabButtons, null, sheets.map((i) => (React.createElement(TabButton, { id: `sheet-button-${i.split(" ").join("-")}`, style: {
                borderColor: getSheetColor(i),
                borderWidth: "0px 1px 1px 1px",
                boxShadow: i === activeSheet
                    ? "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"
                    : "unset",
            }, isActive: activeSheet === i, onClick: handleSheetChange(i), key: i }, i))))));
};
