import { InputLabel, SelectButton } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";

import { AdvancedSearchBtnWrapper } from "../styledComponents";

export const AdvancedSearchBtn = styled(SelectButton).attrs({
  iconPosition: "left",
})`
  display: flex;
  align-items: center;
  height: 2.375rem;
`;

const ButtonText = styled.span`
  white-space: nowrap;
`;

export const AdvancedSearchButton: React.FC<{
  active?: boolean;
  disabled?: boolean;
  onClick: () => void;
  hideText?: boolean;
  hideLabelPlaceholder?: boolean;
}> = ({ active, disabled, onClick, hideText, hideLabelPlaceholder }) => {
  return (
    <AdvancedSearchBtnWrapper>
      {!hideLabelPlaceholder && <InputLabel>&nbsp;</InputLabel>}
      <AdvancedSearchBtn
        disabled={disabled}
        icon={faSearch}
        iconRotation={90}
        onClick={onClick}
        $selected={active}
        $selectedColor="background-color-1-hover"
        title="Advanced Search"
        dataE2EId="advanced-search"
      >
        {!hideText && <ButtonText>Advanced Search</ButtonText>}
      </AdvancedSearchBtn>
    </AdvancedSearchBtnWrapper>
  );
};
