import { styled } from "@enfusion-ui/web-core";
import { ControlledShareClassMultiselect } from "views/reports/components/ReportQueryComponents/ShareClassMultiselect";

import { DateSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";

const InputContainer = styled.div`
  min-width: 250px;
`;

const InvestmentReportQuickFilter = () => {
  return (
    <>
      <InputContainer>
        <ControlledShareClassMultiselect />
      </InputContainer>
      <InputContainer>
        <DateSelection name="valueDate" label="Value Date" />
      </InputContainer>
    </>
  );
};

export default InvestmentReportQuickFilter;
