import {
  UNKNOWN_INSTRUMENT_ID,
  useInstrument,
  useOEMSOrderForm,
} from "@enfusion-ui/core";
import { OrderInfo } from "@enfusion-ui/types";
import { cleanQuickOrderEntryInput } from "@enfusion-ui/utils";
import {
  FormController,
  IconButton,
  Spinner,
  TextInput,
} from "@enfusion-ui/web-components";
import { REST_API, styled, useOEMSRoutes } from "@enfusion-ui/web-core";
import {
  faCheckCircle,
  faExclamationCircle,
  faQuestion,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useWatch } from "react-hook-form";

import ExpressionHelpModal from "./ExpressionHelpModal";

type Error = { message: string };

type DescriptionState = {
  type: "error" | "success" | null;
  content: string[] | null;
};

const defaultDescriptionState: DescriptionState = {
  type: null,
  content: [],
};

const QuickOrderEntryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const QuickOrderEntryInputContainer = styled.div`
  margin-bottom: 4px;
`;

const QuickOrderEntryDescription = styled.div<{ disabled: boolean }>`
  color: var(--text-normal);
  background-color: var(
    --${({ disabled }) => (disabled ? "background-color-0" : "input-background")}
  );
  opacity: 0.8;
  padding: 0.5em;
  outline: none;
  border-radius: var(--radius);
  font: inherit;
  font-size: 0.875em;
  line-height: 1.075em;
  height: 4.225rem;
  overflow-y: auto;
  display: flex;
`;

type DescriptionIconProps = {
  error: boolean;
};

const DescriptionIconContainer = styled.div<DescriptionIconProps>`
  color: ${({ error }: { error: boolean }) =>
    error ? "var(--danger)" : "var(--success)"};
  padding-right: 0.5em;
`;

export type QuickOrderEntryProps = {
  label?: string;
  disabled?: boolean;
};

const QuickOrderEntry: React.FC<QuickOrderEntryProps> = ({
  label,
  disabled,
}) => {
  const { getCurrentOrder, onOrderChange, isTransmitted } = useOEMSOrderForm();
  const instrument = useInstrument();
  const [openHelp, setOpenHelp] = React.useState(false);
  const [{ type, content }, setDescriptionState] = React.useState(
    defaultDescriptionState
  );
  const [loading, setLoading] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const webOrderType = useWatch<string>({ name: "webOrderType" });
  const { routes } = useOEMSRoutes();

  const disableEditing =
    (webOrderType !== "Voice" && isTransmitted) ||
    (webOrderType === "Undecided" && routes && routes.length > 0);

  React.useEffect(() => {
    setDescriptionState(defaultDescriptionState);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  }, [instrument?.id]);

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const quickOrderString = event.target.value;

    setDescriptionState(defaultDescriptionState);

    if (quickOrderString && instrument && instrument?.id !== null) {
      setLoading(true);
      try {
        const response = await REST_API.OEMS.GET_QUICK_ENTRY_CALCULATION.FETCH({
          input: cleanQuickOrderEntryInput(quickOrderString),
          order: getCurrentOrder() as Partial<OrderInfo>,
        });
        const { descriptor, order, notional } = response;

        setDescriptionState({ type: "success", content: [descriptor] });
        onOrderChange({ order, notional });
      } catch (error: unknown) {
        const { message } = error as Error;
        setDescriptionState({
          type: "error",
          content: message.split("\n"),
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleHelpModal = () => setOpenHelp(!openHelp);

  return (
    <QuickOrderEntryContainer>
      <QuickOrderEntryInputContainer>
        <FormController
          name="qoeValue"
          render={({ ref: _ref, ...rest }) => (
            <TextInput
              label={label}
              {...rest}
              onBlur={handleBlur}
              icon={<IconButton icon={faQuestion} onClick={toggleHelpModal} />}
              disabled={
                disabled ||
                !instrument ||
                instrument.id === UNKNOWN_INSTRUMENT_ID ||
                disableEditing
              }
              ref={inputRef}
            />
          )}
        />
      </QuickOrderEntryInputContainer>
      <QuickOrderEntryDescription
        disabled={
          disabled ||
          !instrument ||
          instrument.id === UNKNOWN_INSTRUMENT_ID ||
          disableEditing
        }
      >
        {loading && (
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <Spinner />
          </div>
        )}
        {type === "success" && (
          <DescriptionIconContainer error={false}>
            <FontAwesomeIcon icon={faCheckCircle} />
          </DescriptionIconContainer>
        )}
        {type === "error" && (
          <DescriptionIconContainer error>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </DescriptionIconContainer>
        )}
        <div>
          {content?.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      </QuickOrderEntryDescription>
      {openHelp && (
        <ExpressionHelpModal open={openHelp} onClose={toggleHelpModal} />
      )}
    </QuickOrderEntryContainer>
  );
};

export default QuickOrderEntry;
