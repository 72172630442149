import {
  ColumnSelectCellEditor,
  DatePickerCellEditor,
  NumericCellEditor,
  TrashIconButtonCellRenderer,
} from "@app-components/cells";
import { RoundedDataGrid } from "@app-components/DataGrid";
import { useFormTableListContext } from "@app-utils/useFormTableListContext";
import { amountCellValueFormatter } from "@app-views/trade/TradeTicket/utils";
import {
  useFormTableList,
  useModalState,
  useRefCallback,
  useRestAbortableOptions,
} from "@enfusion-ui/hooks";
import {
  AccrualEntity,
  AccrualFrequency,
  accrualFrequencyOptions,
  AccrualMethod,
  accrualMethodOptions,
  AccrualPeriod,
  accrualPeriodOptions,
  AccrualStepEntity,
} from "@enfusion-ui/trades";
import { SelectOptionsType } from "@enfusion-ui/types";
import { getSelectOption } from "@enfusion-ui/utils";
import {
  Button,
  Checkbox,
  DatePicker,
  FormElementHeader,
  FormPanel,
  Modal,
  Select,
  TextInput,
} from "@enfusion-ui/web-components";
import { REST_API, styled, useGridApi } from "@enfusion-ui/web-core";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import {
  CellValueChangedEvent,
  ColDef,
  GridReadyEvent,
  IRowNode,
  ValueFormatterParams,
} from "ag-grid-community";
import { format } from "date-fns";
import * as React from "react";

import { TradeTicketAccrualStepsModal } from "./TradeTicketAccrualStepsModal";

const ModalContainer = styled.div`
  padding-top: var(--spacing-xl);
  min-width: 38.25rem;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

const ContextPopupParent = styled.div`
  overflow: hidden;
`;

const RowContainer = styled.div`
  display: flex;
  gap: var(--spacing-xl);
`;

const TextInputContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

const NewStepButton = styled(Button)`
  max-width: max-content;
  margin: var(--spacing-l) 0;
`;

const ActionContainer = styled.div`
  display: flex;
  height: 2.25rem;
  gap: var(--spacing-l);
  justify-content: space-between;
  margin-top: var(--spacing-xl);
`;

const accrualStepsColDefs: ColDef[] = [
  {
    headerName: "",
    field: "selection",
    width: 40,
    pinned: "left",
    lockPinned: true,
    resizable: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: "Effective Date",
    field: "effectiveDate",
    initialWidth: 140,
    editable: true,
    type: "date",
    cellStyle: { padding: "0" },
  },
  {
    headerName: "TerminationDate",
    field: "terminationDate",
    initialWidth: 140,
    editable: true,
    type: "date",
    cellStyle: { padding: "0" },
  },
  {
    headerName: "Amount",
    field: "amount",
    initialWidth: 120,
    editable: true,
    type: "numeric",
  },
  {
    headerName: "Currency",
    field: "currencyId",
    initialWidth: 120,
    editable: true,
    type: "currency",
  },
  {
    width: 40,
    headerName: "",
    field: "remove",
    pinned: "right",
    lockPinned: true,
    resizable: false,
    type: "removeAction",
    cellStyle: { padding: "0" },
  },
];

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onSubmit: (accrualEntity: AccrualEntity) => void;
  modifyingNode: IRowNode | null;
};

export const TradeTicketAccrualDetailModal: React.FC<Props> = ({
  open,
  onClose,
  onSubmit: onSubmitProp,
  modifyingNode,
}) => {
  const [description, setDescription] = React.useState<string | null>(null);
  const [isEnabled, setEnabled] = React.useState<boolean>(true);
  const [period, setPeriod] = React.useState<AccrualPeriod | null>(null);
  const [frequency, setFrequency] = React.useState<AccrualFrequency | null>(
    null
  );
  const [earlyTermDate, setEarlyTermDate] = React.useState<
    Date | string | null
  >(null);
  const [method, setMethod] = React.useState<AccrualMethod | null>(null);
  const [steps, setSteps] = React.useState<AccrualStepEntity[]>([]);

  const { options: currencyOptions } = useRestAbortableOptions(
    REST_API.INSTRUMENT.GET_SETTLEMENT_CURRENCIES.FETCH,
    {
      map: (td) => ({ value: td.id, label: td.code }),
    }
  );

  const {
    open: stepsOpen,
    openModal: openStepsModal,
    closeModal: closeStepsModal,
  } = useModalState();

  const { onGridReady } = useGridApi((event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
  });

  const accrualStepsControl = useFormTableList<AccrualStepEntity>(setSteps);

  React.useEffect(() => {
    if (modifyingNode === null) return;
    setDescription(modifyingNode.data.description);
    setEnabled(modifyingNode.data.enabled);
    setPeriod(modifyingNode.data.period);
    setFrequency(modifyingNode.data.frequency);
    setEarlyTermDate(modifyingNode.data.earlyTerminationDate ?? null);
    setMethod(modifyingNode.data.method);
    accrualStepsControl.initializeData(modifyingNode.data.steps);
  }, [modifyingNode]);

  const closeModal = useRefCallback(() => {
    setDescription(null);
    setEnabled(true);
    setPeriod(null);
    setFrequency(null);
    setEarlyTermDate(null);
    setMethod(null);
    accrualStepsControl.initializeData([]);
    onClose();
  }, []);

  const onSubmit = useRefCallback(() => {
    if (!description || !period || !frequency || !method || !steps.length)
      return;
    const entity: AccrualEntity = {
      description,
      enabled: isEnabled,
      period,
      frequency,
      earlyTerminationDate: earlyTermDate,
      method,
      steps,
    };
    onSubmitProp(entity);
    closeModal();
  }, [
    description,
    isEnabled,
    period,
    frequency,
    earlyTermDate,
    method,
    steps,
    onSubmitProp,
  ]);

  const onCellValueChanged = useRefCallback(
    ({ data }: CellValueChangedEvent) => {
      accrualStepsControl.modifyRow(data.__row_id, data);
    },
    []
  );

  const onClickTrashIconCell = useRefCallback((node: IRowNode) => {
    accrualStepsControl.deleteRows([node.data.__row_id]);
  }, []);

  const getContextMenuItems = useFormTableListContext(accrualStepsControl);

  return (
    <>
      <Modal
        dataE2EId="accrual-details-modal"
        isOpen={open}
        onClose={closeModal}
        title={`${modifyingNode === null ? "New" : "Modify"} Accrual Details`}
        content={
          <ModalContainer>
            <ContextPopupParent id="accrual-steps-popup-parent">
              <FormPanel numColumns={1} contentStyle={{ padding: "0" }}>
                <FormElementHeader dividerColor="var(--background-accent)">
                  Accrual Details
                </FormElementHeader>

                <RowContainer style={{ alignItems: "flex-end" }}>
                  <TextInputContainer>
                    <TextInput
                      data-e2e-id="accruals-description-input"
                      required
                      clearable
                      label="Description"
                      name="accruals-description-input"
                      value={description ?? ""}
                      onClearValue={() => setDescription(null)}
                      data-testid="tt-accrual-detail-desc-input"
                      onChange={({ target: { value } }) =>
                        setDescription(value)
                      }
                    />
                  </TextInputContainer>

                  <Checkbox
                    name="enabled"
                    label="Enabled?"
                    checked={isEnabled}
                    labelPlacement="right"
                    onChange={setEnabled}
                    style={{ flex: "0 0 max-content" }}
                  />
                </RowContainer>

                <RowContainer>
                  <Select
                    dataE2EId="accrual-period-select"
                    name="accrual-period-select"
                    required
                    clearable={false}
                    label="Accrual Period"
                    options={accrualPeriodOptions}
                    inputId="tt-accrual-detail-period-select"
                    onChange={(
                      option: SelectOptionsType<AccrualPeriod> | null
                    ) => option && setPeriod(option.value)}
                    value={
                      period
                        ? getSelectOption(accrualPeriodOptions, period)
                        : null
                    }
                  />

                  <Select
                    required
                    clearable={false}
                    label="Accrual Frequency"
                    name="accrual-frquency-select"
                    dataE2EId="accrual-frequency-select"
                    options={accrualFrequencyOptions}
                    inputId="tt-accrual-detail-frequency-select"
                    onChange={(
                      option: SelectOptionsType<AccrualFrequency> | null
                    ) => option && setFrequency(option.value)}
                    value={
                      frequency
                        ? getSelectOption(accrualFrequencyOptions, frequency)
                        : null
                    }
                  />
                </RowContainer>

                <RowContainer>
                  <DatePicker
                    clearable
                    label="Early Termination Date"
                    onChange={setEarlyTermDate}
                    value={
                      earlyTermDate
                        ? earlyTermDate instanceof Date
                          ? earlyTermDate
                          : new Date(earlyTermDate)
                        : null
                    }
                  />

                  <Select
                    required
                    clearable={false}
                    dataE2EId="accrual-method-select"
                    label="Accrual Method"
                    options={accrualMethodOptions}
                    inputId="tt-accrual-detail-method-select"
                    onChange={(
                      option: SelectOptionsType<AccrualMethod> | null
                    ) => option && setMethod(option.value)}
                    value={
                      method
                        ? getSelectOption(accrualMethodOptions, method)
                        : null
                    }
                  />
                </RowContainer>
              </FormPanel>

              <FormPanel
                numColumns={1}
                contentStyle={{ padding: "0" }}
                style={{ marginTop: "var(--spacing-xxl)" }}
              >
                <FormElementHeader dividerColor="var(--background-accent)">
                  Accrual Steps
                </FormElementHeader>
                <NewStepButton
                  icon={faPlus}
                  iconPosition="left"
                  onClick={openStepsModal}
                  title="Add New Step"
                  dataE2EId="accruals-add-new-step"
                >
                  New
                </NewStepButton>

                <RoundedDataGrid
                  height="15rem"
                  rowData={steps}
                  singleClickEdit
                  suppressCellFocus
                  rowSelection="multiple"
                  onGridReady={onGridReady}
                  suppressRowClickSelection
                  columnDefs={accrualStepsColDefs}
                  stopEditingWhenCellsLoseFocus={false}
                  getRowId={accrualStepsControl.getRowId}
                  onCellValueChanged={onCellValueChanged}
                  getContextMenuItems={getContextMenuItems}
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                    suppressHeaderMenuButton: true,
                    suppressMovable: true,
                    suppressKeyboardEvent: () => true,
                  }}
                  columnTypes={{
                    numeric: {
                      cellEditor: NumericCellEditor,
                      cellEditorParams: {
                        min: 0,
                        enableMultiplier: true,
                      },
                      valueFormatter: amountCellValueFormatter,
                    },
                    removeAction: {
                      cellRenderer: TrashIconButtonCellRenderer,
                      cellRendererParams: {
                        onClick: onClickTrashIconCell,
                      },
                    },
                    currency: {
                      cellEditor: ColumnSelectCellEditor,
                      cellEditorParams: {
                        options: currencyOptions,
                      },
                      valueFormatter: ({ value }: ValueFormatterParams) =>
                        getSelectOption(currencyOptions, value as number)!
                          .label,
                    },
                    date: {
                      cellEditor: DatePickerCellEditor,
                      valueFormatter: ({ value }: ValueFormatterParams) => {
                        if (!value) return "";
                        return format(
                          value instanceof Date ? value : new Date(value),
                          "MM/dd/yyyy"
                        );
                      },
                    },
                  }}
                  popupParent={document.getElementById(
                    "accrual-steps-popup-parent"
                  )}
                />
              </FormPanel>

              <ActionContainer>
                <Button
                  theme="primary"
                  onClick={onSubmit}
                  data-testid="tt-accrual-detail-submit-button"
                  disabled={
                    !description ||
                    !period ||
                    !frequency ||
                    !method ||
                    !steps.length
                  }
                >
                  Save and Close
                </Button>
                <Button
                  onClick={closeModal}
                  data-testid="tt-accrual-detail-close-button"
                >
                  Cancel
                </Button>
              </ActionContainer>
            </ContextPopupParent>
          </ModalContainer>
        }
      />
      <TradeTicketAccrualStepsModal
        open={stepsOpen}
        onClose={closeStepsModal}
        onSubmit={accrualStepsControl.addRow}
      />
    </>
  );
};
