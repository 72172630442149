import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const ProgressBarContainer = styled.div`
  border: 2px solid;
  border-color: var(--primary);
  border-radius: 16px;
  padding: 2px;
  width: 192px;
`;

const ProgressBarFill = styled.div`
  background-color: var(--primary);
  height: 8px;
  border-radius: 16px;
  transition: width 300ms;
`;

export type Props = {
  value: number;
  total: number;
};

function ProgressBar({ value, total }: Props) {
  return (
    <ProgressBarContainer>
      <ProgressBarFill
        style={{ width: `${(value / total) * 100}%` }}
        data-testid="fill"
      />
    </ProgressBarContainer>
  );
}

export default ProgressBar;
