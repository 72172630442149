import { getXHRContent, statusText, styled } from "@enfusion-ui/web-core";
import * as React from "react";
import ReactPrismEditor from "react-prism-editor";
import { Spinner } from "../../display";
import { ContentWrapper } from "./styles";
const TXTPreviewWrapper = styled.div `
  flex: 1 1 0%;
  width: 100%;
  overflow: auto;
  display: grid;
  border: 1px solid var(--input-border);
  place-items: center;
`;
const TXTPreviewInner = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const TextPreviewWhiteBg = styled.div `
  background-color: #fff;
  white-space: pre;
  color: #000;
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;
  padding: var(--spacing-xl);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  width: 820px;
`;
const langMap = {
    ts: "typescript",
    js: "javascript",
    json: "json",
    jsx: "jsx",
    tsx: "tsx",
    html: "html",
    vue: "vue",
    css: "css",
    scss: "sass",
    md: "markup",
    java: "java",
    php: "php",
    cs: "csharp",
    c: "c",
    cpp: "cpp",
    xml: "xml",
};
export const TXTPreview = ({ filePath, fileExt, contentModifier, setError, }) => {
    const lang = langMap[fileExt];
    const [data, setData] = React.useState(null);
    React.useEffect(() => {
        const readFile = async () => {
            try {
                const req = await getXHRContent(filePath, "text");
                if (req.status >= 200 && req.status <= 299) {
                    setData(contentModifier(req.responseText));
                }
                else {
                    setError(statusText(req.status));
                }
            }
            catch (err) {
                setError(err.message);
            }
        };
        readFile();
    }, []);
    if (data === null) {
        return (React.createElement(ContentWrapper, null,
            React.createElement(Spinner, null)));
    }
    if (!lang)
        return (React.createElement(TXTPreviewWrapper, null,
            React.createElement(TXTPreviewInner, null,
                React.createElement(TextPreviewWhiteBg, null, data))));
    return (React.createElement(TXTPreviewWrapper, null,
        React.createElement(TXTPreviewInner, null,
            React.createElement(ReactPrismEditor, { language: lang, theme: "default", code: data, lineNumber: true, readOnly: true, clipboard: false }))));
};
