import { css, styled } from "@enfusion-ui/web-core";
import { kebabCase } from "lodash";
import * as React from "react";
import { InputLabelControl } from "./TextInput";
const Container = styled.div `
  display: flex;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
  justify-content: center;
  font-size: 1em;
  font-weight: 500;
`;
const RadioGroupWrapper = styled.label `
  display: flex;
  align-items: center;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "small")};
  margin: ${({ vertical }) => vertical
    ? "var(--spacing) 0 0 var(--spacing)"
    : "var(--spacing-l) var(--spacing) var(--spacing) var(--spacing)"};
  cursor: pointer;

  input {
    accent-color: var(--primary);
    cursor: pointer;
  }
`;
const Orientation = styled.div `
  ${({ vertical }) => vertical
    ? css `
          display: flex;
          flex-direction: column;
          gap: var(--spacing);
          margin: 0 4px;
        `
    : css `
          display: flex;
          flex-direction: row;
          margin-left: 8px;
        `}
`;
export function RadioGroup({ options, label, name, value, onChange, errors, disabled, vertical, required, fontSize, inline, containerStyle, itemStyle, labelPlaceholder, }) {
    return (React.createElement(Container, { inline: inline, style: containerStyle },
        React.createElement(InputLabelControl, { name: name, label: label, errors: errors, required: required, labelPlaceholder: labelPlaceholder }),
        React.createElement(Orientation, { vertical: vertical }, options.map((option) => (React.createElement(RadioGroupWrapper, { key: option.label + option.value, fontSize: fontSize, style: itemStyle, vertical: vertical },
            React.createElement("input", { type: "radio", value: option.value, checked: value === option.value, onChange: () => onChange(option.value), disabled: disabled, style: { marginRight: "3px" }, "data-testid": kebabCase(`radio-${name}-${option.label}`), "data-e2e-id": kebabCase(`radio-${name}-${option.label}`) }),
            option.label))))));
}
