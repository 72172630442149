import {
  useReconciliation,
  useReconciliationBreakDetails,
} from "@app-context/reconciliation/context";
import {
  DEFAULT_DIFF_REPORT_DATA,
  GRAPH_FILTER_KEYS,
} from "@app-views/reconciliation/utils/constants";
import { getBreaksGraphData } from "@app-views/reconciliation/utils/graphUtils";
import { DiffReportSyncRow } from "@enfusion-ui/types";
import React from "react";

import {
  getBreaksData,
  getFilteredData,
  isCashDataSource,
} from "../../../utils/commonUtils";
import { ChartBlock } from "./ChartBlock";

export const BreaksByCode: React.FC<{}> = () => {
  const [chartData, setChartData] = React.useState<DiffReportSyncRow[]>([]);
  const { filterState } = useReconciliation();
  const { breakDetailsRendererData: data } = useReconciliationBreakDetails();
  const { currency, serviceAccount } = filterState;
  const { filterColumns, reportData, reconciliationType } = data;
  const allBreaksData = getBreaksData(
    (isCashDataSource(reconciliationType)
      ? reportData.cashActivityRowsStore
      : reportData.rowsStore) ?? DEFAULT_DIFF_REPORT_DATA
  );

  React.useEffect(() => {
    setChartData(getFilteredData(filterState, allBreaksData, filterColumns));
  }, [currency, serviceAccount]);

  return (
    <ChartBlock
      title="Breaks by Code"
      data={getBreaksGraphData(chartData, "breakCode")}
      type="pie"
      noDataMessage="No Break Code found"
      baseField={GRAPH_FILTER_KEYS.breakCode}
      id={data.id}
    />
  );
};
