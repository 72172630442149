import {
  GeneralLedgerEntrySource,
  generalLedgerEntrySourcesOptions,
  MultiSelectOptionsType,
} from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import { MultiSelect, MultiSelectProps } from "@enfusion-ui/web-components";
import * as React from "react";
import { OptionsType } from "react-select";

export type EntrySourceSelectionProps = Omit<
  Partial<MultiSelectProps<GeneralLedgerEntrySource>>,
  "options" | "value" | "onChange"
> & {
  value?: GeneralLedgerEntrySource[] | null;
  onChange?: (values: GeneralLedgerEntrySource[] | null) => void;
};

export const EntrySourceSelect: React.FC<
  EntrySourceSelectionProps & { label?: string; placeholder?: string }
> = ({
  value: values,
  onChange,
  placeholder = "All",
  label = "Entry Sources",
  ...rest
}) => {
  const [selectedEntrySources, setSelectedEntrySources] = React.useState<
    GeneralLedgerEntrySource[] | null
  >([]);

  React.useEffect(() => {
    setSelectedEntrySources(values ?? []);
  }, [values]);

  const handleChange = (
    selectedOptions: OptionsType<MultiSelectOptionsType> | null
  ) => {
    const newEntrySources = selectedOptions?.length
      ? selectedOptions?.map((opt) => opt.value as GeneralLedgerEntrySource)
      : null;
    setSelectedEntrySources(newEntrySources);
    onChange?.(newEntrySources);
  };

  return (
    <MultiSelect
      {...rest}
      label={label}
      value={
        selectedEntrySources
          ? getSelectedValues(
              selectedEntrySources,
              generalLedgerEntrySourcesOptions
            )
          : []
      }
      onChange={handleChange}
      options={generalLedgerEntrySourcesOptions}
      placeholder={placeholder}
      inputId="entry-source-general-ledger-selection-id"
    />
  );
};
