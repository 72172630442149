import {
  Button,
  Modal,
  SingleColumnGridWithGap,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const ContentContainer = styled.div`
  margin-top: 8px;
`;

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export type RefreshBrowserModalProps = {
  open: boolean;
  onClose: VoidFunction;
};

const RefreshBrowserModal: React.FC<RefreshBrowserModalProps> = ({
  open,
  onClose,
}) => {
  const handleClose = () => {
    window.location.reload();
    onClose();
  };

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      title="Refresh Browser"
      allowDismissal
      zIndex={200}
      content={
        <SingleColumnGridWithGap>
          <ContentContainer>Your Browser will be refreshed.</ContentContainer>
          <ActionContainer>
            <Button onClick={handleClose}>Close</Button>
          </ActionContainer>
        </SingleColumnGridWithGap>
      }
    />
  );
};

export default RefreshBrowserModal;
