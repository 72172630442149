import { useReportRows } from "@app-context/reports/ReportsProvider";
import { useReportDatasource } from "@app-views/dashboards/hooks/useReportDatasource";
import { AsyncDatasourceContent } from "@app-views/dashboards/pdf/AsyncDatasourceContent";
import { pxToPtConversion } from "@app-views/dashboards/pdf/utils";
import { ThemeDefinition } from "@enfusion-ui/core";
import { TileWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { ValuesInputType, WebReportTableSyncColumn } from "@enfusion-ui/types";
import { formatReportValue } from "@enfusion-ui/utils";
import { withTheme } from "@enfusion-ui/web-core";
import { StyleSheet, View } from "@react-pdf/renderer";
import * as React from "react";

import PdfTile from "./PdfTile";

const pdfStyles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    gap: pxToPtConversion(8),
  },
  widgetContainer: {
    width: "100%",
    height: "100%",
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PdfTileWidget = withTheme(({ theme }: { theme: ThemeDefinition }) => {
  const { config } = useWidget();
  const { gridOptions, tableId } = useReportDatasource();
  const { rowsStore } = useReportRows();
  const rowOptions = tableId ? rowsStore?.[tableId] ?? null : null;
  const { columns = [], wrap = false } = config as TileWidgetConfig;

  return (
    <AsyncDatasourceContent>
      <View
        style={[
          pdfStyles.container,
          {
            flexWrap: wrap ? "wrap" : "nowrap",
          },
        ]}
      >
        {columns.map((entry: ValuesInputType, idx: number) => {
          const column =
            (gridOptions?.metadata.columns[
              entry.value
            ] as WebReportTableSyncColumn) || null;

          const data = rowOptions ? rowOptions.totalsRow?.[entry.value] : null;

          const formattedValue = column
            ? formatReportValue(data, column, {
                numberReductionFormat: config?.numberReductionFormat,
              })
            : "";

          const isNegative =
            !!data &&
            (column?.dataType === "Integer" || column?.dataType === "Double") &&
            data.value < 0;

          return (
            <View key={idx} style={pdfStyles.widgetContainer}>
              <PdfTile
                header={entry.label || entry.value}
                value={formattedValue}
                isNegative={isNegative}
              />
            </View>
          );
        })}
      </View>
    </AsyncDatasourceContent>
  );
});

export default PdfTileWidget;
