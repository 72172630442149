/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { getFileExt } from "@enfusion-ui/utils";
import { faExclamationTriangle, faRectangleXmark, } from "@fortawesome/pro-regular-svg-icons";
import { identity, last } from "lodash";
import mime from "mime";
import * as React from "react";
import { ContentMessage } from "../../display";
import { SupportLinkErrorView } from "../../errors";
import { CenterContent } from "../../Styled";
import { CSVPreview } from "./CSVPreview";
import { IMGPreview } from "./IMGPreview";
import { PDFPreview } from "./PDFPreview";
import { ContentWrapper } from "./styles";
import { TXTPreview } from "./TXTPreview";
import { VideoPreview } from "./VideoPreview";
import { XLSXPreview } from "./XLSX/XLSXPreview";
const NoPreview = () => {
    return (React.createElement(CenterContent, null,
        React.createElement(ContentMessage, { icon: faRectangleXmark, message: "No Preview Available" })));
};
const LoadingIssue = ({ error }) => {
    return (React.createElement(ContentWrapper, null,
        React.createElement(ContentMessage, { icon: faExclamationTriangle, message: `Issue loading file: "${error}".` })));
};
function getPreviewComponent(ext) {
    switch (ext.toLocaleLowerCase()) {
        case "xlsx":
        case "xls":
            return XLSXPreview;
        case "pdf":
            return PDFPreview;
        case "txt":
        case "json":
        case "js":
        case "ts":
        case "tsx":
        case "html":
        case "vue":
        case "css":
        case "sass":
        case "md":
        case "java":
        case "php":
        case "cs":
        case "c":
        case "cpp":
        case "xml":
            return TXTPreview;
        case "csv":
            return CSVPreview;
        case "svg":
        case "png":
        case "jpg":
        case "jpeg":
        case "bitmap":
        case "bmp":
        case "gif":
            return IMGPreview;
        case "mp4":
        case "webm":
        case "mov":
            return VideoPreview;
        default:
            return NoPreview;
    }
}
function getContentModifier(ext) {
    if (ext === "json")
        return (res) => JSON.stringify(JSON.parse(res), null, 2);
    return identity;
}
export const FilePreview = ({ fileName, filePath, }) => {
    const [error, setError] = React.useState(null);
    const PreviewComponent = React.useMemo(() => getPreviewComponent(getFileExt(fileName)), [fileName]);
    const contentModifier = React.useMemo(() => getContentModifier(getFileExt(fileName)), [fileName]);
    if (error === "Not Found" || error === "Forbidden")
        return (React.createElement(SupportLinkErrorView, { component: "Preview", type: error === "Not Found" ? "missing" : "forbidden", meta: `File Path: ${filePath}` }));
    if (error !== null)
        return React.createElement(LoadingIssue, { error: error });
    return (React.createElement(PreviewComponent, { filePath: filePath, fileName: fileName, setError: setError, fileExt: getFileExt(fileName).toLowerCase(), contentType: mime.getType(last(fileName.split("."))), contentModifier: contentModifier }));
};
