import { css, getCss, styled, useThemeBase } from "@enfusion-ui/web-core";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import { getSelectStyles, selectTheme } from "./selectStyles";
import { InputLabelControl } from "./TextInput";
const SelectContainer = styled.div `
  width: 100%;
  ${({ inline }) => inline
    ? css `
          display: flex;
          align-items: center;
          gap: var(--spacing);
        `
    : ""}
`;
const preventArrowUpDownEventPropagation = (event) => {
    if (event.key === "ArrowDown" || event.key === "ArrowUp")
        event.nativeEvent.stopImmediatePropagation();
};
const ClearIndicator = (props) => {
    const { getStyles, innerProps: { ref, ...restInnerProps }, } = props;
    return (React.createElement("div", { ...restInnerProps, ref: ref, style: getStyles("clearIndicator", props) },
        React.createElement(FontAwesomeIcon, { icon: faTimes })));
};
export function Select({ name, label, errors, invalid, required, creatable = false, minWidth, disabled, clearable = true, onChange, inputId, value, labelPlaceholder, inline, defaultValue, dataE2EId, infoContent, ...rest }) {
    const theme = useThemeBase();
    const SelectComp = (creatable ? CreatableSelect : ReactSelect);
    const hasError = (invalid && !!name) || (!!errors && !!name && !!errors[name]);
    return (React.createElement(SelectContainer, { "data-e2e-id": dataE2EId || inputId || name, "data-e2e-value": JSON.stringify(value), "data-testId": `testId-${name || infoContent || inputId}`, inline: inline },
        React.createElement(InputLabelControl, { name: name, label: label, errors: errors, required: required, style: { whiteSpace: "nowrap" }, labelPlaceholder: labelPlaceholder, infoContent: infoContent }),
        React.createElement(SelectComp, { styles: {
                ...getSelectStyles(minWidth, hasError),
                menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    ...getCss(theme),
                }),
                container: (provided) => ({
                    ...provided,
                    flex: 1,
                }),
            }, theme: selectTheme, onKeyDown: preventArrowUpDownEventPropagation, components: { ClearIndicator }, menuPortalTarget: document.body, menuPlacement: "auto", isDisabled: disabled, isClearable: clearable, onChange: onChange, formatCreateLabel: (inputValue) => 'Set "' + inputValue + '"', value: value, inputId: inputId, defaultValue: defaultValue, ...rest })));
}
