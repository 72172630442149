import { pxToPtConversion } from "@app-views/dashboards/pdf/utils";
import { ThemeDefinition } from "@enfusion-ui/core";
import { WebDateRangeSelection } from "@enfusion-ui/types";
import { formatDate } from "@enfusion-ui/utils";
import { withTheme } from "@enfusion-ui/web-core";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import * as React from "react";

import { PdfDateInputWrapper } from "./PdfDateWidget";

const pdfStyles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: pxToPtConversion(4),
  },
  rangeText: {
    width: pxToPtConversion(70),
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: pxToPtConversion(24),
  },
});

type PdfDateRangeInputProps = {
  value: WebDateRangeSelection;
};

const PdfDateRangeInput = withTheme(
  ({ value, theme }: PdfDateRangeInputProps & { theme: ThemeDefinition }) => {
    const [startDateValue, setStartDateValue] = React.useState<string | null>(
      null
    );
    const [endDateValue, setEndDateValue] = React.useState<string | null>(null);

    React.useEffect(() => {
      if (value) {
        setStartDateValue(
          formatDate((value as WebDateRangeSelection).startDate?.asOfDate || "")
        );
        setEndDateValue(
          formatDate((value as WebDateRangeSelection).endDate?.asOfDate || "")
        );
      }
    }, [value]);

    return (
      <View style={[pdfStyles.container]}>
        <PdfDateInputWrapper label="Date" value={startDateValue} />
        <View style={[pdfStyles.rangeText]}>
          <Text
            style={[
              {
                color: theme.colors.textNormal,
              },
            ]}
          >
            -
          </Text>
        </View>
        <PdfDateInputWrapper value={endDateValue} />
      </View>
    );
  }
);

export default PdfDateRangeInput;
