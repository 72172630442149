import {
  AnalyticsChartEntry,
  useAnalyticsContext,
} from "@app-context/analytics/context";
import { ChangeView } from "@app-views/analytics/components/ChangeView";
import { SizedChart } from "@app-views/analytics/components/SizedChart";
import { formatterCreator } from "@app-views/analytics/utils";
import { formatPercentage } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import {
  defaultChartOptions,
  defaultTooltipOptions,
} from "../../../chartConfigs";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;

const per = (val?: number | null) =>
  formatPercentage(val ?? 0, 0, false, undefined, true);

const defaultOptions: Highcharts.Options = {
  chart: {
    ...defaultChartOptions,
    animation: false,
    type: "bar",
    spacing: [0, 0, 0, 0],
    margin: 0,
    plotShadow: false,
    plotBorderWidth: 0,
  },
  title: { text: undefined },
  credits: { enabled: false },
  tooltip: {
    ...defaultTooltipOptions,
    outside: true,
    followPointer: true,
    useHTML: true,
    formatter: formatterCreator(({ y, color, series }) => {
      return `
      <div style="display: flex; flex-direction: row; gap: var(--spacing-s); font-size: 14px; line-height: 18px;">
        <div style="color: ${color};">
          ${series.name}
        </div>
        :
        <div style="font-weight: bold;">${per(y)}</div>
      </div>`;
    }),
  },
  legend: { enabled: false },
  xAxis: {
    categories: ["-"],
    min: 0,
    lineWidth: 0,
    tickWidth: 0,
    maxPadding: 0.00001,
    gridLineWidth: 0,
    tickInterval: 0.0001,
    allowDecimals: true,
    title: { text: null },
    labels: { enabled: false },
  },
  yAxis: {
    min: 0,
    lineWidth: 0,
    tickWidth: 0,
    maxPadding: 0.00001,
    gridLineWidth: 0,
    tickInterval: 0.0001,
    allowDecimals: true,
    title: { text: null },
    labels: { enabled: false },
  },
  plotOptions: {
    bar: {
      stacking: "normal",
      borderWidth: 0,
      borderRadius: {
        radius: 5,
        scope: "stack",
        where: "all",
      },
      pointWidth: 21,
      dataLabels: {
        enabled: true,
        useHTML: true,
        formatter: formatterCreator(
          ({ y }) =>
            `<span style="color: var(--text-inverted); text-shadow: 0 0 2px var(--text-normal); font-size: 12px">${per(
              y
            )}</span>`
        ),
      },
      pointPadding: 0,
      groupPadding: 0,
      shadow: false,
    },
  },
};

const getOptionsBase = (
  width?: number | null,
  entry?: AnalyticsChartEntry<{
    new: number;
    removed: number;
    increased: number;
    decreased: number;
  }>
): Highcharts.Options => ({
  ...defaultOptions,
  chart: {
    ...defaultOptions.chart,
    width,
    height: 21,
  },
  series: [
    {
      type: "bar",
      name: "New",
      data: [entry?.data.new ?? 0],
    },
    {
      type: "bar",
      name: "Increased",
      data: [entry?.data.increased ?? 0],
    },
    {
      type: "bar",
      name: "Decreased",
      data: [entry?.data.decreased ?? 0],
    },
  ],
});

const ValueLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 28.8px;
  font-weight: 700;
  text-align: center;
  color: var(--text-normal);
`;

const Label = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  color: var(--text-color-1);
  margin-bottom: var(--spacing-l);
`;

const ChangeLabel = styled(ChangeView)`
  margin-left: var(--spacing);
  font-size: 14px;
`;

export const MetricChart: React.FC<{
  label: string;
  value?: number | null;
  change?: number | null;
  entry?: AnalyticsChartEntry<{
    new: number;
    removed: number;
    increased: number;
    decreased: number;
  }>;
}> = ({ entry, label, value = 0, change }) => {
  const { data } = useAnalyticsContext();

  const metrics = React.useMemo(
    () => data?.portfolioMovement.metrics ?? null,
    [data?.portfolioMovement.metrics]
  );

  const getOptions = React.useCallback(
    (width: number) => getOptionsBase(width, entry),
    [metrics?.returns, entry]
  );

  const hasChange = typeof change === "number";

  return (
    <Container>
      <ValueLabel>
        {per(value)}
        {hasChange && <ChangeLabel change={change} hideIcon showPositive />}
      </ValueLabel>
      <Label>{label}</Label>
      <div style={{ width: "100%", height: "21px" }}>
        <SizedChart getOptions={getOptions} />
      </div>
    </Container>
  );
};
