import ControlledAccountSelect from "@app-components/inputs/account/ControlledAccountSelect";
import { WebReportBookSelection } from "@enfusion-ui/types";
import { PanelRow } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

import ControlledBookSelect from "../../../components/ReportQueryComponents/BookSelect";
import { DateSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledMarketEnvironmentSelect } from "../../../components/ReportQueryComponents/MarketEnvironmentSelect";
import { ControlledPortfolioGroupsSelect } from "../../../components/ReportQueryComponents/PortfolioGroupsSelect";

const InputContainer = styled.div`
  min-width: 250px;
`;

const PositionCashFlowQuickFilter = () => {
  const { bookSelection } = useWatch({
    name: ["bookSelection"],
  });

  const defaultBookSelectionRef = React.useRef(
    bookSelection as WebReportBookSelection
  );

  return (
    <PanelRow>
      <ControlledAccountSelect label="Accounts(s)" />
      <InputContainer>
        <ControlledPortfolioGroupsSelect name="portfolioGroupIds" />
      </InputContainer>
      <InputContainer>
        <ControlledBookSelect
          defaultBookSelection={defaultBookSelectionRef.current}
        />
      </InputContainer>
      <InputContainer>
        <DateSelection
          name="dateSelection"
          label="Value Date"
          defaultValue={undefined}
        />
      </InputContainer>
      <InputContainer>
        <ControlledMarketEnvironmentSelect label="Market Environment" />
      </InputContainer>
    </PanelRow>
  );
};

export default PositionCashFlowQuickFilter;
