import { useAccounts } from "@app-context/accounts/context";
import { useAllocations } from "@app-context/allocation/context";
import {
  findAccountData,
  findNode,
} from "@app-views/oems/utils/searchTreeNode";
import {
  AllocationEntryDetails,
  OptimizedAllocation,
} from "@enfusion-ui/types";
import { REST_API } from "@enfusion-ui/web-core";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import * as React from "react";

const useAllocationsList = (orderId: number | undefined) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [allocations, setAllocations] = React.useState<
    AllocationEntryDetails[]
  >([]);
  const { nodes } = useAccounts();
  const { books } = useAllocations();

  React.useEffect(() => {
    const getAllocations = async () => {
      try {
        setLoading(true);
        setError(null);
        if (orderId) {
          const optimizedAllocations: OptimizedAllocation[] =
            await REST_API.OEMS.GET_OPTIMIZED_ALLOCATION.FETCH(orderId);

          const allocations: AllocationEntryDetails[] =
            optimizedAllocations && optimizedAllocations.length > 0
              ? optimizedAllocations.map(
                  (eachRowData: OptimizedAllocation, idx: number) => {
                    const nodeFamily = findAccountData(
                      eachRowData.entry.accountId ?? 0,
                      nodes
                    );
                    const accountNode = nodeFamily?.find(
                      (i) => i.level === "Account"
                    );
                    const custodianNode = nodeFamily?.find(
                      (i) => i.level === "Custodian"
                    );
                    const fundNode = nodeFamily?.find(
                      (i) => i.level === "Fund"
                    );

                    const bookNodes = findNode(
                      eachRowData.entry.bookId || 0,
                      books,
                      "bookId"
                    );
                    const bookInfo =
                      bookNodes && bookNodes.length > 0 ? bookNodes[0] : null;

                    return {
                      ...eachRowData.entry,
                      // eslint-disable-next-line camelcase
                      __row_key:
                        eachRowData.entry.id === 0
                          ? `${idx}`
                          : `${eachRowData.entry.id}`,

                      positionBlock: null,

                      accountId: accountNode ? accountNode.id : null,
                      accountName: accountNode ? accountNode.name : "",
                      fundId: fundNode ? fundNode.id : null,
                      fundName: fundNode ? (fundNode.shortName as string) : "",
                      custodian: custodianNode ? custodianNode.name : "",
                      custodianId: custodianNode ? custodianNode.id : null,
                      bookId: bookInfo ? bookInfo.id : null,
                      book: bookInfo ? bookInfo.name : "",
                      allocationPct: getAllocationPct(
                        eachRowData.entry.allocationQuantity * 100
                      ),
                      allocationQuantity: eachRowData.quantity ?? 0,
                    };
                  }
                )
              : [];

          setAllocations(allocations);
        } else {
          setAllocations([]);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (
          error.message === "NullPointer" ||
          error.message?.includes('"allocations" is null')
        ) {
          setAllocations([]);
        } else if (error.name === "AbortError") {
          console.info(
            `Api request for fetching allocation for orderId: ${orderId} has been aborted`
          );
        } else {
          setError("Failed to fetch Order Allocations");
        }
      } finally {
        setLoading(false);
      }
    };
    getAllocations();
  }, [orderId, nodes, books]);

  const getAllocationPct = (allocationQuantity: number) => {
    return `${
      Number.isInteger(allocationQuantity)
        ? allocationQuantity
        : allocationQuantity.toFixed(2)
    }%`;
  };

  const gridApi = React.useRef<GridApi>();
  const onGridReady = ({ api }: GridReadyEvent) => {
    gridApi.current = api;
  };

  return { allocations, gridApi, onGridReady, loading, error };
};

export default useAllocationsList;
