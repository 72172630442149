import { useReportRows } from "@app-context/reports/ReportsProvider";
import { useRefCallback } from "@enfusion-ui/hooks";
import { WebReportQuery } from "@enfusion-ui/types";
import { useReports } from "@enfusion-ui/web-core";
import { debounce } from "lodash";
import * as React from "react";
import { v4 } from "uuid";

export function useReportData(
  query: WebReportQuery,
  deps: React.DependencyList = []
) {
  const id = React.useRef(v4());
  const { loadReport, dataStore, metaStore } = useReports();
  const { rowsStore } = useReportRows();

  const tableId = id.current;

  const metadata = tableId ? metaStore?.[tableId] ?? null : null;
  const errorLoading = metadata ? metadata.error : undefined;

  const gridOptions = tableId ? dataStore?.[tableId] ?? null : null;
  const rowOptions = tableId ? rowsStore?.[tableId] ?? null : null;

  const reloadReport = useRefCallback(
    debounce(() => {
      loadReport({
        reportId: id.current,
        name: id.current,
        path: query.path ?? id.current,
        reportQuery: query,
        closeFirst: true,
      });
    }, 300),
    [query]
  );

  React.useEffect(() => {
    reloadReport();
  }, [query, ...deps]);

  return {
    errorLoading,
    gridOptions,
    rowOptions,
    reloadReport,
    metadata,
    tableId,
  };
}
