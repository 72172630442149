import { AppLogging, errorToast } from "@enfusion-ui/web-core";

export async function errorCatch(
  cb: () => Promise<boolean | void>,
  msg: string,
  toast = true
) {
  try {
    const res = await cb();
    if (res === false) throw new Error();
    return true;
  } catch (err) {
    AppLogging.safeError(msg, err);
    if (toast) errorToast(msg, (err as Error).message);
    return false;
  }
}
