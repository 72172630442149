import ControlledAccountSelect from "@app-components/inputs/account/ControlledAccountSelect";
import { basicInfoMap, useRestAbortableOptions } from "@enfusion-ui/hooks";
import {
  CALCULATION_TYPE_OPTIONS,
  FUND_BENCHMARK_TYPE_OPTIONS,
  MultiSelectOptionsType,
  NAV_SOURCE_OPTIONS,
  POSITION_SOURCE_OPTIONS,
} from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  Checkbox,
  ControlledCheckbox,
  ControlledInputBase,
  ControlledSelect,
  MultiSelect,
  PanelRow,
} from "@enfusion-ui/web-components";
import { REST_API, styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import ControlledBookSelect from "../../../components/ReportQueryComponents/BookSelect";
import { DatePeriodSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledFinancialSubTypeSelect } from "../../../components/ReportQueryComponents/FinancialSubTypeSelect";
import { InstrumentsMultiSelect } from "../../../components/ReportQueryComponents/InstrumentsMultiselect";
import { ControlledMarketEnvironmentSelect } from "../../../components/ReportQueryComponents/MarketEnvironmentSelect";
import { ControlledPortfolioGroupsSelect } from "../../../components/ReportQueryComponents/PortfolioGroupsSelect";

const StyledPanelRow = styled(PanelRow)`
  margin-top: 4px;
  margin-bottom: 4px;
`;

const PortfolioAnalyticsSettings = () => {
  const { watch, setValue } = useFormContext();
  const { bookSelection, portfolioAnalyticsSources } = watch([
    "bookSelection",
    "portfolioAnalyticsSources",
  ]);
  const bookSelectionRef = React.useRef(bookSelection);
  const [realTimeChecked, setRealTimeChecked] = React.useState(false);

  const { options: customBenchmarkOptions, loading } = useRestAbortableOptions(
    REST_API.MARKETDATA.GET_CUSTOM_BENCHMARKS.FETCH,
    basicInfoMap
  );

  return (
    <>
      <ControlledAccountSelect label="Account(s)" />
      <ControlledPortfolioGroupsSelect />
      <ControlledBookSelect defaultBookSelection={bookSelectionRef.current} />
      <StyledPanelRow>
        <ControlledCheckbox
          name="includeUnderlyings"
          label="Include Underlyings"
          labelPlacement="right"
        />
      </StyledPanelRow>
      <InstrumentsMultiSelect />
      <ControlledFinancialSubTypeSelect name="financialSubTypes" />
      <StyledPanelRow>
        <DatePeriodSelection
          name="valueDatePeriodSelection"
          label="Value Date"
        />
        <ControlledInputBase
          name="portfolioAnalyticsSources"
          render={({ ref: _ref, onChange, ...rest }) => (
            <MultiSelect
              {...rest}
              value={
                portfolioAnalyticsSources
                  ? getSelectedValues(
                      portfolioAnalyticsSources,
                      POSITION_SOURCE_OPTIONS
                    )
                  : []
              }
              onChange={(selectedOptions) => {
                const portfolioAnalyticsSources =
                  selectedOptions?.map(
                    (option: MultiSelectOptionsType) => option.value
                  ) || [];
                setValue("errorSeverities", portfolioAnalyticsSources);
                onChange(portfolioAnalyticsSources);
              }}
              label="Position Source"
              options={POSITION_SOURCE_OPTIONS}
            />
          )}
        />
      </StyledPanelRow>
      <ControlledMarketEnvironmentSelect />
      <StyledPanelRow>
        <ControlledCheckbox
          name="expandIndices"
          label="Expand Indices?"
          labelPlacement="right"
        />
        <ControlledCheckbox
          name="expandETFs"
          label="Expand ETFs?"
          labelPlacement="right"
        />
        <ControlledCheckbox
          name="expandIndexDerivatives"
          label="Expand Index Derivatives?"
          labelPlacement="right"
        />
      </StyledPanelRow>
      <StyledPanelRow>
        <ControlledCheckbox
          name="expandETFDerivatives"
          label="Expand ETF Derivatives?"
          labelPlacement="right"
        />
        <ControlledCheckbox
          name="expandSpreads"
          label="Expand Spreads?"
          labelPlacement="right"
        />
        <Checkbox
          label="Prefer RealTime?"
          labelPlacement="right"
          onChange={() => setRealTimeChecked(!realTimeChecked)}
          checked={realTimeChecked}
        />
      </StyledPanelRow>
      <StyledPanelRow>
        <ControlledSelect
          name="fundBenchmarkType"
          options={FUND_BENCHMARK_TYPE_OPTIONS}
          label="Fund Benchmark"
        />
        <ControlledSelect
          name="customBenchmarkId"
          options={customBenchmarkOptions}
          label="Custom Benchmark"
          isLoading={loading}
        />
      </StyledPanelRow>
      <StyledPanelRow>
        <ControlledSelect
          name="benchmarkNAVSource"
          options={NAV_SOURCE_OPTIONS}
          label="NAV Source"
        />
        <ControlledSelect
          name="benchmarkCalculationType"
          options={CALCULATION_TYPE_OPTIONS}
          label="Calculation Type"
        />
      </StyledPanelRow>
      <StyledPanelRow>
        <ControlledCheckbox
          name="resolveBenchmarkByBook"
          label="Hierarchy and Fund Based Targets"
          labelPlacement="right"
        />
      </StyledPanelRow>
    </>
  );
};

export default PortfolioAnalyticsSettings;
