/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelectDataValue } from "@app-views/dashboards/pdf/utils";
import {
  GenericInputSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types/build/dashboards";
import * as React from "react";

import PdfSelectionWidget from "../PdfSelectionWidget";

const PdfGenericInputSelectionWidget: React.FC<unknown> = () => {
  const { config, editedConfig } = useWidget();
  const { defaultValue, key, showLabel } =
    config as GenericInputSelectionWidgetConfig;
  const [inputValue, setInputValue] = React.useState<string | null>(null);

  useSelectDataValue(
    key,
    "Generic Input",
    ChannelDataType.String,
    defaultValue
  );

  React.useEffect(() => {
    if (defaultValue) {
      const selectedValue = editedConfig?.genericInputOptions?.find(
        ({ value: val }: any) => defaultValue === val
      );
      setInputValue(selectedValue?.label || null);
    }
  }, [editedConfig?.genericInputOptions]);

  return (
    <PdfSelectionWidget
      label={typeof showLabel === "boolean" && !showLabel ? "" : key}
      value={inputValue}
    />
  );
};

export default PdfGenericInputSelectionWidget;
