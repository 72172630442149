import { pwbColumnDescriptions } from "@enfusion-ui/core";
import { capitalize, omit, words } from "lodash";
function typeGet(name) {
    if (name.includes("%"))
        return 0; // percent
    if (name.includes(" Ex ") ||
        name.includes(" Ex") ||
        name.includes("NAV") ||
        name.includes("Exposure") ||
        // name.includes("MV") ||
        // name.includes("Market Value") ||
        name.includes("Price"))
        return 1; // ccy
    return 2; // other
}
function fixQty(name) {
    if (name === "Qty")
        return "Quantity";
    return name;
}
function classGet(name) {
    if (name.startsWith("Model"))
        return "Model";
    if (name.startsWith("Benchmark"))
        return "Benchmark";
    if (name.startsWith("Inc"))
        return fixQty(name.replace("Inc ", ""));
    if (name.startsWith("Incremental"))
        return fixQty(name.replace("Incremental ", ""));
    if (name.startsWith("Final"))
        return fixQty(name.replace("Final ", ""));
    if (name.startsWith("Current"))
        return fixQty(name.replace("Current ", ""));
    if (name === "Qty")
        return fixQty(name);
    return "Other";
}
const CalcTypeMap = [
    "percentInputCell",
    "currencyInputCell",
    "doubleInputCell",
];
const InfoTypeMap = ["percentColumn", "currencyColumn", "descriptionColumn"];
function mapName(field) {
    return words(field)
        .map((i, idx) => {
        // if (i === "NMV") return "Market Value";
        if (i === "Nav")
            return "NAV";
        if (i === "Percent")
            return "%";
        if (idx === 0)
            return capitalize(i);
        return i;
    })
        .join(" ");
}
function shortenName(name) {
    return name
        .replace("Quantity", "Qty")
        .replace("Exposure", "Ex")
        .replace("Incremental", "Inc");
    // .replace("Market Value", "MV");
}
function mapToColDef(def, editable = false, override) {
    const c = typeof def === "string" ? { f: def } : def;
    const name = c.n ? c.n : mapName(c.f);
    const sName = shortenName(name);
    const other = omit(c, ["f", "n", "w", "c", "p", "t"]);
    const desc = pwbColumnDescriptions[c.f];
    const headerTooltip = desc !== undefined ? desc : c.p ? c.p : name;
    return {
        ...other,
        headerName: sName,
        headerTooltip,
        field: c.f,
        colId: c.f,
        type: c.t
            ? c.t
            : editable
                ? CalcTypeMap[typeGet(sName)]
                : InfoTypeMap[typeGet(sName)],
        headerClass: c.c ? c.c : classGet(sName),
        initialWidth: c.w ? c.w : editable ? undefined : 120,
        ...override,
    };
}
const mainCalcDefs = [
    "finalCTD",
    "finalNAV",
    "finalExposure",
    "finalExposurePercentNAV",
    // "finalNMV",
    "finalDuration",
    // "finalFundNAV",
    "finalQuantity",
    "incrementalCTD",
    "incrementalNAV",
    "incrementalExposure",
    "incrementalExposurePercentNAV",
    // "incrementalNMV",
    "incrementalDuration",
    // "incrementalFundNAV",
    "incrementalQuantity",
];
export const mainGridCalcColumnDefs = mainCalcDefs.map((i) => mapToColDef(i, true, {
    pinned: "right",
    lockPinned: true,
    resizable: false,
    suppressSizeToFit: true,
    suppressAutoSize: true,
}));
const mainInfoDefs = [
    {
        f: "sector",
        t: "textColumn",
        c: "Sectors",
    },
    "issuer",
    {
        f: "displayInstrument",
        n: "Instrument",
        w: 140,
        c: "Symbology",
    },
    { f: "fund", enableRowGroup: false, c: "Portfolios" },
    { f: "account", c: "Portfolios" },
    { f: "benchmarkName", t: "textColumn", n: "Benchmark" },
    { f: "price", w: 100, t: "priceColumn" },
    { f: "duration", n: "Current Duration", t: "doubleColumn" },
    "currentExposure",
    { f: "currentExposurePercentNAV", w: 140 },
    "currentNAV",
    { f: "modelExposurePercentNAV", w: 130 },
    {
        f: "modelPercentDifference",
        n: "Model Ex % NAV Difference",
        w: 130,
        c: "Ex % NAV",
    },
    { f: "benchmarkQuantity", w: 130, t: "doubleColumn" },
    { f: "modelQuantity", w: 130, t: "doubleColumn" },
    { f: "benchmarkExposure", w: 130 },
    { f: "modelExposure", w: 130 },
    {
        f: "benchmarkQuantityDifference",
        w: 130,
        t: "doubleColumn",
    },
    {
        f: "modelQuantityDifference",
        w: 130,
        t: "doubleColumn",
    },
    { f: "benchmarkExposureDifference", w: 130 },
    { f: "modelExposureDifference", w: 130 },
    // "currentNMV",
    { f: "benchmarkExposurePercentNAV", w: 140, c: "Ex % NAV" },
    { f: "benchmarkDuration", w: 140, t: "doubleColumn", c: "Duration" },
    { f: "modelDuration", w: 130, t: "doubleColumn" },
    { f: "currentCTD", n: "Current CTD", w: 130, t: "doubleColumn", c: "CTD" },
    { f: "modelCTD", n: "Model CTD", w: 130, t: "doubleColumn", c: "CTD" },
    {
        f: "benchmarkCTD",
        n: "Benchmark CTD",
        w: 140,
        t: "doubleColumn",
        c: "CTD",
    },
    {
        f: "benchmarkPercentDifference",
        n: "Benchmark Ex % NAV Difference",
        w: 190,
        c: "Ex % NAV",
    },
    // { f: "fundNAV", w: 140 },
    { f: "currentQuantity", w: 140, t: "doubleColumn" },
    { f: "instrumentType", c: "Instrument Types" },
    { f: "instrumentSubtype", c: "Instrument Types" },
    { f: "assetClass", c: "Instrument Types" },
    { f: "country", c: "Geography" },
    { f: "riskCountry", c: "Geography" },
    { f: "instrumentCcy", c: "Instrument Types" },
    { f: "industryGroup", c: "Sectors" },
    { f: "industry", c: "Sectors" },
    { f: "subIndustry", c: "Sectors" },
    { f: "ticker", c: "Symbology" },
    { f: "ric", n: "RIC", c: "Symbology" },
    { f: "cusip", n: "CUSIP", c: "Symbology" },
    { f: "isin", n: "ISIN", c: "Symbology" },
    { f: "sedol", n: "SEDOL", c: "Symbology" },
    { f: "bbYellow", n: "BBG Id", c: "Symbology" },
    {
        f: "fundShortName",
        n: "Fund SN",
        p: "Fund Code / Fund Short Name",
        c: "Portfolios",
    },
];
export const mainGridInfoColumnDefs = mainInfoDefs.map((i) => mapToColDef(i, false, { lockPinned: true }));
export const mainPortfolioGridColumnDefs = [
    {
        headerName: "",
        field: "selection",
        colId: "selection",
        headerTooltip: "Lock Status",
        width: 27,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        pinned: "left",
        lockPinned: true,
        lockVisible: true,
        suppressHeaderMenuButton: true,
        resizable: false,
        rowGroup: false,
        suppressNavigable: true,
        suppressMovable: true,
        enableRowGroup: false,
        type: "textColumn",
        filter: false,
    },
    ...mainGridInfoColumnDefs,
    ...mainGridCalcColumnDefs,
];
export const groupingColumns = mainPortfolioGridColumnDefs.filter((i) => ["textColumn", "descriptionColumn"].includes(i.type) &&
    (i.enableRowGroup !== false || i.rowGroup === true));
const nonIsolationKeys = ["Other"];
const groupedColumns = mainPortfolioGridColumnDefs.reduce((res, entry) => {
    if (entry.headerClass && !res.has(entry.headerClass)) {
        res.set(entry.headerClass, [entry.colId]);
    }
    else if (entry.headerClass && res.has(entry.headerClass)) {
        const prev = res.get(entry.headerClass);
        res.set(entry.headerClass, [...prev, entry.colId]);
    }
    return res;
}, new Map());
export const isolationKeywords = [...groupedColumns.keys()].filter((i) => !nonIsolationKeys.includes(i));
export const baseMapColumns = groupedColumns.get("Other") || [];
export const keywordToColumnMap = groupedColumns;
export const ColIdToAdjustmentMap = {
    incrementalDuration: "IncrementalWeightedDuration",
    incrementalNAV: "IncrementalNAV",
    incrementalExposure: "IncrementalExposure",
    incrementalExposurePercentNAV: "IncrementalPercentNAV",
    incrementalQuantity: "IncrementalQuantity",
    incrementalCTD: "IncrementalDuration",
    // incrementalNMV: "IncrementalNMV",
    // incrementalFundNAV: "IncrementalFundNAV",
    finalDuration: "FinalWeightedDuration",
    finalExposure: "FinalExposure",
    finalExposurePercentNAV: "FinalPercentNAV",
    finalNAV: "FinalNAV",
    finalQuantity: "FinalQuantity",
    finalCTD: "FinalDuration",
    // finalNMV: "FinalNMV",
    // finalFundNAV: "FinalFundNAV",
    price: "Price",
};
export const AdjustmentToColIdMap = Object.entries(ColIdToAdjustmentMap).reduce((res, [value, key]) => ({
    ...res,
    [key]: value,
}), {});
