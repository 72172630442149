import { useRefCallback } from "@enfusion-ui/hooks";
import * as React from "react";

type WatchListExplorerContextType = {
  createWatchListModal: boolean;
  openCreateWatchListModal: VoidFunction;
  closeCreateWatchListModal: VoidFunction;
};

const WatchListExplorerContext = React.createContext<
  WatchListExplorerContextType | undefined
>(undefined);

export function useWatchListExplorer() {
  const context = React.useContext(WatchListExplorerContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useWatchListExplorer must be used within a WatchListExplorerProvider"
    );
  }
  return context;
}

export default function WatchListExplorerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [createWatchListModal, setCreateWatchListModal] =
    React.useState<boolean>(false);

  const openCreateWatchListModal = useRefCallback(
    () => setCreateWatchListModal(true),
    []
  );

  const closeCreateWatchListModal = useRefCallback(
    () => setCreateWatchListModal(false),
    []
  );

  return (
    <WatchListExplorerContext.Provider
      value={{
        createWatchListModal,
        openCreateWatchListModal,
        closeCreateWatchListModal,
      }}
    >
      {children}
    </WatchListExplorerContext.Provider>
  );
}
