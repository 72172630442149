import { TextInputWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import {
  Checkbox,
  FormSectionAccordion,
  TextInput,
} from "@enfusion-ui/web-components";
import * as React from "react";

const TextInputEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange = (key: string) => (value: boolean) => {
    changeConfigKeyToBeApplied(key, value);
  };

  const {
    defaultValue = "",
    textarea = false,
    key = "Text Input",
  } = editedConfig as TextInputWidgetConfig;

  return (
    <>
      <FormSectionAccordion title="Text Input Options">
        <Checkbox
          checked={textarea}
          onChange={handleBasicChange("textarea")}
          label="Use Text Area"
        />

        <TextInput
          label="Key"
          value={key}
          name="key"
          onChange={handleTextChange("key")}
        />
        <TextInput
          value={defaultValue}
          label="Default Value"
          onChange={handleTextChange("defaultValue")}
        />
      </FormSectionAccordion>
    </>
  );
};

export default TextInputEditWidget;
