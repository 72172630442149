import {
  AUTH_EVENT,
  authEventMessage,
  BSSO_TOKEN_KEY,
  checkBbgEnabled,
  GoogleAnalyticsCustomFields,
} from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { useUserAgent } from "@enfusion-ui/hooks/build/web";
import { ConnectionStatus } from "@enfusion-ui/types";
import {
  BrowserErrorView,
  Button,
  ErrorPage,
  useConfirmationModal,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  AUTH_CHANNEL,
  checkBrowserCompatibility,
  errorToast,
  infoToast,
  IS_LOCAL,
  REST_API,
  styled,
  useAuth,
} from "@enfusion-ui/web-core";
import { TabsProvider } from "@enfusion-ui/web-layout";
import { useCookieConsent } from "@use-cookie-consent/core";
import customProtocolCheck from "custom-protocol-check";
import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useCopyToClipboard } from "react-use";

import {
  ConnectionType,
  useConnectionStatus,
} from "../context/connectionStatus/context";
import AuthenticatedApp from "./AuthenticatedApp";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const StyledButton = styled(Button)`
  margin-top: 12px;
`;

function WebApp() {
  const { user, isEnabled, isUserType } = useAuth();
  const { updateStatus } = useConnectionStatus();
  const { consent } = useCookieConsent();
  const agent = useUserAgent();

  const isMainWindow = React.useMemo(
    () => !window.location.pathname?.includes("/pop-out"),
    []
  );

  const [isCompatible, setIsCompatible] = React.useState(true);
  const factsetTimeoutRef = React.useRef<number | undefined>();
  const [clipboardStateIgnored, copyToClipboard] = useCopyToClipboard();
  const isServiceUserType = isUserType("Service");

  const BssoGrantTypeIssueConfirmation = useConfirmationModal({
    title: "Issue Getting Bloomberg Token",
    onSubmit: () => window.location.reload(),
    submitButtonText: "Reload",
    renderContent: () =>
      "There was an issue getting your Bloomberg token. Reload to retry?",
  });

  const checkBgg = useRefCallback(async () => {
    const key = process.env.REACT_APP_BLOOMBERG_APP;
    if (checkBbgEnabled(isEnabled, user, key, agent.os.name)) {
      if (!user?.bsso?.accessToken) {
        try {
          customProtocolCheck(
            `bbg://screens/BAPP%20${key}%20/`,
            () => {
              updateStatus(
                ConnectionType.BloombergRealtime,
                ConnectionStatus.ERROR
              );
              errorToast(
                "You must be on a device with a Bloomberg terminal.",
                `If using citrix please use {BAPP ${key} <go>}.`,
                {
                  autoClose: false,
                  onClick: () => {
                    copyToClipboard(`BAPP ${key} <go>`);
                    infoToast("Copied to clipboard");
                  },
                }
              );
            },
            () => {
              infoToast("Redirecting to Bloomberg terminal");
            }
          );
        } catch (err) {
          AppLogging.error("BBG redirect error", err);
        }
      } else {
        try {
          const token = await user?.bsso.getToken();
          if (token === null) throw new Error("Failed to get token");
          await REST_API.MARKETDATA.SET_BLOOMBERG_TOKEN.FETCH(token);
          updateStatus(
            ConnectionType.BloombergRealtime,
            ConnectionStatus.CONNECTED
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          AppLogging.error("Bloomberg connection error", e);
          updateStatus(
            ConnectionType.BloombergRealtime,
            ConnectionStatus.ERROR
          );
          if (e.message === "invalid_grant") {
            localStorage.removeItem(BSSO_TOKEN_KEY);
            BssoGrantTypeIssueConfirmation.openModal();
          }
        }
      }
    }
  }, [user, agent.os.name, updateStatus]);

  React.useEffect(() => {
    const compatible = checkBrowserCompatibility(agent);
    setIsCompatible(compatible);

    const handleFactSetToken = async (token: string) => {
      clearTimeout(factsetTimeoutRef.current);

      try {
        await REST_API.MARKETDATA.SET_FACTSET_TOKEN.FETCH(token);
        updateStatus(ConnectionType.Factset, ConnectionStatus.CONNECTED);
      } catch (e) {
        AppLogging.error("Factset connection error", e);
        updateStatus(ConnectionType.Factset, ConnectionStatus.ERROR);
      }
    };

    const handler = (event: MessageEvent) => {
      if (
        event.data.type === "token_updated" &&
        event.origin === "https://mlp.factset.com"
      ) {
        handleFactSetToken(event.data.token);
      }
    };

    factsetTimeoutRef.current = setTimeout(() => {
      if (isEnabled("FactSet") && isMainWindow) {
        updateStatus(ConnectionType.Factset, ConnectionStatus.ERROR);
      }
    }, 5000) as unknown as number;

    if (compatible && isMainWindow) checkBgg();

    window.addEventListener("message", handler);
    return () => {
      window.removeEventListener("message", handler);
    };
  }, [isEnabled, isMainWindow]);

  React.useEffect(() => {
    if (user && !IS_LOCAL && consent.statistics && isMainWindow) {
      window.ga("set", GoogleAnalyticsCustomFields.UserId, user.username);
      if (user.flags)
        window.ga(
          "set",
          GoogleAnalyticsCustomFields.PO,
          user.flags.processingOrgName
        );
    }
  }, [user, isMainWindow]);

  if (!isCompatible) return <BrowserErrorView />;

  if (isServiceUserType) {
    return (
      <ErrorPage message="Service account type users are not able to use the UI applications.">
        <StyledButton
          primary
          onClick={() =>
            AUTH_CHANNEL.broadcastMessage(authEventMessage(AUTH_EVENT.LOGOUT))
          }
        >
          Sign Out
        </StyledButton>
      </ErrorPage>
    );
  }

  return (
    <TabsProvider>
      <Router>
        <Switch>
          <Route path="/linking">
            <AuthenticatedApp linked />
          </Route>
          <Route path="/pop-out">
            <AuthenticatedApp undocked />
          </Route>
          <Route path="/">
            <AuthenticatedApp />
          </Route>
        </Switch>
      </Router>
      {user && isEnabled("FactSet") && isMainWindow ? (
        <iframe
          id="factset auth frame"
          src={`https://mlp.factset.com/datafeed?idpid=${user.flags?.FactSet.idpId}`}
          style={{ display: "none" }}
          title="factset auth frame"
        />
      ) : null}
      {BssoGrantTypeIssueConfirmation.content}
    </TabsProvider>
  );
}

export default WebApp;
