import {
  FileExplorerEntryContextArguments,
  TOAST_CONTENT,
} from "@enfusion-ui/core";
import {
  AppEvent,
  AppEventCategories,
  NodeData,
  WebWatchListInfo,
} from "@enfusion-ui/types";
import { getFileName } from "@enfusion-ui/utils";
import {
  AccordionContext,
  CenterContent,
  ConfirmationModal,
  ContentMessage,
  FileExplorer,
  PositionedPortal,
  Spinner,
  useNavBarState,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  errorToast,
  styled,
  successToast,
  useContextMenu,
} from "@enfusion-ui/web-core";
import {
  faChartBar,
  faEdit,
  faPlus,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu, { MenuItem } from "rc-menu";
import * as React from "react";

import { TutorialType } from "../../components/Tour/utils";
import { useWatchList } from "../../context/watchList/context";
import { useWatchListExplorer } from "../../context/watchList/WatchListExplorerProvider";
import WatchlistRenameModal from "./components/RenameWatchlistModal";

const WatchListFileExplorer = styled(FileExplorer)`
  flex: 1;
  overflow-x: hidden;
`;

const WatchListExplorer: React.FC<{ tutorial?: TutorialType | null }> = ({
  tutorial,
}) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState<boolean>(false);
  const [renameConfirmationOpen, setRenameConfirmationOpen] =
    React.useState<boolean>(false);

  const { setMenu } = React.useContext(AccordionContext);
  const {
    loading,
    error,
    watchListNodes,
    openWatchListTab,
    saveWatchList,
    deleteWatchList,
  } = useWatchList();
  const { closeNavBarTabOnMobile } = useNavBarState();

  const {
    contextMenuOpen,
    contextMenuNode,
    closeContextMenu,
    openContextMenu,
    handleContextMenuPlacement,
  } = useContextMenu<{
    node: NodeData | null;
    clientY: number | null;
    clientX: number | null;
  }>();

  const { openCreateWatchListModal } = useWatchListExplorer();

  React.useEffect(() => {
    setMenu({
      menu: [
        {
          key: "newWatchList",
          title: "Add WatchList",
          icon: faPlus,
          onClick: openCreateWatchListModal,
        },
      ],
    });
  }, []);

  const handleEntryContext = React.useCallback(
    ({ node, clientX, clientY }: FileExplorerEntryContextArguments) => {
      if (node) {
        if (node.file) {
          openContextMenu({ node, clientX, clientY });

          AppLogging.event(
            {
              event: AppEvent.OpenContextMenu,
              category: AppEventCategories.WatchLists,
            },
            {
              path: node?.path || "",
              file: node?.file || false,
            }
          );
        }
      }
    },
    []
  );

  const handleClick = React.useCallback(
    (node: NodeData) => {
      openWatchListTab(node.info as WebWatchListInfo);
      closeNavBarTabOnMobile();
    },
    [closeNavBarTabOnMobile]
  );

  const openDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteWatchlist = async () => {
    if (contextMenuNode?.node) {
      const isSuccess = await deleteWatchList(
        parseInt(contextMenuNode.node.id)
      );
      if (isSuccess) {
        successToast(TOAST_CONTENT.WatchLists.delete.success);
      } else {
        errorToast(TOAST_CONTENT.WatchLists.delete.failure);
      }
      closeDeleteConfirmation();
    }
  };

  const openRenameWatchlistConfirmation = () => {
    setRenameConfirmationOpen(true);
  };

  const closeRenameWatchlistConfirmation = () => {
    setRenameConfirmationOpen(false);
  };

  const handleRenameWatchlist = async (name: string) => {
    try {
      const info = contextMenuNode?.node?.info as WebWatchListInfo;
      if (info) {
        await saveWatchList({ ...info, name });
        successToast(TOAST_CONTENT.WatchLists.rename.success);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(`Error while renaming watchlist: ${error.message}`);
      errorToast(TOAST_CONTENT.WatchLists.rename.failure);
    } finally {
      closeRenameWatchlistConfirmation();
    }
  };

  if (loading) {
    return (
      <CenterContent fillHeight={false}>
        <Spinner />
      </CenterContent>
    );
  }

  if (error !== null) {
    return (
      <CenterContent fillHeight={false}>
        <ContentMessage message={error} />
      </CenterContent>
    );
  }

  return (
    <>
      <WatchListFileExplorer
        nodes={watchListNodes || []}
        onEntryContext={handleEntryContext}
        onEntryClick={handleClick}
        getFileIcon={() => faChartBar}
        getFileName={getFileName}
        multiSelect={false}
        tutorial={tutorial}
      />

      <ConfirmationModal
        title="Delete Watchlist"
        onSubmit={handleDeleteWatchlist}
        submitActionTheme="danger"
        open={deleteConfirmationOpen}
        onCancel={closeDeleteConfirmation}
      >
        Are you sure you want to delete {contextMenuNode?.node?.name}?
      </ConfirmationModal>
      <WatchlistRenameModal
        open={renameConfirmationOpen}
        onClose={closeRenameWatchlistConfirmation}
        name={contextMenuNode?.node?.name || ""}
        onSubmit={handleRenameWatchlist}
      />

      <PositionedPortal
        open={contextMenuOpen}
        setPlacement={handleContextMenuPlacement}
        onClickOutside={closeContextMenu}
      >
        <Menu onClick={closeContextMenu}>
          <MenuItem key="add-watchlist" onClick={openCreateWatchListModal}>
            <FontAwesomeIcon
              icon={faPlus}
              style={{ marginRight: "var(--spacing-l)" }}
              size="sm"
            />
            Add WatchList
          </MenuItem>
          <MenuItem
            key="delete-watchlist"
            onClick={openDeleteConfirmation}
            data-e2e-id="delete-watch list-menu-item"
          >
            <FontAwesomeIcon
              icon={faTrash}
              style={{ marginRight: "var(--spacing-l)" }}
              size="sm"
            />
            Delete
          </MenuItem>
          <MenuItem
            key="rename-watchlist"
            onClick={openRenameWatchlistConfirmation}
          >
            <FontAwesomeIcon
              icon={faEdit}
              style={{ marginRight: "var(--spacing-l)" }}
              size="sm"
            />
            Rename
          </MenuItem>
        </Menu>
      </PositionedPortal>
    </>
  );
};

export default WatchListExplorer;
