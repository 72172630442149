import ControlledAccountSelect from "@app-components/inputs/account/ControlledAccountSelect";
import InstrumentMultiSelect from "@app-components/inputs/InstrumentSelect/InstrumentMultiSelect";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  IGNORED_TRADE_BOOKING_STATUS_OPTIONS,
  InstrumentInfo,
  SelectOptionsType,
  TRADE_STATUS_OPTIONS,
} from "@enfusion-ui/types";
import {
  ControlledCheckbox,
  ControlledInputBase,
  ControlledNumericInput,
  ControlledTextInput,
  MultiSelect,
  PanelRow,
  Select,
  TextInput,
} from "@enfusion-ui/web-components";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import ControlledBookSelect from "../../../components/ReportQueryComponents/BookSelect";
import {
  DateRangeSelection,
  DateSelection,
} from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledFinancialSubTypeSelect } from "../../../components/ReportQueryComponents/FinancialSubTypeSelect";
import { ControlledMarketEnvironmentSelect } from "../../../components/ReportQueryComponents/MarketEnvironmentSelect";
import { ControlledMaxResultsInput } from "../../../components/ReportQueryComponents/MaxResultsInput";
import { ControlledPortfolioGroupsSelect } from "../../../components/ReportQueryComponents/PortfolioGroupsSelect";
import { ControlledTradeTypeSelect } from "../../../components/ReportQueryComponents/TradeTypeSelect";
import CounterPartySelect from "./CounterPartySelect";

const AllocationStatusOptions = [
  { label: "Any", value: "null" },
  { label: "Allocated", value: "true" },
  { label: "Unallocated", value: "false" },
];

const SplitTradesOptions = [
  { label: "Any", value: "null" },
  { label: "Split", value: "true" },
  { label: "Unsplit", value: "false" },
];

const TradeBlotterSettings = () => {
  const { bookSelection } = useWatch({
    name: ["bookSelection"],
  });

  const defaultBookSelectionRef = React.useRef(bookSelection);

  const currentTradeIds = React.useRef<Array<string>>([]);
  const currentdealIds = React.useRef<Array<string>>([]);
  const [currentAllocatedTrades, setCurrentAllocatedTrades] =
    React.useState<SelectOptionsType<String> | null>(null);
  const [currentSplitStatus, setCurrentSplitStatus] =
    React.useState<SelectOptionsType<String> | null>(null);

  const { setValue } = useFormContext();

  const handleChange = useRefCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^[0-9,\b]+$/;
      if (e.target.value === "") {
        currentTradeIds.current = [];
      } else if (re.test(e.target.value)) {
        currentTradeIds.current = e.target.value.replace(/,+/g, ",").split(",");
      }

      setValue(
        "tradeIds",
        currentTradeIds.current
          ?.filter((item: String) => item !== "")
          ?.map(Number)
      );
    },
    []
  );

  return (
    <>
      <ControlledAccountSelect label="Account(s)" />
      <ControlledPortfolioGroupsSelect />
      <ControlledBookSelect
        defaultBookSelection={defaultBookSelectionRef.current}
      />
      <DateRangeSelection
        name="tradeDateRange"
        startDateLabel="Trade Date (From)"
        endDateLabel="To"
      />
      <DateRangeSelection
        name="settleDateRange"
        startDateLabel="Settle Date (From)"
        endDateLabel="To"
      />
      <DateRangeSelection
        name="modificationDateRange"
        startDateLabel="Modification Date (From)"
        endDateLabel="To"
      />
      <DateRangeSelection
        name="fixingDateRange"
        startDateLabel="Fixing Date (From)"
        endDateLabel="To"
      />
      <DateRangeSelection
        name="maturityDateRange"
        startDateLabel="Maturity Date (From)"
        endDateLabel="To"
      />
      <PanelRow>
        {/* need api end point to get options */}
        <ControlledInputBase
          name="statusIds"
          render={({ onChange, ...rest }) => (
            <MultiSelect
              {...rest}
              label="Trade Status"
              options={TRADE_STATUS_OPTIONS}
              placeholder="Any"
              onChange={onChange}
            />
          )}
        />
        <ControlledCheckbox
          name="negateTradeStatus"
          label="Exclude Selected Statuses"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </PanelRow>
      <PanelRow>
        {/* need api end point to get options */}
        <ControlledInputBase
          name="bookingStatusIds"
          render={({ onChange, ...rest }) => (
            <MultiSelect
              {...rest}
              label="Trade Booking Status"
              options={IGNORED_TRADE_BOOKING_STATUS_OPTIONS}
              placeholder="Any"
              onChange={onChange}
            />
          )}
        />
      </PanelRow>
      <PanelRow>
        <ControlledInputBase
          name="allocatedTrades"
          render={({ ...rest }) => (
            <Select
              {...rest}
              label="Allocation Status"
              options={AllocationStatusOptions}
              placeholder="Any"
              value={currentAllocatedTrades}
              onChange={(val) => {
                setCurrentAllocatedTrades(val);
                setValue(
                  "allocatedTrades",
                  val?.value === "null" ? null : val?.value === "true"
                );
              }}
              clearable={false}
            />
          )}
        />
        <ControlledInputBase
          name="splitTrades"
          render={({ ...rest }) => (
            <Select
              {...rest}
              label="Split Status"
              options={SplitTradesOptions}
              placeholder="Any"
              value={currentSplitStatus}
              onChange={(val) => {
                setCurrentSplitStatus(val);
                setValue(
                  "splitTrades",
                  val?.value === "null" ? null : val?.value === "true"
                );
              }}
              clearable={false}
            />
          )}
        />
      </PanelRow>
      <PanelRow>
        <ControlledTextInput
          name="externalReference"
          label="External Reference"
        />
        <ControlledTextInput
          name="internalReference"
          label="Internal Reference"
        />
      </PanelRow>
      <PanelRow>
        <ControlledInputBase
          name="tradeIds"
          render={({ ref: _ref, ...rest }) => (
            <TextInput
              {...rest}
              type="text"
              label="Trade Id(s)"
              value={currentTradeIds.current?.join(",")}
              onChange={(e) => handleChange(e)}
            />
          )}
        />
        <ControlledCheckbox
          name="searchCreditFacilityParentId"
          label="Search Credit Facility Parent Id"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </PanelRow>
      <PanelRow>
        <ControlledTradeTypeSelect placeholder="All" />
        <ControlledMaxResultsInput />
      </PanelRow>
      <ControlledInputBase
        name="dealIds"
        render={({ ref: _ref, ...rest }) => (
          <TextInput
            {...rest}
            type="text"
            label="Deal Id(s)"
            value={currentdealIds.current?.join(",")}
            onChange={(e) => {
              currentdealIds.current = e.target.value.split(",");
              setValue(
                "dealIds",
                currentdealIds.current?.filter((item: String) => item !== "")
              );
            }}
          />
        )}
      />
      <PanelRow>
        <ControlledCheckbox
          name="hasMargin"
          label="Has Margin"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />

        <ControlledCheckbox
          name="hasTRS"
          label="Has TRS"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />

        <ControlledCheckbox
          name="searchWithoutBook"
          label="Search Without Book"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </PanelRow>
      <PanelRow>
        <CounterPartySelect />
      </PanelRow>
      <ControlledInputBase
        name="instrumentIds"
        render={({ ref: _ref, onChange, ...rest }) => (
          <InstrumentMultiSelect
            {...rest}
            label="Instrument(s)"
            onChange={(instruments: InstrumentInfo[] | null) => {
              onChange(instruments?.map((instrument) => instrument.id));
            }}
          />
        )}
      />
      <ControlledCheckbox
        name="useUnderliers"
        label="Include Underliners"
        labelPlacement="right"
        nullValue={false}
      />
      <PanelRow>
        <ControlledFinancialSubTypeSelect
          name="instrumentTypes"
          selectProps={{ label: "Instrument type" }}
        />
      </PanelRow>
      <PanelRow>
        <ControlledNumericInput
          name="priceFrom"
          label="Price/Rate From"
          hideControls
        />
        <ControlledNumericInput name="priceTo" label="To" hideControls />
      </PanelRow>
      <PanelRow>
        <DateSelection
          name="valueDate"
          label="Value Date"
          defaultValue={undefined}
        />
        <ControlledCheckbox
          name="useTradeDateForValuation"
          label="Use Trade Date For Valualation"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </PanelRow>
      <ControlledMarketEnvironmentSelect />
    </>
  );
};

export default TradeBlotterSettings;
