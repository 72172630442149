import { useConnectionStatus } from "@app-context/connectionStatus/context";
import WidgetTitle from "@app-views/dashboards/components/WidgetTitle";
import { WidgetContentContainerFill } from "@app-views/dashboards/widget/components/WidgetContentContainer";
import WatchListItem from "@app-views/watchList/components/WatchListItem";
import { UNKNOWN_INSTRUMENT_ID } from "@enfusion-ui/core";
import {
  MarketDataWidgetConfig,
  useDashboard,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ConnectionStatus } from "@enfusion-ui/types";
import {
  Button,
  CenterContent,
  ContentMessage,
  ContentMessageWrapper,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faSkullCrossbones } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
import { useMeasure } from "react-use";

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const MarketDataWidget: React.FC<unknown> = () => {
  const [containerLoaded, setContainerLoaded] = React.useState(false);

  const { config, setEditing } = useWidget();
  const { marketDataConnectionStatus } = useConnectionStatus();
  const { canEdit } = useDashboard();
  const { ref: resizeRef } = useResizeDetector();
  const [measureRef, { width, height }] = useMeasure<HTMLDivElement>();

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const { defaultValue } = config as MarketDataWidgetConfig;

  const handleRef = (ref: HTMLDivElement) => {
    containerRef.current = ref;
    resizeRef.current = ref;
    measureRef(ref);
    setContainerLoaded(true);
  };

  const isWidgetConfigurable =
    defaultValue?.id === UNKNOWN_INSTRUMENT_ID && canEdit;

  const contextMsg = React.useMemo(() => {
    switch (marketDataConnectionStatus) {
      case ConnectionStatus.CONNECTING:
        return "Market data connecting...";
      case ConnectionStatus.DISABLED:
      case ConnectionStatus.DISCONNECTED:
        return "Market data disconnected";
      case ConnectionStatus.ERROR:
        return "Error while connecting to market data";
      default:
        return "";
    }
  }, [marketDataConnectionStatus]);

  if (contextMsg) {
    return (
      <WidgetContentContainerFill ref={handleRef}>
        <CenterContent>
          <ContentMessage
            icon={faSkullCrossbones}
            message={contextMsg}
            parentHeight={height}
            parentWidth={width}
          />
        </CenterContent>
      </WidgetContentContainerFill>
    );
  }

  if (isWidgetConfigurable) {
    return (
      <WidgetContentContainerFill ref={handleRef}>
        <CenterContent>
          <ContentMessageWrapper
            isConfigurable={isWidgetConfigurable}
            onMouseDown={(e) => {
              if (isWidgetConfigurable) {
                e.stopPropagation();
                e.preventDefault();
                setEditing(true);
              }
            }}
          >
            <ContentMessage
              message="Please configure Market Data widget"
              size="3x"
              contentStyle={{ textAlign: "center" }}
              parentHeight={height}
              parentWidth={width}
            />
            <Button
              theme="primary"
              data-e2e-id="market-data-open-settings"
              data-testid="market-data-open-settings"
            >
              Open Settings
            </Button>
          </ContentMessageWrapper>
        </CenterContent>
      </WidgetContentContainerFill>
    );
  }

  return (
    <WidgetContentContainerFill ref={handleRef}>
      {containerLoaded ? (
        <InnerContainer>
          <WidgetTitle title={defaultValue?.description} />
          <WatchListItem instrument={defaultValue} widgetMode />
        </InnerContainer>
      ) : null}
    </WidgetContentContainerFill>
  );
};

export default MarketDataWidget;
