import { ALLOCATION_TYPE, useAllocationsControl } from "@enfusion-ui/core";
import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { SelectOptionsType } from "@enfusion-ui/types";
import {
  ControlledSelect,
  FormElement,
  PanelRow,
  Select,
} from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export type OptimizationOptions = {
  label: string;
  value: string;
};

const TYPES = [
  { value: "Absolute", label: "Absolute" },
  { value: "Percent", label: "Percent" },
] as SelectOptionsType<string>[];

export type OptimizationInputProps = {
  disabled?: boolean;
};

export const OptimizationInput: React.FC<OptimizationInputProps> = ({
  disabled,
}) => {
  const [optimizerDisabled, setOptimizerDisabled] =
    React.useState<boolean>(false);
  const { setValue } = useFormContext();

  const { isEntryTransmitted } = useAllocationsControl();
  const { accountAllocation, type } = useWatch({
    name: ["accountAllocation", "type"],
  });

  const { options: optimizationOptions, loading } = useRestAbortableOptions(
    REST_API.OEMS.GET_OPTIMIZE_TYPES.FETCH,
    (d) => ({ value: d.name, label: d.description })
  );

  React.useEffect(() => setValue("type", ALLOCATION_TYPE.percent), []);

  React.useEffect(
    () => setOptimizerDisabled(type === ALLOCATION_TYPE.absolute),
    [type]
  );

  return (
    <FormElement>
      <PanelRow style={{ width: "100%" }}>
        <ControlledSelect
          name="type"
          label="Type"
          options={TYPES}
          clearable={false}
          disabled={disabled || isEntryTransmitted}
          inputId="optimization-type-selection-id"
          required
        />
        <Select
          label="% Optimize"
          value={optimizationOptions.find(
            (option) =>
              option.value ===
              (accountAllocation?.allocationOptimizer ??
                "FractionSharesToHighestPercent")
          )}
          options={optimizationOptions}
          clearable={false}
          isLoading={loading}
          disabled={optimizerDisabled || disabled || isEntryTransmitted}
          inputId="optimizer-selection-id"
          dataE2EId="optimizer-selection-id"
          name="optimizer-selection"
          onChange={(optimizer) =>
            setValue("accountAllocation", {
              ...accountAllocation,
              allocationOptimizer: optimizer?.value,
            })
          }
          minWidth={150}
        />
      </PanelRow>
    </FormElement>
  );
};
