import { ICellRendererParams } from "ag-grid-community";
import * as React from "react";

import ColumnSelect from "../../../../components/inputs/ColumnSelect";
import { OrderTypeOptions } from "../../../oems/components/ElectronicLimitDetailEntry";

const TypeCellRenderer: React.FC<
  ICellRendererParams & {
    allowGroup?: boolean;
  }
> = (params) => {
  if (!params.allowGroup && params.node.group)
    return <>{params.valueFormatted ?? null}</>;

  return (
    <div style={{ pointerEvents: "none" }}>
      <ColumnSelect
        name="orderType"
        options={OrderTypeOptions.orderType}
        clearable={false}
        mobileBasis="50%"
        minWidth={50}
        colDef={params.colDef}
        {...params}
      />
    </div>
  );
};

export default TypeCellRenderer;
