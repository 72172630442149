import { formatNumber, FormatNumberStyle } from "@enfusion-ui/utils";

export const getDisplayValue = (
  num: string | number | null,
  fractionDigits = 2,
  style: FormatNumberStyle = "number",
  reduce = false
) =>
  num == null || isNaN(Number(num))
    ? "-"
    : formatNumber(num, {
        fractionDigits,
        formatNegative: false,
        style,
        reduce,
      });
