import CurrencySelect from "@app-views/reports/reportSettings/generalLedgerAccounting/glDistributionLine/CurrencySelect";
import {
  StockLoanBorrowOrderTypeValues,
  TradeTicketFormValues,
} from "@enfusion-ui/trades";
import { createSelectOptions } from "@enfusion-ui/utils";
import {
  ControlledCheckbox,
  ControlledNumericInput,
  ControlledSelect,
  FormElement,
  FormPanel,
  PanelRow,
} from "@enfusion-ui/web-components";
import * as React from "react";
import { useWatch } from "react-hook-form";

import { StyledFormElementHeader } from "../../components/styledComponents";
import { CollateralHaircutSection } from "./components/CollateralHaircutSection";

const OrderTypeOptions = createSelectOptions(StockLoanBorrowOrderTypeValues);

export const CashSettlementsPanel: React.FC = () => {
  const orderType = useWatch<TradeTicketFormValues["orderType"]>({
    name: "orderType",
  });

  return (
    <FormPanel
      lineStyle
      collapsible
      defaultOpen
      keepRendered
      numColumns={1}
      defaultRendered
      tabFocusOutline={false}
      title="Cash Settlements"
    >
      <CollateralHaircutSection />

      <StyledFormElementHeader>Order Type</StyledFormElementHeader>
      <PanelRow>
        <ControlledSelect
          required
          name="orderType"
          label="Order Type"
          options={OrderTypeOptions}
        />
        <ControlledNumericInput
          required
          name="notionalQuantity"
          label="Notional Quantity"
          fractionDigits={2}
        />
      </PanelRow>

      <PanelRow>
        <ControlledNumericInput
          name="cleanPrice"
          label="Clean Price"
          mobileBasis="max-content"
          fractionDigits={2}
        />
        <FormElement mobileBasis="min-content">
          <ControlledCheckbox
            topLabelPlaceholder
            labelPlacement="right"
            name="priceResetsDaily"
            label="Price Resets Daily"
          />
        </FormElement>
      </PanelRow>

      <PanelRow>
        <ControlledNumericInput
          name="principalOverride"
          label="Principal Override"
          fractionDigits={2}
        />
        <PanelRow style={{ width: "100%" }}>
          <ControlledNumericInput
            name="accruedOverride"
            label="Accrued Override"
            mobileBasis="max-content"
            fractionDigits={2}
          />
          <ControlledCheckbox
            label="Use"
            topLabelPlaceholder
            labelPlacement="right"
            style={{ flex: "0 1" }}
            name="useAccruedOverride"
          />
        </PanelRow>
      </PanelRow>

      <PanelRow>
        <CurrencySelect name="settleCCY" label="Settlement CCY" />
        <ControlledNumericInput
          name="settleFxRate"
          label="Settlement FX Rate"
          formatStyle="percent"
        />
      </PanelRow>

      <ControlledNumericInput
        name="otherBorrowFee"
        label="Other Borrow Fee %"
        formatStyle="percent"
        disabled={orderType !== StockLoanBorrowOrderTypeValues.Borrow}
      />
    </FormPanel>
  );
};
