import { cashBreakTypes } from "@enfusion-ui/core";
import { useModalState, useRefCallback } from "@enfusion-ui/hooks";
import {
  CashBreakDatasourceDef,
  DatasourceDef,
  FilterColumns,
  ImpactColumns,
  NodeData,
  RECONCILIATION_TYPES,
  ReconciliationTypes,
  ReconReportConfig,
  SelectOptionsType,
  SummaryReportConfig,
} from "@enfusion-ui/types";
import { getSelectOption } from "@enfusion-ui/utils";
import {
  Button,
  ControlledEmpty,
  ControlledInputBase,
  FormSectionAccordion,
  Select,
} from "@enfusion-ui/web-components";
import { faEllipsisH, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColDef } from "ag-grid-community";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import {
  ControlledColumnSelectProps,
  ReconciliationFormValues,
  ReconciliationMetaData,
} from "../types";
import {
  fetchMetadata,
  getCashActivityReportId,
  getCashBalanceReportId,
  getColumnOptions,
  isCashDataSource,
  isCurrencyColumn,
  isInstrumentColumn,
} from "../utils/commonUtils";
import { DEFAULT_RECON_REPORT } from "../utils/constants";
import { ControlledDatasourceSelect } from "./DatasourceSelect";
import { ReconciliationReportSettingsModal } from "./ReconciliationReportSettingsModal";
import {
  ButtonWrapper,
  InputsContainer,
  InputWrapper,
  ReconciliationSettingsModalContainer,
  RowSection,
} from "./styles";

type ReconciliationDashboardSettingsModalContentProps = {
  unsavedMetadata?: ReconciliationMetaData;
  setUnsavedMetadata?: React.Dispatch<
    React.SetStateAction<ReconciliationMetaData | undefined>
  >;
};

const DEFAULT_RECON_REPORT_CONFIG = {
  reconReportConfig: DEFAULT_RECON_REPORT,
  idx: 0,
};

const getDefaultCurrency = (defs: ColDef[]) => {
  return (
    getColumnOptions(defs, "standardColumn").find((i) =>
      isCurrencyColumn(i.label)
    ) ?? null
  );
};

const getDefaultInstrument = (defs: ColDef[]) => {
  return getColumnOptions(defs).find((i) => isInstrumentColumn(i.label));
};

const getDefaultServiceAccount = (defs: ColDef[]) => {
  return getColumnOptions(defs).find((i) =>
    i.value.includes("servicesAccountName")
  );
};

export const reconciliationTypesDropdownOptions: SelectOptionsType<ReconciliationTypes | null>[] =
  Object.entries(RECONCILIATION_TYPES).map(([key, value]) => ({
    label: key,
    value: value,
  }));

const isReconReportSettingIconDisabled = (
  reconReportConfig: ReconReportConfig,
  isMetadataLoading:
    | {
        id: string;
        loading: boolean;
      }
    | undefined
) => {
  let disabled = true;
  if (isCashDataSource(reconReportConfig.reconciliationType)) {
    disabled = !(
      !!(reconReportConfig.datasource as CashBreakDatasourceDef)
        ?.cashActivity &&
      !!(reconReportConfig.datasource as CashBreakDatasourceDef)?.cashBalance
    );
  } else {
    disabled = !reconReportConfig.datasource;
  }
  return (
    disabled ||
    (reconReportConfig.reconReportId === isMetadataLoading?.id &&
      isMetadataLoading?.loading)
  );
};

const ControlledColumnSelect: React.FC<
  ControlledColumnSelectProps<ReconciliationTypes | string>
> = ({
  name,
  label,
  options,
  defaultValue,
  clearable = false,
  required = false,
  onChange: onChangeExternal,
}) => {
  return (
    <ControlledInputBase
      name={name}
      defaultValue={defaultValue}
      render={({ ref: _ref, value, onChange, ...rest }) => (
        <Select
          {...rest}
          label={label}
          options={options}
          clearable={clearable}
          required={required}
          value={getSelectOption(options, value) ?? null}
          onChange={(e) => {
            onChange(e?.value);
            onChangeExternal?.(
              e as SelectOptionsType<ReconciliationTypes | string> | null
            );
          }}
        />
      )}
    />
  );
};

export const ReconciliationDashboardSettingsModalContent: React.FC<
  ReconciliationDashboardSettingsModalContentProps
> = ({ unsavedMetadata, setUnsavedMetadata }) => {
  const [selectedFilterConfig, setSelectedFilterConfig] = React.useState<{
    reconReportConfig: ReconReportConfig;
    idx: number;
  }>(DEFAULT_RECON_REPORT_CONFIG);
  const [isMetadataLoading, setIsMetadataLoading] = React.useState<{
    id: string;
    loading: boolean;
  }>();

  const reconciliationFiltersModalState = useModalState();

  const formMethods = useFormContext<ReconciliationFormValues>();
  const { setValue, watch } = formMethods;
  const { fields: summaryReportsList } = useFieldArray<SummaryReportConfig>({
    name: "summaryReports",
  });
  const {
    fields: reconReportsList,
    remove: removeReconReport,
    append: appendReconReport,
  } = useFieldArray<ReconReportConfig>({ name: "reconReports" });

  const { reconReports = [], summaryReports = [] } = watch([
    "reconReports",
    "summaryReports",
  ]);

  const controlledSummaryReports = summaryReportsList.map(
    (summaryReport, index) => {
      return {
        ...summaryReport,
        ...summaryReports[index],
      };
    }
  );

  const controlledReconReports = reconReportsList.map((reconReport, index) => {
    return {
      ...reconReport,
      ...reconReports[index],
    };
  });

  const getDefaultFilterColumns = useRefCallback(
    (isCashDatasource = false, rowId: string) => {
      const defaultInstrument = !isCashDatasource
        ? getDefaultInstrument(unsavedMetadata?.[rowId]?.defs ?? [])?.value
        : null;
      const defaultCurrency = getDefaultCurrency(
        unsavedMetadata?.[rowId]?.defs ?? []
      )?.value;
      const defaultServiceAccount = getDefaultServiceAccount(
        unsavedMetadata?.[rowId]?.defs ?? []
      )?.value;
      return {
        serviceAccount: {
          source: defaultServiceAccount,
          target: defaultServiceAccount,
        },
        instrument: { source: defaultInstrument, target: defaultInstrument },
        currency: { source: defaultCurrency, target: defaultCurrency },
      } as FilterColumns;
    },
    [getDefaultCurrency, getDefaultInstrument, unsavedMetadata]
  );

  const getDefaultImpactColumns = useRefCallback(
    (
      isCashBalanceDataSource = false,
      rowId: string,
      impactColumns: ImpactColumns
    ): ImpactColumns => {
      const defaultCurrency =
        getDefaultCurrency(unsavedMetadata?.[rowId]?.defs ?? [])?.value ?? null;
      if (isCashBalanceDataSource) {
        return {
          ...impactColumns,
          cashBalance: null,
          cashBalanceCurrency: {
            source: defaultCurrency,
            target: defaultCurrency,
          },
        } as ImpactColumns;
      }
      return {
        ...impactColumns,
        breakImpact: null,
      };
    },
    [getDefaultCurrency, unsavedMetadata]
  );

  const updateMetadata = useRefCallback(
    async (rowId: string, path?: string, isSummaryReport = false) => {
      if (path) {
        setIsMetadataLoading({ id: rowId, loading: true });
        const response = await fetchMetadata(path, isSummaryReport);
        setUnsavedMetadata?.((state) => ({ ...state, [rowId]: response }));
      } else {
        setUnsavedMetadata?.((state) => {
          const newState = { ...state };
          delete newState?.[rowId];
          return newState;
        });
      }
    },
    [setUnsavedMetadata]
  );

  const addEntry = () => {
    appendReconReport(
      { ...DEFAULT_RECON_REPORT, reconReportId: uuidv4() },
      true
    );
  };

  const removeEntry = useRefCallback(
    (idx: number, rowId: string, reconType?: ReconciliationTypes) => {
      removeReconReport(idx);
      if (reconType === "Cash") {
        [getCashActivityReportId(rowId), getCashBalanceReportId(rowId)].forEach(
          (cashId) => {
            updateMetadata(cashId);
          }
        );
      } else {
        updateMetadata(rowId);
      }
    },
    [removeReconReport]
  );

  const handleSummaryDatasourceChange = useRefCallback(
    (rowId: string, idx: number) => async (value: NodeData | null) => {
      setValue(`summaryReports[${idx}].column`, null);
      await updateMetadata(rowId, value?.path, true);
    },
    [setUnsavedMetadata, summaryReports]
  );

  const handleReconciliationDatasourceChange = useRefCallback(
    (
        rowId: string,
        idx: number,
        reconType?: "cash-balance" | "cash-activity"
      ) =>
      async (entry: NodeData | null) => {
        const isCashBalanceDatasource = reconType === "cash-balance";
        const isCashActivityDatasource = reconType === "cash-activity";
        const reportId = isCashBalanceDatasource
          ? getCashBalanceReportId(rowId)
          : isCashActivityDatasource
          ? getCashActivityReportId(rowId)
          : rowId;

        await updateMetadata(reportId, entry?.path);

        if (!isCashBalanceDatasource)
          setValue(
            `reconReports[${idx}].filterColumns`,
            getDefaultFilterColumns(isCashActivityDatasource, reportId)
          );

        setValue(
          `reconReports[${idx}].impactColumns`,
          getDefaultImpactColumns(
            isCashBalanceDatasource,
            reportId,
            reconReports?.[idx]?.impactColumns as ImpactColumns
          )
        );
        setIsMetadataLoading({ id: reportId, loading: false });
      },
    [updateMetadata, setValue, reconReports]
  );

  const handleReconciliationTypeChange = useRefCallback(
    (idx: number) => {
      setValue(`reconReports[${idx}].datasource`, null);
      setValue(
        `reconReports[${idx}].filterColumns`,
        DEFAULT_RECON_REPORT.filterColumns
      );
      setValue(
        `reconReports[${idx}].impactColumns`,
        DEFAULT_RECON_REPORT.impactColumns
      );
    },
    [setValue]
  );

  const handleReportSettingsApply = useRefCallback(
    (formValues: ImpactColumns & FilterColumns) => {
      setValue(
        `reconReports[${selectedFilterConfig.idx}].impactColumns`,
        {
          breakImpact: formValues.breakImpact,
          cashBalance: formValues.cashBalance,
          cashBalanceCurrency: formValues.cashBalanceCurrency,
        } as ImpactColumns,
        { shouldDirty: true }
      );
      setValue(
        `reconReports[${selectedFilterConfig.idx}].filterColumns`,
        {
          currency: formValues.currency,
          instrument: formValues.instrument,
          serviceAccount: formValues.serviceAccount,
        } as FilterColumns,
        { shouldDirty: true }
      );
    },
    [selectedFilterConfig, setValue]
  );

  return (
    <ReconciliationSettingsModalContainer>
      <FormSectionAccordion title="Summary Reports">
        <InputsContainer>
          {controlledSummaryReports.map((entry, idx: number) => {
            const { summaryReportId, datasource, column } = entry;
            return (
              <RowSection gap="l" key={entry.id}>
                <InputWrapper>
                  <ControlledDatasourceSelect
                    label={`${summaryReportId} Datasource`}
                    name={`summaryReports[${idx}].datasource`}
                    onSelect={handleSummaryDatasourceChange(
                      summaryReportId,
                      idx
                    )}
                    defaultValue={datasource ?? null}
                  />
                </InputWrapper>
                <InputWrapper>
                  <ControlledColumnSelect
                    label="Source column"
                    name={`summaryReports[${idx}].column`}
                    options={getColumnOptions(
                      unsavedMetadata?.[summaryReportId]?.defs,
                      "numberColumn"
                    )}
                    defaultValue={column}
                    required
                  />
                </InputWrapper>
                <ControlledEmpty
                  name={`summaryReports[${idx}].summaryReportId`}
                  defaultValue={summaryReportId}
                />
              </RowSection>
            );
          })}
        </InputsContainer>
      </FormSectionAccordion>
      <FormSectionAccordion title="Reconciliation Reports">
        <InputsContainer>
          {controlledReconReports.map((reconReportConfig, idx: number) => {
            const { datasource, reconciliationType, reconReportId } =
              reconReportConfig;

            const isCash = isCashDataSource(reconciliationType);

            return (
              <RowSection key={reconReportConfig.id}>
                <InputWrapper>
                  <ControlledColumnSelect
                    label="Reconciliation Type"
                    name={`reconReports[${idx}].reconciliationType`}
                    options={reconciliationTypesDropdownOptions}
                    defaultValue={reconciliationType}
                    onChange={() => handleReconciliationTypeChange(idx)}
                    clearable={false}
                    required
                  />
                </InputWrapper>

                <InputWrapper>
                  <ControlledDatasourceSelect
                    label={`${
                      isCash ? cashBreakTypes.CashBalance : "Reconciliation"
                    } datasource`}
                    name={
                      isCash
                        ? `reconReports[${idx}].datasource.cashBalance`
                        : `reconReports[${idx}].datasource`
                    }
                    onSelect={handleReconciliationDatasourceChange(
                      reconReportId,
                      idx,
                      isCash ? "cash-balance" : undefined
                    )}
                    defaultValue={
                      isCash
                        ? ((datasource as CashBreakDatasourceDef)
                            ?.cashBalance as DatasourceDef)
                        : (datasource as DatasourceDef)
                    }
                    diffReport
                  />
                </InputWrapper>
                {isCash && (
                  <>
                    <InputWrapper>
                      <ControlledDatasourceSelect
                        label="Cash Activity Datasource"
                        name={`reconReports[${idx}].datasource.cashActivity`}
                        onSelect={handleReconciliationDatasourceChange(
                          reconReportId,
                          idx,
                          "cash-activity"
                        )}
                        defaultValue={
                          (datasource as CashBreakDatasourceDef)
                            ?.cashActivity as DatasourceDef
                        }
                        diffReport
                      />
                    </InputWrapper>
                  </>
                )}
                <ControlledEmpty
                  name={`reconReports[${idx}].reconReportId`}
                  defaultValue={reconReportId}
                />
                <ControlledEmpty
                  name={`reconReports[${idx}].filterColumns`}
                  defaultValue={reconReportConfig.filterColumns}
                />
                <ControlledEmpty
                  name={`reconReports[${idx}].impactColumns`}
                  defaultValue={reconReportConfig.impactColumns}
                />
                <ButtonWrapper>
                  <Button
                    style={{ height: "100%", width: "100%" }}
                    name="filters"
                    onClick={() => {
                      reconciliationFiltersModalState.openModal();
                      setSelectedFilterConfig({
                        reconReportConfig:
                          reconReportConfig as ReconReportConfig,
                        idx,
                      });
                    }}
                    disabled={isReconReportSettingIconDisabled(
                      reconReportConfig,
                      isMetadataLoading
                    )}
                  >
                    <FontAwesomeIcon icon={faEllipsisH} size="xs" />
                  </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button
                    style={{ height: "100%", width: "100%" }}
                    name="remove"
                    onClick={() =>
                      removeEntry(idx, reconReportId, reconciliationType)
                    }
                    disabled={
                      reconReportConfig.reconReportId ===
                        isMetadataLoading?.id && isMetadataLoading?.loading
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} size="xs" />
                  </Button>
                </ButtonWrapper>
              </RowSection>
            );
          })}
        </InputsContainer>
        <ReconciliationReportSettingsModal
          isOpen={reconciliationFiltersModalState.open}
          closeModal={reconciliationFiltersModalState.closeModal}
          initialFilterConfig={selectedFilterConfig.reconReportConfig}
          metadata={unsavedMetadata}
          handleReportSettingsApply={handleReportSettingsApply}
        />

        <RowSection>
          <Button onClick={() => addEntry()}>Add</Button>
        </RowSection>
      </FormSectionAccordion>
    </ReconciliationSettingsModalContainer>
  );
};
