import { useRefCallback } from "@enfusion-ui/hooks";
import { StorageRoot } from "@enfusion-ui/types";
import { CreateFolderModal } from "@enfusion-ui/web-components";
import { useFolderActionContext } from "@enfusion-ui/web-core";

import { useServicesExplorer } from "../../../context/services/ServicesExplorerProvider";

export default function CreateServicesFolderModal({
  storageRoot,
}: {
  storageRoot: StorageRoot;
}) {
  const { createFolderModalPath, closeCreateFolderModal, section } =
    useServicesExplorer();

  const { createFolder } = useFolderActionContext();

  const onSubmit = useRefCallback((folderName: string, folderPath: string) => {
    closeCreateFolderModal();
    createFolder(folderName, folderPath);
  }, []);

  return (
    <CreateFolderModal
      open={section === storageRoot && createFolderModalPath !== null}
      folderPath={createFolderModalPath ?? undefined}
      onCancel={closeCreateFolderModal}
      onSubmit={onSubmit}
    />
  );
}
