import { NodeData } from "@enfusion-ui/types";
import * as React from "react";
//WEB-1927 Initially added only Book in Allocation Context, will add state as and when required
export type AllocationContextState = {
  books: NodeData[];
  getTRSOptions?: (
    custodianId: number | null
  ) => Promise<Array<{ label: string; value: number }>>;
  getBorrowAgreementOptions?: (
    custodianId: number | null
  ) => Promise<Array<{ label: string; value: number }>>;
};

const initialAllocationContextState: AllocationContextState = {
  books: [],
};

export const AllocationContext = React.createContext<AllocationContextState>(
  initialAllocationContextState
);

export const useAllocations = () => {
  const context = React.useContext<AllocationContextState>(AllocationContext);
  if (context === null) {
    throw new Error("useAllocations must be used within AllocationContext");
  }
  return context;
};
