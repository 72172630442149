import { FXOrderFormValues, useOEMSOrderForm } from "@enfusion-ui/core";
import { parseDateString } from "@enfusion-ui/utils";
import {
  DatePicker as DatePickerInput,
  FormController,
  PanelRow,
} from "@enfusion-ui/web-components";
import { kebabCase } from "lodash";
import * as React from "react";
import { useWatch } from "react-hook-form";

const FXDateInput: React.FC<{
  name: string;
  label: string;
  disabled: boolean;
  minDate?: Date;
}> = ({ name, label, disabled, minDate }) => {
  return (
    <FormController
      name={name}
      inputId={kebabCase(name)}
      render={({ ref: _ref, onChange, value, ...rest }) => (
        <DatePickerInput
          {...rest}
          label={label}
          value={
            value instanceof Date
              ? value
              : typeof value === "string"
              ? parseDateString(value)
              : null
          }
          onChange={onChange}
          dateFormat="MM/dd/yyyy"
          clearable
          disabled={disabled}
          minDate={minDate}
        />
      )}
    />
  );
};

const FXDatesPanel: React.FC<{ editMode?: boolean }> = ({ editMode }) => {
  const { fxType, parentOrderId } = useWatch<FXOrderFormValues>({
    name: ["fxType", "parentOrderId"],
  });
  const { splitOrder, newOrder } = useOEMSOrderForm();

  const afterRef = React.useRef(new Date());
  const dateLabel = `${fxType === "Spot" ? "Spot" : "Forward"} Date`;
  const disableFarDates = fxType !== "Swap";
  const disableFixingDate = splitOrder || !!parentOrderId;

  return (
    <div>
      <PanelRow>
        <FXDateInput
          name="settleDate"
          label={dateLabel}
          disabled={!!editMode || !newOrder}
          minDate={afterRef.current}
        />
        <FXDateInput
          name="farSettleDate"
          label="Far Forward Date"
          disabled={disableFarDates || !!editMode || !newOrder}
          minDate={afterRef.current}
        />
      </PanelRow>
      <PanelRow>
        <FXDateInput
          name="fixingDate"
          label="Fixing Date"
          disabled={disableFixingDate || !!editMode}
        />
        <FXDateInput
          name="farFixingDate"
          label="Far Fixing Date"
          disabled={disableFarDates || !!editMode}
        />
      </PanelRow>
    </div>
  );
};

export default FXDatesPanel;
