import { useRefCallback } from "@enfusion-ui/hooks";
import {
  DocumentAttachmentType,
  documentAttachmentTypeOptions,
  DocumentEntity,
} from "@enfusion-ui/trades";
import { getSelectOption } from "@enfusion-ui/utils";
import {
  Button,
  LabeledButton,
  Modal,
  Select,
  TextInput,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const ModalContainer = styled.div`
  min-width: 21.25rem;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

const PathInputContainer = styled.div`
  display: flex;
  gap: var(--spacing-l);
`;

const NameInputContainer = styled.div`
  overflow: hidden;
`;

const ActionContainer = styled.div`
  display: flex;
  height: 2.25rem;
  gap: var(--spacing-l);
  justify-content: space-between;
  margin-top: var(--spacing-xl);
`;

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onSubmit: (document: DocumentEntity) => void;
};

export const TradeTicketDocumentAddModal: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [file, setFile] = React.useState<File | null>(null);
  const [name, setName] = React.useState<string | null>(null);
  const [attachmentType, setAttachmentType] =
    React.useState<DocumentAttachmentType | null>(null);

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleClickAttach = useRefCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileInputChange = useRefCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      if (!file) return;
      setFile(file);
    },
    []
  );

  const clearFileInput = useRefCallback(() => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, []);

  const closeModal = useRefCallback(() => {
    setFile(null);
    setName(null);
    setAttachmentType(null);
    onClose();
  }, []);

  const handleSubmit = useRefCallback(() => {
    if (!file || !name || !attachmentType) return;
    onSubmit({ file, name, attachmentType });
    closeModal();
  }, [file, name, attachmentType]);

  return (
    <Modal
      isOpen={open}
      onClose={closeModal}
      title="Document Attachment Editor New"
      content={
        <ModalContainer>
          <PathInputContainer>
            <TextInput
              required
              readOnly
              clearable
              label="File"
              value={file?.name ?? ""}
              onClearValue={clearFileInput}
            />
            <LabeledButton
              onClick={handleClickAttach}
              containerStyles={{ maxWidth: "6rem" }}
              dataE2EId="trade-attach-doc-btn"
            >
              Attach...
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileInputChange}
                data-testid="tt-document-add-file-input"
              />
            </LabeledButton>
          </PathInputContainer>

          <NameInputContainer>
            <TextInput
              required
              clearable
              label="Name"
              value={name ?? ""}
              onClearValue={() => setName(null)}
              onChange={(e) => setName(e.target.value)}
              data-testid="tt-document-add-name-input"
            />
          </NameInputContainer>

          <Select
            required
            clearable={false}
            label="Attachment Type"
            inputId="tt-document-add-type-select"
            options={documentAttachmentTypeOptions}
            onChange={(option) => option && setAttachmentType(option.value)}
            value={
              attachmentType
                ? getSelectOption(documentAttachmentTypeOptions, attachmentType)
                : null
            }
          />

          <ActionContainer>
            <Button
              theme="primary"
              onClick={handleSubmit}
              data-testid="tt-document-add-submit-button"
              disabled={!file || !name || !attachmentType}
            >
              Save and Close
            </Button>
            <Button
              onClick={closeModal}
              data-testid="tt-document-add-close-button"
            >
              Cancel
            </Button>
          </ActionContainer>
        </ModalContainer>
      }
    ></Modal>
  );
};
