import { useRefCallback } from "@enfusion-ui/hooks";
import { AppLogging } from "@enfusion-ui/web-core";
import * as Highcharts from "highcharts";
import * as React from "react";

// React currently throws a warning when using `useLayoutEffect` on the server.
// To get around it, we can conditionally `useEffect` on the server (no-op) and
// `useLayoutEffect` in the browser. We need `useLayoutEffect` to ensure the
// `Highcharts` ref is available in the layout phase. This makes it available
// in a parent component's `componentDidMount`.
const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

export type HighchartsReactRef = {
  chart: Highcharts.Chart | null;
  resetSeries: (idx: number, name: string) => void;
  renameSeries: (idx: number, name: string) => void;
  removePlotLine: (id: string, axisIndex?: number) => void;
  addPlotLine: (
    options: Highcharts.AxisPlotLinesOptions,
    axisIndex?: number
  ) => void;
  clearExtremes: () => void;
  resize: (
    width?: number | null,
    height?: number | null,
    reflow?: boolean
  ) => void;
  setSeriesData: (idx: number, data: Highcharts.PointOptionsType[]) => void;
  container: React.RefObject<HTMLDivElement | null>;
};

export const HighchartsReact = React.memo(
  React.forwardRef<
    HighchartsReactRef,
    {
      /**
       * Flag for `Chart.update` call (Default: true)
       */
      allowChartUpdate?: boolean;

      /**
       * Reference to the chart factory (Default: chart)
       */
      constructorType?: "chart" | "stockChart";

      /**
       * Properties of the chart container
       */
      containerProps?: { [key: string]: any };

      /**
       * Highcharts namespace
       */
      highcharts?: typeof Highcharts;

      /**
       * Immutably recreates the chart on receiving new props
       */
      immutable?: boolean;

      /**
       * Highcharts options
       */
      options?: Highcharts.Options;

      /**
       * Flags for `Chart.update` call: redraw, oneToOne, and animation. (Default:
       * [true, true, true])
       */
      updateArgs?: [boolean] | [boolean, boolean] | [boolean, boolean, boolean];

      /* *
       *
       *  Functions
       *
       * */

      /**
       * Callback for the chart factory
       */
      callback?: Highcharts.ChartCallbackFunction;
    }
  >(function HighchartsReact(props, ref) {
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const chartRef = React.useRef<Highcharts.Chart | null>(null);
    const constructorType = React.useRef(props.constructorType);
    const highcharts = React.useRef(props.highcharts);

    const createChart = useRefCallback(() => {
      const H =
        props.highcharts || (typeof window === "object" && window.Highcharts);
      const constructorType = props.constructorType || "chart";

      if (!H) {
        console.warn('The "highcharts" property was not passed.');
      } else if (!H[constructorType]) {
        console.warn(
          'The "constructorType" property is incorrect or some ' +
            "required module is not imported."
        );
      } else if (!props.options) {
        console.warn('The "options" property was not passed.');
      } else if (!containerRef.current) {
        console.warn("Missing container element.");
      } else {
        // Create a chart
        chartRef.current = H[constructorType](
          containerRef.current,
          props.options,
          props.callback
        );
      }
    }, [props]);

    useIsomorphicLayoutEffect(() => {
      if (!chartRef.current) {
        createChart();
      } else {
        if (props.allowChartUpdate !== false) {
          // Reacreate chart on Highcharts or constructor type change
          if (
            props.constructorType !== constructorType.current ||
            props.highcharts !== highcharts.current
          ) {
            constructorType.current = props.constructorType;
            highcharts.current = props.highcharts;
            createChart();
            // Use `chart.update` to apply changes
          } else if (!props.immutable && chartRef.current) {
            chartRef.current.update(
              props.options || {},
              ...(props.updateArgs || [true, true])
            );
          } else {
            createChart();
          }
        }
      }
    }, [createChart, props.options, props.allowChartUpdate, props.updateArgs, props.containerProps, props.highcharts, props.constructorType]);

    // Destroy the chart on unmount
    useIsomorphicLayoutEffect(() => {
      return () => {
        if (chartRef.current) {
          try {
            chartRef.current.destroy();
          } catch (err) {
            console.warn("Failed to destroy chartRef");
          }

          chartRef.current = null;
        }
      };
    }, []);

    React.useImperativeHandle(
      ref,
      () => ({
        get chart() {
          return chartRef.current;
        },
        resetSeries: (idx: number, name: string) => {
          if (chartRef.current?.series?.[idx]) {
            chartRef.current.series[idx].name = name;
            chartRef.current.series[idx].setData([]);
          }
        },
        renameSeries: (idx: number, name: string) => {
          if (chartRef.current?.series?.[idx]) {
            chartRef.current.series[idx].name = name;
          }
        },
        setSeriesData: (idx: number, data: Highcharts.PointOptionsType[]) => {
          if (!!chartRef.current?.series?.[idx]) {
            chartRef.current.series[idx].setData(data);
          }
        },
        removePlotLine: (id: string, axisIndex = 0) => {
          chartRef.current?.yAxis[axisIndex]?.removePlotLine(id);
        },
        addPlotLine: (
          options: Highcharts.AxisPlotLinesOptions,
          axisIndex = 0
        ) => {
          chartRef.current?.yAxis[axisIndex]?.addPlotLine(options);
        },
        clearExtremes: () => {
          setTimeout(() => {
            AppLogging.log(
              JSON.stringify({
                msg: "clearExtremes",
                hasRef: !!chartRef.current,
                xAxisCount: chartRef.current?.xAxis.length,
              })
            );
            chartRef.current?.xAxis.forEach((axis) => {
              axis.setExtremes(undefined, undefined, true, true);
            });
          }, 50);
        },
        resize: (
          width?: number | null,
          height?: number | null,
          reflow = true
        ) => {
          chartRef.current?.setSize(width, height);
          if (reflow) chartRef.current?.reflow();
        },
        container: containerRef,
      }),
      []
    );

    // Create container for the chart
    return <div {...props.containerProps} ref={containerRef} />;
  })
);
