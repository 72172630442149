import {
  BreakDetailsCellRendererProps,
  GraphBaseField,
} from "@app-views/reconciliation/types";
import { EMPTY_RECORD } from "@enfusion-ui/core";
import React from "react";

import { ReconciliationBreakDetailsContext } from "./context";

export const ReconciliationBreakDetailsProvider: React.FC<
  React.PropsWithChildren<{
    breakDetailsRendererData: BreakDetailsCellRendererProps;
  }>
> = ({ children, breakDetailsRendererData }) => {
  const [graphFilterState, setGraphFilterState] =
    React.useState<Record<string, Record<GraphBaseField, string>>>(
      EMPTY_RECORD
    );

  const value = React.useMemo(
    () => ({ breakDetailsRendererData, graphFilterState, setGraphFilterState }),
    [breakDetailsRendererData, graphFilterState, setGraphFilterState]
  );

  return (
    <ReconciliationBreakDetailsContext.Provider value={value}>
      {children}
    </ReconciliationBreakDetailsContext.Provider>
  );
};
