/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
import { WebWorkerMessenger, } from "./types";
let enabled = false;
const messenger = new WebWorkerMessenger();
const store = new Map();
export const KeyValueModule = {
    enable: (postMessage) => {
        enabled = true;
        messenger.send = postMessage;
    },
    disable: () => {
        enabled = false;
    },
    getCurrentState: () => {
        return JSON.parse(JSON.stringify(store));
    },
    onTerminate: () => {
        enabled = false;
    },
    onMessage: (data) => {
        const { command, payload } = data;
        const { key, type } = payload;
        if (enabled) {
            switch (command) {
                case "set": {
                    if (!store.has(type)) {
                        store.set(type, new Map());
                    }
                    const date = Date.now();
                    store.get(type)?.set(key, [date, payload.value]);
                    messenger.broadcast({
                        type: "sync-value",
                        payload: { key, type, date, value: payload.value },
                    });
                    break;
                }
                case "get": {
                    const entry = store.get(type)?.get(key) ?? null;
                    if (entry === null) {
                        return {
                            date: Date.now(),
                            value: null,
                            key,
                            type,
                        };
                    }
                    return {
                        date: entry[0],
                        value: entry[1],
                        key,
                        type,
                    };
                }
                case "remove": {
                    store.get(type)?.delete(key);
                    if ([...(store.get(type)?.keys() ?? [])].length === 0) {
                        store.delete(type);
                    }
                    messenger.broadcast({
                        type: "sync-value",
                        payload: { key, type, date: Date.now(), value: null },
                    });
                    break;
                }
            }
        }
    },
};
