import ReportLoadingStatus from "@app-components/display/ReportLoadingStatus";
import { CenterContent, EmptyView, Spinner } from "@enfusion-ui/web-components";
import { ReportRowDataEntry, styled } from "@enfusion-ui/web-core";
import { useWorkerModule } from "@enfusion-ui/web-workers";
import * as React from "react";
import { useDebounce, useMeasure } from "react-use";

import { CurrencyPointChartRef } from "./CurrencyPointChart";
import { sunburstQuery } from "./query";
import { SunburstChart } from "./SunburstChart";
import { TreeMapChart } from "./TreeMapChart";
import { useReportData } from "./useReportData";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: var(--spacing-l);
  padding: var(--spacing-l);
  align-items: center;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const useSetChart = (
  postMessage: (args: Record<string, any>) => Promise<any> | undefined,
  command: string,
  rows: ReportRowDataEntry[]
) => {
  const chartRef = React.useRef<CurrencyPointChartRef>(null);
  const [measureRef, { width, height }] = useMeasure<HTMLDivElement>();
  const [dimensions, setDimensions] = React.useState({ width, height });
  const [loaded, setLoaded] = React.useState(false);

  useDebounce(() => setDimensions({ width, height }), 1500, [width, height]);

  const rowsKey = React.useMemo(() => JSON.stringify(rows), [rows]);

  React.useEffect(() => {
    if (loaded) {
      postMessage({ command, payload: { rows: rowsKey } })?.then((res) => {
        if (res.success) chartRef.current?.setData(res.payload);
      });
    }
  }, [postMessage, rowsKey, command, loaded]);

  return React.useMemo(
    () => ({
      onChartLoad: () => setLoaded(true),
      onChartUnload: () => setLoaded(false),
      chartRef,
      measureRef,
      ...dimensions,
    }),
    [dimensions]
  );
};

export const SunburstChartView: React.FC<unknown> = () => {
  const { enableModule, postMessage } = useWorkerModule("chart");
  React.useEffect(() => {
    enableModule();
  }, []);

  const { rowOptions, gridOptions, metadata } = useReportData(sunburstQuery);
  const [measureRef, { width }] = useMeasure<HTMLDivElement>();
  const [padding, setPadding] = React.useState(() => width / 4 / 2);

  useDebounce(() => setPadding(() => width / 4 / 2), 1500, [width]);

  const rows = React.useMemo(() => rowOptions?.rows ?? [], [rowOptions?.rows]);

  const sunburstYTDChart = useSetChart(
    postMessage,
    "process-sunburst-ytd",
    rows
  );
  const sunburstDailyChart = useSetChart(
    postMessage,
    "process-sunburst-daily",
    rows
  );
  const treeMapYTDChart = useSetChart(
    postMessage,
    "process-tree-map-ytd",
    rows
  );
  const treeMapDailyChart = useSetChart(
    postMessage,
    "process-tree-map-daily",
    rows
  );

  React.useEffect(() => {
    if (!gridOptions || metadata?.loading) {
      sunburstYTDChart.onChartUnload();
      sunburstDailyChart.onChartUnload();
      treeMapYTDChart.onChartUnload();
      treeMapDailyChart.onChartUnload();
    }
  }, [gridOptions, metadata?.loading]);

  return (
    <EmptyView>
      {!gridOptions || metadata?.loading ? (
        <CenterContent>
          {metadata?.progressSteps ? (
            <ReportLoadingStatus steps={metadata.progressSteps} />
          ) : (
            <Spinner />
          )}
        </CenterContent>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <Container
            ref={measureRef}
            style={{ flexDirection: "row", height: "50%" }}
          >
            <Section
              style={{ justifyContent: "flex-end" }}
              ref={sunburstYTDChart.measureRef}
            >
              <SunburstChart
                width={sunburstYTDChart.width - padding}
                height={sunburstYTDChart.height}
                ref={sunburstYTDChart.chartRef}
                title="YTD PNL by Instrument"
                onLoad={sunburstYTDChart.onChartLoad}
              />
            </Section>
            <Section
              style={{ justifyContent: "flex-start" }}
              ref={sunburstDailyChart.measureRef}
            >
              <SunburstChart
                width={sunburstDailyChart.width - padding}
                height={sunburstDailyChart.height}
                ref={sunburstDailyChart.chartRef}
                title="Daily PNL by Instrument"
                onLoad={sunburstDailyChart.onChartLoad}
              />
            </Section>
          </Container>
          <Container style={{ height: "50%" }}>
            <Section ref={treeMapYTDChart.measureRef}>
              <TreeMapChart
                width={(treeMapYTDChart.width / 4) * 3}
                height={treeMapYTDChart.height}
                ref={treeMapYTDChart.chartRef}
                title="YTD PNL by Instrument"
                onLoad={treeMapYTDChart.onChartLoad}
              />
            </Section>
          </Container>
          <Container style={{ height: "50%" }}>
            <Section ref={treeMapDailyChart.measureRef}>
              <TreeMapChart
                width={(treeMapDailyChart.width / 4) * 3}
                height={treeMapDailyChart.height}
                ref={treeMapDailyChart.chartRef}
                title="Daily PNL by Instrument"
                onLoad={treeMapDailyChart.onChartLoad}
              />
            </Section>
          </Container>
        </div>
      )}
    </EmptyView>
  );
};
