import { useScale } from "@enfusion-ui/hooks";
import { LoadingStatusType } from "@enfusion-ui/types";
import { Spinner } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useMeasure } from "react-use";

type LoadingIndicatorProps = {
  status: LoadingStatusType;
};

const StatusScaleWrapper = styled.div`
  position: relative;
`;

const StatusWrapper = styled.div<{ scale: number }>`
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-xl);
  position: relative;
  transform: scale(${({ scale }) => scale});
  transform-origin: center center;
`;

const StepWrapper = styled.div`
  display: grid;
  grid-template-columns: 2rem 14rem 1rem;
  align-items: center;
`;

const StepName = styled.h1<{ status: LoadingStatusType }>`
  font-size: large;
  line-height: var(--spacing);
  font-weight: ${({ status }) => (status === "InProgress" ? "bold" : "normal")};
`;

function LoadingIcon({ status }: LoadingIndicatorProps) {
  return (
    <FontAwesomeIcon
      icon={status === "Completed" ? faCheck : faSquare}
      color={status === "Completed" ? "var(--success)" : "var(--text-normal)"}
      size="lg"
    />
  );
}

function LoadingIndicator({ status }: LoadingIndicatorProps) {
  return status === "InProgress" ? <Spinner /> : null;
}

export type LoadingStatusProps = {
  parentWidth?: number;
  parentHeight?: number;
  steps: Array<{ stepName: string; status: LoadingStatusType }>;
};

const ReportLoadingStatus: React.FC<LoadingStatusProps> = ({
  steps,
  parentHeight,
  parentWidth,
}) => {
  const [ref, { width, height }] = useMeasure<HTMLDivElement>();
  const scale = useScale({ parentHeight, parentWidth, height, width });

  return (
    <StatusScaleWrapper>
      <StatusWrapper ref={ref} scale={scale}>
        {steps.map(({ stepName, status }, i) => (
          <StepWrapper key={`${i}`}>
            <LoadingIcon status={status} />
            <StepName status={status}>{stepName}</StepName>
            <LoadingIndicator status={status} />
          </StepWrapper>
        ))}
      </StatusWrapper>
    </StatusScaleWrapper>
  );
};

export default ReportLoadingStatus;
