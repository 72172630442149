import {
  getOrderSideOptions,
  useInstrument,
  useOEMSOrderForm,
} from "@enfusion-ui/core";
import {
  ButtonGroupSelect,
  FormController,
  PanelRow,
} from "@enfusion-ui/web-components";
import { CSSProperties, styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

const OrderSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

type Props = {
  disabled?: boolean;
  showLimitedOptions?: boolean;
  showLimitedOptionsWithFullDisplay?: boolean;
  showCDXOptions?: boolean;
  showVarSwapOptions?: boolean;
  showIRSOptions?: boolean;
  containerStyle?: CSSProperties;
  onSelect?: (value: any) => void;
};

const CalculatedSidePanel: React.FC<Props> = ({
  disabled,
  showLimitedOptions,
  showLimitedOptionsWithFullDisplay,
  showCDXOptions,
  showVarSwapOptions,
  showIRSOptions,
  containerStyle,
  onSelect: onSelectExternal,
}) => {
  const { isTransmitted, completed, isSplit } = useOEMSOrderForm();

  const orderSide = useWatch({ name: "orderSide" });

  const instrument = useInstrument();

  const disableEditing = isTransmitted || isSplit || completed;

  const sideOptions = React.useMemo(
    () =>
      getOrderSideOptions(
        instrument?.option ?? false,
        instrument?.financialSubType.description ?? "",
        false,
        {
          limited: showLimitedOptions || showLimitedOptionsWithFullDisplay,
          fullDisplay: showLimitedOptionsWithFullDisplay,
          cdx: showCDXOptions,
          varSwap: showVarSwapOptions,
          irs: showIRSOptions,
        }
      ),
    [
      instrument?.option,
      instrument?.financialSubType.description,
      showLimitedOptions,
      showLimitedOptionsWithFullDisplay,
      showCDXOptions,
      showVarSwapOptions,
      showIRSOptions,
    ]
  );

  return (
    <PanelRow style={{ ...containerStyle }}>
      <FormController
        name="orderSide"
        render={({ onChange }) => (
          <OrderSideContainer>
            <ButtonGroupSelect
              title="Side"
              disabled={disabled || disableEditing}
              selectedValue={orderSide}
              onSelect={(value: any) => {
                onChange(value);
                onSelectExternal?.(value);
              }}
              options={sideOptions}
              disableDeselect
              disconnected
              groupId="order-side"
            />
          </OrderSideContainer>
        )}
      />
    </PanelRow>
  );
};

export default CalculatedSidePanel;
