/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelectDataValue } from "@app-views/dashboards/pdf/utils";
import {
  EntrySourceSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { generalLedgerEntrySourcesOptions } from "@enfusion-ui/types";
import { ChannelDataType } from "@enfusion-ui/types/build/dashboards";
import { sortBy } from "lodash";
import * as React from "react";

import PdfSelectionWidget from "../PdfSelectionWidget";

const PdfEntrySourceSelectionWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const { defaultValue, key } = config as EntrySourceSelectionWidgetConfig;

  useSelectDataValue(key, "Entry Source", ChannelDataType.String, defaultValue);

  const inputValue = React.useMemo(() => {
    let result = "";
    if (defaultValue && generalLedgerEntrySourcesOptions) {
      const formattedValue = defaultValue.reduce(
        (res: string, value: string) => {
          const selectedOption = generalLedgerEntrySourcesOptions.find(
            ({ value: val }) => val === value
          );
          if (selectedOption) res = `${res}, ${selectedOption?.label}`;
          return res;
        },
        ""
      );
      result = formattedValue;
    }
    return result;
  }, [defaultValue]);

  return <PdfSelectionWidget label={key} value={inputValue} />;
};

export default PdfEntrySourceSelectionWidget;
