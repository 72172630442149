import { EMPTY_ARRAY } from "@enfusion-ui/core";
import { MiddlewareFunction } from "@enfusion-ui/hooks";
import { GetRowIdParams } from "ag-grid-community";
import { debounce, noop } from "lodash";
import * as React from "react";
import { UseFormMethods } from "react-hook-form";

export function useRefCallback<RefType extends (...args: any[]) => any>(
  method: RefType,
  deps: React.DependencyList,
  defaultValue: RefType = noop as RefType
) {
  const callbackRef = React.useRef<RefType>(defaultValue);

  // needs to be a ref because of use in a event listener
  callbackRef.current = React.useCallback(method, deps);

  const calledMethod = React.useCallback(
    (...args: any[]) => callbackRef.current(...args),
    [callbackRef]
  );

  return calledMethod as RefType;
}

export function useRefValue<T = any>(value: T) {
  const valueRef = React.useRef<T>(value);
  valueRef.current = value;
  return valueRef;
}

export const createCoreBroadcastChannel = () => ({
  broadcastMessage: jest.fn(),
  subscribe: jest.fn(),
});

export function useMounted(defaultValue = true) {
  const mountedRef = React.useRef(defaultValue);
  const get = useRefCallback(() => mountedRef.current, [mountedRef]);

  React.useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, [mountedRef]);

  return get;
}

export function useRefState<T = any>(value: T) {
  const valueRef = React.useRef<T>(value);
  const [valueState, setValue] = React.useState<T>(value);

  React.useEffect(() => {
    valueRef.current = valueState;
  }, [valueState]);

  return [valueRef, setValue] as const;
}

export function useRect(
  ref: React.RefObject<HTMLElement>,
  pollInterval: number | null = 1000
) {
  const isMounted = useMounted();
  const [rect, setRect] = React.useState(() =>
    ref.current?.getBoundingClientRect()
  );

  React.useEffect(() => {
    requestAnimationFrame(() => {
      if (isMounted()) setRect(ref.current?.getBoundingClientRect());
    });
    let timer: number | undefined;
    if (pollInterval !== null) {
      timer = setInterval(() => {
        if (isMounted()) setRect(ref.current?.getBoundingClientRect());
      }, pollInterval) as unknown as number;
    }
    return () => clearInterval(timer);
  }, [pollInterval]);

  const { x, y, width, height } = rect || { x: 0, y: 0, width: 0, height: 0 };

  return React.useMemo(() => ({ x, y, width, height }), [x, y, width, height]);
}

export interface MapStableActions<T extends object> {
  set: <K extends keyof T>(key: K, value: T[K]) => void;
  setAll: (newMap: T) => void;
  remove: <K extends keyof T>(key: K) => void;
  reset: () => void;
}

export interface MapActions<T extends object> extends MapStableActions<T> {
  get: <K extends keyof T>(key: K) => T[K];
}

export const useMap = <T extends object = any>(
  initialMap: T = {} as T
): [React.MutableRefObject<T>, MapActions<T>] => {
  const map = React.useRef<T>(initialMap);

  const stableActions = React.useMemo<MapStableActions<T>>(
    () => ({
      set: (key, entry) => {
        map.current = {
          ...map.current,
          [key]: entry,
        };
      },
      setAll: (newMap: T) => {
        map.current = newMap;
      },
      remove: (key) => {
        const { [key]: omitIgnored, ...rest } = map.current;
        map.current = rest as T;
      },
      reset: () => (map.current = initialMap),
    }),
    [map]
  );

  const utils = {
    get: React.useCallback((key) => map.current[key], [map]),
    ...stableActions,
  } as MapActions<T>;

  return [map, utils];
};

export function useMouseOverSimple(debounceDelay = 50) {
  const [over, setOver] = React.useState(false);

  const handleSetOver = useRefCallback(
    debounce((newOver: boolean) => setOver(newOver), debounceDelay),
    []
  );

  const onMouseEnter = React.useCallback(() => {
    handleSetOver(true);
  }, [handleSetOver]);

  const onMouseLeave = React.useCallback(() => {
    handleSetOver(false);
  }, [handleSetOver]);

  return { onMouseEnter, onMouseLeave, over };
}

export const useRestServerOptions = () => {
  return {
    options: [
      { label: "TEST1", value: "test1" },
      { label: "TEST2", value: "test2" },
      { label: "TEST3", value: "test3" },
    ],
    loading: false,
  };
};

export const useRestAbortableOptions = () => {
  return {
    options: [
      { label: "TEST1", value: "test1" },
      { label: "TEST2", value: "test2" },
      { label: "TEST3", value: "test3" },
    ],
    loading: false,
  };
};

export function useEverTrue(value: boolean) {
  const [everTrue, setEverTrue] = React.useState(value);

  React.useEffect(() => {
    if (!everTrue && value) {
      setEverTrue(true);
    }
  }, [everTrue, value]);

  return everTrue;
}

export function usePrevious<T = any>(value: T) {
  const ref = React.useRef<T>();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useScale() {
  return 1;
}

export function useUserAgent() {
  return {
    browser: {
      fullName: "Chrome",
      name: "chrome",
      version: "200.0.0",
    },
    os: {
      fullName: "windows",
      name: "win",
    },
  };
}

export function useOnScreen() {
  return true;
}

export function useLocalStorage(key: string, initialValue: any) {
  return [initialValue, jest.fn()];
}

export function useModalState<T = any>({
  defaultOpen = false,
  onOpen,
  onClose,
}: {
  defaultOpen?: boolean;
  onOpen?: (passthrough: T | null) => void;
  onClose?: (passthrough: T | null) => void;
} = {}) {
  const [open, setOpen] = React.useState(defaultOpen);
  const openContentRef = React.useRef<T | null>(null);

  const openModal = useRefCallback(
    (passthrough: T | null = null) => {
      openContentRef.current = passthrough;
      setOpen(true);
      onOpen?.(openContentRef.current);
    },
    [onOpen]
  );
  const closeModal = useRefCallback(() => {
    setOpen(false);
    onClose?.(openContentRef.current);
  }, [onClose]);

  return React.useMemo(
    () => ({ open, openModal, closeModal, openContentRef }),
    [open, openModal, closeModal, openContentRef]
  );
}

export function useFormTableList() {
  return {
    addRow: noop,
    getRowId: (params: GetRowIdParams) => params.data.__row_id,
    modifyRow: noop,
    deleteRows: noop,
    initializeData: noop,
    getContextMenuItems: noop,
  };
}

export function useFormTableListSetter(
  name: string,
  getValues: UseFormMethods["getValues"],
  setValue: UseFormMethods["setValue"]
) {
  return useRefCallback(
    (setter) => {
      const val = getValues(name);
      setValue(name, setter(val));
    },
    [name, getValues, setValue]
  );
}

export function useReducerWithMiddleware<State, Action>(
  reducer: React.Reducer<State, Action>,
  initialState: State,
  middlewareFns: MiddlewareFunction<State, Action>[] = EMPTY_ARRAY,
  afterwareFns: MiddlewareFunction<State, Action>[] = EMPTY_ARRAY,
  allowStateModification = false
): [State, React.Dispatch<Action>] {
  const [state, dispatchCore] = React.useReducer<React.Reducer<State, Action>>(
    reducer,
    initialState
  );
  const currentActionRef = React.useRef<Action | null>(null);
  const readyRef = React.useRef(false);

  const dispatch = useRefCallback(
    (action: Action) => {
      dispatchCore(action);
    },
    [dispatchCore]
  );

  const dispatchWithMiddleware = useRefCallback(
    (action: Action) => {
      const res = middlewareFns.reduce(
        (res, middlewareFn) => {
          const change = middlewareFn(res, currentActionRef.current!);
          return allowStateModification ? change : res;
        },
        { ...state }
      );

      if (allowStateModification) {
        dispatch({
          type: "__SET__",
          payload: res,
          nextAction: action,
        } as any);
      } else {
        dispatch(action);
      }
    },
    [state]
  );

  const runAfterware = useRefCallback(() => {
    if (!!currentActionRef.current) {
      const res = afterwareFns.reduce(
        (res, afterwareFn) => {
          const change = afterwareFn(res, currentActionRef.current!);
          return allowStateModification ? change : res;
        },
        { ...state }
      );

      if (allowStateModification) {
        dispatchCore({
          type: "__SET__",
          payload: res,
          nextAction: null,
        } as any);
      }

      currentActionRef.current = null;
    }
  }, [state, allowStateModification, afterwareFns]);

  React.useEffect(() => {
    readyRef.current = true;
    runAfterware();
  }, [state, runAfterware]);

  return [state, dispatchWithMiddleware];
}
