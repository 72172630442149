import { styled } from "@enfusion-ui/web-core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faGripLines } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

const GripIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: calc(50% - 6px);
  top: -2px;
`;

const GripHandle = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div
      ref={ref}
      className="react-resizable-handle react-resizable-handle-n"
      {...props}
    >
      <GripIcon icon={faGripLines as IconDefinition} size="sm" {...props} />
    </div>
  );
});

export default GripHandle;
