import { useRefCallback } from "@enfusion-ui/hooks";
import { styled } from "@enfusion-ui/web-core";
import XLSX from "@sheet/cryptopivot";
import { AgGridReact } from "ag-grid-react";
import { isUndefined, memoize } from "lodash";
import * as React from "react";
import { Spinner } from "../../../display/Spinner";
import { getTextWidth, mergeStyles, parseLinkTarget, resizeRow, valueTooltipGetter, } from "./utils";
const SpinnerWrapper = styled.div `
  height: 100%;
  width: 100%;
  object-position: center;
  border: 1px solid var(--input-border);
`;
const getCellStyles = (params) => {
    const { type, style = {}, otherStyles = {} } = params;
    let backgroundColor = style.fgColor?.rgb ||
        style.bgColor?.rgb;
    if (backgroundColor === "FFFFFF") {
        backgroundColor = undefined;
    }
    const fontSize = `${style.sz ? style.sz + 1 : 12}px`;
    // indent is a string and the min padding we want in 0.25rem
    const padding = 0.7 * (Number(style.alignment?.indent || "0") + 1) - 0.45;
    const styleColor = style.color;
    const color = backgroundColor && styleColor?.rgb
        ? styleColor?.rgb
        : backgroundColor
            ? "000000"
            : undefined;
    const textDecoration = `${style.underline === 1 || style.underline === 2 ? "underline" : ""} ${style.strike ? "line-through" : ""}`.trim() ?? "none";
    return {
        style: {
            height: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: type === "n" ? "flex-end" : "normal",
            textDecoration,
            textDecorationStyle: style.underline === 2 ? "double" : "solid",
            color: color ? `#${color}` : undefined,
            fontWeight: style.bold ? 700 : 500,
            fontSize,
            fontFamily: "inherit",
            [`padding${type !== "n" && style.alignment?.horizontal !== "right"
                ? "Left"
                : "Right"}`]: `${padding}rem`,
            ...otherStyles,
        },
        backgroundColor: backgroundColor ? `#${backgroundColor}` : "transparent",
    };
};
const calculateStyles = (cellKey, sheetRef, cellStyles) => {
    let style = undefined;
    if (sheetRef) {
        style = XLSX.utils.get_computed_style(sheetRef, cellKey);
    }
    return getCellStyles({ style: mergeStyles(style, cellStyles) });
};
const CellRenderer = React.forwardRef(function CellRenderer({ value, context, colDef, node, data }, ref) {
    const cellKey = `${colDef?.field}${data.idx + 1}`;
    const [selected, setSelected] = React.useState(node.isSelected());
    React.useImperativeHandle(ref, () => {
        return {
            refresh: (params) => {
                setSelected(params.node.isSelected());
                return true;
            },
        };
    });
    let val = value;
    let type = "s";
    let style = undefined;
    const otherStyles = {};
    if (context.sheetRef) {
        style = XLSX.utils.get_computed_style(context.sheetRef, cellKey);
    }
    const handleClick = useRefCallback(() => {
        const obj = value?.l;
        if (obj) {
            const target = parseLinkTarget(obj.Target);
            if (Array.isArray(target)) {
                context.focusRow(...target);
            }
            else {
                window.open(target, "_blank");
            }
        }
    }, [value]);
    if (typeof value === "object") {
        type = value.t;
        style = mergeStyles(style, value.s);
        if (value.l) {
            val = value.l.display || "";
            otherStyles.cursor = "pointer";
            otherStyles.color = "var(--primary)";
        }
        else {
            val = value.w || "";
        }
    }
    const cellStyles = getCellStyles({
        type,
        style,
        otherStyles,
    });
    return (React.createElement("div", { style: {
            width: "100%",
            height: "100%",
            backgroundColor: cellStyles.backgroundColor,
        }, onClick: handleClick },
        React.createElement("div", { style: {
                ...cellStyles.style,
                backgroundColor: selected ? "rgba(0, 88, 128, 0.6)" : "transparent",
            } }, val)));
});
const getColumnSpan = ({ colDef, data, context, column }) => {
    if (!colDef.field) {
        return 1;
    }
    const thisData = data[colDef.field];
    if (!thisData ||
        Object.keys(thisData).length === 0 ||
        !thisData.w ||
        thisData.w.trim().length === 0) {
        return 1;
    }
    const cellKey = `${colDef.field}${data.idx + 1}`;
    const styles = calculateStyles(cellKey, context.sheetRef, thisData.s);
    const textWidth = getTextWidth(thisData.w, styles.style.font || "");
    if (textWidth < column.getActualWidth() - 4) {
        // 4px left padding
        return 1;
    }
    const nextColField = XLSX.utils.encode_col(XLSX.utils.decode_col(colDef.field) + 1);
    const nextData = data[nextColField];
    if (!nextData) {
        return 1;
    }
    if (Object.keys(nextData).length === 0 ||
        !nextData.w ||
        nextData.w.trim().length === 0) {
        return 2;
    }
    return 1;
};
const getRowNodeId = (row) => {
    return row.data.idx;
};
export const Sheet = React.memo(function Sheet({ defaultColumnDefs, defaultRowData, defaultPinnedData, focusRow, sheetRef, onGridReady, active, }) {
    const [columnDefs] = React.useState(defaultColumnDefs);
    const [rowData] = React.useState(defaultRowData);
    const [pinnedRowData] = React.useState(defaultPinnedData);
    const [display, setDisplay] = React.useState(active);
    React.useEffect(() => {
        if (!display && active) {
            setDisplay(true);
        }
    }, [display, active]);
    const handleGridReady = (e) => {
        e.api.updateGridOptions({ pinnedTopRowData: pinnedRowData });
        onGridReady(e);
    };
    const getContextMenuItems = (params) => {
        const result = [
            "copy",
            "copyWithHeaders",
            {
                name: `${params.column?.getPinned() ? "Unp" : "P"}in Column`,
                action: function () {
                    if (params.column?.getPinned()) {
                        params.api?.applyColumnState({
                            state: [
                                {
                                    colId: params.column?.getColId(),
                                    pinned: null,
                                },
                            ],
                        });
                    }
                    else {
                        const id = params.column?.getId();
                        if (!isUndefined(id)) {
                            params.api?.setColumnPinned(id, "left");
                        }
                    }
                },
            },
        ];
        return result;
    };
    const processCellForClipboard = ({ value }) => {
        if (!value)
            return "";
        if (typeof value !== "object")
            return value;
        return value.v ?? "";
    };
    if (!display || !columnDefs || columnDefs.length === 0) {
        return (React.createElement(SpinnerWrapper, null,
            React.createElement(Spinner, null)));
    }
    return (React.createElement(AgGridReact, { suppressCellFocus: true, suppressRowHoverHighlight: true, suppressBrowserResizeObserver: true, columnDefs: columnDefs, multiSortKey: "ctrl", components: {
            cellRenderer: CellRenderer,
        }, defaultColDef: {
            resizable: true,
            tooltipValueGetter: valueTooltipGetter,
            autoHeight: true,
            wrapText: true,
            colSpan: memoize(getColumnSpan),
        }, context: { focusRow, sheetRef }, rowData: rowData, getRowId: getRowNodeId, getContextMenuItems: getContextMenuItems, pinnedTopRowData: pinnedRowData, onGridReady: handleGridReady, onColumnResized: resizeRow, overlayNoRowsTemplate: "Empty excel sheet.", processCellForClipboard: processCellForClipboard }));
});
