import { OrderFormValues } from "@enfusion-ui/core";
import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { ControlledSelect } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export type RoutingTargetOptions = {
  label: string;
  value: string;
};

export type RoutingTargetSelectionProps = {
  disabled?: boolean;
};

const RoutingTargetSelection: React.FC<RoutingTargetSelectionProps> = ({
  disabled = false,
}) => {
  const { setValue } = useFormContext<OrderFormValues>();
  const { swapOrder, targetCompId } = useWatch({
    name: ["swapOrder", "targetCompId"],
  });

  const fetchElectronicRoutingTargets = React.useCallback(
    (ac?: AbortController) =>
      REST_API.OEMS.GET_ELECTRONIC_ROUTING_TARGETS.FETCH(
        swapOrder,
        undefined,
        undefined,
        ac
      ),
    [swapOrder]
  );

  const { options, loading } = useRestAbortableOptions(
    fetchElectronicRoutingTargets,
    (t) => ({ label: t.description, value: t.targetCompId })
  );

  React.useEffect(() => {
    if (!loading && !options?.find((option) => option.value === targetCompId)) {
      setValue("targetCompId", null);
    }
  }, [options, loading]);

  return (
    <ControlledSelect
      name="targetCompId"
      label="Target"
      options={options}
      isLoading={loading}
      disabled={disabled}
      required={false}
      mobileBasis="50%"
      inputId="target-selection-id"
    />
  );
};

export default RoutingTargetSelection;
