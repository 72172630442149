/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelectDataValue } from "@app-views/dashboards/pdf/utils";
import {
  NavSourceSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types/build/dashboards";
import * as React from "react";

import PdfSelectionWidget from "../PdfSelectionWidget";

const PdfNavSourceSelectionWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const { defaultValue, key } = config as NavSourceSelectionWidgetConfig;

  useSelectDataValue(
    key,
    "Benchmark NAV Source",
    ChannelDataType.String,
    defaultValue
  );

  return <PdfSelectionWidget label={key} value={defaultValue} />;
};

export default PdfNavSourceSelectionWidget;
