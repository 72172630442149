import {
  FinancialSubTypeWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType, FinancialSubtype } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import FinancialSubTypeSelect from "../../../../../reports/components/ReportQueryComponents/FinancialSubTypeSelect";

const FinancialSubTypeWidget = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, key } = config as FinancialSubTypeWidgetConfig;
  const [value, setValue] = React.useState<FinancialSubtype[] | null>(
    defaultValue
  );

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Financial Type",
        type: ChannelDataType.String,
      },
    ]);
  }, [addChannelKeys, key]);

  React.useEffect(() => {
    setChannelData(value ? [value] : [[]]);
  }, [setChannelData, value]);

  const handleChange = (newValue: FinancialSubtype[] | null) => {
    setValue(newValue);
  };

  return (
    <CenterContent>
      <FinancialSubTypeSelect
        selectProps={{ label: key }}
        value={value}
        onChange={handleChange}
      />
    </CenterContent>
  );
};

export default FinancialSubTypeWidget;
