import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const TitleContainer = styled.div<{
  subTitle?: boolean;
  align: "flex-start" | "center" | "flex-end";
}>`
  width: 100%;
  height: ${({ subTitle }) => (subTitle ? "45px" : "32px")};
  min-height: 32px;
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align};
  justify-content: center;
  background: var(--background-color-1-hover);
`;

const Title = styled.div`
  font-family: var(--default-font);
  font-size: 19px;
  line-height: 24px;
  color: var(--text-normal);
  padding: 0px var(--spacing);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const Subtitle = styled.div`
  font-family: var(--default-font);
  font-size: 16px;
  line-height: 115%;
  letter-spacing: 0.01em;
  color: var(--text-normal);
  margin-top: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const WidgetTitle: React.FC<{
  title?: string;
  subTitle?: string;
  className?: string;
  align?: "flex-start" | "center" | "flex-end";
}> = ({ title, subTitle, className, align = "center" }) => {
  if (!title) return <></>;
  return (
    <TitleContainer align={align} className={className} subTitle={!!subTitle}>
      <Title>{title}</Title>
      {subTitle && <Subtitle>{subTitle}</Subtitle>}
    </TitleContainer>
  );
};

export default React.memo(WidgetTitle);
