import { BookTreeEntry, NodeData } from "@enfusion-ui/types";
import { REST_API } from "@enfusion-ui/web-core";
import { memoize, omit } from "lodash";
import * as React from "react";

import { AllocationContext } from "./context";

const sortByName = (a: NodeData, b: NodeData) => a.name.localeCompare(b.name);
export const bookTreeToNodeData = (
  files: Array<BookTreeEntry>,
  depth = 1,
  baseIds: {
    parent?: string;
  } = {}
): Array<NodeData> =>
  files
    .reduce((res, file) => {
      const parentPath =
        baseIds.parent ??
        file.path.substring(0, file.path.indexOf(file.name) - 1);
      const path = file.name.includes("/")
        ? `${parentPath}/${encodeURIComponent(file.name)}`
        : `${parentPath}/${file.name}`;

      const subNodes = bookTreeToNodeData(file.children || [], depth + 1, {
        parent: path,
      });
      res.push({
        ...omit(file, ["children"]),
        id: `${file.id}` ?? "",
        file: file.leaf,
        parentId: baseIds.parent,
        depth: file.leaf ? 3 : depth,
        nodes: subNodes,
        nodeCount: subNodes.length,
        level: file.level,
        path,
        name: file.name,
        bookId: file.id,
      });
      return res;
    }, [] as Array<NodeData>)
    .sort(sortByName);

export type AllocationViewState = {
  books: NodeData[];
};

const getTRSOptionsCached = memoize(async function getTRSOptionsCore(
  custodianId: number | null
) {
  const res = await REST_API.LEGALENTITY.GET_TRS_AGREEMENTS.FETCH(custodianId);
  return res.map((data) => ({
    value: data.id,
    label: data.description,
  }));
});

async function getTRSOptions(custodianId: number | null) {
  try {
    return getTRSOptionsCached(custodianId);
  } catch (err) {
    getTRSOptionsCached.cache.delete(custodianId);
    throw err;
  }
}

const getBorrowAgreementOptionsCached = memoize(
  async function getBorrowAgreementOptionsCore(custodianId: number | null) {
    const res = await REST_API.LEGALENTITY.GET_BORROW_AGREEMENTS.FETCH(
      custodianId
    );
    return res.map((data) => ({
      value: data.id,
      label: data.description,
    }));
  }
);

async function getBorrowAgreementOptions(custodianId: number | null) {
  try {
    return getBorrowAgreementOptionsCached(custodianId);
  } catch (err) {
    getBorrowAgreementOptionsCached.cache.delete(custodianId);
    throw err;
  }
}

const initialState: AllocationViewState = {
  books: [],
};

const AllocationProvider: React.FC<
  React.PropsWithChildren<{ enabled: boolean }>
> = ({ enabled, children }) => {
  const [state, setState] = React.useState<AllocationViewState>(initialState);

  React.useEffect(() => {
    const fetchData = async () => {
      const levels = await REST_API.FUND.GET_BOOKS_LEVELS.FETCH();

      const selectedLevels = levels.reduce((ids: string, book) => {
        // eslint-disable-next-line no-constant-condition
        if (book.name === "Group" || book.name === "Portfolio Manager") {
          const bookId =
            ids.length === 0 ? book.id.toString() : `,${book.id.toString()}`;
          // eslint-disable-next-line no-param-reassign
          ids += bookId;
        }
        return ids;
      }, "");

      const bookTree = await REST_API.FUND.GET_BOOKS_HIERARCHY.FETCH(
        selectedLevels || "2,9",
        false
      );
      const subNodes = bookTreeToNodeData(bookTree.children);
      const books = {
        ...omit(bookTree, ["children"]),
        id: `${bookTree.id}` ?? "",
        file: false,
        bookId: bookTree.id,
        nodes: subNodes,
        nodeCount: subNodes.length,
        depth: 0,
        name: bookTree.name,
        defaultOpen: true,
      };

      setState((prevState) => ({
        ...prevState,
        books: [books],
      }));
    };

    if (enabled) fetchData();
  }, [enabled]);

  return (
    <AllocationContext.Provider
      value={{
        ...state,
        getTRSOptions,
        getBorrowAgreementOptions,
      }}
    >
      {children}
    </AllocationContext.Provider>
  );
};

export default AllocationProvider;
