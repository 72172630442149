import { useRefCallback } from "@enfusion-ui/hooks";
import { css, styled } from "@enfusion-ui/web-core";
import { debounce } from "lodash";
import * as React from "react";

const PADDING_SIZE = 100;
const BORDER_SIZE = 2;
const BASE_OFFSET_SIZE = 2;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: none;
`;

const RightPanel = styled.div<{ offset: number; moving: boolean }>`
  pointer-events: all;
  position: absolute;
  box-sizing: content-box;
  width: ${BORDER_SIZE}px;
  padding-left: ${BORDER_SIZE}px;
  padding-right: ${BORDER_SIZE}px;
  margin-right: ${({ offset }) => offset - BORDER_SIZE}px;
  height: 100%;

  ${({ offset, moving }) =>
    moving
      ? css`
          :hover {
            padding-left: ${PADDING_SIZE}px;
            padding-right: ${PADDING_SIZE}px;
            margin-right: ${offset - PADDING_SIZE}px;
            cursor: ew-resize;
          }
        `
      : ""}

  ::after {
    content: "";
    display: block;
    background-color: var(--background-accent);
    width: ${BORDER_SIZE}px;
    height: 100%;
    cursor: ew-resize;
    opacity: 0.4;
    z-index: 2;
  }

  :hover::after {
    opacity: 0.9;
  }
`;

export type HorizontalResizeBarProps = {
  right?: number;
  offset?: number;
  min?: number;
  max?: number;
  className?: string;
  open?: boolean;
  onResizeStop?: (width: number) => void;
  onResizeInter?: (width: number) => void;
};

export const HorizontalResizeBar: React.FC<HorizontalResizeBarProps> = ({
  min = 5,
  max,
  right: rightBase = 10,
  offset = 0,
  className,
  open = true,
  onResizeStop,
  onResizeInter,
}) => {
  const [moving, setMoving] = React.useState(false);
  const [right, setRight] = React.useState(rightBase);
  const pos = React.useRef(0);
  const elementRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    setRight(rightBase);
  }, [rightBase]);

  const debounceResizeStop = useRefCallback(
    debounce((val: number) => {
      onResizeStop?.(val);
    }, 300),
    [onResizeStop]
  );

  const resize = useRefCallback(
    (e: MouseEvent) => {
      const dx = pos.current - e.x;
      pos.current = e.x;
      let val = Math.max(
        Number(
          getComputedStyle(elementRef.current!, "").right.replace("px", "")
        ) + dx,
        min
      );
      if (typeof max === "number" && val > max) val = max;

      elementRef.current!.style.right = `${val}px`;
      onResizeInter?.(val);
      setRight(val);
      debounceResizeStop(val);
      e.preventDefault();
      e.stopImmediatePropagation();
    },
    [min, max, onResizeStop, onResizeInter]
  );

  const handleMouseDown = useRefCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.nativeEvent.offsetX <= PADDING_SIZE + BASE_OFFSET_SIZE) {
        setMoving(true);
        pos.current = e.nativeEvent.x;
        elementRef.current?.addEventListener("mousemove", resize, false);
      }
    },
    []
  );

  const handleStopResize = useRefCallback(() => {
    setMoving(false);
    elementRef.current?.removeEventListener("mousemove", resize, false);
  }, []);

  return (
    <Container className={className}>
      <RightPanel
        ref={elementRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleStopResize}
        onMouseOut={handleStopResize}
        offset={offset}
        moving={moving}
        style={{ right, display: open ? "block" : "none" }}
      />
    </Container>
  );
};
