import { SizedChart } from "@app-views/analytics/components/SizedChart";
import { TempTriangleIcon } from "@app-views/analytics/components/TempTriangleIcon";
import { styled } from "@enfusion-ui/web-core";
import { SeriesPieOptions, SubtitleOptions, TooltipOptions } from "highcharts";
import * as React from "react";

import { defaultChartOptions, defaultTooltipOptions } from "../../chartConfigs";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
`;

const defaultOptions: Highcharts.Options = {
  chart: {
    ...defaultChartOptions,
    type: "pie",
    animation: false,
    spacing: [0, 0, 5, 0],
  },
  title: { text: "" },
  subtitle: {
    useHTML: true,
    floating: true,
    verticalAlign: "middle",
    y: 0,
  },
  credits: { enabled: false },
  tooltip: {
    ...defaultTooltipOptions,
    outside: true,
  },
  plotOptions: {
    pie: {
      size: "100%",
      shadow: false,
      borderRadius: 5,
      innerSize: "80%",
      showInLegend: true,
      dataLabels: { enabled: false },
      borderColor: "var(--background-color-2)",
    },
  },
  legend: {
    margin: 0,
    padding: 0,
    itemDistance: 10,
    symbolPadding: 2,
  },
};

type PieChartBlockProps = {
  title: string;
  tooltipFormatter: TooltipOptions["formatter"];
  seriesData: SeriesPieOptions["data"];
  centerText?: SubtitleOptions["text"];
};

export const PieChartBlock: React.FC<PieChartBlockProps> = React.memo(
  ({ title, centerText, tooltipFormatter, seriesData }) => {
    const getOptions = React.useCallback(
      (width: number, height: number): Highcharts.Options => ({
        ...defaultOptions,
        chart: {
          ...defaultOptions.chart,
          width: width || 10,
          height: height || 10,
        },
        tooltip: {
          ...defaultOptions.tooltip,
          formatter: tooltipFormatter,
        },
        subtitle: {
          ...defaultOptions.subtitle,
          text: centerText,
        },
        series: [
          {
            type: "pie",
            data: seriesData,
          },
        ],
      }),
      [tooltipFormatter, seriesData, centerText]
    );

    return (
      <Container>
        <TopRow>
          {title}
          <TempTriangleIcon />
        </TopRow>
        <SizedChart getOptions={getOptions} />
      </Container>
    );
  }
);
