import { UNKNOWN_INSTRUMENT_INFO } from "@enfusion-ui/core";
import { InstrumentInfo, Quote } from "@enfusion-ui/types";
import { IconButton } from "@enfusion-ui/web-components";
import { useTheme } from "@enfusion-ui/web-core";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";

import { useMarketData } from "../../../context/marketData/context";
import { useWatchList } from "../../../context/watchList/context";
import { FormattedValue } from "./FormattedValue";
import {
  ArrowIcon,
  BoldText,
  LabelText,
  ListItemContent,
  ListItemTitleContainer,
  ListItemValuesContainer,
  ValueContainer,
  ValueSection,
  ValueText,
  WatchListItemContainer,
} from "./styles";
import { getColorBasedOnChange, getIconProps } from "./utils";

type WatchListItemsPropType = {
  instrument: InstrumentInfo;
  enableDelete?: boolean;
  onDelete?: (instrument: InstrumentInfo) => void;
  widgetMode?: boolean;
};

const WatchListItem: React.FC<WatchListItemsPropType> = ({
  instrument,
  onDelete,
  enableDelete = true,
  widgetMode = false,
}) => {
  const { theme } = useTheme();
  const { subscribeToMarketData } = useMarketData();
  const { openOrder } = useWatchList();
  const [quote, setQuote] = React.useState<Quote | null>(null);

  React.useEffect(() => {
    if (instrument && instrument.id !== UNKNOWN_INSTRUMENT_INFO.id) {
      return subscribeToMarketData(instrument.id, (v) => setQuote(v));
    }

    setQuote(null);
  }, [instrument]);

  if (!instrument) return null;

  const openOrderWithClickTarget =
    (clickTarget?: "bid" | "ask" | "last") =>
    (e: React.MouseEvent<HTMLDivElement | SVGSVGElement, MouseEvent>) => {
      e.stopPropagation();
      if (clickTarget === "bid") {
        openOrder({
          instrumentId: instrument.id,
          orderSide: "Sell",
          limitPrice: quote?.bid,
          orderType: "Limit",
        });
      } else if (clickTarget === "ask") {
        openOrder({
          instrumentId: instrument.id,
          orderSide: "Buy",
          limitPrice: quote?.ask,
          orderType: "Limit",
        });
      } else if (clickTarget === "last") {
        openOrder({
          instrumentId: instrument.id,
          limitPrice: quote?.last,
          orderType: "Limit",
        });
      } else {
        openOrder({
          instrumentId: instrument.id,
        });
      }
    };

  return (
    <WatchListItemContainer
      widgetMode={widgetMode}
      data-testid="watchList-item"
      data-e2e-id="watchList-item"
    >
      <ArrowIcon
        color={getIconProps(quote?.change || 0, theme).color}
        icon={getIconProps(quote?.change || 0, theme).icon}
        size="2x"
        onClick={openOrderWithClickTarget()}
      />
      <ListItemContent>
        {!widgetMode ? (
          <ListItemTitleContainer>
            <BoldText
              data-e2e-id="instrument-description"
              onClick={openOrderWithClickTarget()}
            >
              {instrument?.description}
            </BoldText>
            {enableDelete ? (
              <IconButton
                data-e2e-id="watchlist-item-delete-icon"
                hoverable
                icon={faTimesCircle}
                onClick={() => onDelete?.(instrument)}
                size="1x"
              />
            ) : (
              <></>
            )}
          </ListItemTitleContainer>
        ) : (
          <></>
        )}
        <ListItemValuesContainer onClick={openOrderWithClickTarget()}>
          <ValueSection width="35%">
            <FormattedValue
              dataE2EId="watchlist-item-bid"
              label="Bid"
              value={quote?.bid ?? null}
              onClick={openOrderWithClickTarget("bid")}
              showChange
            />
            <FormattedValue
              dataE2EId="watchlist-item-ask"
              label="Ask"
              value={quote?.ask ?? null}
              onClick={openOrderWithClickTarget("ask")}
              showChange
            />
          </ValueSection>
          <ValueSection width="35%">
            <FormattedValue
              dataE2EId="watchlist-item-last"
              label="Last"
              value={quote?.last ?? null}
              onClick={openOrderWithClickTarget("last")}
            />
            <FormattedValue label="Volume" value={quote?.volume ?? null} />
          </ValueSection>
          <ValueSection width="auto">
            <FormattedValue
              style={{ color: getColorBasedOnChange(quote, theme) }}
              label="Change"
              value={quote?.change ?? null}
            />
            <ValueContainer>
              <LabelText>Currency: </LabelText>
              <ValueText>
                {instrument?.currency?.code ??
                  instrument?.contract?.currency?.code ??
                  instrument?.contractCurrency?.code}
              </ValueText>
            </ValueContainer>
          </ValueSection>
        </ListItemValuesContainer>
      </ListItemContent>
    </WatchListItemContainer>
  );
};

export default WatchListItem;
