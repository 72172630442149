import { useLocalStorage } from "@enfusion-ui/hooks";
import * as React from "react";

import { SettingsContext } from "./context";

const SettingsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [enableOEMSRealtimeUpdate, setEnableOEMSRealtimeUpdate] =
    useLocalStorage("e-enable-oems-realtime-update", true);
  const [enableReportRealtimeUpdate, setEnableReportRealtimeUpdate] =
    useLocalStorage("e-enable-report-realtime-update", true);
  const [enableKeepTicketOpenAfterCreate, setKeepTicketOpenAfterCreate] =
    useLocalStorage("e-keep-ticket-open-after-create", false);
  const [enableClearTicketAfterCreate, setClearTicketAfterCreate] =
    useLocalStorage("e-clear-ticket-after-create", false);
  const [enablePreferCustomOrderForm, setPreferCustomOrderForm] =
    useLocalStorage("e-prefer-custom-order-form", false);

  const toggleOEMSRealtimeUpdate = () =>
    setEnableOEMSRealtimeUpdate((prev) => !prev);
  const toggleReportRealtimeUpdate = () =>
    setEnableReportRealtimeUpdate((prev) => !prev);
  const toggleKeepTicketOpenAfterCreate = () =>
    setKeepTicketOpenAfterCreate((prev) => !prev);
  const toggleClearTicketAfterCreate = () =>
    setClearTicketAfterCreate((prev) => !prev);
  const togglePreferCustomOrderForm = () =>
    setPreferCustomOrderForm((prev) => !prev);

  return (
    <SettingsContext.Provider
      value={{
        enableOEMSRealtimeUpdate,
        enableReportRealtimeUpdate,
        toggleOEMSRealtimeUpdate,
        toggleReportRealtimeUpdate,

        enableKeepTicketOpenAfterCreate,
        enableClearTicketAfterCreate,
        enablePreferCustomOrderForm,
        toggleKeepTicketOpenAfterCreate,
        toggleClearTicketAfterCreate,
        togglePreferCustomOrderForm,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
