import ControlledAccountSelect from "@app-components/inputs/account/ControlledAccountSelect";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import { InputWrapper } from "../../../../dashboards/widgets/styles";
import ControlledBookSelect from "../../../components/ReportQueryComponents/BookSelect";
import { DatePeriodSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledMarketEnvironmentSelect } from "../../../components/ReportQueryComponents/MarketEnvironmentSelect";
import { ControlledPortfolioGroupsSelect } from "../../../components/ReportQueryComponents/PortfolioGroupsSelect";

const PortfolioAnalyticsQuickFilter = () => {
  const { watch } = useFormContext();
  const { bookSelection } = watch(["bookSelection"]);
  const bookSelectionRef = React.useRef(bookSelection);

  return (
    <>
      <InputWrapper>
        <ControlledAccountSelect label="Account(s)" />
      </InputWrapper>
      <InputWrapper>
        <ControlledPortfolioGroupsSelect />
      </InputWrapper>
      <InputWrapper>
        <ControlledBookSelect defaultBookSelection={bookSelectionRef.current} />
      </InputWrapper>
      <InputWrapper>
        <DatePeriodSelection
          name="valueDatePeriodSelection"
          label="Value Date"
        />
      </InputWrapper>

      <ControlledMarketEnvironmentSelect />
    </>
  );
};

export default PortfolioAnalyticsQuickFilter;
