import { AmortizingScheduleType } from "@enfusion-ui/trades";
import {
  ControlledDatePicker,
  ControlledNumericInput,
  FormElement,
  FormElementHeader,
  InputLabelControl,
  LabeledButton,
  PanelRow,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { kebabCase } from "lodash";
import * as React from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
`;

export const AmortizingSchedule: React.FC<{
  name?: string;
  label?: string;
}> = ({ name, label }) => {
  const { errors } = useFormContext();
  const { append, remove, fields } = useFieldArray<AmortizingScheduleType>({
    name: "AmSched",
  });
  const { effectiveDate, terminatingDate, amorAmount } = useWatch({
    name: ["effectiveDate", "terminatingDate", "amorAmount"],
  });

  return (
    <>
      <InputLabelControl label={label} name={name} errors={errors} required />
      <ListContainer>
        <FormElementHeader style={{ margin: "var(--spacing-l) 0" }}>
          Amortizing Schedule
        </FormElementHeader>
        {fields.map((item, index) => {
          return (
            <>
              <PanelRow key={item.id} data-testid={`${kebabCase(name)}-entry`}>
                <ControlledDatePicker
                  name={`effectiveDate[${index}].type`}
                  label="Effective Date"
                  clearable
                />
                <ControlledDatePicker
                  name={`terminatingDate[${index}].type`}
                  label="Terminating Date"
                  clearable
                />
                <ControlledNumericInput
                  name={`amorAmount[${index}].type`}
                  label="Amount"
                  clearable
                  formatStyle="percent"
                />
                <FormElement basis="max-content" style={{ flexGrow: 0 }}>
                  <LabeledButton
                    icon={faTrashCan}
                    onClick={() => remove(index)}
                    data-testid={`${kebabCase(name)}-amor-remove-button`}
                  ></LabeledButton>
                </FormElement>
              </PanelRow>
            </>
          );
        })}
        <LabeledButton
          onClick={() => append({ effectiveDate, terminatingDate, amorAmount })}
        >
          Add
        </LabeledButton>
      </ListContainer>
    </>
  );
};
