import { RoundedDataGrid } from "@app-components/DataGrid";
import { formatCurrency, formatPercentage } from "@enfusion-ui/utils";
import {
  ColDef,
  GetRowIdParams,
  GridReadyEvent,
  ValueGetterParams,
} from "ag-grid-community";
import * as React from "react";

import { TopNGridEntry } from "../types";
import { ChangeCellRenderer } from "./cellRenderers/ChangeCellRenderer";
import { PNLChangeCellRenderer } from "./cellRenderers/PNLChangeCellRenderer";

// #region columnDefs
const defaultColDefs: ColDef = {
  sortable: false,
  resizable: true,
  lockPinned: true,
  lockVisible: true,
  suppressHeaderMenuButton: true,
};

const columnDefs: ColDef[] = [
  {
    maxWidth: 40,
    minWidth: 40,
    field: "selection",
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    pinned: "left",
    resizable: false,
    suppressMovable: true,
  },
  {
    width: 120,
    field: "instrument",
    headerName: "Instrument",
    valueGetter: (params: ValueGetterParams) =>
      params.data.description ?? params.data.ticker ?? "",
    pinned: "left",
  },
  {
    width: 80,
    field: "exchange",
    headerName: "",
    cellStyle: { color: "var(--text-muted)" },
  },
  {
    width: 80,
    field: "totalPnl",
    headerName: "P&L",
    cellRenderer: PNLChangeCellRenderer,
    tooltipValueGetter: ({ value, data }) =>
      formatCurrency(value ?? 0, data.currency ?? "USD", {
        fractionDigits: [0, 2],
        reduce: true,
      }),
  },
  {
    width: 80,
    field: "return",
    headerName: "Returns",
    cellRenderer: ChangeCellRenderer,
    tooltipValueGetter: ({ value }) =>
      formatPercentage(value ?? 0, [0, 2], false, undefined, false, true),
  },
  {
    width: 80,
    field: "exposure",
    headerName: "Exposure",
    cellRenderer: ChangeCellRenderer,
    tooltipValueGetter: ({ value }) =>
      formatPercentage(value ?? 0, [0, 2], false, undefined, false, true),
  },
];
// #endregion

type TopNGridProps = {
  name: "contributors" | "detractors";
  hide: boolean;
  rowData: TopNGridEntry[];
  onGridReady: (e: GridReadyEvent) => void;
  onSelectionChanged: () => void;
};

export const TopNGrid: React.FC<TopNGridProps> = ({
  name,
  hide,
  rowData,
  onGridReady,
  onSelectionChanged,
}) => {
  return (
    <RoundedDataGrid
      gridName={`top-n-${name}`}
      hide={hide}
      height="100%"
      rowData={rowData}
      suppressCellFocus
      suppressContextMenu
      columnDefs={columnDefs}
      rowSelection="multiple"
      suppressScrollOnNewData
      onGridReady={onGridReady}
      suppressRowClickSelection
      defaultColDef={defaultColDefs}
      onSelectionChanged={onSelectionChanged}
      getRowId={(params: GetRowIdParams) => params.data.instrumentId}
    />
  );
};
