import { useTheme } from "@enfusion-ui/web-core";
import * as React from "react";
import { usePrevious } from "react-use";

import { getDisplayValue } from "../../oems/utils/getDisplayValue";
import { LabelText, ValueContainer, ValueText } from "./styles";
import { getColorBasedOnLastValue } from "./utils";

export const FormattedValue: React.FC<{
  label: string;
  value: number | null;
  showChange?: boolean;
  style?: Record<string, string>;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  dataE2EId?: string;
}> = ({ label, value, showChange, style, onClick, dataE2EId }) => {
  const { theme } = useTheme();
  const prevValue = usePrevious(value);
  const [styles, setStyles] = React.useState<
    Record<string, string> | null | undefined
  >(null);

  React.useEffect(() => {
    setStyles(
      showChange
        ? { color: getColorBasedOnLastValue(value, prevValue, theme) }
        : style
    );
  }, [value]);

  return (
    <ValueContainer clickable={!!onClick} onClick={onClick}>
      <LabelText>{label}:</LabelText>
      <ValueText
        data-e2e-id={dataE2EId}
        style={styles as React.CSSProperties | undefined}
      >
        {getDisplayValue(value)}
      </ValueText>
    </ValueContainer>
  );
};
