import { PNL_ADJUSTMENT_INSTRUCTIONS } from "@enfusion-ui/core";
import { InfoPopover } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const TitleContainer = styled.div`
  display: flex;
  gap: var(--spacing);
  align-items: center;
`;

export const PNLAdjustmentPanelTitle: React.FC = () => {
  return (
    <TitleContainer>
      P&L Adjustment
      <InfoPopover
        title={PNL_ADJUSTMENT_INSTRUCTIONS.header}
        contentProps={{ as: "ul" }}
        testId="pnl-adjustments-popover"
      >
        {PNL_ADJUSTMENT_INSTRUCTIONS.texts.map((text, idx) => (
          <li key={idx}>{text}</li>
        ))}
      </InfoPopover>
    </TitleContainer>
  );
};
