import { ControlledFinancialSubTypeSelect } from "@app-views/reports/components/ReportQueryComponents/FinancialSubTypeSelect";
import { ControlledMaxResultsInput } from "@app-views/reports/components/ReportQueryComponents/MaxResultsInput";
import {
  ControlledCheckbox,
  ControlledTextInput,
  PanelRow,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";

const InputContainer = styled.div`
  min-width: 250px;
`;

const SecurityMasterQuickFilter = () => {
  return (
    <PanelRow>
      <InputContainer>
        <ControlledTextInput name="freeForm" label="Search terms" />
      </InputContainer>
      <InputContainer>
        <ControlledFinancialSubTypeSelect
          name="financialSubTypes"
          selectProps={{ label: "Instrument type" }}
        />
      </InputContainer>
      <ControlledMaxResultsInput />
      <ControlledCheckbox
        name="primaryOnly"
        label="Primary Only"
        labelPlacement="right"
        nullValue={false}
        style={{ alignSelf: "flex-end" }}
      />
      <ControlledCheckbox
        name="ignoreInactive"
        label="Ignore Inactive"
        labelPlacement="right"
        nullValue={false}
        style={{ alignSelf: "flex-end" }}
      />
    </PanelRow>
  );
};

export default SecurityMasterQuickFilter;
