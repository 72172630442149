import { ReportSettingsDefinition } from "../../types";
import GLDistributionLineQuickFilter from "./GLDistributionLineQuickFilter";
import GLDistributionLineSettings from "./GLDistributionLineSettings";

export const WebGLDistributionLineQuery: ReportSettingsDefinition = {
  name: "GLDistributionLine",
  queryType: "WebGLDistributionLineQuery",
  quickFilterComponent: GLDistributionLineQuickFilter,
  settingsComponent: GLDistributionLineSettings,
};
