import { CustomCellRendererProps } from "ag-grid-react";
import * as React from "react";

import { ChangeView } from "../ChangeView";

export const PNLChangeCellRenderer: React.FC<CustomCellRendererProps> = ({
  value,
  data,
  colDef,
}) => {
  if (!colDef) return <></>;
  return (
    <ChangeView
      hideChange
      value={value}
      valueTruncate
      iconSize="1x"
      currency={(colDef.field && data[colDef.field]?.currency) ?? "USD"}
    />
  );
};
