import {
  GenericInputSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import {
  Button,
  InputLabel,
  NumericInput,
  TextInput,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useMount } from "react-use";

const BtnWrapper = styled.div<{ isCompact: boolean }>`
  width: ${({ isCompact }) => (isCompact ? "38px" : "100%")};
  margin: ${({ isCompact }) =>
    isCompact ? "auto 0px 0px" : "var(--spacing-xl) 0"};
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InputPanel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div<{ isCompact: boolean }>`
  width: ${({ isCompact }) => (isCompact ? "45%" : "49%")};
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const InputLabelContainer = styled.div`
  display: flex;
`;

export type GenericInputType = {
  label: string;
  value: string | number;
};

export type ValuesInputProps = {
  label?: string;
  name: string;
  onChange: (values: Array<GenericInputType>) => void;
};

const GenericInput: React.FC<ValuesInputProps> = ({
  label,
  name,
  onChange,
}) => {
  const { editedConfig } = useWidget();

  const { type = "string", genericInputOptions } =
    editedConfig as GenericInputSelectionWidgetConfig;

  const mountedRef = React.useRef(false);

  const [valueState, setValueState] = React.useState<
    Array<GenericInputType | null>
  >(() => genericInputOptions ?? [null]);

  React.useEffect(() => {
    if (
      mountedRef.current &&
      valueState.length >= 1 &&
      valueState[0] !== null
    ) {
      setValueState([null]);
    }
  }, [type]);

  React.useEffect(() => {
    onChange([
      ...(valueState?.filter(
        (i) =>
          i !== null &&
          ((typeof i.value === "string" && i.value?.length !== 0) ||
            (typeof i.value === "number" && i.value !== 0))
      ) as unknown as Array<GenericInputType>),
    ]);
  }, [valueState]);

  useMount(() => {
    mountedRef.current = true;
  });

  const removeEntry = React.useCallback((idx: number) => {
    return () => {
      setValueState((state) => {
        const newState = [...state];
        newState[idx] = null;
        return newState;
      });
    };
  }, []);

  const addEntry = React.useCallback(() => {
    setValueState((state) => [...state, { label: "", value: "" }]);
  }, []);

  const changeEntryLabel = React.useCallback((idx: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setValueState((state) => {
        const newState = [...state];
        const row = newState[idx];
        if (row !== null) {
          newState[idx] = { ...row, label: e.target.value.toString() };
        }
        return newState;
      });
    };
  }, []);

  const changeEntryValue = React.useCallback((idx: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setValueState((state) => {
        const newState = [...state];
        const row = newState[idx];
        if (row !== null) {
          newState[idx] = { ...row, value: e.target.value.toString() };
        }
        return newState;
      });
    };
  }, []);

  const changeEntryNumber = React.useCallback((idx: number) => {
    return (value: number | null) => {
      setValueState((state) => {
        const newState = [...state];
        const row = newState[idx];
        if (row !== null) {
          newState[idx] = { ...row, value: value || 0 };
        }
        return newState;
      });
    };
  }, []);

  return (
    <>
      <InputLabelContainer>
        <InputLabel>{label}</InputLabel>
      </InputLabelContainer>

      {valueState.map((entry, idx) => {
        if (entry === null)
          return <div key={`${idx}`} style={{ display: "none" }}></div>;
        const { label, value } = entry;
        return (
          <Container key={`${idx}`}>
            <InputPanel>
              <InputWrapper isCompact>
                <TextInput
                  label="Label"
                  value={label}
                  name={name}
                  onChange={changeEntryLabel(idx)}
                />
              </InputWrapper>
              <InputWrapper isCompact>
                {type === "number" ? (
                  <NumericInput
                    label="Value"
                    value={Number(value) ? (value as number) : 0}
                    onChange={changeEntryNumber(idx)}
                  />
                ) : (
                  <TextInput
                    label="Value"
                    value={value}
                    name={name}
                    onChange={changeEntryValue(idx)}
                  />
                )}
              </InputWrapper>
              <BtnWrapper isCompact>
                <Button
                  style={{ height: "38px", width: "36px" }}
                  name="remove"
                  onClick={removeEntry(idx)}
                  data-testid="remove"
                >
                  <FontAwesomeIcon
                    data-testid="icon"
                    icon={faTrash}
                    size="xs"
                  />
                </Button>
              </BtnWrapper>
            </InputPanel>
          </Container>
        );
      })}
      <BtnWrapper isCompact={false}>
        <Button onClick={addEntry}>Add</Button>
      </BtnWrapper>
    </>
  );
};

export default GenericInput;
