import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import { useRefCallback } from "@enfusion-ui/hooks";
import { DashboardRoot } from "@enfusion-ui/types";
import * as React from "react";

import { ExplorerSection } from "../types";
import { EXPLORER_EVENTS_CHANNEL_NAME, ExplorerEventsContext } from "./context";
import {
  ExplorerEventsAction,
  ExplorerEventsSubscriptionCallback,
} from "./types";

export type ExplorerEventsProviderProps = {};

const sep = "-/-";

export const ExplorerEventsProvider: React.FC<
  React.PropsWithChildren<ExplorerEventsProviderProps>
> = ({ children }) => {
  const subscriptionsRef = React.useRef<
    Map<ExplorerSection, Set<ExplorerEventsSubscriptionCallback>>
  >(new Map());

  const channel = useBroadcastChannel<string>(
    EXPLORER_EVENTS_CHANNEL_NAME,
    (msg) => {
      const [section, root, action, meta] = msg.split(sep) as [
        ExplorerSection,
        DashboardRoot,
        ExplorerEventsAction,
        string | undefined
      ];

      const cbs = subscriptionsRef.current.get(section) || [];
      for (const cb of cbs) {
        try {
          cb(root, action, meta);
        } catch (err) {
          console.warn("explorer events cb err", err);
        }
      }
    }
  );

  const subscribe = useRefCallback(
    (section: ExplorerSection, cb: ExplorerEventsSubscriptionCallback) => {
      const cbs =
        subscriptionsRef.current.get(section) ||
        new Set<ExplorerEventsSubscriptionCallback>();

      cbs.add(cb);
      subscriptionsRef.current.set(section, cbs);

      return () => {
        const cbs =
          subscriptionsRef.current.get(section) ||
          new Set<ExplorerEventsSubscriptionCallback>();

        cbs.delete(cb);
        subscriptionsRef.current.set(section, cbs);
      };
    },
    []
  );

  const broadcast = useRefCallback(
    (
      section: ExplorerSection,
      root: DashboardRoot,
      event: ExplorerEventsAction,
      meta?: string
    ) => {
      channel.broadcastMessage([section, root, event, meta].join(sep));
    },
    [channel]
  );

  return (
    <ExplorerEventsContext.Provider
      value={{
        subscribe,
        broadcast,
      }}
    >
      {children}
    </ExplorerEventsContext.Provider>
  );
};
