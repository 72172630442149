import { PanelRow } from "@enfusion-ui/web-components";

import { ControlledCorporateActionsSelect } from "../../../components/ReportQueryComponents/CorporateActionsSelect";
import { DateRangeSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";

const CorporateActionQuickFilter = () => {
  return (
    <>
      <DateRangeSelection
        name="effectiveDateRange"
        startDateLabel="Effective Date"
        endDateLabel="To"
      />
      <PanelRow>
        <ControlledCorporateActionsSelect />
      </PanelRow>
    </>
  );
};

export default CorporateActionQuickFilter;
