import { basicInfoMap, useRestAbortableOptions } from "@enfusion-ui/hooks";
import { ControlledSelect, PanelRow } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

const OTCOrdersAllocationPanel: React.FC = () => {
  const { options, loading } = useRestAbortableOptions(
    REST_API.FUND.GET_STRATEGY_BOOKS.FETCH,
    basicInfoMap
  );

  return (
    <PanelRow style={{ marginBottom: "var(--spacing-l)" }}>
      <ControlledSelect
        name="strategyBookId"
        label="Strategy"
        options={options}
        isLoading={loading}
        isClearable
        minWidth={200}
        inputId="otc-orders-alloc-strategy-selection-id"
      />
    </PanelRow>
  );
};

export default OTCOrdersAllocationPanel;
