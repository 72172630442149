import { ReportSettingsDefinition } from "../../types";
import CounterpartyCommissionQuickFilter from "./CounterpartyCommissionQuickFilter";
import CounterpartyCommissionSettings from "./CounterpartyCommissionSettings";

export const WebCounterpartyCommissionQuery: ReportSettingsDefinition = {
  name: "CounterpartyCommission",
  queryType: "WebCounterpartyCommissionQuery",
  quickFilterComponent: CounterpartyCommissionQuickFilter,
  settingsComponent: CounterpartyCommissionSettings,
};
