import { NumericInputWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import {
  Checkbox,
  FormSectionAccordion,
  NumericInput,
  TextInput,
} from "@enfusion-ui/web-components";
import { isEqual } from "lodash";
import * as React from "react";

import { InputWrapper, SingleRowContainer } from "../../styles";

const getDefaultValue = (
  defaultValue: number | null,
  minValue: string | null
) =>
  !minValue || (defaultValue && defaultValue > parseFloat(minValue))
    ? defaultValue
    : parseFloat(minValue);

const NumericInputEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const {
    defaultValue = null,
    key = "Numeric Input",
    clearable = false,
    stepSize = "",
    minValue = "",
  } = editedConfig as NumericInputWidgetConfig;

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange = (key: string) => (val: number | boolean | null) => {
    if (typeof val === "number" && !isNaN(val)) {
      if (key === "minValue") {
        const strVal = val.toString();
        if (!isEqual(editedConfig["minValue"], strVal)) {
          changeConfigKeyToBeApplied("minValue", strVal);
          changeConfigKeyToBeApplied(
            "defaultValue",
            getDefaultValue(editedConfig["defaultValue"], strVal)
          );
        }
      } else if (key === "defaultValue") {
        if (!isEqual(editedConfig["defaultValue"], val)) {
          changeConfigKeyToBeApplied(
            "defaultValue",
            getDefaultValue(val, editedConfig["minValue"])
          );
        }
      } else {
        changeConfigKeyToBeApplied(key, val.toString());
      }
    } else if (typeof val === "boolean") {
      changeConfigKeyToBeApplied(key, val);
    } else {
      changeConfigKeyToBeApplied(key, null);
    }
  };

  return (
    <>
      <FormSectionAccordion title="Key">
        <TextInput
          label="Key"
          value={key}
          name="key"
          onChange={handleTextChange("key")}
        />
      </FormSectionAccordion>

      <FormSectionAccordion title="Numeric Input Options">
        <SingleRowContainer>
          <NumericInput
            value={stepSize ? parseFloat(stepSize) : null}
            onChange={handleBasicChange("stepSize")}
            label="Increment By"
            clearable
            onClearValue={() => changeConfigKeyToBeApplied("stepSize", "")}
            min={0}
            hideControls
          />
          <NumericInput
            value={minValue ? parseFloat(minValue) : null}
            onChange={handleBasicChange("minValue")}
            label="Minimum Value"
            clearable
            onClearValue={() => changeConfigKeyToBeApplied("minValue", "")}
            hideControls
          />
        </SingleRowContainer>

        <SingleRowContainer noMargin>
          <NumericInput
            value={defaultValue}
            label="Default Value"
            onChange={handleBasicChange("defaultValue")}
            clearable={clearable}
            step={stepSize ? parseFloat(stepSize || "") : undefined}
            hideControls={!stepSize}
            min={minValue ? parseFloat(minValue) : undefined}
            enableMultiplier
          />
          <InputWrapper margin="var(--spacing-xl) 0px 0px">
            <Checkbox
              checked={clearable}
              onChange={handleBasicChange("clearable")}
              label="Clearable"
              style={{ marginTop: "var(--spacing-xl)" }}
            />
          </InputWrapper>
        </SingleRowContainer>
      </FormSectionAccordion>
    </>
  );
};

export default NumericInputEditWidget;
