import { styled } from "@enfusion-ui/web-core";

export const WidgetContentContainer = styled.div`
  border-radius: var(--radius);
  overflow: hidden;
`;

export const WidgetContentContainerFill = styled(WidgetContentContainer)`
  width: 100%;
  height: 100%;
`;
