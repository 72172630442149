import { WidgetComponentsDefinition } from "../../types";
import PdfReportWidget from "./PdfReportWidget/PdfReportWidget";
import ReportEditWidget from "./ReportEditWidget";
import ReportWidget from "./ReportWidget";

export const report: WidgetComponentsDefinition = {
  pdfContentAsync: true,
  renderPdfComponent: PdfReportWidget,
  renderComponent: ReportWidget,
  editComponent: ReportEditWidget,
};
