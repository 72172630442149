import { DataGridProps, RoundedDataGrid } from "@app-components/DataGrid";
import { formatCellValues, orderCellFormatter } from "@enfusion-ui/web-core";
import { GetRowIdParams } from "ag-grid-enterprise";
import * as React from "react";

import { defaultOemsColDef, oemsColumnTypes } from "../utils/createColumnDefs";
import PercentageCellRenderer from "./blotters/cellRenderer/PercentageCellRenderer";
import TransmitCancelCellRenderer from "./blotters/cellRenderer/TransmitCancelCellRenderer";

export type OrdersListProps = {
  height?: number | string;
  gridName?: string;
} & DataGridProps;

const OrdersList: React.FC<OrdersListProps> = ({
  columnDefs,
  onRowClicked,
  height,
  gridName,
  ...rest
}) => {
  const dataTypeDefinitions: any = React.useMemo(() => {
    return {
      numericColumn: {
        extendsDataType: "number",
        baseDataType: "number",
        valueFormatter: orderCellFormatter,
      },
      standardColumn: {
        extendsDataType: "text",
        baseDataType: "text",
        valueFormatter: orderCellFormatter,
      },
      dateColumn: {
        extendsDataType: "dateString",
        baseDataType: "dateString",
        valueFormatter: orderCellFormatter,
        valueParser: (param: any) => {
          return param.newValue;
        },
        dateParser: (value: string | undefined) => {
          if (value === null || value === undefined || value === "") {
            return new Date("");
          }
          return new Date(formatCellValues("DateTime", value));
        },
      },
    };
  }, []);

  return (
    <RoundedDataGrid
      height={height}
      suppressCellFocus
      suppressDragLeaveHidesColumns
      suppressRowGroupHidesColumns
      rowSelection="single"
      multiSortKey="ctrl"
      rowGroupPanelShow="always"
      groupDefaultExpanded={-1}
      onRowClicked={onRowClicked}
      columnDefs={columnDefs}
      getRowId={(params: GetRowIdParams) => params.data?.orderId?.toString()}
      defaultColDef={defaultOemsColDef}
      columnTypes={oemsColumnTypes}
      components={{
        transmitCancelCellRenderer: TransmitCancelCellRenderer,
        percentageCellRenderer: PercentageCellRenderer,
      }}
      gridName={gridName}
      dataTypeDefinitions={dataTypeDefinitions}
      {...rest}
    />
  );
};

export default OrdersList;
