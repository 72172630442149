/* eslint-disable @typescript-eslint/no-unused-vars */
import { pxToPtConversion } from "@app-views/dashboards/pdf/utils";
import { ThemeDefinition } from "@enfusion-ui/core";
import { SwitchWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { withTheme } from "@enfusion-ui/web-core";
import { faCheckSquare, faSquare } from "@fortawesome/pro-solid-svg-icons";
import { Path, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import * as React from "react";

const pdfStyles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingVertical: pxToPtConversion(8),
  },
  inputContainer: {
    width: "100%",
    height: "100%",
    paddingTop: pxToPtConversion(12),
  },
  input: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textInputContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: pxToPtConversion(4),
    padding: pxToPtConversion(4),
  },
  textInput: {
    width: "100%",
    height: "100%",
    // justifyContent and alignItems are reversed
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: pxToPtConversion(4),
  },
  text: {
    fontSize: pxToPtConversion(12),
    fontFamily: "Lato",
    width: "100%",
    maxLines: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  checkBtn: {
    width: pxToPtConversion(27),
    height: pxToPtConversion(23),
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: pxToPtConversion(4),
  },
});

const PdfSwitchWidget = withTheme(({ theme }: { theme: ThemeDefinition }) => {
  const { config, addChannelKeys, setChannelData } = useWidget();
  const { defaultValue, key } = config as SwitchWidgetConfig;

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Checkbox value",
        type: ChannelDataType.Boolean,
      },
    ]);
    setChannelData([defaultValue]);
  }, [setChannelData, defaultValue, key]);

  const icon = defaultValue ? faCheckSquare : faSquare;

  return (
    <View style={[pdfStyles.container]}>
      <View style={[pdfStyles.inputContainer]}>
        <View style={[pdfStyles.input]}>
          <View
            style={[
              pdfStyles.textInputContainer,
              {
                backgroundColor: theme.colors.inputBackground,
                border: `1px solid ${theme.colors.inputBorder}`,
              },
            ]}
          >
            <View style={[pdfStyles.textInput]}>
              <Text
                style={[
                  pdfStyles.text,
                  {
                    color: theme.colors.textNormal,
                  },
                ]}
              >
                {key}
              </Text>
            </View>
            <View style={[pdfStyles.checkBtn]}>
              <Svg
                width={pxToPtConversion(12)}
                height={pxToPtConversion(12)}
                viewBox={`0 0 ${icon.icon[0]} ${icon.icon[1]}`}
              >
                <Path
                  d={icon.icon[4] as string}
                  stroke={theme.colors.backgroundColor2}
                  fill={theme.colors.textNormal}
                />
              </Svg>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
});

export default PdfSwitchWidget;
