import { useRefCallback } from "@enfusion-ui/hooks";
import {
  InstrumentInfo,
  NodeData,
  PrimeBrokerEnquire,
} from "@enfusion-ui/types";
import {
  FormPanel,
  NumericInput,
  TextInput,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { ActionButton, ActionButtonContainer } from "./LocatesModal";
import { PrimeBrokerSelect } from "./PrimeBrokerSelect";

export type LocateSearchPanelProps = {
  quantity: number;
  instrumentInfo: InstrumentInfo | null;
  onCancel: () => void;
  onSubmit: (qty: number, brokers: PrimeBrokerEnquire[]) => void;
};

export const PosnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: var(--spacing-l);
`;
export const PosnLabel = styled.div`
  font-size: 12px;
`;

export const PosnValue = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  font-size: 12px;

  > div {
    padding-left: var(--spacing-l);
    padding-right: var(--spacing);
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const ShowInstrumentInfoContainer = styled.div`
  display: flex;
`;

const LocateEnquiryPanel: React.FC<LocateSearchPanelProps> = ({
  quantity,
  instrumentInfo,
  onCancel,
  onSubmit,
}: LocateSearchPanelProps) => {
  const [enquiredQuantity, setEnquireQuantity] = React.useState(quantity);

  const selectedPrimeBrokers = React.useRef<NodeData[] | null>(null);

  const handleSubmit = useRefCallback(() => {
    onSubmit(
      enquiredQuantity || 0,
      selectedPrimeBrokers === null
        ? ([] as PrimeBrokerEnquire[])
        : selectedPrimeBrokers.current?.reduce<PrimeBrokerEnquire[]>(
            (res, entry) => {
              if (!entry.file) return res;
              return [
                ...res,
                {
                  code: entry.code,
                  groupId: entry.groupId,
                } as PrimeBrokerEnquire,
              ];
            },
            [] as PrimeBrokerEnquire[]
          ) ?? []
    );
  }, [onSubmit, enquiredQuantity, selectedPrimeBrokers]);

  return (
    <FormPanel numColumns={1} popStyle={false}>
      <TextInput
        label="Instrument"
        value={instrumentInfo?.description ?? ""}
        readOnly
      />
      <NumericInput
        label="Quantity"
        value={enquiredQuantity}
        enableMultiplier
        onChange={(qty: number | null) => setEnquireQuantity(qty || 0)}
      />
      <PrimeBrokerSelect
        onChange={(nodes: NodeData[] | null) =>
          (selectedPrimeBrokers.current = nodes)
        }
      />
      <ActionButtonContainer>
        <ActionButton theme="primary" onClick={handleSubmit}>
          Save and Close
        </ActionButton>
        <ActionButton onClick={onCancel}>Cancel</ActionButton>
      </ActionButtonContainer>
    </FormPanel>
  );
};

export default LocateEnquiryPanel;
