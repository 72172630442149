import { useTheme } from "@enfusion-ui/web-core";
import { ICellRendererParams } from "ag-grid-community";
import { startCase, words } from "lodash";
import * as React from "react";

const SideCellRenderer = ({ value }: ICellRendererParams) => {
  const [styles, setStyles] = React.useState<React.CSSProperties | undefined>();

  const { theme } = useTheme();

  React.useEffect(() => {
    if (typeof value === "string") {
      const txt = words(value).map((i) => i.toLocaleLowerCase());
      const textColor =
        theme.colors[txt.includes("buy") ? "success" : "danger"];
      setStyles({ color: textColor });
    }
  }, [value]);

  const content = React.useMemo(() => {
    if (typeof value === "string") return startCase(value);
    return "";
  }, [value]);

  return <div style={styles}>{content}</div>;
};

export default SideCellRenderer;
