/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { AppEvent, AppEventCategories, } from "@enfusion-ui/types";
import * as FullStory from "@fullstory/browser";
import { FULLSTORY_ENABLED } from "./constants";
import { gaEvent } from "./ga";
const LocalStatus = {
    LocalOnly: 0,
    LocalAndRemote: 1,
    RemoteOnly: 2,
    DebugOnly: 3,
};
const debugMode = (typeof window !== "undefined" ? window : {}).allowDebugLogging ??
    false;
const logDefault = (local = LocalStatus.LocalAndRemote, message) => {
    if (FULLSTORY_ENABLED && local === LocalStatus.RemoteOnly) {
        FullStory.log("log", message);
    }
    if (local === LocalStatus.LocalOnly || local === LocalStatus.LocalAndRemote) {
        console.log("[AppLogging]", message);
    }
    if (!!debugMode && local === LocalStatus.DebugOnly) {
        console.log("[AppLogging|Debug]", message);
    }
};
const logInfo = (local = LocalStatus.LocalAndRemote, message) => {
    if (FULLSTORY_ENABLED && local === LocalStatus.RemoteOnly) {
        FullStory.log("info", message);
    }
    if (local === LocalStatus.LocalOnly || local === LocalStatus.LocalAndRemote) {
        console.info("[AppLogging]", message);
    }
    if (!!debugMode && local === LocalStatus.DebugOnly) {
        console.info("[AppLogging|Debug]", message);
    }
};
const logWarn = (local = LocalStatus.LocalAndRemote, message) => {
    if (FULLSTORY_ENABLED && local === LocalStatus.RemoteOnly) {
        FullStory.log("warn", message);
    }
    if (local === LocalStatus.LocalOnly || local === LocalStatus.LocalAndRemote) {
        console.warn("[AppLogging]", message);
    }
    if (!!debugMode && local === LocalStatus.DebugOnly) {
        console.warn("[AppLogging|Debug]", message);
    }
};
const logError = (local = LocalStatus.LocalAndRemote, safe = false, message, error, event) => {
    if (FULLSTORY_ENABLED && local === LocalStatus.RemoteOnly) {
        FullStory.log("error", message);
    }
    if (event)
        logEvent(local, {
            event: AppEvent.CaughtError,
            category: AppEventCategories.Navigation,
        }, { message, error: error?.message });
    if (local === LocalStatus.LocalOnly || local === LocalStatus.LocalAndRemote) {
        if (safe) {
            console.warn("[AppLogging]", message, error);
        }
        else {
            console.error("[AppLogging]", message, error);
        }
    }
    if (!!debugMode && local === LocalStatus.DebugOnly) {
        if (safe) {
            console.warn("[AppLogging|Debug]", message, error);
        }
        else {
            console.error("[AppLogging|Debug]", message, error);
        }
    }
};
const logEvent = (local = LocalStatus.LocalAndRemote, eventParams, extraParams = {}) => {
    const { event, category, gaLabel, gaValue } = eventParams;
    if (FULLSTORY_ENABLED &&
        (local === LocalStatus.RemoteOnly || local === LocalStatus.LocalAndRemote)) {
        FullStory.event(event, {
            ...extraParams,
            category,
        });
    }
    gaEvent({
        category: category || "App",
        action: event || "Event",
        label: gaLabel || event || "Event",
        value: gaValue,
    });
    if (local === LocalStatus.LocalOnly || local === LocalStatus.LocalAndRemote) {
        console.log(`[EVENT]: ${event}`);
    }
    if (!!debugMode && local === LocalStatus.DebugOnly) {
        console.warn("[EVENT|Debug]", event);
    }
};
const logScreenView = (local = LocalStatus.LocalAndRemote, screenName) => {
    if (FULLSTORY_ENABLED &&
        (local === LocalStatus.RemoteOnly || local === LocalStatus.LocalAndRemote)) {
        FullStory.event(AppEvent.ScreenView, { screenName });
    }
    if (local === LocalStatus.LocalOnly || local === LocalStatus.LocalAndRemote) {
        console.log(`[SCREEN VIEW]: ${screenName}`);
    }
    if (!!debugMode && local === LocalStatus.DebugOnly) {
        console.warn(`[SCREEN VIEW|Debug]: ${screenName}`);
    }
};
const createAppLogging = (local = LocalStatus.LocalAndRemote) => new Proxy({}, {
    get: (target, key) => {
        if (key === "localOnly") {
            return createAppLogging(LocalStatus.LocalOnly);
        }
        if (key === "remoteOnly") {
            return createAppLogging(LocalStatus.RemoteOnly);
        }
        if (key === "debug") {
            return createAppLogging(LocalStatus.DebugOnly);
        }
        if (key === "safeError") {
            return (message, error, event) => {
                logError(local, true, message, error, event);
            };
        }
        if (key === "error") {
            return (message, error, event) => {
                logError(local, false, message, error, event);
            };
        }
        if (key === "event") {
            return (eventParams, extraParams = {}) => {
                logEvent(local, eventParams, extraParams);
            };
        }
        return (message) => {
            if (key === "log") {
                logDefault(local, message);
            }
            else if (key === "info") {
                logInfo(local, message);
            }
            else if (key === "warn") {
                logWarn(local, message);
            }
            else if (key === "screenView") {
                logScreenView(local, message);
            }
        };
    },
});
export const AppLogging = createAppLogging();
