import {
  OrderFormValues,
  useInstrument,
  useOEMSOrderForm,
} from "@enfusion-ui/core";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { ExecutionScheme } from "@enfusion-ui/types";
import { REST_API, useAuth } from "@enfusion-ui/web-core";
import { debounce, size, sortBy, uniq } from "lodash";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import SchemeHotButtonsPanel from "../../../../components/control/SchemeHotButtonsPanel";

type SchemesState = {
  schemes: ExecutionScheme[];
  loading: boolean;
};

export type PreferredExecutionSchemeSelectProps = {
  instrumentId: number | null;
  disabled?: boolean;
  swap: boolean;
  onChange?: (executionSchemeId: number | null) => void;
  value: number | null;
};

const EXECUTION_SCHEME_MAP = [
  { k: "orderType", d: "Market" },
  { k: "strategy" },
  { k: "targetCompId" },
  { k: "capacity", sk: "orderCapacity", d: "" },
  { k: "timeInForce", d: "Day" },
  { k: "counterpartyId" },
  { k: "brokerAccount", sk: "brokerAccountNumber" },
  { k: "executionInstructions", d: [] },
  { k: "counterpartyMemo", d: "" },
  { k: "destination" },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasValue(entry: any) {
  if (typeof entry === "number") return true;
  return size(entry) > 0;
}

function getExecutionSchemeValues(
  executionScheme: ExecutionScheme | null,
  initialValues: Partial<OrderFormValues>
) {
  return EXECUTION_SCHEME_MAP.map((i) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const exeValue = (executionScheme as any)?.[i.sk || i.k];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const initValue = (initialValues as any)?.[i.k];

    return {
      name: i.k,
      value: hasValue(exeValue)
        ? exeValue
        : hasValue(initValue)
        ? initValue
        : i.d || null,
    };
  });
}

const PreferredExecutionSchemeSelect: React.FC<
  PreferredExecutionSchemeSelectProps
> = ({ instrumentId, disabled, swap, onChange, value }) => {
  const isMounted = useMounted();
  const { isUserType } = useAuth();
  const { setValue } = useFormContext<OrderFormValues>();
  const instrument = useInstrument();
  const {
    isFxOrder,
    newOrder,
    isCdxOrder,
    isVarSwapOrder,
    initialOrder,
    isIrsOrder,
  } = useOEMSOrderForm();
  const [{ schemes, loading }, setSchemesState] = React.useState<SchemesState>({
    schemes: [],
    loading: false,
  });

  const [
    { schemes: allSchemes, loading: loadingAllSchemes },
    setAllSchemesState,
  ] = React.useState<SchemesState>({
    schemes: [],
    loading: false,
  });

  const { assignedTradingDesk, fxType } = useWatch({
    name: uniq(["assignedTradingDesk", "fxType"]),
  });

  const handleSchemeChange = useRefCallback(
    (scheme: ExecutionScheme | null) => {
      getExecutionSchemeValues(scheme, initialOrder).forEach(
        ({ name, value }) => {
          setValue(name, value, {
            shouldDirty: true,
          });
        }
      );

      onChange?.(scheme?.id ?? null);
    },
    [onChange, initialOrder]
  );

  const financialSubType = React.useMemo(() => {
    if (isFxOrder) {
      return fxType === "Swap" ? "FXSwap" : "FXForward";
    } else if (isCdxOrder) {
      return "CreditDefaultSwap";
    } else if (isVarSwapOrder) {
      return "VarianceSwap";
    } else if (isIrsOrder) {
      return "InterestRateSwap";
    }
    return instrument?.financialSubType?.parent;
  }, [fxType, isFxOrder, instrument, isCdxOrder, isVarSwapOrder, isIrsOrder]);

  const fetchExecutionSchemes = useRefCallback(
    async (isPreferred = false) => {
      if (!instrumentId) return null;
      try {
        if (isPreferred)
          return await REST_API.OEMS.GET_PREFERRED_EXECUTION_SCHEMES.FETCH(
            instrumentId,
            financialSubType,
            swap,
            assignedTradingDesk
          );

        return await REST_API.OEMS.GET_ALL_EXECUTION_SCHEMES.FETCH(
          instrumentId,
          financialSubType,
          swap,
          assignedTradingDesk
        );
      } catch (err) {
        console.error("failed to get execution schemes", err);
      }

      return null;
    },
    [instrumentId, swap, assignedTradingDesk, fxType]
  );

  const loadExecutionSchemes = useRefCallback(
    debounce(async () => {
      const data = (await fetchExecutionSchemes(true)) || [];
      const allData = isUserType("Express")
        ? []
        : (await fetchExecutionSchemes()) || [];
      let otherData = allData;
      const preferredSchemes = data.length > 0 ? data : allData;

      if (!data) {
        otherData = [];
      } else if (allData?.length > 0) {
        const dataIds = data.map(({ id }) => id);
        otherData = allData.filter((i) => !dataIds.includes(i.id));
      }

      if (isMounted()) {
        setSchemesState({ schemes: preferredSchemes, loading: false });
        setAllSchemesState({
          schemes: sortBy(otherData, ["name"]),
          loading: false,
        });
      }
      if (data?.length === 1 && newOrder) handleSchemeChange(data[0]);
      else if (data?.length === 0 && otherData?.length === 1 && newOrder)
        handleSchemeChange(otherData[0]);
    }, 500),
    [isUserType, fetchExecutionSchemes]
  );

  React.useEffect(() => {
    if (instrumentId) {
      setSchemesState({ schemes: [], loading: true });
      setAllSchemesState({
        schemes: [],
        loading: true,
      });
      loadExecutionSchemes();
    } else {
      handleSchemeChange(null);
    }
  }, [instrumentId, swap, assignedTradingDesk, fxType]);

  const getSelectedScheme = () => {
    let selectedScheme = schemes.find((scheme) => scheme.id === value);
    if (!selectedScheme)
      selectedScheme = allSchemes.find((scheme) => scheme.id === value);
    return selectedScheme || null;
  };

  return (
    <SchemeHotButtonsPanel
      schemes={schemes}
      otherSchemes={allSchemes}
      selectedScheme={getSelectedScheme()}
      onSchemeChange={handleSchemeChange}
      noSchemesMessage="No preferred execution schemes available"
      loading={loading || loadingAllSchemes}
      disabled={disabled}
    />
  );
};

export default PreferredExecutionSchemeSelect;
