import {
  LedgerSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import LedgerSelect from "../../../../../reports/components/ReportQueryComponents/LedgerSelect";

const LedgerSelectionWidget = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, key } = config as LedgerSelectionWidgetConfig;
  const [value, setValue] = React.useState<string[] | null>(defaultValue);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Ledger",
        type: ChannelDataType.Number,
      },
    ]);
  }, [addChannelKeys, key]);

  React.useEffect(() => {
    setChannelData([value]);
  }, [setChannelData, value]);

  const handleChange = (newValue: string[] | null) => {
    setValue(newValue);
  };

  return (
    <CenterContent>
      <LedgerSelect label={key} value={value} onChange={handleChange} />
    </CenterContent>
  );
};

export default LedgerSelectionWidget;
