import MarketDataDisplay from "@app-views/oems/components/MarketDataDisplay";
import { useWidget } from "@enfusion-ui/dashboards";

const MarketInfoMobileWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const { fields, viewMode } = config;

  return <MarketDataDisplay orientation={viewMode} fields={fields} />;
};

export default MarketInfoMobileWidget;
