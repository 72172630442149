import {
  NavSourceSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { SelectOptionsType } from "@enfusion-ui/types";
import { FormSectionAccordion, TextInput } from "@enfusion-ui/web-components";
import * as React from "react";

import { BenchmarkNavSourceSelect } from "./NavSourceSelect";

const NavSourceSelectionEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const { defaultValue = null, key = "Benchmark NAV Source" } =
    editedConfig as NavSourceSelectionWidgetConfig;

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange =
    (key: string) => (option: SelectOptionsType<string> | null) => {
      changeConfigKeyToBeApplied(key, option?.value);
    };

  return (
    <FormSectionAccordion title="NAV Source Options">
      <TextInput
        value={key}
        label="Key"
        name="key"
        onChange={handleTextChange("key")}
      />
      <BenchmarkNavSourceSelect
        value={defaultValue}
        label="Default Benchmark NAV Source"
        onChange={handleBasicChange("defaultValue")}
      />
    </FormSectionAccordion>
  );
};

export default NavSourceSelectionEditWidget;
