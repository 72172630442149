import ControlledAccountSelect from "@app-components/inputs/account/ControlledAccountSelect";
import {
  DATE_ADJUSTMENT_OPTIONS,
  IN_GRID_SHOCK_EFFECT_OPTIONS,
  POSITION_REQUEST_TYPE_OPTIONS,
} from "@enfusion-ui/types";
import {
  ControlledCheckbox,
  ControlledNumericInput,
  ControlledSelect,
  ControlledTextInput,
  PanelRow,
  Select,
  TextInput,
} from "@enfusion-ui/web-components";
import { styled, useAuth } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

import ControlledBookSelect from "../../../../reports/components/ReportQueryComponents/BookSelect";
import { DatePeriodSelection } from "../../../../reports/components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledFinancialSubTypeSelect } from "../../../../reports/components/ReportQueryComponents/FinancialSubTypeSelect";
import { InstrumentsMultiSelect } from "../../../../reports/components/ReportQueryComponents/InstrumentsMultiselect";
import { ControlledMarketEnvironmentSelect } from "../../../../reports/components/ReportQueryComponents/MarketEnvironmentSelect";
import { ControlledPortfolioGroupsSelect } from "../../../../reports/components/ReportQueryComponents/PortfolioGroupsSelect";
import MarginFundSelect from "../../../components/ReportQueryComponents/MarginFundSelect";
import { ControlledTradeBookingStatusSelect } from "../../../components/ReportQueryComponents/TradeBookingStatusSelect";
import { ControlledTradeTypeSelect } from "../../../components/ReportQueryComponents/TradeTypeSelect";

const StyledNumericInput = styled(ControlledNumericInput)`
  width: 50%;
  position: relative;
  bottom: var(--spacing-xl);
`;

const StyledText = styled.div`
  font-size: 0.75em;
  line-height: 1em;
  font-weight: 500;
  align-self: center;
`;

const StyledNumeric = styled.div`
  width: 15%;
`;

const FilterTypeOptions = [
  { label: "None", value: "None" },
  { label: "Inactive Days", value: "InactiveDays" },
];

const PositionSettings = () => {
  const { isAdmin } = useAuth();
  const [filterType, setFilterType] = React.useState<string | undefined>(
    "None"
  );
  const { bookSelection } = useWatch({
    name: ["bookSelection"],
  });
  const bookSelectionRef = React.useRef(bookSelection);

  return (
    <>
      <ControlledAccountSelect label="Account(s)" />
      <ControlledPortfolioGroupsSelect />
      <ControlledBookSelect
        defaultBookSelection={bookSelectionRef.current}
        showIgnoreHierarchyCheckBox
      />
      <PanelRow>
        <InstrumentsMultiSelect />
        <ControlledFinancialSubTypeSelect name="financialSubTypes" />
      </PanelRow>
      <PanelRow>
        <ControlledCheckbox
          name="includeUnderlyings"
          label="Include Underlyings"
          labelPlacement="right"
        />
      </PanelRow>
      <PanelRow>
        <DatePeriodSelection name="datePeriodSelection" label="Value Date" />
        <ControlledMarketEnvironmentSelect />
      </PanelRow>
      <PanelRow>
        <MarginFundSelect />
        <ControlledSelect
          name="positionRequestType"
          options={POSITION_REQUEST_TYPE_OPTIONS}
          label="Positions to include"
        />
      </PanelRow>
      <PanelRow>
        <ControlledSelect
          name="inGridShockEffect"
          options={IN_GRID_SHOCK_EFFECT_OPTIONS}
          label="In-Grid Shock Behavior"
        />
        <ControlledSelect
          name="dateAdjustment"
          options={DATE_ADJUSTMENT_OPTIONS}
          label="Business Date Adjustment"
        />
      </PanelRow>
      <PanelRow>
        {/* need api endpoint to get options */}
        {isAdmin() && (
          <ControlledSelect
            name="scenarioSetId"
            label="Scenario Sets"
            options={[]}
          />
        )}
        <ControlledTradeTypeSelect
          name="ignoredTradeTypes"
          label="Ignored Trade Types"
        />
      </PanelRow>
      <PanelRow>
        <ControlledTradeBookingStatusSelect />
        <ControlledCheckbox
          name="includeExpiredFunds"
          label="Include Inactive Funds/Accounts"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </PanelRow>
      <PanelRow>
        <TextInput label="Holding Id" />
        <ControlledTextInput label="Deal Id" name="dealId" />
        <ControlledTextInput label="Position Block" name="positionBlock" />
      </PanelRow>
      <Select
        label="Filter Type"
        options={FilterTypeOptions}
        onChange={(val) => setFilterType(val?.value)}
      />
      {filterType === "InactiveDays" && (
        <PanelRow>
          <StyledText>Ignore Positions Inactive for</StyledText>
          <StyledNumeric>
            <StyledNumericInput
              name="daysBeforeInactive"
              hideControls
              defaultValue={0}
            />
          </StyledNumeric>
          <StyledText>days since start of value date year.</StyledText>
        </PanelRow>
      )}
    </>
  );
};

export default PositionSettings;
