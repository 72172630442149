import { AccountingMethodOptions } from "@enfusion-ui/types";
import { ControlledSelect, PanelRow } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { DateRangeSymbolSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledLedgerSelect } from "../../../components/ReportQueryComponents/LedgerSelect";

const InputContainer = styled.div`
  min-width: 230px;
`;

const DateRangeContainer = styled.div`
  min-width: 350px;
`;

const GLTrialBalanceQuickFilter = () => {
  return (
    <PanelRow>
      <InputContainer>
        <ControlledLedgerSelect
          name="generalLedgerIds"
          label="General Ledger(s)"
        />
      </InputContainer>
      <DateRangeContainer>
        <DateRangeSymbolSelection name="period" label="Period" />
      </DateRangeContainer>
      <InputContainer>
        <ControlledSelect
          name="accountingMethod"
          label="Accounting Method"
          options={AccountingMethodOptions}
          clearable={false}
        />
      </InputContainer>
    </PanelRow>
  );
};

export default GLTrialBalanceQuickFilter;
