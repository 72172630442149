import { ControlledInstrumentSelect } from "@app-components/inputs/InstrumentSelect/ControlledInstrumentSelect";
import CurrencySelect from "@app-views/reports/reportSettings/generalLedgerAccounting/glDistributionLine/CurrencySelect";
import { TradeTicketFormTypes, useTradeTicket } from "@enfusion-ui/trades";
import { createSelectOptions } from "@enfusion-ui/utils";
import {
  ControlledDatePicker,
  ControlledNumericInput,
  ControlledSelect,
  FormPanel,
  LabeledButton,
  PanelRow,
} from "@enfusion-ui/web-components";
import { useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";

import { StyledFormElementHeader } from "../../../components/styledComponents";

const transactionOptions = createSelectOptions(["Buy", "Sell"]);

export const SwapDetailsDividendSwap: React.FC = () => {
  const isMobile = useIsContentMobile();
  const { formDataType } = useTradeTicket();
  return (
    <FormPanel
      lineStyle
      collapsible
      defaultOpen
      keepRendered
      numColumns={1}
      defaultRendered
      tabFocusOutline={false}
      title="Dividend Swap Details"
    >
      <StyledFormElementHeader>Swap Terms</StyledFormElementHeader>
      {formDataType !== TradeTicketFormTypes.DIVIDEND_SWAP && (
        <>
          <PanelRow>
            <ControlledInstrumentSelect
              required
              name="underlying"
              label="Underlying"
              showAdvancedSearch
              hideAdvancedSearchButtonText
            />
            <LabeledButton disabled hideLabelPlaceholder={isMobile}>
              None
            </LabeledButton>
          </PanelRow>
        </>
      )}
      <PanelRow>
        <ControlledDatePicker
          required
          label="Effective Date"
          name="effectiveDate"
        />
        <ControlledDatePicker
          required
          label="Maturity Date"
          name="maturityDate"
        />
      </PanelRow>

      <PanelRow>
        <ControlledNumericInput
          required
          label="Strike"
          name="strike"
          formatStyle="percent"
        />
        <CurrencySelect
          required
          label="Strike Currency"
          name="strikeCurrency"
        />
        <ControlledNumericInput label="Settle Days" name="settleDays" />
      </PanelRow>

      <StyledFormElementHeader>Trade Details</StyledFormElementHeader>
      <PanelRow>
        <ControlledSelect
          required
          label="Transaction"
          name="transactionType"
          options={transactionOptions}
        />
        <ControlledNumericInput
          required
          label="Notional"
          name="notionalAmount"
          fractionDigits={2}
        />
        <CurrencySelect
          required
          label="Notional Currency"
          name="notionalCurrency"
        />
      </PanelRow>
    </FormPanel>
  );
};
