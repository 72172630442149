import { useRefCallback } from "@enfusion-ui/hooks";
import { KeywordType } from "@enfusion-ui/types";
import { getSelectOption } from "@enfusion-ui/utils";
import { Select } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { ICellEditorParams } from "ag-grid-enterprise";
import { uniqBy } from "lodash";
import * as React from "react";

import { SelectOption } from "../../control/SimpleSelect";

const SelectContainer = styled.div`
  width: 250px;
  font-size: 1rem;
`;

type KeywordCellEditorProps = ICellEditorParams & {
  keywords: KeywordType[];
};

export const KeywordCellEditor = React.forwardRef<any, KeywordCellEditorProps>(
  ({ value, colDef, stopEditing, keywords = [] }, ref) => {
    const [optedValue, setOptedValue] = React.useState<string>(value);

    const field = colDef.field as keyof KeywordType;

    const options = React.useMemo(() => {
      if (field === "name") return [];
      return uniqBy(keywords, field).map(
        (item) =>
          ({
            label: item[field],
            value: item[field],
          } as SelectOption<string>)
      );
    }, [keywords]);

    React.useImperativeHandle(
      ref,
      () => ({
        getValue: () => optedValue,
        isCancelBeforeStart: () => false,
        isCancelAfterEnd: () => {
          // Cancel editing when having duplicate keyword names
          if (field === "name") {
            return (
              optedValue && keywords.map((k) => k.name).includes(optedValue)
            );
          }
          return false;
        },
      }),
      [optedValue, keywords]
    );

    const handleValueChange = useRefCallback(
      (option: SelectOption<string> | null) => {
        option && setOptedValue(option.value);
        setTimeout(stopEditing, 0);
      },
      []
    );

    return (
      <SelectContainer>
        <Select
          autoFocus
          creatable
          minWidth={110}
          options={options}
          clearable={false}
          onChange={handleValueChange}
          defaultInputValue={optedValue}
          value={optedValue ? getSelectOption(options, optedValue) : null}
        />
      </SelectContainer>
    );
  }
);
