import {
  ExplorerFolderProvider,
  ExplorerFolderProviderWrapperProps,
  getCopyFilePath,
} from "@app-context/explorer/explorerFolders/ExplorerFolderProvider";
import { useRefCallback } from "@enfusion-ui/hooks";
import { DashboardRoot, NodeData } from "@enfusion-ui/types";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

const getRoot = REST_API.PORTFOLIO.GET_ROOT_NODE_FACTORY();

export const PortfolioFolderProvider: React.FC<
  ExplorerFolderProviderWrapperProps
> = ({ children, root }) => {
  const copyFile = useRefCallback(
    async (node: NodeData, targetRoot: DashboardRoot, targetPath: string) => {
      try {
        const { name, ...rest } = await REST_API.PORTFOLIO.DOWNLOAD_PORTFOLIO(
          node.path
        );

        const path = getCopyFilePath(node.name, targetPath);

        const res = await REST_API.PORTFOLIO.STORE_PORTFOLIO({
          ...rest,
          name,
          path,
          rootPath: targetRoot.toLowerCase(),
        });

        if (res.success) return true;
        throw new Error("");
      } catch (err) {
        let msg = (err as Error).message
          .replace(/save/g, "copy")
          .replace("Failed to copy workbench", "")
          .trim();
        if ((err as Error).message?.includes("already exists")) {
          msg = "Workbench already exists";
        }
        throw new Error(msg);
      }
    },
    []
  );

  return (
    <ExplorerFolderProvider
      root={root}
      section="Portfolios"
      getRoot={getRoot}
      createFolder={REST_API.PORTFOLIO.CREATE_FOLDER}
      renameEntry={REST_API.PORTFOLIO.RENAME_FOLDER}
      deleteEntry={REST_API.PORTFOLIO.DELETE_ENTRY}
      moveEntry={REST_API.PORTFOLIO.MOVE_ENTRY}
      copyEntry={copyFile}
    >
      {children}
    </ExplorerFolderProvider>
  );
};
