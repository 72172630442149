/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ReportLoadingStatus from "@app-components/display/ReportLoadingStatus";
import { useReportRows } from "@app-context/reports/ReportsProvider";
import { useReportDatasource } from "@app-views/dashboards/hooks/useReportDatasource";
import {
  TileWidgetConfig,
  useDashboard,
  useWidget,
} from "@enfusion-ui/dashboards";
import { WebReportTableSyncColumn } from "@enfusion-ui/types";
import {
  Button,
  CenterContent,
  ContentMessage,
  ContentMessageWrapper,
  Spinner,
} from "@enfusion-ui/web-components";
import { css, styled, useAuth } from "@enfusion-ui/web-core";
import {
  faRedoAlt,
  faSkullCrossbones,
  faTable,
} from "@fortawesome/pro-solid-svg-icons";
import { MenuItem } from "rc-menu";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
import { useMeasure } from "react-use";

import { MenuItemsIcon, MenuItemsTitle } from "../../../widget/WidgetContainer";
import Tile from "./Tile";

const TileWrapperContainer = styled.div<{ wrapContent: boolean }>`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 8px;
  ${({ wrapContent }) =>
    wrapContent
      ? css`
          flex-wrap: wrap;
          /* overflow-x: hidden;
          overflow-y: auto; */
        `
      : css`
          flex-wrap: nowrap;
          /* overflow-x: auto;
          overflow-y: hidden; */
        `}
  height: 100%;
`;

const TileWidgetContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const TileWidget: React.FC<unknown> = () => {
  const { config, addWidgetActions, setEditing } = useWidget();
  const { ref: resizeRef } = useResizeDetector();
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [containerLoaded, setContainerLoaded] = React.useState(false);
  const [measureRef, { width, height }] = useMeasure<HTMLDivElement>();
  const { canEdit } = useDashboard();
  const { isUserType } = useAuth();

  const {
    metadata,
    errorLoading,
    gridOptions,
    datasource,
    reloadReport,
    openDrillDown,
    tableId,
  } = useReportDatasource();

  const { rowsStore } = useReportRows();
  const rowOptions = tableId ? rowsStore?.[tableId] ?? null : null;

  const {
    datasourceId,
    columns = [],
    wrap = false,
  } = config as TileWidgetConfig;

  React.useEffect(() => {
    addWidgetActions(
      !datasource
        ? null
        : {
            all: [
              <MenuItem key="refresh" onClick={reloadReport}>
                <MenuItemsIcon icon={faRedoAlt} />
                <MenuItemsTitle>Refresh</MenuItemsTitle>
              </MenuItem>,
              isUserType("Express") ? null : (
                <MenuItem key="drill-down" onClick={openDrillDown}>
                  <MenuItemsIcon icon={faTable} />
                  <MenuItemsTitle>Drill Down</MenuItemsTitle>
                </MenuItem>
              ),
            ],
          }
    );
  }, [!!datasource]);

  const handleRef = (ref: HTMLDivElement) => {
    containerRef.current = ref;
    resizeRef.current = ref;
    measureRef(ref);
    setContainerLoaded(true);
  };

  const isWidgetConfigurable = !datasourceId && canEdit;

  return (
    <TileWidgetContainer ref={handleRef}>
      {datasource && errorLoading ? (
        <CenterContent>
          <ContentMessage
            icon={faSkullCrossbones}
            message="We couldn't load this report data right now."
            details={errorLoading}
            parentHeight={height}
            parentWidth={width}
          />
        </CenterContent>
      ) : null}
      {!datasource ? (
        <CenterContent>
          <ContentMessageWrapper
            isConfigurable={isWidgetConfigurable}
            onMouseDown={(e) => {
              if (isWidgetConfigurable) {
                e.stopPropagation();
                e.preventDefault();
                setEditing(true);
              }
            }}
          >
            <ContentMessage
              message="Please configure Tile widget"
              size="3x"
              contentStyle={{ textAlign: "center" }}
              parentHeight={height}
              parentWidth={width}
            />
            {canEdit ? (
              <Button theme="primary" data-e2e-id="tile-widget-settings-btn">
                Open Settings
              </Button>
            ) : null}
          </ContentMessageWrapper>
        </CenterContent>
      ) : null}
      {!errorLoading &&
      datasourceId &&
      (!gridOptions || !containerLoaded || metadata?.loading) ? (
        <CenterContent>
          {metadata?.progressSteps ? (
            <ReportLoadingStatus
              steps={metadata.progressSteps}
              parentHeight={height}
              parentWidth={width}
            />
          ) : (
            <Spinner />
          )}
        </CenterContent>
      ) : (
        <TileWrapperContainer wrapContent={wrap}>
          {columns.map((entry, i) => {
            return (
              <Tile
                key={i}
                header={entry.label || entry.value}
                column={
                  (gridOptions?.metadata.columns[
                    entry.value
                  ] as WebReportTableSyncColumn) || null
                }
                value={rowOptions ? rowOptions.totalsRow?.[entry.value] : null}
              />
            );
          })}
        </TileWrapperContainer>
      )}
    </TileWidgetContainer>
  );
};

export default TileWidget;
