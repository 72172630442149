import { WebDatePeriodSelection, WebDateSelection } from "@enfusion-ui/types";
import { ControlledInputBase } from "@enfusion-ui/web-components";
import * as React from "react";

import DateRangeSymbolSelectionInput from "./DateRangeSymbolSelectionInput";
import {
  DatePeriodSelectionInput,
  DateRangeSelectionInput,
  DateSelectionInput,
} from "./DateSelectionInput";

export const DateSelection: React.FC<{
  name: string;
  label: string;
  defaultValue?: WebDateSelection | null;
}> = ({ name, label, defaultValue }) => {
  return (
    <ControlledInputBase
      name={name}
      defaultValue={defaultValue}
      render={({ ref: _ref, ...rest }) => (
        <DateSelectionInput
          {...rest}
          label={label}
          defaultValue={defaultValue}
          clearable={false}
        />
      )}
    />
  );
};

export const DatePeriodSelection: React.FC<{
  name: string;
  label: string;
  defaultValue?: WebDatePeriodSelection | null;
}> = ({ name, label, defaultValue }) => {
  return (
    <ControlledInputBase
      name={name}
      defaultValue={defaultValue}
      render={({ ref: _ref, ...rest }) => (
        <DatePeriodSelectionInput
          {...rest}
          label={label}
          defaultValue={defaultValue}
          clearable={false}
        />
      )}
    />
  );
};

export const DateRangeSymbolSelection: React.FC<{
  name: string;
  label: string;
  timezone?: string;
  datePickerWidth?: number;
}> = ({ name, label, timezone, datePickerWidth }) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...rest }) => (
        <DateRangeSymbolSelectionInput
          {...rest}
          label={label}
          clearable={false}
          timezone={timezone}
          datePickerWidth={datePickerWidth}
        />
      )}
    />
  );
};

export const DateRangeSelection: React.FC<{
  name: string;
  startDateLabel: string;
  endDateLabel: string;
  datePickerWidth?: number;
}> = ({ name, datePickerWidth, ...labels }) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, value, onChange }) => (
        <DateRangeSelectionInput
          {...labels}
          value={value}
          onChange={onChange}
          defaultValue={value}
          clearable={false}
          datePickerWidth={datePickerWidth}
        />
      )}
    />
  );
};
