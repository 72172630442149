import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import { ExplorerSection } from "@app-context/explorer/types";
import { AUTH_EVENT, parseBroadcastMessage } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { DashboardRoot } from "@enfusion-ui/types";
import {
  CenterContent,
  EmptyView,
  ForbiddenFileView,
  MissingFileView,
  Spinner,
} from "@enfusion-ui/web-components";
import { AUTH_CHANNEL } from "@enfusion-ui/web-core";
import queryString from "query-string";
import React from "react";

type FileErrorBoundaryProps = {
  section: ExplorerSection;
  filePath: string;
  loading?: boolean;
  fileMissingError?: boolean;
  errorContent?: React.ReactNode;
};

const SECTION_COMPONENT_CONTENT = {
  Dashboards: "Dashboard",
  Portfolios: "Portfolio workbench",
  Reports: "Report",
  GeneralFiles: "Preview",
  Services: "Preview",
  Operations: "Preview",
  WatchLists: "WatchList",
};

export const FileErrorBoundary: React.FC<
  React.PropsWithChildren<FileErrorBoundaryProps>
> = ({
  section,
  filePath,
  loading,
  fileMissingError,
  errorContent,
  children,
}) => {
  const [fileMissing, setFileMissing] = React.useState<boolean>(false);
  const [isForbidden, setForbidden] = React.useState(false);

  const handleMessage = useRefCallback(
    (event: string) => {
      const { action, meta } = parseBroadcastMessage(event);
      if (action === AUTH_EVENT.FORBIDDEN) {
        const { query } = queryString.parseUrl(meta[0]);

        const queryParamFullPath = query?.root
          ? `${query.root}/${query.path}`
          : query.path;

        if (
          filePath.startsWith((query?.report ?? queryParamFullPath) as string)
        ) {
          setForbidden(true);
        }
      }
    },
    [filePath]
  );

  React.useEffect(() => {
    return AUTH_CHANNEL.subscribe(handleMessage);
  }, []);

  const deleteEvent = useRefCallback(
    (_root: DashboardRoot, event: ExplorerEventsAction, meta?: string) => {
      if (
        event === ExplorerEventsAction.Delete &&
        !!meta &&
        filePath.startsWith(meta)
      )
        setFileMissing(true);
    },
    [filePath, setFileMissing]
  );

  useExplorerEvents(section, deleteEvent);

  if (loading)
    return (
      <EmptyView>
        <CenterContent>
          <Spinner />
        </CenterContent>
      </EmptyView>
    );

  if (fileMissing || fileMissingError)
    return (
      <MissingFileView
        component={SECTION_COMPONENT_CONTENT[section]}
        filePath={filePath}
      />
    );

  if (isForbidden)
    return (
      <ForbiddenFileView
        component={SECTION_COMPONENT_CONTENT[section]}
        filePath={filePath}
      />
    );

  if (errorContent) return <>{errorContent}</>;

  return <>{children}</>;
};

export default FileErrorBoundary;
