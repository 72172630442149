import AccountSelect from "@app-components/inputs/account/AccountSelect";
import { BorrowAgreementSelect } from "@app-components/inputs/BorrowAgreementSelect";
import { FinancingAgreementSelect } from "@app-components/inputs/FinancingAgreementSelect";
import { StrategyBookIdSelect } from "@app-views/oems/OrderTicket/panels/InstrumentAndStrategyPanel";
import { TraderSelect } from "@app-views/reports/components/ReportQueryComponents/AssignedTraderSelect";
import TradeBookingStatusSelect from "@app-views/reports/components/ReportQueryComponents/TradeBookingStatusSelect";
import { ControlledTradeTypeSelect } from "@app-views/reports/components/ReportQueryComponents/TradeTypeSelect";
import CurrencySelect from "@app-views/reports/reportSettings/generalLedgerAccounting/glDistributionLine/CurrencySelect";
import { TradeTicketFormTypes, useTradeTicket } from "@enfusion-ui/trades";
import {
  ControlledDatePicker,
  ControlledTextInput,
  FormController,
  LabeledButton,
  MultiSelect,
  PanelRow,
} from "@enfusion-ui/web-components";
import { useIsContentMobile } from "@enfusion-ui/web-core";
import { noop } from "lodash";
import * as React from "react";

import { InitialMarginInput } from "../../../components/InitialMarginInput";
import { PanelItemContainer } from "../../../components/styledComponents";

const TRADE_STATUS_OPTIONS = [
  { label: "Archived", value: "Archived" },
  { label: "Cancelled", value: "Cancelled" },
  { label: "Reconciled", value: "Reconciled" },
  { label: "Locked", value: "Locked" },
  { label: "Allocation Locked", value: "AllocationLocked" },
];

export const RowTwo: React.FC<unknown> = () => {
  const { isEquity, formDataType } = useTradeTicket();
  const isMobile = useIsContentMobile();
  const renderBorrowerAgreement: boolean =
    isEquity || formDataType === TradeTicketFormTypes.GENERAL_LISTED;

  return (
    <>
      <PanelRow>
        <AccountSelect onChange={noop} required label="Account" />
        <StrategyBookIdSelect
          label="Hierarchy"
          name="hierarchy"
          isClearable
          inputId="hierarchy"
          disabled={undefined}
          basis={undefined}
          mobileBasis={undefined}
          tabletBasis={undefined}
          desktopBasis={undefined}
        />
      </PanelRow>

      <PanelRow>
        <FormController
          name="tradeStatus"
          render={({ ref: _ref, value, onChange, ...rest }) => {
            return (
              <MultiSelect
                {...rest}
                label="Trade Status"
                value={value?.map((eachValue: string) =>
                  TRADE_STATUS_OPTIONS.find((e) => e.value === eachValue)
                )}
                options={TRADE_STATUS_OPTIONS}
                onChange={(selectedValues) =>
                  onChange(selectedValues?.map((e) => e.value) || [])
                }
                name="accountsSelect"
                inputId="trade-status-multi-selection-id"
              />
            );
          }}
        />
        <ControlledTradeTypeSelect
          required
          name="tradeType"
          label="Trade Type"
          multi={false}
          basis={undefined}
        />
      </PanelRow>

      <PanelRow>
        <ControlledDatePicker
          label="Trade Date"
          name="tradeDate"
          clearable
          showTimeInput
          showTimeSelect
          dateFormat="MM/dd/yyyy HH:mm:ss"
          required
        />
        <ControlledDatePicker label="Settle Date" name="settleDate" clearable />
      </PanelRow>

      <PanelRow>
        <ControlledTextInput name="externalRef" label="External Reference" />
        <ControlledTextInput name="internalRef" label="Internal Reference" />
      </PanelRow>

      <PanelRow>
        <TraderSelect name="traderSelect" selectProps={{ label: "Trader" }} />
        <TradeBookingStatusSelect
          placeholder=""
          label="Booking Status"
          name="tradeBookingStatusSelect"
        />
      </PanelRow>

      <PanelRow>
        <ControlledDatePicker
          clearable
          dateFormat="MM/dd/yyyy"
          name="originalTradeDate"
          label="Original Trade Date"
        />
        <ControlledTextInput name="dealId" label="Deal ID" />
      </PanelRow>

      <PanelRow>
        <ControlledDatePicker
          clearable
          dateFormat="MM/dd/yyyy"
          name="originalSettleDate"
          label="Original Settle Date"
        />
        <ControlledTextInput name="positionBlock" label="Position Block" />
      </PanelRow>

      <PanelRow>
        <InitialMarginInput />
        <CurrencySelect showNullOption nullOptionLabel="Underlying CCY" />
      </PanelRow>

      <PanelRow style={{ marginTop: "var(--spacing-xl)" }}>
        <ControlledTextInput
          textarea
          hideLabel
          name="orderNotes"
          placeholder="Enter Notes Here"
          style={{ resize: "vertical" }}
        />
      </PanelRow>
      {renderBorrowerAgreement && (
        <PanelRow>
          <BorrowAgreementSelect showAdvancedSearch />
          <LabeledButton disabled hideLabelPlaceholder={isMobile}>
            Overrides
          </LabeledButton>
        </PanelRow>
      )}
      <PanelRow>
        <PanelItemContainer>
          <FinancingAgreementSelect showAdvancedSearch />
        </PanelItemContainer>

        <PanelItemContainer>
          <LabeledButton hideLabelPlaceholder={isMobile}>
            Price Dirty
          </LabeledButton>
          <LabeledButton disabled hideLabelPlaceholder={isMobile}>
            Overrides
          </LabeledButton>
        </PanelItemContainer>
      </PanelRow>
    </>
  );
};
