/* eslint-disable camelcase */
import * as React from "react";
import { v4 } from "uuid";
import { useRefCallback } from "./useRefCallback";
const EMPTY_ARRAY = [];
function getRowId(params) {
    return params.data.__row_id;
}
export function useFormTableListSetter(name, getValues, setValue) {
    return useRefCallback((setter) => {
        const val = getValues(name);
        setValue(name, setter(val));
    }, [name, getValues, setValue]);
}
export function useFormTableList(setData, initialData = EMPTY_ARRAY) {
    const initializeData = useRefCallback((initData) => {
        const data = initData.map((row) => {
            if (!row.__row_id) {
                return { ...row, __row_id: v4() };
            }
            return row;
        });
        setData(() => data);
        return data;
    }, []);
    React.useEffect(() => {
        initializeData(initialData);
    }, [initialData]);
    const addRow = useRefCallback((data) => {
        setData((formData) => [
            ...(formData || []),
            { ...data, __row_id: data.__row_id ?? v4() },
        ]);
    }, []);
    const deleteRows = useRefCallback((rowIds) => {
        if (!rowIds || !rowIds.length)
            return;
        const deleteIds = new Set(rowIds);
        setData((formData) => (formData || []).filter((d) => !deleteIds.has(d.__row_id)));
    }, []);
    const modifyRow = useRefCallback((rowId, data) => {
        setData((formData) => {
            const targetIdx = (formData || []).findIndex((d) => d.__row_id === rowId);
            const newRowData = [...(formData || [])];
            data.__row_id = rowId;
            newRowData[targetIdx] = data;
            return newRowData;
        });
    }, []);
    const getContextMenuItems = useRefCallback((selectedIds, nodeId) => [
        {
            name: `Remove Row${selectedIds.length > 1 ? "s" : ""}`,
            disabled: !nodeId && !selectedIds.length,
            action: () => {
                if (selectedIds.length) {
                    deleteRows(selectedIds);
                    return;
                }
                if (nodeId)
                    deleteRows([nodeId]);
            },
        },
    ], []);
    return React.useMemo(() => ({
        addRow,
        getRowId,
        modifyRow,
        deleteRows,
        initializeData,
        getContextMenuItems,
    }), []);
}
