import { useRefCallback } from "@enfusion-ui/hooks";
import {
  InstructionAction,
  InstructionActionOptions,
  TradeTicketFormValues,
} from "@enfusion-ui/trades";
import { getSelectOption } from "@enfusion-ui/utils";
import {
  ControlledEmpty,
  FormElement,
  FormPanel,
  PanelRow,
  Select,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import {
  FullHeightContainer,
  TabContentContainer,
} from "./components/styledComponents";
import { TradeTicketInstructionsTabHeader } from "./components/TradeTicketInstructionsTabHeader";

const InstructionInputsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: var(--spacing-l) 0;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
`;

export const InstructionsTab: React.FC = () => {
  const { setValue } = useFormContext<TradeTicketFormValues>();
  const { instructionActions = {}, destinations = [] } =
    useWatch<TradeTicketFormValues>({
      name: ["instructionActions", "destinations"],
    });

  React.useEffect(() => {
    const initialInstructions = destinations.reduce((res, dest) => {
      res[dest.description as string] = null;
      return res;
    }, {} as Record<string, InstructionAction | null>);
    setValue("instructionActions", initialInstructions);
  }, [destinations]);

  const applyActionToAll = useRefCallback(
    (instructionAction: InstructionAction | null) => {
      const newInstructionActions = Object.keys(instructionActions).reduce(
        (res, key) => {
          res[key] = instructionAction;
          return res;
        },
        {} as TradeTicketFormValues["instructionActions"]
      );
      setValue("instructionActions", newInstructionActions);
    },
    [instructionActions]
  );

  const onChange = useRefCallback(
    (destination: string, action: InstructionAction | null) => {
      const newInstructionActions = {
        ...instructionActions,
        [destination]: action,
      };
      setValue("instructionActions", newInstructionActions);
    },
    [instructionActions]
  );

  const rows = React.useMemo(
    () =>
      destinations.reduce(
        (rows, destination, index) => {
          const rowIdx = Math.floor(index / 2);
          rows[rowIdx][index % 2] = destination.description;
          return rows;
        },
        Array(Math.ceil(destinations.length / 2))
          .fill(null)
          .map(() => Array(2).fill(null))
      ),
    [destinations]
  );

  return (
    <>
      <ControlledEmpty name="instructionActions" />
      <TabContentContainer>
        <FormPanel
          numColumns={1}
          style={{ height: "100%" }}
          contentStyle={{ height: "100%" }}
        >
          <FullHeightContainer data-e2e-id="trade-ticket-instructions-tab">
            <TradeTicketInstructionsTabHeader />

            <PanelRow>
              <FormElement
                basis="49.5%"
                mobileBasis="100%"
                style={{ flexGrow: 0 }}
              >
                <Select
                  name="apply-action-select"
                  clearable
                  label="Apply Action To All"
                  options={InstructionActionOptions}
                  inputId="tt-instructions-tab-apply-all-select"
                  onChange={(option) => applyActionToAll(option?.value ?? null)}
                />
              </FormElement>
            </PanelRow>

            <InstructionInputsContainer>
              {rows.map((row, idx) => (
                <PanelRow key={idx}>
                  {row.map((destination: string | null, idx) => (
                    <FormElement key={idx}>
                      {destination && (
                        <Select
                          name={destination}
                          inputId={destination
                            .split(" ")
                            .join("-")
                            .toLowerCase()}
                          clearable
                          placeholder="Default"
                          label={destination}
                          options={InstructionActionOptions}
                          onChange={(option) =>
                            onChange(destination, option?.value ?? null)
                          }
                          value={
                            instructionActions[destination]
                              ? getSelectOption(
                                  InstructionActionOptions,
                                  instructionActions[destination]
                                )
                              : null
                          }
                        />
                      )}
                    </FormElement>
                  ))}
                </PanelRow>
              ))}
            </InstructionInputsContainer>
          </FullHeightContainer>
        </FormPanel>
      </TabContentContainer>
    </>
  );
};
