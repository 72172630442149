import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import {
  ConnectionType,
  useConnectionStatus,
} from "@app-context/connectionStatus/context";
import {
  getCanCreateOrders,
  PortfolioConfigV1,
  PortfolioSettingsV1,
  StorePortfolioArgs,
} from "@enfusion-ui/core";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import {
  PortfolioProposedOrder,
  PortfolioProvider as PortfolioProviderBase,
  PortfolioProviderReducerAction,
  PortfolioProviderReducerState,
  PROPOSED_ORDERS_REMOVAL_CONFIRMATION,
  usePortfolio as usePortfolioCore,
} from "@enfusion-ui/portfolios";
import {
  ConnectionStatus,
  DashboardRoot,
  FileTreeEntry,
} from "@enfusion-ui/types";
import { useConfirmationModal } from "@enfusion-ui/web-components";
import {
  errorToast,
  infoToast,
  PWB_EVENTS,
  restServer,
  successToast,
  useAuth,
  useThisTab,
  warningToast,
} from "@enfusion-ui/web-core";
import { useWorkerModule } from "@enfusion-ui/web-workers";
import { ColumnState, RowNode } from "ag-grid-community";
import { omit, pick } from "lodash";
import React from "react";

export const PWB_EVENTS_ACTIONS = {
  LockedRowsChanged: "locked-rows-changed",
} as const;

export function usePortfolio() {
  return usePortfolioCore<RowNode, ColumnState>();
}

export const PortfolioProvider: React.FC<
  React.PropsWithChildren<{
    config: {
      id: string;
      root: DashboardRoot;
      filePath: string;
      data?: FileTreeEntry;
    };
    onSave?: (params: StorePortfolioArgs) => void;
  }>
> = ({ children, config, onSave }) => {
  const isMounted = useMounted();
  const { renameTab, closeTab } = useThisTab();
  const { enableModule, getCurrentState, postMessage } =
    useWorkerModule("portfolios");
  const { updateStatus } = useConnectionStatus();
  const { user } = useAuth();
  const pwbEventsChannel = useBroadcastChannel(PWB_EVENTS);

  const canCreateOrders = React.useMemo(() => getCanCreateOrders(user), [user]);
  const removeOrderCallbackRef = React.useRef();
  const { id } = config;

  React.useEffect(() => {
    if (isMounted()) {
      enableModule();
      getCurrentState()?.then(
        async (msg: { payload: { socketStatus: Array<[string, number]> } }) => {
          const [_Ignored, socketStatus] =
            msg?.payload?.socketStatus?.find((i) => i[0] === id) || [];

          if (socketStatus === WebSocket.OPEN)
            updateStatus(ConnectionType.Portfolios, ConnectionStatus.CONNECTED);
        }
      );
    }
  }, [id]);

  const onPortfolioSave = useRefCallback(
    (params: StorePortfolioArgs) => {
      renameTab?.(params.name);
      onSave?.(params);
    },
    [renameTab, onSave]
  );

  const RemoveOrderConfirmation = useConfirmationModal<{
    removals: number[];
    changes: number[];
    orders: PortfolioProposedOrder[];
  }>({
    onSubmit: removeOrderCallbackRef.current,
    submitActionTheme: "primary",
    title: PROPOSED_ORDERS_REMOVAL_CONFIRMATION.title,
    submitButtonText: PROPOSED_ORDERS_REMOVAL_CONFIRMATION.buttonText,
    renderContent: () => PROPOSED_ORDERS_REMOVAL_CONFIRMATION.message,
  });

  const onRemoveProposedAllocation = useRefCallback(
    (
      removals: number[],
      changes: number[],
      orders: PortfolioProposedOrder[],
      removeOrderCallback: any
    ) => {
      removeOrderCallbackRef.current = removeOrderCallback;
      RemoveOrderConfirmation.openModal({
        removals: [...removals],
        changes: [...changes],
        orders,
      });
    },
    [RemoveOrderConfirmation]
  );

  const onPortfolioLoad = useRefCallback(
    (
      destination: string,
      name: string,
      config: PortfolioConfigV1,
      settings: PortfolioSettingsV1,
      reload?: boolean,
      shouldRenameTab?: boolean
    ) => {
      postMessage({
        destination,
        command: !reload ? "load" : "reload",
        payload: {
          canCreateOrders,
          ...config,
          fundIds: config.fundIds.length === 0 ? [286296] : config.fundIds,
          groupings: config.groupings || ["sector", "issuer", "instrument"],
          ...pick(settings, [
            "rebalancingDurationType",
            "rebalanceCalculationLevel",
            "marketEnvironment",
            "checkComplianceAutomatically",
            "includeActiveOrders",
          ]),
          rebalancingExposureType: settings.numeratorAggregation,
          rebalancingBenchmarkType: settings.denominator,
          deminimusQuantity: settings?.quantity,
          deminimusExposure: settings?.exposure,
          deminimusIncrementalExposurePercent: settings?.perIncremental,
          // valueDate: format(new Date(), "yyyy-MM-dd"),
        },
      });
      if (shouldRenameTab) {
        renameTab?.(name);
      }
    },
    [postMessage, canCreateOrders]
  );

  const postMessageReducer = useRefCallback(
    (
      state: PortfolioProviderReducerState,
      action: PortfolioProviderReducerAction
    ) => {
      switch (action.type) {
        case "add-security":
        case "remove-security":
        case "rebalance":
        case "adjust":
        case "price-override":
        case "test-compliance":
        case "submit-orders":
        case "modify-orders":
        case "regroup":
          postMessage({
            destination: state.id,
            command: action.type,
            payload: { ...(action?.payload || {}) },
          });
          break;

        case "change-settings":
          if (action?.payload.send)
            postMessage({
              destination: state.id,
              command: action.type,
              payload: { ...omit(state.settings, ["valueDate"]) },
            });
          break;

        case "undo": {
          const undoAction = action.payload;
          if (
            ["adjust", "price-override", "modify-orders"].includes(
              undoAction.type
            )
          ) {
            postMessage({
              destination: state.id,
              command: "undo-adjustment",
              payload: {},
            });
          } else if (undoAction.type === "add-security") {
            postMessage({
              destination: state.id,
              command: "remove-security",
              payload: { ...undoAction.payload },
            });
          } else if (undoAction.type === "remove-security") {
            postMessage({
              destination: state.id,
              command: "add-security",
              payload: { ...undoAction.payload },
            });
          } else if (undoAction.type === "change-settings") {
            postMessage({
              destination: state.id,
              command: "change-settings",
              payload: {
                ...omit({ ...undoAction.payload.prevSettings }, ["valueDate"]),
              },
            });
          }
          break;
        }

        default:
          break;
      }
      return state;
    },
    [postMessage]
  );

  const afterLockedRowsChanged = useRefCallback(
    () =>
      pwbEventsChannel.broadcastMessage({
        id,
        action: PWB_EVENTS_ACTIONS.LockedRowsChanged,
      }),
    [id]
  );

  return (
    <PortfolioProviderBase
      config={config}
      user={user}
      onSave={onPortfolioSave}
      onLoad={onPortfolioLoad}
      middlewareFns={[postMessageReducer]}
      onRemoveProposedAllocation={onRemoveProposedAllocation}
      restServer={restServer}
      errorToast={errorToast}
      successToast={successToast}
      infoToast={infoToast}
      warningToast={warningToast}
      afterLockedRowsChanged={afterLockedRowsChanged}
      closeTab={closeTab}
    >
      {children}
      {RemoveOrderConfirmation.content}
    </PortfolioProviderBase>
  );
};
