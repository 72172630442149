export const ReportDefinitions = {
    WebPositionCashFlowQuery: "CustodianCashFlowReportDefinition",
    WebInstrumentQuery: "InstrumentReportDefinition",
    WebGLCashFlowStatementQuery: "GeneralLedgerCashFlowStatementReportDefinition",
    WebGLPositionQuery: "GeneralLedgerPositionPerformanceReportDefinition2",
    WebCustodianAccountQuery: "CustodianAccountReportDefinition",
    WebQuotesQuery: "HistoricalQuoteReportDefinition",
    WebPositionReportQuery: "CustodianPositionReportDefinition",
    WebOrderBlotterQuery: "OrderReportDefinition",
    WebGLDistributionLineQuery: "GeneralLedgerDistributionLineReportDefinition",
    WebTradeValuationReportQuery: "TradeValuationReportDefinition",
    WebTradeBlotterQuery: "TradeReportDefinition",
    WebGLBalanceSheetQuery: "GeneralLedgerBalanceSheetReportDefinition",
    WebGLCashBalancesQuery: "GeneralLedgerCashBalancesReportDefinition",
    WebGLIncomeStatementQuery: "GeneralLedgerIncomeStatementReportDefinition",
    WebGLTrialBalancesQuery: "GeneralLedgerTrialBalancesReportDefinition",
    WebGLCashActivityQuery: "GeneralLedgerCashActivityReportDefinition",
    WebPositionEventReportQuery: "PositionEventReportDefinition",
    WebCounterpartyCommissionQuery: "CounterpartyCommissionReportDefinition",
    WebBookReportQuery: "BookReportDefinition",
    WebInvestmentReportQuery: "InvestmentReportDefinition",
    WebComplianceBlotterQuery: "ComplianceRecordReportDefinition",
    WebCorporateActionReportQuery: "CorporateActionReportDefinition",
};
