import { usePortfolio } from "@app-context/portfolios/PortfolioProvider";
import { ICellRendererParams } from "ag-grid-community";

import ColumnSelect from "../../../../components/inputs/ColumnSelect";

const StrategyCellRenderer: React.FC<
  ICellRendererParams & {
    allowGroup?: boolean;
  }
> = (params) => {
  const {
    strategyData: { options, loading },
  } = usePortfolio();

  if (!params.allowGroup && params.node.group)
    return <>{params.valueFormatted ?? null}</>;

  return (
    <div style={{ pointerEvents: "none" }}>
      <ColumnSelect
        name="strategy"
        options={options}
        isLoading={loading}
        clearable={false}
        mobileBasis="50%"
        minWidth={50}
        colDef={params.colDef}
        {...params}
      />
    </div>
  );
};

export default StrategyCellRenderer;
