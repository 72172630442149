import {
  NavSourceSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType, SelectOptionsType } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import { BenchmarkNavSourceSelect } from "./NavSourceSelect";

const NavSourceSelectionWidget = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, key } = config as NavSourceSelectionWidgetConfig;
  const [value, setValue] = React.useState<string | null>(defaultValue);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Benchmark NAV Source",
        type: ChannelDataType.String,
      },
    ]);
  }, [addChannelKeys, key]);

  React.useEffect(() => {
    setChannelData(value ? [value] : [[]]);
  }, [setChannelData, value]);

  const handleChange = (option: SelectOptionsType<string> | null) => {
    setValue(option?.value || null);
  };

  return (
    <CenterContent>
      <BenchmarkNavSourceSelect
        label={key}
        value={value}
        onChange={handleChange}
      />
    </CenterContent>
  );
};

export default NavSourceSelectionWidget;
