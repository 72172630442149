/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRefCallback } from "@enfusion-ui/hooks";
import * as React from "react";
export const WorkerContext = React.createContext(undefined);
export function useWorker() {
    const context = React.useContext(WorkerContext);
    if (context === undefined) {
        throw new Error("useWorker must be used within a WorkerProvider");
    }
    return context;
}
export function useWorkerModule(moduleKey) {
    const context = React.useContext(WorkerContext);
    if (context === undefined) {
        throw new Error("useWorkerModule must be used within a WorkerProvider");
    }
    const postMessage = useRefCallback((payload) => context.postMessage(moduleKey, payload), [context.postMessage]);
    const enableModule = useRefCallback(() => context.enableModule(moduleKey), [context.enableModule, moduleKey]);
    const disableModule = useRefCallback(() => context.disableModule(moduleKey), [context.disableModule, moduleKey]);
    const getCurrentState = useRefCallback(() => context.getCurrentState(moduleKey), [context.getCurrentState, moduleKey]);
    const subscribeToModule = useRefCallback((callback) => context.subscribeToModule(moduleKey, callback), [context.subscribeToModule, moduleKey]);
    return {
        enableModule,
        disableModule,
        getCurrentState,
        postMessage,
        subscribeToModule,
    };
}
