import { ColDef, Column } from "ag-grid-enterprise";

import { ReportModelKey, reportsDAO } from "../../../utils/db/ReportsDAO";
import { ColumnSettings } from "../components/ReportGrid";

function getColDefsToSave(columns: Column[] | null) {
  return columns
    ? (columns.map((e) => ({
        colId: e.getColId(),
        initialPinned: e.getPinned(),
        initialWidth: e.getActualWidth(),
      })) as ColDef[])
    : null;
}

const persistChildGridState = async (
  columns: Column[] | null,
  reportModelKey: ReportModelKey,
  subTableId: string
) => {
  const storedColSettings = await getState(reportModelKey);
  const colDefsToSave = getColDefsToSave(columns);

  if (!storedColSettings) {
    // Column Defs do not exist in local storage for a given report id

    setState(reportModelKey, {
      columns: [],
      subGridColumns: { [subTableId]: colDefsToSave },
    });
  } else {
    const storedObj = storedColSettings;

    // Top level grid ColumnDefs saved but subGridColumn object not yet stored
    if (!storedObj.subGridColumns) {
      setState(reportModelKey, {
        ...storedObj,
        subGridColumns: { [subTableId]: colDefsToSave },
      });
    } else {
      storedObj.subGridColumns[subTableId] = colDefsToSave;

      setState(reportModelKey, { ...storedObj });
    }
  }
};

const persistMainGridState = async (
  columns: Column[] | null,
  reportModelKey: ReportModelKey
) => {
  const storedColSettings = await getState(reportModelKey);

  const colDefsToSave = getColDefsToSave(columns);

  if (!storedColSettings) {
    setState(reportModelKey, { columns: colDefsToSave });
  } else {
    const storedCols: ColumnSettings = storedColSettings;

    setState(reportModelKey, { ...storedCols, columns: colDefsToSave });
  }
};

export const persistOnColumnEvent = (
  columns: Column[] | null,
  reportModelKey: ReportModelKey,
  subTableId?: string,
  isChildGrid?: boolean
) => {
  if (isChildGrid && subTableId) {
    persistChildGridState(columns, reportModelKey, subTableId);
  } else {
    persistMainGridState(columns, reportModelKey);
  }
};

const generateColDefsToLoad = (
  storedCols: ColDef[],
  existingColDefs: ColDef[] | undefined
) => {
  if (!storedCols) return existingColDefs;
  if (!existingColDefs) return [];

  return storedCols?.reduce((res, entry) => {
    const existing = existingColDefs.find((i) => i.colId === entry.colId);
    if (existing) {
      res.push({ ...existing, ...entry });
    }
    return res;
  }, [] as ColDef[]);
};

export const loadColumnState = async (
  reportModelKey: ReportModelKey,
  existingCols: ColDef[] | undefined,
  subTableId?: string,
  isChildGrid?: boolean
) => {
  const storedColSettings = await getState(reportModelKey);

  if (!storedColSettings) {
    console.warn(
      `Expected column state saved with key: ${reportModelKey.reportId}`
    );
    return existingCols;
  }

  try {
    const storedCols: ColumnSettings = storedColSettings;

    if (isChildGrid) {
      if (subTableId && storedCols.subGridColumns) {
        return generateColDefsToLoad(
          storedCols.subGridColumns[subTableId],
          existingCols
        );
      }
    } else {
      if (storedCols.columns)
        return generateColDefsToLoad(storedCols.columns, existingCols);
    }

    return existingCols;
  } catch (err) {
    console.warn("failed to load col defs", err);
    return existingCols;
  }
};

export const clearState = async (reportModelKey: ReportModelKey) => {
  if (reportModelKey.reportId && reportModelKey.username) {
    await reportsDAO.deleteReport(reportModelKey);
  }
};

export const getState = async (reportModelKey: ReportModelKey) => {
  return await reportsDAO.fetchUniqueReport(reportModelKey);
};

export const setState = async (
  reportModelKey: ReportModelKey,
  data: unknown
) => {
  await reportsDAO.addReport({ ...reportModelKey, report: data });
};
