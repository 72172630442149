import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import { useRefCallback } from "@enfusion-ui/hooks";
import { DashboardRoot, StorageRoot } from "@enfusion-ui/types";
import { gaModalView } from "@enfusion-ui/web-core";
import * as React from "react";

import { ServicesSectionMap } from "./ServicesFolderProvider";

type ServicesExplorerContextType = {
  uploadFileModalPath: string | null;
  createFolderModalPath: string | null;

  openUploadFileModal: (
    root: DashboardRoot,
    path: string,
    section: StorageRoot
  ) => void;
  closeUploadFileModal: VoidFunction;

  openCreateFolderModal: (
    root: DashboardRoot,
    path: string,
    section: StorageRoot
  ) => void;
  closeCreateFolderModal: VoidFunction;
  section: string | null;

  deleteServicesFile: (
    root: DashboardRoot,
    path: string,
    section: StorageRoot
  ) => void;
};

const ServicesExplorerContext = React.createContext<
  ServicesExplorerContextType | undefined
>(undefined);

export function useServicesExplorer() {
  const context = React.useContext(ServicesExplorerContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useServicesExplorer must be used within a ServicesExplorerProvider"
    );
  }
  return context;
}

export default function ServicesExplorerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [uploadFileModalPath, setUploadFileModalPath] = React.useState<
    string | null
  >(null);
  const [createFolderModalPath, setCreateFolderModalPath] = React.useState<
    string | null
  >(null);
  const [section, setSection] = React.useState<StorageRoot | null>(null);

  const explorerEvent = useExplorerEvents();

  const openUploadFileModal = useRefCallback(
    (_root: DashboardRoot, path: string, section: StorageRoot) => {
      setSection(section);
      gaModalView("/services/upload-file");
      setUploadFileModalPath(path);
    },
    []
  );

  const closeUploadFileModal = useRefCallback(() => {
    setSection(null);
    setUploadFileModalPath(null);
  }, []);

  const openCreateFolderModal = useRefCallback(
    (_root: DashboardRoot, path: string, section: StorageRoot) => {
      setSection(section);
      setCreateFolderModalPath(path);
    },
    []
  );

  const closeCreateFolderModal = useRefCallback(() => {
    setSection(null);
    setCreateFolderModalPath(null);
  }, []);

  const deleteServicesFile = useRefCallback(
    (root: DashboardRoot, path: string, section: StorageRoot) => {
      explorerEvent.broadcast(
        ServicesSectionMap[section],
        root,
        ExplorerEventsAction.Delete,
        path
      );
    },
    [explorerEvent]
  );

  return (
    <ServicesExplorerContext.Provider
      value={{
        uploadFileModalPath,
        createFolderModalPath,
        openUploadFileModal,
        closeUploadFileModal,
        openCreateFolderModal,
        closeCreateFolderModal,
        section,
        deleteServicesFile,
      }}
    >
      {children}
    </ServicesExplorerContext.Provider>
  );
}
