import { DateRangeSymbolSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledLedgerSelect } from "../../../components/ReportQueryComponents/LedgerSelect";

const GLCashFlowStatementSettings = () => {
  return (
    <>
      <ControlledLedgerSelect
        name="generalLedgerIds"
        label="General Ledger(s)"
      />
      <DateRangeSymbolSelection
        name="period"
        label="Period"
        datePickerWidth={100}
      />
    </>
  );
};

export default GLCashFlowStatementSettings;
