import {
  ControlledDatePicker,
  ControlledTextInput,
  FormPanel,
  PanelRow,
} from "@enfusion-ui/web-components";
import * as React from "react";

const DATE_FORMAT = "MM-dd-yyyy hh:mm:ss aa";

export const HistoryTabSummaryPanel: React.FC = () => {
  return (
    <FormPanel title="Summary" numColumns={1} keepRendered>
      <PanelRow>
        <ControlledDatePicker
          disabled
          placeholder=""
          name="creationDate"
          label="Creation Date"
          dateFormat={DATE_FORMAT}
        />

        <ControlledDatePicker
          disabled
          placeholder=""
          name="lastModifiedDate"
          label="Last Modified Date"
          dateFormat={DATE_FORMAT}
        />
      </PanelRow>

      <PanelRow>
        <ControlledTextInput readOnly name="createdBy" label="Created By" />

        <ControlledTextInput
          readOnly
          name="lastModifiedBy"
          label="Last Modified By"
        />
      </PanelRow>
    </FormPanel>
  );
};
