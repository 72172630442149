import { getDefaultColor } from "@enfusion-ui/dashboards";
import { useRefCallback } from "@enfusion-ui/hooks";
import { SelectOptionsType, ValuesInputType } from "@enfusion-ui/types";
import { createTestId, getSelectOption } from "@enfusion-ui/utils";
import {
  Button,
  ColorPicker,
  InputLabelControl,
  Select,
  TextInput,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { FieldErrors } from "react-hook-form";

const Container = styled.div<{ noMargin: boolean }>`
  display: flex;
  width: 100%;
  margin-bottom: ${({ noMargin }) => (noMargin ? "0px" : "var(--spacing)")};
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--spacing);
`;

const BtnWrapper = styled.div`
  width: 38px;
  margin: auto 0 0;

  @media (max-width: 630px) {
    flex: 1;
    min-width: 45%;
  }
`;

const InputWrapper = styled.div`
  flex: 1;

  @media (max-width: 630px) {
    min-width: 45%;
  }
`;

const ColorWrapper = styled.div`
  @media (max-width: 630px) {
    flex: 1;
    min-width: 45%;
  }
`;

export type ValuesInputProps = {
  defaultValues: Array<ValuesInputType>;
  label?: string;
  emptyLabelPlaceholder?: boolean;
  name: string;
  max?: number;
  options: SelectOptionsType<string>[];
  onChange: (values: Array<ValuesInputType>) => void;
  includeColorPicker?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  required?: boolean;
  errors?: FieldErrors;
  btnTestId?: string;
};

const ValuesInput: React.FC<ValuesInputProps> = ({
  defaultValues,
  emptyLabelPlaceholder = true,
  label,
  name,
  max,
  options,
  onChange,
  includeColorPicker,
  disabled,
  clearable,
  required,
  errors,
  btnTestId,
}) => {
  const [valueState, setValueState] = React.useState<Array<ValuesInputType>>(
    []
  );

  React.useEffect(() => {
    setValueState(
      // this is to support the older structure for this of [string, string]
      defaultValues.map((i, idx) => {
        if (Array.isArray(i)) {
          const color = getDefaultColor(idx);
          return { label: i[0], value: i[1], color, negativeColor: color };
        }
        return i;
      })
    );
  }, [defaultValues]);

  const removeEntry = React.useCallback((idx: number) => {
    return () => {
      setValueState((state) => {
        const newState = [...state].filter((v, i) => i !== idx);
        onChange(newState);
        return newState;
      });
    };
  }, []);

  const addEntry = React.useCallback(() => {
    setValueState((state) => [...state, { label: "", value: "", color: "" }]);
  }, []);

  const changeEntryLabel = React.useCallback((idx: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setValueState((state) => {
        const newState = [...state];
        const row = newState[idx];
        if (row !== null) {
          newState[idx] = { ...row, label: e.target.value.toString() };
        }
        onChange(newState);
        return newState;
      });
    };
  }, []);

  const changeEntryValue = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (idx: number) => (option: any) => {
      setValueState((state) => {
        const newState = [...state];
        if (newState[idx] !== null) {
          newState[idx] = {
            ...option,
            color: newState[idx]?.color || "",
          };
        }
        onChange(newState);
        return newState;
      });
    },
    []
  );

  const handleColorPicker = useRefCallback(
    (idx: number, key: string, value: string | undefined) => {
      setValueState((state) => {
        const newState = [...state];
        const row = newState[idx];
        if (row !== null) {
          newState[idx] = { ...row, [key]: value };
        }
        onChange(newState);
        return newState;
      });
    },
    []
  );

  const handleColorChangeFull = React.useCallback(
    (idx: number) => (value: string[] | undefined) => {
      handleColorPicker(idx, "color", value ? value[0] : undefined);
      handleColorPicker(idx, "negativeColor", value ? value[1] : undefined);
    },
    []
  );

  const canAdd = typeof max === "number" ? valueState.length < max : true;

  return (
    <>
      <InputLabelControl
        label={label}
        labelPlaceholder={emptyLabelPlaceholder}
        required={required}
        name={name}
        errors={errors}
      />

      {valueState.map((entry, idx) => {
        const { label, value, color, negativeColor } = entry;
        return (
          <Container
            noMargin={!canAdd && idx === valueState.length - 1}
            key={`${idx}`}
          >
            <InputWrapper>
              <TextInput
                value={label}
                name={name}
                onChange={changeEntryLabel(idx)}
                data-testid={`test-input-${name}`}
                label="Description"
                disabled={disabled}
                clearable={clearable}
              />
            </InputWrapper>
            <InputWrapper>
              <Select
                value={getSelectOption(options, value)}
                options={options}
                onChange={changeEntryValue(idx)}
                label="Column"
                disabled={disabled}
                clearable={clearable}
                inputId={`${name}-column-input-id`}
              />
            </InputWrapper>

            {includeColorPicker && (
              <ColorWrapper>
                <ColorPicker
                  multi
                  value={
                    color || negativeColor
                      ? [color || "", negativeColor || ""]
                      : []
                  }
                  defaultValue={[getDefaultColor(idx), getDefaultColor(idx)]}
                  onChange={handleColorChangeFull(idx)}
                  clearable={clearable}
                  label="Colors"
                  disabled={disabled}
                  valueLabel={["Positive Color", "Negative Color"]}
                />
              </ColorWrapper>
            )}

            <BtnWrapper>
              <Button
                style={{ height: "38px", minWidth: "36px" }}
                name="remove"
                onClick={removeEntry(idx)}
                data-testid="remove"
              >
                <FontAwesomeIcon data-testid="icon" icon={faTrash} size="xs" />
              </Button>
            </BtnWrapper>
          </Container>
        );
      })}
      {canAdd && (
        <Button
          onClick={addEntry}
          data-e2e-testId={btnTestId}
          data-testid={createTestId(btnTestId ?? "values-input")}
        >
          Add
        </Button>
      )}
    </>
  );
};

export default ValuesInput;
