import ControlledAccountSelect from "@app-components/inputs/account/ControlledAccountSelect";
import InstrumentMultiSelect from "@app-components/inputs/InstrumentSelect/InstrumentMultiSelect";
import {
  IGNORED_TRADE_BOOKING_STATUS_OPTIONS,
  InstrumentInfo,
  TRADE_STATUS_OPTIONS,
} from "@enfusion-ui/types";
import {
  ControlledCheckbox,
  ControlledInputBase,
  MultiSelect,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

import ControlledBookSelect from "../../../components/ReportQueryComponents/BookSelect";
import { DateRangeSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledFinancialSubTypeSelect } from "../../../components/ReportQueryComponents/FinancialSubTypeSelect";
import { ControlledMaxResultsInput } from "../../../components/ReportQueryComponents/MaxResultsInput";
import { ControlledPortfolioGroupsSelect } from "../../../components/ReportQueryComponents/PortfolioGroupsSelect";

const InputContainer = styled.div`
  min-width: 250px;
`;

const HierarchyContainer = styled.div``;

const TradeBlotterQuickFilter = () => {
  const { bookSelection } = useWatch({
    name: ["bookSelection"],
  });

  const defaultBookSelectionRef = React.useRef(bookSelection);

  return (
    <>
      <InputContainer>
        <ControlledAccountSelect label="Account(s)" />
      </InputContainer>
      <InputContainer>
        <ControlledPortfolioGroupsSelect />
      </InputContainer>
      <HierarchyContainer>
        <ControlledBookSelect
          defaultBookSelection={defaultBookSelectionRef.current}
        />
      </HierarchyContainer>
      <DateRangeSelection
        name="tradeDateRange"
        startDateLabel="Trade Date (From)"
        endDateLabel="To"
      />
      <InputContainer>
        <ControlledInputBase
          name="instrumentIds"
          render={({ ref: _ref, onChange, ...rest }) => (
            <InstrumentMultiSelect
              {...rest}
              label="Instrument(s)"
              onChange={(instruments: InstrumentInfo[] | null) => {
                onChange(instruments?.map((instrument) => instrument.id));
              }}
            />
          )}
        />
      </InputContainer>
      <InputContainer>
        <ControlledFinancialSubTypeSelect
          name="instrumentTypes"
          selectProps={{ label: "Instrument type" }}
        />
      </InputContainer>

      {/* need api end point to get options */}
      <InputContainer>
        <ControlledInputBase
          name="statusIds"
          render={({ onChange, ...rest }) => (
            <MultiSelect
              {...rest}
              label="Trade Status"
              options={TRADE_STATUS_OPTIONS}
              placeholder="Any"
              onChange={onChange}
            />
          )}
        />
      </InputContainer>

      <ControlledCheckbox
        name="negateTradeStatus"
        label="Exclude Selected Statuses"
        labelPlacement="right"
        nullValue={false}
        style={{ alignSelf: "flex-end" }}
      />

      {/* need api end point to get options */}
      <InputContainer>
        <ControlledInputBase
          name="bookingStatusIds"
          render={({ onChange, ...rest }) => (
            <MultiSelect
              {...rest}
              label="Trade Booking Status"
              options={IGNORED_TRADE_BOOKING_STATUS_OPTIONS}
              placeholder="Any"
              onChange={onChange}
            />
          )}
        />
      </InputContainer>
      <ControlledMaxResultsInput />
    </>
  );
};

export default TradeBlotterQuickFilter;
