import { CashModeOptions } from "@enfusion-ui/types";
import { ControlledEmpty, ControlledSelect } from "@enfusion-ui/web-components";
import * as React from "react";
import { useFormContext } from "react-hook-form";

const CashModeSelect = () => {
  const { setValue, watch } = useFormContext();

  const { accountingMethod, cashMode } = watch([
    "accountingMethod",
    "cashMode",
  ]);

  React.useEffect(() => {
    if (cashMode === "SettleDate") {
      setValue("accountingMethod", "CashBasis");
    } else if (cashMode === "TradeDate") {
      setValue("accountingMethod", "AccrualBasis");
    }
  }, [cashMode]);

  React.useEffect(() => {
    if (accountingMethod === "CashBasis") {
      setValue("cashMode", "SettleDate");
    } else if (accountingMethod === "AccrualBasis") {
      setValue("cashMode", "TradeDate");
    }
  }, [accountingMethod]);

  return (
    <>
      <ControlledEmpty name="accountingMethod" />
      <ControlledSelect
        name="cashMode"
        label="Cash Mode"
        options={CashModeOptions}
        clearable={false}
        isMulti={false}
        inputId="cash-mode-selection-id"
      />
    </>
  );
};

export default CashModeSelect;
