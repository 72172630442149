import { DiffReportSyncRow } from "@enfusion-ui/types";
import { formatNumber } from "@enfusion-ui/utils";
import { Options } from "highcharts";
import { isEmpty } from "lodash";
import React from "react";

import { formatterCreator } from "../components/SizedChart";
import { GraphBaseField } from "../types";
import { getColId } from "./commonUtils";

const setFilterState = (
  setGraphFilterState: React.Dispatch<
    React.SetStateAction<Record<string, Record<GraphBaseField, string>>>
  >,
  baseField: GraphBaseField,
  reconRowId: string,
  series: any
) => {
  setGraphFilterState((currentState) => {
    if (currentState[reconRowId]?.[baseField] !== series.name) {
      return {
        ...currentState,
        [reconRowId]: {
          ...currentState[reconRowId],
          [baseField]: series.name,
        },
      };
    } else {
      const currentFilterState = { ...currentState };
      const rowFilterState = currentState[reconRowId];
      delete rowFilterState[baseField];

      if (isEmpty(rowFilterState)) {
        delete currentFilterState[reconRowId];
        return currentFilterState;
      }
      return {
        ...currentState,
        [reconRowId]: { ...rowFilterState },
      };
    }
  });
};

const pieChartOptions = (
  baseField: GraphBaseField,
  setGraphFilterState: React.Dispatch<
    React.SetStateAction<Record<string, Record<GraphBaseField, string>>>
  >,
  reconRowId: string
): Options => ({
  chart: {
    backgroundColor: "var(--background-color-2)",
    animation: false,
    spacing: [0, 0, 5, 0],
    type: "pie",
    events: {
      load: () => {
        setGraphFilterState((current) => {
          const currentFilterState = { ...current };
          delete currentFilterState[reconRowId];
          return currentFilterState;
        });
      },
    },
  },
  title: {
    text: "",
  },
  credits: { enabled: false },
  tooltip: {
    hideDelay: 0,
    backgroundColor: "var(--background-color-2)",
    style: { color: "var(--text-normal)" },
    formatter: formatterCreator(
      ({ color, point, y }) =>
        `<span style={{color: ${color}}}>${point.name}</span>: ${y}`
    ),
  },
  plotOptions: {
    pie: {
      borderRadius: 5,
      dataLabels: {
        format: "{point.percentage:.2f}%",
        distance: -20,
      },

      showInLegend: true,
      innerSize: "60%",
      size: "100%",
      borderColor: "var(--background-color-2)",
    },
    series: {
      allowPointSelect: true,
      events: {
        click: (params: any) => {
          const { options } = params.point;
          setFilterState(setGraphFilterState, baseField, reconRowId, options);
        },
      },
    },
  },
  legend: {
    padding: 0,
    symbolPadding: 2,
    itemDistance: 10,
    maxHeight: 50,
    labelFormatter: formatterCreator(({ y, name }) => {
      return `<div><div>${name}</div> <div>(${y})</div></div>`;
    }),
  },
});

const stackedBarChartOptions = (
  baseField: GraphBaseField,
  setGraphFilterState: React.Dispatch<
    React.SetStateAction<Record<string, Record<GraphBaseField, string>>>
  >,
  reconRowId: string
): Options => ({
  chart: {
    type: "bar",
    backgroundColor: "var(--background-color-2)",
    animation: false,
    spacing: [0, 0, 0, 0],
    events: {
      load: () => {
        setGraphFilterState((current) => {
          const currentFilterState = { ...current };
          delete currentFilterState[reconRowId];
          return currentFilterState;
        });
      },
    },
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    hideDelay: 0,
    backgroundColor: "var(--background-color-2)",
    style: { color: "var(--text-normal)" },
    formatter: formatterCreator(
      ({ color, series, y }) =>
        `
    <div style="display: flex; flex-direction: row; gap: var(--spacing-s); font-size: 14px; line-height: 18px;">
      <div style="color: ${color};">
        ${series.name}
      </div>
      :
      <div style="font-weight: bold;">${y}</div>
    </div>`
    ),
  },
  xAxis: {
    lineWidth: 0,
    gridLineWidth: 0,
    tickWidth: 0,
    title: { text: null },
    labels: { enabled: false },
  },
  yAxis: {
    lineWidth: 0,
    gridLineWidth: 0,
    title: {
      text: null,
    },
    labels: {
      enabled: false,
    },
  },
  legend: {
    reversed: true,
    width: "100%",
    labelFormatter: formatterCreator(({ visible, color, name, options }) => {
      const nameColor = visible ? color : "var(--text-muted)";
      const valueColor = visible ? "var(--text-normal)" : "var(--text-muted)";
      return `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center">
      <div style="color: ${nameColor}">${name}</div> <p style="color:${valueColor}">(${
        typeof ((options as Options).data as Array<any>)[0] === "number"
          ? (options as Options).data
          : [((options as Options).data as Array<any>)[0].y] ?? ""
      })</p></div>`;
    }),
  },
  plotOptions: {
    series: {
      allowPointSelect: true,
      events: {
        click: (params: any) => {
          const { series } = params.point;
          setFilterState(setGraphFilterState, baseField, reconRowId, series);
        },
      },
    },
    bar: {
      borderWidth: 0,
      borderRadius: {
        radius: 5,
        scope: "stack",
        where: "all",
      },
      pointWidth: 21,
      stacking: "normal",
      dataLabels: {
        enabled: true,
        format: "{point.percentage:.2f}%",
      },
    },
  },
});

const columnChartOptions = (
  baseField: GraphBaseField,
  setGraphFilterState: React.Dispatch<
    React.SetStateAction<Record<string, Record<GraphBaseField, string>>>
  >,
  reconRowId: string,
  yAxisTitle?: string
) =>
  ({
    chart: {
      backgroundColor: "var(--background-color-2)",
      animation: false,
      spacing: [5, 0, 5, 0],
      type: "column",
      events: {
        load: () => {
          setGraphFilterState((current) => {
            const currentFilterState = { ...current };
            delete currentFilterState[reconRowId];
            return currentFilterState;
          });
        },
      },
    },
    title: {
      text: "",
    },
    credits: { enabled: false },
    tooltip: {
      outside: true,
      followPointer: true,
      backgroundColor: "var(--background-color-2)",
      style: { color: "var(--text-normal)" },
      formatter: formatterCreator((params) => {
        const { color, y, series } = params;
        return `<span style={{color: ${color}}}>${
          series.userOptions.name
        }</span>: ${formatNumber(y, { fractionDigits: 2 })}`;
      }),
    },
    yAxis: {
      title: { text: yAxisTitle },
      endOnTick: false,
      gridLineWidth: 1,
      labels: {
        enabled: true,
      },
    },
    xAxis: {
      lineWidth: 0,
      gridLineWidth: 0,
      tickWidth: 0,
      labels: { enabled: false },
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        events: {
          click: (params: any) => {
            const { series } = params.point;
            setFilterState(setGraphFilterState, baseField, reconRowId, series);
          },
        },
      },
      column: {
        showInLegend: true,
        padding: 0,
        borderColor: "var(--background-color-2)",
      },
    },
    legend: {
      margin: 20,
      padding: 0,
      symbolPadding: 2,
      itemDistance: 10,
      maxHeight: 50,
      labelFormatter: formatterCreator((params) => {
        const { name } = params;
        return `<div>${name}</div>`;
      }),
    },
  } as Options);

export const getChartOptions = (
  width: number,
  height: number,
  type: string,
  data:
    | Array<{ name: string; y: number }>
    | Array<{ name: string; data: number[] }>,
  baseField: GraphBaseField,
  setGraphFilterState: React.Dispatch<
    React.SetStateAction<Record<string, Record<GraphBaseField, string>>>
  >,
  id: string,
  yAxisTitle?: string
) => {
  if (type === "pie") {
    return {
      ...pieChartOptions(baseField, setGraphFilterState, id),
      chart: {
        ...pieChartOptions(baseField, setGraphFilterState, id).chart,
        width: width || 10,
        height: height || 10,
      },
      series: [
        {
          data: data,
        },
      ],
    };
  } else if (type === "stacked bar") {
    return {
      ...stackedBarChartOptions(baseField, setGraphFilterState, id),
      chart: {
        ...stackedBarChartOptions(baseField, setGraphFilterState, id).chart,
        width: width || 5,
        height: height || 5,
      },
      series: data,
    };
  } else if (type === "column") {
    return {
      ...columnChartOptions(baseField, setGraphFilterState, id, yAxisTitle),
      chart: {
        ...columnChartOptions(baseField, setGraphFilterState, id, yAxisTitle)
          .chart,
        width: width || 10,
        height: height || 10,
      },
      series: data,
    };
  }
};

export const getBreaksGraphData = (
  data: DiffReportSyncRow[],
  columnName: string
) => {
  const countMap = new Map();
  data.forEach((row) => {
    const columnValue =
      row.columnValues[getColId(row.columnValues, columnName)]?.value;
    if (columnValue) {
      if (countMap.has(columnValue)) {
        countMap.set(columnValue, countMap.get(columnValue) + 1);
      } else {
        countMap.set(columnValue, 1);
      }
    }
  });
  let result: Array<{ name: string; y: number }> = [];
  countMap.forEach((value, key) => {
    result.push({ name: key, y: value });
  });

  return result;
};
