import { useEverTrue } from "@enfusion-ui/hooks";
import {
  ColumnGrid,
  ControlledEmpty,
  FormController,
  FormElementHeader,
  TertiaryTabList,
} from "@enfusion-ui/web-components";
import { styled, useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

import { ElectronicExecutionDetails } from "./ElectronicExecutionDetails";
import ElectronicLimitDetailEntry from "./ElectronicLimitDetailEntry";
import VoiceDetailEntry from "./VoiceDetailEntry";

const defaultExecutionMethod = "Electronic";

const ExecutionMethodSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailTitle = styled.div`
  justify-self: center;
  align-self: center;
  font-weight: bold;
  font-size: 1rem;
  margin-top: 4px;
`;

export type ExecutionMethodDetailPanelProps = {
  disabled?: boolean;
  show?: boolean;
};

const ExecutionMethodDetailPanel: React.FC<ExecutionMethodDetailPanelProps> = ({
  disabled,
}) => {
  const webOrderType = useWatch<string>({ name: "webOrderType" });
  const isMobile = useIsContentMobile();

  const electronicEverTrue = useEverTrue(webOrderType === "Electronic");
  const voiceEverTrue = useEverTrue(webOrderType === "Voice");

  return (
    <DetailPanelContainer>
      {webOrderType ? (
        <ColumnGrid
          numColumns={webOrderType === "Undecided" ? 1 : 2}
          isMobile={isMobile}
        >
          <ControlledEmpty name="counterpartyId" defaultValue="" />
          <div>
            <FormElementHeader style={{ margin: "1.5rem 0 0.75rem" }}>
              {webOrderType === "Undecided"
                ? "Optional Electronic Limit Details"
                : webOrderType === "Electronic"
                ? "Electronic Limit Details"
                : webOrderType === "Voice"
                ? "Additional Order Details"
                : ""}
            </FormElementHeader>
            <ElectronicLimitDetailEntry
              disabled={disabled}
              singular={webOrderType !== "Undecided"}
            />
          </div>
          {electronicEverTrue || voiceEverTrue ? (
            <div>
              {electronicEverTrue && (
                <ElectronicExecutionDetails
                  show={webOrderType === "Electronic"}
                  disabled={disabled}
                />
              )}
              {voiceEverTrue && (
                <VoiceDetailEntry
                  show={webOrderType === "Voice"}
                  disabled={disabled}
                />
              )}
            </div>
          ) : null}
        </ColumnGrid>
      ) : (
        <DetailPanelContainer>
          <DetailTitle>No execution method selected</DetailTitle>
        </DetailPanelContainer>
      )}
    </DetailPanelContainer>
  );
};

export type ExecutionMethodSelectionProps = {
  hideUndecided?: boolean;
  disableMethodSelection?: boolean;
  disableAllInputs?: boolean;
};

const tabs = [
  { key: "Undecided", label: "Parent (Undecided)" },
  { key: "Electronic", label: "Electronic" },
  { key: "Voice", label: "Voice" },
];

export const WebOrderTypeSelection: React.FC<{
  hideUndecided?: boolean;
  disabled?: boolean;
}> = ({ hideUndecided, disabled }) => (
  <FormController
    name="webOrderType"
    inputId="web-order-type-id"
    render={({ value, onChange }) => (
      <TertiaryTabList
        disabled={disabled}
        tabs={
          hideUndecided ? tabs.filter(({ key }) => key !== "Undecided") : tabs
        }
        value={value}
        onSelect={onChange}
        scrollToView={false}
      />
    )}
    defaultValue={defaultExecutionMethod}
  />
);
const ExecutionMethodSelection: React.FC<ExecutionMethodSelectionProps> = ({
  hideUndecided,
  disableMethodSelection,
  disableAllInputs,
}) => (
  <ExecutionMethodSelectionContainer>
    <WebOrderTypeSelection
      disabled={disableMethodSelection || disableAllInputs}
      hideUndecided={hideUndecided}
    />
    <ExecutionMethodDetailPanel disabled={disableAllInputs} />
  </ExecutionMethodSelectionContainer>
);

export default React.memo(ExecutionMethodSelection);
