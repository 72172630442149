import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import ProgressBar from "./ProgressBar";

const ContentProgressContainer = styled.div`
  color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`;

const ContentProgressText = styled.div`
  font-size: 0.75em;
  font-weight: bold;
  margin-top: 8px;
`;

export type ContentProgressProps = {
  value: number;
  total: number;
  message: string;
};

function ContentProgress({ value, total, message }: ContentProgressProps) {
  return (
    <ContentProgressContainer>
      <ProgressBar value={value} total={total} />
      <ContentProgressText>{message}</ContentProgressText>
    </ContentProgressContainer>
  );
}

export default ContentProgress;
