import { useModalState } from "__mocks__/@enfusion-ui/hooks";
import {
  ColumnSelectCellEditor,
  TrashIconButtonCellRenderer,
} from "@app-components/cells";
import { RoundedDataGrid } from "@app-components/DataGrid";
import { useFormTableListContext } from "@app-utils/useFormTableListContext";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  DocumentAttachmentType,
  documentAttachmentTypeOptions,
  useTradeTicket,
} from "@enfusion-ui/trades";
import { getSelectOption } from "@enfusion-ui/utils";
import { ControlledEmpty, FormPanel } from "@enfusion-ui/web-components";
import { useGridApi } from "@enfusion-ui/web-core";
import {
  CellValueChangedEvent,
  ColDef,
  GridReadyEvent,
  IRowNode,
  ValueFormatterParams,
} from "ag-grid-community";
import * as React from "react";
import { useWatch } from "react-hook-form";

import {
  FullHeightContainer,
  TabContentContainer,
  TicketTabHeaderButton,
} from "./components/styledComponents";
import { TradeTicketDocumentAddModal } from "./components/TradeTicketDocumentAddModal";

const columnDefs: ColDef[] = [
  {
    headerName: "",
    field: "selection",
    width: 40,
    pinned: "left",
    lockPinned: true,
    resizable: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: "Name",
    field: "name",
    initialWidth: 120,
    editable: true,
  },
  {
    headerName: "Type",
    field: "attachmentType",
    initialWidth: 120,
    editable: true,
    cellEditor: ColumnSelectCellEditor,
    cellEditorParams: {
      options: documentAttachmentTypeOptions,
    },
    valueFormatter: ({ value }: ValueFormatterParams) =>
      getSelectOption(
        documentAttachmentTypeOptions,
        value as DocumentAttachmentType
      )?.label ?? "",
  },
  {
    headerName: "ID",
    field: "id",
    initialWidth: 120,
  },
  {
    width: 40,
    headerName: "",
    field: "delete",
    pinned: "right",
    lockPinned: true,
    resizable: false,
    type: "removeAction",
    cellStyle: { padding: "0" },
  },
];

export const DocumentsTab: React.FC = () => {
  const { open, openModal, closeModal } = useModalState();

  const { onGridReady } = useGridApi((e: GridReadyEvent) => {
    e.api.sizeColumnsToFit();
  });

  const { documents } = useWatch({ name: ["documents"] });
  const { documentsControl } = useTradeTicket();

  const onCellValueChanged = useRefCallback(
    ({ data }: CellValueChangedEvent) => {
      documentsControl.modifyRow(data.__row_id, data);
    },
    []
  );

  const onClickTrashIconCell = useRefCallback((node: IRowNode) => {
    documentsControl.deleteRows([node.data.__row_id]);
  }, []);

  const getContextMenuItems = useFormTableListContext(documentsControl);

  return (
    <>
      <ControlledEmpty name="documents" />
      <TabContentContainer>
        <FormPanel
          numColumns={1}
          style={{ height: "100%" }}
          contentStyle={{ height: "100%" }}
        >
          <FullHeightContainer data-e2e-id="documents-tab">
            <TicketTabHeaderButton
              primary
              onClick={openModal}
              data-e2e-id="trade-new-doc-btn"
              data-testid="tt-documents-tab-add-button"
            >
              New Document
            </TicketTabHeaderButton>

            <RoundedDataGrid
              height="100%"
              singleClickEdit
              suppressCellFocus
              rowData={documents}
              multiSortKey="ctrl"
              columnDefs={columnDefs}
              rowSelection="multiple"
              onGridReady={onGridReady}
              suppressRowClickSelection
              stopEditingWhenCellsLoseFocus
              getRowId={documentsControl.getRowId}
              onCellValueChanged={onCellValueChanged}
              getContextMenuItems={getContextMenuItems}
              defaultColDef={{
                sortable: true,
                resizable: true,
                suppressMovable: true,
              }}
              columnTypes={{
                removeAction: {
                  cellRenderer: TrashIconButtonCellRenderer,
                  cellRendererParams: {
                    onClick: onClickTrashIconCell,
                  },
                },
              }}
            />
          </FullHeightContainer>
        </FormPanel>
      </TabContentContainer>

      <TradeTicketDocumentAddModal
        open={open}
        onClose={closeModal}
        onSubmit={documentsControl.addRow}
      />
    </>
  );
};
