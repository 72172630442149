import { FilterButton } from "@app-components/control/FilterButton";
import AccountTree from "@app-components/inputs/account/AccountTree";
import { useAccounts } from "@app-context/accounts/context";
import { EMPTY_ARRAY, ThemeDefinition } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { NodeData } from "@enfusion-ui/types";
import { Checkbox, Portal, TextInput } from "@enfusion-ui/web-components";
import {
  getCss,
  reduceSelectedNodes,
  styled,
  useThemeBase,
} from "@enfusion-ui/web-core";
import { faChevronDown, faSlidersH } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual, orderBy } from "lodash";
import * as React from "react";
import { FieldErrors } from "react-hook-form";

const FilterButtonWrapper = styled.div`
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  margin-left: var(--spacing);
  transform: rotate(90deg);
`;

const FilterMenu = styled.div`
  height: 100px;
  min-width: 11rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: var(--spacing);
`;

const OptionsContainer = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--background-color-1);
  max-height: 150px;
`;

const MultiInputContainer = styled.div`
  min-width: 250px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const ThreeDots = styled.div`
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--text-normal);
  color: var(--text-normal);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  ::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: -8px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: var(--text-normal);
    color: var(--text-normal);
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }

  ::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 8px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: var(--text-normal);
    color: var(--text-normal);
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: var(--text-normal);
    }
    50%,
    100% {
      background-color: var(--text-muted);
    }
  }
`;

export type AccountSelectProps = {
  values?: string[];
  onChange: (values: NodeData[]) => void;
  label?: string;
  inline?: boolean;
  name?: string;
  errors?: FieldErrors;
  required?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  multiSelect?: boolean;
  checkSelections?: boolean;
  allowClickOutSide?: boolean;
  onClick?: (node: NodeData) => void;
  showFundsOnly?: boolean;
};

type Filters = {
  filterExternal: boolean;
  filterInactive: boolean;
  filterEmpty: boolean;
  filterAccounts: boolean;
};

const AccountSelect: React.FC<AccountSelectProps> = React.memo(
  function AccountSelect({
    onChange,
    name,
    errors,
    values = EMPTY_ARRAY,
    label = "Accounts",
    inline,
    required,
    disabled,
    clearable,
    multiSelect = true,
    checkSelections = true,
    allowClickOutSide = false,
    onClick,
    showFundsOnly = false,
  }: AccountSelectProps) {
    const [selectedValues, setSelectedValues] =
      React.useState<string[]>(values);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [selectedAccountText, setSelectedAccountText] =
      React.useState<string>();
    const [filterMenuOpen, setFilterMenuOpen] = React.useState(false);
    const [displayId, setDisplayId] = React.useState<boolean>(false);
    const [displayShortName, setDisplayShortName] =
      React.useState<boolean>(false);

    const ref = React.useRef(null);
    const filterRef = React.useRef(null);
    const theme = useThemeBase();

    const { loading, nodes } = useAccounts();

    const [filters, setFilters] = React.useState<Filters>({
      filterExternal: true,
      filterInactive: true,
      filterEmpty: true,
      filterAccounts: showFundsOnly,
    });

    const setSelectedValuesInternal = useRefCallback(
      (ids: string[]) => {
        if (!isEqual(ids.sort(), selectedValues.sort())) {
          setSelectedValues(ids);
        }
      },
      [selectedValues]
    );

    React.useEffect(() => {
      setSelectedValuesInternal(values);
    }, [JSON.stringify(values)]);

    const handleInputChange = (
      e: React.ChangeEvent<HTMLInputElement> | null
    ) => {
      setMenuOpen(true);
      setSearchTerm(e?.target.value || "");
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement> | null) => {
      if (e?.key === "Enter") {
        if (allowClickOutSide) {
          e.preventDefault();
          e.stopPropagation();
        }
        closeMenu();
      }
    };

    const handleInputFocus = () => {
      setMenuOpen(true);
    };

    const closeMenu = () => {
      setMenuOpen(false);
      setSearchTerm("");
    };

    const closeFilterMenu = () => {
      setFilterMenuOpen(false);
    };

    const handleClearValue = () => {
      setSelectedValuesInternal([]);
      setSelectedAccountText("");
    };

    const handleSelectionChange = React.useCallback(
      (newSelectedNodes: Array<NodeData>) => {
        if (newSelectedNodes?.length) {
          const selectedNodes = reduceSelectedNodes(newSelectedNodes);
          const text = selectedNodes.some((node: NodeData) => node.depth === 0)
            ? "All"
            : orderBy(selectedNodes, ["depth"], ["asc"])
                .map((i) => i.name)
                .join(", ");

          onChange(selectedNodes);
          setSelectedAccountText(text);
        } else {
          onChange([]);
          setSelectedAccountText("");
        }
      },
      []
    );

    const toggleFilter = React.useCallback(
      (key: keyof Filters) => () => {
        setFilters((state) => ({ ...state, [key]: !state[key] }));
      },
      []
    );

    return (
      <>
        <MultiInputContainer>
          <input
            style={{
              width: 0,
              height: 0,
              minWidth: 0,
              minHeight: 0,
              opacity: 0,
              zIndex: -1000,
              position: "absolute",
            }}
            onFocus={() => {
              closeMenu();
            }}
            data-e2e-id={`select-input-${label
              .split(" ")
              .join("-")
              .toLowerCase()}-hidden-trigger`}
          />
          <TextInput
            ref={ref}
            title={selectedAccountText}
            label={label}
            inline={inline}
            value={menuOpen ? searchTerm : selectedAccountText}
            name={name}
            errors={errors}
            onFocus={handleInputFocus}
            onChange={handleInputChange}
            onKeyUp={handleKeyDown}
            required={required}
            disabled={disabled}
            clearable={
              (!!selectedAccountText || !!searchTerm) && !menuOpen && clearable
            }
            onClearValue={handleClearValue}
            iconInteractive={false}
            icon={
              loading || nodes === null ? (
                <ThreeDots />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="sm"
                  color="var(--text-normal)"
                />
              )
            }
            placeholder="Select..."
            minWidth="100px"
            data-e2e-id={`select-input-${label
              .split(" ")
              .join("-")
              .toLowerCase()}`}
          />
          <FilterButtonWrapper tabIndex={1} ref={filterRef}>
            <FilterButton
              title="Fund Filter"
              icon={faSlidersH}
              isFiltered={filters.filterEmpty}
              onClick={() => setFilterMenuOpen((prev) => !prev)}
              type="button"
            />
          </FilterButtonWrapper>
        </MultiInputContainer>
        <Portal
          key="filterMenuPortal"
          open={filterMenuOpen}
          attachedRef={filterRef}
          onClickOutside={closeFilterMenu}
          align="right"
        >
          <FilterMenu>
            <Checkbox
              onChange={toggleFilter("filterEmpty")}
              checked={filters.filterEmpty}
              label="Filter Empty Funds"
              labelPlacement="right"
            />
            <Checkbox
              checked={filters.filterExternal}
              onChange={toggleFilter("filterExternal")}
              label="Exclude External Accounts"
              labelPlacement="right"
            />
            <Checkbox
              checked={filters.filterInactive}
              onChange={toggleFilter("filterInactive")}
              label="Exclude Inactive Accounts"
              labelPlacement="right"
            />
            <Checkbox
              checked={filters.filterAccounts}
              onChange={toggleFilter("filterAccounts")}
              label="Exclude Accounts"
              labelPlacement="right"
            />
            <Checkbox
              onChange={() => setDisplayId((prev) => !prev)}
              checked={displayId}
              label="Display Id"
              labelPlacement="right"
            />
            <Checkbox
              onChange={() => setDisplayShortName((prev) => !prev)}
              checked={displayShortName}
              label="Display Short Name"
              labelPlacement="right"
            />
          </FilterMenu>
        </Portal>
        <Portal
          key="fileExplorerPortal"
          open={menuOpen}
          attachedRef={ref}
          onClickOutside={allowClickOutSide ? undefined : closeMenu}
        >
          <OptionsContainer
            data-e2e-id="account-select-portal"
            style={{ ...(getCss(theme as ThemeDefinition) as any) }}
          >
            <AccountTree
              values={selectedValues}
              multiSelect={multiSelect}
              checkSelections={checkSelections}
              onClick={onClick}
              onSelectionChange={handleSelectionChange}
              showId={displayId}
              showShortName={displayShortName}
              searchTerm={searchTerm}
              {...filters}
            />
          </OptionsContainer>
        </Portal>
      </>
    );
  }
);

export default AccountSelect;
