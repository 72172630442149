import { getSelectedValues } from "@enfusion-ui//utils";
import {
  ComplianceState,
  complianceStateTypeOptions,
  SelectOptionsType,
} from "@enfusion-ui/types";
import { ControlledInputBase, MultiSelect } from "@enfusion-ui/web-components";
import * as React from "react";
import { useFormContext } from "react-hook-form";

const ComplianceStateSelect = () => {
  const { setValue, watch } = useFormContext();
  const complianceStates = watch("complianceStates");

  return (
    <ControlledInputBase
      name="complianceStates"
      render={({ onChange, ...rest }) => (
        <MultiSelect
          {...rest}
          label="Compliance State"
          placeholder="All"
          options={complianceStateTypeOptions}
          value={
            complianceStates
              ? getSelectedValues<ComplianceState>(
                  complianceStates,
                  complianceStateTypeOptions as SelectOptionsType<ComplianceState>[]
                )
              : []
          }
          onChange={(selectedOption) => {
            const complianceTypes = selectedOption?.length
              ? selectedOption.map((option) => option.value as ComplianceState)
              : null;
            setValue("complianceStates", complianceTypes);
            onChange(complianceTypes);
          }}
          inputId="compliance-state"
        />
      )}
    />
  );
};

export default ComplianceStateSelect;
