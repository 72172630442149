import { DashboardRoot } from "@enfusion-ui/types";
import { getFileNameDirect } from "@enfusion-ui/utils";
import {
  CenterContent,
  Spinner,
  SVGBackground,
} from "@enfusion-ui/web-components";
import { isDesktop } from "@enfusion-ui/web-core";
import * as fdc3 from "@finos/fdc3";
import {
  BloombergConnection,
  connect,
  isBloombergTerminalReady,
} from "@openfin/bloomberg";
import queryString from "query-string";
import * as React from "react";

let connection: BloombergConnection | null = null;

type PWBMetadata = {
  fileName: string;
  root: DashboardRoot;
  filePath: string;
};

async function getConnection() {
  if (connection) return;
  if (!(await isBloombergTerminalReady())) throw new Error("Not Ready");
  connection = await connect();
}

async function viewChart() {
  try {
    await getConnection();
    const context: fdc3.Instrument = {
      type: "fdc3.instrument",
      name: "Microsoft Corporation",
      id: {
        ticker: "MSFT",
      },
    };

    await fin.me.interop.fireIntent({
      name: fdc3.Intents.ViewChart,
      context,
    });
  } catch (err) {
    console.error("viewChart err", err);
  }
}

function openPortfolioWorkbench(workbenchMetadata: PWBMetadata) {
  const { root, fileName } = workbenchMetadata;

  const destinationUrl = queryString.stringifyUrl({
    url: `${window.location.origin}/linking`,
    query: {
      frame: true,
      portfolio: btoa(JSON.stringify({ p: `${root}/${fileName}`, r: root })),
    },
  });

  const titleWorkbenchName = getFileNameDirect(fileName);

  setTimeout(
    () =>
      window.open(
        destinationUrl,
        "_blank",
        "noopener,name=" + titleWorkbenchName
      ),
    500
  );
}

if (isDesktop()) {
  fin.InterApplicationBus.subscribe(
    { uuid: "*" },
    "view-chart",
    (submsg: string) => {
      console.log("submsg: ", submsg);
      viewChart();
    }
  );

  fin.InterApplicationBus.subscribe(
    { uuid: "*" },
    "portfolio-workbench",
    (pwbMetadata: PWBMetadata) => {
      console.log("Workbench Metadata: ", pwbMetadata);
      if (pwbMetadata) {
        openPortfolioWorkbench(pwbMetadata);
      }
    }
  );
}

export const AppBus: React.FC<unknown> = () => {
  return (
    <SVGBackground>
      <CenterContent>
        <Spinner />
        Inter App Bus
      </CenterContent>
    </SVGBackground>
  );
};
