import { useRefCallback } from "@enfusion-ui/hooks";
import {
  Column,
  GridApi,
  GridReadyEvent,
  IRowNode,
  RowClassParams,
} from "ag-grid-community";
import { debounce, get, noop, sortBy } from "lodash";
import * as React from "react";

import { contractOrExpandParentRows } from "./gridRowExpand";
import {
  GeneralSearchDirection,
  GeneralSearchFilterMode,
  useGeneralSearchEvents,
} from "./useGeneralSearchEvents";

function showRow(api: GridApi, rowNodes: IRowNode[]) {
  contractOrExpandParentRows(rowNodes[0], true);
  requestAnimationFrame(() => {
    api.ensureNodeVisible(rowNodes[0], "middle");
  });
}

function getValue(column: Column, node: IRowNode, gridApi: GridReadyEvent) {
  const colDef = column.getColDef();

  const field = colDef.field;
  const vGetter = colDef.valueGetter;
  const vFormatter = colDef.valueFormatter;

  const args = {
    node,
    data: node.data,
    column,
    colDef,
    getValue: noop,
    ...gridApi,
  };

  let value: any = undefined;
  if (typeof vGetter !== "undefined") {
    if (typeof vGetter === "string") {
      value = get(node.data || {}, vGetter);
    } else {
      value = vGetter({ ...args });
    }
  } else if (typeof field !== "undefined") {
    value = get(node.data || {}, field);
  }

  if (typeof vFormatter !== "undefined") {
    if (typeof vFormatter === "string") {
      value = get(value || {}, vFormatter);
    } else {
      value = vFormatter({ ...args, value });
    }
  }

  return value;
}

export const useGeneralSearchGrid = (
  key: string,
  gridRef: React.MutableRefObject<GridReadyEvent | undefined>
) => {
  const matchedRows = React.useRef<IRowNode[]>([]);
  const caseMatchRef = React.useRef(true);
  const filterModeRef = React.useRef<GeneralSearchFilterMode>("highlight");
  const quickFilterTextRef = React.useRef("");
  const currentHighlightedRowIndex = React.useRef<number>(-1);

  const debouncedSearchTextChanged = React.useMemo(
    () =>
      debounce(
        (
          value: string,
          matchCase: boolean,
          filterMode: GeneralSearchFilterMode,
          changeResultCount: (count: number) => void,
          changeResultIndex: (idx: number) => void
        ) => {
          filterModeRef.current = filterMode;
          const textChange = quickFilterTextRef.current !== value;
          quickFilterTextRef.current = value;
          const caseChange = caseMatchRef.current !== matchCase;
          caseMatchRef.current = matchCase;
          // save previously matched rows
          const oldMatchedRows: IRowNode[] = [...matchedRows.current];

          // filter the rows based on search text
          matchedRows.current = [];
          currentHighlightedRowIndex.current = 0;

          if (
            (textChange || caseChange) &&
            filterMode === "filter" &&
            value !== ""
          ) {
            gridRef.current?.api.setGridOption("quickFilterText", "");
          }

          gridRef.current?.api.setGridOption(
            "quickFilterText",
            filterMode === "filter" ? value : "VALID"
          );

          const text = matchCase ? value : value.toLocaleLowerCase();
          if (value.length > 0) {
            const cols = gridRef.current?.api.getColumns() ?? [];
            if (cols.length > 0) {
              gridRef.current?.api.forEachNodeAfterFilter((node) => {
                if (!node.group) {
                  for (const column of cols) {
                    const columnVal = getValue(column, node, gridRef.current!);
                    if (
                      typeof columnVal !== "undefined" &&
                      columnVal !== null
                    ) {
                      let checkVal = `${columnVal}`;
                      if (!matchCase) checkVal = checkVal.toLocaleLowerCase();
                      if (checkVal.includes(text)) {
                        matchedRows.current.push(node);
                        break;
                      }
                    }
                  }
                }
              });
            }
          }

          // redraw the rows - this will invoke getRowClass callback
          if (matchedRows.current.length > 0) {
            matchedRows.current = sortBy(matchedRows.current, ["id"]);
            gridRef.current?.api.redrawRows({
              rowNodes: [...oldMatchedRows, matchedRows.current[0]],
            });
            if (gridRef.current)
              showRow(gridRef.current.api, [matchedRows.current[0]]);
          } else {
            gridRef.current?.api.redrawRows({
              rowNodes: [...oldMatchedRows],
            });
          }

          changeResultCount(matchedRows.current.length);
          changeResultIndex(currentHighlightedRowIndex.current);
        },
        800
      ),
    []
  );

  const navigateToNextMatchedRows = useRefCallback(
    (
      direction: GeneralSearchDirection,
      changeResultIndex: (idx: number) => void
    ) => {
      const current = currentHighlightedRowIndex.current;
      const rowCount = matchedRows.current.length;
      if (rowCount > 0) {
        const currentSelection = matchedRows.current[current];

        if (direction === "up") {
          if (current === 0) currentHighlightedRowIndex.current = rowCount - 1;
          else currentHighlightedRowIndex.current = current - 1;
        } else {
          if (current === rowCount - 1) currentHighlightedRowIndex.current = 0;
          else currentHighlightedRowIndex.current = current + 1;
        }

        const nextSelection =
          matchedRows.current[currentHighlightedRowIndex.current];

        requestAnimationFrame(() => {
          if (nextSelection)
            gridRef.current?.api.redrawRows({
              rowNodes: currentSelection
                ? [currentSelection, nextSelection]
                : [nextSelection],
            });
          if (gridRef.current) showRow(gridRef.current!.api, [nextSelection]);
          changeResultIndex(currentHighlightedRowIndex.current);
        });
      }
    },
    []
  );

  const handleNextMatch = useRefCallback(
    (changeResultIndex: (idx: number) => void) =>
      navigateToNextMatchedRows("down", changeResultIndex),
    []
  );

  const quickFilterParser = useRefCallback(() => {
    return ["VALID"];
  }, []);

  const quickFilterMatcher = useRefCallback(
    (parts: string[], content: string) => {
      if (filterModeRef.current === "highlight") return true;
      let c = (v: string) => (caseMatchRef.current ? v : v.toLocaleLowerCase());
      let text = c(content);
      let part = c(quickFilterTextRef.current);
      return text.includes(part);
    },
    []
  );

  const getRowClass = useRefCallback(({ node }: RowClassParams) => {
    const highlightRow =
      matchedRows.current[currentHighlightedRowIndex.current];
    if (!!highlightRow && node.id === highlightRow.id) {
      return "grid-highlighted-row";
    }
    return "";
  }, []);

  useGeneralSearchEvents(key, {
    onTextChange: debouncedSearchTextChanged,
    onNavigateMatch: navigateToNextMatchedRows,
    onNextMatch: handleNextMatch,
  });

  return React.useMemo(
    () => ({
      gridProps: {
        includeHiddenColumnsInQuickFilter: true,
        cacheQuickFilter: true,
        quickFilterParser,
        quickFilterMatcher,
        getRowClass,
      },
    }),
    [quickFilterParser, quickFilterMatcher, getRowClass]
  );
};
