import {
  CenterContent,
  ContentMessage,
  FactSetLogo,
  Spinner,
  TabTopActionBar,
  ViewContainer,
  ViewContent,
  WidthHeight100,
} from "@enfusion-ui/web-components";
import { IS_PROD, styled, useAuth, useThisTab } from "@enfusion-ui/web-core";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import * as React from "react";

const FactSetLogoContainer = styled.div`
  height: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: var(--spacing);
  font-size: 10px;
`;

const aspectRatio = 5.14;
const height = 12;
const FactSetLogoInnerContainer = styled.div`
  height: ${height}px;
  width: ${aspectRatio * height}px;
  margin-left: 5px;
`;

const ServiceFileActionBar = styled(TabTopActionBar)`
  justify-content: flex-end;
`;

const FactSetView: React.FC<{ slug: "market-watch" | "full-quote" }> = ({
  slug,
}) => {
  const { user, isEnabled } = useAuth();
  const tab = useThisTab();

  React.useEffect(() => {
    if (user && !isEnabled("FactSet")) {
      tab.closeTab();
    }
  }, [user]);

  return (
    <ViewContainer>
      <ServiceFileActionBar>
        <FactSetLogoContainer>
          Powered By
          <FactSetLogoInnerContainer>
            <FactSetLogo />
          </FactSetLogoInnerContainer>
        </FactSetLogoContainer>
      </ServiceFileActionBar>
      <ViewContent style={{ flex: 1 }}>
        {user ? (
          !IS_PROD ? (
            <CenterContent>
              <ContentMessage
                icon={faQuestionCircle}
                message="Factset views are not loaded in UAT"
              />
            </CenterContent>
          ) : (
            <WidthHeight100
              as="iframe"
              src={`https://enfusion.factset.com/views/components/${slug}/?idpid=${
                user.flags?.FactSet.idpId || ""
              }`}
              frameBorder={0}
            />
          )
        ) : (
          <CenterContent>
            <Spinner />
          </CenterContent>
        )}
      </ViewContent>
    </ViewContainer>
  );
};

export default FactSetView;
