import { createContext, useContext } from "react";

export type SessionContextState = {
  sessionExpired: boolean;
  logout: () => void | undefined;
  resetTimer: () => void | undefined;
  setEnabled: (enabled: boolean) => void;
  setTimeoutValue: (timeout: number) => void;
  timeout: number;
  enabled: boolean;
};

export const SessionContext = createContext<SessionContextState | undefined>(
  undefined
);

export function useSession() {
  const context = useContext<SessionContextState | undefined>(SessionContext);
  if (context === undefined) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
}
