import { NodeData } from "@enfusion-ui/types";
import * as React from "react";

export type AccountsContextState = {
  nodes: NodeData[] | null;
  loading: boolean;
  error: Error | null;
};

export const AccountsContext = React.createContext<
  AccountsContextState | undefined
>(undefined);

export function useAccounts() {
  const context = React.useContext(AccountsContext);
  if (typeof context === "undefined") {
    throw new Error("useAccounts must be used within a AccountsProvider");
  }
  return context;
}
