import { useReconciliation } from "@app-context/reconciliation/context";
import { useModalState } from "@enfusion-ui/hooks";
import {
  DiffReportSyncRow,
  FilterColumns,
  ReconciliationColumnType,
  ReconciliationFilterState,
  SelectOptionsType,
} from "@enfusion-ui/types";
import { getSelectOption } from "@enfusion-ui/utils";
import {
  AlignRightRow,
  IconButton,
  MultiSelect,
  Select,
} from "@enfusion-ui/web-components";
import { useAuth } from "@enfusion-ui/web-core";
import { faGear, faRotateRight } from "@fortawesome/pro-solid-svg-icons";
import { uniqBy } from "lodash";
import React from "react";

import { ReconciliationRowsStore } from "../types";
import {
  getBreaksData,
  getCashActivityReportId,
  getCashBalanceReportId,
  getReportId,
  isCashDataSource,
} from "../utils/commonUtils";
import ReconciliationDashboardSettingsModal from "./ReconciliationDashboardSettingsModal";
import { TopActionBarContainer } from "./styles";

type TopActionBarProps = {
  reconciliationRowStore: ReconciliationRowsStore;
};

const getDefaultFilterOptions = (filterState: ReconciliationFilterState) => {
  let filterOptions: Record<string, SelectOptionsType<string>[]> = {};
  Object.keys(filterState).forEach(
    (i) =>
      (filterOptions = {
        ...filterOptions,
        [i]: [] as SelectOptionsType<string>[],
      })
  );
  return filterOptions;
};

const getFilterDataset = (
  data: DiffReportSyncRow[],
  filterOptions: Record<string, SelectOptionsType<string>[]>,
  filterColumns?: FilterColumns
): Record<string, SelectOptionsType<string>[]> => {
  let options = filterOptions;
  Object.keys(filterOptions).forEach((key) => {
    const typedKey = key as unknown as keyof ReconciliationFilterState;
    const column = filterColumns?.[typedKey] as ReconciliationColumnType;
    if (column?.source || column?.target) {
      data.forEach((row) => {
        const columnValues = row.columnValues;
        const sourceColumnValue = column.source
          ? columnValues[column.source]?.value
          : undefined;
        const targetColumnValue = column.target
          ? columnValues[column.target]?.value
          : undefined;
        if (column) {
          [targetColumnValue, sourceColumnValue].forEach((value) => {
            if (value) {
              options[typedKey].push({
                label: value?.toString(),
                value: value?.toString(),
              });
            }
          });
        }
      });
      options[typedKey] = uniqBy(options[typedKey], "value");
    }
  });
  return options;
};

const TopActionBar: React.FC<TopActionBarProps> = ({
  reconciliationRowStore,
}) => {
  const settingsModalState = useModalState();
  const {
    reloadReconciliationDashboard,
    changeFilterState,
    filterState,
    config,
    reportsLoadingStatus,
    loading,
  } = useReconciliation();

  const { isAdmin } = useAuth();
  const { reconReports } = config;

  const [filterOptions, setFilterOptions] = React.useState<
    Record<string, SelectOptionsType<string>[]>
  >(getDefaultFilterOptions(filterState));

  React.useEffect(() => {
    let options = getDefaultFilterOptions(filterState);
    reconReports.forEach((config) => {
      const loadingStatus =
        reportsLoadingStatus?.[
          getReportId(config.reconReportId, config.reconciliationType)
        ];
      if (!loadingStatus) {
        if (isCashDataSource(config.reconciliationType)) {
          options = getFilterDataset(
            getBreaksData(
              reconciliationRowStore?.[
                getCashBalanceReportId(config.reconReportId)
              ]
            ),
            options,
            {
              ...config.filterColumns,
              currency: config.impactColumns?.cashBalanceCurrency,
            }
          );
          options = getFilterDataset(
            getBreaksData(
              reconciliationRowStore?.[
                getCashActivityReportId(config.reconReportId)
              ]
            ),
            options,
            config.filterColumns
          );
        } else {
          options = getFilterDataset(
            getBreaksData(reconciliationRowStore?.[config.reconReportId]),
            options,
            config.filterColumns
          );
        }
      }
    });

    setFilterOptions(options);
  }, [reconciliationRowStore, config, reportsLoadingStatus]);

  return (
    <TopActionBarContainer>
      {Object.keys(filterState).map((key) => {
        const typedKey = key as unknown as keyof ReconciliationFilterState;
        if (filterState[typedKey].type === "multi")
          return (
            <MultiSelect
              inline
              key={typedKey}
              label={filterState[typedKey].label}
              isClearable
              options={filterOptions[typedKey]}
              onChange={(selectedOptions) => {
                changeFilterState({
                  ...filterState,
                  [typedKey]: {
                    ...filterState[typedKey],
                    value: selectedOptions?.length
                      ? selectedOptions.map((option) => option.value)
                      : [],
                  },
                });
              }}
              value={
                (filterState[typedKey].value as string[]).map((eachValue) =>
                  filterOptions[typedKey].find((e) => e.value === eachValue)
                ) as SelectOptionsType<string>[]
              }
              disabled={
                typedKey === "instrument" &&
                (!filterState.currency.value ||
                  !filterState.serviceAccount.value?.length)
              }
            />
          );
        else
          return (
            <Select
              inline
              key={typedKey}
              label={filterState[typedKey].label}
              isClearable
              options={filterOptions[typedKey]}
              onChange={(e) => {
                changeFilterState({
                  ...filterState,
                  [typedKey]: {
                    ...filterState[typedKey],
                    value: e?.value,
                  },
                });
              }}
              value={getSelectOption(
                filterOptions[typedKey] ?? [],
                filterState[typedKey].value
              )}
            />
          );
      })}

      <AlignRightRow flex>
        {isAdmin() && (
          <IconButton
            hoverable
            icon={faGear}
            disabled={loading}
            size="xs"
            onClick={settingsModalState.openModal}
            title="Settings"
          />
        )}
        <IconButton
          hoverable
          icon={faRotateRight}
          disabled={loading}
          size="xs"
          onClick={reloadReconciliationDashboard}
          title="Reload"
        />
      </AlignRightRow>
      <ReconciliationDashboardSettingsModal
        isOpen={settingsModalState.open}
        closeModal={settingsModalState.closeModal}
      />
    </TopActionBarContainer>
  );
};

export default TopActionBar;
