import { ThemeDefinition } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  Button,
  CenterContent,
  PanelRow,
  Select,
  Spinner,
} from "@enfusion-ui/web-components";
import {
  styled,
  useAuth,
  useIsContentMobile,
  useTheme,
} from "@enfusion-ui/web-core";
import * as React from "react";
import { ValueType } from "react-select";

const DEFAULT_MAX_ROWS = 2;
const DEFAULT_MAX_COLS = 3;

const MOBILE_MAX_ROWS = 2;
const MOBILE_MAX_COLS = 3;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type HotButtonScheme = { id: number; name: string; [index: string]: any };

type SchemeOption<T extends HotButtonScheme> = { value: T; label: string };

type SchemeOptionsState<T extends HotButtonScheme> = {
  buttonOptions: SchemeOption<T>[];
  selectOptions: SchemeOption<T>[];
};

const defaultSchemeOptionsState = <T extends HotButtonScheme>(
  selectOptions: SchemeOption<T>[] = [] as SchemeOption<T>[]
): SchemeOptionsState<T> => ({
  buttonOptions: [],
  selectOptions,
});

const ButtonContainer = styled.div<{ rows: number; mobile: boolean }>`
  flex: 1;
  margin-right: ${({ mobile }) => (mobile ? 0 : "var(--spacing-l)")};
  margin-bottom: ${({ mobile }) => (mobile ? "var(--spacing)" : 0)};
`;

type ButtonGridProps = {
  rows: number;
  cols: number;
};

const ButtonGrid = styled.div<ButtonGridProps>`
  display: grid;

  grid-template-columns: ${({ cols }) => `repeat(${cols}, minmax(0, 1fr))`};
  grid-template-rows: ${({ rows }) => `repeat(${rows}, 1fr)`};
  grid-gap: var(--spacing-l);
`;

const getBorderColor = ({
  disabled,
  selected,
  idx,
}: {
  disabled?: boolean;
  selected?: boolean;
  idx: number;
}) => {
  if (selected && disabled) return "var(--text-normal)";
  return selected || disabled
    ? "var(--input-border)"
    : `var(--preferred-button-color-${idx % 8})`;
};

const StyledButton = styled(Button)<{
  idx: number;
  disabled?: boolean;
  theme: ThemeDefinition;
}>`
  line-height: 1.4em;
  padding: var(--spacing-s) var(--spacing);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--radius);
  transition: background-color 100ms, color 100ms, border 100ms;

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: ${({ idx }) => `var(--preferred-button-color-${idx % 8})`};
  background-color: ${({ idx }) =>
    `var(--preferred-button-bg-color-${idx % 8})`};
  border: ${(props) => `1px solid ${getBorderColor(props)}`};

  :hover {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.8)};
    background-color: ${({ disabled, idx }) =>
      !disabled
        ? `var(--preferred-button-bg-color-${idx % 8})`
        : `var(--preferred-button-color-${idx % 8})`};
    color: ${({ disabled, idx }) =>
      !disabled
        ? `var(--preferred-button-color-${idx % 8})`
        : "var(--text-inverted)"};
  }

  :active {
    opacity: 1;
    background-color: ${({ idx }) =>
      `var(--preferred-button-color-${idx % 8})`};
    color: var(--text-inverted);
  }
`;

const useButtonGridDimensions = (
  maxRows = DEFAULT_MAX_ROWS,
  maxCols = DEFAULT_MAX_COLS
) => {
  const [[rows, cols], setGridDimensions] = React.useState([
    DEFAULT_MAX_ROWS,
    DEFAULT_MAX_COLS,
  ]);
  const isMobile = useIsContentMobile();

  React.useEffect(() => {
    if (isMobile) {
      setGridDimensions([
        maxRows * maxCols < MOBILE_MAX_ROWS * MOBILE_MAX_COLS
          ? Math.ceil((maxRows * maxCols) / MOBILE_MAX_COLS)
          : MOBILE_MAX_ROWS,
        MOBILE_MAX_COLS,
      ]);
    } else {
      setGridDimensions([maxRows, maxCols]);
    }
  }, [maxRows, maxCols, isMobile]);

  return { rows, cols };
};

export type SchemeHotButtonsPanelProps<T> = {
  schemes: T[];
  maxButtonRows?: number;
  maxButtonsPerRow?: number;
  selectedScheme: T | null;
  onSchemeChange: (scheme: T | null) => void;
  noSchemesMessage: string;
  loading: boolean;
  otherSchemes?: T[];
  disabled?: boolean;
};

function createButtonHandler<T extends HotButtonScheme>(
  value: T | null,
  onChange: (args: T | null) => void
) {
  return function handleSchemeButtonClick() {
    onChange(value);
  };
}

function SchemeHotButtonsPanel<T extends HotButtonScheme>({
  schemes,
  maxButtonRows = DEFAULT_MAX_ROWS,
  maxButtonsPerRow,
  selectedScheme,
  onSchemeChange,
  noSchemesMessage,
  loading,
  otherSchemes,
  disabled,
}: SchemeHotButtonsPanelProps<T>) {
  const isMobile = useIsContentMobile();
  const { isUserType } = useAuth();
  const { theme } = useTheme();

  const [{ buttonOptions, selectOptions }, setOptions] = React.useReducer<
    React.Reducer<SchemeOptionsState<T>, SchemeOptionsState<T>>
  >(
    (prevState, newState) => ({ ...prevState, ...newState }),
    defaultSchemeOptionsState(
      otherSchemes?.map((scheme: T) => ({
        value: scheme,
        label: scheme.name,
      }))
    )
  );

  const { rows, cols } = useButtonGridDimensions(
    maxButtonRows,
    maxButtonsPerRow
  );

  const buildOptions = useRefCallback(
    (rows: number, cols: number) => {
      const newButtonOptions: SchemeOption<T>[] = [];
      let newSelectOptions: SchemeOption<T>[] = [];

      if (schemes) {
        schemes.forEach((scheme, idx: number) => {
          const option = { value: scheme, label: scheme.name };
          if (idx < rows * cols) {
            newButtonOptions.push(option);
          } else {
            newSelectOptions.push(option);
          }
        });
      }

      if (!isUserType("Express") && otherSchemes && otherSchemes.length > 0) {
        newSelectOptions = [
          ...newSelectOptions,
          ...otherSchemes.map((scheme: T) => ({
            value: scheme,
            label: scheme.name,
          })),
        ];
      }
      setOptions({
        buttonOptions: newButtonOptions,
        selectOptions: newSelectOptions,
      });
    },
    [schemes, otherSchemes, rows, cols]
  );

  const neededRows = React.useMemo(() => {
    const newRowCount = Math.max(
      Math.min(Math.ceil(buttonOptions.length / cols), rows, maxButtonRows),
      1
    );
    if (!loading) {
      buildOptions(newRowCount, cols);
    }
    return newRowCount;
  }, [
    rows,
    cols,
    maxButtonRows,
    buttonOptions.length,
    otherSchemes?.length,
    buildOptions,
    loading,
  ]);

  const handleSelectChange = useRefCallback(
    (option: ValueType<SchemeOption<T>, false>) => {
      onSchemeChange(option != null ? (option as SchemeOption<T>).value : null);
    },
    [onSchemeChange]
  );

  const selectValue = React.useMemo(
    () =>
      selectedScheme
        ? selectOptions.find(({ value }) => selectedScheme.id === value.id) ||
          null
        : null,
    [selectedScheme?.id, JSON.stringify(selectOptions)]
  );

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "var(--spacing-xl)",
      }}
    >
      <PanelRow>
        <ButtonContainer mobile={isMobile} rows={neededRows}>
          {loading ? (
            <CenterContent>
              <Spinner />
            </CenterContent>
          ) : buttonOptions.length > 0 ? (
            <ButtonGrid rows={neededRows} cols={cols}>
              {buttonOptions.map(({ label, value }, idx) => (
                <StyledButton
                  theme={theme}
                  key={label}
                  title={label}
                  idx={idx}
                  onClick={createButtonHandler(value, onSchemeChange)}
                  type="button"
                  disabled={disabled}
                >
                  {label}
                </StyledButton>
              ))}
            </ButtonGrid>
          ) : (
            <CenterContent>{noSchemesMessage}</CenterContent>
          )}
        </ButtonContainer>
      </PanelRow>
      {selectOptions.length > 0 && (
        <PanelRow>
          <div
            style={{
              flex: isMobile ? "1 0 auto" : "0 0 calc(33% - var(--spacing-l))",
            }}
          >
            <Select
              value={selectValue}
              name="additionalSchemes"
              options={selectOptions}
              onChange={handleSelectChange}
              clearable
              disabled={disabled || loading || selectOptions.length === 0}
              placeholder="All Schemes"
              inputId="additional-schemes-selection-id"
              minWidth={150}
            />
          </div>
        </PanelRow>
      )}
    </div>
  );
}

export default SchemeHotButtonsPanel;
