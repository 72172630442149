import { AccordionListView, AccordionTitle } from "@enfusion-ui/web-components";
import { defaultToast, styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { TutorialType } from "../../components/Tour/utils";
import AccountExplorer from "./AccountExplorer";

const defaultOpen = { resIns: true, resIss: true };

const StyledAccordionTitle = styled(AccordionTitle)`
  border-top-width: 1px;
  font-weight: bold;
  text-transform: uppercase;
  height: 28px;
  padding: 0 0.25rem 0 0.75rem;
  ${({ top }: { top: boolean }) => (top ? "border-top-width: 0px;" : "")}
  color: var(--primary);

  :hover {
    color: var(--primary-hover);
  }
`;

const createAccountExplorer = (tutorial?: TutorialType | null) =>
  React.memo(function AccountExplorerContainer({ open }: { open: boolean }) {
    return <AccountExplorer open={open} tutorial={tutorial} />;
  });

const createAccordionAction = (text: string, onClick: VoidFunction) =>
  React.memo(function AccordionAction({ top }: { top: boolean }) {
    return (
      <StyledAccordionTitle
        title={text}
        open={false}
        onClick={onClick}
        top={top}
      />
    );
  });

const items = [
  {
    key: "resIns",
    title: "Restricted Instruments",
    component: createAccordionAction("Restricted Instruments", () => {
      defaultToast("TODO open tab");
    }),
  },
  {
    key: "resIss",
    title: "Restricted Issuers",
    contentComponent: createAccountExplorer(),
  },
];

const ComplianceSidebarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ReportSidebarTitle = styled.div`
  height: 40px;
  padding: 0 0.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const ComplianceSidebar: React.FC<{ tutorial?: TutorialType | null }> = ({
  tutorial,
}) => {
  return (
    <ComplianceSidebarContainer
      data-tutorial-step={
        tutorial ? `${tutorial}__compliance-sidebar` : undefined
      }
    >
      <ReportSidebarTitle>Compliance Explorer</ReportSidebarTitle>
      <AccordionListView defaultOpen={defaultOpen} items={items} />
    </ComplianceSidebarContainer>
  );
};

export default ComplianceSidebar;
