import { OrderFormValues, sideDisplaySettings } from "@enfusion-ui/core";
import { ExecutionOrderSide, InstrumentInfo } from "@enfusion-ui/types";
import { getKeywordFromInstrument, getNormalizedInstrumentInfo } from "@enfusion-ui/utils";
import { FormPanel, PanelRow } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import { GridData, GridView } from "../../../../components/display/GridView";
import { getDisplayValue } from "../../utils/getDisplayValue";
import {
  LabeledValue,
  NumericLabeledValue,
  VarColorText,
} from "../styledComponents";

const OrderSummaryGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export type OrderSummaryPanelProps = {
  bbyellow?: string | null;
  orderSide: ExecutionOrderSide | null;
  quantity: string | number | null;
  localNotional: string | number | null;
  nativeNotional?: string | number | null;
  instrument: InstrumentInfo | null;
  exposureCurrencyCode?: string | null;
  title?: string;
  splitOrder?: boolean;
  viewMode?: "grid" | "row";
  stopPrice?: number | null;
  limitPrice?: number | null;
};

const OrderSummaryPanelGrid: React.FC<{
  gridData: GridData;
  fieldNameWidth?: number;
  fieldValueWidth?: number;
}> = ({ gridData, fieldNameWidth, fieldValueWidth }) => {
  return (
    <GridView
      gridData={gridData}
      fieldNameWidth={fieldNameWidth}
      fieldValueWidth={fieldValueWidth}
    />
  );
};

const OrderSummaryPanel: React.FC<OrderSummaryPanelProps> = ({
  bbyellow,
  orderSide,
  quantity,
  localNotional,
  nativeNotional,
  instrument: instrumentBase,
  exposureCurrencyCode,
  title = "",
  viewMode = "row",
  stopPrice,
  limitPrice,
}) => {
  const instrument = instrumentBase
    ? {
        ...instrumentBase,
        ...getNormalizedInstrumentInfo(instrumentBase),
      }
    : null;
  const settings = sideDisplaySettings(instrument?.option || false);
  const values =
    orderSide && settings[orderSide]
      ? settings[orderSide]
      : {
          name: null,
          color: null,
        };

  const { name, color } = values;
  const instrumentSubType = instrument?.financialSubType?.description ?? null;

  if (viewMode === "grid") {
    const gridData = [
      {
        fieldName: "Side",
        fieldValue: name ?? "",
        showColored: {
          onValueChanged: (value: string) => value ?? null,
          data: color,
        },
      },
      {
        fieldName: "Quantity",
        fieldValue: getDisplayValue(quantity, 2),
      },
      ...(instrument?.currency?.code !== "USD"
        ? [
            {
              fieldName: "Notional",
              fieldValue: getDisplayValue(nativeNotional ?? 0, 2) ?? "-",
            },
          ]
        : []),
      {
        fieldName: `Notional (${instrument?.currency?.code || "USD"})`,
        fieldValue: getDisplayValue(localNotional ?? 0, 2) ?? "-",
      },
      { fieldName: "Limit", fieldValue: getDisplayValue(limitPrice ?? 0, 2) },
      {
        fieldName: "Stop Limit",
        fieldValue: getDisplayValue(stopPrice ?? 0, 2),
      },
    ];
    return <OrderSummaryPanelGrid gridData={gridData} />;
  }

  return (
    <FormPanel title={title}>
      <PanelRow grow>
        <OrderSummaryGrid>
          <LabeledValue label="BB Yellow" title={bbyellow ?? "-"}>
            {bbyellow ?? "-"}
          </LabeledValue>
          <LabeledValue label="Side" title={name ?? "-"}>
            <VarColorText colorValue={color}>{name ?? "-"}</VarColorText>
          </LabeledValue>
          <NumericLabeledValue
            label="Quantity"
            title={getDisplayValue(quantity, 0)}
          >
            {getDisplayValue(quantity, 0)}
          </NumericLabeledValue>
          <NumericLabeledValue
            label={`${
              instrumentSubType === "Bond" ? "Proceeds" : "Notional"
            } (${exposureCurrencyCode ?? ""})`}
            title={getDisplayValue(localNotional, 2)}
          >
            {getDisplayValue(localNotional, 2)}
          </NumericLabeledValue>
          <NumericLabeledValue
            label="$ Notional (USD)"
            title={getDisplayValue(nativeNotional ?? null, 2)}
          >
            {getDisplayValue(nativeNotional ?? null, 2)}
          </NumericLabeledValue>
        </OrderSummaryGrid>
      </PanelRow>
    </FormPanel>
  );
};

export const OrderSummaryFormPanel: React.FC<{ title?: string }> = ({
  title = "Order Summary",
}) => {
  const { watch } = useFormContext<OrderFormValues>();
  const { instrument, orderSide, quantity, notional, localNotional } = watch([
    "instrument",
    "orderSide",
    "quantity",
    "notional",
    "localNotional",
  ]);

  return (
    <OrderSummaryPanel
      title={title}
      bbyellow={getKeywordFromInstrument(instrument, "BBYELLOW")}
      orderSide={orderSide}
      quantity={quantity}
      instrument={instrument}
      exposureCurrencyCode={instrument?.currency?.code ?? null}
      //TODO: populate the below fields
      localNotional={localNotional}
      nativeNotional={notional}
    />
  );
};

export default OrderSummaryPanel;
