import { WidgetComponentsDefinition } from "@app-views/dashboards/widgets/types";
import {
  OrderFormInstrumentSelect,
  StrategyBookIdSelect,
} from "@app-views/oems/OrderTicket/panels/InstrumentAndStrategyPanel";

import InstrumentEditWidget from "./InstrumentPanelEditWidget";
import InstrumentWidget from "./InstrumentPanelWidget";
import {
  InstrumentPanelWidgetMobile,
  InstrumentSelectMobile,
} from "./InstrumentPanelWidgetMobile";

export const instrumentPanel: WidgetComponentsDefinition = {
  renderComponent: InstrumentWidget,
  editComponent: InstrumentEditWidget,
  renderMobileComponent: InstrumentPanelWidgetMobile,
};

export const instrumentSelect: WidgetComponentsDefinition = {
  renderComponent: OrderFormInstrumentSelect,
  renderMobileComponent: InstrumentSelectMobile,
};

export const strategyBookSelect: WidgetComponentsDefinition = {
  renderComponent: StrategyBookIdSelect,
};
