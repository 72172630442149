import CurrencySelect from "@app-views/reports/reportSettings/generalLedgerAccounting/glDistributionLine/CurrencySelect";
import { CollateralPledgeOrderTypeValues } from "@enfusion-ui/trades";
import { createSelectOptions } from "@enfusion-ui/utils";
import {
  ControlledNumericInput,
  ControlledSelect,
  FormPanel,
  PanelRow,
} from "@enfusion-ui/web-components";
import * as React from "react";

import { StyledFormElementHeader } from "../../components/styledComponents";
import { CollateralHaircutSection } from "./components/CollateralHaircutSection";

const collateralPledgeOrderOptions = createSelectOptions(
  CollateralPledgeOrderTypeValues
);

export const CollateralPledgeDetailsPanel: React.FC = () => {
  return (
    <FormPanel
      lineStyle
      collapsible
      defaultOpen
      keepRendered
      numColumns={1}
      defaultRendered
      title="Collateral Pledge Details"
      tabFocusOutline={false}
    >
      <CollateralHaircutSection />

      <StyledFormElementHeader>Order Type</StyledFormElementHeader>
      <PanelRow>
        <ControlledSelect
          required
          name="orderType"
          label="Order Type"
          options={collateralPledgeOrderOptions}
        />
        <ControlledNumericInput
          required
          name="notionalQuantity"
          label="Notional Quantity"
        />
      </PanelRow>

      <PanelRow>
        <CurrencySelect name="settlementCCY" label="Settlement CCY" />
        <ControlledNumericInput
          name="settlementFxRate"
          label="Settlement FX Rate"
          formatStyle="percent"
        />
      </PanelRow>
    </FormPanel>
  );
};
