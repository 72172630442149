import { FormController, MultiSelect } from "@enfusion-ui/web-components";
import * as React from "react";

const INSTRUCTIONS = [{ label: "None", value: null }];

export const InstructionsSelect: React.FC<{
  disabled?: boolean;
  name?: string;
  label?: string;
}> = ({ disabled, name = "instructions", label = "Instructions" }) => {
  return (
    <FormController
      name={name}
      render={({ ref: _ref, value, onChange, ...rest }) => {
        return (
          <MultiSelect
            {...rest}
            label={label}
            value={value?.map((eachValue: string) =>
              INSTRUCTIONS.find((e) => e.value === eachValue)
            )}
            options={INSTRUCTIONS}
            onChange={(selectedValues) =>
              onChange(selectedValues?.map((e) => e.value) || [])
            }
            disabled={disabled}
            inputId="trade-instructions-multi-selection-id"
          />
        );
      }}
    />
  );
};
