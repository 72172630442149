import { CDXOrderFormValues, sideDisplaySettings } from "@enfusion-ui/core";
import { ExecutionOrderSide } from "@enfusion-ui/types";
import { FormPanel, PanelRow } from "@enfusion-ui/web-components";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import { GridView } from "../../../../components/display/GridView";
import { getDisplayValue } from "../../utils/getDisplayValue";
import {
  LabeledValue,
  NumericLabeledValue,
  VarColorText,
} from "../styledComponents";
import { OrderSummaryGrid, SummaryModalTitle } from "./styles";

export type CDXOrderSummaryPanelProps = {
  tenorShortName: string;
  orderSide: ExecutionOrderSide | null;
  notional: string | null;
  clearingType?: string | null;
  fcmdcmShortName?: string | null;
  title?: string;
  viewMode?: "grid" | "row";
  ccpShortName?: string | null;
};

const CDXOrderSummaryPanelGrid: React.FC<{
  tenorShortName: string | null;
  orderSide: ExecutionOrderSide | null;
  notional: string | null;
  clearingType?: string | null;
  fcmdcmShortName?: string | null;
  ccpShortName?: string | null;
}> = ({
  tenorShortName,
  orderSide,
  notional,
  clearingType,
  fcmdcmShortName,
  ccpShortName,
}) => {
  const settings = sideDisplaySettings(false);
  const values = (orderSide && settings[orderSide]) || {
    name: null,
    color: null,
  };

  const { name, color } = values;

  const gridData = [
    {
      fieldName: "Side",
      fieldValue: name ?? "",
      showColored: {
        onValueChanged: (value: string) => value ?? null,
        data: color,
      },
    },
    {
      fieldName: "Notional",
      fieldValue: getDisplayValue(notional ?? 0, 2),
    },
    { fieldName: "Tenor", fieldValue: tenorShortName ?? "" },
    {
      fieldName: "Clearing Type",
      fieldValue: clearingType ?? "",
    },
    { fieldName: "CCP", fieldValue: ccpShortName ?? "" },
    { fieldName: "FCM/DCM", fieldValue: fcmdcmShortName ?? "" },
  ];

  return <GridView gridData={gridData} />;
};

export const CDXOrderSummaryPanel: React.FC<{
  title?: string;
  viewMode?: string;
}> = ({ title = "CDXOrder Summary", viewMode = "grid" }) => {
  const { watch } = useFormContext<CDXOrderFormValues>();
  const {
    orderSide,
    notional,
    tenorShortName,
    clearingType,
    fcmdcmShortName,
    ccpShortName,
    cdxInstrument,
  } = watch([
    "orderSide",
    "notional",
    "tenorShortName",
    "clearingType",
    "fcmdcmShortName",
    "ccpShortName",
    "cdxInstrument",
  ]);

  const settings = sideDisplaySettings(false);
  const values = (orderSide && settings[orderSide]) || {
    name: null,
    color: null,
  };

  const { name, color } = values;

  if (viewMode === "grid") {
    return (
      <>
        <SummaryModalTitle label="CDX Index">
          {cdxInstrument?.description}
        </SummaryModalTitle>
        <CDXOrderSummaryPanelGrid
          orderSide={orderSide}
          tenorShortName={tenorShortName ?? ""}
          notional={notional}
          clearingType={clearingType}
          ccpShortName={ccpShortName}
          fcmdcmShortName={fcmdcmShortName}
        />
      </>
    );
  }

  return (
    <FormPanel title={title}>
      <PanelRow grow>
        <OrderSummaryGrid>
          <LabeledValue label="Side" title={name ?? "-"}>
            <VarColorText colorValue={color}>{name ?? "-"}</VarColorText>
          </LabeledValue>
          <NumericLabeledValue
            label="Notional"
            title={getDisplayValue(notional, 0)}
          >
            {getDisplayValue(notional, 0)}
          </NumericLabeledValue>
          <LabeledValue label="Tenor" title={tenorShortName ?? "-"}>
            {tenorShortName ?? "-"}
          </LabeledValue>
          <LabeledValue label="Clearing Type" title={clearingType ?? "-"}>
            {clearingType ?? "-"}
          </LabeledValue>
          <LabeledValue label="CCP" title={ccpShortName ?? "-"}>
            {ccpShortName ?? "-"}
          </LabeledValue>
          <LabeledValue label="FCM/DCM" title={fcmdcmShortName ?? "-"}>
            {fcmdcmShortName ?? "-"}
          </LabeledValue>
        </OrderSummaryGrid>
      </PanelRow>
    </FormPanel>
  );
};

export default CDXOrderSummaryPanel;
