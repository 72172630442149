import { useAllocations } from "@app-context/allocation/context";
import { NodeData } from "@enfusion-ui/types";
import { getFileIcon, getFileName } from "@enfusion-ui/utils";
import { FileExplorer, Portal } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import React, { forwardRef, useImperativeHandle } from "react";

const Explorer = styled(FileExplorer)`
  flex: 1;
  overflow-x: hidden;
`;

const PortalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: var(--background-color-1);
  height: 180px;
  min-height: 150px;
  max-height: 200px;
  border-radius: var(--radius);
`;

const FrontOfficeHierarchySelect = forwardRef((props: any, ref) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const { value } = props;

  const containerRef = React.useRef(null);
  const selectedBookNodeRef = React.useRef<NodeData>();

  FrontOfficeHierarchySelect.displayName = "FrontOfficeHierarchySelect";

  const cell = props.eGridCell;

  const { books } = useAllocations();

  const selectedFO = React.useMemo(() => [`${value}`], [value]);

  useImperativeHandle(ref, () => ({
    getValue() {
      return selectedBookNodeRef.current;
    },

    isCancelBeforeStart() {
      setMenuOpen(true);
      return false;
    },

    isPopup() {
      return true;
    },
  }));

  const handleSelectionChange = React.useCallback(
    (newValues: Array<NodeData>) => {
      selectedBookNodeRef.current = newValues.find(
        (node: NodeData) => node.file
      );
    },
    []
  );

  const onClose = () => {
    setMenuOpen(false);
    setTimeout(() => props.stopEditing(), 400);
  };

  return (
    <div ref={containerRef}>
      <Portal
        open={menuOpen}
        attachedRef={containerRef}
        onClickOutside={onClose}
        align="left"
        leftOffset={cell?.clientLeft ?? 5}
        topOffset={cell?.offsetHeight ?? 28}
        minWidth={cell?.clientWidth + 80 ?? 250}
      >
        <PortalContainer>
          <Explorer
            nodes={books}
            getFileIcon={getFileIcon}
            getFileName={getFileName}
            onEntryClick={(node: NodeData) => {
              if (node.file) handleSelectionChange([node]);
            }}
            checkSelections
            multiSelect={false}
            values={selectedFO || []}
            onSelectionChange={handleSelectionChange}
          />
        </PortalContainer>
      </Portal>
    </div>
  );
});

export default FrontOfficeHierarchySelect;
