import { RoundedDataGrid } from "@app-components/DataGrid";
import { TableLoader } from "@app-components/display/loader/TableLoader";
import { usePortfolio } from "@app-context/portfolios/PortfolioProvider";
import { useStoredGridColumnState } from "@app-utils/useStoredGridColumnState";
import ComplianceStateCellRenderer from "@app-views/oems/components/ComplianceStateCellRenderer";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  getProposedOrderTransaction,
  PortfolioProposedOrder,
  PortfolioProposedOrderAllocationEntry,
  PROPOSED_ORDERS_SUBMIT_CONFIRMATION,
} from "@enfusion-ui/portfolios";
import { useConfirmationModal } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { icon, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faLayerGroupMinus,
  faSave,
  faTasks,
} from "@fortawesome/pro-solid-svg-icons";
import {
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridReadyEvent,
  RowNode,
  SelectionChangedEvent,
} from "ag-grid-community";
import * as React from "react";

import { complianceStateToolTipGetter } from "../utils";
import SideCellRenderer from "./cellRenderers/SideCellRenderer";
import {
  ComplianceStateTooltip,
  GetComplianceStateTooltipRecords,
} from "./ComplianceStateTooltip";
import { ContextItemEntry } from "./MainPortfolioGrid";
import {
  aggFuncs,
  gridOverlayTemplate,
  proposedAllocationColumnDefs,
} from "./utils";

const StyledGridWrapper = styled(RoundedDataGrid)`
  .ag-header-cell[col-id="selection"] {
    padding: 5px;
  }
  .ag-react-container {
    line-height: 22px !important;
  }
  .ag-row-selected {
    background-color: var(--info);
  }
  .ag-row-selected .ag-cell {
    background-color: var(--info) !important;
  }
`;

const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
`;

type ProposedAllocationGridProps = {};

function mapAllocationRow(
  order: PortfolioProposedOrder,
  allocationEntry: PortfolioProposedOrderAllocationEntry
) {
  return {
    orderId: order.id,
    ...allocationEntry,
    orderSide: order.orderSide,
    // issuer: order.issuer,
    security: order.instrumentDescription,
    // account: order.account,
    complianceResult: order.complianceResult,
  };
}

function mapOrdersToAllocationRows(orders: PortfolioProposedOrder[]) {
  return orders.reduce((res, i) => {
    i.proposedAllocations.forEach((allocation) => {
      res.push(mapAllocationRow(i, allocation));
    });
    return res;
  }, [] as ReturnType<typeof mapAllocationRow>[]);
}
const getRowNodeId = (row: GetRowIdParams<PortfolioProposedOrder>) =>
  row.data.id.toString();

const ProposedAllocationGrid: React.FC<ProposedAllocationGridProps> = () => {
  const {
    submitProposedOrders,
    testCompliance,
    registerHandlers,
    triggerInitProposedOrders,
    syncReceived,
    config: { root, filePath },
    removeAndDownsize,
    removeAndRedistribute,
    isPortfolioAdjusting,
    isModifyingProposedOrders,
    selectedOrders,
    setSelectedOrders,
    canCreateOrders,
  } = usePortfolio();

  const ProposedOrdersConfirmation = useConfirmationModal({
    submitActionTheme: "primary",
    onSubmit: () => submitProposedOrders(),
    title: PROPOSED_ORDERS_SUBMIT_CONFIRMATION.title,
    submitButtonText: PROPOSED_ORDERS_SUBMIT_CONFIRMATION.buttonText,
    renderContent: () => PROPOSED_ORDERS_SUBMIT_CONFIRMATION.message,
  });

  const proposedAllocationsRef = React.useRef<
    PortfolioProposedOrderAllocationEntry[]
  >([]);

  const args = useStoredGridColumnState({
    storageKey: `e-portfolio-proposed-allocations-grid-${root}${filePath}`,
    onGridReady: (event: GridReadyEvent) => {
      event.api.updateGridOptions({ rowData: [] });

      if (isPortfolioAdjusting)
        args.gridReadyEventRef.current?.api.showLoadingOverlay();

      registerHandlers({
        initProposedOrders: (orders) => {
          const newRows = mapOrdersToAllocationRows(orders);
          event.api.updateGridOptions({ rowData: newRows });
          proposedAllocationsRef.current = newRows;
        },
        updateProposedOrders: (updates) => {
          const { add, update } = updates;
          const newRows = [
            ...mapOrdersToAllocationRows(add || []),
            ...mapOrdersToAllocationRows(update || []),
          ];
          event.api.applyTransaction(
            getProposedOrderTransaction(proposedAllocationsRef.current, newRows)
          );
          proposedAllocationsRef.current = newRows;
        },
      });

      triggerInitProposedOrders();
    },
  });

  React.useEffect(() => {
    if (isPortfolioAdjusting)
      args.gridReadyEventRef.current?.api.showLoadingOverlay();
    else args.gridReadyEventRef.current?.api.hideOverlay();
  }, [isPortfolioAdjusting]);

  const getContextMenuItems = useRefCallback(
    (params: GetContextMenuItemsParams) => {
      const defaultItems = params?.defaultItems || ["export"];
      const selectedNodes = params.api.getSelectedNodes();
      const entries = [
        ...defaultItems,
        "separator",
        {
          name: "Test Compliance",
          icon: icon(faTasks).html?.[0],
          action: testCompliance,
        },
        canCreateOrders
          ? {
              name: "Create Orders",
              icon: icon(faSave).html?.[0],
              action: ProposedOrdersConfirmation.openModal,
            }
          : null,
        params.node || selectedNodes.length > 0 ? "separator" : null,
        selectedNodes.length > 0
          ? {
              name: "Remove & Downsize Selected",
              disabled: isModifyingProposedOrders,
              icon: icon(faLayerGroupMinus as IconDefinition).html?.[0],
              action: () =>
                removeAndDownsize(
                  selectedNodes.map((i) => ({
                    orderId: i.data?.orderId,
                    id: i.data?.id,
                  }))
                ),
            }
          : params.node
          ? {
              name: "Remove & Downsize",
              disabled: isModifyingProposedOrders,
              icon: icon(faLayerGroupMinus as IconDefinition).html?.[0],
              action: () =>
                removeAndDownsize([
                  {
                    orderId: params.node?.data.orderId,
                    id: params.node?.data.id,
                  },
                ]),
            }
          : null,
        selectedNodes.length > 0
          ? {
              name: "Remove & Redistribute Selected",
              disabled: isModifyingProposedOrders,
              icon: icon(faLayerGroupMinus as IconDefinition).html?.[0],
              action: () =>
                removeAndRedistribute(
                  selectedNodes.map((i) => ({
                    orderId: i.data?.orderId,
                    id: i.data?.id,
                  }))
                ),
            }
          : params.node
          ? {
              name: "Remove & Redistribute",
              disabled: isModifyingProposedOrders,
              icon: icon(faLayerGroupMinus as IconDefinition).html?.[0],
              action: () =>
                removeAndRedistribute([
                  {
                    orderId: params.node?.data?.orderId,
                    id: params.node?.data?.id,
                  },
                ]),
            }
          : null,
      ] as (ContextItemEntry | null)[];

      return entries.filter((i) => i !== null) as unknown as ContextItemEntry[];
    },
    [selectedOrders, canCreateOrders]
  );

  const changeSelectedOrders = useRefCallback(
    (event: SelectionChangedEvent) =>
      setSelectedOrders(event.api.getSelectedNodes() as RowNode[]),
    [setSelectedOrders]
  );

  return (
    <>
      <GridContainer>
        {syncReceived ? (
          <StyledGridWrapper
            height="100%"
            suppressRowClickSelection
            suppressCellFocus
            rowSelection="multiple"
            multiSortKey="ctrl"
            rowGroupPanelShow="always"
            groupDefaultExpanded={-1}
            aggFuncs={aggFuncs}
            columnDefs={proposedAllocationColumnDefs}
            defaultColDef={{
              cellStyle: { padding: "1px 4px" },
              resizable: true,
              sortable: true,
              filter: true,
              suppressKeyboardEvent: () => true,
            }}
            components={{
              sideCellRenderer: SideCellRenderer,
            }}
            columnTypes={{
              complianceState: {
                cellRenderer: ComplianceStateCellRenderer,
                cellRendererParams: {
                  defaultState: "PendingCheck",
                  allowGroup: true,
                },
                tooltipComponent: ComplianceStateTooltip,
                tooltipComponentParams: {
                  resultField: "complianceResult",
                  getRecords: ((params) => {
                    return params.value.complianceRecords.filter(
                      (i) => i.fundId === params.data.fundId
                    );
                  }) as GetComplianceStateTooltipRecords,
                },
                tooltipValueGetter: complianceStateToolTipGetter,
              },
            }}
            gridName="proposedAllocationGrid"
            getContextMenuItems={getContextMenuItems}
            getRowId={getRowNodeId}
            overlayLoadingTemplate={gridOverlayTemplate(isPortfolioAdjusting)}
            onSelectionChanged={changeSelectedOrders}
            maintainColumnOrder
            groupSelectsChildren
            groupSelectsFiltered
            suppressAggFuncInHeader
            groupDisplayType="singleColumn"
            {...args}
          />
        ) : (
          <TableLoader fillArea />
        )}
      </GridContainer>
      {ProposedOrdersConfirmation.content}
    </>
  );
};

export default ProposedAllocationGrid;
