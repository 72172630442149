import { Portal } from "@enfusion-ui/web-components";
import { useIsMobileDevice } from "@enfusion-ui/web-core";

import { AdvancedSearchContainer } from "../styledComponents";

type AdvancedSearchContentPortalProps = {
  open: boolean;
  closePopup: VoidFunction;
  attachedRef: React.RefObject<HTMLElement>;
  hideOptions?: boolean;
  dataE2EId?: string;
};

export const AdvancedSearchContentPortal: React.FC<
  React.PropsWithChildren<AdvancedSearchContentPortalProps>
> = ({ open, closePopup, attachedRef, hideOptions, children, dataE2EId }) => {
  const isMobile = useIsMobileDevice();

  return (
    <Portal
      dataE2EId={dataE2EId}
      open={open}
      attachedRef={attachedRef}
      onClickOutside={(e: Event) => {
        const isClickOnAgPopup = Array.from(
          document.querySelectorAll(".ag-popup")
        ).some((el) => el.contains(e.target as Node));
        if (!isClickOnAgPopup) {
          closePopup();
        }
      }}
    >
      <AdvancedSearchContainer
        isMobile={isMobile}
        hideOptions={hideOptions}
        animate={{ opacity: open ? 1 : 0 }}
      >
        {children}
      </AdvancedSearchContainer>
    </Portal>
  );
};
