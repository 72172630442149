import SchemeHotButtonsPanel from "@app-components/control/SchemeHotButtonsPanel";
import { applyAllocationForAllocationScheme } from "@app-views/oems/utils/actions";
import {
  convertFormValuesToOrder,
  OrderFormValues,
  UNKNOWN_INSTRUMENT_ID,
  useAllocationsControl,
} from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { AllocationScheme, OrderInfo } from "@enfusion-ui/types";
import { styled, useAuth, useOEMS } from "@enfusion-ui/web-core";
import * as React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const AllocationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export type PreferredAllocationSchemeSelectProps = {
  value: number | null;
  onChange?: (schemeId: number | null) => void;
  setError?: (errors: string | null) => void;
  disabled?: boolean;
};

const PreferredAllocationSchemeSelect: React.FC<
  PreferredAllocationSchemeSelectProps
> = ({ value, onChange, setError, disabled }) => {
  const { isUserType } = useAuth();
  const { preferredAllocationSchemes: schemes, allocationSchemes: allSchemes } =
    useOEMS();

  const {
    setSubmitErrors,
    newEntry,
    isEntryTransmitted,
    initialAccountAllocations,
    setIsFormBusy,
  } = useAllocationsControl();

  const { getValues, reset, watch, setValue } =
    useFormContext<OrderFormValues>();
  const [selectedScheme, setSelectedScheme] =
    React.useState<AllocationScheme | null>(null);

  const isExpressUser = isUserType("Express");
  const { orderSide, quantity, instrument } = watch([
    "orderSide",
    "quantity",
    "instrument",
  ]);
  const prevWatchValuesRef = React.useRef({ orderSide, quantity, instrument });
  const { preferredSchemes, otherSchemes } = React.useMemo(() => {
    const allData = isExpressUser ? [] : allSchemes || [];
    const preferredSchemes = schemes && schemes.length > 0 ? schemes : allData;
    const dataIds = schemes?.map(({ id }) => id);
    const otherSchemes = schemes
      ? allData?.filter((i) => !dataIds?.includes(i.id))
      : [];
    return { preferredSchemes, otherSchemes };
  }, [isExpressUser, JSON.stringify(allSchemes), JSON.stringify(schemes)]);

  const applyAllocationScheme = useRefCallback(
    async (allocationSchemeId: number) => {
      if (allocationSchemeId !== null) {
        setIsFormBusy(true);

        const schemeName = allSchemes?.find(
          (eachScheme) => eachScheme.id === allocationSchemeId
        )?.name;
        try {
          const orderData = await applyAllocationForAllocationScheme(
            allocationSchemeId,
            convertFormValuesToOrder(getValues()) as OrderInfo
          );
          if (orderData) {
            setValue("allocationInstructions", schemeName || "");
            setValue(
              "strategyBookId",
              isExpressUser ? null : orderData.strategyBookId
            );
            setValue("accountAllocation", orderData.accountAllocation, {
              shouldDirty: true,
            });
            setValue("swapOrder", orderData.swapOrder ?? false);
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          if (err && typeof err === "object") {
            const messageList = err.message ? [err.message] : [];
            setSubmitErrors(err.errors ?? messageList);
          } else if (typeof err === "string") {
            setSubmitErrors([JSON.parse(err)?.message]);
          }
        } finally {
          setIsFormBusy(false);
        }
      }
    },
    [allSchemes, isExpressUser, getValues, reset]
  );

  const handleSchemeChange = useRefCallback(
    async (allocationScheme: AllocationScheme | null) => {
      if (allocationScheme) {
        applyAllocationScheme(allocationScheme.id);
      }
      onChange?.(allocationScheme ? allocationScheme.id : null);
    },
    [onChange, applyAllocationScheme]
  );

  useEffect(() => {
    if (value !== selectedScheme?.id) {
      const newScheme =
        allSchemes?.find((scheme) => scheme.id === value) || null;
      if (newEntry) {
        handleSchemeChange(newScheme);
      }
      setSelectedScheme(newScheme);
      setError?.(null);
      setSubmitErrors(null);
    }
  }, [
    newEntry,
    value,
    JSON.stringify(allSchemes),
    JSON.stringify(selectedScheme),
    setError,
    setSubmitErrors,
  ]);

  useEffect(() => {
    const prevValues = prevWatchValuesRef.current;
    /*
     * If a new order or don't have a scheme
     * Also not transmitted
     * Also have instrument, quantity, & orderSide
     * Also if they don't match prev value
     * Also valid instrument
     * Also if not already selected except in the case of express
     */
    if (
      newEntry &&
      !isEntryTransmitted &&
      !!instrument &&
      !!Number(quantity) &&
      !!orderSide &&
      JSON.stringify({ instrument, quantity, orderSide }) !==
        JSON.stringify(prevValues) &&
      preferredSchemes.length === 1 &&
      !initialAccountAllocations &&
      instrument.id !== UNKNOWN_INSTRUMENT_ID &&
      (isExpressUser ? true : value !== selectedScheme?.id)
    ) {
      prevWatchValuesRef.current = { instrument, quantity, orderSide };
      handleSchemeChange(preferredSchemes[0]);
      setSelectedScheme(preferredSchemes[0]);
      setError?.(null);
      setSubmitErrors(null);
    }
  }, [
    newEntry,
    isEntryTransmitted,
    isExpressUser,
    value,
    instrument?.id,
    quantity,
    orderSide,
    JSON.stringify(selectedScheme),
    JSON.stringify(preferredSchemes),
    initialAccountAllocations,
  ]);

  return (
    <AllocationsContainer>
      <SchemeHotButtonsPanel
        schemes={preferredSchemes}
        selectedScheme={selectedScheme}
        onSchemeChange={handleSchemeChange}
        noSchemesMessage="No preferred allocation schemes available"
        loading={false}
        disabled={disabled}
        otherSchemes={otherSchemes}
      />
    </AllocationsContainer>
  );
};

export default PreferredAllocationSchemeSelect;
