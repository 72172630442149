import { useModalState, useRefCallback } from "@enfusion-ui/hooks";
import * as React from "react";

import {
  GeneralSearchFilterMode,
  useGeneralSearchEvents,
} from "./useGeneralSearchEvents";

const asyncDelay = (cb: VoidFunction) => {
  new Promise(() => {
    requestAnimationFrame(cb);
  });
};

export const useGeneralSearchPortal = (key: string) => {
  const [searchText, setSearchText] = React.useState("");
  const [resultCount, setResultCount] = React.useState(0);
  const [resultIndex, setResultIndex] = React.useState(0);
  const [matchCase, setMatchCase] = React.useState(true);
  const [filterMode, setFilterMode] =
    React.useState<GeneralSearchFilterMode>("highlight");

  const {
    changeText: changeTextEvent,
    navigateMatch,
    nextMatch,
  } = useGeneralSearchEvents(key, {
    onResultCount: setResultCount,
    onResultIndex: setResultIndex,
  });

  const changeText = useRefCallback(
    (text: string) => {
      setSearchText(text);
      changeTextEvent(text, matchCase, filterMode);
    },
    [setSearchText, changeTextEvent, matchCase, filterMode]
  );

  const modalState = useModalState({
    onClose: () => {
      changeText("");
      setResultIndex(0);
      setResultCount(0);
    },
  });

  const toggleMatchCase = useRefCallback(() => {
    setMatchCase((state) => {
      asyncDelay(() => {
        changeTextEvent(searchText, !state, filterMode);
      });
      return !state;
    });
  }, [changeTextEvent, searchText, filterMode]);

  const toggleFilterMode = useRefCallback(() => {
    setFilterMode((mode) => {
      let newMode: GeneralSearchFilterMode =
        mode === "highlight" ? "filter" : "highlight";
      asyncDelay(() => {
        changeTextEvent(
          searchText,
          newMode === "filter" ? false : matchCase,
          newMode
        );
      });
      return newMode;
    });
  }, [changeTextEvent, searchText, matchCase]);

  return React.useMemo(
    () => ({
      searchText,
      resultCount,
      resultIndex,
      matchCase,
      filterMode,
      modalState,
      changeText,
      toggleMatchCase,
      toggleFilterMode,
      navigateMatch,
      nextMatch,
    }),
    [
      searchText,
      resultCount,
      resultIndex,
      matchCase,
      filterMode,
      modalState,
      changeText,
      toggleMatchCase,
      toggleFilterMode,
      navigateMatch,
      nextMatch,
    ]
  );
};
