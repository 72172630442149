import { DashboardViewCore } from "@app-views/dashboards/DashboardView";
import { WIDGET_COMPONENT_DEFINITIONS } from "@app-views/dashboards/widgets/definitions";
import {
  DashboardDataV2,
  DashboardProvider,
  DEFAULT_CUSTOM_ORDER_CONFIG,
  DEFAULT_CUSTOM_ORDER_MOBILE_CONFIG,
} from "@enfusion-ui/dashboards";
import { ControlledEmpty } from "@enfusion-ui/web-components";
import {
  REST_API,
  styled,
  successToast,
  useAuth,
  useThisTab,
} from "@enfusion-ui/web-core";
import { isEmpty } from "lodash";
import * as React from "react";

const CustomTicketContainer = styled.div`
  display: "flex";
  flex-direction: "column";
  width: 100%;
  height: 100%;
`;

let controller: AbortController;

const loadDashboard = (
  filePath: string,
  mobile?: boolean,
  revertDefault?: boolean
) => {
  return new Promise<DashboardDataV2>(async (accept, reject) => {
    try {
      controller?.abort();
      controller = new AbortController();
      const customOrderData = await REST_API.DASHBOARD.DOWNLOAD_CUSTOM_ORDER(
        revertDefault ? filePath.replace("user/", "shared/") : filePath,
        mobile,
        controller
      );
      if (isEmpty(customOrderData))
        accept(
          (mobile
            ? DEFAULT_CUSTOM_ORDER_MOBILE_CONFIG
            : DEFAULT_CUSTOM_ORDER_CONFIG) as unknown as DashboardDataV2
        );
      accept(customOrderData);
    } catch (e) {
      if (!((e as DOMException).name === "AbortError")) reject(e);
    }
  });
};

export const CustomTicket: React.FC<{}> = () => {
  const { user, hasPerm, isUserType, isPoId } = useAuth();
  const { closeTab } = useThisTab();

  const handleSave = React.useCallback(() => {
    successToast("Saved changes to custom order ticket layout.");
  }, []);

  return (
    <CustomTicketContainer>
      <DashboardProvider
        loadDashboard={loadDashboard}
        storeDashboard={REST_API.DASHBOARD.STORE_CUSTOM_ORDER}
        user={user}
        hasPerm={hasPerm}
        isPoId={isPoId}
        isUserType={isUserType}
        root="user"
        filePath="user/Custom Order Ticket.json"
        loadReport={() => {}}
        onSave={handleSave}
        closeTab={closeTab}
        widgetDefinitions={WIDGET_COMPONENT_DEFINITIONS}
        mobile={false}
      >
        <DashboardViewCore category="orderForm" showSearchBar />
        <ControlledEmpty name="customOrderEditMode" />
        <ControlledEmpty name="accountAllocation" />
        <ControlledEmpty name="quantity" />
        <ControlledEmpty name="notional" />
        <ControlledEmpty name="locates" />
        <ControlledEmpty name="notionalCcy" />
        <ControlledEmpty name="localNotionalCcy" />
        <ControlledEmpty name="swapOrder" />
        <ControlledEmpty name="side" />
        <ControlledEmpty name="traderDiscretion" />
        <ControlledEmpty name="allocationSchemeId" />
        <ControlledEmpty name="locateId" />
        <ControlledEmpty name="locateBrokerIds" />
        <ControlledEmpty name="darkPools" />
        <ControlledEmpty name="instrumentId" />
        <ControlledEmpty name="orderSide" />
        <ControlledEmpty name="orderType" />
        <ControlledEmpty name="limitPrice" />
        <ControlledEmpty name="stopPrice" />
        <ControlledEmpty name="portfolioManager" />
        <ControlledEmpty name="assignedTradingDesk" />
        <ControlledEmpty name="assignedTrader" />
        <ControlledEmpty name="webOrderType" />
        <ControlledEmpty name="destination" />
        <ControlledEmpty name="targetCompId" />
      </DashboardProvider>
    </CustomTicketContainer>
  );
};
