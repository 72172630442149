import { WidgetComponentsDefinition } from "../../../types";
import NavSourceSelectionEditWidget from "./EntrySourceSelectionEditWidget";
import NavSourceSelectionWidget from "./EntrySourceSelectionWidget";
import PdfNavSourceSelectionWidget from "./PdfEntrySourceSelectionWidget";

export const entrySourceSelection: WidgetComponentsDefinition = {
  renderComponent: NavSourceSelectionWidget,
  editComponent: NavSourceSelectionEditWidget,
  renderPdfComponent: PdfNavSourceSelectionWidget,
};
