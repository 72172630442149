import { INTERNAL_PO_IDS } from "@enfusion-ui/core";
import { useAuth } from "@enfusion-ui/web-core";

export const useAllowCustomOrderTicket = () => {
  const { isPoId, isAdmin } = useAuth();

  return (
    isAdmin() ||
    [
      INTERNAL_PO_IDS.Enfusion,
      INTERNAL_PO_IDS.Demo,
      INTERNAL_PO_IDS.DemoAM,
      INTERNAL_PO_IDS.QA,
      433451, // "Schonfeld Group Holdings LLC",
    ].some(isPoId) ||
    !!process.env.REACT_APP_ENABLE_CUSTOM_ORDER_TICKET
  );
};
