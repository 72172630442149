import {
  formatCurrency,
  formatNumber,
  FormatNumberOptions,
  formatPercentage,
} from "@enfusion-ui/utils";
import { css, styled } from "@enfusion-ui/web-core";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

export type ChangeViewProps = {
  className?: string;
  style?: React.CSSProperties;
  value?: number;
  change?: number | null;
  hideIcon?: boolean;
  iconSize?: SizeProp;
  currency?: string | null;
  hideChange?: boolean;
  showPositive?: boolean;
  valueTruncate?: boolean;
  changeTruncate?: boolean;
  changeFractionDigits?: FormatNumberOptions["fractionDigits"];
};

const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Change = styled.div<{ negative: boolean; truncate: boolean }>`
  font-size: 14px;
  color: var(--${({ negative }) => (negative ? "danger" : "success")});
  margin-right: var(--spacing);
  ${({ truncate }) =>
    truncate
      ? css`
          flex: 1;
          overflow: hidden;
          text-align: right;
          text-overflow: ellipsis;
        `
      : ""}
`;

export const ChangeView: React.FC<ChangeViewProps> = ({
  style,
  value,
  change,
  currency,
  hideIcon,
  iconSize = "sm",
  className,
  hideChange = false,
  showPositive,
  valueTruncate = false,
  changeTruncate = false,
  changeFractionDigits = 0,
}) => {
  const hasValue = typeof value === "number";
  const changeVal = change ?? 0;
  const negative = hasValue ? value < 0 : changeVal < 0;
  const pct = formatPercentage(
    Math.abs(changeVal),
    changeFractionDigits,
    false,
    undefined,
    true,
    showPositive
  );

  return (
    <Content className={className} style={style}>
      {hasValue && (
        <Change truncate={valueTruncate} negative={negative}>
          {currency
            ? formatCurrency(value, currency, {
                fractionDigits: 0,
                formatNegative: false,
                reduce: true,
              })
            : formatNumber(value, {
                fractionDigits: 0,
                formatNegative: false,
                reduce: true,
              })}
        </Change>
      )}
      {!hideChange && (
        <Change truncate={changeTruncate} negative={negative}>
          {hasValue ? `(${pct})` : pct}
        </Change>
      )}
      {hideIcon ? null : (
        <FontAwesomeIcon
          size={iconSize}
          icon={negative ? faCaretDown : faCaretUp}
          style={{ color: `var(--${negative ? "danger" : "success"})` }}
        />
      )}
    </Content>
  );
};
