/* eslint-disable @typescript-eslint/no-explicit-any */
import WidgetTitle from "@app-views/dashboards/components/WidgetTitle";
import { useReportDatasource } from "@app-views/dashboards/hooks/useReportDatasource";
import {
  ReportWidgetConfig,
  useDashboard,
  useWidget,
} from "@enfusion-ui/dashboards";
import { DashboardRoot, NodeData } from "@enfusion-ui/types";
import { getFileName } from "@enfusion-ui/utils";
import {
  Button,
  CenterContent,
  ContentMessage,
  ContentMessageWrapper,
  Spinner,
} from "@enfusion-ui/web-components";
import { styled, useAuth } from "@enfusion-ui/web-core";
import { faRedoAlt, faTable } from "@fortawesome/pro-solid-svg-icons";
import { MenuItem } from "rc-menu";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
import { useMeasure } from "react-use";

import ReportView from "../../../../reports/ReportView";
import { WidgetContentContainerFill } from "../../../widget/components/WidgetContentContainer";
import { MenuItemsIcon, MenuItemsTitle } from "../../../widget/WidgetContainer";

const ReportContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: transparent;
  border-radius: var(--radius);
  overflow: hidden;
`;

const ReportViewContainer = styled.div<{ showTitle: boolean }>`
  height: calc(100% - ${({ showTitle }) => (showTitle ? "32px" : "0px")});
  background-color: var(--background-color-1);
  padding: var(--spacing-l);
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: var(--spacing);
`;

const ReportWidget: React.FC<unknown> = () => {
  const { config, addWidgetActions, setEditing } = useWidget();
  const { filePath, canEdit } = useDashboard();
  const { ref: resizeRef } = useResizeDetector();
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [containerLoaded, setContainerLoaded] = React.useState(false);
  const [measureRef, { width, height }] = useMeasure<HTMLDivElement>();
  const { isUserType } = useAuth();

  const {
    datasourceId,
    showTitle = true,
    showTotalsRow = true,
    showGroupingRow = false,
  } = config as ReportWidgetConfig;

  const {
    metadata,
    datasource,
    reloadReport,
    openDrillDown,
    reportViewConfig,
  } = useReportDatasource([showGroupingRow]);

  const handleRef = (ref: HTMLDivElement) => {
    containerRef.current = ref;
    resizeRef.current = ref;
    setContainerLoaded(true);
    measureRef(ref);
  };

  React.useEffect(() => {
    addWidgetActions(
      !datasource
        ? null
        : {
            all: [
              <MenuItem key="refresh" onClick={reloadReport}>
                <MenuItemsIcon icon={faRedoAlt} />
                <MenuItemsTitle>Refresh</MenuItemsTitle>
              </MenuItem>,
              isUserType("Express") ? null : (
                <MenuItem key="drill-down" onClick={openDrillDown}>
                  <MenuItemsIcon icon={faTable} />
                  <MenuItemsTitle>Drill Down</MenuItemsTitle>
                </MenuItem>
              ),
            ],
          }
    );
  }, [!!datasource]);

  const contextMsg = React.useMemo(() => {
    if (metadata?.error) return metadata?.error;
    if (!datasourceId) return "Please configure Report widget";
    return "There is a unknown issue. Please reload.";
  }, [datasourceId, metadata?.error]);

  const isWidgetConfigurable = !datasourceId && canEdit;

  const title = React.useMemo(() => {
    return showTitle && datasource
      ? getFileName({
          name: datasource.name,
        } as NodeData)
      : undefined;
  }, [showTitle, datasource?.name]);

  return (
    <WidgetContentContainerFill ref={handleRef} data-e2e-id="report-widget">
      {reportViewConfig === null ? (
        <CenterContent>
          <ErrorContainer>
            <ContentMessageWrapper
              isConfigurable={isWidgetConfigurable}
              onMouseDown={(e) => {
                if (isWidgetConfigurable) {
                  e.stopPropagation();
                  e.preventDefault();
                  setEditing(true);
                }
              }}
            >
              <ContentMessage
                message={contextMsg}
                size="3x"
                contentStyle={{ textAlign: "center" }}
                parentHeight={height}
                parentWidth={width}
              />
              {isWidgetConfigurable ? (
                <Button
                  theme="primary"
                  data-e2e-id="report-widget-open-settings"
                >
                  Open Settings
                </Button>
              ) : null}
            </ContentMessageWrapper>
          </ErrorContainer>
        </CenterContent>
      ) : null}
      {!containerLoaded ? (
        <CenterContent>
          <Spinner />
        </CenterContent>
      ) : null}
      {reportViewConfig !== null && containerLoaded && metadata ? (
        <ReportContainer>
          <WidgetTitle title={title} />
          <ReportViewContainer showTitle={showTitle}>
            <ReportView
              asWidget
              filePath={filePath}
              config={{
                ...reportViewConfig,
                root: reportViewConfig.path.split("/")[0] as DashboardRoot,
              }}
              showTotalsRow={showTotalsRow}
              showGroupingRow={showGroupingRow}
            />
          </ReportViewContainer>
        </ReportContainer>
      ) : null}
    </WidgetContentContainerFill>
  );
};

export default ReportWidget;
