import { AppEvent, AppEventCategories, NodeData } from "@enfusion-ui/types";
import {
  AccordionContext,
  TextInput,
  useNavBarState,
} from "@enfusion-ui/web-components";
import { AppLogging, styled } from "@enfusion-ui/web-core";
import * as React from "react";

import AccountTree from "../../components/inputs/account/AccountTree";
import { TutorialType } from "../../components/Tour/utils";

const SearchPanel = styled.div`
  padding: 0.5rem;
  background-color: var(--background-accent);
  margin-bottom: var(--spacing);
`;

const SearchForm = styled.form`
  display: flex;
`;

const AccountExplorer: React.FC<{
  open: boolean;
  tutorial?: TutorialType | null;
}> = React.memo(function AccountExplorer({ tutorial }) {
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const { closeNavBarTabOnMobile } = useNavBarState();
  // const { openTab } = useTabs();
  const { setSearch, setForceSearchIcon } = React.useContext(AccordionContext);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    AppLogging.event({
      event: AppEvent.SearchFiles,
      category: AppEventCategories.Compliance,
    });
    setSearchTerm(e.target.value);
  };

  const handleClearSearchTerm = () => {
    setSearchTerm("");
  };

  React.useEffect(() => {
    setForceSearchIcon(searchTerm.trim().length > 0);
  }, [searchTerm]);

  React.useEffect(() => {
    setSearch(
      <SearchPanel>
        <SearchForm>
          <TextInput
            label="Search"
            name="name"
            value={searchTerm}
            onChange={handleSearchChange}
            clearable
            onClearValue={handleClearSearchTerm}
          />
        </SearchForm>
      </SearchPanel>
    );
  }, [searchTerm]);

  const handleClick = React.useCallback(
    (node: NodeData) => {
      if (node.file) {
        // const fileName: string = data.name;

        // openTab({
        //   component: "file",
        //   name: fileName,
        //   icon: getFileIcon(node),
        //   config: { fileName, filePath: data.path, data },
        // });
        closeNavBarTabOnMobile();
      }
    },
    [closeNavBarTabOnMobile]
  );

  return (
    <AccountTree
      onClick={handleClick}
      tutorial={tutorial}
      searchTerm={searchTerm.trim()}
      filterEmpty={false}
    />
  );
});

export default AccountExplorer;
