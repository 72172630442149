import { RoundedDataGrid } from "@app-components/DataGrid";
import { MOCK_TRADE_TICKET_VALUATION } from "@enfusion-ui/trades";
import { DatePresetOptions } from "@enfusion-ui/utils";
import {
  ControlledSelect,
  FormElement,
  FormPanel,
  LabeledButton,
  PanelRow,
} from "@enfusion-ui/web-components";
import { useGridApi } from "@enfusion-ui/web-core";
import { ColDef } from "ag-grid-community";
import * as React from "react";

import { ControlledMarketEnvironmentSelect } from "../../../../reports/components/ReportQueryComponents/MarketEnvironmentSelect";
import {
  FullHeightContainer,
  TabContentContainer,
} from "./components/styledComponents";

const colDefs: ColDef[] = [
  {
    headerName: "",
    field: "selection",
    width: 40,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    resizable: false,
  },
  {
    headerName: "Description",
    field: "description",
    initialWidth: 120,
  },
  {
    headerName: "Value",
    field: "value",
    initialWidth: 120,
  },
  {
    headerName: "Override",
    field: "override",
    initialWidth: 120,
  },
];

export const ValuationTab: React.FC = () => {
  const { onGridReady } = useGridApi((event) => {
    event.api.sizeColumnsToFit();
  });

  return (
    <TabContentContainer>
      <FormPanel
        numColumns={1}
        keepRendered
        style={{ height: "100%" }}
        contentStyle={{ height: "100%" }}
      >
        <FullHeightContainer data-e2e-id="valuation-tab">
          <PanelRow>
            <ControlledSelect
              basis="40%"
              mobileBasis="100%"
              name="valuationValueDate"
              options={DatePresetOptions.period}
              label="Value Date"
            />

            <FormElement basis="40%" mobileBasis="50%">
              <ControlledMarketEnvironmentSelect
                name="valuationMarketEnvironment"
                label="Market Environment"
              />
            </FormElement>

            <FormElement basis="20%">
              <LabeledButton>Price</LabeledButton>
            </FormElement>
          </PanelRow>

          <RoundedDataGrid
            height="100%"
            suppressContextMenu
            rowSelection="multiple"
            suppressRowClickSelection
            rowData={MOCK_TRADE_TICKET_VALUATION}
            columnDefs={colDefs}
            onGridReady={onGridReady}
            defaultColDef={{
              resizable: true,
              suppressHeaderMenuButton: true,
              suppressMovable: true,
            }}
          />
        </FullHeightContainer>
      </FormPanel>
    </TabContentContainer>
  );
};
