import { useRefCallback } from "@enfusion-ui/hooks";
import { DatePicker } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { ICellEditorParams } from "ag-grid-community";
import * as React from "react";

const Container = styled.div`
  padding-bottom: 1rem;
  font-size: small;
`;

/**
 * When using this date-picker editor component,
 * set "stopEditingWhenCellsLoseFocus={false}" on the grid props to prevent unexpected close of the date-picker
 */
export const DatePickerCellEditor = React.forwardRef<any, ICellEditorParams>(
  ({ value, stopEditing }, ref) => {
    const [internalValue, setInternalValue] = React.useState<Date | null>(
      value ? (value instanceof Date ? value : new Date(value)) : null
    );

    React.useImperativeHandle(
      ref,
      () => ({
        getValue: () => internalValue,
        isCancelBeforeStart: () => false,
        isCancelAfterEnd: () => false,
      }),
      [internalValue]
    );

    const onChange = useRefCallback((value: Date | null) => {
      setInternalValue(value);
      setTimeout(stopEditing, 50);
    }, []);

    return (
      <Container>
        <DatePicker
          autoFocus
          onChange={onChange}
          value={internalValue}
          onClickOutside={stopEditing}
        />
      </Container>
    );
  }
);
