/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectOptionsType } from "@enfusion-ui/types";
import { getSelectOption } from "@enfusion-ui/utils";
import {
  getSelectStyles,
  Select,
  SelectProps,
} from "@enfusion-ui/web-components";
import { REPORT_ROW_HEIGHT } from "@enfusion-ui/web-core";
import * as React from "react";

const getColumnSelectStyles = (
  height: number,
  minWidth?: number,
  hasError?: boolean
) => {
  const { valueContainer, container, ...rest } = getSelectStyles(
    minWidth,
    hasError
  );
  return {
    ...rest,
    valueContainer: (provided: React.CSSProperties) => ({
      ...valueContainer(provided),
      height: height,
    }),
    container: (provided: React.CSSProperties, state: any) => ({
      ...container(provided, state),
      height: height,
    }),
    indicatorsContainer: (provided: React.CSSProperties) => ({
      ...provided,
      height: height,
    }),
  };
};

type ColumnSelectProps<T, IsMulti extends boolean = false> = Omit<
  SelectProps<T, IsMulti>,
  "options"
> & { options: SelectOptionsType<T>[] };

// This has been created with the intention of customizing React Select component
// as per the need and to be used as a cell renderer in ag-grid
function ColumnSelect<T, IsMulti extends boolean = false>({
  minWidth,
  invalid,
  name,
  errors,
  value,
  options,
  ...rest
}: ColumnSelectProps<T, IsMulti>) {
  const hasError =
    (invalid && !!name) || (!!errors && !!name && !!errors[name]);

  return (
    <Select
      classNamePrefix="column-select"
      styles={
        getColumnSelectStyles(REPORT_ROW_HEIGHT - 5, minWidth, hasError) as any
      }
      placeholder="Undecided"
      options={options}
      value={value ? getSelectOption(options, value) : null}
      {...rest}
    />
  );
}

export default ColumnSelect;
