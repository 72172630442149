import { ExplorerSection } from "@app-context/explorer/types";
import { TOAST_CONTENT } from "@enfusion-ui/core";
import { AppEvent, AppEventCategories } from "@enfusion-ui/types";
import { AppLogging, errorToast, successToast } from "@enfusion-ui/web-core";
import { camelCase } from "lodash";
import queryString from "query-string";

const copyToClipboardProps = (
  getText: () => string,
  successText: string,
  errorText: string,
  onSuccess: VoidFunction = () => {
    /** noop */
  }
) => {
  return {
    getText,
    onSuccess: () => {
      successToast(successText);
      onSuccess();
    },
    onError: () => errorToast(errorText),
  };
};

const sectionMap: Record<string, string> = {
  Services: "serviceFile",
};
function getQueryKey(section: ExplorerSection) {
  if (sectionMap[section]) return sectionMap[section];
  return camelCase(section).slice(0, -1);
}

export const createShareAction = (section: ExplorerSection) => {
  return (name: string, path: string) => {
    return copyToClipboardProps(
      () =>
        queryString.stringifyUrl({
          url: `${window.location.origin}/linking`,
          query: {
            [getQueryKey(section)]: btoa(JSON.stringify({ p: path })),
          },
        }),
      TOAST_CONTENT[section].share.success,
      TOAST_CONTENT[section].share.failure,
      () => {
        AppLogging.event(
          {
            event: AppEvent.CopyShareLink,
            category: AppEventCategories[section],
          },
          { name, path }
        );
      }
    );
  };
};

export const reportShareLinkProps = createShareAction("Reports");

export const portfolioShareLinkProps = createShareAction("Portfolios");

export const dashboardShareLinkProps = createShareAction("Dashboards");

export const serviceFileShareLinkProps = createShareAction("Services");

export const generalFileShareLinkProps = createShareAction("GeneralFiles");
