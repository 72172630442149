import { NodeData } from "@enfusion-ui/types";
import {
  Button,
  ControlledInputBase,
  ReportSelect,
} from "@enfusion-ui/web-components";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export type DatasourceSelectProps = {
  onSelect?:
    | ((entry: NodeData | null, key?: string | undefined) => void)
    | undefined;
  label: string;
  name: string;
  defaultValue: NodeData | null;
  diffReport?: boolean;
};
const icon = (setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>) => (
  <Button onClick={() => setMenuOpen((prev) => !prev)}>
    <FontAwesomeIcon icon={faChevronDown} />
  </Button>
);

export const ControlledDatasourceSelect: React.FC<DatasourceSelectProps> = ({
  onSelect,
  label,
  name,
  diffReport,
  defaultValue,
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  return (
    <ControlledInputBase
      name={name}
      defaultValue={defaultValue}
      render={({ ref: _ref, value, name, onChange, ...rest }) => (
        <ReportSelect
          {...rest}
          label={label}
          name={name}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          iconContainer={icon(setMenuOpen)}
          onSelect={(entry: NodeData | null) => {
            onChange(entry);
            onSelect?.(entry);
          }}
          showFixedReports
          showSharedReports
          showMyReports
          showOptionalKey={false}
          value={value}
          diffReport={diffReport}
          required
        />
      )}
    />
  );
};
