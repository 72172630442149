import { Quote } from "@enfusion-ui/types";
import {
  columnTypeCategoryStyle,
  formatCellValues,
} from "@enfusion-ui/web-core";
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faCircle,
} from "@fortawesome/pro-solid-svg-icons";
import {
  CellClassParams,
  ColDef,
  ValueFormatterParams,
} from "ag-grid-community";

export const getColorBasedOnLastValue = (
  value: number | null,
  prevValue: number | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any
) => {
  if (value && prevValue) {
    if (value > prevValue) return theme.colors.success;
    if (value < prevValue) return theme.colors.danger;
  }
  return theme.colors.textNormal;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getColorBasedOnChange = (quote: Quote | null, theme: any) => {
  if (quote && quote.change > 0) {
    return theme.colors.success;
  }
  if (quote && quote.change < 0) {
    return theme.colors.danger;
  }
  return theme.colors.textNormal;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getIconProps = (change: number, theme: any) => {
  if (change === 0)
    return { icon: faCircle, color: theme.colors.backgroundAccent };
  if (change < 0)
    return { icon: faArrowCircleDown, color: theme.colors.danger };
  return { icon: faArrowCircleUp, color: theme.colors.success };
};

//***WatchList Blotter***/
const numberCellFormatter = ({ value }: ValueFormatterParams) => {
  if (value === null) return "";

  return formatCellValues("Number", value);
};

const percentageCellFormatter = ({ value }: ValueFormatterParams) => {
  if (value === null) return "";

  return formatCellValues("Percent", value);
};

export const watchListCellStyle = ({ value }: CellClassParams) =>
  columnTypeCategoryStyle("SignSensitiveNumber", value);

export const COLUMN_DEFS: ColDef[] = [
  {
    headerName: "Contract",
    field: "contract",
    colId: "contract",
    width: 200,
    pinned: "left",
    filter: true,
  },
  {
    headerName: "Bid",
    field: "bid",
    colId: "bid",
    width: 80,
    type: "numberColumn",
    valueFormatter: numberCellFormatter,
    cellRenderer: "priceCellRenderer",
  },
  {
    headerName: "Ask",
    field: "ask",
    colId: "ask",
    width: 80,
    type: "numberColumn",
    valueFormatter: numberCellFormatter,
    cellRenderer: "priceCellRenderer",
  },
  {
    headerName: "Last",
    field: "last",
    colId: "last",
    width: 80,
    type: "numberColumn",
    valueFormatter: numberCellFormatter,
  },
  {
    headerName: "Volume",
    field: "volume",
    colId: "volume",
    width: 120,
    type: "numberColumn",
    valueFormatter: numberCellFormatter,
    cellStyle: watchListCellStyle,
  },
  {
    headerName: "% Change",
    field: "change",
    colId: "change",
    width: 150,
    type: "numberColumn",
    valueFormatter: percentageCellFormatter,
    cellStyle: watchListCellStyle,
  },
  {
    headerName: "Currency",
    field: "currency",
    colId: "currency",
    width: 120,
  },
];
