import { DateSelection } from "@app-views/reports/components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledLedgerSelect } from "@app-views/reports/components/ReportQueryComponents/LedgerSelect";
import {
  AccountingMethodOptions,
  FXTranslationMethodOptions,
} from "@enfusion-ui/types";
import { ControlledSelect, PanelRow } from "@enfusion-ui/web-components";

const GLBalanceSheetSettings = () => {
  return (
    <>
      <PanelRow>
        <ControlledLedgerSelect name="generalLedgerIds" label="Ledger" />
        <DateSelection name="valueDate" label="Value Date" />
      </PanelRow>
      <PanelRow>
        <ControlledSelect
          name="accountingMethod"
          options={AccountingMethodOptions}
          label="Accounting Method"
        />
        <ControlledSelect
          name="fxTranslationMethod"
          options={FXTranslationMethodOptions}
          label="FX Translation Method"
        />
      </PanelRow>
    </>
  );
};

export default GLBalanceSheetSettings;
