/* General Tab - Stock Loan/Borrow */
export const StockLoanBorrowOrderTypeValues = {
    Borrow: "Borrow",
    "Unwind Borrow": "UnwindBorrow",
    "Unwind Loan": "UnwindLoan",
    Loan: "Loan",
};
export const SpreadCalcMethodValues = {
    "Basis Point Add": "BasisPointAdd",
    "Percent Shift": "PercentShift",
};
export const PaymentTypeValues = {
    "Fixed Rate": "FixedRate",
    "Floating Rate": "FloatingRate",
};
export const CompoundingFrequencyValues = {
    Simple: "Simple",
    Daily: "Daily",
    Quarterly: "Quarterly",
    SemiAnnual: "SemiAnnual",
    Monthly: "Monthly",
    Annual: "Annual",
    Continuous: "Continuous",
    Weekly: "Weekly",
};
/* General Tab - CollateralPledge */
export const HaircutTypeValues = {
    Haircut: "Haircut",
    Collateral: "Collateral",
};
export const CollateralPledgeOrderTypeValues = {
    "Collateral Pledge": "CollateralPledge",
    "Unwind Collateral Pledge": "UnwindCollateralPledge",
    "Unwind Collateral Receive": "UnwindCollateralReceive",
    "Collateral Receive": "CollateralReceive",
};
/* General Tab - VarSwap */
export const NotionalTypeValues = {
    "Variance Units": "VarianceUnits",
    "Vega Notional": "VegaNotional",
};
export const KnockInOutValues = {
    "No Barriers": "NoBarriers",
    "Single Barrier": "SingleBarrier",
};
export const ConditionalValues = {
    None: "None",
    Conditional: "Conditional",
    Corridor: "Corridor",
};
export const BusinessDayConventionValues = {
    None: "None",
    Following: "Following",
    FRN: "FRN",
    "Modified Following": "ModifiedFollowing",
    Preceding: "Preceding",
    "Modified Preceding": "ModifiedPreceding",
    "End of Month": "EndOfMonth",
    "End of Month Following": "EndOfMonthFollowing",
    "End of Month Preceding": "EndOfMonthPreceding",
    "Third Wednesday": "ThirdWednesday",
    "EOM Modified Following": "EOMModifiedFollowing",
    "EOM Modified Preceding": "EOMModifiedPreceding",
};
export const DayCountValues = {
    Unknown: "Unknown",
    "Actual/360": "Actual360",
    "30/360": "30360",
    "Actual/365": "Actual365",
    "Actual/366": "Actual366",
    "Actual/Actual": "ActualActual",
    "Bus/252": "Bus252",
    "Actual/364": "Actual364",
    "Actual/Actual (ISDA)": "ActualActualISDA",
    "30/360 ISMA": "30360ISMA",
    "Actual/365 (Fixed)": "Actual365Fixed",
    "Bus/252 (Simple)": "Bus252Simple",
    "30/360 (SIA)": "30360SIA",
    NL365: "NL365",
    NL360: "NL360",
    "1/1 (Whole Years)": "11WholeYears",
};
/* Documents Tab */
export const DocumentAttachmentTypes = {
    "Trade Confirm": "TradeConfirm",
    Prospectus: "Prospectus",
    "Supporting Documentation": "SupportingDocumentation",
};
/* Lot Relief Tab */
export const LotReliefTypes = {
    "Account Default": "AccountDefault",
    "User Specified": "UserSpecified",
};
/* Accruals Tab */
export const AccrualPeriods = {
    Week: "Week",
    Month: "Month",
    Quarter: "Quarter",
    Year: "Year",
    Term: "Term",
    SemiAnnual: "SemiAnnual",
    Daily: "Daily",
};
export const AccrualFrequencies = {
    Annual: "Annual",
    Semiannual: "Semiannual",
    Quarterly: "Quarterly",
    Bimonthly: "Bimonthly",
    Monthly: "Monthly",
    Weekly: "Weekly",
    Biweekly: "Biweekly",
    Daily: "Daily",
    Term: "Term",
    "28 Day": "TwentyEightDay",
    "35 Day": "ThirtyFiveDay",
    "42 Day": "FortyTwoDay",
    "91 Day": "NinetyOneDay",
    "182 Day": "OneEightyTwoDay",
};
export const AccrualMethods = {
    Unknown: "Unknown",
    "30/360": "30360",
    "30/360 ISMA": "30360ISMA",
    "Actual/360": "Actual360",
    "Actual/365": "Actual365",
    "Actual/365 (Fixed)": "Actual365Fixed",
    "Actual/Actual": "ActualActual",
    "Actual/Actual (ISDA)": "ActualActualISDA",
    "Bus/252": "Bus252",
    "Bus/252 (Simple)": "Bus252Simple",
    "30/360 (SIA)": "30360SIA",
};
/* Instructions Tab */
export const InstructionActions = {
    Send: "Send",
    "Suppress current and future changes": "SuppressCurrentAndFutureChanges",
    "One time suppress": "OneTimeSuppress",
    "Send Repo Rerate ": "SendRepoRerate",
};
/* Reported Amounts Tab */
export const ReportedAmountTypes = {
    "Native Proceeds": "NativeProceeds",
    "Base Proceeds": "BaseProceeds",
    "FX Rate": "FXRate",
    "Bond Trade Quantity": "BondTradeQuantity",
    "Initial Reset Rate": "InitialResetRate",
    "Market Value": "MarketValue",
    "Gross Proceeds": "GrossProceeds",
    "Notional Net Proceeds": "NotionalNetProceeds",
    "Reported Settle Price": "ReportedSettlePrice",
    "PTH Cost": "PTHCost",
    Spread: "Spread",
    "Dividend Percent": "DividendPercent",
    "Settle Net Price": "SettleNetPrice",
    "Settle Notional": "SettleNotional",
    "Local Notional": "LocalNotional",
    "Accrued Interest": "AccruedInterest",
    "Benchmark Rate": "BenchmarkRate",
    "Open Trade Equity": "OpenTradeEquity",
    "Reported External Reference": "ReportedExternalReference",
    "Reported Local Gross Price": "ReportedLocalGrossPrice",
    "Reported Local Net Price": "ReportedLocalNetPrice",
    "Reported Settle Gross Price": "ReportedSettleGrossPrice",
    "Reported Settle Gross Proceeds": "ReportedSettleGrossProceeds",
    "Reported Order Date": "ReportedOrderDate",
    "Reported Cash Flow Type": "ReportedCashFlowType",
    "Reported Clearing Counterparty": "ReportedClearingCounterparty",
    "Reported Underlying Symbol": "ReportedUnderlyingSymbol",
    "Reported Strike Price": "ReportedStrikePrice",
    "Reported Option Type": "ReportedOptionType",
    "Reported Exchange": "ReportedExchange",
    "Reported Trade Underlying Security ID": "ReportedTradeUnderlyingSecurityID",
    "Reported Trade Contract Keyword Value": "ReportedTradeContractKeywordValue",
    "Reported Counterparty": "ReportedCounterparty",
    "Reported Order Id": "ReportedOrderId",
};
export const formRateType = {
    fixed: "Fixed",
    variable: "Variable",
};
