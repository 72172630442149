import { TradeTicketFormTypes, useTradeTicket } from "@enfusion-ui/trades";

import { Allocations } from "../panels/generalTab/AllocationsPanel";
import { CashSettlementsPanel } from "../panels/generalTab/CashSettlementsPanel";
import { CollateralPledgeDetailsPanel } from "../panels/generalTab/CollateralPledgeDetailsPanel";
import { CostPanel } from "../panels/generalTab/CostPanel";
import { FinancingPanel } from "../panels/generalTab/FinancingPanel";
import { PaymentsPanel } from "../panels/generalTab/PaymentsPanel";
import { PNLAdjustmentPanel } from "../panels/generalTab/PNLAdjustmentPanel";
import { PositionInformationPanel } from "../panels/generalTab/PositionInformationPanel";
import { SwapDetailsPanel } from "../panels/generalTab/SwapDetailsPanel";
import { TermsPanel } from "../panels/generalTab/TermsPanel";
import { TradeDetailsPanel } from "../panels/generalTab/TradeDetailsPanel";
import { BarriersPanel } from "./optionDetails/components/tabs/barriers/BarriersPanel";
import { DividendsPanel } from "./optionDetails/DividendsPanel";

export function TradeTicketPanels() {
  const { formDataType } = useTradeTicket();
  switch (formDataType) {
    case TradeTicketFormTypes.GENERAL_LISTED:
    case TradeTicketFormTypes.EQUITY:
    case TradeTicketFormTypes.FUTURE:
    case TradeTicketFormTypes.INDEX:
    case TradeTicketFormTypes.EQUITY_OPTIONS:
    case TradeTicketFormTypes.INDEX_OPTIONS:
    case TradeTicketFormTypes.FUTURE_OPTIONS:
    case TradeTicketFormTypes.WARRANT:
      return (
        <>
          <PositionInformationPanel />
          <CostPanel />
          <TradeDetailsPanel />
          <PNLAdjustmentPanel />
          <Allocations />
        </>
      );
    case TradeTicketFormTypes.OTC_OPTION:
    case TradeTicketFormTypes.EQUITY_FLEX_OPTION:
    case TradeTicketFormTypes.OTC_SPREAD_OPTIONS:
    case TradeTicketFormTypes.OTC_OUTPERFORMANCE_OPTION:
      return (
        <>
          <TradeDetailsPanel />
          <TermsPanel />
          <BarriersPanel />
          <DividendsPanel />
          <Allocations />
          <PaymentsPanel />
        </>
      );
    case TradeTicketFormTypes.ASSET_SWAP_CONVERTIBLE_OPTION:
      return (
        <>
          <TradeDetailsPanel />
          <TermsPanel />
          <Allocations />
          <PaymentsPanel />
        </>
      );
    case TradeTicketFormTypes.OTC_REALIZED_VARIANCE_OPTION:
    case TradeTicketFormTypes.NEW_DUAL_DIGITAL_OPTION:
      return (
        <>
          <TradeDetailsPanel />
          <TermsPanel />
          <Allocations />
          <PaymentsPanel />
        </>
      );
    case TradeTicketFormTypes.VARIANCE_SWAP:
      return (
        <>
          <TradeDetailsPanel />
          <SwapDetailsPanel />
          <CostPanel />
          <Allocations />
          <PaymentsPanel title="Fees/Commissions" />
        </>
      );
    case TradeTicketFormTypes.COLLATERAL_PLEDGE:
      return (
        <>
          <PositionInformationPanel />
          <TradeDetailsPanel />
          <Allocations />
          <PNLAdjustmentPanel />
          <PaymentsPanel title="Cost" />
          <CollateralPledgeDetailsPanel />
        </>
      );
    case TradeTicketFormTypes.DIVIDEND_SWAP:
      return (
        <>
          <TradeDetailsPanel />
          <SwapDetailsPanel />
          <Allocations />
          <PaymentsPanel />
        </>
      );
    case TradeTicketFormTypes.STOCK_LOAN_BORROW:
      return (
        <>
          <PositionInformationPanel />
          <TradeDetailsPanel />
          <FinancingPanel />
          <Allocations />
          <PaymentsPanel title="Fees/Commissions" />
          <PNLAdjustmentPanel />
          <CashSettlementsPanel />
        </>
      );

    default:
      throw new Error(
        `No matched panel group to the form type: ${formDataType}`
      );
  }
}
