import { errorToast, ReportRowMetadata } from "@enfusion-ui/web-core";
import { useWorkerModule } from "@enfusion-ui/web-workers";
import * as React from "react";

import { ReportsProviderMessage } from "../../../context/reports/ReportsProvider";

type RowMetadataOptions = {
  reportId: string;
  tableId: string;
  rowId: number;
};

type UpdateHandlerFunction = (rowMetadata: ReportRowMetadata[] | null) => void;

const useRowMetadata = () => {
  const { subscribeToModule, postMessage } = useWorkerModule("reports");

  const subscriptionStoreRef = React.useRef<{
    [id: string]: UpdateHandlerFunction[];
  }>({});
  const metadataStoreRef = React.useRef<{
    [id: string]: ReportRowMetadata[] | null;
  }>({});

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return subscribeToModule(async (data: any) => {
      const { command, payload } = data as ReportsProviderMessage;

      switch (command) {
        case "metadata-error": {
          const { message, destination } = payload;

          metadataStoreRef.current[destination] = null;
          subscriptionStoreRef.current[destination]?.forEach((callback) => {
            callback(null);
          });

          errorToast(message, { dismissToastId: "position-order-loading" });
          break;
        }

        case "row-metadata": {
          const { metadata, destination } = payload;
          metadataStoreRef.current[destination] = metadata;
          subscriptionStoreRef.current[destination]?.forEach((callback) => {
            callback(metadata);
          });
        }
      }
    });
  }, []);

  const unsubscribeFromRowMetadata = React.useCallback(
    (destination: string, updateHandler: UpdateHandlerFunction) => {
      if (subscriptionStoreRef.current[destination]) {
        const newSubscriptions = subscriptionStoreRef.current[
          destination
        ].filter((callback) => callback !== updateHandler);

        if (newSubscriptions.length === 0) {
          delete subscriptionStoreRef.current[destination];
        } else {
          subscriptionStoreRef.current[destination] = newSubscriptions;
        }
      }
    },
    []
  );

  const subscribeToRowMetadata = React.useCallback(
    (params: RowMetadataOptions, updateHandler: UpdateHandlerFunction) => {
      const { reportId, tableId, rowId } = params;
      const destination = `/report-feed/report/${reportId}/${tableId}/${rowId}`;
      if (!subscriptionStoreRef.current[destination]) {
        subscriptionStoreRef.current[destination] = [updateHandler];
        postMessage({
          command: "subscribe-row-metadata",
          payload: { destination },
        });
      } else if (
        !subscriptionStoreRef.current[destination].includes(updateHandler)
      ) {
        subscriptionStoreRef.current[destination].push(updateHandler);
      }
      if (!!metadataStoreRef.current[destination]) {
        updateHandler(metadataStoreRef.current[destination]);
      }
      return () => unsubscribeFromRowMetadata(destination, updateHandler);
    },
    []
  );

  return { subscribeToRowMetadata };
};

export default useRowMetadata;
