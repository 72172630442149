import { ReportSettingsDefinition } from "../../types";
import PositionQuickFilter from "./PositionQuickFilter";
import PositionSettings from "./PositionSettings";

export const WebPositionReportQuery: ReportSettingsDefinition = {
  name: "Position",
  queryType: "WebPositionReportQuery",
  quickFilterComponent: PositionQuickFilter,
  settingsComponent: PositionSettings,
};
