import { useLocalStorage } from "@enfusion-ui/hooks/build/web";
import { styled, useAuth, useTheme } from "@enfusion-ui/web-core";
import { faExclamation, faFolderUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

const ServicesIconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ServicesIconTag = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 3px;
  min-width: 15px;
  border-radius: 10px;
  background-color: var(--info);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  font-size: 12px;
  line-height: 12px;
`;

const marketing = process.env.REACT_APP_ENABLE_SERVICES_MARKETING;

const ServicesIcon: React.FC<unknown> = () => {
  const { user, isEnabled } = useAuth();
  const { theme } = useTheme();
  const [firstTime] = useLocalStorage(
    `e-first-services-load-${user?.username}`,
    true
  );
  const color =
    theme.key === "dracula" ? "var(--tab-icon-color-0)" : "var(--text-normal)";

  return (
    <ServicesIconContainer>
      <FontAwesomeIcon size="lg" icon={faFolderUser} color={color} />
      {marketing && !isEnabled("Services") && firstTime ? (
        <ServicesIconTag>
          <FontAwesomeIcon size="xs" icon={faExclamation} color={color} />
        </ServicesIconTag>
      ) : null}
    </ServicesIconContainer>
  );
};

export default ServicesIcon;
