import { useAnalyticsContext } from "@app-context/analytics/context";
import { TempTriangleIcon } from "@app-views/analytics/components/TempTriangleIcon";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { MetricChart } from "./MetricsChart";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const SubContainer = styled(Container)`
  justify-content: center;
  align-items: center;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
`;

export const MetricsSummary: React.FC = () => {
  const { data } = useAnalyticsContext();

  const metrics = React.useMemo(
    () => data?.portfolioMovement.metrics ?? null,
    [data?.portfolioMovement.metrics]
  );

  return (
    <Container>
      <TopRow>
        Metrics
        <TempTriangleIcon />
      </TopRow>
      <SubContainer>
        <MetricChart
          entry={metrics?.returns}
          label="Returns"
          value={metrics?.returns.value}
          change={metrics?.returns.change}
        />
        <MetricChart
          entry={metrics?.currentExposure}
          label="Current Exposure"
          value={metrics?.currentExposure.value}
          change={metrics?.currentExposure.change}
        />
      </SubContainer>
    </Container>
  );
};
