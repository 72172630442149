import { ReportSettingsDefinition } from "../../types";
import GLCashBalancesQuickFilter from "./GLCashBalancesQuickFilter";
import GLCashBalancesSettings from "./GLCashBalancesSettings";

export const WebGLCashBalancesQuery: ReportSettingsDefinition = {
  name: "GLCashBalances",
  queryType: "WebGLCashBalancesQuery",
  quickFilterComponent: GLCashBalancesQuickFilter,
  settingsComponent: GLCashBalancesSettings,
};
