import { ConnectionStatus } from "@enfusion-ui/types";
import * as React from "react";

export enum ConnectionType {
  API,
  MarketData,
  Factset,
  OEMS,
  Locates,
  BloombergRealtime,
  Portfolios,
  Reports,
}

export type ConnectionStatusState = {
  apiStatus: ConnectionStatus;
  marketDataConnectionStatus: ConnectionStatus;
  factsetConnectionStatus: ConnectionStatus;
  oemsConnectionStatus: ConnectionStatus;
  locatesConnectionStatus: ConnectionStatus;
  bloombergConnectionStatus: ConnectionStatus;
  reportSocketConnectionStatus: ConnectionStatus;
  updateStatus: (
    connectionType: ConnectionType,
    status: ConnectionStatus
  ) => void;
};

export const ConnectionStatusContext = React.createContext<
  ConnectionStatusState | undefined
>(undefined);

export const useConnectionStatus = () => {
  const context = React.useContext(ConnectionStatusContext);
  if (context === undefined) {
    throw new Error(
      "useConnectionStatus must be used within a ConnectionStatusProvider"
    );
  }
  return context;
};

export const ConnectionStatusProviderPassthrough: React.FC<
  React.PropsWithChildren<ConnectionStatusState>
> = ({ children, ...value }) => {
  return (
    <ConnectionStatusContext.Provider value={value}>
      {children}
    </ConnectionStatusContext.Provider>
  );
};
