/* eslint-disable react/display-name */
import { TOAST_CONTENT } from "@enfusion-ui/core";
import { useEverTrue } from "@enfusion-ui/hooks";
import {
  Button,
  CenterContent,
  ContentMessage,
} from "@enfusion-ui/web-components";
import {
  errorToast,
  OEMS_INITIALIZATION_STEPS,
  useOEMS,
} from "@enfusion-ui/web-core";
import { faSkullCrossbones } from "@fortawesome/pro-solid-svg-icons";
import { useTour } from "@reactour/tour";
import * as React from "react";

import ContentProgress from "./ContentProgress";

// Handle OMS init status
const OMSContentView: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    hasError,
    errorMessage,
    restartSocket,
    isInitialized,
    initializationStep,
    initializationMessage,
  } = useOEMS();

  const everInitialized = useEverTrue(isInitialized);

  const { isOpen, setIsOpen } = useTour();
  React.useEffect(() => {
    if (isOpen && hasError) {
      setIsOpen(false);
      errorToast(TOAST_CONTENT.oems.tourError);
    }
  }, [hasError, isOpen]);

  if (hasError) {
    return (
      <CenterContent>
        <ContentMessage
          icon={faSkullCrossbones}
          message={errorMessage ?? "There was an OEMS connection issue."}
          containerStyle={{
            maxWidth: "75%",
          }}
        >
          <Button primary onClick={restartSocket}>
            Retry Connection
          </Button>
        </ContentMessage>
      </CenterContent>
    );
  }

  if (!everInitialized) {
    return (
      <CenterContent>
        <ContentProgress
          value={
            initializationMessage === "OMS Initialized"
              ? OEMS_INITIALIZATION_STEPS
              : initializationStep
          }
          total={Math.max(OEMS_INITIALIZATION_STEPS, initializationStep)}
          message={initializationMessage || "Opening Socket"}
        />
      </CenterContent>
    );
  }

  return <>{children}</>;
};

export default OMSContentView;
