import { ReportSettingsDefinition } from "../../types";
import MarketRatesQuickFilter from "./MarketRatesQuickFilter";
import MarketRatesSettings from "./MarketRatesSettings";

export const WebQuotesQuery: ReportSettingsDefinition = {
  name: "Quotes",
  queryType: "WebQuotesQuery",
  quickFilterComponent: MarketRatesQuickFilter,
  settingsComponent: MarketRatesSettings,
};
