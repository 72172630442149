import { RoundedDataGrid } from "@app-components/DataGrid";
import {
  CenterContent,
  ContentMessage,
  Spinner,
} from "@enfusion-ui/web-components";
import { useOEMSQuotes } from "@enfusion-ui/web-core";
import { faSkullCrossbones } from "@fortawesome/pro-solid-svg-icons";
import { ColDef } from "ag-grid-community";
import * as React from "react";

import QuoteCellRenderer from "./QuoteCellRenderer";

export const colDefs: ColDef[] = [
  { headerName: "Dealer", field: "counterpartyName", width: 200 },
  { headerName: "Selected", field: "", width: 100 },
  { headerName: "Quote Status", field: "quoteStatus", width: 200 },
  { headerName: "Quote Size", field: "quoteSize", width: 200 },
  {
    headerName: "Bid",
    field: "bid",
    width: 200,
    cellRenderer: "quoteCellRenderer",
  },
  {
    headerName: "Ask",
    field: "ask",
    width: 200,
    cellRenderer: "quoteCellRenderer",
  },
  {
    headerName: "Dealable Price",
    field: "dealablePrice",
    width: 200,
    cellRenderer: "quoteCellRenderer",
  },
  { headerName: "Time", field: "time", width: 200 },
  { headerName: "Cancel", field: "", width: 100 },
];

export const QuotesTable: React.FC = () => {
  const { quotes, loading, error } = useOEMSQuotes();
  if (error) {
    return (
      <CenterContent fillHeight>
        <ContentMessage icon={faSkullCrossbones} message={error} />
      </CenterContent>
    );
  } else if (loading) {
    return (
      <CenterContent fillHeight>
        <Spinner />
      </CenterContent>
    );
  }
  return (
    <RoundedDataGrid
      height="100%"
      rowData={quotes}
      columnDefs={colDefs}
      defaultColDef={{
        resizable: true,
        sortable: true,
      }}
      rowSelection="single"
      overlayNoRowsTemplate="No options to display"
      overlayLoadingTemplate="Searching..."
      suppressMovableColumns
      components={{
        quoteCellRenderer: QuoteCellRenderer,
      }}
    />
  );
};

export default QuotesTable;
