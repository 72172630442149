import {
  InstrumentSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType, InstrumentInfo } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import InstrumentMultiSelect from "../../../../../../components/inputs/InstrumentSelect/InstrumentMultiSelect";

const InstrumentSelectionWidget: React.FC<unknown> = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, key } = config as InstrumentSelectionWidgetConfig;
  const [value, setValue] = React.useState<number[] | null>(defaultValue);

  React.useEffect(() => {
    setValue(defaultValue);
    setChannelData(defaultValue ? [defaultValue] : [[]]);
  }, [defaultValue]);

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Instrument IDs",
        type: ChannelDataType.Number,
      },
    ]);
  }, [addChannelKeys, key]);

  const handleInstrumentChange = React.useCallback(
    (instruments: InstrumentInfo[] | null) => {
      const newValue = instruments?.length
        ? instruments.map(({ id }) => id)
        : null;
      setChannelData(newValue ? [newValue] : [[]]);
    },
    []
  );

  return (
    <CenterContent>
      <InstrumentMultiSelect
        label={key}
        value={value}
        onChange={handleInstrumentChange}
      />
    </CenterContent>
  );
};

export default InstrumentSelectionWidget;
