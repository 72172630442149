import { useAnalyticsContext } from "@app-context/analytics/context";
import { getDTDLongTermChartConfig } from "@app-views/analytics/chartConfigs";
import {
  CalloutGrid,
  CalloutGridSeriesEntry,
} from "@app-views/analytics/components/CalloutGrid";
import { SizedChart } from "@app-views/analytics/components/SizedChart";
import { formatPercentage } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const SubContainer = styled(Container)`
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
`;

const TopChart = styled(SizedChart)`
  margin-top: var(--spacing);
`;

const per = (val?: number | null) =>
  formatPercentage(val ?? 0, [0, 2], false, undefined, true);

export const KPISummary: React.FC = () => {
  const { data } = useAnalyticsContext();

  const calloutSeries = React.useMemo(
    () =>
      [
        {
          name: "R^2",
          data: [
            {
              value: data?.risk.kpi.r2.value ?? 0,
              change: data?.risk.kpi.r2.change ?? 0,
            },
          ],
        },
        {
          name: "Sharpe",
          data: [
            {
              value: data?.risk.kpi.sharpe.value ?? 0,
              change: data?.risk.kpi.sharpe.change ?? 0,
            },
          ],
        },
        {
          name: "Carr",
          data: [
            {
              value: data?.risk.kpi.carr.value ?? 0,
              change: data?.risk.kpi.carr.change ?? 0,
            },
          ],
        },
      ] as CalloutGridSeriesEntry[],
    [data?.risk.kpi.r2, data?.risk.kpi.sharpe, data?.risk.kpi.carr]
  );

  const volatility = React.useMemo(
    () => data?.risk.kpi.volatility ?? null,
    [data?.risk.kpi.volatility]
  );

  const beta = React.useMemo(
    () => data?.risk.kpi.beta ?? null,
    [data?.risk.kpi.beta]
  );

  const getOptionsV = React.useCallback(
    (width: number) =>
      getDTDLongTermChartConfig(width, "Volatility", per, [
        [volatility?.data.dtd ?? 0],
        [volatility?.data.longTerm ?? 0],
      ]),
    [volatility?.data]
  );

  const getOptionsB = React.useCallback(
    (width: number) =>
      getDTDLongTermChartConfig(width, "Beta", per, [
        [beta?.data.dtd ?? 0],
        [beta?.data.longTerm ?? 0],
      ]),
    [beta?.data]
  );

  return (
    <Container>
      <TopRow>KPIs</TopRow>
      <SubContainer>
        <CalloutGrid series={calloutSeries} />
        <TopChart getOptions={getOptionsV} />
        <SizedChart getOptions={getOptionsB} />
      </SubContainer>
    </Container>
  );
};
