import { TrashIconButtonCellRenderer } from "@app-components/cells";
import { RoundedDataGrid } from "@app-components/DataGrid";
import { useFormTableListContext } from "@app-utils/useFormTableListContext";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  lotReliefRadioOptions,
  LotReliefType,
  TradeTicketFormValues,
  useTradeTicket,
} from "@enfusion-ui/trades";
import {
  Checkbox,
  ControlledEmpty,
  FormElement,
  FormPanel,
  PanelRow,
  RadioGroup,
} from "@enfusion-ui/web-components";
import { styled, useIsContentMobile } from "@enfusion-ui/web-core";
import { ColDef, IRowNode } from "ag-grid-community";
import * as React from "react";
import { useWatch } from "react-hook-form";

import { FullHeightContainer } from "../components/styledComponents";

const StylePanelRow = styled(PanelRow)`
  flex-wrap: wrap;
  gap: var(--spacing-xl);
  align-items: flex-start;
`;

const columnDefs: ColDef[] = [
  {
    headerName: "",
    field: "selection",
    width: 40,
    pinned: "left",
    lockPinned: true,
    resizable: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: "Event Sequence",
    field: "eventSequence",
    initialWidth: 120,
  },
  {
    headerName: "Original Lot Opening",
    field: "originalLotOpening",
    initialWidth: 120,
  },
  {
    headerName: "Event Date",
    field: "eventDate",
    initialWidth: 120,
  },
  {
    headerName: "Event Type",
    field: "eventType",
    initialWidth: 120,
  },
  {
    headerName: "Trade Quantity",
    field: "tradeQuantity",
    initialWidth: 120,
  },
  {
    headerName: "Open Lot Quantity",
    field: "openLotQuantity",
    initialWidth: 120,
  },
  {
    headerName: "Native Unit Cost",
    field: "nativeUnitCost",
    initialWidth: 120,
  },
  {
    width: 40,
    headerName: "",
    field: "delete",
    pinned: "right",
    lockPinned: true,
    resizable: false,
    type: "removeAction",
    cellStyle: { padding: "0" },
  },
];

export const LotReliefTabLotPanel = () => {
  const [lotReliefType, setLotReliefType] = React.useState<LotReliefType>(
    lotReliefRadioOptions[0].value
  );
  const [showAllLots, setShowAllLots] = React.useState<boolean>(true);

  const { lotReliefs = [] } = useWatch<TradeTicketFormValues>({
    name: ["lotReliefs"],
  });
  const { lotReliefsControl } = useTradeTicket();

  const isMobile = useIsContentMobile();

  const onClickTrashIconCell = useRefCallback((node: IRowNode) => {
    lotReliefsControl.deleteRows([node.data.__row_id]);
  }, []);

  const getContextMenuItems = useFormTableListContext(lotReliefsControl);

  return (
    <>
      <ControlledEmpty name="lotReliefs" />
      <FormPanel
        numColumns={1}
        title="Lot Relief"
        style={{ height: "100%", minHeight: "15rem" }}
        contentStyle={{ height: "100%" }}
      >
        <FullHeightContainer>
          <StylePanelRow>
            <FormElement basis="max-content">
              <RadioGroup
                vertical={isMobile}
                name="lotReliefType"
                value={lotReliefType}
                label="Lot Relief Type:"
                options={lotReliefRadioOptions}
                inline={!isMobile}
                onChange={(value) => setLotReliefType(value as LotReliefType)}
              />
            </FormElement>

            <FormElement
              basis="max-content"
              mobileBasis="100%"
              style={{ flexGrow: 0 }}
            >
              <Checkbox
                name="showAllLots"
                label="Show All Lots"
                checked={showAllLots}
                labelPlacement="right"
                onChange={setShowAllLots}
              />
            </FormElement>
          </StylePanelRow>

          <RoundedDataGrid
            height="100%"
            suppressCellFocus
            multiSortKey="ctrl"
            rowData={lotReliefs}
            rowSelection="multiple"
            columnDefs={columnDefs}
            suppressRowClickSelection
            getRowId={lotReliefsControl.getRowId}
            getContextMenuItems={getContextMenuItems}
            defaultColDef={{
              sortable: true,
              resizable: true,
              suppressMovable: true,
            }}
            columnTypes={{
              removeAction: {
                cellRenderer: TrashIconButtonCellRenderer,
                cellRendererParams: {
                  onClick: onClickTrashIconCell,
                },
              },
            }}
          />
        </FullHeightContainer>
      </FormPanel>
    </>
  );
};
