import { TutorialType } from "@app-components/Tour/utils";
import { DashboardFolderProvider } from "@app-context/dashboards/DashboardFolderProvider";
import { INTERNAL_PO_IDS } from "@enfusion-ui/core";
import { TradeTicketFormType, TradeTicketFormTypes } from "@enfusion-ui/trades";
import { DashboardRoot, NodeData } from "@enfusion-ui/types";
import { AccordionListView } from "@enfusion-ui/web-components";
import {
  IS_LOCAL,
  styled,
  TabsContextState,
  useAuth,
  useTabs,
} from "@enfusion-ui/web-core";
import {
  faChartMixedUpCircleDollar,
  faInfo,
} from "@fortawesome/pro-solid-svg-icons";
import { startCase } from "lodash";
import * as React from "react";

import DashboardsExplorerProvider from "../../context/dashboards/DashboardsExplorerProvider";
import CreateDashboardFolderModal from "./components/CreateDashboardFolderModal";
import CreateDashboardModal from "./components/CreateDashboardModal";
import DashboardsExplorer from "./DashboardsExplorer";

const createExplorer = (
  root: DashboardRoot,
  extraNodes?: NodeData[],
  onExtraNodeClick?: (node: NodeData) => void,
  tutorial?: TutorialType | null,
  disableEdit?: boolean
) =>
  React.memo(function ReportFileExplorer({ open }: { open: boolean }) {
    return (
      <DashboardFolderProvider root={root}>
        <DashboardsExplorer
          root={root}
          open={open}
          tutorial={tutorial}
          disableEdit={disableEdit}
          extraNodes={extraNodes}
          onExtraNodeClick={onExtraNodeClick}
        />
      </DashboardFolderProvider>
    );
  });

const defaultOpen = { user: true, shared: true, global: true };

function getTradeEntry(formType: string) {
  return formType
    ? {
        id: formType,
        name: startCase(formType),
        path: `global/ExampleTradeTicket/Equity/${formType}-ticket/`,
        file: true,
        extraNode: true,
      }
    : (null as unknown as NodeData);
}

const items = (openTab: TabsContextState["openTab"], extra = IS_LOCAL) => {
  return [
    {
      key: "user",
      title: "My Dashboards",
      contentComponent: createExplorer("user"),
    },
    {
      key: "shared",
      title: "Shared Dashboards",
      contentComponent: createExplorer("shared"),
    },
    {
      key: "global",
      title: "Global Dashboards",
      contentComponent: createExplorer(
        "global",
        [
          ...(extra
            ? [
                {
                  id: "ExampleChartViews",
                  name: "Example Chart Views",
                  path: "global/ExampleChartViews/",
                  nodes: [
                    {
                      id: "SunburstChart",
                      name: "Sunburst / TreeMap Charts",
                      path: "global/ExampleChartViews/SunburstChart",
                      file: true,
                      extraNode: true,
                    },
                  ],
                },
                {
                  id: "ExampleAnalyticsDashboards",
                  name: "Example Analytics Dashboards",
                  path: "global/ExampleAnalyticsDashboards",
                  nodes: [
                    {
                      id: "AnalyticsDashboard",
                      name: "Analytics Dashboard",
                      path: "global/ExampleAnalyticsDashboards/AnalyticsDashboard",
                      file: true,
                      extraNode: true,
                    },
                  ],
                },
                {
                  id: "ExampleConfluenceView",
                  name: "Example Confluence Views",
                  path: "global/ExampleConfluenceView",
                  nodes: [
                    {
                      id: "TestConfluenceView",
                      name: "Test Confluence View",
                      path: "global/ExampleConfluenceView/TestConfluenceView",
                      file: true,
                      extraNode: true,
                    },
                  ],
                },
                process.env.REACT_APP_ENABLE_TRADE_TICKET?.length
                  ? {
                      id: "ExampleTradeTicket",
                      name: "Example Trade Ticket",
                      path: "global/ExampleTradeTicket",
                      nodes: [
                        {
                          id: "Equity",
                          name: "Equity",
                          path: "global/ExampleTradeTicket/Equity",
                          nodes: [
                            ...(process.env.REACT_APP_ENABLE_TRADE_TICKET ===
                            "ALL"
                              ? Object.values(TradeTicketFormTypes).map(
                                  getTradeEntry
                                )
                              : process.env.REACT_APP_ENABLE_TRADE_TICKET.split(
                                  ","
                                ).map((key) => {
                                  const formType =
                                    TradeTicketFormTypes[
                                      key as keyof typeof TradeTicketFormTypes
                                    ];

                                  return getTradeEntry(formType);
                                })
                            ).filter((i) => i !== null),
                          ],
                        },
                      ],
                    }
                  : (null as unknown as NodeData),
              ].filter((i) => i !== null)
            : []),
          ...(process.env.REACT_APP_ENABLE_RECON_DASHBOARD
            ? [
                {
                  id: "ExampleReconciliationDashboards",
                  name: "Example Reconciliation Dashboards",
                  path: "global/ExampleReconDashboards",
                  nodes: [
                    {
                      id: "ReconciliationDashboard",
                      name: "Reconciliation Dashboard",
                      path: "global/ExampleReconciliationDashboards/ReconciliationDashboard",
                      file: true,
                      extraNode: true,
                    },
                  ],
                },
              ]
            : []),
        ],
        (node: NodeData) => {
          if (node.id === "ReconciliationDashboard") {
            openTab({
              name: "Reconciliation Dashboard",
              component: "reconciliation-view",
              unique: "reconciliation-view",
              icon: faChartMixedUpCircleDollar,
            });
          }
          if (extra) {
            if (node.id === "SunburstChart") {
              openTab({
                name: "Sunburst / TreeMap Test Charts",
                component: "sunburst-view",
                unique: "sunburst-view",
              });
            } else if (node.id === "AnalyticsDashboard") {
              openTab({
                name: "Analytics Dashboard",
                component: "analytics-view",
                unique: "analytics-view",
                icon: faChartMixedUpCircleDollar,
              });
            } else if (node.id === "TestConfluenceView") {
              openTab({
                name: "Test Confluence View",
                component: "confluence-view",
                unique: "test-confluence-view",
                icon: faInfo,
              });
            } else if (
              Object.values(TradeTicketFormTypes).includes(
                node.id as TradeTicketFormType
              )
            ) {
              openTab({
                name: startCase(node.id),
                component: "new-trade-ticket",
                unique: node.id,
                config: {
                  formType: node.id,
                },
              });
            }
          }
        }
      ),
    },
  ];
};

const DashboardsSidebarContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 214px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const DashboardsSidebarTitle = styled.div`
  height: 40px;
  padding: 0 0.25rem 0 0.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const DashboardsSidebar: React.FC<{ tutorial?: TutorialType | null }> = ({
  tutorial,
}) => {
  const { isUserType, user, isPoId } = useAuth();
  const { openTab } = useTabs();
  const listItems = React.useMemo(() => {
    return isUserType("Express")
      ? [
          {
            key: "global",
            title: "Express Dashboards",
            contentComponent: createExplorer(
              "global/fixed",
              undefined,
              undefined,
              null,
              true
            ),
          },
        ]
      : items(openTab, isPoId(INTERNAL_PO_IDS.Enfusion));
  }, [user?.flags?.userType, openTab]);
  return (
    <DashboardsSidebarContainer
      data-tutorial-step={
        tutorial ? `${tutorial}__Dashboards-sidebar` : undefined
      }
    >
      <DashboardsSidebarTitle data-e2e-id="dashboard-sidebar-title">
        Dashboards Explorer
      </DashboardsSidebarTitle>
      <DashboardsExplorerProvider>
        <AccordionListView defaultOpen={defaultOpen} items={listItems} />

        <DashboardFolderProvider>
          <CreateDashboardModal />
          <CreateDashboardFolderModal />
        </DashboardFolderProvider>
      </DashboardsExplorerProvider>
    </DashboardsSidebarContainer>
  );
};

export default DashboardsSidebar;
