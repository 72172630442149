import { basicInfoMap, useRestAbortableOptions } from "@enfusion-ui/hooks";
import { ControlledSelect } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";

const MarginFundSelect = () => {
  const { options, loading } = useRestAbortableOptions(
    REST_API.LEGALENTITY.GET_MARGIN_FUNDS.FETCH,
    basicInfoMap
  );

  return (
    <ControlledSelect
      name="marginFundId"
      options={options}
      label="Margin Source"
      isLoading={loading}
    />
  );
};

export default MarginFundSelect;
