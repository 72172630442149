import { OrderDetailsPanel } from "@app-views/oems/OrderTicket/panels/OrderDetailsPanel";
import { useWidget } from "@enfusion-ui/dashboards";
import { ColumnGrid } from "@enfusion-ui/web-components";
import { useIsContentMobile } from "@enfusion-ui/web-core";

export const OrderDetailsWidget = () => {
  const isMobile = useIsContentMobile();
  const { config } = useWidget();
  const { input, showCalculationInfoText } = config;
  return (
    <ColumnGrid isMobile={isMobile} numColumns={2}>
      <OrderDetailsPanel
        disabled={false}
        input={input}
        showCalculationInfoText={showCalculationInfoText}
      />
    </ColumnGrid>
  );
};
