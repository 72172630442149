/* eslint-disable @typescript-eslint/no-explicit-any */
import ReportLoadingStatus from "@app-components/display/ReportLoadingStatus";
import { FlexGroup, InputWrapper } from "@app-views/dashboards/widgets/styles";
import { ParamConfig } from "@app-views/reports/components/ReportQueryComponents/config/FormConfig";
import ReportQueryForm, {
  SimpleForm,
} from "@app-views/reports/components/ReportQueryForm";
import { ReportWidgetQueryFormContext } from "@app-views/reports/components/ReportQueryForms/context";
import ReportQueryFormWidget from "@app-views/reports/components/ReportQueryForms/ReportQueryFormWidget";
import { EMPTY_RECORD } from "@enfusion-ui/core";
import { useDashboard, useWidget } from "@enfusion-ui/dashboards";
import {
  ChannelDataIdentifier,
  ChannelDataType,
  NodeData,
} from "@enfusion-ui/types";
import { createTestId } from "@enfusion-ui/utils";
import {
  AccentBarAccordion,
  Button,
  CenterContent,
  Checkbox,
  ControlledSelect,
  ReportSelect,
} from "@enfusion-ui/web-components";
import { styled, useAuth, useReports } from "@enfusion-ui/web-core";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { omit, pick } from "lodash";
import * as React from "react";
import { FieldErrors } from "react-hook-form";

import ChannelDataPicker from "./ChannelDataPicker";

const ConfigButton = styled(Button)`
  background-color: var(--input-background) !important;
`;

const MenuButton = styled(ConfigButton)``;

const DatasourcePickerCore: React.FC<
  React.PropsWithChildren<{
    label?: string;
    name?: string;
    value?: string | null;
    errors?: FieldErrors;
    onChange: (value: string | null) => void;
    loading?: boolean;
    e2eTestId?: string;
    steps: any;
  }>
> = ({
  value,
  label = "Datasource",
  name = "datasourceId",
  errors,
  onChange,
  loading = false,
  children,
  e2eTestId,
  steps,
}) => {
  const { hasPerm } = useAuth();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [configOpen, setConfigOpen] = React.useState(false);
  const { getDatasource, addDatasource, root } = useDashboard();

  React.useEffect(() => {
    if (children || loading) setConfigOpen(true);
  }, [children, loading]);

  const handleSelectChange = (node?: NodeData | null, key?: string) => {
    if (node) {
      const summary = pick(node, ["name", "path", "pathParams"]);
      const type = summary.path.startsWith("fixed/")
        ? "fixed-report"
        : "report";
      const id = addDatasource({ type, key, summary });
      if (id) onChange(id);
    } else if (node === null) onChange(null);
  };

  const datasource = React.useMemo(
    () => (value ? getDatasource(value) : undefined),
    [value]
  );

  const selected = React.useMemo(() => {
    if (datasource) {
      return {
        id: datasource.id,
        name: datasource.name,
        path: datasource.path,
        key: datasource.key,
        pathParams: datasource.pathParams,
        file: true,
      } as NodeData;
    }
    return undefined;
  }, [datasource]);

  const icons = (
    <div style={{ display: "flex", marginRight: "1px" }}>
      <MenuButton
        icon={faChevronDown}
        onClick={() => setMenuOpen((prev) => !prev)}
      />
    </div>
  );

  return (
    <>
      <ReportSelect
        label={label}
        name={name}
        errors={errors}
        value={selected}
        onSelect={handleSelectChange}
        iconContainer={icons}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        showFixedReports={hasPerm("DashboardEditor")}
        showMyReports={root === "user"}
        showSharedReports={root && ["user", "shared"].includes(root)}
        data-e2e-id={e2eTestId}
        data-testid={createTestId(e2eTestId ?? "data-source-picker")}
      />
      <AccentBarAccordion
        style={{ padding: 0, marginTop: "var(--spacing)" }}
        defaultOpen={configOpen}
        title="Datasource Config"
      >
        {loading && steps ? (
          <CenterContent style={{ height: 150 }}>
            <ReportLoadingStatus steps={steps} />
          </CenterContent>
        ) : (
          <>{children}</>
        )}
      </AccentBarAccordion>
    </>
  );
};

export const widgetWithDatasourceDefaultConfig = {
  datasourceId: null,
  subTableDatasourceChannelId: null,
};

export type WidgetWithDatasourceConfig = {
  datasourceId: string | null;
  subTableDatasourceChannelId: ChannelDataIdentifier | null;
  paramChannelIds?: Record<string, ChannelDataIdentifier | null>;
  paramStaticValues?: Record<string, string | Date | null>;
  reportParams?: any;
  urlParams?: any;
  isStaticFilterSelection?: Record<string, boolean>;
};

const FormElement = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
`;

const options = {
  pnl: [
    {
      name: "groupingType",
      staticType: "--custom--",
      dynamicType: ChannelDataType.String,
      element: (
        <ControlledSelect
          name="groupingType"
          label="Grouping Type"
          options={[
            { label: "By Instrument", value: "Instrument" },
            { label: "By Instrument Type", value: "InstrumentType" },
            { label: "By Sector", value: "Sector" },
            { label: "By Region", value: "Region" },
            { label: "By Market Cap", value: "MarketCap" },
            { label: "By Account", value: "Account" },
            { label: "By Country", value: "Country" },
          ]}
          inputId="datasource-picker-pnl-grouping-type-selection-id"
        />
      ),
    },
  ] as ParamConfig[],
  trade: [
    {
      name: "groupingType",
      staticType: "--custom--",
      dynamicType: ChannelDataType.String,
      element: (
        <ControlledSelect
          name="groupingType"
          label="Grouping Type"
          options={[
            { label: "By Purchases & Sales", value: "PurchasesSales" },
            { label: "By Cash Flows", value: "CashFlows" },
            { label: "By Cash Balance", value: "CashBalances" },
          ]}
          inputId="datasource-picker-trade-grouping-type-selection-id"
        />
      ),
    },
    {
      name: "startDate",
      staticType: "valueDate",
      dynamicType: ChannelDataType.DateSelection,
    },
    {
      name: "endDate",
      staticType: "valueDate",
      dynamicType: ChannelDataType.DateSelection,
    },
  ] as ParamConfig[],
  taxlots: [
    {
      name: "groupingType",
      staticType: "--custom--",
      dynamicType: ChannelDataType.String,
      element: (
        <ControlledSelect
          name="groupingType"
          label="Grouping Type"
          options={[
            { label: "By Open", value: "Open" },
            { label: "By Closed", value: "Closed" },
          ]}
          inputId="datasource-picker-taxlots-grouping-type-selection-id"
        />
      ),
    },
  ] as ParamConfig[],
  cptycommission: [
    {
      name: "startDate",
      staticType: "valueDate",
      dynamicType: ChannelDataType.DateSelection,
    },
    {
      name: "endDate",
      staticType: "valueDate",
      dynamicType: ChannelDataType.DateSelection,
    },
  ] as ParamConfig[],
  position: [
    {
      name: "groupingType",
      staticType: "--custom--",
      dynamicType: ChannelDataType.String,
      element: (
        <ControlledSelect
          name="groupingType"
          label="Grouping Type"
          options={[
            { label: "Daily Losers", value: "DailyLosers" },
            { label: "Daily Winners", value: "DailyWinners" },
            { label: "Exposure", value: "Exposure" },
            {
              label: "Exposure by Instrument Subtype",
              value: "ExposureBySubtype",
            },
            {
              label: "Exposure by Long / Short",
              value: "ExposureByLongShort",
            },
            { label: "Position Appraisal", value: "Appraisal" },
            {
              label: "Position Appraisal by Instrument Subtype",
              value: "AppraisalBySubtype",
            },
            { label: "Profit and Loss", value: "PnL" },
            {
              label: "Profit and Loss by Instrument Subtype",
              value: "PnLBySubtype",
            },
          ]}
          inputId="datasource-picker-position-grouping-type-selection-id"
        />
      ),
    },
  ] as ParamConfig[],
};

const FixedReportOptions: React.FC<{
  report: string;
  onFormValuesChanged: (values: Record<string, any>) => void;
  initialValues: Record<string, any>;
}> = ({ report, onFormValuesChanged, initialValues = {} }) => {
  const configs = React.useMemo(() => {
    return (
      options[
        report
          .replace("fixed/", "")
          .split("?")[0] as unknown as keyof typeof options
      ] ?? []
    );
  }, [report]);

  return (
    <SimpleForm
      defaultValues={{}}
      initValues={initialValues}
      onFormValuesChanged={onFormValuesChanged}
      keysToWatch={configs.map((i) => i.name)}
      direction="column"
    >
      {configs.map((eachConfig: ParamConfig, idx: number) => (
        <FormElement key={eachConfig.name} tabIndex={idx}>
          <ReportQueryFormWidget
            reportQuery={(eachConfig as any).element}
            param={eachConfig}
            key={eachConfig.name}
            allowFieldModeChange
          />
        </FormElement>
      ))}
    </SimpleForm>
  );
};

const DatasourcePicker: React.FC<{
  e2eTestId?: string;
  canUseSubTable?: boolean;
  onDatasourceChange?: () => Record<string, any>;
}> = ({ onDatasourceChange, canUseSubTable = false, e2eTestId }) => {
  const { hasPerm, isAdmin, isInternalPo } = useAuth();
  const { getDatasource } = useDashboard();
  const { editedConfig, changeConfigKeyToBeApplied, changeConfigToBeApplied } =
    useWidget();
  const { dataStore, metaStore } = useReports();

  const isGlobalAdmin = hasPerm("DashboardEditor");
  const adminUser = isGlobalAdmin || isAdmin() || isInternalPo();

  const {
    datasourceId,
    reportParams = EMPTY_RECORD,
    urlParams = EMPTY_RECORD,
    subTableDatasourceChannelId,
  } = editedConfig as WidgetWithDatasourceConfig;

  const [useSubTable, setUseSubTable] = React.useState(
    () =>
      subTableDatasourceChannelId !== null &&
      typeof subTableDatasourceChannelId !== "undefined"
  );

  const metadata = datasourceId ? metaStore[datasourceId] : null;
  const datasourceErrors = metadata ? metadata.error : null;

  const datasource = React.useMemo(
    () => (datasourceId ? getDatasource(datasourceId) : undefined),
    [datasourceId]
  );

  const datasourceOptions = datasource ? dataStore[datasource.id] : null;
  const reportQuery = datasource
    ? metaStore[datasource.id]?.reportQuery
    : undefined;

  const handleDatasourceChange = React.useCallback(
    (value: any) => {
      const isDataSourcePathChanged =
        datasource?.path !== getDatasource(value)?.path;
      changeConfigToBeApplied({
        ...editedConfig,
        datasourceId: value,
        column: undefined,
        reportParams: {},
        ...(isDataSourcePathChanged ? onDatasourceChange?.() ?? {} : {}),
      });
    },
    [editedConfig, onDatasourceChange]
  );

  const handleSubTableChange = React.useCallback(
    (value: any) => {
      changeConfigToBeApplied({
        ...editedConfig,
        subTableDatasourceChannelId: value,
        ...(onDatasourceChange?.() ?? {}),
      });
    },
    [editedConfig, onDatasourceChange]
  );

  return (
    <>
      <FlexGroup>
        {canUseSubTable && (
          <div style={{ width: 200 }}>
            <Checkbox
              name="canUseSubTable"
              label="Use Sub Table"
              labelPlacement="right"
              checked={useSubTable}
              topLabelPlaceholder
              onChange={(checked) => {
                if (!checked) handleSubTableChange(null);
                setUseSubTable(checked);
              }}
            />
          </div>
        )}
        <InputWrapper>
          <ChannelDataPicker
            disabled={!useSubTable}
            type={ChannelDataType.SubTableDatasource}
            name="subTableDatasourceChannelId"
            label="Sub Table Datasource"
            value={subTableDatasourceChannelId}
            onChange={handleSubTableChange}
          />
        </InputWrapper>
      </FlexGroup>
      {!useSubTable && (
        <DatasourcePickerCore
          name={datasource ? datasource.id : "dataSource"}
          errors={
            datasourceErrors
              ? {
                  [datasource ? datasource.id : "dataSource"]: {
                    type: "manual",
                    message: datasourceErrors,
                  },
                }
              : {}
          }
          value={datasourceId}
          onChange={handleDatasourceChange}
          steps={metadata?.progressSteps}
          loading={
            !!(datasource && !datasourceErrors && !datasourceOptions) ||
            metadata?.loading
          }
          e2eTestId={e2eTestId}
        >
          {isGlobalAdmin &&
            datasource &&
            datasource.type === "fixed-report" && (
              <FixedReportOptions
                report={datasource.path}
                onFormValuesChanged={(query) => {
                  changeConfigKeyToBeApplied("urlParams", query);
                }}
                initialValues={urlParams}
              />
            )}
          {datasourceOptions && reportQuery && (
            <>
              <div data-e2e-id="report-filter-form">
                <ReportWidgetQueryFormContext.Provider
                  value={{
                    allowFieldModeChange: adminUser,
                    defaultFieldMode: adminUser ? undefined : "static",
                  }}
                >
                  <ReportQueryForm
                    reportQuery={reportQuery}
                    asWidget
                    onFormValuesChanged={(query) => {
                      changeConfigKeyToBeApplied(
                        "reportParams",
                        omit(
                          query,
                          datasource?.type === "fixed-report"
                            ? ["accountSelection"]
                            : []
                        ) as any
                      );
                    }}
                    initValues={
                      Object.keys(reportParams).length > 0
                        ? reportParams
                        : reportQuery
                    }
                    fixedReport={datasource?.type === "fixed-report"}
                  />
                </ReportWidgetQueryFormContext.Provider>
              </div>
            </>
          )}
        </DatasourcePickerCore>
      )}
    </>
  );
};

export default DatasourcePicker;
