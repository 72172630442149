import { EMPTY_ARRAY } from "@enfusion-ui/core";
import {
  AccountSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType, NodeData } from "@enfusion-ui/types";
import { uniq } from "lodash";
import * as React from "react";

import AccountSelect from "../../../../../../components/inputs/account/AccountSelect";

export const reduceFundNodes = (nodes: NodeData[]) => {
  return nodes.reduce(
    (res, data) => {
      switch (data.level) {
        case "Custodian":
          if (data.nodes?.length) {
            res["accountIds"] = [
              ...res["accountIds"],
              ...data.nodes.map((x) => Number(x.id)),
            ];
          }
          break;
        case "Account":
          res["accountIds"].push(Number(data.accountId));
          break;
        case "Fund":
          res["fundIds"].push(Number(data.fundId));
          break;
        case "FundContainer":
          if (data.nodes?.length) {
            res["fundIds"] = [
              ...res["fundIds"],
              ...data.nodes.map((x) => Number(x.fundId)),
            ];
          }
          break;
      }
      return res;
    },
    { accountIds: [], fundIds: [] } as {
      accountIds: Array<number>;
      fundIds: Array<number>;
    }
  );
};

const AccountSelectionWidget: React.FC = React.memo(
  function AccountSelectionWidget() {
    const [selectedIds, setSelectedIds] = React.useState<string[]>();

    const { config, setChannelData, addChannelKeys } = useWidget();
    const { defaultValues = EMPTY_ARRAY, key = "Accounts" } =
      config as AccountSelectionWidgetConfig;

    React.useEffect(() => {
      const ids = reduceFundNodes(defaultValues ?? []);
      setSelectedIds([
        ...ids.accountIds.map((x) => `${x}`),
        ...ids.fundIds.map((x) => `${x}`),
      ]);
      setChannelData([
        { accountIds: uniq(ids.accountIds), fundIds: uniq(ids.fundIds) },
      ]);
    }, [JSON.stringify(defaultValues)]);

    React.useEffect(() => {
      addChannelKeys([
        {
          name: key,
          description: "Accounts",
          type: ChannelDataType.Number,
        },
      ]);
    }, [key]);

    const handleOnChange = React.useCallback(
      (nodes: NodeData[]) => {
        const ids = reduceFundNodes(nodes);
        setChannelData([
          { accountIds: uniq(ids.accountIds), fundIds: uniq(ids.fundIds) },
        ]);
      },
      [setChannelData]
    );

    return (
      <AccountSelect
        values={selectedIds}
        label={key}
        onChange={handleOnChange}
        clearable
      />
    );
  }
);

export default AccountSelectionWidget;
