import { createSelectOptions } from "@enfusion-ui/utils";
import { SpreadCalcMethodValues, } from "./types";
export const TradeTicketFormTypes = {
    GENERAL_LISTED: "generalListed",
    EQUITY: "equity",
    FUTURE: "future",
    INDEX: "index",
    EQUITY_OPTIONS: "equityOptions",
    INDEX_OPTIONS: "indexOptions",
    FUTURE_OPTIONS: "futureOptions",
    WARRANT: "warrant",
    OTC_OPTION: "otcOption",
    EQUITY_FLEX_OPTION: "equityFlexOption",
    OTC_SPREAD_OPTIONS: "otcSpreadOption",
    OTC_OUTPERFORMANCE_OPTION: "otcOutperformanceOption",
    ASSET_SWAP_CONVERTIBLE_OPTION: "assetSwapConvertibleOption",
    OTC_REALIZED_VARIANCE_OPTION: "otcRealizedVarSwap",
    NEW_DUAL_DIGITAL_OPTION: "newDualDigitalOption",
    VARIANCE_SWAP: "varianceSwap",
    COLLATERAL_PLEDGE: "collateralPledge",
    DIVIDEND_SWAP: "dividendSwap",
    STOCK_LOAN_BORROW: "stockLoanBorrow",
};
// TODO get real values form the api
export const COMMISSION_OPTIONS = [
    {
        label: "Clearing Commission",
        value: "ClearingCommission",
    },
    {
        label: "Clearing Commission (Auto Calculate)",
        value: "ClearingCommissionAutoCalculate",
    },
    {
        label: "Clearing Commission (Basis Points)",
        value: "ClearingCommissionBasisPoints",
    },
    {
        label: "Clearing Commission (Per Share)",
        value: "ClearingCommissionPerShare",
    },
    {
        label: "Commission (Auto Calculate)",
        value: "CommissionAutoCalculate",
    },
    {
        label: "Commission (Basis Points)",
        value: "CommissionBasisPoints",
    },
    {
        label: "Commission (Cents Per Share)",
        value: "CommissionCentsPerShare",
    },
    {
        label: "Commission (Flat)",
        value: "CommissionFlat",
    },
    {
        label: "Commission (Per Notional Unit)",
        value: "CommissionPerNotionalUnit",
    },
    {
        label: "Commission (Per Share)",
        value: "CommissionPerShare",
    },
    {
        label: "End Broker Commission (Auto Calculate)",
        value: "EndBrokerCommissionAutoCalculate",
    },
    {
        label: "End Broker Commission (Basis Points)",
        value: "EndBrokerCommissionBasisPoints",
    },
    {
        label: "End Broker Commission (Cents Per Share)",
        value: "EndBrokerCommissionCentsPerShare",
    },
    {
        label: "End Broker Commission (Flat)",
        value: "EndBrokerCommissionFlat",
    },
    {
        label: "End Broker Commission (Per Notional Unit)",
        value: "EndBrokerCommissionPerNotionalUnit",
    },
    {
        label: "End Broker Commission (Per Share)",
        value: "EndBrokerCommissionPerShare",
    },
    {
        label: "Soft Dollar Commission (Auto Calculate)",
        value: "SoftDollarCommissionAutoCalculate",
    },
    {
        label: "Soft Dollar Commission (Basis Points)",
        value: "SoftDollarCommissionBasisPoints",
    },
    {
        label: "Soft Dollar Commission (Cents Per Share)",
        value: "SoftDollarCommissionCentsPerShare",
    },
    {
        label: "Soft Dollar Commission (Flat)",
        value: "SoftDollarCommissionFlat",
    },
    {
        label: "Soft Dollar Commission (Per Notional Unit)",
        value: "SoftDollarCommissionPerNotionalUnit",
    },
    {
        label: "Soft Dollar Commission (Per Share)",
        value: "SoftDollarCommissionPerShare",
    },
];
export const callPutsInputOptions = [
    { label: "Call", value: "Call" },
    { label: "Put", value: "Put" },
];
export const exerciseTypeOptions = [
    { label: "American", value: "American" },
    { label: "European", value: "European" },
    { label: "Bermudan", value: "Bermudan" },
];
export const settlementTypeOptions = [
    { label: "None", value: "None" },
    { label: "Physical", value: "Physical" },
    { label: "Cash", value: "Cash" },
];
export const cutOptions = [
    { value: "Auckland", label: "Auckland 16:00" },
    { value: "Basil", label: "Basil 17:00" },
    { value: "Bermudan", label: "Bermudan 18:00" },
    { value: "Tokyo", label: "Tokyo 19:00" },
];
export const orderTypeOptions = [
    {
        label: "Buy",
        value: "Buy",
    },
    {
        label: "Sell",
        value: "Sell",
    },
    {
        label: "Buy to Cover",
        value: "BuyToCover",
    },
    {
        label: "Sell Short",
        value: "SellShort",
    },
];
export const transactionsSelectOptions = [
    {
        label: "Buy",
        value: "Buy",
    },
    {
        label: "Sell",
        value: "Sell",
    },
];
export const spreadCalcMethodOptions = createSelectOptions(SpreadCalcMethodValues);
export const rateTypeOptions = createSelectOptions([
    "Fixed",
    "Variable",
]);
const discountCurveOptionsArr = [
    "0% Discounting",
    "Use Default",
    "DZ IR Crv OIS 00",
];
export const discountCurveOptions = createSelectOptions(discountCurveOptionsArr);
export const ORDER_TYPE_OPTIONS = [
    {
        label: "Buy",
        value: "Buy",
    },
    {
        label: "Sell",
        value: "Sell",
    },
    {
        label: "Buy to Cover",
        value: "BuyToCover",
    },
    {
        label: "Sell Short",
        value: "SellShort",
    },
];
export const BTN_ORDER_TYPE_OPTIONS = [
    {
        label: "B",
        value: "Buy",
    },
    {
        label: "S",
        value: "Sell",
    },
    {
        label: "BTC",
        value: "BuyToCover",
    },
    {
        label: "SS",
        value: "SellShort",
    },
];
export const SINGLE_BARRIER_RADIO = "SingleBarrier";
export const CONDITIONAL_RADIO = "Conditional";
export const CORRIDOR_RADIO = "Corridor";
export const NONE_RADIO = "None";
export const underlyingBtnOptions = [
    {
        label: "Underlying Instrument",
        value: "UnderlyingInstrument",
    },
    {
        label: "Alternative Barrier Instrument",
        value: "Alternative Barrier Instrument",
    },
];
export const underlyingBtnOptionsShort = [
    {
        label: "Underlying",
        value: "UnderlyingInstrument",
    },
    {
        label: "Alternative",
        value: "Alternative Barrier Instrument",
    },
];
export const conventionBtnOptions = [
    { label: "T", value: "T" },
    { label: "T-1", value: "T1" },
    { label: "T&T-1", value: "T&t1" },
];
export const knockOutBtnOptions = [
    { label: "Including KO date", value: "IncludingKODate" },
    { label: "Excluding KO date", value: "ExcludingKODate" },
    { label: "Zero Payout", value: "ZeroPayout" },
];
export const knockOutBtnOptionsShort = [
    { label: "Including", value: "IncludingKODate" },
    { label: "Excluding", value: "ExcludingKODate" },
    { label: "Zero", value: "ZeroPayout" },
];
export const barrierTypeSelectOptions = [
    { label: "Up/In", value: "UpIn" },
    { label: "Down/In", value: "DownIn" },
    { label: "Up/Out", value: "UpOut" },
    { label: "Down/Out", value: "DownOut" },
];
export const INSTRUMENT_INPUT_NAME = "instrument";
