import {
  PerformanceDetailsInstrumentValue,
  TopNGridEntry,
} from "@app-views/analytics/types";
import * as React from "react";

export type AnalyticsCurrencyEntry = {
  value: number;
  change?: number | null;
  currency?: string | null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnalyticsChartEntry<T = any> = {
  data: T;
};

export type AnalyticsPivotData = Record<
  string,
  { return: number; exposure: number }
>;

export type AnalyticsData = {
  pivots: {
    assetClass: AnalyticsPivotData;
    gicSector: AnalyticsPivotData;
    instrumentType: AnalyticsPivotData;
    issuer: AnalyticsPivotData;
    riskCountry: AnalyticsPivotData;
  };
  performance: {
    ytd: AnalyticsCurrencyEntry;
    mtd: AnalyticsCurrencyEntry;
    dtd: AnalyticsCurrencyEntry;
    nav: AnalyticsCurrencyEntry & AnalyticsChartEntry<[number, number][]>;
    positions: AnalyticsCurrencyEntry & AnalyticsChartEntry<[number, number][]>;
  };
  performanceDetails: {
    contributors: TopNGridEntry[];
    instrumentValues: PerformanceDetailsInstrumentValue[];
  };
  risk: {
    exposure: AnalyticsCurrencyEntry &
      AnalyticsChartEntry<{ long: number; short: number; net: number }>;
    kpi: {
      r2: AnalyticsCurrencyEntry;
      sharpe: AnalyticsCurrencyEntry;
      carr: AnalyticsCurrencyEntry;
      volatility: AnalyticsChartEntry<{ dtd: number; longTerm: number }>;
      beta: AnalyticsChartEntry<{ dtd: number; longTerm: number }>;
    };
    drawdowns: {
      returns: {
        romad: AnalyticsCurrencyEntry;
        trough: AnalyticsCurrencyEntry;
      };
      proportions: {
        fund: AnalyticsCurrencyEntry;
        positions: AnalyticsCurrencyEntry;
      };
      length: {
        daysAvg: AnalyticsCurrencyEntry;
        period: AnalyticsCurrencyEntry;
      };
      liquidity: AnalyticsChartEntry<{ dtd: number; longTerm: number }>;
    };
  };
  portfolioMovement: {
    metrics: {
      returns: AnalyticsCurrencyEntry &
        AnalyticsChartEntry<{
          new: number;
          removed: number;
          increased: number;
          decreased: number;
        }>;
      currentExposure: AnalyticsCurrencyEntry &
        AnalyticsChartEntry<{
          new: number;
          removed: number;
          increased: number;
          decreased: number;
        }>;
    };
    dollarTurnover: AnalyticsCurrencyEntry &
      AnalyticsChartEntry<{
        new: number;
        removed: number;
        increased: number;
        decreased: number;
      }>;
    nameTurnover: AnalyticsCurrencyEntry &
      AnalyticsChartEntry<{
        new: number;
        removed: number;
        increased: number;
        decreased: number;
      }>;
  };
};

export type AnalyticsPivotSelection = {
  section: string;
  categories: string[];
};

export type AnalyticsContextType = {
  loading: boolean;
  error: string | null;
  data: AnalyticsData | null;
  setPivots: React.Dispatch<React.SetStateAction<AnalyticsPivotSelection[]>>;
  pivots: AnalyticsPivotSelection[];
  changeInstruments: (instruments: number[]) => void;
};

export const AnalyticsContext = React.createContext<
  AnalyticsContextType | undefined
>(undefined);

export function useAnalyticsContext() {
  const context = React.useContext(AnalyticsContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useAnalyticsContext must be used within a AnalyticsContextProvider"
    );
  }
  return context;
}
