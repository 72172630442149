import { useRefCallback } from "@enfusion-ui/hooks";
import {
  ControlledCheckbox,
  ControlledInputBase,
  PanelRow,
  TextInput,
} from "@enfusion-ui/web-components";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import {
  DateRangeSelection,
  DateRangeSymbolSelection,
} from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledLedgerHierarchySelect } from "../../../components/ReportQueryComponents/LedgerHierarchySelect";
import { ControlledMaxResultsInput } from "../../../components/ReportQueryComponents/MaxResultsInput";
import CurrencySelect from "./CurrencySelect";
import EntrySourcesSelect from "./EntrySourcesSelect";

const GLDistributionLineSettings = () => {
  const { accountsDescriptor } = useWatch({ name: ["accountsDescriptor"] });
  const defaultAccountDescriptor = React.useRef(accountsDescriptor);

  const currentEntryIds = React.useRef<Array<string>>([]);
  const { setValue } = useFormContext();

  const handleChange = useRefCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^[0-9,\b]+$/;
      if (e.target.value === "") {
        currentEntryIds.current = [];
      } else if (re.test(e.target.value)) {
        currentEntryIds.current = e.target.value.replace(/,+/g, ",").split(",");
      }

      setValue(
        "entryIds",
        currentEntryIds.current
          ?.filter((item: String) => item !== "")
          ?.map(Number)
      );
    },
    []
  );

  return (
    <>
      <ControlledLedgerHierarchySelect
        label="General Ledger(s)"
        name="accountsDescriptor"
        defaultValue={defaultAccountDescriptor.current}
      />
      <DateRangeSymbolSelection
        name="period"
        label="Period"
        datePickerWidth={100}
      />
      <DateRangeSelection
        name="modificationDateRange"
        startDateLabel="Modification Date (From)"
        endDateLabel="To"
      />
      <PanelRow>
        <ControlledMaxResultsInput />
        <ControlledInputBase
          name="entryIds"
          render={({ ref: _ref, ...rest }) => (
            <TextInput
              {...rest}
              type="text"
              label="Entry Id(s)"
              value={currentEntryIds.current?.join(",")}
              onChange={(e) => handleChange(e)}
            />
          )}
        />
      </PanelRow>
      <PanelRow>
        <EntrySourcesSelect />
        <ControlledCheckbox
          name="negateEntrySources"
          label="Exculde Selected Sources"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </PanelRow>

      <CurrencySelect />
      <ControlledCheckbox
        name="excludeTemplateEntries"
        label="Exclude Template Entries"
        labelPlacement="right"
        nullValue={false}
        style={{ alignSelf: "flex-end" }}
      />
    </>
  );
};

export default GLDistributionLineSettings;
