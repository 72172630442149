import InstrumentSelect from "@app-components/inputs/InstrumentSelect/InstrumentSelect";
import { UNKNOWN_INSTRUMENT_ID } from "@enfusion-ui/core";
import { MarketDataWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { InstrumentInfo } from "@enfusion-ui/types";
import { FormSectionAccordion } from "@enfusion-ui/web-components";
import * as React from "react";

const MarketDataEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const { defaultValue } = editedConfig as MarketDataWidgetConfig;

  const handleChange = (key: string) => (value: InstrumentInfo | null) => {
    changeConfigKeyToBeApplied(key, value);
  };

  return (
    <FormSectionAccordion title="Instrument" defaultOpen>
      <InstrumentSelect
        value={defaultValue?.id ?? UNKNOWN_INSTRUMENT_ID}
        onChange={handleChange("defaultValue")}
        label="Instrument"
        hideAdvancedSearchOnSelect
      />
    </FormSectionAccordion>
  );
};

export default MarketDataEditWidget;
