import {
  DEFAULT_ENTRY,
  entryType,
  FeeCommissionControlProps,
  TradeFeeCommissionEntry,
} from "@enfusion-ui/trades";
import {
  Button,
  ControlledCheckbox,
  ControlledNumericInput,
  ControlledSelect,
  FormElement,
  InputLabelControl,
  PanelRow,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { kebabCase } from "lodash";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
`;

export const FeeCommissionControl: React.FC<FeeCommissionControlProps> = ({
  name,
  label,
  typeOptions,
  loadingOptions,
  isAutoCalculated,
  isForceImpliedInPrice = () => false,
}) => {
  const { errors } = useFormContext();
  const { fields, append, remove } = useFieldArray<TradeFeeCommissionEntry>({
    name,
  });

  React.useEffect(() => {
    if (!fields.length) append(DEFAULT_ENTRY);
  }, []);

  return (
    <>
      <InputLabelControl label={label} name={name} errors={errors} required />
      <ListContainer>
        {fields.map((item, index) => {
          const type = entryType(item.type);
          const forceImpliedInPrice = isForceImpliedInPrice(type);
          const autoCalculated = isAutoCalculated?.(type);
          return (
            <PanelRow key={item.id} data-testid={`${kebabCase(name)}-entry`}>
              <ControlledSelect
                options={typeOptions}
                isLoading={loadingOptions}
                name={`${name}[${index}].type`}
                inputId={`${kebabCase(name)}-type-select`}
              />
              <ControlledNumericInput
                hideLabel
                style={{
                  display: autoCalculated ? "none" : "block",
                }}
                name={`${name}[${index}].value`}
                basis="max-content"
                mobileBasis="20%"
                minWidth="unset"
              />
              <FormElement basis="max-content" style={{ flexGrow: 0 }}>
                <ControlledCheckbox
                  defaultValue={forceImpliedInPrice}
                  disabled={forceImpliedInPrice}
                  name={`${name}[${index}].impliedInPrice`}
                  label="Implied in price"
                  labelPlacement="right"
                />
              </FormElement>
              <FormElement basis="max-content" style={{ flexGrow: 0 }}>
                <Button
                  icon={faTrashCan}
                  onClick={() => remove(index)}
                  data-testid={`${kebabCase(name)}-remove-button`}
                ></Button>
              </FormElement>
            </PanelRow>
          );
        })}
      </ListContainer>
      <FormElement>
        <Button
          style={{ minHeight: "22px" }}
          onClick={() => append(DEFAULT_ENTRY)}
          data-testid={`${kebabCase(name)}-add-button`}
          data-e2e-id={`${kebabCase(name)}-add-button`}
        >
          Add
        </Button>
      </FormElement>
    </>
  );
};
