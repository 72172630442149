import { WidgetComponentsDefinition } from "@enfusion-ui/dashboards";

import { OrderDetailsEditWidget } from "../orderDetails/OrderDetailsEditWidget";
import { QuantityNotionalWidget } from "./QuantityNotionalWidget";
import { QuantityNotionalWidgetMobile } from "./QuantityNotionalWidgetMobile";

export const quantityNotionalPanel: WidgetComponentsDefinition = {
  renderComponent: QuantityNotionalWidget,
  renderMobileComponent: QuantityNotionalWidgetMobile,
  editComponent: OrderDetailsEditWidget,
};
