import OMSContentView from "@app-components/display/OMSContentView";
import FileErrorBoundary from "@app-components/ErrorBoundary/FileErrorBoundary";
import { useAppTutorial } from "@app-components/Tour/context";
import { TutorialType } from "@app-components/Tour/utils";
import {
  ConnectionType,
  useConnectionStatus,
} from "@app-context/connectionStatus/context";
import {
  PortfolioProvider,
  usePortfolio,
} from "@app-context/portfolios/PortfolioProvider";
import { TOAST_CONTENT } from "@enfusion-ui/core";
import { useModalState, useRefCallback } from "@enfusion-ui/hooks";
import {
  PortfolioWorkerMessage,
  PROPOSED_ORDERS_IGNORE_COMPLIANCE_CONFIRMATION,
} from "@enfusion-ui/portfolios";
import {
  ConnectionStatus,
  DashboardRoot,
  FileTreeEntry,
  PortfolioRevertMode,
} from "@enfusion-ui/types";
import {
  ContentMessage,
  EmptyView,
  useConfirmationModal,
  useNavBarState,
  ViewContainer,
  ViewContent,
} from "@enfusion-ui/web-components";
import {
  errorToast,
  styled,
  useAuth,
  useOnWindowWillClose,
  useThisTab,
  useTrackUnsavedChanges,
} from "@enfusion-ui/web-core";
import { useWorkerModule } from "@enfusion-ui/web-workers";
import {
  faSkullCrossbones,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { useTour } from "@reactour/tour";
import * as React from "react";
import { useUnmount } from "react-use";

import CloseTabWithUnsavedChangeModal from "./CloseTabWithUnsavedChangeModal";
import { MainPortfolioGrid } from "./components/MainPortfolioGrid";
import PortfolioDetails from "./components/PortfolioDetails";
import TopActionBar from "./components/TopActionBar";

// const ViewContainer = styled(ViewContainerBase)`
//   + .ag-popup .ag-menu {
//     z-index: 20;
//   }
// `;

const PortfolioMainContentContainer = styled(ViewContent).attrs({
  fullHeight: true,
  fullWidth: true,
})`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--spacing);
  padding-top: 0px;
  box-sizing: border-box;
  border-width: 0px;
  background-color: var(--background-color-2);
  min-height: 200px;
`;

export type PortfolioViewProps = {
  config: {
    id: string;
    root: DashboardRoot;
    filePath: string;
    data?: FileTreeEntry;
  };
};

const PortfolioContent: React.FC<unknown> = () => {
  const { onTabWillClose } = useThisTab();
  const { subscribeToModule } = useWorkerModule("portfolios");
  const { isOpen, setIsOpen, currentStep, setCurrentStep, steps } = useTour();
  const { tutorialType, step } = useAppTutorial();
  const { updateStatus } = useConnectionStatus();

  const {
    config: { id, filePath },
    revertMode,
    handleSocketSubscription,
    submitProposedOrders,
    loading,
    error,
    hasUnsavedChanges,
    setRevertMode,
    revertChanges,
    saveChanges,
    undoAction,
    redoAction,
    hasUndo,
    hasRedo,
  } = usePortfolio();

  const IgnoreComplianceFailureConfirmation = useConfirmationModal({
    submitActionTheme: "primary",
    onSubmit: () => submitProposedOrders(true),
    title: PROPOSED_ORDERS_IGNORE_COMPLIANCE_CONFIRMATION.title,
    submitButtonText: PROPOSED_ORDERS_IGNORE_COMPLIANCE_CONFIRMATION.buttonText,
    renderContent: () => PROPOSED_ORDERS_IGNORE_COMPLIANCE_CONFIRMATION.message,
  });

  const handleSocketMessage = useRefCallback(
    async (message: any) => {
      const { type } = message as PortfolioWorkerMessage;

      if (type === "sync") {
        if (tutorialType === TutorialType.Portfolio) {
          if (step?.loading)
            setTimeout(() => setCurrentStep((c) => c + 1), 500);
        }
      } else if (type === "init-status") {
        updateStatus(ConnectionType.Portfolios, ConnectionStatus.CONNECTED);
      } else if (type === "close" || type === "terminated") {
        updateStatus(ConnectionType.Portfolios, ConnectionStatus.DISCONNECTED);
      }
    },
    [currentStep, steps, tutorialType]
  );

  React.useEffect(() => {
    return subscribeToModule(
      handleSocketSubscription(handleSocketMessage, () => {
        IgnoreComplianceFailureConfirmation.openModal();
      })
    );
  }, [id]);

  const unsavedChangeConfirmation = useModalState<null, VoidFunction>({
    onClose: (_, passthrough) => {
      if (passthrough) {
        passthrough();
        setRevertMode(PortfolioRevertMode.CLOSE_TAB);
      }
    },
  });

  const handleKeyDown = useRefCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (hasUndo && (event.ctrlKey || event.metaKey) && event.key === "z") {
        undoAction();
      } else if (
        hasRedo &&
        (event.ctrlKey || event.metaKey) &&
        event.key === "y"
      ) {
        redoAction();
      } else {
        return;
      }
    },
    [hasUndo, hasRedo]
  );

  React.useEffect(() => {
    return onTabWillClose(() => {
      if (
        hasUnsavedChanges &&
        revertMode !== PortfolioRevertMode.CLOSE_TAB &&
        !error
      ) {
        unsavedChangeConfirmation.openModal();
        return false;
      }
      return true;
    });
  }, [hasUnsavedChanges, revertMode, error]);

  useTrackUnsavedChanges(hasUnsavedChanges);

  React.useEffect(() => {
    if (isOpen && !!error) {
      setIsOpen(false);
      errorToast(TOAST_CONTENT.Portfolios.tourError);
    }
  }, [error, isOpen]);

  return (
    <FileErrorBoundary
      loading={loading}
      section="Portfolios"
      filePath={filePath}
      fileMissingError={error?.message === "Failed to load"}
      errorContent={
        error && (
          <ViewContainer>
            <EmptyView>
              <ContentMessage
                icon={
                  error.maxSocketsReached
                    ? faTriangleExclamation
                    : faSkullCrossbones
                }
                message={error.message}
              />
            </EmptyView>
          </ViewContainer>
        )
      }
    >
      <ViewContainer tabIndex={0} onKeyDown={handleKeyDown}>
        <TopActionBar />
        <PortfolioMainContentContainer>
          <MainPortfolioGrid />
        </PortfolioMainContentContainer>
        <PortfolioDetails />

        {hasUnsavedChanges && (
          <CloseTabWithUnsavedChangeModal
            open={unsavedChangeConfirmation.open}
            onCancel={unsavedChangeConfirmation.closeModal}
            onSaveClose={() =>
              unsavedChangeConfirmation.closeModal(saveChanges)
            }
            onDiscardClose={() =>
              unsavedChangeConfirmation.closeModal(revertChanges)
            }
          />
        )}
        {IgnoreComplianceFailureConfirmation.content}
      </ViewContainer>
    </FileErrorBoundary>
  );
};

const PortfolioView: React.FC<PortfolioViewProps> = ({ config }) => {
  const { user, hasPerm, isEnabled } = useAuth();
  const { onSave } = useNavBarState();
  const { postMessage } = useWorkerModule("portfolios");
  const handleSave = React.useCallback(() => {
    onSave();
  }, [onSave]);

  const Parent = React.useMemo(
    () => (isEnabled("OMS") ? OMSContentView : React.Fragment),
    [user, isEnabled, hasPerm]
  );

  const cleanUpSocket = useRefCallback(() => {
    postMessage({
      command: "close",
      destination: config.id,
    });
  }, [postMessage, config]);

  useUnmount(cleanUpSocket);

  const delayedCleanUpSocket = useRefCallback(() => {
    const cancelAfter = 10000;
    postMessage({
      command: "close",
      destination: config.id,
      payload: { delay: cancelAfter + 2000 },
    });
    setTimeout(() => {
      postMessage({
        command: "cancel-close",
        destination: config.id,
      });
    }, cancelAfter);

    return false;
  }, [postMessage, config]);

  useOnWindowWillClose(delayedCleanUpSocket, true);

  return (
    <Parent>
      <PortfolioProvider onSave={handleSave} config={config}>
        <PortfolioContent />
      </PortfolioProvider>
    </Parent>
  );
};

export default PortfolioView;
