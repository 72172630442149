import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import { useRefCallback } from "@enfusion-ui/hooks";
import { NodeData, WebWatchListInfo } from "@enfusion-ui/types";
import {
  REST_API,
  TabConfig,
  useOEMS,
  useTabs,
  WATCHLIST_EVENTS,
} from "@enfusion-ui/web-core";
import { faThumbtack } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";

import { OrderConfig, WatchListContext } from "./context";

export const WATCHLIST_EVENTS_ACTIONS = {
  GetWatchlist: "get-watchlist",
  SaveWatchlist: "save-watchlist",
  DeleteWatchlist: "delete-watchlist",
  DeleteWatchlistItems: "save-watchlist",
} as const;

export const getWatchListById = (watchListId: number) =>
  REST_API.WATCHLIST.GET_WATCHLIST_BY_ID.FETCH(watchListId);

const getWatchList = async () => {
  const items = await REST_API.WATCHLIST.GET_WATCHLIST_LIST.FETCH();
  const nodes = items.map(
    (item) =>
      ({
        name: item.name,
        id: item.id.toString(),
        path: "",
        info: item,
        file: true,
      } as NodeData)
  );
  return nodes;
};

const WatchListProvider: React.FC<
  React.PropsWithChildren<{ enabled: boolean }>
> = ({ children, enabled }) => {
  const { openTab, closeTab } = useTabs();
  const { openNewOrderTab } = useOEMS();

  const [watchListNodes, setWatchList] = React.useState<Array<NodeData> | null>(
    null
  );
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const getSetWatchList = useRefCallback(async () => {
    if (enabled) {
      try {
        setLoading(true);
        setError(null);
        setWatchList(await getWatchList());
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        setError(err.message || "Failed to load watch lists");
      } finally {
        setLoading(false);
      }
    } else {
      setWatchList([]);
    }
  }, [enabled]);

  const watchlistEventsChannel = useBroadcastChannel(
    WATCHLIST_EVENTS,
    (msg) => {
      if (
        msg.action === WATCHLIST_EVENTS_ACTIONS.GetWatchlist ||
        msg.action === WATCHLIST_EVENTS_ACTIONS.SaveWatchlist ||
        msg.action === WATCHLIST_EVENTS_ACTIONS.DeleteWatchlist ||
        msg.action === WATCHLIST_EVENTS_ACTIONS.DeleteWatchlistItems
      ) {
        getSetWatchList();
      }
    }
  );

  React.useEffect(() => {
    watchlistEventsChannel.broadcastMessage({
      action: WATCHLIST_EVENTS_ACTIONS.GetWatchlist,
    });
  }, []);

  const saveWatchList = useRefCallback(
    async (watchListInfo: WebWatchListInfo) => {
      try {
        const savedWatchListInfo =
          await REST_API.WATCHLIST.SAVE_WATCHLIST.FETCH(watchListInfo);
        watchlistEventsChannel.broadcastMessage({
          action: WATCHLIST_EVENTS_ACTIONS.SaveWatchlist,
        });
        return savedWatchListInfo;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        throw new Error(err.message);
      }
    },
    [watchlistEventsChannel]
  );

  const deleteWatchListItems = useRefCallback(
    async (watchListId: number, itemIds: number[]) => {
      try {
        const watchListInfo =
          await REST_API.WATCHLIST.DELETE_WATCHLIST_ITEMS.FETCH(
            watchListId,
            itemIds
          );
        watchlistEventsChannel.broadcastMessage({
          action: WATCHLIST_EVENTS_ACTIONS.DeleteWatchlistItems,
        });
        return watchListInfo;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        throw new Error(err.message);
      }
    },
    [watchlistEventsChannel]
  );

  const deleteWatchList = useRefCallback(
    async (watchListId: number) => {
      const res = await REST_API.WATCHLIST.DELETE_WATCHLIST.FETCH(watchListId);
      if (res) {
        watchlistEventsChannel.broadcastMessage({
          action: WATCHLIST_EVENTS_ACTIONS.DeleteWatchlist,
        });
        closeTab(`watch-list-${watchListId}`);
      }
      return res;
    },
    [closeTab, watchlistEventsChannel]
  );

  const openOrder = useRefCallback(
    (initialValues: OrderConfig) => {
      openNewOrderTab("equity", "prefer", initialValues);
    },
    [openNewOrderTab]
  );

  const openWatchListTab = useRefCallback(
    (config?: WebWatchListInfo) => {
      if (config)
        openTab({
          component: "watch-list",
          name: config?.name,
          icon: faThumbtack,
          unique: `watch-list-${config?.id}`,
          config,
        } as TabConfig);
    },
    [openTab]
  );

  const value = React.useMemo(
    () => ({
      watchListNodes,
      loading,
      error,
      openWatchListTab,
      getWatchListById,
      saveWatchList,
      deleteWatchList,
      deleteWatchListItems,
      openOrder,
    }),
    [
      watchListNodes,
      loading,
      error,
      openWatchListTab,
      getWatchListById,
      saveWatchList,
      deleteWatchList,
      deleteWatchListItems,
      openOrder,
    ]
  );

  return (
    <WatchListContext.Provider value={value}>
      {children}
    </WatchListContext.Provider>
  );
};

export default WatchListProvider;
