import { Text, View } from "@react-pdf/renderer";
import * as React from "react";

import { CellFlags } from "./CellFlags";
import { pxToPtConversion, WarningFlagsData } from "./utils";

export const ReportRow: React.FC<{
  values: Array<WarningFlagsData>;
  pdfReportWidgetStyles: any;
  upperIndex: number;
  showFlags: boolean;
  isTotalsRow?: boolean;
}> = ({
  values,
  pdfReportWidgetStyles,
  upperIndex,
  showFlags,
  isTotalsRow,
}) => {
  return (
    <>
      {values.map((valueObject: WarningFlagsData, k: number) => {
        const isFirstCell = k === 0 ? true : false;
        return (
          <View
            key={`${k}-${valueObject?.value}`}
            style={[
              {
                width: isFirstCell
                  ? pxToPtConversion(valueObject?.width + 10)
                  : pxToPtConversion(valueObject?.width),
              },
              pdfReportWidgetStyles.tableRow,
              isFirstCell
                ? pdfReportWidgetStyles["staticThemeRightBorder"]
                : "",
            ]}
          >
            {isFirstCell && valueObject.isExpandable && !isTotalsRow && (
              <View>
                <Text
                  style={[
                    pdfReportWidgetStyles[
                      valueObject?.errorText
                        ? "rowTextDangerColor"
                        : "rowsTextNormal"
                    ],
                    pdfReportWidgetStyles.chevron,
                  ]}
                >
                  &gt;
                </Text>
              </View>
            )}
            <Text
              style={[
                pdfReportWidgetStyles.tableData,
                pdfReportWidgetStyles[
                  valueObject?.errorText
                    ? "rowTextDangerColor"
                    : "rowsTextNormal"
                ],
              ]}
            >
              {valueObject.value}
            </Text>
            {showFlags && (
              <CellFlags
                valueObject={valueObject}
                pdfReportWidgetStyles={pdfReportWidgetStyles}
                indexNumber={upperIndex}
              />
            )}
          </View>
        );
      })}
    </>
  );
};
