import { useRefCallback } from "@enfusion-ui/hooks";
import {
  ControlledCheckbox,
  ControlledInputBase,
  PanelRow,
  TextInput,
} from "@enfusion-ui/web-components";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import ControlledAssignedDeskSelect from "../../../components/ReportQueryComponents/AssignedDeskSelect";
import ControlledAssignedTraderSelect from "../../../components/ReportQueryComponents/AssignedTraderSelect";
import ComplianceStateSelect from "../../../components/ReportQueryComponents/ComplianceSelect";
import {
  DateRangeSelection,
  DateSelection,
} from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import ControlledFundSelect from "../../../components/ReportQueryComponents/FundSelect";
import { ControlledMarketEnvironmentSelect } from "../../../components/ReportQueryComponents/MarketEnvironmentSelect";
import { ControlledMaxResultsInput } from "../../../components/ReportQueryComponents/MaxResultsInput";

const ActiveCheckbox = () => (
  <ControlledCheckbox
    name="activeOrdersOnly"
    label="Active"
    labelPlacement="right"
    nullValue={false}
    style={{ alignSelf: "flex-end" }}
  />
);

const OrderBlotterSettings = () => {
  const { assignedTraderIds, assignedDeskIds, dateRange } = useWatch({
    name: ["assignedTraderIds", "assignedDeskIds", "dateRange"],
  });

  const isAsOfDate =
    dateRange?.startDate?.dateSelectionType === "AsOfDate" &&
    dateRange?.endDate?.dateSelectionType === "AsOfDate";
  const currentOrderIds = React.useRef<Array<string>>([]);

  const { setValue } = useFormContext();

  const defaultAssignedTraderIdsRef = React.useRef(assignedTraderIds);
  const defaultAssignedDeskIdsRef = React.useRef(assignedDeskIds);

  const handleChange = useRefCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^[0-9,\b]+$/;
      if (e.target.value === "") {
        currentOrderIds.current = [];
      } else if (re.test(e.target.value)) {
        currentOrderIds.current = e.target.value.replace(/,+/g, ",").split(",");
      }

      setValue(
        "orderIds",
        currentOrderIds.current
          ?.filter((item: String) => item !== "")
          ?.map(Number)
      );
    },
    []
  );

  return (
    <>
      <PanelRow>
        <DateRangeSelection
          name="dateRange"
          startDateLabel="Order Date (From)"
          endDateLabel="To"
          datePickerWidth={100}
        />
        {!isAsOfDate && <ActiveCheckbox />}
      </PanelRow>
      <PanelRow>
        <ControlledAssignedTraderSelect
          defaultAssignedTrader={defaultAssignedTraderIdsRef.current}
        />
        <ComplianceStateSelect />
      </PanelRow>
      <PanelRow>
        <ControlledMaxResultsInput label="Limit" />
        <ControlledInputBase
          name="orderIds"
          render={({ ref: _ref, ...rest }) => (
            <TextInput
              {...rest}
              type="text"
              label="Order Id(s)"
              value={currentOrderIds.current?.join(",")}
              onChange={(e) => handleChange(e)}
            />
          )}
        />
      </PanelRow>
      <PanelRow>
        <ControlledFundSelect />
        {isAsOfDate && <ActiveCheckbox />}
      </PanelRow>
      <PanelRow>
        <ControlledAssignedDeskSelect
          defaultAssignedDesk={defaultAssignedDeskIdsRef.current}
        />
        <ControlledCheckbox
          name="parentOrdersOnly"
          label="Limit to Parent Order"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </PanelRow>
      <PanelRow>
        <DateSelection
          name="valueDate"
          label="Value Date"
          defaultValue={undefined}
        />
        <ControlledCheckbox
          name="useTradeDateForValuation"
          label="Use Trade Date For Valuation"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </PanelRow>
      <ControlledMarketEnvironmentSelect />
    </>
  );
};

export default OrderBlotterSettings;
