/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ConnectionType,
  useConnectionStatus,
} from "@app-context/connectionStatus/context";
import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import { EMPTY_RECORD, TOAST_CONTENT } from "@enfusion-ui/core";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import {
  ConnectionStatus,
  DashboardRoot,
  FundTreeEntry,
  LoadReportParams,
  NodeData,
  WebReportQuery,
} from "@enfusion-ui/types";
import {
  formatForUrl,
  getFileExtensionIcon,
  getFileParts,
} from "@enfusion-ui/utils";
import {
  createReportColumnDefs,
  errorToast,
  ReportConfig,
  ReportContextDataStoreEntry,
  ReportContextMetaStoreEntry,
  ReportContextRowsStoreEntry,
  ReportDataChangeHandler,
  ReportOpenDetailTableArgs,
  ReportsContext,
  REST_API,
  successToast,
  TabDef,
  useTabs,
} from "@enfusion-ui/web-core";
import { useWorkerModule } from "@enfusion-ui/web-workers";
import { omit, pick } from "lodash";
import queryString from "query-string";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";

export type ReportsProviderMessage = {
  command:
    | "init-status"
    | "table-sync"
    | "recon-sync"
    | "rows-update"
    | "row-metadata"
    | "row-delete"
    | "error"
    | "load-report"
    | "update-open-subscriptions"
    | "current-state"
    | "metadata-error"
    | "connected"
    | "disconnected"
    | "reconnect"
    | "socket-error";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
};

const sortByName = (a: NodeData, b: NodeData) => a.name.localeCompare(b.name);

const fundTreeToNodeData = (
  entries: Array<FundTreeEntry> = [],
  depth = 1,
  baseIds: {
    glId?: number;
    parent?: string;
    path?: string;
  } = {}
): Array<NodeData> => {
  return entries
    .reduce((res, entry) => {
      const glId = baseIds.glId || entry.id;
      const path = baseIds.path;
      const subNodes = fundTreeToNodeData(entry.children, depth + 1, {
        glId: glId,
        parent: entry.id ? `${entry.id}` : "",
        path: `${path}/${entry.id ?? ""}`,
      });
      res.push({
        ...omit(entry, ["children"]),
        id: entry.id ? `${entry.id}` : "",
        file: subNodes.length === 0 ? true : false,
        glId,
        parentId: baseIds.parent,
        accountId: depth > 1 ? entry.id : undefined,
        nodes: subNodes,
        nodeCount: subNodes.length,
        path: `${path}/${entry.id ?? ""}`,
        depth,
      });
      return res;
    }, [] as Array<NodeData>)
    .sort(sortByName);
};

export const ReportsRowContext = React.createContext<
  | {
      rowsStore: Record<string, ReportContextRowsStoreEntry>;
    }
  | undefined
>(undefined);

export const useReportRows = () => {
  const context = React.useContext(ReportsRowContext);
  if (context === undefined)
    throw new Error("useReportRows needs to be used inside of ReportsProvider");
  return context;
};

export const ReportsProvider: React.FC<
  React.PropsWithChildren<{
    enabled: boolean;
    orderActionsEnabled: boolean;
  }>
> = ({ children, enabled, orderActionsEnabled }) => {
  const isMounted = useMounted();
  const { reportSocketConnectionStatus, updateStatus } = useConnectionStatus();
  const connectionStatusRef = React.useRef(reportSocketConnectionStatus);
  connectionStatusRef.current = reportSocketConnectionStatus;

  const {
    enableModule,
    disableModule,
    subscribeToModule,
    postMessage,
    getCurrentState,
  } = useWorkerModule("reports");
  const { openTab, onTabClose } = useTabs();

  const [metaStore, setMetaStore] = React.useState<
    Record<string, ReportContextMetaStoreEntry>
  >({});
  const [dataStore, setDataStore] = React.useState<
    Record<string, ReportContextDataStoreEntry>
  >({});
  const [glNodes, setGLNodes] = React.useState<NodeData[]>();

  const dataStoreRef = React.useRef<
    Record<string, ReportContextDataStoreEntry>
  >({});
  const [rowsStore, setRowsStore] = React.useState<
    Record<string, ReportContextRowsStoreEntry>
  >({});
  const rowsStoreRef = React.useRef<
    Record<string, ReportContextRowsStoreEntry>
  >({});

  const openSubscriptionsRef = React.useRef<Record<string, string>>({});
  const changeSubscriptionsRef = React.useRef<
    Record<string, Array<ReportDataChangeHandler>>
  >({});

  const loadReportRef = React.useRef<Record<string, boolean>>({});

  const explorerChannel = useExplorerEvents("Reports");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const constructDataStore = (tableData: any) => {
    const { tableId, totalsRow, tableMetadata, rows } = tableData;
    rowsStoreRef.current = {
      ...rowsStoreRef.current,
      [tableId]: {
        rows,
        totalsRow,
      },
    };

    dataStoreRef.current = {
      ...dataStoreRef.current,
      [tableId]: {
        columnDefs: createReportColumnDefs(tableMetadata),
        metadata: {
          ...tableMetadata,
          columns: tableMetadata.columns.reduce(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (res: any, entry: any) => ({ ...res, [entry.name]: entry }),
            {}
          ),
          descriptionColumn: tableMetadata.columns.find(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (i: any) => i.visible === true
          )?.name,
        },
      },
    };
  };

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return subscribeToModule(async (data: any) => {
      const { command, payload } = data as ReportsProviderMessage;
      switch (command) {
        case "connected": {
          updateStatus(ConnectionType.Reports, ConnectionStatus.CONNECTED);
          break;
        }
        case "disconnected": {
          updateStatus(ConnectionType.Reports, ConnectionStatus.DISCONNECTED);
          break;
        }
        case "socket-error": {
          updateStatus(ConnectionType.Reports, ConnectionStatus.ERROR);
          break;
        }
        case "reconnect": {
          updateStatus(
            ConnectionType.Reports,
            connectionStatusRef.current === ConnectionStatus.ERROR
              ? ConnectionStatus.ERROR_RECONNECTING
              : ConnectionStatus.RECONNECTING
          );
          break;
        }

        case "init-status": {
          setMetaStore((prev) => ({
            ...prev,
            ...payload.metaStore,
          }));
          break;
        }
        case "recon-sync": {
          const { reportId, tableData } = payload;
          const {
            targetOnlyRows,
            targetIgnoredRows,
            sourceOnlyRows,
            sourceIgnoredRows,
            differenceRows,
            matchedRows,
            statistics,
          } = tableData;

          rowsStoreRef.current = {
            ...rowsStoreRef.current,
            [reportId]: {
              diffRows: {
                targetIgnoredRows,
                targetOnlyRows,
                matchedRows,
                differenceRows,
                sourceIgnoredRows,
                sourceOnlyRows,
                statistics,
              },
            },
          };

          setMetaStore((prev) => ({
            ...prev,
            ...payload.metaStore,
          }));
          setRowsStore(rowsStoreRef.current);
          break;
        }
        case "table-sync": {
          /**
           * Owing to problems in serializing the column defs, table data is stored in worker
           * but datastore is constructed in the provider
           */

          constructDataStore(payload.tableData);

          setMetaStore((prev) => ({
            ...prev,
            ...payload.metaStore,
          }));
          setDataStore(dataStoreRef.current);
          setRowsStore(rowsStoreRef.current);

          const { tableId } = payload.tableData;

          requestAnimationFrame(() => {
            if (changeSubscriptionsRef.current[tableId]) {
              for (const handler of changeSubscriptionsRef.current[tableId]) {
                handler({
                  change: {
                    add: rowsStoreRef.current[tableId].rows,
                  },
                  totalsRow: rowsStoreRef.current[tableId].totalsRow,
                  tableId,
                  columnDefs: dataStoreRef.current[tableId].columnDefs,
                  type: "init",
                } as any);
              }
            }
          });

          delete loadReportRef?.current[payload.reportId];
          break;
        }
        case "rows-update": {
          const { tableId, totalsRow, newRows, add } = payload;

          rowsStoreRef.current = {
            ...rowsStoreRef.current,
            [tableId]: {
              ...rowsStoreRef.current[tableId],
              rows: newRows,
              totalsRow: { ...totalsRow },
            },
          };

          if (changeSubscriptionsRef.current[tableId]) {
            for (const handler of changeSubscriptionsRef.current[tableId]) {
              handler({
                change: {
                  add,
                  update: newRows.filter(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (i: any) => !add.some((a: any) => a.__row_id === i.__row_id)
                  ),
                },
                totalsRow,
                tableId,
              });
            }
          }

          setMetaStore(payload.metaStore);
          setRowsStore(rowsStoreRef.current);
          break;
        }
        case "row-delete": {
          const { newRows, tableId, rowIndex } = payload;

          rowsStoreRef.current = {
            ...rowsStoreRef.current,
            [tableId]: {
              ...rowsStoreRef.current[tableId],
              rows: newRows,
            },
          };

          const remove = newRows.splice(rowIndex, 1);

          if (changeSubscriptionsRef.current[tableId]) {
            for (const handler of changeSubscriptionsRef.current[tableId]) {
              handler({ change: { remove, update: newRows }, tableId });
            }
          }

          setMetaStore(payload.metaStore);
          setRowsStore(rowsStoreRef.current);
          break;
        }

        case "error": {
          console.warn("syncError", payload.message);
          handleReportError({
            reportId: payload.reportId,
            message: payload.message,
          });
          break;
        }
        case "load-report": {
          if (
            payload.reportId &&
            payload.error &&
            loadReportRef?.current[payload.reportId]
          ) {
            delete loadReportRef.current[payload.reportId];
          }
          setMetaStore(payload.metaStore);
          break;
        }
        case "update-open-subscriptions": {
          openSubscriptionsRef.current = {
            ...openSubscriptionsRef.current,
            ...payload.openSubscriptions,
          };
          break;
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (enabled && isMounted()) {
      enableModule();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getCurrentState()?.then(({ payload }) => {
        const tableDataRef = payload.tableData;

        openSubscriptionsRef.current = {
          ...openSubscriptionsRef.current,
          ...payload.openSubscriptions,
        };

        /**
         * Construct datastore for each tableId data when current-state is requested
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        for (const key of Object.keys(tableDataRef)) {
          constructDataStore(tableDataRef[key]);
        }

        setMetaStore((prev) => ({
          ...prev,
          ...payload.metaStore,
        }));
        setDataStore(dataStoreRef.current);
        setRowsStore(rowsStoreRef.current);
      });
    }

    return () => {
      disableModule();
    };
  }, [enabled]);

  React.useEffect(() => {
    const fetchGeneralLedger = async () => {
      try {
        const res = await REST_API.FUND.GET_LEDGER_HIERARCHY.FETCH();
        if (res.children) {
          const rootId = uuidv4();
          const subNodes = fundTreeToNodeData(res.children, 1, {
            parent: rootId,
            path: `/${rootId}`,
          });
          const rootNode = {
            ...omit(res, ["children"]),
            id: rootId,
            file: false,
            nodes: subNodes,
            nodeCount: subNodes.length,
            path: `/${rootId}`,
            depth: 0,
            defaultOpen: true,
          };
          setGLNodes([rootNode]);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.warn(err);
        // errorToast("Error while loading general lodger");
      }
    };
    if (isMounted()) {
      fetchGeneralLedger();
    }
  }, []);

  const handleReportError = React.useCallback(
    ({ reportId, message }: { reportId: string; message: string }) => {
      postMessage({
        command: "sync-error",
        payload: {
          reportId,
          message,
        },
      });
    },
    []
  );

  React.useEffect(() => {
    const unsubscribeOnTabClose = onTabClose((tabDef: TabDef) => {
      if (tabDef.component === "report" && isMounted()) {
        closeReport(tabDef.config.reportId);
      }
    });

    return unsubscribeOnTabClose;
  }, []);

  const openReportTab = React.useCallback(
    (args: ReportConfig, force = false) => {
      const {
        name: nameBase,
        path,
        params,
        pathParams,
        reportQuery,
        root,
      } = args;
      const reportId = uuidv4();

      const { name, extension } = getFileParts(nameBase);

      console.log("path", path);

      openTab({
        name,
        icon: getFileExtensionIcon(extension),
        component: "report",
        config: {
          name: nameBase,
          path,
          reportId,
          params,
          pathParams,
          reportQuery,
          root,
        },
        unique: force
          ? new Date().toISOString()
          : path || new Date().toISOString(),
      });
    },
    []
  );

  const preLoadRef = React.useRef<Record<string, number | undefined>>({});
  const loadReport = useRefCallback(
    ({
      reportId,
      name,
      path: pathBase,
      pathParams = EMPTY_RECORD,
      params: allParams = EMPTY_RECORD,
      closeFirst = true,
      skipEqualCheck = false,
      reportQuery,
    }: LoadReportParams) => {
      clearTimeout(preLoadRef.current[reportId]);
      preLoadRef.current[reportId] = setTimeout(async () => {
        // restructure params to how they are expected by reports feed
        // TO-DO: figure out ways to utilize cleanParams. Currently not in use
        try {
          if (!skipEqualCheck && loadReportRef?.current[reportId]) return;
          loadReportRef.current[reportId] = true;

          const allParamKeys = Object.keys(allParams);
          const [urlParams, params] = allParamKeys.reduce(
            (res, key) => {
              if (key.startsWith("url.")) {
                res[0][key.replace("url.", "")] = allParams[key];
              } else {
                res[1][key] = allParams[key];
              }
              return res;
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [{}, {}] as [Record<string, string>, Record<string, any>]
          );

          const payload = {
            reportId,
            name,
            path: pathBase,
            reportQuery,
            params,
          };

          if (pathBase) {
            const query = {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ...(pathParams as Record<string, any>),
              ...Object.fromEntries(
                Object.entries(
                  pick(
                    urlParams,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    Object.keys(pathParams as Record<string, any>)
                  )
                ).filter((v) => Boolean(v[1]))
              ),
            };

            const path = queryString.stringifyUrl({
              url: `${pathBase}`,
              query: formatForUrl(query),
            });
            if (!pathBase.includes(".diff")) {
              const savedReportQuery =
                await REST_API.REPORTS.GET_SAVED_QUERY.FETCH(path);
              if (!reportQuery) payload.reportQuery = savedReportQuery;
            }
          }

          if (closeFirst) {
            setMetaStore((prev) => ({
              ...prev,
              [reportId]: {
                ...prev[reportId],
                progressSteps: undefined,
                loading: true,
              },
            }));
            closeReport(reportId);
          }

          postMessage({
            command: "load-report",
            payload,
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          console.warn("loadReport error", err);

          const message = err.message ? err?.message : err;

          setMetaStore((prev) => ({
            ...prev,
            [reportId]: {
              ...prev[reportId],
              error: message,
              loading: false,
            },
          }));
          closeReport(reportId);

          errorToast(message);
        }
      }, 300) as unknown as number;
    },
    [openSubscriptionsRef]
  );

  // NOTE: rowId is the row index from the parent report
  const openDetailTable = React.useCallback(
    ({
      destination,
      reportId,
      parentTableId,
      row,
      label,
      siblings = [],
      replace = false,
      tableId = uuidv4(),
    }: ReportOpenDetailTableArgs) => {
      const entry = {
        parentTableId,
        label,
        row,
        tableId,
        siblings,
      };

      postMessage({
        command: "add-breadcrumb",
        payload: {
          destination,
          entry,
          reportId,
          replace,
          params: {
            parentTableId,
            tableId,
            row,
          },
        },
      });
    },
    []
  );

  const closeReport = React.useCallback(
    (reportIdToClose: string, path?: string) => {
      postMessage({
        command: "close-report",
        payload: {
          reportIdToClose,
        },
      });

      postMessage({
        command: "unsubscribe",
        payload: {
          reportIdToClose,
          path,
        },
      });
    },
    []
  );

  const selectBreadcrumbTable = React.useCallback(
    (reportId: string, tableId: string) => {
      postMessage({
        command: "select-breadcrumb",
        payload: { reportId, tableId },
      });
    },
    []
  );

  const subscribeToReportChange = React.useCallback(
    (tableId: string, callback: ReportDataChangeHandler) => {
      const subs = (changeSubscriptionsRef.current[tableId] || []).filter(
        (i) => i !== callback
      );

      changeSubscriptionsRef.current = {
        ...changeSubscriptionsRef.current,
        [tableId]: [...subs, callback],
      };

      return () => {
        const subs = (changeSubscriptionsRef.current[tableId] || []).filter(
          (i) => i !== callback
        );

        changeSubscriptionsRef.current = {
          ...changeSubscriptionsRef.current,
          [tableId]: subs,
        };
      };
    },
    []
  );

  const bulkSubscribeToReportChange = useRefCallback(
    (tableIds: string[], callback: ReportDataChangeHandler) => {
      for (const tableId of tableIds) {
        const subs = (changeSubscriptionsRef.current[tableId] || []).filter(
          (i) => i !== callback
        );

        changeSubscriptionsRef.current = {
          ...changeSubscriptionsRef.current,
          [tableId]: [...subs, callback],
        };
      }

      return () => {
        for (const tableId of tableIds) {
          const subs = (changeSubscriptionsRef.current[tableId] || []).filter(
            (i) => i !== callback
          );

          changeSubscriptionsRef.current = {
            ...changeSubscriptionsRef.current,
            [tableId]: subs,
          };
        }
      };
    },
    []
  );

  const saveReport = React.useCallback(
    async (
      name: string,
      path: string,
      root: DashboardRoot,
      reportQuery: WebReportQuery,
      reportId: string,
      forceWrite: boolean | undefined
    ) => {
      try {
        const payload = { reportId, name, path };
        const filePath = root ? path.replace(`${root}/`, "") : path;
        const response = await REST_API.REPORTS.STORE_REPORT({
          path: filePath,
          subRoot: root,
          reportQuery,
          forceWrite,
        });

        if (response.success) {
          explorerChannel.broadcast(root, ExplorerEventsAction.Refetch);
          const { name: savedName, path: savedPath } = metaStore[reportId];
          if (path !== savedPath || name !== savedName) {
            postMessage({
              command: "save-report",
              payload,
            });
          }

          successToast(TOAST_CONTENT.Reports.save.success);
        } else {
          console.error("report save error", response);
          errorToast(TOAST_CONTENT.Reports.save.failure);
        }
      } catch (err: any) {
        console.error("report save error", err);
        errorToast(TOAST_CONTENT.Reports.save.failure);
      }
    },
    [explorerChannel]
  );

  const value = React.useMemo(
    () => ({
      glNodes,
      dataStore,
      metaStore,
      loadReport,
      closeReport,
      saveReport,
      openReportTab,
      openDetailTable,
      handleReportError,
      selectBreadcrumbTable,
      subscribeToReportChange,
      bulkSubscribeToReportChange,
      orderActionsEnabled,
    }),
    [
      glNodes,
      dataStore,
      metaStore,
      loadReport,
      closeReport,
      saveReport,
      openReportTab,
      openDetailTable,
      handleReportError,
      selectBreadcrumbTable,
      subscribeToReportChange,
      bulkSubscribeToReportChange,
      orderActionsEnabled,
    ]
  );

  return (
    <ReportsContext.Provider value={value}>
      <ReportsRowContext.Provider
        value={{
          rowsStore,
        }}
      >
        {children}
      </ReportsRowContext.Provider>
    </ReportsContext.Provider>
  );
};
