import {
  Button,
  ControlledTextInput,
  ErrorAlert,
  Modal,
  SingleColumnGridWithGap,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

export type FileRenameFormValues = {
  newFileName: string;
};

export type RenameFileFormProps = {
  fileName: string;
  onSubmit: SubmitHandler<FileRenameFormValues>;
  loading: boolean;
};

const RenameFileForm: React.FC<RenameFileFormProps> = ({
  fileName,
  onSubmit,
  loading,
}) => {
  const formMethods = useForm<FileRenameFormValues>();
  return (
    <FormProvider {...formMethods}>
      <SingleColumnGridWithGap
        as="form"
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <ControlledTextInput
          label="File Name"
          name="newFileName"
          disabled={loading}
          defaultValue={fileName}
          autoFocus
          rules={{
            required: "File name is required",
          }}
          data-testid="folder-name-text-input"
          data-e2e-id="rename-modal-name-input"
        />
        <Button
          primary
          type="submit"
          busy={loading}
          data-testid="rename-folder-submit-button"
          data-e2e-id="rename-modal-submit-button"
        >
          Submit
        </Button>
      </SingleColumnGridWithGap>
    </FormProvider>
  );
};

export type FileRenameModalProps = {
  open: boolean;
  filePath: string;
  fileName: string;
  onClose: VoidFunction;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (filePath: string, newName: string) => Promise<any>;
};

const ModalContentContainer = styled.div`
  margin-top: 1rem;
`;

const FileRenameModal: React.FC<FileRenameModalProps> = ({
  open,
  onClose,
  filePath,
  fileName,
  onSubmit,
}) => {
  const [error, setError] = React.useState<Error | null>();
  const [loading, setLoading] = React.useState(false);

  const renameFile: SubmitHandler<FileRenameFormValues> = ({ newFileName }) => {
    setLoading(true);
    setError(null);

    try {
      onSubmit(filePath, newFileName);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title="Rename File"
      allowDismissal
      content={
        <ModalContentContainer>
          {error && <ErrorAlert error={error} />}
          <RenameFileForm
            onSubmit={renameFile}
            loading={loading}
            fileName={fileName}
          />
        </ModalContentContainer>
      }
    />
  );
};

export default FileRenameModal;
