import {
  ButtonGroupSelect,
  IconButton,
  Modal,
} from "@enfusion-ui/web-components";
import { styled, useIsContentUnderWidth } from "@enfusion-ui/web-core";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import Interweave from "interweave";
import * as React from "react";

import { data } from "./expressionHelpData";

const DialogContent = styled.div`
  position: relative;
  background-color: var(--background-color-0);
  border-radius: 5px;
  border: 1px solid var(--primary);
  z-index: 2;
  max-height: 90vh;
  max-width: 65vw;
  overflow: hidden;

  p {
    font-size: 0.95em;
  }
`;

const MobileDialogContent = styled.div`
  position: relative;
  background-color: var(--background-color-2);
  z-index: 2;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-size: 0.9em;
  padding: 2vh 5vw;

  p {
    font-size: 0.95em;
  }
`;

const FadeContainer = styled.div<{ background?: string }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10vh;
  z-index: 3;

  background-image: ${({ background = "var(--background-color-0)" }) =>
    `linear-gradient(to bottom, transparent 50%, ${background});`};
`;

const DesktopWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const CloseButton = styled(IconButton).attrs({ icon: faTimes })`
  font-size: 1.25rem;
`;

const Page = styled.div`
  padding: 2rem;
`;

const PageSelectWrapper = styled.div`
  padding: 1vh 10vw;
  font-size: 1.2em;
  width: 100%;
  max-width: 60rem;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

const PageHeaderMobile = styled(PageHeader)`
  justify-content: flex-end;
`;

const PageTitle = styled.h2`
  font-weight: normal;
  margin: 0;
`;
const PageDescription = styled.p`
  font-size: 0.7em;
  color: var(--text-muted);
  font-style: italic;
  margin-bottom: 1rem;
  max-width: 30rem;
`;
const SectionTitle = styled.h3`
  color: var(--primary);
`;

const SyntaxListWrapper = styled.div`
  position: relative;
  height: 58vh;
  overflow: auto;
  padding-bottom: 5rem;
`;

const SyntaxHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 0.25rem 0;
  padding: 0 1rem;
  align-items: center;
  border-radius: 8px;
`;

const SyntaxRow = styled(SyntaxHeaderRow)<{ isMobile?: boolean }>`
  :hover {
    background-color: ${(props) =>
      props.isMobile ? "var(--overlay)" : "var(--background-color-2)"};
  }
`;

const SyntaxInput = styled.p`
  padding: 0;
  font-weight: bold;
`;
const SyntaxDescription = styled.p`
  padding: 0;
  color: var(--text-muted);
  margin-left: 1rem;
  max-width: 30rem;
`;

const CategoriesSections = styled.div`
  max-height: 65vh;
  overflow: auto;
`;
const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionContent = styled.p`
  margin-bottom: 1.25em;
`;
const SectionList = styled.ul``;
const SectionListItem = styled.li`
  margin-bottom: 1.25em;
`;

const BREAK_WIDTH = 650;
const { syntax, categories } = data;

const SyntaxList: React.FC = () => {
  const isMobile = useIsContentUnderWidth(BREAK_WIDTH);
  return (
    <>
      <SyntaxHeaderRow>
        <SectionTitle>Input</SectionTitle>
        <SectionTitle>Description</SectionTitle>
      </SyntaxHeaderRow>
      {syntax.examples.map(({ input, description }) => (
        <SyntaxRow key={input} isMobile={isMobile}>
          <SyntaxInput>{input}</SyntaxInput>
          <SyntaxDescription>{description}</SyntaxDescription>
        </SyntaxRow>
      ))}
    </>
  );
};

const SyntaxSummary: React.FC = () => {
  return (
    <>
      <PageDescription>{syntax.description}</PageDescription>
      <div style={{ overflow: "hidden", position: "relative" }}>
        <FadeContainer background="var(--background-color-1)" />
        <SyntaxListWrapper>
          <SyntaxList />
        </SyntaxListWrapper>
      </div>
    </>
  );
};

const SyntaxSummaryMobile: React.FC = () => {
  return (
    <div style={{ maxWidth: "40rem" }}>
      <PageDescription>{syntax.description}</PageDescription>
      <SyntaxList />
    </div>
  );
};

const FormulaCategoriesContent: React.FC = () => {
  return (
    <>
      {categories.sections.map((section) => (
        <Section key={section.title}>
          <SectionTitle>{section.title}</SectionTitle>
          {section.content.map((item, idx) =>
            typeof item === "string" ? (
              <SectionContent key={`${idx}`}>
                <Interweave content={item} />
              </SectionContent>
            ) : (
              <SectionList>
                {item.map((html, idx) => (
                  <SectionListItem key={`${idx}`}>
                    <SectionContent>
                      <Interweave content={html} />
                    </SectionContent>
                  </SectionListItem>
                ))}
              </SectionList>
            )
          )}
        </Section>
      ))}
    </>
  );
};

const FormulaCategories: React.FC = () => {
  return (
    <div style={{ position: "relative" }}>
      <CategoriesSections>
        <FadeContainer />
        <FormulaCategoriesContent />
      </CategoriesSections>
    </div>
  );
};

const FormulaCategoriesMobile: React.FC = () => {
  return (
    <div style={{ maxWidth: "40rem" }}>
      <FormulaCategoriesContent />
    </div>
  );
};

const ExpressionHelpDesktop: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => (
  <DesktopWrapper>
    <Page style={{ backgroundColor: "var(--background-color-1)" }}>
      <PageHeader>
        <PageTitle>{syntax.title}</PageTitle>
      </PageHeader>
      <SyntaxSummary />
    </Page>
    <Page>
      <PageHeader>
        <PageTitle>{categories.title}</PageTitle>
        <CloseButton onClick={onClose} />
      </PageHeader>
      <FormulaCategories />
    </Page>
  </DesktopWrapper>
);

const ExpressionHelpMobile: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const [activeTab, setActiveTab] = React.useState<string>(syntax.title);
  return (
    <>
      <PageHeaderMobile>
        <CloseButton onClick={onClose} />
      </PageHeaderMobile>
      <MobileWrapper>
        <PageSelectWrapper>
          <ButtonGroupSelect
            selectedValue={activeTab}
            onSelect={(value) => setActiveTab(value as string)}
            options={[
              { value: syntax.title, label: syntax.title },
              { value: categories.title, label: categories.title },
            ]}
            disableDeselect
          />
        </PageSelectWrapper>
        <div
          style={{
            overflow: "auto",
            maxHeight: "85vh",
            paddingBottom: "1rem",
          }}
        >
          {activeTab === syntax.title ? (
            <SyntaxSummaryMobile />
          ) : (
            <FormulaCategoriesMobile />
          )}
        </div>
      </MobileWrapper>
    </>
  );
};

const ExpressionHelpModal: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const isMobile = useIsContentUnderWidth(BREAK_WIDTH);
  const DialogContainer = isMobile ? MobileDialogContent : DialogContent;
  return (
    <Modal isOpen={open} onClose={onClose}>
      <DialogContainer>
        {isMobile ? (
          <ExpressionHelpMobile onClose={onClose} />
        ) : (
          <ExpressionHelpDesktop onClose={onClose} />
        )}
      </DialogContainer>
    </Modal>
  );
};

export default ExpressionHelpModal;
