import ControlledAccountSelect from "@app-components/inputs/account/ControlledAccountSelect";
import * as React from "react";

import { DateRangeSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledPortfolioGroupsSelect } from "../../../components/ReportQueryComponents/PortfolioGroupsSelect";

const CounterpartyCommissionQuickFilter = () => {
  return (
    <>
      <ControlledAccountSelect label="Account(s)" />

      <ControlledPortfolioGroupsSelect />

      <DateRangeSelection
        name="tradeDateRange"
        startDateLabel="Trade Date"
        endDateLabel="To"
      />
    </>
  );
};

export default CounterpartyCommissionQuickFilter;
