import {
  FundBenchmarkTypeSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { SelectOptionsType } from "@enfusion-ui/types";
import { FormSectionAccordion, TextInput } from "@enfusion-ui/web-components";
import * as React from "react";

import { FundBenchmarkTypeSelect } from "./FundBenchmarkTypeSelect";

const FundBenchmarkTypeSelectionEditWidget = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange =
    (key: string) => (option: SelectOptionsType<string> | null) => {
      changeConfigKeyToBeApplied(key, option?.value);
    };

  const { defaultValue = null, key = "Fund Benchmark Type" } =
    editedConfig as FundBenchmarkTypeSelectionWidgetConfig;

  return (
    <>
      <FormSectionAccordion title="Fund Benchmark Type Options">
        <TextInput
          label="Key"
          value={key}
          name="key"
          onChange={handleTextChange("key")}
        />
        <FundBenchmarkTypeSelect
          value={defaultValue}
          label="Default Fund Benchmark Type"
          onChange={handleBasicChange("defaultValue")}
        />
      </FormSectionAccordion>
    </>
  );
};

export default FundBenchmarkTypeSelectionEditWidget;
