import { Button, ButtonThemeOptions, Modal } from "@enfusion-ui/web-components";
import { useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";

import {
  ActionContainer,
  InLineGridWrapper,
  InLineGridWrapperMobile,
  MobileWrapper,
  TitleContainer,
  WebWrapper,
} from "./styles";
import VarSwapOrderSummaryPanel from "./VarSwapOrderSummaryPanel";

export const VarSwapOrderSummaryModalCore: React.FC = () => {
  const isMobileDevice = useIsContentMobile();

  if (isMobileDevice) {
    return (
      <MobileWrapper>
        <InLineGridWrapperMobile padding="0rem 1rem 0rem 0.5rem">
          <VarSwapOrderSummaryPanel />
        </InLineGridWrapperMobile>
      </MobileWrapper>
    );
  }
  return (
    <WebWrapper>
      <InLineGridWrapper width="16rem">
        <VarSwapOrderSummaryPanel />
      </InLineGridWrapper>
    </WebWrapper>
  );
};

export type VarSwapOrderSummaryModalProps = {
  open: boolean;
  title?: string;
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
  submitActionTheme?: ButtonThemeOptions;
  children: React.ReactElement;
};

const VarSwapOrderSummaryModal: React.FC<VarSwapOrderSummaryModalProps> = (
  props: VarSwapOrderSummaryModalProps
) => {
  const { open, title, onCancel, onSubmit, submitActionTheme, children } =
    props;

  return (
    <Modal
      isOpen={open}
      onClose={onCancel}
      title={<TitleContainer>{title}</TitleContainer>}
      content={
        <div style={{ display: "flex", flexDirection: "column" }}>
          {children}
          <ActionContainer>
            <Button theme={submitActionTheme} onClick={onSubmit}>
              Proceed
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </ActionContainer>
        </div>
      }
    />
  );
};

export default VarSwapOrderSummaryModal;
