import { CDXOrderFormValues } from "@enfusion-ui/core";
import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { ControlledEmpty, ControlledSelect } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export type CCPOptions = {
  label: string;
  value: number;
};

export type CCPSelectProps = {
  disabled?: boolean;
};

const CCPSelect = ({ disabled = false }: CCPSelectProps) => {
  const { setValue } = useFormContext<CDXOrderFormValues>();
  const ccp = useWatch<number | null>({ name: "ccp" });

  const { options, loading } = useRestAbortableOptions(
    REST_API.LEGALENTITY.GET_CCP.FETCH,
    (ccp) => ({ value: ccp.id, label: ccp.shortName })
  );

  React.useEffect(() => {
    const selectedCcp = options.find((option) => option.value === Number(ccp));
    setValue("ccpShortName", selectedCcp?.label);
  }, [ccp, options]);

  return (
    <>
      <ControlledEmpty name="ccpShortName" />
      <ControlledSelect
        name="ccp"
        label="CCP"
        options={options}
        isLoading={loading}
        disabled={disabled}
        inputId="cdx-ccp-input-id"
      />
    </>
  );
};

export default CCPSelect;
