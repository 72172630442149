import { FormType } from "@enfusion-ui/core";
import { EmptyView } from "@enfusion-ui/web-components";
import * as React from "react";

import OrderTicket from "./OrderTicket/OrderTicket";

type NewOrderViewProps = {
  config: {
    formType?: FormType;
    custom?: boolean;
  };
};

const NewOrderView: React.FC<NewOrderViewProps> = ({ config }) => {
  return (
    <EmptyView thinTopBar contentStyles={{ borderTopWidth: 0 }}>
      <OrderTicket orderId={0} autoFocus="details" {...config} />
    </EmptyView>
  );
};

export default NewOrderView;
