import { varSwapOptions } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  ExecutionOrderSide,
  FinancialSubType,
  FinancialType,
} from "@enfusion-ui/types";
import { formatPercentage } from "@enfusion-ui/utils";
import {
  Button,
  ButtonGroupSelect,
  ButtonThemeOptions,
  ControlledInputBase,
  ControlledNumericInput,
  ControlledSelect,
  DatePicker as DatePickerInput,
  Modal,
  PanelRow,
  Title,
} from "@enfusion-ui/web-components";
import { REST_API, styled, useOEMS } from "@enfusion-ui/web-core";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import CounterpartySelection from "../CounterpartySelection";

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: var(--spacing-xl);
  gap: var(--spacing-xl);
  overflow-x: hidden;
`;

const ContentContainer = styled.div`
  margin-top: var(--spacing-l);
`;

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--spacing-l);
`;

export type VarSwapVoiceOrderFillModalProps = {
  open: boolean;
  title?: string;
  onSubmit: (fills: VarSwapVoiceOrderFormValues) => void;
  onCancel: VoidFunction;
  submitActionTheme?: ButtonThemeOptions;
  instrumentType?: FinancialType;
  instrumentSubtype?: FinancialSubType;
  orderId?: number | null;
};

const TitleContainer = styled(Title)`
  font-size: 0.875rem;
`;

export type VarSwapVoiceOrderFormValues = {
  tradeDate: Date | null;
  counterpartyShortName: string;
  strike: number | undefined;
  orderSide: ExecutionOrderSide | null;
  notionalType: string | null;
  notionalCcy: string | null;
  vegaNotional: number | null;
  varianceUnits: number | null;
};

export const varSwapVoiceOrderFormDefaultValues: VarSwapVoiceOrderFormValues = {
  tradeDate: null,
  counterpartyShortName: "",
  strike: 0,
  orderSide: null,
  notionalType: null,
  notionalCcy: null,
  vegaNotional: null,
  varianceUnits: null,
};

export const VarSwapVoiceOrderFillModal: React.FC<
  VarSwapVoiceOrderFillModalProps
> = (props: VarSwapVoiceOrderFillModalProps) => {
  const { open, title, orderId, onCancel, onSubmit, submitActionTheme } = props;
  const formMethods = useForm<VarSwapVoiceOrderFormValues>();
  const { currencyDetails } = useOEMS();
  const currencyOptions = React.useMemo(() => {
    return currencyDetails
      ? Object.keys(currencyDetails).map((i) => {
          return { value: currencyDetails[i], label: i };
        })
      : [];
  }, [currencyDetails]);

  const [counterpartyId, setCounterpartyId] = React.useState<number | null>();
  const [activeInput, setActiveInput] = React.useState<string | null>(
    "varianceUnits"
  );
  const { reset, getValues, setValue, watch } = formMethods;

  const { vegaNotional, varianceUnits, orderSide, strike } = watch([
    "vegaNotional",
    "varianceUnits",
    "orderSide",
    "strike",
  ]);

  React.useEffect(() => {
    const getCounterParty = async () => {
      if (orderId) {
        const orderDetails = await REST_API.OEMS.GET_ORDER("varSwap", orderId);
        setCounterpartyId(orderDetails?.counterpartyId);
      }
    };
    getCounterParty();
  }, [orderId]);

  React.useEffect(() => {
    const getUnitsOrNotional = async () => {
      const response = await REST_API.OEMS.CALCULATE_VARIANCE_NOTIONAL.FETCH({
        orderId,
        vegaNotional: activeInput === "vegaNotional" ? varianceUnits : null,
        varianceUnits: activeInput === "varianceUnits" ? vegaNotional : null,
        orderSide,
        strike: strike ? strike * 0.01 : 0,
      });

      setValue("vegaNotional", response.vegaNotional);
      setValue("varianceUnits", response.varianceUnits);
    };
    getUnitsOrNotional();
  }, [activeInput, orderSide, orderId, strike]);

  const handleSubmit = () => {
    onSubmit?.(getValues());
  };

  const closeModal = () => {
    reset();
    onCancel();
  };

  const handleVegaNotionalChange = useRefCallback(
    (value: number | null) => {
      const getUnitsOrNotional = async () => {
        const response = await REST_API.OEMS.CALCULATE_VARIANCE_NOTIONAL.FETCH({
          orderId,
          vegaNotional: value,
          varianceUnits: null,
          orderSide,
          strike: strike ? strike * 0.01 : 0,
        });
        setValue("varianceUnits", response.varianceUnits);
      };
      getUnitsOrNotional();
    },
    [setValue, orderSide, orderId, strike]
  );

  const handleVarianceUnitsChange = useRefCallback(
    (value: number | null) => {
      const getUnitsOrNotional = async () => {
        const response = await REST_API.OEMS.CALCULATE_VARIANCE_NOTIONAL.FETCH({
          orderId,
          vegaNotional: null,
          varianceUnits: value,
          orderSide,
          strike: strike ? strike * 0.01 : 0,
        });
        setValue("vegaNotional", response.vegaNotional);
      };
      getUnitsOrNotional();
    },
    [setValue, orderSide, orderId, strike]
  );

  return (
    <Modal
      isOpen={open}
      onClose={closeModal}
      title={<TitleContainer>{title}</TitleContainer>}
      content={
        <FormProvider {...formMethods}>
          <form>
            <FormContainer>
              <ContentContainer>
                <ControlledInputBase
                  name="tradeDate"
                  defaultValue={new Date()}
                  render={({ ref: _ref, value, ...rest }) => (
                    <DatePickerInput
                      {...rest}
                      label="Trade Date"
                      clearable
                      value={value}
                      dateFormat="yyyy-MM-dd"
                    />
                  )}
                />
                <ControlledNumericInput
                  name="strike"
                  label="Strike"
                  defaultValue={0}
                  formatValue={(num) => formatPercentage(Number(num) / 100)}
                />

                <CounterpartySelection defaultValue={counterpartyId} />

                <>
                  <ControlledSelect
                    name="orderSide"
                    label="Order Side"
                    options={varSwapOptions()}
                    inputId="order-side-input-id"
                  />
                  <ButtonGroupSelect
                    selectedValue={activeInput}
                    onSelect={setActiveInput}
                    style={{ marginTop: "8px" }}
                    options={[
                      { label: "Vega Notional", value: "vegaNotional" },
                      { label: "Variance Units", value: "varianceUnits" },
                    ]}
                    disableDeselect
                    neutralSeparatorColor
                  />
                  <PanelRow>
                    <ControlledNumericInput
                      label="Vega Notional"
                      name="vegaNotional"
                      onChange={handleVegaNotionalChange}
                      defaultValue={null}
                      disabled={activeInput !== "vegaNotional"}
                    />
                    <ControlledNumericInput
                      label="Variance Units"
                      name="varianceUnits"
                      onChange={handleVarianceUnitsChange}
                      defaultValue={null}
                      disabled={activeInput !== "varianceUnits"}
                    />
                  </PanelRow>
                  <ControlledSelect
                    options={currencyOptions}
                    name="notionalCcy"
                    label="Notional Ccy"
                  />
                </>
              </ContentContainer>

              <ActionContainer>
                <Button onClick={closeModal}>Cancel</Button>
                <Button theme={submitActionTheme} onClick={handleSubmit}>
                  Save and Close
                </Button>
              </ActionContainer>
            </FormContainer>
          </form>
        </FormProvider>
      }
    />
  );
};

export default VarSwapVoiceOrderFillModal;
