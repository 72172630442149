import { OrderFormValues } from "@enfusion-ui/core";
import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { ControlledSelect } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import { isEqual } from "lodash";
import * as React from "react";
import { useFormContext } from "react-hook-form";

export type RoutingStrategyOptions = {
  label: string;
  value: string;
};

export type RoutingStrategySelectionProps = {
  disabled?: boolean;
};

const RoutingStrategySelection: React.FC<RoutingStrategySelectionProps> = ({
  disabled = false,
}) => {
  const { watch, setValue } = useFormContext<OrderFormValues>();
  const { targetCompId, destination, instrument, strategy } = watch([
    "targetCompId",
    "destination",
    "instrument",
    "strategy",
  ]);

  const fetchElectronicRoutingStrategy = React.useCallback(
    (ac?: AbortController) =>
      REST_API.OEMS.GET_ELECTRONIC_ROUTING_STRATEGY.FETCH(
        targetCompId ?? "",
        typeof destination === "string"
          ? destination
          : destination?.description ?? "",
        instrument?.id,
        instrument?.financialSubType?.description,
        ac
      ),
    [targetCompId, destination, instrument]
  );

  const { options, loading } = useRestAbortableOptions(
    destination === null ? null : fetchElectronicRoutingStrategy,
    (value) => ({ value, label: value.description })
  );

  React.useEffect(() => {
    if (
      !loading &&
      !(options?.find((option) => isEqual(option.value, strategy)) ?? false)
    )
      setValue("strategy", null);
  }, [options, loading]);

  return (
    <ControlledSelect
      name="strategy"
      label="Strategy"
      options={options}
      isLoading={loading}
      disabled={
        !!process.env.REACT_APP_ENABLE_STRATEGY_PARAMETER_VALIDATION
          ? disabled
          : true
      }
      required={false}
      mobileBasis="50%"
      inputId="routing-strategy-selection-id"
    />
  );
};

export default RoutingStrategySelection;
