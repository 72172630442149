/* eslint-disable @typescript-eslint/no-explicit-any */
import { NewDashboardConfig } from "@enfusion-ui/types";
import { TabConfig } from "@enfusion-ui/web-core";
import { faChartBar, IconDefinition } from "@fortawesome/pro-regular-svg-icons";

export const getNewDashboardTabContent = ({
  path,
  pdf = false,
  root = "user",
  icon = faChartBar,
  name = "New Dashboard",
  openInEditMode = false,
}: NewDashboardConfig & { icon?: IconDefinition }) => {
  return {
    name,
    component: "dashboard",
    icon,
    unique: `${pdf ? "pdf-" : ""}dashboard-${path}`,
    config: { filePath: path, pdf, root, openInEditMode },
  } as TabConfig;
};
