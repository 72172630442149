import { OrderColumnInfo } from "@enfusion-ui/core";

const persistMainGridState = (
  columns: Record<string, OrderColumnInfo[]> | OrderColumnInfo[],
  storageKey: string
) => {
  const storedColSettings = localStorage.getItem(storageKey);

  if (!storedColSettings) {
    localStorage.setItem(storageKey, JSON.stringify(columns));
  } else {
    const storedCols: OrderColumnInfo[] = JSON.parse(storedColSettings);

    localStorage.setItem(
      storageKey,
      JSON.stringify({ ...storedCols, ...columns })
    );
  }
};

export const persistOnColumnEvent = (
  columns: Record<string, OrderColumnInfo[]> | OrderColumnInfo[],
  storageKey: string
) => {
  persistMainGridState(columns, storageKey);
};

export const loadColumnState = (
  storageKey: string,
  existingCols: OrderColumnInfo[]
) => {
  const storedColSettings = localStorage.getItem(storageKey);
  if (!storedColSettings) {
    console.warn(`Expected column state saved with key: ${storageKey}`);
    return existingCols;
  }

  try {
    const storedCols: OrderColumnInfo[] = JSON.parse(storedColSettings);

    if (storedCols) return storedCols;

    return existingCols;
  } catch (err) {
    console.warn("failed to load col defs", err);
    return existingCols;
  }
};
