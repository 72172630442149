import { ConnectionStatus } from "@enfusion-ui/types";
import { Spinner } from "@enfusion-ui/web-components";
import {
  faCheckCircle,
  faExclamationCircle,
  faSkullCrossbones,
  faStopCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

import { ConnectionType } from "../../../context/connectionStatus/context";

type ConnectionValues = {
  title?: string;
  description?: string;
};
export type ConnectionStatusItem = Record<
  typeof ConnectionStatus[number],
  ConnectionValues
>;
type ConnectionStatusDetails = Record<
  typeof ConnectionType[number],
  ConnectionStatusItem
>;

export type ConnectionStatusOverview = Record<
  typeof ConnectionStatus[number],
  { text: string; icon: React.ReactNode }
>;

export const statusOverview: ConnectionStatusOverview = {
  [ConnectionStatus.CONNECTED]: {
    text: "All services connected.",
    icon: <FontAwesomeIcon icon={faCheckCircle} color="var(--success)" />,
  },
  [ConnectionStatus.CONNECTING]: {
    text: "Services are connecting...",
    icon: <Spinner size="sm" />,
  },
  [ConnectionStatus.DISCONNECTED]: {
    text: "Some services failed to connect.",
    icon: <FontAwesomeIcon icon={faExclamationCircle} color="var(--warning)" />,
  },
  [ConnectionStatus.DISABLED]: {
    text: "Some services are disabled.",
    icon: <FontAwesomeIcon icon={faStopCircle} color="var(--warning)" />,
  },
  [ConnectionStatus.ERROR]: {
    text: "Some services errored in connecting.",
    icon: <FontAwesomeIcon icon={faSkullCrossbones} color="var(--danger)" />,
  },
};

export const statusText: ConnectionStatusDetails = {
  [ConnectionType.API]: {
    [ConnectionStatus.CONNECTED]: {
      title: "API Connected",
      description: "Ready to use",
    },
    [ConnectionStatus.CONNECTING]: {
      title: "API Connecting...",
      description: "Establishing connection",
    },
    [ConnectionStatus.DISCONNECTED]: {
      title: "API Failed to Connect",
      description: "Failed to connect",
    },
    [ConnectionStatus.DISABLED]: {
      title: "API Connection disabled",
      description: "Unable to connect",
    },
    [ConnectionStatus.ERROR]: {
      title: "API Error",
      description: "Failed to connect",
    },
    [ConnectionStatus.ERROR_RECONNECTING]: {
      title: "API Error - Reconnecting ...",
      description: "Failed to connect. Retrying to establish connection",
    },
    [ConnectionStatus.RECONNECTING]: {
      title: "API Reconnecting ...",
      description: "Retrying to establish connection",
    },
    [ConnectionStatus.WAITING_ON_MARKET_DATA]: {
      title: "Waiting on marketdata ...",
      description: "Real time marketdata connecting...",
    },
  },
  [ConnectionType.MarketData]: {
    [ConnectionStatus.CONNECTED]: {
      title: "Market Data Connected",
      description: "Ready to use",
    },
    [ConnectionStatus.CONNECTING]: {
      title: "Market Data Connecting...",
      description: "Establishing connection",
    },
    [ConnectionStatus.DISCONNECTED]: {
      title: "Market Data Failed to Connect",
      description: "Failed to connect",
    },
    [ConnectionStatus.DISABLED]: {
      title: "Market Data Disabled",
      description: "Unable to connect",
    },
    [ConnectionStatus.ERROR]: {
      title: "Market Data Error",
      description: "Failed to connect",
    },
    [ConnectionStatus.ERROR_RECONNECTING]: {
      title: "Market Data Error - Reconnecting ...",
      description: "Failed to connect. Retrying to establish connection",
    },
    [ConnectionStatus.RECONNECTING]: {
      title: "Market Data Reconnecting ...",
      description: "Retrying to establish connection",
    },
    [ConnectionStatus.WAITING_ON_MARKET_DATA]: {
      title: "Waiting on marketdata ...",
      description: "Real time marketdata connecting...",
    },
  },
  [ConnectionType.OEMS]: {
    [ConnectionStatus.CONNECTED]: {
      title: "OEMS Connected",
      description: "Ready to use",
    },
    [ConnectionStatus.CONNECTING]: {
      title: "OEMS Connecting...",
      description: "Establishing connection",
    },
    [ConnectionStatus.DISCONNECTED]: {
      title: "OEMS Failed to Connect",
      description: "Failed to connect",
    },
    [ConnectionStatus.DISABLED]: {
      title: "OEMS Connection Disabled",
      description: "Unable to connect",
    },
    [ConnectionStatus.ERROR]: {
      title: "OEMS Error",
      description: "Failed to connect",
    },
    [ConnectionStatus.ERROR_RECONNECTING]: {
      title: "OEMS Error - Reconnecting ...",
      description: "Failed to connect. Retrying to establish connection",
    },
    [ConnectionStatus.RECONNECTING]: {
      title: "OEMS Reconnecting ...",
      description: "Retrying to establish connection",
    },
    [ConnectionStatus.WAITING_ON_MARKET_DATA]: {
      title: "Waiting on marketdata ...",
      description: "Real time marketdata connecting...",
    },
  },
  [ConnectionType.Factset]: {
    [ConnectionStatus.CONNECTED]: {
      title: "FactSet Subscribed",
      description: "Realtime data connected",
    },
    [ConnectionStatus.CONNECTING]: {
      title: "FactSet Subscribing...",
      description: "Realtime data connecting",
    },
    [ConnectionStatus.DISCONNECTED]: {
      title: "FactSet Failed to Subscribe",
      description: "Realtime data failed to connect",
    },
    [ConnectionStatus.DISABLED]: {
      title: "FactSet Subscription Disabled",
      description: "Realtime data disabled",
    },
    [ConnectionStatus.ERROR]: {
      title: "FactSet Error",
      description: "Realtime data failed to connect",
    },
    [ConnectionStatus.ERROR_RECONNECTING]: {
      title: "FactSet Error - Reconnecting ...",
      description: "Failed to connect. Retrying to establish connection",
    },
    [ConnectionStatus.RECONNECTING]: {
      title: "FactSet Reconnecting ...",
      description: "Retrying to establish connection",
    },
    [ConnectionStatus.WAITING_ON_MARKET_DATA]: {
      title: "Waiting on marketdata ...",
      description: "Real time marketdata connecting...",
    },
  },
  [ConnectionType.BloombergRealtime]: {
    [ConnectionStatus.CONNECTED]: {
      title: "Bloomberg Subscribed",
      description: "Realtime data connected",
    },
    [ConnectionStatus.CONNECTING]: {
      title: "Bloomberg Subscribing...",
      description: "Realtime data connecting",
    },
    [ConnectionStatus.DISCONNECTED]: {
      title: "Bloomberg Failed to Subscribe",
      description: "Realtime data failed to connect",
    },
    [ConnectionStatus.DISABLED]: {
      title: "Bloomberg Subscription Disabled",
      description: "Realtime data disabled",
    },
    [ConnectionStatus.ERROR]: {
      title: "Bloomberg Error",
      description: "Realtime data failed to connect",
    },
    [ConnectionStatus.ERROR_RECONNECTING]: {
      title: "Bloomberg Error - Reconnecting ...",
      description: "Failed to connect. Retrying to establish connection",
    },
    [ConnectionStatus.RECONNECTING]: {
      title: "Bloomberg Reconnecting ...",
      description: "Retrying to establish connection",
    },
    [ConnectionStatus.WAITING_ON_MARKET_DATA]: {
      title: "Waiting on marketdata ...",
      description: "Real time marketdata connecting...",
    },
  },
  [ConnectionType.Reports]: {
    [ConnectionStatus.CONNECTED]: {
      title: "Reports Subscribed",
      description: "Reports engine connected",
    },
    [ConnectionStatus.CONNECTING]: {
      title: "Reports Subscribing...",
      description: "Reports engine connecting",
    },
    [ConnectionStatus.DISCONNECTED]: {
      title: "Reports Failed to Subscribe",
      description: "Reports engine failed to connect",
    },
    [ConnectionStatus.DISABLED]: {
      title: "Reports Subscription Disabled",
      description: "Reports engine disabled",
    },
    [ConnectionStatus.ERROR]: {
      title: "Reports Error",
      description: "Reports engine failed to connect",
    },
    [ConnectionStatus.ERROR_RECONNECTING]: {
      title: "Reports Error - Reconnecting ...",
      description: "Failed to connect. Retrying to establish connection",
    },
    [ConnectionStatus.RECONNECTING]: {
      title: "Reports Reconnecting ...",
      description: "Retrying to establish connection",
    },
    [ConnectionStatus.WAITING_ON_MARKET_DATA]: {
      title: "Waiting on marketdata ...",
      description: "Real time marketdata connecting...",
    },
  },
};
