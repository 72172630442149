import {
  PortfolioProposedOrderEditFields,
  useBulkEditProposedOrdersLogic,
  usePortfolio,
} from "@enfusion-ui/portfolios";
import {
  Button,
  ControlledSelect,
  ControlledTextInput,
  Modal,
  ViewContainer,
} from "@enfusion-ui/web-components";
import { REST_API, styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  InputWrapper,
  SingleRowContainer,
} from "../../dashboards/widgets/styles";
import {
  LimitPrice,
  OrderType,
  StopPrice,
} from "../../oems/components/ElectronicLimitDetailEntry";
import { timeInForceOptions } from "../../oems/components/ExecutionInfoPanel";

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  margin: 1.5rem 0 var(--spacing-l);
`;

type BulkEditOrderModalProps = {
  open: boolean;
  onClose: () => void;
  onSave: (data: Partial<PortfolioProposedOrderEditFields>) => void;
};

const BulkEditOrderModal: React.FC<BulkEditOrderModalProps> = ({
  open,
  onClose,
  onSave,
}) => {
  const formMethods = useForm<PortfolioProposedOrderEditFields>();
  const { control, getValues, setValue } = formMethods;

  const {
    strategyData: { options: strategyOptions, loading: strategiesLoading },
    tradingDeskData: {
      options: tradingDeskOptions,
      loading: tradingDeskLoading,
    },
    portfolioManagerData: {
      options: portfolioManagerOptions,
      loading: portfolioManagersLoading,
    },
  } = usePortfolio();

  const { traderOptions, traderLoading } = useBulkEditProposedOrdersLogic(
    setValue,
    control,
    REST_API
  );

  const handleSave = () => {
    onSave(getValues());
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      title="Modify Selected"
      isOpen={open}
      onClose={handleClose}
      content={
        <FormProvider {...formMethods}>
          <ViewContainer>
            <SingleRowContainer count={3}>
              <OrderType required={false} dataE2EId="order-type" />
              <LimitPrice disabled={false} tickSize={0.01} min={0} clearable />
              <StopPrice disabled={false} tickSize={0.01} min={0} clearable />
            </SingleRowContainer>
            <SingleRowContainer>
              <ControlledSelect
                name="bookId"
                label="Book Strategy"
                options={strategyOptions}
                isLoading={strategiesLoading}
              />
              <ControlledSelect
                name="timeInForce"
                label="TIF"
                options={timeInForceOptions}
              />
            </SingleRowContainer>
            <SingleRowContainer count={3}>
              <ControlledSelect
                name="tradingDesk"
                label="Desk"
                options={tradingDeskOptions}
                isLoading={tradingDeskLoading}
              />
              <ControlledSelect
                name="portfolioManager"
                label="PM"
                options={portfolioManagerOptions}
                isLoading={portfolioManagersLoading}
              />
              <ControlledSelect
                name="trader"
                label="Trader"
                options={traderOptions}
                isLoading={traderLoading}
              />
            </SingleRowContainer>
            <InputWrapper>
              <ControlledTextInput
                name="notes"
                label="Notes"
                textarea
                rows={2}
              />
            </InputWrapper>
            <ActionContainer>
              <Button
                theme="primary"
                onClick={handleSave}
                data-testid="btn-apply"
              >
                Apply Changes
              </Button>
              <Button onClick={handleClose} data-testid="btn-cancel">
                Cancel
              </Button>
            </ActionContainer>
          </ViewContainer>
        </FormProvider>
      }
    />
  );
};

export default BulkEditOrderModal;
