import { AllocationsControlContext, } from "@enfusion-ui/core";
import { useFormTableList, useFormTableListSetter } from "@enfusion-ui/hooks";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { TradeTicketFormTypes } from "../constants";
import { MOCK_DESTINATIONS, MOCK_TRADE_TICKET_ACCRUALS, MOCK_TRADE_TICKET_DATE_STRING, MOCK_TRADE_TICKET_EXTERNAL_IDENTIFIERS, MOCK_TRADE_TICKET_KEYWORDS, MOCK_TRADE_TICKET_LOT_RELIEF, MOCK_TRADE_TICKET_REPORTED_AMOUNTS, MOCK_TRADE_TICKET_USERNAME, } from "../mock-form-data";
import { TradeTicketContext } from "./context";
export const tradeFormDefaultValues = {
    /* General Tab */
    instrument: null,
    positionDate: null,
    positionGrouping: null,
    activeOnly: null,
    relatedPositionsBtn: false,
    barriers: "NoBarriers",
    orderType: null,
    quantity: 0,
    orderPrice: null,
    tradeCCY: null,
    fxRate: null,
    fxRateDivisor: null,
    settleFxRate: null,
    settleFxRateDivisor: null,
    initialMargin: null,
    fees: [],
    commissions: [],
    currencyId: null,
    counterparty: null,
    tradeContact: null,
    instructions: null,
    accountsSelect: null,
    tradeType: null,
    tradeDate: new Date(),
    settleDate: null,
    externalRef: null,
    internalRef: null,
    tradeBookingStatusSelect: null,
    originalTradeDate: null,
    dealId: null,
    originalSettleDate: null,
    positionBlock: null,
    initialMarginUnitTypes: null,
    currencyType: null,
    orderNotes: null,
    financingAgreementId: null,
    borrowAgreementId: null,
    allocationsType: null,
    optimization: null,
    importFrom: null,
    settleCCY: null,
    settleCcyId: null,
    allocationTemplateId: null,
    accountAllocation: null,
    allocationSchemeId: null,
    allocationInstructions: null,
    generalLedger: null,
    assetLiabilityAccrualAccount: null,
    incomeExpenseAccrualAccount: null,
    incomeExpenseRealizedAccount: null,
    /* General Tab - Loan/Borrow */
    paymentType: "FixedRate",
    paymentFrequency: null,
    accrualMethod: null,
    paymentBDC: null,
    firstPaymentDate: null,
    compoundingFrequency: null,
    settleAdjustmentDays: null,
    initialSpread: null,
    benchmark: null,
    lookbackDays: null,
    floorRate: null,
    spreadCalcMethod: null,
    priceLookbackDays: null,
    fixedRate: null,
    fixedVariableRates: [],
    cleanPrice: null,
    priceResetsDaily: false,
    principalOverride: null,
    accruedOverride: null,
    useAccruedOverride: false,
    otherBorrowFee: null,
    /* General Tab - Collateral Pledge */
    startDate: null,
    terminationDate: null,
    relatedDealEndDate: null,
    haircutType: "Haircut",
    haircutValue: null,
    /* General Tab - varSwap */
    effectiveDate: null,
    expiryDate: null,
    dayCount: null,
    strike: null,
    strikeTwo: null,
    lowerCap: null,
    upperCap: null,
    lowerBarrier: null,
    upperBarrier: null,
    businessDayConvention: null,
    underlying: null,
    fixingRate: null,
    finalDayOnly: false,
    conditional: "None",
    knockInOut: "NoBarriers",
    alternativeBarrierInstrument: null,
    notionalType: null,
    varianceUnits: null,
    vegaNotional: null,
    notionalCurrency: null,
    notionalAmount: null,
    maturityDate: null,
    strikeCurrency: null,
    settleDays: null,
    underlyingInstrumentSelect: null,
    transactionType: null,
    callPuts: null,
    callPutsTwo: null,
    detailOptionsQuantity: null,
    contractSize: null,
    dateExpiry: null,
    exerciseType: null,
    fxRateTradeToBook: null,
    pricePerContract: null,
    premiumCurrencyType: null,
    volatilityProxy: null,
    settlementType: null,
    noBarriersTriggerDate: null,
    barrierType: null,
    singleBarrierType: null,
    terminatingDate: null,
    rebate: null,
    barriersInstrumentSelect: null,
    doubleBarrierType: null,
    doubleBarrierInstrument: null,
    lowerLevel: null,
    upperLevel: null,
    barrierEffectiveDate: null,
    barrierTerminatingDate: null,
    /* Valuation Tab */
    valuationValueDate: null,
    valuationMarketEnvironment: null,
    /* Keywords Tab */
    keywords: MOCK_TRADE_TICKET_KEYWORDS,
    /* History Tab */
    historyArchiveDate: null,
    creationDate: MOCK_TRADE_TICKET_DATE_STRING,
    lastModifiedDate: MOCK_TRADE_TICKET_DATE_STRING,
    createdBy: MOCK_TRADE_TICKET_USERNAME,
    lastModifiedBy: MOCK_TRADE_TICKET_USERNAME,
    /* Documents Tab */
    documents: [],
    /* Lot Relief Tab */
    lotReliefs: MOCK_TRADE_TICKET_LOT_RELIEF,
    /* Accruals Tab */
    accruals: MOCK_TRADE_TICKET_ACCRUALS,
    /* External Identifiers Tab */
    externalIdentifiers: MOCK_TRADE_TICKET_EXTERNAL_IDENTIFIERS,
    /* Instructions Tab */
    destinations: MOCK_DESTINATIONS,
    instructionActions: {},
    /* Reported Amounts Tab */
    reportedAmounts: MOCK_TRADE_TICKET_REPORTED_AMOUNTS,
    /* option details  */
    cut: null,
    cutTwo: null,
    optionSettleDate: null,
    triggerDate: null,
    payoutCurrencyType: null,
    amortizingSchedule: [],
};
const { FUTURE, INDEX, EQUITY_OPTIONS, FUTURE_OPTIONS, WARRANT, OTC_OPTION, EQUITY_FLEX_OPTION, OTC_SPREAD_OPTIONS, OTC_OUTPERFORMANCE_OPTION, } = TradeTicketFormTypes;
export const TradeTicketProvider = ({ children, formDataType = "equity", tradeId = 0, focusSection }) => {
    const isFutures = React.useMemo(() => {
        return [FUTURE, INDEX, EQUITY_OPTIONS, FUTURE_OPTIONS, WARRANT].includes(formDataType);
    }, [formDataType]);
    const isOTC = React.useMemo(() => {
        return [
            OTC_OPTION,
            EQUITY_FLEX_OPTION,
            OTC_SPREAD_OPTIONS,
            OTC_OUTPERFORMANCE_OPTION,
        ].includes(formDataType);
    }, [formDataType]);
    const [initialTrade] = React.useState(tradeFormDefaultValues);
    const [submitErrors, setSubmitErrors] = React.useState([]);
    const formMethods = useForm({
        defaultValues: tradeFormDefaultValues,
    });
    const [isFormBusy, setIsFormBusy] = React.useState(false);
    const newTrade = tradeId === 0;
    const isEquity = formDataType === TradeTicketFormTypes.EQUITY;
    const isVarSwap = formDataType === TradeTicketFormTypes.VARIANCE_SWAP;
    const isCollateralPledge = formDataType === TradeTicketFormTypes.COLLATERAL_PLEDGE;
    const isDividendSwap = formDataType === TradeTicketFormTypes.DIVIDEND_SWAP;
    const isStockLoanBorrow = formDataType === TradeTicketFormTypes.STOCK_LOAN_BORROW;
    const keywordsControl = useFormTableList(useFormTableListSetter("keywords", formMethods.getValues, formMethods.setValue), initialTrade.keywords);
    const documentsControl = useFormTableList(useFormTableListSetter("documents", formMethods.getValues, formMethods.setValue), initialTrade.documents);
    const lotReliefsControl = useFormTableList(useFormTableListSetter("lotReliefs", formMethods.getValues, formMethods.setValue), initialTrade.lotReliefs);
    const accrualsControl = useFormTableList(useFormTableListSetter("accruals", formMethods.getValues, formMethods.setValue), initialTrade.accruals);
    const reportedAmountsControl = useFormTableList(useFormTableListSetter("reportedAmounts", formMethods.getValues, formMethods.setValue), initialTrade.reportedAmounts);
    const allocationsControl = React.useMemo(() => ({
        entryType: "trade",
        newEntry: newTrade,
        formType: formDataType,
        isEntryCompleted: false,
        isEntryTransmitted: false,
        submitErrors,
        setSubmitErrors,
        isFormBusy,
        setIsFormBusy,
        initialAccountAllocations: initialTrade.accountAllocation,
        /* TODO: verify flags */
        isVarSwapEntry: isVarSwap,
        isFxEntry: false,
        isIrsEntry: false,
        isCdxEntry: false,
        isFutureSpreadEntry: false,
    }), [
        newTrade,
        formDataType,
        submitErrors,
        setSubmitErrors,
        isFormBusy,
        setIsFormBusy,
        initialTrade,
        isVarSwap,
    ]);
    return (React.createElement(TradeTicketContext.Provider, { value: {
            keywordsControl,
            documentsControl,
            lotReliefsControl,
            accrualsControl,
            reportedAmountsControl,
            tradeId: 0,
            newTrade,
            formDataType,
            focusSection,
            initialTrade,
            completed: false,
            isTransmitted: false,
            submitErrors,
            setSubmitErrors,
            isFormBusy,
            setIsFormBusy,
            /* TODO: verify flags */
            isOTC,
            isEquity,
            isVarSwap,
            isFutures,
            isDividendSwap,
            isStockLoanBorrow,
            isCollateralPledge,
            isCdxTrade: false,
            isIrsTrade: false,
            isSwapTrade: false,
            isFxTrade: false,
            isFutureSpreadTrade: false,
        } },
        React.createElement(AllocationsControlContext.Provider, { value: allocationsControl },
            React.createElement(FormProvider, { ...formMethods }, children))));
};
