import { useReportsExplorer } from "@app-context/reports/ReportsExplorerProvider";
import { useRefCallback } from "@enfusion-ui/hooks";
import { CreateFolderModal } from "@enfusion-ui/web-components";
import { useFolderActionContext } from "@enfusion-ui/web-core";

export default function CreateReportFolderModal() {
  const { createFolderModalParams, closeCreateFolderModal } =
    useReportsExplorer();

  const { createFolder } = useFolderActionContext();

  const onSubmit = useRefCallback(
    (folderName: string, folderPath: string) => {
      closeCreateFolderModal();
      createFolder?.(folderName, folderPath, createFolderModalParams?.root);
    },
    [createFolderModalParams]
  );

  return (
    <CreateFolderModal
      root={createFolderModalParams?.root}
      open={!!createFolderModalParams}
      folderPath={createFolderModalParams?.path}
      onCancel={closeCreateFolderModal}
      onSubmit={onSubmit}
    />
  );
}
