/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRefCallback, useRestAbortableOptions } from "@enfusion-ui/hooks";
import { usePortfolio } from "@enfusion-ui/portfolios";
import { SelectOptionsType } from "@enfusion-ui/types";
import { REST_API } from "@enfusion-ui/web-core";
import { ICellEditorParams } from "ag-grid-community";
import * as React from "react";

import ColumnSelect from "../../../../components/inputs/ColumnSelect";

export const TraderCellEditor = React.memo(
  React.forwardRef<any, ICellEditorParams>(
    ({ value, api, data: { tradingDesk } }, ref) => {
      const { tradingDeskData } = usePortfolio();
      const hasDesk = React.useMemo(
        () => tradingDeskData.options.some((i) => i.value === tradingDesk),
        [tradingDeskData.options, tradingDesk]
      );

      const [internalValue, setInternalValue] = React.useState<string | null>(
        hasDesk ? value : null
      );

      const fetchTraderOptions = React.useCallback(
        (ac?: AbortController) =>
          REST_API.SECURITY.GET_TRADERS.FETCH(tradingDesk, ac),
        [tradingDesk]
      );

      const { options, loading } = useRestAbortableOptions(
        tradingDesk ? fetchTraderOptions : null,
        (td) => ({ value: td.id, label: td.fullName })
      );

      React.useImperativeHandle(
        ref,
        () => {
          return {
            // the final value to send to the grid, on completion of editing
            getValue: () => {
              return internalValue;
            },

            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            isCancelBeforeStart: () => false,

            // Gets called once when editing is finished (eg if Enter is pressed).
            // If you return true, then the result of the edit will be ignored.
            isCancelAfterEnd: () => false,
          };
        },
        [internalValue]
      );

      const handleChange = useRefCallback(
        (data: SelectOptionsType<string>) => {
          setInternalValue(data?.value ?? null);
          requestAnimationFrame(() => api.stopEditing());
        },
        [api]
      );

      return (
        <div
          data-testid="trader-cell-editor"
          onBlur={() => requestAnimationFrame(() => api.stopEditing())}
        >
          <ColumnSelect
            autoFocus
            menuIsOpen
            minWidth={50}
            name="trader"
            mobileBasis="50%"
            options={options}
            clearable={false}
            isLoading={loading}
            value={internalValue}
            onChange={handleChange}
          />
        </div>
      );
    }
  )
);
