import { TutorialType } from "@app-components/Tour/utils";
import { PortfolioFolderProvider } from "@app-context/portfolios/PortfolioFolderProvider";
import { AccordionListView } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import PortfolioExplorerProvider from "../../context/portfolios/PortfolioExplorerProvider";
import CreatePortfolioFolderModal from "./components/CreatePortfolioFolderModal";
import { CreatePortfolioModal } from "./components/CreatePortfolioModal";
import PortfolioExplorer from "./PortfolioExplorer";

const SidebarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const SidebarTitle = styled.div`
  height: 40px;
  padding: 0 0.25rem 0 0.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const createExplorer = (
  root: "user" | "shared",
  tutorial?: TutorialType | null
) =>
  React.memo(function PortfolioFileExplorer({ open }: { open: boolean }) {
    return (
      <PortfolioFolderProvider root={root}>
        <PortfolioExplorer root={root} open={open} tutorial={tutorial} />
      </PortfolioFolderProvider>
    );
  });

const items = [
  {
    key: "user",
    title: "MY WORKBENCHES",
    contentComponent: createExplorer("user", TutorialType.Portfolio),
  },
  {
    key: "shared",
    title: "SHARED WORKBENCHES",
    contentComponent: createExplorer("shared", TutorialType.Portfolio),
  },
];

const PortfolioSideBar: React.FC<{ tutorial?: TutorialType | null }> = () => {
  return (
    <SidebarContainer data-e2e-id="portfolio-sidebar-title">
      <SidebarTitle>Portfolio Workbench</SidebarTitle>
      <PortfolioExplorerProvider>
        <AccordionListView
          defaultOpen={{ user: true, shared: true }}
          items={items}
        />

        <PortfolioFolderProvider>
          <CreatePortfolioModal />
          <CreatePortfolioFolderModal />
        </PortfolioFolderProvider>
      </PortfolioExplorerProvider>
    </SidebarContainer>
  );
};

export default PortfolioSideBar;
