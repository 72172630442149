import ControlledAccountSelect from "@app-components/inputs/account/ControlledAccountSelect";
import {
  ControlledSelect,
  PanelRow,
  RadioGroup,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

import PortfolioGroupsSelect from "../../../components/ReportQueryComponents//PortfolioGroupsSelect";
import ControlledBookSelect from "../../../components/ReportQueryComponents/BookSelect";
import { ControlledCorporateActionsSelect } from "../../../components/ReportQueryComponents/CorporateActionsSelect";
import { DateRangeSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { InstrumentsMultiSelect } from "../../../components/ReportQueryComponents/InstrumentsMultiselect";

const RadioSelectionWrapper = styled.div`
  display: flex;
  margin-bottom: var(--spacing);
  margin-top: var(--spacing);
`;

const radioGroupOptions = [
  { label: "Dynamic", value: "dynamic" },
  { label: "Static", value: "static" },
];

const CorporateActionSettings = () => {
  const [resolveDataType, setResolveDataType] =
    React.useState<string>("static");

  const { bookSelection } = useWatch({
    name: ["bookSelection"],
  });
  const bookSelectionRef = React.useRef(bookSelection);

  return (
    <>
      <DateRangeSelection
        name="effectiveDateRange"
        startDateLabel="Effective Date"
        endDateLabel="To"
      />
      <ControlledCorporateActionsSelect />
      <RadioSelectionWrapper>
        <RadioGroup
          name=""
          options={radioGroupOptions}
          value={resolveDataType}
          onChange={(val: string) => {
            setResolveDataType(val);
          }}
          label="Resolve Data: "
          fontSize="x-small"
          inline
        />
      </RadioSelectionWrapper>
      {resolveDataType === "static" ? (
        <PanelRow>
          <InstrumentsMultiSelect />
          <ControlledSelect
            name="trsIds"
            options={[]}
            disabled
            label="Financial Agreement(s)"
          />
        </PanelRow>
      ) : (
        <>
          <ControlledAccountSelect label="Account(s)" />
          <PortfolioGroupsSelect />
          <ControlledBookSelect
            defaultBookSelection={bookSelectionRef.current}
          />
        </>
      )}
    </>
  );
};

export default CorporateActionSettings;
