import { useRefCallback } from "@enfusion-ui/hooks";
import { DashboardRoot } from "@enfusion-ui/types";
import { noop } from "lodash";
import * as React from "react";

import { ExplorerSection } from "../types";
import {
  ExplorerEventsAction,
  ExplorerEventsSubscriptionCallback,
} from "./types";

export const EXPLORER_EVENTS_CHANNEL_NAME = "ef-explorer-events";

export type ExplorerEvents = {
  subscribe: (
    section: ExplorerSection,
    cb: ExplorerEventsSubscriptionCallback
  ) => VoidFunction;
  broadcast: (
    section: ExplorerSection,
    root: DashboardRoot,
    event: ExplorerEventsAction,
    meta?: string
  ) => void;
};

export const ExplorerEventsContext = React.createContext<
  ExplorerEvents | undefined
>(undefined);

export function useExplorerEvents(
  section: ExplorerSection,
  cb: ExplorerEventsSubscriptionCallback
): {
  broadcast: (
    root: DashboardRoot,
    event: ExplorerEventsAction,
    meta?: string
  ) => void;
};
export function useExplorerEvents(section: ExplorerSection): {
  broadcast: (
    root: DashboardRoot,
    event: ExplorerEventsAction,
    meta?: string
  ) => void;
};
export function useExplorerEvents(): {
  broadcast: (
    section: ExplorerSection,
    root: DashboardRoot,
    event: ExplorerEventsAction,
    meta?: string
  ) => void;
};
export function useExplorerEvents(
  section?: ExplorerSection,
  cb?: ExplorerEventsSubscriptionCallback
) {
  const context = React.useContext(ExplorerEventsContext);
  if (context === undefined) {
    throw new Error(
      "useExplorerEvents must be used within a ExplorerEventsProvider"
    );
  }

  React.useEffect(() => {
    let unsubscribe: VoidFunction = noop;

    if (typeof section === "string" && typeof cb === "function")
      unsubscribe = context.subscribe(section, cb);

    return () => {
      unsubscribe();
    };
  }, [section, cb]);

  const shortBroadcast = useRefCallback(
    (root: DashboardRoot, event: ExplorerEventsAction, meta?: string) => {
      if (section) context.broadcast(section, root, event, meta);
    },
    [section, context.broadcast]
  );

  if (section) return { broadcast: shortBroadcast };

  return { broadcast: context.broadcast };
}
