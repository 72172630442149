/* eslint-disable @typescript-eslint/no-explicit-any */
import { TestChannelWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import * as React from "react";

import ChannelDataPicker from "../../../widget/components/ChannelDataPicker";

const TestChannelEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const handleBasicChange = (key: string) => (value: any) => {
    changeConfigKeyToBeApplied(key, value);
  };

  const { channelDataId } = editedConfig as TestChannelWidgetConfig;

  return (
    <>
      <ChannelDataPicker
        label="Test Specific Channel Data"
        name="channelDataId"
        value={channelDataId}
        onChange={handleBasicChange("channelDataId")}
      />
    </>
  );
};

export default TestChannelEditWidget;
