import {
  AccountingMethodSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType, SelectOptionsType } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import { AccountingMethodSelect } from "./AccountingMethodSelect";

const AccountingMethodSelectionWidget: React.FC<unknown> = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, key } = config as AccountingMethodSelectionWidgetConfig;
  const [value, setValue] = React.useState<string | null>(null);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Accounting Method",
        type: ChannelDataType.String,
      },
    ]);
  }, [addChannelKeys, key]);

  React.useEffect(() => {
    setChannelData(value ? [value] : [[]]);
  }, [setChannelData, value]);

  const handleChange = (option: SelectOptionsType<string> | null) => {
    setValue(option?.value || null);
  };

  return (
    <CenterContent>
      <AccountingMethodSelect
        label={key}
        value={value}
        onChange={handleChange}
      />
    </CenterContent>
  );
};

export default AccountingMethodSelectionWidget;
