import { PNL_ADJUSTMENT_INSTRUCTIONS } from "@enfusion-ui/core";
import { TradeTicketFormValues } from "@enfusion-ui/trades";
import { ControlledSelect, FormPanel } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useWatch } from "react-hook-form";

import { PNLAdjustmentAccountsSection } from "./PNLAdjustmentAccountsSection";
import { PNLAdjustmentPanelTitle } from "./PNLAdjustmentPanelTitle";

const TopRowContainer = styled.div`
  position: relative;
`;

const InstructionAnchor = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  font-size: small;
  align-items: center;
  gap: var(--spacing-s);
  color: var(--text-muted);
  :hover {
    opacity: 0.9;
  }
`;

export const PNLAdjustmentPanel: React.FC = () => {
  const tradeType = useWatch<TradeTicketFormValues["tradeType"]>({
    name: "tradeType",
  });

  return (
    <FormPanel
      lineStyle
      collapsible
      defaultOpen
      keepRendered
      numColumns={1}
      defaultRendered
      tabFocusOutline={false}
      title={<PNLAdjustmentPanelTitle />}
    >
      <TopRowContainer>
        <ControlledSelect
          options={[]}
          placeholder="None"
          name="generalLedger"
          label="General Ledger"
          disabled // TODO: Verify enable condition & options
        />
        <InstructionAnchor
          target="_blank"
          referrerPolicy="no-referrer"
          title="Link to the instructions"
          href={PNL_ADJUSTMENT_INSTRUCTIONS.externalLink}
        >
          <FontAwesomeIcon size="sm" icon={faQuestionCircle} />
          Instructions
        </InstructionAnchor>
      </TopRowContainer>

      {tradeType === "PNLAdjustment" ? <PNLAdjustmentAccountsSection /> : null}
    </FormPanel>
  );
};
