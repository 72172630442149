import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import { submitFills } from "@app-views/oems/utils/actions";
import {
  BlotterColumnKey,
  BlotterRow,
  getOrderFormType,
} from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { FinancialSubType, FinancialType } from "@enfusion-ui/types";
import { ConfirmationModal } from "@enfusion-ui/web-components";
import {
  OEMS_EVENTS,
  OemsBroadcastEvent,
  TabConfig,
  useAuth,
  useTabs,
} from "@enfusion-ui/web-core";
import { faDisplay } from "@fortawesome/pro-solid-svg-icons";
import {
  ColDef,
  GetContextMenuItemsParams,
  MenuItemDef,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import React, { useEffect, useState } from "react";

import { createRouteBlotterColumnDefs } from "../../utils/createColumnDefs";
import OrdersList from "../OrdersList";
import useRoutes from "./behavior/useRoutes";
import VoiceOrderFillModal, {
  voiceOrderFormDefaultValues,
  VoiceOrderFormValues,
} from "./VoiceOrderFillModal";

const COLUMNS: BlotterColumnKey[] = [
  "Destination",
  "OrderId",
  "Counterparty",
  "Contract",
  "Status",
  "Method",
  "Txn",
  "Type",
  "Limit",
  "Stop",
  "Filled",
  "FilledPct",
  "Open",
  "Compliance",
  "Broker",
  "Strategy",
  "NotionalTradeAmount",
  "_Control_",
];

const RoutesBlotter: React.FC = () => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>();
  const { onGridReady, routes } = useRoutes();
  const { openTab } = useTabs();
  const { user } = useAuth();
  const [openVoiceFillModal, setOpenVoiceFillModal] =
    React.useState<boolean>(false);
  const [selectedRowInfo, setSelectedRowInfo] = React.useState<{
    orderId: number;
    instrumentType?: FinancialType;
    counterparty?: string | null;
    unfilledQuantity?: number | null;
    financialSubtype?: FinancialSubType;
  }>({ orderId: 0 });
  const [voiceFillData, setVoiceFillData] =
    React.useState<VoiceOrderFormValues>(voiceOrderFormDefaultValues);
  const [overfillConfirmationOpen, setOverfillConfirmationOpen] =
    React.useState<boolean>(false);

  useEffect(() => {
    setColumnDefs(createRouteBlotterColumnDefs(COLUMNS));
  }, [COLUMNS]);

  const channelRef = useBroadcastChannel<OemsBroadcastEvent>(OEMS_EVENTS);

  const getContextMenuItems = useRefCallback(
    (params: GetContextMenuItemsParams) => {
      const { node } = params;
      let menuItems: (string | MenuItemDef)[] = [];

      const row = node?.data as BlotterRow;

      if (
        row?.columnValues.Method === "Voice" &&
        Number(row?.columnValues.FilledPct) < 100 &&
        !row?.completed
      ) {
        menuItems = [
          ...menuItems,
          {
            name: "Add Fills ...",
            tooltip: "Add Fill for Voice order",
            action: () => {
              setOpenVoiceFillModal(true);
              setSelectedRowInfo({
                orderId: row.orderId,
                instrumentType: row.columnValues?.InstrumentType?.value,
                unfilledQuantity: row.columnValues?.Open,
                financialSubtype: row?.columnValues?.InstrumentSubtype?.value,
              });
            },
          },
        ];
      }

      if (menuItems.length > 0) {
        menuItems.unshift("separator");
      }

      const result = [
        "copy",
        "copyWithHeaders",
        "paste",
        ...menuItems,
        "separator",
        "export",
      ];
      return result;
    },
    []
  );

  const handleRowDoubleClick = React.useCallback(
    (e: RowDoubleClickedEvent) => {
      if (!user?.flags?.OMS.readOnly) {
        const { completed, orderId } = e?.data;
        const submissionStatus =
          e.data.columnValues?.SubmissionStatus?.value ?? "";
        const financialSubType = e.data?.columnValues?.InstrumentType?.value;
        const transmitted = e.data?.transmitted;

        openTab({
          name: `Order #${orderId}`,
          component: "oems-order",
          unique: `oems-order-${orderId}`,
          icon: faDisplay,
          config: {
            orderId,
            completed,
            submissionStatus,
            transmitted,
            formType: getOrderFormType(financialSubType, {
              varSwap: !process.env.REACT_APP_ENABLE_VAR_SWAP_ORDERS,
              irs: !process.env.REACT_APP_ENABLE_IRS_ORDERS,
            }),
          },
        } as TabConfig);
        channelRef.broadcastMessage({
          action: "open-order-tab",
          payload: { orderId },
        });
      }
    },
    [user, openTab]
  );

  const handleSubmitFills = (data: VoiceOrderFormValues) => {
    setOpenVoiceFillModal(false);
    if (
      selectedRowInfo?.unfilledQuantity &&
      ((data.quantity && data.quantity > selectedRowInfo?.unfilledQuantity) ||
        (data?.notional && selectedRowInfo?.unfilledQuantity < data?.notional))
    ) {
      setVoiceFillData(data);
      setOverfillConfirmationOpen(true);
    } else {
      submitFills(data, selectedRowInfo, false);
    }
  };

  return (
    <>
      <OrdersList
        columnDefs={columnDefs}
        height="100%"
        rowData={routes}
        getContextMenuItems={getContextMenuItems}
        onGridReady={onGridReady}
        onRowDoubleClicked={handleRowDoubleClick}
        rowGroupPanelShow="always"
        gridName="routes-blotter"
      />
      <ConfirmationModal
        title="Confirm Save Trade for Voice Order Overfill"
        onSubmit={() => {
          submitFills(voiceFillData, selectedRowInfo, true);
          setOverfillConfirmationOpen(false);
        }}
        submitActionTheme="primary"
        open={overfillConfirmationOpen}
        onCancel={() => {
          setOverfillConfirmationOpen(false);
        }}
      >
        The trade quantity for Voice order fill is greater than Voice order
        quantity. Are your sure you want to save this trade overfilling voice
        order?
      </ConfirmationModal>

      {openVoiceFillModal && (
        <VoiceOrderFillModal
          title="New Voice Order Fill"
          open={openVoiceFillModal}
          instrumentSubtype={selectedRowInfo.financialSubtype}
          onSubmit={handleSubmitFills}
          onCancel={() => setOpenVoiceFillModal(false)}
          orderId={selectedRowInfo.orderId}
        />
      )}
    </>
  );
};

export default RoutesBlotter;
