import { useTheme } from "@enfusion-ui/web-core";
import { ICellRendererParams } from "ag-grid-enterprise";
import * as React from "react";
import { usePrevious } from "react-use";

import { getColorBasedOnLastValue } from "./utils";

const PriceCellRenderer: React.FC<ICellRendererParams> = React.memo(
  ({ data, value, valueFormatted }) => {
    const { theme } = useTheme();
    const prevValue = usePrevious(value);
    const [styles, setStyles] = React.useState<
      Record<string, string> | null | undefined
    >(null);

    React.useEffect(() => {
      const textColor = getColorBasedOnLastValue(value, prevValue, theme);
      setStyles({ color: textColor });
    }, [value]);

    if (!data) {
      return null;
    }

    return (
      <div style={styles as React.CSSProperties | undefined}>
        {valueFormatted}
      </div>
    );
  }
);

PriceCellRenderer.displayName = "PriceCellRenderer";
export default PriceCellRenderer;
