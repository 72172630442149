const financialSubtypeDisplayNames: { [key: string]: string } = {
  Equity: "Equity",
  CurrencyPair: "Currency Pair",
  EquityOption: "Equity Option",
  Index: "Index",
  Commodity: "Commodity",
  IndexOption: "Index Option",
  CommodityFuture: "Commodity Future",
  IndexFuture: "Index Future",
  Bond: "Corporate Bond",
  CurrencyFuture: "Currency Future",
  InterestRateFuture: "Interest Rate Future",
  BondFuture: "Bond Future",
  EquityFuture: "Equity Future",
  BondFutureOption: "Bond Future Option",
  CommodityFutureOption: "Commodity Future Option",
  CurrencyFutureOption: "Currency Future Option",
  EquityFutureOption: "Equity Future Option",
  IndexFutureOption: "Index Future Option",
  InterestRateFutureOption: "Interest Rate Future Option",
  MarketRate: "Interest Rate",
  VanillaInterestRateSwap: "Vanilla Interest Rate Swap",
  FXForward: "FX Forward",
  ForwardRateAgreement: "Forward Rate Agreement",
  VanillaFXOption: "FX Option",
  BinaryOneTouchFXDigitalOption: "Binary One Touch",
  BinaryDoubleOneTouchFXDigitalOption: "Binary Double One Touch",
  BinaryNoTouchFXDigitalOption: "Binary No Touch",
  BinaryDoubleNoTouchFXDigitalOption: "Binary Double No Touch",
  DigitalCallFXDigitalOption: "Digital Call",
  DigitalPutFXDigitalOption: "Digital Put",
  DigitalRangeFXDigitalOption: "Digital Range",
  FXOptionBarrier: "FX Option Barrier",
  FXSwap: "FX Swap",
  FXSpot: "FX Spot",
  DepositRate: "Deposit Rate",
  SwapRate: "Swap Rate",
  Future: "Future",
  FutureOption: "Future Option",
  FutureCalendarSpread: "Future Calendar Spread",
  Warrant: "Warrant",
  CreditDefaultSwap: "CDS",
  RestrictedEquity: "Restricted Equity",
  Right: "Right",
  Preferred: "Preferred",
  Cash: "Cash",
  TotalReturnSwap: "TRS",
  LiborRate: "Libor Rate",
  ForwardRate: "Forward Rate",
  VolatilityRate: "Volatility Point",
  DomesticRate: "Domestic Rate",
  DiscountRate: "Discount Rate",
  InterestRateVolatility: "Interest Rate Volatility",
  BankLoan: "Bank Loan",
  ConvertibleBond: "Convertible Bond",
  CDSSpread: "CDS Spread",
  MunicipalBond: "Municipal Bond",
  ConvertiblePreferred: "Convertible Preferred",
  BankersAcceptanceRate: "Banker's Acceptance Rate",
  TreasuryRate: "Treasury Rate",
  TreasuryBond: "Govt/Treasury/Central Bank",
  CDX: "CDX",
  EnergyFuture: "Energy Future",
  CapFloor: "Cap/Floor",
  CapFloorVolatility: "Cap/Floor Volatility",
  BasisSwap: "Basis Swap",
  Swaption: "Swaption",
  SwaptionVolatility: "Swaption Volatility",
  IndexDivisor: "Index Divisor",
  ADR: "ADR",
  VarianceSwap: "Variance Swap",
  VolatilitySwap: "Volatility Swap",
  VarianceVolatilitySwap: "Variance Volatility Swap",
  InterestRateSwap: "Interest Rate Swap",
  FXDigitalOption: "FX Digital Option",
  RepurchaseAgreement: "Repurchase Agreement",
  PreferredBond: "Preferred",
  BRLInterestRateSwap: "BRL CDI Interest Rate Swap",
  CommodityForward: "Commodity Forward",
  CommodityRate: "Commodity Rate",
  ForexFixingRate: "Forex Fixing Rates",
  RealEstate: "Real Estate",
  OTCEquityOption: "OTC Equity Option",
  ABS: "Asset Backed Security",
  LCDX: "LCDX",
  LCDS: "LCDS",
  FRARate: "FRA Rate",
  CDSReferenceObligation: "CDS Reference Obligation",
  InflationSwapRate: "Inflation Swap Rate",
  ZeroCouponInflationSwap: "Zero Coupon Inflation Swap",
  YearOnYearInflationSwap: "Year-on-Year Inflation Swap",
  OISInterestRateSwap: "Overnight Interest Rate Swap",
  ZeroCouponSwap: "Zero Coupon Swap",
  CertificateOfDeposit: "Bank CD",
  StockLoanBorrow: "Stock Loan/Borrow",
  ExchangeTradedFund: "Exchange Traded Fund",
  BasisSwapSpread: "Basis Swap Spread",
  MuniCDS: "Muni CDS",
  FutureSpread: "Future Spread",
  CreditDefaultSwaption: "Credit Default Swaption",
  OTCBondOption: "OTC Bond Option",
  MutualFund: "Mutual Fund",
  HedgeFund: "Hedge Fund",
  RecoveryRateSwap: "Recovery Rate CDS",
  OTCSpreadOption: "OTC Spread Option",
  SwaptionVolatilitySkew: "Swaption Volatility Skew",
  OTCOutperformanceOption: "OTC Outperformance Option",
  OTCWorstOfOption: "OTC Worst Of Option",
  PhysicalCommodity: "Physical Commodity",
  CDXTranche: "CDX Tranche",
  REIT: "REIT",
  MBS: "Mortgage Backed Security",
  FXSpotForward: "FX Spot",
  EquityBasket: "Equity Basket",
  TradeClaim: "Trade Claim",
  BondForward: "Bond Forward",
  CreditIndexSwaption: "Credit Index Swaption",
  ASCOT: "Asset Swapped Convertible Option",
  OTCRealizedVarianceOption: "OTC Realized Variance Option",
  PrivateEquity: "Private Equity",
  BorrowRate: "Borrow Rate",
  NonTradingAsset: "Non-Trading Asset",
  NonTradingLiability: "Non-Trading Liability",
  NonTradingIncome: "Non-Trading Income",
  NonTradingExpense: "Non-Trading Expense",
  BondIndex: "Bond Index",
  CollateralPledge: "Collateral Pledge",
  OTCFlexOption: "Equity FLEX Option",
  SpreadOverride: "Spread Override",
  ParticipatoryNote: "Participatory Note",
  VolatilitySurface: "Equity Option Volatility Surface",
  CrossCurrencyOIS: "Cross Currency OIS",
  CreditFacility: "Credit Facility",
  FundedRevolver: "Funded Revolver",
  UnfundedRevolver: "Unfunded Revolver",
  CMBS: "Commercial Mortgage-Backed Securities",
  CDO: "Collateralized Debt Obligation",
  CMO: "Collateralized Mortgage Obligation",
  TermLoan: "Term Loan",
  FXVolatilitySurface: "FX Volatility Surface",
  EquityForward: "Equity Forward",
  CryptoCurrency: "Crypto Currency",
  CryptoSpot: "Crypto Spot",
  Mortgage: "Mortgage",
  CLO: "Collateralized Loan Obligation",
  DualDigitalOption: "Dual Digital Option",
  DividendSwap: "Dividend Swap",
  VarVolVolatilitySurface: "Var/Vol Volatility Surface",
  SwaptionVolatilitySurface: "Swaption Volatility Cube",
  CDXIndex: "CDX Index",
  TBA_MBS: "To-Be-Announced",
};

export default financialSubtypeDisplayNames;
