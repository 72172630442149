import {
  BlotterColumnKey,
  BlotterRow,
  oemsBlotterColumns,
  oemsColumnMeta,
  OrderColumnInfo,
  OrdersTableColumn,
} from "@enfusion-ui/core";
import {
  CDef,
  CDefObj,
  ComplianceCellValue,
  EnumCellValue,
  QuoteCellValue,
} from "@enfusion-ui/types";
import {
  dataEntry,
  formatCellValues,
  orderCellFormatter,
  orderCellStyle,
  valueTooltipGetter,
} from "@enfusion-ui/web-core";
import {
  ColDef,
  SuppressKeyboardEventParams,
  ValueGetterParams,
} from "ag-grid-community";
import { parseISO } from "date-fns";
import { get, isUndefined, omit } from "lodash";

export function lookupType(ctc: string) {
  if (
    ctc === "Quantity" ||
    ctc.includes("Price") ||
    ctc.includes("Number") ||
    ctc.includes("Percent") ||
    ctc.includes("Quantity") ||
    ctc.includes("Integer")
  )
    return "numericColumn";

  if (ctc.includes("Date") || ctc.includes("Time")) return "dateColumn";

  return "standardColumn";
}

const numericColumnFilterDef: ColDef = {
  filter: "agMultiColumnFilter",
  filterParams: {
    filters: [
      {
        filter: "agNumberColumnFilter",
      },
      {
        filter: "agSetColumnFilter",
        filterParams: { valueFormatter: orderCellFormatter },
      },
    ],
  },
};

export const oemsColumnTypes: { [key: string]: ColDef } = {
  dateColumn: {
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agDateColumnFilter",
          filterParams: {
            comparator: (filterDate: Date, cellValue: string | null) => {
              if (cellValue === null) return -1;
              return parseISO(cellValue).getTime() - filterDate.getTime();
            },
          },
        },
        {
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: orderCellFormatter,
            comparator: (valueA: string, valueB: string) => {
              return parseISO(valueA).getTime() - parseISO(valueB).getTime();
            },
          },
        },
      ],
    },
    comparator: (valueA, valueB) =>
      parseISO(valueA).getTime() - parseISO(valueB).getTime(),
  },
  standardColumn: {
    enableRowGroup: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
          filterParams: {
            defaultOption: "startsWith",
          },
        },
        {
          filter: "agSetColumnFilter",
          filterParams: {
            caseSensitive: true,
            valueFormatter: orderCellFormatter,
          },
        },
      ],
    },
  },
};

export const defaultOemsColDef: ColDef = {
  filter: true,
  resizable: true,
  sortable: true,
  enableRowGroup: false,
  suppressKeyboardEvent: ({ event }: SuppressKeyboardEventParams) =>
    event.ctrlKey && event.key === "C",
  valueFormatter: orderCellFormatter,
  cellStyle: orderCellStyle,
  tooltipValueGetter: valueTooltipGetter,
  cellClassRules: {
    warningCell: (args) => dataEntry(args).status === "Warning",
    errorCell: (args) => dataEntry(args).status === "Error",
  },
  // could be part of the default column def
  valueGetter: ({ data, colDef }) => {
    const value = colDef.field ? get(data, colDef.field) : null;
    if (value === null || value === undefined) return null;

    const { columnTypeCategory } = (
      oemsColumnMeta as unknown as Record<string, OrdersTableColumn>
    )[colDef.colId!] || { columnTypeCategory: "Standard" };

    if (columnTypeCategory === "Compliance")
      return (value as ComplianceCellValue).complianceState;
    if (columnTypeCategory === "Enum")
      return (value as EnumCellValue).description || "";
    if (columnTypeCategory === "Quotation") {
      const val = (value as QuoteCellValue).value;
      return isNaN(val) ? null : val;
    }
    return value;
  },
};

export function createOrderColumnDefs(columns: OrderColumnInfo[]): ColDef[] {
  const [keys, map] = columns.reduce(
    (res, entry) => {
      res[0].push(entry.key);
      res[1][entry.key] = entry;
      return res;
    },
    [[], {}] as [BlotterColumnKey[], Record<string, OrderColumnInfo>]
  );
  return createColumnDefs(keys, map);
}

function getDef(def: CDef) {
  const c: CDefObj = typeof def === "string" ? { f: def } : def;
  const key = c.f.replace("columnValues.", "") as BlotterColumnKey;

  const headerName = c.n ? c.n : key;
  const other = omit(c, ["f", "n", "w", "c", "p", "t"]);

  const { columnTypeCategory } = (
    oemsColumnMeta as unknown as Record<string, OrdersTableColumn>
  )[key] || { columnTypeCategory: "Standard" };

  return {
    columnTypeCategory,
    headerName,
    headerClass: c.c,
    headerTooltip: c.p || headerName,
    field: c.f,
    colId: key,
    type: c.t,
    initialWidth: c.w ? c.w : 120,
    cellDataType: c.t,
    ...other,
  };
}

function createColumnDefs(
  keys: BlotterColumnKey[],
  overridesMap: Record<string, OrderColumnInfo> = {}
): ColDef[] {
  const buildDef = (col: ReturnType<typeof getDef>) => {
    const { columnTypeCategory, ...def } = col;

    const { width, sortOrder, pinned, group, sortIndex } = overridesMap[
      def.colId
    ] || {
      key: def.colId,
      sortOrder: "None",
      pinned: "None",
    };

    const colDef: ColDef = {
      ...def,
      initialWidth: width || def.initialWidth,
      initialSort:
        sortOrder === "Ascending"
          ? "asc"
          : sortOrder === "Decending"
          ? "desc"
          : null,
      initialPinned:
        pinned === "Left" ? "left" : pinned === "Right" ? "right" : false,
      initialRowGroup: !isUndefined(group),
      initialRowGroupIndex: !isUndefined(group) ? group : undefined,
      hide: !keys.includes(def.colId),
      initialSortIndex: sortIndex !== undefined ? sortIndex : undefined,
    };

    // could be part of "standardColumn" type
    if (columnTypeCategory === "Enum" || columnTypeCategory === "Compliance") {
      colDef.comparator = (valueA, valueB) => {
        if (!valueA) return -1;
        if (!valueB) return 1;
        const value1 = formatCellValues("Standard", valueA);
        const value2 = formatCellValues("Standard", valueB);
        return value1.localeCompare(value2);
      };
    }

    if (def.colId === "_Control_")
      return { cellRenderer: "transmitCancelCellRenderer", ...colDef };
    if (columnTypeCategory === "PercentFilled")
      return { cellRenderer: "percentageCellRenderer", ...colDef };
    if (def.colId === "InstrumentId") {
      return { enableRowGroup: true, ...colDef };
    }

    if (def.type === "numericColumn") {
      return { ...numericColumnFilterDef, ...colDef };
    }
    return colDef;
  };

  const mainCols = keys.reduce((acc, key) => {
    const col = oemsBlotterColumns.find((e) => getDef(e).colId === key);
    if (col) acc.push(buildDef(getDef(col)));
    return acc;
  }, [] as ColDef[]);
  const columnDefs = oemsBlotterColumns.reduce((acc, key) => {
    const col = getDef(key);
    if (keys.includes(col.colId)) return acc;
    acc.push(buildDef(col));
    return acc;
  }, [] as ColDef[]);

  return [...mainCols, ...columnDefs];
}

export function createRouteBlotterColumnDefs(
  keys: BlotterColumnKey[]
): ColDef[] {
  const colDefs = createColumnDefs(keys);
  return colDefs.map((eachColDef: ColDef) => {
    if (eachColDef.colId && eachColDef.type === "standardColumn") {
      eachColDef.enableRowGroup = true;
    }

    if (eachColDef.colId === "Destination") {
      // To align with Desktop app's behavior
      eachColDef.valueGetter = ({ data }: ValueGetterParams<BlotterRow>) =>
        data?.columnValues.Destination || data?.columnValues.Counterparty || "";
    }

    return eachColDef;
  });
}
