import PositionDetailsPanel from "@app-components/inputs/positionDetailsPanel/PositionDetailsPanel";
import { useInterTabContext } from "@app-context/InterTab/InterTabContextProvider";
import { useRefCallback } from "@enfusion-ui/hooks";
import { FormPanel } from "@enfusion-ui/web-components";
import { SelectionChangedEvent } from "ag-grid-community";
import * as React from "react";

export const PositionInformationPanel: React.FC = () => {
  const { setInterTabContext } = useInterTabContext();

  const handleSelectedChangePosition = useRefCallback(
    (e: SelectionChangedEvent) => {
      const selectedRowData = e.api.getSelectedNodes()[0].data;
      setInterTabContext("selectedPosition", { ...selectedRowData });
    },
    []
  );

  return (
    <FormPanel
      title="Position Information"
      numColumns={1}
      collapsible
      defaultOpen={false}
      keepRendered
      lineStyle
      tabFocusOutline={false}
      dataE2EId="position-info-tab"
    >
      <PositionDetailsPanel
        disabled={false}
        handleSelectedChange={handleSelectedChangePosition}
        showRelatedPositionsBtn
      />
    </FormPanel>
  );
};
