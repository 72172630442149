import {
  TraderSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { WebReportTableParam } from "@enfusion-ui/types";
import { FormSectionAccordion, TextInput } from "@enfusion-ui/web-components";
import * as React from "react";

import { TraderSelect } from "../../../../../reports/components/ReportQueryComponents/AssignedTraderSelect";
import DatasourceParamsFilter from "../../../../widget/components/DatasourceParamsFilter";

const param: WebReportTableParam = {
  name: "desks",
  description: "Desk",
  supportsMultiple: true,
  required: false,
  type: "desk",
};

const TraderSelectionEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();
  const { defaultValue, key } = editedConfig as TraderSelectionWidgetConfig;

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleChange = (deskIds: number[] | null) => {
    changeConfigKeyToBeApplied("defaultValue", deskIds ?? []);
  };

  return (
    <>
      <FormSectionAccordion title="Trader Options">
        <TextInput
          label="Key"
          value={key}
          name="key"
          onChange={handleTextChange("key")}
        />
        <TraderSelect
          selectProps={{ label: "Default Value" }}
          value={defaultValue}
          onChange={handleChange}
        />
        <DatasourceParamsFilter key={param.name} param={param} />
      </FormSectionAccordion>
    </>
  );
};

export default TraderSelectionEditWidget;
