import { ModalTitle } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";

import { LabeledValue } from "../styledComponents";

export const OrderSummaryPanelGridWrapper = styled.div`
  display: flex;
`;

export const InLineGridWrapper = styled.div<{
  margin?: string;
  padding?: string;
  width?: string;
}>`
  font-size: 0.875rem;
  padding: ${({ padding }) => padding ?? "1rem 1rem 1rem 2rem"};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width ?? "13.5rem"};
`;

export const FXInLineGridWrapper = styled.div<{
  margin?: string;
  padding?: string;
}>`
  font-size: 0.875rem;
  padding: ${({ padding }) => padding ?? "1rem 1rem 1rem 2rem"};
  margin: ${({ margin }) => margin};
  width: 32rem;
`;
export const InLineGridWrapperMobile = styled(InLineGridWrapper)`
  margin: 0 0 0 4rem;
`;
export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const WebWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const OrderSummaryGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  margin-top: 15px;
`;

export const TitleContainer = styled(ModalTitle)`
  font-size: 0.875rem;
`;

export const OrderSummaryModalTitle = styled(LabeledValue)`
  font-size: 0.875rem;
  margin: auto;
`;

export const SummaryModalTitle = styled(LabeledValue)`
  font-size: 0.875rem;
  padding-right: 20px;
  margin: auto auto 10px auto;
`;
