import { Model } from "flexlayout-react";
import { noop } from "lodash";
import { createContext } from "react";
export const TabComponentUserPrefMap = {
    oems: "oems",
    "watch-list": "oems",
    "oems-order": "oems",
    "new-oems-order": "oems",
    report: "reports",
    dashboard: "dashboards",
    "portfolio-workbench": "portfolios",
    file: (tabDef) => {
        if (tabDef?.config?.root === "services") {
            return "services";
        }
        return "generalFiles";
    },
    "factset-full-quote": "factset",
    "factset-market-watch": "factset",
};
export const EMPTY_MODEL_JSON = {
    global: {
        splitterSize: 2,
        tabSetTabStripHeight: 38,
        tabEnableRename: false,
    },
    borders: [],
    layout: {
        type: "row",
        weight: 100,
        children: [
            {
                type: "tabset",
                weight: 50,
                selected: 0,
                active: true,
                children: [],
            },
        ],
    },
};
export const initialTabsContextState = () => ({
    tabs: [],
    undockedTabs: [],
    layoutModel: Model.fromJson(EMPTY_MODEL_JSON),
    onTabCloseSubscriptions: [],
    onTabWillCloseSubscriptions: {},
    openTab: noop,
    closeTab: noop,
    focusTab: noop,
    renameTab: noop,
    undockTab: noop,
    dockTab: noop,
    onTabClose: () => noop,
    onTabWillClose: () => noop,
    reUndockTabs: noop,
    closeAllUndocked: noop,
    showUndockConfirmation: false,
});
export const TabsContext = createContext(initialTabsContextState());
export const initialThisTabContextState = {
    id: "",
    selector: "",
    config: {},
    closeTab: noop,
    focusTab: noop,
    renameTab: noop,
    undockTab: noop,
    dockTab: noop,
    onTabWillClose: () => noop,
    width: null,
    height: null,
    visible: false,
};
export const ThisTabContext = createContext(initialThisTabContextState);
