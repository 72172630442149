import PositionDetailsPanel from "@app-components/inputs/positionDetailsPanel/PositionDetailsPanel";
import { WidgetComponentsDefinition } from "@app-views/dashboards/widgets/types";
import { ColumnGrid } from "@enfusion-ui/web-components";

export const positionDetailsPanel: WidgetComponentsDefinition = {
  renderComponent: () => (
    <ColumnGrid numColumns={1} isMobile={false}>
      <PositionDetailsPanel disabled={false} />
    </ColumnGrid>
  ),
};
