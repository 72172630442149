import { AnalyticsFormValues } from "@app-context/analytics/AnalyticsContextProvider";
import { useAnalyticsContext } from "@app-context/analytics/context";
import { PanelItemBase } from "@app-views/analytics/components/styledComponents";
import { TopNGridPanel } from "@app-views/analytics/components/TopNGridPanel";
import { useRefCallback } from "@enfusion-ui/hooks";
import { HorizontallyResizable } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";
import { useMeasure } from "react-use";

import { PerformanceDetailsLeftSection } from "./PerformanceDetailsLeftSection";

const DEFAULT_RIGHT_WIDTH = 500;

const Container = styled(PanelItemBase)`
  display: flex;
  padding: 0;
  gap: var(--space-l);
`;

const LeftSectionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const RightSectionContainer = styled.div`
  border-left: 1px solid var(--background-color-1);
`;

const RightSectionInnerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ScatterChart = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--background-color-1);
`;

const instrumentNameMap = new Map<number, string>();

export const PerformanceDetails: React.FC = () => {
  const { data, changeInstruments } = useAnalyticsContext();
  const { topN = 10 } = useWatch<AnalyticsFormValues>({ name: ["topN"] });

  const [selectedIds, setSelectedIds] = React.useState<Set<number>>(new Set());

  React.useEffect(() => changeInstruments([...selectedIds]), [selectedIds]);

  React.useEffect(() => {
    changeInstruments([...selectedIds]);
    data?.performanceDetails?.contributors?.forEach((entry) => {
      if (!instrumentNameMap.has(entry.instrumentId)) {
        instrumentNameMap.set(
          entry.instrumentId,
          entry.description ?? entry.ticker ?? ""
        );
      }
    });
  }, [data?.performanceDetails?.contributors]);

  const instrumentValues = React.useMemo(() => {
    if (selectedIds.size === 0) return [];
    return data?.performanceDetails?.instrumentValues ?? [];
  }, [data?.performanceDetails?.instrumentValues, selectedIds.size === 0]);

  const getInstrumentName = useRefCallback((id: string | number) => {
    const key = typeof id === "string" ? parseInt(id) : id;
    return instrumentNameMap.get(key);
  }, []);

  const [measureRef, { width }] = useMeasure<HTMLDivElement>();

  return (
    <Container ref={measureRef}>
      <LeftSectionContainer>
        <PerformanceDetailsLeftSection
          selectedIds={selectedIds}
          instrumentValues={instrumentValues}
          getInstrumentName={getInstrumentName}
        />
      </LeftSectionContainer>

      <RightSectionContainer>
        <HorizontallyResizable
          open
          minWidth={50}
          width={DEFAULT_RIGHT_WIDTH}
          maxWidth={(width || DEFAULT_RIGHT_WIDTH) - 50}
        >
          <RightSectionInnerContainer>
            <ScatterChart>Scatter Chart Section</ScatterChart>
            <TopNGridPanel
              topN={topN}
              setSelectedIds={setSelectedIds}
              data={data?.performanceDetails?.contributors ?? []}
            />
          </RightSectionInnerContainer>
        </HorizontallyResizable>
      </RightSectionContainer>
    </Container>
  );
};
