import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { ControlledSelect } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext } from "react-hook-form";

export type RoutingBrokerAccountOptions = {
  label: string;
  value: string;
};

export type RoutingBrokerAccountSelectionProps = {
  disabled?: boolean;
};

const RoutingBrokerAccountSelection: React.FC<
  RoutingBrokerAccountSelectionProps
> = ({ disabled = false }) => {
  const { watch } = useFormContext();
  const { targetCompId, destination } = watch(["targetCompId", "destination"]);

  const fetchElectronicRoutingBrokerAccounts = React.useCallback(
    (ac?: AbortController) =>
      REST_API.OEMS.GET_ELECTRONIC_ROUTING_BROKER_ACCOUNTS.FETCH(
        targetCompId,
        typeof destination === "string"
          ? destination
          : destination?.description,
        ac
      ),
    [targetCompId, destination]
  );

  const { options, loading } = useRestAbortableOptions(
    !destination ? null : fetchElectronicRoutingBrokerAccounts,
    {
      map: (ba) => ({ label: ba.accountPrettyName, value: ba.accountNumber }),
      filter: (ba) => ba.accountNumber !== "" && ba.accountPrettyName !== "",
    }
  );

  return (
    <ControlledSelect
      name="brokerAccount"
      label="Broker Account"
      options={options}
      isLoading={loading}
      disabled={disabled}
      mobileBasis="50%"
      inputId="broker-account-selection-id"
    />
  );
};

export default RoutingBrokerAccountSelection;
