import {
  SourceIdsSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { useRefCallback } from "@enfusion-ui/hooks";
import { ChannelDataType } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import { SourceIdsSelect } from "../../../../../reports/components/ReportQueryComponents/SourceIdsSelect";

const SourceIdsSelectionWidget = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, key } = config as SourceIdsSelectionWidgetConfig;
  const [value, setValue] = React.useState<string[] | null>(defaultValue);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Source Ids",
        type: ChannelDataType.Number,
      },
    ]);
  }, [addChannelKeys, key]);

  React.useEffect(() => {
    setChannelData(value ? [value] : [[]]);
  }, [setChannelData, value]);

  const handleChange = useRefCallback(
    (newValue: number[] | null) => {
      setValue(newValue?.map((val) => `${val}`) ?? []);
    },
    [setValue]
  );

  return (
    <CenterContent>
      <SourceIdsSelect
        label={key}
        value={value?.map((val) => Number(val))}
        onChange={handleChange}
      />
    </CenterContent>
  );
};

export default SourceIdsSelectionWidget;
