import { DimensionsProvider } from "@enfusion-ui/web-core";
import * as React from "react";
import { useMeasure } from "react-use";

export const SizeGridWrapper: React.FC<React.PropsWithChildren<{
  style?: React.CSSProperties;
  className?: string;
}>> = ({ children, className, style = {} }) => {
  const [ref, { width, height }] = useMeasure<HTMLDivElement>();

  return (
    <div
      className={className}
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        flex: 1,
        display: "flex",
        ...style,
      }}
    >
      <DimensionsProvider width={width} height={height}>
        {children}
      </DimensionsProvider>
    </div>
  );
};
