import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { ControlledSelect } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";

export type CDXRootOptions = {
  label: string;
  value: number;
};

export type CDXRootSelectProps = {
  disabled?: boolean;
};

const CDXRootSelect = ({ disabled = false }: CDXRootSelectProps) => {
  const { options, loading } = useRestAbortableOptions(
    REST_API.INSTRUMENT.GET_CDX_ROOTS.FETCH,
    ({ description }) => ({ value: description, label: description })
  );

  return (
    <ControlledSelect
      name="cdxRoot"
      label="CDX Root"
      options={options}
      isLoading={loading}
      disabled={disabled}
      inputId="cdx-root-id"
    />
  );
};

export default CDXRootSelect;
