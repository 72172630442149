import queryString from "query-string";
import { varReplace } from "./utils/varReplace";
const baseUrl = "/internal/api/instrument";
export const calls = {
    FAST_SEARCH: {
        url: `${baseUrl}/search/fast`,
        query: {
            searchText: { optional: false, defaultValue: "ABC" },
            activeOnly: { defaultValue: false },
            primaryOnly: { defaultValue: false },
            searchDescription: { defaultValue: false },
            financialSubTypes: { optional: true, defaultValue: "" },
        },
    },
    GET_INSTRUMENT_INFO: {
        url: `${baseUrl}/{id}`,
        urlParams: {
            id: { defaultValue: 1 },
        },
    },
    GET_CURRENCY_PAIR: {
        url: `${baseUrl}/currencies/pairs/{id}`,
        urlParams: {
            id: { defaultValue: 1 },
        },
    },
    GET_CURRENCY_PAIRS: { url: `${baseUrl}/currencies/pairs` },
    GET_SETTLEMENT_CURRENCIES: { url: `${baseUrl}/currencies` },
    GET_CDX: { url: `${baseUrl}/cdx` },
    GET_CDX_ALL: { url: `${baseUrl}/cdx/all` },
    GET_CDX_ROOTS: { url: `${baseUrl}/cdx/roots` },
    GET_QUICK_TEMPLATES: { url: `${baseUrl}/irs/templates` },
    GET_CDX_INSTRUMENT: {
        url: `${baseUrl}/cdx`,
        query: {
            cdxRoot: { optional: false, defaultValue: "" },
            otrOnly: { defaultValue: false },
        },
    },
    GET_FRONT_MONTH_FUTURE: {
        url: `${baseUrl}/frontMonthFuture`,
        query: {
            instrumentId: { optional: false, defaultValue: "" },
        },
    },
    OPTIONS: {
        GET_OPTIONS: {
            url: `${baseUrl}/options/{id}/{expirationDate}`,
            urlParams: {
                id: { defaultValue: 1 },
                expirationDate: { defaultValue: "" },
            },
        },
        GET_EXPIRATION_DATES: {
            url: `${baseUrl}/optionsExpirationDates/{id}`,
            urlParams: {
                id: { defaultValue: 1 },
            },
            query: {
                historical: { defaultValue: false },
            },
        },
    },
};
export default (callCreator) => {
    return {
        FAST_SEARCH: callCreator((query) => queryString.stringifyUrl({
            url: calls.FAST_SEARCH.url,
            query: {
                ...query,
                financialSubTypes: query?.financialSubTypes?.join(",") ?? undefined,
            },
        })),
        GET_INSTRUMENT_INFO: callCreator((id) => varReplace(calls.GET_INSTRUMENT_INFO.url, { id })),
        GET_CURRENCY_PAIR: callCreator((id) => varReplace(calls.GET_CURRENCY_PAIR.url, { id })),
        GET_CURRENCY_PAIRS: callCreator(() => calls.GET_CURRENCY_PAIRS.url),
        GET_SETTLEMENT_CURRENCIES: callCreator(() => calls.GET_SETTLEMENT_CURRENCIES.url),
        GET_CDX_ROOTS: callCreator(() => calls.GET_CDX_ROOTS.url),
        GET_QUICK_TEMPLATES: callCreator(() => calls.GET_QUICK_TEMPLATES.url),
        GET_CDX_INSTRUMENT: callCreator((cdxRoot, otrOnly) => queryString.stringifyUrl({
            url: calls.GET_CDX_INSTRUMENT.url,
            query: { cdxRoot, otrOnly },
        })),
        GET_FRONT_MONTH_FUTURE: callCreator((instrumentId) => queryString.stringifyUrl({
            url: calls.GET_FRONT_MONTH_FUTURE.url,
            query: { instrumentId },
        })),
        OPTIONS: {
            GET_OPTIONS: callCreator((id, expirationDate = "") => varReplace(calls.OPTIONS.GET_OPTIONS.url, { id, expirationDate })),
            GET_EXPIRATION_DATES: callCreator((id, historical = false) => queryString.stringifyUrl({
                url: varReplace(calls.OPTIONS.GET_EXPIRATION_DATES.url, { id }),
                query: { historical },
            })),
        },
    };
};
