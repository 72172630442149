import { FormPanel } from "@enfusion-ui/web-components";
import * as React from "react";

import { AnalyticsPerformancePanelTopRow } from "./AnalyticsPerformancePanelTopRow";
import { PerformanceDetails } from "./PerformanceDetails";

export const AnalyticsPerformancePanel: React.FC = () => {
  return (
    <FormPanel
      lineStyle
      collapsible
      keepRendered
      numColumns={1}
      title="Performance"
      showTextCollapseButton
      tabFocusOutline={false}
      componentAlwaysShown={<AnalyticsPerformancePanelTopRow />}
      gapSize="m"
      render={() => <PerformanceDetails />}
      titleStyle={{
        padding: "0 var(--spacing-l) 0 var(--spacing-l)",
      }}
      thinContainer
    />
  );
};
