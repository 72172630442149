import {
  CenterContent,
  EnfusionLogo,
  SVGBackground,
} from "@enfusion-ui/web-components";
import { styled, ThemeProvider } from "@enfusion-ui/web-core";
import * as React from "react";

const Card = styled.div`
  background: var(--background-color-0);
  padding: 40px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: grid;
  grid-gap: 24px;
  width: 420px;
  max-width: 100%;
  box-sizing: border-box;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

const LoginSuccessPage: React.FC<unknown> = () => {
  return (
    <ThemeProvider defaultTheme="light">
      <SVGBackground>
        <CenterContent>
          <Card>
            <CenterContent>
              <EnfusionLogo style={{ width: 300 }} />
            </CenterContent>
            <CardContent>
              <h2>Login Successful</h2>
              You may close this page and continue in the application
            </CardContent>
          </Card>
        </CenterContent>
      </SVGBackground>
    </ThemeProvider>
  );
};

export default LoginSuccessPage;
