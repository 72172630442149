import { useRefCallback } from "@enfusion-ui/hooks";
import {
  SelectOptionsType,
  TRADE_TYPE_OPTIONS,
  TradeType,
} from "@enfusion-ui/types";
import { getSelectedValues, getSelectOption } from "@enfusion-ui/utils";
import {
  FormController,
  FormControllerProps,
  MultiSelect,
  MultiSelectProps,
  Select,
} from "@enfusion-ui/web-components";
import * as React from "react";

export type TradeTypesSelectProps = Omit<
  Partial<MultiSelectProps<TradeType>>,
  "options" | "value" | "onChange"
> & {
  value?: TradeType[] | null;
  onChange?: (values: TradeType[] | null) => void;
};

const TradeTypeSelect: React.FC<
  TradeTypesSelectProps & { label?: string; placeholder: string }
> = ({ value: values, onChange, placeholder, label, ...rest }) => {
  const [selectedTradeTypes, setSelectedTradeTypes] = React.useState<
    TradeType[] | null
  >([]);

  React.useEffect(() => {
    if (values) setSelectedTradeTypes(values);
  }, [values]);

  const handleChange = useRefCallback(
    (values: SelectOptionsType<TradeType>[]) => {
      const newTradeTypes = values?.length
        ? values.map((opt: SelectOptionsType<TradeType>) => opt?.value)
        : null;
      setSelectedTradeTypes(newTradeTypes);
      onChange?.(newTradeTypes);
    },
    [onChange]
  );

  return (
    <MultiSelect
      {...rest}
      value={
        selectedTradeTypes
          ? getSelectedValues(selectedTradeTypes, TRADE_TYPE_OPTIONS)
          : []
      }
      onChange={(selectedOptions) =>
        handleChange(selectedOptions as SelectOptionsType<TradeType>[])
      }
      label={label}
      placeholder={placeholder}
      options={TRADE_TYPE_OPTIONS}
    />
  );
};

export const ControlledTradeTypeSelect: React.FC<
  Omit<TradeTypesSelectProps, "value" | "onChange"> &
    Pick<
      FormControllerProps,
      "basis" | "mobileBasis" | "tabletBasis" | "desktopBasis" | "control"
    > & {
      name?: string;
      label?: string;
      placeholder?: string;
      multi?: boolean;
    }
> = ({
  basis,
  mobileBasis,
  tabletBasis,
  desktopBasis,
  control,
  name = "tradeTypes",
  label = "Trade Types",
  placeholder = "",
  multi = true,
  ...props
}) => {
  return (
    <FormController
      name={name}
      basis={basis}
      mobileBasis={mobileBasis}
      tabletBasis={tabletBasis}
      desktopBasis={desktopBasis}
      control={control}
      render={({ ref: _ref, value, onChange, ...renderProps }) =>
        multi ? (
          <TradeTypeSelect
            {...props}
            {...renderProps}
            label={label}
            placeholder={placeholder}
          />
        ) : (
          <Select
            {...props}
            {...renderProps}
            label={label}
            placeholder={placeholder}
            options={TRADE_TYPE_OPTIONS}
            onChange={(v) => onChange(v?.value ?? null)}
            value={getSelectOption(TRADE_TYPE_OPTIONS, value)}
          />
        )
      }
    />
  );
};

export default TradeTypeSelect;
