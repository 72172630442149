import {
  GeneralLedgerEntrySource,
  generalLedgerEntrySourcesOptions,
  MultiSelectOptionsType,
} from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import { ControlledInputBase, MultiSelect } from "@enfusion-ui/web-components";
import { useFormContext } from "react-hook-form";

const EntrySourcesSelect = () => {
  const { setValue, watch } = useFormContext();
  const entrySources = watch("entrySources");

  return (
    <ControlledInputBase
      name="entrySources"
      render={({ onChange, ...rest }) => (
        <MultiSelect
          {...rest}
          label="Source"
          placeholder="Any"
          options={generalLedgerEntrySourcesOptions}
          value={
            entrySources
              ? getSelectedValues(
                  entrySources,
                  generalLedgerEntrySourcesOptions
                )
              : []
          }
          onChange={(selectedOption) => {
            const entrySourcesTypes =
              selectedOption?.map(
                (option: MultiSelectOptionsType) =>
                  option.value as GeneralLedgerEntrySource
              ) || [];
            setValue("entrySources", entrySourcesTypes);
            onChange(entrySourcesTypes);
          }}
          inputId="report-base-entry-source-selection-id"
        />
      )}
    />
  );
};

export default EntrySourcesSelect;
