/* eslint-disable @typescript-eslint/no-explicit-any */
import { RoundedDataGrid } from "@app-components/DataGrid";
import { TOAST_CONTENT, useOEMSOrderForm } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  ComplianceState,
  ExecutionComplianceRecordInfo,
} from "@enfusion-ui/types";
import {
  Button,
  ConfirmationModal,
  ContentContainer,
  TextInput,
} from "@enfusion-ui/web-components";
import {
  errorToast,
  styled,
  successToast,
  useTheme,
  useThisTab,
} from "@enfusion-ui/web-core";
import * as React from "react";

import { cancelOrder, performTransmit } from "../../utils/actions";

const ComplianceTableContainer = styled.div`
  height: 200px;
  width: 60rem;
`;

const TextInputContainer = styled.div`
  width: 100%;
  margin-top: var(--spacing-xl);
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.label`
  display: block;
  font-size: 0.75em;
  line-height: 1em;
  font-weight: 500;
  margin: var(--spacing-l) 0 var(--spacing) 0;
`;

const Label: React.FC = () => (
  <FlexColumn>
    <div style={{ margin: "var(--spacing-l) 0 var(--spacing) 0" }}>
      Compliance Notes Required
    </div>
    <InputLabel>
      One of the compliance severity above require a compliance note to be added
      before you can continue transmitting this order to the market. Please
      populate a note here to continue.
    </InputLabel>
  </FlexColumn>
);

const ComplianceTable: React.FC<{
  complianceRecords: ExecutionComplianceRecordInfo[] | null;
}> = ({ complianceRecords }) => {
  const { theme } = useTheme();
  return (
    <ComplianceTableContainer className={theme.agGridClass}>
      <RoundedDataGrid
        height="100%"
        columnDefs={[
          { field: "complianceState", headerName: "Compliance State" },
          { field: "text", headerName: "Description" },
        ]}
        rowData={complianceRecords}
        onGridReady={({ columnApi }) => columnApi.autoSizeAllColumns()}
      />
    </ComplianceTableContainer>
  );
};

export const ComplianceConfirmation: React.FC<{
  handleOrderSubmissionWithCompliance?: () => void;
  handleOrderCancellation?: () => void;
  canTransmit?: boolean;
}> = ({
  handleOrderSubmissionWithCompliance,
  handleOrderCancellation,
  canTransmit,
}) => {
  const [complianceNotes, setComplianceNotes] = React.useState<string>("");
  const {
    complianceRecords,
    complianceFailure: failedOrderId,
    setComplianceFailure,
    getCurrentOrder,
    submitOrder,
    submitErrors,
    setSubmitErrors,
  } = useOEMSOrderForm();

  const hasComplianceIssues =
    !!failedOrderId &&
    complianceRecords !== null &&
    complianceRecords.length > 0 &&
    complianceRecords.some((x: any) => x.complianceState !== "Passed");

  const { closeTab } = useThisTab();

  const complianceErrorMessageSuffix =
    (complianceRecords?.length ?? 0) > 1 ? "s" : "";

  const checkComplianceState = useRefCallback(
    (complianceState: ComplianceState[]) =>
      complianceRecords
        ? complianceRecords.find((record) =>
            complianceState.includes(record.complianceState)
          ) !== undefined
        : false,
    [complianceRecords]
  );

  const showNotesSection = checkComplianceState(["WarningWithNote"]);

  const isComplianceFailed = checkComplianceState([
    "Failed",
    "OverrideNotAllowed",
  ]);

  const doCancel = useRefCallback(async () => {
    if (failedOrderId) {
      try {
        handleOrderCancellation?.();
        await cancelOrder(failedOrderId);
        successToast(`Order(${failedOrderId}) canceled successfully.`);
        closeTab();
      } catch (err: any) {
        console.error(
          `Error while canceling the order(${failedOrderId}).`,
          err
        );
        errorToast(`Order(${failedOrderId}) failed to cancel.`, err?.message);
      } finally {
        setComplianceFailure?.(undefined);
        setSubmitErrors?.(null);
      }
    } else {
      errorToast("Not a valid order id: " + failedOrderId);
      setComplianceFailure?.(undefined);
      setSubmitErrors?.(null);
    }
  }, [
    failedOrderId,
    handleOrderCancellation,
    cancelOrder,
    setComplianceFailure,
  ]);

  const doSubmit = async () => {
    if (showNotesSection) {
      const currentOrder = getCurrentOrder();
      try {
        await submitOrder(
          {
            ...currentOrder,
            complianceNotes,
            id: failedOrderId ?? undefined,
          },
          false,
          true
        );
      } catch (err: any) {
        errorToast("Failed to stage order:", err?.message);
      }
    } else {
      successToast("Order staged successfully: " + failedOrderId);
    }
    handleOrderSubmissionWithCompliance?.();
    setComplianceFailure?.(undefined);
    setSubmitErrors?.(null);
  };

  const doTransmit = async () => {
    try {
      if (failedOrderId) {
        await performTransmit(failedOrderId, true);
      } else {
        const currentOrder = getCurrentOrder();
        await submitOrder(
          {
            ...currentOrder,
            id: undefined,
          },
          true,
          true
        );
      }
      successToast(TOAST_CONTENT.oems.transmit.success);

      handleOrderSubmissionWithCompliance?.();
      setComplianceFailure?.(undefined);
      setSubmitErrors?.(null);
    } catch (err) {
      errorToast(TOAST_CONTENT.oems.transmit.failure, (err as Error).message);
    }
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComplianceNotes(e.target.value);
  };

  return (
    <>
      <ConfirmationModal
        title="Compliance Failed"
        open={hasComplianceIssues}
        allowDismissal={false}
        onSubmit={doSubmit}
        onCancel={doCancel}
        actions={
          <>
            {!showNotesSection && !isComplianceFailed && canTransmit && (
              <Button theme="warning" onClick={doTransmit}>
                Transmit Anyway
              </Button>
            )}
            <Button theme="warning" onClick={doSubmit}>
              Stage Order
            </Button>
            <Button onClick={doCancel}>Cancel Order</Button>
          </>
        }
      >
        Order(s) caused {complianceRecords?.length} compliance failure
        {complianceErrorMessageSuffix}.
        <ContentContainer>
          <ComplianceTable complianceRecords={complianceRecords} />
          {showNotesSection ? (
            <TextInputContainer>
              <TextInput
                label={<Label />}
                value={complianceNotes}
                onChange={onTextChange}
                textarea
                rows={5}
                maxLength={250}
              />
            </TextInputContainer>
          ) : (
            <></>
          )}
        </ContentContainer>
      </ConfirmationModal>
      <ConfirmationModal
        title="Transmit Failed"
        cancelButtonText="Cancel Order"
        submitButtonText="Stage Order"
        submitActionTheme="warning"
        open={!!failedOrderId && !hasComplianceIssues && !!submitErrors?.length}
        allowDismissal={false}
        onSubmit={doSubmit}
        onCancel={doCancel}
      >
        {submitErrors}
      </ConfirmationModal>
    </>
  );
};
