/* eslint-disable @typescript-eslint/no-explicit-any */
import FileErrorBoundary from "@app-components/ErrorBoundary/FileErrorBoundary";
import DocumentSaveForm from "@app-components/modal/DocumentSaveForm";
import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import { ReportsFolderProvider } from "@app-context/reports/ReportsFolderProvider";
import { EMPTY_RECORD } from "@enfusion-ui/core";
import {
  DASHBOARD_CONTEXT_CHANNEL_NAME,
  DASHBOARD_CONTEXT_REFRESH,
  DashboardContextChannelEvent,
} from "@enfusion-ui/dashboards";
import { useRefCallback } from "@enfusion-ui/hooks";
import { ReportExtensions } from "@enfusion-ui/reports";
import {
  AppEvent,
  AppEventCategories,
  DashboardRoot,
  WebPositionReportQuery,
  WebReportQuery,
} from "@enfusion-ui/types";
import { replaceDoubleSlashWithSingleSlash } from "@enfusion-ui/utils";
import {
  AlignRightRow,
  Button,
  CenterContent,
  ContentMessage,
  CopyToClipboard,
  EmptyView,
  IconButton,
  Modal,
  Portal,
  Spinner,
  TabTopActionBar,
  ViewContainer,
  ViewContent,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  ReportBreadcrumbSibling,
  ReportDataChangeArgs,
  styled,
  useAuth,
  useIsContentMobile,
  useReports,
  useThisTab,
} from "@enfusion-ui/web-core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCog,
  faEllipsisV,
  faFileDownload,
  faFilter,
  faRedoAlt,
  faSave,
  faSearch,
  faShare,
  faSkullCrossbones,
} from "@fortawesome/pro-solid-svg-icons";
import {
  GridApi,
  GridOptions,
  GridReadyEvent,
  MenuItemDef,
  RowGroupOpenedEvent,
} from "ag-grid-community";
import { parseISO } from "date-fns";
import { isEqual, last } from "lodash";
import Menu, { MenuItem } from "rc-menu";
import * as React from "react";
import { useEffectOnce, useMeasure } from "react-use";

import ReportLoadingStatus from "../../components/display/ReportLoadingStatus";
import { useSettings } from "../../context/settings/context";
import { reportShareLinkProps } from "../../utils/shareLinks";
import {
  MenuItemsIcon,
  MenuItemsTitle,
} from "../dashboards/widget/WidgetContainer";
import Breadcrumbs from "./components/Breadcrumbs";
import ReportQueryForm from "./components/ReportQueryForm";
import { SearchableReportView } from "./components/SearchableReportView";
import { getState, loadColumnState } from "./hooks/usePersistColumnState";
import { ReportBaseFilterPanel } from "./reportSettings/ReportBaseFilterPanel";
import ReportSettingsModal from "./reportSettings/ReportSettingsModal";

type Props = {
  config: {
    name: string;
    path: string | null;
    reportId: string;
    params?: Record<string, any>;
    pathParams?: Record<string, any>;
    tableId?: string;
    reportQuery?: WebReportQuery;
    root: DashboardRoot;
  };
  useParentHeight?: boolean;
  asBasicTable?: boolean;
  overrideGridConfig?: GridOptions;
  asWidget?: boolean;
  filePath?: string;
  showTotalsRow?: boolean;
  showGroupingRow?: boolean;
  onGridReady?: (event: GridReadyEvent) => void;
};

const BreadcrumbsContainer = styled.div`
  flex: 1;
`;

const FormContainer = styled.div`
  padding: var(--spacing);
`;

const EmptyViewContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const getGridApi = (path: string[], parentApi?: GridApi): GridApi | null => {
  if (parentApi) {
    if (path.length === 0) return parentApi;
    const entry = path.shift()!;
    return getGridApi(
      path,
      parentApi.getDetailGridInfo(`detail_${entry}`)?.api
    );
  }
  return null;
};

const ActionContainer = styled.div<{ background?: boolean }>`
  top: 0px;
  right: 0px;
  z-index: var(--widget-z);
  border: 1px solid transparent;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${({ background: b }) => (b ? "var(--primary)" : "transparent")};
  border-radius: 5px;
`;

const ReportView: React.FC<Props> = ({
  config,
  asWidget = false,
  asBasicTable = false,
  overrideGridConfig,
  showTotalsRow = true,
  showGroupingRow = false,
  filePath = "-null",
  useParentHeight = false,
  onGridReady,
}) => {
  const {
    reportId,
    name: nameBase,
    path: pathBase,
    params = EMPTY_RECORD,
    pathParams = EMPTY_RECORD,
    tableId: tableIdBase,
    reportQuery: reportSettingQuery,
    root,
  } = config;
  const {
    dataStore,
    metaStore,
    loadReport,
    saveReport,
    bulkSubscribeToReportChange,
    openDetailTable,
  } = useReports();
  const { enableReportRealtimeUpdate } = useSettings();
  const { user } = useAuth();

  const [configOpen, setConfigOpen] = React.useState<boolean>(false);
  const [reportParams, setReportParams] = React.useState<any>(params);
  const [showSearchBar, setShowSearchBar] = React.useState<boolean>(
    !asWidget && !asBasicTable
  );
  const [saveModalOpen, setSaveModalOpen] = React.useState<boolean>(false);
  const [reportSettingModalOpen, setReportSettingModalOpen] =
    React.useState(false);
  const [optionsPortalOpen, setOptionsPortalOpen] =
    React.useState<boolean>(false);
  const [enableSave, setEnableSave] = React.useState<boolean>(false);
  const [subscriptions, setSubscriptions] = React.useState<
    Record<string, string[]>
  >({ [reportId]: [reportId] });

  const firstLoadDone = React.useRef(asWidget);
  const menuIconRef = React.useRef<HTMLDivElement | null>(null);
  const gridApiRef = React.useRef<GridReadyEvent | null>(null);

  const [ref, { width, height }] = useMeasure<HTMLDivElement>();
  const [emptyViewRef, { width: emptyViewWidth, height: emptyViewHeight }] =
    useMeasure<HTMLDivElement>();
  const isBreadcrumbNavigation = false;
  const isMobile = useIsContentMobile();
  const { renameTab } = useThisTab();

  const tableId = tableIdBase || reportId;

  const storageKey = `${reportId}-${user?.username}`;

  const metadata = metaStore[reportId] ? metaStore[reportId] : undefined;
  const breadcrumbs = metadata?.breadcrumbs;
  const reportQuery = metadata?.reportQuery;

  const path = metadata?.path ?? "";
  const name = metadata?.name ?? "";
  const lastSavedQuery = React.useRef<WebReportQuery | null>();
  // Mobile - Change the selected table id - this will refresh the entire grid
  // Non-mobile - Selected table Id remains same, metaStore and dataStore should get updated
  // in case user changes the saved query
  const selectedTableId = React.useMemo(() => {
    if (isBreadcrumbNavigation) {
      return breadcrumbs && breadcrumbs.length
        ? breadcrumbs[breadcrumbs.length - 1].tableId
        : tableId;
    }
    return tableId;
  }, [tableId]);

  const gridOptions = dataStore[selectedTableId];

  useBroadcastChannel<DashboardContextChannelEvent>(
    DASHBOARD_CONTEXT_CHANNEL_NAME,
    // `${DASHBOARD_CONTEXT_CHANNEL_BASE}${filePath.replace(/\//g, "-")}`,
    (ev) => {
      if (ev.path === filePath.replace(/\//g, "-")) {
        if (ev.action === DASHBOARD_CONTEXT_REFRESH) {
          handleRefresh();
        }
      }
    }
  );

  React.useEffect(() => {
    if (
      !asWidget &&
      firstLoadDone.current &&
      reportId &&
      nameBase &&
      (pathBase || reportSettingQuery)
    ) {
      loadReport({
        reportId,
        path: pathBase ?? "",
        name: nameBase,
        params: reportParams,
        pathParams,
        closeFirst: true,
        reportQuery: reportSettingQuery,
      });
    }
  }, [
    asWidget,
    reportId,
    nameBase,
    pathBase,
    JSON.stringify(reportParams),
    JSON.stringify(pathParams),
    JSON.stringify(reportSettingQuery),
  ]);

  React.useEffect(() => {
    if (
      !path ||
      (path &&
        reportQuery &&
        lastSavedQuery.current &&
        !isEqual(reportQuery, lastSavedQuery.current))
    ) {
      setEnableSave(true);
    } else {
      setEnableSave(false);
    }
  }, [path, JSON.stringify(reportQuery)]);

  React.useEffect(() => {
    if (asWidget && reportId) setSubscriptions({ [reportId]: [reportId] });
  }, [reportId]);

  useEffectOnce(() => {
    AppLogging.event(
      {
        event: AppEvent.ReportOpened,
        category: AppEventCategories.Reports,
        gaLabel: nameBase,
      },
      {
        pathBase,
      }
    );

    if (
      !asWidget &&
      !firstLoadDone.current &&
      reportId &&
      nameBase &&
      (pathBase || reportSettingQuery)
    ) {
      loadReport({
        reportId,
        path: pathBase ?? "",
        name: nameBase,
        params: reportParams,
        pathParams,
        reportQuery: reportSettingQuery,
      });
      firstLoadDone.current = true;
    }
  });

  const handleReportChange = useRefCallback(
    (args: ReportDataChangeArgs) => {
      if (!gridApiRef.current?.api) {
        setTimeout(() => {
          handleReportChange(args);
        }, 300);
      } else {
        if (enableReportRealtimeUpdate || (args as any).type === "init") {
          requestAnimationFrame(async () => {
            const apiPath = subscriptions[args.tableId].slice(1);
            const api = getGridApi([...apiPath], gridApiRef.current?.api);
            if (api) {
              if (
                !overrideGridConfig?.columnDefs &&
                (args as any).type === "init"
              ) {
                const { columnDefs } = args as any;

                const username = user?.username ?? "";

                const storedColSettings = await getState({
                  reportId,
                  username,
                });
                try {
                  if (
                    storedColSettings &&
                    (!lastSavedQuery.current ||
                      isEqual(lastSavedQuery.current, reportQuery))
                  ) {
                    const updatedColDefs = await loadColumnState(
                      {
                        reportId,
                        username,
                      },
                      columnDefs,
                      `depth-${apiPath.length}`,
                      apiPath.length > 0
                    );

                    if (updatedColDefs && updatedColDefs.length > 0) {
                      api.updateGridOptions({ columnDefs: updatedColDefs });
                    }
                  } else {
                    api.updateGridOptions({ columnDefs });
                  }
                } catch (e) {
                  api.updateGridOptions({ columnDefs });
                }
                // api.setColumnDefs((args as any).columnDefs);
              }
              requestAnimationFrame(() => {
                new Promise(() => {
                  api.applyTransactionAsync(args.change, () => {
                    if (args.totalsRow && showTotalsRow) {
                      api.updateGridOptions({
                        pinnedTopRowData: [args.totalsRow],
                      });
                    }
                  });
                });
              });
            }
          });
        }
      }
    },
    [
      gridApiRef,
      showTotalsRow,
      enableReportRealtimeUpdate,
      subscriptions,
      storageKey,
      overrideGridConfig,
      reportQuery,
      lastSavedQuery.current,
    ]
  );

  const handleGridReady = useRefCallback(
    (event: GridReadyEvent) => {
      gridApiRef.current = event;
      setSubscriptions((state) => ({ ...state }));
      onGridReady?.(event);
    },
    [gridOptions, showTotalsRow, onGridReady]
  );

  React.useEffect(() => {
    return bulkSubscribeToReportChange(
      Object.keys(subscriptions),
      handleReportChange
    );
  }, [subscriptions]);

  const handleSearchBarToggle = () => {
    setShowSearchBar(!showSearchBar);
  };

  const handleDownloadFile = React.useCallback(() => {
    gridApiRef.current?.api.exportDataAsCsv({
      fileName: name?.split(".")[0],
      processCellCallback: (params) => params.value,
    });
  }, [name, gridApiRef]);

  const toggleShowConfig = () => {
    setConfigOpen((val) => !val);
  };

  const handleRefresh = useRefCallback(() => {
    if (name && path) {
      loadReport({
        reportId,
        name,
        path,
        params: reportParams,
        pathParams,
        closeFirst: true,
      });
    }
  }, [name, path, pathParams, reportId, reportParams]);

  const onRowGroupOpened = React.useCallback(
    (event: RowGroupOpenedEvent) => {
      if (event.expanded) {
        const id = event.data.__row_id;
        const options = dataStore[id];
        const siblings: Array<ReportBreadcrumbSibling> = [];
        event.api.forEachNode((rowNode) => {
          siblings.push({
            value: `${rowNode.data.__row_idx}`,
            label: rowNode.data[options?.metadata.descriptionColumn]?.value,
          });
        });
        openDetailTable({
          destination: metadata?.destination || "",
          reportId,
          parentTableId: last(event.context.path)!,
          row: `${event.data.__row_idx}`,
          label: event.data[options?.metadata.descriptionColumn]?.value,
          siblings,
          tableId: `${id}`,
        });
        setSubscriptions((state) => ({
          ...state,
          [id]: [...event.context.path, id],
        }));

        const el = document.querySelector(`[row-id="detail_${id}"]`);
        el?.addEventListener("wheel", (e) => e.stopPropagation());
      }
    },
    [dataStore, reportId]
  );

  const handleReset = useRefCallback(() => {
    setReportParams(params);
    loadReport({
      reportId,
      name,
      path,
      params,
      pathParams,
      closeFirst: true,
      skipEqualCheck: true,
    });
  }, [reportId, name, path, params, pathParams]);

  const getContextMenu = useRefCallback(
    (menuItems: (string | MenuItemDef)[]) => {
      const existingDateSelectionType = (
        reportQuery as unknown as WebPositionReportQuery
      )?.datePeriodSelection?.dateSelection?.dateSelectionType;
      const queriedDateSelectionType = (reportParams as WebPositionReportQuery)
        ?.datePeriodSelection?.dateSelection?.dateSelectionType;

      const dateSelection =
        queriedDateSelectionType || existingDateSelectionType;

      if (dateSelection === "Latest" || asWidget) return menuItems;

      return menuItems.filter(
        (e: string | MenuItemDef) =>
          typeof e === "string" || e.name !== "Unwind Position"
      );
    },
    [asWidget, reportQuery, reportParams]
  );

  const handleSaveReport = useRefCallback(
    async (reportName: string, filePath: string, root: DashboardRoot) => {
      if (reportQuery) {
        const name = `${reportName}.${
          ReportExtensions[(reportQuery as WebReportQuery).type] ?? ""
        }`;

        const path = replaceDoubleSlashWithSingleSlash(
          `${root}/${filePath}${name}`
        );

        await saveReport(name, path, root, reportQuery, reportId, false);
        setEnableSave(false);
        lastSavedQuery.current = null;
        setSaveModalOpen(false);
        renameTab(reportName, { path, name });
      }
    },
    [reportId, saveReport, reportQuery]
  );

  const updateReport = useRefCallback(async () => {
    if (reportQuery && root) {
      await saveReport(name, path, root, reportQuery, reportId, true);
      setEnableSave(false);
      lastSavedQuery.current = null;
    }
  }, [saveReport, reportQuery, path, root, reportId, name]);

  const handleRunReport = useRefCallback(
    async (newQuery: WebReportQuery) => {
      lastSavedQuery.current = reportQuery;
      if (newQuery) {
        loadReport({
          reportId,
          path,
          name,
          pathParams,
          closeFirst: true,
          reportQuery: newQuery,
        });
      }
    },
    [reportId, path, name, pathParams, reportQuery]
  );

  return (
    <FileErrorBoundary
      section="Reports"
      filePath={path || pathBase!}
      fileMissingError={metadata?.error?.startsWith("Could not locate file")}
      errorContent={
        metadata?.error && (
          <EmptyViewContainer ref={emptyViewRef}>
            <EmptyView
              thinTopBar={asWidget || asBasicTable}
              borderedContent={!asWidget && !asBasicTable}
            >
              <ContentMessage
                icon={faSkullCrossbones}
                message="We couldn't load this report right now."
                details={metadata?.error}
                parentHeight={emptyViewHeight}
                parentWidth={emptyViewWidth}
              >
                <Button
                  primary
                  onClick={handleReset}
                  style={{ marginTop: "var(--spacing-l)" }}
                >
                  Reset
                </Button>
              </ContentMessage>
            </EmptyView>
          </EmptyViewContainer>
        )
      }
    >
      <ViewContainer className="not-moveable">
        <TabTopActionBar thin={asWidget || asBasicTable}>
          {!asWidget && !asBasicTable && (
            <>
              <BreadcrumbsContainer>
                {isBreadcrumbNavigation ? (
                  <Breadcrumbs
                    disabled={!!metadata?.error || !gridOptions}
                    reportId={reportId}
                    breadcrumbs={breadcrumbs || []}
                    destination={metadata?.destination || ""}
                  />
                ) : null}
              </BreadcrumbsContainer>
              <AlignRightRow>
                {process.env.REACT_APP_ENABLE_PMS_PHASE_ONE ? (
                  <>
                    <IconButton
                      hoverable
                      icon={faSave}
                      title="Save"
                      onClick={() => {
                        if (path) {
                          updateReport();
                        } else {
                          setSaveModalOpen(true);
                        }
                      }}
                      size="sm"
                      disabled={!enableSave}
                      data-e2e-id="report-view-action-save"
                    />

                    <IconButton
                      hoverable
                      icon={faCog}
                      title="More Settings..."
                      onClick={() => setReportSettingModalOpen(true)}
                      size="sm"
                      data-e2e-id="report-view-action-config"
                    />
                  </>
                ) : null}

                {asWidget && (
                  <IconButton
                    hoverable
                    icon={faSearch}
                    title="Search"
                    onClick={handleSearchBarToggle}
                    size="sm"
                    data-e2e-id="report-view-action-search"
                  />
                )}
                {!asWidget && (
                  <>
                    <IconButton
                      hoverable
                      icon={faRedoAlt}
                      title="Refresh Report"
                      onClick={handleRefresh}
                      disabled={metadata?.loading || !path}
                      size="sm"
                      data-e2e-id="report-view-action-refresh"
                    />
                  </>
                )}
                <CopyToClipboard
                  component={
                    <IconButton
                      title="Share Report"
                      icon={faShare}
                      hoverable
                      size="sm"
                      data-e2e-id="report-view-action-share"
                    />
                  }
                  {...reportShareLinkProps(name, path)}
                />
                <IconButton
                  hoverable
                  icon={faFileDownload}
                  title="Download File"
                  onClick={handleDownloadFile}
                  size="sm"
                  data-e2e-id="report-view-action-download"
                />
                {!asWidget ? (
                  <IconButton
                    hoverable
                    icon={faFilter}
                    title="Quick Filters"
                    onClick={toggleShowConfig}
                    size="sm"
                    data-e2e-id="report-view-action-quick-filter"
                  />
                ) : null}
                {process.env.REACT_APP_ENABLE_PMS_PHASE_ONE ? (
                  <ActionContainer ref={menuIconRef}>
                    <IconButton
                      hoverable
                      icon={faEllipsisV}
                      title="Options"
                      onClick={() => setOptionsPortalOpen(true)}
                      size="sm"
                      data-e2e-id="report-view-action-options"
                    />
                  </ActionContainer>
                ) : null}

                <Portal
                  open={optionsPortalOpen}
                  attachedRef={menuIconRef}
                  align="right"
                  onClickOutside={() => setOptionsPortalOpen(false)}
                >
                  <Menu>
                    <MenuItem
                      onClick={() => setSaveModalOpen(true)}
                      data-e2e-id="report-view-action-options-save-as"
                    >
                      <MenuItemsIcon icon={faSave as IconProp}></MenuItemsIcon>
                      <MenuItemsTitle>Save As</MenuItemsTitle>
                    </MenuItem>
                  </Menu>
                </Portal>
              </AlignRightRow>
            </>
          )}
        </TabTopActionBar>
        {configOpen && metadata ? (
          <FormContainer data-e2e-id="report-filter-form">
            {process.env.REACT_APP_ENABLE_PMS_PHASE_ONE ? (
              <ReportBaseFilterPanel
                reportQuery={reportQuery as WebReportQuery}
                onSubmit={(query: WebReportQuery) => setReportParams(query)}
              />
            ) : (
              <ReportQueryForm
                reportQuery={reportQuery as WebReportQuery}
                onSubmit={(query: WebReportQuery) => setReportParams(query)}
                initValues={reportQuery as WebReportQuery}
              />
            )}
          </FormContainer>
        ) : null}
        {gridOptions && breadcrumbs && !metadata?.loading ? (
          <SearchableReportView
            name={name}
            path={path}
            height={useParentHeight ? `${height}px` : undefined}
            reportId={reportId}
            onGridReady={handleGridReady}
            onRowGroupOpened={onRowGroupOpened}
            masterDetail={!isBreadcrumbNavigation}
            columnDefs={gridOptions?.columnDefs || []}
            columnTypes={{
              numberColumn: {
                filter: "agMultiColumnFilter",
                enableRowGroup: false,
                filterParams: {
                  filters: [
                    {
                      filter: "agNumberColumnFilter",
                    },
                    {
                      filter: "agSetColumnFilter",
                    },
                  ],
                },
              },
              dateColumn: {
                enableRowGroup: true,
                filter: "agMultiColumnFilter",
                filterParams: {
                  filters: [
                    {
                      filter: "agDateColumnFilter",
                      filterParams: {
                        comparator: (
                          filterDate: Date,
                          cellValue: string | null
                        ) => {
                          if (cellValue === null) return -1;
                          return (
                            parseISO(cellValue).getTime() - filterDate.getTime()
                          );
                        },
                      },
                    },
                    {
                      filter: "agSetColumnFilter",
                      filterParams: {
                        comparator: (valueA: string, valueB: string) => {
                          return (
                            parseISO(valueA).getTime() -
                            parseISO(valueB).getTime()
                          );
                        },
                      },
                    },
                  ],
                },
                comparator: (valueA, valueB) =>
                  parseISO(valueA).getTime() - parseISO(valueB).getTime(),
              },
              standardColumn: {
                enableRowGroup: true,
                filter: "agMultiColumnFilter",
                filterParams: {
                  filters: [
                    {
                      filter: "agTextColumnFilter",
                      filterParams: {
                        defaultOption: "startsWith",
                      },
                    },
                    {
                      filter: "agSetColumnFilter",
                    },
                  ],
                },
              },
              standardRightColumn: {
                enableRowGroup: true,
                headerClass: "ag-right-aligned-header",
                cellClass: "ag-right-aligned-cell",
                filter: "agMultiColumnFilter",
                filterParams: {
                  filters: [
                    {
                      filter: "agTextColumnFilter",
                      filterParams: {
                        defaultOption: "startsWith",
                      },
                    },
                    {
                      filter: "agSetColumnFilter",
                    },
                  ],
                },
              },
            }}
            maintainColumnOrder
            isMobile={isMobile}
            isWidget={asWidget}
            isBasicTable={asBasicTable}
            rounded={asWidget || asBasicTable}
            noBackground={asWidget || asBasicTable}
            showSearchBar={showSearchBar}
            showRowCount={!asBasicTable}
            showGroupingRow={showGroupingRow}
            suppressDragLeaveHidesColumns={showGroupingRow}
            rowGroupPanelShow={showGroupingRow ? "always" : undefined}
            breakWidth={520}
            getContextMenu={getContextMenu}
            {...overrideGridConfig}
          />
        ) : (
          <ViewContent ref={ref} bordered={!asWidget && !asBasicTable}>
            <CenterContent>
              {metadata?.loading && metadata?.progressSteps ? (
                <ReportLoadingStatus
                  steps={metadata.progressSteps}
                  parentHeight={height}
                  parentWidth={width}
                />
              ) : (
                <Spinner dataE2EId="report-view-spinner" />
              )}
            </CenterContent>
          </ViewContent>
        )}
        <Modal
          isOpen={saveModalOpen}
          onClose={() => setSaveModalOpen(false)}
          title="Save Report"
          content={
            <ReportsFolderProvider root={root}>
              <DocumentSaveForm
                root={root}
                onSubmit={handleSaveReport}
                onCancel={() => setSaveModalOpen(false)}
                fileName="New Report"
                keyword="report"
              />
            </ReportsFolderProvider>
          }
        />
        <ReportSettingsModal
          open={reportSettingModalOpen}
          onClose={() => setReportSettingModalOpen(false)}
          runReport={handleRunReport}
          reportQuery={reportQuery as WebReportQuery}
        />
      </ViewContainer>
    </FileErrorBoundary>
  );
};

export default ReportView;
