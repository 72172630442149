import { ReconciliationBreakDetailsProvider } from "@app-context/reconciliation/ReconciliationBreakDetailsProvider";
import { BreakDetailsPanelContainer } from "@app-views/reconciliation/components/styles";
import { BreakDetailsCellRendererProps } from "@app-views/reconciliation/types";
import React from "react";

import { BreakDetailsGrid } from "./BreakDetailsGrid";
import { BreakDetailsSummaryPanel } from "./BreakDetailsSummaryPanel";
import { BreakDetailsMetricsPanel } from "./metrics/BreakDetailsMetricsPanel";

export const BreakDetailsCellRenderer: React.FC<{
  data: BreakDetailsCellRendererProps;
}> = ({ data }) => {
  return (
    <ReconciliationBreakDetailsProvider breakDetailsRendererData={data}>
      <BreakDetailsPanelContainer>
        <BreakDetailsSummaryPanel />
        <BreakDetailsMetricsPanel />
        <BreakDetailsGrid />
      </BreakDetailsPanelContainer>
    </ReconciliationBreakDetailsProvider>
  );
};
