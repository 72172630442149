import { AppEvent, AppEventCategories } from "@enfusion-ui/types";
import {
  AppLogging,
  ReportBreadcrumbEntry,
  styled,
  useReports,
} from "@enfusion-ui/web-core";
import { faChevronRight, faHome } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

import SimpleSelect from "../../../components/control/SimpleSelect";

export type BreadcrumbsProps = {
  reportId: string;
  destination: string;
  breadcrumbs: Array<ReportBreadcrumbEntry>;
  disabled?: boolean;
};

const BreadcrumbsContainer = styled.div`
  font-size: 14px;
  padding: 8px;

  & * {
    margin-left: 8px;
  }
`;

function Breadcrumb({
  reportId,
  isLast,
  index,
  breadcrumb,
  destination,
}: {
  index: number;
  isLast: boolean;
  reportId: string;
  destination: string;
  breadcrumb: ReportBreadcrumbEntry;
}) {
  const { selectBreadcrumbTable, openDetailTable } = useReports();

  const handleSiblingClick = (row: unknown) => {
    if (row) {
      const sibling = breadcrumb.siblings.find((i) => i.value === row);
      if (sibling && breadcrumb.parentTableId) {
        openDetailTable({
          label: sibling.label,
          row: sibling.value,
          siblings: breadcrumb.siblings,
          parentTableId: breadcrumb.parentTableId,
          reportId,
          destination,
          replace: index,
        });

        AppLogging.event({
          event: AppEvent.ReportBreadcrumbNavigation,
          category: AppEventCategories.Reports,
        });
      }
    }
  };
  if (breadcrumb.siblings.length > 1) {
    return (
      <SimpleSelect
        defaultValue={breadcrumb.row}
        onChange={handleSiblingClick}
        options={breadcrumb.siblings}
      />
    );
  }

  const handleClick = () => {
    if (!isLast) {
      selectBreadcrumbTable(reportId, breadcrumb.tableId);
    }
  };

  return (
    <span
      style={isLast ? undefined : { cursor: "pointer" }}
      onClick={handleClick}
    >
      {breadcrumb.label}
    </span>
  );
}

export default function Breadcrumbs({
  reportId,
  breadcrumbs,
  destination,
  disabled,
}: BreadcrumbsProps) {
  const { selectBreadcrumbTable } = useReports();
  if (disabled || breadcrumbs.length < 1) return null;

  return (
    <BreadcrumbsContainer>
      {breadcrumbs.map((b, i) => {
        if (i === 0)
          return (
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              onClick={() => selectBreadcrumbTable(reportId, b.tableId)}
              key={i}
              icon={faHome}
              title="Report Root"
              size="sm"
              data-e2e-id="report-root-icon"
            />
          );

        return (
          <React.Fragment key={i}>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb
              breadcrumb={breadcrumbs[i]}
              destination={destination}
              isLast={i === breadcrumbs.length - 1}
              reportId={reportId}
              index={i}
            />
          </React.Fragment>
        );
      })}
    </BreadcrumbsContainer>
  );
}
