import { WebDateSelection } from "@enfusion-ui/types";
import {
  addBusinessDays,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subBusinessDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const getDatePreset = (webDateSelection: WebDateSelection) => {
  if (webDateSelection.asOfDate) {
    return format(new Date(webDateSelection.asOfDate), "yyyy-MM-dd");
  } else {
    return webDateSelection.dateSelectionType;
  }
};

export const getDateSelection = (
  valueDate?: string | Date | WebDateSelection | null,
  defaultValue?: WebDateSelection | null
) => {
  if (typeof valueDate !== "undefined" && valueDate !== null) {
    if (typeof valueDate === "string") {
      return {
        dateSelectionType: valueDate,
        asOfDate: null,
      } as WebDateSelection;
    } else if (valueDate instanceof Date) {
      return {
        dateSelectionType: "AsOfDate",
        asOfDate: format(valueDate as Date, "yyyy-MM-dd"),
      } as WebDateSelection;
    } else if (typeof valueDate.dateSelectionType === "string") {
      return valueDate;
    }
  }
  return defaultValue;
};

export const SYMBOL_RANGE_OPTIONS = [
  { label: "AllDates", value: "AllDates" },
  { label: "Custom", value: "Custom" },
  { label: "Today", value: "Today" },
  { label: "This Week", value: "ThisWeek" },
  { label: "This Week-to-date", value: "ThisWeekToDate" },
  { label: "This Month", value: "ThisMonth" },
  { label: "This Month-to-date", value: "ThisMonthToDate" },
  { label: "This Quarter", value: "ThisQuarter" },
  { label: "This Quarter-to-date", value: "ThisQuarterToDate" },
  { label: "This Year", value: "ThisYear" },
  { label: "This Year-to-date", value: "ThisYearToDate" },
  { label: "Last Week", value: "LastWeek" },
  { label: "Last Month", value: "LastMonth" },
  { label: "Last Quarter", value: "LastQuarter" },
  { label: "Last Year", value: "LastYear" },
  { label: "Last PBD-to-date", value: "LastPreviousBusinessDay" },
  { label: "Previous Business Day", value: "PBD" },
  { label: "This Week-to-PBD", value: "ThisWeekToPBD" },
  { label: "This Month-to-PBD", value: "ThisMonthToPBD" },
  { label: "This Quarter-to-PBD", value: "ThisQuarterToPBD" },
  { label: "This Year-to-PBD", value: "ThisYearToPBD" },
  { label: "Next Business Day", value: "NBD" },
  { label: "This Week-to-NBD", value: "ThisWeekToNBD" },
  { label: "This Month-to-NBD", value: "ThisMonthToNBD" },
  { label: "This Quarter-to-NBD", value: "ThisQuarterToNBD" },
  { label: "This Year-to-NBD", value: "ThisYearToNBD" },
  { label: "This Year-to-Previous Month End", value: "ThisYearToPME" },
  { label: "Inception-to-date", value: "InceptionToDate" },
  { label: "Inception-to-PBD", value: "InceptionToPBD" },
  { label: "Inception-to-NBD", value: "InceptionToNBD" },
  { label: "Today-onwards", value: "TodayOnwards" },
  { label: "Custom-to-PBD", value: "CustomToPBD" },
  { label: "Custom-to-date", value: "CustomToDate" },
  { label: "Custom-to-NBD", value: "CustomToNBD" },
  { label: "Custom-to-T+3", value: "CustomToTPlus3" },
];

export const chicagoTimeZone = "America/Chicago";

export const generalDateFormat = "yyyy-MM-dd";

const nowZoned = (timezone: string) => utcToZonedTime(new Date(), timezone);

export const DateRangePresetEval = {
  AllDates: () => [null, null],
  Custom: () => [null, null],
  Today: (timezone: string) => [nowZoned(timezone), nowZoned(timezone)],
  ThisWeek: (timezone: string) => [
    startOfWeek(nowZoned(timezone)),
    endOfWeek(nowZoned(timezone)),
  ],
  ThisWeekToDate: (timezone: string) => [
    startOfWeek(nowZoned(timezone)),
    nowZoned(timezone),
  ],
  ThisMonth: (timezone: string) => [
    startOfMonth(nowZoned(timezone)),
    endOfMonth(nowZoned(timezone)),
  ],
  ThisMonthToDate: (timezone: string) => [
    startOfMonth(nowZoned(timezone)),
    nowZoned(timezone),
  ],
  ThisQuarter: (timezone: string) => [
    startOfQuarter(nowZoned(timezone)),
    endOfQuarter(nowZoned(timezone)),
  ],
  ThisQuarterToDate: (timezone: string) => [
    startOfQuarter(nowZoned(timezone)),
    nowZoned(timezone),
  ],
  ThisYear: (timezone: string) => [
    startOfYear(nowZoned(timezone)),
    endOfYear(nowZoned(timezone)),
  ],
  ThisYearToDate: (timezone: string) => [
    startOfYear(nowZoned(timezone)),
    nowZoned(timezone),
  ],
  LastWeek: (timezone: string) => {
    const lastWeekDate = subWeeks(nowZoned(timezone), 1);
    return [startOfWeek(lastWeekDate), endOfWeek(lastWeekDate)];
  },
  LastMonth: (timezone: string) => {
    const lastMonthDate = subMonths(nowZoned(timezone), 1);
    return [startOfMonth(lastMonthDate), endOfMonth(lastMonthDate)];
  },
  LastQuarter: (timezone: string) => {
    const lastMonthDate = subQuarters(nowZoned(timezone), 1);
    return [startOfQuarter(lastMonthDate), endOfQuarter(lastMonthDate)];
  },
  LastYear: (timezone: string) => {
    const lastMonthDate = subYears(nowZoned(timezone), 1);
    return [startOfYear(lastMonthDate), endOfYear(lastMonthDate)];
  },
  LastPreviousBusinessDay: (timezone: string) => [
    subBusinessDays(nowZoned(timezone), 1),
    nowZoned(timezone),
  ],
  PBD: (timezone: string) => [
    subBusinessDays(nowZoned(timezone), 1),
    subBusinessDays(nowZoned(timezone), 1),
  ],
  ThisWeekToPBD: (timezone: string) => [
    startOfWeek(nowZoned(timezone)),
    subBusinessDays(nowZoned(timezone), 1),
  ],
  ThisMonthToPBD: (timezone: string) => [
    startOfMonth(nowZoned(timezone)),
    subBusinessDays(nowZoned(timezone), 1),
  ],
  ThisQuarterToPBD: (timezone: string) => [
    startOfQuarter(nowZoned(timezone)),
    subBusinessDays(nowZoned(timezone), 1),
  ],
  ThisYearToPBD: (timezone: string) => [
    startOfYear(nowZoned(timezone)),
    subBusinessDays(nowZoned(timezone), 1),
  ],
  NBD: (timezone: string) => [
    addBusinessDays(nowZoned(timezone), 1),
    addBusinessDays(nowZoned(timezone), 1),
  ],
  ThisWeekToNBD: (timezone: string) => [
    startOfWeek(nowZoned(timezone)),
    addBusinessDays(nowZoned(timezone), 1),
  ],
  ThisMonthToNBD: (timezone: string) => [
    startOfMonth(nowZoned(timezone)),
    addBusinessDays(nowZoned(timezone), 1),
  ],
  ThisQuarterToNBD: (timezone: string) => [
    startOfQuarter(nowZoned(timezone)),
    addBusinessDays(nowZoned(timezone), 1),
  ],
  ThisYearToNBD: (timezone: string) => [
    startOfYear(nowZoned(timezone)),
    addBusinessDays(nowZoned(timezone), 1),
  ],
  ThisYearToPME: (timezone: string) => [
    startOfYear(nowZoned(timezone)),
    endOfMonth(subMonths(nowZoned(timezone), 1)),
  ],
  InceptionToDate: (timezone: string) => [null, nowZoned(timezone)],
  InceptionToPBD: (timezone: string) => [
    null,
    subBusinessDays(nowZoned(timezone), 1),
  ],
  InceptionToNBD: (timezone: string) => [
    null,
    addBusinessDays(nowZoned(timezone), 1),
  ],
  TodayOnwards: (timezone: string) => [nowZoned(timezone), null],
  CustomToPBD: (timezone: string) => [
    null,
    subBusinessDays(nowZoned(timezone), 1),
  ],
  CustomToDate: (timezone: string) => [null, nowZoned(timezone)],
  CustomToNBD: (timezone: string) => [
    null,
    addBusinessDays(nowZoned(timezone), 1),
  ],
  CustomToTPlus3: (timezone: string) => [
    null,
    addBusinessDays(nowZoned(timezone), 3),
  ],
} as const;

export function getDateRange(
  key: keyof typeof DateRangePresetEval,
  formatStr: string = generalDateFormat,
  timezone: string
): [string | undefined, string | undefined] {
  const dateRange = DateRangePresetEval[key](timezone);
  return [
    dateRange[0] ? format(dateRange[0], formatStr) : undefined,
    dateRange[1] ? format(dateRange[1], formatStr) : undefined,
  ];
}
