import {
  FinancialSubtype,
  InstrumentInfo,
  InstrumentSearchResult,
} from "@enfusion-ui/types";
import { REST_API } from "@enfusion-ui/web-core";

export function getSelectorValue(instrument: InstrumentInfo | null) {
  return instrument
    ? { label: instrument.description, value: instrument.id, instrument }
    : null;
}

export const fetchInstrumentOptions = async (
  searchText: string,
  financialSubTypes?: FinancialSubtype[],
  searchDescription?: boolean,
  primaryOnly?: boolean
) => {
  const res = await REST_API.INSTRUMENT.FAST_SEARCH.FETCH({
    searchText,
    financialSubTypes,
    searchDescription,
    primaryOnly,
  });

  if (!res) {
    return;
  }

  return res.map((instrument: InstrumentSearchResult) => {
    return {
      value: instrument.id,
      label: instrument.description,
      instrument,
    };
  });
};

export const fetchCDXInstrument = async (cdxOptions: {
  cdxRoot: string | undefined;
  otrOnly: boolean;
}) => {
  if (!cdxOptions.cdxRoot) {
    return [];
  }
  const res = await REST_API.INSTRUMENT.GET_CDX_INSTRUMENT.FETCH(
    cdxOptions.cdxRoot,
    cdxOptions.otrOnly!!
  );

  if (!res) {
    return;
  }

  return res.map((instrument: InstrumentInfo) => {
    return {
      value: instrument.id,
      label: instrument.description,
      instrument: instrument,
    };
  });
};
