import { useRefCallback } from "@enfusion-ui/hooks";
import { WebReportQuery, WebReportQuerySubType } from "@enfusion-ui/types";
import { Button, ButtonThemeOptions } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import GroupingTemplateSelect from "../components/ReportQueryComponents/GroupingTemplateSelect";
import { ReportFormContent } from "./ReportFormContent";

const ButtonWrapper = styled.div`
  width: 120px;
  height: 38px;
  display: flex;
  align-self: flex-end;
  :focus-within {
    border: 1px solid var(--primary);
  }
  margin-top: 24px;
`;

const FiltersContainer = styled.div`
  display: flex;
  grid-gap: var(--spacing-l);
  overflow: auto;
  padding-bottom: var(--spacing);
  max-width: calc(100% - 130px);
  float: left;
  margin-right: var(--spacing-l);
`;

const InputContainer = styled.div`
  max-width: 17rem;
  min-width: 250px;
`;

type ReportBaseFilterPanelProps = {
  reportQuery: WebReportQuery;
  onSubmit?: (query: WebReportQuery) => void;
};

export const ReportBaseFilterPanel: React.FC<ReportBaseFilterPanelProps> = ({
  reportQuery,
  onSubmit,
}) => {
  const formMethods = useForm<Record<string, any>>({
    defaultValues: reportQuery,
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods;
  const [theme, setTheme] = React.useState<ButtonThemeOptions>("basic");

  React.useEffect(() => {
    setTheme(isDirty ? "primary" : "basic");
  }, [isDirty]);

  React.useEffect(() => {
    reset(reportQuery);
  }, [JSON.stringify(reportQuery)]);

  const submitForm = useRefCallback((data) => {
    onSubmit?.({
      ...reportQuery,
      ...data,
    });
  }, []);

  return (
    <FormProvider {...formMethods}>
      <FiltersContainer>
        <InputContainer>
          <GroupingTemplateSelect />
        </InputContainer>
        <ReportFormContent
          type={reportQuery.type as WebReportQuerySubType}
          renderContentType="quickFilterComponent"
        />
      </FiltersContainer>
      <ButtonWrapper>
        <Button
          name="apply"
          onClick={handleSubmit(submitForm)}
          theme={theme}
          data-e2e-id="report-filter-apply-btn"
        >
          Apply
        </Button>
      </ButtonWrapper>
    </FormProvider>
  );
};
