import { useSelectDataValue } from "@app-views/dashboards/pdf/utils";
import {
  MarketEnvironmentSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { basicInfoMap, useRestAbortableOptions } from "@enfusion-ui/hooks";
import { ChannelDataType } from "@enfusion-ui/types";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

import PdfSelectionWidget from "../PdfSelectionWidget";

const PdfMarketEnvSelectionWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const { defaultValue, key } =
    config as MarketEnvironmentSelectionWidgetConfig;
  const [inputValue, setInputValue] = React.useState<string | null>(null);

  useSelectDataValue(
    key,
    "Market Environment",
    ChannelDataType.String,
    defaultValue
  );

  const { options } = useRestAbortableOptions(
    REST_API.LEGALENTITY.GET_MARKET_ENV.FETCH,
    basicInfoMap
  );

  React.useEffect(() => {
    if (defaultValue) {
      const selectedValue = options.find(
        ({ value: val }) => val === defaultValue
      );
      const formattedValue = selectedValue?.label;
      setInputValue(formattedValue || null);
    }
  }, [options, defaultValue]);

  return <PdfSelectionWidget label={key} value={inputValue} />;
};

export default PdfMarketEnvSelectionWidget;
