import { WidgetComponentsDefinition } from "../../types";
import GraphEditWidget from "./GraphEditWidget";
import GraphWidget from "./GraphWidget";
import PdfGraphWidget from "./PdfGraphWidget";

export const graph: WidgetComponentsDefinition = {
  pdfContentAsync: true,
  renderPdfComponent: PdfGraphWidget,
  renderComponent: GraphWidget,
  editComponent: GraphEditWidget,
};
