import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import { useRefCallback } from "@enfusion-ui/hooks";
import { DashboardRoot } from "@enfusion-ui/types";
import * as React from "react";

type CreateFolderModalParams = { root: DashboardRoot; path: string };

type DashboardsExplorerContextType = {
  createDashboardModalRoot: DashboardRoot | null;
  createFolderModalParams: CreateFolderModalParams | null;

  openCreateDashboardModal: (root: DashboardRoot) => void;
  closeCreateDashboardModal: VoidFunction;

  openCreateFolderModal: (root: DashboardRoot, path: string) => void;
  closeCreateFolderModal: VoidFunction;

  deleteDashboard: (root: DashboardRoot, path: string) => void;
};

const DashboardsExplorerContext = React.createContext<
  DashboardsExplorerContextType | undefined
>(undefined);

export function useDashboardsExplorer() {
  const context = React.useContext(DashboardsExplorerContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useDashboardsExplorer must be used within a DashboardsExplorerProvider"
    );
  }
  return context;
}

export default function DashboardsExplorerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [createDashboardModalRoot, setCreateDashboardModalRoot] =
    React.useState<DashboardRoot | null>(null);

  const [createFolderModalParams, setCreateFolderModalParams] =
    React.useState<CreateFolderModalParams | null>(null);

  const explorerEvent = useExplorerEvents("Dashboards");

  const openCreateDashboardModal = useRefCallback((root: DashboardRoot) => {
    setCreateDashboardModalRoot(root);
  }, []);
  const closeCreateDashboardModal = useRefCallback(() => {
    setCreateDashboardModalRoot(null);
  }, []);

  const openCreateFolderModal = useRefCallback(
    (root: DashboardRoot, path: string) => {
      setCreateFolderModalParams({ root, path });
    },
    []
  );
  const closeCreateFolderModal = useRefCallback(() => {
    setCreateFolderModalParams(null);
  }, []);

  const deleteDashboard = useRefCallback(
    (root: DashboardRoot, path: string) => {
      explorerEvent.broadcast(root, ExplorerEventsAction.Delete, path);
    },
    [explorerEvent]
  );

  return (
    <DashboardsExplorerContext.Provider
      value={{
        createDashboardModalRoot,
        createFolderModalParams,
        openCreateDashboardModal,
        closeCreateDashboardModal,
        openCreateFolderModal,
        closeCreateFolderModal,
        deleteDashboard,
      }}
    >
      {children}
    </DashboardsExplorerContext.Provider>
  );
}
