import {
  basicInfoMap,
  useRefCallback,
  useRestAbortableOptions,
} from "@enfusion-ui/hooks";
import { SelectOptionsType } from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

export type QuoteSetIdsSelectProps = Omit<
  Partial<MultiSelectProps<number>>,
  "options" | "value" | "onChange"
> & { value?: number[] | null; onChange?: (ids: number[] | null) => void };

export const ControlledQuoteSetIdsSelect: React.FC<
  Omit<QuoteSetIdsSelectProps, "value" | "onChange"> & { name?: string }
> = ({ name = "quoteSetIds", ...props }) => {
  return (
    <ControlledInputBase
      name={name}
      render={(renderProps) => (
        <QuoteSetIdsSelect {...renderProps} {...props} />
      )}
    />
  );
};

export const QuoteSetIdsSelect: React.FC<QuoteSetIdsSelectProps> = ({
  value: values,
  onChange,
  label = "Quote Set",
  placeholder = "All",
  inputId = "quote-set-multi-selection-id",
  ...rest
}) => {
  const [quoteSetIds, setQuoteSetIds] = React.useState<number[] | null>();

  const { options: quoteSetOptions, loading } = useRestAbortableOptions(
    REST_API.LEGALENTITY.GET_QUOTE_SETS.FETCH,
    basicInfoMap
  );

  React.useEffect(() => {
    if (values) {
      setQuoteSetIds(values);
    }
  }, [values]);

  const handleChange = useRefCallback(
    (values: SelectOptionsType<number>[]) => {
      const ids = values?.length
        ? values.map((option: SelectOptionsType<number>) => option.value)
        : null;
      setQuoteSetIds(ids);
      onChange?.(ids);
    },
    [onChange]
  );

  return (
    <MultiSelect
      {...rest}
      placeholder={placeholder}
      value={quoteSetIds ? getSelectedValues(quoteSetIds, quoteSetOptions) : []}
      onChange={(values) => handleChange(values as SelectOptionsType<number>[])}
      label={label}
      options={quoteSetOptions}
      isLoading={loading}
      inputId={inputId}
    />
  );
};
