import { useRefCallback } from "@enfusion-ui/hooks";
import { useLocalStorage } from "@enfusion-ui/hooks/build/web";
import { useAuth } from "@enfusion-ui/web-core";
import { useWorkerModule } from "@enfusion-ui/web-workers";
import { debounce } from "lodash";
import * as React from "react";

import { SessionContext } from "./context";

const TIMEOUT_STEP = 5;

const SessionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [sessionExpired, setSessionExpired] = React.useState<boolean>(false);

  const { enableModule, subscribeToModule, postMessage, disableModule } =
    useWorkerModule("session");

  const { logout: shutDown, user } = useAuth();
  const [timeoutSettings, setTimeoutSettings] = useLocalStorage(
    `e-sts-${user?.username}`,
    { timeout: 15, enabled: true }
  );

  const settings = React.useMemo(() => {
    if (
      typeof timeoutSettings === "undefined" ||
      Number.isNaN(timeoutSettings.timeout)
    ) {
      return {
        timeout: 15,
        enabled: true,
      };
    } else {
      return timeoutSettings;
    }
  }, [timeoutSettings]);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const unsubscribeModule = subscribeToModule(async (message: any) => {
      const { type, payload } = message;
      switch (type) {
        case "state-update": {
          setSessionExpired(payload.sessionExpired);
          if (settings.timeout !== payload.timeout) {
            setTimeoutSettings((state) => ({
              ...state,
              timeout: payload.timeout,
            }));
          }
          break;
        }
        case "shutdown": {
          console.info("shutting down session");
          shutDown();
          break;
        }
      }
    });
    if (window.location.pathname.includes("/mobile-app-auth")) {
      disableModule();
    } else {
      if (settings.enabled) enableModule();
    }

    return unsubscribeModule;
  }, [setTimeoutSettings]);

  const debouncedEventHandler = React.useCallback(
    debounce(() => {
      postMessage({ command: "reset" });
    }, 300),
    [postMessage]
  );

  React.useEffect(() => {
    if (settings.enabled) {
      window.addEventListener("click", debouncedEventHandler);
      window.addEventListener("scroll", debouncedEventHandler);
      window.addEventListener("keydown", debouncedEventHandler);
      window.addEventListener("mousemove", debouncedEventHandler);
    }

    return () => {
      window.removeEventListener("click", debouncedEventHandler);
      window.removeEventListener("scroll", debouncedEventHandler);
      window.removeEventListener("keydown", debouncedEventHandler);
      window.removeEventListener("mousemove", debouncedEventHandler);
    };
  }, [settings.enabled]);

  const resetTimer = React.useCallback(() => {
    postMessage({ command: "awake" });
  }, [postMessage]);

  const logout = React.useCallback(() => {
    shutDown();
  }, [shutDown]);

  const setEnabled = React.useCallback(
    debounce((enabled: boolean) => {
      if (!settings.enabled && enabled) {
        enableModule();
      } else if (settings.enabled && !enabled) {
        disableModule();
      }
      setTimeoutSettings((state) => ({ ...state, enabled }));
    }, 600),
    [settings]
  );

  const updateTimeout = useRefCallback(
    debounce((timeout: number) => {
      postMessage({ command: "set-timeout", payload: { timeout } });
    }, 600),
    []
  );

  const setTimeoutValue = useRefCallback((val: number) => {
    let timeout = Math.abs(val);

    if (Number.isNaN(timeout)) {
      timeout = 15;
    }

    if (timeout >= TIMEOUT_STEP && timeout % TIMEOUT_STEP !== 0) {
      timeout = timeout - (timeout % TIMEOUT_STEP);
    }

    setTimeoutSettings((state) => ({ ...state, timeout }));
    updateTimeout(timeout);
  }, []);

  return (
    <SessionContext.Provider
      value={{
        sessionExpired,
        logout,
        resetTimer,
        setEnabled,
        setTimeoutValue,
        ...settings,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
