import AllocationsGrid from "@app-components/inputs/allocations/AllocationsGrid";
import AllocationsPanel from "@app-components/inputs/allocations/AllocationsPanel";
import ApplyAllocationsPanel from "@app-components/inputs/allocations/ApplyAllocationsPanel";
import PreferredAllocationSchemeSelect from "@app-components/inputs/allocations/PreferredAllocationSchemeSelect";
import { WidgetComponentsDefinition } from "@app-views/dashboards/widgets/types";
import { BoldText } from "@app-views/watchList/components/styles";
import { useOEMSOrderForm } from "@enfusion-ui/core";
import {
  Button,
  ColumnGrid,
  FormController,
  Select,
  TextInput,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useWatch } from "react-hook-form";

const EmptyContainer = styled.div<{ height?: number }>`
  border: 1px dotted var(--primary);
  height: ${({ height }) => height ?? 1}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AllocationsGridWidget = () => {
  const { isCdxOrder, splitOrder } = useOEMSOrderForm();
  const { quantity, notional } = useWatch({
    name: ["quantity", "notional"],
  });
  return (
    <AllocationsGrid
      disabled={false}
      quantity={isCdxOrder && !splitOrder ? notional : quantity}
      suppressClickEdit={false}
    />
  );
};

const ApplyAllocationsWidget = () => {
  const { swapOrder } = useWatch({
    name: ["swapOrder"],
  });
  return <ApplyAllocationsPanel disabled={swapOrder} />;
};

const AllocationSchemeSelectWidget: React.FC = () => {
  return (
    <FormController
      name="allocationSchemeId"
      render={({ ref: _ref, ...rest }) => (
        <PreferredAllocationSchemeSelect {...rest} disabled={false} />
      )}
    />
  );
};

const AllocationsPanelMobile = () => (
  <ColumnGrid isMobile={false} numColumns={1}>
    <BoldText style={{ textAlign: "center", marginTop: 8 }}>
      Allocation
    </BoldText>
    <EmptyContainer height={114}>Allocation Scheme Select</EmptyContainer>
    <Select disabled placeholder="" />
    <TextInput label="Allocation Instructions" />
    <Button icon={faChevronRight} iconPosition="right" style={{ width: "50%" }}>
      View Details
    </Button>
  </ColumnGrid>
);
export const allocationsPanel: WidgetComponentsDefinition = {
  renderComponent: AllocationsPanel,
  renderMobileComponent: AllocationsPanelMobile,
};

export const allocationsGridPanel: WidgetComponentsDefinition = {
  renderComponent: AllocationsGridWidget,
};

export const applyAllocationsPanel: WidgetComponentsDefinition = {
  renderComponent: ApplyAllocationsWidget,
};

export const allocationSchemeSelect: WidgetComponentsDefinition = {
  renderComponent: AllocationSchemeSelectWidget,
};
