import CurrencySelect from "@app-views/reports/reportSettings/generalLedgerAccounting/glDistributionLine/CurrencySelect";
import { NotionalTypeValues, TradeTicketFormValues } from "@enfusion-ui/trades";
import { createSelectOptions } from "@enfusion-ui/utils";
import {
  ControlledNumericInput,
  ControlledSelect,
  FormPanel,
  PanelRow,
} from "@enfusion-ui/web-components";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

const ORDER_TYPES = {
  "Buy to Open": "BuyToOpen",
  "Sell to Close": "sellToClose",
  "Buy to Close": "BuyToClose",
  "Sell to Open": "SellToOpen",
} as const;
const orderTypeOptions = createSelectOptions(ORDER_TYPES);

const notionalTypeOptions = createSelectOptions(NotionalTypeValues);

export const CostPanelVarSwap: React.FC = () => {
  const { setValue } = useFormContext<TradeTicketFormValues>();
  const notionalType = useWatch({ name: "notionalType" });

  React.useEffect(() => {
    setValue(
      notionalType === "VarianceUnits" ? "vegaNotional" : "varianceUnits",
      null
    );
  }, [notionalType]);

  return (
    <FormPanel
      id="trade-cost-section"
      title="Cost"
      numColumns={1}
      collapsible
      defaultOpen
      keepRendered
      defaultRendered
      lineStyle
      tabFocusOutline={false}
    >
      <PanelRow>
        <ControlledSelect
          required
          name="orderType"
          label="Order Type"
          options={orderTypeOptions}
        />
      </PanelRow>

      <PanelRow>
        <ControlledSelect
          required
          name="notionalType"
          label="Notional Type"
          options={notionalTypeOptions}
        />
        <ControlledNumericInput
          name="varianceUnits"
          label="Variance Units"
          required={notionalType === "VarianceUnits"}
          disabled={notionalType !== "VarianceUnits"}
        />
      </PanelRow>

      <PanelRow>
        <CurrencySelect name="notionalCcy" label="Notional CCY" required />
        <ControlledNumericInput
          name="vegaNotional"
          label="Vega Notional"
          required={notionalType === "VegaNotional"}
          disabled={notionalType !== "VegaNotional"}
        />
      </PanelRow>
    </FormPanel>
  );
};
