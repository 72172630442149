import { AssetMeasureWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import { AssetMeasureSelect } from "../../../../../reports/components/ReportQueryComponents/AssetMeasureSelect";

const AssetMeasureWidget = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, key } = config as AssetMeasureWidgetConfig;
  const [value, setValue] = React.useState<string[] | null>(defaultValue);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Asset Measures",
        type: ChannelDataType.String,
      },
    ]);
  }, [addChannelKeys, key]);

  React.useEffect(() => {
    setChannelData(value ? [value] : [[]]);
  }, [setChannelData, value]);

  const handleChange = (newValue: string[] | null) => {
    setValue(newValue);
  };

  return (
    <CenterContent>
      <AssetMeasureSelect label={key} value={value} onChange={handleChange} />
    </CenterContent>
  );
};

export default AssetMeasureWidget;
