import { useOEMSOrderForm } from "@enfusion-ui/core";
import {
  ErrorAlert,
  InfoAlert,
  SuccessAlert,
  TopAlertContainer,
  WarningAlert,
} from "@enfusion-ui/web-components";
import * as React from "react";

export const ComplianceErrorList: React.FC = () => {
  const { submitErrors, complianceRecords } = useOEMSOrderForm();
  return (
    <>
      {submitErrors ? (
        <TopAlertContainer>
          {submitErrors.map((error, idx) => (
            <ErrorAlert key={`${error}-${idx}`} error={error} />
          ))}
        </TopAlertContainer>
      ) : null}
      {complianceRecords ? (
        <TopAlertContainer>
          {complianceRecords.map((record, idx) => {
            switch (record.complianceState) {
              case "Passed":
                return <SuccessAlert key={idx} message={record.text} />;
              case "Failed":
              case "OverrideNotAllowed":
                return <ErrorAlert key={idx} error={record.text} />;
              case "Warning":
              case "WarningWithNote":
                return <WarningAlert key={idx} warning={record.text} />;
              //TODO: check how the below compliance states should be treated
              case "Tracking":
              case "Disabled":
              case "PendingCheck":
              default:
                return record.text ? (
                  <InfoAlert key={idx} message={record.text} />
                ) : null;
            }
          })}
        </TopAlertContainer>
      ) : null}
    </>
  );
};
