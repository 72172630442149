import { useRefCallback } from "@enfusion-ui/hooks";
import { Button } from "@enfusion-ui/web-components";
import { css, styled } from "@enfusion-ui/web-core";
import HighchartsReact from "highcharts-react-official";
import * as React from "react";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export type ZoomResetButtonPosition = {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
};

const ZoomResetButton = styled(Button)<{
  position: ZoomResetButtonPosition;
}>`
  width: fit-content;
  padding: 0px;
  height: 11px;
  position: absolute;
  background-color: transparent !important;
  white-space: nowrap;
  font-size: 11px;
  line-height: 11px;
  font-weight: normal;
  opacity: 0.6;

  ${({ position: { top, right, bottom, left } }) => css`
    top: ${top ?? "unset"};
    right: ${right ?? "unset"};
    bottom: ${bottom ?? "unset"};
    left: ${left ?? "unset"};
  `}

  :hover {
    opacity: 1;
  }
`;

type Props = HighchartsReact.Props & {
  zoomResetButtonPosition?: ZoomResetButtonPosition;
};

export const HighchartsWithCustomResetButton = React.forwardRef<
  HighchartsReact.RefObject,
  Props
>(({ options, zoomResetButtonPosition, ...props }, ref) => {
  const localRef = React.useRef<HighchartsReact.RefObject>();
  const [isZoomed, setIsZoomed] = React.useState(false);

  const updatedOptions: Highcharts.Options = React.useMemo(
    () =>
      options
        ? {
            ...options,
            chart: {
              ...options.chart,
              events: {
                ...options.chart?.events,
                render: () => {
                  /* Overwrite method which is responsible for showing the default button */
                  if (localRef.current) {
                    localRef.current.chart.showResetZoom = () =>
                      setIsZoomed(true);
                  }
                },
              },
            },
          }
        : {},
    [options]
  );

  const resetZoom = useRefCallback(() => {
    localRef.current?.chart.zoomOut();
    setIsZoomed(false);
  }, []);

  const setRef = useRefCallback((refObj: HighchartsReact.RefObject) => {
    localRef.current = refObj;
    if (typeof ref === "function") {
      ref(refObj);
    } else if (ref) {
      ref.current = refObj;
    }
  }, []);

  return (
    <Container>
      <HighchartsReact ref={setRef} options={updatedOptions} {...props} />

      {zoomResetButtonPosition ? (
        <ZoomResetButton
          theme="primary"
          hidden={!isZoomed}
          onClick={resetZoom}
          position={zoomResetButtonPosition}
        >
          Reset Zoom
        </ZoomResetButton>
      ) : null}
    </Container>
  );
});
