import { useAnalyticsContext } from "@app-context/analytics/context";
import { getDTDLongTermChartConfig } from "@app-views/analytics/chartConfigs";
import {
  CalloutGrid,
  CalloutGridSeriesEntry,
} from "@app-views/analytics/components/CalloutGrid";
import { SizedChart } from "@app-views/analytics/components/SizedChart";
import { TempTriangleIcon } from "@app-views/analytics/components/TempTriangleIcon";
import { formatNumber, formatPercentage } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

const SubContainer = styled(Container)`
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  margin-bottom: var(--spacing);
`;

const days = (val?: number | null) =>
  `${formatNumber(val ?? 0, { fractionDigits: [0, 2, 1], reduce: true })} Days`;

const per = (val?: number | null) =>
  formatPercentage(val ?? 0, [0, 2], false, undefined, true);

const baseEntry = (subText: string, val?: number) => ({
  value: val ?? "-",
  subText,
});

const sideEntry = (subText: string, val?: number) => ({
  ...baseEntry(subText, val),
  color: val && val > 0 ? "success" : "danger",
});

const percentEntry = (subText: string, val?: number) => ({
  ...sideEntry(subText, val),
  value: per(val ?? null),
});

export const DrawdownsSummary: React.FC = () => {
  const { data } = useAnalyticsContext();

  const calloutSeries = React.useMemo(
    () =>
      [
        {
          name: "Returns",
          data: [
            percentEntry("ROMAD", data?.risk.drawdowns.returns.romad.value),
            percentEntry(
              "To Trough",
              data?.risk.drawdowns.returns.trough.value
            ),
          ],
        },
        {
          name: "Proportion",
          data: [
            percentEntry(
              "Of Fund",
              data?.risk.drawdowns.proportions.fund.value
            ),
            baseEntry(
              "Positions",
              data?.risk.drawdowns.proportions.positions.value
            ),
          ],
        },
        {
          name: "Length",
          data: [
            sideEntry("Days Avg.", data?.risk.drawdowns.length.daysAvg.value),
            baseEntry("Of Periods", data?.risk.drawdowns.length.period.value),
          ],
        },
      ] as CalloutGridSeriesEntry[],
    [
      data?.risk.drawdowns.returns,
      data?.risk.drawdowns.proportions,
      data?.risk.drawdowns.length,
    ]
  );

  const liquidity = React.useMemo(
    () => data?.risk.drawdowns.liquidity ?? null,
    [data?.risk.drawdowns.liquidity]
  );

  const getOptions = React.useCallback(
    (width: number) =>
      getDTDLongTermChartConfig(width, "Liquidity", days, [
        [liquidity?.data.dtd ?? 0],
        [liquidity?.data.longTerm ?? 0],
      ]),
    [liquidity]
  );

  return (
    <Container>
      <TopRow>
        Drawdowns
        <TempTriangleIcon />
      </TopRow>
      <SubContainer>
        <CalloutGrid series={calloutSeries} />
        <div
          style={{ height: "var(--spacing-xl)", padding: "var(--spacing-l)" }}
        />
        <SizedChart getOptions={getOptions} />
      </SubContainer>
    </Container>
  );
};
