import { WidgetComponentsDefinition } from "../../../types";
import MarketEnvSelectionEditWidget from "./MarketEnvSelectionEditWidget";
import MarketEnvSelectionWidget from "./MarketEnvSelectionWidget";
import PdfMarketEnvSelectionWidget from "./PdfMarketEnvSelectionWidget";

export const marketEnvironmentSelection: WidgetComponentsDefinition = {
  renderComponent: MarketEnvSelectionWidget,
  editComponent: MarketEnvSelectionEditWidget,
  renderPdfComponent: PdfMarketEnvSelectionWidget,
};
