import { ReconciliationProvider } from "@app-context/reconciliation/ReconciliationProvider";
import React from "react";

import { BreakDetailsCellRenderer } from "../panels/breakDetailsPanel/BreakDetailsCellRenderer";
import { BreakDetailsCellRendererProps } from "../types";
import { ContentWrapper } from "./styles";

const ReconciliationBreakDetailsView: React.FC<{
  config: BreakDetailsCellRendererProps;
}> = ({ config }) => {
  return (
    <ContentWrapper>
      <ReconciliationProvider>
        <BreakDetailsCellRenderer data={config} />
      </ReconciliationProvider>
    </ContentWrapper>
  );
};

export default ReconciliationBreakDetailsView;
