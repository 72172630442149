import { DataGridProps, RoundedDataGrid } from "@app-components/DataGrid";
import {
  createRouteBlotterColumnDefs,
  defaultOemsColDef,
  oemsColumnTypes,
} from "@app-views/oems/utils/createColumnDefs";
import { BlotterColumnKey } from "@enfusion-ui/core";
import { ColDef, GetRowIdParams } from "ag-grid-community";
import * as React from "react";

import useRoutes from "./behavior/useRoutes";

export type RoutesListProps = {
  height: number | string;
} & DataGridProps;

const COLUMNS: BlotterColumnKey[] = [
  "OrderId",
  "OrderDate",
  "Destination",
  "Strategy",
  "Quantity",
  "Filled",
  "AvgPx",
];

function RoutesList({
  height,
  onRowClicked,
  onRowDataUpdated,
}: RoutesListProps) {
  const [columnDefs, setColumnDefs] = React.useState<ColDef[]>([]);
  const { routes, onGridReady, loading, gridApi: gridApiRef } = useRoutes();

  React.useEffect(() => {
    if (loading) gridApiRef.current?.showLoadingOverlay();
    else if (routes.length === 0) gridApiRef.current?.showNoRowsOverlay();
    else gridApiRef.current?.hideOverlay();
  }, [loading, routes]);

  React.useEffect(() => {
    setColumnDefs(createRouteBlotterColumnDefs(COLUMNS));
  }, []);

  return (
    <RoundedDataGrid
      height={height}
      gridName="routes"
      columnDefs={columnDefs}
      groupDefaultExpanded={-1}
      rowData={routes.length > 0 ? routes : undefined}
      getRowId={(params: GetRowIdParams) => params.data?.orderId?.toString()}
      suppressCellFocus
      suppressDragLeaveHidesColumns
      suppressRowGroupHidesColumns
      multiSortKey="ctrl"
      rowSelection="single"
      rowGroupPanelShow="always"
      onGridReady={onGridReady}
      onRowClicked={onRowClicked}
      onRowDataUpdated={onRowDataUpdated}
      defaultColDef={defaultOemsColDef}
      columnTypes={oemsColumnTypes}
    />
  );
}

export default RoutesList;
