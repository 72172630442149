import ColumnSelect from "@app-components/inputs/ColumnSelect";
import { useAllocations } from "@app-context/allocation/context";
import { SelectOptionsType } from "@enfusion-ui/types";
import * as React from "react";

type BorrowAgreementOption = SelectOptionsType<number>;
export type BorrowAgreementCellEditorProps = {
  custodianId: number;
  label?: string;
};

const BorrowAgreementCellEditor = React.forwardRef(
  (props: BorrowAgreementCellEditorProps, ref) => {
    const { custodianId } = props;
    const [borrowAgreementOptions, setBorrowAgreementOptions] = React.useState<
      BorrowAgreementOption[]
    >([]);
    const [selectedBorrowAgreement, setSelectedBorrowAgreement] =
      React.useState<BorrowAgreementOption | null>();
    const BorrowAgreementCellEditorRef = React.useRef(null);
    const { getBorrowAgreementOptions } = useAllocations();

    React.useEffect(() => {
      const getAgreements = async () => {
        const agreements = await getBorrowAgreementOptions?.(custodianId);
        setBorrowAgreementOptions((agreements || []).filter((i) => i !== null));
      };
      if (custodianId) getAgreements();
    }, [getBorrowAgreementOptions, custodianId]);

    React.useImperativeHandle(ref, () => ({
      getValue() {
        return selectedBorrowAgreement;
      },
      isPopup() {
        return true;
      },
    }));

    return (
      <>
        <div ref={BorrowAgreementCellEditorRef} />
        <ColumnSelect
          options={borrowAgreementOptions || []}
          onChange={(newBorrowAgreement: BorrowAgreementOption | null) => {
            setSelectedBorrowAgreement(newBorrowAgreement);
          }}
          inputId="borrow-agreement-selection-id"
          value={selectedBorrowAgreement?.value}
          placeholder="Select"
        />
      </>
    );
  }
);

export default BorrowAgreementCellEditor;
