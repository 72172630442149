import { useRefCallback, useRestAbortableOptions } from "@enfusion-ui/hooks";
import { SelectOptionsType } from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

export type DeskSelectProps = {
  value?: number[] | null;
  onChange?: (ids: number[] | null) => void;
  selectProps?: Omit<
    Partial<MultiSelectProps<number>>,
    "options" | "value" | "onChange"
  >;
};

export const DeskSelect: React.FC<DeskSelectProps> = ({
  selectProps = {},
  value,
  onChange,
}) => {
  const {
    placeholder = "All Desks",
    label = "Desks",
    inputId = "assigned-desk-multi-selection-id",
    ...rest
  } = selectProps;
  const [assignedDeskIds, setAssignedDeskIds] = useState<number[] | null>();

  const { options, loading } = useRestAbortableOptions(
    REST_API.SECURITY.GET_TRADING_DESKS.FETCH,
    (td) => ({ value: td.id, label: td.groupName })
  );

  React.useEffect(() => {
    if (value) {
      setAssignedDeskIds(value);
    }
  }, [value]);

  const handleChange = useRefCallback(
    (values: SelectOptionsType<number>[]) => {
      const ids = values?.length
        ? values.map((option: SelectOptionsType<number>) => option.value)
        : null;
      setAssignedDeskIds(ids);
      onChange?.(ids);
    },
    [onChange]
  );

  return (
    <MultiSelect
      {...rest}
      placeholder={placeholder}
      label={label}
      isLoading={loading}
      value={assignedDeskIds ? getSelectedValues(assignedDeskIds, options) : []}
      onChange={(values) => handleChange(values as SelectOptionsType<number>[])}
      options={options}
      inputId={inputId}
    />
  );
};

const ControlledAssignedDeskSelect: React.FC<{
  defaultAssignedDesk: Array<number>;
}> = ({ defaultAssignedDesk }) => {
  const { setValue, watch } = useFormContext();
  const { assignedDeskIds } = watch();

  return (
    <ControlledInputBase
      name="assignedDeskIds"
      render={({ ref: _ref, onChange, ...rest }) => (
        <DeskSelect
          selectProps={rest}
          value={assignedDeskIds}
          onChange={(ids) => {
            setValue("assignedDeskIds", ids);
            onChange(ids);
          }}
        />
      )}
      defaultValue={defaultAssignedDesk}
    />
  );
};

export default ControlledAssignedDeskSelect;
