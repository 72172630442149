import QuantityNotionalPanel from "@app-views/oems/OrderTicket/panels/QuantityNotionalPanel";
import { useWidget } from "@enfusion-ui/dashboards";

export const QuantityNotionalWidget = () => {
  const { config } = useWidget();
  const { input, showCalculationInfoText } = config;
  return (
    <QuantityNotionalPanel
      disabled={false}
      input={input}
      showCalculationInfoText={showCalculationInfoText}
    />
  );
};
