import QuantityNotionalPanel from "@app-views/oems/OrderTicket/panels/QuantityNotionalPanel";
import { useWidget } from "@enfusion-ui/dashboards";
import { ColumnGrid } from "@enfusion-ui/web-components";

import { OrderSideSelectMobile, QuickEntryButton } from "./definitions";

export const OrderDetailsWidgetMobile = () => {
  const { config } = useWidget();
  const { input = "quantity", showCalculationInfoText = true } = config;
  return (
    <ColumnGrid isMobile={false} numColumns={1}>
      <QuickEntryButton />
      <OrderSideSelectMobile />
      <QuantityNotionalPanel
        input={input}
        showCalculationInfoText={showCalculationInfoText}
      />
    </ColumnGrid>
  );
};
