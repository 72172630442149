import ColumnSelect from "@app-components/inputs/ColumnSelect";
import { useAllocations } from "@app-context/allocation/context";
import { SelectOptionsType } from "@enfusion-ui/types";
import * as React from "react";

type TRSOptionsType = SelectOptionsType<number>;
export type TRSSelectProps = {
  custodianId: number;
};
const TRSSelect = React.forwardRef((props: TRSSelectProps, ref) => {
  const { custodianId } = props;
  const [TRSOptions, setTRSOptions] = React.useState<TRSOptionsType[]>([]);
  const [selectedTRS, setSelectedTRS] = React.useState<TRSOptionsType | null>(
    null
  );
  const TRSSelectRef = React.useRef(null);

  const { getTRSOptions } = useAllocations();

  React.useEffect(() => {
    const getAgreements = async () => {
      const options = await getTRSOptions?.(custodianId);
      setTRSOptions(options || []);
    };

    if (custodianId) getAgreements();
  }, [getTRSOptions, custodianId]);

  React.useImperativeHandle(ref, () => ({
    getValue() {
      return selectedTRS;
    },
    isPopup() {
      return true;
    },
  }));

  return (
    <>
      <div ref={TRSSelectRef} />
      <ColumnSelect
        inputId="allocation-trs-id"
        options={TRSOptions}
        onChange={(newTRSOption: TRSOptionsType | null) => {
          setSelectedTRS(newTRSOption);
        }}
        value={selectedTRS?.value}
        placeholder="Select"
      />
    </>
  );
});

export default TRSSelect;
