import { ThemeDefinition } from "@enfusion-ui/core";
import * as React from "react";
import { styled, css } from "@enfusion-ui/web-core";

export const Label = styled.div`
  font-weight: bold;
  font-size: 0.8em;
  line-height: 1.25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LabeledValueContainer = styled.div`
  width: max-content;
  font-size: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Leg = styled.div`
  max-width: 100%;
  flex: 1;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

export const Value = styled.label<{
  themeColor?: keyof ThemeDefinition["colors"];
}>`
  overflow: hidden;
  ${({ themeColor }) =>
    themeColor !== undefined
      ? css`
          color: ${({ theme }) =>
            theme.colors[themeColor as keyof ThemeDefinition["colors"]]};
        `
      : ""}
`;

export const LabeledValue: React.FC<React.PropsWithChildren<{
  label: string;
  themeColor?: keyof ThemeDefinition["colors"];
  className?: string;
  title?: string;
}>> = ({ label, title, className, children, themeColor = "textNormal" }) => (
  <LabeledValueContainer
    title={`${label}${title ? `: ${title}` : ""}`}
    className={className}
  >
    <Label>{label}</Label>
    <Value themeColor={themeColor}>{children}</Value>
  </LabeledValueContainer>
);

export const FutureLegLabeledValue: React.FC<React.PropsWithChildren<{
  label: string;
  futureLeg: string;
  themeColor?: keyof ThemeDefinition["colors"];
  className?: string;
  title?: string;
}>> = ({
  label,
  title,
  className,
  children,
  futureLeg,
  themeColor = "textNormal",
}) => (
  <LabeledValueContainer
    title={`${label}${title ? `: ${title}` : ""}`}
    className={className}
  >
    <Label>{label}</Label>
    <Leg>{futureLeg}</Leg>
    <Value themeColor={themeColor}>{children}</Value>
  </LabeledValueContainer>
);

export const NumericLabeledValue = styled(LabeledValue)`
  font-variant-numeric: lining-nums tabular-nums;
`;

export const VarColorText = styled.div<{
  colorValue: string | null;
}>`
  color: ${({ colorValue }) =>
    colorValue ? `var(--${colorValue})` : "inherit"};
`;
