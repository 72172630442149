import { ReportSettingsDefinition } from "../../types";
import CorporateActionQuickFilter from "./CorporateActionQuickFilter";
import CorporateActionSettings from "./CorporateActionSettings";

export const WebCorporateActionReportQuery: ReportSettingsDefinition = {
  name: "CorporateAction",
  queryType: "WebCorporateActionReportQuery",
  settingsComponent: CorporateActionSettings,
  quickFilterComponent: CorporateActionQuickFilter,
};
