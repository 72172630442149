import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import { useSettings } from "@app-context/settings/context";
import { useAllowCustomOrderTicket } from "@app-utils/useAllowCustomOrderTicket";
import { FormType, InitialValues, OrderColumnInfo } from "@enfusion-ui/core";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { ConnectionStatus, CurrencyInfo } from "@enfusion-ui/types";
import {
  CustomState,
  initialOEMSContextState,
  OEMS_DEFAULT_SELECTED_FILTER,
  OEMS_EVENTS,
  OemsBroadcastEvent,
  OEMSContext,
  OEMSContextState,
  OemsFilterKey,
  OemsTransaction,
  REST_API,
  useTabs,
} from "@enfusion-ui/web-core";
import { useWorkerModule } from "@enfusion-ui/web-workers";
import { faDisplay } from "@fortawesome/pro-solid-svg-icons";
import { isEqual, pick } from "lodash";
import * as React from "react";

import {
  ConnectionType,
  useConnectionStatus,
} from "../connectionStatus/context";

const prevOverrideKeys = [
  "accounts",
  "books",
  "allocationSchemes",
  "preferredAllocationSchemes",
  "currencyDetails",
  "allocationTemplate",
];

export const NewOrderTicketNames: Record<"custom" | FormType, string> = {
  custom: "New Custom Order",
  equity: "New Order",
  fx: "New FX Order",
  cdx: "New CDX Order",
  irs: "New IRS Order",
  varSwap: "New Variance Swap Order",
  futureSpread: "New Future Spread Order",
};

const OEMSProvider: React.FC<React.PropsWithChildren<{ enabled: boolean }>> = ({
  children,
  enabled,
}) => {
  const isMounted = useMounted();
  const { enableModule, disableModule, subscribeToModule, getCurrentState } =
    useWorkerModule("oms");
  const [omsState, setOMSStateBase] = React.useState<OEMSContextState>(
    initialOEMSContextState
  );
  const [selectedFilter, setSelectedFilter] = React.useState<OemsFilterKey>(
    OEMS_DEFAULT_SELECTED_FILTER
  );
  const { updateStatus } = useConnectionStatus();

  const subscriptionsRef = React.useRef(
    new Set<(transaction: OemsTransaction) => void>()
  );

  const subscribe = useRefCallback(
    (cb: (transaction: OemsTransaction) => void) => {
      subscriptionsRef.current.add(cb);
      return () => {
        subscriptionsRef.current.delete(cb);
      };
    },
    [subscriptionsRef]
  );

  const { openTab, focusTab } = useTabs();
  const { enablePreferCustomOrderForm: preferCustom } = useSettings();
  const allowCustomOrderTicket = useAllowCustomOrderTicket();
  const [newOrderTicketReady, setNewOrderTicketReady] = React.useState(false);
  const initialValuesRef = React.useRef<InitialValues | null>(null);
  const oemsEventsChannel =
    useBroadcastChannel<OemsBroadcastEvent>(OEMS_EVENTS);

  const openNewOrderTab = useRefCallback(
    (
      formType: FormType,
      customState: CustomState = "prefer",
      initialValues?: InitialValues
    ) => {
      if (initialValues && newOrderTicketReady) {
        oemsEventsChannel.broadcastMessage({
          action: "instrument-selected",
          payload: { ...initialValues },
        });
        focusTab("new-oems-order");
        return;
      }

      if (initialValues) {
        /* Initial values will be broadcasted when new order ticket gets ready */
        initialValuesRef.current = initialValues;
      }

      const custom =
        allowCustomOrderTicket &&
        formType === "equity" &&
        ((preferCustom && customState === "prefer") || customState === "force");
      const name = NewOrderTicketNames[custom ? "custom" : formType];
      openTab({
        name,
        component: "new-oems-order",
        unique: "new-oems-order",
        icon: faDisplay,
        config: {
          formType,
          custom,
        },
      });
      oemsEventsChannel.broadcastMessage({
        action: "open-order-tab",
        payload: { orderId: 0 },
      });
    },
    [preferCustom, newOrderTicketReady]
  );

  React.useEffect(() => {
    if (newOrderTicketReady && !!initialValuesRef.current) {
      oemsEventsChannel.broadcastMessage({
        action: "instrument-selected",
        payload: { ...initialValuesRef.current },
      });
      initialValuesRef.current = null;
    }
  }, [newOrderTicketReady]);

  const triggerTransaction = useRefCallback(
    (transaction: OemsTransaction) => {
      [...subscriptionsRef.current].map((cb) => {
        return new Promise(() => {
          try {
            cb(transaction);
          } catch (err) {
            console.warn("failed to trigger transaction callback", err);
          }
        });
      });
    },
    [subscriptionsRef]
  );

  const setOMSState = useRefCallback(
    (action: React.SetStateAction<OEMSContextState>) => {
      if (isMounted()) setOMSStateBase(action);
    },
    [isMounted]
  );

  const orderColumnsRef = React.useRef<
    Record<string, OrderColumnInfo[]> | undefined
  >();
  const getInitialMetadata = useRefCallback(
    async (
      initializationMessage = "",
      overrideState: Partial<OEMSContextState> = {}
    ) => {
      const [
        orderColumns,
        accounts,
        books,
        allocationSchemes,
        preferredAllocationSchemes,
        currenciesInfo,
        allocationTemplate,
      ] = await Promise.all([
        REST_API.OEMS.GET_ORDER_COLUMNS.FETCH(),
        REST_API.OEMS.GET_ACCOUNTS.FETCH(),
        REST_API.FUND.GET_BOOKS.FETCH(),
        REST_API.OEMS.GET_ALL_ALLOCATION_SCHEMES.FETCH(),
        REST_API.OEMS.GET_PREFERRED_ALLOCATION_SCHEMES.FETCH(),
        REST_API.INSTRUMENT.GET_SETTLEMENT_CURRENCIES.FETCH(),
        REST_API.OEMS.GET_ALLOCATIONS_TEMPLATES.FETCH(),
      ]);

      const currencyDetails = currenciesInfo.reduce(
        (acc, value: CurrencyInfo) => ({
          ...acc,
          [value.code]: value,
        }),
        {}
      );

      orderColumnsRef.current = orderColumns;

      setOMSState((prevState) => ({
        ...prevState,
        ...overrideState,
        accounts,
        books,
        allocationSchemes,
        preferredAllocationSchemes,
        currencyDetails,
        allocationTemplate,
        initializationMessage,
      }));
    },
    []
  );

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return subscribeToModule(async (message: any) => {
      const { type, payload } = message;
      switch (type) {
        case "state-update": {
          setOMSState((prevState) => ({ ...prevState, ...payload }));
          break;
        }
        case "error": {
          updateStatus(ConnectionType.OEMS, ConnectionStatus.ERROR);
          setOMSState((prevState) => ({ ...prevState, ...payload }));
          break;
        }
        case "init-status": {
          const { message, completed } = payload;
          updateStatus(ConnectionType.OEMS, ConnectionStatus.CONNECTED);
          if (completed) {
            getInitialMetadata(message);
          } else {
            setOMSState((prevState) => ({
              ...prevState,
              initializationStep: (prevState.initializationStep ?? 0) + 1,
              initializationMessage: message,
            }));
          }
          break;
        }
        case "blotter-sync": {
          const { rows, isInitialized } = payload;
          setOMSState((prevState) => ({ ...prevState, isInitialized }));

          triggerTransaction({
            add: rows,
            action: "blotter-sync",
          });
          break;
        }
        case "blotter-rows-update": {
          triggerTransaction({
            add: payload.add,
            update: payload.update,
            action: "blotter-rows-update",
          });

          break;
        }
        case "blotter-row-update": {
          const { newOrder, row } = payload;

          if (newOrder) {
            triggerTransaction({
              add: [row],
              action: "blotter-row-update",
            });
          } else {
            triggerTransaction({
              action: "blotter-row-update",
              update: [row],
            });
          }

          break;
        }
        case "order-delete": {
          const { rowToDelete } = payload;
          triggerTransaction({
            action: "order-delete",
            remove: [rowToDelete],
          });

          break;
        }
        case "close": {
          updateStatus(ConnectionType.OEMS, ConnectionStatus.DISCONNECTED);
          setOMSState((prevState) => ({
            ...prevState,
            ...initialOEMSContextState,
            ...pick(prevState, prevOverrideKeys),
          }));
          break;
        }
        case "terminated": {
          updateStatus(ConnectionType.OEMS, ConnectionStatus.DISCONNECTED);
          setOMSState((prevState) => ({
            ...prevState,
            ...initialOEMSContextState,
            ...pick(prevState, prevOverrideKeys),
          }));
          break;
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (enabled && isMounted()) {
      enableModule();
      getCurrentState()?.then(async ({ payload }) => {
        const { socketStatus, currentState, rows } = payload;

        if (socketStatus === WebSocket.OPEN)
          updateStatus(ConnectionType.OEMS, ConnectionStatus.CONNECTED);

        if (rows.length > 0) {
          getInitialMetadata("", currentState);
        } else {
          setOMSState((prevState) => ({
            ...prevState,
            ...currentState,
            ...pick(prevState, prevOverrideKeys),
          }));
        }
      });
    }

    return () => {
      disableModule();
    };
  }, [enabled]);

  const updateOrderColumnsState = useRefCallback(
    async (columnsState: Record<string, OrderColumnInfo[]>) => {
      try {
        if (!orderColumnsRef.current) orderColumnsRef.current = {};
        const keys = Object.keys(columnsState);
        const old = { ...orderColumnsRef.current };
        orderColumnsRef.current = {
          ...(orderColumnsRef.current || {}),
          ...columnsState,
        };
        if (!isEqual(pick(columnsState, keys), pick(old, keys)))
          await REST_API.OEMS.SAVE_ORDER_COLUMNS.FETCH(columnsState);
      } catch (error) {
        console.error(`Error while saving order column state:- ${error}`);
      }
    },
    [omsState.orderColumns]
  );

  const restartSocket = useRefCallback(async () => {
    await disableModule();
    await enableModule();
  }, [disableModule, enableModule]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).restartOMS = restartSocket;

  const value = React.useMemo(
    () => ({
      ...omsState,
      orderColumns: orderColumnsRef.current,
      selectedFilter,
      updateOrderColumnsState,
      setSelectedFilter,
      restartSocket,
      subscribe,
      setNewOrderTicketReady,
      openNewOrderTab,
    }),
    [
      omsState,
      selectedFilter,
      orderColumnsRef.current,
      updateOrderColumnsState,
      setSelectedFilter,
      restartSocket,
      subscribe,
      setNewOrderTicketReady,
      openNewOrderTab,
    ]
  );

  return <OEMSContext.Provider value={value}>{children}</OEMSContext.Provider>;
};

export default OEMSProvider;
