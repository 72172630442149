import InstrumentMultiSelect from "@app-components/inputs/InstrumentSelect/InstrumentMultiSelect";
import { ControlledAssetMeasureSelect } from "@app-views/reports/components/ReportQueryComponents/AssetMeasureSelect";
import { InstrumentInfo } from "@enfusion-ui/types";
import {
  ControlledInputBase,
  ControlledSelect,
  PanelRow,
} from "@enfusion-ui/web-components";
import * as React from "react";

import { DateSelection } from "../../../../reports/components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledQuoteSetIdsSelect } from "../../../../reports/components/ReportQueryComponents/QuoteSetIdsSelect";
import { ControlledSourceIdsSelect } from "../../../../reports/components/ReportQueryComponents/SourceIdsSelect";

const options = [
  { label: "Instrument", value: "Instrument" },
  { label: "CDS Spread Curve", value: "CDSSpreadCurve" },
  { label: "Interest Rate Curve", value: "IRCurve" },
  { label: "Term Volatility Curve", value: "TermVolatilityCurve" },
  { label: "Forward Curve", value: "ForwardCurve" },
  { label: "Bond", value: "Bond" },
];

const MarketRatesQuickFilter = () => {
  return (
    <PanelRow>
      <ControlledInputBase
        name="instrumentIds"
        render={({ ref: _ref, onChange, ...rest }) => (
          <InstrumentMultiSelect
            {...rest}
            label="Instrument(s)"
            onChange={(instruments: InstrumentInfo[] | null) => {
              onChange(instruments?.map((instrument) => instrument.id));
            }}
          />
        )}
      />
      <ControlledAssetMeasureSelect />

      <DateSelection
        name="fromDate"
        label="From Date"
        defaultValue={undefined}
      />
      <DateSelection name="toDate" label="To Date" defaultValue={undefined} />

      <ControlledSourceIdsSelect />

      <ControlledQuoteSetIdsSelect />

      <ControlledSelect name="mode" label="Mode" options={options} />
    </PanelRow>
  );
};

export default MarketRatesQuickFilter;
