import { useRefCallback } from "@enfusion-ui/hooks";
import { ReportExtensions } from "@enfusion-ui/reports";
import { DashboardRoot, WebReportQuery } from "@enfusion-ui/types";
import { TabDef, useReports, useTabs } from "@enfusion-ui/web-core";

import { useReportsExplorer } from "../../../context/reports/ReportsExplorerProvider";
import { REPORT_SETTINGS_COMPONENT_DEFINITIONS } from "../reportSettings/definitions";
import ReportSettingsModal from "../reportSettings/ReportSettingsModal";

export default function GenerateReportModal() {
  const { generateReportModalRoot, closeGenerateReportModal } =
    useReportsExplorer();

  const { tabs } = useTabs();
  const { openReportTab } = useReports();

  const getTabName = useRefCallback((name: string, tabDefs: Array<TabDef>) => {
    if (!tabDefs?.find((tab) => tab.name.startsWith(name))) {
      return `${name} - 1`;
    }
    const reportTabIndex = tabDefs.reduce((res: number, tabDef: TabDef) => {
      if (tabDef.name.startsWith(name)) {
        const reportNumber =
          tabDef.name.toString().split("-")?.length === 2
            ? tabDef.name.split("-")?.[1]?.trim()
            : "";
        if (parseInt(reportNumber) > res) {
          res = parseInt(reportNumber);
        }
      }
      return res;
    }, 1);
    return `${name} - ${reportTabIndex + 1}`;
  }, []);

  const onSubmit = useRefCallback(
    async (reportQuery: WebReportQuery) => {
      if (reportQuery.type) {
        const name =
          REPORT_SETTINGS_COMPONENT_DEFINITIONS[reportQuery.type].name;
        const extension = ReportExtensions[reportQuery.type] ?? "";
        const tabName = getTabName(`${name} Report`, tabs);
        openReportTab({
          name: `${tabName}.${extension}`,
          path: null,
          reportQuery,
          root: generateReportModalRoot as DashboardRoot,
        });
      }
    },
    [generateReportModalRoot]
  );

  return (
    <ReportSettingsModal
      open={!!generateReportModalRoot}
      onClose={closeGenerateReportModal}
      runReport={onSubmit}
    />
  );
}
