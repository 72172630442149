import { WebReportQuerySubType } from "@enfusion-ui/types";
import * as React from "react";

import { REPORT_SETTINGS_COMPONENT_DEFINITIONS } from "./definitions";

export type RenderContentType = "settingsComponent" | "quickFilterComponent";

export const ReportFormContent: React.FC<{
  type: WebReportQuerySubType;
  renderContentType: RenderContentType;
}> = ({ type, renderContentType }) => {
  const ContentComponent =
    REPORT_SETTINGS_COMPONENT_DEFINITIONS[type][renderContentType] ??
    (() => <></>);

  return <ContentComponent />;
};
