import {
  FinancialSubType,
  InstrumentInfo,
  InstrumentSearchResult,
} from "@enfusion-ui/types";

export type InstrumentOption = {
  label: string;
  value: number;
  instrument: InstrumentInfo | InstrumentSearchResult | null;
};

export type BaseInstrumentSelectProps = {
  label?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  searchOnlyPrimary?: boolean;
  required?: boolean;
};

export enum InstrumentSearchBy {
  MARKET_IDENTIFIER,
  DESCRIPTION,
}

export type InstrumentSelectProps = BaseInstrumentSelectProps & {
  value?: number | null;
  onChange: (instrument: InstrumentInfo | null) => void;
  defaultSearchBy?: InstrumentSearchBy;
  financialSubTypes?: FinancialSubType[];
  hideOptions?: boolean;
  showAdvancedSearch?: boolean;
  hideAdvancedSearchOnSelect?: boolean;
  hideAdvancedSearchButtonText?: boolean;
  inline?: boolean;
  dataE2EId?: string;
};

export type InstrumentMultiSelectProps = BaseInstrumentSelectProps & {
  value?: number[] | null;
  onChange: (instruments: InstrumentInfo[] | null) => void;
  enableAdvanced?: boolean;
  defaultSearchBy?: InstrumentSearchBy;
  financialSubTypes?: FinancialSubType[];
};
