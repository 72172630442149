import { styled } from "@enfusion-ui/web-core";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

import { MenuList, SettingsListContainer } from "../core/styledComponents";

const MenuToggle = styled.div.attrs({
  role: "button",
})`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-top: 1px solid var(--input-border);
`;

type DisplayMenuProps = {
  children?: React.ReactNode;
};

export const DisplayMenu: React.FC<DisplayMenuProps> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <MenuToggle
        onClick={() => setIsOpen((prev) => !prev)}
        id="dashboard-settings"
        data-e2e-id="menu-toggle-dashboard-settings"
      >
        <span>Dashboard Settings</span>
        <FontAwesomeIcon icon={isOpen ? faAngleDown : faAngleUp} />
      </MenuToggle>
      {isOpen && (
        <SettingsListContainer>
          <MenuList>{children}</MenuList>
        </SettingsListContainer>
      )}
    </>
  );
};

export default DisplayMenu;
