import { errorCatch } from "@app-utils/errorCatch";
import { TOAST_CONTENT } from "@enfusion-ui/core";
import { FolderActionReturn } from "@enfusion-ui/core/build/api/documents";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  AppEvent,
  AppEventCategories,
  DashboardRoot,
  NodeData,
} from "@enfusion-ui/types";
import {
  replaceDoubleSlashWithSingleSlash,
  trimSlash,
} from "@enfusion-ui/utils";
import {
  AppLogging,
  FolderActionContext,
  successToast,
} from "@enfusion-ui/web-core";
import * as React from "react";

import { useExplorerEvents } from "../explorerEvents/context";
import { ExplorerEventsAction } from "../explorerEvents/types";
import { ExplorerSection } from "../types";

export type ExplorerFolderProviderWrapperProps = React.PropsWithChildren<{
  root?: DashboardRoot;
}>;

export type ExplorerFolderProviderProps = {
  section: ExplorerSection;
  hasGlobal?: boolean;
  root?: DashboardRoot;
  getRoot: (node: string | NodeData) => Promise<NodeData[]>;
  copyEntry: (
    node: NodeData,
    targetRoot: DashboardRoot,
    targetPath: string
  ) => Promise<boolean>;
  deleteEntry: (targetPath: string) => Promise<FolderActionReturn>;
  moveEntry: (
    targetPath: string,
    targetDestination: string,
    forceWrite?: boolean
  ) => Promise<FolderActionReturn>;
  createFolder: (
    root: DashboardRoot,
    filePath: string
  ) => Promise<FolderActionReturn>;
  renameEntry: (
    filePath: string,
    newName: string
  ) => Promise<FolderActionReturn>;
};

export const getCopyFilePath = (nameAndExt: string, targetPath: string) => {
  const fullyQualifiedPath = replaceDoubleSlashWithSingleSlash(
    "/" + (targetPath.split("/").slice(1).join("/") ?? "/")
  );

  let folder = fullyQualifiedPath;
  if (fullyQualifiedPath.endsWith(nameAndExt)) {
    folder = folder.slice(0, 0 - nameAndExt.length);
  }

  return replaceDoubleSlashWithSingleSlash(`/${folder}/${nameAndExt}`);
};

export const ExplorerFolderProvider: React.FC<
  React.PropsWithChildren<ExplorerFolderProviderProps>
> = ({
  children,
  section,
  getRoot,
  createFolder,
  renameEntry,
  deleteEntry,
  copyEntry,
  moveEntry,
  hasGlobal = false,
  root = "user",
}) => {
  const explorerChannel = useExplorerEvents(section);
  const toastContent = React.useMemo(() => TOAST_CONTENT[section], [section]);
  const eventCategory = React.useMemo(
    () => AppEventCategories[section],
    [section]
  );

  const refetch = useRefCallback(
    (targetRoot: DashboardRoot) => {
      explorerChannel.broadcast(targetRoot, ExplorerEventsAction.Refetch);
    },
    [section, explorerChannel]
  );

  const executeCreateFolder = useRefCallback(
    async (
      folderName: string,
      folderPath: string,
      rootOverride?: DashboardRoot
    ) => {
      errorCatch(async () => {
        const selectedRoot = rootOverride || root || "user";
        const path = folderPath + folderName;

        const res = await createFolder(selectedRoot, path);
        if (!res.success) return false;

        successToast(TOAST_CONTENT.folder.create.success);
        refetch(selectedRoot);
        AppLogging.event(
          { event: AppEvent.CreateFile, category: eventCategory },
          { path, file: false }
        );
      }, TOAST_CONTENT.folder.create.failure);
    },
    [createFolder, root, eventCategory]
  );

  const executeRenameFile = useRefCallback(
    async (path: string, newName: string, isFolder?: boolean) => {
      const toastEntry = isFolder ? TOAST_CONTENT.folder : toastContent;
      errorCatch(async () => {
        const res = await renameEntry(
          path.startsWith(root) ? path : `${root}/${path}`,
          newName.replace(/(\/|\\)/g, "")
        );
        if (!res.success) return false;

        successToast(toastEntry.rename.success);
        refetch(root);
        AppLogging.event(
          { event: AppEvent.RenameFile, category: eventCategory },
          { path, file: !isFolder }
        );
      }, toastEntry.rename.failure);
    },
    [renameEntry, root]
  );

  const retrieveNodes = useRefCallback(
    async (node: string | NodeData) => {
      if (node !== "all/") return getRoot(node);

      const nodes: NodeData[] = [];
      // filtering roots based on permission
      const roots: DashboardRoot[] = ["user", "shared"];
      if (hasGlobal) roots.push("global");

      for (const listRoot of roots) {
        nodes.push({
          id: listRoot,
          name: listRoot,
          path: replaceDoubleSlashWithSingleSlash(listRoot.toLowerCase() + "/"),
          file: false,
          nodes: await getRoot(listRoot),
          defaultOpen: false,
          root: listRoot,
        });
      }
      return nodes;
    },
    [getRoot, hasGlobal]
  );

  const executeCopyFile = useRefCallback(
    async (node: NodeData, targetRoot: DashboardRoot, targetPath: string) => {
      return await errorCatch(async () => {
        const success = await copyEntry(node, targetRoot, targetPath);
        if (!success) return false;

        successToast(toastContent.copy.success);
        if (root !== targetRoot) refetch(root);
        refetch(targetRoot);
        AppLogging.event(
          { event: AppEvent.CopyFile, category: eventCategory },
          {
            targetPath,
            targetRoot,
            sourceRoot: root,
            path: node.path,
          }
        );
      }, toastContent.copy.failure);
    },
    [copyEntry, root, toastContent, eventCategory]
  );

  const executeDeleteFile = useRefCallback(
    async (node: NodeData) => {
      const toastEntry = !node.file ? TOAST_CONTENT.folder : toastContent;
      errorCatch(async () => {
        const path = node.path;
        const res = await deleteEntry(path);
        if (!res.success) return false;

        successToast(toastEntry.delete.success);
        refetch(root);
        AppLogging.event(
          { event: AppEvent.DeleteFile, category: eventCategory },
          { path, file: !!node.file }
        );
      }, toastEntry.delete.failure);
    },
    [deleteEntry, root, toastContent, eventCategory]
  );

  const executeMoveFile = useRefCallback(
    async (node: NodeData, targetPath: string) => {
      errorCatch(async () => {
        const res = await moveEntry(node.path, targetPath);
        if (!res.success) return false;

        const destRoot = trimSlash(targetPath).split("/")[0] as DashboardRoot;

        successToast(toastContent.move.success);
        if (root !== destRoot) refetch(root);
        refetch(destRoot);
        AppLogging.event(
          { event: AppEvent.MoveFile, category: eventCategory },
          {
            targetPath,
            targetRoot: destRoot,
            sourceRoot: root,
            path: node.path,
          }
        );
      }, toastContent.move.failure);
    },
    [moveEntry, root, toastContent, eventCategory]
  );

  return (
    <FolderActionContext.Provider
      value={{
        createFolder: executeCreateFolder,
        renameFile: executeRenameFile,
        copyFile: executeCopyFile,
        deleteFile: executeDeleteFile,
        moveFile: executeMoveFile,
        retrieveNodes,
      }}
    >
      {children}
    </FolderActionContext.Provider>
  );
};
