import { useAllocations } from "@app-context/allocation/context";
import { EMPTY_ARRAY } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { NodeData, WebReportBookSelection } from "@enfusion-ui/types";
import {
  Checkbox,
  ControlledInputBase,
  HierarchySelect,
} from "@enfusion-ui/web-components";
import { reduceSelectedNodes, styled } from "@enfusion-ui/web-core";
import { orderBy } from "lodash";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export const InputContainer = styled.div`
  min-width: 250px;
`;

const CheckBoxContainer = styled.div`
  margin-top: 1%;
`;

export type BookSelectorProps = {
  values?: NodeData[];
  selectedIds?: string[];
  onChange: (values: NodeData[] | null) => void;
  label?: string;
};

function findBooks(data: NodeData) {
  const books = (data.nodes as NodeData[])?.reduce<NodeData[]>((res, data) => {
    if (data.level === "Book") res.push(data);
    else if (data.level === "Hierarchy")
      res = [...res, ...(findBooks(data) ?? [])];
    return res;
  }, [] as NodeData[]);
  return books;
}

function determinePlaceholder(nodes: Array<NodeData>, filterDepthZero = true) {
  const placeholderList = reduceSelectedNodes(nodes ?? []);
  if (placeholderList?.some((node: NodeData) => node.depth === 0)) {
    return "All";
  }
  return orderBy(
    placeholderList.filter((i) => (filterDepthZero ? i.depth !== 0 : true)),
    ["depth"],
    ["asc"]
  )
    .map((i) => i.name)
    .join(", ");
}

const BookSelector: React.FC<BookSelectorProps> = ({
  onChange,
  label,
  selectedIds = EMPTY_ARRAY,
}) => {
  const { books: nodes } = useAllocations();

  const textFormatter = React.useCallback((nodes?: NodeData[] | null) => {
    return nodes ? determinePlaceholder(nodes) : "";
  }, []);

  return (
    <>
      <HierarchySelect
        defaultValues={selectedIds}
        onChange={onChange}
        autoFocus={false}
        textFormatter={textFormatter}
        label={label}
        options={nodes}
        clearable
      />
    </>
  );
};

const ControlledBookSelect: React.FC<{
  name?: string;
  defaultBookSelection?: WebReportBookSelection;
  showIgnoreHierarchyCheckBox?: boolean;
}> = ({
  name = "bookSelection",
  defaultBookSelection,
  showIgnoreHierarchyCheckBox = false,
}) => {
  const { setValue } = useFormContext();
  const bookSelection = useWatch<WebReportBookSelection>({
    name,
  });

  const [ignoreBookSelection, setIgnoreBookSelection] = React.useState<boolean>(
    bookSelection?.ignoreBookSelection ??
      defaultBookSelection?.ignoreBookSelection ??
      false
  );

  const selectedIds = React.useMemo(
    () => defaultBookSelection?.bookIds?.map((i: number) => `${i}`),
    [defaultBookSelection]
  );

  React.useEffect(() => {
    if (bookSelection) {
      setValue(name, {
        ...bookSelection,
        ignoreBookSelection: ignoreBookSelection,
      });
    } else {
      setValue(name, {
        bookIds: [],
        ignoreBookSelection: ignoreBookSelection,
      });
    }
  }, [ignoreBookSelection]);

  const handleOnChange = useRefCallback(
    (nodeData: NodeData[] | null) => {
      const bookIds = (
        reduceSelectedNodes(nodeData ?? []) as NodeData[]
      )?.reduce<number[]>((res, data) => {
        if (data.level === "Hierarchy") {
          res = [
            ...res,
            ...(findBooks(data)?.map((i) => Number(i.bookId)) ?? []),
          ];
        } else if (data.level === "Book") {
          res.push(Number(data.bookId));
        }
        return res;
      }, []);

      setValue(
        name,
        {
          ...defaultBookSelection,
          bookIds: [...new Set(bookIds)],
          ignoreBookSelection: ignoreBookSelection,
        },
        { shouldDirty: true }
      );
    },
    [defaultBookSelection]
  );

  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...rest }) => (
        <>
          <InputContainer>
            <BookSelector
              {...rest}
              label="Hierarchy"
              selectedIds={selectedIds}
              onChange={handleOnChange}
            />
          </InputContainer>
          {showIgnoreHierarchyCheckBox && (
            <CheckBoxContainer>
              <Checkbox
                label="Ignore Hierarchy In Lot Relief"
                labelPlacement="right"
                style={{ alignSelf: "flex-end" }}
                checked={ignoreBookSelection}
                onChange={() => {
                  setIgnoreBookSelection(!ignoreBookSelection);
                }}
              />
            </CheckBoxContainer>
          )}
        </>
      )}
    />
  );
};

export default ControlledBookSelect;
