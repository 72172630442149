import { useMounted } from "@enfusion-ui/hooks";
import { CurrencyInfo, SelectOptionsType } from "@enfusion-ui/types";
import {
  ControlledSelect,
  ControlledSelectWrapperProps,
} from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

export type CurrencyOptions = SelectOptionsType<number | null>;

function CurrencySelect<IsMulti extends boolean>({
  name = "currencyId",
  label = "Currency",
  isClearable = true,
  nullOptionLabel = "None",
  showNullOption = false,
  ...props
}: Omit<
  ControlledSelectWrapperProps<number | null, IsMulti>,
  "options" | "isLoading" | "name"
> & {
  controllerStyle?: React.CSSProperties;
  name?: string;
  nullOptionLabel?: string;
  showNullOption?: boolean;
}) {
  const [currencyOptions, setCurrencyOptions] =
    React.useState<CurrencyOptions[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const isMounted = useMounted();

  React.useEffect(() => {
    const getAllCurrencies = async () => {
      try {
        if (isMounted()) setIsLoading(true);
        const currencies =
          await REST_API.INSTRUMENT.GET_SETTLEMENT_CURRENCIES.FETCH();

        let currOptions: CurrencyOptions[] = currencies.map(
          (eachCurrency: CurrencyInfo) => {
            return { value: eachCurrency.id, label: eachCurrency.code };
          }
        );

        if (showNullOption)
          currOptions = [
            { label: nullOptionLabel, value: null },
            ...currOptions,
          ];

        if (isMounted()) setCurrencyOptions(currOptions);
      } catch (err) {
        console.error(err);
      } finally {
        if (isMounted()) setIsLoading(false);
      }
    };

    getAllCurrencies();
  }, []);

  return (
    <ControlledSelect
      {...props}
      name={name}
      label={label}
      options={currencyOptions}
      isLoading={isLoading}
      isClearable={isClearable}
    />
  );
}

export default CurrencySelect;
