import { usePortfolio } from "@app-context/portfolios/PortfolioProvider";
import { SingleRowContainer } from "@app-views/dashboards/widgets/styles";
import ControlledAccountSelect from "@app-views/reports/components/ReportQueryComponents/AccountSelect";
import { PortfolioSettingsV1 } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  defaultPortfolioSettingsValueV1,
  DENOMINATOR_OPTIONS,
  INCLUDE_ACTIVE_ORDERS_OPTIONS,
  MODEL_BM_MAPPING_OPTIONS,
  NUMERATOR_AGGREGATION_OPTIONS,
  REBALANCE_CALCULATION_LEVEL_OPTIONS,
  REBALANCE_DURATION_OPTIONS,
  ROUND_TO_LOT_SIZE_INFO_TEXT,
} from "@enfusion-ui/portfolios";
import {
  Button,
  ControlledButtonGroupSelect,
  ControlledCheckbox,
  ControlledEmpty,
  ControlledNumericInput,
  ControlledSelect,
  FormSectionAccordion,
  Modal,
  useConfirmationModal,
  ViewContainer,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { omit } from "lodash";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

type FormValues = PortfolioSettingsV1 & {
  fundIds: { fundIds: number[] } | null;
  renderKey: string;
};

type SettingsModalProps = {
  isOpen: boolean;
  closeModal: VoidFunction;
};

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-xl);
  min-height: 32px;
`;

const SettingsModal: React.FC<SettingsModalProps> = ({
  isOpen,
  closeModal,
}) => {
  const {
    hasProposedOrders,
    settings,
    oldSettings,
    fundIds: defaultFundIds,
    changeFundsAndSettings,
  } = usePortfolio();

  const defaultValues = React.useMemo(
    () => ({
      ...defaultPortfolioSettingsValueV1,
      ...settings,
      fundIds: { fundIds: defaultFundIds },
      renderKey: new Date().toISOString(),
    }),
    [settings, defaultFundIds]
  );

  const formMethods = useForm<FormValues>({
    defaultValues,
    mode: "onTouched",
  });

  const { watch, formState, reset, getValues, setValue } = formMethods;
  const { renderKey } = watch(["renderKey"]);

  React.useEffect(() => {
    if (isOpen) reset(defaultValues);
  }, [isOpen]);

  const submitForm = useRefCallback(() => {
    const { fundIds, ...formValues } = getValues();
    changeFundsAndSettings(
      omit(formValues, "renderKey"),
      fundIds?.fundIds ?? []
    );
    closeModal();
  }, [changeFundsAndSettings, closeModal]);

  /* Revert to the last saved settings (state.oldSettings) */
  const handleRevertToDefault = useRefCallback(() => {
    (Object.keys(oldSettings) as (keyof PortfolioSettingsV1)[]).forEach((key) =>
      setValue(key, oldSettings[key], { shouldDirty: true })
    );
  }, [oldSettings]);

  const ProposedOrdersConfirmation = useConfirmationModal({
    renderContent: () =>
      "Please confirm that this change will affect proposed orders.",
    onSubmit: submitForm,
    onCancel: closeModal,
    submitButtonText: "Confirm",
    submitActionTheme: "primary",
    dataE2EId: "proposed-order-model",
  });

  const UpdateFundsConfirmationModal = useConfirmationModal({
    onSubmit: submitForm,
    onCancel: closeModal,
    renderContent: () => {
      return "Please confirm that changing funds will cause all adjustments to be lost";
    },
    submitButtonText: "Confirm",
    submitActionTheme: "primary",
    dataE2EId: "funds-updated-confirmation",
  });

  const handleApplyChanges = useRefCallback(() => {
    if (formState.isDirty && formState.dirtyFields.fundIds) {
      UpdateFundsConfirmationModal.openModal();
    } else if (hasProposedOrders) {
      ProposedOrdersConfirmation.openModal();
    } else {
      submitForm();
    }
  }, [formState, hasProposedOrders, submitForm]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        title="Settings"
        onClose={closeModal}
        size="medium"
        content={
          <FormProvider {...formMethods}>
            <ViewContainer>
              <ControlledEmpty name="renderKey" />
              <ControlledAccountSelect
                name="fundIds"
                label="Funds"
                key={renderKey}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                defaultAccountSelection={{ fundIds: defaultFundIds } as any}
                performDirtyCheck
              />

              <SingleRowContainer>
                <ControlledSelect
                  mobileBasis="100%"
                  name="numeratorAggregation"
                  label="Numerator Aggregation"
                  options={NUMERATOR_AGGREGATION_OPTIONS}
                  inputId="numeratorAggregation"
                />
                <ControlledSelect
                  name="includeActiveOrders"
                  label="Include Active Orders"
                  mobileBasis="100%"
                  options={INCLUDE_ACTIVE_ORDERS_OPTIONS}
                  inputId="includeActiveOrders"
                />
                {/* <ControlledCheckbox
                  name="constituentDisplay"
                  style={{ marginTop: "24px" }}
                  labelPlacement="right"
                  label="Constituent Display"
                  infoText={CONSTITUENT_DISPLAY_INFO_TEXT}
                /> */}
              </SingleRowContainer>
              <SingleRowContainer>
                <ControlledSelect
                  name="denominator"
                  label="Denominator"
                  mobileBasis="100%"
                  options={DENOMINATOR_OPTIONS}
                  inputId="denominator"
                />
                <ControlledSelect
                  name="rebalanceCalculationLevel"
                  label="Calculation Level"
                  mobileBasis="100%"
                  options={REBALANCE_CALCULATION_LEVEL_OPTIONS}
                  inputId="rebalanceCalculationLevel"
                />
              </SingleRowContainer>
              <SingleRowContainer>
                <ControlledCheckbox
                  name="roundToLotSize"
                  style={{ marginTop: "24px" }}
                  labelPlacement="right"
                  label="Round To Lot Size"
                  infoText={ROUND_TO_LOT_SIZE_INFO_TEXT}
                />

                <ControlledButtonGroupSelect
                  name="resolveBenchmarksByBook"
                  title="Model/BM Mapping Level"
                  options={MODEL_BM_MAPPING_OPTIONS}
                  defaultValue={false}
                />
                {/* <ControlledSelect
                  name="lotSizeType"
                  label="Lot Size"
                  mobileBasis="100%"
                  options={lotSizeOptions}
                /> */}
                {/* {lotSizeType === "Custom" ? (
                  <ControlledNumericInput
                    name="lotSize"
                    bigControls
                    defaultValue={1}
                    onChange={() => {}}
                    mobileBasis="100%"
                    min={1}
                  />
                ) : undefined} */}
              </SingleRowContainer>
              <SingleRowContainer>
                <ControlledSelect
                  name="rebalancingDurationType"
                  label="Duration Type"
                  mobileBasis="100%"
                  options={REBALANCE_DURATION_OPTIONS}
                />
              </SingleRowContainer>
              {/* <SingleRowContainer>
                <ControlledCheckbox
                  name="normalize"
                  labelPlacement="right"
                  label="Normalize"
                  infoText={NORMALIZE_INFO_TEXT}
                />
                <ControlledCheckbox
                  name="rollUp"
                  labelPlacement="right"
                  label="Roll Up"
                />
              </SingleRowContainer> */}
              <FormSectionAccordion title="De Minimis Thresholds" defaultOpen>
                <SingleRowContainer>
                  <ControlledNumericInput
                    name="quantity"
                    label="Quantity:"
                    mobileBasis="100%"
                    dataTestId="quantity"
                  />
                  <ControlledNumericInput
                    name="exposure"
                    label="Exposure:"
                    mobileBasis="100%"
                    dataTestId="exposure"
                  />
                </SingleRowContainer>

                <SingleRowContainer style={{ marginBottom: 0 }}>
                  <ControlledNumericInput
                    name="perIncremental"
                    mobileBasis="100%"
                    label="% Incremental:"
                    dataTestId="perIncremental"
                  />
                  {/* <ControlledNumericInput
                    name="perDiff"
                    label="% Diff:"
                    mobileBasis="100%"
                  /> */}
                </SingleRowContainer>
              </FormSectionAccordion>
            </ViewContainer>
          </FormProvider>
        }
        footerContent={
          <FooterContainer>
            <Button
              data-e2e-id="settings-apply-btn"
              disabled={!formState.isDirty}
              primary
              onClick={handleApplyChanges}
              data-testid="btn-apply"
            >
              Apply Changes
            </Button>

            <Button
              primary
              outlined
              onClick={handleRevertToDefault}
              data-testid="btn-revert"
            >
              Revert to Default
            </Button>

            <Button onClick={closeModal}>Cancel</Button>
          </FooterContainer>
        }
      />

      {ProposedOrdersConfirmation.content}
      {UpdateFundsConfirmationModal.content}
    </>
  );
};

export default SettingsModal;
