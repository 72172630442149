export const ReportExtensions = {
    WebPositionCashFlowQuery: "cfr",
    WebInstrumentQuery: "irb",
    WebGLCashFlowStatementQuery: "cfs",
    WebGLPositionQuery: "ppy",
    WebCustodianAccountQuery: "acr",
    WebQuotesQuery: "mdq",
    WebPositionReportQuery: "ppr",
    WebOrderBlotterQuery: "orb",
    WebGLDistributionLineQuery: "gld",
    WebTradeValuationReportQuery: "par",
    WebTradeBlotterQuery: "trb",
    WebGLBalanceSheetQuery: "bsr",
    WebGLCashBalancesQuery: "cbx",
    WebGLIncomeStatementQuery: "isr",
    WebGLTrialBalancesQuery: "tbr",
    WebGLCashActivityQuery: "car",
    WebPositionEventReportQuery: "per",
    WebCounterpartyCommissionQuery: "ccr",
    WebBookReportQuery: "bkr",
    WebInvestmentReportQuery: "ivr",
    WebComplianceBlotterQuery: "cmb",
    WebCorporateActionReportQuery: "crp",
};
