import { ThemeDefinition } from "@enfusion-ui/core";
import { ProgressStepsType } from "@enfusion-ui/types";
import { withTheme } from "@enfusion-ui/web-core";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { Path, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import * as React from "react";

import { pxToPtConversion } from "./utils";

const pdfStyles = StyleSheet.create({
  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  stepContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    // border: "1px solid red",
  },
  iconContainer: {
    width: "11.7%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  nameContainer: {
    width: "82.3%",
    fontFamily: "Lato",
    fontSize: pxToPtConversion(16),
    // justifyContent and alignItems are reversed
    justifyContent: "center",
  },
  spinnerContainer: {
    width: "5.8%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

const PdfReportLoadingStatus = withTheme(
  ({ steps, theme }: { steps?: ProgressStepsType; theme: ThemeDefinition }) => {
    return (
      <View style={pdfStyles.centerContent}>
        {steps?.map((step, idx) => {
          let icon, iconColor;

          if (step.status === "Completed") {
            icon = faCheck;
            iconColor = theme.colors.success;
          } else {
            icon = faSquare;
            iconColor = theme.colors.textNormal;
          }

          return (
            <View key={idx} style={pdfStyles.stepContainer}>
              <View style={[pdfStyles.iconContainer]}>
                <Svg
                  width={pxToPtConversion(16)}
                  height={pxToPtConversion(16)}
                  viewBox={`0 0 ${icon.icon[0]} ${icon.icon[1]}`}
                >
                  <Path
                    d={icon.icon[4] as string}
                    stroke={iconColor}
                    fill={iconColor}
                  />
                </Svg>
              </View>
              <View
                style={[
                  pdfStyles.nameContainer,
                  {
                    color: theme.colors.textNormal,
                    fontWeight:
                      step.status === "InProgress" ? "bold" : "normal",
                  },
                ]}
              >
                <Text>{step.stepName}</Text>
              </View>
              {step.status === "InProgress" ||
                (true && (
                  <View style={[pdfStyles.spinnerContainer]}>
                    <Svg
                      width={pxToPtConversion(16)}
                      height={pxToPtConversion(16)}
                      viewBox={`0 0 ${faSpinner.icon[0]} ${faSpinner.icon[1]}`}
                    >
                      <Path
                        d={faSpinner.icon[4] as string}
                        stroke="#4f545c"
                        fill="#4f545c"
                      />
                    </Svg>
                  </View>
                ))}
            </View>
          );
        })}
      </View>
    );
  }
);

export default PdfReportLoadingStatus;
