import {
  Button,
  ControlledTextInput,
  Modal,
  SingleColumnGridWithGap,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

export type WatchlistModalPropType = {
  open: boolean;
  name: string;
  onClose: VoidFunction;
  onSubmit: (newName: string) => void;
};

const ModalContentContainer = styled.div`
  margin-top: 1rem;
`;

const WatchlistRenameModal: React.FC<WatchlistModalPropType> = ({
  open,
  onClose,
  name,
  onSubmit,
}) => {
  const formMethods = useForm<{ newName: string }>();

  const renameFile: SubmitHandler<{ newName: string }> = ({ newName }) => {
    onSubmit(newName);
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title="Rename Watchlist"
      allowDismissal
      content={
        <FormProvider {...formMethods}>
          <ModalContentContainer>
            <SingleColumnGridWithGap
              as="form"
              onSubmit={formMethods.handleSubmit(renameFile)}
            >
              <ControlledTextInput
                label="Watchlist Name"
                name="newName"
                defaultValue={name}
                autoFocus
                rules={{
                  required: "Name is required",
                }}
              />
              <Button primary type="submit">
                Submit
              </Button>
            </SingleColumnGridWithGap>
          </ModalContentContainer>
        </FormProvider>
      }
    />
  );
};

export default WatchlistRenameModal;
