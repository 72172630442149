import { useRefCallback } from "@enfusion-ui/hooks";
import {
  DateInputType,
  DateRangeSymbol,
  DateSelectionType,
  DateValue,
  DateWidgetInputResult,
  WebDatePeriodSelection,
  WebDateRangeSelection,
  WebDateRangeSymbolSelection,
  WebDateSelection,
} from "@enfusion-ui/types";
import {
  generalDateFormat,
  getDateRangeSymbol,
  getDateSelectionValue,
  getEvalDate,
  getPeriodDateSelectionValue,
  getPresetValue,
  isValidDate,
} from "@enfusion-ui/utils";
import {
  DatePicker as DatePickerInput,
  DatePresetInput,
  DateRangePicker,
} from "@enfusion-ui/web-components";
import { useAuth, useDimensions } from "@enfusion-ui/web-core";
import { format, parse } from "date-fns";
import * as React from "react";

import DateRangeSymbolSelectionInput from "../../../../reports/components/ReportQueryComponents/Date/DateRangeSymbolSelectionInput";
import { DatePeriodSelectionInput } from "../../../../reports/components/ReportQueryComponents/Date/DateSelectionInput";

type DateWidgetInputProps = {
  label: string;
  defaultValue: DateValue;
  inputType: DateInputType;
  onChange: (value: DateWidgetInputResult) => void;
  before?: Date | null;
  after?: Date | null;
  noLatest?: boolean;
};

const DateWidgetInput: React.FC<DateWidgetInputProps> = ({
  label,
  defaultValue,
  inputType,
  onChange,
  before,
  after,
  noLatest,
}) => {
  const { user } = useAuth();
  const { width } = useDimensions();
  const [periodValue, setPeriodValue] = React.useState<WebDatePeriodSelection>(
    () => getPeriodDateSelectionValue(defaultValue, inputType)
  );
  const [symbol, setSymbol] = React.useState<DateRangeSymbol | undefined>(() =>
    getDateRangeSymbol(defaultValue, inputType)
  );
  const [startValue, setStartValue] = React.useState<WebDateSelection | null>(
    () => getDateSelectionValue(defaultValue, inputType, "start")
  );
  const [endValue, setEndValue] = React.useState<WebDateSelection | null>(() =>
    getDateSelectionValue(defaultValue, inputType, "end")
  );

  const timeZone = user?.flags?.timeZone || "America/Chicago";

  React.useEffect(() => {
    const start = startValue ? { ...startValue } : null;
    const end = endValue ? { ...endValue } : null;
    const noLatestDate = start
      ? {
          ...start,
          dateSelectionType:
            start.dateSelectionType === "Latest"
              ? "Today"
              : start.dateSelectionType,
        }
      : null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const options: any[] = [
      getEvalDate(timeZone, startValue),
      getEvalDate(timeZone, endValue),
      start,
      end,
      { startDate: start, endDate: end },
      noLatestDate,
    ];
    if (inputType === "symbol-range") {
      options.push({
        symbol,
        fromDate: startValue
          ? format(
              getEvalDate(timeZone, startValue) ?? new Date(),
              generalDateFormat
            )
          : undefined,
        toDate: endValue
          ? format(
              getEvalDate(timeZone, endValue) ?? new Date(),
              generalDateFormat
            )
          : undefined,
      });
    }
    if (inputType === "period") {
      options.push({ ...periodValue });
    }
    onChange(options as DateWidgetInputResult);
  }, [
    JSON.stringify(periodValue),
    symbol,
    JSON.stringify(startValue),
    JSON.stringify(endValue),
    timeZone,
    onChange,
  ]);

  React.useEffect(() => {
    setPeriodValue(getPeriodDateSelectionValue(defaultValue, inputType));
    setSymbol(getDateRangeSymbol(defaultValue, inputType));
    setStartValue(getDateSelectionValue(defaultValue, inputType, "start"));
    setEndValue(getDateSelectionValue(defaultValue, inputType, "end"));
  }, [JSON.stringify(defaultValue), inputType]);

  const handleSimpleChange = useRefCallback(
    (selected?: Date | WebDatePeriodSelection | null) => {
      setPeriodValue(getPeriodDateSelectionValue(selected, inputType));
      setSymbol(getDateRangeSymbol(selected, inputType));
      setStartValue(getDateSelectionValue(selected, inputType, "start"));
      setEndValue(getDateSelectionValue(selected, inputType, "end"));
    },
    [inputType]
  );

  const handlePresetChange = useRefCallback(
    (selected: string | Date | null) => {
      const newValue: WebDateSelection =
        selected instanceof Date
          ? {
              dateSelectionType: "AsOfDate",
              asOfDate: format(
                isValidDate(selected) ? selected : new Date(),
                generalDateFormat
              ),
            }
          : {
              dateSelectionType: selected as DateSelectionType,
              asOfDate: null,
            };
      setPeriodValue(getPeriodDateSelectionValue(newValue, inputType));
      setSymbol(getDateRangeSymbol(newValue, inputType));
      setStartValue(getDateSelectionValue(newValue, inputType, "start"));
      setEndValue(getDateSelectionValue(newValue, inputType, "end"));
    },
    [inputType]
  );

  const handleRangeChange = useRefCallback(
    (value: [Date | null, Date | null]) => {
      const startValue: WebDateSelection = {
        dateSelectionType: "AsOfDate",
        asOfDate: isValidDate(value[0])
          ? format(value[0], generalDateFormat)
          : null,
      };
      const endValue: WebDateSelection = {
        dateSelectionType: "AsOfDate",
        asOfDate: isValidDate(value[1])
          ? format(value[1], generalDateFormat)
          : null,
      };
      const rangeValue: WebDateRangeSelection = {
        startDate: startValue,
        endDate: endValue,
      };
      setPeriodValue(getPeriodDateSelectionValue(startValue, inputType));
      setSymbol(getDateRangeSymbol(startValue, inputType));
      setStartValue(getDateSelectionValue(rangeValue, inputType, "start"));
      setEndValue(getDateSelectionValue(rangeValue, inputType, "end"));
    },
    [inputType]
  );

  const handleSymbolRangeChange = useRefCallback(
    (value?: WebDateRangeSymbolSelection | null) => {
      const newStartValue = value?.fromDate
        ? parse(value.fromDate, generalDateFormat, new Date())
        : null;

      const rangeValue: WebDateRangeSymbolSelection = {
        fromDate: value?.fromDate,
        toDate: value?.toDate,
      };

      setPeriodValue(getPeriodDateSelectionValue(newStartValue, inputType));
      setSymbol(getDateRangeSymbol(value, inputType));
      setStartValue(getDateSelectionValue(rangeValue, inputType, "start"));
      setEndValue(getDateSelectionValue(rangeValue, inputType, "end"));
    },
    [inputType]
  );

  const symbolRange = React.useMemo(() => {
    const startDate = startValue ? getEvalDate(timeZone, startValue) : null;
    const endDate = endValue ? getEvalDate(timeZone, endValue) : null;
    return {
      symbol,
      fromDate: startDate ? format(startDate, generalDateFormat) : undefined,
      toDate: endDate ? format(endDate, generalDateFormat) : undefined,
    };
  }, [timeZone, symbol, JSON.stringify(startValue), JSON.stringify(endValue)]);

  return (
    <>
      {inputType === "date" && (
        <DatePickerInput
          label={label}
          value={getEvalDate(timeZone, startValue)}
          onChange={handleSimpleChange}
          clearable
          maxDate={before}
          minDate={after}
        />
      )}
      {inputType === "preset" && (
        <DatePresetInput
          label={label}
          value={getPresetValue(startValue)}
          onChange={handlePresetChange}
          clearable
          timezone={timeZone}
          optionSet={noLatest ? "value" : "all"}
          before={before}
          after={after}
        />
      )}
      {inputType === "period" && (
        <DatePeriodSelectionInput
          label={label}
          value={periodValue}
          onChange={handleSimpleChange}
          clearable
          timezone={timeZone}
          before={before}
          after={after}
          optionSet={noLatest ? "value" : "all"}
        />
      )}
      {inputType === "range" && (
        <DateRangePicker
          startLabel={label}
          startName={`${label} - Start Range Date`}
          endName={`${label} - End Range Date`}
          defaultValue={[
            getEvalDate(timeZone, startValue),
            getEvalDate(timeZone, endValue),
          ]}
          onChange={handleRangeChange}
          // 20: Separator's width + side padding
          minWidth={`${width ? width / 2 - 20 : 200}px`}
        />
      )}
      {inputType === "symbol-range" && (
        <DateRangeSymbolSelectionInput
          label={label}
          value={symbolRange}
          onChange={handleSymbolRangeChange}
          timezone={timeZone}
          clearable
        />
      )}
    </>
  );
};

export default DateWidgetInput;
