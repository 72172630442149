import { WidgetComponentsDefinition } from "../../../types";
import GenericInputSelectionEditWidget from "./GenericInputSelectionEditWidget";
import GenericInputSelectionWidget from "./GenericInputSelectionWidget";
import PdfGenericInputSelectionWidget from "./PdfGenericInputSelectionWidget";

export const genericInputSelection: WidgetComponentsDefinition = {
  renderComponent: GenericInputSelectionWidget,
  editComponent: GenericInputSelectionEditWidget,
  renderPdfComponent: PdfGenericInputSelectionWidget,
};
