import { SelectOptionsType } from "@enfusion-ui/types";
import * as React from "react";

import { FeeCommissionControl } from "./FeeCommissionControl";

// TODO get real values form the api
// There are way more options in the desktop ui
const FEE_OPTIONS: SelectOptionsType<string>[] = [
  {
    label: "P&L Unrlzd and Rlzd Adjustment",
    value: "PNLUnrlzdAndRlzdAdjustment",
  },
  {
    label: "P&L Realized Adjustment",
    value: "PNLRealizedAdjustment",
  },
  {
    label: "Accrued",
    value: "Accrued",
  },
  {
    label: "Accrued (Auto Calculate)",
    value: "AccruedAutoCalculate",
  },
  {
    label: "Accrued Dividends Adjustment",
    value: "AccruedDividendsAdjustment",
  },
  {
    label: "Accrued Dividends W/H Tax Adjustment",
    value: "AccruedDividendsTaxAdjustment",
  },
];

function isAutoCalculated(val: string) {
  return val.endsWith("AutoCalculate");
}

// Keep as its own component as in the future should be pulling typeOptions form the api
export const Fees: React.FC<unknown> = () => {
  return (
    <FeeCommissionControl
      label="Fee(s) / Taxes"
      name="fees"
      typeOptions={FEE_OPTIONS}
      isAutoCalculated={isAutoCalculated}
    />
  );
};
