import ControlledAccountSelect from "@app-components/inputs/account/ControlledAccountSelect";
import * as React from "react";
import { useWatch } from "react-hook-form";

import { InputWrapper } from "../../../../dashboards/widgets/styles";
import ControlledBookSelect from "../../../../reports/components/ReportQueryComponents/BookSelect";
import { DatePeriodSelection } from "../../../../reports/components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledMarketEnvironmentSelect } from "../../../../reports/components/ReportQueryComponents/MarketEnvironmentSelect";
import { ControlledPortfolioGroupsSelect } from "../../../../reports/components/ReportQueryComponents/PortfolioGroupsSelect";

const PositionQuickFilter = () => {
  const { bookSelection } = useWatch({
    name: ["bookSelection"],
  });
  const bookSelectionRef = React.useRef(bookSelection);
  return (
    <>
      <InputWrapper>
        <ControlledAccountSelect label="Account(s)" />
      </InputWrapper>
      <InputWrapper>
        <ControlledPortfolioGroupsSelect />
      </InputWrapper>
      <InputWrapper>
        <ControlledBookSelect defaultBookSelection={bookSelectionRef.current} />
      </InputWrapper>
      <InputWrapper>
        <DatePeriodSelection name="datePeriodSelection" label="Value Date" />
      </InputWrapper>
      <InputWrapper>
        <ControlledMarketEnvironmentSelect />
      </InputWrapper>
    </>
  );
};

export default PositionQuickFilter;
