import { RoundedDataGrid } from "@app-components/DataGrid";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  MOCK_TRADE_TICKET_HISTORY,
  MOCK_TRADE_TICKET_HISTORY_DETAILS,
} from "@enfusion-ui/trades";
import { FormPanel } from "@enfusion-ui/web-components";
import { useGridApi } from "@enfusion-ui/web-core";
import { ColDef, GridReadyEvent, RowSelectedEvent } from "ag-grid-community";
import * as React from "react";

const historyColumnDefs: ColDef[] = [
  {
    headerName: "Date",
    field: "date",
    initialWidth: 80,
  },
  {
    headerName: "Time",
    field: "time",
    initialWidth: 80,
  },
  {
    headerName: "Trade ID",
    field: "tradeId",
    initialWidth: 80,
  },
  {
    headerName: "Action",
    field: "action",
    initialWidth: 80,
  },
  {
    headerName: "User",
    field: "user",
    initialWidth: 80,
  },
];

const detailsColumnDefs: ColDef[] = [
  {
    headerName: "Property Name",
    field: "propertyName",
    initialWidth: 120,
  },
  {
    headerName: "Old Value",
    field: "oldValue",
    initialWidth: 120,
  },
  {
    headerName: "New Value",
    field: "newValue",
    initialWidth: 120,
  },
];

export const HistoryTabRevisionPanel: React.FC = () => {
  const [selectedHistory, setSelectedHistory] = React.useState<number>();

  React.useEffect(() => {
    const detailsRowData = MOCK_TRADE_TICKET_HISTORY_DETAILS;
    detailsGridRef.current?.api.updateGridOptions({ rowData: detailsRowData });
  }, [selectedHistory]);

  const { gridApiRef: historyGridRef, onGridReady: handleHistoryGridReady } =
    useGridApi((event: GridReadyEvent) => {
      event.api.sizeColumnsToFit();
    });

  const { gridApiRef: detailsGridRef, onGridReady: handleDetailsGridReady } =
    useGridApi((event: GridReadyEvent) => {
      event.api.sizeColumnsToFit();
    });

  const handleHistoryRowSelected = useRefCallback((e: RowSelectedEvent) => {
    if (e.node.isSelected()) {
      setSelectedHistory(e.data.tradeId);
    }
  }, []);

  return (
    <FormPanel
      keepRendered
      numColumns={2}
      title="Revision History"
      style={{ height: "100%" }}
      contentStyle={{
        height: "100%",
        minHeight: "20rem",
        gap: "var(--spacing-xl)",
      }}
    >
      <RoundedDataGrid
        height="100%"
        suppressCellFocus
        multiSortKey="ctrl"
        suppressContextMenu
        rowSelection="single"
        suppressNoRowsOverlay
        columnDefs={historyColumnDefs}
        rowData={MOCK_TRADE_TICKET_HISTORY}
        onGridReady={handleHistoryGridReady}
        onRowSelected={handleHistoryRowSelected}
        defaultColDef={{
          sortable: true,
          resizable: true,
          suppressMovable: true,
        }}
      />

      <RoundedDataGrid
        rowData={[]}
        height="100%"
        suppressCellFocus
        multiSortKey="ctrl"
        suppressContextMenu
        columnDefs={detailsColumnDefs}
        onGridReady={handleDetailsGridReady}
        overlayNoRowsTemplate="Select row on left to view details"
        defaultColDef={{
          sortable: true,
          resizable: true,
          suppressMovable: true,
        }}
      />
    </FormPanel>
  );
};
