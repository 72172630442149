/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useDashboard,
  WIDGET_DEFINITIONS,
  WIDGET_WIDTH,
  WidgetDefinition,
  WidgetDefinitionCategory,
  WidgetType,
} from "@enfusion-ui/dashboards";
import { createTestId } from "@enfusion-ui/utils";
import { SecondaryTabList } from "@enfusion-ui/web-components";
import { styled, useAuth } from "@enfusion-ui/web-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import * as React from "react";

import {
  animationAttrs,
  MenuList,
  WidgetListContainer,
  WidgetName,
} from "../core/styledComponents";

const WidgetMenuWrapper = styled.div`
  padding: var(--spacing-l);
`;

const AnimationWrapper = styled(motion.div).attrs(animationAttrs)``;

// fontawesome icons are 1em wide
const WidgetWrapper = styled.div`
  display: grid;
  grid-template-columns: 1em calc(100% - 1em - var(--spacing-xl));
  width: ${WIDGET_WIDTH}rem;
  align-items: center;
  grid-gap: var(--spacing-xl);
  padding: var(--spacing-l);
  background-color: var(--background-color-1);
  cursor: grab;
  border: 1px dashed var(--primary);
  border-radius: var(--radius);

  :hover {
    border-style: dashed;
    border-color: var(--primary);
  }
`;

const WidgetDescription = styled.span`
  font-size: small;
`;

const WidgetOption: React.FC<{
  widget: WidgetDefinition & { key: string };
  dataE2EId: string;
}> = ({ widget, dataE2EId }) => (
  <AnimationWrapper>
    <WidgetWrapper
      draggable
      title={widget.description}
      onDragStart={(e) => e.dataTransfer.setData("text/plain", widget.key)}
      unselectable="on"
      data-e2e-id={dataE2EId}
      data-testid={createTestId(dataE2EId ?? "widget-menu")}
    >
      <FontAwesomeIcon icon={widget.icon as any} />
      <div>
        <WidgetName>{widget.name}</WidgetName>
        <WidgetDescription title={widget.description}>
          {widget.description}
        </WidgetDescription>
      </div>
    </WidgetWrapper>
  </AnimationWrapper>
);

type WidgetMenuProps = {
  filterList?: Array<WidgetDefinitionCategory>;
  displayLabel?: string;
  inputLabel?: string;
  searchTerm?: string;
};

const widgetKeys = Object.keys(WIDGET_DEFINITIONS) as any[];

export const WidgetMenu: React.FC<WidgetMenuProps> = ({
  filterList = ["dashboard"],
  displayLabel = "Display Widgets",
  inputLabel = "Input Widgets",
  searchTerm = "",
}) => {
  const { user, isAdmin, isInternalPo, hasPerm } = useAuth();
  const { mobile } = useDashboard();

  const [activeTab, setActiveTab] = React.useState<string>("display-widgets");

  const widgetDefs = React.useMemo(
    () =>
      widgetKeys.reduce((res, key: WidgetType) => {
        if (![...filterList, "any"].includes(WIDGET_DEFINITIONS[key].category))
          return res;
        return [...res, { ...WIDGET_DEFINITIONS[key], key }];
      }, [] as Array<WidgetDefinition & { key: string }>) as Array<
        WidgetDefinition & { key: string }
      >,
    [widgetKeys]
  );

  const availableWidgets = React.useMemo(() => {
    const filter = searchTerm.toLowerCase();
    return widgetDefs
      .filter((i) =>
        hasPerm("DashboardEditor") || isAdmin() || isInternalPo()
          ? true
          : !i.adminOnly
      )

      .filter((i) => (mobile ? !i.webOnly : true))

      .filter(
        (i) =>
          (i.name.toLowerCase().indexOf(filter) !== -1 ||
            i.description.toLowerCase().indexOf(filter)) !== -1
      );
  }, [user, widgetDefs, searchTerm, mobile]);

  const displayWidgets = React.useMemo(
    () =>
      availableWidgets
        .filter(({ type }) => type === "display" || type === "simpleDisplay")
        .map((widget) => (
          <WidgetOption
            key={widget.key}
            widget={widget}
            dataE2EId={widget.key}
          />
        )),
    [availableWidgets]
  );

  const inputWidgets = React.useMemo(
    () =>
      availableWidgets
        .filter(({ type }) => type === "input")
        .map((widget) => (
          <WidgetOption
            key={widget.key}
            widget={widget}
            dataE2EId={widget.key}
          />
        )),
    [availableWidgets]
  );

  return (
    <>
      <WidgetMenuWrapper style={{ paddingBottom: 0 }}>
        <SecondaryTabList
          value={activeTab}
          onSelect={setActiveTab}
          tabs={[
            {
              label: displayLabel,
              key: "display-widgets",
            },
            {
              label: inputLabel,
              key: "input-widgets",
            },
          ]}
        />
      </WidgetMenuWrapper>
      <WidgetListContainer>
        <MenuList data-e2e-id="dashboard-widget-list">
          {activeTab === "display-widgets" ? displayWidgets : inputWidgets}
        </MenuList>
      </WidgetListContainer>
    </>
  );
};

export default WidgetMenu;
