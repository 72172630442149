import { parseNumber, REPORT_ROW_HEIGHT } from "@enfusion-ui/web-core";
import XLSX from "@sheet/cryptopivot";
import { debounce, last } from "lodash";
export function valueTooltipGetter({ value }) {
    let val = `${value}`;
    if (typeof value === "object") {
        const data = value;
        if (data.l) {
            val = data.l.Tooltip || value.l.display || "";
        }
        else {
            val = value.w || "";
        }
    }
    return val;
}
export const parseLinkTarget = (target) => {
    if (!target.startsWith("#")) {
        return target;
    }
    const [sheet, rowIdx] = target.replace("#'", "").replace("'", "").split("!");
    return [sheet, XLSX.utils.decode_cell(rowIdx)];
};
export const makeCols = (sheet, maxCols = 16000) => {
    const refStr = sheet["!ref"];
    const defs = [
        {
            colId: "_idx",
            headerName: "",
            field: "idx",
            pinned: "left",
            width: 46,
            maxWidth: 46,
            minWidth: 46,
            cellStyle: {
                backgroundColor: "rgb(28, 28, 28)",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                height: "100%",
                minHeight: `${REPORT_ROW_HEIGHT}px`,
                borderWidth: "0px 1px 1px 0px",
                borderColor: "inherit",
            },
            valueFormatter: ({ value }) => value + 1,
            suppressHeaderMenuButton: true,
            suppressAutoSize: true,
            suppressSizeToFit: true,
            suppressMovable: true,
            resizable: false,
            sortable: false,
        },
    ];
    if (!refStr)
        return defs;
    const frozenCols = [];
    if (sheet["!freeze"]) {
        const freezeRange = XLSX.utils.decode_range(`A1:${sheet["!freeze"]}`);
        for (let colId = 0; colId < freezeRange.e.c; colId += 1) {
            frozenCols.push(XLSX.utils.encode_col(colId));
        }
    }
    const count = Math.min(XLSX.utils.decode_range(refStr).e.c + 1, maxCols);
    const cols = sheet["!cols"];
    for (let i = 0; i <= count + 1; i += 1) {
        const field = XLSX.utils.encode_col(i);
        defs.push({
            colId: field,
            field,
            width: cols && cols[i] ? cols[i].wpx + 40 : undefined,
            flex: cols ? 0 : 1,
            cellRenderer: "cellRenderer",
            pinned: frozenCols.includes(field) ? "left" : undefined,
        });
    }
    return defs;
};
export const resizeRow = debounce((event) => {
    event.api.resetRowHeights();
}, 1000);
export function getCellValue(sheet, rowIdx, colIdx) {
    const key = { c: colIdx, r: rowIdx };
    const cellId = XLSX.utils.encode_cell(key);
    const cellData = sheet[cellId];
    return cellData || {};
}
export function loadSheet(sheetData, workbook, sheetName) {
    const sheet = workbook.Sheets[sheetName];
    const columnDefs = makeCols(sheet, 50);
    const lastColDef = last(columnDefs);
    const lastCol = lastColDef?.colId === "_idx" ? "A" : lastColDef?.colId ?? "A";
    const lastRow = parseNumber((sheet["!ref"] || "A1:A1").split(":")[1] ?? "A1");
    const range = XLSX.utils.decode_range(`A1:${lastCol}${lastRow}`);
    const data = [];
    for (let rowIdx = 0; rowIdx <= range.e.r + 1; rowIdx += 1) {
        const row = { idx: rowIdx };
        for (let colIdx = 0; colIdx <= range.e.c + 1; colIdx += 1) {
            row[XLSX.utils.encode_col(colIdx)] = getCellValue(sheet, rowIdx, colIdx);
        }
        data.push(row);
    }
    let frozenCount = 0;
    if (sheet["!freeze"]) {
        const freezeRange = XLSX.utils.decode_range(`A1:${sheet["!freeze"]}`);
        frozenCount = freezeRange.e.r;
    }
    sheetData.pinnedData[sheetName] = data.slice(0, frozenCount);
    sheetData.data[sheetName] = data.slice(frozenCount);
    sheetData.columnDefs[sheetName] = columnDefs;
    return sheetData;
}
export const mergeStyles = (basePivotStyle, cellStyle) => {
    if (typeof basePivotStyle === "undefined") {
        return cellStyle;
    }
    if (typeof cellStyle === "undefined") {
        return basePivotStyle;
    }
    return {
        ...basePivotStyle,
        sz: cellStyle?.sz || basePivotStyle.sz,
        bold: cellStyle?.bold || basePivotStyle.bold,
        italic: cellStyle?.italic || basePivotStyle.italic,
        underline: cellStyle?.underline || basePivotStyle.underline,
        strike: cellStyle?.strike || basePivotStyle.strike,
        name: cellStyle?.name || basePivotStyle.name,
        valign: cellStyle?.valign || basePivotStyle.valign,
        left: cellStyle?.left || basePivotStyle.left,
        right: cellStyle?.right || basePivotStyle.right,
        top: cellStyle?.top || basePivotStyle.top,
        bottom: cellStyle?.bottom || basePivotStyle.bottom,
        fgColor: cellStyle?.fgColor || basePivotStyle.fgColor,
        bgColor: cellStyle?.bgColor || basePivotStyle.bgColor,
        alignment: cellStyle?.alignment || basePivotStyle.alignment,
        color: cellStyle?.fgColor || cellStyle?.bgColor
            ? cellStyle.color
            : basePivotStyle.color,
    };
};
let canvas = null;
export function getTextWidth(text, font) {
    if (!canvas) {
        canvas = document.createElement("canvas");
    }
    const context = canvas.getContext("2d");
    if (context) {
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    }
    return text.length * 9;
}
