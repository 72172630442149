/* eslint-disable @typescript-eslint/no-explicit-any */
import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import { ContextItemEntry } from "@app-views/portfolios/components/MainPortfolioGrid";
import {
  getExpandContractActions,
  getMainMenuExpandContractActions,
} from "@app-views/portfolios/components/utils";
import {
  BlotterRow,
  complianceErrorMessage,
  getOrderFormType,
  OrderColumnInfo,
  TOAST_CONTENT,
} from "@enfusion-ui/core";
import { useModalState, useRefCallback } from "@enfusion-ui/hooks";
import {
  ColumnChooserColumn,
  ComplianceState,
  FinancialSubType,
  FinancialType,
  OrderSaveError,
  OrderSaveSuccess,
  VarSwapVoiceOrderFillRequest,
} from "@enfusion-ui/types";
import {
  ColumnChooserModal,
  copyToKeyboardAction,
  useConfirmationModal,
} from "@enfusion-ui/web-components";
import {
  defaultToast,
  errorToast,
  infoToast,
  OEMS_EVENTS,
  OemsBroadcastEvent,
  REST_API,
  successToast,
  TabConfig,
  useAuth,
  useTabs,
  warningToast,
} from "@enfusion-ui/web-core";
import { icon } from "@fortawesome/fontawesome-svg-core";
import {
  faColumns3,
  faDisplay,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import {
  ColDef,
  ColumnState,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridReadyEvent,
  ProcessCellForExportParams,
  RowDoubleClickedEvent,
  ValueFormatterFunc,
  ValueFormatterParams,
} from "ag-grid-community";
import { format } from "date-fns";
import { isEqual, sortBy } from "lodash";
import * as React from "react";

import {
  cancelOrder,
  performTransmit,
  SelectedRowInfo,
  submitFills,
} from "../../utils/actions";
import { createOrderColumnDefs } from "../../utils/createColumnDefs";
import OrdersList, { OrdersListProps } from "../OrdersList";
import VarSwapVoiceOrderFillModal, {
  VarSwapVoiceOrderFormValues,
} from "./VarSwapVoiceOrderFillModal";
import VoiceOrderFillModal, {
  VoiceOrderFormValues,
} from "./VoiceOrderFillModal";

const VoiceFillModal: React.FC<{
  open: boolean;
  onCancel: VoidFunction;
  orderId: number | null;
  instrumentType?: FinancialType;
  instrumentSubtype?: FinancialSubType;
  onSubmit: (data: VarSwapVoiceOrderFormValues | VoiceOrderFormValues) => void;
}> = ({
  open,
  orderId,
  onCancel,
  onSubmit,
  instrumentType,
  instrumentSubtype,
}) => {
  if (!open) return null;
  const FillModal =
    process.env.REACT_APP_ENABLE_VAR_SWAP_ORDERS &&
    instrumentType === "VarianceVolatilitySwap"
      ? VarSwapVoiceOrderFillModal
      : VoiceOrderFillModal;

  return (
    <FillModal
      open
      orderId={orderId}
      onCancel={onCancel}
      onSubmit={onSubmit}
      title="New Voice Order Fill"
      instrumentType={instrumentType}
      instrumentSubtype={instrumentSubtype}
    />
  );
};

type OrderBlotterProps = {
  columns: OrderColumnInfo[];
  gridName?: string;
  setFilterState?: (value: Record<string, any>) => void;
  getFilterState?: () => Record<string, any>;
  onColumnStateChanged?: () => void;
} & OrdersListProps;

function columnsChanged(
  oldColumns: OrderColumnInfo[] | null,
  newColumns: OrderColumnInfo[]
) {
  if (!oldColumns) return true;

  const oldColumnList = new Set([
    ...oldColumns.map((column) => column.key).sort(),
  ]);

  const newColumnList = new Set([
    ...newColumns.map((column) => column.key).sort(),
  ]);

  if (
    oldColumnList.size !== newColumnList.size ||
    !isEqual([...oldColumnList], [...newColumnList])
  ) {
    return true;
  }

  return !isEqual(sortBy(oldColumns, ["key"]), sortBy(newColumns, ["key"]));
}

const processCellCallback = (params: ProcessCellForExportParams) => {
  const colDef = params.column.getColDef();
  if (colDef.valueFormatter) {
    const valueFormatterParams: ValueFormatterParams = {
      ...params,
      data: params.node?.data,
      node: params.node!,
      colDef: params.column.getColDef(),
    };
    return (colDef.valueFormatter as ValueFormatterFunc)(valueFormatterParams);
  }
  return params.value;
};

const mapChooserCols = (
  defs: ColDef[],
  defaultColumns: ColumnChooserColumn[] = []
) => {
  return defs.reduce((res, entry: ColDef, idx) => {
    if (!entry.headerCheckboxSelection && !entry.lockVisible)
      res.push({
        id: entry.colId || "Unknown",
        name: entry.headerName || "Unknown",
        selected: !entry.hide,
        category: (entry.headerClass as string) || "Other",
        tooltip: entry.headerTooltip,
        groupName: "Columns",
        orderIndex: idx,
      });

    return res;
  }, defaultColumns);
};

type SelectedTransmitRow = {
  orderId: number;
  complianceState?: ComplianceState;
};

const OrderBlotter: React.FC<OrderBlotterProps> = ({
  columns,
  onGridReady,
  setFilterState,
  getFilterState: _getFilterState,
  onColumnStateChanged,
  ...orderBlotterProps
}: OrderBlotterProps) => {
  const voiceFillModalState = useModalState<
    SelectedRowInfo,
    VarSwapVoiceOrderFormValues | VoiceOrderFormValues
  >({
    onClose: (selectedOrder, data) => {
      if (selectedOrder !== null && data !== null) {
        const varSwap =
          process.env.REACT_APP_ENABLE_VAR_SWAP_ORDERS &&
          selectedOrder.instrumentType === "VarianceVolatilitySwap";

        if (varSwap) {
          const res = data as VarSwapVoiceOrderFormValues;
          if (
            selectedOrder?.unfilledQuantity &&
            res.vegaNotional &&
            res.vegaNotional > selectedOrder?.unfilledQuantity
          ) {
            overfillConfirmation.openModal(res);
          } else {
            submitVarSwapFills(res, false);
          }
        } else {
          const res = data as VoiceOrderFormValues;
          if (
            selectedOrder.unfilledQuantity &&
            ((res.quantity && res.quantity > selectedOrder.unfilledQuantity) ||
              (res?.notional && selectedOrder.unfilledQuantity < res?.notional))
          ) {
            overfillConfirmation.openModal(res);
          } else {
            submitFills(res, selectedOrder, false);
          }
        }
      }
    },
  });

  const { openTab } = useTabs();
  const { user, isAdmin } = useAuth();
  const columnsRef = React.useRef<OrderColumnInfo[] | null>(null);
  const gridRef = React.useRef<GridReadyEvent>();

  const columnChooserColumnsRef = React.useRef<ColumnChooserColumn[]>([]);
  const columnChooserState = useModalState({
    beforeOpen: () => {
      const defs = gridRef.current?.api.getColumnDefs() || [];
      columnChooserColumnsRef.current = mapChooserCols(defs);
    },
  });

  const channelRef = useBroadcastChannel<OemsBroadcastEvent>(OEMS_EVENTS);
  const postComplianceUpdateMessage = (orderId: number) =>
    channelRef.broadcastMessage({
      action: "compliance-update",
      payload: { orderId },
    });

  const applyOrderColumnInfo = useRefCallback((columns: OrderColumnInfo[]) => {
    if (gridRef.current && columnsChanged(columnsRef.current, columns)) {
      columnsRef.current = columns;
      gridRef.current.api.updateGridOptions({ columnDefs: [] });
      gridRef.current.api.updateGridOptions({
        columnDefs: createOrderColumnDefs(columns),
      });
    }
  }, []);

  React.useEffect(() => {
    applyOrderColumnInfo(columns);
  }, [columns]);

  const handleGridReady = useRefCallback(
    (event: GridReadyEvent) => {
      gridRef.current = event;
      applyOrderColumnInfo(columns);
      onGridReady?.(event);
    },
    [columns, onGridReady]
  );

  const openSplitOrderView = useRefCallback(
    (
      orderId: number,
      financialSubType: any | undefined,
      completed: boolean,
      submissionStatus: any
    ) => {
      openTab({
        name: `New Order - Parent #${orderId}`,
        component: "new-oems-order",
        unique: "new-oems-order",
        icon: faDisplay,
        config: {
          orderId,
          completed,
          submissionStatus,
          formType: getOrderFormType(financialSubType, {
            varSwap: !process.env.REACT_APP_ENABLE_VAR_SWAP_ORDERS,
            irs: !process.env.REACT_APP_ENABLE_IRS_ORDERS,
          }),
          splitOrder: true,
        },
      } as TabConfig);
      channelRef.broadcastMessage({
        action: "open-order-tab",
        payload: { orderId: 0 },
      });
    },
    [openTab]
  );

  const openOrderTab = useRefCallback(
    (row: BlotterRow, autoFocus?: string) => {
      const { completed, orderId, columnValues, transmitted } = row;
      const submissionStatus = columnValues?.SubmissionStatus?.value ?? "";
      const financialSubType = columnValues?.InstrumentType?.value as any;
      const isSplit =
        columnValues?.Method === "Undecided" &&
        columnValues?.Idle !== Math.abs(columnValues?.Quantity ?? 0);

      openTab({
        name: `Order #${orderId}`,
        component: "oems-order",
        unique: `oems-order-${orderId}`,
        icon: faDisplay,
        config: {
          orderId,
          completed,
          submissionStatus,
          transmitted,
          isSplit,
          formType: getOrderFormType(financialSubType, {
            varSwap: !process.env.REACT_APP_ENABLE_VAR_SWAP_ORDERS,
            irs: !process.env.REACT_APP_ENABLE_IRS_ORDERS,
          }),
          autoFocus,
        },
      } as TabConfig);
      channelRef.broadcastMessage({
        action: "open-order-tab",
        payload: { orderId },
      });
    },
    [openTab]
  );

  const openComplianceView = useRefCallback(
    (row: BlotterRow) => {
      openOrderTab(row, "complianceRecords");
    },
    [openOrderTab]
  );

  const runCompliance = useRefCallback(
    async (orderId: number) => {
      try {
        infoToast("Checking compliance", {
          autoClose: 2000,
        });
        const response: OrderSaveSuccess & OrderSaveError =
          await REST_API.OEMS.RUN_COMPLIANCE.FETCH(orderId);

        if (response.errorMessage) {
          throw new Error("Compliance run failed: " + response.errorMessage);
        }

        if (response.complianceResult) {
          const { complianceResult } = response;
          switch (complianceResult.complianceState) {
            case "Passed":
              successToast(TOAST_CONTENT.oems.compliance.success, {
                toastId: orderId,
                onClose: () => postComplianceUpdateMessage(orderId),
              });

              break;

            case "Failed":
              errorToast(TOAST_CONTENT.oems.compliance.failure, {
                toastId: orderId,
              });
              break;
          }

          if (complianceResult.complianceState.startsWith("Warning")) {
            warningToast(TOAST_CONTENT.oems.compliance.warn, {
              toastId: orderId,
              onClose: () => postComplianceUpdateMessage(orderId),
            });
          }
        } else {
          defaultToast(TOAST_CONTENT.oems.compliance.default, {
            toastId: orderId,
            onClose: () => postComplianceUpdateMessage(orderId),
          });
        }
      } catch (err: any) {
        errorToast(
          TOAST_CONTENT.oems.compliance.failure,
          complianceErrorMessage(err?.message),
          {
            toastId: orderId,
          }
        );
      }
    },
    [postComplianceUpdateMessage]
  );

  const submitVarSwapFills = useRefCallback(
    async (data: VarSwapVoiceOrderFormValues, acceptWarnings: boolean) => {
      const selectedRowInfo = voiceFillModalState.openContentRef.current;
      try {
        const {
          tradeDate: date,
          counterpartyShortName,
          vegaNotional,
          varianceUnits,
          strike,
          notionalCcy,
        } = data;

        const bodyBase: VarSwapVoiceOrderFillRequest = {
          orderId: selectedRowInfo?.orderId ?? 0,
          lastPx: null,
          lastQty: null,
          tradeDate: date ? format(date, "yyyy-MM-dd") : null,
          counterpartyCode: counterpartyShortName,
          transactionType: "New",
        };
        const body = {
          ...bodyBase,
          vegaNotional,
          varianceUnits,
          notionalCcy,
          strike,
        };

        const response = await REST_API.OEMS.FILL_VOICE_ORDER(
          "varSwap",
          body,
          acceptWarnings
        );

        response.success
          ? successToast("Order fill added successfully")
          : errorToast(response.message);
      } catch (ex: any) {
        console.error(ex?.message);
        errorToast(ex?.message);
      }
    },
    []
  );

  const handleRowDoubleClick = React.useCallback(
    (e: RowDoubleClickedEvent) => {
      if (e?.data && e.api.getAllDisplayedColumns().length)
        openOrderTab(e.data);
    },
    [user, openTab]
  );

  // #region Confirmation Modals
  const handleTransmitOrder = useRefCallback(
    async (selectedRow: SelectedTransmitRow | null) => {
      if (selectedRow !== null) {
        try {
          await performTransmit(
            selectedRow.orderId,
            selectedRow.complianceState !== "Failed"
          );
          successToast(
            TOAST_CONTENT.oems.transmit.success,
            `OID:(${selectedRow.orderId})`
          );
        } catch (err: any) {
          console.error("Error while transmitting the order", selectedRow, err);
          errorToast(TOAST_CONTENT.oems.transmit.failure, err?.message);
        }
      }
    },
    [performTransmit]
  );

  const transmitConfirmation = useConfirmationModal<SelectedTransmitRow>({
    title: "Transmit Order",
    renderContent: () => "Are you sure you want to transmit the order?",
    onSubmit: handleTransmitOrder,
    submitActionTheme: "warning",
  });

  const handleCancelOrder = useRefCallback(async (orderId: number | null) => {
    if (orderId !== null) {
      try {
        await cancelOrder(orderId);
        successToast(TOAST_CONTENT.oems.cancel.success, `OID:${orderId}`);
      } catch (err: any) {
        console.warn("Error while canceling the order", { orderId }, err);
        warningToast(TOAST_CONTENT.oems.cancel.failure, err?.message);
      }
    }
  }, []);

  const cancelConfirmation = useConfirmationModal<number>({
    title: "Cancel Order",
    renderContent: (id) =>
      `Are you sure you want to cancel the order (OID:${id})?`,
    onSubmit: handleCancelOrder,
    submitActionTheme: "danger",
  });

  const handleOverFillOrder = useRefCallback(
    (data: VoiceOrderFormValues | VarSwapVoiceOrderFormValues | null) => {
      const selectedRowInfo = voiceFillModalState.openContentRef.current;
      if (selectedRowInfo !== null && data !== null) {
        const varSwap =
          process.env.REACT_APP_ENABLE_VAR_SWAP_ORDERS &&
          selectedRowInfo.instrumentType === "VarianceVolatilitySwap";

        if (varSwap)
          submitVarSwapFills(data as VarSwapVoiceOrderFormValues, true);
        else submitFills(data as VoiceOrderFormValues, selectedRowInfo, true);
      }
    },
    []
  );

  const overfillConfirmation = useConfirmationModal<
    VoiceOrderFormValues | VarSwapVoiceOrderFormValues
  >({
    title: "Confirm Save Trade for Voice Order Overfill",
    renderContent: () =>
      `The fill quantity given is greater than unfilled order quantity.
Are your sure you want to save this trade overfilling the voice order?`,
    onSubmit: handleOverFillOrder,
    submitActionTheme: "primary",
    size: "small",
  });
  // #endregion Confirmation Modals

  const updateColumnChooser = useRefCallback(
    (orderedCols: Array<ColumnChooserColumn>) => {
      columnChooserState.closeModal();
      gridRef.current?.api.showLoadingOverlay();

      // Hide Columns
      const currState =
        gridRef.current?.api.getColumnState() || ([] as ColumnState[]);

      const newColumnState = orderedCols.reduce((res, { id, selected }) => {
        const state = currState.find((s) => id === s.colId);
        if (state) res.push({ ...state, hide: !selected });
        return res;
      }, [] as ColumnState[]);

      // Reorder Columns
      const currDefs = (gridRef.current?.api.getColumnDefs() as ColDef[]) || [];

      const existingDefs = orderedCols.reduce((res, { id }) => {
        const def = currDefs.find((d) => d.colId === id);
        if (def) res.push(def);
        return res;
      }, [] as ColDef[]);

      const lockedDefs = currDefs.filter(
        (def) => def.headerCheckboxSelection || def.lockVisible
      );

      const newColumnDefs = [...lockedDefs, ...existingDefs].map((def) => {
        if (def.headerCheckboxSelection || def.lockVisible) return def;
        const added =
          orderedCols.find((col) => col.id === def.colId)?.selected === true;
        def.hide = !added;
        return def;
      });

      // Update column hide state
      gridRef.current?.api.applyColumnState({
        state: newColumnState,
        applyOrder: true,
      });

      // Reorder column
      gridRef.current?.api.updateGridOptions({ columnDefs: newColumnDefs });

      setTimeout(() => {
        gridRef.current?.api.hideOverlay();
        onColumnStateChanged?.();
      }, 500);
    },
    [onColumnStateChanged]
  );

  const getMenuItems = useRefCallback(
    (
      params: GetMainMenuItemsParams | GetContextMenuItemsParams,
      preItems: Array<ContextItemEntry | null> = [],
      postItems: Array<ContextItemEntry | null> = []
    ): Array<ContextItemEntry> => {
      let defaultItems = params?.defaultItems || ["export"];

      const realPreItems = preItems?.filter(
        Boolean
      ) as unknown as Array<ContextItemEntry>;

      const entries = [
        ...defaultItems,
        defaultItems.length > 0 && realPreItems.length > 0 ? "separator" : null,
        ...realPreItems,
        realPreItems.length > 0 || defaultItems.length > 0 ? "separator" : null,
        {
          name: "Customize Columns",
          icon: icon(faColumns3 as IconDefinition).html?.[0],
          action: columnChooserState.openModal,
        },
        ...postItems,
      ] as (ContextItemEntry | null)[];

      return entries.filter(Boolean) as unknown as ContextItemEntry[];
    },
    []
  );

  const getMainMenuItems = useRefCallback(
    (params: GetMainMenuItemsParams): ContextItemEntry[] => {
      return getMenuItems(params, getMainMenuExpandContractActions(params));
    },
    [getMenuItems]
  );

  const getContextMenuItems = useRefCallback(
    (params: GetContextMenuItemsParams) => {
      const { node } = params;
      const contractExpand = getExpandContractActions(params);
      if (!node) return getMenuItems(params, contractExpand);

      let menuItems: ContextItemEntry[] = [...contractExpand, "separator"];
      const row = node.data as BlotterRow;
      const { completed, columnValues, orderId } = row || {};

      const submissionStatus = columnValues?.SubmissionStatus?.value ?? "";

      if (row?.splittable) {
        menuItems.push({
          name: "Split",
          action: () =>
            openSplitOrderView(
              orderId,
              columnValues?.InstrumentType?.value,
              completed,
              submissionStatus
            ),
        });
      }

      if (row?.columnValues.ParentOrderId === null) {
        menuItems.push({
          name: "View Compliance",
          action: () => openComplianceView(row),
        });
      }

      if (
        columnValues.Method === "Voice" &&
        columnValues.FilledPct !== 100 &&
        !completed
      ) {
        menuItems.push({
          name: "Add Fills ...",
          tooltip: "Add Fill for Voice order",
          action: () => {
            voiceFillModalState.openModal({
              orderId,
              unfilledQuantity: columnValues?.Open,
              instrumentType: columnValues?.InstrumentType?.value,
              financialSubtype: columnValues?.InstrumentSubtype?.value,
            });
          },
        });
      }

      if (row?.complianceEligible) {
        menuItems.push({
          name: "Run Compliance",
          action: () => runCompliance(orderId),
        });
      }

      if (row?.transmittable) {
        menuItems.push({
          name: "Transmit Order",
          action: () => {
            transmitConfirmation.openModal({
              orderId,
              complianceState: columnValues.Compliance?.complianceState,
            });
          },
        });
      }

      if (row?.cancellable) {
        menuItems = [
          ...menuItems,
          {
            name: "Cancel Order",
            action: () => {
              cancelConfirmation.openModal(row.orderId);
            },
          },
        ];
      }

      if (!!row && isAdmin()) {
        menuItems.push("separator");
        menuItems.push({
          name: "[Debug] Copy raw row data",
          action: () => {
            copyToKeyboardAction({
              getText: () => JSON.stringify(row),
              onSuccess: () => successToast("Copied raw row data"),
              onError: () => errorToast("Failed to copy raw row data"),
            });
          },
        });
      }

      return gridRef.current?.api.getAllDisplayedColumns().length
        ? getMenuItems(params, menuItems)
        : getMenuItems(params, []);
    },
    [openComplianceView, openSplitOrderView]
  );

  return (
    <>
      <OrdersList
        {...orderBlotterProps}
        ignoreColumns
        onGridReady={handleGridReady}
        getMainMenuItems={getMainMenuItems}
        getContextMenuItems={getContextMenuItems}
        onRowDoubleClicked={handleRowDoubleClick}
        processCellForClipboard={processCellCallback}
        onColumnStateChanged={onColumnStateChanged}
        onFilterChanged={(ev: FilterChangedEvent) => {
          setFilterState?.(ev.api.getFilterModel());
        }}
      />
      {transmitConfirmation.content}
      {cancelConfirmation.content}
      {overfillConfirmation.content}
      <VoiceFillModal
        open={voiceFillModalState.open}
        onCancel={voiceFillModalState.closeModal}
        instrumentSubtype={
          voiceFillModalState.openContentRef.current?.financialSubtype
        }
        orderId={voiceFillModalState.openContentRef.current?.orderId ?? 0}
        onSubmit={voiceFillModalState.closeModal}
      />
      <ColumnChooserModal
        open={columnChooserState.open}
        onAccept={updateColumnChooser}
        onCancel={columnChooserState.closeModal}
        columns={columnChooserColumnsRef.current}
      />
    </>
  );
};

export default OrderBlotter;
