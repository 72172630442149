import { EMPTY_RECORD } from "@enfusion-ui/core";
import { useWidget, WidgetWithDatasourceConfig } from "@enfusion-ui/dashboards";
import { ChannelDataIdentifier, WebReportTableParam } from "@enfusion-ui/types";
import { ReportParamInput } from "@enfusion-ui/web-components";
import * as React from "react";

import { FilterFieldMode } from "./DatasourceParamsFilter";

export const useDatasourceParamsFilterBehaviour = (
  param: WebReportTableParam
) => {
  const { editedConfig, changeConfigKeyToBeApplied, changeConfigKey } =
    useWidget();

  const {
    paramChannelIds = EMPTY_RECORD,
    paramStaticValues = EMPTY_RECORD,
    isStaticFilterSelection = EMPTY_RECORD,
  } = editedConfig as WidgetWithDatasourceConfig;

  const handleParamChannelIdChange =
    (key: string) => (value: ChannelDataIdentifier | null) => {
      changeConfigKeyToBeApplied("paramChannelIds", {
        ...paramChannelIds,
        [key]: value,
      });
    };

  const handleParamStaticValueChange =
    (key: string) =>
    (value: string | Date | number | string[] | number[] | null) => {
      changeConfigKeyToBeApplied("paramStaticValues", {
        ...paramStaticValues,
        [key]: value,
      });
    };

  const handleStaticValueChange = (
    key: string,
    value: string | Date | number | string[] | number[] | null
  ) => {
    handleParamStaticValueChange(key)(value);
  };

  const handleFilterModeChange = (
    key: string,
    selectedMode: FilterFieldMode
  ) => {
    changeConfigKey("isStaticFilterSelection", {
      ...isStaticFilterSelection,
      [key]: selectedMode === "static",
    });

    // Clearing static/dynamic fields on filter mode change.
    if (selectedMode === "static") {
      delete paramChannelIds[key];
      changeConfigKeyToBeApplied("paramChannelIds", paramChannelIds);
    } else {
      delete paramStaticValues[key];
      changeConfigKeyToBeApplied("paramStaticValues", paramStaticValues);
    }
    return EMPTY_RECORD;
  };

  /*
   * As of now, the types can be ID, string and enums only as sent from RestServer.
   * If the type is not one of Id, Date and Enum return TextInput
   * This is point to be discussed --- What are the defined types?
   */
  const getStaticInputBasedOnFieldType = () => {
    const { type, name, description: label, supportsMultiple } = param;
    return (
      <ReportParamInput
        type={type}
        name={name}
        label={label}
        multi={supportsMultiple}
        optionValues={param.values}
        value={paramStaticValues[name]}
        onChange={handleStaticValueChange}
      />
    );
  };

  return {
    handleParamChannelIdChange,
    handleParamStaticValueChange,
    handleFilterModeChange,
    getStaticInputBasedOnFieldType,
  };
};
