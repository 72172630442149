import { RoundedDataGrid } from "@app-components/DataGrid";
import {
  useReconciliation,
  useReconciliationBreakDetails,
} from "@app-context/reconciliation/context";
import { ContextItemEntry } from "@app-views/portfolios/components/MainPortfolioGrid";
import { BreakDetailsModalContent } from "@app-views/reconciliation/components/BreakDetailsModal";
import { LoadingContent } from "@app-views/reconciliation/components/LoadingContent";
// import { PostAndConfirmEntriesModalContent } from "@app-views/reconciliation/components/PostAndConfirmEntriesModal"; for phase-2
import {
  ButtonGroupSelectWrapper,
  Column,
  GridContainer,
  GridWrapper,
  RowSection,
} from "@app-views/reconciliation/components/styles";
import { GraphBaseField } from "@app-views/reconciliation/types";
import {
  BREAK_DETAILS_MODAL_COLUMN_TYPES,
  BREAK_TYPE_COL_DEF,
  breakMap,
  DEFAULT_DIFF_REPORT_DATA,
  GRAPH_FILTER_KEYS,
  ROW_SELECTION_COL_DEF,
} from "@app-views/reconciliation/utils/constants";
import { cashBreakTypes } from "@enfusion-ui/core";
import { useModalState, useRefCallback } from "@enfusion-ui/hooks";
import {
  DiffReportSyncRow,
  FilterColumns,
  ReconciliationColumnType,
  ReconciliationTypes,
  ReconReportTableSyncRow,
  WebReconciliationReportMetadata,
} from "@enfusion-ui/types";
import {
  Button,
  ButtonGroupSelect,
  FormPanel,
  Modal,
} from "@enfusion-ui/web-components";
import { useTheme } from "@enfusion-ui/web-core";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColDef,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  GridReadyEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import { debounce, isEmpty, isNull } from "lodash";
import React from "react";

import {
  getCashBalanceReportId,
  getColId,
  getFilteredData,
  getRearrangedColumns,
  getReconStorageKey,
  getReportId,
  isCashDataSource,
} from "../../utils/commonUtils";
import { CashBalanceGrid } from "./CashBalanceGrid";

const getFilterOptions = (reconciliationType: ReconciliationTypes) => {
  return [
    {
      value: "Missing",
      label: `Missing ${reconciliationType}`,
      selectedColor: "primary",
    },
    {
      value: "Difference",
      label: "Differences",
      selectedColor: "primary",
    },
    {
      value: "Match",
      label: "Matches",
      selectedColor: "primary",
    },
  ];
};

const shouldFilterRow = (
  columnValues: ReconReportTableSyncRow,
  filterColumns: FilterColumns,
  graphFilters: Record<GraphBaseField, string>
) => {
  if (!graphFilters) return true;
  let filterRow = true;
  Object.keys(graphFilters).forEach((filterKey) => {
    let column: string | ReconciliationColumnType =
      filterColumns[filterKey as keyof FilterColumns];
    if (
      filterKey === GRAPH_FILTER_KEYS.breakCode ||
      filterKey === GRAPH_FILTER_KEYS.custodianName
    )
      column = getColId(columnValues, filterKey);

    if (typeof column === "string") {
      filterRow =
        filterRow &&
        columnValues[column]?.value ===
          graphFilters[filterKey as GraphBaseField];
    } else {
      filterRow =
        filterRow &&
        (columnValues[column.source ?? ""]?.value ===
          graphFilters[filterKey as GraphBaseField] ||
          columnValues[column.target ?? ""]?.value ===
            graphFilters[filterKey as GraphBaseField]);
    }
  });
  return filterRow;
};

export const BreakDetailsGrid: React.FC<{}> = () => {
  const [rowData, setRowData] = React.useState<DiffReportSyncRow[]>([]);
  const [selectedRowData, setSelectedRowData] =
    React.useState<DiffReportSyncRow | null>(null);
  const [selectedFilter, setSelectedFilter] = React.useState<string | null>(
    null
  );
  const [selectedRows, setSelectedRows] = React.useState<DiffReportSyncRow[]>(
    []
  );
  const { theme } = useTheme();
  const { filterState, metadata, reportsLoadingStatus } = useReconciliation();
  const { breakDetailsRendererData: data } = useReconciliationBreakDetails();
  const { graphFilterState } = useReconciliationBreakDetails();
  const loading =
    reportsLoadingStatus?.[getReportId(data.id, data.reconciliationType)];

  const breakDetailsModalState = useModalState();
  const { reportData, id, reconciliationType, filterColumns, impactColumns } =
    data;

  const gridData = isCashDataSource(reconciliationType)
    ? reportData.cashActivityRowsStore ?? DEFAULT_DIFF_REPORT_DATA
    : reportData.rowsStore ?? DEFAULT_DIFF_REPORT_DATA;

  // const postAndConfirmEntriesModalState = useModalState(); for phase-2
  const gridRef = React.useRef<GridReadyEvent | null>(null);

  const gridId = getReportId(id, reconciliationType);
  const getBreakDetailsGridColDef = useRefCallback(() => {
    const reportColDefs = (metadata?.[gridId]?.defs ?? []).map((colDef) => ({
      ...colDef,
      filter: true,
    }));
    const rearrangedColumns = getRearrangedColumns(reportColDefs);
    const colDefs = [
      ROW_SELECTION_COL_DEF,
      ...rearrangedColumns,
      BREAK_TYPE_COL_DEF,
    ] as ColDef<any, any>[];

    return colDefs;
  }, [id, metadata, reconciliationType]);

  React.useEffect(() => {
    let allBreaksData: DiffReportSyncRow[] = [];
    Object.entries(gridData ?? {}).forEach(([type, rowData]) => {
      if (type !== "statistics") {
        allBreaksData = [
          ...allBreaksData,
          ...(rowData as DiffReportSyncRow[]).map((row: DiffReportSyncRow) => {
            row.columnValues["breakType"] = {
              value: breakMap[type] ?? breakMap.default,
            };
            return row;
          }),
        ];
      }
    });

    setRowData(
      getFilteredData(filterState, allBreaksData, filterColumns).filter(
        (row) => {
          if (graphFilterState[id] && !isEmpty(graphFilterState[id])) {
            return shouldFilterRow(
              row.columnValues,
              filterColumns,
              graphFilterState[id]
            );
          }
          return true;
        }
      )
    );
  }, [
    selectedFilter,
    filterState,
    reconciliationType,
    filterColumns?.currency,
    graphFilterState,
  ]);

  const getContextMenuItems = useRefCallback(
    (params: GetContextMenuItemsParams) => {
      const menuItems = [
        ...(params?.defaultItems ?? ["export"]),
        "separator",
        {
          name: "Break Details",
          action: breakDetailsModalState.openModal,
          disabled: selectedRows.length !== 1,
          tooltip: selectedRows.length !== 1 ? "Select only one row." : "",
        },
        "separator",
        // PHASE 2
        // {
        //   name: "Post and Confirm Entries",
        //   action: postAndConfirmEntriesModalState.openModal,
        //   disabled: selectedRows.length !== 1,
        //   tooltip: selectedRows.length !== 1 ? "Select only one row." : "",
        // },
      ];

      return menuItems as ContextItemEntry[];
    },
    [selectedRows]
  );

  const doesExternalFilterPass = useRefCallback(
    (node) => {
      if (isNull(selectedFilter)) {
        return true;
      }
      if (node.data) {
        return node.data.columnValues.breakType.value === selectedFilter;
      }
      return true;
    },
    [selectedFilter]
  );

  const handleGridReady = useRefCallback(
    (event) => {
      gridRef.current = event;
      const savedColumnState = localStorage.getItem(getReconStorageKey(id));
      const initialColumnState = !isNull(savedColumnState)
        ? JSON.parse(savedColumnState)
        : getBreakDetailsGridColDef();
      gridRef.current?.api.applyColumnState({
        state: initialColumnState,
      });
    },
    [gridRef.current]
  );

  const handleSelectionChanged = useRefCallback(
    debounce(
      (
        event: SelectionChangedEvent<any, any> | FilterChangedEvent<any, any>
      ) => {
        setSelectedRows(event.api.getSelectedRows());
        setSelectedRowData(event.api.getSelectedRows()[0]);
      },
      250
    ),
    [setSelectedRows, setSelectedRowData]
  );

  const handleColumnStateChanged = useRefCallback(() => {
    const currentState = gridRef.current?.api.getColumnState();
    localStorage.setItem(getReconStorageKey(id), JSON.stringify(currentState));
  }, [gridRef.current]);

  return (
    <>
      <Column gap="xl">
        <ButtonGroupSelectWrapper>
          <ButtonGroupSelect
            options={getFilterOptions(reconciliationType)}
            selectedValue={selectedFilter}
            disconnected
            onSelect={(value) => setSelectedFilter(value)}
          />
        </ButtonGroupSelectWrapper>
        {isCashDataSource(reconciliationType) && (
          <CashBalanceGrid
            selectedFilter={selectedFilter}
            id={getCashBalanceReportId(id)}
            doesExternalFilterPass={doesExternalFilterPass}
            filterColumns={filterColumns}
            impactColumns={impactColumns}
            reportData={reportData.cashBalanceRowsStore}
            getContextMenuItems={getContextMenuItems}
          />
        )}
        <FormPanel
          lineStyle
          collapsible
          keepRendered
          numColumns={1}
          defaultOpen
          title={
            isCashDataSource(reconciliationType)
              ? cashBreakTypes.CashActivity
              : `${reconciliationType} details`
          }
          showTextCollapseButton
          gapSize="l"
          titleStyle={{
            padding: "0 var(--spacing-l) var(--spacing-l) var(--spacing-l)",
            fontSize: "14px",
          }}
          thinContainer
          render={() => (
            <GridContainer>
              <GridWrapper className={theme.agGridClass.trim()} height={150}>
                {loading ? (
                  <LoadingContent />
                ) : (
                  <RoundedDataGrid
                    columnTypes={BREAK_DETAILS_MODAL_COLUMN_TYPES}
                    columnDefs={getBreakDetailsGridColDef()}
                    rowData={rowData}
                    rowSelection="multiple"
                    animateRows={false}
                    suppressRowDrag
                    getContextMenuItems={getContextMenuItems}
                    autoSizeStrategy={{
                      type: "fitCellContents",
                    }}
                    isExternalFilterPresent={() => true}
                    doesExternalFilterPass={doesExternalFilterPass}
                    height="100%"
                    onGridReady={handleGridReady}
                    onColumnStateChanged={handleColumnStateChanged}
                    onSelectionChanged={handleSelectionChanged}
                  />
                )}
              </GridWrapper>
              <Modal
                isOpen={breakDetailsModalState.open}
                onClose={breakDetailsModalState.closeModal}
                title={
                  <RowSection flex={1} style={{ fontSize: "12px" }}>
                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    Break Details
                  </RowSection>
                }
                content={
                  <BreakDetailsModalContent
                    data={selectedRowData}
                    colDefs={getBreakDetailsGridColDef() as ColDef[]}
                    diffColumnPairs={
                      (
                        metadata?.[gridId]
                          ?.metadata as WebReconciliationReportMetadata
                      )?.diffColumnPairs
                    }
                  />
                }
                footerContent={
                  <Button onClick={breakDetailsModalState.closeModal}>
                    Close
                  </Button>
                }
              />
              {/* <Modal
                isOpen={postAndConfirmEntriesModalState.open}
                onClose={postAndConfirmEntriesModalState.closeModal}
                title={
                  <RowSection flex={1} style={{ fontSize: "12px" }}>
                    <FontAwesomeIcon
                      icon={faMoneyCheckDollar}
                    ></FontAwesomeIcon>
                    Post and Confirm Entries
                  </RowSection>
                }
                content={<PostAndConfirmEntriesModalContent />}
                footerContent={
                  <RowSection gap="xl">
                    <Button
                      style={{ backgroundColor: "var(--primary)" }}
                      onClick={() => {
                        successToast(
                          "Successfully posted trade adjustments and GL entries for breaks."
                        );
                        postAndConfirmEntriesModalState.closeModal();
                      }}
                    >
                      Okay
                    </Button>
                    <Button
                      onClick={postAndConfirmEntriesModalState.closeModal}
                    >
                      Leave
                    </Button>
                  </RowSection>
                }
              /> for phase-2*/}
            </GridContainer>
          )}
        />
      </Column>
    </>
  );
};
