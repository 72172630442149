import HighchartsBase from "highcharts";
import treeMapModule from "highcharts/modules/treemap";

import { createCurrencyPointChart } from "./CurrencyPointChart";

treeMapModule(HighchartsBase);

export const TreeMapChart = createCurrencyPointChart(
  HighchartsBase,
  {
    type: "treemap",
    layoutAlgorithm: "squarified",
    dataLabels: {
      enabled: false,
    },
    levels: [
      {
        level: 1,
        dataLabels: {
          enabled: true,
          style: { fontSize: "14px" },
        },
        borderWidth: 3,
        levelIsConstant: false,
      },
      {
        level: 2,
        dataLabels: {
          enabled: true,
          style: { fontSize: "14px" },
        },
      },
    ],
  },
  {
    // Let the center circle be transparent
    colors: [
      "transparent",
      ...new Array(15)
        .fill("0")
        .map((_, idx) => `var(--dashboard-color-alpha-${idx})`),
      ...new Array(15)
        .fill("0")
        .map((_, idx) => `var(--dashboard-color-alpha-${idx})`),
    ],
  }
);
