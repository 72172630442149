import CDXIndexSelect from "@app-views/oems/components/cdx/CDXIndexSelect";
import CDXRootSelect from "@app-views/oems/components/cdx/CDXRootSelect";
import { CDXOrderFormValues } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { SelectOptionsType, Tenor } from "@enfusion-ui/types";
import { getMaturity } from "@enfusion-ui/utils";
import {
  Checkbox,
  ControlledEmpty,
  ControlledSelect,
  FormController,
  panelContentStyles,
  PanelRow,
} from "@enfusion-ui/web-components";
import { styled, useIsContentMobile } from "@enfusion-ui/web-core";
import * as React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

const CDXOrderDetailsPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  ${panelContentStyles}
`;
const ReadOnlyInputsWrapper = styled.div`
  display: flex;
  margin-top: var(--spacing-xl);
  padding: 12 var(--spacing);
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xl);
  font-size: 0.8rem;
`;

const InputLabel = styled.label`
  white-space: nowrap;
`;

const ReadOnlyInput = styled.input.attrs({
  disabled: true,
})`
  color: var(--text-normal);
  background: none;
  border: none;
  width: 100%;
  padding: 0;
`;

const OTROnly = styled.div`
  width: 150px;
  margin-top: 24px;
`;
export type CDXOrderDetailsPanelProps = {
  disabled?: boolean;
};

const CDXOrderDetailsPanel: React.FC<CDXOrderDetailsPanelProps> = ({
  disabled = false,
}) => {
  const { setValue } = useFormContext<CDXOrderFormValues>();
  const { cdxRoot, otrOnly, cdxInstrument, tenor } = useWatch({
    name: ["otrOnly", "cdxRoot", "cdxInstrument", "tenor"],
  });
  const [currency, setCurrency] = React.useState<string>("");
  const [tenors, setTenors] = React.useState<SelectOptionsType<Tenor>[]>([]);
  const isMobile = useIsContentMobile();

  const setMaturity = useRefCallback(
    (tenorShortName: string) => {
      if (tenorShortName && cdxInstrument?.startDate)
        setValue(
          "maturityDate",
          getMaturity(tenorShortName, cdxInstrument.startDate)
        );
      setValue("tenorShortName", tenorShortName);
    },
    [getMaturity, cdxInstrument?.startDate]
  );

  React.useEffect(() => {
    if (cdxInstrument?.id) {
      setCurrency(cdxInstrument?.currency?.code ?? "");
      setValue("cdxIndex", cdxInstrument?.id);
      if (
        cdxInstrument?.tenors &&
        cdxInstrument.tenors.length > 0 &&
        !disabled
      ) {
        setTenors(
          cdxInstrument.tenors.map((tenor: Tenor) => {
            return {
              value: tenor,
              label: tenor?.multiplier + tenor?.period?.charAt(0),
            };
          })
        );
        const selectedTenor = cdxInstrument.tenors[0];
        setValue("tenor", selectedTenor);
        const tenorShortName =
          selectedTenor.multiplier + selectedTenor.period?.charAt(0);
        setMaturity(tenorShortName);
      }
    } else {
      setCurrency("");
      setValue("maturityDate", "");
      setValue("cdxIndex", null);
      setValue("tenor", null);
      setTenors([]);
    }
  }, [cdxInstrument?.id]);

  React.useEffect(() => {
    if (tenor) {
      const tenorShortName = tenor.multiplier + tenor.period?.charAt(0);
      if (tenors?.length === 0) {
        setTenors([{ value: tenor, label: tenorShortName }]);
      }
      setMaturity(tenorShortName);
    }
  }, [tenor]);

  return (
    <CDXOrderDetailsPanelWrapper>
      <PanelRow>
        <CDXRootSelect disabled={disabled} />
      </PanelRow>
      <PanelRow>
        <OTROnly>
          <FormController
            name="otrOnly"
            inputId="otr-only"
            mobileBasis="30%"
            render={({ value, ref: _ref, ...rest }) => (
              <Checkbox
                {...rest}
                disabled={disabled}
                label="OTR Only"
                checked={value}
                labelPlacement="right"
              />
            )}
          />
        </OTROnly>
        <ControlledEmpty name="tenorShortName" />
        <ControlledEmpty name="cdxIndex" />
        <FormController
          name="cdxInstrument"
          inputId="cdx-instrument"
          mobileBasis="70%"
          render={({ value, onChange }) => (
            <CDXIndexSelect
              label="CDX Index"
              onChange={onChange}
              value={value}
              disabled={disabled || !cdxRoot!!}
              cdxOptions={{ cdxRoot: cdxRoot, otrOnly: !!otrOnly }}
            />
          )}
        />
      </PanelRow>
      <PanelRow>
        <div
          style={{
            flex: isMobile ? "1 0 auto" : "0 0 calc(33% - var(--spacing-l))",
          }}
        >
          <ControlledSelect
            name="tenor"
            label="Tenor"
            options={tenors}
            disabled={disabled}
            inputId="cdx-tenor-select-id"
          />
        </div>
        <ReadOnlyInputsWrapper>
          <FormController
            name="maturityDate"
            render={({ value }) => (
              <InputWrapper>
                <InputLabel>Maturity</InputLabel>
                <ReadOnlyInput key="maturity-readonly" value={value} />
              </InputWrapper>
            )}
            inputId="cdxMaturityDateId"
          />
          <InputWrapper>
            <InputLabel>Currency</InputLabel>
            <ReadOnlyInput key="notionacurrency-readonly" value={currency} />
          </InputWrapper>
        </ReadOnlyInputsWrapper>
      </PanelRow>
      <Controller name="instrumentId" render={() => <></>} />
    </CDXOrderDetailsPanelWrapper>
  );
};

export default CDXOrderDetailsPanel;
