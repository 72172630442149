import { useRefCallback } from "@enfusion-ui/hooks";
import { useWorkerModule } from "@enfusion-ui/web-workers";
// import { startOfDay, startOfMonth, startOfYear } from "date-fns";
import { debounce } from "lodash";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  AnalyticsBenchmark,
  AnalyticsFrequency,
  AnalyticsPivotsPerformance,
  AnalyticsPivotsRisk,
  AnalyticsRollUp,
  AnalyticsTimeFrame,
  AnalyticsTopN,
} from "../../views/analytics/types";
import {
  AnalyticsContext,
  AnalyticsData,
  AnalyticsPivotSelection,
} from "./context";

export type AnalyticsFormValues = {
  rollUp: AnalyticsRollUp;
  benchmark: AnalyticsBenchmark;
  topN: AnalyticsTopN;
  startDate: Date | null;
  endDate: Date | null;
  timeFrame: AnalyticsTimeFrame | null;
  frequency: AnalyticsFrequency;
  fund: unknown; // TODO

  performance: AnalyticsPivotsPerformance;
  risk: AnalyticsPivotsRisk;
};

const defaultValues: AnalyticsFormValues = {
  rollUp: "Issuer",
  benchmark: "SP500",
  topN: 10,
  startDate: new Date(2023, 0, 1), //startOfYear(startOfDay(new Date())),
  endDate: null,
  timeFrame: "YTD",
  frequency: "Annual",
  fund: null,

  performance: "Returns",
  risk: "Exposure",
};

export const AnalyticsContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const formMethods = useForm<AnalyticsFormValues>({ defaultValues });
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [data, setData] = React.useState<AnalyticsData | null>(null);

  const [pivots, setPivots] = React.useState<AnalyticsPivotSelection[]>([]);

  const { startDate, endDate } = formMethods.watch(["startDate", "endDate"]);

  const handleMessage = useRefCallback((message: Record<string, unknown>) => {
    const { type, payload } = message as unknown as {
      type: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any;
    };
    if (type === "sync") {
      setLoading(false);
      setData(payload.data);
    } else if (type === "error") {
      setLoading(false);
      setError(payload.message);
    } else if (type === "instrument-data") {
      setData((data) => {
        if (!data) return null;
        return {
          ...data,
          performanceDetails: {
            ...data.performanceDetails,
            instrumentValues: payload.instrumentValues,
          },
        };
      });
    }
  }, []);

  const { enableModule, postMessage, subscribeToModule } =
    useWorkerModule("analytics");

  React.useEffect(() => {
    enableModule();
    return subscribeToModule(handleMessage);
  }, []);

  const subscribe = React.useMemo(
    () =>
      debounce(
        (
          startDate?: string | Date | null,
          endDate?: string | Date | null,
          pivots: AnalyticsPivotSelection[] = []
        ) => {
          requestAnimationFrame(() => {
            postMessage({
              command: "subscribe",
              payload: {
                pivots,
                startDate: startDate ? (startDate as Date).toISOString() : "",
                endDate: endDate ? (endDate as Date).toISOString() : "",
              },
            });
          });
        },
        1000
      ),
    [postMessage]
  );

  React.useEffect(() => {
    subscribe(startDate, endDate, pivots);
  }, [startDate, endDate, pivots]);

  const changeInstruments = useRefCallback(
    debounce((instruments: number[]) => {
      if (!instruments?.length) return;
      postMessage({
        command: "performance-details",
        payload: { instruments },
      });
    }, 1000),
    [postMessage]
  );

  const value = React.useMemo(
    () => ({
      loading,
      error,
      data,
      setPivots,
      pivots,
      changeInstruments,
    }),
    [loading, error, data, setPivots, pivots, changeInstruments]
  );

  return (
    <AnalyticsContext.Provider value={value}>
      <FormProvider {...formMethods}>{children}</FormProvider>
    </AnalyticsContext.Provider>
  );
};
