import { WidgetComponentsDefinition } from "../../../types";
import PdfPortfolioAnalyticsSourcesSelectionWidget from "./PdfPortfolioAnalyticsSourcesSelectionWidget";
import PortfolioAnalyticsSourcesSelectionEditWidget from "./PortfolioAnalyticsSourcesSelectionEditWidget";
import PortfolioAnalyticsSourcesSelectionWidget from "./PortfolioAnalyticsSourcesSelectionWidget";

export const portfolioAnalyticsSourcesSelection: WidgetComponentsDefinition = {
  renderComponent: PortfolioAnalyticsSourcesSelectionWidget,
  editComponent: PortfolioAnalyticsSourcesSelectionEditWidget,
  renderPdfComponent: PdfPortfolioAnalyticsSourcesSelectionWidget,
};
