/* eslint-disable no-restricted-globals */
import { TimeProxy } from "@enfusion-ui/utils";
import { WebWorkerMessenger, } from "./types";
const RESPONSE_TIMEOUT = TimeProxy.MINUTES.TWO.AS_MILLISECONDS;
let sessionTimeout;
let responseTimeout;
let sessionExpired = false;
let timerTimeout = TimeProxy.minutes.fifteen;
let enabled = false;
const messenger = new WebWorkerMessenger();
const startTimer = () => {
    clearTimeout(responseTimeout);
    clearTimeout(sessionTimeout);
    sessionTimeout = setTimeout(() => {
        sessionExpired = true;
        messenger.broadcast({
            type: "state-update",
            payload: { sessionExpired },
        });
        startResponseTimer();
    }, timerTimeout.asMilliseconds);
};
const startResponseTimer = () => {
    clearTimeout(responseTimeout);
    // waiting for 2 min for the response --> after that logout
    responseTimeout = setTimeout(() => {
        messenger.broadcast({
            type: "shutdown",
            payload: {},
        });
    }, RESPONSE_TIMEOUT);
};
export const SessionTimerModule = {
    enable: (postMessage) => {
        enabled = true;
        messenger.send = postMessage;
        startTimer();
    },
    disable: () => {
        enabled = false;
        clearTimeout(responseTimeout);
        clearTimeout(sessionTimeout);
    },
    getCurrentState: () => {
        return {
            sessionExpired,
            timeout: timerTimeout.asMinutes,
        };
    },
    onTerminate: () => {
        enabled = false;
        clearTimeout(responseTimeout);
        clearTimeout(sessionTimeout);
    },
    onMessage: (data) => {
        const { command, payload } = data;
        if (enabled) {
            switch (command) {
                case "reset": {
                    if (!sessionExpired) {
                        startTimer();
                    }
                    break;
                }
                case "awake": {
                    sessionExpired = false;
                    startTimer();
                    messenger.broadcast({
                        type: "state-update",
                        payload: { sessionExpired, timeout: timerTimeout.asMinutes },
                    });
                    break;
                }
                case "set-timeout": {
                    timerTimeout = TimeProxy.get(payload.timeout, "minutes");
                    startTimer();
                    messenger.broadcast({
                        type: "state-update",
                        payload: { sessionExpired, timeout: timerTimeout.asMinutes },
                    });
                    break;
                }
            }
        }
    },
};
