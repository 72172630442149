import {
  CalculationMethodOptions,
  SelectOptionsType,
} from "@enfusion-ui/types";
import { Select } from "@enfusion-ui/web-components";
import * as React from "react";

export const CalculationMethodSelect: React.FC<{
  value: string | null;
  onChange: (option: SelectOptionsType<string> | null) => void;
  label?: string;
}> = ({ value, onChange, label = "Calculation Method" }) => {
  return (
    <Select
      label={label}
      value={CalculationMethodOptions.find(({ value: val }) => value === val)}
      options={CalculationMethodOptions}
      onChange={onChange}
      inputId="calculation-method-selection-id"
    />
  );
};
