import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { SelectOptionsType } from "@enfusion-ui/types";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

function getValues<T>(
  ids: T[],
  options: SelectOptionsType<T>[] = []
): SelectOptionsType<T>[] {
  const result: SelectOptionsType<T>[] = [];
  ids.forEach((id) => {
    const selectedOption = options.find((option) => option.value === id);
    if (selectedOption) {
      result.push(selectedOption);
    }
  });
  return result;
}

export type LedgerSelectProps = Omit<
  Partial<MultiSelectProps<number>>,
  "options" | "value" | "onChange"
> & {
  value?: string[] | null;
  label?: string;
  onChange?: (ids: string[] | null) => void;
};

export const ControlledLedgerSelect: React.FC<
  Omit<LedgerSelectProps, "value" | "onChange"> & { name: string }
> = ({ name, ...props }) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...rest }) => <LedgerSelect {...rest} {...props} />}
    />
  );
};

const LedgerSelect: React.FC<LedgerSelectProps> = ({
  onChange,
  label,
  value: values,
  inputId = "ledger-multi-selection-id",
  placeholder = "All",
  ...rest
}) => {
  const { options, loading } = useRestAbortableOptions(
    REST_API.REPORTS.GET_LEDGERS.FETCH,
    (l) => ({ label: l.glName, value: l.glId })
  );

  return (
    <MultiSelect
      {...rest}
      value={values ? getValues(values, options) : []}
      label={label}
      onChange={(selectedOptions) => {
        const newLedgers = selectedOptions?.length
          ? selectedOptions.map((opt) => opt?.value as string)
          : null;
        onChange?.(newLedgers);
      }}
      options={options}
      isLoading={loading}
      emptyText="No ledger found"
      inputId={inputId}
      placeholder={placeholder}
    />
  );
};

export default LedgerSelect;
