import GripHandle from "@app-components/control/GripHandle";
import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import { useAllowCustomOrderTicket } from "@app-utils/useAllowCustomOrderTicket";
import {
  BlotterRow,
  getCanCreateOrders,
  OrderColumnInfo,
} from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { AppEvent, AppEventCategories } from "@enfusion-ui/types";
import { createTestId } from "@enfusion-ui/utils";
import {
  AlignRightRow,
  IconButton,
  SecondaryTabList,
  TabTopActionBar,
  VerticallyResizable,
  ViewContainer,
  ViewContent,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  OEMS_EVENTS,
  OEMS_FILTERS,
  OemsBroadcastEvent,
  OemsFilterKey,
  OemsTransaction,
  styled,
  useAuth,
  useOEMS,
  useTabs,
  useThisTab,
} from "@enfusion-ui/web-core";
import { useWorkerModule } from "@enfusion-ui/web-workers";
import {
  faArrowRightArrowLeft,
  faArrowUpArrowDown,
  faCalendarDay,
  faCreditCard,
  faDollarSign,
  faGripHorizontal,
  faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { ColDef, GridReadyEvent, RowClickedEvent } from "ag-grid-community";
import { debounce, kebabCase } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useMeasure } from "react-use";

import OMSContentView from "../../components/display/OMSContentView";
import { useSettings } from "../../context/settings/context";
import OrderBlotter from "./components/blotters/OrderBlotter";
import { OrderDetails } from "./components/OrderDetails";
import columnStateToOrderColumnInfo from "./utils/columnsToOrderColumnInfo";
import { defaultOemsColDef } from "./utils/createColumnDefs";
import { persistOnColumnEvent } from "./utils/PersistColumnState";

const defaultColDef: ColDef = {
  ...defaultOemsColDef,
  menuTabs: ["generalMenuTab", "filterMenuTab"],
};

// #region -------------- styles -------------------------------------------------------------------
const OEMSMainContentInnerContainer = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--spacing);
  border-radius: var(--radius-l);
  box-sizing: border-box;
`;

const OEMSMainContentContainer = styled(ViewContent).attrs({
  fullHeight: true,
  fullWidth: true,
})`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--spacing);
  box-sizing: border-box;
`;

export const CountTitleContainer = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 14px;
  overflow-x: hidden;
  white-space: nowrap;
  padding-right: var(--spacing);
`;

export const Count = styled.div`
  height: 14px;
  font-size: 9px;
  padding: 1px 4px 1px 4px;
  margin-left: 8px;
  border-radius: 2px;
  background-color: var(--background-accent);
  justify-content: center;
  align-items: center;
  display: flex;
`;

const CountTitle: React.FC<{
  filterKey: OemsFilterKey;
}> = ({ filterKey }) => {
  const [data, setData] = React.useState<number>();
  const { postMessage } = useWorkerModule("oms");

  const oemsEventsChannel = useBroadcastChannel<OemsBroadcastEvent>(
    OEMS_EVENTS,
    async ({ action }) => {
      if (action === "update-tab-count") {
        const {
          payload: { count },
        } = await postMessage({
          payload: { filterKey },
          command: "fetch-count",
        });

        setData(count);
      }
    }
  );

  React.useEffect(() => {
    const fetchRecordCount = async () => {
      oemsEventsChannel.broadcastMessage({
        action: "update-tab-count",
      });
    };
    fetchRecordCount();
  }, [filterKey, oemsEventsChannel]);

  return <>{data}</>;
};
// #endregion -----------------------------------------------------------------------------------

const getFilterOptions = () => {
  const filterKeys = Object.keys(OEMS_FILTERS);
  return filterKeys.map((key: string) => {
    const filterKey = key as OemsFilterKey;
    const { title } = OEMS_FILTERS[filterKey];
    return {
      label: title,
      key: title,
      count: <CountTitle filterKey={filterKey} />,
    };
  });
};

const filterOptions = getFilterOptions();

const useStall = () => {
  const stallRef = React.useRef<boolean>(false);
  const isStalled = React.useCallback(() => stallRef.current, []);
  const stall = React.useCallback(() => (stallRef.current = true), []);
  const resume = React.useCallback(() => (stallRef.current = false), []);

  return { isStalled, stall, resume };
};

const BOTTOM_MIN_HEIGHT = 15; // px
const FILTER_STORAGE_KEY = "e-oems-orders-filter-state";

const OEMSView: React.FC<unknown> = () => {
  const { user } = useAuth();
  const [columnDefs, setColumnDefs] = useState<OrderColumnInfo[]>([]);
  const [parentRef, { width: parentWidth, height: parentHeight }] =
    useMeasure<HTMLDivElement>();

  const {
    orderColumns,
    updateOrderColumnsState,
    isInitialized,
    selectedFilter,
    setSelectedFilter,
    subscribe,
    openNewOrderTab,
  } = useOEMS();

  const canCreateOrders = React.useMemo(() => getCanCreateOrders(user), [user]);
  const { enableOEMSRealtimeUpdate } = useSettings();
  const allowCustomOrderTicket = useAllowCustomOrderTicket();

  const [orderId, setOrderId] = useState<number | null | undefined>(undefined);

  const gridRef = useRef<GridReadyEvent>();

  const [isGridReady, setIsGridReady] = React.useState<boolean>(false);
  const { getCurrentState } = useWorkerModule("oms");
  const { stall, isStalled, resume } = useStall();

  const storageKey = `OEMS-column-state-${user?.username}-${kebabCase(
    selectedFilter
  )}`;

  const filtersKeysRef = React.useRef(new Set<string>());

  const oemsEventsChannel = useBroadcastChannel<OemsBroadcastEvent>(
    OEMS_EVENTS,
    (ev) => {
      const action = ev.action;
      const eventPayload = ev.payload;

      switch (action) {
        case "set-tab-data": {
          const p = getCurrentState();
          p?.then(({ payload: { rows } }) => {
            if (eventPayload)
              gridRef.current?.api.updateGridOptions({
                rowData:
                  OEMS_FILTERS[eventPayload?.filter as OemsFilterKey].filter(
                    rows
                  ),
              });
            resume();
          });
          break;
        }
        case "reset-order-id": {
          eventPayload &&
            getCurrentState()?.then(({ payload: { rows } }) => {
              const filteredRows =
                OEMS_FILTERS[eventPayload.filter as OemsFilterKey].filter(rows);
              if (
                !filteredRows.find(
                  (e: BlotterRow) => e.orderId === eventPayload.orderId
                )
              ) {
                setOrderId(null);
              }
            });

          break;
        }
      }
    }
  );

  const handleTransaction = useRefCallback(
    (transaction: OemsTransaction) => {
      if (isGridReady) {
        switch (transaction.action) {
          case "blotter-sync": {
            const addRows = transaction.add;

            if (addRows) {
              const filteredRows = OEMS_FILTERS[selectedFilter].filter(addRows);
              gridRef.current?.api.updateGridOptions({ rowData: filteredRows });
            }
            break;
          }

          case "blotter-rows-update":
          case "blotter-row-update": {
            const addRows = transaction?.add ?? [];
            const updateRowsBase = transaction?.update ?? [];
            const updateRows =
              OEMS_FILTERS[selectedFilter].filter(updateRowsBase);
            const removeRows = updateRows.length === 0 ? updateRowsBase : [];

            const filteredAddRows =
              OEMS_FILTERS[selectedFilter].filter(addRows);

            let updateRoutesFor = new Set<number>();
            const addUpdateRoutes = (rows: BlotterRow[]) => {
              updateRoutesFor = rows.reduce((acc, e: BlotterRow) => {
                acc.add(e.orderId);
                return acc;
              }, updateRoutesFor);
            };
            addUpdateRoutes(filteredAddRows);
            addUpdateRoutes(updateRows);

            if (updateRoutesFor.size > 0) {
              [...updateRoutesFor].forEach((orderId) => {
                oemsEventsChannel.broadcastMessage({
                  action: "update-routes-for-order-id",
                  payload: { orderId },
                });
              });
            }

            if (filteredAddRows && filteredAddRows.length > 0) {
              oemsEventsChannel.broadcastMessage({
                action: "update-tab-count",
              });
            }

            if (!isStalled()) {
              gridRef.current?.api.applyTransaction({ add: filteredAddRows });

              if (filteredAddRows.length > 0) {
                oemsEventsChannel.broadcastMessage({
                  action: "update-tab-count",
                });
              }
            }

            if (!isStalled() && enableOEMSRealtimeUpdate) {
              gridRef.current?.api.applyTransactionAsync(
                {
                  update: updateRows,
                  remove: removeRows,
                },
                () => {
                  if (removeRows.length > 0) {
                    oemsEventsChannel.broadcastMessage({
                      action: "update-tab-count",
                    });
                  }
                }
              );
            }

            break;
          }

          case "order-delete": {
            const removeRows = OEMS_FILTERS[selectedFilter].filter(
              transaction?.remove ?? []
            );

            if (!isStalled() && enableOEMSRealtimeUpdate) {
              gridRef.current?.api.applyTransactionAsync(
                {
                  remove: removeRows,
                },
                () => {
                  if (removeRows.length > 0) {
                    oemsEventsChannel.broadcastMessage({
                      action: "update-tab-count",
                    });
                  }
                }
              );
            }

            break;
          }
        }
      }
    },
    [
      selectedFilter,
      isGridReady,
      oemsEventsChannel,
      enableOEMSRealtimeUpdate,
      isStalled,
      stall,
      resume,
    ]
  );

  const { onTabClose } = useTabs();

  React.useEffect(() => {
    AppLogging.event({
      event: AppEvent.OrderBlotterOpened,
      category: AppEventCategories.OEMS,
    });
    return subscribe(handleTransaction);
  }, []);

  React.useEffect(() => {
    !isInitialized && setIsGridReady(false);
  }, [isInitialized]);

  React.useEffect(() => {
    function selectAndDisplayRow(rowIndex: number) {
      const api = gridRef.current?.api;
      if (!api) return;
      const nextRow = api.getDisplayedRowAtIndex(rowIndex);
      if (nextRow) {
        nextRow.setSelected(true, true);
        api.ensureNodeVisible(nextRow, "middle");
      }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      const api = gridRef.current?.api;
      if (!api) return;
      const [selectedRowNode] = api.getSelectedNodes();

      if (event.key === "ArrowUp") {
        const nextIndex = selectedRowNode?.rowIndex || 0 - 1;
        if (nextIndex >= 0) selectAndDisplayRow(nextIndex);
      }

      if (event.key === "ArrowDown") {
        const nextIndex = selectedRowNode?.rowIndex || 0 + 1;
        if (nextIndex < api.getDisplayedRowCount())
          selectAndDisplayRow(nextIndex);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  React.useEffect(() => {
    if (orderColumns) {
      let columns = orderColumns[selectedFilter];
      if (columns) {
        if (user?.flags?.OMS.readOnly) {
          columns = columns.filter((i) => i.key !== "_Control_");
        }
        setColumnDefs(columns);
        persistOnColumnEvent(columns, storageKey);
        filtersKeysRef.current.add(selectedFilter);
      }
    }
  }, [selectedFilter, storageKey, user, orderColumns]);

  React.useEffect(() => {
    return onTabClose(() => {
      filtersKeysRef.current.forEach((key) => {
        localStorage.removeItem(
          `OEMS-column-state-${user?.username}-${kebabCase(key)}`
        );
      });
    });
  }, []);

  const onGridReady = useRefCallback(
    (gridReadyEvent: GridReadyEvent) => {
      gridRef.current = gridReadyEvent;
      const displayedRow = gridReadyEvent.api.getDisplayedRowAtIndex(0);
      setIsGridReady(true);

      if (displayedRow) displayedRow.setSelected(true, true);
    },
    [setIsGridReady]
  );

  React.useEffect(() => {
    if (isGridReady) {
      /**
       * We need to stall blotter updates when tabs are switched because the entire rowData is set
       * and during that time updates in previous state result in lot of console logs mentioning order ids not found
       */
      stall();
      oemsEventsChannel.broadcastMessage({
        action: "set-tab-data",
        payload: { filter: selectedFilter },
      });
    }
  }, [isGridReady, selectedFilter, oemsEventsChannel, stall]);

  const onColumnStateChanged = useRefCallback(
    debounce(async () => {
      if (gridRef.current) {
        const currentState = gridRef.current.api.getColumnState();
        const currentStateOrderColumnInfo =
          columnStateToOrderColumnInfo(currentState);

        if (currentStateOrderColumnInfo) {
          updateOrderColumnsState?.({
            [selectedFilter]: currentStateOrderColumnInfo,
          });

          persistOnColumnEvent(
            {
              [selectedFilter]: currentStateOrderColumnInfo,
            },
            storageKey
          );
        }
        if (!gridRef.current.api.getAllDisplayedColumns().length)
          setOrderId(null);
      }
    }, 200),
    [selectedFilter, storageKey]
  );

  const handleFilterSelect = useRefCallback(
    (value: unknown) => {
      setSelectedFilter(value as OemsFilterKey);
    },
    [setSelectedFilter]
  );

  // #region order-details
  const handleRowClicked = (e: RowClickedEvent) => {
    if (!e.api.getAllDisplayedColumns().length) setOrderId(null);
    else if (!e.node.group) {
      const newId = e?.data.orderId;
      AppLogging.event(
        {
          event: AppEvent.SelectOrderBlotterRow,
          category: AppEventCategories.OEMS,
        },
        { orderId: newId }
      );
      if (newId && newId !== orderId) {
        setOrderId(newId);
      }
    }
  };

  const handleGetFilterState = useRefCallback(() => {
    let filter = undefined;
    try {
      const filterState = JSON.parse(
        localStorage.getItem(FILTER_STORAGE_KEY) || "{}"
      );
      filter = filterState[selectedFilter];
    } catch (_error) {
      // no-op
    }
    return filter;
  }, [selectedFilter]);

  const handleSetFilterState = useRefCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    debounce((filters: Record<string, any>) => {
      let savedFilters = {};
      try {
        savedFilters = JSON.parse(
          localStorage.getItem(FILTER_STORAGE_KEY) || "{}"
        );
      } catch (_error) {
        // no-op
      }
      localStorage.setItem(
        FILTER_STORAGE_KEY,
        JSON.stringify({ ...savedFilters, [selectedFilter]: filters })
      );
    }, 1000),
    [selectedFilter]
  );

  useEffect(() => {
    if (typeof orderId === "number") {
      oemsEventsChannel.broadcastMessage({
        action: "reset-order-id",
        payload: { orderId, filter: selectedFilter },
      });
    }
  }, [selectedFilter, orderId, oemsEventsChannel]);

  const { width } = useThisTab();
  //#endregion order-details

  return (
    <ViewContainer>
      <TabTopActionBar>
        {isInitialized && !user?.flags?.OMS.readOnly && canCreateOrders && (
          <AlignRightRow flex>
            <IconButton
              hoverable
              title="New Order"
              icon={faPlus}
              onClick={() => openNewOrderTab("equity", "ignore")}
              data-e2e-id="oems-view-action-new-order"
            />
            {allowCustomOrderTicket && (
              <IconButton
                hoverable
                title="New Custom Order"
                icon={faGripHorizontal}
                onClick={() => openNewOrderTab("equity", "force")}
                data-e2e-id="oems-view-action-new-custom-order"
              />
            )}
            <IconButton
              hoverable
              title="New Fx Order"
              icon={faDollarSign}
              onClick={() => openNewOrderTab("fx")}
              data-e2e-id="oems-view-action-new-fx-order"
              data-testid={createTestId("oems-view-action-new-fx-order")}
            />
            <IconButton
              hoverable
              title="New Future Spread Order"
              icon={faCalendarDay}
              onClick={() => openNewOrderTab("futureSpread")}
              data-e2e-id="oems-view-action-new-future-spread-order"
              data-testid={createTestId(
                "oems-view-action-new-future-spread-order"
              )}
            />
            <IconButton
              hoverable
              title="New CDX Order"
              icon={faCreditCard}
              onClick={() => openNewOrderTab("cdx")}
              data-e2e-id="oems-view-action-new-cdx-order"
              data-testid={createTestId("oems-view-action-new-cdx-order")}
            />
            {process.env.REACT_APP_ENABLE_VAR_SWAP_ORDERS && (
              <IconButton
                hoverable
                title="New Variance Swap Order"
                icon={faArrowUpArrowDown}
                onClick={() => openNewOrderTab("varSwap")}
                data-testid={createTestId("variance-swap-order-btn")}
              />
            )}
            {process.env.REACT_APP_ENABLE_IRS_ORDERS && (
              <IconButton
                hoverable
                title="New IRS Order"
                icon={faArrowRightArrowLeft}
                onClick={() => openNewOrderTab("irs")}
                data-testid={createTestId("irs-order-btn")}
              />
            )}
          </AlignRightRow>
        )}
      </TabTopActionBar>
      <OEMSMainContentContainer bordered={false}>
        <OEMSMainContentInnerContainer>
          <OMSContentView>
            <SecondaryTabList
              value={selectedFilter}
              onSelect={handleFilterSelect}
              tabs={filterOptions}
              data-testid={createTestId(selectedFilter)}
            />
            <div
              style={{
                flex: 1,
                paddingBottom: "var(--spacing)",
              }}
            >
              <OrderBlotter
                data-testid={createTestId("grid", selectedFilter)}
                height="100%"
                columns={columnDefs}
                onGridReady={onGridReady}
                defaultColDef={defaultColDef}
                onRowClicked={handleRowClicked}
                setFilterState={handleSetFilterState}
                getFilterState={handleGetFilterState}
                onColumnStateChanged={onColumnStateChanged}
                maintainColumnOrder
              />
            </div>
            <VerticallyResizable
              open
              height={150}
              handle={<GripHandle />}
              width={(width || 16) - 16}
              minHeight={BOTTOM_MIN_HEIGHT}
            >
              <div ref={parentRef} style={{ width: "100%", height: "100%" }}>
                <OrderDetails
                  orderId={orderId || null}
                  parentWidth={parentWidth}
                  parentHeight={parentHeight}
                />
              </div>
            </VerticallyResizable>
          </OMSContentView>
        </OEMSMainContentInnerContainer>
      </OEMSMainContentContainer>
    </ViewContainer>
  );
};

export default OEMSView;
