import {
  Checkbox,
  DatePresetInput,
  LabeledButton,
  PanelRow,
} from "@enfusion-ui/web-components";
import { styled, useTabs } from "@enfusion-ui/web-core";
import { faBriefcase } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { useWatch } from "react-hook-form";

const PositionDetailSearchContainer = styled(PanelRow)`
  display: flex;
  gap: var(--spacing-xl);
  max-width: 50rem;
`;

export type PositionSearchCriteria = {
  newPositionDate: Date | string | null;
  newActiveOnly: boolean;
};
export type PositionDetailSearchProps = {
  disabled?: boolean;
  onSearchChange?: (searchCriteria: PositionSearchCriteria) => void;
  positionDate: Date | string | null;
  activeOnly: boolean;
  showRelatedPositionsBtn?: boolean;
};

const PositionDetailSearch: React.FC<PositionDetailSearchProps> = ({
  disabled,
  onSearchChange,
  positionDate,
  activeOnly,
  showRelatedPositionsBtn = false,
}) => {
  const instrument = useWatch({ name: "instrument" });
  const { openTab } = useTabs();
  const handleDateChange = (date: Date | string | null) => {
    onSearchChange?.({ newPositionDate: date, newActiveOnly: activeOnly });
  };

  const handleActiveOnlyChange = (checked: boolean) => {
    onSearchChange?.({ newActiveOnly: checked, newPositionDate: positionDate });
  };

  const openRPositionsTab = () => {
    openTab({
      component: "related-positions",
      name: "Related Positions",
      icon: faBriefcase,
      unique: "related-positions",
    });
  };

  return (
    <PositionDetailSearchContainer>
      <div style={{ flex: 2 }}>
        <DatePresetInput
          label="Position Date"
          value={positionDate}
          onChange={handleDateChange}
          disabled={disabled}
          data-e2e-id="position-date-picker"
        />
      </div>
      <div style={{ flex: 1 }}>
        <Checkbox
          label="Is Active"
          checked={activeOnly}
          onChange={handleActiveOnlyChange}
          disabled={disabled}
          topLabelPlaceholder
          data-e2e-id="is-active-checkbox"
        />
      </div>
      {showRelatedPositionsBtn && (
        <div>
          <LabeledButton
            disabled={!instrument}
            name="relatedPositionsBtn"
            onClick={openRPositionsTab}
          >
            Related
          </LabeledButton>
        </div>
      )}
    </PositionDetailSearchContainer>
  );
};

export default PositionDetailSearch;
