/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFileExplorerContext, } from "@enfusion-ui/core";
import { createTestId, getFileName as getFileNameDefault, } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";
import { faFile } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Checkbox } from "../../inputs/Checkbox";
const FileNodeContainer = styled.div `
  height: 23px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  padding: 1px 0px 2px 10px;
  ${({ selected }) => selected ? "background-color: var(--background-accent);" : ""}
  color: var(--text-normal);

  :hover {
    background-color: var(--background-accent);
    opacity: 0.9;
  }
`;
const FileNodeText = styled.div `
  margin-left: 10px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0px;
  flex: 1;
`;
const FileDateText = styled.div `
  margin-left: var(--spacing);
  margin-right: var(--spacing);
  font-size: 12px;
  overflow: hidden;
  width: 70px;
  color: var(--text-muted);
  white-space: nowrap;
  text-align: right;
  font-variant-numeric: tabular-nums lining-nums;
  min-width: 0px;
`;
const CheckContainer = styled.div `
  display: inline-block;
  margin-right: var(--spacing);
`;
export const FileNode = React.memo(function FileNode({ node, selected, fileIndex, onClick, onContextMenu, onCheckChange, tutorial, getFileIcon = () => faFile, getFileName = getFileNameDefault, getFileDate = () => "", getTooltipText = () => "", }) {
    const { checkSelections } = useFileExplorerContext();
    const name = getFileName(node);
    const icon = getFileIcon(node);
    const date = getFileDate(node);
    const tooltipText = getTooltipText(node).trim();
    return (React.createElement(FileNodeContainer, { onClick: onClick, onContextMenu: onContextMenu, selected: selected, "data-tutorial-step": fileIndex === 0 && tutorial ? `${tutorial}__file` : undefined, "data-e2e-id": createTestId("file-node", name), "data-testid": createTestId("file-node", name) },
        checkSelections && (React.createElement(CheckContainer, null,
            React.createElement(Checkbox, { noContainer: true, checked: selected, onChange: onCheckChange, iconSize: "1x", iconStyle: {} }))),
        icon && React.createElement(FontAwesomeIcon, { icon: icon }),
        React.createElement(FileNodeText, { title: tooltipText ? tooltipText : name }, name),
        date ? React.createElement(FileDateText, null, date) : null));
});
