import ControlledAccountSelect from "@app-components/inputs/account/ControlledAccountSelect";
import InstrumentMultiSelect from "@app-components/inputs/InstrumentSelect/InstrumentMultiSelect";
import {
  IncludeCashFlowsOptions,
  InstrumentInfo,
  valueDateOffsetTypeOptions,
  WebReportBookSelection,
} from "@enfusion-ui/types";
import {
  ControlledCheckbox,
  ControlledInputBase,
  ControlledSelect,
  DatePicker as DatePickerInput,
  FormController,
  PanelRow,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

import ControlledBookSelect from "../../../components/ReportQueryComponents/BookSelect";
import { DateSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledFinancialSubTypeSelect } from "../../../components/ReportQueryComponents/FinancialSubTypeSelect";
import { ControlledMarketEnvironmentSelect } from "../../../components/ReportQueryComponents/MarketEnvironmentSelect";
import { ControlledPortfolioGroupsSelect } from "../../../components/ReportQueryComponents/PortfolioGroupsSelect";
import { ControlledCashFlowSelect } from "./CashFlowSelect";
import ControlledFilterSelect from "./FilterSelect";

export const InputContainer = styled.div`
  min-width: 230px;
`;

const StyledPanelRow = styled(PanelRow)`
  margin-top: 1%;
`;

const PositionCashFlowSettings = () => {
  const { bookSelection } = useWatch({
    name: ["bookSelection"],
  });

  const defaultBookSelectionRef = React.useRef(
    bookSelection as WebReportBookSelection
  );

  return (
    <>
      <ControlledAccountSelect label="Account(s)" />
      <InputContainer>
        <ControlledPortfolioGroupsSelect name="portfolioGroupIds" />
      </InputContainer>
      <ControlledBookSelect
        defaultBookSelection={defaultBookSelectionRef.current}
        showIgnoreHierarchyCheckBox
      />
      <StyledPanelRow>
        <InputContainer>
          <ControlledInputBase
            name="instrumentIds"
            render={({ ref: _ref, onChange, ...rest }) => (
              <InstrumentMultiSelect
                {...rest}
                label="Instrument(s)"
                onChange={(instruments: InstrumentInfo[] | null) => {
                  onChange(instruments?.map((instrument) => instrument.id));
                }}
              />
            )}
          />
        </InputContainer>
        <ControlledFinancialSubTypeSelect
          name="financialSubTypes"
          selectProps={{ label: "Financial Type" }}
        />
      </StyledPanelRow>
      <StyledPanelRow>
        <InputContainer>
          <DateSelection
            name="dateSelection"
            label="Value Date"
            defaultValue={undefined}
          />
        </InputContainer>
        <ControlledSelect
          name="valueDateOffsetType"
          label="Value Date Offset Type"
          options={valueDateOffsetTypeOptions}
        />
      </StyledPanelRow>
      <StyledPanelRow>
        <InputContainer>
          <ControlledMarketEnvironmentSelect label="Market Environemnt" />
        </InputContainer>
        <FormController
          name="latestValueDate"
          render={({ ref: _ref, onChange, value, ...rest }) => (
            <DatePickerInput
              {...rest}
              label="Latest Value Date"
              value={value ? new Date(value) : null}
              onChange={onChange}
              dateFormat="MM/dd/yyyy"
              clearable
            />
          )}
        />
      </StyledPanelRow>
      <StyledPanelRow>
        <InputContainer>
          <ControlledSelect
            name="valueDateRange"
            label="Include Cash Flows"
            options={IncludeCashFlowsOptions}
          />
        </InputContainer>
        <ControlledCheckbox
          name="expandedCashFlows"
          label="Do Not Collapse Cash Flows"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </StyledPanelRow>
      <StyledPanelRow>
        <InputContainer>
          <ControlledCashFlowSelect />
        </InputContainer>
        <ControlledCheckbox
          name="includeExpiredFunds"
          label="Include Inactive Funds/Accounts"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </StyledPanelRow>

      <ControlledFilterSelect />
    </>
  );
};

export default PositionCashFlowSettings;
