import { IRSOrderFormValues, useOEMSOrderForm } from "@enfusion-ui/core";
import { SelectOptionsType } from "@enfusion-ui/types";
import {
  Checkbox,
  ControlledNumericInput,
  ControlledSelect,
  FormController,
  panelContentStyles,
  PanelRow,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import CCPSelect from "views/oems/components/CCPSelect";
import FCMDCMSelect from "views/oems/components/FCMDCMSelect";
import { getDisplayValue } from "views/oems/utils/getDisplayValue";

const IRSOrderDetailsPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  ${panelContentStyles}
`;

const ATM = styled.div`
  margin-top: auto;
`;

const TYPES = [
  { value: "Bilateral", label: "Bilateral" },
  { value: "Cleared", label: "Cleared" },
] as SelectOptionsType<string>[];

export type IRSClearingPanelProps = {
  disabled?: boolean;
};

const IRSClearingPanel: React.FC<IRSClearingPanelProps> = ({ disabled }) => {
  const [shouldDisableCCPFCM, setShouldDisabledCCPFCM] =
    React.useState<boolean>(true);
  const { completed, isTransmitted } = useOEMSOrderForm();

  const { setValue } = useFormContext<IRSOrderFormValues>();
  const { atm, clearingType } = useWatch({
    name: ["atm", "clearingType"],
  });

  React.useEffect(() => {
    setShouldDisabledCCPFCM(clearingType === "Bilateral");
    if (!clearingType || clearingType === "Bilateral") {
      setValue("ccp", null);
      setValue("fcmDcmId", null);
    }
  }, [clearingType]);

  return (
    <IRSOrderDetailsPanelWrapper>
      <PanelRow>
        <ControlledNumericInput
          id="fixed-notional-id"
          name="fixedNotional"
          label="Fixed Notional"
          mobileBasis="50%"
          min={0}
          disabled={completed || isTransmitted}
          defaultValue={0}
          dataTestId="fixed-notional-input"
          enableMultiplier
        />
        <ATM>
          <FormController
            name="atm"
            inputId="atm-id"
            mobileBasis="30%"
            render={({ value, ref: _ref, ...rest }) => (
              <Checkbox
                {...rest}
                disabled={disabled}
                label="ATM"
                onChange={() => {
                  setValue("rate", 0);
                  setValue("atm", !atm);
                }}
                checked={value}
                labelPlacement="right"
              />
            )}
          />
        </ATM>
        <ControlledNumericInput
          id="rate-id"
          name="rate"
          label="Rate"
          formatValue={(num) => getDisplayValue(num, 2) + "%"}
          defaultValue={0}
          mobileBasis="50%"
          min={0}
          disabled={atm || disabled}
          dataTestId="rate-input"
          hideControls
        />
      </PanelRow>
      <PanelRow grow>
        <ControlledSelect
          name="clearingType"
          label="Clearing Type"
          options={TYPES}
          clearable={false}
          disabled={disabled}
          inputId="clearing-type-selection-id"
        />
        <CCPSelect
          disabled={shouldDisableCCPFCM || !clearingType || disabled}
        />
        <FCMDCMSelect
          disabled={shouldDisableCCPFCM || !clearingType || disabled}
          label="FCM"
        />
      </PanelRow>
    </IRSOrderDetailsPanelWrapper>
  );
};

export default IRSClearingPanel;
