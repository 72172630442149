import DocumentSaveForm from "@app-components/modal/DocumentSaveForm";
import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import { usePortfolioExplorer } from "@app-context/portfolios/PortfolioExplorerProvider";
import ControlledAccountSelect from "@app-views/reports/components/ReportQueryComponents/AccountSelect";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  defaultPortfolioGroupingsV1,
  defaultPortfolioSettingsValueV1,
} from "@enfusion-ui/portfolios";
import {
  AppEvent,
  AppEventCategories,
  DashboardRoot,
} from "@enfusion-ui/types";
import { Modal, useNavBarState } from "@enfusion-ui/web-components";
import {
  AppLogging,
  errorToast,
  REST_API,
  useTabs,
} from "@enfusion-ui/web-core";
import { useTour } from "@reactour/tour";
import { cloneDeep } from "lodash";
import { useState } from "react";

import { getNewPortfolioTabContent } from "../utils";

export const CreatePortfolioModal = () => {
  const { openTab } = useTabs();
  const { closeNavBarTabOnMobile } = useNavBarState();
  const explorerChannel = useExplorerEvents("Portfolios");
  const { createPortfolioModalRoot, closeCreatePortfolioModal } =
    usePortfolioExplorer();
  const { isOpen } = useTour();
  const [workbenchName] = useState("New Workbench");

  const handleSubmit = useRefCallback(
    async (
      name: string,
      filePath: string,
      rootPath: DashboardRoot,
      args: Record<string, unknown>
    ) => {
      const path = `/${filePath}${name}.json`;
      const { fundIds } = args.accountSelect as { fundIds: number[] };

      if (fundIds.length === 0) {
        errorToast("Funds are required to create a workbench");
        return;
      }

      const result = await REST_API.PORTFOLIO.STORE_PORTFOLIO({
        name,
        path,
        rootPath,
        version: 0,
        config: {
          instrumentIds: [],
          fundIds,
          groupings: [...defaultPortfolioGroupingsV1],
        },
        settings: { ...cloneDeep(defaultPortfolioSettingsValueV1) },
      });

      if (result.success && result.filePath) {
        closeCreatePortfolioModal();
        explorerChannel.broadcast(rootPath, ExplorerEventsAction.Refetch);
        closeNavBarTabOnMobile();
        AppLogging.event(
          {
            event: AppEvent.CreateFile,
            category: AppEventCategories.Portfolios,
          },
          { name, root: rootPath, path: result.filePath }
        );
        openTab(
          getNewPortfolioTabContent({
            name,
            root: rootPath,
            path: result.filePath,
          })
        );
      } else {
        errorToast("Failed to create workbench");
      }
    },
    [explorerChannel]
  );

  return (
    <Modal
      animateEnabled={!isOpen}
      key={createPortfolioModalRoot ? "open" : "closed"}
      isOpen={!!createPortfolioModalRoot}
      onClose={closeCreatePortfolioModal}
      title="Create A New Workbench"
      dataE2EId="CreateWorkbenchModal"
      content={
        <DocumentSaveForm
          root={createPortfolioModalRoot || undefined}
          fileName={workbenchName}
          keyword="portfolio"
          onSubmit={handleSubmit}
          onCancel={closeCreatePortfolioModal}
          submitButtonText="Create"
          additionalRequiredFieldNames={["accountSelect"]}
        >
          <div style={{ width: "100%", overflow: "hidden" }}>
            <ControlledAccountSelect
              name="accountSelect"
              label="Funds"
              allowClickOutSide={!!isOpen}
              required
            />
          </div>
        </DocumentSaveForm>
      }
    />
  );
};
