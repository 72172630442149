import "flexlayout-react/style/dark.css";
import "react-toastify/dist/ReactToastify.css";

import ConnectionStatusView from "@app-components/ConnectionStatusView/ConnectionStatusView";
import WebAppNavBar from "@app-components/layout/WebAppNavBar";
import AcceptAllCookiesModal from "@app-components/modal/AcceptAllCookiesModal";
import ServicesIcon from "@app-components/ServicesIcon";
import { AppTutorialProvider } from "@app-components/Tour/AppTutorialProvider";
import { useAppTutorial } from "@app-components/Tour/context";
import { TutorialType } from "@app-components/Tour/utils";
import { AccountsProvider } from "@app-context/accounts/AccountsProvider";
import AllocationProvider from "@app-context/allocation/AllocationProvider";
import { useConnectionStatus } from "@app-context/connectionStatus/context";
import { ExplorerEventsProvider } from "@app-context/explorer/explorerEvents/ExplorerEventsProvider";
import { useGDPR } from "@app-context/gdpr/context";
import GDPRProvider from "@app-context/gdpr/GDPRProvider";
import { InterTabContextProvider } from "@app-context/InterTab/InterTabContextProvider";
import MarketDataProvider from "@app-context/marketData/MarketDataProvider";
import OEMSProvider from "@app-context/oems/OEMSProvider";
import { ReportsProvider } from "@app-context/reports/ReportsProvider";
import { useSession } from "@app-context/session/context";
import SessionProvider from "@app-context/session/SessionProvider";
import SettingsProvider from "@app-context/settings/SettingsProvider";
import WatchListProvider from "@app-context/watchList/WatchListProvider";
import { AnalyticsView } from "@app-views/analytics/AnalyticsView";
import ComplianceSidebar from "@app-views/compliance/ComplianceSidebar";
import DashboardsSidebar from "@app-views/dashboards/DashboardsSidebar";
import DashboardView from "@app-views/dashboards/DashboardView";
import { TextConfluenceView } from "@app-views/examples/Confluence/TestConfluenceView";
import { SunburstChartView } from "@app-views/examples/SunburstChart/SunburstChartView";
import FullQuoteView from "@app-views/factset/FullQuoteView";
import MarketWatchView from "@app-views/factset/MarketWatchView";
import FileView from "@app-views/file/FileView";
import GeneralFilesSidebar from "@app-views/files/GeneralFilesSidebar";
import LocateProvider from "@app-views/oems/context/LocatesProvider"; // TODO move to top level context
import PositionReportProvider from "@app-views/oems/context/PositionReportProvider"; // TODO move to top level context
import NewOrderView from "@app-views/oems/NewOrderView";
import OEMSView from "@app-views/oems/OEMS";
import OrderView from "@app-views/oems/OrderView";
import PortfolioSideBar from "@app-views/portfolios/PortfolioSideBar";
import PortfolioView from "@app-views/portfolios/PortfolioView";
import ReconciliationBreakDetailsView from "@app-views/reconciliation/components/ReconciliationBreakDetailsView";
import ReconciliationView from "@app-views/reconciliation/ReconciliationView";
import RelatedPositionsTab from "@app-views/relatedPositions/RelatedPositions";
import ReleaseNotesView from "@app-views/releaseNotes/ReleaseNotesView";
import ReportsSidebar from "@app-views/reports/ReportsSidebar";
import ReportView from "@app-views/reports/ReportView";
import ServicesMarketingView from "@app-views/services/ServicesMarketingView";
import ServicesSidebar from "@app-views/services/ServicesSidebar";
import SettingsView from "@app-views/settings/SettingsView";
import { NewTradeView } from "@app-views/trade/NewTradeView";
import WatchListSidebar from "@app-views/watchList/WatchListSidebar";
import WatchListView from "@app-views/watchList/WatchListView";
import { FACTSET_LOADED_KEY } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { useLocalStorage } from "@enfusion-ui/hooks/build/web";
import {
  AppEvent,
  AppEventCategories,
  ConnectionStatus,
  DashboardRoot,
} from "@enfusion-ui/types";
import {
  fileTreeEntriesToNodeData,
  getFileIcon,
  getFileNameDirect,
} from "@enfusion-ui/utils";
import {
  BottomInfoBar,
  CenterContent,
  ConfirmationModal,
  DuplicateLogInPage,
  ErrorBoundary,
  NavBarContext,
  NavBarSection,
  PermissionDeniedView,
  SideBar,
  SidebarProvider,
  Spinner,
  SVGBackground,
  TopAppBar,
  UndockedView,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  errorToast,
  FilesProvider,
  Flags,
  GeneralFilesContext,
  IS_LOCAL,
  isDesktop,
  OperationFilesContext,
  REST_API,
  ServicesFilesContext,
  styled,
  TabComponentUserPrefMap,
  TabConfig,
  TabDef,
  UnsavedChangeTrackerProvider,
  useAuth,
  useFlags,
  useIsMobileDevice,
  useIsTabletDevice,
  useReports,
  useTabs,
} from "@enfusion-ui/web-core";
import { TabLayout } from "@enfusion-ui/web-layout";
import { WorkerProvider } from "@enfusion-ui/web-workers";
import { faAbacus } from "@fortawesome/pro-regular-svg-icons";
import {
  faBinoculars,
  faChartBar,
  faClipboardCheck,
  faCog,
  faDisplay,
  faFile,
  faHandPointDown,
  faHandPointLeft,
  faLink,
  faNote,
  faQuoteLeft,
  faTable,
  faThumbtack,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { last } from "lodash";
import queryString from "query-string";
import * as React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useHistory } from "react-router-dom";
import { useBeforeUnload } from "react-use";
import { v4 as uuidv4 } from "uuid";

import EntryWorker from "../workers/entry.worker.ts";

const LayoutWrapper = styled.div<{ desktop: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  flex-direction: column;
  border-style: solid;
  border-width: ${({ desktop }) => (desktop ? 0.75 : 0)}px;
  border-color: var(--background-accent, black);
`;

const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  min-height: 0;
`;

const SidebarContainer = styled.div`
  overflow: hidden;
  height: 100%;
`;

export const TABS = {
  watchList: "Watch List",
  oems: "OEMS",
  compliance: "Compliance",
  reports: "Reports",
  services: "Services",
  generalFiles: "Files",
  dashboards: "Dashboards",
  factsetMarketWatch: "Market Watch",
  factsetFullQuote: "Full Quote",
  portfolios: "Portfolio Workbench",
  settings: "User Settings",
  releaseNotes: "Release Notes",
};

type Tab = NavBarSection & {
  content?: JSX.Element;
  tutorial?: TutorialType | null;
};

export const getNavTabs = (tutorial?: TutorialType | null) => {
  return {
    watchList: {
      iconDef: faThumbtack,
      name: TABS.watchList,
      content: <WatchListSidebar />,
    },
    oems: {
      iconDef: faDisplay,
      name: TABS.oems,
      canSelect: false,
    },
    compliance: {
      iconDef: faClipboardCheck,
      name: TABS.compliance,
      content: <ComplianceSidebar />,
    },
    dashboards: {
      iconDef: faChartBar,
      name: TABS.dashboards,
      content: <DashboardsSidebar />,
    },
    factsetMarketWatch: {
      iconDef: faBinoculars,
      name: TABS.factsetMarketWatch,
      canSelect: false,
    },
    factsetFullQuote: {
      iconDef: faQuoteLeft,
      name: TABS.factsetFullQuote,
      canSelect: false,
    },
    reports: {
      iconDef: faTable,
      name: TABS.reports,
      content: (
        <ReportsSidebar
          tutorial={
            tutorial === TutorialType.Reports ? TutorialType.Reports : null
          }
        />
      ),
      tutorial: tutorial === TutorialType.Reports ? tutorial : null,
    },
    services: {
      icon: <ServicesIcon />,
      name: TABS.services,
      content: (
        <ServicesSidebar
          tutorial={
            tutorial === TutorialType.ServicesMarketing ||
            tutorial === TutorialType.ServicesClient
              ? tutorial
              : null
          }
        />
      ),
      tutorial:
        tutorial === TutorialType.ServicesMarketing ||
        tutorial === TutorialType.ServicesClient
          ? tutorial
          : null,
    },
    generalFiles: {
      iconDef: faFile,
      name: TABS.generalFiles,
      content: <GeneralFilesSidebar />,
    },
    portfolios: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      iconDef: faAbacus as any,
      name: TABS.portfolios,
      content: <PortfolioSideBar />,
    },
  };
};

export const contentTabs = {
  oems: {
    component: "oems",
    name: "OEMS",
    unique: "oems",
    icon: faDisplay,
  } as TabConfig,
  marketing: {
    component: "services-marketing",
    name: "About Services",
    unique: "services-marketing",
    icon: faLink,
  } as TabConfig,
  factsetMarketWatch: {
    component: "factset-market-watch",
    unique: "factset-market-watch",
    icon: faBinoculars,
    name: TABS.factsetMarketWatch,
  } as TabConfig,
  factsetFullQuote: {
    component: "factset-full-quote",
    unique: "factset-full-quote",
    icon: faQuoteLeft,
    name: TABS.factsetFullQuote,
  } as TabConfig,
  settings: {
    component: "user-settings",
    unique: "user-settings",
    icon: faCog,
    name: TABS.settings,
  } as TabConfig,
  releaseNotes: {
    component: "release-notes",
    unique: "release-notes",
    icon: faNote,
    name: TABS.releaseNotes,
    focusIfFound: false,
  } as TabConfig,
} as const;

function getTabContent(def: TabDef) {
  switch (def.component) {
    case "watch-list": {
      return <WatchListView config={def.config} />;
    }
    case "oems": {
      return <OEMSView />;
    }
    case "oems-order": {
      return <OrderView config={def.config} />;
    }
    case "new-oems-order": {
      return <NewOrderView config={def.config} />;
    }
    case "report": {
      return <ReportView config={def.config} />;
    }
    case "file": {
      return <FileView config={def.config} />;
    }
    case "services-marketing": {
      return <ServicesMarketingView />;
    }
    case "dashboard": {
      return <DashboardView config={def.config} />;
    }
    case "factset-market-watch": {
      return <MarketWatchView />;
    }
    case "factset-full-quote": {
      return <FullQuoteView />;
    }
    case "portfolio-workbench": {
      return <PortfolioView config={def.config} />;
    }
    case "user-settings": {
      return <SettingsView />;
    }
    case "new-trade-ticket": {
      return <NewTradeView config={def.config} />;
    }
    case "related-positions": {
      return <RelatedPositionsTab />;
    }
    case "release-notes": {
      return <ReleaseNotesView />;
    }
    case "sunburst-view": {
      return <SunburstChartView />;
    }
    case "analytics-view": {
      return <AnalyticsView />;
    }
    case "reconciliation-view": {
      return <ReconciliationView />;
    }
    case "confluence-view": {
      return <TextConfluenceView />;
    }
    case "reconciliation-break-details": {
      return <ReconciliationBreakDetailsView config={def.config} />;
    }
    default: {
      return <>Need renderer for {def.component}</>;
    }
  }
}

function checkPermissionsBeforeRendering(flags: Flags) {
  return function (def: TabDef) {
    const flagMapping = TabComponentUserPrefMap[def.component];

    if (typeof flagMapping === "undefined") {
      return renderTab(def);
    }

    const flagKey =
      typeof flagMapping === "string" ? flagMapping : flagMapping(def);

    if (!(flagKey in flags)) {
      console.warn(`Invalid permission flag "${flagKey}"`);
      return renderTab(def);
    }

    if (!flags[flagKey]) {
      return <PermissionDeniedView component={def.component} />;
    }

    return renderTab(def);
  };
}

function renderTab(def: TabDef) {
  return (
    <ErrorBoundary showDetails={IS_LOCAL} showReset={IS_LOCAL}>
      {getTabContent(def)}
    </ErrorBoundary>
  );
}

function isValidString(value: string) {
  return typeof value === "string" && value.trim().length !== 0;
}

const AuthenticatedAppCore: React.FC<{
  linked?: boolean;
  flags: Flags;
  tab: string;
  isTabletDevice: boolean;
  setTab: React.Dispatch<React.SetStateAction<string>>;
}> = ({ linked, flags, tab, isTabletDevice, setTab }) => {
  const { tutorialType } = useAppTutorial();

  const { layoutModel, undockTab } = useTabs();
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [logoutConfirmationOpen, setLogoutConfirmationOpen] =
    React.useState<boolean>(false);
  const { user, isEnabled } = useAuth();
  const { openTab } = useTabs();
  const { openReportTab } = useReports();
  const { sessionExpired, resetTimer, logout } = useSession();
  const {
    acceptAllCookiesModalOpen,
    setAcceptAllCookiesModalOpen,
    toggleCustomizeCookies,
    acceptAll,
  } = useGDPR();
  const { setTourReady } = useAppTutorial();

  const isMobileDevice = useIsMobileDevice();
  const [resizeCountIgnored, setResizeCount] = React.useState(0);
  const history = useHistory();

  const [firstTimeCookie, setFirstTimeCookie] = useLocalStorage(
    `e-first-cookie-load-${user?.username}`,
    true
  );

  // const [lastLoadedVersion, setLastLoadedVersion] = useLocalStorage<string>(
  //   `e-last-loaded-version-${user?.username}`,
  //   ""
  // );

  const [view, setView] = React.useState<JSX.Element | undefined>();

  const handleHotkey = useRefCallback(() => {
    const selectedNode = layoutModel?.getActiveTabset()?.getSelectedNode();
    if (!!selectedNode) {
      undockTab(selectedNode.getId());
    }
  }, [layoutModel]);
  useHotkeys("ctrl+d", handleHotkey);

  const internalHandler = useRefCallback(() => {
    localStorage.removeItem(FACTSET_LOADED_KEY);
    return false;
  }, []);

  useBeforeUnload(internalHandler, "Please provide feedback");

  const omsDependentTabs = [getNavTabs().watchList, getNavTabs().oems];

  const activeTabs: Tab[] = [];
  if (flags.oems) activeTabs.push(...omsDependentTabs);
  if (flags.compliance) activeTabs.push(getNavTabs().compliance);
  if (flags.reports) activeTabs.push(getNavTabs(tutorialType).reports);
  if (flags.services) activeTabs.push(getNavTabs(tutorialType).services);
  if (flags.dashboards) activeTabs.push(getNavTabs().dashboards);
  if (flags.generalFiles) activeTabs.push(getNavTabs().generalFiles);
  if (flags.portfolios) activeTabs.push(getNavTabs().portfolios);

  if (flags.factset) {
    activeTabs.push(getNavTabs().factsetMarketWatch);
    activeTabs.push(getNavTabs().factsetFullQuote);
  }

  React.useEffect(() => {
    if (!firstTimeCookie) setTourReady();
  }, [firstTimeCookie]);

  // React.useEffect(() => {
  //   if (!!process.env.REACT_APP_ENABLE_RELEASE_NOTES) {
  //     if (lastLoadedVersion !== process.env.REACT_APP_VERSION)
  //       openTab([contentTabs.releaseNotes]);
  //     setLastLoadedVersion(process.env.REACT_APP_VERSION!);
  //   }
  // }, [user, flags, lastLoadedVersion]);

  React.useEffect(() => {
    const parseLinked = async () => {
      // eslint-disable-next-line no-restricted-globals
      const parsed = queryString.parse(location.search);
      if (parsed.report) {
        try {
          const { p } = JSON.parse(atob(parsed.report as string)) as {
            p: string;
          };
          const summary = await REST_API.REPORTS.GET_ENTRY(p);
          openReportTab({
            name: summary.name,
            path: summary.path,
            root: summary.root as DashboardRoot,
          });
        } catch (err) {
          errorToast("Failed to open report deep link");
          console.warn("Failed to open report deep link", err);
        }
      }
      if (parsed.serviceFile) {
        try {
          const { p } = JSON.parse(atob(parsed.serviceFile as string)) as {
            p: string;
          };
          const response = await REST_API.STORAGE.LIST_NODES.FETCH({
            root: "services",
            path: p,
          });
          const data = fileTreeEntriesToNodeData([response])[0];
          if (data.file) {
            const fileName = data.name as string;

            openTab({
              component: "file",
              name: fileName,
              icon: getFileIcon(data),
              config: { fileName, filePath: data.path, data },
            });
          }
        } catch (err) {
          errorToast("Failed to open service file deep link");
          console.warn("Failed to open service file deep link", err);
        }
      }
      if (parsed.generalFile) {
        try {
          const { p } = JSON.parse(atob(parsed.generalFile as string)) as {
            p: string;
          };
          const response = await REST_API.STORAGE.LIST_NODES.FETCH({
            root: "general",
            path: p,
          });
          const data = fileTreeEntriesToNodeData([response])[0];
          if (data.file) {
            const fileName = data.name as string;

            openTab({
              component: "file",
              name: fileName,
              icon: getFileIcon(data),
              config: { fileName, filePath: data.path, data },
            });
          }
        } catch (err) {
          errorToast("Failed to open file deep link");
          console.warn("Failed to open file deep link", err);
        }
      }
      if (parsed.portfolio) {
        try {
          const { p: filePath, r: root } = JSON.parse(
            atob(parsed.portfolio as string)
          ) as {
            p: string;
            r: DashboardRoot;
          };

          if (!isValidString(filePath) || !isValidString(root))
            throw new Error(
              "Invalid root or filePath - root: " +
                root +
                ", filePath: " +
                filePath
            );

          const windowTitle = getFileNameDirect(
            last(filePath.trim().split("/")) ?? ""
          );

          window.document.title = `Portfolio Workbench${
            windowTitle ? ` - ${windowTitle}` : ""
          }`;

          setView(<PortfolioView config={{ id: uuidv4(), root, filePath }} />);
        } catch (err) {
          errorToast("Failed to open workbench deep link");
          console.warn("Failed to open workbench deep link", err);
        }
      }
    };

    if (linked) {
      parseLinked();
      history.replace("/");
    }
  }, [linked]);

  React.useEffect(() => {
    sessionExpired && setLogoutConfirmationOpen(true);
  }, [sessionExpired]);

  const currentTabIndex = activeTabs.findIndex(({ name }) => name === tab);

  const handleSelection = React.useCallback(
    (index: number) => {
      const selectedTab = index === -1 ? "" : activeTabs[index].name;
      if (selectedTab === TABS.oems) {
        openTab(contentTabs.oems);
      } else if (selectedTab === TABS.factsetFullQuote) {
        openTab(contentTabs.factsetFullQuote);
      } else if (selectedTab === TABS.factsetMarketWatch) {
        openTab(contentTabs.factsetMarketWatch);
      } else if (
        process.env.REACT_APP_ENABLE_SERVICES_MARKETING &&
        selectedTab === TABS.services &&
        !isEnabled("Services")
      ) {
        openTab(contentTabs.marketing);
        setTab(selectedTab);
      } else {
        setTab(selectedTab);
      }

      if (selectedTab) {
        AppLogging.event(
          {
            event: AppEvent.TabSelection,
            category: AppEventCategories.Navigation,
            gaValue: index,
            gaLabel: selectedTab,
          },
          {
            selectedTab,
          }
        );
      }
    },
    [activeTabs]
  );

  const toggleRefreshFlag = React.useCallback(() => {
    setRefresh((flag) => !flag);
  }, []);

  const closeNavBarTabOnMobile = React.useCallback(() => {
    if (isMobileDevice) handleSelection(-1);
  }, [isMobileDevice]);

  const handleSidebarResize = () =>
    setResizeCount((state) => (state === 100 ? 0 : state + 1));

  const handleAcceptAllCookiesClose = () => {
    setFirstTimeCookie(false);
    setAcceptAllCookiesModalOpen(false);
  };

  const handleAcceptCookies = () => {
    setFirstTimeCookie(false);
    acceptAll();
  };

  const handleCustomize = () => {
    setFirstTimeCookie(false);
    toggleCustomizeCookies();
  };

  const resetSessionTimeout = () => {
    resetTimer();
    setLogoutConfirmationOpen(false);
  };

  const handleSessionTimeout = () => logout();

  if (view) return view;

  return (
    <>
      <NavBarContext.Provider
        value={{
          sections: activeTabs,
          selectedIndex: currentTabIndex,
          onSelection: handleSelection,
          onSave: toggleRefreshFlag,
          closeNavBarTabOnMobile,
        }}
      >
        <LayoutWrapper desktop={isDesktop()}>
          <TopAppBar titleLeftContent={<ConnectionStatusView />} />
          <MainWrapper>
            <WebAppNavBar
              style={{ display: isTabletDevice ? "none" : "flex" }}
            />
            <SidebarContainer>
              <SidebarProvider
                onResize={handleSidebarResize}
                refreshFlag={refresh}
              >
                {activeTabs.map(({ content, name }, idx) => (
                  <SideBar
                    key={name}
                    fullWidth={isMobileDevice}
                    open={idx === currentTabIndex && !!content}
                  >
                    {content}
                  </SideBar>
                ))}
              </SidebarProvider>
            </SidebarContainer>
            <TabLayout
              renderTab={checkPermissionsBeforeRendering(flags)}
              emptyMessage={
                <>
                  <FontAwesomeIcon
                    icon={isMobileDevice ? faHandPointDown : faHandPointLeft}
                    size="3x"
                    style={{ marginRight: "var(--spacing-xxl)" }}
                  />
                  Welcome to the Enfusion Web App. Please select a file or
                  report from the {isMobileDevice ? "bottom" : "left"} to begin.
                </>
              }
              tutorial={tutorialType}
            />
          </MainWrapper>
          <WebAppNavBar
            horizontal
            style={{ display: isTabletDevice ? "flex" : "none" }}
          />
          <BottomInfoBar
            style={{ display: isTabletDevice ? "none" : "flex" }}
          />
        </LayoutWrapper>
      </NavBarContext.Provider>
      {firstTimeCookie ? (
        <AcceptAllCookiesModal
          open={firstTimeCookie && !acceptAllCookiesModalOpen}
          onAccept={handleAcceptCookies}
          onCustomize={handleCustomize}
          onCancel={handleAcceptAllCookiesClose}
        />
      ) : null}
      <ConfirmationModal
        title="Session Timeout"
        cancelButtonText="Logout Now"
        submitButtonText="Stay Logged In"
        submitActionTheme="danger"
        open={logoutConfirmationOpen}
        allowDismissal={false}
        onSubmit={resetSessionTimeout}
        onCancel={handleSessionTimeout}
      >
        For your security you will be automatically logged out in 2 mins.
      </ConfirmationModal>
    </>
  );
};

type ProviderGroupProps = React.PropsWithChildren<{ flags: Flags }>;

const OEMSProviders: React.FC<ProviderGroupProps> = ({ flags, children }) => {
  const enabled = React.useMemo(() => flags.oems, [flags.oems]);
  return (
    <OEMSProvider enabled={enabled}>
      <WatchListProvider enabled={enabled}>
        <MarketDataProvider enabled={enabled}>
          <AllocationProvider enabled={enabled}>
            <LocateProvider enabled={enabled}>{children}</LocateProvider>
          </AllocationProvider>
        </MarketDataProvider>
      </WatchListProvider>
    </OEMSProvider>
  );
};
// const enabled = true; //flags.reports;
const ReportProviders: React.FC<ProviderGroupProps> = ({ flags, children }) => {
  return (
    <ReportsProvider enabled orderActionsEnabled={flags.oems}>
      <AccountsProvider enabled>
        <PositionReportProvider enabled>{children}</PositionReportProvider>
      </AccountsProvider>
    </ReportsProvider>
  );
};

const FileProviders: React.FC<ProviderGroupProps> = ({ flags, children }) => {
  return (
    <FilesProvider
      root="general"
      enabled={flags.generalFiles}
      StateProvider={GeneralFilesContext.Provider}
    >
      <FilesProvider
        root="services"
        enabled={flags.services}
        StateProvider={ServicesFilesContext.Provider}
      >
        <FilesProvider
          root="operations"
          enabled={flags.operationsFiles}
          StateProvider={OperationFilesContext.Provider}
        >
          {children}
        </FilesProvider>
      </FilesProvider>
    </FilesProvider>
  );
};

const AuthenticatedApp: React.FC<{ linked?: boolean; undocked?: boolean }> = ({
  linked,
  undocked = false,
}) => {
  const { user, isLoggedInDesktop } = useAuth();
  const { reportSocketConnectionStatus } = useConnectionStatus();
  const isTabletDevice = useIsTabletDevice();
  const [tab, setTab] = useLocalStorage(
    `e-nav-tab-${user?.username}`,
    TABS.reports
  );

  const flags = useFlags();

  if (
    !undocked &&
    reportSocketConnectionStatus === ConnectionStatus.WAITING_ON_MARKET_DATA
  ) {
    return (
      <SVGBackground>
        <CenterContent fillHeight fillWidth style={{ flexDirection: "column" }}>
          <Spinner style={{ height: "fit-content" }} />
          <div>Loading market data session ...</div>
        </CenterContent>
      </SVGBackground>
    );
  }

  return (
    <InterTabContextProvider>
      <UnsavedChangeTrackerProvider>
        <ExplorerEventsProvider>
          <WorkerProvider EntryWorker={EntryWorker}>
            <SessionProvider>
              <GDPRProvider>
                <SettingsProvider>
                  <FileProviders flags={flags}>
                    <AppTutorialProvider tab={tab}>
                      <ReportProviders flags={flags}>
                        <OEMSProviders flags={flags}>
                          {isLoggedInDesktop && (
                            <DuplicateLogInPage undocked={undocked} />
                          )}
                          {undocked ? (
                            <UndockedView
                              renderTab={checkPermissionsBeforeRendering(flags)}
                            />
                          ) : (
                            <AuthenticatedAppCore
                              flags={flags}
                              linked={linked}
                              tab={tab}
                              setTab={setTab}
                              isTabletDevice={isTabletDevice}
                            />
                          )}
                        </OEMSProviders>
                      </ReportProviders>
                    </AppTutorialProvider>
                  </FileProviders>
                </SettingsProvider>
              </GDPRProvider>
            </SessionProvider>
          </WorkerProvider>
        </ExplorerEventsProvider>
      </UnsavedChangeTrackerProvider>
    </InterTabContextProvider>
  );
};

export default AuthenticatedApp;
