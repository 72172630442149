import { useRefCallback } from "@enfusion-ui/hooks";
import {
  IGNORED_TRADE_BOOKING_STATUS_OPTIONS,
  SelectOptionsType,
  TradeBookingStatus,
} from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import * as React from "react";

export type TradeBookingStatusSelectProps = Omit<
  Partial<MultiSelectProps<TradeBookingStatus>>,
  "options" | "value" | "onChange"
> & {
  value?: TradeBookingStatus[] | null;
  onChange?: (values: TradeBookingStatus[]) => void;
};

const TradeBookingStatusSelect: React.FC<
  TradeBookingStatusSelectProps & { label?: string; placeholder: string }
> = ({ value: values, onChange, placeholder, label, ...rest }) => {
  const [selectedTradeBookingStatusTypes, setSelectedTradeBookingStatusTypes] =
    React.useState<TradeBookingStatus[] | null>([]);

  React.useEffect(() => {
    values && setSelectedTradeBookingStatusTypes(values);
  }, [values]);

  const handleChange = useRefCallback(
    (values: SelectOptionsType<TradeBookingStatus>[]) => {
      const newTradeBookingStatus =
        values?.map(
          (opt: SelectOptionsType<TradeBookingStatus>) => opt?.value
        ) || [];
      setSelectedTradeBookingStatusTypes(newTradeBookingStatus);
      onChange?.(newTradeBookingStatus);
    },
    [onChange]
  );

  return (
    <MultiSelect
      {...rest}
      value={
        selectedTradeBookingStatusTypes
          ? getSelectedValues(
              selectedTradeBookingStatusTypes,
              IGNORED_TRADE_BOOKING_STATUS_OPTIONS
            )
          : []
      }
      onChange={(selectedOptions) =>
        handleChange(selectedOptions as SelectOptionsType<TradeBookingStatus>[])
      }
      label={label}
      placeholder={placeholder}
      options={IGNORED_TRADE_BOOKING_STATUS_OPTIONS}
    />
  );
};

export const ControlledTradeBookingStatusSelect: React.FC<
  Omit<TradeBookingStatusSelectProps, "value" | "onChange"> & {
    name?: string;
    label?: string;
    placeholder?: string;
  }
> = ({
  name = "ignoredTradeBookingStatus",
  label = "Ignored Booking Status",
  placeholder = "",
  ...props
}) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...renderProps }) => (
        <TradeBookingStatusSelect
          label={label}
          placeholder={placeholder}
          {...renderProps}
          {...props}
        />
      )}
    />
  );
};

export default TradeBookingStatusSelect;
