import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { ControlledSelect } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";

const CounterPartySelect = () => {
  const { options, loading } = useRestAbortableOptions(
    REST_API.LEGALENTITY.GET_ALL_COUNTERPARTIES.FETCH,
    (cp) => ({
      value: cp.counterpartyId,
      label: `[${cp.counterpartyShortName || "n / a"}] ${cp.counterpartyName}`,
    })
  );

  return (
    <ControlledSelect
      name="counterpartyId"
      label="CounterParty"
      options={options}
      isLoading={loading}
    />
  );
};

export default CounterPartySelect;
