/* eslint-disable camelcase */
import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { TimeProxy } from "@enfusion-ui/utils";
import {
  createReportColumnDefs,
  errorToast,
  OEMS_EVENTS,
  OemsBroadcastEvent,
  PositionFinderRowData,
  PositionReportContext,
  PositionReportData,
  REST_API,
} from "@enfusion-ui/web-core";
import { ColDef } from "ag-grid-community";
import { debounce } from "lodash";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";

const INTERVAL = TimeProxy.minutes.five.asMilliseconds;

const PositionReportProvider: React.FC<
  React.PropsWithChildren<{ enabled: boolean }>
> = ({ children, enabled }) => {
  const [gridData, setGridData] = React.useState<PositionReportData>({
    columnDefs: [],
    rowData: [],
  });
  const isMounted = useMounted();
  const intervalRef = React.useRef<number | undefined>(undefined);

  const fetchPositionsReport = useRefCallback(
    debounce(async () => {
      if (enabled) {
        try {
          const webReport = await REST_API.OEMS.GET_ALL_POSITIONS.FETCH();

          const colDefs: Array<ColDef> = createReportColumnDefs(
            webReport.tableMetadata
          );
          if (isMounted()) {
            const reportData =
              webReport?.rows?.map(
                (i) => ({ ...i, __row_idx: uuidv4() } as PositionFinderRowData)
              ) ?? [];

            setGridData((existingData) => ({
              ...existingData,
              columnDefs: colDefs,
              rowData: reportData,
            }));
          }
        } catch (err) {
          errorToast("Failed to pull current position information");
          console.error(err);
        }
      }
    }, 500),
    [enabled]
  );

  const setUpInterval = useRefCallback(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(
      fetchPositionsReport,
      INTERVAL
    ) as unknown as number;
  }, [enabled]);

  useBroadcastChannel<OemsBroadcastEvent>(OEMS_EVENTS, (ev) => {
    if (enabled && ev.action === "open-order-tab") {
      setUpInterval();
      fetchPositionsReport();
    }
  });

  React.useEffect(() => {
    setUpInterval();
    fetchPositionsReport();
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [enabled]);

  return (
    <PositionReportContext.Provider value={gridData}>
      {children}
    </PositionReportContext.Provider>
  );
};

export default PositionReportProvider;
