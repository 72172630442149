export let reportProviderState = {
    openSubscriptions: {},
    metaStore: {},
    rowsStore: {},
    tableData: {},
};
export const setReportProviderState = (newReportProviderState = {
    openSubscriptions: {},
    metaStore: {},
    tableData: {},
    rowsStore: {},
}) => (reportProviderState = newReportProviderState);
export const getReportProviderState = () => reportProviderState;
