import { useRefCallback } from "@enfusion-ui/hooks";
import {
  CorporateActionOptions,
  CorporateActionType,
  SelectOptionsType,
} from "@enfusion-ui/types";
import { getSelectedValues } from "@enfusion-ui/utils";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import * as React from "react";

export type CorporateActionsSelectProps = Omit<
  Partial<MultiSelectProps<CorporateActionType>>,
  "options" | "value" | "onChange"
> & {
  value?: CorporateActionType[] | null;
  onChange?: (values: CorporateActionType[]) => void;
  label?: string;
};

const CorporateActionsSelect: React.FC<CorporateActionsSelectProps> = ({
  value: values,
  onChange,
  label,
  ...rest
}) => {
  const [selectedCorporateActionTypes, setSelectedCorporateActionTypes] =
    React.useState<CorporateActionType[] | null>([]);

  React.useEffect(() => {
    setSelectedCorporateActionTypes(values ?? []);
  }, [values]);

  const handleChange = useRefCallback(
    (values: SelectOptionsType<CorporateActionType>[]) => {
      const selectedValues = values?.length
        ? values.map(
            (option: SelectOptionsType<CorporateActionType>) => option.value
          )
        : [];
      setSelectedCorporateActionTypes(selectedValues);
      onChange?.(selectedValues);
    },
    [onChange]
  );

  return (
    <MultiSelect
      {...rest}
      value={
        selectedCorporateActionTypes
          ? getSelectedValues(
              selectedCorporateActionTypes,
              CorporateActionOptions
            )
          : []
      }
      onChange={(values) =>
        handleChange(values as SelectOptionsType<CorporateActionType>[])
      }
      label={label}
      options={CorporateActionOptions}
    />
  );
};

export const ControlledCorporateActionsSelect: React.FC<
  Omit<CorporateActionsSelectProps, "value" | "onChange"> & { name?: string }
> = ({
  name = "corporateActionTypes",
  label = "Corporate Action Types",
  ...props
}) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...renderProps }) => (
        <CorporateActionsSelect label={label} {...renderProps} {...props} />
      )}
    />
  );
};

export default CorporateActionsSelect;
