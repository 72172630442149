import {
  ControlledDatePicker,
  FormController,
  FormPanel,
  PanelRow,
  TertiaryTabList,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

import { HorizontalRule } from "../../../../styledComponents";
import { DoubleBarrier } from "./tabs/DoubleBarrier";
import { SingleBarrier } from "./tabs/SingleBarrier";

const tabButtons = [
  { label: "No Barriers", key: "NoBarriers" },
  {
    label: "Single Barrier",
    key: "SingleBarrier",
  },
  {
    label: "Double Barrier",
    key: "DoubleBarrier",
  },
];

const BarrierTabList = styled(TertiaryTabList)`
  margin-top: var(--spacing-l);
`;

export const BarriersPanel: React.FC<unknown> = () => {
  const barriers = useWatch({ name: "barriers" });

  return (
    <FormPanel
      title="Barriers"
      numColumns={1}
      collapsible
      defaultOpen
      keepRendered
      defaultRendered
      lineStyle
      tabFocusOutline={false}
      dataE2EId="barriers-panel"
    >
      <>
        <PanelRow>
          <FormController
            name="barriers"
            inputId="web-trade-barriers-type"
            render={({ value, onChange }) => (
              <BarrierTabList
                tabs={tabButtons}
                value={value}
                onSelect={onChange}
                scrollToView={false}
              />
            )}
          />
        </PanelRow>
        {barriers === "SingleBarrier" ? (
          <SingleBarrier />
        ) : barriers === "DoubleBarrier" ? (
          <DoubleBarrier />
        ) : null}
        <HorizontalRule />
        <ControlledDatePicker
          name="noBarriersTriggerDate"
          label="Trigger Date"
          controllerStyle={{ margin: "0 0 var(--spacing-xl) 0" }}
        />
      </>
    </FormPanel>
  );
};
