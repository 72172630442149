import {
  PencilIconButtonCellRenderer,
  TrashIconButtonCellRenderer,
} from "@app-components/cells";
import { RoundedDataGrid } from "@app-components/DataGrid";
import { useFormTableListContext } from "@app-utils/useFormTableListContext";
import {
  useModalState,
  useRefCallback,
  useRestAbortableOptions,
} from "@enfusion-ui/hooks";
import {
  AccrualEntity,
  AccrualFrequency,
  accrualFrequencyOptions,
  AccrualStepEntity,
  TradeTicketFormValues,
  useTradeTicket,
} from "@enfusion-ui/trades";
import { getSelectOption } from "@enfusion-ui/utils";
import { ControlledEmpty, FormPanel } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import {
  CellValueChangedEvent,
  ColDef,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import * as React from "react";
import { useWatch } from "react-hook-form";

import {
  FullHeightContainer,
  TabContentContainer,
  TicketTabHeaderButton,
} from "./components/styledComponents";
import { TradeTicketAccrualDetailModal } from "./components/TradeTicketAccrualDetailModal";

const columnDefs: ColDef[] = [
  {
    headerName: "",
    field: "selection",
    width: 40,
    pinned: "left",
    lockPinned: true,
    resizable: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: "ID",
    field: "id",
    initialWidth: 60,
  },
  {
    headerName: "Description",
    field: "description",
    initialWidth: 100,
  },
  {
    headerName: "Currency",
    field: "currency",
    initialWidth: 100,
    type: "currency",
  },
  {
    headerName: "Period",
    field: "period",
    initialWidth: 100,
  },
  {
    headerName: "Frequency",
    field: "frequency",
    initialWidth: 100,
    valueFormatter: ({ value }: ValueFormatterParams) =>
      getSelectOption<AccrualFrequency>(accrualFrequencyOptions, value)
        ?.label ?? "",
  },
  {
    headerName: "#Steps",
    field: "numOfSteps",
    initialWidth: 100,
    valueGetter: ({ data }) => data.steps?.length ?? 0,
  },
  {
    headerName: "Enabled",
    field: "enabled",
    initialWidth: 100,
  },
  {
    width: 40,
    headerName: "",
    field: "modify",
    pinned: "right",
    lockPinned: true,
    resizable: false,
    type: "modifyAction",
    cellStyle: { padding: "0" },
  },
  {
    width: 40,
    headerName: "",
    field: "remove",
    pinned: "right",
    lockPinned: true,
    resizable: false,
    type: "removeAction",
    cellStyle: { padding: "0" },
  },
];

export const AccrualsTab: React.FC = () => {
  const [modifyingNode, setModifyingNode] = React.useState<IRowNode | null>(
    null
  );

  const { open, openModal, closeModal } = useModalState({
    onClose: () => setModifyingNode(null),
  });
  const { accruals = [] } = useWatch<TradeTicketFormValues>({
    name: ["accruals"],
  });
  const { accrualsControl } = useTradeTicket();

  const { options: currencyOptions } = useRestAbortableOptions(
    REST_API.INSTRUMENT.GET_SETTLEMENT_CURRENCIES.FETCH,
    {
      map: (td) => ({ value: td.id, label: td.code }),
    }
  );

  const onSubmitAccrualDetail = useRefCallback(
    (entity: AccrualEntity) => {
      if (modifyingNode === null) {
        accrualsControl.addRow(entity);
      } else {
        accrualsControl.modifyRow(
          modifyingNode.data.__row_id as string,
          entity
        );
      }
    },
    [modifyingNode, accrualsControl.addRow, accrualsControl.modifyRow]
  );

  const openModifying = useRefCallback((node: IRowNode) => {
    setModifyingNode(node);
    openModal();
  }, []);

  const currencyValueGetter = useRefCallback(
    (params: ValueGetterParams) => {
      // handle the case of accrual step has multiple currency types
      const steps = params.data.steps as AccrualStepEntity[];
      if (!steps || !steps.length) return "";

      const result = steps.reduce((res, step) => {
        res.add(
          getSelectOption<number>(currencyOptions, step.currencyId)?.label ?? ""
        );
        return res;
      }, new Set() as Set<string>);
      return [...result].join(",");
    },
    [currencyOptions]
  );

  const onCellValueChanged = useRefCallback(
    ({ data }: CellValueChangedEvent) => {
      accrualsControl.modifyRow(data.__row_id, data);
    },
    []
  );

  const onClickTrashIconCell = useRefCallback((node: IRowNode) => {
    accrualsControl.deleteRows([node.data.__row_id]);
  }, []);

  const getContextMenuItems = useFormTableListContext(accrualsControl);

  return (
    <>
      <ControlledEmpty name="accruals" />

      <TabContentContainer>
        <FormPanel
          numColumns={1}
          style={{ height: "100%" }}
          contentStyle={{ height: "100%" }}
        >
          <FullHeightContainer data-e2e-id="accruals-tab">
            <TicketTabHeaderButton
              primary
              onClick={openModal}
              data-e2e-id="new-accruals-btn"
              data-testid="tt-accruals-tab-new-button"
            >
              New Accrual
            </TicketTabHeaderButton>

            <RoundedDataGrid
              height="100%"
              singleClickEdit
              suppressCellFocus
              rowData={accruals}
              columnDefs={columnDefs}
              rowSelection="multiple"
              suppressRowClickSelection
              stopEditingWhenCellsLoseFocus
              getRowId={accrualsControl.getRowId}
              onCellValueChanged={onCellValueChanged}
              getContextMenuItems={getContextMenuItems}
              defaultColDef={{
                sortable: true,
                resizable: true,
                suppressHeaderMenuButton: true,
                suppressMovable: true,
              }}
              columnTypes={{
                modifyAction: {
                  cellRenderer: PencilIconButtonCellRenderer,
                  cellRendererParams: { onClick: openModifying },
                },
                removeAction: {
                  cellRenderer: TrashIconButtonCellRenderer,
                  cellRendererParams: {
                    onClick: onClickTrashIconCell,
                  },
                },
                currency: {
                  valueGetter: currencyValueGetter,
                },
              }}
            />
          </FullHeightContainer>
        </FormPanel>
      </TabContentContainer>

      <TradeTicketAccrualDetailModal
        open={open}
        onClose={closeModal}
        onSubmit={onSubmitAccrualDetail}
        modifyingNode={modifyingNode ?? null}
      />
    </>
  );
};
