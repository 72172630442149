import { basicInfoMap, useRestAbortableOptions } from "@enfusion-ui/hooks";
import { SelectOptionsType } from "@enfusion-ui/types";
import { ControlledInputBase, Select } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

const getValue = (id: number, options: SelectOptionsType<number>[] = []) =>
  options.find((option) => option.value === id);

const MarketEnvironmentSelect: React.FC<{
  value: number | null;
  onChange: (value: number | null) => void;
  label?: string;
}> = ({ value, onChange, label = "Market Environment", ...rest }) => {
  const { options, loading } = useRestAbortableOptions(
    REST_API.LEGALENTITY.GET_MARKET_ENV.FETCH,
    basicInfoMap
  );

  return (
    <Select
      {...rest}
      onChange={(option: SelectOptionsType<number> | null) => {
        onChange(option?.value || null);
      }}
      value={value ? getValue(value, options) : null}
      label={label}
      options={options}
      isLoading={loading}
      inputId="market-env-selection-id"
    />
  );
};

export const ControlledMarketEnvironmentSelect: React.FC<{
  name?: string;
  label?: string;
}> = ({ name = "marketEnvironmentId", label }) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...rest }) => (
        <MarketEnvironmentSelect label={label} {...rest} />
      )}
    />
  );
};

export default MarketEnvironmentSelect;
