import { Button, Checkbox } from "@enfusion-ui/web-components";
import { styled, useOEMSQuotes } from "@enfusion-ui/web-core";
import { faRedo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

import QuotesTable from "./QuotesTable";

const Header = styled.div`
  padding: var(--spacing-l);
`;

const TopBar = styled.div`
  width: 100%;
  padding-left: var(--spacing);
  padding-bottom: var(--spacing);
`;

const TableActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const LeftActions = styled.div`
  display: flex;
  align-items: center;
  min-width: 10rem;
`;

const RightActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--spacing-xl);
  min-width: 35rem;
`;

const QuoteInfo = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xl);
  flex: 1 0 auto;
`;

export const QuotesView: React.FC = () => {
  const { order } = useOEMSQuotes();
  const [onlyActive, setOnlyActive] = React.useState<boolean>(false);

  const handleSaveQuote = () => console.log("save quote");
  const handleRefresh = () => console.log("refresh data");

  return (
    <>
      <Header>
        <TopBar>
          {order?.columnValues.Txn} {order?.columnValues.Contract}
        </TopBar>
        <TableActions>
          <LeftActions>
            <Checkbox
              label="Only Active"
              checked={onlyActive}
              onChange={() => setOnlyActive((prev) => !prev)}
            />
          </LeftActions>
          <RightActions>
            <QuoteInfo>
              <span>Filled: {order?.columnValues.Filled}</span>
              <span>Quote Format: N/A</span>
            </QuoteInfo>
            <Button onClick={handleRefresh} primary style={{ width: "2rem" }}>
              <FontAwesomeIcon icon={faRedo} />
            </Button>
            <Button onClick={handleSaveQuote} primary>
              Save Quotes
            </Button>
            <Button primary disabled>
              Execute Selected
            </Button>
          </RightActions>
        </TableActions>
      </Header>
      <QuotesTable />
    </>
  );
};

export default QuotesView;
