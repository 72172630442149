import { SocketController } from "../utils/socketController";
import { WebWorkerMessenger } from "./types";
const messenger = new WebWorkerMessenger();
const eventType = {
    connected: "connected",
    disconnect: "disconnect",
    error: "error",
    marketDataUpdate: "marketdata-update",
    subscribeToMarketData: "subscribe-to-market-data",
    unsubscribeFromMarketData: "unsubscribe-from-market-data",
};
const socketController = new SocketController({
    path: `wss://${self.location.host}/oms`,
    onOpen: (socket) => {
        socket.send(JSON.stringify({
            command: "CONNECT",
            "accept-version": "1.2",
            "sub-to": "/marketprice",
        }));
        messenger.broadcast({ type: eventType.connected });
    },
    onMessage: async (event) => {
        const eventMessage = JSON.parse(event.data);
        if (eventMessage.command === "MESSAGE") {
            const { payload } = eventMessage;
            if (payload.type === eventType.marketDataUpdate) {
                messenger.broadcast({
                    type: eventType.marketDataUpdate,
                    payload,
                });
            }
        }
    },
    onError: () => {
        messenger.broadcast({ type: eventType.error });
    },
    onClose: (ev) => {
        console.warn("Market data socket close", ev);
    },
    onDisconnect: (socket) => {
        socket.send(JSON.stringify({ command: "DISCONNECT" }));
    },
});
export const MarketDataModule = {
    enable: (postMessage) => {
        messenger.send = postMessage;
        socketController.init();
    },
    disable: () => {
        socketController.close("market data");
    },
    getCurrentState: () => {
        return { socketStatus: socketController.socketStatus };
    },
    onTerminate: () => {
        socketController.close();
    },
    onMessage: (message) => {
        const { type, payload } = message;
        const { instrumentId } = payload;
        if (type === eventType.subscribeToMarketData) {
            socketController.send(JSON.stringify({
                command: "SUBSCRIBE",
                id: `/oms/marketprice/${instrumentId}`,
                destination: `/oms/marketprice/${instrumentId}`,
            }));
        }
        else if (type === eventType.unsubscribeFromMarketData) {
            socketController.send(JSON.stringify({
                command: "UNSUBSCRIBE",
                id: `/oms/marketprice/${instrumentId}`,
                destination: `/oms/marketprice/${instrumentId}`,
            }));
        }
    },
};
