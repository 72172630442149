import { WidgetComponentsDefinition } from "../../types";
import DateEditWidget from "./DateEditWidget";
import DateWidget from "./DateWidget";
import PdfDateWidget from "./PdfDateWidget";

export const date: WidgetComponentsDefinition = {
  renderComponent: DateWidget,
  editComponent: DateEditWidget,
  renderPdfComponent: PdfDateWidget,
};
