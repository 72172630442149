import {
  ColumnSelectCellEditor,
  NumericCellEditor,
  TrashIconButtonCellRenderer,
} from "@app-components/cells";
import { RoundedDataGrid } from "@app-components/DataGrid";
import { useFormTableListContext } from "@app-utils/useFormTableListContext";
import { useRefCallback, useRestAbortableOptions } from "@enfusion-ui/hooks";
import {
  ReportedAmountEntity,
  ReportedAmountTypeOptions,
  TradeTicketFormValues,
  useTradeTicket,
} from "@enfusion-ui/trades";
import { getSelectOption } from "@enfusion-ui/utils";
import { ControlledEmpty, FormPanel } from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import {
  CellValueChangedEvent,
  ColDef,
  IRowNode,
  ValueFormatterParams,
} from "ag-grid-community";
import * as React from "react";
import { useWatch } from "react-hook-form";

import { amountCellValueFormatter } from "../../utils";
import {
  FullHeightContainer,
  TabContentContainer,
  TicketTabHeaderButton,
} from "./components/styledComponents";

const columnDefs: ColDef[] = [
  {
    headerName: "",
    field: "selection",
    width: 40,
    pinned: "left",
    lockPinned: true,
    resizable: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: "Type",
    field: "type",
    initialWidth: 180,
    editable: true,
    cellEditor: ColumnSelectCellEditor,
    cellEditorParams: { options: ReportedAmountTypeOptions },
    valueFormatter: ({ value }: ValueFormatterParams) =>
      getSelectOption(ReportedAmountTypeOptions, value as string)?.label ?? "",
  },
  {
    headerName: "Amount",
    field: "amount",
    initialWidth: 180,
    editable: true,
    type: "numeric",
  },
  {
    headerName: "Currency",
    field: "currencyId",
    initialWidth: 180,
    editable: true,
    type: "currency",
  },
  {
    width: 40,
    headerName: "",
    field: "remove",
    pinned: "right",
    lockPinned: true,
    resizable: false,
    type: "removeAction",
    cellStyle: { padding: "0" },
  },
];

export const ReportedAmountsTab: React.FC = () => {
  const { reportedAmounts = [] } = useWatch<TradeTicketFormValues>({
    name: ["reportedAmounts"],
  });

  const { reportedAmountsControl } = useTradeTicket();

  const { options: currencyOptions } = useRestAbortableOptions(
    REST_API.INSTRUMENT.GET_SETTLEMENT_CURRENCIES.FETCH,
    {
      map: (td) => ({ value: td.id, label: td.code }),
    }
  );

  const onClickTrashIconCell = useRefCallback((node: IRowNode) => {
    reportedAmountsControl.deleteRows([node.data.__row_id]);
  }, []);

  const onCellValueChanged = useRefCallback(
    ({ data }: CellValueChangedEvent) => {
      reportedAmountsControl.modifyRow(data.__row_id, data);
    },
    []
  );

  const getContextMenuItems = useFormTableListContext(reportedAmountsControl);

  const onClickAddButton = useRefCallback(() => {
    const newEntity: ReportedAmountEntity = {
      type: ReportedAmountTypeOptions[0].value,
      amount: 0,
      currencyId: null,
    };
    reportedAmountsControl.addRow(newEntity);
  }, []);

  return (
    <>
      <ControlledEmpty name="reportedAmounts" />
      <TabContentContainer>
        <FormPanel
          numColumns={1}
          style={{ height: "100%" }}
          contentStyle={{ height: "100%" }}
        >
          <FullHeightContainer>
            <TicketTabHeaderButton
              primary
              onClick={onClickAddButton}
              data-testid="tt-amounts-tab-add-button"
            >
              Add Amount
            </TicketTabHeaderButton>

            <RoundedDataGrid
              height="100%"
              singleClickEdit
              suppressCellFocus
              multiSortKey="ctrl"
              rowSelection="multiple"
              columnDefs={columnDefs}
              rowData={reportedAmounts}
              suppressRowClickSelection
              onCellValueChanged={onCellValueChanged}
              getContextMenuItems={getContextMenuItems}
              getRowId={reportedAmountsControl.getRowId}
              defaultColDef={{
                sortable: true,
                resizable: true,
                suppressMovable: true,
                suppressKeyboardEvent: () => true,
              }}
              columnTypes={{
                numeric: {
                  cellEditor: NumericCellEditor,
                  cellEditorParams: {
                    min: 0,
                    enableMultiplier: true,
                  },
                  valueFormatter: amountCellValueFormatter,
                },
                currency: {
                  cellEditor: ColumnSelectCellEditor,
                  cellEditorParams: {
                    options: [
                      { label: "Undecided", value: null },
                      ...currencyOptions,
                    ],
                  },
                  valueFormatter: ({ value }: ValueFormatterParams) =>
                    getSelectOption(currencyOptions, value as number)?.label ??
                    "N/A",
                },
                removeAction: {
                  cellRenderer: TrashIconButtonCellRenderer,
                  cellRendererParams: {
                    onClick: onClickTrashIconCell,
                  },
                },
              }}
            />
          </FullHeightContainer>
        </FormPanel>
      </TabContentContainer>
    </>
  );
};
