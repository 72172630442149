import { CenterContent } from "@enfusion-ui/web-components";
import React from "react";

import {
  ContentProgressContainer,
  ContentProgressText,
  ProgressBar,
  ProgressBarContainer,
} from "./styles";

export const LoadingContent: React.FC = () => {
  return (
    <CenterContent>
      <ContentProgressContainer>
        <ProgressBarContainer>
          <ProgressBar loading={1} />
        </ProgressBarContainer>
        <ContentProgressText>Performing Reconciliation</ContentProgressText>
      </ContentProgressContainer>
    </CenterContent>
  );
};
