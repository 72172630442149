import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import { useRefCallback } from "@enfusion-ui/hooks";
import { DashboardRoot } from "@enfusion-ui/types";
import * as React from "react";

type CreateFolderModalParams = { root: DashboardRoot; path: string };

type ReportsExplorerContextType = {
  generateReportModalRoot: DashboardRoot | null;
  createFolderModalParams: CreateFolderModalParams | null;

  openGenerateReportModal: (root: DashboardRoot) => void;
  closeGenerateReportModal: VoidFunction;

  openCreateFolderModal: (root: DashboardRoot, path: string) => void;
  closeCreateFolderModal: VoidFunction;

  deleteReport: (root: DashboardRoot, path: string) => void;
};

const ReportsExplorerContext = React.createContext<
  ReportsExplorerContextType | undefined
>(undefined);

export function useReportsExplorer() {
  const context = React.useContext(ReportsExplorerContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useReportsExplorer must be used within a ReportsExplorerProvider"
    );
  }
  return context;
}

export default function ReportsExplorerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [generateReportModalRoot, setGenerateReportModalRoot] =
    React.useState<DashboardRoot | null>(null);
  const [createFolderModalParams, setCreateFolderModalParams] =
    React.useState<CreateFolderModalParams | null>(null);

  const explorerEvent = useExplorerEvents("Reports");

  const openGenerateReportModal = useRefCallback((root: DashboardRoot) => {
    setGenerateReportModalRoot(root);
  }, []);
  const closeGenerateReportModal = useRefCallback(() => {
    setGenerateReportModalRoot(null);
  }, []);

  const openCreateFolderModal = useRefCallback(
    (root: DashboardRoot, path: string) => {
      setCreateFolderModalParams({ root, path });
    },
    []
  );
  const closeCreateFolderModal = useRefCallback(() => {
    setCreateFolderModalParams(null);
  }, []);

  const deleteReport = useRefCallback(
    (root: DashboardRoot, path: string) => {
      explorerEvent.broadcast(root, ExplorerEventsAction.Delete, path);
    },
    [explorerEvent]
  );

  return (
    <ReportsExplorerContext.Provider
      value={{
        generateReportModalRoot,
        createFolderModalParams,
        openGenerateReportModal,
        closeGenerateReportModal,
        openCreateFolderModal,
        closeCreateFolderModal,
        deleteReport,
      }}
    >
      {children}
    </ReportsExplorerContext.Provider>
  );
}
