/* eslint-disable @typescript-eslint/no-unused-vars */
import { styled, useAuth, useTheme } from "@enfusion-ui/web-core";
import { faExclamation, faMegaphone } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

const NPSSurveyIconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NPSSurveyIconTag = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 3px;
  min-width: 15px;
  border-radius: 10px;
  background-color: var(--danger);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  font-size: 12px;
  line-height: 12px;
`;

type NPSSurveyIconProps = {
  isHighlighted: boolean;
};

const NPSSurveyIcon: React.FC<NPSSurveyIconProps> = ({ isHighlighted }) => {
  const { theme } = useTheme();

  const color =
    theme.key === "dracula" ? "var(--tab-icon-color-0)" : "var(--text-normal)";

  return (
    <NPSSurveyIconContainer title="Feedback">
      <FontAwesomeIcon size="lg" icon={faMegaphone} color={color} />
      {isHighlighted ? (
        <NPSSurveyIconTag>
          <FontAwesomeIcon size="sm" icon={faExclamation} color={color} />
        </NPSSurveyIconTag>
      ) : null}
    </NPSSurveyIconContainer>
  );
};

export default NPSSurveyIcon;
