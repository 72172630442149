import {
  CalculationTypeSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { SelectOptionsType } from "@enfusion-ui/types";
import { FormSectionAccordion, TextInput } from "@enfusion-ui/web-components";
import * as React from "react";

import { BenchmarkCalculationTypeSelect } from "./CalculationTypeSelect";

const BenchmarkCalculationTypeEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange =
    (key: string) => (option: SelectOptionsType<string> | null) => {
      changeConfigKeyToBeApplied(key, option?.value);
    };

  const { defaultValue = null, key = "Benchmark Calculation Type" } =
    editedConfig as CalculationTypeSelectionWidgetConfig;

  return (
    <FormSectionAccordion title="Calculation Type Options" defaultOpen>
      <TextInput
        label="Key"
        value={key}
        name="key"
        onChange={handleTextChange("key")}
      />
      <BenchmarkCalculationTypeSelect
        value={defaultValue}
        label="Default Benchmark Calculation Type"
        onChange={handleBasicChange("defaultValue")}
      />
    </FormSectionAccordion>
  );
};

export default BenchmarkCalculationTypeEditWidget;
