import { useRefCallback } from "@enfusion-ui/hooks";
import { NumericInput, NumericInputProps } from "@enfusion-ui/web-components";
import { ICellEditorParams } from "ag-grid-community";
import * as React from "react";

type NumericCellEditorProps = Omit<
  NumericInputProps,
  "value" | "onChange" | "hideLabel" | "keyboardOnly" | "defaultValue"
> &
  ICellEditorParams;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const NumericCellEditor = React.forwardRef<any, NumericCellEditorProps>(
  ({ value, stopEditing, ...rest }, ref) => {
    const [internalValue, setInternalValue] = React.useState<number | null>(
      value ?? null
    );

    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
      inputRef.current?.focus();
    }, []);

    React.useImperativeHandle(
      ref,
      () => ({
        getValue: () => internalValue ?? 0,
        isCancelBeforeStart: () => false,
        isCancelAfterEnd: () => false,
      }),
      [internalValue]
    );

    const onBlur = useRefCallback(
      () => requestAnimationFrame(() => stopEditing()),
      []
    );

    const onKeyDown = useRefCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          inputRef.current?.blur();
        }
      },
      []
    );

    return (
      <NumericInput
        {...rest}
        hideLabel
        keyboardOnly
        ref={inputRef}
        onBlur={onBlur}
        value={internalValue}
        onKeyDown={onKeyDown}
        onChange={setInternalValue}
        style={{
          height: "100%",
          border: "none",
          padding: "0 0 0 12px",
        }}
      />
    );
  }
);
