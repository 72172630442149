import {
  Button,
  FormContainer,
  FormContent,
  FormElementHeader,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";

const TabContentContainerBase = styled(FormContainer)`
  padding: var(--spacing-xl);
`;

const TabContent = styled(FormContent)`
  padding: 0;
`;

export const TabContentContainer: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <TabContentContainerBase>
    <TabContent>{children}</TabContent>
  </TabContentContainerBase>
);

export const FullHeightContainer = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const HorizontalRule = styled.div`
  display: block;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid var(--input-background);
  margin: var(--spacing-xl) 0 0;
`;

export const TicketTabHeaderButton = styled(Button)`
  max-width: 8rem;
  height: 2.25rem;
  flex-shrink: 0;
`;

export const PanelItemContainer = styled.div`
  width: 100%;
  display: flex;
  gap: var(--spacing-s);
`;

export const StyledFormElementHeader = styled(FormElementHeader).attrs({
  dividerColor: "var(--background-accent)",
  style: { marginTop: "var(--spacing-xl)" },
})``;
