import { CustomCellRendererProps } from "ag-grid-react";
import * as React from "react";

import { ChangeView } from "../ChangeView";

export const ChangeCellRenderer: React.FC<CustomCellRendererProps> = ({
  value,
}) => {
  return (
    <ChangeView
      iconSize="1x"
      change={value}
      changeTruncate
      changeFractionDigits={[0, 2]}
    />
  );
};
