import { WidgetComponentsDefinition } from "../../../types";
import FinancialSubTypeEditWidget from "./FinancialSubTypeEditWidget";
import FinancialSubTypeWidget from "./FinancialSubTypeWidget";
import PdfFinancialSubTypeWidget from "./PdfFinancialSubTypeWidget";

export const financialSubType: WidgetComponentsDefinition = {
  renderComponent: FinancialSubTypeWidget,
  editComponent: FinancialSubTypeEditWidget,
  renderPdfComponent: PdfFinancialSubTypeWidget,
};
