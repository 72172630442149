import { useReconciliationBreakDetails } from "@app-context/reconciliation/context";
import { SizedChart } from "@app-views/reconciliation/components/SizedChart";
import {
  ChartContainer,
  TopRow,
} from "@app-views/reconciliation/components/styles";
import { GraphBaseField } from "@app-views/reconciliation/types";
import { getChartOptions } from "@app-views/reconciliation/utils/graphUtils";
import { styled } from "@enfusion-ui/web-core";
import { Options } from "highcharts";
import React from "react";

const NoDataMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type GraphTypes = "pie" | "column" | "stacked bar";

export type ChartBlockProps = {
  title: string;
  data:
    | Array<{ name: string; data: number[] }>
    | Array<{ name: string; y: number }>;
  type: GraphTypes;
  baseField: GraphBaseField;
  id: string;
  yAxisTitle?: string;
  noDataMessage?: string;
};
export const ChartBlock: React.FC<ChartBlockProps> = React.memo(
  ({
    title,
    data,
    yAxisTitle,
    type,
    noDataMessage = "No Data",
    baseField,
    id,
  }) => {
    const { setGraphFilterState } = useReconciliationBreakDetails();
    return (
      <>
        <ChartContainer>
          <TopRow>{title}</TopRow>

          <SizedChart
            getOptions={(width, height) =>
              getChartOptions(
                width,
                height,
                type,
                data,
                baseField,
                setGraphFilterState,
                id,
                yAxisTitle
              ) as Options
            }
          />

          {!data?.length && (
            <NoDataMessageContainer>{noDataMessage}</NoDataMessageContainer>
          )}
        </ChartContainer>
      </>
    );
  }
);
