import { pxToPtConversion } from "@app-views/dashboards/pdf/utils";
import { ThemeDefinition } from "@enfusion-ui/core";
import { NumericInputWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { withTheme } from "@enfusion-ui/web-core";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { Path, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import * as React from "react";

const pdfStyles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingVertical: pxToPtConversion(8),
  },
  label: {
    width: "100%",
    fontFamily: "Lato",
    fontSize: pxToPtConversion(10),
    lineHeight: 1.5,
    fontWeight: "light",
    marginLeft: pxToPtConversion(4),
    paddingBottom: pxToPtConversion(4),
  },
  inputContainer: {
    width: "100%",
  },
  input: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textInputContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: pxToPtConversion(4),
    padding: pxToPtConversion(4),
  },
  textInput: {
    width: "100%",
    height: "100%",
    minHeight: pxToPtConversion(38),
    // justifyContent and alignItems are reversed
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: pxToPtConversion(4),
  },
  text: {
    fontSize: pxToPtConversion(12),
    fontFamily: "Lato",
    width: "100%",
    maxLines: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  stepBtn: {
    width: pxToPtConversion(24),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

const PdfNumericInputWidget = withTheme(
  ({ theme }: { theme: ThemeDefinition }) => {
    const { config, addChannelKeys, setChannelData } = useWidget();
    const { defaultValue, stepSize, key } = config as NumericInputWidgetConfig;

    React.useEffect(() => {
      addChannelKeys([
        {
          name: key,
          description: "Numeric Input value",
          type: ChannelDataType.Number,
        },
      ]);
      setChannelData([defaultValue]);
    }, [defaultValue, key]);

    return (
      <View style={[pdfStyles.container]}>
        <Text
          style={[
            pdfStyles.label,
            {
              color: theme.colors.textNormal,
            },
          ]}
        >
          {key}
        </Text>
        <View style={[pdfStyles.inputContainer]}>
          <View style={[pdfStyles.input]}>
            <View
              style={[
                pdfStyles.textInputContainer,
                {
                  backgroundColor: theme.colors.inputBackground,
                  border: `1px solid ${theme.colors.inputBorder}`,
                },
              ]}
            >
              <View style={[pdfStyles.textInput]}>
                <Text
                  style={[
                    pdfStyles.text,
                    {
                      color: theme.colors.textNormal,
                    },
                  ]}
                >
                  {defaultValue}
                </Text>
              </View>
              {stepSize && (
                <View style={[pdfStyles.stepBtn]}>
                  <View>
                    <Svg
                      width={pxToPtConversion(12)}
                      height={pxToPtConversion(12)}
                      viewBox={`0 0 ${faCaretUp.icon[0]} ${faCaretUp.icon[1]}`}
                    >
                      <Path
                        d={faCaretUp.icon[4] as string}
                        stroke={theme.colors.textMuted}
                        fill={theme.colors.textMuted}
                      />
                    </Svg>
                  </View>
                  <View style={{ marginTop: pxToPtConversion(4) }}>
                    <Svg
                      width={pxToPtConversion(12)}
                      height={pxToPtConversion(12)}
                      viewBox={`0 0 ${faCaretDown.icon[0]} ${faCaretDown.icon[1]}`}
                    >
                      <Path
                        d={faCaretDown.icon[4] as string}
                        stroke={theme.colors.textMuted}
                        fill={theme.colors.textMuted}
                      />
                    </Svg>
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    );
  }
);

export default PdfNumericInputWidget;
