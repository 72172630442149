import { RoundedDataGrid } from "@app-components/DataGrid";
import { useRefCallback } from "@enfusion-ui/hooks";
import { Broker, LocateRow } from "@enfusion-ui/types";
import { formatDateTime, formatNumber } from "@enfusion-ui/utils";
import {
  Button,
  IconButton,
  Modal,
  TertiaryTabList,
} from "@enfusion-ui/web-components";
import { styled, useLocates } from "@enfusion-ui/web-core";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import {
  ColDef,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  IRowNode,
  SelectionChangedEvent,
  ValueFormatterParams,
} from "ag-grid-community";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import LocatesEnquiryForm from "./LocatesEnquiryForm";

const TABLE_HEIGHT = "20rem";

const ModalContent = styled.div`
  min-width: 35rem;
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
`;

const TopActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing);
`;

export const ActionButtonContainer = styled.div`
  margin-top: var(--spacing);
  display: flex;
  gap: 1rem;
`;

export const ActionButton = styled(Button)`
  flex: 1;
`;

const locatePanelTabs = [
  {
    label: "Available Locates",
    key: "available",
  },
  {
    label: "On-Demand Locates",
    key: "on-demand",
  },
];

const OnDemandLocatesColDefs: ColDef[] = [
  { field: "broker", headerName: "Broker", width: 100 },
  { field: "groupId", headerName: "Group Id", width: 100 },
  {
    field: "requestedQty",
    headerName: "Requested Qty",
    width: 100,
    valueFormatter: ({ value }) => formatNumber(value),
  },
  {
    field: "availableQty",
    headerName: "Available Qty",
    width: 120,
    valueFormatter: ({ value }) => formatNumber(value),
  },
  {
    field: "cost",
    headerName: "Rate",
    width: 120,
    valueFormatter: (params: ValueFormatterParams) => {
      const data: LocateRow = params.data;
      if (!data) {
        return params.value;
      }

      return data.cost && data.costType
        ? `${data.cost} - ${data.costType}`
        : "";
    },
  },
  { field: "inquiryState", headerName: "Inquiry State", width: 120 },
  { field: "locateState", headerName: "Locate State", width: 120 },
  { field: "lastMessage", headerName: "Last Message", width: 150 },
  { field: "locateId", headerName: "Locate Id", width: 110 },
  {
    field: "lastPollTime",
    headerName: "Last Poll Time",
    width: 150,
    valueFormatter: ({ value }) => formatDateTime(value),
  },
];

const handleGetRowId = (params: GetRowIdParams<LocateRow>) => {
  return params.data.locateId?.toString()!;
};

const AvailableLocatesColDefs: ColDef[] = [
  { field: "broker", headerName: "Broker", width: 100 },
  {
    field: "availableQty",
    headerName: "Available Qty",
    width: 120,
    valueFormatter: ({ value }) => formatNumber(value),
  },
  { field: "cost", headerName: "Rate/Fee/Spread", width: 120 },
  { field: "costType", headerName: "R/F/S", width: 100 },
  { field: "locateId", headerName: "Locate Id", width: 100 },
];

const AvailableLocatesPanel: React.FC<{
  rows: LocateRow[];
  onGridReady: ((event: GridReadyEvent) => void) | undefined;
  handleSelectionChanged: (event: SelectionChangedEvent) => void;
}> = ({ rows = [], onGridReady, handleSelectionChanged }) => {
  const availableLocates = rows.filter(
    (eachRow: LocateRow) =>
      eachRow.locateState === "Approved" || eachRow.enquiryType === "Daily"
  );

  return (
    <RoundedDataGrid
      height={TABLE_HEIGHT}
      columnDefs={AvailableLocatesColDefs}
      rowData={availableLocates}
      rowSelection="multiple"
      onGridReady={onGridReady}
      defaultColDef={{
        resizable: true,
        sortable: true,
      }}
      getRowId={handleGetRowId}
      onSelectionChanged={handleSelectionChanged}
    />
  );
};

const OnDemandLocates: React.FC<{ rows: LocateRow[] }> = ({ rows = [] }) => {
  return (
    <>
      <RoundedDataGrid
        height={TABLE_HEIGHT}
        columnDefs={OnDemandLocatesColDefs}
        rowData={rows}
        defaultColDef={{
          resizable: true,
          sortable: true,
        }}
        getRowId={(params: GetRowIdParams<LocateRow>) =>
          params.data.id?.toString()
        }
      />
    </>
  );
};

const getBrokersFromRows = (rowsObj: IRowNode<any>[]): Broker[] => {
  const selectedLocates = Object.values(rowsObj || {}).map(
    (row) => row.data
  ) as LocateRow[];

  const uniqueLocates = [
    ...new Set(selectedLocates.map((e) => e.locateId as string)),
  ]
    .map((id: string | null) =>
      selectedLocates.find((locateRow) => id === locateRow.locateId)
    )
    .filter((e) => e && e.locateId && e.broker);

  return uniqueLocates.map(
    (eachLocate) =>
      ({
        locateId: eachLocate?.locateId as string,
        brokerCode: eachLocate?.broker as string,
      } as Broker)
  );
};

export type LocatesModalProps = {
  open: boolean;
  onClose: VoidFunction;
  onLocatesSelect: (selectedRows: Broker[]) => void;
};

const LocatesModal: React.FC<LocatesModalProps> = ({
  open,
  onClose,
  onLocatesSelect,
}) => {
  const {
    enquireForQuantity,
    isSubscribed,
    locateRows,
    enquiryRows,
    subscribeInstrument,
    unsubscribeInstrument,
    isConnected,
  } = useLocates();
  const [selectedTab, setSelectedTab] = React.useState<string>(
    locatePanelTabs[0].key
  );
  const [openBorrowDetails, setOpenBorrowDetails] =
    React.useState<boolean>(false);

  const { watch } = useFormContext();
  const { quantity, instrument: instrumentInfo } = watch([
    "quantity",
    "instrument",
  ]);

  const instrumentId = instrumentInfo?.id ?? 0;

  const gridApiRef = React.useRef<GridApi | null>();
  const instrumentIdRef = React.useRef(instrumentId);
  const [isDisabled, setIsDisabled] = React.useState(true);

  React.useEffect(() => {
    if (instrumentId && isConnected)
      unsubscribeInstrument?.(instrumentIdRef.current);
    instrumentIdRef.current = instrumentId;
    if (instrumentId && isConnected) subscribeInstrument?.(instrumentId);
    return () => {
      gridApiRef.current = null;
      setIsDisabled(true);
    };
  }, [instrumentId, isConnected]);

  const onGridReady = ({ api }: GridReadyEvent) => (gridApiRef.current = api);

  const handleOkClick = useRefCallback(() => {
    const selectedRows = gridApiRef.current?.getSelectedNodes() ?? [];

    onLocatesSelect?.(getBrokersFromRows(selectedRows));
    setIsDisabled(true);
  }, []);

  const handleSelectionChanged = useRefCallback(
    (event: SelectionChangedEvent) => {
      gridApiRef.current = event.api;
      setIsDisabled(
        (gridApiRef.current &&
          (gridApiRef.current.getSelectedNodes() ?? []).length === 0) ??
          true
      );
    },
    [gridApiRef.current]
  );

  const handleModalClose = useRefCallback(() => {
    onClose?.();
    setIsDisabled(true);
  }, [onClose]);

  const handleClose = useRefCallback(() => {
    if (openBorrowDetails) {
      setOpenBorrowDetails(false);
    } else {
      handleModalClose();
    }
  }, [openBorrowDetails, onClose]);

  return (
    <Modal
      title={openBorrowDetails ? "On Demand Stock Borrow" : "Locates"}
      isOpen={open}
      onClose={handleClose}
      content={
        <ModalContent>
          <div style={{ display: openBorrowDetails ? "block" : "none" }}>
            <LocatesEnquiryForm
              instrumentInfo={instrumentInfo || null}
              quantity={quantity || 0}
              onCancel={() => setOpenBorrowDetails(false)}
              onSubmit={(qty, brokers) => {
                console.info("submit", instrumentId || null, qty, brokers);
                enquireForQuantity?.(instrumentId || null, qty, brokers);
                setOpenBorrowDetails(false);
              }}
            />
          </div>
          <div style={{ display: !openBorrowDetails ? "block" : "none" }}>
            <TopActions>
              <div style={{ flex: 1 }}>
                <TertiaryTabList
                  tabs={locatePanelTabs}
                  value={selectedTab}
                  onSelect={(val) => setSelectedTab(val)}
                />
              </div>
              <IconButton
                icon={faPlus}
                onClick={() => setOpenBorrowDetails(true)}
                type="button"
                title="Request Locates"
                disabled={!isSubscribed}
              />
            </TopActions>

            {selectedTab === "available" ? (
              <AvailableLocatesPanel
                rows={locateRows}
                onGridReady={onGridReady}
                handleSelectionChanged={handleSelectionChanged}
              />
            ) : (
              <OnDemandLocates rows={enquiryRows} />
            )}

            <ActionButtonContainer>
              <ActionButton
                theme="primary"
                onClick={handleOkClick}
                disabled={isDisabled}
              >
                OK
              </ActionButton>
              <ActionButton onClick={handleModalClose}>Cancel</ActionButton>
            </ActionButtonContainer>
          </div>
        </ModalContent>
      }
    />
  );
};

export default LocatesModal;
