import {
  ContextMenuIcon,
  ContextMenuItemContainer,
  ExplorerContextMenuNode,
  useExplorerContextMenu,
} from "@app-context/explorer/explorerContextMenu/useExplorerContextMenu";
import { useExplorerEvents } from "@app-context/explorer/explorerEvents/context";
import { ExplorerEventsAction } from "@app-context/explorer/explorerEvents/types";
import { ServicesSectionMap } from "@app-context/services/ServicesFolderProvider";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  AppEvent,
  AppEventCategories,
  FileSortOption,
  FileTreeEntry,
  NodeData,
  StorageRoot,
} from "@enfusion-ui/types";
import { getFileIcon, getFileName } from "@enfusion-ui/utils";
import {
  AccordionContext,
  CenterContent,
  DateFilterForm,
  ErrorAlert,
  FileExplorer,
  FilterDateType,
  MenuOptions,
  Spinner,
  TextInput,
  useNavBarState,
} from "@enfusion-ui/web-components";
import {
  AppLogging,
  FilesContextState,
  getBlobFromXHR,
  getStorageFileUrl,
  getXHRContent,
  styled,
  useAuth,
  useTabs,
} from "@enfusion-ui/web-core";
import {
  faBroom,
  faFileDownload,
  faFolderPlus,
  faUpload,
} from "@fortawesome/pro-solid-svg-icons";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { isEqual } from "lodash";
import { Divider, MenuItem, SubMenu } from "rc-menu";
import { MenuInfo } from "rc-menu/lib/interface";
import * as React from "react";
import { useDebounce } from "react-use";

import { FilterButton } from "../../components/control/FilterButton";
import { TutorialType } from "../../components/Tour/utils";
import { useServicesExplorer } from "../../context/services/ServicesExplorerProvider";
import { downloadStorageFile } from "../../utils/downloadStorageFile";

export type DateFilter = {
  dateType: FilterDateType;
  dateRange: [Date | null, Date | null];
};

const ServicesFileExplorer = styled(FileExplorer)`
  flex: 1;
  overflow-x: hidden;
`;

const SearchPanel = styled.div`
  padding: 0.5rem;
  background-color: var(--background-accent);
  margin-bottom: var(--spacing);
`;

const SearchForm = styled.form`
  display: flex;
`;

const FilterIconContainer = styled.div`
  height: 38px;
  margin-left: 4px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DateFilterContent = styled.div`
  display: ${({ open }: { open: boolean }) => (open ? "block" : "none")};
`;

function notValid(value?: Date | null) {
  return value === null || typeof value === "undefined";
}

const ServicesExplorer: React.FC<{
  open: boolean;
  useFiles: () => FilesContextState;
  storageRoot: StorageRoot;
  tutorial?: TutorialType | null;
}> = React.memo(function ServicesExplorer({ tutorial, useFiles, storageRoot }) {
  const { isEnabled, isAdmin } = useAuth();
  const { openTab } = useTabs();
  const {
    files: nodes,
    error,
    loading,
    setParams,
    retrieveNodes,
    fetchStorageList,
  } = useFiles();
  const { closeNavBarTabOnMobile } = useNavBarState();
  const { setMenu, setSearch, setForceSearchIcon } =
    React.useContext(AccordionContext);
  const [sortOption, setSortOption] =
    React.useState<FileSortOption>("ModifiedAt");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [dateFilterOpen, setDateFilterOpen] = React.useState(false);
  const [dateFilter, setDateFilter] = React.useState<DateFilter | null>(null);
  const [selectedNodes, setSelectedNodes] = React.useState<Array<NodeData>>([]);

  const haveSelectedNodes = selectedNodes.filter((i) => i.file).length > 1;
  const adminEnabled = isEnabled("Services") && isAdmin();

  const [foldersOpen, setFoldersOpen] = React.useState<boolean>(false);

  useExplorerEvents(ServicesSectionMap[storageRoot], (_root, event) => {
    if (event === ExplorerEventsAction.Refetch) {
      fetchStorageList();
    }
  });

  const { openCreateFolderModal, openUploadFileModal, deleteServicesFile } =
    useServicesExplorer();

  //#region Handle accordion action interactions
  const sortHandler =
    (sortOption: FileSortOption) =>
    ({ domEvent }: MenuInfo) => {
      domEvent.preventDefault();
      domEvent.stopPropagation();
      AppLogging.event(
        {
          event: AppEvent.SortFiles,
          category: AppEventCategories[ServicesSectionMap[storageRoot]],
        },
        { sortOption }
      );
      setSortOption(sortOption);
    };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim().length === 1)
      AppLogging.event({
        event: AppEvent.SearchFiles,
        category: AppEventCategories[ServicesSectionMap[storageRoot]],
      });
    setSearchTerm(e.target.value);
  };

  const handleDateFilterIconClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setDateFilterOpen(!dateFilterOpen);
  };

  React.useEffect(() => {
    setForceSearchIcon(searchTerm.trim().length > 0 || !!dateFilter);
  }, [searchTerm, dateFilter]);

  const handleSetDateFilter = (
    dateType: FilterDateType,
    dateRange: [Date | null, Date | null]
  ) => {
    if (notValid(dateRange[0]) && notValid(dateRange[1])) {
      setDateFilter(null);
    } else {
      setDateFilter({ dateType, dateRange });
    }
  };

  const handleClearSearchTerm = () => {
    setSearchTerm("");
  };

  React.useEffect(() => {
    const adminActions: MenuOptions = adminEnabled
      ? [
          {
            key: "uploadFile",
            title: "Upload File",
            icon: faUpload,
            onClick: () => openUploadFileModal("shared", "", storageRoot),
          },
          {
            key: "createFolder",
            title: "Create Folder",
            icon: faFolderPlus,
            onClick: () => openCreateFolderModal("shared", "", storageRoot),
          },
          "divider",
        ]
      : [];

    const actions: MenuOptions = [
      {
        key: "sort",
        title: "Sort",
        children: [
          {
            key: "Name",
            title: "Sort by Name",
            onClick: sortHandler("Name"),
          },
          {
            key: "ModifiedAt",
            title: "Sort by Modified At",
            onClick: sortHandler("ModifiedAt"),
          },
          {
            key: "CreatedAt",
            title: "Sort by Created At",
            onClick: sortHandler("CreatedAt"),
          },
        ],
      },
    ];

    setMenu({
      menu: [...adminActions, ...actions],
      selectedKeys: [sortOption],
    });
  }, [sortOption, adminEnabled]);

  React.useEffect(() => {
    setSearch(
      <SearchPanel>
        <SearchForm>
          <TextInput
            label="Search"
            name="name"
            value={searchTerm}
            onChange={handleSearchChange}
            clearable
            onClearValue={handleClearSearchTerm}
          />
          <FilterIconContainer>
            <FilterButton
              title="Date Filter"
              onClick={handleDateFilterIconClick}
              isFiltered={dateFilter !== null}
            />
          </FilterIconContainer>
        </SearchForm>
        <DateFilterContent open={dateFilterOpen}>
          <DateFilterForm onChange={handleSetDateFilter} />
        </DateFilterContent>
      </SearchPanel>
    );
  }, [dateFilterOpen, dateFilter, searchTerm]);
  //#endregion

  //#region FileExplorer handlers
  useDebounce(
    () => {
      const [fromDate, toDate] = dateFilter?.dateRange || [];
      setParams({
        sortOption,
        query: searchTerm.trim() ? searchTerm : undefined,
        ascending: sortOption === "Name" ? true : false,
        fromDate,
        toDate,
      });
    },
    500,
    [searchTerm, sortOption, dateFilter]
  );

  React.useEffect(() => {
    if ((searchTerm?.trim().length > 0 || dateFilter) && !foldersOpen) {
      setFoldersOpen(true);
    }

    if (searchTerm.trim().length === 0 && !dateFilter) {
      setFoldersOpen(false);
    }
  }, [nodes, searchTerm, dateFilter]);

  const handleClick = React.useCallback(
    (node: NodeData) => {
      setSelectedNodes([]);
      const data = node as unknown as FileTreeEntry;
      if (node.file) {
        const fileName: string = data.name;

        openTab({
          component: "file",
          name: fileName,
          unique: `${storageRoot}-${data.path}`,
          icon: getFileIcon(node),
          config: {
            fileName,
            filePath: data.path,
            data,
            root: storageRoot,
            size: data.size || 0,
          },
        });
        closeNavBarTabOnMobile();
      }
    },
    [closeNavBarTabOnMobile]
  );

  const getFileDate = useRefCallback(
    (node: NodeData) => {
      const key =
        sortOption === "ModifiedAt" ? "modifiedDateTime" : "creationDateTime";
      const data = node as unknown as FileTreeEntry;
      if (!data[key]) return "N/A";
      const date = new Date(data[key]!);
      const today = new Date();
      if (
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate() &&
        date.getFullYear() === today.getFullYear()
      ) {
        return format(date, "p");
      }
      if (
        date.getFullYear() === today.getFullYear() ||
        date.getMonth() - today.getMonth() >= 6
      ) {
        return format(date, "LLL do");
      }
      return date.toLocaleDateString();
    },
    [sortOption]
  );
  //#endregion

  //#region Handle context menu
  const handleSelectionChange = React.useCallback(
    (nodes: Array<NodeData>) => {
      if (!isEqual(selectedNodes, nodes)) setSelectedNodes(nodes);
    },
    [selectedNodes]
  );

  const handleZipFileDownload = React.useCallback(async () => {
    AppLogging.event(
      {
        event: AppEvent.DownloadZipFile,
        category: AppEventCategories[ServicesSectionMap[storageRoot]],
      },
      { paths: selectedNodes.map((i) => i?.path || "") }
    );
    const zip = new JSZip();
    const fileData = await Promise.all(
      selectedNodes
        .filter((i) => i.file)
        .map(async (node) => {
          const fileNode = node as unknown as FileTreeEntry;
          const xhr = await getXHRContent(
            getStorageFileUrl(storageRoot, fileNode.path)
          );
          const { blob } = getBlobFromXHR(xhr);
          return { filePath: fileNode.path, blob };
        })
    );
    for (const entry of fileData) {
      zip.file(entry.filePath, entry.blob, { createFolders: true });
    }
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "Services.zip");
  }, [selectedNodes]);
  const handleClearSelectedFiles = React.useCallback(() => {
    setSelectedNodes([]);
  }, []);

  const handleFileDownload = React.useCallback((node: FileTreeEntry) => {
    AppLogging.event(
      {
        event: AppEvent.DownloadFile,
        category: AppEventCategories[ServicesSectionMap[storageRoot]],
      },
      { path: node.path }
    );
    downloadStorageFile(storageRoot, node.path);
  }, []);
  //#endregion

  const renderContextMenuItems = useRefCallback(
    (contextMenuNode: ExplorerContextMenuNode | null) => {
      const haveContextNode = !!contextMenuNode && !!contextMenuNode.node;
      const nodeIsNotDir = haveContextNode && contextMenuNode?.node?.file;
      const node = contextMenuNode?.node as unknown as FileTreeEntry;

      let entries: React.ReactElement[] = [];
      if (haveContextNode && nodeIsNotDir) {
        entries.push(
          <MenuItem
            key="download-file"
            onClick={() => handleFileDownload(node)}
          >
            <ContextMenuItemContainer>
              <ContextMenuIcon icon={faFileDownload} />
              Download
            </ContextMenuItemContainer>
          </MenuItem>
        );
      }
      if (haveSelectedNodes) {
        if (haveContextNode) entries.push(<Divider key="multi-divider" />);
        entries.push(
          <MenuItem
            key="download-selected-files"
            onClick={handleZipFileDownload}
          >
            <ContextMenuItemContainer>
              <ContextMenuIcon icon={faFileDownload} />
              Download Selected
            </ContextMenuItemContainer>
          </MenuItem>
        );
        entries.push(
          <MenuItem
            key="clear-selected-files"
            onClick={handleClearSelectedFiles}
          >
            <ContextMenuItemContainer>
              <ContextMenuIcon icon={faBroom} />
              Clear Selected
            </ContextMenuItemContainer>
          </MenuItem>
        );
      }
      if (haveContextNode || haveSelectedNodes || adminEnabled)
        entries.push(<Divider key="sort-divider" />);
      entries.push(
        <SubMenu key="sort" title="Sort">
          <MenuItem key="Name" onClick={sortHandler("Name")}>
            By Name
          </MenuItem>
          <MenuItem key="ModifiedAt" onClick={sortHandler("ModifiedAt")}>
            By Modified At
          </MenuItem>
          <MenuItem key="CreatedAt" onClick={sortHandler("CreatedAt")}>
            By Created At
          </MenuItem>
        </SubMenu>
      );

      return entries;
    },
    [haveSelectedNodes, adminEnabled]
  );
  const ExplorerContextMenu = useExplorerContextMenu(
    {
      root: "shared",
      section: ServicesSectionMap[storageRoot],
      editEnabled: adminEnabled,
      selectedKeys: [sortOption],
      openCreate: openUploadFileModal,
      openCreateFolder: openCreateFolderModal,
      deleteFile: deleteServicesFile,
    },
    renderContextMenuItems
  );

  if (error) {
    return (
      <CenterContent fillHeight={false}>
        <ErrorAlert error={error} />
      </CenterContent>
    );
  }

  if (loading || nodes === null) {
    return (
      <CenterContent fillHeight>
        <Spinner size="lg" dataTestId="spinner" />
      </CenterContent>
    );
  }

  return (
    <>
      <ServicesFileExplorer
        key={sortOption}
        nodes={nodes}
        onEntryContext={ExplorerContextMenu.handleEntryContext}
        onEntryClick={handleClick}
        getFileIcon={getFileIcon}
        getFileName={getFileName}
        getFileDate={getFileDate}
        tutorial={tutorial}
        onSelectionChange={handleSelectionChange}
        defaultFoldersOpen={foldersOpen}
        retrieveNodes={retrieveNodes}
        selectedNodes={selectedNodes}
        multiSelect
      />

      {ExplorerContextMenu.content}
    </>
  );
});

export default ServicesExplorer;
