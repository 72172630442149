import {
  UNKNOWN_INSTRUMENT_ID,
  useInstrument,
  useOEMSOrderForm,
} from "@enfusion-ui/core";
import { OrderInfo } from "@enfusion-ui/types";
import {
  ControlledEmpty,
  DatePicker as DatePickerInput,
  FormController,
  FormElementHeader,
  Select,
} from "@enfusion-ui/web-components";
import {
  css,
  REST_API,
  styled,
  useIsContentMobile,
} from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import PreferredExecutionSchemeSelect from "../OrderTicketForm/components/PreferredExecutionSchemeSelect";
import ElectronicRoutingDetailEntry from "./ElectronicRoutingDetailEntry";
import { ExecutionMethodDetailPanelProps } from "./ExecutionMethodSelection";

const InputContainer = styled.div`
  display: flex;
  gap: var(--spacing-xl);
`;

const Notes = styled.div`
  font-size: 0.8rem;
  padding: var(--spacing-xl);
  padding-left: var(--spacing);
`;

const ElectronicExecutionDetailsContainer = styled.div<{ show?: boolean }>`
  ${({ show }) =>
    show
      ? css`
          height: 100%;
        `
      : css`
          height: 0px;
          max-height: 0px;
          overflow: hidden;
        `}
`;

type TimeZonesOption = { label: string; value: number };

const timeZones: TimeZonesOption[] = [
  { label: "America/Chicago", value: 0 },
  { label: "America/New_York", value: 1 },
  { label: "America/Los_Angeles", value: 2 },
  { label: "Asia/Tokyo", value: 3 },
  { label: "Asia/Hong_Kong", value: 4 },
  { label: "Asia/Singapore", value: 5 },
  { label: "Australia/Sydney", value: 6 },
  { label: "Europe/London", value: 7 },
].sort((tz1: TimeZonesOption, tz2: TimeZonesOption) =>
  tz1.label === tz2.label ? 0 : tz1.label > tz2.label ? 1 : -1
);

export const SchedulingNotes: React.FC = () => {
  const [releaseOrderTime, setReleaseOrderTime] = React.useState<Date>(
    new Date()
  );
  return (
    <>
      <Notes>
        Orders may be scheduled for release to a broker at a specific time and
        date up to 84 hours in advance. The release time must match the end
        broker&apos;s availability or the order will be rejected. Once
        scheduled, orders may only be canceled using the schedule action in the
        main tool bar.
      </Notes>
      <InputContainer>
        <DatePickerInput
          onChange={(date: Date | null) => {
            date && setReleaseOrderTime(date);
          }}
          name="orderTime"
          label="Release Order at"
          value={releaseOrderTime}
          showTimeInput
          showTimeSelect
          timeIntervals={1}
          dateFormat="dd-MM-yyyy hh:mm:ss"
        />
        <Select
          options={timeZones}
          label="TimeZone"
          inputId="electronic-execution-timezone-selection-id"
        />
      </InputContainer>
    </>
  );
};

export const ExecutionSchemeSelect: React.FC<
  ExecutionMethodDetailPanelProps
> = ({ disabled }) => {
  const instrument = useInstrument();
  const { isTransmitted } = useOEMSOrderForm();
  const instrumentId = instrument?.id ?? UNKNOWN_INSTRUMENT_ID;
  const swapOrder = useWatch<boolean>({ name: "swapOrder" }) ?? false;
  return (
    <FormController
      name="executionSchemeId"
      render={({ ref: _ref, ...rest }) => (
        <PreferredExecutionSchemeSelect
          {...rest}
          instrumentId={instrumentId}
          swap={swapOrder}
          disabled={disabled || isTransmitted}
          value={null}
        />
      )}
      defaultValue={0}
    />
  );
};

export const ElectronicExecutionDetails: React.FC<
  ExecutionMethodDetailPanelProps
> = ({ disabled, show }) => {
  const { isTransmitted, setSubmitErrors, getCurrentOrder } =
    useOEMSOrderForm();
  const { setValue } = useFormContext();
  const isMobile = useIsContentMobile();

  const { strategy, destination, orderType, orderSide, timeInForce } = useWatch(
    {
      name: [
        "strategy",
        "destination",
        "orderType",
        "orderSide",
        "timeInForce",
      ],
    }
  );

  const currentOrder = getCurrentOrder() as Partial<OrderInfo>;

  React.useEffect(() => {
    const validateStrategyParams = async () => {
      if (destination?.requiresStrategy && strategy !== null) {
        try {
          const isValid = await REST_API.OEMS.VALIDATE_STRATEGY_PARAMS.FETCH(
            currentOrder
          );
          setValue("strategyParamsValid", isValid);
          setSubmitErrors(null);
        } catch (ex) {
          setValue("strategyParamsValid", false);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setSubmitErrors((ex as any)?.errors ?? null);
        }
      } else {
        setValue("strategyParamsValid", true);
      }
    };
    if (process.env.REACT_APP_ENABLE_STRATEGY_PARAMETER_VALIDATION) {
      setSubmitErrors(null);
      validateStrategyParams();
    }
  }, [strategy, orderSide, orderType, timeInForce]);

  return (
    <ElectronicExecutionDetailsContainer show={show}>
      <FormElementHeader
        style={{ margin: isMobile ? "0 0 1.5rem" : "1.5rem 0 0.75rem" }}
      >
        Execution Schemes
      </FormElementHeader>
      <ExecutionSchemeSelect disabled={disabled} />
      {process.env.REACT_APP_ENABLE_STRATEGY_PARAMETER_VALIDATION ? (
        <ControlledEmpty name="strategyParamsValid" />
      ) : null}
      <FormElementHeader style={{ margin: "1.5rem 0 0.75rem" }}>
        Electronic Routing Details
      </FormElementHeader>
      <ElectronicRoutingDetailEntry disabled={disabled || isTransmitted} />
    </ElectronicExecutionDetailsContainer>
  );
};
