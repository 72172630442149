import { format } from "date-fns";
/* Mock data for UI dev */
export const MOCK_TRADE_TICKET_USERNAME = "demouser";
export const MOCK_TRADE_TICKET_DATE_ISO = new Date().toISOString();
export const MOCK_TRADE_TICKET_DATE_STRING = format(new Date(), "yyyy-MM-dd");
export const MOCK_TRADE_TICKET_TIME_STRING = format(new Date(), "hh:mm:ss aa");
const MOCK_TRADE_TICKET_CURRENCY_INFO_USD = {
    id: 231,
    code: "USD",
    deliverable: true,
    description: "US Dollar",
    crypto: false,
    parentCurrencyId: 0,
    parentConversionFactor: 1,
};
export const MOCK_TRADE_TICKET_REPORTED_AMOUNTS = [
    {
        type: "InitialResetRate",
        amount: 1158800,
        currencyId: MOCK_TRADE_TICKET_CURRENCY_INFO_USD.id,
    },
    {
        type: "DividendPercent",
        amount: 2423,
        currencyId: null,
    },
    {
        type: "BondTradeQuantity",
        amount: 1680000,
        currencyId: null,
    },
];
export const MOCK_DESTINATIONS = [
    {
        description: "Goldman Sachs Algo",
        enableCounterparties: false,
        requiresStrategy: true,
        defaultCounterpartyId: 0,
        defaultBrokerAccountNumber: null,
        defaultOrderCapacity: "Agency",
        fixName: "test",
    },
    {
        description: "Goldman Sachs HT",
        enableCounterparties: false,
        requiresStrategy: false,
        defaultCounterpartyId: 0,
        defaultBrokerAccountNumber: null,
        defaultOrderCapacity: "Agency",
        fixName: "test",
    },
    {
        description: "Morgan Stanley HT",
        enableCounterparties: false,
        requiresStrategy: false,
        defaultCounterpartyId: 0,
        defaultBrokerAccountNumber: null,
        defaultOrderCapacity: "Agency",
        fixName: "test",
    },
    {
        description: "BMOA LL Equity Algo Cert",
        enableCounterparties: false,
        requiresStrategy: true,
        defaultCounterpartyId: 0,
        defaultBrokerAccountNumber: null,
        defaultOrderCapacity: "Agency",
        fixName: "test",
    },
    {
        description: "Citi Options Algo",
        enableCounterparties: false,
        requiresStrategy: true,
        defaultCounterpartyId: 0,
        defaultBrokerAccountNumber: null,
        defaultOrderCapacity: "Agency",
        fixName: "test",
    },
    {
        description: "Scotia Bank HT (MiFID2)",
        enableCounterparties: false,
        requiresStrategy: false,
        defaultCounterpartyId: 0,
        defaultBrokerAccountNumber: null,
        defaultOrderCapacity: "Agency",
        fixName: "test",
    },
    {
        description: "Mock Dest 1",
        enableCounterparties: false,
        requiresStrategy: false,
        defaultCounterpartyId: 0,
        defaultBrokerAccountNumber: null,
        defaultOrderCapacity: "Agency",
        fixName: "test",
    },
    {
        description: "Mock Dest 2",
        enableCounterparties: false,
        requiresStrategy: false,
        defaultCounterpartyId: 0,
        defaultBrokerAccountNumber: null,
        defaultOrderCapacity: "Agency",
        fixName: "test",
    },
    {
        description: "Mock Dest 3",
        enableCounterparties: false,
        requiresStrategy: false,
        defaultCounterpartyId: 0,
        defaultBrokerAccountNumber: null,
        defaultOrderCapacity: "Agency",
        fixName: "test",
    },
    {
        description: "Mock Dest 4",
        enableCounterparties: false,
        requiresStrategy: false,
        defaultCounterpartyId: 0,
        defaultBrokerAccountNumber: null,
        defaultOrderCapacity: "Agency",
        fixName: "test",
    },
    {
        description: "Mock Dest 5",
        enableCounterparties: false,
        requiresStrategy: false,
        defaultCounterpartyId: 0,
        defaultBrokerAccountNumber: null,
        defaultOrderCapacity: "Agency",
        fixName: "test",
    },
];
export const MOCK_TRADE_TICKET_EXTERNAL_IDENTIFIERS = [
    { source: "Source A", identifierValue: "Mock Value 1" },
    { source: "Source B", identifierValue: "Mock Value 2" },
    { source: "Source C", identifierValue: "Mock Value 3" },
    { source: "Source D", identifierValue: "Mock Value 4" },
];
export const MOCK_TRADE_TICKET_ACCRUALS = [
    {
        id: 1,
        description: "description 01",
        period: "Month",
        frequency: "Bimonthly",
        enabled: true,
        method: "30360",
        steps: [
            {
                effectiveDate: MOCK_TRADE_TICKET_DATE_STRING,
                terminationDate: MOCK_TRADE_TICKET_DATE_STRING,
                amount: 100,
                currencyId: MOCK_TRADE_TICKET_CURRENCY_INFO_USD.id,
            },
        ],
    },
    {
        id: 2,
        description: "description 02",
        period: "Quarter",
        frequency: "Quarterly",
        enabled: true,
        method: "Actual360",
        steps: [
            {
                effectiveDate: MOCK_TRADE_TICKET_DATE_STRING,
                terminationDate: MOCK_TRADE_TICKET_DATE_STRING,
                amount: 100,
                currencyId: MOCK_TRADE_TICKET_CURRENCY_INFO_USD.id,
            },
            {
                effectiveDate: MOCK_TRADE_TICKET_DATE_STRING,
                terminationDate: MOCK_TRADE_TICKET_DATE_STRING,
                amount: 200,
                currencyId: MOCK_TRADE_TICKET_CURRENCY_INFO_USD.id,
            },
        ],
    },
    {
        id: 3,
        description: "description 03",
        period: "Daily",
        frequency: "NinetyOneDay",
        enabled: true,
        method: "Bus252",
        steps: [
            {
                effectiveDate: MOCK_TRADE_TICKET_DATE_STRING,
                terminationDate: MOCK_TRADE_TICKET_DATE_STRING,
                amount: 100,
                currencyId: MOCK_TRADE_TICKET_CURRENCY_INFO_USD.id,
            },
            {
                effectiveDate: MOCK_TRADE_TICKET_DATE_STRING,
                terminationDate: MOCK_TRADE_TICKET_DATE_STRING,
                amount: 200,
                currencyId: 54,
            },
            {
                effectiveDate: MOCK_TRADE_TICKET_DATE_STRING,
                terminationDate: MOCK_TRADE_TICKET_DATE_STRING,
                amount: 300,
                currencyId: 130,
            },
        ],
    },
];
export const MOCK_TRADE_TICKET_LOT_RELIEF = [
    {
        eventSequence: "1",
        originalLotOpening: "A",
        eventDate: MOCK_TRADE_TICKET_DATE_STRING,
        eventType: "type",
        tradeQuantity: 10,
        openLotQuantity: 10,
        nativeUnitCost: "-",
    },
    {
        eventSequence: "2",
        originalLotOpening: "B",
        eventDate: MOCK_TRADE_TICKET_DATE_STRING,
        eventType: "type",
        tradeQuantity: 10,
        openLotQuantity: 10,
        nativeUnitCost: "-",
    },
    {
        eventSequence: "3",
        originalLotOpening: "C",
        eventDate: MOCK_TRADE_TICKET_DATE_STRING,
        eventType: "type",
        tradeQuantity: 10,
        openLotQuantity: 10,
        nativeUnitCost: "-",
    },
];
export const MOCK_TRADE_TICKET_HISTORY = [
    {
        date: MOCK_TRADE_TICKET_DATE_STRING,
        time: MOCK_TRADE_TICKET_TIME_STRING,
        tradeId: 477771520,
        action: "Modify",
        user: "demouser",
    },
    {
        date: MOCK_TRADE_TICKET_DATE_STRING,
        time: MOCK_TRADE_TICKET_TIME_STRING,
        tradeId: 477771521,
        action: "Insert",
        user: "demouser2",
    },
];
export const MOCK_TRADE_TICKET_HISTORY_DETAILS = [
    {
        propertyName: "A",
        oldValue: "100",
        newValue: "200",
    },
    {
        propertyName: "B",
        oldValue: "300",
        newValue: "400",
    },
    {
        propertyName: "C",
        oldValue: "500",
        newValue: "600",
    },
];
export const MOCK_TRADE_TICKET_KEYWORDS = [
    { name: "Client Location", value: "" },
    { name: "DROP_COPY_SOURCE", value: "" },
    { name: "ENABLE_AMORTIZATION_ACCRETION", value: "" },
    { name: "Location", value: "" },
    { name: "MW ID", value: "" },
];
export const MOCK_TRADE_TICKET_VALUATION = [
    {
        description: "Fair Value",
        value: "",
        override: "",
    },
    {
        description: "Native NMV",
        value: "",
        override: "",
    },
    {
        description: "$ CS01",
        value: "",
        override: "",
    },
    {
        description: "$ DV01",
        value: "",
        override: "",
    },
];
export const MOCK_TRADE_TICKET_COST = [
    {
        type: "Margin Notional",
        amount: 17500,
    },
    {
        type: "Local Margin @ 0.00",
        amount: 0,
    },
    {
        type: "Book Margin @ 1.00per USD",
        amount: 0,
    },
];
export const MOCK_TRADE_TICKET_INSTRUMENT = {
    id: 122763,
    processingOrgId: 1,
    description: "APPLE ORD",
    financialSubType: {
        description: "Equity",
        parent: "Equity",
    },
    priceDivisor: 1,
    restricted: false,
    quoteType: "Price",
    quotationFormat: "Price4",
    keywords: [
        {
            name: "IFS_SECURITY_ID",
            value: "38905",
        },
        {
            name: "RBSS",
            value: "1769",
        },
        {
            name: "CUSIP" /* CUSIP */,
            value: "037833100",
        },
        {
            name: "DSE_QUOTE_ID",
            value: "0x0003dc004a4e845f",
        },
        {
            name: "RBC_CODE",
            value: "5710602011",
        },
        {
            name: "CFI",
            value: "ESVUFR",
        },
        {
            name: "DSE_ASSET_ID",
            value: "0x0003dd00137e64d7",
        },
        {
            name: "COMMON",
            value: "9718834",
        },
        {
            name: "DS_ISSUE_ID",
            value: "910068",
        },
        {
            name: "SEDOL" /* sedol */,
            value: "2046251",
        },
        {
            name: "ELEKTRON_ID",
            value: "AAPL.NB",
        },
        {
            name: "CSFB_SEC_ID",
            value: "60495",
        },
        {
            name: "CITCO_TID",
            value: "20764",
        },
        {
            name: "BBYELLOW" /* PICK */,
            value: "AAPL US Equity",
        },
        {
            name: "FTSE",
            value: "90-93-34",
        },
        {
            name: "ISIN" /* PICK */,
            value: "US0378331005",
        },
        {
            name: "JPMHFO_SEC_ID",
            value: "AAPL",
        },
        {
            name: "APT_SEC_ID",
            value: "992816",
        },
        {
            name: "GIC",
            value: "45202030",
        },
        {
            name: "DS_UNIQUE",
            value: "824273",
        },
        {
            name: "BARRA_ID",
            value: "USAB1X1",
        },
        {
            name: "ESMA_SHORT_SELLING_EXEMPT",
            value: "true",
        },
        {
            name: "RIC" /* PICK */,
            value: "AAPL.O",
        },
        {
            name: "BNPPARIBAS_SYMBOL",
            value: "AAPL",
        },
        {
            name: "SSNC_SEC_ID",
            value: "AAPL",
        },
        {
            name: "TICKER",
            value: "AAPL",
        },
    ],
    tickSize: 0.01,
    lotSize: 100,
    currency: {
        id: 231,
        code: "USD" /* Currency */,
        description: "US Dollar",
        deliverable: true,
        crypto: false,
        parentCurrencyId: 0,
        parentConversionFactor: 1,
    },
    exchange: {
        id: 4,
        shortName: "NASDAQ GSM (Q)" /* Exchange */,
        name: "NASDAQ Global Select Market Consolidated",
        country: {
            id: 231,
            code: "US",
        },
        keywords: [
            {
                name: "DS_EXCHANGE",
                value: "NSQ",
            },
            {
                name: "WILLIAMBUCKFA_EXCHANGE_CODE",
                value: "NSM",
            },
            {
                name: "SUNGARD_RT_EXCHANGE_CODE",
                value: "O",
            },
            {
                name: "TIME_ZONE",
                value: "America/New_York",
            },
            {
                name: "STOCK_DIV_ORDERING",
                value: "After",
            },
            {
                name: "MIC_CODE",
                value: "XNAS",
            },
            {
                name: "TRADE_PRICE_ROUNDING_DECIMALS",
                value: "4",
            },
            {
                name: "OPERATING_MIC_CODE",
                value: "XNAS",
            },
            {
                name: "FACT_SET_PRODUCT_DESCRIPTION",
                value: "NASDAQLevelI_RT",
            },
            {
                name: "BB_PRIMARY_EXCHANGE",
                value: "UW",
            },
            {
                name: "BB_COMPOSITE_EXCHANGE",
                value: "US",
            },
        ],
        Links: [],
    },
    Links: [],
};
export const MOCK_FA_GRID_PORTAL_DATA = [
    {
        id: 111111,
        name: "Bulk Financing",
        frequency: "Term",
        currency: "USD",
        startDate: "2003-04-01",
        endDate: "2003-04-01",
    },
    {
        id: 222222,
        name: "DB Short - China A - Connect Swap 1",
        frequency: "Annual",
        currency: "USN",
        startDate: "2004-05-02",
        endDate: "2004-05-02",
    },
    {
        id: 333333,
        name: "TEST TRS",
        frequency: "28 Day",
        currency: "UVR",
        startDate: "2005-06-03",
        endDate: "2005-06-03",
    },
];
export const createRelatedPositionsMockData = (selectedPosition) => {
    return [
        {
            description: selectedPosition.fundName.value,
            custodian: selectedPosition.custodianAccountDisplayName.value,
            currency: selectedPosition.bookNetAverageCost.currency,
            account: "account",
            strategyBook: "",
            quantity: selectedPosition.notionalQuantity.value,
            trs: selectedPosition.tRSDescription.value,
            dealId: selectedPosition.dealId.value,
            avgCost: selectedPosition.bookNetAverageCost.value,
        },
    ];
};
export const MOCK_BORROW_AGREEMENT_DATA = [
    {
        id: 111111,
        index: 0,
        name: "Agreement-1",
        country: "United States",
        frequency: "Annual",
        notionalCurrency: "USD",
        instrumentCurrency: "USD",
        initialSpreadRate: 0.3,
        createDate: "2023-01-01",
        startDate: "2023-01-01",
        endDate: "2023-01-31",
        custodian: "Citigroup Inc",
    },
    {
        id: 222222,
        index: 1,
        name: "Agreement-2",
        country: "United States",
        frequency: "Annual",
        notionalCurrency: "USD",
        instrumentCurrency: "USD",
        initialSpreadRate: 0.1,
        createDate: "2023-02-01",
        startDate: "2023-02-01",
        endDate: "2023-02-28",
        custodian: "Citigroup Inc",
    },
    {
        id: 333333,
        index: 2,
        name: "Agreement-3",
        country: "United States",
        frequency: "Annual",
        notionalCurrency: "USD",
        instrumentCurrency: "USD",
        initialSpreadRate: 0.2,
        createDate: "2023-04-01",
        startDate: "2023-04-01",
        endDate: "2023-04-30",
        custodian: "Citigroup Inc",
    },
    {
        id: 444444,
        index: 2,
        name: "Agreement-4",
        country: "United States",
        frequency: "Annual",
        notionalCurrency: "USD",
        instrumentCurrency: "USD",
        initialSpreadRate: 0.2,
        createDate: "2023-03-01",
        startDate: "2023-03-01",
        endDate: "2023-03-31",
        custodian: "Citigroup Inc",
    },
];
export const mockDividendsGridData = [
    {
        selected: "unknown",
        id: "123",
        instrumentId: "321",
        instrumentName: "Instrument",
        definitionName: "Def Name",
    },
];
export const DEFAULT_ENTRY = [
    {
        type: null,
        value: null,
        impliedInPrice: false,
    },
];
// TODO get real values form the api
// There are way more options in the desktop ui
export const FEE_OPTIONS = [
    {
        label: "Accrued",
        value: "Accrued",
    },
    {
        label: "Accrued (Auto Calculate)",
        value: "AccruedAutoCalculate",
    },
    {
        label: "Accrued Dividends Adjustment",
        value: "AccruedDividendsAdjustment",
    },
    {
        label: "Accrued Dividends W/H Tax Adjustment",
        value: "AccruedDividendsTaxAdjustment",
    },
];
export const paymentsGridMockData = [
    {
        paymentType: "Marginal",
        paymentAmount: "$100.00",
    },
    {
        paymentType: "Marginal",
        paymentAmount: "$100.00",
    },
];
