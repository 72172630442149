import { useReportRows } from "@app-context/reports/ReportsProvider";
import { useReportDatasource } from "@app-views/dashboards/hooks/useReportDatasource";
import { AsyncDatasourceContent } from "@app-views/dashboards/pdf/AsyncDatasourceContent";
import { ThemeDefinition } from "@enfusion-ui/core";
import { GraphWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { useRefCallback } from "@enfusion-ui/hooks";
import { useDimensions, withTheme } from "@enfusion-ui/web-core";
import { Image as PDFImage, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Canvg } from "canvg";
import * as React from "react";
import ReactDOM from "react-dom";
import { v4 as uuidv4 } from "uuid";

import RefGraphRenderer from "./RefGraphRenderer";

const pdfWidgetTitleStyles = StyleSheet.create({
  container: {
    display: "flex",
    minHeight: 32,
    width: "100%",
    padding: 4,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontFamily: "Lato",
    fontSize: 19,
  },
  subtitle: {
    fontFamily: "Lato",
    fontSize: 16,
    marginTop: 4,
    marginBottom: 4,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
});

const PdfWidgetTitle: React.FC<{
  title: string;
  subTitle?: string;
  theme: ThemeDefinition;
}> = ({ title, subTitle, theme }) => {
  if (!title) return <></>;
  return (
    <View
      style={[
        pdfWidgetTitleStyles.container,
        { backgroundColor: theme.colors.backgroundColor1Hover },
      ]}
    >
      <Text
        style={[pdfWidgetTitleStyles.title, { color: theme.colors.textNormal }]}
      >
        {title}
      </Text>
      {subTitle && (
        <Text
          style={[
            pdfWidgetTitleStyles.subtitle,
            { color: theme.colors.textNormal },
          ]}
        >
          {subTitle}
        </Text>
      )}
    </View>
  );
};

const PdfGraphWidget = withTheme(({ theme }: { theme: ThemeDefinition }) => {
  const { width, height } = useDimensions();
  const { config } = useWidget();
  const { gridOptions, tableId } = useReportDatasource();
  const { rowsStore } = useReportRows();
  const rowOptions = tableId ? rowsStore?.[tableId] ?? null : null;
  const id = `chart-${uuidv4()}`;

  const handleLoad = async () => {
    const svg = document.querySelector(`#${id} svg`);
    const svgString = svg?.outerHTML;
    const modifiedSvgString = svgString?.replace(
      "&quot;Lucida Grande&quot",
      "Lato"
    );
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (ctx && modifiedSvgString) {
      const v = Canvg.fromString(ctx, modifiedSvgString);
      v.start();
      await v.ready();
      document.body.appendChild(canvas);
      return canvas.toDataURL("image/png");
    }

    return "";
  };

  const afterDataCb = useRefCallback(() => {
    return new Promise<{ status: "success"; content: string }>((resolve) => {
      const chartPlaceHolderElement = document.createElement("div");
      chartPlaceHolderElement.id = id;
      chartPlaceHolderElement.style.display = "none";
      document.body.appendChild(chartPlaceHolderElement);
      ReactDOM.render(
        <RefGraphRenderer
          width={width!}
          height={height!}
          config={config as unknown as GraphWidgetConfig}
          gridOptions={gridOptions}
          rowOptions={rowOptions}
          onLoad={() => {
            handleLoad()
              .then((url) => resolve({ status: "success", content: url }))
              .finally(() => chartPlaceHolderElement.remove());
          }}
          theme={theme}
        />,
        chartPlaceHolderElement
      );
    });
  }, [theme, gridOptions, config, width, height, rowOptions]);

  return (
    <AsyncDatasourceContent afterDataCb={afterDataCb}>
      {(cachedContent) => (
        <View style={{ width: width!, height: height! }}>
          <View>
            <PdfWidgetTitle
              subTitle={config.subTitle}
              title={config.title}
              theme={theme}
            />
            <PDFImage
              style={{
                width: "100%",
                height: "100%",
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
              }}
              src={cachedContent.content as string}
            />
          </View>
        </View>
      )}
    </AsyncDatasourceContent>
  );
});

export default PdfGraphWidget;
