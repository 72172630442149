/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRefCallback } from "@enfusion-ui/hooks";
import { TextInput } from "@enfusion-ui/web-components";
import { REPORT_ROW_HEIGHT } from "@enfusion-ui/web-core";
import { ICellEditorParams } from "ag-grid-enterprise";
import * as React from "react";

export const TextInputCellEditor = React.memo(
  React.forwardRef<
    any,
    ICellEditorParams & {
      cellStyle?: React.CSSProperties;
    }
  >(({ value, api, cellStyle = {} }, ref) => {
    const [internalValue, setInternalValue] = React.useState<string | null>(
      value ?? ""
    );

    const inputRef = React.useRef<HTMLInputElement | null>(null);
    React.useEffect(() => {
      requestAnimationFrame(() => {
        inputRef.current?.focus();
      });
    }, []);

    React.useImperativeHandle(
      ref,
      () => {
        return {
          // the final value to send to the grid, on completion of editing
          getValue: () => {
            return internalValue;
          },

          // Gets called once before editing starts, to give editor a chance to
          // cancel the editing before it even starts.
          isCancelBeforeStart: () => false,

          // Gets called once when editing is finished (eg if Enter is pressed).
          // If you return true, then the result of the edit will be ignored.
          isCancelAfterEnd: () => false,
        };
      },
      [internalValue]
    );

    const handleChange = useRefCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(e.currentTarget.value);
      },
      []
    );

    return (
      <TextInput
        style={{
          height: REPORT_ROW_HEIGHT - 3,
          ...cellStyle,
        }}
        hideLabel
        autoFocus
        minWidth="50px"
        ref={inputRef}
        onChange={handleChange}
        value={internalValue ?? ""}
        onBlur={() => requestAnimationFrame(() => api.stopEditing())}
        data-testid="text-input-cell-editor"
      />
    );
  })
);
