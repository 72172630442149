import { CashAccountingMethodOptions } from "@enfusion-ui/types";
import { ControlledSelect, PanelRow } from "@enfusion-ui/web-components";

import { DateSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledLedgerSelect } from "../../../components/ReportQueryComponents/LedgerSelect";

const GLCashBalancesSettings = () => {
  return (
    <>
      <ControlledLedgerSelect name="generalLedgerIds" label="General Ledger" />
      <PanelRow>
        <DateSelection name="valueDate" label="Value Date" />
        <ControlledSelect
          name="accountingMethod"
          label="Cash Mode"
          options={CashAccountingMethodOptions}
        />
      </PanelRow>
    </>
  );
};

export default GLCashBalancesSettings;
