import { InstrumentInfo } from "@enfusion-ui/types";
import {
  FormController,
  FormControllerProps,
} from "@enfusion-ui/web-components";
import * as React from "react";

import InstrumentSelect from "./InstrumentSelect";
import { InstrumentSelectProps } from "./types";

type ControlledInstrumentSelectProps = Omit<
  InstrumentSelectProps,
  "onChange" | "value"
> &
  Pick<
    FormControllerProps,
    | "name"
    | "basis"
    | "mobileBasis"
    | "tabletBasis"
    | "desktopBasis"
    | "control"
    | "style"
  > & {
    onChange?: (instrument: InstrumentInfo | null) => void;
    dataE2EId?: string;
  };

export const ControlledInstrumentSelect: React.FC<
  ControlledInstrumentSelectProps
> = ({
  name,
  label = "",
  style,
  basis,
  inline,
  control,
  mobileBasis,
  tabletBasis,
  desktopBasis,
  onChange: onChangeExternal,
  ...props
}) => {
  return (
    <FormController
      name={name}
      style={style}
      basis={basis}
      control={control}
      mobileBasis={mobileBasis}
      tabletBasis={tabletBasis}
      desktopBasis={desktopBasis}
      render={({ onChange, value }) => (
        <InstrumentSelect
          {...props}
          label={label}
          inline={inline}
          value={value?.id}
          onChange={(instrument) => {
            onChangeExternal?.(instrument);
            onChange(instrument);
          }}
        />
      )}
    />
  );
};
