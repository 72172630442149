/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BREAK_POINTS_MAP_V2,
  getBreakPointKey,
  getDimensions,
  useGridSizing as useGridSizingCore,
  useWidget,
  WIDGET_DEFINITIONS,
  WidgetConfig,
} from "@enfusion-ui/dashboards";
import {
  ChannelDataType,
  DashboardGridLayoutConfig,
  DashboardOrientation,
  FlexLayout,
} from "@enfusion-ui/types";
import { last, sortBy } from "lodash";
import * as React from "react";

export const PAPER_WIDTH = { portrait: 595.28, landscape: 841.89 } as const;

export function createPageLayouts(
  layout: FlexLayout[] | null | undefined,
  pageRowCount: number
) {
  if (!layout) return null;

  return sortBy(layout, ["y"]).reduce(
    (res, entry) => {
      // find how much to move a widget up
      let moveUp = 0;
      for (let i = entry.x; i < entry.x + entry.w; i += 1) {
        if (typeof res[1][i] === "undefined") res[1][i] = 0;
        if (moveUp === 0) moveUp = res[1][i] as number;
        else moveUp = Math.min(moveUp, res[1][i] as number);
      }

      entry.y = entry.y - moveUp;
      const page = Math.floor(entry.y / pageRowCount);

      if (!res[0][page]) {
        res[0][page] = [];
      }

      entry.y = entry.y - page * pageRowCount;

      const bottom = entry.y + entry.h;
      // if bottom of widget goes to next page cut it off
      if (bottom > pageRowCount) {
        const diff = bottom - pageRowCount;
        entry.h = Math.max(entry.h - diff, 0);

        // increase columns affected that should move up
        for (let i = entry.x; i < entry.x + entry.w; i += 1) {
          if (typeof res[1][i] === "undefined") res[1][i] = 0;
          res[1][i] = (res[1][i] as number) + diff;
        }
      }

      (res[0][page] as Array<FlexLayout>).push(entry);
      return res;
    },
    [[] as Array<Array<FlexLayout>>, {} as Record<number, number>]
  )[0] as Array<Array<FlexLayout>>;
}

function generateLayout(colCount: number, widgets: WidgetConfig[]) {
  const res = getDimensions(
    widgets,
    {
      gen: widgets.reduce((acc, entry) => {
        const prevEntry = last(acc);
        const prevBottom = prevEntry ? prevEntry.y + prevEntry.h : 0;
        const minSizeBase = WIDGET_DEFINITIONS[entry.type].minSize || [1, 1];
        const minSize =
          typeof minSizeBase === "function" ? minSizeBase() : minSizeBase;
        acc.push({
          i: entry.id,
          x: 0,
          y: prevBottom + 1,
          h: minSize[0],
          w: Math.min(minSize[1], colCount),
          minH: minSize[0],
          minW: minSize[1],
          isResizable: false,
          isDraggable: false,
        });
        return acc;
      }, [] as FlexLayout[]),
    },
    true
  );
  return res.layout.gen;
}

export const usePDFGridSizing = (
  mainGridProps: DashboardGridLayoutConfig<any>,
  orientation: DashboardOrientation = "portrait"
) => {
  const breakpointKey = React.useMemo(() => {
    return getBreakPointKey(PAPER_WIDTH[orientation]);
  }, [PAPER_WIDTH, orientation]);

  const mainProps = React.useMemo(
    () => ({
      ...mainGridProps,
      placement: {
        ...mainGridProps.placement,
        [breakpointKey]:
          mainGridProps.placement[breakpointKey] ??
          generateLayout(
            BREAK_POINTS_MAP_V2[breakpointKey],
            mainGridProps.widgets
          ),
      },
    }),
    [mainGridProps, breakpointKey]
  );

  const sizing = useGridSizingCore(
    PAPER_WIDTH[orientation],
    Number.MAX_SAFE_INTEGER,
    0,
    false,
    mainProps
  );

  return React.useMemo(
    () => ({
      breakpointKey,
      sizing,
    }),
    [breakpointKey, sizing]
  );
};

// utils
export function pxToPtConversion(px: number): number {
  return (3 / 4) * px;
}

export function isProgressStepsComplete(metadata: any) {
  if (metadata !== undefined && metadata.progressSteps !== undefined) {
    return metadata?.progressSteps.every(
      (step: any) => step.status === "Completed"
    );
  }
  return false;
}

export function useSelectDataValue(
  name: string,
  description: string,
  type: ChannelDataType,
  defaultValue?: any
) {
  const { addChannelKeys, setChannelData } = useWidget();
  React.useEffect(() => {
    addChannelKeys([
      {
        name,
        description,
        type,
      },
    ]);
    setChannelData(defaultValue ? [defaultValue] : [[]]);
  }, [setChannelData, defaultValue, name]);
}
