/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  contractOrExpandAllRows,
  contractOrExpandNode,
  contractOrExpandParentRows,
  contractOrExpandSubRows,
} from "@app-utils/gridRowExpand";
import { getComplianceIcon } from "@app-views/oems/components/ComplianceStateCellRenderer";
import {
  ArgsWithGetParentRows,
  getGroupRowComplianceState,
  GetParentRowsFunc,
  PortfolioTreeNodeCore,
} from "@enfusion-ui/portfolios";
import {
  formatNumber,
  formatPercentage,
  formatReportValue,
} from "@enfusion-ui/utils";
import { SpinnerHTML } from "@enfusion-ui/web-components";
import { dataEntry } from "@enfusion-ui/web-core";
import { icon } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowsToLine,
  faArrowsUpDown,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import {
  CellClassParams,
  CellStyle,
  ColDef,
  EditableCallbackParams,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GetQuickFilterTextParams,
  IAggFunc,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import BigNumber from "bignumber.js";
import * as React from "react";

import { priceStatusToolTipGetter } from "../utils";
import { ContextItemEntry } from "./MainPortfolioGrid";

type EditableArgs = ArgsWithGetParentRows<EditableCallbackParams>;
function isPriceCellEditable({ node, context }: EditableArgs) {
  const data = getRowData(node, context?.getParentRows);
  if (
    !data ||
    !data?.displayInstrument?.description ||
    data?.issuer?.description === "Cash"
  )
    return false;
  return !!data.priceStatus?.value;
}

const editableCellStyles = (params: CellClassParams) => {
  if (params.node.group) return {} as CellStyle;
  return {
    backgroundColor: "var(--background-accent)",
    padding: "0px",
  };
};

function getRowPath(
  rowNode: IRowNode,
  pathMap: Map<string, string[]>
): string[] | null {
  const childNode = rowNode.group ? rowNode.allLeafChildren[0] : rowNode;
  if (!childNode || !childNode.id) return null;
  return pathMap.get(childNode.id) ?? null;
}

export function getParentRowValues(
  rowNode: IRowNode,
  getParentRows?: GetParentRowsFunc
) {
  if (rowNode.id === "ROOT_NODE_ID") return null;

  const parentInfo = getParentRows?.();

  if (!parentInfo || parentInfo?.rows.size === 0) return null;

  const path = getRowPath(rowNode, parentInfo.paths);
  const parentNodeId = path?.[rowNode.level + 1];
  const parentNode = parentInfo.rows.get(parentNodeId ?? "");

  if (!parentNode) return null;

  return {
    ...parentNode.columnValues,
    ...parentNode,
  } as PortfolioTreeNodeCore & Record<string, any>;
}

export function getRowData(
  node: IRowNode<any>,
  getParentRows?: GetParentRowsFunc
) {
  if (!node) return null;
  let data: null | Record<string, any> = node.group
    ? getParentRowValues(node, getParentRows)
    : (node.data as Record<string, any>);
  return data;
}

export function useRowData(
  node: IRowNode<any>,
  getParentRows?: GetParentRowsFunc,
  key?: string | null,
  forceKey = false,
  deps: React.DependencyList = []
): null | any {
  const data = React.useMemo(
    () => getRowData(node, getParentRows) ?? {},
    [node, node.aggData, node.data, getParentRows, key, ...deps]
  );

  if (typeof key !== "string") return forceKey ? null : data;
  return data[key] ?? null;
}

export const aggFuncs = {
  doubleAgg: (({ context, rowNode, colDef }) => {
    const data = getParentRowValues(rowNode, context?.getParentRows);
    if (data === null || typeof colDef.valueGetter !== "function") return 0;
    return colDef.valueGetter({
      colDef,
      data,
    } as any);
  }) as IAggFunc,
  textAgg: ((args) => {
    const res = new Set(args.values);
    return [...res]
      .filter(
        (i) =>
          typeof i !== "undefined" &&
          i !== null &&
          i.toString().trim().length > 0
      )
      .sort()
      .join(", ");
  }) as IAggFunc,
  complianceStateAgg: ((args) => {
    const {
      rowNode,
      colDef: { cellRendererParams },
    } = args;
    const allLeafNode: IRowNode[] = rowNode.allLeafChildren;

    return getGroupRowComplianceState(
      allLeafNode,
      cellRendererParams.defaultState
    )?.[0]?.state;
  }) as IAggFunc,
};

const numericColumnType = {
  aggFunc: "doubleAgg",
  valueGetter: (args: ValueGetterParams) => dataEntry(args).value || 0,
  filterValueGetter: (args: ValueGetterParams) => dataEntry(args).value || 0,

  filter: "agNumberColumnFilter",
};

const textColumnType = {
  aggFunc: "textAgg",
  enableRowGroup: true,
  cellStyle: { textAlign: "left" },
  filter: "agMultiColumnFilter",
  filterParams: {
    filters: [
      {
        filter: "agTextColumnFilter",
      },
      {
        filter: "agSetColumnFilter",
      },
    ],
  },

  tooltipValueGetter: (params: any) => params.value,
};

function isCellEditable({ node }: EditableArgs) {
  return node.isSelected() !== true;
}

const percentInputCellEditorParams = {
  step: 0.01,
  convertValue: (value: number | null) =>
    value === null
      ? null
      : Number(
          new BigNumber(value).multipliedBy(100).toFixed(DECIMAL_PRECISION)
        ),
  reverseConvertValue: (value: number | null) =>
    value === null
      ? null
      : Number(
          new BigNumber(value).dividedBy(100).toFixed(DECIMAL_PRECISION + 2)
        ),
};

const numericInputCell = {
  ...numericColumnType,
  width: 130,
  editable: isCellEditable,
  cellEditor: "numericInputCellEditor",
  cellEditorParams: {
    step: 1,
  },
  cellRenderer: "numericInputCellRenderer",
  cellStyle: {
    padding: "0px 1px",
    lineHeight: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "var(--background-accent)",
  },
} as ColDef;

type Args =
  | ArgsWithGetParentRows<ValueFormatterParams>
  | ArgsWithGetParentRows<GetQuickFilterTextParams>;
const createFormatter = (formatterArgs: any, options?: any) => {
  const formatter = ({ data, context, colDef, node, value }: Args) => {
    if (typeof value === "undefined" || value === null) return null;
    let entry = dataEntry({ data, colDef } as ValueFormatterParams, null);
    if (!entry && typeof node?.rowGroupIndex === "number") {
      const res = getParentRowValues(node, context?.getParentRows);
      if (res !== null)
        entry = dataEntry({ data: res, colDef } as ValueFormatterParams);
    }
    return formatReportValue(
      {
        ...(entry || {}),
        value,
        currency: entry?.currency ?? "USD",
      },
      formatterArgs,
      options
    );
  };
  return {
    valueFormatter: formatter,
    getQuickFilterText: (args: Args) => {
      if (args.node?.group) return "";
      return formatter(args);
    },
  };
};

const currencyFormatter = createFormatter({
  dataType: "Double",
  numberStyle: "Currency",
});

const percentFormatter = createFormatter({
  dataType: "Double",
  numberStyle: "Percent",
});

const doubleFormatter = createFormatter(
  {
    dataType: "Double",
  },
  {
    numberFormat: {
      fractionDigits: [0, 4, 2],
    },
  }
);

const integerFormatter = createFormatter({
  dataType: "Integer",
});

export const DECIMAL_PRECISION = 6;

export const mainPortfolioGridColumnTypes: { [key: string]: ColDef } = {
  currencyInputCell: {
    ...numericInputCell,
    ...currencyFormatter,
  },
  percentInputCell: {
    ...numericInputCell,
    ...percentFormatter,
    cellEditorParams: percentInputCellEditorParams,
  },
  doubleInputCell: {
    ...numericInputCell,
    ...doubleFormatter,
  },
  descriptionColumn: {
    ...textColumnType,
    valueGetter: (args: ValueGetterParams) =>
      dataEntry(args).description || null,
    filterValueGetter: (args: ValueGetterParams) =>
      dataEntry(args).description || null,
  },
  textColumn: {
    ...textColumnType,
    valueGetter: (args: ValueGetterParams) => dataEntry(args).value || null,
    filterValueGetter: (args: ValueGetterParams) =>
      dataEntry(args).value || null,
  },
  doubleColumn: {
    ...numericColumnType,
    ...doubleFormatter,
    cellRenderer: "statusCellRenderer",
  },
  currencyColumn: {
    ...numericColumnType,
    ...currencyFormatter,
    cellRenderer: "statusCellRenderer",
  },
  percentColumn: {
    ...numericColumnType,
    ...percentFormatter,
    cellRenderer: "statusCellRenderer",
  },
  priceColumn: {
    ...numericColumnType,
    ...currencyFormatter,
    singleClickEdit: true,
    editable: isPriceCellEditable,
    cellEditorSelector: ({ node, context, colDef }) => {
      const data = getRowData(node, context?.getParentRows);
      const dataEntry = data?.[colDef?.field ?? ""];
      if (
        typeof dataEntry?.value === "number" &&
        dataEntry.quoteType === "PercentOfPar"
      ) {
        return {
          component: "numericInputCellEditor",
          params: percentInputCellEditorParams,
        };
      }
      return { component: "numericInputCellEditor" };
    },
    cellRenderer: "statusCellRenderer",
    cellRendererParams: {
      statusValueGetter: (data: Record<string, { value: string }> | null) =>
        data?.priceStatus?.value,
    },
    tooltipValueGetter: priceStatusToolTipGetter,
  },
};

export const proposedOrderColumnDefs: ColDef[] = [
  {
    headerName: "",
    field: "selection",
    colId: "selection",
    initialWidth: 27,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    pinned: "left",
    lockPinned: true,
    lockVisible: true,
    suppressHeaderMenuButton: true,
    resizable: false,
    suppressNavigable: true,
    suppressMovable: true,
  },
  {
    field: "instrumentDescription",
    headerName: "Security",
    initialWidth: 150,
    minWidth: 80,
    enableRowGroup: true,
    sortable: true,
  },
  {
    field: "orderSide",
    headerName: "Side",
    cellRenderer: "sideCellRenderer",
    initialWidth: 80,
    minWidth: 70,
    enableRowGroup: true,
  },
  {
    field: "quantity",
    headerName: "Qty",
    initialWidth: 80,
    minWidth: 70,
    enableRowGroup: false,
    ...integerFormatter,
  },
  {
    field: "orderType",
    headerName: "Type",
    editable: true,
    cellEditor: "typeCellEditor",
    cellRenderer: "typeCellRenderer",
    cellRendererParams: {
      allowGroup: false,
    },
    cellStyle: editableCellStyles,
    initialWidth: 145,
    minWidth: 80,
    enableRowGroup: true,
  },
  {
    colId: "limitPrice",
    field: "limitPrice",
    headerName: "Limit",
    valueGetter: (args: ValueGetterParams) => dataEntry(args),
    editable: true,
    cellEditor: "numericInputCellEditor",
    cellEditorParams: {
      step: 1,
      min: 0,
      useValue: true,
      cellStyle: { height: "80%", paddingTop: "var(--spacing-l)" },
    },
    cellRenderer: "numericInputCellRenderer",
    cellRendererParams: {
      useValue: true,
      cellStyle: { height: "80%", paddingTop: "var(--spacing-l)" },
      placeholder: "0",
      allowGroup: false,
    },
    cellStyle: editableCellStyles,
    initialWidth: 80,
    minWidth: 70,
    enableRowGroup: false,
    ...doubleFormatter,
  },
  {
    colId: "stopPrice",
    field: "stopPrice",
    headerName: "Stop",
    valueGetter: (args: ValueGetterParams) => dataEntry(args),
    editable: true,
    cellEditor: "numericInputCellEditor",
    cellEditorParams: {
      step: 1,
      min: 0,
      useValue: true,
      cellStyle: { height: "80%", paddingTop: "var(--spacing-l)" },
    },
    cellRenderer: "numericInputCellRenderer",
    cellRendererParams: {
      useValue: true,
      cellStyle: { height: "80%", paddingTop: "var(--spacing-l)" },
      placeholder: "0",
      allowGroup: false,
    },
    cellStyle: editableCellStyles,
    initialWidth: 80,
    minWidth: 70,
    enableRowGroup: false,
    ...doubleFormatter,
  },
  {
    colId: "bookId",
    field: "bookId",
    headerName: "Book Strategy",
    cellEditor: "strategyCellEditor",
    cellRenderer: "strategyCellRenderer",
    cellRendererParams: {
      allowGroup: false,
    },
    cellStyle: editableCellStyles,
    initialWidth: 150,
    minWidth: 100,
    enableRowGroup: true,
    editable: true,
  },
  {
    colId: "portfolioManager",
    field: "portfolioManager",
    headerName: "PM",
    cellEditor: "pmCellEditor",
    cellRenderer: "pmCellRenderer",
    cellRendererParams: {
      allowGroup: false,
    },
    cellStyle: editableCellStyles,
    initialWidth: 120,
    minWidth: 90,
    enableRowGroup: true,
    editable: true,
  },
  {
    colId: "tradingDesk",
    field: "tradingDesk",
    headerName: "Desk",
    cellEditor: "deskCellEditor",
    cellRenderer: "deskCellRenderer",
    cellStyle: editableCellStyles,
    cellRendererParams: {
      allowGroup: false,
    },
    initialWidth: 120,
    minWidth: 90,
    enableRowGroup: true,
    editable: true,
  },
  {
    colId: "trader",
    field: "trader",
    headerName: "Trader",
    cellEditor: "traderCellEditor",
    cellRenderer: "traderCellRenderer",
    cellRendererParams: {
      allowGroup: false,
    },
    cellStyle: editableCellStyles,
    initialWidth: 120,
    minWidth: 90,
    enableRowGroup: true,
    editable: true,
  },
  {
    colId: "timeInForce",
    field: "timeInForce",
    headerName: "TIF",
    cellEditor: "tifCellEditor",
    cellRenderer: "tifCellRenderer",
    cellRendererParams: {
      allowGroup: false,
    },
    cellStyle: editableCellStyles,
    initialWidth: 120,
    minWidth: 90,
    enableRowGroup: true,
    editable: true,
  },
  {
    colId: "notes",
    field: "notes",
    headerName: "Note",
    editable: true,
    cellEditor: "textInputCellEditor",
    cellRenderer: "textInputCellRenderer",
    cellRendererParams: {
      allowGroup: false,
    },
    cellStyle: editableCellStyles,
    initialWidth: 150,
    minWidth: 120,
    enableRowGroup: false,
  },
  {
    aggFunc: "complianceStateAgg",
    field: "complianceResult.complianceState",
    headerName: "Compliance State",
    type: "complianceState",
    initialWidth: 150,
    minWidth: 100,
    initialPinned: "right",
    enableRowGroup: true,
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

export const proposedAllocationColumnDefs: ColDef[] = [
  {
    headerName: "",
    field: "selection",
    colId: "selection",
    initialWidth: 27,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    pinned: "left",
    lockVisible: true,
    lockPinned: true,
    suppressHeaderMenuButton: true,
    resizable: false,
    suppressNavigable: true,
    suppressMovable: true,
  },
  {
    headerName: "Security",
    field: "security",
    enableRowGroup: true,
    initialWidth: 151,
  },
  {
    headerName: "Fund",
    field: "fundName",
    enableRowGroup: true,
    initialWidth: 100,
  },
  {
    headerName: "Account",
    field: "accountName",
    enableRowGroup: true,
    initialWidth: 140,
  },
  {
    headerName: "Side",
    field: "orderSide",
    cellRenderer: "sideCellRenderer",
    initialWidth: 100,
    enableRowGroup: true,
  },
  {
    headerName: "Inc % NAV",
    field: "incrementalNAV",
    initialWidth: 140,
    cellClass: "ag-right-aligned-cell",
    valueFormatter: ({ value }) => formatPercentage(value),
  },
  {
    headerName: "Final % NAV",
    field: "finalNAV",
    initialWidth: 140,
    cellClass: "ag-right-aligned-cell",
    valueFormatter: ({ value }) => formatPercentage(value),
  },
  {
    headerName: "Proposed Trade Quantity,",
    field: "allocation",
    initialWidth: 149,
    cellClass: "ag-right-aligned-cell",
    valueFormatter: ({ value }) => formatNumber(value, [0, 4]),
  },
  {
    headerName: "Violation Message",
    field: "complianceResult",
    enableRowGroup: true,
    valueGetter: (params) => {
      return (
        params.data?.complianceResult?.complianceRecords?.filter(
          (i: any) => i.fundId === params.data.fundId
        )[0] ?? {}
      );
    },
    valueFormatter: (params) => params.value?.text ?? "",
    cellStyle: ({ value }) => {
      if (!value) return { color: "inherit" };
      const { color: c } = getComplianceIcon(value.complianceState);
      return { color: `var(--${c})` };
    },
  },
  {
    aggFunc: "complianceStateAgg",
    headerName: "Compliance State",
    field: "complianceResult.complianceState",
    initialPinned: "right",
    type: "complianceState",
    enableRowGroup: true,
    initialWidth: 150,
    minWidth: 100,
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

export const gridOverlayTemplate = (isPortfolioAdjusting: boolean) => {
  return `<span className="ag-overlay-loading-center">${SpinnerHTML}<p style="margin-top: var(--spacing)">${
    isPortfolioAdjusting ? "Adjustment processing" : "Loading..."
  }</p></span>`;
};

export const PROPOSED_ORDER_EDITABLE_FIELDS = [
  "orderType",
  "limitPrice",
  "stopPrice",
  "bookId",
  "timeInForce",
  "tradingDesk",
  "portfolioManager",
  "trader",
  "notes",
];

export function getMainMenuExpandContractActions(
  params: GetMainMenuItemsParams
): ContextItemEntry[] {
  return [
    {
      name: "Expand All Rows",
      icon: icon(faArrowsUpDown as IconDefinition).html?.[0],
      action: () => {
        contractOrExpandAllRows(params.api, true);
      },
    },
    {
      name: "Contract All Rows",
      icon: icon(faArrowsToLine as IconDefinition).html?.[0],
      action: () => {
        contractOrExpandAllRows(params.api, false);
      },
    },
  ];
}

export function getExpandContractActions(params: GetContextMenuItemsParams) {
  return [
    {
      name: "Expand Rows",
      icon: icon(faArrowsUpDown as IconDefinition).html?.[0],
      subMenu: [
        {
          name: "All Rows",
          action: () => {
            contractOrExpandAllRows(params.api, true);
          },
        },
        params.node && params.node?.parent && params.node?.group
          ? {
              name: "This Level",
              action: () => {
                contractOrExpandSubRows(params.node?.parent!, true, false);
              },
            }
          : null,
        params.node && params.node?.group
          ? {
              name: "This Row & Sub Rows",
              action: () => {
                contractOrExpandNode(params.node, true);
                contractOrExpandSubRows(params.node, true);
              },
            }
          : null,
      ].filter(Boolean) as Array<ContextItemEntry>,
    },
    {
      name: "Contract Rows",
      icon: icon(faArrowsToLine as IconDefinition).html?.[0],
      subMenu: [
        {
          name: "All Rows",
          action: () => {
            contractOrExpandAllRows(params.api, false);
          },
        },
        params.node && params.node?.group
          ? {
              name: "All Sub Rows",
              action: () => {
                contractOrExpandSubRows(params.node, false);
              },
            }
          : null,
        params.node && params.node?.group && params.node?.parent
          ? {
              name: "All Parent & Sub Rows",
              action: () => {
                contractOrExpandSubRows(params.node, false);
                contractOrExpandNode(params.node, false);
                contractOrExpandParentRows(params.node, false);
              },
            }
          : null,
        params.node && !params.node?.group && params.node?.parent
          ? {
              name: "All Parent Rows",
              action: () => {
                contractOrExpandParentRows(params.node, false);
              },
            }
          : null,
      ].filter(Boolean) as Array<ContextItemEntry>,
    },
  ];
}
