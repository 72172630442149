import { WidgetComponentsDefinition } from "../../../types";
import LedgerSelectionEditWidget from "./LedgerSelectionEditWidget";
import LedgerSelectionWidget from "./LedgerSelectionWidget";
import PdfLedgerSelectionWidget from "./PdfLedgerSelectionWidget";

export const ledgerSelection: WidgetComponentsDefinition = {
  renderComponent: LedgerSelectionWidget,
  editComponent: LedgerSelectionEditWidget,
  renderPdfComponent: PdfLedgerSelectionWidget,
};
