import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { TabPanel, TabPanelContent } from "../../components/TabPanel";
import { DrawdownsSummary } from "./DrawdownSummary";
import { ExposureSummary } from "./ExposureSummary";
import { KPISummary } from "./KPISummary";

const MockDetailBlock = styled.div`
  height: 300px;
`;

const contents: TabPanelContent[] = [
  {
    key: "exposure",
    summaryComponent: <ExposureSummary />,
    detailComponent: <MockDetailBlock>Exposure</MockDetailBlock>,
  },
  {
    key: "kpis",
    summaryComponent: <KPISummary />,
    detailComponent: <MockDetailBlock>KPIs</MockDetailBlock>,
  },
  {
    key: "drawdowns",
    summaryComponent: <DrawdownsSummary />,
    detailComponent: <MockDetailBlock>DrawDowns</MockDetailBlock>,
  },
];

export const AnalyticsRiskPanel: React.FC = () => {
  return <TabPanel title="Risk" contents={contents} />;
};
