import {
  MarketEnvironmentSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import MarketEnvironmentSelect from "../../../../../reports/components/ReportQueryComponents/MarketEnvironmentSelect";

const MarketEnvironmentSelectionWidget = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, key } =
    config as MarketEnvironmentSelectionWidgetConfig;
  const [value, setValue] = React.useState<number | null>(defaultValue);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Market Environment",
        type: ChannelDataType.Number,
      },
    ]);
  }, [addChannelKeys, key]);

  React.useEffect(() => {
    setChannelData(value ? [value] : [[]]);
  }, [setChannelData, value]);

  const handleChange = (value: number | null) => {
    setValue(value);
  };

  return (
    <CenterContent>
      <MarketEnvironmentSelect
        label={key}
        value={value}
        onChange={handleChange}
      />
    </CenterContent>
  );
};

export default MarketEnvironmentSelectionWidget;
