import { useRefCallback } from "@enfusion-ui/hooks";
import {
  FixedVariableRateEntry,
  TradeTicketFormValues,
} from "@enfusion-ui/trades";
import {
  Button,
  ControlledDatePicker,
  ControlledNumericInput,
  FormElement,
  LabeledButton,
  PanelRow,
} from "@enfusion-ui/web-components";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

export const VariableRates: React.FC = () => {
  const { fields, append, remove } = useFieldArray<FixedVariableRateEntry>({
    name: "fixedVariableRates",
  });

  const fixedVariableRates = useWatch<
    TradeTicketFormValues["fixedVariableRates"]
  >({ name: "fixedVariableRates" });

  const { setValue } = useFormContext();

  const addEmptyEntry = useRefCallback(
    () => append({ effectiveDate: null, rate: null }),
    [append]
  );

  React.useEffect(() => {
    if (!fields?.length) addEmptyEntry();
  }, [fields]);

  React.useEffect(() => {
    const firstRowRate = fixedVariableRates?.[0]?.rate ?? null;
    if (firstRowRate) {
      setValue("fixedRate", firstRowRate);
    }
  }, [fixedVariableRates?.[0]?.rate]);

  return (
    <>
      {fields.map((item, index) => {
        const isFirstRow = index === 0;
        return (
          <PanelRow key={item.id} style={{ flexWrap: "nowrap" }}>
            <ControlledNumericInput
              required
              minWidth="0"
              hideLabel={!isFirstRow}
              formatStyle="percent"
              label={isFirstRow ? "Rate" : null}
              name={`fixedVariableRates[${index}].rate`}
            />
            <ControlledDatePicker
              required
              inline={!isFirstRow}
              hideLabel={!isFirstRow}
              label={isFirstRow ? "Effective Date" : null}
              name={`fixedVariableRates[${index}].effectiveDate`}
            />
            <FormElement mobileBasis="max-content" style={{ flex: 0 }}>
              <LabeledButton
                icon={faTrashCan}
                onClick={() => remove(index)}
                hideLabelPlaceholder={!isFirstRow}
              />
            </FormElement>
          </PanelRow>
        );
      })}

      <Button onClick={addEmptyEntry}>Add Rate</Button>
    </>
  );
};
