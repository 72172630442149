import CounterpartySelection from "@app-views/oems/components/CounterpartySelection";
import { InstructionsSelect } from "@app-views/trade/TradeTicket/components/InstructionsSelect";
import {
  ControlledSelect,
  FormElement,
  LabeledButton,
  PanelRow,
} from "@enfusion-ui/web-components";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { noop } from "lodash";
import * as React from "react";

import { EllipsisLabeledButton } from "../../../components/EllipsisLabeledButton";
import { PanelItemContainer } from "../../../components/styledComponents";

export const RowOne: React.FC<unknown> = () => {
  return (
    <>
      <PanelRow>
        <PanelItemContainer>
          <CounterpartySelection controllerName="counterparty" required />
          <EllipsisLabeledButton ellipsisHandler={noop} />
        </PanelItemContainer>

        <PanelItemContainer>
          <ControlledSelect
            name="tradeContact"
            options={undefined}
            label="Contact"
          />
          <FormElement basis="max-content" style={{ flexGrow: 0 }}>
            <LabeledButton disabled icon={faEnvelope} />
          </FormElement>
        </PanelItemContainer>
      </PanelRow>

      <PanelRow>
        <PanelItemContainer>
          <InstructionsSelect name="instructions" />
          <EllipsisLabeledButton ellipsisHandler={noop} />
        </PanelItemContainer>

        <PanelItemContainer>
          <LabeledButton disabled label="Trade/Order">
            None
          </LabeledButton>
          <LabeledButton disabled>None</LabeledButton>
        </PanelItemContainer>
      </PanelRow>
    </>
  );
};
