import SecurityInfoPanel from "@app-views/oems/OrderTicketForm/panels/SecurityInfoPanel";
import { useWidget } from "@enfusion-ui/dashboards";
import * as React from "react";

const SecurityInfoWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const { fields, viewMode } = config;
  return <SecurityInfoPanel fields={fields} viewMode={viewMode} />;
};
export default SecurityInfoWidget;
