import "react-resizable/css/styles.css";
import { useEverTrue } from "@enfusion-ui/hooks";
import { styled } from "@enfusion-ui/web-core";
import { useWindowSize } from "@react-hook/window-size/throttled";
import * as React from "react";
import { ResizableBox, } from "react-resizable";
const ResizableContainer = styled(ResizableBox) `
  ${({ open }) => (open ? "" : "display: none;")};
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  & .react-resizable-handle-n {
    margin: 0;
    transform: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-image: none;
  }
`;
export const VerticallyResizable = ({ open, height = 350, minHeight = 10, minWidth = 10, width, children, handle, onResizeStop, dataE2EId, }) => {
    const opened = useEverTrue(open);
    const [windowWidth, windowHeight] = useWindowSize();
    return (React.createElement(ResizableContainer, { "data-e2e-id": dataE2EId, height: Math.max(height, minHeight), width: width || windowWidth, minConstraints: [minWidth, minHeight], maxConstraints: [width || windowWidth, windowHeight], open: open, axis: "y", resizeHandles: ["n"], handle: handle, onResizeStop: onResizeStop }, opened ? children : null));
};
