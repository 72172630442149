/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelectDataValue } from "@app-views/dashboards/pdf/utils";
import {
  AccountingMethodSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { AccountingMethodOptions, ChannelDataType } from "@enfusion-ui/types";
import * as React from "react";

import PdfSelectionWidget from "../PdfSelectionWidget";

const PdfAccountingMethodSelectionWidget: React.FC<unknown> = () => {
  const { config, addChannelKeys, setChannelData } = useWidget();
  const { defaultValue, key } = config as AccountingMethodSelectionWidgetConfig;
  const [inputValue, setInputValue] = React.useState<string | null>(null);

  useSelectDataValue(
    key,
    "Accounting Method",
    ChannelDataType.String,
    defaultValue
  );

  React.useEffect(() => {
    if (defaultValue) {
      const selectedValue = AccountingMethodOptions.find(
        ({ value: val }) => defaultValue === val
      );
      setInputValue(selectedValue?.label || null);
    }
  }, [defaultValue]);

  return <PdfSelectionWidget label={key} value={inputValue} />;
};

export default PdfAccountingMethodSelectionWidget;
