import {
  QuoteSetIdsSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import { QuoteSetIdsSelect } from "../../../../../reports/components/ReportQueryComponents/QuoteSetIdsSelect";

const QuoteSetIdsSelectionWidget = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();
  const { defaultValue, key } = config as QuoteSetIdsSelectionWidgetConfig;

  React.useEffect(() => {
    addChannelKeys([
      {
        name: key,
        description: "Quote Set IDs",
        type: ChannelDataType.Number,
      },
    ]);
  }, [addChannelKeys, key]);

  React.useEffect(() => {
    setChannelData([defaultValue]);
  }, [setChannelData, defaultValue]);

  return (
    <CenterContent>
      <QuoteSetIdsSelect
        label={key}
        value={defaultValue}
        onChange={(ids: number[] | null) => setChannelData([ids])}
      />
    </CenterContent>
  );
};

export default QuoteSetIdsSelectionWidget;
