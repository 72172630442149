import CurrencySelect from "@app-views/reports/reportSettings/generalLedgerAccounting/glDistributionLine/CurrencySelect";
import {
  discountCurveOptions,
  exerciseTypeOptions,
  settlementTypeOptions,
  transactionsSelectOptions,
} from "@enfusion-ui/trades";
import {
  ControlledDatePicker,
  ControlledNumericInput,
  ControlledSelect,
  PanelRow,
} from "@enfusion-ui/web-components";
import React from "react";

export const AssetSwapOptionTerms = () => {
  return (
    <>
      <PanelRow>
        <ControlledSelect
          options={transactionsSelectOptions}
          name="transactionType"
          label="Transaction"
          required
        />
        <ControlledNumericInput
          name="notionalAmount"
          label="Notional"
          required
        />
      </PanelRow>
      <PanelRow>
        <ControlledNumericInput
          name="recallSpread"
          label="Recall Spread"
          required
        />
        <ControlledNumericInput
          name="dealSpread"
          label="Deal Spread"
          required
        />
      </PanelRow>
      <PanelRow>
        <ControlledDatePicker name="expiry" label="Expiry" />
        <ControlledSelect
          name="exerciseType"
          label="ExerciseType"
          options={exerciseTypeOptions}
          required
        />
      </PanelRow>
      <PanelRow>
        <ControlledNumericInput
          name="pricePercentOfNotional"
          label="Price (% of Notional)"
          required
          formatStyle="percent"
        />
        <CurrencySelect
          name="notionalCurrency"
          label="Notional Currency"
          required
        />
      </PanelRow>
      <PanelRow>
        <ControlledNumericInput name="tradingFactor" label="Trading Factor" />
        <ControlledNumericInput name="pricingFactor" label="Pricing Factor" />
      </PanelRow>
      <PanelRow>
        <ControlledNumericInput
          name="tradeToBook"
          label="FX Rate (Trade to Book)"
        />
        <ControlledSelect
          name="discountCurve"
          label="Discount Curve"
          options={discountCurveOptions}
        />
        <ControlledSelect
          name="settlement"
          label="Settlement Type"
          options={settlementTypeOptions}
        />
      </PanelRow>
    </>
  );
};
