import { WidgetComponentsDefinition } from "../../../types";
import AccountSelectionEditWidget from "./AccountSelectionEditWidget";
import AccountSelectionWidget from "./AccountSelectionWidget";
import PdfAccountSelectionWidget from "./PdfAccountSelectionWidget";

export const accountSelection: WidgetComponentsDefinition = {
  renderComponent: AccountSelectionWidget,
  editComponent: AccountSelectionEditWidget,
  renderPdfComponent: PdfAccountSelectionWidget,
};
