import { useAnalyticsContext } from "@app-context/analytics/context";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  CenterContent,
  ContentMessage,
  Spinner,
  StyleableErrorBoundary,
} from "@enfusion-ui/web-components";
import {
  DimensionsProvider,
  styled,
  useIsContentUnderWidth,
  useThisTab,
} from "@enfusion-ui/web-core";
import { faExclamation } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { ResizeCallbackData } from "react-resizable";

import { AnalyticsContextProvider } from "../../context/analytics/AnalyticsContextProvider";
import { AnalyticsViewActionBar } from "./AnalyticsViewActionBar";
import { AnalyticsViewContent } from "./AnalyticsViewContent";
import { AnalyticsViewPivotsSection } from "./pivotsSection/AnalyticsViewPivotsSection";
import {
  ANALYTICS_ERROR_MESSAGE,
  ANALYTICS_PIVOTS_DEFAULT_WIDTH,
} from "./utils";

const ViewContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  gap: var(--spacing);
  overflow: hidden;
`;

const CenterErrorBoundary = styled(StyleableErrorBoundary)`
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const AnalyticsViewCore: React.FC = () => {
  const { width } = useThisTab();
  const { loading, error } = useAnalyticsContext();
  const showDrawer = useIsContentUnderWidth(600);
  const drawerWidth = React.useRef(ANALYTICS_PIVOTS_DEFAULT_WIDTH);

  const [contentWidth, setContentWidth] = React.useState<number | undefined>(
    () => {
      if (width) return width - (showDrawer ? 0 : drawerWidth.current);
    }
  );

  React.useEffect(() => {
    if (width) setContentWidth(width - (showDrawer ? 0 : drawerWidth.current));
  }, [width, showDrawer]);

  const onResizeCallBack = useRefCallback(
    (_e: React.SyntheticEvent, data: ResizeCallbackData) => {
      drawerWidth.current = data.size.width;
      if (width) setContentWidth(width - data.size.width);
    },
    [width]
  );

  return (
    <DimensionsProvider width={contentWidth}>
      <ViewContainer>
        <AnalyticsViewActionBar />

        <ContentContainer>
          {loading ? (
            <CenterContent fillWidth>
              <Spinner />
            </CenterContent>
          ) : error ? (
            <CenterContent fillWidth>
              <ContentMessage
                icon={faExclamation}
                message={ANALYTICS_ERROR_MESSAGE}
                details={error}
              />
            </CenterContent>
          ) : (
            <CenterErrorBoundary>
              <AnalyticsViewContent />
              <AnalyticsViewPivotsSection
                showDrawer={showDrawer}
                onResizeCallBack={onResizeCallBack}
              />
            </CenterErrorBoundary>
          )}
        </ContentContainer>
      </ViewContainer>
    </DimensionsProvider>
  );
};

export const AnalyticsView: React.FC = () => {
  return (
    <AnalyticsContextProvider>
      <AnalyticsViewCore />
    </AnalyticsContextProvider>
  );
};
