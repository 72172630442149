import { useAnalyticsContext } from "@app-context/analytics/context";
import { ChangeView } from "@app-views/analytics/components/ChangeView";
import { SizedChart } from "@app-views/analytics/components/SizedChart";
import { formatCurrency } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { defaultChartOptions, defaultTooltipOptions } from "../../chartConfigs";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

const SubContainer = styled(Container)`
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const TopRightContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

const categories = ["Long", "Net", "Short"];

const abs = (val?: number | null) => (val ? Math.abs(val) : null);

const ccy = (val?: number | null, ccyCode?: string | null) =>
  formatCurrency(val ?? 0, ccyCode ?? "USD", {
    fractionDigits: [0, 2, 1],
    reduce: true,
  });

const defaultOptions: Highcharts.Options = {
  chart: {
    ...defaultChartOptions,
    animation: false,
    type: "column",
    inverted: true,
    polar: true,
    spacing: [0, 0, 0, 0],
  },
  title: { text: "" },
  credits: { enabled: false },
  subtitle: {
    useHTML: true,
    floating: true,
    verticalAlign: "middle",
    y: 0,
  },
  tooltip: {
    ...defaultTooltipOptions,
    outside: true,
    followPointer: true,
    formatter: function () {
      const {
        color,
        series: { options, name },
      } = this;
      const value = options.custom?.data[name.toLocaleLowerCase()];
      const valueColor = `var(--${value >= 0 ? "success" : "danger"})`;
      const valueFormatted = ccy(value, options.custom?.currency);
      return `<span style="color:${color};">&#9210</span><b> ${name}: </b><span style="color: ${valueColor};">${valueFormatted}</span>`;
    },
  },
  legend: {
    margin: 0,
    padding: 0,
    useHTML: true,
    symbolWidth: 0,
    symbolHeight: 0,
    labelFormatter: function () {
      const { options, name, color, visible } = this;
      const value = options.custom?.data[name.toLocaleLowerCase()];
      const valueFormatted = ccy(value, options.custom?.currency);
      const valueColor = visible
        ? `var(--${value >= 0 ? "success" : "danger"})`
        : "var(--text-muted)";
      const symbolColor = visible ? color : "var(--text-muted)";
      const nameColor = visible ? "var(--text-normal)" : "var(--text-muted)";

      return `
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
        <div style="display: flex; align-items: center; gap: 4px;">
          <div style="background-color:${symbolColor}; border-radius: 50%; width: 11px; height: 11px;"></div>
          <div style="color:${nameColor};">${name}</div>
        </div>
        <div style="color:${valueColor}">${valueFormatted}</div>
      </div>`;
    },
  },
  pane: {
    size: "100%",
    innerSize: "40%",
    endAngle: 270,
  },
  xAxis: {
    tickInterval: 1,
    labels: {
      enabled: false,
      align: "right",
      useHTML: true,
      allowOverlap: true,
      step: 1,
      y: 5,
      style: {
        fontSize: "13px",
        color: "var(--text-normal)",
      },
    },
    lineWidth: 0,
    gridLineWidth: 0,
    categories,
  },
  yAxis: {
    lineWidth: 0,
    labels: { enabled: false },
    reversedStacks: false,
    endOnTick: true,
    showLastLabel: true,
    gridLineWidth: 0,
  },
  plotOptions: {
    column: {
      pointWidth: 16,
      borderWidth: 0,
      pointPadding: 0,
      groupPadding: 0,
      stacking: "normal",
      borderRadius: "50%",
    },
  },
};

export const ExposureSummary: React.FC = () => {
  const { data } = useAnalyticsContext();

  const exp = React.useMemo(
    () => data?.risk.exposure ?? null,
    [data?.risk.exposure]
  );

  const getOptions = React.useCallback(
    (width: number, height: number): Highcharts.Options => {
      const net = ccy(exp?.value, exp?.currency);
      return {
        ...defaultOptions,
        chart: {
          ...defaultOptions.chart,
          width: width || 10,
          height: height || 10,
        },
        subtitle: {
          ...defaultOptions.subtitle,
          text: `<div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                  <div style="font-size: 24px; font-weight: 600; color: var(--text-normal);">${net}</div>
                  <div style="font-size: 12px; color: var(--text-color-1); width: 110px; white-space: wrap; text-align: center;">
                    Total Fund Level Exposure
                  </div>
                </div>`,
        },
        plotOptions: {
          column: {
            ...defaultOptions.plotOptions?.column,
            custom: exp ?? undefined,
          },
        },
        series: [
          {
            type: "column",
            name: categories[0],
            data: [abs(exp?.data.long), null, null],
            pointPlacement: -0.1,
          },
          {
            type: "column",
            name: categories[1],
            data: [null, abs(exp?.data.net), null],
            pointPlacement: 0,
          },
          {
            type: "column",
            name: categories[2],
            data: [null, null, abs(exp?.data.short)],
            pointPlacement: 0.1,
          },
        ],
      };
    },
    [defaultOptions, exp]
  );

  return (
    <Container>
      <TopRow>
        <div>Exposure</div>
        <TopRightContent>
          <ChangeView change={exp?.change} />
        </TopRightContent>
      </TopRow>
      <SubContainer>
        <SizedChart getOptions={getOptions} />
      </SubContainer>
    </Container>
  );
};
