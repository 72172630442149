import ConnectionStatusProvider from "@app-context/connectionStatus/ConnectionStatusProvider";
import { FACTSET_LOADED_KEY } from "@enfusion-ui/core";
import { useUserAgent } from "@enfusion-ui/hooks/build/web";
import { mobilePostMessage } from "@enfusion-ui/utils";
import {
  CenterContent,
  MobileAppAuth,
  Spinner,
} from "@enfusion-ui/web-components";
import {
  ThemeProvider,
  useAuth,
  useIsMobileDevice,
} from "@enfusion-ui/web-core";
import queryString from "query-string";
import * as React from "react";
import { ToastContainer } from "react-toastify";

import { AppBus } from "./AppBus";
import { ConfluencePage } from "./ConfluencePage";
import WebApp from "./WebApp";

const AppEntryCore: React.FC<unknown> = React.memo(() => {
  const isMobile = useIsMobileDevice();
  const urlData = new URL(window.location.href);

  if (urlData.pathname.startsWith("/wiki")) {
    return <ConfluencePage />;
  }

  if (urlData.pathname.endsWith("/mobile-app-auth")) {
    return <MobileAppAuth />;
  }

  if (urlData.pathname.endsWith("/app-bus")) {
    return <AppBus />;
  }

  return (
    <ConnectionStatusProvider>
      <WebApp />
      <ToastContainer
        autoClose={5000}
        position={isMobile ? "bottom-right" : "top-right"}
        hideProgressBar
        pauseOnHover
        closeOnClick={false}
        draggable
      />
    </ConnectionStatusProvider>
  );
});

const AppCore = React.memo(function AppCore() {
  const { user, isEnabled } = useAuth();

  const agent = useUserAgent();

  if (isEnabled("FactSet") && typeof user?.flags?.FactSet.idpId === "string") {
    const FactSetLoaded = localStorage.getItem(FACTSET_LOADED_KEY);
    if (
      (agent.os.name === "ios" || agent.browser.name === "safari") &&
      process.env.REACT_APP_FACTSET_REDIRECT &&
      FactSetLoaded !== "1"
    ) {
      const { url, query } = queryString.parseUrl(
        process.env.REACT_APP_FACTSET_REDIRECT
      );
      mobilePostMessage({
        source: "mobile-app-auth",
        action: "factset-redirect",
        payload: { idpid: user?.flags?.FactSet.idpId },
      });
      window.location.href = queryString.stringifyUrl({
        url,
        query: { ...query, idpid: user?.flags?.FactSet.idpId },
      });
      return null;
    }
  }

  return <AppEntryCore />;
});

function App() {
  const { user } = useAuth();
  return (
    <ThemeProvider controlled>
      {!user ? (
        <CenterContent>
          <Spinner />
        </CenterContent>
      ) : (
        <AppCore />
      )}
    </ThemeProvider>
  );
}

export default React.memo(App);
