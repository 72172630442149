/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AccountSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { FormSectionAccordion, TextInput } from "@enfusion-ui/web-components";
import * as React from "react";

import AccountSelect from "../../../../../../components/inputs/account/AccountSelect";
import { reduceFundNodes } from "./AccountSelectionWidget";

const AccountSelectionEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const { defaultValues, key = "Accounts" } =
    editedConfig as AccountSelectionWidgetConfig;

  const selectedIds = React.useMemo(() => {
    const ids = reduceFundNodes(defaultValues ?? []);
    return [
      ...ids.accountIds.map((x) => `${x}`),
      ...ids.fundIds.map((x) => `${x}`),
    ];
  }, [JSON.stringify(defaultValues)]);

  const handleBasicChange = (key: string) => (value: any) => {
    changeConfigKeyToBeApplied(key, value);
  };

  return (
    <FormSectionAccordion title="Account Options">
      <TextInput
        label="Key"
        value={key}
        name="key"
        onChange={handleTextChange("key")}
        data-e2e-id="account-key"
      />
      <AccountSelect
        values={selectedIds}
        label="Default Accounts"
        onChange={handleBasicChange("defaultValues")}
        clearable
      />
    </FormSectionAccordion>
  );
};

export default AccountSelectionEditWidget;
