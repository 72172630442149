import { AccordionListView } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { TutorialType } from "../../components/Tour/utils";
import WatchListExplorerProvider from "../../context/watchList/WatchListExplorerProvider";
import CreateWatchListModal from "./components/CreateWatchListModal";
import WatchListExplorer from "./WatchListExplorer";

const WatchListSidebarContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 214px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const WatchListSidebarTitle = styled.div`
  height: 40px;
  padding: 0 0.25rem 0 0.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const defaultOpen = { user: true };

const createExplorer = (tutorial?: TutorialType | null) =>
  React.memo(function Explorer() {
    return <WatchListExplorer tutorial={tutorial} />;
  });

const getItems = (tutorial?: TutorialType | null) => [
  {
    key: "user",
    title: "All Watchlist",
    contentComponent: createExplorer(tutorial),
  },
];

const WatchListSidebar: React.FC<{ tutorial?: TutorialType | null }> = ({
  tutorial,
}) => {
  const items = React.useMemo(() => getItems(tutorial), [tutorial]);

  return (
    <WatchListSidebarContainer>
      <WatchListSidebarTitle data-e2e-id="watchlist-sidebar-title">
        Watchlist Explorer
      </WatchListSidebarTitle>

      <WatchListExplorerProvider>
        <AccordionListView defaultOpen={defaultOpen} items={items} />
        <CreateWatchListModal />
      </WatchListExplorerProvider>
    </WatchListSidebarContainer>
  );
};

export default WatchListSidebar;
