import {
  useReconciliation,
  useReconciliationBreakDetails,
} from "@app-context/reconciliation/context";
import {
  Column,
  RowSection,
  ValueContainer,
  ValueContent,
  ValueHeader,
} from "@app-views/reconciliation/components/styles";
import {
  getFilteredBreaksData,
  getReportId,
  isCashDataSource,
} from "@app-views/reconciliation/utils/commonUtils";
import { DEFAULT_DIFF_REPORT_DATA } from "@app-views/reconciliation/utils/constants";
import { formatCurrency } from "@enfusion-ui/utils";
import { Spinner } from "@enfusion-ui/web-components";
import React from "react";

import CashBalanceWidget from "./widgets/CashBalanceWidget";

export const BreakDetailsSummaryPanel: React.FC<{}> = () => {
  const { filterState, reportsLoadingStatus } = useReconciliation();
  const { breakDetailsRendererData: data } = useReconciliationBreakDetails();
  const { reportData, reconciliationType, breakImpact, id, filterColumns } =
    data;
  const { currency } = filterState;

  const loading = reportsLoadingStatus?.[getReportId(id, reconciliationType)];
  const filteredBreaksData = React.useMemo(() => {
    const reportDataRowStore = isCashDataSource(reconciliationType)
      ? reportData.cashActivityRowsStore ?? DEFAULT_DIFF_REPORT_DATA
      : reportData.rowsStore ?? DEFAULT_DIFF_REPORT_DATA;

    return getFilteredBreaksData(
      filterState,
      reportDataRowStore,
      filterColumns
    );
  }, [filterState, reportData]);

  return (
    <Column gap="l">
      <RowSection justifyContent="space-between">
        <ValueContainer>
          <ValueHeader>Missing {reconciliationType} Breaks</ValueHeader>
          <ValueContent>
            {loading ? (
              <Spinner size="1x"></Spinner>
            ) : (
              filteredBreaksData.sourceOnlyRows.length +
              filteredBreaksData.targetOnlyRows.length
            )}
          </ValueContent>
        </ValueContainer>
        <ValueContainer>
          <ValueHeader>{reconciliationType} Breaks</ValueHeader>
          <ValueContent>
            {loading ? (
              <Spinner size="1x"></Spinner>
            ) : (
              filteredBreaksData.differenceRows.length
            )}
          </ValueContent>
        </ValueContainer>
        <ValueContainer>
          <ValueHeader>Total Break Value</ValueHeader>
          <ValueContent>
            {!isNaN(breakImpact) && currency
              ? formatCurrency(Math.abs(breakImpact), currency.value, {
                  fractionDigits: 2,
                  reduce: false,
                })
              : "-"}
          </ValueContent>
        </ValueContainer>
        {isCashDataSource(reconciliationType) && (
          <CashBalanceWidget id={id} data={data} />
        )}
      </RowSection>
    </Column>
  );
};
