import { getCanCreateOrders } from "@enfusion-ui/core";
import { User } from "@enfusion-ui/types";
import { Spinner } from "@enfusion-ui/web-components";
import { StepType } from "@reactour/tour";
import * as React from "react";

import {
  actionCallback,
  TourConfig,
  TourContent,
  triggerClick,
  TutorialType,
} from "./utils";

const scrollToTarget = (targetSelector: string) => (_node: HTMLElement) => {
  const el = document.querySelector(targetSelector) as HTMLElement;
  el?.scrollIntoView();
};

export type TourStepMeta = {
  name?: string;
  loading?: boolean;
  highlightColumn?: string;
  openDrawer?: boolean;
  closeDrawer?: boolean;
  complete?: boolean;
};

export type TourMeta = {
  disableButton?: boolean;
};

export type TourStepType = StepType & TourStepMeta;

export const getTourConfig = (
  tutorialType: TutorialType,
  openMarketingTab: VoidFunction,
  user: User | null
): TourConfig | undefined => {
  const canCreateOrders = getCanCreateOrders(user);
  switch (tutorialType) {
    case TutorialType.ServicesMarketing:
      return document.querySelector(
        '[data-tutorial-step="ServicesMarketing__file"]'
      )
        ? {
            stepsRequiringTargetClick: [2],
            welcomeModalContent: {
              title: "Managed Services Tutorial",
              body: "Welcome to a tutorial of the Enfusion Managed Services Web Portal.",
            },
            steps: [
              {
                selector:
                  '[data-tutorial-step="ServicesMarketing__services-tab"]',
                content: (
                  <TourContent header="Managed Services Web Portal Tutorial">
                    <p>
                      Welcome to a tutorial of the Enfusion Managed Services Web
                      Portal. Enfusion offers a variety of outsourced middle and
                      back-office services and our web portal provides easy and
                      secure two-way communication with our expert teams as well
                      as on-demand access to your data and reports.
                    </p>

                    <p>Click the arrow to see more capabilities.</p>
                  </TourContent>
                ),
                stepInteraction: false,
              },
              {
                selector:
                  '[data-tutorial-step="ServicesMarketing__services-sidebar"]',
                content: (
                  <TourContent header="Managed Services Web Portal Tutorial">
                    <p>
                      At left, FILES is where you will find new, as well as
                      historical, daily reconciliations and reports. All are
                      uploaded by our Managed Services investment professionals
                      to put transparency at your fingertips.
                    </p>
                  </TourContent>
                ),
                action: scrollToTarget(
                  '[data-tutorial-step="ServicesMarketing__file"]'
                ),
                stepInteraction: false,
              },
              {
                selector: '[data-tutorial-step="ServicesMarketing__file"]',
                content: (
                  <TourContent header="Managed Services Web Portal Tutorial">
                    <p>
                      At left, click on a sample report to view your
                      reconciliations and reporting in a new tab side by side
                      with live portfolio data.
                    </p>
                  </TourContent>
                ),
              },
              {
                selector: '[data-tutorial-step="ServicesMarketing__tabs"]',
                content: (
                  <TourContent header="Managed Services Web Portal Tutorial">
                    <p>
                      Our web portal provides access to live and dynamic file
                      previews on the web. View quick summaries of items that
                      require your attention as well as full, detailed
                      breakdowns of your portfolio and the operational workflows
                      performed by our team.
                    </p>
                  </TourContent>
                ),
                position: "left",
              },
              {
                selector: '[data-tutorial-step="ServicesMarketing__tabs"]',
                content: (
                  <TourContent header="Managed Services Web Portal Tutorial">
                    <p>
                      To learn more about the range of middle and back-office
                      services available, scroll down on the page to the right.
                      Your Account Manager is always available to provide full
                      details.
                    </p>
                  </TourContent>
                ),
                action: () => {
                  openMarketingTab();
                },
                position: "left",
              },
            ],
          }
        : {
            welcomeModalContent: {
              title: "Managed Services Tutorial",
              body: "Welcome to a tutorial of the Enfusion Managed Services Web Portal.",
            },
            steps: [
              {
                selector:
                  '[data-tutorial-step="ServicesMarketing__services-tab"]',
                content: (
                  <TourContent header="Managed Services Web Portal Tutorial">
                    <p>
                      Welcome to a tutorial of the Enfusion Managed Services Web
                      Portal. Enfusion offers a variety of outsourced middle and
                      back-office services and our web portal provides easy and
                      secure two-way communication with our expert teams as well
                      as on-demand access to your data and reports.
                    </p>

                    <p>Click the arrow to see more capabilities.</p>
                  </TourContent>
                ),
                stepInteraction: false,
              },
              {
                selector:
                  '[data-tutorial-step="ServicesMarketing__services-sidebar"]',
                content: (
                  <TourContent header="Services Tab Tutorial">
                    <p>
                      At left, MY FILES is where you will find new, as well as
                      historical, daily reconciliations and reports. All are
                      uploaded by our Managed Services investment professionals
                      to put transparency at your fingertips.
                    </p>
                  </TourContent>
                ),
                stepInteraction: false,
              },
              {
                selector: '[data-tutorial-step="ServicesMarketing__tabs"]',
                content: (
                  <TourContent header="Managed Services Web Portal Tutorial">
                    <p>
                      On the right, a file preview will open in a new tab. Our
                      web portal provides access to live and dynamic file
                      previews on the web. View quick summaries of items that
                      require your attention as well as full, detailed
                      breakdowns of your portfolio and the operational workflows
                      performed by our team.
                    </p>
                  </TourContent>
                ),
                position: "left",
              },
              {
                selector: '[data-tutorial-step="ServicesMarketing__tabs"]',
                content: (
                  <TourContent header="Managed Services Web Portal Tutorial">
                    <p>
                      To learn more about the range of middle and back-office
                      services available, scroll down on the page to the right.
                      Your Account Manager is always available to provide full
                      details.
                    </p>
                  </TourContent>
                ),
                action: () => {
                  openMarketingTab();
                },
                position: "left",
              },
            ],
          };
    case TutorialType.ServicesClient:
      return document.querySelector(
        '[data-tutorial-step="ServicesClient__file"]'
      )
        ? {
            stepsRequiringTargetClick: [2],
            welcomeModalContent: {
              title: "Managed Services Tutorial",
              body: "Welcome to a tutorial of the Enfusion Managed Services Web Portal.",
            },
            steps: [
              {
                selector: '[data-tutorial-step="ServicesClient__services-tab"]',
                content: (
                  <TourContent header="Managed Services Web Portal Tutorial">
                    <p>
                      Welcome to a tutorial of the Enfusion Managed Services Web
                      Portal. Enfusion offers a variety of outsourced middle and
                      back-office services and our web portal provides easy and
                      secure two-way communication with our expert teams as well
                      as on-demand access to your data and reports.
                    </p>

                    <p>Click the arrow to see more capabilities.</p>
                  </TourContent>
                ),
                stepInteraction: false,
              },
              {
                selector:
                  '[data-tutorial-step="ServicesClient__services-sidebar"]',
                content: (
                  <TourContent header="Managed Services Web Portal Tutorial">
                    <p>
                      At left, FILES is where you will find new, as well as
                      historical, daily reconciliations and reports. All are
                      uploaded by our Managed Services investment professionals
                      to put transparency at your fingertips.
                    </p>
                  </TourContent>
                ),
                action: scrollToTarget(
                  '[data-tutorial-step="ServicesClient__file"]'
                ),
                stepInteraction: false,
              },
              {
                selector: '[data-tutorial-step="ServicesClient__file"]',
                content: (
                  <TourContent header="Managed Services Web Portal Tutorial">
                    <p>
                      At left, click on a sample report to view your
                      reconciliations and reporting in a new tab side by side
                      with live portfolio data.
                    </p>
                  </TourContent>
                ),
              },
              {
                selector: '[data-tutorial-step="ServicesClient__tabs"]',
                content: (
                  <TourContent header="Managed Services Web Portal Tutorial">
                    <p>
                      Our web portal provides access to live and dynamic file
                      previews on the web. View quick summaries of items that
                      require your attention as well as full, detailed
                      breakdowns of your portfolio and the operational workflows
                      performed by our team.
                    </p>
                  </TourContent>
                ),
                position: "left",
              },
            ],
          }
        : {
            welcomeModalContent: {
              title: "Managed Service Tutorial",
              body: "Welcome to a tutorial of the Enfusion Managed Services Web Portal.",
            },
            steps: [
              {
                selector: '[data-tutorial-step="ServicesClient__services-tab"]',
                content: (
                  <TourContent header="Managed Services Web Portal Tutorial">
                    <p>
                      Welcome to a tutorial of the Enfusion Managed Services Web
                      Portal. Enfusion offers a variety of outsourced middle and
                      back-office services and our web portal provides easy and
                      secure two-way communication with our expert teams as well
                      as on-demand access to your data and reports.
                    </p>

                    <p>Click the arrow to see more capabilities.</p>
                  </TourContent>
                ),
                stepInteraction: false,
              },
              {
                selector:
                  '[data-tutorial-step="ServicesClient__services-sidebar"]',
                content: (
                  <TourContent header="Services Tab Tutorial">
                    <p>
                      At left, FILES is where you will find new, as well as
                      historical, daily reconciliations and reports. All are
                      uploaded by our Managed Services investment professionals
                      to put transparency at your fingertips.
                    </p>
                  </TourContent>
                ),
                stepInteraction: false,
              },
              {
                selector: '[data-tutorial-step="ServicesClient__tabs"]',
                content: (
                  <TourContent header="Managed Services Web Portal Tutorial">
                    <p>
                      On the right, a file preview will open in a new tab. Our
                      web portal provides access to live and dynamic file
                      previews on the web. View quick summaries of items that
                      require your attention as well as full, detailed
                      breakdowns of your portfolio and the operational workflows
                      performed by our team.
                    </p>
                  </TourContent>
                ),
                position: "left",
              },
            ],
          };
    case TutorialType.Reports:
      // TODO: check if report list loaded before checking for the selector
      // return document.querySelector(`[data-tutorial-step="Reports__file"]`)
      //   ? {
      //TODO: replace PLACEHOLDER TEXT
      return {
        stepsRequiringTargetClick: [2],
        welcomeModalContent: {
          title: "Reports Tutorial",
          body: "Welcome to a tutorial of the Enfusion Reports Web Portal.",
        },
        steps: [
          {
            selector: '[data-tutorial-step="Reports__reports-tab"]',
            content: (
              <TourContent header="Reports Tab Tutorial">
                PLACEHOLDER TEXT: Welcome to the tutorial
              </TourContent>
            ),
            stepInteraction: false,
          },
          {
            selector: '[data-tutorial-step="Reports__reports-sidebar"]',
            content: (
              <TourContent header="Reports Tab Tutorial">
                PLACEHOLDER TEXT: List of reports here
              </TourContent>
            ),
            action: scrollToTarget('[data-tutorial-step="Reports__file"]'),
            stepInteraction: false,
          },
          {
            selector: '[data-tutorial-step="Reports__file"]',
            content: (
              <TourContent header="Reports Tab Tutorial">
                PLACEHOLDER TEXT: Click a report to open in a new tab
              </TourContent>
            ),
          },
          {
            selector: '[data-tutorial-step="Reports__tabs"]',
            content: (
              <TourContent header="Reports Tab Tutorial">
                PLACEHOLDER TEXT: Opened report!
              </TourContent>
            ),
            position: "left",
          },
        ],
      };
    case TutorialType.Portfolio: {
      return {
        stepsRequiringTargetClick: [],
        welcomeModalContent: {
          title:
            "The Portfolio Workbench, portfolio construction made flexible",
          body: "Let's set up your workbench and learn some key features.",
        },
        steps: [
          {
            selector:
              '[data-e2e-id="accordion-title-my-workbenches"] [data-e2e-id="menu-icon-my-workbenches"]',
            highlightedSelectors: [
              '[data-e2e-id="accordion-title-my-workbenches"] [data-e2e-id="menu-icon-my-workbenches"]',
              '[data-e2e-id="menu-item-add-workbench"]',
              '[data-e2e-id="menu-item-add-folder"]',
            ],
            mutationObservables: ["div.not-moveable"],
            resizeObservables: ["div.not-moveable"],
            name: "create-workbench",
            action: () => {
              actionCallback(
                '[data-e2e-id="accordion-title-my-workbenches"] button[title="Menu"]'
              );
            },
            content: (
              <TourContent header="Create a workbench">
                We'll click <strong>Add workbench</strong> to add a new
                workbench.
              </TourContent>
            ),
          },
          {
            selector: '[data-e2e-id="CreateWorkbenchModal"]',
            mutationObservables: ["div.not-moveable"],
            resizeObservables: [
              "div.not-moveable",
              '[data-e2e-id="CreateWorkbenchModal"]',
            ],
            action: () => {
              actionCallback('[data-e2e-id="menu-item-add-workbench"]');
            },
            content: (
              <TourContent header="Portfolio setup">
                This is the workbench creation form. Now let's create your new
                workbench.
              </TourContent>
            ),
          },
          {
            selector: '[data-e2e-id="text-input-name"]',
            highlightedSelectors: [
              '[data-e2e-id="CreateWorkbenchModal"] label[for="name"]',
              '[data-e2e-id="text-input-name"]',
            ],

            stepInteraction: true,
            name: "name-new-workbench",
            content: (
              <TourContent header="Name Workbench">
                Give your workbench a name
              </TourContent>
            ),
          },
          {
            selector: '[data-e2e-id="select-input-funds"]',
            highlightedSelectors: [
              '[data-e2e-id="select-input-funds"]',
              '[data-e2e-id="account-select-portal"]',
              '[data-e2e-id="CreateWorkbenchModal"] label[for="accountSelect"]',
            ],
            mutationObservables: ["div.not-moveable"],
            resizeObservables: [
              "div.not-moveable",
              '[data-e2e-id="account-select-portal"]',
            ],
            stepInteraction: true,
            name: "select-fund",
            actionAfter: () => {
              const focusEl = document.querySelector(
                '[data-e2e-id="select-input-funds-hidden-trigger"]'
              ) as HTMLInputElement;

              focusEl?.focus();
              triggerClick('[data-testid="save-form-submit-btn"]');
            },
            content: (
              <TourContent header="Select Fund">
                Select fund(s) for your workbench
              </TourContent>
            ),
          },
          {
            selector: '[data-e2e-id="portfolio-grid-loader"]',
            mutationObservables: ['[data-e2e-id="portfolio-grid-loader"]'],
            resizeObservables: ['[data-e2e-id="portfolio-grid-loader"]'],
            position: "center",
            loading: true,
            name: "portfolio-loading",

            content: (
              <TourContent header="Portfolio loading">
                <Spinner size="lg" dataTestId="spinner" />
              </TourContent>
            ),
          },
          {
            selector: "#main-portfolio-grid",
            mutationObservables: ["#main-portfolio-grid"],
            resizeObservables: ["#main-portfolio-grid"],
            position: "center",
            disableDotsNavigation: false,

            content: (
              <TourContent header="Portfolio Editing">
                Drag this section to the left to see all the columns you can use
                for calculation by typing directly into the grid.
              </TourContent>
            ),
          },
          {
            selector: '[col-id="currentExposurePercentNAV"]',
            mutationObservables: [
              '[col-id="currentExposurePercentNAV"][role="columnheader"]',
            ],
            resizeObservables: [
              '[col-id="currentExposurePercentNAV"][role="columnheader"]',
            ],
            highlightedSelectors: ['[col-id="currentExposurePercentNAV"]'],
            position: "left",
            highlightColumn: "currentExposurePercentNAV",
            content: (
              <TourContent header="Current Exposure">
                View current columns as a snapshot of this portfolio's funds'
                values.
              </TourContent>
            ),
          },
          {
            selector: '[col-id="finalExposure"][role="columnheader"]',
            mutationObservables: [
              '[col-id="finalExposure"][role="columnheader"]',
            ],
            resizeObservables: [
              '[col-id="finalExposure"][role="columnheader"]',
            ],
            highlightedSelectors: [
              '[col-id="finalExposure"][role="columnheader"]',
              '[col-id="finalExposure"][role="gridcell"]',
            ],
            position: "left",

            highlightColumn: "finalExposure",
            content: (
              <TourContent header="Final Exposure">
                Edit final columns, which display the exposure of your edits and
                become your new target weights.
              </TourContent>
            ),
          },
          {
            selector: '[col-id="incrementalExposure"][role="columnheader"]',
            mutationObservables: [
              '[col-id="incrementalExposure"][role="columnheader"]',
            ],
            resizeObservables: [
              '[col-id="incrementalExposure"][role="columnheader"]',
            ],
            highlightedSelectors: [
              '[col-id="incrementalExposure"][role="columnheader"]',
            ],
            highlightColumn: "incrementalNAV",
            position: "bottom",
            content: (
              <TourContent header="Incremental Exposure">
                Edit your incremental columns to provide a difference between
                current and final values.
              </TourContent>
            ),
          },
          {
            selector: '[data-e2e-id="portfolio-workbench-details"]',
            mutationObservables: [
              '[data-e2e-id="portfolio-workbench-details"]',
            ],
            resizeObservables: ['[data-e2e-id="portfolio-workbench-details"]'],
            openDrawer: true,
            content: (
              <TourContent header="Proposed Orders Drawer">
                Lift this drawer to see your proposed orders and allocations,
                which will display when edits are made to the portfolio.
              </TourContent>
            ),
          },
          {
            selector: '[data-e2e-id="tab-proposed-orders"]',
            content: (
              <TourContent header="Proposed Orders">
                See proposed orders, and define your parameters so they are
                executed accordingly.
              </TourContent>
            ),
          },
          {
            selector: '[data-e2e-id="tab-proposed-allocations"]',
            content: (
              <TourContent header="Proposed Allocations">
                Address compliance violations by downsizing or redistributing
                allocations.
              </TourContent>
            ),
          },
          canCreateOrders
            ? {
                selector: '[data-e2d-id="test-compliance-button"]',
                stepInteraction: true,
                content: (
                  <TourContent header="Test Compliance">
                    Click to run compliance against your proposed orders.
                    Adjustments made to will invalidate a compliance run.
                  </TourContent>
                ),
              }
            : null,
          canCreateOrders
            ? {
                selector: '[data-e2d-id="create-orders-button"]',
                stepInteraction: true,
                content: (
                  <TourContent header="Create Orders">
                    Click to send your order instructions to the OEMS.
                  </TourContent>
                ),
              }
            : null,
          {
            selector:
              '[col-id="currentExposurePercentNAV"][role="columnheader"]',
            highlightedSelectors: [
              '[col-id="currentExposurePercentNAV"][role="columnheader"]',
            ],
            content: (
              <TourContent header="Sort Column">
                Left-click a column to trigger sort; this will cycle through
                ascending, descending, and none.
              </TourContent>
            ),
            closeDrawer: true,
          },
          {
            selector: "div.ag-theme-balham-dark.ag-popup > div",
            highlightedSelectors: ["div.ag-theme-balham-dark.ag-popup > div"],
            content: (
              <TourContent header="Filter Column">
                Use specific values to apply filters to any column. Left-click
                column menu to access.
              </TourContent>
            ),
            action: () => {
              triggerClick(
                '[col-id="currentExposurePercentNAV"][role="columnheader"] .ag-header-cell-menu-button'
              );
              triggerClick('span[aria-label="filter"]');
            },
            actionAfter: () => {
              triggerClick(
                '[col-id="currentExposurePercentNAV"][role="columnheader"] .ag-header-cell-menu-button'
              );
              triggerClick('span[aria-label="general"]');
            },
          },
          {
            selector: ".ag-menu-list .ag-menu-option:nth-child(12)",
            highlightedSelectors: [
              ".ag-menu-list .ag-menu-option:nth-child(12)",
            ],

            mutationObservables: [".ag-tabs-body.ag-menu-body"],
            complete: true,
            content: (
              <TourContent header="Customize Columns">
                Display, hide, and reorder columns. Left-click column menu or
                right-click any row to access.
              </TourContent>
            ),
          },
        ].filter((step) => step !== null) as StepType[],
      };
    }
  }
};
