import { CashAccountingMethodOptions } from "@enfusion-ui/types";
import { ControlledSelect } from "@enfusion-ui/web-components";

import { DateSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledLedgerSelect } from "../../../components/ReportQueryComponents/LedgerSelect";

const GLCashBalancesQuickFilter = () => {
  return (
    <>
      <ControlledLedgerSelect name="generalLedgerIds" label="General Ledger" />
      <DateSelection name="valueDate" label="Value Date" />{" "}
      <ControlledSelect
        name="accountingMethod"
        label="Cash Mode"
        options={CashAccountingMethodOptions}
      />
    </>
  );
};

export default GLCashBalancesQuickFilter;
