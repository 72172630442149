import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

export type InstrumentCardProps = {
  description: string;
  subType: string;
  exchange: string;
  ticker: string;
};

const InstrumentCardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1em 1em;
  grid-row-gap: 4px;
  justify-content: space-between;
`;

const InstrumentCardDescription = styled.div`
  grid-row: 1/2;
  grid-column: 1/2;
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-self: center;
`;

const InstrumentCardSecondaryInfo = styled.div`
  grid-row: 2/3;
  grid-column: 1/2;
  font-size: 0.875em;
  color: var(--text-color-1);
  align-self: center;
`;

const InstrumentCardTicker = styled.div`
  grid-row: 1/3;
  grid-column: 2/3;
  font-size: 1.6em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InstrumentCard: React.FC<InstrumentCardProps> = ({
  description,
  subType,
  exchange,
  ticker,
}) => {
  let secondaryInfo = subType;

  if (exchange) secondaryInfo += ` \u00B7 ${exchange}`;

  return (
    <InstrumentCardContainer data-e2e-id="instrument-item">
      <InstrumentCardDescription title={description}>
        {description}
      </InstrumentCardDescription>
      <InstrumentCardSecondaryInfo>{secondaryInfo}</InstrumentCardSecondaryInfo>
      <InstrumentCardTicker>{ticker}</InstrumentCardTicker>
    </InstrumentCardContainer>
  );
};

export default InstrumentCard;
