import { useRefCallback } from "@enfusion-ui/hooks";
import {
  CenterContent,
  ContentMessage,
  TabTopActionBar,
  ViewContainer,
  ViewContent,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { faSkullCrossbones } from "@fortawesome/pro-solid-svg-icons";
import * as contentful from "contentful";
import * as React from "react";
import Markdown from "react-markdown";
import { useMount } from "react-use";
import remarkGfm from "remark-gfm";

export type ReleaseNotesViewProps = {
  config?: {};
};

const ContentWrapper = styled(ViewContent)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100%;
  max-height: 100%;
  border-top-width: 0;
  padding: var(--spacing-xxl);
  padding-top: var(--spacing);
  overflow: hidden auto;

  p {
    max-width: 575px;
  }

  img {
    width: 100%;
  }

  hr {
    width: 100%;
    color: var(--text-muted);
    border-style: solid;
    border-width: 1px;
    border-top-width: 0px;
  }

  h1 {
    margin-bottom: var(--spacing-l);
    font-weight: bold;
  }

  h2 {
    font-weight: bold;
  }
`;

const ReleaseNotesView: React.FC<ReleaseNotesViewProps> = () => {
  const [content, setContent] = React.useState("No Release Notes Available");
  const [error, setError] = React.useState<string | null>(null);

  const getContent = useRefCallback(async () => {
    const client = contentful.createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE!,
      accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN!,
    });
    try {
      const res = await client.getEntries({
        limit: 5,
        "metadata.tags.sys.id[all]": [
          "webApp",
          `web-v${process.env
            .REACT_APP_VERSION!.split(".")
            .slice(0, 2)
            .join(".")}`,
        ],
      });
      if (res.items.length > 0) {
        setContent(res.items.map((item) => item.fields.body).join("\n\n"));
      }
    } catch (err) {
      setError((err as Error).message);
    }
  }, []);

  useMount(() => {
    if (
      !process.env.REACT_APP_CONTENTFUL_SPACE ||
      !process.env.REACT_APP_CONTENTFUL_TOKEN
    ) {
      setError("Missing configuration to pull content");
    } else {
      getContent();
    }
  });

  if (error !== null) {
    return (
      <CenterContent>
        <ContentMessage
          icon={faSkullCrossbones}
          message={error}
          containerStyle={{
            maxWidth: "75%",
          }}
        />
      </CenterContent>
    );
  }

  return (
    <ViewContainer>
      <TabTopActionBar bordered={false} />
      <ContentWrapper>
        <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
      </ContentWrapper>
    </ViewContainer>
  );
};

export default React.memo(ReleaseNotesView);
