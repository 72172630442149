import { ReportSettingsDefinition } from "../../types";
import PositionEventQuickFilter from "./PositionEventQuickFilter";
import PositionEventSettings from "./PositionEventSettings";

export const WebPositionEventReportQuery: ReportSettingsDefinition = {
  name: "PositionEvent",
  queryType: "WebPositionEventReportQuery",
  quickFilterComponent: PositionEventQuickFilter,
  settingsComponent: PositionEventSettings,
};
