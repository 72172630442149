import { useInstrument, useOEMSOrderForm } from "@enfusion-ui/core";
import { QuantityNotionalType } from "@enfusion-ui/dashboards";
import {
  Checkbox,
  ControlledCheckbox,
  FormController,
  FormElement,
  NumericInputAsString,
  PanelRow,
} from "@enfusion-ui/web-components";
import { useAuth } from "@enfusion-ui/web-core";
import * as React from "react";

import QuickOrderEntry from "../../../../components/QuickOrderEntry/QuickOrderEntry";
import { DarkPoolSelect } from "../components/inputs/DarkPoolSelect";
import { BorrowBrokerPanel } from "../panels/BorrowBroker/BorrowBrokerPanel";
import CalculatedSidePanel from "../panels/CalculatedSidePanel";
import QuantityNotionalPanel from "../panels/QuantityNotionalPanel";

export const OrderDetailsPanel: React.FC<
  QuantityNotionalType & {
    disabled?: boolean;
  }
> = ({ disabled, input, showCalculationInfoText }) => {
  const {
    splitOrder,
    isFutureSpreadOrder,
    minQuantity = 0,
    completed,
  } = useOEMSOrderForm();
  const { isUserType } = useAuth();

  const instrument = useInstrument();

  const isSwapOrderTouched = React.useRef<boolean | undefined>();

  const optionsOrder = instrument?.option ?? false;

  if (isFutureSpreadOrder) {
    return (
      <PanelRow
        style={{
          gridColumn: "1 / span 2",
        }}
      >
        <FormElement desktopBasis="45%" tabletBasis="45%">
          <CalculatedSidePanel
            disabled={splitOrder || disabled}
            showLimitedOptions={isFutureSpreadOrder}
            containerStyle={{ width: "100%", marginTop: "var(--spacing)" }}
          />
        </FormElement>

        <FormController
          name="quantity"
          desktopBasis="25%"
          tabletBasis="25%"
          render={({ value, onChange }) => (
            <NumericInputAsString
              id="quantity-id"
              key="quantity"
              label="Quantity"
              value={value}
              onChange={onChange}
              disabled={completed}
              step={instrument?.lotSize || 1}
              forceStep={false}
              enableMultiplier
              min={minQuantity}
            />
          )}
        />

        <FormElement desktopBasis="15%" tabletBasis="15%">
          <ControlledCheckbox
            name="swapOrder"
            label="Route To Swap Desk"
            labelPlacement="right"
            style={{ marginTop: "var(--spacing-xxl)", alignSelf: "end" }}
            disabled={completed}
            onChange={() => (isSwapOrderTouched.current = true)}
          />
        </FormElement>
      </PanelRow>
    );
  }

  return (
    <>
      <div>
        <CalculatedSidePanel
          disabled={splitOrder || disabled}
          showLimitedOptions={isFutureSpreadOrder}
        />
        <QuantityNotionalPanel
          disabled={completed}
          input={input}
          showCalculationInfoText={showCalculationInfoText}
        />
        <PanelRow>
          <FormElement mobileBasis="50%">
            <ControlledCheckbox
              name="swapOrder"
              label="Route To Swap Desk"
              labelPlacement="right"
              style={{ marginTop: "var(--spacing)" }}
              disabled={completed}
              onChange={() => (isSwapOrderTouched.current = true)}
            />
          </FormElement>

          <FormController
            name="traderDiscretion"
            mobileBasis="50%"
            render={({ value, ref: _ref, ...rest }) => (
              <Checkbox
                checked={value}
                label="Trader Discretion"
                {...rest}
                labelPlacement="right"
                style={{ marginTop: "var(--spacing)" }}
                disabled={completed || !optionsOrder || splitOrder}
              />
            )}
          />
        </PanelRow>
        <PanelRow>
          {!isUserType("Express") && (
            <DarkPoolSelect disabled={completed || splitOrder} />
          )}
        </PanelRow>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <QuickOrderEntry disabled={disabled} label="Expression" />
        <BorrowBrokerPanel disabled={completed} />
      </div>
    </>
  );
};
