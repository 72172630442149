import {
  getOrderFormType,
  TOAST_CONTENT,
  UNKNOWN_INSTRUMENT_ID,
  UNKNOWN_INSTRUMENT_INFO,
} from "@enfusion-ui/core";
import {
  CDXVoiceOrderFillRequest,
  FinancialSubType,
  FinancialType,
  FXVoiceOrderFillRequest,
  IRSVoiceOrderFillRequest,
  ListedVoiceOrderFillsRequest,
  OrderInfo,
  OrderSaveSuccess,
} from "@enfusion-ui/types";
import { errorToast, REST_API, successToast } from "@enfusion-ui/web-core";
import { format } from "date-fns";

import { VoiceOrderFormValues } from "../components/blotters/VoiceOrderFillModal";

export type SelectedRowInfo = {
  orderId: number;
  instrumentType?: FinancialType;
  counterparty?: string | null;
  unfilledQuantity?: number | null;
  financialSubtype?: FinancialSubType;
};

export const performTransmit = async (
  orderId: number,
  transmitWithWarnings?: boolean
) => {
  try {
    const response: OrderSaveSuccess = await REST_API.OEMS.TRANSMIT_ORDER.FETCH(
      orderId,
      transmitWithWarnings
    );

    if (response.errorMessage) {
      throw new Error(response.errorMessage);
    }

    return response;
  } catch (err) {
    console.error("Error while transmitting the order", err);
    throw err;
  }
};

export const cancelOrder = async (orderId: number) => {
  try {
    const response: OrderSaveSuccess = await REST_API.OEMS.CANCEL_ORDER.FETCH(
      orderId
    );

    if (response.message) {
      throw new Error(response.message);
    }

    if (response.errorMessage) {
      throw new Error(response.errorMessage);
    }

    return response;
  } catch (err) {
    console.error("Error while cancelling the order", err);
    throw err;
  }
};

export const applyAllocationForAllocationScheme = async (
  allocationSchemeId: number,
  order: OrderInfo
) => {
  try {
    return await REST_API.OEMS.GET_ALLOCATION_SCHEME.FETCH(
      allocationSchemeId,
      order
    );
  } catch (err) {
    console.error("Error while applying allocation scheme: ", err);
    throw err;
  }
};

export const applySwapCheck = async (order: OrderInfo) => {
  try {
    return await REST_API.OEMS.APPLY_SWAP_CHECK.FETCH(order);
  } catch (err) {
    console.error("Error while applying swap check: ", err);
    throw err;
  }
};

export const fetchInstrument = async (instrumentId: number | null) => {
  if (instrumentId === null) return null;

  if (instrumentId === UNKNOWN_INSTRUMENT_ID) return UNKNOWN_INSTRUMENT_INFO;

  try {
    return await REST_API.INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(instrumentId);
  } catch (err) {
    console.error("instrument info load error", err);
  }

  return null;
};

export const submitFills = async (
  data: VoiceOrderFormValues,
  selectedRowInfo: SelectedRowInfo,
  acceptWarnings: boolean
) => {
  try {
    const {
      tradeDate: date,
      quantity,
      price,
      counterpartyShortName,
      farLegLastPx,
      farLegLastQty,
      notional,
      tradeSpread,
      upfrontFee,
      legs,
      upfrontFees,
      fxRateLeg1ToLeg2,
    } = data;

    const bodyBase:
      | FXVoiceOrderFillRequest
      | CDXVoiceOrderFillRequest
      | IRSVoiceOrderFillRequest
      | ListedVoiceOrderFillsRequest = {
      orderId: selectedRowInfo.orderId,
      tradeDate: date ? format(date, "yyyy-MM-dd") : null,
      counterpartyCode: counterpartyShortName,
      lastQty: quantity,
      lastPx: price,
      transactionType: "New",
    };

    const type = getOrderFormType(selectedRowInfo.financialSubtype, {
      varSwap: !process.env.REACT_APP_ENABLE_VAR_SWAP_ORDERS,
      irs: !process.env.REACT_APP_ENABLE_IRS_ORDERS,
    });
    const body =
      type === "fx"
        ? {
            ...bodyBase,
            farLegLastPx,
            farLegLastQty,
          }
        : type === "cdx"
        ? {
            ...bodyBase,
            notional,
            tradeSpread,
            upfrontFee,
          }
        : type === "irs"
        ? {
            ...bodyBase,
            notional,
            upfrontFees,
            fxRateLeg1ToLeg2,
          }
        : type === "futureSpread"
        ? {
            ...bodyBase,
            legs:
              legs?.map((leg) => ({
                price: leg.price,
                instrumentId: leg.instrumentId,
              })) ?? null,
          }
        : bodyBase;

    const response = await REST_API.OEMS.FILL_VOICE_ORDER(
      type,
      body,
      acceptWarnings
    );

    Array.isArray(response)
      ? response.forEach((res) => {
          res.success
            ? successToast(TOAST_CONTENT.oems.fill.success)
            : errorToast(res.message);
        })
      : response.success
      ? successToast(TOAST_CONTENT.oems.fill.success)
      : errorToast(response.message);
  } catch (err) {
    console.error("Submit fills error", err);
    errorToast(TOAST_CONTENT.oems.fill.failure, (err as Error)?.message);
  }
};
