import { WidgetType } from "@enfusion-ui/dashboards";
import { DashboardViewGridProps } from "@enfusion-ui/types";
import * as React from "react";

import { Grid } from "./Grid";

export const MainGrid: React.FC<DashboardViewGridProps<WidgetType>> = ({
  outerWidth,
  height,
  ...rest
}) => {
  return (
    <div
      style={{
        width: outerWidth,
        height: height,
        position: "relative",
      }}
    >
      <Grid {...rest} height={height} gridId="main" />
    </div>
  );
};
