import { ThemeDefinition } from "@enfusion-ui/core";
import { styled, withTheme } from "@enfusion-ui/web-core";
import { faComment } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import Zendesk, { ZendeskAPI } from "react-zendesk";

const NavbarAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
`;

const ZendeskLauncher: React.FC<{ theme: ThemeDefinition }> = ({ theme }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (loaded) {
      ZendeskAPI("webWidget", "updateSettings", {
        color: {
          theme: theme.colors.backgroundColor0,
          button: theme.colors.primary,
        },
      });
      // hides original widget on load
      ZendeskAPI("webWidget", "hide");
      // handles close from within
      ZendeskAPI("webWidget:on", "close", () => {
        ZendeskAPI("webWidget", "hide");
        setExpanded(false);
      });
    }
  }, [loaded]);

  const toggleMenu = () => {
    if (expanded) {
      ZendeskAPI("webWidget", "hide");
    } else {
      ZendeskAPI("webWidget", "show");
      ZendeskAPI("webWidget", "open");
    }
    setExpanded((prev) => !prev);
  };

  return process.env.REACT_APP_ZENDESK_KEY ? (
    <>
      <Zendesk
        defer
        color={{
          theme: theme.colors.backgroundColor0,
          button: theme.colors.primary,
        }}
        zendeskKey={process.env.REACT_APP_ZENDESK_KEY}
        onLoaded={() => setLoaded(true)}
      />
      {loaded && (
        <NavbarAction onClick={toggleMenu}>
          <FontAwesomeIcon
            icon={faComment}
            size="lg"
            color={
              theme.key === "dracula"
                ? "var(--tab-icon-color-0)"
                : `var(--text-${theme.key === "light" ? "inverted" : "normal"})`
            }
          />
        </NavbarAction>
      )}
    </>
  ) : null;
};

export default withTheme(ZendeskLauncher);
