import HolidayCenterPicker from "@app-views/oems/OrderTicket/panels/varSwap/HolidayCenterPicker";
import { HaircutTypeValues } from "@enfusion-ui/trades";
import { createSelectOptions } from "@enfusion-ui/utils";
import {
  ButtonGroupSelect,
  ControlledDatePicker,
  ControlledNumericInput,
  FormController,
  PanelRow,
} from "@enfusion-ui/web-components";
import * as React from "react";
import { ControllerRenderProps, useFormContext } from "react-hook-form";

import { HorizontalRule } from "../../../components/styledComponents";

type DateOption = "Open" | "Term";
const dateOptions = createSelectOptions<DateOption>(["Open", "Term"]);
const haircutTypeOptions = createSelectOptions(HaircutTypeValues);

export const CollateralHaircutSection: React.FC = () => {
  const [dateOption, setDateOption] = React.useState<DateOption>("Open");
  const { setValue } = useFormContext();

  React.useEffect(() => {
    setValue("terminationDate", null);
  }, [dateOption]);

  return (
    <>
      <PanelRow>
        <ButtonGroupSelect
          disableDeselect
          options={dateOptions}
          selectedValue={dateOption}
          onSelect={(val) => setDateOption(val!)}
        />
        <div style={{ width: "100%" }} />
      </PanelRow>

      <PanelRow>
        <ControlledDatePicker
          required
          placeholder=""
          name="startDate"
          label="Start Date"
        />
        <ControlledDatePicker
          placeholder=""
          name="terminationDate"
          label="Termination Date"
          required={dateOption === "Term"}
          disabled={dateOption !== "Term"}
          dataTestId="date-picker-termination-date"
        />
      </PanelRow>

      <PanelRow>
        <HolidayCenterPicker />
        <ControlledDatePicker
          disabled // Readonly
          placeholder=""
          name="terminationTradeDate"
          label="Termination Trade Date"
        />
      </PanelRow>

      <HorizontalRule />
      <PanelRow style={{ marginTop: "var(--spacing-xl)" }}>
        <FormController
          name="haircutType"
          render={({ value, onChange }: ControllerRenderProps) => (
            <ButtonGroupSelect
              disableDeselect
              onSelect={onChange}
              selectedValue={value}
              options={haircutTypeOptions}
            />
          )}
        />
        <ControlledNumericInput
          required
          hideLabel
          formatStyle="percent"
          name="haircutValue"
        />
      </PanelRow>
    </>
  );
};
