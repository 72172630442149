import { InstrumentInfo } from "@enfusion-ui/types";
import { Modal } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import InstrumentSelect from "../../../components/inputs/InstrumentSelect/InstrumentSelect";

const SelectorContainer = styled.div`
  margin-bottom: var(--spacing-xl);
  flex: 0 1;
`;

type InstrumentSelectorModalPropType = {
  onChange: (instrument: InstrumentInfo | null) => void;
  open: boolean;
  onClose: () => void;
};

const InstrumentSelectorModal: React.FC<InstrumentSelectorModalPropType> = ({
  onChange,
  open,
  onClose,
}) => {
  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title="Select Instrument"
      allowDismissal
      content={
        <SelectorContainer>
          <InstrumentSelect
            value={null}
            onChange={onChange}
            label="Add to Watch List"
          />
        </SelectorContainer>
      }
    />
  );
};

export default InstrumentSelectorModal;
