import { themesForDashboard } from "@enfusion-ui/core";
import {
  BREAK_POINTS_MAP_V2,
  BREAK_POINTS_OPTIONS,
  DASHBOARD_ORIENTATION_OPTIONS,
  EDIT_MENU_WIDTH,
  getBreakPointKey,
  useDashboard,
  WidgetDefinitionCategory,
} from "@enfusion-ui/dashboards";
import { useRefCallback, useRefValue } from "@enfusion-ui/hooks";
import { DashboardOrientation } from "@enfusion-ui/types";
import { getSelectOption } from "@enfusion-ui/utils";
import {
  Checkbox,
  Select,
  TextInput,
  useConfirmationModal,
  ViewContent,
} from "@enfusion-ui/web-components";
import {
  getCss,
  SCROLL_BAR_WIDTH,
  styled,
  ThemeProviderBase,
  useTheme,
  useThisTab,
} from "@enfusion-ui/web-core";
import * as React from "react";

import { DisplayMenu } from "../components/DisplayMenu";
import { MainGrid } from "../components/MainGrid";
import { StickyGrid } from "../components/StickyGrid";
import { WidgetMenu } from "../components/WidgetMenu";
import { useGridSizing } from "../hooks/useGridSizing";
import { DashboardPdfPreview } from "../pdf/DashboardPdfPreview";
import { PAPER_WIDTH } from "../pdf/utils";

export const EditMenu = styled.div`
  position: absolute;
  width: ${EDIT_MENU_WIDTH}px;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--background-accent);
  z-index: 2;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  border-left: 1px solid var(--input-border);
  overflow-x: hidden;
  overflow-y: auto;
`;
const SearchContainer = styled.div`
  padding: var(--spacing);
`;

const SubTitleContainer = styled.div`
  font-size: 0.9rem;
`;

export const COMPACT_TYPE_OPTIONS = [
  {
    label: "Vertical Compact",
    value: "vertical",
  },
  {
    label: "Horizontal Compact",
    value: "horizontal",
  },
];

const THEME_OPTIONS = [
  {
    label: "Dark",
    value: "dashDark",
  },
  {
    label: "Light",
    value: "dashLight",
  },
];

export const WidgetGridContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  background-color: var(--background-color-2);
`;

const Dashboard: React.FC<{
  category: WidgetDefinitionCategory;
  showSearchBar?: boolean;
}> = React.memo(({ category, showSearchBar = false }) => {
  const { theme } = useTheme();
  const {
    config,
    editMode,
    settings,
    changeSettingsKey,
    pdf,
    mobile,
    setMobile,
    orientation,
    setOrientation,
    enablePdfLayout,
    setEnablePdfLayout,
    hasUnsavedChanges,
    theme: dashboardTheme,
  } = useDashboard();
  const { width } = useThisTab();
  const innerWidthRef = useRefValue(width);

  const [numOfCols, setNumOfCols] = React.useState<number | null>(null);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [showStickyGrid, setShowStickyGrid] = React.useState(
    !!config?.sticky?.widgets.length
  );

  const gridSizing = useGridSizing(
    editMode,
    config?.main,
    showStickyGrid ? config?.sticky : undefined,
    editMode ? numOfCols : undefined
  );

  React.useEffect(() => {
    setNumOfCols(
      editMode
        ? BREAK_POINTS_MAP_V2[
            getBreakPointKey(innerWidthRef?.current as unknown as number)
          ]
        : null
    );
  }, [editMode]);

  React.useEffect(() => {
    if (enablePdfLayout) {
      setNumOfCols(
        BREAK_POINTS_MAP_V2[
          getBreakPointKey(PAPER_WIDTH[orientation as DashboardOrientation])
        ]
      );
    }
  }, [enablePdfLayout, orientation]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCompactTypeChange = (value: any) => {
    changeSettingsKey("compactType", value.value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleNumOfColsChange = (value: any) => {
    setNumOfCols(value.value);
  };

  const handleStickyGridChange = (value: boolean) => {
    setShowStickyGrid(value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleThemeChange = (e: any) => {
    changeSettingsKey("theme", e.value);
  };

  const handleLayoutLockChange = (value: boolean) => {
    changeSettingsKey("isLayoutLocked", value);
  };

  const handleMobileLayoutChange = () => {
    if (hasUnsavedChanges) SwitchLayoutModal.openModal();
    else switchLayout();
  };

  const switchLayout = useRefCallback(() => {
    setMobile?.(!mobile);
    setNumOfCols(2);
  }, [setMobile, setNumOfCols, mobile]);

  const handlePdfLayoutChange = (value: boolean) => {
    setEnablePdfLayout(value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDashboardOrientationChange = (value: any) => {
    setOrientation(value.value as DashboardOrientation);
    changeSettingsKey("orientation", value.value as DashboardOrientation);
  };

  const selectedTheme = category === "orderForm" ? theme : dashboardTheme;

  const renderSwitchLayoutModalContent = useRefCallback(
    () =>
      "Do you want to revert all changes to last saved state and switch layout?",
    []
  );
  const SwitchLayoutModal = useConfirmationModal({
    onSubmit: switchLayout,
    title: "You have unsaved changes",
    submitActionTheme: "warning",
    cancelActionTheme: "secondary",
    allowDismissal: false,
    submitButtonText: "Switch Layout",
    cancelButtonText: "Continue Editing",
    renderContent: renderSwitchLayoutModalContent,
  });

  if (pdf) return <DashboardPdfPreview />;

  return (
    <ViewContent
      style={{
        overflow: "auto",
        flex: 1,
        display: "flex",
        position: "relative",
      }}
    >
      <ThemeProviderBase theme={selectedTheme ?? themesForDashboard.dashDark}>
        <WidgetGridContainer
          style={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(getCss(selectedTheme) as any),
            width: editMode ? gridSizing.innerWidth + SCROLL_BAR_WIDTH : "100%",
            overflowX: editMode ? "auto" : "hidden",
            marginRight: editMode ? EDIT_MENU_WIDTH : 0,
          }}
          className={selectedTheme?.agGridClass}
        >
          {showStickyGrid && (
            <StickyGrid
              gridId="sticky"
              {...config?.sticky}
              dimensions={gridSizing.dimensions.sticky}
              height={gridSizing.height.sticky}
              width={gridSizing.innerWidth}
              outerWidth={gridSizing.outerWidth}
              compactType="vertical"
              numOfCols={editMode ? numOfCols : undefined}
            />
          )}

          <MainGrid
            gridId="main"
            {...config?.main}
            dimensions={gridSizing.dimensions.main}
            height={gridSizing.height.main}
            width={gridSizing.innerWidth}
            outerWidth={gridSizing.outerWidth}
            compactType={settings.compactType}
            numOfCols={editMode ? numOfCols : undefined}
          />
        </WidgetGridContainer>
      </ThemeProviderBase>

      {editMode ? (
        <EditMenu>
          {showSearchBar ? (
            <SearchContainer>
              <TextInput
                value={searchTerm}
                label="Search Widgets"
                onChange={(e) => setSearchTerm(e.target.value)}
                clearable
                onClearValue={() => setSearchTerm("")}
              />
            </SearchContainer>
          ) : null}
          <WidgetMenu
            filterList={[category]}
            displayLabel={
              category === "orderForm" ? "Panel Widgets" : "Display Widgets"
            }
            searchTerm={searchTerm}
          />
          <DisplayMenu>
            <>
              {category !== "orderForm" && (
                <Checkbox
                  label="Sticky Section"
                  checked={showStickyGrid}
                  onChange={handleStickyGridChange}
                />
              )}

              {mobile ? null : (
                <Select
                  label="Layout"
                  value={getSelectOption(
                    COMPACT_TYPE_OPTIONS,
                    settings.compactType
                  )}
                  options={COMPACT_TYPE_OPTIONS}
                  onChange={handleCompactTypeChange}
                  inputId="dashboard-layout-selection-id"
                />
              )}
              {category !== "orderForm" && (
                <Select
                  label="Color Theme"
                  value={getSelectOption(THEME_OPTIONS, settings.theme)}
                  options={THEME_OPTIONS}
                  onChange={handleThemeChange}
                  inputId="dashboard-color-theme-selection-id"
                />
              )}
              {category === "orderForm" && (
                <Checkbox
                  label="Mobile layout"
                  checked={mobile ?? false}
                  onChange={handleMobileLayoutChange}
                  id="mobile-layout"
                />
              )}
              {!enablePdfLayout && (
                <Select
                  label="Number of Columns"
                  value={getSelectOption(BREAK_POINTS_OPTIONS, numOfCols)}
                  options={BREAK_POINTS_OPTIONS}
                  onChange={handleNumOfColsChange}
                  clearable={false}
                  inputId="dashboard-no-of-columns-selection-id"
                />
              )}
              {category !== "orderForm" && (
                <Checkbox
                  label="Layout Locked"
                  checked={settings.isLayoutLocked}
                  onChange={handleLayoutLockChange}
                />
              )}
              {process.env.REACT_APP_ENABLE_PDF_DASHBOARDS &&
                category !== "orderForm" && (
                  <>
                    <SubTitleContainer>PDF Settings</SubTitleContainer>

                    <Checkbox
                      label="PDF Layout"
                      checked={enablePdfLayout}
                      onChange={handlePdfLayoutChange}
                    />
                    {enablePdfLayout && (
                      <Select
                        label="PDF Layout Orientation"
                        value={getSelectOption(
                          DASHBOARD_ORIENTATION_OPTIONS,
                          orientation
                        )}
                        options={DASHBOARD_ORIENTATION_OPTIONS}
                        onChange={handleDashboardOrientationChange}
                        clearable={false}
                        inputId="dashboard-pdf-layout-orientation-selection-id"
                        disabled={!enablePdfLayout}
                      />
                    )}
                  </>
                )}
            </>
          </DisplayMenu>
        </EditMenu>
      ) : null}
      {SwitchLayoutModal.content}
    </ViewContent>
  );
});

export default Dashboard;
