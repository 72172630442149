import { AnalyticsData } from "@app-context/analytics/context";
import { formatPercentage } from "@enfusion-ui/utils";
import { css, styled } from "@enfusion-ui/web-core";
import * as React from "react";

const Table = styled.table`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  border-spacing: 0;
  border-radius: var(--radius);
  background-color: var(--background-color-2);
`;

const TableRow = styled.tr<{ selected?: boolean }>`
  width: 100%;
  display: flex;
  cursor: pointer;

  ${({ selected }) =>
    selected
      ? css`
          background-color: var(--primary);
        `
      : ""}

  :first-child > td {
    border-top: 0;
  }

  :last-child > td {
    border-bottom: 0;
  }
`;

const TableCell = styled.td<{
  textAlign?: "left" | "right" | "center";
  selected?: boolean;
}>`
  flex: 1;
  padding: var(--spacing);
  border-top: 0.5px solid var(--background-color-0);
  border-bottom: 0.5px solid var(--background-color-0);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: ${({ textAlign }) => textAlign ?? "left"};
`;

const GraphCellContainer = styled.div`
  height: 100%;
  display: flex;
  position: relative;
`;

const BarContainer = styled.div<{
  percent: number;
  negative: boolean;
}>`
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;

  :after {
    content: "";
    position: absolute;

    height: 100%;
    ${({ negative }) =>
      negative
        ? css`
            right: 50%;
            background-color: var(--danger);
            border-radius: var(--radius) 0px 0px var(--radius);
          `
        : css`
            left: 50%;
            background-color: var(--success);
            border-radius: 0px var(--radius) var(--radius) 0px;
          `};
    width: ${({ percent }) => (percent ? `${percent / 2}%` : "0px")};
  }
`;

const PerformanceCellValue = styled.div`
  position: absolute;
  right: 0;
  padding-right: 2px;
  font-size: 11px;
  line-height: 13px;
  font-weight: 600;
`;

const Bar: React.FC<{ value: number }> = ({ value }) => {
  const negative = value < 0;
  let val = Math.abs(value);
  val = val > 0 && val < 0.01 ? 0.01 : val;

  return (
    <BarContainer negative={negative} percent={Math.min(val * 100, 100)} />
  );
};

const GraphTableCell: React.FC<{ value: number }> = ({ value }) => {
  return (
    <TableCell>
      <GraphCellContainer>
        <Bar value={value} />
        <PerformanceCellValue>
          {formatPercentage(value, [0, 2], false, undefined, false, true)}
        </PerformanceCellValue>
      </GraphCellContainer>
    </TableCell>
  );
};

export type AnalyticsPivotTableRowData = {
  key: string;
  performance: number;
  risk: number;
};

type AnalyticsPivotTableProps = {
  data: AnalyticsPivotTableRowData[];
  selected?: string[];
  section: keyof AnalyticsData["pivots"];
  onRowPress?: (
    section: keyof AnalyticsData["pivots"],
    categories: string
  ) => void;
};

export const AnalyticsPivotTable: React.FC<AnalyticsPivotTableProps> = ({
  data = [],
  selected = [],
  section,
  onRowPress,
}) => {
  return (
    <Table>
      {data.map((row, i) => (
        <TableRow
          selected={selected.includes(row.key)}
          key={i}
          onClick={() => {
            onRowPress?.(section, row.key);
          }}
        >
          {(Object.keys(row) as (keyof AnalyticsPivotTableRowData)[]).map(
            (key) => {
              switch (key) {
                case "risk":
                case "performance":
                  return <GraphTableCell value={row[key]} key={key} />;
                default:
                  return (
                    <TableCell title={row[key]} key={key}>
                      {row[key]}
                    </TableCell>
                  );
              }
            }
          )}
        </TableRow>
      ))}
    </Table>
  );
};
