import { WidgetComponentsDefinition } from "../../../types";
import PdfPortfolioGroupsSelectionWidget from "./PdfPortfolioGroupsSelectionWidget";
import PortfolioGroupsSelectionEditWidget from "./PortfolioGroupsSelectionEditWidget";
import PortfolioGroupsSelectionWidget from "./PortfolioGroupsSelectionWidget";

export const portfolioGroupsSelection: WidgetComponentsDefinition = {
  renderComponent: PortfolioGroupsSelectionWidget,
  editComponent: PortfolioGroupsSelectionEditWidget,
  renderPdfComponent: PdfPortfolioGroupsSelectionWidget,
};
