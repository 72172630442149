import { basicInfoMap, useRestAbortableOptions } from "@enfusion-ui/hooks";
import { MultiSelectOptionsType } from "@enfusion-ui/types";
import {
  ControlledInputBase,
  MultiSelect,
  MultiSelectProps,
} from "@enfusion-ui/web-components";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

export type PortfolioGroupsSelectProps = Omit<
  Partial<MultiSelectProps<number>>,
  "options" | "value" | "onChange"
> & { value?: number[] | null; onChange?: (ids: number[] | null) => void };

const getValues = (
  ids: number[],
  options: MultiSelectOptionsType[] = []
): MultiSelectOptionsType[] => {
  const result: MultiSelectOptionsType[] = [];
  ids.forEach((id) => {
    const selectedOption = options.find((option) => option.value === id);
    if (selectedOption) {
      result.push(selectedOption);
    }
  });
  return result;
};

export const ControlledPortfolioGroupsSelect: React.FC<
  Omit<PortfolioGroupsSelectProps, "value" | "onChange"> & {
    name?: string;
  }
> = ({ name = "portfolioGroupIds", ...props }) => {
  return (
    <ControlledInputBase
      name={name}
      render={({ ref: _ref, ...renderProps }) => (
        <PortfolioGroupsSelect {...renderProps} {...props} />
      )}
    />
  );
};

const PortfolioGroupsSelect: React.FC<PortfolioGroupsSelectProps> = ({
  value: values,
  onChange,
  label = "Portfolio Group(s)",
  placeholder = "All",
  inputId = "portfolio-group-multi-selection-id",
  ...rest
}) => {
  const [selectedPortfolioGroups, setSelectedPortfolioGroups] = React.useState<
    number[] | null
  >([]);

  const { options, loading } = useRestAbortableOptions(
    REST_API.LEGALENTITY.GET_PORTFOLIO_GROUPS.FETCH,
    basicInfoMap
  );

  React.useEffect(() => {
    values && setSelectedPortfolioGroups(values);
  }, [values]);

  return (
    <MultiSelect
      {...rest}
      isLoading={loading}
      value={
        selectedPortfolioGroups
          ? getValues(selectedPortfolioGroups, options)
          : []
      }
      onChange={(selectedOptions) => {
        const newPortfolios = selectedOptions?.length
          ? selectedOptions.map(
              (opt: MultiSelectOptionsType) => opt?.value as number
            )
          : null;

        setSelectedPortfolioGroups(newPortfolios);
        onChange?.(newPortfolios);
      }}
      label={label}
      options={options}
      inputId={inputId}
      placeholder={placeholder}
    />
  );
};

export default PortfolioGroupsSelect;
