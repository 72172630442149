import { WidgetComponentsDefinition } from "../../../types";
import PdfTraderSelectionWidget from "./PdfTraderSelectionWidget";
import TraderSelectionEditWidget from "./TraderSelectionEditWidget";
import TraderSelectionWidget from "./TraderSelectionWidget";

export const traderSelection: WidgetComponentsDefinition = {
  renderComponent: TraderSelectionWidget,
  editComponent: TraderSelectionEditWidget,
  renderPdfComponent: PdfTraderSelectionWidget,
};
