import { useReconciliation } from "@app-context/reconciliation/context";
import { TOAST_CONTENT } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  Button,
  Modal,
  useConfirmationModal,
} from "@enfusion-ui/web-components";
import { errorToast, successToast } from "@enfusion-ui/web-core";
import { isEqual } from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { reconciliationProviderDefaultState } from "../reducer";
import { ReconciliationFormValues, ReconciliationMetaData } from "../types";
import {
  getUpdatedDataSources,
  hasEmptyMandatoryFields,
} from "../utils/commonUtils";
import { ReconciliationDashboardSettingsModalContent } from "./ReconciliationDashboardSettingsModalContent";
import { RowSection } from "./styles";

type SettingsModalProps = {
  isOpen: boolean;
  closeModal: VoidFunction;
};

const ReconciliationDashboardSettingsModal: React.FC<SettingsModalProps> = ({
  isOpen,
  closeModal,
}) => {
  const {
    config,
    updateConfig,
    changeFilterState,
    loadAddedDatasources,
    storeReconciliationDashboard,
    metadata,
    setMetadata,
  } = useReconciliation();

  const [unsavedMetadata, setUnsavedMetadata] =
    React.useState<ReconciliationMetaData>();
  const [hasUnsavedChanges, setHasUnsavedChanges] =
    React.useState<boolean>(false);

  const formMethods = useForm<ReconciliationFormValues>({
    defaultValues: {
      summaryReports: config.summaryReports,
      reconReports: config.reconReports,
    },
  });
  const { reset, formState, getValues, watch } = formMethods;
  const { summaryReports = [], reconReports = [] } = getValues();
  const {
    reconReports: unsavedReconReports,
    summaryReports: unsavedSummaryReports,
  } = watch(["reconReports", "summaryReports"]);

  const unsavedConfig = React.useMemo(() => {
    return { reconReports, summaryReports };
  }, [reconReports, summaryReports]);

  const UnsavedChangesModal = useConfirmationModal({
    onSubmit: () => {
      handleRevert();
      UnsavedChangesModal.closeModal();
      closeModal();
    },
    onCancel: () => {
      UnsavedChangesModal.closeModal();
    },
    allowDismissal: false,
    title: "Cancel Changes?",
    submitButtonText: "Confirm",
    submitActionTheme: "danger",
    cancelButtonText: "Cancel",
    renderContent: () =>
      "All the unapplied changes will be lost. Do you want to cancel the changes?",
  });

  const handleApply = useRefCallback(async () => {
    if (hasEmptyMandatoryFields(unsavedConfig)) {
      errorToast("Please fill all the mandatory fields");
      return;
    }
    try {
      const res = await storeReconciliationDashboard({
        path: "/recon-dashboard.json",
        rootPath: "user",
        name: "recon dashboard",
        version: 0,
        config: unsavedConfig,
      });
      if (res.success) {
        if (!isEqual(config.reconReports, unsavedConfig.reconReports))
          changeFilterState(reconciliationProviderDefaultState.filterState);
        const { addedDatasources, removedDatasources } = getUpdatedDataSources(
          config,
          unsavedConfig
        );
        updateConfig(unsavedConfig);
        setMetadata(unsavedMetadata);
        loadAddedDatasources(addedDatasources, removedDatasources);
        setHasUnsavedChanges(false);
        closeModal();
        successToast(TOAST_CONTENT.Reconciliation.success);
      } else {
        throw new Error("Failed to save dashboard changes");
      }
    } catch (error: any) {
      console.error(error);
      if (error.message?.startsWith("Failed to save dashboard")) {
        errorToast(TOAST_CONTENT.Reconciliation.failure);
      } else {
        errorToast("Something went wrong.");
      }
    }
  }, [
    config,
    unsavedConfig,
    setMetadata,
    unsavedMetadata,
    setHasUnsavedChanges,
  ]);

  const handleClose = useRefCallback(() => {
    if (hasUnsavedChanges) {
      UnsavedChangesModal.openModal();
    } else {
      closeModal();
    }
  }, [hasUnsavedChanges]);

  const handleRevert = useRefCallback(() => {
    if (hasUnsavedChanges) {
      reset({
        summaryReports: config.summaryReports,
        reconReports: config.reconReports,
      });
      setUnsavedMetadata(metadata);
      setHasUnsavedChanges(false);
    }
  }, [
    hasUnsavedChanges,
    config,
    setUnsavedMetadata,
    metadata,
    setHasUnsavedChanges,
  ]);

  React.useEffect(() => {
    if (isOpen) {
      reset({
        summaryReports: config.summaryReports,
        reconReports: config.reconReports,
      });
      setUnsavedMetadata(metadata);
    }
  }, [isOpen]);

  React.useEffect(() => {
    setHasUnsavedChanges(
      formState.isDirty ||
        !isEqual(config, {
          summaryReports: unsavedSummaryReports,
          reconReports: unsavedReconReports,
        })
    );
  }, [formState.isDirty, unsavedReconReports, unsavedSummaryReports]);

  return (
    <>
      <Modal
        title="Reconciliation Settings"
        isOpen={isOpen}
        onClose={handleClose}
        content={
          <FormProvider {...formMethods}>
            <ReconciliationDashboardSettingsModalContent
              unsavedMetadata={unsavedMetadata}
              setUnsavedMetadata={setUnsavedMetadata}
            />
          </FormProvider>
        }
        footerContent={
          <RowSection gap="xl">
            <Button
              style={{
                backgroundColor: `var(--${
                  hasUnsavedChanges ? "primary" : "background-color-2"
                })`,
              }}
              onClick={handleApply}
              disabled={!hasUnsavedChanges}
            >
              Apply
            </Button>
            <Button onClick={handleRevert} disabled={!hasUnsavedChanges}>
              Revert
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </RowSection>
        }
      />

      {UnsavedChangesModal.content}
    </>
  );
};

export default ReconciliationDashboardSettingsModal;
