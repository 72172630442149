import { RoundedDataGrid } from "@app-components/DataGrid";
// /import { TradeTicketFormValues } from "@enfusion-ui/trades";
import { useRefCallback } from "@enfusion-ui/hooks";
import { useTradeTicket } from "@enfusion-ui/trades";
import { styled } from "@enfusion-ui/web-core";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import * as React from "react";

import {
  SummaryEditButton,
  SummaryPanel,
  SummaryPanelLabel,
} from "./styledComponents";

const GridContainer = styled.div`
  overflow: hidden;
  border: 1px solid #424242;
  border-radius: var(--radius);
`;

const colDefs: ColDef[] = [
  {
    headerName: "Payment Type",
    field: "type",
    initialWidth: 200,
  },
  {
    headerName: "Amount",
    field: "amount",
    initialWidth: 100,
  },
];

/**
 * TODO when the data is decided:
 * - Add valueFormatter to format amount values with currency sign and digit
 * - Add data processing/aggregation if needed
 */

export const ContentSummaryPanelPayment: React.FC = () => {
  const { focusSection } = useTradeTicket();
  // const { } = useWatch<TradeTicketFormValues>({
  //   name: [""],
  // });
  const rowData = undefined;

  const onClickEditButton = useRefCallback(() => {
    focusSection("general", "trade-cost-section");
  }, []);

  const onGridReady = useRefCallback(
    (event: GridReadyEvent) => event.api.sizeColumnsToFit(),
    []
  );

  return (
    <SummaryPanel>
      <SummaryPanelLabel>Payments</SummaryPanelLabel>
      {rowData ? (
        <GridContainer>
          <RoundedDataGrid
            height="unset"
            rowData={rowData}
            suppressCellFocus
            columnDefs={colDefs}
            domLayout="autoHeight"
            onGridReady={onGridReady}
            defaultColDef={{
              resizable: true,
              suppressHeaderMenuButton: true,
              suppressMovable: true,
            }}
          />
        </GridContainer>
      ) : (
        <SummaryEditButton onClick={onClickEditButton}>
          Select Payment
        </SummaryEditButton>
      )}
    </SummaryPanel>
  );
};
