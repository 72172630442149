import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { VarColorText } from "../../views/oems/components/styledComponents";

export type GridData = {
  fieldName: string;
  fieldValue: string | number | undefined;
  fieldTitle?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showColored?: { onValueChanged: (value: any) => string | null; data?: any };
}[];

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const GridItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.25rem 0;
`;
const FieldValue = styled.div<{ width?: string; color?: string }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1em;
  width: ${({ width = "0rem" }) => width};
  color: ${({ color = "inherit" }) => color};
`;
const FieldName = styled(FieldValue)<{ width?: string }>`
  font-size: 1em;
  width: ${({ width = "0rem" }) => width};
`;

export const GridView: React.FC<{
  gridData: GridData;
  fieldNameWidth?: string | number;
  fieldValueWidth?: string | number;
  fieldValueDefaultColor?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showColored?: { onValueChanged: (value: any) => string | null; data?: any };
}> = ({
  gridData,
  fieldNameWidth,
  fieldValueWidth,
  fieldValueDefaultColor,
  showColored: showColoredDefault,
}) => {
  return (
    <GridWrapper>
      {gridData.map(
        ({ fieldName, fieldValue, showColored, fieldTitle }, idx) => (
          <GridItem key={`${idx}`}>
            <FieldName
              width={
                typeof fieldNameWidth === "string"
                  ? fieldNameWidth
                  : fieldNameWidth + "rem"
              }
              title={fieldName}
            >
              {fieldName}
            </FieldName>
            <FieldValue
              title={
                !!fieldTitle
                  ? fieldTitle
                  : typeof fieldValue !== "string"
                  ? fieldValue?.toString()
                  : fieldValue
              }
              width={
                typeof fieldValueWidth === "string"
                  ? fieldValueWidth
                  : fieldValueWidth + "rem"
              }
              color={fieldValueDefaultColor}
            >
              {!!showColored || !!showColoredDefault ? (
                <VarColorText
                  colorValue={(
                    showColored! || showColoredDefault!
                  ).onValueChanged(
                    (showColored! || showColoredDefault!).data ?? fieldValue
                  )}
                >
                  {fieldValue}
                </VarColorText>
              ) : (
                fieldValue || "-"
              )}
            </FieldValue>
          </GridItem>
        )
      )}
    </GridWrapper>
  );
};
