import {
  Button,
  InfoPopover,
  Modal,
  ToggleSwitch,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { useCookieConsent } from "@use-cookie-consent/core";
import * as React from "react";

import { useGDPR } from "../../context/gdpr/context";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 1.25rem;
  gap: 0.5rem;
`;

const ContentContainer = styled.div`
  margin-top: 2px;
`;

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`;

const Action = styled.div`
  display: flex;
  align-items: center;
`;

const ActionButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 8px;
  margin-top: 8px;
`;

export type SettingCookiesModalProps = {
  open: boolean;
  onCancel: VoidFunction;
};

const InfoLabel: React.FC<React.PropsWithChildren<{ text: string }>> = ({
  text,
  children,
}) => {
  return (
    <div style={{ flex: 1, fontSize: 14, paddingLeft: "var(--spacing)" }}>
      {text}
      <InfoPopover style={{ marginLeft: "var(--spacing)" }}>
        {children}
      </InfoPopover>
    </div>
  );
};

const SettingCookiesModal: React.FC<SettingCookiesModalProps> = ({ open }) => {
  const { acceptCookies } = useCookieConsent();
  const {
    strictlyNecessaryOn,
    performanceCookiesOn,
    functionalCookiesOn,
    targetingCookiesOn,
    toggleRefreshBrowserModalOpen,
    toggleSettingCookiesModalOpen,
    toggleStrictlyNecessaryChange,
    togglePerformanceCookiesChange,
    toggleFunctionalCookiesChange,
    toggleTargetingCookiesChange,
    acceptAll,
  } = useGDPR();

  const confirmCookies = () => {
    acceptCookies({
      necessary: strictlyNecessaryOn,
      statistics: performanceCookiesOn,
      preferences: functionalCookiesOn,
      marketing: targetingCookiesOn,
    });

    toggleSettingCookiesModalOpen();
    toggleRefreshBrowserModalOpen();
  };

  return (
    <Modal
      isOpen={open}
      onClose={toggleSettingCookiesModalOpen}
      title="Cookie Settings"
      maxWidth={600}
      zIndex={200}
      content={
        <GridContainer>
          <ContentContainer>
            When you visit any of our websites, it may store or retrieve
            information on your browser, mostly in the form of cookies. This
            information might be about you, your preferences or your device and
            is mostly used to make the site work as you expect it to. The
            information does not usually directly identify you, but it can give
            you a more personalized web experience. Because we respect your
            right to privacy, you can choose not to allow some types of cookies.
            Click on the different category headings to find out more and manage
            your preferences. Please note, blocking some types of cookies may
            impact your experience of the site and the services we are able to
            offer.
          </ContentContainer>
          <ActionContainer>
            <Action>
              <InfoLabel text="Strictly Necessary">
                These cookies are necessary for our website to function properly
                and cannot be switched off in our systems. They are usually only
                set in response to actions made by you which amount to a request
                for services, such as setting your privacy preferences, logging
                in or filling in forms or where they’re essential to provide you
                with a service you have requested. You cannot opt-out of these
                cookies. You can set your browser to block or alert you about
                these cookies, but if you do, some parts of the site will not
                then work. These cookies do not store any personally
                identifiable information.
              </InfoLabel>
              <ToggleSwitch
                id="strictlyNecessaryToggleSwitch"
                checked={strictlyNecessaryOn}
                onChange={toggleStrictlyNecessaryChange}
                disabled
              />
            </Action>
            <Action>
              <InfoLabel text="Performance Cookies">
                These cookies allow us to count visits and traffic sources so we
                can measure and improve the performance of our site. They help
                us to know which pages are the most and least popular and see
                how visitors move around the site, which helps us optimize your
                experience. All information these cookies collect is aggregated
                and therefore anonymous. If you do not allow these cookies we
                will not be able to use your data in this way.
              </InfoLabel>
              <ToggleSwitch
                id="performanceCookiesToggleSwitch"
                checked={performanceCookiesOn}
                onChange={togglePerformanceCookiesChange}
              />
            </Action>
            <Action>
              <InfoLabel text="Functional Cookies">
                These cookies enable the website to provide enhanced
                functionality and personalization. They may be set by us or by
                third party providers whose services we have added to our pages.
                If you do not allow these cookies then some or all of these
                services may not function properly.
              </InfoLabel>
              <ToggleSwitch
                id="functionalCookiesToggleSwitch"
                checked={functionalCookiesOn}
                onChange={toggleFunctionalCookiesChange}
              />
            </Action>
            <Action>
              <InfoLabel text="Targeting Cookies">
                These cookies may be set through our site by our advertising
                partners. They may be used by those companies to build a profile
                of your interests and show you relevant adverts on other sites.
                They do not store directly personal information but are based on
                uniquely identifying your browser and internet device. If you do
                not allow these cookies, you will experience less targeted
                advertising.
              </InfoLabel>
              <ToggleSwitch
                id="targetingCookiesToggleSwitch"
                checked={targetingCookiesOn}
                onChange={toggleTargetingCookiesChange}
              />
            </Action>
            <ActionButtonContainer>
              <Button theme="primary" onClick={confirmCookies}>
                Confirm my choices
              </Button>
              <Button theme="text-link" onClick={acceptAll}>
                Accept all cookies
              </Button>
              <Button onClick={toggleSettingCookiesModalOpen}>Cancel</Button>
            </ActionButtonContainer>
          </ActionContainer>
        </GridContainer>
      }
    />
  );
};

export default SettingCookiesModal;
