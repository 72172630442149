import {
  ButtonGroupSelect,
  ControlledNumericInput,
  FormElement,
} from "@enfusion-ui/web-components";
import * as React from "react";

const units = [
  { label: "$", value: "amount" },
  { label: "%", value: "percent" },
];

export const InitialMarginInput: React.FC<unknown> = () => {
  const [marginType, setMarginType] = React.useState<string>("percent");

  return (
    <FormElement style={{ gap: 5 }}>
      <FormElement basis="50%">
        <ButtonGroupSelect
          title="Initial Margin"
          onSelect={(val) => {
            if (val) setMarginType(val);
          }}
          options={units}
          selectedValue={marginType}
        />
      </FormElement>
      <ControlledNumericInput
        name="initialMargin"
        labelPlaceholder
        placeholder={marginType === "percent" ? "0.00%" : "0.00"}
        formatStyle={marginType === "percent" ? "percent" : "number"}
      />
    </FormElement>
  );
};
