import { ControlledInstrumentSelect } from "@app-components/inputs/InstrumentSelect/ControlledInstrumentSelect";
import { SelectOptionsType } from "@enfusion-ui/types";
import {
  ControlledDatePicker,
  ControlledNumericInput,
  ControlledSelect,
  PanelRow,
} from "@enfusion-ui/web-components";
import { noop } from "lodash";
import * as React from "react";

const barrierTypeSelectOptions: SelectOptionsType<string>[] = [
  { label: "Knock out", value: "knockOut" },
  { label: "Knock In", value: "knockIn" },
];

export const DoubleBarrier: React.FC<unknown> = () => {
  return (
    <div data-e2e-id="double-barrier-tab">
      <PanelRow>
        <ControlledSelect
          name="doubleBarrierType"
          label="Barrier Type"
          options={barrierTypeSelectOptions}
          required
        />

        <ControlledInstrumentSelect
          onChange={noop}
          label="Barrier Instrument"
          name="doubleBarrierInstrument"
          hideAdvancedSearchButtonText
        />
      </PanelRow>
      <PanelRow>
        <ControlledNumericInput
          name="lowerLevel"
          label="Lower Level"
          required
        />
        <ControlledNumericInput
          name="upperLevel"
          label="Upper Level"
          required
        />
      </PanelRow>
      <PanelRow>
        <ControlledDatePicker
          name="barrierEffectiveDate"
          label="Effective Date"
        />
        <ControlledDatePicker
          name="barrierTerminatingDate"
          label="Terminating Date"
        />
      </PanelRow>
    </div>
  );
};
