import { WidgetComponentsDefinition } from "../../types";
import NumericInputEditWidget from "./NumericInputEditWidget";
import NumericInputWidget from "./NumericInputWidget";
import PdfNumericInputWidget from "./PdfNumericInputWidget";

export const numericInput: WidgetComponentsDefinition = {
  renderComponent: NumericInputWidget,
  editComponent: NumericInputEditWidget,
  renderPdfComponent: PdfNumericInputWidget,
};
