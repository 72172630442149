import { InactiveDateDescriptor } from "@enfusion-ui/types";
import { SelectOptionsType } from "@enfusion-ui/types/src";
import {
  ControlledInputBase,
  NumericInput,
  PanelRow,
  Select,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { InputContainer } from "./PositionCashFlowSettings";

const StyledNumericInput = styled(NumericInput)`
  width: 50%;
  position: relative;
  bottom: 0.8rem;
`;

const StyledNumeric = styled.div`
  width: 15%;
`;

const StyledText = styled.div`
  font-size: 0.75em;
  line-height: 1em;
  font-weight: 500;
  align-self: center;
`;

type FilterType = "None" | "NumberOfDays";

const FilterTypeOptions: SelectOptionsType<FilterType>[] = [
  { label: "None", value: "None" },
  { label: "Inactive Days", value: "NumberOfDays" },
];

const getValue = (value: String): SelectOptionsType<FilterType> | undefined => {
  return FilterTypeOptions.find((Option) => Option.value === value);
};

const ControlledFilterSelect = () => {
  const inactiveDateDescriptor = useWatch<InactiveDateDescriptor>({
    name: ["inactiveDateDescriptor"],
  });
  const { setValue } = useFormContext();

  const [filterTypeValue, setFilterValue] = React.useState<
    SelectOptionsType<FilterType> | null | undefined
  >(getValue(inactiveDateDescriptor?.schemeType ?? "None"));

  const [numberOfLookbackDaysTextInput, setNumberOfLookbackDaysTextInput] =
    React.useState<number>(inactiveDateDescriptor?.numberOfLookbackDays ?? 0);

  React.useEffect(() => {
    setValue("inactiveDateDescriptor", {
      schemeType: filterTypeValue?.value,
      numberOfLookbackDays: numberOfLookbackDaysTextInput,
    });
  }, [filterTypeValue, numberOfLookbackDaysTextInput]);

  return (
    <>
      <ControlledInputBase
        name="inactiveDateDescriptor"
        render={({ ref: _ref, ...rest }) => (
          <>
            <InputContainer>
              <Select
                {...rest}
                label="Filter Type"
                options={FilterTypeOptions}
                value={filterTypeValue}
                clearable={false}
                onChange={(val: SelectOptionsType<FilterType> | null) => {
                  setFilterValue(val);
                  setNumberOfLookbackDaysTextInput(0);
                }}
              />
            </InputContainer>
            {filterTypeValue?.value === "NumberOfDays" ? (
              <PanelRow>
                <StyledText>Ignore Position Inactive for</StyledText>
                <StyledNumeric>
                  <StyledNumericInput
                    value={numberOfLookbackDaysTextInput}
                    onChange={(val) =>
                      setNumberOfLookbackDaysTextInput(Number(val))
                    }
                    hideControls
                  />
                </StyledNumeric>
                <StyledText>days since start of value date year</StyledText>
              </PanelRow>
            ) : (
              <></>
            )}
          </>
        )}
      />
    </>
  );
};

export default ControlledFilterSelect;
