import {
  reconciliationProviderDefaultState,
  reconciliationProviderReducer,
} from "@app-views/reconciliation/reducer";
import { ReconciliationMetaData } from "@app-views/reconciliation/types";
import {
  fetchMetadata,
  getAllDatasources,
} from "@app-views/reconciliation/utils/commonUtils";
import { SUMMARY_REPORT_IDS } from "@app-views/reconciliation/utils/constants";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import {
  ReconciliationConfig,
  ReconciliationData,
  ReconciliationDatasourceDef,
  ReconciliationFilterState,
  StoreReconciliationArgs,
} from "@enfusion-ui/types";
import { errorToast, REST_API, useReports } from "@enfusion-ui/web-core";
import React from "react";

import { ReconciliationContext } from "./context";

const loadReconciliationDashboard = () =>
  REST_API.RECONCILIATION.DOWNLOAD<ReconciliationData>();

const storeReconciliationDashboard = (params: StoreReconciliationArgs) =>
  REST_API.RECONCILIATION.STORE(params);

export const ReconciliationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [reportsLoadingStatus, setReportsLoadingStatus] =
    React.useState<Record<string, boolean>>();
  const [{ config, filterState, loading }, dispatchBase] = React.useReducer(
    reconciliationProviderReducer,
    reconciliationProviderDefaultState
  );
  const [metadata, setMetadataBase] = React.useState<ReconciliationMetaData>();
  const isMounted = useMounted();
  const { loadReport, closeReport } = useReports();

  const dispatch = useRefCallback(
    (action) => {
      if (isMounted()) dispatchBase(action);
    },
    [dispatchBase, isMounted]
  );

  const updateConfig = useRefCallback(
    (configData: ReconciliationConfig) => {
      dispatch({ type: "update-config", payload: { configData: configData } });
    },
    [dispatch]
  );

  const changeFilterState = useRefCallback(
    (filterState: ReconciliationFilterState) => {
      //Instrument filter needs to be cleared out when any of the other filters are cleared
      Object.keys(filterState).forEach((key) => {
        const typedKey = key as keyof ReconciliationFilterState;
        if (!filterState[typedKey]?.value?.length) {
          filterState.instrument.value = [];
        }
      });
      dispatch({
        type: "change-filter-state",
        payload: { filterState: filterState },
      });
    },
    [dispatch]
  );

  const setMetadata = useRefCallback(
    async (id: string, path: string) => {
      if (path) {
        const isSummaryReport = Object.values(SUMMARY_REPORT_IDS).includes(id);
        const { defs, metadata } = await fetchMetadata(path, isSummaryReport);
        setMetadataBase((state) => ({
          ...state,
          [id]: { defs, metadata },
        }));
      }
    },
    [dispatch]
  );

  const loadDataSources = useRefCallback(
    (datasources: ReconciliationDatasourceDef[]) => {
      datasources.forEach((datasource) => {
        setReportsLoadingStatus((prev) => ({
          ...prev,
          [datasource.reconId]: true,
        }));
        loadReport({
          ...datasource.datasource,
          reportId: datasource.reconId,
          skipEqualCheck: true,
          closeFirst: false,
        });
      });
    },
    [loadReport]
  );

  const downloadReconciliationDashboard = useRefCallback(async () => {
    try {
      const data = await loadReconciliationDashboard();
      const datasources = getAllDatasources(data.config);
      for (const { reconId, datasource } of datasources) {
        closeReport(reconId, datasource.path);
        await setMetadata(reconId, datasource.path);
      }
      dispatch({ type: "hydrate", payload: { configData: data.config } });
      loadDataSources(datasources);
    } catch (e) {
      dispatch({ type: "loading", payload: { loading: false } });
      errorToast("Error in getting Reconciliation Dashboard.");
      console.error(e);
    }
  }, [loadReconciliationDashboard, loadDataSources, dispatch]);

  const reloadReconciliationDashboard = useRefCallback(() => {
    dispatch({ type: "loading", payload: { loading: true } });
    downloadReconciliationDashboard();
  }, [downloadReconciliationDashboard, dispatch]);

  const loadAddedDatasources = useRefCallback(
    (
      addedDatasources: ReconciliationDatasourceDef[],
      removedDatasources: ReconciliationDatasourceDef[]
    ) => {
      removedDatasources.forEach(({ reconId, datasource }) =>
        closeReport(reconId, datasource?.path)
      );
      loadDataSources(addedDatasources);
    },
    [loadDataSources]
  );

  React.useEffect(() => {
    downloadReconciliationDashboard();
  }, []);

  const value = React.useMemo(
    () => ({
      config,
      filterState,
      updateConfig,
      loadAddedDatasources,
      storeReconciliationDashboard,
      reloadReconciliationDashboard,
      changeFilterState,
      metadata,
      setMetadata: setMetadataBase,
      reportsLoadingStatus,
      setReportsLoadingStatus,
      loading,
    }),
    [
      config,
      filterState,
      updateConfig,
      loadAddedDatasources,
      storeReconciliationDashboard,
      reloadReconciliationDashboard,
      changeFilterState,
      metadata,
      setMetadataBase,
      reportsLoadingStatus,
      setReportsLoadingStatus,
      loading,
    ]
  );
  return (
    <ReconciliationContext.Provider value={value}>
      {children}
    </ReconciliationContext.Provider>
  );
};
