import { WidgetType } from "@enfusion-ui/dashboards";

import * as orderFormWidgets from "../../oems/OrderTicket/CustomTicket/widgets/definitions";
import * as displayWidgets from "./display/definitions";
import * as inputWidgets from "./input/definitions";
import { WidgetComponentsDefinition } from "./types";

export const WIDGET_COMPONENT_DEFINITIONS = {
  ...displayWidgets,
  ...inputWidgets,
  ...orderFormWidgets,
} as Record<WidgetType, WidgetComponentsDefinition>;
