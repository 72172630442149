import { RoundedDataGrid } from "@app-components/DataGrid";
import { TableLoader } from "@app-components/display/loader/TableLoader";
import { usePortfolio } from "@app-context/portfolios/PortfolioProvider";
import { useStoredGridColumnState } from "@app-utils/useStoredGridColumnState";
import ComplianceStateCellRenderer from "@app-views/oems/components/ComplianceStateCellRenderer";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  PortfolioProposedOrder,
  PortfolioProposedOrderEditFields,
  PROPOSED_ORDERS_SUBMIT_CONFIRMATION,
} from "@enfusion-ui/portfolios";
import { useConfirmationModal } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { faPencilAlt, faSave, faTasks } from "@fortawesome/pro-solid-svg-icons";
import {
  CellValueChangedEvent,
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridReadyEvent,
  RowNode,
  SelectionChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { get } from "lodash";
import * as React from "react";

import { complianceStateToolTipGetter } from "../utils";
import { DeskCellEditor } from "./cellRenderers/DeskCellEditor";
import DeskCellRenderer from "./cellRenderers/DeskCellRenderer";
import { NumericInputCellEditor } from "./cellRenderers/NumericInputCellEditor";
import { NumericInputCellRenderer } from "./cellRenderers/NumericInputCellRenderer";
import { PortfolioManagerCellEditor } from "./cellRenderers/PortfolioManagerCellEditor";
import PortfolioManagerCellRenderer from "./cellRenderers/PortfolioManagerCellRenderer";
import SideCellRenderer from "./cellRenderers/SideCellRenderer";
import { StrategyCellEditor } from "./cellRenderers/StrategyCellEditor";
import StrategyCellRenderer from "./cellRenderers/StrategyCellRenderer";
import { TextInputCellEditor } from "./cellRenderers/TextInputCellEditor";
import TextInputCellRenderer from "./cellRenderers/TextInputCellRenderer";
import { TIFCellEditor } from "./cellRenderers/TIFCellEditor";
import TIFCellRenderer from "./cellRenderers/TIFCellRenderer";
import { TraderCellEditor } from "./cellRenderers/TraderCellEditor";
import TraderCellRenderer from "./cellRenderers/TraderCellRenderer";
import { TypeCellEditor } from "./cellRenderers/TypeCellEditor";
import TypeCellRenderer from "./cellRenderers/TypeCellRenderer";
import { ComplianceStateTooltip } from "./ComplianceStateTooltip";
import { ContextItemEntry } from "./MainPortfolioGrid";
import { SizeGridWrapper } from "./SizeGridWrapper";
import {
  aggFuncs,
  gridOverlayTemplate,
  PROPOSED_ORDER_EDITABLE_FIELDS,
  proposedOrderColumnDefs,
} from "./utils";

const StyledGridWrapper = styled(RoundedDataGrid)`
  .ag-header-cell[col-id="selection"] {
    padding: 5px;
  }
  .ag-react-container {
    line-height: 22px !important;
  }
  .ag-row-selected {
    background-color: var(--info);
  }
  .ag-row-selected .ag-cell {
    background-color: var(--info) !important;
  }
  .ag-row-selected .ag-react-container input {
    background-color: var(--info-hover) !important;
  }
  .ag-row-selected .ag-react-container .column-select__control {
    background-color: var(--info-hover) !important;
  }
`;

const GridWrapper = styled(SizeGridWrapper)`
  min-height: 200px;
  height: 100%;
  width: 100%;
`;

const getRowNodeId = (row: GetRowIdParams<PortfolioProposedOrder>) =>
  row.data.id.toString();

type ProposedOrderGridProps = {
  openEditModal: VoidFunction;
};

const ProposedOrderGrid = ({ openEditModal }: ProposedOrderGridProps) => {
  const {
    setSelectedOrders,
    submitProposedOrders,
    testCompliance,
    registerHandlers,
    triggerInitProposedOrders,
    syncReceived,
    config: { root, filePath },
    updateProposedOrderData,
    isPortfolioAdjusting,
    canCreateOrders,
    formatProposedOrderRowGroupValue,
  } = usePortfolio();

  const ProposedOrdersConfirmation = useConfirmationModal({
    submitActionTheme: "primary",
    onSubmit: () => submitProposedOrders(),
    title: PROPOSED_ORDERS_SUBMIT_CONFIRMATION.title,
    submitButtonText: PROPOSED_ORDERS_SUBMIT_CONFIRMATION.buttonText,
    renderContent: () => PROPOSED_ORDERS_SUBMIT_CONFIRMATION.message,
  });

  const handleCellValueChanged = useRefCallback(
    (params: CellValueChangedEvent) => {
      const {
        data,
        colDef: { field },
        value,
        oldValue,
        newValue,
      } = params;
      if (
        oldValue === newValue ||
        ([undefined, null].includes(oldValue) &&
          [undefined, null].includes(newValue)) ||
        !PROPOSED_ORDER_EDITABLE_FIELDS.includes(field as string)
      )
        return;

      updateProposedOrderData(
        data.id,
        field as keyof PortfolioProposedOrderEditFields,
        value
      );
    },
    [updateProposedOrderData]
  );

  const getContextMenuItems = useRefCallback(
    (params: GetContextMenuItemsParams) => {
      const defaultItems = params?.defaultItems || ["export"];
      const entries = [
        ...defaultItems,
        "separator",
        {
          name: "Modify Selected",
          icon: icon(faPencilAlt).html?.[0],
          action: openEditModal,
          disabled: !params.api.getSelectedNodes().length,
        },
        canCreateOrders
          ? {
              name: "Test Compliance",
              icon: icon(faTasks).html?.[0],
              action: testCompliance,
            }
          : null,
        {
          name: "Create Orders",
          icon: icon(faSave).html?.[0],
          action: ProposedOrdersConfirmation.openModal,
        },
      ] as (ContextItemEntry | null)[];

      return entries.filter((i) => i !== null) as unknown as ContextItemEntry[];
    },
    [canCreateOrders]
  );

  const args = useStoredGridColumnState({
    storageKey: `e-portfolio-proposed-orders-grid-${root}${filePath}`,
    onGridReady: (event: GridReadyEvent) => {
      event.api.updateGridOptions({ rowData: [] });

      if (isPortfolioAdjusting)
        args.gridReadyEventRef.current?.api.showLoadingOverlay();

      registerHandlers({
        initProposedOrders: (orders) => {
          event.api.updateGridOptions({ rowData: orders });
        },
        updateProposedOrders: (updates) => {
          event.api.applyTransaction(updates);
        },
      });
      triggerInitProposedOrders();
    },
  });

  React.useEffect(() => {
    if (isPortfolioAdjusting) {
      args.gridReadyEventRef.current?.api.showLoadingOverlay();
    } else {
      args.gridReadyEventRef.current?.api.hideOverlay();
    }
  }, [isPortfolioAdjusting]);

  const changeSelectedOrders = useRefCallback(
    (event: SelectionChangedEvent) =>
      setSelectedOrders(event.api.getSelectedNodes() as RowNode[]),
    [setSelectedOrders]
  );

  return (
    <>
      <GridWrapper>
        {syncReceived ? (
          <StyledGridWrapper
            height="100%"
            suppressRowClickSelection
            suppressCellFocus
            rowSelection="multiple"
            multiSortKey="ctrl"
            rowGroupPanelShow="always"
            groupDefaultExpanded={-1}
            aggFuncs={aggFuncs}
            columnDefs={proposedOrderColumnDefs}
            defaultColDef={{
              cellStyle: { padding: "1px 4px" },
              resizable: true,
              sortable: true,
              filter: true,
              suppressKeyboardEvent: () => true,
              valueGetter: (params: ValueGetterParams) => {
                if (params.node?.group) return null;
                return get(params.data, params.colDef.field!);
              },
              valueFormatter: (params: ValueFormatterParams) => {
                if (params.value !== null && params.node?.group) {
                  return formatProposedOrderRowGroupValue(
                    params.colDef.field as string,
                    params.value
                  );
                }
                return params.value;
              },
            }}
            components={{
              typeCellEditor: TypeCellEditor,
              typeCellRenderer: TypeCellRenderer,
              sideCellRenderer: SideCellRenderer,
              numericInputCellEditor: NumericInputCellEditor,
              numericInputCellRenderer: NumericInputCellRenderer,
              textInputCellEditor: TextInputCellEditor,
              textInputCellRenderer: TextInputCellRenderer,
              strategyCellEditor: StrategyCellEditor,
              strategyCellRenderer: StrategyCellRenderer,
              pmCellEditor: PortfolioManagerCellEditor,
              pmCellRenderer: PortfolioManagerCellRenderer,
              deskCellEditor: DeskCellEditor,
              deskCellRenderer: DeskCellRenderer,
              traderCellEditor: TraderCellEditor,
              traderCellRenderer: TraderCellRenderer,
              tifCellEditor: TIFCellEditor,
              tifCellRenderer: TIFCellRenderer,
            }}
            columnTypes={{
              complianceState: {
                cellRenderer: ComplianceStateCellRenderer,
                cellRendererParams: {
                  defaultState: "PendingCheck",
                  allowGroup: true,
                },
                tooltipComponent: ComplianceStateTooltip,
                tooltipComponentParams: {
                  resultField: "complianceResult",
                },
                tooltipValueGetter: complianceStateToolTipGetter,
              },
            }}
            tooltipShowDelay={0}
            tooltipHideDelay={60000}
            gridName="proposedOrderGrid"
            onCellValueChanged={handleCellValueChanged}
            getContextMenuItems={getContextMenuItems}
            onSelectionChanged={changeSelectedOrders}
            getRowId={getRowNodeId}
            overlayLoadingTemplate={gridOverlayTemplate(isPortfolioAdjusting)}
            singleClickEdit
            maintainColumnOrder
            groupSelectsChildren
            groupSelectsFiltered
            suppressAggFuncInHeader
            groupDisplayType="singleColumn"
            {...args}
          />
        ) : (
          <TableLoader fillArea />
        )}
      </GridWrapper>
      {ProposedOrdersConfirmation.content}
    </>
  );
};

export default ProposedOrderGrid;
