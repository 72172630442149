import HighchartsBase from "highcharts";
import sunburstModule from "highcharts/modules/sunburst";

import { createCurrencyPointChart } from "./CurrencyPointChart";

sunburstModule(HighchartsBase);

export const SunburstChart = createCurrencyPointChart(
  HighchartsBase,
  {
    type: "sunburst",
    dataLabels: {
      format: "{point.name}",
      style: { color: "var(--text-normal)" },
      filter: {
        property: "innerArcLength",
        operator: ">",
        value: 16,
      },
    },
    levels: [
      {
        level: 1,
        levelIsConstant: false,
        dataLabels: {
          filter: {
            property: "outerArcLength",
            operator: ">",
            value: 64,
          },
        },
      },
      {
        level: 2,
        colorByPoint: true,
      },
      {
        level: 3,
        colorVariation: {
          key: "brightness",
          to: -0.5,
        },
      },
      {
        level: 4,
        colorVariation: {
          key: "brightness",
          to: 0.5,
        },
      },
    ],
  },
  {
    // Let the center circle be transparent
    colors: [
      "transparent",
      ...new Array(15)
        .fill("0")
        .map((_, idx) => `var(--dashboard-color-${idx})`),
      ...new Array(15)
        .fill("0")
        .map((_, idx) => `var(--dashboard-color-${idx})`),
    ],
  }
);
