import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import React from "react";
import { useWatch } from "react-hook-form";
export function useBulkEditProposedOrdersLogic(setValue, control, REST_API) {
    const { tradingDesk, limitPrice, stopPrice, orderType } = useWatch({
        name: ["tradingDesk", "limitPrice", "stopPrice", "orderType"],
        control,
    });
    React.useEffect(() => {
        if (limitPrice && stopPrice) {
            setValue("orderType", "StopLimit");
        }
        else if (limitPrice) {
            setValue("orderType", "Limit");
        }
        else if (stopPrice) {
            setValue("orderType", "Stop");
        }
    }, [limitPrice, stopPrice]);
    React.useEffect(() => {
        if (orderType !== "Limit" && orderType !== "StopLimit") {
            setValue("limitPrice", null);
        }
        if (orderType !== "Stop" && orderType !== "StopLimit") {
            setValue("stopPrice", null);
        }
    }, [orderType]);
    React.useEffect(() => {
        setValue("trader", null);
    }, [tradingDesk]);
    const fetchTraderOptions = React.useCallback((ac) => REST_API.SECURITY.GET_TRADERS.FETCH(tradingDesk, ac), [tradingDesk]);
    const { options: traderOptions, loading: traderLoading } = useRestAbortableOptions(typeof tradingDesk === "number" ? fetchTraderOptions : null, {
        map: (td) => ({ value: td.id, label: td.fullName }),
    });
    return {
        traderOptions,
        traderLoading,
    };
}
