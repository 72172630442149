import { PanelRow } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";

import { DateRangeSymbolSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledLedgerSelect } from "../../../components/ReportQueryComponents/LedgerSelect";

const InputContainer = styled.div`
  min-width: 230px;
`;

const DateRangeContainer = styled.div`
  min-width: 350px;
`;

const GLCashFlowStatementQuickFilter = () => {
  return (
    <PanelRow>
      <InputContainer>
        <ControlledLedgerSelect
          name="generalLedgerIds"
          label="General Ledger(s)"
        />
      </InputContainer>
      <DateRangeContainer>
        <DateRangeSymbolSelection name="period" label="Period" />
      </DateRangeContainer>
    </PanelRow>
  );
};

export default GLCashFlowStatementQuickFilter;
