import { ReportSettingsDefinition } from "../../types";
import InvestmentReportQuickFilter from "./InvestmentReportQuickFilter";
import InvestmentReportSettings from "./InvestmentReportSettings";

export const WebInvestmentReportQuery: ReportSettingsDefinition = {
  name: "InvestmentReport",
  queryType: "WebInvestmentReportQuery",
  quickFilterComponent: InvestmentReportQuickFilter,
  settingsComponent: InvestmentReportSettings,
};
