import { BoldText, Button, Modal } from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 1.25rem;
  gap: 1.25rem;
`;

const ContentContainer = styled.div`
  margin-bottom: 4px;
`;

const MessageText = styled.div`
  margin-top: 4px;
`;

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`;

export type AcceptAllCookiesModalProps = {
  open: boolean;
  onAccept: VoidFunction;
  onCustomize: VoidFunction;
  onCancel: VoidFunction;
};

const AcceptAllCookiesModal: React.FC<AcceptAllCookiesModalProps> = ({
  open,
  onAccept,
  onCustomize,
  onCancel,
}) => {
  return (
    <Modal
      isOpen={open}
      onClose={onCancel}
      title="Accept all cookies"
      maxWidth={450}
      zIndex={200}
      dataE2EId="accept-all-cookies-modal"
      content={
        <GridContainer>
          <ContentContainer>
            <BoldText>Your Policy</BoldText>
            <MessageText>
              By clicking &#34;Accept all cookies&#34;, you agree Enfusion can
              store cookies on your device and disclose information in
              accordance with our Cookie Policy.
            </MessageText>
          </ContentContainer>
          <ActionContainer>
            <Button theme="primary" onClick={onAccept}>
              Accept all cookies
            </Button>
            <Button theme="secondary" onClick={onCustomize}>
              Customize settings
            </Button>
          </ActionContainer>
        </GridContainer>
      }
    />
  );
};

export default AcceptAllCookiesModal;
