/* eslint-disable camelcase */
import { RoundedDataGrid } from "@app-components/DataGrid";
import { useInstrument } from "@enfusion-ui/core";
import {
  AppEvent,
  AppEventCategories,
  WebReportTableSync,
  WebReportTableSyncRow,
} from "@enfusion-ui/types";
import {
  AppLogging,
  createReportColumnDefs,
  errorToast,
  REST_API,
} from "@enfusion-ui/web-core";
import { ColDef, SelectionChangedEvent } from "ag-grid-community";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";

import PositionDetailSearch, {
  PositionSearchCriteria,
} from "./PositionDetailSearch";

export type PositionRowData = WebReportTableSyncRow & {
  __row_idx: string;
};

const PositionDetailsPanel: React.FC<{
  disabled: boolean;
  handleSelectedChange?: (e: SelectionChangedEvent) => void;
  showRelatedPositionsBtn?: boolean;
}> = ({ disabled, handleSelectedChange, showRelatedPositionsBtn }) => {
  const instrument = useInstrument();

  React.useEffect(() => {
    AppLogging.event({
      event: AppEvent.PositionDetailsOpened,
      category: AppEventCategories.OEMS,
    });
  }, []);

  const [{ positionDate, activeOnly }, setPositionSearchDetails] =
    React.useState<{
      positionDate: Date | null | string;
      activeOnly: boolean;
    }>({
      positionDate: "Today",
      activeOnly: true,
    });

  const [
    { positionDetailGridRows, positionGridColDefs },
    setPositionGridDetails,
  ] = React.useState<{
    positionDetailGridRows: PositionRowData[];
    positionGridColDefs: ColDef[];
  }>({
    positionDetailGridRows: [],
    positionGridColDefs: [],
  });

  React.useEffect(() => {
    const fetchPositions = async (instrumentId: number | undefined) => {
      setPositionGridDetails((existingValues) => ({
        ...existingValues,
        positionGridColDefs: [],
      }));

      const args: { dateSelectionType: string; asOfDate?: string } = {
        dateSelectionType: "Today",
      };
      if (positionDate instanceof Date) {
        args.dateSelectionType = "AsOfDate";
        args.asOfDate = positionDate.toISOString().substring(0, 10);
      } else if (typeof positionDate === "string") {
        args.dateSelectionType = positionDate;
      }

      try {
        const reportData: WebReportTableSync =
          await REST_API.OEMS.GET_OEMS_POSITIONS.FETCH(
            instrumentId ?? 0,
            activeOnly,
            args
          );

        const positionColDefs = createReportColumnDefs(
          reportData.tableMetadata
        );

        const positionRowData =
          reportData?.rows?.map(
            (i) => ({ ...i, __row_idx: uuidv4() } as PositionRowData)
          ) ?? [];

        setPositionGridDetails((existingValues) => ({
          ...existingValues,
          positionDetailGridRows: positionRowData,
          positionGridColDefs: positionColDefs,
        }));
      } catch (err) {
        console.log("Error fetching the positions", err);
        errorToast("Error fetching the positions");
      }
    };
    fetchPositions(instrument?.id);
  }, [instrument?.id, positionDate, activeOnly]);

  const handleSearchCriteriaChange = ({
    newPositionDate,
    newActiveOnly,
  }: PositionSearchCriteria) => {
    setPositionSearchDetails((existingValue) => ({
      ...existingValue,
      positionDate: newPositionDate,
      activeOnly: newActiveOnly,
    }));
  };

  return (
    <>
      <PositionDetailSearch
        disabled={disabled}
        onSearchChange={handleSearchCriteriaChange}
        activeOnly={activeOnly}
        positionDate={positionDate}
        showRelatedPositionsBtn={showRelatedPositionsBtn}
      />
      <RoundedDataGrid
        height="12.5rem"
        columnDefs={positionGridColDefs}
        columnTypes={{
          standardColumn: {},
          numberColumn: {},
        }}
        rowData={positionDetailGridRows}
        defaultColDef={{ sortable: true, filter: true, resizable: true }}
        rowSelection={handleSelectedChange ? "single" : undefined}
        onSelectionChanged={handleSelectedChange}
      />
    </>
  );
};

export default PositionDetailsPanel;
