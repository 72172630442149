import { RoundedDataGrid } from "@app-components/DataGrid";
import { useAccounts } from "@app-context/accounts/context";
import { useAllocations } from "@app-context/allocation/context";
import AllocationAccountSelect from "@app-views/oems/components/AllocationAccountSelect";
import BorrowAgreementSelect from "@app-views/oems/components/BorrowAgreementCellEditor";
import FrontOfficeHierarchySelect from "@app-views/oems/components/FrontOfficeHierarchySelect";
import TRSSelect from "@app-views/oems/components/TRSSelect";
import { applySwapCheck } from "@app-views/oems/utils/actions";
import {
  findAccountData,
  findNode,
} from "@app-views/oems/utils/searchTreeNode";
import {
  ALLOCATION_TYPE,
  convertFormValuesToOrder,
  getAllocationAndQtyFromInput,
  getAllocationAndQtyValues,
  OrderFormValues,
  useAllocationsControl,
} from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { AllocationEntryInfo, NodeData, OrderInfo } from "@enfusion-ui/types";
import { ConfirmationModal } from "@enfusion-ui/web-components";
import { errorToast, REST_API } from "@enfusion-ui/web-core";
import {
  CellValueChangedEvent,
  ColDef,
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  ValueSetterParams,
} from "ag-grid-community";
import { debounce, last, omit } from "lodash";
import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useClickAway } from "react-use";
import { v4 as uuidv4 } from "uuid";

export type AllocationsGridProps = {
  height?: string | number;
  quantity?: number | null;
  disabled: boolean;
} & GridOptions;

type AllocationEntryInfoGrid = AllocationEntryInfo & {
  rowId: string;
  book: string;
  fundName: string;
  custodianId: string;
  borrowAgreement: string;
  portfolioTotalReturnSwap: string;
  positionBlock: string;
  swapAccount: boolean;
};

const columnDefs: ColDef[] = [
  { field: "rowId", hide: true },
  { field: "fundName", headerName: "Fund" },
  {
    field: "accountName",
    headerName: "Account",
    editable: true,
    cellEditor: "allocationAccountSelect",
    valueSetter: (params: ValueSetterParams) => {
      if (params && params.newValue) {
        const newAccountName = params.newValue.name ? params.newValue.name : "";
        const newCustodianName = params.newValue.parentId
          ? params.newValue.parentId.substring(
              params.newValue.parentId.lastIndexOf("/") + 1
            )
          : "";
        params.data.accountName = newAccountName;
        params.data.custodian = newCustodianName;
        params.data.swapAccount = params.newValue.swapAccount ?? false;

        if (params.newValue.accountId) {
          params.data.accountId = params.newValue.accountId;
        }
      }

      return true;
    },
  },
  {
    field: "custodian",
    headerName: "Custodian",
  },
  {
    field: "book",
    editable: true,
    headerName: "Hierarchy",
    cellEditor: "frontOfficeHierarchySelect",
    cellEditorParams: (params: ValueSetterParams) => {
      return { value: params.data.bookId };
    },
    valueSetter: (params: ValueSetterParams) => {
      if (params && params.newValue) {
        params.data.book = params.newValue ? params.newValue.name : "";
        params.data.bookId = params.newValue ? params.newValue.bookId : null;
      }

      return true;
    },
  },
  {
    field: "allocationQuantityDisplay",
    headerName: "Allocation",
    editable: true,
    valueSetter: (params: ValueSetterParams) => {
      if (params && params.newValue) {
        params.data.allocationQuantityDisplay = params && params.newValue;
      }
      return true;
    },
  },
  { field: "qty", headerName: "Qty/Pct" },
  {
    field: "portfolioTotalReturnSwap",
    headerName: "TRS",
    editable: true,
    cellEditor: "TRSSelect",
    cellEditorParams: (params: ValueSetterParams) => {
      return { custodianId: params.data.custodianId };
    },
    valueSetter: (params: ValueSetterParams) => {
      if (params && params.newValue) {
        params.data.portfolioTotalReturnSwapId = params.newValue.value;
        params.data.portfolioTotalReturnSwap = params.newValue.label;
      }
      return true;
    },
  },
  { field: "dealId", headerName: "Deal Id", editable: true },
  { field: "positionBlock", headerName: "Position Block", editable: true },
  {
    field: "borrowAgreement",
    headerName: "Borrow Agreement",
    editable: true,
    cellEditor: "borrowAgreementSelect",
    cellEditorParams: (params: ValueSetterParams) => {
      return { custodianId: params.data.custodianId };
    },
    valueSetter: (params: ValueSetterParams) => {
      if (params && params.newValue) {
        params.data.borrowAgreementId = params.newValue.value;
        params.data.borrowAgreement = params.newValue.label;
      }
      return true;
    },
  },
];

function formatAllocationAndQty(value: number) {
  if (isNaN(value)) return 0;
  return Number.parseFloat(value.toFixed(4));
}

export type AllocationsGridAPI = {
  addNewRow: VoidFunction;
  removeSelectedRows: VoidFunction;
};

const AllocationsGrid = React.forwardRef<
  AllocationsGridAPI,
  AllocationsGridProps
>(({ height = "100%", quantity, disabled, ...rest }, ref) => {
  const gridApi = React.useRef<GridApi>();
  const { nodes, loading } = useAccounts();
  const { books, getTRSOptions, getBorrowAgreementOptions } = useAllocations();
  const { setValue, watch, getValues } = useFormContext();
  const { setSubmitErrors, newEntry } = useAllocationsControl();
  const containerRef = useRef(null);
  const canSwapChangeRef = useRef<boolean>(newEntry);
  const [openDeleteAllocationsModal, setOpenDeleteAllocationsModal] =
    React.useState<boolean>(false);
  const [optimizedQty, setOptimizedQty] = React.useState<number[]>([]);
  const { accountAllocation, instrument, type } = watch([
    "accountAllocation",
    "instrument",
    "type",
  ]);

  const getAllNodes = useRefCallback(() => {
    const allRows: AllocationEntryInfoGrid[] = [];
    gridApi?.current?.forEachNode((eachNode) =>
      allRows.push(eachNode.data as AllocationEntryInfoGrid)
    );
    return allRows;
  }, [gridApi.current]);

  const updateAllocationChanges = useRefCallback(() => {
    const allRows = getAllNodes();
    if (allRows) {
      const allocationEntries = allRows.map((eachRow) => {
        return omit(eachRow, [
          "rowId",
          "custodianId",
          "fundName",
          "book",
          "allocationQuantityDisplay",
          "accountName",
          "portfolioTotalReturnSwap",
          "borrowAgreement",
        ]);
      });
      canSwapChangeRef.current = true;
      setValue(
        "accountAllocation",
        {
          ...(accountAllocation ?? {}),
          allocationEntries,
        },
        { shouldDirty: true }
      );
    }
  }, [setValue, accountAllocation]);

  const getAllocationFillsQty = useRefCallback(() => {
    let newRowData: AllocationEntryInfoGrid[] = [];
    gridApi.current?.forEachNode((node) => newRowData.push(node.data));
    const qtySum = newRowData?.reduce(
      (accumulator: number, allocation: AllocationEntryInfoGrid) => {
        return accumulator + Number(allocation.allocationQuantity);
      },
      0
    );
    if (accountAllocation?.allocationNumberType === ALLOCATION_TYPE.absolute) {
      return (quantity ?? 0) - qtySum;
    }
    return 1 - qtySum;
  }, [accountAllocation]);

  const getOptimizedQty = useRefCallback(
    (
      quantity: number | null | undefined,
      allocationQuantity: number,
      index: number
    ) => {
      if (type === ALLOCATION_TYPE.absolute) {
        return allocationQuantity * 100;
      }
      const baseRes = allocationQuantity * (quantity || 0);
      return optimizedQty && optimizedQty?.length > 0
        ? optimizedQty[index] || baseRes
        : baseRes;
    },
    [type, optimizedQty]
  );

  const getNewEntry = useRefCallback(
    (idx: number, qtyOverride?: number) => {
      const allocationQuantity = qtyOverride ?? 0;
      const { allocationQuantityDisplay, qtyPct } = getAllocationAndQtyValues(
        (q: number, aq: number) => getOptimizedQty(q, aq, idx),
        allocationQuantity,
        quantity ?? 0,
        type,
        accountAllocation?.allocationNumberType
      );

      return {
        id: 0,
        // eslint-disable-next-line camelcase
        __row_key: `${idx}`,
        rowId: uuidv4(),
        accountId: null,
        bookId: null,
        portfolioTotalReturnSwapId: null,
        borrowAgreementId: null,
        dealId: null,
        fundName: null,
        fundId: null,
        accountName: null,
        custodian: "",
        custodianId: "",
        book: "",
        positionBlock: "",
        swapAccount: false,
        accountNode: undefined,
        portfolioTotalReturnSwap: undefined,
        borrowAgreement: undefined,
        allocationQuantity,
        allocationQuantityDisplay: formatAllocationAndQty(
          allocationQuantityDisplay
        ),
        qty: formatAllocationAndQty(qtyPct),
      };
    },
    [type, accountAllocation]
  );

  const addNewRow = useRefCallback(() => {
    const allCount = getAllNodes().length;
    gridApi?.current?.applyTransaction({
      add: [getNewEntry(allCount)],
    });
    requestAnimationFrame(() => {
      updateAllocationChanges();
    });
  }, []);

  const addFillsRow = useRefCallback(() => {
    const emptyEntry = last(getAllNodes());
    gridApi?.current?.applyTransaction({
      update: [
        {
          ...emptyEntry,
          allocationQuantity: getAllocationFillsQty(),
        },
      ],
    });
    requestAnimationFrame(() => {
      updateAllocationChanges();
    });
  }, []);

  React.useImperativeHandle(
    ref,
    () => ({
      addNewRow,
      removeSelectedRows: () => {
        setOpenDeleteAllocationsModal(true);
      },
    }),
    [updateAllocationChanges, gridApi, addNewRow]
  );

  const onGridReady = useRefCallback(({ api }: GridReadyEvent) => {
    gridApi.current = api;
  }, []);

  const getOptimizedAllocation = useRefCallback(
    debounce(async () => {
      let allocations = accountAllocation;

      if (
        accountAllocation?.allocationNumberType === ALLOCATION_TYPE.absolute
      ) {
        // When "Absolute" template, convert accountAllocation entry data to have ratio
        allocations = {
          ...accountAllocation,
          allocationEntries: accountAllocation.allocationEntries.map(
            (eachRowData: AllocationEntryInfoGrid) => ({
              ...eachRowData,
              allocationQuantity: quantity
                ? eachRowData.allocationQuantity / quantity
                : 0,
            })
          ),
        };
      }

      try {
        if (allocations !== null) {
          const optimizedAllocation =
            await REST_API.OEMS.GET_OPTIMIZED_ALLOCATION_QTY.FETCH({
              allocations,
              instrumentId: instrument?.id,
              totalQuantity: quantity || 0,
              optimizationType: accountAllocation?.allocationOptimizer,
            });

          setOptimizedQty(optimizedAllocation.map((i) => i.quantity));
        }
      } catch (err) {
        errorToast("Failed to optimize", (err as Error).message);
      }
    }, 300),
    [accountAllocation, quantity, instrument?.id]
  );

  React.useEffect(() => {
    if (quantity) getOptimizedAllocation();
  }, [quantity]);

  React.useEffect(() => {
    if (instrument?.id) {
      try {
        if (canSwapChangeRef.current) {
          (async () => {
            const orderData = await applySwapCheck(
              convertFormValuesToOrder({
                ...(getValues() as OrderFormValues),
                accountAllocation,
              }) as OrderInfo
            );
            if (orderData) setValue("swapOrder", orderData.swapOrder ?? false);
          })();
        }
        if (accountAllocation) getOptimizedAllocation();
      } catch (error) {
        const err = error as Error;
        if (err && typeof err === "object") {
          const messageList = err.message ? [err.message] : [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setSubmitErrors((err as any).errors ?? messageList);
        } else if (typeof err === "string") {
          setSubmitErrors([JSON.parse(err)?.message]);
        }
      }
    }
  }, [accountAllocation, instrument?.id]);

  React.useEffect(() => {
    const fetchTRSOptions = async (custodianNode: NodeData | undefined) => {
      if (custodianNode?.id) {
        return await getTRSOptions?.(Number.parseInt(custodianNode.id));
      }
    };
    const fetchBorrowAgreementOptions = async (
      custodianNode: NodeData | undefined
    ) => {
      if (custodianNode?.id) {
        return await getBorrowAgreementOptions?.(
          Number.parseInt(custodianNode.id)
        );
      }
    };
    const setAllocationGridData = () => {
      if (accountAllocation?.allocationEntries) {
        const newRowDataPromises = accountAllocation.allocationEntries?.map(
          async (eachRowData: AllocationEntryInfoGrid, idx: number) => {
            const nodeFamily = findAccountData(
              eachRowData.accountId ?? 0,
              nodes
            );

            const accountNode = nodeFamily?.find((i) => i.level === "Account");
            const custodianNode = nodeFamily?.find(
              (i) => i.level === "Custodian"
            );

            const fundNode = nodeFamily?.find((i) => i.level === "Fund");

            const bookNodes = findNode(
              eachRowData.bookId || 0,
              books,
              "bookId"
            );
            const bookInfo =
              bookNodes && bookNodes.length > 0 ? bookNodes[0] : null;
            const trsOptions = await fetchTRSOptions(custodianNode);
            const borrowAgreementOptions = await fetchBorrowAgreementOptions(
              custodianNode
            );

            const { allocationQuantityDisplay, qtyPct } =
              getAllocationAndQtyValues(
                (q: number, aq: number) => getOptimizedQty(q, aq, idx),
                eachRowData.allocationQuantity,
                quantity ?? 0,
                type,
                accountAllocation?.allocationNumberType
              );

            return {
              ...eachRowData,
              /* rowId is only for AgGrid to identify a row with unique id
               * On template changes, allocations are calculated on the fly and carry id = 0
               * Hence we cannot use id as a unique row identifier.
               * Due to absence of any other proper unique identifier, we generate our own for the Grid
               */
              rowId: uuidv4(),
              fundName: fundNode ? fundNode.shortName : null,
              fundId: fundNode ? fundNode.fundId : null,
              accountId: accountNode ? (accountNode.accountId as string) : null,
              accountName: accountNode ? (accountNode.name as string) : null,
              custodian: custodianNode ? custodianNode.name : "",
              custodianId: custodianNode ? custodianNode.id : "",
              bookId: bookInfo ? bookInfo.bookId : null,
              book: bookInfo ? bookInfo.name : "",
              accountNode: accountNode,
              portfolioTotalReturnSwap: trsOptions?.find(
                (option) =>
                  option.value === eachRowData.portfolioTotalReturnSwapId
              )?.label,
              borrowAgreement: borrowAgreementOptions?.find(
                (option) => option.value === eachRowData.borrowAgreementId
              )?.label,
              allocationQuantityDisplay: formatAllocationAndQty(
                allocationQuantityDisplay
              ),
              qty: formatAllocationAndQty(qtyPct),
            };
          }
        );
        Promise.all(newRowDataPromises).then((newRowData) => {
          let entries = [...newRowData];
          const lastEntry = last(entries);
          if (
            !disabled &&
            (!lastEntry ||
              lastEntry.allocationQuantityDisplay !== 0 ||
              lastEntry.qty !== 0)
          ) {
            entries.push(getNewEntry(entries.length, 0));
          }
          setTimeout(
            () => gridApi.current?.updateGridOptions({ rowData: entries }),
            250
          );
        });
      } else {
        setTimeout(
          () => gridApi.current?.updateGridOptions({ rowData: [] }),
          250
        );
      }
    };
    if (!loading) requestAnimationFrame(() => setAllocationGridData());
  }, [
    gridApi.current,
    accountAllocation?.allocationEntries,
    optimizedQty,
    type,
    quantity,
    disabled,
    loading,
  ]);

  const handleCellValueChanged = useRefCallback(
    (params: CellValueChangedEvent) => {
      switch (params && params.colDef.field) {
        case "allocationQuantityDisplay": {
          if (parseFloat(params.oldValue) !== parseFloat(params.newValue)) {
            const rowNodeId = params.data.rowId;
            const rowNode = gridApi.current?.getRowNode(rowNodeId);

            const { allocationQuantity, qtyPct } = getAllocationAndQtyFromInput(
              parseFloat(params.newValue),
              quantity ?? 0,
              type,
              accountAllocation?.allocationNumberType
            );

            if (rowNode) {
              const adds = [];
              const updatedNode = {
                ...rowNode?.data,
                allocationQuantity,
                qty: qtyPct,
              };
              gridApi.current?.applyTransaction({
                update: [updatedNode],
              });

              const allNodeCount = getAllNodes().length;
              if (
                params.rowIndex === allNodeCount - 1 &&
                (params.data.allocationQuantityDisplay !== 0 || qtyPct !== 0)
              ) {
                adds.push(getNewEntry(params.rowIndex));
              }

              gridApi.current?.applyTransaction({
                add: adds,
              });
              requestAnimationFrame(() => {
                updateAllocationChanges();
              });
            }
          }

          break;
        }
        case "accountName":
        case "dealId":
        case "portfolioTotalReturnSwap":
        case "borrowAgreement":
        case "book":
        case "positionBlock": {
          if (params.oldValue !== params.newValue) {
            updateAllocationChanges();
          }
          break;
        }
        default: {
          console.error("Field not found");
        }
      }
    },
    [type, quantity, accountAllocation]
  );

  const hoverNodeRef = React.useRef<IRowNode<any> | null>(null);
  const handleDeleteAllocations = useRefCallback(() => {
    if (!!hoverNodeRef.current)
      gridApi?.current?.applyTransaction({
        remove: [hoverNodeRef.current.data],
      });
    updateAllocationChanges();
    setOpenDeleteAllocationsModal(false);
  }, [accountAllocation]);

  useClickAway(containerRef, () => {
    const editingCol = gridApi.current?.getEditingCells()[0]?.column.getColId();
    if (editingCol !== "accountName" && editingCol !== "book") {
      gridApi.current?.stopEditing();
    }
  });

  const getContextMenuItems = useRefCallback(
    (params: GetContextMenuItemsParams) => {
      const result = [
        "copy",
        "copyWithHeaders",
        "separator",
        "export",
        "separator",
        {
          name: "Add Fills",
          disabled: getAllocationFillsQty() <= 0,
          action: addFillsRow,
        },
        {
          name: "Add Entry",
          disabled,
          action: addNewRow,
        },
        {
          name: "Remove Entry",
          disabled: disabled || !params.node,
          action: () => {
            hoverNodeRef.current = params.node;
            setOpenDeleteAllocationsModal(true);
          },
        },
      ];
      return result;
    },
    [disabled, addNewRow]
  );

  return (
    <>
      <RoundedDataGrid
        ref={containerRef}
        height={height}
        animateRows
        suppressCellFocus
        suppressRowClickSelection
        multiSortKey="ctrl"
        columnDefs={columnDefs}
        defaultColDef={{
          resizable: true,
          sortable: true,
          enableRowGroup: true,
          editable: disabled ? false : undefined,
          filter: true,
        }}
        components={{
          allocationAccountSelect: AllocationAccountSelect,
          borrowAgreementSelect: BorrowAgreementSelect,
          TRSSelect: TRSSelect,
          frontOfficeHierarchySelect: FrontOfficeHierarchySelect,
        }}
        stopEditingWhenCellsLoseFocus
        singleClickEdit
        onGridReady={onGridReady}
        onCellValueChanged={handleCellValueChanged}
        getContextMenuItems={getContextMenuItems}
        getRowId={(params: GetRowIdParams) => params.data?.rowId}
        {...rest}
      />
      <ConfirmationModal
        title="Delete Allocations"
        onSubmit={handleDeleteAllocations}
        submitActionTheme="danger"
        open={openDeleteAllocationsModal}
        onCancel={() => setOpenDeleteAllocationsModal(false)}
      >
        Are you sure you want to delete the selected allocations ?
      </ConfirmationModal>
    </>
  );
});

export default AllocationsGrid;
