import { getWatchListById } from "@app-context/watchList/WatchListProvider";
import { fetchInstrument } from "@app-views/oems/utils/actions";
import { useMounted } from "@enfusion-ui/hooks";
import { useLocalStorage } from "@enfusion-ui/hooks/build/web";
import {
  InstrumentInfo,
  WebWatchListInfo,
  WebWatchlistItemInfo,
} from "@enfusion-ui/types";
import { getNormalizedInstrumentInfo } from "@enfusion-ui/utils";
import {
  AlignRightRow,
  ConfirmationModal,
  IconButton,
  TabTopActionBar,
  ViewContainer as DefaultViewContainer,
} from "@enfusion-ui/web-components";
import {
  errorToast,
  styled,
  successToast,
  useAuth,
  useOEMS,
} from "@enfusion-ui/web-core";
import {
  faClipboardList,
  faMinusCircle,
  faPlus,
  faTable,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { pick } from "lodash";
import * as React from "react";

import OMSContentView from "../../components/display/OMSContentView";
import { useWatchList } from "../../context/watchList/context";
import InstrumentSelectorModal from "./components/InstrumentSelectorModal";
import WatchListBlotter from "./components/WatchListBlotter";
import WatchListItem from "./components/WatchListItem";

const ViewContainer = styled(DefaultViewContainer)`
  align-items: center;
  height: calc(100% - 28px);
`;

const WatchList = styled.div`
  padding: 0 var(--spacing);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: var(--spacing-l);
  row-gap: var(--spacing);
  overflow: auto;
`;

const WatchListView: React.FC<{ config: WebWatchListInfo }> = ({ config }) => {
  const { user } = useAuth();
  const { isInitialized } = useOEMS();
  const isMounted = useMounted();
  const [showInstrumentSelector, setShowInstrumentSelector] =
    React.useState<boolean>(false);

  const [instruments, setInstruments] = React.useState<Array<InstrumentInfo>>(
    []
  );
  const [itemToDelete, setItemToDelete] = React.useState<InstrumentInfo | null>(
    null
  );
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState<boolean>(false);

  const { saveWatchList, deleteWatchListItems, deleteWatchList } =
    useWatchList();

  const [watchListLayout, setWatchListLayout] = useLocalStorage(
    `watchList-layout-${config.id}`,
    "card"
  );

  const [gridLayoutOn, setGridLayoutOn] = React.useState<boolean>(false);

  const currentWatchListState = React.useRef<WebWatchListInfo>(config);

  React.useEffect(() => {
    const getInstrumentInfo = async () => {
      try {
        const watchlistInfo = await getWatchListById(config.id);
        currentWatchListState.current = watchlistInfo;
        const promiseList = watchlistInfo.items.map(
          async (item: WebWatchlistItemInfo) =>
            fetchInstrument(item.instrumentId)
        );
        const instrumentList = await Promise.all(promiseList);
        const filteredInstrument: InstrumentInfo[] = instrumentList.reduce<
          InstrumentInfo[]
        >((res, ins) => {
          if (ins !== null) {
            res.push(ins);
          }
          return res;
        }, []);
        setInstruments(filteredInstrument);
      } catch (err) {
        console.error("Error getting instrument info", err);
      }
    };
    setGridLayoutOn(() => (watchListLayout === "card" ? false : true));
    if (isMounted()) getInstrumentInfo();
  }, [config, isMounted]);

  React.useEffect(() => {
    gridLayoutOn ? setWatchListLayout("grid") : setWatchListLayout("card");
  }, [gridLayoutOn]);

  const addItem = async (instrument: InstrumentInfo | null) => {
    try {
      if (
        instrument &&
        !instruments.find((item) => item?.id === instrument?.id)
      ) {
        const newInstrumentItems = [
          pick(instrument, [
            "id",
            "description",
            "currency.code",
          ]) as InstrumentInfo,
          ...instruments,
        ];
        currentWatchListState.current = await saveWatchList({
          ...currentWatchListState.current,
          items: [
            {
              id: 0,
              instrumentId: instrument.id,
              listIndex: 0,
              notes: null,
            },
            ...currentWatchListState.current?.items,
          ],
        });
        setInstruments(newInstrumentItems);
        closeInstrumentSelectorModal();
      }
    } catch (err) {
      errorToast("Error occurred while saving watchlist item");
      console.error(`Error while saving watching item: ${err}`);
    }
  };

  const closeInstrumentSelectorModal = () => {
    setShowInstrumentSelector(false);
  };

  const handleDelete = (instrument: InstrumentInfo) => {
    setItemToDelete(instrument);
  };

  const deleteItem = async () => {
    try {
      const watchListItem = currentWatchListState.current?.items.find(
        (item) => item.instrumentId === itemToDelete?.id
      );
      if (watchListItem) {
        currentWatchListState.current = await deleteWatchListItems(config.id, [
          watchListItem.id,
        ]);
        setInstruments((prev) =>
          prev.filter((item) => item?.id !== itemToDelete?.id)
        );
      }
    } catch (err) {
      errorToast("Error occurred while deleting watchlist item");
      console.error(`Error while deleting watching item: ${err}`);
    } finally {
      setItemToDelete(null);
    }
  };

  const openDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteWatchlist = async () => {
    if (config) {
      const isSuccess = await deleteWatchList(config.id);
      if (isSuccess) {
        successToast("Watchlist deleted successfully");
      } else {
        errorToast("Error while deleting watchlist");
      }
      closeDeleteConfirmation();
    }
  };

  return (
    <>
      <TabTopActionBar>
        {isInitialized && !user?.flags?.OMS.readOnly && (
          <AlignRightRow flex>
            <IconButton
              hoverable
              title={
                gridLayoutOn ? "Switch to Card layout" : "Switch to Grid layout"
              }
              icon={gridLayoutOn ? faTable : faClipboardList}
              onClick={() => setGridLayoutOn((prev) => !prev)}
            />
            <IconButton
              data-e2e-id="add-instrument"
              hoverable
              title="Add Instrument"
              icon={showInstrumentSelector ? faMinusCircle : faPlus}
              onClick={() => setShowInstrumentSelector((prev) => !prev)}
            />
            <IconButton
              hoverable
              title="Delete Watchlist"
              icon={faTrash}
              onClick={openDeleteConfirmation}
            />
          </AlignRightRow>
        )}
      </TabTopActionBar>
      <ViewContainer>
        <OMSContentView>
          {gridLayoutOn ? (
            <WatchListBlotter instruments={instruments || []} />
          ) : (
            <WatchList>
              {instruments.map(
                (instrument, index) =>
                  instrument && (
                    <WatchListItem
                      key={`${index}`}
                      instrument={{
                        ...instrument,
                        ...getNormalizedInstrumentInfo(instrument),
                      }}
                      onDelete={handleDelete}
                    />
                  )
              )}
            </WatchList>
          )}
        </OMSContentView>
      </ViewContainer>
      <ConfirmationModal
        title="Stop Watching Instrument"
        cancelButtonText="Cancel"
        submitButtonText="Confirm"
        submitActionTheme="danger"
        open={!!itemToDelete}
        onSubmit={deleteItem}
        onCancel={() => setItemToDelete(null)}
      >
        Would you like to stop watching this instrument?
      </ConfirmationModal>
      <ConfirmationModal
        title="Delete Watchlist"
        onSubmit={handleDeleteWatchlist}
        submitActionTheme="danger"
        open={deleteConfirmationOpen}
        onCancel={closeDeleteConfirmation}
      >
        Are you sure you want to delete {config?.name}?
      </ConfirmationModal>
      <InstrumentSelectorModal
        open={showInstrumentSelector}
        onChange={addItem}
        onClose={closeInstrumentSelectorModal}
      />
    </>
  );
};

export default WatchListView;
