import { BlotterRow } from "@enfusion-ui/core";
import {
  initialOEMSQuotesContextState,
  OEMSQuotesContext,
  OEMSQuotesContextState,
  REST_API,
} from "@enfusion-ui/web-core";
import { useWorkerModule } from "@enfusion-ui/web-workers";
import * as React from "react";

const OEMSQuotesProvider: React.FC<
  React.PropsWithChildren<{
    orderId: number;
  }>
> = ({ children, orderId }) => {
  const [state, setState] = React.useState<OEMSQuotesContextState>(
    initialOEMSQuotesContextState
  );
  const { getCurrentState } = useWorkerModule("oms");

  React.useEffect(() => {
    getCurrentState()?.then(({ payload: { rows } }) => {
      setState((prev) => ({
        ...prev,
        order: rows.find((e: BlotterRow) => e.orderId === orderId),
      }));
    });
  }, [orderId]);

  React.useEffect(() => {
    async function fetchQuotesData(orderId: number) {
      setState((prev) => ({ ...prev, loading: true }));
      try {
        const response = await REST_API.OEMS.GET_QUOTES.FETCH(orderId);
        setState((prev) => ({ ...prev, quotes: response, loading: false }));
      } catch (error) {
        setState((prev) => ({
          ...prev,
          error: "Failed to fetch quotes for selected order",
          loading: false,
        }));
      }
    }
    if (orderId) fetchQuotesData(orderId);
  }, [orderId]);

  return (
    <OEMSQuotesContext.Provider value={state}>
      {children}
    </OEMSQuotesContext.Provider>
  );
};

export default OEMSQuotesProvider;
