import { PanelItemBase } from "@app-views/analytics/components/styledComponents";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { DollarTurnoverSummary } from "./DollarTurnoverSummary";
import { MetricsSummary } from "./Metrics/MetricsSummary";
import { NameTurnoverSummary } from "./NameTurnoverSummary";

const RowContainer = styled(PanelItemBase)`
  display: flex;
  gap: var(--spacing);
  padding: var(--spacing-l);
  height: 280px;
`;

const RowItem = styled.div`
  flex: 1;
`;

export const PortfolioMovementPanelTopRow: React.FC = () => {
  return (
    <RowContainer>
      <RowItem>
        <MetricsSummary />
      </RowItem>
      <RowItem>
        <DollarTurnoverSummary />
      </RowItem>
      <RowItem>
        <NameTurnoverSummary />
      </RowItem>
    </RowContainer>
  );
};
