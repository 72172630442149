import { ReportSettingsDefinition } from "../../types";
import GLCashActivityQuickFilter from "./GLCashActivityQuickFilter";
import GLCashActivitySettings from "./GLCashActivitySettings";

export const WebGLCashActivityQuery: ReportSettingsDefinition = {
  name: "GLCashActivity",
  queryType: "WebGLCashActivityQuery",
  quickFilterComponent: GLCashActivityQuickFilter,
  settingsComponent: GLCashActivitySettings,
};
