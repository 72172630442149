import { ChangeView } from "@app-views/analytics/components/ChangeView";
import { SizedChart } from "@app-views/analytics/components/SizedChart";
import { PanelItemBase } from "@app-views/analytics/components/styledComponents";
import { formatterCreator } from "@app-views/analytics/utils";
import { themes } from "@enfusion-ui/core";
import { formatNumber, hexToRgb } from "@enfusion-ui/utils";
import { styled, useTheme } from "@enfusion-ui/web-core";
import { format } from "date-fns";
import * as React from "react";

import { defaultChartOptions, defaultTooltipOptions } from "../../chartConfigs";

const Highcharts = styled(SizedChart)`
  padding-left: 15px;
`;

const Container = styled(PanelItemBase)`
  height: 90px;
  display: flex;
  flex-direction: column;
  padding: var(--spacing);
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
`;

const defaultOptions: Highcharts.Options = {
  chart: {
    ...defaultChartOptions,
    type: "areaspline",
    margin: 2,
  },
  title: { text: "" },
  credits: { enabled: false },
  xAxis: {
    type: "datetime",
    labels: { enabled: false },
    title: { text: null },
    startOnTick: false,
    endOnTick: false,
    lineWidth: 0,
  },
  yAxis: {
    endOnTick: false,
    startOnTick: false,
    labels: {
      enabled: true,
      style: { color: "var(--text-normal)" },
      x: -5,
    },
    title: { text: null },
    tickPositions: [0],
    gridLineColor: "var(--background-accent)",
  },
  legend: { enabled: false },
  tooltip: {
    ...defaultTooltipOptions,
    outside: true,
    shared: true,
    formatter: formatterCreator(({ y, x }) => {
      return `<div style="display: flex; flex-direction: row; gap: var(--spacing)">
      <div style="color: var(--text-normal)">${format(
        new Date(x),
        "MM/dd"
      )}:</div>
      <div style="color: var(--${y > 0 ? "success" : "danger"})">${formatNumber(
        y,
        {
          reduce: true,
          fractionDigits: [0, 2],
          formatNegative: false,
        }
      )}</div>
      </div>`;
    }),
  },
  plotOptions: {
    series: {
      lineWidth: 1,
      shadow: false,
      states: { hover: { lineWidth: 1 } },
      marker: { radius: 1 },
      threshold: null,
    },
  },
};

export const SparkLineBlock: React.FC<{
  label: string;
  value: number;
  change?: number | null;
  data?: number[] | [number, number][];
  currency?: string | null;
}> = ({ label, value, change, currency, data }) => {
  const { theme } = useTheme();
  const color = React.useMemo(
    () => (theme ?? themes.dark).colors[value > 0 ? "success" : "danger"],
    [value, theme.colors]
  );

  const getOptions = React.useCallback(
    (width: number, _height: number) => {
      return {
        ...defaultOptions,
        chart: {
          ...defaultOptions.chart,
          width: Number((width || 1).toFixed(1)) - 15,
          height: 63,
          zooming: { type: "x" },
        },
        series: [{ data, color }],
        plotOptions: {
          ...defaultOptions.plotOptions,
          areaspline: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0.8,
              },
              stops: [
                [0, hexToRgb(color, 0.8)],
                [1, hexToRgb(color, 0)],
              ],
            },
          },
        },
      };
    },
    [defaultOptions, data, color]
  );

  return (
    <Container>
      <TopRow>
        <Label>{label}</Label>
        <ChangeView value={value} change={change} currency={currency} />
      </TopRow>
      <div style={{ maxWidth: "100%", height: 63 }}>
        <Highcharts
          getOptions={getOptions}
          zoomResetButtonPosition={{ bottom: "0px", left: "0px" }}
        />
      </div>
    </Container>
  );
};
