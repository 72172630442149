import * as React from "react";
import { ResizeCallbackData } from "react-resizable";

import { ContentSummaryDrawerContainer } from "./components/ContentSummaryDrawerContainer";
import { ContentSummaryPanelInstrument } from "./components/ContentSummaryPanelInstrument";
import { ContentSummaryPanelPayment } from "./components/ContentSummaryPanelPayment";
import { ContentSummaryResizableContainer } from "./components/ContentSummaryResizableContainer";

type TradeTicketContentSummaryProps = {
  showDrawer: boolean;
  onResizeCallBack: (e: React.SyntheticEvent, data: ResizeCallbackData) => void;
};

export const TradeTicketContentSummary: React.FC<
  TradeTicketContentSummaryProps
> = ({ showDrawer, onResizeCallBack }) => {
  const ContentSummaryContainer = showDrawer
    ? ContentSummaryDrawerContainer
    : ContentSummaryResizableContainer;

  return (
    <ContentSummaryContainer onResizeCallBack={onResizeCallBack}>
      <ContentSummaryPanelInstrument />
      <ContentSummaryPanelPayment />
    </ContentSummaryContainer>
  );
};
