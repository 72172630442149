import { AccountingMethodOptions } from "@enfusion-ui/types";
import {
  ControlledCheckbox,
  ControlledSelect,
  PanelRow,
} from "@enfusion-ui/web-components";

import { DateRangeSymbolSelection } from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledLedgerSelect } from "../../../components/ReportQueryComponents/LedgerSelect";

const GLTrialBalanceSettings = () => {
  return (
    <>
      <ControlledLedgerSelect
        name="generalLedgerIds"
        label="General Ledger(s)"
      />
      <DateRangeSymbolSelection name="period" label="Period" />
      <PanelRow>
        <ControlledSelect
          name="accountingMethod"
          label="Accounting Method"
          options={AccountingMethodOptions}
          clearable={false}
        />
        <ControlledCheckbox
          name="includeAllActivity"
          label="Include All Activity"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </PanelRow>
    </>
  );
};

export default GLTrialBalanceSettings;
