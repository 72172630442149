import { useBroadcastChannel } from "@app-context/broadcastChannels/context";
import { BlotterRow } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import {
  initialOEMSRoutesContextState,
  OEMS_EVENTS,
  OemsBroadcastEvent,
  OEMSRoutesContext,
  OEMSRoutesContextState,
} from "@enfusion-ui/web-core";
import { useWorkerModule } from "@enfusion-ui/web-workers";
import * as React from "react";

const OEMSRoutesProvider: React.FC<
  React.PropsWithChildren<{ orderId: number }>
> = ({ children, orderId: orderIdBase }) => {
  const [routes, setRoutes] = React.useState<OEMSRoutesContextState>(
    initialOEMSRoutesContextState
  );
  const [loading, setLoading] = React.useState(false);
  const { postMessage } = useWorkerModule("oms");
  const orderIdRef = React.useRef<number>(orderIdBase);

  const fetchRoutes = useRefCallback(async (orderId: number) => {
    setLoading(true);
    try {
      const res = postMessage({
        command: "routes",
        payload: { orderId },
      });
      if (res) {
        const {
          payload: { rows },
        } = await res;
        setRoutes((state) => ({
          ...state,
          orderId,
          routes: rows as BlotterRow[],
        }));
      }
    } catch (error) {
      // todo
    } finally {
      setLoading(false);
    }
  }, []);

  useBroadcastChannel<OemsBroadcastEvent>(OEMS_EVENTS, (ev) => {
    if (
      ev.action === "update-routes-for-order-id" &&
      ev.payload.orderId === orderIdRef.current
    ) {
      fetchRoutes(orderIdRef.current);
    }
  });

  React.useEffect(() => {
    orderIdRef.current = orderIdBase;
    fetchRoutes(orderIdBase);
  }, [orderIdBase]);

  const refetchRoutes = useRefCallback(
    () => fetchRoutes(orderIdRef.current),
    []
  );

  return (
    <OEMSRoutesContext.Provider value={{ ...routes, refetchRoutes, loading }}>
      {children}
    </OEMSRoutesContext.Provider>
  );
};

export default OEMSRoutesProvider;
