import { EMPTY_RECORD } from "@enfusion-ui/core";
import {
  DateWidgetConfig,
  useChannelDataValues,
  useWidget,
} from "@enfusion-ui/dashboards";
import { ChannelDataType } from "@enfusion-ui/types";
import { CenterContent } from "@enfusion-ui/web-components";
import * as React from "react";

import DateWidgetInput from "./DateWidgetInput";

const DateWidget: React.FC = () => {
  const { config, setChannelData, addChannelKeys } = useWidget();

  const {
    defaultValue,
    key,
    inputType = "preset",
    paramChannelIds = EMPTY_RECORD,
    paramStaticValues = EMPTY_RECORD,
    noLatest,
  } = config as DateWidgetConfig;

  const paramChannelData = useChannelDataValues(paramChannelIds);
  const params = React.useMemo(() => {
    return { ...paramChannelData, ...paramStaticValues };
  }, [JSON.stringify(paramChannelData), JSON.stringify(paramStaticValues)]);

  React.useEffect(() => {
    const options = [
      {
        name: `${key} - Start Pure Date`,
        description: "Start Pure Date Value",
        type: ChannelDataType.Date,
      },
      {
        name: `${key} - End Pure Date`,
        description: "End Pure Date Value",
        type: ChannelDataType.Date,
      },
      {
        name: `${key} - Start Selection Date`,
        description: "Start Selection Date Value",
        type: ChannelDataType.DateSelection,
      },
      {
        name: `${key} - End Selection Date`,
        description: "End Selection Date Value",
        type: ChannelDataType.DateSelection,
      },
      {
        name: `${key} - Date Range`,
        description: "Date Range Value",
        type: ChannelDataType.DateRange,
      },
      {
        name: `${key} - Selection Date No Latest`,
        description: "Selection Date Value - replaces Latest with Today",
        type: ChannelDataType.DateSelection,
      },
    ];
    if (inputType === "symbol-range") {
      options.push({
        name: `${key} - Date Symbol Range`,
        description: "Date Symbol Range Value",
        type: ChannelDataType.DateSymbolRange,
      });
    }
    if (inputType === "period") {
      options.push({
        name: `${key} - Period Selection`,
        description: "Period Selection Value",
        type: ChannelDataType.DatePeriodSelection,
      });
    }
    addChannelKeys(options);
  }, [key, inputType]);

  return (
    <CenterContent>
      <DateWidgetInput
        label={key}
        defaultValue={defaultValue}
        inputType={inputType}
        onChange={setChannelData}
        before={params.before}
        after={params.after}
        noLatest={noLatest}
      />
    </CenterContent>
  );
};

export default DateWidget;
