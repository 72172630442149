import { RoundedDataGrid } from "@app-components/DataGrid";
import { DiffReportSyncRow, WebColumnPair } from "@enfusion-ui/types";
import { ColDef } from "ag-grid-community";
import React from "react";

import { breakDetailsModalColumns } from "../utils/colDefs";
import { BREAK_DETAILS_MODAL_COLUMN_TYPES } from "../utils/constants";
import { BreakDetailsModalContainer } from "./styles";

const getRowData = (
  colDefs: ColDef[],
  data: DiffReportSyncRow | null,
  diffColumnPairs: Array<WebColumnPair> | undefined
) => {
  let rowData = colDefs
    .filter(({ hide }) => !hide)
    .map(({ colId, headerName }) => {
      if (!colId) return null;
      const values = data?.columnValues?.[colId];
      const isDiff =
        diffColumnPairs?.some((col) => col.reconColumnId === colId) &&
        data?.columnValues?.breakType.value === "Difference";
      return { isDiff, description: headerName, values };
    });
  return rowData;
};

export const BreakDetailsModalContent: React.FC<{
  data: DiffReportSyncRow | null;
  colDefs: ColDef[];
  diffColumnPairs?: Array<WebColumnPair>;
}> = ({ data, colDefs, diffColumnPairs }) => {
  return (
    <BreakDetailsModalContainer>
      <RoundedDataGrid
        columnTypes={BREAK_DETAILS_MODAL_COLUMN_TYPES}
        columnDefs={breakDetailsModalColumns(
          data?.columnValues?.["target(custodianName)"]?.value as string
        )}
        autoSizeStrategy={{
          type: "fitGridWidth",
        }}
        height="100%"
        width="100%"
        rowData={getRowData(colDefs, data, diffColumnPairs)}
        getRowClass={({ data }) =>
          data?.isDiff &&
          data?.values?.sourceValue !== data?.values?.targetValue
            ? "diff-columns"
            : ""
        }
      />
    </BreakDetailsModalContainer>
  );
};
