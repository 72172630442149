import { styled } from "@enfusion-ui/web-core";
import { motion } from "framer-motion";

export const AdvancedSearchBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdvancedSearchContainer = styled(motion.div)<{
  isMobile: boolean;
  hideOptions?: boolean;
}>`
  width: min(40rem, 100vw);
  box-shadow: 0px 3px 8px 1px var(--overlay);
  background-color: var(--background-color-0);
  border: 1px solid var(--input-border);
  border-radius: 3px;
  padding: 8px;
  height: ${({ isMobile, hideOptions }) =>
    hideOptions ? "271px" : isMobile ? "604px" : "471px"};
`;
