import ControlledAccountSelect from "@app-components/inputs/account/ControlledAccountSelect";
import InstrumentMultiSelect from "@app-components/inputs/InstrumentSelect/InstrumentMultiSelect";
import {
  DATE_ADJUSTMENT_OPTIONS,
  IN_GRID_SHOCK_EFFECT_OPTIONS,
  InstrumentInfo,
  POSITION_REQUEST_TYPE_OPTIONS,
  WebReportBookSelection,
} from "@enfusion-ui/types";
import {
  ControlledCheckbox,
  ControlledInputBase,
  ControlledNumericInput,
  ControlledSelect,
  ControlledTextInput,
  PanelRow,
  Select,
  TextInput,
} from "@enfusion-ui/web-components";
import { styled, useAuth } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

import ControlledBookSelect from "../../../components/ReportQueryComponents/BookSelect";
import {
  DatePeriodSelection,
  DateRangeSelection,
} from "../../../components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledFinancialSubTypeSelect } from "../../../components/ReportQueryComponents/FinancialSubTypeSelect";
import MarginFundSelect from "../../../components/ReportQueryComponents/MarginFundSelect";
import { ControlledMarketEnvironmentSelect } from "../../../components/ReportQueryComponents/MarketEnvironmentSelect";
import { ControlledPortfolioGroupsSelect } from "../../../components/ReportQueryComponents/PortfolioGroupsSelect";
import { ControlledTradeBookingStatusSelect } from "../../../components/ReportQueryComponents/TradeBookingStatusSelect";
import { ControlledTradeTypeSelect } from "../../../components/ReportQueryComponents/TradeTypeSelect";
import { ControlledLotTypeSelect } from "./LotTypeSelect";

const StyledNumericInput = styled(ControlledNumericInput)`
  width: 50%;
  position: relative;
  bottom: var(--spacing-xl);
`;

const StyledText = styled.div`
  font-size: 0.75em;
  line-height: 1em;
  font-weight: 500;
  align-self: center;
`;

const StyledNumeric = styled.div`
  width: 15%;
`;

const FilterTypeOptions = [
  { label: "None", value: "None" },
  { label: "Inactive Days", value: "InactiveDays" },
];

const PositionEventSettings = () => {
  const { isAdmin } = useAuth();
  const [filterType, setFilterType] = React.useState<string | undefined>(
    "None"
  );
  const { bookSelection } = useWatch({
    name: ["bookSelection"],
  });

  const defaultBookSelectionRef = React.useRef(
    bookSelection as WebReportBookSelection
  );

  return (
    <>
      <ControlledAccountSelect label="Account(s)" />
      <ControlledPortfolioGroupsSelect />
      <ControlledBookSelect
        defaultBookSelection={defaultBookSelectionRef.current}
        showIgnoreHierarchyCheckBox
      />
      <ControlledInputBase
        name="instrumentIds"
        render={({ ref: _ref, onChange, ...rest }) => (
          <InstrumentMultiSelect
            {...rest}
            label="Instrument(s)"
            onChange={(instruments: InstrumentInfo[] | null) => {
              onChange(instruments?.map((instrument) => instrument.id));
            }}
          />
        )}
      />
      <PanelRow>
        <ControlledFinancialSubTypeSelect
          name="financialSubTypes"
          selectProps={{ label: "Instrument type" }}
        />
        <ControlledCheckbox
          name="includeUnderlyings"
          label="Include Underlyings"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </PanelRow>
      <PanelRow>
        <DatePeriodSelection name="datePeriodSelection" label="Value Date" />
        <ControlledMarketEnvironmentSelect />
      </PanelRow>
      <PanelRow>
        <MarginFundSelect />
        <ControlledSelect
          name="positionRequestType"
          options={POSITION_REQUEST_TYPE_OPTIONS}
          label="Positions to include"
        />
      </PanelRow>
      <PanelRow>
        <ControlledSelect
          name="inGridShockEffect"
          options={IN_GRID_SHOCK_EFFECT_OPTIONS}
          label="In-Grid Shock Behavior"
        />
        <ControlledSelect
          name="dateAdjustment"
          options={DATE_ADJUSTMENT_OPTIONS}
          label="Business Date Adjustment"
        />
      </PanelRow>
      <PanelRow>
        {/* need api endpoint to get options */}
        {isAdmin() && (
          <ControlledSelect
            name="scenarioSetId"
            label="Scenario Sets"
            options={[]}
          />
        )}
        <ControlledTradeTypeSelect
          name="ignoredTradeTypes"
          label="Ignored Trade Types"
        />
      </PanelRow>
      <PanelRow>
        <ControlledTradeBookingStatusSelect />
        <ControlledCheckbox
          name="includeExpiredFunds"
          label="Include Inactive Funds/Accounts"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </PanelRow>
      <PanelRow>
        <TextInput label="Holding Id" disabled />
        <ControlledTextInput label="Deal Id" name="dealId" />
        <ControlledTextInput label="Position Block" name="positionBlock" />
      </PanelRow>
      <Select
        label="Filter Type"
        options={FilterTypeOptions}
        onChange={(val) => setFilterType(val?.value)}
      />
      {filterType === "InactiveDays" && (
        <PanelRow>
          <StyledText>Ignore Positions Inactive for</StyledText>
          <StyledNumeric>
            <StyledNumericInput
              name="daysBeforeInactive"
              hideControls
              defaultValue={0}
            />
          </StyledNumeric>
          <StyledText>days since start of value date year.</StyledText>
        </PanelRow>
      )}
      <DateRangeSelection
        name="tradeDateRange"
        startDateLabel="Trade Date (From)"
        endDateLabel="To"
      />
      <DateRangeSelection
        name="lotUnwindDateRange"
        startDateLabel="Lot Unwind Date (From)"
        endDateLabel="To"
      />
      <PanelRow>
        <ControlledLotTypeSelect />
        <ControlledCheckbox
          name="activeLotsOnly"
          label="Active Lots Only"
          labelPlacement="right"
          nullValue={false}
          style={{ alignSelf: "flex-end" }}
        />
      </PanelRow>
    </>
  );
};

export default PositionEventSettings;
