import { FormPanel } from "@enfusion-ui/web-components";
import * as React from "react";

import { HorizontalRule } from "../../../components/styledComponents";
import { RowOne } from "./RowOne";
import { RowTwo } from "./RowTwo";

export const TradeDetailsPanel: React.FC<unknown> = () => {
  return (
    <FormPanel
      title="Basic Trade Details"
      numColumns={1}
      collapsible
      defaultOpen
      keepRendered
      defaultRendered
      lineStyle
      tabFocusOutline={false}
    >
      <RowOne />
      <HorizontalRule />
      <RowTwo />
    </FormPanel>
  );
};
