import {
  CalculationMethodSelectionWidgetConfig,
  useWidget,
} from "@enfusion-ui/dashboards";
import { SelectOptionsType } from "@enfusion-ui/types";
import { FormSectionAccordion, TextInput } from "@enfusion-ui/web-components";
import * as React from "react";

import { EditContainer } from "../../../styles";
import { CalculationMethodSelect } from "./CalculationMethodSelect";

const CalculationMethodSelectionEditWidget: React.FC<unknown> = () => {
  const { editedConfig, changeConfigKeyToBeApplied } = useWidget();

  const handleTextChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeConfigKeyToBeApplied(key, e.target.value);
    };

  const handleBasicChange =
    (key: string) => (option: SelectOptionsType<string> | null) => {
      changeConfigKeyToBeApplied(key, option?.value);
    };

  const { defaultValue = null, key = "Calculation Method" } =
    editedConfig as CalculationMethodSelectionWidgetConfig;

  return (
    <EditContainer>
      <FormSectionAccordion title="Calculation Method Options">
        <TextInput
          label="Key"
          value={key}
          name="key"
          onChange={handleTextChange("key")}
        />
        <CalculationMethodSelect
          value={defaultValue}
          label="Default Calculation Method"
          onChange={handleBasicChange("defaultValue")}
        />
      </FormSectionAccordion>
    </EditContainer>
  );
};

export default CalculationMethodSelectionEditWidget;
