import { useSelectDataValue } from "@app-views/dashboards/pdf/utils";
import { DeskSelectionWidgetConfig, useWidget } from "@enfusion-ui/dashboards";
import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { ChannelDataType } from "@enfusion-ui/types";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";

import PdfSelectionWidget from "../PdfSelectionWidget";

const PdfDeskSelectionWidget: React.FC<unknown> = () => {
  const { config } = useWidget();
  const { defaultValue, key } = config as DeskSelectionWidgetConfig;
  const [inputValue, setInputValue] = React.useState<string | null>(null);

  useSelectDataValue(key, "Desk IDs", ChannelDataType.Number, defaultValue);

  const { options: tradingDeskOptions } = useRestAbortableOptions(
    REST_API.SECURITY.GET_TRADING_DESKS.FETCH,
    (td) => ({ value: td.id, label: td.groupName })
  );

  React.useEffect(() => {
    if (defaultValue) {
      const selectedValues = defaultValue
        .map((value) => {
          const selectedValue = tradingDeskOptions.find(
            ({ value: val }) => val === value
          );
          return selectedValue?.label;
        })
        .filter((value) => value);

      const formattedValue = selectedValues.join(", ");
      setInputValue(formattedValue);
    }
  }, [tradingDeskOptions, defaultValue]);

  return (
    <PdfSelectionWidget
      label={key}
      value={inputValue}
      placeholder="All Desks"
    />
  );
};

export default PdfDeskSelectionWidget;
