import {
  FundBenchmarkTypeOptions,
  SelectOptionsType,
} from "@enfusion-ui/types";
import { Select } from "@enfusion-ui/web-components";
import * as React from "react";

export const FundBenchmarkTypeSelect: React.FC<{
  value: string | null;
  onChange: (option: SelectOptionsType<string> | null) => void;
  label?: string;
}> = ({ value, onChange, label = "Fund Type" }) => {
  return (
    <Select
      label={label}
      value={FundBenchmarkTypeOptions.find(({ value: val }) => value === val)}
      options={FundBenchmarkTypeOptions}
      onChange={onChange}
      inputId="fund-benchmark-type-selection-id"
    />
  );
};
