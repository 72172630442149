import InstrumentMultiSelect from "@app-components/inputs/InstrumentSelect/InstrumentMultiSelect";
import { ControlledAssetMeasureSelect } from "@app-views/reports/components/ReportQueryComponents/AssetMeasureSelect";
import { InstrumentInfo } from "@enfusion-ui/types";
import { ControlledInputBase, PanelRow } from "@enfusion-ui/web-components";
import * as React from "react";

import { DateSelection } from "../../../../reports/components/ReportQueryComponents/Date/ControlledDateSelection";
import { ControlledQuoteSetIdsSelect } from "../../../../reports/components/ReportQueryComponents/QuoteSetIdsSelect";
import { ControlledSourceIdsSelect } from "../../../../reports/components/ReportQueryComponents/SourceIdsSelect";

const MarketRatesSettings = () => {
  return (
    <>
      <ControlledInputBase
        name="instrumentIds"
        render={({ ref: _ref, onChange, ...rest }) => (
          <InstrumentMultiSelect
            {...rest}
            label="Instrument(s)"
            onChange={(instruments: InstrumentInfo[] | null) => {
              onChange(instruments?.map((instrument) => instrument.id));
            }}
          />
        )}
      />
      <ControlledAssetMeasureSelect />
      <PanelRow>
        <DateSelection
          name="fromDate"
          label="From Date"
          defaultValue={undefined}
        />

        <ControlledSourceIdsSelect />
      </PanelRow>

      <PanelRow>
        <DateSelection name="toDate" label="To Date" defaultValue={undefined} />
        <ControlledQuoteSetIdsSelect />
      </PanelRow>
    </>
  );
};

export default MarketRatesSettings;
