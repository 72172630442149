import {
  TileWidgetStyles,
  TitleHeight,
  useWidget,
} from "@enfusion-ui/dashboards";
import { WebReportTableSyncColumn } from "@enfusion-ui/types";
import { formatReportValue } from "@enfusion-ui/utils";
import { css, styled } from "@enfusion-ui/web-core";
import * as React from "react";

import { WidgetContentContainer } from "../../../widget/components/WidgetContentContainer";

export type TileProps = {
  header: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  column: WebReportTableSyncColumn | null;
  bordered?: boolean;
  borderColors?: [string, string];
};

const TileContainer = styled(WidgetContentContainer)<{
  styleOptions: TileWidgetStyles;
  isNegative: boolean;
}>`
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-1);
  flex: 1;
  min-width: 86px;
  min-height: 57px;
  ${({ styleOptions, isNegative }) =>
    styleOptions.showBorder
      ? css`
          border-left: 1.5px solid
            ${isNegative
              ? styleOptions.negativeBorderColor ?? styleOptions.borderColor
              : styleOptions.borderColor};
        `
      : css`
          border: none;
        `};
`;

const ValueContainer = styled.div<{
  styleOptions: TileWidgetStyles;
  isNegative: boolean;
}>`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 0px var(--spacing-l);
  ${({ styleOptions }) => css`
    align-items: ${styleOptions.alignValue};
    justify-content: ${styleOptions.justifyValue};
    font-size: ${styleOptions.valueFontSize};
    font-weight: ${styleOptions.valueFontWeight};
  `};
  color: ${({ styleOptions, isNegative }) =>
    isNegative ? styleOptions.negativeValueColor : styleOptions.valueColor};
`;

const ValueText = styled.div`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const HeaderContainer = styled.div<{
  styleOptions: TileWidgetStyles;
  isNegative: boolean;
  titleHeight: TitleHeight;
  showBackground: boolean;
}>`
  display: flex;
  padding: 0px var(--spacing-l);

  ${({ titleHeight }) => {
    if (titleHeight === "half") {
      return css`
        flex: 1;
      `;
    } else if (titleHeight === "none") {
      return css`
        display: none;
      `;
    }
    return css`
      padding: var(--spacing) var(--spacing-l);
    `;
  }}

  width: 100%;
  align-items: center;
  ${({ styleOptions }) => css`
    color: ${styleOptions.titleColor};
    align-items: ${styleOptions.alignTitle};
    justify-content: ${styleOptions.justifyTitle};
    font-size: ${styleOptions.titleFontSize};
    font-weight: ${styleOptions.titleFontWeight};
  `};

  color: ${({ styleOptions, isNegative }) =>
    isNegative ? styleOptions.negativeTitleColor : styleOptions.titleColor};

  background-color: ${({ showBackground }) =>
    showBackground ? "var(--background-color-1-hover)" : "transparent"};
`;

const Tile: React.FC<TileProps> = ({ header, value: data, column }) => {
  const { config } = useWidget();

  const formattedValue = React.useMemo(
    () =>
      column
        ? formatReportValue(data, column, {
            numberReductionFormat: config?.numberReductionFormat,
          })
        : "",
    [data, column, config.numberReductionFormat]
  );

  const {
    showBorder = true,
    borderColor = "var(--background-accent)",
    negativeBorderColor = "var(--background-accent)",
    titleColor = "var(--primary)",
    negativeTitleColor = "var(--primary)",
    justifyTitle = "flex-start",
    alignTitle = "center",
    titleFontSize = "medium",
    alignValue = "center",
    justifyValue = "flex-start",
    valueFontSize = "medium",
    numberReductionFormat = false,
    valueColor = "var(--text-normal)",
    negativeValueColor = "var(--text-normal)",
    titleFontWeight = "normal",
    valueFontWeight = "normal",
    titleHeight = "half",
    titleShowBackground = false,
  } = config as TileWidgetStyles;

  const fixedStyle = {
    showBorder,
    borderColor,
    negativeBorderColor,
    titleColor,
    negativeTitleColor,
    justifyTitle,
    alignTitle,
    titleFontSize,
    alignValue,
    justifyValue,
    valueFontSize,
    numberReductionFormat,
    valueColor,
    negativeValueColor,
    titleFontWeight,
    valueFontWeight,
  };

  const isNegative =
    (column?.dataType === "Integer" || column?.dataType === "Double") &&
    data?.value < 0;

  return (
    <TileContainer styleOptions={{ ...fixedStyle }} isNegative={isNegative}>
      <HeaderContainer
        titleHeight={titleHeight}
        showBackground={titleShowBackground}
        styleOptions={{ ...fixedStyle }}
        isNegative={isNegative}
        title={header || ""}
      >
        {header}
      </HeaderContainer>
      <ValueContainer
        styleOptions={{ ...fixedStyle }}
        isNegative={isNegative}
        title={formattedValue || ""}
      >
        <ValueText>{formattedValue}</ValueText>
      </ValueContainer>
    </TileContainer>
  );
};

export default Tile;
