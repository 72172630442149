import { ReportsFolderProvider } from "@app-context/reports/ReportsFolderProvider";
import { AccordionListView } from "@enfusion-ui/web-components";
import { styled, useAuth } from "@enfusion-ui/web-core";
import * as React from "react";

import { TutorialType } from "../../components/Tour/utils";
import ReportsExplorerProvider from "../../context/reports/ReportsExplorerProvider";
import CreateReportFolderModal from "./components/CreateReportFolderModal";
import GenerateReportModal from "./components/GenerateReportModal";
import ReportsExplorer from "./ReportsExplorer";

const createExplorer = (
  root: "user" | "shared" | "global",
  tutorial?: TutorialType | null
) =>
  React.memo(function ReportFileExplorer({ open }: { open: boolean }) {
    return (
      <ReportsFolderProvider root={root}>
        <ReportsExplorer root={root} open={open} tutorial={tutorial} />
      </ReportsFolderProvider>
    );
  });

const defaultOpen = { user: true, shared: true, global: true };

const items = [
  {
    key: "user",
    title: "MY REPORTS",
    contentComponent: createExplorer("user", TutorialType.Reports),
  },
];

const ReportsSidebarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ReportSidebarTitle = styled.div`
  height: 40px;
  padding: 0 0.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const ReportsSidebar: React.FC<{ tutorial?: TutorialType | null }> = ({
  tutorial,
}) => {
  const { isEnfusionAdmin, hasRestrictedPerm } = useAuth();

  React.useEffect(() => {
    if (!hasRestrictedPerm?.("DisallowAllReportViewing")) {
      items.push({
        key: "shared",
        title: "SHARED REPORTS",
        contentComponent: createExplorer("shared"),
      });
    }
    if (isEnfusionAdmin()) {
      items.push({
        key: "global",
        title: "GLOBAL REPORTS",
        contentComponent: createExplorer("global"),
      });
    }
  }, []);

  return (
    <ReportsSidebarContainer
      data-tutorial-step={tutorial ? `${tutorial}__reports-sidebar` : undefined}
    >
      <ReportSidebarTitle data-e2e-id="reports-sidebar-title">
        Reports Explorer
      </ReportSidebarTitle>

      <ReportsExplorerProvider>
        <AccordionListView defaultOpen={defaultOpen} items={items} />

        <ReportsFolderProvider>
          <GenerateReportModal />
          <CreateReportFolderModal />
        </ReportsFolderProvider>
      </ReportsExplorerProvider>
    </ReportsSidebarContainer>
  );
};

export default ReportsSidebar;
