import { ControlledInstrumentSelect } from "@app-components/inputs/InstrumentSelect/ControlledInstrumentSelect";
import {
  formRateType,
  PaymentTypeValues,
  RateType,
  rateTypeOptions,
  spreadCalcMethodOptions,
  TradeTicketFormValues,
} from "@enfusion-ui/trades";
import {
  ButtonGroupSelect,
  ControlledNumericInput,
  ControlledSelect,
  PanelRow,
} from "@enfusion-ui/web-components";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useWatch } from "react-hook-form";

import { StyledFormElementHeader } from "../../../components/styledComponents";
import { VariableRates } from "./VariableRates";

const RateContainer = styled.div<{ hidden: boolean }>`
  flex-direction: column;
  gap: var(--spacing);
`;

export const PaymentSection: React.FC = () => {
  const [rateType, setRateType] = React.useState<RateType>("Fixed");
  const paymentType = useWatch<TradeTicketFormValues["paymentType"]>({
    name: "paymentType",
  });

  const isRateTypeFixed = rateType !== formRateType.fixed;
  const isRateTypeVar = rateType !== formRateType.variable;

  if (paymentType === PaymentTypeValues["Floating Rate"]) {
    return (
      <>
        <StyledFormElementHeader>Floating Rate Payment</StyledFormElementHeader>
        <PanelRow>
          <ControlledNumericInput
            required
            name="initialSpread"
            label="Initial Spread"
            fractionDigits={2}
          />
          <ControlledInstrumentSelect
            required
            name="benchmark"
            label="Benchmark"
            hideAdvancedSearchButtonText
          />
        </PanelRow>

        <PanelRow>
          <ControlledNumericInput name="lookbackDays" label="Lookback Days" />
          <ControlledNumericInput
            name="floorRate"
            label="Floor Rate"
            formatStyle="percent"
          />
        </PanelRow>

        <PanelRow>
          <ControlledSelect
            name="spreadCalcMethod"
            label="Spread Calc Method"
            options={spreadCalcMethodOptions}
          />
          <ControlledNumericInput
            name="priceLookbackDays"
            label="Price Lookback Days"
          />
        </PanelRow>
      </>
    );
  }

  return (
    <>
      <StyledFormElementHeader>Fixed Rate Payment</StyledFormElementHeader>
      <ButtonGroupSelect
        onSelect={(val) => val && setRateType(val)}
        selectedValue={rateType}
        options={rateTypeOptions}
      />

      {isRateTypeFixed && (
        <RateContainer hidden={isRateTypeFixed}>
          <ControlledNumericInput
            required
            name="fixedRate"
            label="Fixed Rate"
            formatStyle="percent"
          />
        </RateContainer>
      )}

      {isRateTypeVar && (
        <RateContainer hidden={isRateTypeVar}>
          <VariableRates />
        </RateContainer>
      )}
    </>
  );
};
