import * as React from "react";
export const OBJECT_WORDING = {
    Portfolios: {
        upper: "Workbench",
        upperPlural: "Workbenches",
        lower: "workbench",
        lowerPlural: "workbenches",
    },
    Dashboards: {
        upper: "Dashboard",
        upperPlural: "Dashboards",
        lower: "dashboard",
        lowerPlural: "dashboards",
    },
    Reconciliation: {
        upper: "Reconciliation settings",
        upperPlural: "Reconciliation settings",
        lower: "reconciliation settings",
        lowerPlural: "reconciliation settings",
    },
    Services: {
        upper: "Services file",
        upperPlural: "Services files",
        lower: "services file",
        lowerPlural: "services files",
    },
    Operations: {
        upper: "Operation",
        upperPlural: "Operations",
        lower: "operation",
        lowerPlural: "operations",
    },
    Reports: {
        upper: "Report",
        upperPlural: "Reports",
        lower: "report",
        lowerPlural: "reports",
    },
    GeneralFiles: {
        upper: "File",
        upperPlural: "Files",
        lower: "file",
        lowerPlural: "files",
    },
    WatchLists: {
        upper: "Watch list",
        upperPlural: "Watch lists",
        lower: "watch list",
        lowerPlural: "watch lists",
    },
    Folder: {
        upper: "Folder",
        upperPlural: "Folders",
        lower: "folder",
        lowerPlural: "folders",
    },
    Password: {
        upper: "Your password",
        upperPlural: "Your passwords",
        lower: "your password",
        lowerPlural: "your passwords",
    },
    Order: {
        upper: "Order",
        upperPlural: "Orders",
        lower: "order",
        lowerPlural: "orders",
    },
    Compliance: {
        upper: "Compliance",
        upperPlural: "Compliance",
        lower: "compliance",
        lowerPlural: "compliance",
    },
};
const toastActionContent = (w, past, present) => ({
    success: `${w.upper} ${past} successfully`,
    failure: `Failed to ${present} ${w.lower}`,
});
function createExplorerActionStatusTexts(section) {
    const wording = OBJECT_WORDING[section];
    return {
        save: toastActionContent(wording, "saved", "save"),
        create: toastActionContent(wording, "created", "create"),
        rename: toastActionContent(wording, "renamed", "rename"),
        copy: toastActionContent(wording, "copied", "copy"),
        move: toastActionContent(wording, "moved", "move"),
        delete: toastActionContent(wording, "deleted", "delete"),
        share: {
            success: `Copied ${wording.lower} share link`,
            failure: `Failed to copy ${wording.lower} share link`,
        },
        openAdditionalInstance: {
            success: `Opened an additional ${wording.lower} instance`,
            failure: `Failed opening an additional ${wording.lower} instance`,
        },
    };
}
export const TOAST_CONTENT = {
    Reports: createExplorerActionStatusTexts("Reports"),
    Services: createExplorerActionStatusTexts("Services"),
    Operations: createExplorerActionStatusTexts("Operations"),
    Portfolios: {
        ...createExplorerActionStatusTexts("Portfolios"),
        tourError: "Tour canceled because workbench failed to load",
    },
    Dashboards: createExplorerActionStatusTexts("Dashboards"),
    Reconciliation: toastActionContent(OBJECT_WORDING.Reconciliation, "saved", "save"),
    GeneralFiles: createExplorerActionStatusTexts("GeneralFiles"),
    WatchLists: createExplorerActionStatusTexts("WatchLists"),
    folder: createExplorerActionStatusTexts("Folder"),
    password: {
        change: toastActionContent(OBJECT_WORDING.Password, "was changed", "change"),
    },
    oems: {
        newOrder: toastActionContent(OBJECT_WORDING.Order, "staged", "stage"),
        split: toastActionContent(OBJECT_WORDING.Order, "split", "split"),
        fill: toastActionContent(OBJECT_WORDING.Order, "fill added", "add fill"),
        cancel: toastActionContent(OBJECT_WORDING.Order, "cancelled", "cancel"),
        transmit: toastActionContent(OBJECT_WORDING.Order, "transmitted", "transmit"),
        tourError: "Tour canceled because OMS failed to load",
        compliance: {
            ...toastActionContent(OBJECT_WORDING.Compliance, "ran", "run"),
            warn: "Compliance completed with warnings",
            default: "Compliance state unchanged",
        },
        networkError: {
            holidayCenter: {
                fetchFailed: "Failed to get holiday center",
            },
            dayCounts: {
                fetchFailed: "Failed to get day counts",
            },
            varianceNotional: {
                fetchFailed: "Failed to get calculated variance notional",
            },
        },
    },
};
const editableDesc = (desc) => `Editable. ${desc}`;
const editablePWBDescriptions = {
    finalExposure: "Pro-forma exposure. Current Ex plus Inc Ex.",
    incrementalExposure: "Incremental exposure. Final Ex minus Current Ex.",
    finalExposurePercentNAV: "Final Ex divided by the denominator set in the settings.",
    incrementalExposurePercentNAV: "Inc Ex divided by the denominator set in the settings.",
    finalNMV: "Pro-forma market value. Current MV plus Inc MV.",
    incrementalNMV: "Incremental market value. Final MV minus Current MV.",
    finalNAV: "Pro-forma NAV. Current NAV plus Inc NAV.",
    incrementalNAV: "Amount of any modeled subscriptions or redemptions. Final NAV minus Current NAV.",
    finalQuantity: "Pro-forma quantity. Current Qty plus Inc Qty.",
    incrementalQuantity: "Proposed order quantity. Final Qty minus Current Qty.",
    finalCTD: "Final Contribution to Duration",
    incrementalCTD: "Incremental Contribution to Duration",
    finalDuration: "Final Weighted Duration",
    incrementalDuration: "Incremental Weighted Duration",
};
export const pwbColumnDescriptions = {
    ...Object.entries(editablePWBDescriptions).reduce((res, e) => ({ ...res, [e[0]]: editableDesc(e[1]) }), {}),
    currentCTD: "Current Contribution to Duration",
    benchmarkCTD: "Benchmark Contribution to Duration",
    modelCTD: "Model Contribution to Duration",
    duration: "Duration of the instrument held or weighted average when aggregated.",
    benchmarkDuration: "Duration of the instrument held by the benchmark or weighted average when aggregated.",
    modelDuration: "Duration of the instrument held by the model or weighted average when aggregated.",
    currentExposure: "Exposure defined by the numerator aggregation set in the settings.",
    benchmarkExposure: "The exposure of the instrument associated with the corresponding benchmark(s) defined by the numerator aggregation set in the settings.",
    modelExposure: "The exposure of the instrument associated with the corresponding model(s) defined by the numerator aggregation set in the settings.",
    benchmarkExposureDifference: "The difference between the Current Exposure and the Benchmark Exposure.",
    modelExposureDifference: "The difference between the Current Exposure and the Model Exposure.",
    currentExposurePercentNAV: "Current Ex divided by the denominator set in the settings.",
    benchmarkExposurePercentNAV: "The total weight of the instrument associated with the corresponding benchmark or blend of multiple benchmarks as a percent.",
    modelExposurePercentNAV: "The total weight of the instrument associated with the corresponding model or blend of multiple models as a percent",
    benchmarkPercentDifference: "The difference between the Current Exposure % NAV and the Benchmark Exposure % NAV. Also known as Active Weight.",
    modelPercentDifference: "The difference between the Current Exposure % NAV and the Model Exposure % NAV. Also known as Active Weight.",
    currentQuantity: "Current quantity of the holding.",
    benchmarkQuantity: "The notional quantity of the instrument associated with the corresponding benchmark or blend of multiple benchmarks.",
    modelQuantity: "The notional quantity of the instrument associated with the corresponding model or blend of multiple models.",
    benchmarkQuantityDifference: "The difference between the Current Quantity and the Benchmark Quantity.",
    modelQuantityDifference: "The difference between the Current Quantity and the Model Quantity.",
    currentMNV: "Local market value displayed in the fund's base currency. This not impacted by the settings.",
    currentNAV: "Current net asset value calculated using the denominator set in the settings.",
    benchmarkName: "Name of the benchmark(s) that are associated with a particular fund.",
    modelName: "Name of the model(s) that are associated with a particular fund.",
    country: "Country of the security.",
    riskCountry: "Country of the security to which it is predominantly exposed.",
    assetClass: "Group of similar instrument types (i.e, equity and fixed income).",
    instrumentCcy: "Currency in which a given instrument is denominated.",
    instrumentSubtype: "Subgroup of instrument types (e.g., corporate bonds and government bonds).",
    instrument: "Primary method of grouping instruments together at Enfusion (e.g., equity and bond).",
    price: "Editable. Price used to calculate exposure.",
    issuer: "Entity that issued a given security.",
    account: "Custodian account.",
    fund: "The long name of the fund.",
    fundShortName: "The short name or code of the fund.",
    sector: "The highest level group in the GICS global industry classification standard (e.g., Financials).",
    industry: "The third-highest grouping level in the GICS global industry classification standard (e.g., Capital Markets & Financial Services).",
    industryGroup: "The second-highest grouping level in the GICS global industry classification standard (e.g., Banks & Insurance).",
    subIndustry: "The most granular group in the GICS global industry classification standard (e.g., Life Insurance vs. Property & Casualty Insurance).",
    bbYellow: "The conventional Bloomberg symbol, which is a combination of the exchange ticker plus the countries exchange code plus the asset class. For example, Apple Inc is AAPL US because it is the US composite of all exchanges.",
    cusip: "The CUSIP number is a unique identification number assigned to all stocks and registered bonds in the United States and Canada; it is used to create a concrete distinction between securities that are traded on public markets.",
    isin: "International Securities Identification Numbering system is the global ISO standard for unique identification of financial and referential instruments, including equity, debt, derivatives, and indices.",
    sedol: "The Stock Exchange Daily Official List, which is a list of security identifiers; this field is empty unless licensed from LSEG.",
};
export const CHANGE_TRADE_INSTRUCTIONS = {
    header: "For one or more destinations, select",
    texts: [
        "Send to queue trade(s) for sending",
        "Suppress current and future changes",
        "One time suppress",
        "Leave blank for default action",
    ],
};
export const PNL_ADJUSTMENT_INSTRUCTIONS = {
    header: "To utilize the P&L Adjustment,",
    texts: [
        "A trade type of 'P&L Adjustment' is required",
        "One (and only one) trade fee of either 'P&L Realized Adjustment' or 'P&L Unrlzd and Rlzd Adjustment' is required",
        "A trading account associated with a general ledger must be defined",
    ],
    externalLink: "https://zendesk.enfusionsystems.com/hc/en-us/articles/4411989068951",
};
const SUPPORT_MAIL_SUBJECT = {
    permission: "Issue with permissions",
    missing: "Issue with file could not be found.",
    forbidden: "Issue with forbidden access.",
};
const SUPPORT_MAIL_CONTENT = (component, msg, when, appSource, version, meta = "") => `Hello,%0D%0DI am seeing an issue in a ${component} view.%0DThe message shown is:%0D"${msg}"%0D%0DSome helpful details:%0DTime: ${when.toISOString()}%0DApp: ${appSource}%0DVersion: ${version}${meta ? `%0D${meta}` : ""}%0D%0DThanks`;
export const ERROR_VIEW_CONTENT = {
    permission: (component) => `You do not have permission to access the ${component} view.`,
    missing: (component) => `${component} file could not be found.`,
    contactSupport: (link) => (React.createElement(React.Fragment, null,
        "Please contact ",
        link,
        " if you believe this is an error.")),
    forbidden: (component) => `This ${component} file's access is forbidden.`,
    supportMail: (type, component, when, appSource, version, meta) => [
        SUPPORT_MAIL_SUBJECT[type],
        SUPPORT_MAIL_CONTENT(component, ERROR_VIEW_CONTENT[type](component), when, appSource, version, meta),
    ],
};
export const cashBreakTypes = {
    CashActivity: "Cash Activity",
    CashBalance: "Cash Balance",
};
