import { WidgetComponentsDefinition } from "../../../types";
import AssetMeasureSelectionEditWidget from "./AssetMeasureSelectionEditWidget";
import AssetMeasureSelectionWidget from "./AssetMeasureSelectionWidget";
import PdfAssetMeasureSelectionWidget from "./PdfAssetMeasureSelectionWidget";

export const assetMeasureSelection: WidgetComponentsDefinition = {
  renderComponent: AssetMeasureSelectionWidget,
  editComponent: AssetMeasureSelectionEditWidget,
  renderPdfComponent: PdfAssetMeasureSelectionWidget,
};
