/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRefCallback } from "@enfusion-ui/hooks";
import { getParentWindow, isDesktop, styled, ThisTabProvider, useAuth, useContextMenu, useTabs, } from "@enfusion-ui/web-core";
import queryString from "query-string";
import Menu, { MenuItem } from "rc-menu";
import * as React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useHistory } from "react-router-dom";
import { useBeforeUnload } from "react-use";
import { Spinner } from "../display";
import { PositionedPortal } from "../portal";
import { CenterContent, SVGBackground } from "../Styled";
import { TopAppBar } from "../TopAppBar";
import { DuplicateLogInPage } from "./login";
const LayoutWrapper = styled.div `
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  flex-direction: column;
  border-style: solid;
  border-width: ${({ desktop }) => (desktop ? 0.75 : 0)}px;
  border-color: var(--background-accent, black);
`;
const MainWrapper = styled.div `
  display: flex;
  width: 100%;
  flex: 1;
  min-height: 0;
`;
export function UndockedView({ renderTab, }) {
    const { isLoggedInDesktop } = useAuth();
    const history = useHistory();
    const { undockedTabs = [], onTabWillCloseSubscriptions, dockTab } = useTabs();
    const [tabId, setTabId] = React.useState(undefined);
    const tabDef = React.useMemo(() => {
        if (!tabId)
            return undefined;
        return undockedTabs.find((t) => t.id === tabId);
    }, [undockedTabs, tabId]);
    const handleUndock = useRefCallback(() => {
        if (tabDef)
            dockTab(tabDef.id);
    }, [tabDef, dockTab]);
    useHotkeys("ctrl+d", handleUndock);
    const internalHandler = useRefCallback(() => {
        if (tabId && tabDef) {
            if (onTabWillCloseSubscriptions &&
                onTabWillCloseSubscriptions[tabId] &&
                onTabWillCloseSubscriptions[tabId]
                    .map((callback) => typeof callback === "function" ? callback(tabDef) : true)
                    .some((i) => i === false)) {
                return true;
            }
            getParentWindow()?.postMessage({ action: "close", source: "app-pop-out", id: tabId }, "*");
            // give post message time to propagate
            for (let i = 0; i < 500; i += 1) {
                document.querySelectorAll("div");
            }
        }
        return false;
    }, [onTabWillCloseSubscriptions, tabId, tabDef]);
    useBeforeUnload(internalHandler, "");
    React.useEffect(() => {
        const parsed = queryString.parse(window.location.search);
        if (parsed.v) {
            setTabId(atob(parsed.v));
            history.replace("/pop-out");
        }
    }, [tabId]);
    React.useEffect(() => {
        if (!tabDef)
            return;
        document.title = tabDef.name;
    }, [tabDef?.name]);
    const { handleContextEvent, contextMenuOpen, handleContextMenuPlacement, closeContextMenu, } = useContextMenu();
    return (React.createElement(LayoutWrapper, { desktop: isDesktop() },
        React.createElement(TopAppBar, { showTitle: false, titleLeftContent: tabDef?.name ?? document.title, onContextMenu: handleContextEvent }),
        React.createElement(PositionedPortal, { open: contextMenuOpen, setPlacement: handleContextMenuPlacement, onClickOutside: closeContextMenu },
            React.createElement(Menu, { onClick: closeContextMenu, selectedKeys: [] },
                React.createElement(MenuItem, { key: "dock", onClick: handleUndock }, "Dock"))),
        React.createElement(MainWrapper, null,
            isLoggedInDesktop && React.createElement(DuplicateLogInPage, { undocked: true }),
            tabDef ? (React.createElement(ThisTabProvider, { id: tabDef.id, config: tabDef.config }, renderTab(tabDef))) : (React.createElement(SVGBackground, null,
                React.createElement(CenterContent, null,
                    React.createElement(Spinner, null)))))));
}
