/* eslint-disable no-nested-ternary */
import { FXOrderFormValues, useOEMSOrderForm } from "@enfusion-ui/core";
import { useRefCallback, useRestAbortableOptions } from "@enfusion-ui/hooks";
import { SelectOptionsType } from "@enfusion-ui/types";
import {
  ButtonGroupSelect,
  Checkbox,
  ControlledNumericInputAsString,
  ControlledSelect,
  FormController,
  PanelRow,
} from "@enfusion-ui/web-components";
import { REST_API, useOEMS } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import CalculatedSidePanel from "./CalculatedSidePanel";

const fxGroupOptions = [
  { label: "FX Spot", value: "Spot" },
  { label: "FX Forward", value: "Forward" },
  { label: "FX Swap", value: "Swap" },
];

export type FXOrderDetailsPanelProps = {
  editMode?: boolean;
  disabled?: boolean;
};

const FXOrderDetailsPanel: React.FC<FXOrderDetailsPanelProps> = ({
  editMode,
  disabled,
}) => {
  const { setValue } = useFormContext<FXOrderFormValues>();

  const { fxType, currencyPair, orderSide } = useWatch<FXOrderFormValues>({
    name: ["fxType", "currencyPair", "orderSide"],
  });

  const { currencyDetails } = useOEMS();
  const {
    newOrder,
    minQuantity = 0,
    isTransmitted,
    completed,
  } = useOEMSOrderForm();
  const [currencyOptions, setCurrencyOptions] = React.useState<
    SelectOptionsType<string>[]
  >([]);

  const { options: currencyPairs, loading: loadingCurrencyPairs } =
    useRestAbortableOptions(REST_API.INSTRUMENT.GET_CURRENCY_PAIRS.FETCH);

  React.useEffect(() => {
    const currencies = currencyPair ? (currencyPair as string).split("/") : [];
    if (newOrder && currencies?.length > 0) setValue("currency", currencies[0]);
    setCurrencyOptions(currencies.map((value) => ({ label: value, value })));
  }, [currencyPair, newOrder]);

  const handleCurrencyPairChange = useRefCallback(
    (newCurrencyPair?: string | string[] | null) => {
      if (newOrder) {
        let isDeliverable = false;
        if (!!newCurrencyPair && !!currencyDetails) {
          const [curr1, curr2] = (newCurrencyPair as string).split("/");
          // check if both currency details have deliverable as true
          isDeliverable =
            currencyDetails[curr1]?.deliverable &&
            currencyDetails[curr2]?.deliverable;
        }
        setValue("deliverable", isDeliverable);
      }
    },
    [currencyDetails, newOrder, setValue]
  );

  const handleClearFarDates = useRefCallback(
    (newFxType: string | null) => {
      if (newFxType !== "Swap") {
        setValue("farFixingDate", "");
        setValue("farSettleDate", "");
      }
    },
    [setValue]
  );

  const currencyPairOptionsRef = React.useRef<
    SelectOptionsType<string>[] | null
  >(null);
  const currencyPairOptions = React.useMemo(() => {
    // currency pairs not loaded yet
    if (!currencyPairs || currencyPairs.length === 0) return [];

    if (!currencyPairOptionsRef.current) {
      currencyPairOptionsRef.current = [...currencyPairs];
    }

    // if we don't have a pair just return the list
    if (!currencyPair) return currencyPairOptionsRef.current;

    const pair = currencyPairOptionsRef.current.find(
      (e) => e.value === currencyPair
    );
    // if its a valid string but not in the list
    // add it to the list this is likely an old order
    if (!pair) {
      currencyPairOptionsRef.current.push({
        label: currencyPair,
        value: currencyPair,
      });
    }

    return currencyPairOptionsRef.current;
  }, [currencyPairs, currencyPair]);

  return (
    <div>
      <PanelRow>
        <ControlledSelect
          name="currencyPair"
          label="Currency Pair"
          options={currencyPairOptions}
          disabled={editMode}
          onChange={handleCurrencyPairChange}
          isLoading={loadingCurrencyPairs}
          inputId="currency-pair-id"
        />
        <FormController
          name="deliverable"
          mobileBasis="50%"
          style={{ alignItems: "flex-end" }}
          render={({ ref: _ref, value, ...rest }) => (
            <Checkbox
              {...rest}
              label="Deliverable"
              checked={value}
              labelPlacement="right"
              disabled={completed || isTransmitted}
            />
          )}
        />
        <FormController
          name="fxType"
          style={{ alignItems: "flex-end" }}
          render={({ ref: _ref, onChange, ...rest }) => (
            <ButtonGroupSelect
              {...rest}
              options={fxGroupOptions}
              selectedValue={fxType}
              onSelect={(value) => {
                handleClearFarDates(value);
                onChange(value);
              }}
              disableDeselect
              size="medium"
              disabled={editMode}
            />
          )}
        />
      </PanelRow>
      <PanelRow>
        <CalculatedSidePanel
          showLimitedOptionsWithFullDisplay
          disabled={disabled}
        />
        <ControlledNumericInputAsString
          name="quantity"
          label="Quantity"
          mobileBasis="50%"
          enableMultiplier
          defaultValue="0"
          min={minQuantity}
          disabled={!orderSide || completed}
          id="quantity-id"
        />
        <ControlledSelect
          name="currency"
          label="Currency"
          options={currencyOptions}
          disabled={disabled}
          inputId="fx-order-detail-currency-selection-id"
        />
      </PanelRow>
    </div>
  );
};

export default FXOrderDetailsPanel;
